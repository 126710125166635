// 시 경계선
export const cityData = {
  type: 'FeatureCollection',
  name: 'SIDO',
  crs: {
    type: 'name',
    properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' }
  },
  features: [
    {
      id: '31',
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        SIDO_NM: '경기도 고양시',
        SIDO_CD: '31'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.930188663968877, 37.747785177937402],
              [126.930665498703704, 37.74748814859408],
              [126.931289951612186, 37.747065070878165],
              [126.931846285919619, 37.746677987998943],
              [126.93216417542456, 37.746470954534544],
              [126.932550182325954, 37.746218912048555],
              [126.93304847238737, 37.74590105531793],
              [126.933072415469937, 37.745885861007025],
              [126.930460495730372, 37.744208528098689],
              [126.930362345888724, 37.744145401617708],
              [126.930457040945228, 37.740037075148059],
              [126.931140017907765, 37.737649936092076],
              [126.930437451733681, 37.736793600563587],
              [126.930619466569993, 37.736271152120416],
              [126.930627056081022, 37.736116587605778],
              [126.930631034624227, 37.736036908897681],
              [126.929995724682669, 37.736045534753991],
              [126.929885636529207, 37.736056729933985],
              [126.929383040140678, 37.736108228768451],
              [126.928569199718922, 37.736161506361917],
              [126.928566181758853, 37.736161783792468],
              [126.928484874663255, 37.736103729496961],
              [126.927137770119785, 37.735142803731932],
              [126.927046995843796, 37.735160765666322],
              [126.926597414890452, 37.735213979924914],
              [126.926593160125293, 37.735214535817292],
              [126.926586555965599, 37.735081081074028],
              [126.926537582274307, 37.734079287802487],
              [126.925528955796111, 37.733078573436181],
              [126.925154615273613, 37.733060312277921],
              [126.92331706174528, 37.732860895793529],
              [126.922841054282586, 37.732437126572222],
              [126.922761740908712, 37.732346977333194],
              [126.922104331546961, 37.731832987485816],
              [126.922093016395451, 37.731805950977034],
              [126.922070347612092, 37.731787916444347],
              [126.921968646656453, 37.731418453075932],
              [126.921833513589604, 37.730490369935666],
              [126.921629576539502, 37.730255981507788],
              [126.921675406321938, 37.72983255988963],
              [126.921689392078093, 37.729685604249568],
              [126.921732772556908, 37.729228953384336],
              [126.922413610479197, 37.729022190661063],
              [126.92337816809696, 37.728698478158009],
              [126.923345006885711, 37.72786056921678],
              [126.923356463931356, 37.727752461196417],
              [126.923447712404126, 37.727275010994155],
              [126.923357704343431, 37.726572199734484],
              [126.923426179911544, 37.726175821087288],
              [126.923222429851691, 37.725770252251536],
              [126.923098054762406, 37.725391764778976],
              [126.923064700393169, 37.724752058220737],
              [126.923155652763867, 37.724553906641091],
              [126.923643690419794, 37.724274931192589],
              [126.923734876211924, 37.723851538285537],
              [126.92417750793102, 37.723599559547608],
              [126.92379232459723, 37.723148824144381],
              [126.923838325796567, 37.72254520842106],
              [126.924042821150763, 37.722230005476227],
              [126.923986756323089, 37.721608303192014],
              [126.924134431321264, 37.721383159103183],
              [126.924168619846682, 37.721239027221998],
              [126.924656771580331, 37.720824902802498],
              [126.924861500128031, 37.720275438680616],
              [126.924872925924547, 37.720194368300312],
              [126.924850640703994, 37.719806938994807],
              [126.923989404309452, 37.71906758467594],
              [126.923695122962144, 37.718481763623942],
              [126.923683790401839, 37.718472746493703],
              [126.923661153576404, 37.71842768330098],
              [126.923649896470934, 37.718346589022858],
              [126.923650047355125, 37.718202434731147],
              [126.923672825517556, 37.718112353276908],
              [126.923695716720133, 37.717914156092213],
              [126.923798038537043, 37.717679972683555],
              [126.923798179718432, 37.717544828019321],
              [126.923492857037672, 37.716679699831985],
              [126.923266581891795, 37.716147980380576],
              [126.923312109411626, 37.715994846490837],
              [126.923265769859171, 37.715906404018448],
              [126.923142326974997, 37.715670386232567],
              [126.922417003684828, 37.715165361220556],
              [126.922190183678637, 37.715156199171865],
              [126.921726390782666, 37.714029679325705],
              [126.921057243913381, 37.714038234229392],
              [126.920999656707394, 37.714034536995399],
              [126.92090981666415, 37.714029123871512],
              [126.920501774862288, 37.713803602792574],
              [126.920740166550175, 37.713596544265975],
              [126.920955884246879, 37.713380460101789],
              [126.921398387033207, 37.713209578147492],
              [126.921534450510336, 37.71322768984367],
              [126.922453392863304, 37.712940000623263],
              [126.923066175474418, 37.71259804295638],
              [126.923769727826013, 37.712211093678846],
              [126.924563982006106, 37.71184221872771],
              [126.924678415131439, 37.710851230740268],
              [126.924804065119162, 37.709977375382252],
              [126.924475604825915, 37.709571726399915],
              [126.924577772949107, 37.70947268682135],
              [126.92456665592519, 37.709256447648826],
              [126.925349773833844, 37.708653308664417],
              [126.924432528714604, 37.707364339426768],
              [126.923752563071886, 37.706931410908602],
              [126.923639405918266, 37.706697093888181],
              [126.923458132961329, 37.706534800006196],
              [126.923356185011215, 37.706426616458387],
              [126.923368745513798, 37.705264377536892],
              [126.923562025169517, 37.704786992977809],
              [126.922881859653657, 37.704570309245284],
              [126.922530524582157, 37.704380871331757],
              [126.922236493164604, 37.7036148609428],
              [126.922373060147081, 37.703164460062361],
              [126.922157787533351, 37.702984121917247],
              [126.922164237262947, 37.702763109967414],
              [126.922169570683508, 37.702578694551754],
              [126.922510283517667, 37.702083391268985],
              [126.92247659032256, 37.701777039753395],
              [126.922057377252756, 37.701452400689796],
              [126.922035217932802, 37.700965872280015],
              [126.922319352658178, 37.700353405459289],
              [126.922217510787746, 37.70015512411679],
              [126.921696516491778, 37.69958716289819],
              [126.921674321421605, 37.699136663948401],
              [126.921447937951342, 37.69876711360228],
              [126.921413950087896, 37.698740061480578],
              [126.921040056002454, 37.698469516508283],
              [126.921097188639663, 37.698064119881181],
              [126.92062153349579, 37.697532223358905],
              [126.920440691671388, 37.697000527942386],
              [126.920395513531957, 37.696838313582809],
              [126.92020292310778, 37.696685025344358],
              [126.920100963797665, 37.696603867849113],
              [126.920026317526137, 37.696493447674008],
              [126.920021721446417, 37.696486678225973],
              [126.919180165478593, 37.696016752196854],
              [126.919149184846347, 37.695999558104212],
              [126.919070094276307, 37.695747231689658],
              [126.918991283851369, 37.695242634242852],
              [126.918720223167966, 37.694287417584441],
              [126.918857292937261, 37.693377535377302],
              [126.918788462197355, 37.693139856596837],
              [126.918703221220483, 37.692845008781148],
              [126.918687871959719, 37.692791786781449],
              [126.91865389784968, 37.692755724159703],
              [126.917871885057991, 37.692475871714834],
              [126.917656888772612, 37.692097312077074],
              [126.917634507163214, 37.69183601516005],
              [126.917306043551918, 37.69153846129003],
              [126.917714724329542, 37.691079258199245],
              [126.916899067443254, 37.690493045806605],
              [126.917035855989596, 37.689844446030662],
              [126.916082000531276, 37.688982774525051],
              [126.916027852229632, 37.688933740567492],
              [126.915257748644166, 37.688203394518617],
              [126.914907662649114, 37.687022867735458],
              [126.914704296796756, 37.686419068474343],
              [126.91419580677082, 37.684986150918284],
              [126.914218722625947, 37.68476993507624],
              [126.914230303088516, 37.684571730234104],
              [126.91428743032543, 37.684193365056842],
              [126.914356009649055, 37.683715901569194],
              [126.914390262095154, 37.68350870372673],
              [126.914401640099697, 37.683463663619555],
              [126.914391020835708, 37.682860005676602],
              [126.914391579337376, 37.682382491788182],
              [126.914391927077006, 37.682085171800253],
              [126.914392053526498, 37.681977055437244],
              [126.914176904432395, 37.681769673087707],
              [126.914210955846173, 37.681733659479612],
              [126.914239621524445, 37.681756484260376],
              [126.91427891086083, 37.681787767996234],
              [126.914279977473726, 37.681786921872941],
              [126.914287238695621, 37.681781863789531],
              [126.914483134393507, 37.681643763600711],
              [126.914630622838189, 37.68154476559107],
              [126.914574109291024, 37.681400568653842],
              [126.914521297175796, 37.681271015188585],
              [126.914415853558737, 37.681013034525492],
              [126.914370698163168, 37.680850826452975],
              [126.914291629211306, 37.680607514929989],
              [126.914280356308836, 37.680553439340905],
              [126.914167363977469, 37.680238015956455],
              [126.914065698135431, 37.67993161055427],
              [126.913907479939212, 37.679517046551844],
              [126.913869004830019, 37.679401864878983],
              [126.913862283413522, 37.679381867340041],
              [126.913762373500759, 37.679251719876504],
              [126.913613284816819, 37.679057332494061],
              [126.913420828857568, 37.678840955869511],
              [126.913160454700687, 37.678543440672051],
              [126.913136823540128, 37.678453884467579],
              [126.913115314215617, 37.678372222353644],
              [126.913104277709238, 37.678119942188346],
              [126.913093284047463, 37.677831623199104],
              [126.9130934674304, 37.677829929510878],
              [126.913127857923982, 37.677354134408034],
              [126.913094257080274, 37.677011740162726],
              [126.913083758958763, 37.676754955484306],
              [126.913083242224701, 37.676741440527728],
              [126.913004331666784, 37.676371983099386],
              [126.912994615488216, 37.676354235681757],
              [126.912789427901757, 37.675975394225894],
              [126.912751054596697, 37.675920460154991],
              [126.912732846972204, 37.675894264221334],
              [126.912472528906491, 37.675560708546627],
              [126.912449901091534, 37.675524652602846],
              [126.912212105185176, 37.675317249349327],
              [126.911872378913088, 37.6750376904589],
              [126.911453297027236, 37.674767079723814],
              [126.911034292541331, 37.674584591580647],
              [126.910105111704539, 37.674180410990509],
              [126.909504600067336, 37.673972720562809],
              [126.909096644451836, 37.673882304433441],
              [126.908905937854755, 37.673848368406759],
              [126.908790672920858, 37.673827996971852],
              [126.908575248160901, 37.673863875290998],
              [126.908325840296655, 37.673899717360989],
              [126.907996916135957, 37.674052622197571],
              [126.907777806426168, 37.674205613439966],
              [126.907713286209855, 37.674250610725288],
              [126.907508933510471, 37.674502720072894],
              [126.907293232711496, 37.674763829705057],
              [126.9070320404239, 37.675142028789871],
              [126.906952544106417, 37.675259091370421],
              [126.906850308023849, 37.67543019393176],
              [126.906770937133416, 37.675448149627719],
              [126.906589570284254, 37.675448003794443],
              [126.906294849157206, 37.675447766220557],
              [126.906328889970396, 37.675420764555426],
              [126.90587547302259, 37.675420397750322],
              [126.905682782410238, 37.675411231625482],
              [126.905569462994094, 37.675384110352034],
              [126.905263558012692, 37.675266734815558],
              [126.905456375966111, 37.675176794868626],
              [126.905966271948103, 37.675330374200549],
              [126.906238436995068, 37.675240497230568],
              [126.906386130729146, 37.674979334718699],
              [126.906658397216503, 37.674808369417903],
              [126.906601927140514, 37.674646149110124],
              [126.906534202420531, 37.674420851697107],
              [126.906296507113311, 37.674150368556809],
              [126.906410951848869, 37.673294537784031],
              [126.906410986345932, 37.673267508658938],
              [126.906513404242972, 37.672952251071862],
              [126.906842269455169, 37.672835389168618],
              [126.908430423058604, 37.671836574430742],
              [126.907819619644414, 37.670817992350699],
              [126.907774371412856, 37.670745878624878],
              [126.907627462824337, 37.670394382621289],
              [126.907752348122713, 37.670232306963854],
              [126.908183301212716, 37.670043444588593],
              [126.908444267207912, 37.66982741745101],
              [126.909056591285292, 37.669620675399855],
              [126.909146559300495, 37.669625250752247],
              [126.909237932810328, 37.669629827135587],
              [126.909241651589298, 37.669628983130927],
              [126.90964610967275, 37.669522029084661],
              [126.909816482332033, 37.66923385049931],
              [126.90982785006878, 37.669206830164164],
              [126.910145403285256, 37.669053911273281],
              [126.911222624228586, 37.668685342113989],
              [126.911237322556516, 37.668687326494727],
              [126.911766588682667, 37.668757835075446],
              [126.911902341628604, 37.668974171688212],
              [126.912038116995831, 37.669172488705563],
              [126.912158251810496, 37.669124720123889],
              [126.912174195503425, 37.66911853351121],
              [126.912212267978532, 37.669118562343286],
              [126.912287540174972, 37.669118619310353],
              [126.912570891103485, 37.669127843048031],
              [126.912639788719346, 37.669122263905358],
              [126.912963917946641, 37.66909603712368],
              [126.913012978182238, 37.669092136696356],
              [126.913080963580683, 37.66911020713831],
              [126.913194276210547, 37.669137321215594],
              [126.913284919974174, 37.669164418228931],
              [126.913307610250314, 37.669146415751079],
              [126.913326040760907, 37.669136293592814],
              [126.913712730616169, 37.668922321821221],
              [126.91379531067733, 37.66887648772736],
              [126.913851887452111, 37.66895761731422],
              [126.913919745857726, 37.66908380387499],
              [126.913897002786612, 37.669146855054308],
              [126.91391959760314, 37.669209939907915],
              [126.914043949086775, 37.669489333643313],
              [126.914395214013567, 37.669579690860374],
              [126.914644332298963, 37.669787098249195],
              [126.915063575505556, 37.669904532690545],
              [126.915244939336745, 37.669895655761273],
              [126.915403540260627, 37.669967849447033],
              [126.915471391808993, 37.670103044813381],
              [126.916015339768848, 37.670202547328806],
              [126.916031284654906, 37.670195242991092],
              [126.916403400882146, 37.670026570781495],
              [126.916412257705929, 37.670022639923211],
              [126.916775026566455, 37.669968842775027],
              [126.917126511326785, 37.669869987819624],
              [126.917141584369915, 37.669851699854462],
              [126.917342163510753, 37.669608859926392],
              [126.917705132333907, 37.669374865570255],
              [126.917734351742894, 37.669375733243122],
              [126.918271837601978, 37.66939328634075],
              [126.918509912502998, 37.669348405503079],
              [126.918623277585468, 37.669330465779815],
              [126.918759291601489, 37.669330561289939],
              [126.91899724629981, 37.669393796077863],
              [126.919223886779079, 37.669439003043237],
              [126.919314463547224, 37.669529163446462],
              [126.919371076621218, 37.669583261233306],
              [126.919699857410492, 37.669511411959462],
              [126.920141845024546, 37.669565776065774],
              [126.920197996675853, 37.670043330157675],
              [126.92037910521104, 37.670268697965682],
              [126.92025416868978, 37.670502864752642],
              [126.920339225720738, 37.670948904585565],
              [126.920355601892922, 37.671034508214866],
              [126.920229276173472, 37.671075811883945],
              [126.920162842108027, 37.671097443471055],
              [126.920105764710641, 37.671466802673947],
              [126.920354964084439, 37.671620139676335],
              [126.921148355013727, 37.671665731560942],
              [126.921397789678437, 37.671602833212326],
              [126.921794461157489, 37.671648150601392],
              [126.921941987441684, 37.671486075241589],
              [126.923120696055349, 37.671594981240688],
              [126.923551467374054, 37.671550218168761],
              [126.92433373933126, 37.671388557683628],
              [126.925104728541115, 37.671172826363396],
              [126.925478951058622, 37.671001883487421],
              [126.925773764639814, 37.670893956508699],
              [126.926227290821998, 37.670759101040318],
              [126.926782610823096, 37.670840541714753],
              [126.927417526100754, 37.670669757568675],
              [126.928346370577785, 37.671282998579912],
              [126.928640837511452, 37.671526443381218],
              [126.928708732105164, 37.671643611692957],
              [126.928720022954437, 37.671688667269706],
              [126.929139202861123, 37.671905158555283],
              [126.92924117323092, 37.671950269690896],
              [126.929490706545863, 37.67177923763397],
              [126.929524728584084, 37.671761238962517],
              [126.930114064339165, 37.671842684701602],
              [126.930442518253969, 37.672113174718511],
              [126.930691963731917, 37.672032237282281],
              [126.931599027487962, 37.671744467392458],
              [126.932132018136841, 37.671474490489672],
              [126.93277811181963, 37.671465859078893],
              [126.932891460118071, 37.67146592508351],
              [126.932993564717634, 37.671366877504383],
              [126.933231612341345, 37.67134899611839],
              [126.933854887947888, 37.671502520594665],
              [126.934127013628242, 37.671403569447698],
              [126.93458050357097, 37.671295711015226],
              [126.934875393798038, 37.671088654383141],
              [126.936314941524259, 37.671053420074038],
              [126.936586842503729, 37.671206735356741],
              [126.936949665115122, 37.671080798416924],
              [126.936995012004829, 37.671071813509634],
              [126.937267310612583, 37.670765631570383],
              [126.937754803978564, 37.670648769713367],
              [126.938196888164427, 37.67061296898229],
              [126.938809303200074, 37.670207858592917],
              [126.938861873411909, 37.670248150988222],
              [126.939420983380529, 37.670676687856364],
              [126.940502353063081, 37.670935155548079],
              [126.940894210038635, 37.671028833486616],
              [126.941064107266754, 37.671182085980014],
              [126.94177770058846, 37.671804120419068],
              [126.942877035396535, 37.671993876270662],
              [126.943250108264294, 37.673246412348256],
              [126.943317942958416, 37.673471688830858],
              [126.943374506162144, 37.673615872237647],
              [126.943634731908944, 37.674237670827999],
              [126.944143951158424, 37.675364134315437],
              [126.944120759773043, 37.676039851733471],
              [126.944483338047732, 37.676247251081321],
              [126.944584917473492, 37.676823921904749],
              [126.94447884371445, 37.677024336792471],
              [126.944346523201759, 37.677274292282604],
              [126.944319929153835, 37.677304407870359],
              [126.943892697362102, 37.67778762506979],
              [126.943086965669508, 37.67892245233611],
              [126.943040872174194, 37.679877458781135],
              [126.943108775821372, 37.680021647781473],
              [126.943527539482631, 37.680886786467539],
              [126.9441959077175, 37.681499773332845],
              [126.944467773328839, 37.681770196366188],
              [126.944841579770255, 37.68215779397606],
              [126.945045472106216, 37.682374124728575],
              [126.945374026656808, 37.682644572766606],
              [126.94545300264366, 37.683149153818128],
              [126.945531972924258, 37.683662744474752],
              [126.945361623732524, 37.684059090436847],
              [126.945225194633068, 37.68457257845413],
              [126.945225002389961, 37.684586939828023],
              [126.945212911111653, 37.685824921092106],
              [126.946289627126788, 37.686230866758862],
              [126.94711683248758, 37.686780842922573],
              [126.94792160898443, 37.68700645389832],
              [126.948499620066755, 37.687267996437356],
              [126.948873755274121, 37.68725915435023],
              [126.949293112978111, 37.687430525078305],
              [126.950267931555643, 37.687701243693745],
              [126.950642007188904, 37.687782492950255],
              [126.951390100264845, 37.688035084850483],
              [126.951752522348499, 37.688593839559942],
              [126.952069653607396, 37.689071486860037],
              [126.952273514359149, 37.689395921205438],
              [126.95233001174023, 37.689684255026393],
              [126.952352591780397, 37.689828419558218],
              [126.95236391156763, 37.689855453360906],
              [126.952295593970987, 37.690296899881403],
              [126.952420231440385, 37.690414077859742],
              [126.952952628203406, 37.691144083469311],
              [126.953122338445439, 37.691693744464281],
              [126.953450963421531, 37.691964169622608],
              [126.953733990193811, 37.69262199208314],
              [126.954041465242369, 37.692731358772576],
              [126.954266758646327, 37.692811410263907],
              [126.955071582663749, 37.693100040091203],
              [126.955888104454829, 37.692812048800121],
              [126.956353069683075, 37.692641043689598],
              [126.956455150362032, 37.692578014910424],
              [126.956852144150133, 37.692307875230348],
              [126.957362568135579, 37.691947679814746],
              [126.95789563658704, 37.691632539005838],
              [126.959154123386469, 37.69166903661138],
              [126.959879658898075, 37.691831469489166],
              [126.960582536519937, 37.691966860961593],
              [126.960877284006273, 37.692039040446716],
              [126.961897581090227, 37.692264629961556],
              [126.962203521576086, 37.692616110305984],
              [126.962645602200013, 37.692814470060625],
              [126.962736288102633, 37.692850538682386],
              [126.963099060484637, 37.692940754338558],
              [126.963858690634524, 37.692986047840215],
              [126.964040095866693, 37.692995115326532],
              [126.965128638272731, 37.692824272162966],
              [126.96512864267595, 37.692815262473921],
              [126.965124585787663, 37.692787673549738],
              [126.96510604140741, 37.69266209075775],
              [126.965083413779922, 37.692562977170184],
              [126.965094756233313, 37.692553970983504],
              [126.96511744553645, 37.692526948917262],
              [126.965094809120188, 37.692445854708922],
              [126.965128884848625, 37.692319729554356],
              [126.965134555367612, 37.692316631970357],
              [126.965162907673971, 37.692301720666464],
              [126.965208272880034, 37.692274705570604],
              [126.965242286886976, 37.692274716039492],
              [126.965287634512293, 37.692283739672682],
              [126.965321644143629, 37.692292759808559],
              [126.965332982148681, 37.692292763288414],
              [126.96537833416896, 37.692292777196791],
              [126.965389676545584, 37.69228377098127],
              [126.965446379658104, 37.692256759266954],
              [126.965514433794283, 37.69220272191756],
              [126.965571158547661, 37.692130661692183],
              [126.965593860589266, 37.692076610461491],
              [126.965627909247203, 37.692004543296633],
              [126.965786826611293, 37.69161717480327],
              [126.966410687350347, 37.691031732165342],
              [126.966807573810897, 37.690896704261426],
              [126.967533352914302, 37.690545537614398],
              [126.968213705929074, 37.690347518462353],
              [126.968599438967402, 37.68978001600717],
              [126.968735514240862, 37.689725995744617],
              [126.969472548376856, 37.689518975349181],
              [126.970425014475737, 37.689257949166219],
              [126.970799186867353, 37.689176959970752],
              [126.971332156756375, 37.688915816415573],
              [126.971570298431786, 37.688780731686776],
              [126.9717404037318, 37.688672658403256],
              [126.972371747150035, 37.6882521713977],
              [126.972443508844307, 37.688204329599493],
              [126.973135240332482, 37.687808071414914],
              [126.973357425154319, 37.68769888209205],
              [126.97337336838693, 37.687691002412286],
              [126.973377269696698, 37.68768762470642],
              [126.974280632926735, 37.686925391042699],
              [126.974325455618498, 37.686906823386749],
              [126.974949626723742, 37.686646242974987],
              [126.975335102379091, 37.686610290396807],
              [126.976287446660351, 37.686538420411985],
              [126.976474692243997, 37.686500169101507],
              [126.97663892177961, 37.686466417489797],
              [126.976741651168808, 37.686489242671669],
              [126.977047028472526, 37.686556599893891],
              [126.979121802436183, 37.686295730902984],
              [126.98007421688861, 37.685953539315229],
              [126.982409846296875, 37.685179106578268],
              [126.982459804853576, 37.685147580704708],
              [126.98318087692266, 37.684692705048725],
              [126.98396323850362, 37.684188280716299],
              [126.984110639720981, 37.68408919582366],
              [126.98480232352874, 37.683458616577745],
              [126.984849626699727, 37.683429062431323],
              [126.986131004348508, 37.682629336903126],
              [126.987024579371479, 37.682071415909235],
              [126.98852108371392, 37.681540017609031],
              [126.989306702746475, 37.681280504865626],
              [126.989938202877909, 37.681071660463473],
              [126.98995397372542, 37.681058426748884],
              [126.990629802758932, 37.680486095250593],
              [126.990788601864708, 37.679846420372044],
              [126.99085873996701, 37.679835443896742],
              [126.991407668764353, 37.679749899995706],
              [126.992197844217714, 37.679626928498386],
              [126.992433489013067, 37.679148305964141],
              [126.992924693201331, 37.678821740725958],
              [126.993247534183524, 37.67766063700698],
              [126.993120067076561, 37.67722534165366],
              [126.993266923381512, 37.677001523671571],
              [126.993072495964242, 37.676582558127116],
              [126.993156848413776, 37.676141925946652],
              [126.993142001536967, 37.675916402672001],
              [126.993125359183907, 37.675831935391308],
              [126.993199755264754, 37.675719886811841],
              [126.993809970163099, 37.675252269888283],
              [126.993981634598725, 37.674907091652912],
              [126.993958441859519, 37.674801225974363],
              [126.993954022704557, 37.674785179381914],
              [126.99379608864092, 37.674226287254868],
              [126.993543710991503, 37.674102945410361],
              [126.99407413027177, 37.672761090303183],
              [126.993802706385168, 37.670197247830693],
              [126.994350875660245, 37.669810990426853],
              [126.994357631870528, 37.669566881306295],
              [126.994086720716425, 37.669039795502329],
              [126.993830672751074, 37.668622799868814],
              [126.99362901819849, 37.668019702340715],
              [126.993625310954783, 37.667921432976314],
              [126.993560865336278, 37.66780402286107],
              [126.993961330004382, 37.667338921908609],
              [126.994129424310827, 37.667030069995349],
              [126.994017524306983, 37.666783133236386],
              [126.993261388531678, 37.666189566646345],
              [126.993123275643654, 37.666056384044268],
              [126.992749314766925, 37.665532666121742],
              [126.992434816239225, 37.665383423114328],
              [126.992224795089598, 37.665231646800727],
              [126.991238779479346, 37.664993653940513],
              [126.989997447534762, 37.664499409337637],
              [126.989529210836253, 37.664526112564261],
              [126.989453421194256, 37.664530042088536],
              [126.988818558501478, 37.664468312804715],
              [126.988268532839754, 37.664391110941764],
              [126.988033260021609, 37.663989024851027],
              [126.987802227278522, 37.663593696058797],
              [126.987751033651207, 37.662732413339747],
              [126.987365543200781, 37.661618259540404],
              [126.98728399469816, 37.661170294500373],
              [126.987225259555871, 37.660847621583791],
              [126.987277020338382, 37.660600148299551],
              [126.986544304536068, 37.659485102057452],
              [126.986509974001365, 37.659442860022246],
              [126.986020797814049, 37.658943323656509],
              [126.98461709266266, 37.658228541068567],
              [126.984335483988161, 37.65773381134904],
              [126.983741846301299, 37.657329411058122],
              [126.983406319921812, 37.657233910767673],
              [126.983010266150657, 37.657003542163444],
              [126.982857062842783, 37.656449697924202],
              [126.981193637387662, 37.656487714442207],
              [126.979658388392835, 37.656038646387231],
              [126.979944355259249, 37.654815058089525],
              [126.980431753818323, 37.653787187804937],
              [126.980805714499738, 37.653176284807301],
              [126.981048064522597, 37.652712613190076],
              [126.981676732643749, 37.652316291490642],
              [126.98187379028046, 37.651688443753244],
              [126.982298494632175, 37.651254081379449],
              [126.982495566127895, 37.650540369517806],
              [126.983937206241194, 37.649610056527273],
              [126.984019790980824, 37.649317530989002],
              [126.983883982839174, 37.648600676371018],
              [126.983980930447558, 37.64818201632356],
              [126.984603215912287, 37.647612519184001],
              [126.98471307396693, 37.647251865155738],
              [126.984678783063544, 37.6469632595946],
              [126.984812835786826, 37.646836871701346],
              [126.984763098222672, 37.646759993360291],
              [126.984929150789299, 37.646066832749945],
              [126.985100562065028, 37.645929457847281],
              [126.985483316757211, 37.646005525868837],
              [126.985579439260931, 37.64610267314945],
              [126.985718245884669, 37.646092844152975],
              [126.98572338657182, 37.64605623817517],
              [126.985680545855445, 37.645994857926155],
              [126.985563525899906, 37.645965560426795],
              [126.985413406060843, 37.645946673582834],
              [126.985257966605417, 37.645848950387595],
              [126.985106101817976, 37.645728423772432],
              [126.984948365118044, 37.645672370002046],
              [126.984858267438128, 37.645595783331807],
              [126.984795437161026, 37.645445978110914],
              [126.984820597660587, 37.645368840077325],
              [126.984643208613903, 37.645337559962989],
              [126.984193679686655, 37.644603487305503],
              [126.984190185243364, 37.644428075673879],
              [126.983902355133793, 37.644265568951589],
              [126.983811014519972, 37.644186161221263],
              [126.983726078102862, 37.644050164046],
              [126.983472750736709, 37.643940873142988],
              [126.983247435731002, 37.643683501516591],
              [126.983690900013698, 37.643039371031655],
              [126.983874601737227, 37.642560187119649],
              [126.983853935890536, 37.642365068483777],
              [126.983830975089106, 37.64214883960539],
              [126.984002573428427, 37.641957119306085],
              [126.984257046096673, 37.641644914249007],
              [126.984956370028428, 37.641464251493694],
              [126.985134458302085, 37.641520587423813],
              [126.985224372901115, 37.641636042056952],
              [126.985329729927244, 37.641547643692306],
              [126.985268158802128, 37.64135026720686],
              [126.985657415011772, 37.640740187534647],
              [126.986132813959799, 37.640428007863157],
              [126.985954769708783, 37.640223859120887],
              [126.985897060717861, 37.640192875897526],
              [126.985676506802193, 37.64004446455062],
              [126.985533719585035, 37.639701236031136],
              [126.985428645033821, 37.639281132073876],
              [126.985407794322896, 37.639087986715666],
              [126.98544519486093, 37.638875415216269],
              [126.985421154789535, 37.638761384239018],
              [126.985394102075418, 37.638590194834912],
              [126.985294119583088, 37.638418436806134],
              [126.985192527729708, 37.638302980547707],
              [126.985233831271415, 37.638065921058171],
              [126.984960206967585, 37.637807131232506],
              [126.985045060097846, 37.637528686982918],
              [126.985084579903102, 37.637314421999207],
              [126.984936976486836, 37.637164902125591],
              [126.984855968585109, 37.636814626629842],
              [126.984744658959855, 37.636648218291114],
              [126.984461624543741, 37.63654765562277],
              [126.984201093352368, 37.636340672085247],
              [126.985378025393402, 37.635970041560377],
              [126.985907180645242, 37.63579694482722],
              [126.985917979287066, 37.635788783396244],
              [126.985460522650499, 37.635934851614209],
              [126.984414070229249, 37.636269760165199],
              [126.984214725024884, 37.636330826398321],
              [126.983959434847108, 37.636438059090992],
              [126.983706646208034, 37.636456887670029],
              [126.98367412206747, 37.636444737574841],
              [126.983586802000261, 37.636412099930112],
              [126.983546819538049, 37.636320590543676],
              [126.983131929721168, 37.636105418163787],
              [126.982977597954715, 37.635975878448455],
              [126.982787871530974, 37.635809446881026],
              [126.982609078181042, 37.635824347562355],
              [126.982515971419545, 37.635786032033714],
              [126.982515099125351, 37.635741271312988],
              [126.982289799112181, 37.635534568500653],
              [126.982196903599501, 37.635382513301202],
              [126.982160641899114, 37.635281435652907],
              [126.982041002577105, 37.635156963878103],
              [126.981994631669465, 37.635156956213443],
              [126.981679418263539, 37.634935317106205],
              [126.98151360362391, 37.634721027527199],
              [126.981415678261058, 37.634829686849969],
              [126.981255297741384, 37.634842056870546],
              [126.981122355136506, 37.634860044512699],
              [126.980751497741878, 37.634836896759964],
              [126.980606885509488, 37.634815752291985],
              [126.980380155645065, 37.634685916993973],
              [126.98019060998466, 37.634552988620854],
              [126.980049735926087, 37.634417816361953],
              [126.97965239185244, 37.634206581155823],
              [126.979198209446849, 37.634078665331721],
              [126.978905774798292, 37.63406256322493],
              [126.978457927239646, 37.63404558319953],
              [126.977804765229735, 37.633910586309561],
              [126.977610276478245, 37.633773994809928],
              [126.977331710225258, 37.633583038776401],
              [126.977194958072644, 37.633336088575049],
              [126.977006019600111, 37.632968900833355],
              [126.976728365779621, 37.632705027364246],
              [126.976224218107646, 37.632231618407857],
              [126.976100679389518, 37.632190489223852],
              [126.975828486420582, 37.632009108365061],
              [126.975764972969884, 37.631922096041016],
              [126.975579873509815, 37.631752554416465],
              [126.975369074857298, 37.631698169873694],
              [126.975388355570004, 37.631733087061114],
              [126.974628909958625, 37.631912832812361],
              [126.974453458665053, 37.631980086775215],
              [126.973672112297095, 37.63203114359753],
              [126.973029771497181, 37.632374767564016],
              [126.971327559251918, 37.631618657634455],
              [126.970582747157792, 37.631484993747421],
              [126.96938882952486, 37.631015895541438],
              [126.968682098259464, 37.630840568759346],
              [126.968235548900395, 37.630730073762052],
              [126.967653533535241, 37.630743422829489],
              [126.967084331169801, 37.63070657924807],
              [126.9668016107005, 37.630688269603446],
              [126.966511999098856, 37.630725637715031],
              [126.966047311865708, 37.630815587782259],
              [126.965228476696453, 37.630768037401104],
              [126.96433141659648, 37.630360065259204],
              [126.963767222288482, 37.629793116436552],
              [126.961439668838366, 37.629959318164019],
              [126.960489731461564, 37.629826088302799],
              [126.960115737374295, 37.62977556592385],
              [126.958813961056222, 37.629589275095711],
              [126.958418417079713, 37.629469751590285],
              [126.958759437618824, 37.62958838140046],
              [126.959822439149789, 37.629761155101249],
              [126.960604373710638, 37.630718844792341],
              [126.961081466070183, 37.631266624022018],
              [126.961619135261586, 37.631887941612071],
              [126.962260271519597, 37.632396948172719],
              [126.963328787266136, 37.633245128421684],
              [126.963290904135135, 37.633375586806473],
              [126.96292222311348, 37.63464554856067],
              [126.962727978612392, 37.635242905313582],
              [126.962540777750746, 37.63581885677948],
              [126.961604340989396, 37.637117357123401],
              [126.961604098893844, 37.637188696447872],
              [126.961602751780831, 37.637610182305536],
              [126.961602892015051, 37.637877502379972],
              [126.961602956898361, 37.637989178560566],
              [126.961305453218856, 37.638296653216216],
              [126.961133075868389, 37.638474861727722],
              [126.960861868250504, 37.638755477092083],
              [126.960562290874648, 37.639671586283349],
              [126.960510255233501, 37.639830897015678],
              [126.960449535058515, 37.639897268903098],
              [126.96013162102355, 37.640538437287447],
              [126.959971238621122, 37.641461702149897],
              [126.959912595909188, 37.64151762314134],
              [126.959267780883877, 37.642132734158324],
              [126.959316562040613, 37.642666661451123],
              [126.959365333090716, 37.643199651655912],
              [126.959019704441999, 37.643879044215865],
              [126.959121089073065, 37.644341038386038],
              [126.959122230499887, 37.644346273469594],
              [126.958906540396711, 37.644760365627064],
              [126.959137768330024, 37.645716321755131],
              [126.959145884871475, 37.645749877026979],
              [126.959096660756373, 37.645834073588318],
              [126.958919917922216, 37.64613660276958],
              [126.959013936167423, 37.646704395821139],
              [126.959027012554827, 37.646783380098057],
              [126.958544628656284, 37.647427042859341],
              [126.958377341907294, 37.647650297712609],
              [126.958332122352516, 37.647683680346951],
              [126.957839159643072, 37.648047691351813],
              [126.957854688543065, 37.648445532010349],
              [126.957842470085126, 37.648470475508631],
              [126.957562260171585, 37.649042455030191],
              [126.957648434714685, 37.649333069801649],
              [126.957789459340447, 37.649808639103227],
              [126.957764676551491, 37.65002229628336],
              [126.957684985131891, 37.650710296681609],
              [126.95746014687218, 37.65187190359044],
              [126.957108103069288, 37.652837229629263],
              [126.956642297825425, 37.653049629428097],
              [126.956504694750066, 37.653121646002461],
              [126.956412354606613, 37.653170047153651],
              [126.956532462837444, 37.653170426378338],
              [126.956508482547548, 37.653191950536431],
              [126.956468674039371, 37.65322778514053],
              [126.956392328410544, 37.653267732225942],
              [126.956365788876312, 37.653267442768374],
              [126.956348233873371, 37.6532888342064],
              [126.956298289168387, 37.65329585168714],
              [126.956261873511522, 37.653305243902281],
              [126.956249304245418, 37.65330852763865],
              [126.95616326190914, 37.653360931339215],
              [126.956087118291919, 37.653422213393632],
              [126.95600956865421, 37.653484666123482],
              [126.956020276634376, 37.65350464487112],
              [126.956029196191949, 37.653521271300896],
              [126.955963655353116, 37.653570241033002],
              [126.955950515156616, 37.653580507073549],
              [126.955931944228624, 37.653595095694357],
              [126.955851003056281, 37.653662313165306],
              [126.955802359407798, 37.653690819193876],
              [126.955713974166841, 37.653682009411455],
              [126.955591387978785, 37.653747759955891],
              [126.955534855841123, 37.65377814584005],
              [126.955524536525587, 37.653789593209588],
              [126.955467213116577, 37.653853377896297],
              [126.955462780145368, 37.653856772841458],
              [126.955458664552083, 37.653860041773903],
              [126.955457066022149, 37.653861167368483],
              [126.955444195160226, 37.653875505864306],
              [126.955423975984047, 37.65389804036014],
              [126.955406616518431, 37.653950731603409],
              [126.955402472469473, 37.653963352643132],
              [126.955398903555064, 37.653998831642987],
              [126.955368249673896, 37.654035984872742],
              [126.95535335517971, 37.654079054658517],
              [126.955335234643812, 37.654171118197702],
              [126.955321365189164, 37.654224108113951],
              [126.955317281472674, 37.65423075570979],
              [126.955290544359556, 37.654274433510366],
              [126.9552561696552, 37.654299259913891],
              [126.955235921192454, 37.654313928853121],
              [126.955182439773466, 37.654339612673006],
              [126.955044349064366, 37.654406104359275],
              [126.954897698210956, 37.65446094289473],
              [126.954801031499017, 37.654483185761293],
              [126.95477803502672, 37.654488492403814],
              [126.954600432801669, 37.654529452297467],
              [126.954583544321096, 37.654534869448149],
              [126.954552019743531, 37.654532045859547],
              [126.954351545993802, 37.654595313418007],
              [126.954262601429207, 37.654680870435165],
              [126.954219348475576, 37.654766445713257],
              [126.954037237187521, 37.654981975834616],
              [126.954032191026812, 37.655076089633532],
              [126.954194123771344, 37.655211273899553],
              [126.954177926289518, 37.655216835424667],
              [126.953945505233833, 37.655296577298486],
              [126.953890619145554, 37.655266381505619],
              [126.953736213247623, 37.655181546345403],
              [126.953358847387918, 37.654968249275925],
              [126.953275669549043, 37.65494848394011],
              [126.953210495991385, 37.654933077628421],
              [126.952625016998851, 37.654924682593403],
              [126.952314573519615, 37.654916363653086],
              [126.951860000386546, 37.654864925723842],
              [126.95171836393132, 37.65488968787264],
              [126.951376053619839, 37.654949682936724],
              [126.951309797186283, 37.654994712505967],
              [126.951053096241864, 37.655197033987648],
              [126.95090052401298, 37.655354080599857],
              [126.950781098502233, 37.655477733128244],
              [126.950772706607012, 37.655486423924792],
              [126.950690333925138, 37.655532500348265],
              [126.950404851526528, 37.65569229099863],
              [126.950104037128284, 37.655799808730471],
              [126.949791161036018, 37.655911762209669],
              [126.949633573747988, 37.656087932655396],
              [126.949571774805136, 37.656157055261339],
              [126.949552483387208, 37.656178318775659],
              [126.949490276650508, 37.656246972651367],
              [126.949379403927679, 37.656450958407397],
              [126.949344874479834, 37.656514533926639],
              [126.949228213377808, 37.65672986925],
              [126.949213838745763, 37.656753008913192],
              [126.949203083237322, 37.656770401962092],
              [126.949126241125583, 37.656812713661651],
              [126.948832816316568, 37.656974361959634],
              [126.948349003231925, 37.657038339316543],
              [126.94828637258135, 37.657047392893382],
              [126.948236333711066, 37.657054731263855],
              [126.948184676918174, 37.65705835686672],
              [126.948128010750111, 37.657062457696284],
              [126.948011557623261, 37.657092434411474],
              [126.947882432047678, 37.657125810933842],
              [126.947757842361256, 37.657280847963591],
              [126.947813350468493, 37.657421560407037],
              [126.947992142656062, 37.657711061680189],
              [126.948038340819636, 37.657892439765611],
              [126.948039132422394, 37.658005395278956],
              [126.947915445250032, 37.658259134596378],
              [126.947939463234064, 37.658380119326402],
              [126.947942534689957, 37.65839553639141],
              [126.948001905219726, 37.658572370531921],
              [126.947983439965881, 37.658641070449498],
              [126.947967038435948, 37.658708140536916],
              [126.947902303124792, 37.658773684035438],
              [126.947807581603428, 37.658869576678924],
              [126.947755343987666, 37.65892252115566],
              [126.947693295313258, 37.659017933055786],
              [126.947677604445374, 37.659042035964212],
              [126.947564852161619, 37.659215385935369],
              [126.947537414797182, 37.659200056822101],
              [126.947510962942104, 37.659185376855],
              [126.947084083904372, 37.659014734346087],
              [126.946584448172715, 37.658815117136633],
              [126.946425921659539, 37.658756101472051],
              [126.946043347011639, 37.658632191384214],
              [126.94568915554207, 37.658517528476267],
              [126.945205496127556, 37.658376233037252],
              [126.945022611333115, 37.65832518648466],
              [126.944882383774512, 37.658288782949136],
              [126.944728196984045, 37.658248867737257],
              [126.94439644847975, 37.658163583506777],
              [126.944293900276762, 37.658145649464515],
              [126.944123228416657, 37.658115843404509],
              [126.943338294903654, 37.65805858037254],
              [126.942697217611084, 37.657919458342498],
              [126.942437968893202, 37.657833790281906],
              [126.942099956176634, 37.657722160970884],
              [126.942069824515627, 37.657705297569038],
              [126.941801472854038, 37.657554158720757],
              [126.941573096516123, 37.657426473923749],
              [126.941443452117454, 37.657354023687404],
              [126.941021500538383, 37.657118428477069],
              [126.940848913980261, 37.6570228455587],
              [126.940769190807742, 37.656978719849171],
              [126.940335210641763, 37.656767847725654],
              [126.940332446892882, 37.656766134440645],
              [126.940186061103859, 37.65667282558038],
              [126.940128159297174, 37.65663592758456],
              [126.94006767447749, 37.656584360349321],
              [126.93990472481866, 37.656445498244416],
              [126.93990438668115, 37.656443272660674],
              [126.939666230845461, 37.656241059435303],
              [126.939398464151907, 37.655944047603938],
              [126.939285109896531, 37.655816445888924],
              [126.939036245762807, 37.655536471607171],
              [126.938809816837576, 37.655275627399604],
              [126.938459653755871, 37.654771174176858],
              [126.938261612601124, 37.654354592533458],
              [126.938151060267955, 37.65412206366269],
              [126.938121572720618, 37.654070115626247],
              [126.937923330481638, 37.653720971234499],
              [126.937837033082317, 37.653591977143847],
              [126.937747238786798, 37.653457809500587],
              [126.93773993889512, 37.653446921776229],
              [126.937622896851593, 37.653160798871568],
              [126.937569801430769, 37.653034192116465],
              [126.937537095975827, 37.652956348156614],
              [126.937425068731145, 37.652691337598995],
              [126.937306161910101, 37.652520637257247],
              [126.937163318141714, 37.652315704698346],
              [126.937060732484383, 37.652221316590058],
              [126.936766094884646, 37.6519694227635],
              [126.936659457745336, 37.651883068776272],
              [126.936454733535342, 37.651717473919881],
              [126.936281688602094, 37.651583925897775],
              [126.936136761352103, 37.651485864603082],
              [126.935705689018633, 37.651202655590978],
              [126.935267072376703, 37.651060506467779],
              [126.934947471446534, 37.650985122137854],
              [126.934735896199683, 37.65093523267781],
              [126.934716760703324, 37.650931023298632],
              [126.934621117160148, 37.650910075482685],
              [126.93459049362427, 37.65090330079795],
              [126.934537031448841, 37.650889755824949],
              [126.934241772276152, 37.650814590624158],
              [126.933889720956657, 37.650725093661102],
              [126.933349176200011, 37.650614404053094],
              [126.933344440455485, 37.650613428263064],
              [126.932823201184164, 37.650496152258299],
              [126.932797449658096, 37.650490389037159],
              [126.932221399730423, 37.650386692169938],
              [126.932215072845011, 37.650378624723153],
              [126.932206369091674, 37.650367573650819],
              [126.932199249892065, 37.650358541693926],
              [126.932196114717826, 37.650354575559724],
              [126.932185713245858, 37.650341352137396],
              [126.932157610001994, 37.650305675009328],
              [126.931900565590965, 37.650230652542042],
              [126.931875014091332, 37.650229285997682],
              [126.931853099742028, 37.650228236937075],
              [126.931593216079392, 37.650293548061171],
              [126.931536861959842, 37.650307741047691],
              [126.931544754557407, 37.65031364711853],
              [126.931431070464555, 37.650331319840419],
              [126.930862931207699, 37.650301419937698],
              [126.93044866526462, 37.650246824261778],
              [126.930284728995261, 37.650225047919179],
              [126.930042487316015, 37.650172104239616],
              [126.930003095585775, 37.650163512090224],
              [126.929876171169852, 37.65011548516587],
              [126.929709850433724, 37.650052126557767],
              [126.929699372545315, 37.650048155887475],
              [126.92948825588914, 37.649940477732017],
              [126.929285940121858, 37.649837273424538],
              [126.929281092595843, 37.649834819803019],
              [126.929259159576404, 37.649818111294707],
              [126.929188617611246, 37.649762883315844],
              [126.92911805977144, 37.649665750912433],
              [126.9289780177496, 37.649568170321956],
              [126.928954634032763, 37.649551884322847],
              [126.928863691940208, 37.64948903936407],
              [126.928645821226098, 37.649338333948371],
              [126.928453916348985, 37.649206060152366],
              [126.928201904591305, 37.649033421033685],
              [126.928166744132412, 37.649009406196519],
              [126.927992076854053, 37.648887773871685],
              [126.92798809122246, 37.648884708070405],
              [126.9279728280233, 37.648873012902065],
              [126.927972001586681, 37.648872246557559],
              [126.927936531998711, 37.648840122677548],
              [126.927858426557592, 37.648769383386046],
              [126.927591782893543, 37.648522745691444],
              [126.927587548821108, 37.648518859832869],
              [126.927574495433163, 37.648507102928825],
              [126.927553437870557, 37.648488205277708],
              [126.927421647277455, 37.648369860504594],
              [126.927376046626364, 37.648327684210656],
              [126.927219795601999, 37.648183294644681],
              [126.927173448526744, 37.648139901483837],
              [126.927146471234551, 37.648114657158992],
              [126.927134007105494, 37.648103008694051],
              [126.927096820884131, 37.648077235354492],
              [126.926898864627987, 37.647940107936066],
              [126.926599835968247, 37.64775634460171],
              [126.926580879696928, 37.647766747842809],
              [126.926489617100458, 37.647699999607866],
              [126.926141235509746, 37.647445215819637],
              [126.925899575217116, 37.647277686882838],
              [126.925809487841377, 37.647215299593398],
              [126.925707249911767, 37.647145426327278],
              [126.925480592293539, 37.646990645963925],
              [126.925281526290163, 37.646858560559089],
              [126.925236866730273, 37.646828952651141],
              [126.925174157279343, 37.646787791545222],
              [126.924844893991008, 37.646571839319094],
              [126.924791994741284, 37.646533513448311],
              [126.924397267491983, 37.646280424189023],
              [126.924243584120603, 37.646182874058873],
              [126.924122909184177, 37.646106302088981],
              [126.924081900686247, 37.646097004135953],
              [126.923975221887318, 37.646072923055577],
              [126.923680153795971, 37.646006002460311],
              [126.92339534647806, 37.645944430709676],
              [126.92335565906609, 37.645939728356851],
              [126.923122995523258, 37.645912175300936],
              [126.923078788321874, 37.645906307592355],
              [126.922862329425129, 37.645877629565263],
              [126.92228008008469, 37.645832470441398],
              [126.922141751438829, 37.645822331570656],
              [126.921846229625501, 37.645800770228348],
              [126.92166200999587, 37.645791401664226],
              [126.921470856120138, 37.64578181187354],
              [126.920630815688327, 37.645676563518784],
              [126.91987103460221, 37.645581473705064],
              [126.919525351699633, 37.645529734173572],
              [126.919153306537595, 37.645474164098587],
              [126.918190751313418, 37.645359541728638],
              [126.918116249443429, 37.64535067753846],
              [126.917647271495966, 37.645294439505591],
              [126.917341504368892, 37.645257777255203],
              [126.917296309392796, 37.645254420398444],
              [126.915806123717601, 37.645143770466781],
              [126.91580612533258, 37.645142364945158],
              [126.915189327581544, 37.645116291466501],
              [126.91460674563109, 37.644908639217121],
              [126.913720823284947, 37.644755655217871],
              [126.913457479004947, 37.644952484422461],
              [126.913398608804158, 37.644996498201202],
              [126.913363229005995, 37.645000733387747],
              [126.913285703789029, 37.645010144694417],
              [126.913045656894482, 37.645041850474179],
              [126.912931707568205, 37.645056964436478],
              [126.912900543558152, 37.645060544937031],
              [126.912881220810561, 37.645063656808844],
              [126.912679754133492, 37.64509612061304],
              [126.912563233130967, 37.64512025105391],
              [126.912504193795414, 37.645134532044814],
              [126.912327744226218, 37.645177330296477],
              [126.912141684256554, 37.645268782723839],
              [126.912019272746832, 37.6453290373058],
              [126.911945589596982, 37.645365579035975],
              [126.911854310184708, 37.645439281621456],
              [126.911786441240849, 37.645493288586458],
              [126.911709519819993, 37.645546459723271],
              [126.911673706957856, 37.645571281376036],
              [126.91131376059117, 37.645822379032687],
              [126.911210586107188, 37.645865024384968],
              [126.911057734276213, 37.645928290946635],
              [126.911041625007869, 37.645934873738042],
              [126.911039867826432, 37.645935602181041],
              [126.911033417131662, 37.645938390259403],
              [126.911005471640024, 37.645950495960022],
              [126.910905208770089, 37.645993215355922],
              [126.910863228355126, 37.646011202625381],
              [126.91072609174482, 37.646102600290341],
              [126.910602585609894, 37.64618500754905],
              [126.910558608281676, 37.646214318438325],
              [126.910496339079941, 37.646255868472998],
              [126.910435894287843, 37.646278706612598],
              [126.910436797599061, 37.646281302120698],
              [126.9104385138927, 37.646286231784103],
              [126.910304553373663, 37.646383271515489],
              [126.910139038554973, 37.646505622099596],
              [126.909966580773244, 37.64666203295937],
              [126.909965517036525, 37.64666090591404],
              [126.909857535829033, 37.646759217644899],
              [126.909727263656976, 37.646877910027307],
              [126.909582102790125, 37.646949983181983],
              [126.909491500337325, 37.646995024403566],
              [126.909237456443165, 37.64712349432321],
              [126.908947319238635, 37.647263278662521],
              [126.908635408729566, 37.647416199390484],
              [126.908623563251723, 37.647410631043549],
              [126.90861282745557, 37.647405685242546],
              [126.908590897923759, 37.647390468510217],
              [126.908577326542982, 37.647378673056636],
              [126.908538269750338, 37.64734999125232],
              [126.908513285253278, 37.647331231262903],
              [126.908413664167881, 37.647256587931281],
              [126.90826360213795, 37.647340998957993],
              [126.908123234664046, 37.647420029632364],
              [126.908047262548081, 37.647462495521083],
              [126.907776272761851, 37.647610319832779],
              [126.907659435740442, 37.647674097107327],
              [126.907620978659622, 37.647700248859934],
              [126.907552228206228, 37.647748342263327],
              [126.907500579449305, 37.647785105972105],
              [126.907300629770063, 37.647927615958686],
              [126.907225662358258, 37.647981254214656],
              [126.907109299243089, 37.648063690524239],
              [126.906922927366608, 37.64816699125862],
              [126.906642869594563, 37.648322265753315],
              [126.906522208555231, 37.648388615607573],
              [126.906486794996965, 37.648408886066882],
              [126.906437785081906, 37.648437056124195],
              [126.906172067132729, 37.648586322532275],
              [126.906094153340746, 37.648630128037851],
              [126.906029312571391, 37.648658798674539],
              [126.905926919206166, 37.64870544935507],
              [126.905540531366128, 37.64888983562949],
              [126.905164543551692, 37.648969211423108],
              [126.904986719899995, 37.649082850250032],
              [126.904802022979382, 37.649201015078404],
              [126.904805680268467, 37.649168195540568],
              [126.904810162926495, 37.649128042738674],
              [126.904822390492143, 37.649118574499433],
              [126.905015637968589, 37.648968801401871],
              [126.905228493513064, 37.648805961824337],
              [126.905341036355665, 37.648718487814513],
              [126.905353502883884, 37.648708136758231],
              [126.905414508369375, 37.648657416497599],
              [126.905625158206817, 37.648481357086283],
              [126.905648346390223, 37.648460410230129],
              [126.905880760748516, 37.648250419279165],
              [126.905949100634558, 37.648187901902517],
              [126.906096636877649, 37.648052874979967],
              [126.906204931200122, 37.647954296677106],
              [126.90622233359494, 37.647938453563597],
              [126.90623609432545, 37.647925995174852],
              [126.906323298502571, 37.647847004956731],
              [126.906348712766018, 37.647821708044084],
              [126.906350459855901, 37.647820078687417],
              [126.906385061470829, 37.647787671469651],
              [126.90644442531223, 37.647734723933922],
              [126.906479399364585, 37.647703425188212],
              [126.906622000953277, 37.647571276743435],
              [126.906796185234512, 37.647409844743521],
              [126.906973908383094, 37.647245018638884],
              [126.907237309984382, 37.646995884377546],
              [126.907291560217786, 37.646944554077585],
              [126.907473403583609, 37.646655567013731],
              [126.907560305761905, 37.64651725536946],
              [126.907705175944855, 37.646289784140762],
              [126.907951141831049, 37.646178429638809],
              [126.908128118890218, 37.646098319936073],
              [126.908166893720917, 37.646080033782027],
              [126.90825096260329, 37.646040286144242],
              [126.908420819828208, 37.645962855279812],
              [126.908484887539359, 37.645933615089263],
              [126.908745634383735, 37.645825135681299],
              [126.908777164238472, 37.645810240307902],
              [126.909216211321294, 37.645547896046679],
              [126.909284064326812, 37.645517487112201],
              [126.909340319479554, 37.645492177611864],
              [126.909386643762701, 37.645461724766285],
              [126.909441393859225, 37.645425593319572],
              [126.909652184801871, 37.645331434457177],
              [126.909725323572232, 37.64531459808321],
              [126.909915018269089, 37.64525534416719],
              [126.910073573315117, 37.645170153740516],
              [126.910116219222886, 37.64514515767069],
              [126.910140722823229, 37.64513073400456],
              [126.9101407384954, 37.645117940155757],
              [126.910140788933376, 37.645076765585856],
              [126.910241493115336, 37.645043138023041],
              [126.910495361542473, 37.644958381118556],
              [126.910589634071414, 37.644930307318347],
              [126.910663628466509, 37.644908200310162],
              [126.910884529338801, 37.644846509192661],
              [126.910965299957525, 37.644823920673112],
              [126.911185189209689, 37.644764444616484],
              [126.911484034022905, 37.644627274182902],
              [126.911775166884354, 37.644495052491912],
              [126.911906380568681, 37.644444751617677],
              [126.912029602402555, 37.644397381717987],
              [126.912279860168795, 37.644315357191154],
              [126.912260631713295, 37.64425874331652],
              [126.912208480029562, 37.644199014171711],
              [126.912175258948011, 37.644141831083736],
              [126.912107195660525, 37.643914850620249],
              [126.912085053793248, 37.643840728535189],
              [126.912066737393531, 37.643779664492769],
              [126.911334406000194, 37.641538756807563],
              [126.91121521092326, 37.641174076402294],
              [126.911202816281403, 37.641136117774685],
              [126.911151476550359, 37.640979146332384],
              [126.910892065878585, 37.640284294231492],
              [126.910841469325007, 37.640150775631533],
              [126.910794204523668, 37.640026134191473],
              [126.910556535056273, 37.639523377246093],
              [126.91009200205265, 37.638509419003135],
              [126.910170312538028, 37.638334627022559],
              [126.910218175877716, 37.638227655010446],
              [126.910258464440005, 37.638098089619767],
              [126.91031596266356, 37.637912974250746],
              [126.91079690794372, 37.636833676881785],
              [126.911210370938321, 37.635905581827842],
              [126.910854458727542, 37.635316601112748],
              [126.909806592658086, 37.634941885552088],
              [126.909122457444255, 37.634332912535783],
              [126.908112670523408, 37.633829822352439],
              [126.907096247596499, 37.633397949495425],
              [126.906202193517757, 37.633220134435561],
              [126.906757397513942, 37.632736305971768],
              [126.906929396430655, 37.631804490274817],
              [126.907294155188637, 37.630867206155671],
              [126.908118271401136, 37.630060638811692],
              [126.908382094220102, 37.62999412088282],
              [126.908770040982603, 37.629327702372422],
              [126.908801843308211, 37.629087841823278],
              [126.908903169671731, 37.627458555464791],
              [126.908610824925063, 37.626265654799973],
              [126.908395753211508, 37.625992380503426],
              [126.907789973401023, 37.625502272811367],
              [126.907439856927397, 37.625094021824495],
              [126.906627146365935, 37.624495625425396],
              [126.906748878923267, 37.624241755210143],
              [126.906999538415832, 37.623936749500743],
              [126.907109014084199, 37.623448623431315],
              [126.907142806106151, 37.623312656624108],
              [126.907100193242954, 37.623007136433358],
              [126.907113110736944, 37.622869468088567],
              [126.907140421312846, 37.622546822127774],
              [126.907117467180598, 37.621941462588779],
              [126.90627821967351, 37.621352052051655],
              [126.905612694430801, 37.620703933814859],
              [126.905557578624354, 37.620623080125625],
              [126.905213970595412, 37.619867950227523],
              [126.905189256898268, 37.619813871257804],
              [126.905223472370324, 37.619219252364097],
              [126.905231089428028, 37.619074543158071],
              [126.905097689820067, 37.619041773709029],
              [126.904917582362032, 37.618997136015054],
              [126.904700324147512, 37.61894289916345],
              [126.904509408800678, 37.618900225124889],
              [126.904274285171056, 37.618831331873807],
              [126.903729054511388, 37.618798220793714],
              [126.903361229532777, 37.618839306446056],
              [126.903340160553299, 37.618306016782455],
              [126.903051608092667, 37.618093766714068],
              [126.903007771942299, 37.617785991313475],
              [126.903125569786326, 37.61730434442245],
              [126.90279862087246, 37.617086430554849],
              [126.902478361123883, 37.616899208781362],
              [126.902473189054561, 37.616665508136947],
              [126.902149010341304, 37.616369605902925],
              [126.90189902777, 37.615899759047437],
              [126.901822599892554, 37.615753564467802],
              [126.901917094002101, 37.615092549717268],
              [126.901893086982369, 37.615055084695946],
              [126.90172886635051, 37.614776200536596],
              [126.901799180389474, 37.614470214828195],
              [126.901734239550379, 37.614069223476257],
              [126.90100071959472, 37.612870854961976],
              [126.900913491642939, 37.612724659531544],
              [126.900961949701696, 37.612350226067996],
              [126.900889920067428, 37.612222054168967],
              [126.900790525650791, 37.612044872525836],
              [126.900304321441482, 37.61119134447091],
              [126.90070526163106, 37.610318852956993],
              [126.900601148353843, 37.609319521448846],
              [126.900675624175861, 37.608942019762338],
              [126.900775750075937, 37.608439249387935],
              [126.900874742409769, 37.608121170169689],
              [126.90124674304694, 37.606778744541216],
              [126.901413861484826, 37.606190716343249],
              [126.901579421225918, 37.605978558246214],
              [126.901637558338948, 37.605905970284574],
              [126.901743384714877, 37.605765281341405],
              [126.901725882487995, 37.605752310421209],
              [126.901708562584176, 37.605738501739168],
              [126.901749645971236, 37.605715444303364],
              [126.901505407797941, 37.605356538575236],
              [126.90182346402166, 37.604763006318635],
              [126.901848600898376, 37.60462168144818],
              [126.902029914391122, 37.604269045785919],
              [126.902108472865208, 37.603741757976216],
              [126.901306837382492, 37.603398986032055],
              [126.900770910866427, 37.603369806460059],
              [126.900152654141962, 37.603116443388828],
              [126.900204539490119, 37.60256772649273],
              [126.899982302196761, 37.602040181368906],
              [126.900293217572496, 37.601232663343218],
              [126.900529020970879, 37.600880635110876],
              [126.901012490145391, 37.600175470048264],
              [126.901016792345573, 37.599873076788668],
              [126.901322686404242, 37.59936653388629],
              [126.901297259509818, 37.599192793744869],
              [126.90126122064153, 37.59848859072445],
              [126.90117137042877, 37.598207515713263],
              [126.901253505724441, 37.597908855490331],
              [126.901094607078193, 37.597524955555329],
              [126.901011880353508, 37.597337093172541],
              [126.901076293993881, 37.596937336602124],
              [126.901201108836787, 37.596746542367583],
              [126.90122470026914, 37.596439381092523],
              [126.901210549612159, 37.596301689770172],
              [126.901628152415739, 37.596000493718407],
              [126.901784259709444, 37.595556891275557],
              [126.901791810705092, 37.595473277358806],
              [126.901829756243487, 37.595141350967886],
              [126.901173393055004, 37.594719584884913],
              [126.900355097992602, 37.594211236060374],
              [126.900020698234769, 37.593831977136581],
              [126.899878091450333, 37.593577047152209],
              [126.899826584585355, 37.593467758480699],
              [126.8997782099717, 37.59338662823491],
              [126.899413910326231, 37.593141921755517],
              [126.899299470114599, 37.592868148798544],
              [126.89896162866323, 37.592678369785411],
              [126.89928240471518, 37.592149310853237],
              [126.899566452405637, 37.59168977526766],
              [126.899696408047603, 37.591362154403456],
              [126.899710355818726, 37.590989384160416],
              [126.899575447980254, 37.590673076262263],
              [126.899750352051626, 37.589938086916547],
              [126.899643836988162, 37.589816920734435],
              [126.899498094940029, 37.589791738482845],
              [126.899484279630542, 37.589809746232973],
              [126.899468742047333, 37.589787208187467],
              [126.898082087425379, 37.589538798113175],
              [126.897962408087182, 37.589090453332879],
              [126.897572667146264, 37.588962837685251],
              [126.896940706172984, 37.588611183787215],
              [126.896859033190708, 37.588566620776895],
              [126.89641421235865, 37.588738259572153],
              [126.895954432180716, 37.588733902980131],
              [126.895687384601487, 37.588798147335488],
              [126.895231217317757, 37.588864464403287],
              [126.894267470364397, 37.58882023731708],
              [126.893974246063678, 37.589001005382158],
              [126.893840575470549, 37.589071277453492],
              [126.893667932560831, 37.589055631917482],
              [126.893327883437806, 37.589067429972573],
              [126.893225904592043, 37.58900397922207],
              [126.892235725134711, 37.588522175124133],
              [126.892052062078591, 37.588548746174347],
              [126.891766733911851, 37.588530740844156],
              [126.891663304441053, 37.588485036789116],
              [126.891558757108044, 37.588483533911337],
              [126.891444134823033, 37.588465407388725],
              [126.890936613178411, 37.588566573120893],
              [126.890711766556464, 37.588679832281628],
              [126.890224544628808, 37.588924603921043],
              [126.889578862604736, 37.589132066771839],
              [126.889253451629955, 37.588827675433116],
              [126.888911367052387, 37.588785399752808],
              [126.888343652836255, 37.588795272198844],
              [126.887608601770722, 37.588667021789192],
              [126.887522316001281, 37.588636530261581],
              [126.887322933085656, 37.588523993770139],
              [126.887305599183634, 37.588523697694754],
              [126.887159279162432, 37.588532845183785],
              [126.887054722340409, 37.588648466311533],
              [126.88689170946256, 37.58881499028405],
              [126.886739859124148, 37.588974488191013],
              [126.886557530456002, 37.589162102976573],
              [126.886257054195582, 37.589326239640179],
              [126.886133819786593, 37.589393413778403],
              [126.885822060243513, 37.589531076382769],
              [126.885754072334038, 37.589565643569998],
              [126.885778418431528, 37.589614933275165],
              [126.885826531492597, 37.5897292973494],
              [126.885835701750295, 37.589751830948153],
              [126.885860200371695, 37.589812383090056],
              [126.885871882500211, 37.589806763382896],
              [126.885880875057239, 37.589936856325728],
              [126.885814578142359, 37.590019853114157],
              [126.885775955492704, 37.590518167727872],
              [126.885735727926246, 37.590670737001751],
              [126.885714116737162, 37.590807268982928],
              [126.885651936027955, 37.590863798787005],
              [126.88563862667047, 37.590889130409849],
              [126.885629928691245, 37.590905168400759],
              [126.885540347663067, 37.590946750941562],
              [126.885489359280925, 37.590970351670066],
              [126.885484533505092, 37.591001034450436],
              [126.885467209314243, 37.591103504378211],
              [126.885467037278687, 37.591104918754318],
              [126.885493049082513, 37.591211936203017],
              [126.885502580477308, 37.591227992100421],
              [126.885533636031568, 37.591280667127364],
              [126.885536397220591, 37.591325439746058],
              [126.885540200024082, 37.591384854381047],
              [126.885561182451454, 37.591427671744576],
              [126.885595391838905, 37.591498378545289],
              [126.885611804831342, 37.591523451032948],
              [126.885713852291701, 37.591664888578954],
              [126.885715265005004, 37.591666583815112],
              [126.885769442225964, 37.591748293147255],
              [126.885899488810836, 37.591854844845251],
              [126.8859950849629, 37.591936594633793],
              [126.88614414588946, 37.59210004379311],
              [126.886175348660245, 37.592175243390038],
              [126.886215093960317, 37.592327890903356],
              [126.886270322144398, 37.592308798927291],
              [126.88640464064872, 37.592517282555349],
              [126.886438882988728, 37.592567428676048],
              [126.886563401011827, 37.592706923224483],
              [126.886620442467049, 37.592885770025809],
              [126.886677515357874, 37.593044335676062],
              [126.886721432241032, 37.59313589133496],
              [126.886719111641128, 37.593150241752262],
              [126.886710092887483, 37.59314657498583],
              [126.886715181082579, 37.593173330147827],
              [126.886762170100411, 37.593225741025968],
              [126.886882647234714, 37.593343274316645],
              [126.886919042738739, 37.593377934469203],
              [126.886955036648956, 37.593438227212516],
              [126.886986979046654, 37.593489785479328],
              [126.887007294522434, 37.593510635937122],
              [126.887170707357342, 37.593666493378365],
              [126.887181661251333, 37.593678333907448],
              [126.887251211563409, 37.593779203330541],
              [126.887317223699242, 37.593883718272394],
              [126.887337685984036, 37.593919777421092],
              [126.887303190828817, 37.593923122737266],
              [126.887036491171841, 37.59385416435493],
              [126.886845671308848, 37.593804992637246],
              [126.886732788171216, 37.593807414682402],
              [126.886683038041227, 37.593827359123644],
              [126.886365446554862, 37.593954025370344],
              [126.886272911558279, 37.593955908214681],
              [126.886093503953134, 37.593959111603205],
              [126.885976373539037, 37.593962096391174],
              [126.885796281749734, 37.593953757055559],
              [126.885720755757646, 37.593939879914743],
              [126.885409992522682, 37.593882704540391],
              [126.885208691141457, 37.593744538703604],
              [126.885188376026136, 37.593731012936381],
              [126.885139689914325, 37.593750948765951],
              [126.88500398796576, 37.593639038837473],
              [126.884856560481069, 37.593444334764605],
              [126.884637615598805, 37.593164254550075],
              [126.884475275327901, 37.592891546047888],
              [126.884334189705768, 37.592719651492729],
              [126.884112383872747, 37.592454208422403],
              [126.883767315856289, 37.592054611636577],
              [126.883575147503507, 37.591852545284112],
              [126.883348794858094, 37.591787844475697],
              [126.882922859908049, 37.591514301981285],
              [126.882808854153211, 37.591448586726862],
              [126.882681588299903, 37.591376659241789],
              [126.882377448688288, 37.591164342067167],
              [126.882323024393983, 37.591126553904097],
              [126.882249542375391, 37.591054409952505],
              [126.882072021098551, 37.590880502669897],
              [126.882030895942719, 37.59081768046498],
              [126.882056060791271, 37.590792640553467],
              [126.881900950869621, 37.590652272301085],
              [126.881886817976095, 37.590639581129714],
              [126.881900992785418, 37.590626080730402],
              [126.881907728728009, 37.590619618488908],
              [126.881986400629984, 37.590540015129783],
              [126.881731232307231, 37.59035843336958],
              [126.881723638473588, 37.590349136514774],
              [126.88127834357266, 37.590125690140404],
              [126.881152313306458, 37.590062213484948],
              [126.881004365787874, 37.589982938083693],
              [126.88085183489062, 37.589888458204257],
              [126.880754989580907, 37.589828110339205],
              [126.880665917462125, 37.589771149044282],
              [126.880597191401904, 37.589714478821087],
              [126.880349137786538, 37.589506160121942],
              [126.880222424057109, 37.589320760549093],
              [126.880044510316964, 37.589066328783865],
              [126.880034452336702, 37.589047740132465],
              [126.880002894161166, 37.588979854505986],
              [126.879900813668414, 37.588763224773686],
              [126.879794805757371, 37.588573908748423],
              [126.879656149121999, 37.58832656254198],
              [126.879627224303576, 37.58827585229276],
              [126.879606748154998, 37.588244575990593],
              [126.879592288073354, 37.588217810821973],
              [126.879539579683808, 37.588102042910165],
              [126.879437349360529, 37.587874717903425],
              [126.879322825978136, 37.587583752533639],
              [126.879263862197064, 37.587188096102061],
              [126.879205081695559, 37.586999107706539],
              [126.879133093909104, 37.586772381347998],
              [126.878871618233219, 37.586562072376537],
              [126.878465228565716, 37.58636934323021],
              [126.877542342891957, 37.586247870855985],
              [126.877265729048545, 37.5855718412935],
              [126.8772471997342, 37.585119635407843],
              [126.877193375925216, 37.584936012028329],
              [126.87708688349997, 37.58482749329405],
              [126.876924966986607, 37.584749053930722],
              [126.876745783697089, 37.584637647068014],
              [126.876537897019134, 37.584440336648143],
              [126.876561750379906, 37.584244676923127],
              [126.876623439826773, 37.584164500462421],
              [126.876696352486363, 37.584149936571436],
              [126.876778791346695, 37.584146077396738],
              [126.876973764092696, 37.584238922561973],
              [126.877164986874362, 37.584238277227414],
              [126.877293707541739, 37.58417365894465],
              [126.877331243799873, 37.584047839809593],
              [126.877250850613279, 37.583774639540906],
              [126.877146751676989, 37.58360982078726],
              [126.876987736999311, 37.583388064814024],
              [126.876995812201585, 37.583216327501177],
              [126.877062108388884, 37.583027190480657],
              [126.877075270897805, 37.582982155085972],
              [126.87709841316871, 37.582892918853204],
              [126.877091058225162, 37.582740878817106],
              [126.877084307134638, 37.582545745277507],
              [126.877049674436776, 37.582206154356676],
              [126.87695832155363, 37.582037123210796],
              [126.876671369260507, 37.581632502801931],
              [126.876625448810927, 37.581375952626352],
              [126.876662787177594, 37.581259990217546],
              [126.876919304798662, 37.581033888584528],
              [126.877161239462708, 37.580855352080789],
              [126.877417917559072, 37.580639953226637],
              [126.877462018246959, 37.580500346780568],
              [126.877476894973697, 37.580383792868396],
              [126.877469582732232, 37.580308048316219],
              [126.877455158100673, 37.580261299022609],
              [126.877389315037178, 37.580179573461386],
              [126.877367492927974, 37.580115355271616],
              [126.877426188225044, 37.580022219005933],
              [126.877543514185135, 37.579888320624903],
              [126.877631664251197, 37.579748481042181],
              [126.8775879983084, 37.579626243498446],
              [126.877522204214117, 37.579515236010678],
              [126.877427281149195, 37.579363653500231],
              [126.877259233794348, 37.579159628657756],
              [126.877127739169651, 37.578984644830633],
              [126.877076569281584, 37.57890294354803],
              [126.876754221622406, 37.578943426995586],
              [126.876387994283846, 37.578983863060692],
              [126.876259255336677, 37.578962895737504],
              [126.876260557997625, 37.578185228995956],
              [126.875195380630743, 37.578274480950526],
              [126.873915249061994, 37.578280699533032],
              [126.873084864358106, 37.578221802588899],
              [126.871443069788313, 37.578092452435939],
              [126.870600529400505, 37.578008468200522],
              [126.869791415567235, 37.577831028933254],
              [126.868799301248487, 37.577612834151623],
              [126.86837936877501, 37.577521136462678],
              [126.867332776561597, 37.577505031181239],
              [126.864941184067163, 37.577472438926698],
              [126.864818344868851, 37.577416552738107],
              [126.864248841951422, 37.577194303381944],
              [126.863387507647403, 37.57685768136183],
              [126.863253707239423, 37.576804591792623],
              [126.862611394571744, 37.576549029863045],
              [126.862025990212587, 37.576319714348088],
              [126.861855064079705, 37.576252785217712],
              [126.861115906161629, 37.575959934839972],
              [126.860362598782984, 37.575663675198868],
              [126.859294167186974, 37.575240902442076],
              [126.859285763468336, 37.57491569026444],
              [126.85835078777059, 37.574730704832959],
              [126.857340314552417, 37.574532384326609],
              [126.855766358794071, 37.574221863771051],
              [126.8548342289061, 37.574037700923938],
              [126.854133557271268, 37.573897175368145],
              [126.853628583749781, 37.573799120202239],
              [126.853631853579685, 37.571790491461343],
              [126.853522341869763, 37.571895931878814],
              [126.85306022993656, 37.572224500214617],
              [126.852924916552311, 37.572319771519567],
              [126.852640103002145, 37.572522702357567],
              [126.852347155714, 37.57272223451308],
              [126.851287257319996, 37.573375232144144],
              [126.84838127671857, 37.575157145592598],
              [126.847296516879013, 37.576002660611231],
              [126.84407141529438, 37.578289718968044],
              [126.84324022562032, 37.57887904148226],
              [126.842411046921853, 37.579261972007451],
              [126.842333703542096, 37.57928297783554],
              [126.841974166552887, 37.579494503408327],
              [126.841830340760467, 37.57958187573508],
              [126.839137119851316, 37.581174351691381],
              [126.838097937808314, 37.581792912684492],
              [126.837149144999799, 37.582563343253625],
              [126.836655477646133, 37.58286139446205],
              [126.836217612428229, 37.583122347098488],
              [126.835815344683539, 37.583365328370498],
              [126.834586907935176, 37.584106630846662],
              [126.834180191536788, 37.584354663738637],
              [126.832955607572288, 37.585095116627066],
              [126.831930098875276, 37.585723490090153],
              [126.831759648921022, 37.585843184151983],
              [126.831301213132818, 37.586033703895531],
              [126.830055150884689, 37.586552498353072],
              [126.828808180835495, 37.587069593424907],
              [126.828373809520812, 37.587251406413145],
              [126.82781176170613, 37.587484836575342],
              [126.827658463423177, 37.58760539683157],
              [126.827449226717107, 37.587738551258688],
              [126.827196761000508, 37.587896130229126],
              [126.826958463960821, 37.588046413616482],
              [126.826746224428163, 37.588179553334541],
              [126.826562138573635, 37.588295003031845],
              [126.826349532681277, 37.588429267716563],
              [126.826146854069052, 37.588554545933135],
              [126.825917069040898, 37.588699208692631],
              [126.825702860421799, 37.588835163666538],
              [126.825509563093945, 37.58895678774563],
              [126.825332571124349, 37.589068020468709],
              [126.825218865203155, 37.589123882890753],
              [126.824756957464899, 37.589416849793494],
              [126.823651892852467, 37.590116839989527],
              [126.822668356477493, 37.590740690618333],
              [126.822539174560589, 37.59082693518581],
              [126.822239906855373, 37.591015966902184],
              [126.8218876719544, 37.591237298608533],
              [126.821720413626963, 37.5913409363718],
              [126.821527625336955, 37.591466780252432],
              [126.821239685353007, 37.591651610076454],
              [126.820998168430648, 37.59180778684339],
              [126.820688614774298, 37.592004961765376],
              [126.820466958524761, 37.592145959257763],
              [126.820243523744097, 37.592286962608149],
              [126.820053934785506, 37.592403519831279],
              [126.819302528746817, 37.592849754549292],
              [126.818682961820912, 37.593715713510392],
              [126.818365179259857, 37.594160081246422],
              [126.817525986205183, 37.595330333256584],
              [126.81655626428936, 37.595681606518362],
              [126.815885712673037, 37.596181446201633],
              [126.815150448834942, 37.596727066909118],
              [126.814519582568266, 37.597198247062281],
              [126.813953231340733, 37.597619395670776],
              [126.812381600202855, 37.598439881088737],
              [126.811368917789352, 37.59896082371678],
              [126.81053935322555, 37.599551880141206],
              [126.810076877051415, 37.599886467451633],
              [126.809364807245899, 37.600396059033386],
              [126.809021437249214, 37.600643260551344],
              [126.808442662599916, 37.601060154558503],
              [126.808239679600803, 37.601208196028331],
              [126.807237037897579, 37.601792188045906],
              [126.806449367660832, 37.60223940656136],
              [126.806135343644257, 37.602421624239476],
              [126.805863857566521, 37.602580251821031],
              [126.805623975586698, 37.602760897161225],
              [126.805253794165253, 37.603036207770494],
              [126.804843191638184, 37.603342137281835],
              [126.804421581685517, 37.603655371942466],
              [126.803884378276052, 37.604058509705965],
              [126.803367723485366, 37.604444497777536],
              [126.802877668320946, 37.604812229398739],
              [126.802581404511898, 37.605032750097358],
              [126.801988682953535, 37.605408756089837],
              [126.801448366092785, 37.60563703297148],
              [126.800461661823263, 37.606043329438563],
              [126.798562022983546, 37.607044671742685],
              [126.797167400008917, 37.607773753485667],
              [126.79691127095515, 37.607930693028827],
              [126.795607021964557, 37.608796465427709],
              [126.794202893821591, 37.609741162923548],
              [126.793218224705498, 37.610399397165459],
              [126.792698057088543, 37.610742535235673],
              [126.792338157527112, 37.610979252088981],
              [126.791657331874816, 37.611437543232668],
              [126.791258230917393, 37.611705153722937],
              [126.79020063556915, 37.612412795455803],
              [126.789519809350523, 37.612862055315055],
              [126.789064342737078, 37.613158847886581],
              [126.788809718942886, 37.613331539513283],
              [126.788315321604543, 37.613623474787197],
              [126.78792994460062, 37.613848302154814],
              [126.787407539294492, 37.614153120964268],
              [126.786777359316048, 37.614517719584761],
              [126.786626518643857, 37.614603884986302],
              [126.785581502149512, 37.615213237955686],
              [126.783737148074735, 37.616287356918107],
              [126.782635176636262, 37.616932617603936],
              [126.78222743630144, 37.617166682801447],
              [126.781579479580557, 37.617542761918365],
              [126.781358598308017, 37.61766594634031],
              [126.780689868668773, 37.618061974493948],
              [126.779918112994551, 37.618519747693611],
              [126.77968779735248, 37.618659813604857],
              [126.777693435110407, 37.619830131068724],
              [126.776204823182013, 37.620708004666753],
              [126.773471689170137, 37.622308180619363],
              [126.773258038785954, 37.622435877615807],
              [126.772305152399511, 37.623002214333901],
              [126.77116641764735, 37.623680514557918],
              [126.770429682881144, 37.624109290993829],
              [126.769952224743889, 37.624371890997033],
              [126.769344594476593, 37.624715320995094],
              [126.76856213490386, 37.625157510356971],
              [126.765219491360284, 37.626856531387475],
              [126.763554417433568, 37.627698689281736],
              [126.762888572873493, 37.628016912179852],
              [126.761914579293858, 37.628494987997719],
              [126.759960322093264, 37.629465752390651],
              [126.759520425831298, 37.629693473886334],
              [126.759089029971392, 37.629922616717472],
              [126.759027221099899, 37.629985558078438],
              [126.758733552949153, 37.630152481804011],
              [126.758497257875291, 37.630206053977687],
              [126.75771108672312, 37.630661677803374],
              [126.757239618666844, 37.630850187073719],
              [126.75653284002712, 37.63127696488128],
              [126.755099257555685, 37.631964073555885],
              [126.754584897816017, 37.63205929460262],
              [126.751692788107988, 37.632610118127232],
              [126.748627691376882, 37.63404908749709],
              [126.746036317613303, 37.635242370339292],
              [126.743884685986529, 37.636972916520556],
              [126.74195797166, 37.638440941222171],
              [126.740402247136117, 37.640314635621905],
              [126.736321788603149, 37.643113475456445],
              [126.734684241546489, 37.646362299179643],
              [126.732602930982949, 37.647478159405914],
              [126.732460070111173, 37.647521476843991],
              [126.732235773589309, 37.647711854350526],
              [126.732118609863662, 37.647796332429223],
              [126.731931428896303, 37.647953295901672],
              [126.731526263610874, 37.648220976567316],
              [126.731380804173469, 37.648245978965093],
              [126.731097670832156, 37.648305019810223],
              [126.730631569828716, 37.648429807486686],
              [126.730526127408538, 37.648456035930373],
              [126.730149157749665, 37.648465305388626],
              [126.730115070817973, 37.648487751309581],
              [126.729818246934244, 37.64851268277522],
              [126.7297715128052, 37.648510611016853],
              [126.729535646355529, 37.648518230311133],
              [126.72933201514914, 37.648526482111336],
              [126.729254305558669, 37.648520113116206],
              [126.729187013578198, 37.648525030283039],
              [126.728461763776465, 37.648586980670984],
              [126.728416776728679, 37.648590534492364],
              [126.726512150862192, 37.648753357835467],
              [126.725224183402432, 37.648875917173974],
              [126.724836707103151, 37.648943149693608],
              [126.72122215319915, 37.650505688931382],
              [126.718904695099212, 37.651524169645391],
              [126.71751628709562, 37.65215348935849],
              [126.714522662049049, 37.653503892844533],
              [126.708589743859832, 37.656265150650484],
              [126.700850062646879, 37.659854068095129],
              [126.69200591629091, 37.664106299347786],
              [126.68877739621712, 37.665842845514454],
              [126.67934338898597, 37.670822339797425],
              [126.677971164723928, 37.671519654522136],
              [126.675638615662024, 37.673558431525962],
              [126.674621415379917, 37.675250653107867],
              [126.672592353522987, 37.678041300516448],
              [126.671042556966754, 37.681550461021722],
              [126.670453853453111, 37.683526711321306],
              [126.670356092154151, 37.685116642692833],
              [126.671491729297699, 37.690313047997556],
              [126.672461965372079, 37.696010705927087],
              [126.67360933437044, 37.700302213292517],
              [126.67395147325243, 37.700299217967718],
              [126.674867962899086, 37.699945041514248],
              [126.675939090362746, 37.699514693975935],
              [126.680219387886311, 37.697384949389743],
              [126.682881075581861, 37.695994270332399],
              [126.682926293316399, 37.69594399252081],
              [126.683083857151559, 37.695762819585809],
              [126.683334979156314, 37.695413247253072],
              [126.683364391222142, 37.695328293522614],
              [126.68334182889943, 37.695301492006216],
              [126.686961829335559, 37.692401364532493],
              [126.687342095011061, 37.691960346430719],
              [126.687693320311851, 37.691640312555393],
              [126.687849656076423, 37.691495438734549],
              [126.689915483600473, 37.688605723915217],
              [126.690256521718453, 37.688641536943983],
              [126.690313554882977, 37.688642813664174],
              [126.690310322048418, 37.688654067211687],
              [126.690321120608189, 37.688655501214001],
              [126.690675326723039, 37.688804247860318],
              [126.690696903898001, 37.688811908885036],
              [126.690743484975954, 37.688815689565295],
              [126.690811590540463, 37.68875280137587],
              [126.690812778158815, 37.688764345882539],
              [126.690822348508945, 37.688807455302253],
              [126.690834372815686, 37.688854787707669],
              [126.690891045942422, 37.689113685483903],
              [126.690892391914915, 37.689128041420766],
              [126.690913235168154, 37.689186659121034],
              [126.691003814853758, 37.689299797721148],
              [126.691073238198584, 37.689347848822329],
              [126.691152538868593, 37.689403521021731],
              [126.691186889237756, 37.689409521754513],
              [126.691203839029896, 37.68942308084754],
              [126.691264816269452, 37.689454775094646],
              [126.691419698758949, 37.689571749450792],
              [126.691637834295278, 37.689724081559092],
              [126.691731197150943, 37.689809071715139],
              [126.691858468817045, 37.6899163956896],
              [126.691949455746666, 37.690063041551703],
              [126.691954208564795, 37.69007065818132],
              [126.692110626735555, 37.690326150175245],
              [126.692195848671872, 37.690452508977984],
              [126.6923983582831, 37.690614655246563],
              [126.692528530579807, 37.690709318508418],
              [126.692716696445487, 37.690831153464018],
              [126.692749212721694, 37.690850384129455],
              [126.692795190248731, 37.690868812127171],
              [126.692806156575529, 37.690873624963679],
              [126.693072833295119, 37.690944426906974],
              [126.693169621813439, 37.690972276600462],
              [126.693182528110185, 37.690976806165132],
              [126.693225314984304, 37.690999162874327],
              [126.693355594173667, 37.691065670313321],
              [126.693429918178282, 37.691127526612838],
              [126.693588916337902, 37.691276312948666],
              [126.693721812700204, 37.691442221375496],
              [126.693749027155775, 37.691501693083353],
              [126.693880990054495, 37.691547932599946],
              [126.693937892664351, 37.691540197571278],
              [126.694548094489036, 37.691474494331878],
              [126.694657535589101, 37.691398197046475],
              [126.694695403650073, 37.691323965883257],
              [126.694706860339949, 37.691251080514483],
              [126.694714891040789, 37.691151148241872],
              [126.694739579786003, 37.6910918027707],
              [126.694794556908178, 37.691033941580102],
              [126.694972381421834, 37.691044260509187],
              [126.695231159943589, 37.691098143931704],
              [126.695621471080983, 37.69117349568738],
              [126.695740766396796, 37.69119858162523],
              [126.696200593935757, 37.691345044740984],
              [126.696272053622579, 37.691370573896968],
              [126.696391753930428, 37.691512785800747],
              [126.696391536576911, 37.691524326612353],
              [126.696415173295961, 37.691591401613451],
              [126.696413682567822, 37.691653618464898],
              [126.696392935717597, 37.691700306900913],
              [126.696370402872901, 37.691751207232102],
              [126.696331997874779, 37.691743782999744],
              [126.69625550720535, 37.691734016771896],
              [126.696153027512679, 37.691841020462483],
              [126.696120128775632, 37.691870495962782],
              [126.696105640020264, 37.691903406764226],
              [126.696074986040784, 37.691949492866193],
              [126.695928986525431, 37.691955313280133],
              [126.695766461236815, 37.691929556863364],
              [126.695522363735236, 37.691839385766301],
              [126.69545033739945, 37.691822026447795],
              [126.69536201319967, 37.691803210259764],
              [126.695169238688194, 37.691810593100016],
              [126.695096875803756, 37.691830965142813],
              [126.694938734594999, 37.691902631407494],
              [126.694790426295711, 37.692036823034641],
              [126.694738317692142, 37.6920862406146],
              [126.69467946482942, 37.692140704051575],
              [126.694652966231573, 37.692163159248764],
              [126.694709281128212, 37.692212858891232],
              [126.694699925865464, 37.692290263553993],
              [126.694688042777543, 37.692422566493264],
              [126.694710236682866, 37.692580851844006],
              [126.6947350936221, 37.69274225244591],
              [126.69479722148138, 37.692883748542229],
              [126.694798528545945, 37.692910501613788],
              [126.694811286673598, 37.693039201707158],
              [126.694920330578981, 37.693315973599319],
              [126.694932265805079, 37.693344439104287],
              [126.694976375878852, 37.693387349638854],
              [126.695197298200739, 37.693387635380653],
              [126.69527839013675, 37.693398270160984],
              [126.695359406800065, 37.693468584654319],
              [126.69536559918248, 37.693473673166864],
              [126.695394199681928, 37.693497109451151],
              [126.695780615202878, 37.693444621554498],
              [126.695676037610298, 37.693631021455673],
              [126.695642389909892, 37.693712858990878],
              [126.695608776568776, 37.693789074579023],
              [126.695553699710302, 37.693912255865278],
              [126.695828134092849, 37.694038255833249],
              [126.696076511414176, 37.693824920078988],
              [126.696192426428681, 37.693854213320307],
              [126.696300230537801, 37.693831968242755],
              [126.696402130463099, 37.693911354729892],
              [126.696414151914567, 37.693916169959543],
              [126.696552979362693, 37.693976497319916],
              [126.696615580875161, 37.694003408859658],
              [126.696627084233256, 37.694007384830044],
              [126.696712708928544, 37.694035473036998],
              [126.69682179731835, 37.694090092176644],
              [126.696888312017805, 37.694113914360436],
              [126.696920339375765, 37.694123006743894],
              [126.69702720713417, 37.694155104827992],
              [126.697068463957223, 37.694161400979048],
              [126.697124775505699, 37.694168303583723],
              [126.697135387807833, 37.694171150997583],
              [126.697147960466012, 37.694174282769431],
              [126.69719488998814, 37.694176935597014],
              [126.697329738887888, 37.694256676592772],
              [126.69737481099898, 37.69428382181129],
              [126.697470253697659, 37.694337278949639],
              [126.69752098693462, 37.694366411822237],
              [126.697689043666088, 37.694469050452099],
              [126.697834286714937, 37.694560926624419],
              [126.697888530211372, 37.694596825619122],
              [126.697952512016528, 37.694635839968797],
              [126.698014384201045, 37.69466669511641],
              [126.698141157391035, 37.694772605463704],
              [126.698202477307746, 37.694811045220959],
              [126.698246487839171, 37.694834529454553],
              [126.698283854199957, 37.694883052437554],
              [126.698247003990957, 37.694927726651585],
              [126.698210136525773, 37.694976626337343],
              [126.698188203352856, 37.695011194026428],
              [126.698240364144013, 37.695079747498099],
              [126.698321967641888, 37.695137952477857],
              [126.698352030503045, 37.695150706350553],
              [126.698390431634962, 37.695162346432589],
              [126.698459211664172, 37.695195462505183],
              [126.698453163529976, 37.6952022042029],
              [126.698482623653533, 37.69523212889446],
              [126.69854203003473, 37.695219893259903],
              [126.698558634546032, 37.695229792480461],
              [126.698565532614225, 37.695234306019138],
              [126.698557509079052, 37.695247241289607],
              [126.698477927203427, 37.695341358883944],
              [126.698514529906291, 37.695360310143137],
              [126.698539092570769, 37.695376140146848],
              [126.698572317586695, 37.695394812420396],
              [126.698660722962828, 37.69543952015588],
              [126.698674120838163, 37.695454483559914],
              [126.698730478715234, 37.695494883410937],
              [126.698774470365763, 37.69552315152356],
              [126.698790195036906, 37.695534742267398],
              [126.698792840186968, 37.69553672217188],
              [126.698822171744609, 37.695556501577073],
              [126.69887324762766, 37.6955853554445],
              [126.698914428731484, 37.695610516970767],
              [126.698956125596013, 37.695639905333536],
              [126.698970406860511, 37.695655141259707],
              [126.699003115120306, 37.695672685878897],
              [126.699011600590723, 37.695677212473036],
              [126.699040567128449, 37.695697558495461],
              [126.699149070749499, 37.695765967754014],
              [126.699263397711874, 37.695841158087909],
              [126.699295446694165, 37.695845177445953],
              [126.699332464231432, 37.695845839916814],
              [126.699367239269392, 37.695876615902229],
              [126.699407860380447, 37.69590853306088],
              [126.699420751801256, 37.695919828152867],
              [126.699575118610596, 37.696036222787072],
              [126.699571737399296, 37.696039592749671],
              [126.6995872797565, 37.696051462219053],
              [126.699666373637854, 37.696119516397765],
              [126.699890198371577, 37.696233835972031],
              [126.700014864593086, 37.69633776578307],
              [126.700073141079272, 37.696383521627958],
              [126.700200009290668, 37.696509422403693],
              [126.700290574018126, 37.69658989374183],
              [126.700310725723782, 37.696600369383269],
              [126.700378390885433, 37.696646149056178],
              [126.700513107601807, 37.696763339179924],
              [126.70053920963764, 37.696791272657237],
              [126.700640651795311, 37.696896268730114],
              [126.700721381506753, 37.696955605025074],
              [126.700811553652954, 37.696956681902918],
              [126.700845394756257, 37.696955074377172],
              [126.700890827425482, 37.696935765396972],
              [126.700922941750704, 37.696923738288831],
              [126.701092425333059, 37.696852381274425],
              [126.701404383316827, 37.696687055625894],
              [126.70147360847956, 37.696652598673751],
              [126.70174529160542, 37.696498152402022],
              [126.701765528533954, 37.696490320396869],
              [126.701831274732029, 37.696486270979001],
              [126.701843667701084, 37.696486302472707],
              [126.702069493088331, 37.696501796110603],
              [126.70222388722425, 37.696525279802088],
              [126.702276472974361, 37.696534422905145],
              [126.702522251795784, 37.696522369739405],
              [126.703330502417202, 37.696604376922714],
              [126.703437347198005, 37.696689112540938],
              [126.703479209757475, 37.696720193536834],
              [126.703553663977516, 37.696755852688824],
              [126.703668327982399, 37.696787964450479],
              [126.703847247020576, 37.696837401701139],
              [126.703902967540245, 37.696859507873015],
              [126.703917503559381, 37.696859544557412],
              [126.703990636363017, 37.696868450438757],
              [126.704225313144974, 37.696885376797425],
              [126.704400219019107, 37.696875114140639],
              [126.70470313641593, 37.696794231484873],
              [126.704780392635399, 37.696792732095822],
              [126.704798017011612, 37.696814462678297],
              [126.704813604168649, 37.69681533979481],
              [126.704819208349846, 37.696831688396287],
              [126.704844418751989, 37.696864691114619],
              [126.704882698286994, 37.696904483942696],
              [126.705015487159315, 37.696972390289147],
              [126.705170025192501, 37.697048513889243],
              [126.705270529611184, 37.69708030020044],
              [126.705378213675587, 37.69713434928908],
              [126.705482064422725, 37.697168684545431],
              [126.705646272731329, 37.697219766941849],
              [126.705718608005128, 37.6972526083985],
              [126.705878103571095, 37.697331284226529],
              [126.705978890902855, 37.697380522340111],
              [126.7059918237738, 37.697381689967457],
              [126.706038528582425, 37.697398700101445],
              [126.706135859177394, 37.697470453550594],
              [126.706190989867579, 37.697507197848857],
              [126.706268430811221, 37.697547935199815],
              [126.706365142543874, 37.697598568283993],
              [126.706416948280634, 37.69762460068717],
              [126.706458838285997, 37.697649202752075],
              [126.706595901263611, 37.697666438715117],
              [126.706696540743962, 37.697661617930464],
              [126.706735557633195, 37.697652994125846],
              [126.706796206134683, 37.697639342928753],
              [126.706845650332539, 37.697634682365546],
              [126.706897001531473, 37.697643820323911],
              [126.706993866043746, 37.697698967110149],
              [126.707021014373908, 37.697732541808648],
              [126.707028273561832, 37.697734812350085],
              [126.707057521311427, 37.69777655502773],
              [126.707146903175001, 37.69788602021508],
              [126.707285875753584, 37.698002645614928],
              [126.707505718655881, 37.69814481665582],
              [126.707791960706942, 37.698296162342494],
              [126.707977413567775, 37.698395441981965],
              [126.708038015194361, 37.69843980317782],
              [126.708165749527552, 37.698485448560184],
              [126.708381408658909, 37.698608173736666],
              [126.708494902614376, 37.698673775778197],
              [126.708557613835552, 37.698718132938204],
              [126.708693750336153, 37.698792809697622],
              [126.708730704426856, 37.698812884861056],
              [126.708774581291124, 37.698825670371775],
              [126.709017757405732, 37.698891873070785],
              [126.709253293224023, 37.698963408065261],
              [126.709362419087569, 37.699012663947101],
              [126.709561435483764, 37.699043005816215],
              [126.709810674273967, 37.699052632273109],
              [126.709997827201306, 37.699083781956261],
              [126.710073878746059, 37.699118323738681],
              [126.710192547196527, 37.699218281721592],
              [126.710270504596892, 37.699307723886356],
              [126.710332058270836, 37.699378826894019],
              [126.710396235988853, 37.69946035150285],
              [126.710453755536662, 37.699522155536883],
              [126.710608618630928, 37.699561396299238],
              [126.711006321067217, 37.699667113923873],
              [126.711067009806854, 37.699686967524578],
              [126.711082264764158, 37.699683067880407],
              [126.711122208294185, 37.699707375181859],
              [126.711245366121346, 37.69974709562478],
              [126.711366113243258, 37.699768232108987],
              [126.711412182508255, 37.699768624753879],
              [126.711484810274385, 37.699771335117532],
              [126.711593576884496, 37.69977667501427],
              [126.711669863561298, 37.699794881848263],
              [126.711859785031322, 37.699753390572532],
              [126.711862256932733, 37.699753396644361],
              [126.711865104107673, 37.699753124338763],
              [126.711956511429989, 37.699755321940238],
              [126.712026129991457, 37.699759150787585],
              [126.712108113612075, 37.699770334786891],
              [126.712185914075079, 37.699763768464237],
              [126.712306880664201, 37.699731405220597],
              [126.712453916450713, 37.699690096108441],
              [126.712514725807026, 37.699678983091815],
              [126.712717856836889, 37.699701725574627],
              [126.712937643650477, 37.699678045701198],
              [126.713033459469457, 37.699642809148322],
              [126.713147911496037, 37.699597193926103],
              [126.713193346984326, 37.699576754012625],
              [126.713205050548254, 37.699573404008554],
              [126.713264238021196, 37.699568196970347],
              [126.713521923882269, 37.699596134834934],
              [126.713543530497276, 37.699597602116732],
              [126.713691044617676, 37.699617098761117],
              [126.714028706803532, 37.699624121086586],
              [126.714041804888367, 37.699626684730163],
              [126.714071521880783, 37.699639145441488],
              [126.714133618366233, 37.699664640938281],
              [126.714162617764202, 37.699677946784966],
              [126.714457846956293, 37.699800287109106],
              [126.714744737965916, 37.699926840951782],
              [126.714876005713293, 37.700025428364135],
              [126.714916150032465, 37.700042130724604],
              [126.715000843504839, 37.700041777937408],
              [126.715129160031168, 37.70003223308219],
              [126.715348764550541, 37.70000883658259],
              [126.715483684180143, 37.699983540476786],
              [126.715563643348517, 37.699969940624825],
              [126.715746237514537, 37.699943354310797],
              [126.715911111832952, 37.700010479223117],
              [126.716034789479821, 37.700054421452187],
              [126.716047517855642, 37.700061768102472],
              [126.716217630142211, 37.700099632911289],
              [126.716328108302591, 37.700120162853921],
              [126.716410620820895, 37.700180051312906],
              [126.716512725343648, 37.700258294676793],
              [126.716640788228275, 37.700362494399847],
              [126.716732393368673, 37.700452532867892],
              [126.716948681724929, 37.700555260484585],
              [126.717017677990967, 37.700582735215733],
              [126.717066304357971, 37.700608466957569],
              [126.717139883929292, 37.700641313414614],
              [126.717215943672542, 37.700674994693038],
              [126.717307751968136, 37.700712101508309],
              [126.717394755907961, 37.700754260099565],
              [126.717433790608624, 37.700833188579651],
              [126.71767319467078, 37.701009173952386],
              [126.71789361171659, 37.701192717313489],
              [126.717928670175425, 37.701244039506065],
              [126.717984849449664, 37.701379886942604],
              [126.718050087803363, 37.701510116086801],
              [126.718103777778197, 37.701647642281465],
              [126.71810677794312, 37.701651875017347],
              [126.718156402978082, 37.701743216967188],
              [126.718196848592626, 37.701776532724352],
              [126.718299342327072, 37.70189362506521],
              [126.718374357048702, 37.701967008601777],
              [126.718378555775573, 37.701981091753822],
              [126.718513537987576, 37.701990713740685],
              [126.718901023295132, 37.70198939064359],
              [126.718924284115971, 37.701976490515911],
              [126.719097245150564, 37.70192256769824],
              [126.719244386122512, 37.701901522096271],
              [126.719692807539516, 37.701858672623175],
              [126.71991649678084, 37.701787697438498],
              [126.720163433899302, 37.701755059296893],
              [126.720710827876573, 37.701681187580569],
              [126.720900001901384, 37.701648419337566],
              [126.720972501026864, 37.701688855964839],
              [126.721009081106075, 37.701713151914142],
              [126.721063347415566, 37.701747346473191],
              [126.721187966495904, 37.701825918623896],
              [126.721273498229465, 37.701883558437629],
              [126.721482081385147, 37.702014693870581],
              [126.721608423324128, 37.702057510370878],
              [126.721630720880015, 37.702065446739532],
              [126.72167920754076, 37.702080761091523],
              [126.721741329110557, 37.702097801588401],
              [126.721771524221126, 37.702125460769302],
              [126.721815725849908, 37.702149504258564],
              [126.72191475818812, 37.702199571484293],
              [126.722080432796261, 37.702243606940741],
              [126.722203559522129, 37.702294298626079],
              [126.722326891094369, 37.702338792029622],
              [126.722423041814565, 37.702353092710993],
              [126.7225231143982, 37.702361780572502],
              [126.722635593116095, 37.702324881840674],
              [126.722786278252485, 37.702350573248737],
              [126.722934667485617, 37.702468616945495],
              [126.722969640258398, 37.702495440198938],
              [126.723102170009938, 37.702591201397567],
              [126.723114655955214, 37.702615160502418],
              [126.723190249225183, 37.702729374065783],
              [126.723243108915995, 37.702806639424942],
              [126.723261822861019, 37.702823864984389],
              [126.723297851079295, 37.702856600958803],
              [126.723366925465115, 37.702864368056247],
              [126.72340828321083, 37.702842509093117],
              [126.72351304779427, 37.702924969167007],
              [126.72358960222958, 37.703204160389383],
              [126.723701849895463, 37.703514978421936],
              [126.723776620508275, 37.703655650988814],
              [126.723817734623481, 37.703747817398686],
              [126.723885270666827, 37.703742624698236],
              [126.72409310840402, 37.703741708353732],
              [126.724304616495161, 37.703799642273125],
              [126.724426715805507, 37.703844130578247],
              [126.724485899789897, 37.703889317869645],
              [126.724719809789178, 37.704214214439169],
              [126.724998175189171, 37.70454794513406],
              [126.72541973323149, 37.704633677672],
              [126.725488569590169, 37.704657209849451],
              [126.725824675731133, 37.704613227223604],
              [126.726077480524197, 37.704574959414039],
              [126.726341629845436, 37.70453842035942],
              [126.72651645173886, 37.70368938071416],
              [126.726541636745864, 37.703539095463164],
              [126.72642366239495, 37.703338634940522],
              [126.72642119777737, 37.703333556759162],
              [126.726410650627784, 37.703312422548443],
              [126.726427009978494, 37.703298099335662],
              [126.726449731842123, 37.703283511663479],
              [126.726478842943166, 37.703270632703351],
              [126.726492506288324, 37.703264745232993],
              [126.726584294800787, 37.703263274474175],
              [126.726663787590013, 37.703281190775677],
              [126.726674221934019, 37.70328375581704],
              [126.726779871850965, 37.703271893050704],
              [126.726862843399232, 37.703305872515493],
              [126.726925419159102, 37.703344867850753],
              [126.726975116660412, 37.703370607006896],
              [126.726995057259685, 37.703390645839875],
              [126.727135701142998, 37.703548074409269],
              [126.727150873128451, 37.703563597295606],
              [126.727183971093226, 37.703573530856012],
              [126.727292686423709, 37.703594614033875],
              [126.727378246701832, 37.703646339150914],
              [126.727481339062422, 37.703704015279214],
              [126.727582122136113, 37.703665120552984],
              [126.7276717258422, 37.703773723743488],
              [126.727717413298237, 37.703829293224324],
              [126.727846024375296, 37.704030903335457],
              [126.727886552513837, 37.704089560093081],
              [126.727891869743914, 37.704234852983767],
              [126.727910154321563, 37.704319919445126],
              [126.727934526122453, 37.704343905614017],
              [126.728050044798138, 37.704343894275944],
              [126.728145833210576, 37.704362135676909],
              [126.728263596985826, 37.704380716235065],
              [126.728391781000624, 37.704405780728372],
              [126.728408585348774, 37.704415396904267],
              [126.728417974639939, 37.704415418650456],
              [126.72844826403626, 37.704418308816898],
              [126.728464897303596, 37.704425104569268],
              [126.728472143391897, 37.704428211656975],
              [126.728507869607299, 37.704445187464863],
              [126.728576027709124, 37.704415784599369],
              [126.728611810724587, 37.704417272925177],
              [126.72859767505426, 37.704452999495416],
              [126.728624442992654, 37.704451376642936],
              [126.728904595121264, 37.704491153553256],
              [126.729009606244588, 37.704508568667578],
              [126.72901562969065, 37.704508023989582],
              [126.729333418760007, 37.7045324082804],
              [126.729351128340909, 37.70453329606422],
              [126.729516987516632, 37.704529453245279],
              [126.729615362784642, 37.704519544274937],
              [126.729675037728626, 37.704527565303287],
              [126.729666186983948, 37.704575692449019],
              [126.72958474452868, 37.704656312177363],
              [126.729479945020074, 37.704726733103612],
              [126.729413565548086, 37.704806261255065],
              [126.729390648198532, 37.704871249002679],
              [126.729418607340321, 37.704928461706395],
              [126.729470208838478, 37.704967439370201],
              [126.729478677672006, 37.704977315460852],
              [126.729480417870775, 37.70498520291553],
              [126.729486053828865, 37.704994504862405],
              [126.729512275947883, 37.705042992198635],
              [126.729736349952333, 37.705062375068159],
              [126.729772179550622, 37.705051195587835],
              [126.729814418122288, 37.705033273629994],
              [126.729892114118726, 37.705006702993053],
              [126.729921361572963, 37.705006202754298],
              [126.730036359587956, 37.705003097964237],
              [126.730133128362752, 37.704998527584941],
              [126.730404078562117, 37.704987609506951],
              [126.730635726899209, 37.705019675644408],
              [126.73071788731437, 37.705034505031655],
              [126.730897627254805, 37.705061946611018],
              [126.731225264347714, 37.70515448835787],
              [126.731286785784732, 37.705144484502149],
              [126.731558058700102, 37.70509471491993],
              [126.731748665664796, 37.705008712599401],
              [126.731808622778004, 37.704992235966145],
              [126.732007422120418, 37.704942290548011],
              [126.732027267794336, 37.704942056606505],
              [126.732104533043028, 37.70494026005278],
              [126.73215255087247, 37.704938964247965],
              [126.732169415823236, 37.704928866920412],
              [126.732168344920211, 37.704933369297343],
              [126.732178258057075, 37.70493593266108],
              [126.732197391374854, 37.704935129456089],
              [126.732226316659109, 37.704923365851876],
              [126.732294024319813, 37.704917889430078],
              [126.732371741941719, 37.704935239232498],
              [126.732474617524147, 37.704954060902089],
              [126.732597822768327, 37.704939142673624],
              [126.73292858842926, 37.704900479346954],
              [126.733050605568963, 37.704916235785028],
              [126.733132771593986, 37.704932757316833],
              [126.733199333910434, 37.704946143967966],
              [126.733325945192092, 37.704967820903114],
              [126.733501785240605, 37.70499834896291],
              [126.733584992894578, 37.70501853046364],
              [126.733661112012399, 37.705035875784425],
              [126.733744662369347, 37.705061688979967],
              [126.733936912575274, 37.705107741237221],
              [126.734022596837178, 37.705129892152065],
              [126.734120917720773, 37.705135178574174],
              [126.734241148243271, 37.70515601116977],
              [126.734298170899322, 37.705066043942651],
              [126.734347237229372, 37.705022233098404],
              [126.734484414136077, 37.705010155563734],
              [126.734530531257676, 37.704998151026061],
              [126.734564287034843, 37.704973730221809],
              [126.734696617548892, 37.704880554647104],
              [126.734742073622257, 37.70485362855522],
              [126.734783257062261, 37.704832323810137],
              [126.734825687620301, 37.704807922552575],
              [126.734875341543145, 37.704796214187205],
              [126.734985654060921, 37.704670049223367],
              [126.734996878944571, 37.70465148769258],
              [126.735015308063879, 37.704650970747196],
              [126.735067251762729, 37.704642637083602],
              [126.735147220505056, 37.704626771575271],
              [126.735232322095868, 37.704612043809831],
              [126.735302490532959, 37.704612760844292],
              [126.735438031659456, 37.704613913676994],
              [126.73570968155866, 37.704657322166071],
              [126.735797643381716, 37.704683143853281],
              [126.735819871888594, 37.704711069790811],
              [126.735830326164745, 37.704711372645619],
              [126.735876922299767, 37.704711198326386],
              [126.735901019323066, 37.704711252609101],
              [126.735985568470497, 37.704654285828767],
              [126.736004394334927, 37.704641093048245],
              [126.736110854940748, 37.704650063065223],
              [126.736139152210441, 37.704515261338585],
              [126.736265121436986, 37.704516670977043],
              [126.736317725125502, 37.704523546531995],
              [126.736813727633759, 37.704406130087676],
              [126.736972393485317, 37.704380862719006],
              [126.737306776311016, 37.704372882076683],
              [126.737701179318606, 37.70437489133942],
              [126.737804112351512, 37.704377662307166],
              [126.73853008606082, 37.704413349138704],
              [126.738613236785667, 37.704449852450075],
              [126.738797106493877, 37.704512482886827],
              [126.73919740457265, 37.705007499201969],
              [126.739286856785057, 37.705116093233542],
              [126.739287732341708, 37.705118635901194],
              [126.739306011808694, 37.705160625468594],
              [126.739321776066987, 37.705263145259494],
              [126.739429122687497, 37.705471731989995],
              [126.739540897542014, 37.705730440108404],
              [126.739739347864514, 37.706233456921488],
              [126.73975197964775, 37.706269235238047],
              [126.73978043217204, 37.706343348674849],
              [126.739902477584096, 37.706455393178338],
              [126.740074977143792, 37.706628364537401],
              [126.740381759204624, 37.706609907852958],
              [126.740449479710392, 37.706601039153178],
              [126.740529785179746, 37.706589675569454],
              [126.740717593005328, 37.70654335807474],
              [126.740938577646915, 37.706485283937255],
              [126.740979600488785, 37.706457778041532],
              [126.74116041254652, 37.706333168579626],
              [126.741166632275906, 37.706328398190699],
              [126.741211016033319, 37.706302872746626],
              [126.741196505215086, 37.706197824278902],
              [126.741169593434478, 37.706091333913413],
              [126.741225044663423, 37.705939427560303],
              [126.741082652049002, 37.705617297581554],
              [126.741123618515331, 37.705557131536061],
              [126.741145332825099, 37.705528465740692],
              [126.741181800569422, 37.705438161480018],
              [126.741238874874426, 37.705379733777505],
              [126.741370327592833, 37.705285701863573],
              [126.741456970177907, 37.705235493014435],
              [126.741524081076847, 37.705196223797842],
              [126.741728925393019, 37.705089118105256],
              [126.741890486691702, 37.704997692655766],
              [126.742098862591575, 37.704895387223878],
              [126.742205526829565, 37.704842969454063],
              [126.742418217007838, 37.704722653644268],
              [126.742597112406926, 37.704638581140109],
              [126.742735440730414, 37.704653525477212],
              [126.742847324684377, 37.704629273731015],
              [126.742882090674073, 37.704620061042831],
              [126.742980876527668, 37.704592681120779],
              [126.743105003386717, 37.704667562108718],
              [126.74340147072671, 37.704861080985872],
              [126.74396706887994, 37.704854992860753],
              [126.74402555958666, 37.70490101574979],
              [126.744173570591727, 37.704985525020469],
              [126.744237610359733, 37.705014099215283],
              [126.74427958858287, 37.705070501101524],
              [126.744295495407357, 37.705081239263784],
              [126.744329975925538, 37.70510214478108],
              [126.744367993166748, 37.705126724927915],
              [126.744374705059101, 37.70513039748203],
              [126.744440507867054, 37.705164047842899],
              [126.744517768241735, 37.70516364864983],
              [126.744678508759847, 37.705203983694084],
              [126.744832190217963, 37.705236131402039],
              [126.74493511185878, 37.70524592364756],
              [126.745035980519688, 37.70528218168414],
              [126.745049605869326, 37.705287004452835],
              [126.745063587630327, 37.70529041348037],
              [126.745098463637078, 37.70529865206754],
              [126.745149228913633, 37.705326070693431],
              [126.745152033813923, 37.70533480714068],
              [126.745161203970127, 37.705345809838434],
              [126.745173726264042, 37.705364703264927],
              [126.745206785797308, 37.7053867316422],
              [126.745207162394649, 37.705326764243374],
              [126.74523067677147, 37.70529190296682],
              [126.745252033051159, 37.705264641138321],
              [126.745378126576483, 37.705177629634932],
              [126.745521084633666, 37.705083338774394],
              [126.745615605362957, 37.705010061246625],
              [126.745635143068924, 37.704997147770783],
              [126.745636733556239, 37.704996304315202],
              [126.745638337303603, 37.704994902290146],
              [126.745777102746473, 37.704935802715219],
              [126.745849310352213, 37.704907515837192],
              [126.74591308421752, 37.704911321022081],
              [126.745960219297373, 37.704859897003708],
              [126.745997877256428, 37.704830697253406],
              [126.746054463193644, 37.70481420606577],
              [126.746065096316784, 37.704811976689349],
              [126.746074178598988, 37.704799040489299],
              [126.746094593872769, 37.70478811096055],
              [126.746109984495675, 37.704691569868139],
              [126.746140550998234, 37.704613927845784],
              [126.74615803390418, 37.704578485708481],
              [126.746160187013928, 37.704572300742029],
              [126.746161840260498, 37.704553158817163],
              [126.746164660600826, 37.704507837378081],
              [126.746167837465961, 37.704408170503989],
              [126.746178812271921, 37.704359208803815],
              [126.746181185721738, 37.704338374623354],
              [126.746180622941267, 37.704294172062632],
              [126.746216073924131, 37.704240470222075],
              [126.746236383876905, 37.704207295613635],
              [126.746292206656108, 37.704151953046058],
              [126.746327034765642, 37.704124431865857],
              [126.746415122924489, 37.704064654236447],
              [126.746566298646101, 37.704000219825588],
              [126.746613695493053, 37.703974707857171],
              [126.746623987562671, 37.703969378372811],
              [126.74662986487165, 37.703961786932197],
              [126.746662222860465, 37.703930323095136],
              [126.746787720495277, 37.703863868793633],
              [126.74697144817398, 37.703764033180065],
              [126.747050972916654, 37.703721129680162],
              [126.747084679981171, 37.703706273423428],
              [126.747266417993231, 37.703617695117167],
              [126.747415621261695, 37.703562553317198],
              [126.747589892966772, 37.703531674246193],
              [126.747684924569597, 37.703513580249115],
              [126.747731198191246, 37.703504670197987],
              [126.748017234539859, 37.703436866171643],
              [126.748208332154732, 37.703356478281933],
              [126.748277462562356, 37.703345355691461],
              [126.748326241380113, 37.703329981856442],
              [126.748439304236584, 37.703271382531817],
              [126.748517749147084, 37.703235503247996],
              [126.748523967167358, 37.703231020772584],
              [126.748550703813365, 37.703235015350756],
              [126.748641590267056, 37.703238588910928],
              [126.748649570579857, 37.703236074312244],
              [126.748671381469052, 37.703231336956932],
              [126.748694246965215, 37.703226592849049],
              [126.748829003650798, 37.703197600328096],
              [126.74896784247116, 37.703168616395736],
              [126.749075014236269, 37.703121545206052],
              [126.749165544188699, 37.703073032799502],
              [126.749270918118341, 37.70303439962742],
              [126.749305794488677, 37.703042357674072],
              [126.749505060753151, 37.70306052371388],
              [126.749606584568824, 37.70306046134467],
              [126.7497791990628, 37.703049567910803],
              [126.750020285942838, 37.70301404373766],
              [126.750227457502376, 37.703001538540505],
              [126.750646083032265, 37.703384772523698],
              [126.751390278293115, 37.703902435315932],
              [126.751501728054166, 37.704009661280068],
              [126.751573405999238, 37.704138200763104],
              [126.751700820816367, 37.704344007936029],
              [126.752218663164825, 37.704364807993038],
              [126.75248570086714, 37.704464199117112],
              [126.752493845679709, 37.704466748029901],
              [126.752524103088632, 37.704476100875034],
              [126.752607135867706, 37.704499088613062],
              [126.752750423190392, 37.704567810277219],
              [126.752996850695936, 37.704682914638383],
              [126.753101974085638, 37.704722841678169],
              [126.753223830961744, 37.704787850688575],
              [126.753280088301935, 37.704814998085446],
              [126.753302015094917, 37.70482996422443],
              [126.753611408105101, 37.705032206015929],
              [126.753852135377727, 37.704949372607651],
              [126.753983812410354, 37.704885176043447],
              [126.754032314458811, 37.704847266135154],
              [126.754261248077697, 37.704842682978033],
              [126.754416421635582, 37.704856802029703],
              [126.754654012908688, 37.704915583104771],
              [126.75475303345236, 37.704922268305452],
              [126.755056447147027, 37.705061705543066],
              [126.755180747271879, 37.70508814732478],
              [126.75567814555481, 37.705288803188644],
              [126.755871383109962, 37.704991887444756],
              [126.755939987730144, 37.704604056759443],
              [126.755959881134615, 37.704482747196067],
              [126.755948268487273, 37.704403888563135],
              [126.755959352103844, 37.70410687243924],
              [126.75626709033547, 37.703900579199541],
              [126.756503400606448, 37.703755501496815],
              [126.756672058436948, 37.703761762151522],
              [126.756721182720639, 37.703747791065915],
              [126.756923199768522, 37.703792699942397],
              [126.757116913187573, 37.703883477422664],
              [126.757131581929073, 37.703895328488699],
              [126.7573510298744, 37.704029801671574],
              [126.757667712290839, 37.704228110734491],
              [126.758115793425318, 37.70401646276688],
              [126.758332335362468, 37.703902604085137],
              [126.758325667680452, 37.703881751007458],
              [126.758875375694785, 37.703585285588247],
              [126.758936952411517, 37.703553310885169],
              [126.759151646707025, 37.703462808916584],
              [126.759229039639081, 37.703417640395791],
              [126.759232987175537, 37.703403296128712],
              [126.759314320527892, 37.703293653556251],
              [126.759358915823498, 37.7032529224049],
              [126.75936636958734, 37.703251811505055],
              [126.759436248347242, 37.703229710148399],
              [126.759479705644353, 37.703214600070197],
              [126.759524763648002, 37.703198925652636],
              [126.7596397871866, 37.703134400255436],
              [126.759679548810183, 37.703111957673698],
              [126.759729753218551, 37.703037451680835],
              [126.759880522899053, 37.703149813800252],
              [126.759909225644549, 37.703205056744736],
              [126.759918042013439, 37.703220841703065],
              [126.759920850009919, 37.703225640592137],
              [126.759931428982739, 37.70324339326524],
              [126.759946193204598, 37.703278335921382],
              [126.759956241014024, 37.703295537915047],
              [126.760019208934224, 37.703330858598697],
              [126.760160163908353, 37.70340688234139],
              [126.760328064715253, 37.703538145161055],
              [126.76034982933821, 37.70354748763878],
              [126.760516146865655, 37.703621589709215],
              [126.760671030168211, 37.703668927539724],
              [126.76073938948339, 37.703678356095828],
              [126.760809692854039, 37.703689761694825],
              [126.760865297816267, 37.703698326243853],
              [126.760947464749279, 37.70371256698369],
              [126.760965713397269, 37.70371513592481],
              [126.760976912543214, 37.703702491197809],
              [126.761016767274924, 37.70370735661659],
              [126.761161500562068, 37.703713003454062],
              [126.76128127216964, 37.703716058539449],
              [126.761281069828783, 37.703722536068021],
              [126.76127784146756, 37.703844439109616],
              [126.761308305219728, 37.703849852904973],
              [126.761363767090785, 37.703846587252798],
              [126.761444542222321, 37.70385295037476],
              [126.761970153842668, 37.703936511699432],
              [126.76213432581622, 37.703962747849971],
              [126.762416068929653, 37.704010052547552],
              [126.762548707727291, 37.704032007659613],
              [126.76272721038842, 37.704062776852787],
              [126.762803356704438, 37.704077004107312],
              [126.762889424367387, 37.704091539699718],
              [126.763037114474272, 37.704116056433143],
              [126.763136578469883, 37.704150602348122],
              [126.763380057167481, 37.704252732206804],
              [126.763694498720284, 37.704384006778071],
              [126.76377483588999, 37.704417675678535],
              [126.763903855367786, 37.704518735729266],
              [126.763943439434584, 37.704551754778016],
              [126.763990793294738, 37.704591267404879],
              [126.76408780462657, 37.704673387550223],
              [126.764118883589163, 37.704653187416341],
              [126.764131126762123, 37.704647292722875],
              [126.764167858883766, 37.704628788761582],
              [126.764518891907414, 37.704341465227877],
              [126.764614620156436, 37.704270985871908],
              [126.764782877129633, 37.704125763831996],
              [126.765111916319341, 37.70384599849212],
              [126.765088814169829, 37.70403262318905],
              [126.765200780732826, 37.704372682746246],
              [126.765213333530852, 37.704604985763581],
              [126.765133499125383, 37.704691255347036],
              [126.765065487024174, 37.704739834176998],
              [126.765041027951057, 37.704739505828826],
              [126.765031149317679, 37.704782840464794],
              [126.765000438697442, 37.704912013322108],
              [126.765113468601513, 37.705028239254339],
              [126.76525954902786, 37.705114123726048],
              [126.765344482847368, 37.705207769007473],
              [126.765447981732748, 37.7052580879418],
              [126.765518594218648, 37.705283573437846],
              [126.765641406056446, 37.705333651554426],
              [126.765740884483307, 37.705364537379722],
              [126.765846706806641, 37.705465548908684],
              [126.765867060832093, 37.705469526804833],
              [126.76596637208776, 37.705499574207906],
              [126.76601127690202, 37.705532323888455],
              [126.766087320490655, 37.705576118492417],
              [126.766142264302303, 37.705629439180811],
              [126.76621418541616, 37.705689550961417],
              [126.766227047773, 37.705713785560562],
              [126.766303830143102, 37.705918629009645],
              [126.766314706473793, 37.705954130709891],
              [126.766276967056569, 37.706065261544985],
              [126.76623782155697, 37.706172740651716],
              [126.766188071689513, 37.706273441342368],
              [126.766090601580814, 37.706446114933478],
              [126.766065742065891, 37.70651842179565],
              [126.766051572947859, 37.706573856906523],
              [126.766024433808752, 37.706635464751876],
              [126.766017995304523, 37.706767218018747],
              [126.765984533378159, 37.706870203553976],
              [126.766077847108491, 37.70728398685948],
              [126.76703256685127, 37.707167070037471],
              [126.767193916530545, 37.707134451510896],
              [126.7672073726863, 37.707139550683166],
              [126.767221907417365, 37.707137047853138],
              [126.767232898710404, 37.707136222785337],
              [126.76726694670468, 37.707127560080558],
              [126.767311456536518, 37.707116945032958],
              [126.7678715501292, 37.706947440452183],
              [126.768834605663073, 37.706487032628125],
              [126.769737097394497, 37.705956114458267],
              [126.769749165886154, 37.70594797543589],
              [126.770372938826512, 37.705582344839385],
              [126.77074715967278, 37.705468763344598],
              [126.770966150950912, 37.70541598071646],
              [126.771462576319166, 37.705259839967994],
              [126.771525782225439, 37.705047957123419],
              [126.772442529069053, 37.704648532759862],
              [126.772683046971807, 37.704681939058823],
              [126.772745370300825, 37.704637858627798],
              [126.772776606653053, 37.704621026124627],
              [126.77341801847804, 37.703871367967992],
              [126.773805918113354, 37.704378910767154],
              [126.77390580300397, 37.704507491189077],
              [126.774147804106732, 37.704983496613998],
              [126.774047840056582, 37.705630034735861],
              [126.773779068237076, 37.706031004068606],
              [126.773490298254188, 37.706303267255002],
              [126.773540324099514, 37.706516505306553],
              [126.773642398333791, 37.706741943887799],
              [126.7736858781618, 37.706837755445591],
              [126.773566833140478, 37.707406538838391],
              [126.773499307413843, 37.707760037021217],
              [126.773564628413453, 37.708181923003885],
              [126.7736528290406, 37.708487295321127],
              [126.773834257267637, 37.7091903983031],
              [126.773813547055113, 37.709239352771284],
              [126.773892466641598, 37.709331854030495],
              [126.773990418018855, 37.709520119457494],
              [126.774101384175083, 37.709680254755305],
              [126.774220955890314, 37.710041709054352],
              [126.774369653671826, 37.710146174138629],
              [126.774505623885631, 37.710244415894444],
              [126.774778280536154, 37.710436954084784],
              [126.774731956651237, 37.710577361403203],
              [126.774483889071234, 37.711268653532088],
              [126.774421924055034, 37.711424238868133],
              [126.774425436783432, 37.711436354594802],
              [126.774443138298977, 37.711440605199584],
              [126.774454651981813, 37.711443447390977],
              [126.774492728666232, 37.71144915173786],
              [126.774627490307921, 37.711594970935188],
              [126.774870652473211, 37.711814201746058],
              [126.775125572650026, 37.71207203391662],
              [126.775204542449146, 37.712153272302047],
              [126.775560233499021, 37.712488437454802],
              [126.775857515217837, 37.712743240866374],
              [126.776551241088853, 37.713162676965268],
              [126.776798792382749, 37.713223964109353],
              [126.776996793188616, 37.713319501141946],
              [126.77716594965942, 37.713405135422626],
              [126.77735710485905, 37.713480675438802],
              [126.777875554265535, 37.714149499563277],
              [126.777997292302686, 37.714326823893188],
              [126.778211743466159, 37.714728159203261],
              [126.778254716813763, 37.714818616274798],
              [126.778569054065045, 37.715598824110529],
              [126.778362088019122, 37.715827619739244],
              [126.778198284848699, 37.715966391372874],
              [126.778303017274695, 37.71608597640013],
              [126.778480884509534, 37.716280011187969],
              [126.779050001572713, 37.716725946218808],
              [126.779322761203318, 37.717309825477287],
              [126.779639641077949, 37.717480488105686],
              [126.780103513981047, 37.717746018172328],
              [126.780710251207566, 37.717465604925088],
              [126.780782461170318, 37.717436170513231],
              [126.780896830614353, 37.717417527410092],
              [126.781337020972728, 37.717358940736723],
              [126.781823115329416, 37.717302690371369],
              [126.781845794806131, 37.717603708517153],
              [126.781837282645782, 37.717605665767522],
              [126.782181877200344, 37.718057356685748],
              [126.782153960031721, 37.718570565598306],
              [126.782368692861979, 37.718710893550394],
              [126.78258669471947, 37.718700324824127],
              [126.783177216956474, 37.718692410248082],
              [126.783467538290324, 37.718618897350012],
              [126.78405461798468, 37.71893869744671],
              [126.784374590324177, 37.718969136101087],
              [126.784377981134739, 37.719019812525737],
              [126.784420465142432, 37.719036504525512],
              [126.784439719761536, 37.71905737926086],
              [126.784477870885127, 37.719101371461448],
              [126.784490561653612, 37.719125045110609],
              [126.784496211319095, 37.719132371332499],
              [126.784513169310017, 37.719151268718406],
              [126.784513303136663, 37.719167603430122],
              [126.78451923295674, 37.719199427359705],
              [126.784539183326515, 37.719226772275576],
              [126.784547600503316, 37.719315757894243],
              [126.78456113680204, 37.719535671907657],
              [126.784563572289628, 37.719614510676337],
              [126.784514380365749, 37.719708183393067],
              [126.784291278414557, 37.719945680901745],
              [126.784278433819949, 37.719974659241366],
              [126.784252647612888, 37.720065825240809],
              [126.784289127547567, 37.72013459090352],
              [126.784364988736158, 37.720253549676102],
              [126.78437078870698, 37.720271579621979],
              [126.784466884394831, 37.720316804729087],
              [126.784568115747277, 37.72036288611335],
              [126.784741209366956, 37.720444849779717],
              [126.784968955934801, 37.720622361620293],
              [126.785012627290826, 37.720656236951008],
              [126.785023939227841, 37.720666672856829],
              [126.785040762131956, 37.720669794050288],
              [126.785250865716122, 37.72075999664974],
              [126.78528963473525, 37.720775276015871],
              [126.785298119332765, 37.720782895704929],
              [126.785383170674208, 37.720850903183766],
              [126.785453530850489, 37.720912126455104],
              [126.785462540403842, 37.720922558099097],
              [126.785468006364724, 37.720930730841118],
              [126.785500474798084, 37.720979226110131],
              [126.785701196563934, 37.721066590699799],
              [126.785773417250184, 37.721096562775671],
              [126.785838298973374, 37.721150180671778],
              [126.785926475255252, 37.72117737083628],
              [126.785935850383467, 37.721183018998438],
              [126.786052017101454, 37.721215044360825],
              [126.786161269915652, 37.72124930938741],
              [126.78626928901592, 37.721278787946567],
              [126.786312848243341, 37.721292949517064],
              [126.78631795538827, 37.721303653270503],
              [126.78633902934952, 37.721311575161323],
              [126.786339502233346, 37.721328469081371],
              [126.786484488328384, 37.721384764498595],
              [126.786678574975355, 37.721437491310283],
              [126.786921348936687, 37.721508037384119],
              [126.787225876920374, 37.72186024615231],
              [126.787193838726992, 37.722276036368513],
              [126.787189746034443, 37.722405263046056],
              [126.787371521591766, 37.722732471694393],
              [126.787446649865387, 37.722801306547787],
              [126.787450676476567, 37.722817080708488],
              [126.787513083376808, 37.722872377977509],
              [126.787916239798719, 37.723068221595263],
              [126.788102174619354, 37.723184845585472],
              [126.788349480181154, 37.723224142510638],
              [126.788755685241597, 37.72322403837115],
              [126.788877633933822, 37.72321890666327],
              [126.788944977266851, 37.723281807191832],
              [126.789232726060803, 37.723434372140467],
              [126.789495762462124, 37.723546339611772],
              [126.789772153996523, 37.723577243835699],
              [126.789925517155197, 37.723558085414282],
              [126.79011110827382, 37.723545750817593],
              [126.790378403296003, 37.723536652581686],
              [126.790401089468887, 37.723536413909414],
              [126.790567335115185, 37.723534179782042],
              [126.790820561116604, 37.723670623921741],
              [126.79090054952789, 37.723714968077154],
              [126.790968150592818, 37.723751694940489],
              [126.791065987638518, 37.723809864596987],
              [126.791517513846287, 37.724085742659284],
              [126.791665759236025, 37.724304769373418],
              [126.791797524586983, 37.724402992537811],
              [126.791813589792227, 37.724425824479113],
              [126.792024524046781, 37.724541910116727],
              [126.792336632133953, 37.724728576149566],
              [126.79235663197899, 37.724739873666088],
              [126.792429570361307, 37.72477096913012],
              [126.792686132741068, 37.725677179521242],
              [126.793175485849886, 37.725866959128119],
              [126.793834226138955, 37.726134752235573],
              [126.793990371058186, 37.726644638547235],
              [126.794168278683216, 37.726912987873092],
              [126.794208881380655, 37.726972180423552],
              [126.794291497794163, 37.727093108674829],
              [126.794309159450478, 37.727113411369373],
              [126.794294335864222, 37.727153370019742],
              [126.794347232697874, 37.727188933870394],
              [126.794446034984873, 37.727285960894392],
              [126.794607643093812, 37.727555127805786],
              [126.795024651499929, 37.728076723200665],
              [126.795076651194222, 37.728243780568896],
              [126.795090483705224, 37.72830743073385],
              [126.795110912367988, 37.728418122666092],
              [126.795131244779625, 37.728498118646719],
              [126.795167563284394, 37.728568844676772],
              [126.795221395820064, 37.728651998581441],
              [126.795255279438052, 37.728704989389101],
              [126.795297794031441, 37.728774888322583],
              [126.795292972624978, 37.72897956944778],
              [126.795304708770161, 37.72903026908908],
              [126.795318604845178, 37.729071115982656],
              [126.795315869642195, 37.729096166951187],
              [126.795353272064901, 37.729160705204471],
              [126.79539954358043, 37.729217663842903],
              [126.795473661394979, 37.729336891566696],
              [126.795566502863991, 37.729478387693369],
              [126.795623509238609, 37.729566898661218],
              [126.795631948355691, 37.729591689852214],
              [126.79563852703896, 37.729645191449293],
              [126.795670916906971, 37.729856974051621],
              [126.79569411994764, 37.730051847564255],
              [126.795699379290525, 37.730139421226575],
              [126.795706827220869, 37.730264163382763],
              [126.795664005268094, 37.730431892749756],
              [126.79563605224223, 37.730539112443914],
              [126.795544998391122, 37.730779681359209],
              [126.795492670589667, 37.730918392082536],
              [126.795368460972099, 37.731480996680894],
              [126.795337234401643, 37.731557523807602],
              [126.79532327067561, 37.731733186893884],
              [126.795286542830425, 37.731940343806002],
              [126.795270286752981, 37.731985363443428],
              [126.795260615595325, 37.732020258782313],
              [126.795191950666776, 37.73217132897696],
              [126.795161258340855, 37.732247298386369],
              [126.795081328058586, 37.732435234152412],
              [126.795026225929817, 37.732555073636213],
              [126.795154576046414, 37.732611329177381],
              [126.795575147289668, 37.732882073773659],
              [126.795653563727328, 37.732921060193206],
              [126.795740106284498, 37.732971043464659],
              [126.795659777441799, 37.733176160237818],
              [126.795634203411367, 37.733255508290519],
              [126.795352634543221, 37.733355527450684],
              [126.795172745058281, 37.733475436990489],
              [126.794951267485246, 37.733631870433847],
              [126.794648642922951, 37.733841380864966],
              [126.794303743281318, 37.734080935340593],
              [126.794251833615377, 37.734137154158752],
              [126.794021306012951, 37.734425335488325],
              [126.793933481837243, 37.734582290471714],
              [126.794008480069635, 37.734700106052557],
              [126.794049998423731, 37.734749731989083],
              [126.79433230646994, 37.735026147820165],
              [126.794579269303682, 37.735265056983707],
              [126.794683745210321, 37.735304378085459],
              [126.794794799487349, 37.735336106519739],
              [126.795063197027417, 37.735390346233487],
              [126.795236958217586, 37.735436545373986],
              [126.795546601321107, 37.735508605085748],
              [126.795712680013011, 37.735580404422215],
              [126.795998840411954, 37.735747022848315],
              [126.796008000507072, 37.735769842139703],
              [126.796060762662052, 37.735856651601715],
              [126.796165797025651, 37.735883304850447],
              [126.79619201061, 37.735892918716708],
              [126.796211473852424, 37.735906746327707],
              [126.796235914112685, 37.735913546097599],
              [126.796326950967099, 37.735939607221148],
              [126.796417090580746, 37.73597017151797],
              [126.796536838868562, 37.736001075542028],
              [126.79654390132275, 37.73601121462324],
              [126.796578115063625, 37.736011841714159],
              [126.796650572136869, 37.736024635169201],
              [126.796664736576986, 37.736030579097836],
              [126.796729935470822, 37.736042512987538],
              [126.796809846005388, 37.736055319292696],
              [126.796932645094643, 37.736070173088684],
              [126.797033832237375, 37.736081052070652],
              [126.797238036747871, 37.736080838508691],
              [126.797274894350366, 37.736085407178237],
              [126.797488759659288, 37.7361159056587],
              [126.797591075398202, 37.736166203156287],
              [126.797622046883475, 37.73624874871161],
              [126.797619724625676, 37.73625578119961],
              [126.797700565334736, 37.736318420657447],
              [126.7979389471692, 37.736394576357242],
              [126.798005150401565, 37.736491543964334],
              [126.798039421840798, 37.736537498072629],
              [126.79806056675659, 37.73658370881374],
              [126.798167735469733, 37.736739021171026],
              [126.798264380033245, 37.736855754158675],
              [126.798283036076299, 37.736903645363014],
              [126.798334622039533, 37.736969621552937],
              [126.798342579692488, 37.736976113176802],
              [126.798453005643083, 37.73704415480767],
              [126.798619932870892, 37.736803155922836],
              [126.79876353034912, 37.736605182596115],
              [126.799249430406206, 37.736136400461248],
              [126.799498253384712, 37.735894126272505],
              [126.799536977800372, 37.735867163799377],
              [126.799562731390068, 37.735845810099015],
              [126.799922583575977, 37.735515043564369],
              [126.800469022787098, 37.735009744750947],
              [126.800753926483083, 37.734792035529459],
              [126.800798340315794, 37.734758325116097],
              [126.800852859051489, 37.734717307072444],
              [126.801121051604838, 37.734520110419041],
              [126.801269942440015, 37.734125913581813],
              [126.80128991629519, 37.734080899444486],
              [126.80130296022044, 37.734043756945304],
              [126.801337416411116, 37.733954007636939],
              [126.801368689770214, 37.733790752487401],
              [126.801383869697048, 37.733684924310261],
              [126.801417727986959, 37.733484229573101],
              [126.801358599793005, 37.733063497334854],
              [126.801350396732161, 37.733017588429945],
              [126.80134836332671, 37.732980699614941],
              [126.801343289602656, 37.732956482165065],
              [126.801334482158921, 37.732932816970816],
              [126.801326561778978, 37.732916757397028],
              [126.801190333751663, 37.732557267581008],
              [126.801166932221165, 37.732493592898805],
              [126.801083990111565, 37.732289330058414],
              [126.801111437611439, 37.731840869026037],
              [126.801113180537669, 37.731787652184693],
              [126.801120575714776, 37.731737553269014],
              [126.801121909324223, 37.731701517069737],
              [126.801125749071289, 37.731594246090978],
              [126.801080368678498, 37.731528569922212],
              [126.801033769344571, 37.731456413747736],
              [126.801000229734001, 37.731404272108556],
              [126.800568978557521, 37.730753439838786],
              [126.800854772037937, 37.730132542267683],
              [126.801225683382569, 37.729894985720655],
              [126.801386850280949, 37.729483627923656],
              [126.8017160849124, 37.72931610290015],
              [126.801826709780116, 37.729306992408354],
              [126.801874389862931, 37.729305946960203],
              [126.80189214858234, 37.729295282613272],
              [126.80227285190341, 37.729299584556394],
              [126.802401294080482, 37.729253627260022],
              [126.802582385406907, 37.729142718226605],
              [126.802628028463204, 37.729110135363378],
              [126.802784091619074, 37.729009040314978],
              [126.8029440031397, 37.728927943889246],
              [126.803287810945946, 37.728746645269155],
              [126.803388257244649, 37.728695846695103],
              [126.803399789714888, 37.728693055074288],
              [126.803400875491405, 37.728685741086238],
              [126.80355737526601, 37.728488071602186],
              [126.803648971404371, 37.728369694786188],
              [126.803820023220965, 37.728163598338654],
              [126.80387492087506, 37.727981813135585],
              [126.803956302870574, 37.727702651231745],
              [126.80397155714202, 37.727566964838523],
              [126.803980550875664, 37.727516300776919],
              [126.804016121273051, 37.727269442627133],
              [126.804219573674501, 37.727278513615047],
              [126.804296994180604, 37.727290463782865],
              [126.804305500129786, 37.727291045618379],
              [126.804309384298222, 37.727297809333685],
              [126.804352742912144, 37.727321811416147],
              [126.804570307018466, 37.727283604873556],
              [126.804655248680476, 37.727269664701829],
              [126.804761303496647, 37.727245912188586],
              [126.804768925432484, 37.72724620420729],
              [126.804861980199362, 37.727244107062354],
              [126.80501576401069, 37.727264635142205],
              [126.805152417772263, 37.727328777108667],
              [126.805343142256731, 37.727394135106273],
              [126.805505871401763, 37.727450995225141],
              [126.805621789841794, 37.727519606981062],
              [126.805654617226295, 37.727502489181539],
              [126.805713906911649, 37.727468792570548],
              [126.805764308253629, 37.727441856408284],
              [126.805772295941296, 37.727436797252935],
              [126.805788601672873, 37.727439076731415],
              [126.80606695582064, 37.727404626244841],
              [126.806122999313345, 37.727393177816531],
              [126.806206321928656, 37.727382333154608],
              [126.80622836213989, 37.727360692511176],
              [126.806249628939938, 37.727292308652849],
              [126.806332244970875, 37.727217836765291],
              [126.806391821470456, 37.727208646463964],
              [126.806477049659492, 37.727080958945606],
              [126.807002903196107, 37.726885309808516],
              [126.807123562166979, 37.726902401872358],
              [126.807837456594214, 37.72697846485908],
              [126.808216403464826, 37.727038775568772],
              [126.809179724302055, 37.726686444046969],
              [126.809551085835054, 37.726464061090141],
              [126.809894191513024, 37.726267245396329],
              [126.809981124325034, 37.726232483372705],
              [126.81002922258709, 37.726209190535677],
              [126.810160190958186, 37.726142957194156],
              [126.810138737643712, 37.726078161175543],
              [126.81012580082249, 37.725732116448562],
              [126.810125132578918, 37.725714096109627],
              [126.810106250848293, 37.725544855646696],
              [126.810210255220753, 37.725421421382421],
              [126.810305465561441, 37.725338804393459],
              [126.810539638535403, 37.725110564657854],
              [126.8106632195489, 37.724956186592017],
              [126.810784940501009, 37.724832501187173],
              [126.810941083155456, 37.724692545853877],
              [126.811099585882715, 37.724605237383564],
              [126.811359233842708, 37.724464313407459],
              [126.811459106045433, 37.724429571150026],
              [126.811559665626675, 37.724401010520879],
              [126.811737930897891, 37.724343014322756],
              [126.811862127257839, 37.724292814312946],
              [126.811958746388783, 37.72428368077432],
              [126.81210057693194, 37.724261663956931],
              [126.812345055765817, 37.724232495901539],
              [126.812550309239811, 37.724154837713577],
              [126.812984142771754, 37.723956473514988],
              [126.813232605043311, 37.723827357147599],
              [126.813304305920667, 37.723785802094653],
              [126.81347096083239, 37.723693440019289],
              [126.813565049339047, 37.723630522640221],
              [126.813621345038371, 37.72358500562374],
              [126.813725651244695, 37.723477894139918],
              [126.813732733311284, 37.723480725434918],
              [126.813778104830035, 37.723480509385716],
              [126.814287226032121, 37.723434586061735],
              [126.814386856096633, 37.723495000654438],
              [126.814743553085023, 37.723733753894912],
              [126.814957450200069, 37.724025211744689],
              [126.815322050775848, 37.724434320796412],
              [126.815677358403249, 37.724805402422618],
              [126.815908837528923, 37.724944857735942],
              [126.816245947394506, 37.725009860892399],
              [126.816405531480314, 37.725411040047589],
              [126.817095861518567, 37.725551212595128],
              [126.817305114922021, 37.725575469317818],
              [126.817562394334999, 37.725604584728764],
              [126.817867327614678, 37.725637720038399],
              [126.818185154168859, 37.725621889261326],
              [126.818437209152975, 37.725612424420326],
              [126.818694064672655, 37.72559733546715],
              [126.818851470546434, 37.7255888581386],
              [126.8191042700373, 37.725562499971367],
              [126.819132814298811, 37.725555786912167],
              [126.819217507694901, 37.725567179940235],
              [126.819262328724349, 37.725574565057158],
              [126.81929706756253, 37.725576591864403],
              [126.819457764355491, 37.725599922801358],
              [126.819468593553466, 37.725592056099153],
              [126.819460509570007, 37.725562771332733],
              [126.819443837818412, 37.725496858172406],
              [126.819534193430457, 37.725507412812512],
              [126.819548233040052, 37.725494766945651],
              [126.819742074367397, 37.725513364536454],
              [126.81991986197562, 37.725505475801967],
              [126.820568897708668, 37.725506473757271],
              [126.820860864408289, 37.725479892632713],
              [126.821139406370534, 37.725436955788396],
              [126.821444393512763, 37.725452909577754],
              [126.821467960871573, 37.725454351074717],
              [126.821484068098187, 37.725608953874875],
              [126.821490450608152, 37.725680752342583],
              [126.821537786542592, 37.725825546279204],
              [126.821609803225527, 37.725943060666673],
              [126.821661375454738, 37.726165569053265],
              [126.821682558025145, 37.726201072272133],
              [126.821720495289981, 37.726273774764664],
              [126.821762145929185, 37.726344789096167],
              [126.821808031739408, 37.726428198105921],
              [126.821893694454403, 37.726696365066928],
              [126.821897353139875, 37.726721994020735],
              [126.821961212556616, 37.72692058238988],
              [126.82197368412173, 37.726965370219332],
              [126.821996754212364, 37.727027346534896],
              [126.822052384674123, 37.727185937592168],
              [126.822063813852665, 37.727220867292161],
              [126.822073141543342, 37.727247631070775],
              [126.822085586534484, 37.72730818569142],
              [126.822086780153967, 37.727325927462026],
              [126.822112047884829, 37.727431540715095],
              [126.822190994150816, 37.727621142269186],
              [126.822210523224541, 37.727754631557829],
              [126.822333994478882, 37.728004836390063],
              [126.82254610253932, 37.728317116847137],
              [126.82254004180713, 37.728408050797029],
              [126.822602589420981, 37.728488385453822],
              [126.822631438051758, 37.728577119974318],
              [126.822636193859765, 37.728590074020772],
              [126.82264388304408, 37.728637953821348],
              [126.822749325878391, 37.728862507457933],
              [126.822770389404283, 37.72902358642137],
              [126.822799197524219, 37.729058542388785],
              [126.822815468291367, 37.729075739396279],
              [126.822846592053281, 37.729105347145605],
              [126.822884124056571, 37.72912117086333],
              [126.822904668277445, 37.72913022062442],
              [126.822978676056863, 37.729162425007893],
              [126.822979568475262, 37.729088376263846],
              [126.82297876629319, 37.729049237244119],
              [126.823135393012933, 37.728852956429733],
              [126.823190374910439, 37.728763222669372],
              [126.823214293595058, 37.728694281165218],
              [126.823249625992119, 37.728667026427395],
              [126.823267570575908, 37.728648466706076],
              [126.823299218569744, 37.728610791256557],
              [126.823448472723342, 37.728451375257755],
              [126.823570207185625, 37.728315567794525],
              [126.823598108652661, 37.728288581031855],
              [126.823619226403778, 37.728275945368054],
              [126.823696276826936, 37.728221445266328],
              [126.823752397010836, 37.72817704037378],
              [126.823768249163166, 37.728141584372224],
              [126.823781240424296, 37.728121341312054],
              [126.823803496391761, 37.728078570134585],
              [126.823812788524123, 37.728049311869164],
              [126.823821135439786, 37.728040594123847],
              [126.823824482380715, 37.727973864873796],
              [126.823822906528846, 37.727892784879174],
              [126.823816083853927, 37.727857015391535],
              [126.823810275753729, 37.727838140480756],
              [126.823772279705949, 37.727793593668679],
              [126.823738960413138, 37.727719223025886],
              [126.823721664526801, 37.72768484323953],
              [126.823721611481588, 37.727635857823778],
              [126.823726176865534, 37.727505504430425],
              [126.823798169313903, 37.727416922219682],
              [126.823821846996822, 37.727372468376409],
              [126.823837399066747, 37.727315343756736],
              [126.823898910514245, 37.727236034609355],
              [126.823969140323342, 37.727144359332073],
              [126.824001261942968, 37.727055158341145],
              [126.824026086282686, 37.726976920079501],
              [126.824041256931892, 37.726932174082108],
              [126.824114789409634, 37.72686809116292],
              [126.82416628153436, 37.726827913622195],
              [126.824210315705216, 37.726794752351587],
              [126.824319693320106, 37.72671129845093],
              [126.824371846885867, 37.726693357573829],
              [126.82448859159193, 37.726640880674147],
              [126.824523284275656, 37.726586307392139],
              [126.824569474128168, 37.726538517586675],
              [126.824629532065302, 37.726478351323586],
              [126.824749104653534, 37.726428986728031],
              [126.824840756504372, 37.726343523640075],
              [126.825074215425644, 37.72617888896449],
              [126.82509677827575, 37.72615442555135],
              [126.825131406162058, 37.726131673995717],
              [126.825129431771956, 37.726141527575138],
              [126.825136198176622, 37.726201226486282],
              [126.825138566816506, 37.726249936080265],
              [126.825138901083108, 37.726257261408065],
              [126.825164213511002, 37.726345701746226],
              [126.825198984193605, 37.726406289444455],
              [126.82520018522932, 37.726425995297021],
              [126.825233550106532, 37.726404935633674],
              [126.825263903052502, 37.726386394153693],
              [126.82530171774971, 37.726359142499319],
              [126.825377168272581, 37.726308287785855],
              [126.825504342037163, 37.726267655098646],
              [126.825632544631475, 37.726242790658297],
              [126.825866973845777, 37.726262006332917],
              [126.826018361164401, 37.726251807650229],
              [126.826186900126928, 37.726260509453688],
              [126.826263334904041, 37.726316365673121],
              [126.8262972336329, 37.726371888341419],
              [126.826365202747439, 37.72648235728618],
              [126.826468105010264, 37.72658977875259],
              [126.82652297253712, 37.72662336701422],
              [126.826599519182352, 37.726631922633182],
              [126.826646143545148, 37.726625522888149],
              [126.826695787855442, 37.726617992389841],
              [126.826761370086629, 37.726614990310978],
              [126.826819351617843, 37.726607472118857],
              [126.827114496787814, 37.726590457459025],
              [126.827182073442216, 37.726641515863825],
              [126.827384732527733, 37.726607182365555],
              [126.827555989334613, 37.72658913664123],
              [126.827576188252081, 37.726590571946865],
              [126.827823202293771, 37.726617965022378],
              [126.827999196413984, 37.726616251079676],
              [126.828064613026697, 37.726611283933082],
              [126.82807949593564, 37.726611026530627],
              [126.82809845713885, 37.726613027532494],
              [126.828314372444353, 37.726672745422199],
              [126.828367509337113, 37.726690004837494],
              [126.828472342193621, 37.726729575882331],
              [126.828539843950281, 37.726813293299514],
              [126.828562839686114, 37.726836409704092],
              [126.828644400268672, 37.726898749818773],
              [126.828666664395499, 37.726929198966886],
              [126.828696894131198, 37.726958524574805],
              [126.828703263375075, 37.726965849729041],
              [126.828724657174192, 37.726990098966368],
              [126.828781287201707, 37.727100549890679],
              [126.828790303591035, 37.727110978230449],
              [126.82874740206698, 37.727267736937314],
              [126.828849031451995, 37.727466097623925],
              [126.828861198764287, 37.72749229741941],
              [126.829007003597653, 37.727674397208062],
              [126.829051005175486, 37.727727951739681],
              [126.828942735510992, 37.727795086198299],
              [126.828916017296407, 37.727850789641458],
              [126.829003595009652, 37.72792131003856],
              [126.829178771467028, 37.727970272288694],
              [126.82930157122972, 37.728060268701],
              [126.82959741394582, 37.728278625328151],
              [126.82973710045566, 37.72834611283443],
              [126.829754795514958, 37.72835627444563],
              [126.829889014804152, 37.728413906285006],
              [126.830012351255903, 37.728503055828277],
              [126.830363041964148, 37.728608869986864],
              [126.830481030522606, 37.728631565288083],
              [126.830558092132264, 37.72864912870876],
              [126.830630366159426, 37.728666973447339],
              [126.830748900967208, 37.728684038256496],
              [126.830803805583685, 37.728702983930312],
              [126.830990992863391, 37.728773926342107],
              [126.831090557702026, 37.728792648166205],
              [126.831183926062707, 37.728807703045632],
              [126.831315858665704, 37.728784810935501],
              [126.831389247087003, 37.728856146961306],
              [126.831478101487363, 37.728910612202512],
              [126.831601451189556, 37.728990191876115],
              [126.831644762671075, 37.728964630920593],
              [126.8316477680726, 37.728964914549557],
              [126.831700298831734, 37.72893570894967],
              [126.831749480213119, 37.728902840595239],
              [126.831883140489083, 37.728813783652313],
              [126.831967673433979, 37.728743801364963],
              [126.832063216906235, 37.728660590693714],
              [126.832127002903306, 37.728590299168246],
              [126.832383904409738, 37.728244076724003],
              [126.832511290109238, 37.728109961923849],
              [126.832648018305605, 37.728002600936563],
              [126.832763135987221, 37.727885640632664],
              [126.832874885594194, 37.727768116798792],
              [126.832898524124218, 37.727738590025581],
              [126.832971616192253, 37.727630579022637],
              [126.833170990143302, 37.727323689660523],
              [126.833241096454202, 37.72720638529227],
              [126.833319003132047, 37.72708064097796],
              [126.833335214633607, 37.727049689005213],
              [126.83335494939061, 37.727020724173897],
              [126.833365992555485, 37.726997936556913],
              [126.833401954132469, 37.726926190117673],
              [126.833437633980552, 37.726823477172346],
              [126.833444504214071, 37.726764915372982],
              [126.833465528700486, 37.726712302085545],
              [126.833504196799169, 37.72654202116032],
              [126.833522413926886, 37.726481790723298],
              [126.833547832761937, 37.726291498528994],
              [126.833559798628201, 37.726096123745947],
              [126.833568173706311, 37.725838235028085],
              [126.833605223674752, 37.725364435310617],
              [126.833825722251532, 37.725278310756849],
              [126.833938544057361, 37.725231170618606],
              [126.83402121568723, 37.725672759997622],
              [126.834053113782957, 37.725752765779639],
              [126.834142230619747, 37.726002350966198],
              [126.834213066851916, 37.726184608202971],
              [126.834305684508365, 37.726456722289157],
              [126.834351521116815, 37.726644016389685],
              [126.834400733914251, 37.726747417591923],
              [126.834554787185013, 37.727050296313088],
              [126.83458407759575, 37.727110035587224],
              [126.834591481508966, 37.727126650815322],
              [126.834636099692588, 37.727225540591746],
              [126.834725796022312, 37.727380236700917],
              [126.834753641037864, 37.72744728970244],
              [126.834887407150987, 37.727708190432665],
              [126.834963914173684, 37.727816414069544],
              [126.835199789847096, 37.727668935130495],
              [126.835335914152793, 37.727591139758424],
              [126.835342285344254, 37.727592833539504],
              [126.835349024535162, 37.727592284441755],
              [126.835368582224149, 37.727566130029601],
              [126.835572734516944, 37.727655099335273],
              [126.835702400626076, 37.727690761695598],
              [126.835881195770469, 37.727629351147826],
              [126.836050566561696, 37.727500634192566],
              [126.836239878958821, 37.727494151539901],
              [126.836307754905846, 37.727495372882458],
              [126.836453486690729, 37.727478395657108],
              [126.836595482062521, 37.727463395142642],
              [126.836687294887923, 37.727463523546881],
              [126.836737983191227, 37.727465846812962],
              [126.836750231249908, 37.727456854304002],
              [126.836768664760726, 37.727456600767148],
              [126.836888373796654, 37.727424666691562],
              [126.836902732556922, 37.727425533646965],
              [126.837037855954279, 37.727471049683324],
              [126.837133238175241, 37.727539601833115],
              [126.837148275994409, 37.727551443431956],
              [126.837174629758522, 37.72757569805173],
              [126.837234225711754, 37.727638280901203],
              [126.837218017093107, 37.727673738226201],
              [126.837210219851471, 37.727675700467699],
              [126.837171391712388, 37.72768070975637],
              [126.837172791593815, 37.727688874430306],
              [126.837191361091328, 37.727704108566556],
              [126.837310322747953, 37.727769305815492],
              [126.837344107478117, 37.727803427278985],
              [126.837381570464714, 37.727851626891564],
              [126.837433879540214, 37.727921524318916],
              [126.837479163057424, 37.727962409957421],
              [126.837525225825289, 37.727974015374386],
              [126.837573415404179, 37.72798309202588],
              [126.837619634690341, 37.728000896247018],
              [126.837836183227978, 37.728097762287163],
              [126.838005072651043, 37.728109546996834],
              [126.838036266437982, 37.728109869556455],
              [126.838087809701022, 37.72812598716677],
              [126.83811011807326, 37.728133063618579],
              [126.838178671558538, 37.728151457172359],
              [126.838183467353971, 37.728152590020301],
              [126.838219768605526, 37.728161929234346],
              [126.838313157009807, 37.728173320589192],
              [126.838365765198759, 37.728184934738735],
              [126.838412007249175, 37.728197675270195],
              [126.838464750684679, 37.72823039911821],
              [126.838507414518531, 37.728255802198319],
              [126.838680665490628, 37.728374013681041],
              [126.838722089726616, 37.728395748052783],
              [126.838734312067359, 37.728403648353279],
              [126.838754137239107, 37.728415505365902],
              [126.838880662651519, 37.728508866549824],
              [126.838925082208405, 37.728540749821313],
              [126.838974625655268, 37.728579388350823],
              [126.839059378836367, 37.728561197614567],
              [126.839085785236662, 37.728561801607349],
              [126.839096062092409, 37.728567167484627],
              [126.839109161019124, 37.728573663452096],
              [126.839141749593111, 37.728584682067293],
              [126.839153617613476, 37.728588644629404],
              [126.83921490858117, 37.728603369669095],
              [126.839271369001892, 37.728638080389594],
              [126.839298291679071, 37.728651631878193],
              [126.839405952469775, 37.728698792202145],
              [126.839415961039023, 37.728738790685306],
              [126.839426927750537, 37.7287503470953],
              [126.839463069619299, 37.728838240636001],
              [126.839480846412087, 37.728894854531411],
              [126.839515882867872, 37.729000765785919],
              [126.839568254833182, 37.729043074867981],
              [126.839593738046659, 37.72914474745847],
              [126.839601879725109, 37.729229503177677],
              [126.839629400561222, 37.72929148214709],
              [126.839659497905217, 37.729305884816242],
              [126.839655409609605, 37.729313204028408],
              [126.839664538514739, 37.729350939862798],
              [126.839699464552425, 37.729346203697467],
              [126.839706373744008, 37.729350997291952],
              [126.839737578468061, 37.729346535305893],
              [126.839865119668247, 37.729297436621856],
              [126.839941205975038, 37.729274458268499],
              [126.839953267898693, 37.729272780990136],
              [126.840042193553359, 37.729292327579145],
              [126.840186689815468, 37.729359818301234],
              [126.84024101378003, 37.729396778036396],
              [126.840308422097081, 37.729452333486456],
              [126.84031709727185, 37.729458823269532],
              [126.840319560766304, 37.729468403869561],
              [126.840297184487142, 37.729570857755022],
              [126.840203000917541, 37.729846648671717],
              [126.840285244164448, 37.730254158367458],
              [126.840547366583451, 37.730347712122011],
              [126.840577291334569, 37.73036351982045],
              [126.840733423738882, 37.73045438770162],
              [126.840876533222456, 37.730666308948905],
              [126.840883524086109, 37.730874954317777],
              [126.840887450971422, 37.730947595248431],
              [126.840894496348071, 37.731046999006594],
              [126.841039660050555, 37.731211901615353],
              [126.841277821239004, 37.731324566561533],
              [126.841337945281737, 37.731392499736096],
              [126.84138514741413, 37.731445207085287],
              [126.84149660376616, 37.731544473282575],
              [126.841689610057642, 37.731634263727052],
              [126.841972185159207, 37.731535540654811],
              [126.842470469959878, 37.731683746529825],
              [126.842502668389898, 37.731713629833727],
              [126.842506729431548, 37.731718987026909],
              [126.842708668507527, 37.731780641792128],
              [126.842747108983232, 37.731788009383187],
              [126.842812758271606, 37.73184047071306],
              [126.842878067425147, 37.731887570823432],
              [126.842940888693022, 37.731936370369155],
              [126.843075686135379, 37.73197540110511],
              [126.843200408510427, 37.732004570640527],
              [126.843250909577804, 37.732016468083899],
              [126.843476761510189, 37.732078991527885],
              [126.843545168354538, 37.73208865143372],
              [126.843733015293608, 37.732104390545977],
              [126.843761722257696, 37.732106681370631],
              [126.844053754163582, 37.732143389591364],
              [126.844168281713095, 37.732133694997259],
              [126.844534964763469, 37.732156419605325],
              [126.844959132706848, 37.732155578183153],
              [126.84571277328169, 37.732340994586039],
              [126.845898361533131, 37.732422884911664],
              [126.845988187525776, 37.732442995804703],
              [126.846187396804055, 37.732537580180932],
              [126.846360319710527, 37.732573558079466],
              [126.846361697173379, 37.732592993644836],
              [126.846551011162859, 37.732754848228694],
              [126.846726352678971, 37.732909935953131],
              [126.846895865347918, 37.733053465434949],
              [126.846977476811944, 37.733101999159778],
              [126.847041863690052, 37.73316345708227],
              [126.847078175734978, 37.733174775694501],
              [126.847401272949384, 37.733365238451967],
              [126.84759244446829, 37.733492190507796],
              [126.848206262940295, 37.733755116261065],
              [126.849058898975315, 37.734165871414227],
              [126.849143839690853, 37.734152187510695],
              [126.849653806337173, 37.734061902484299],
              [126.849707136729052, 37.734078305614375],
              [126.850366383342731, 37.734233154163839],
              [126.85120109899681, 37.734403719445211],
              [126.851701933182312, 37.73453837508616],
              [126.852116220492633, 37.734606193708821],
              [126.852634637363167, 37.734720316728286],
              [126.853425416872554, 37.734884323831942],
              [126.853685342634776, 37.734940960194663],
              [126.853755654740496, 37.734970050310061],
              [126.854185319426932, 37.735060125680995],
              [126.854280268189001, 37.735091210368765],
              [126.854451947149428, 37.735135914166953],
              [126.854869893036309, 37.735238351636177],
              [126.855033558674378, 37.735302748651826],
              [126.855996805915268, 37.735561562681148],
              [126.856032912562796, 37.735586383695711],
              [126.857711066405486, 37.734833319729198],
              [126.85797589515262, 37.734649512918367],
              [126.858116492046719, 37.734541000998746],
              [126.858466067262327, 37.73424805449902],
              [126.858910240739021, 37.734173695697642],
              [126.85881006084017, 37.733356798311647],
              [126.860080373443964, 37.733210796723725],
              [126.860294475017668, 37.732941044055451],
              [126.860518444382222, 37.732886695824455],
              [126.860643493264433, 37.732843761313511],
              [126.86112735512539, 37.732676255030029],
              [126.86148117427831, 37.732757203869866],
              [126.861953174387097, 37.73258010287234],
              [126.862290440865706, 37.732698473678155],
              [126.862827067415751, 37.732557204679615],
              [126.863299465386305, 37.732639684003694],
              [126.863548323568622, 37.732742748287968],
              [126.864151777310795, 37.732600983846339],
              [126.864944906788381, 37.732635970758942],
              [126.865279421775782, 37.731936985813547],
              [126.865367600339013, 37.731892886471705],
              [126.865617345056151, 37.731796600484763],
              [126.865754094531042, 37.731648946155808],
              [126.866256940431924, 37.731654875903594],
              [126.86644227498941, 37.73081381466308],
              [126.867084153823555, 37.729993266874622],
              [126.867918399046857, 37.729435618345235],
              [126.867982668070709, 37.729077837735126],
              [126.867917057495305, 37.72869851208074],
              [126.867978296378141, 37.728448564250513],
              [126.868878491174826, 37.728762106535427],
              [126.869477776845002, 37.729142309898364],
              [126.869915717073454, 37.72916307125999],
              [126.869935207755333, 37.729168156427633],
              [126.870099710795358, 37.729252805192544],
              [126.87033045239788, 37.729271649053246],
              [126.871541726270252, 37.729381664886368],
              [126.871571321748121, 37.729485596547143],
              [126.871714746548278, 37.72967016362427],
              [126.871747822501774, 37.729710464066137],
              [126.872239520561862, 37.730105454808104],
              [126.872280077791032, 37.729920801912385],
              [126.872326507132385, 37.729718703782659],
              [126.872475335659445, 37.729444631723219],
              [126.873000859068895, 37.72924446226309],
              [126.87302166100018, 37.728799355201247],
              [126.87325565974912, 37.728060541057488],
              [126.873186746874083, 37.727831279072554],
              [126.873068149774326, 37.727431626822252],
              [126.873002964366293, 37.726798629126918],
              [126.873405978333281, 37.726397301209381],
              [126.873657706215255, 37.72596201276594],
              [126.874366671725213, 37.72595039129844],
              [126.874948881758456, 37.725849659413697],
              [126.875294601687855, 37.725365753202198],
              [126.876463930748855, 37.725400794502313],
              [126.87732283446951, 37.725715066562266],
              [126.877633618144927, 37.726188399351933],
              [126.878831356590538, 37.725884450529264],
              [126.879782559465752, 37.726118281571765],
              [126.880754818101977, 37.725606570239435],
              [126.882142529957093, 37.725861100264872],
              [126.882690141881312, 37.725555605201741],
              [126.883182715612222, 37.725419550620629],
              [126.883636739824311, 37.725006967726522],
              [126.883867936532383, 37.725061824098631],
              [126.884591743951944, 37.725300173346412],
              [126.885020044229535, 37.72478676878989],
              [126.885252682048545, 37.724826974203665],
              [126.885384370512469, 37.724829077111075],
              [126.885762460416643, 37.72502175063579],
              [126.886041743056467, 37.725046512716872],
              [126.886297173698523, 37.724908807081057],
              [126.886481644781938, 37.724820575661951],
              [126.886632166003338, 37.724548748527717],
              [126.886961928133445, 37.72413575229394],
              [126.887177956488088, 37.723683227686998],
              [126.887342714450611, 37.723495869751815],
              [126.887573960239422, 37.723524816205142],
              [126.888332937955852, 37.723472894979999],
              [126.88853398856736, 37.722964890107384],
              [126.889516185976035, 37.723792741002526],
              [126.890104016160677, 37.724299531545007],
              [126.89040245966703, 37.724663298029469],
              [126.890755223109352, 37.72521292953197],
              [126.891249248366535, 37.725402315713012],
              [126.891635237004806, 37.725658324535694],
              [126.89229801902836, 37.725592773662683],
              [126.892454886551377, 37.725582224449255],
              [126.892454083786021, 37.725650075275659],
              [126.892578068295805, 37.725701148412128],
              [126.892753604264485, 37.72577761309519],
              [126.893131845462818, 37.726007427857688],
              [126.893353394990356, 37.72600283821059],
              [126.894023872166613, 37.726986916171562],
              [126.894111598752559, 37.727114257209308],
              [126.895219699585994, 37.726990252891724],
              [126.896860317239913, 37.727173039666312],
              [126.897069641816628, 37.727047369731778],
              [126.897336603161676, 37.726887684943406],
              [126.899275256976807, 37.726881219498445],
              [126.900144498432027, 37.727226021074145],
              [126.900875909254708, 37.72791841643128],
              [126.901922958949697, 37.728117526591049],
              [126.902640770316296, 37.728665745306699],
              [126.902717214646017, 37.729024221563307],
              [126.901983791903916, 37.730339578263042],
              [126.902166232205715, 37.731759027934061],
              [126.902754404688906, 37.732257015755664],
              [126.902939926574518, 37.732564624540807],
              [126.903054173207337, 37.732761814600543],
              [126.903143970043743, 37.732944613789513],
              [126.903026208354092, 37.733396681517874],
              [126.902373990177878, 37.734538383910852],
              [126.901274588832166, 37.735623960504284],
              [126.901204909913616, 37.736557523162006],
              [126.901127427691222, 37.736706962830375],
              [126.901041934455293, 37.736874135556306],
              [126.900994070739969, 37.737008392133625],
              [126.900918099714119, 37.737214134049374],
              [126.901597394781788, 37.737729395282365],
              [126.901975240566387, 37.738447385058784],
              [126.901798513253084, 37.738975983054956],
              [126.90304481374605, 37.740496556439091],
              [126.903533417994467, 37.74073347498085],
              [126.903517565722169, 37.741596132240254],
              [126.905883863078316, 37.742656984502894],
              [126.906804478523355, 37.743615278212381],
              [126.907884471983479, 37.744033114799358],
              [126.90790155956266, 37.744113656255131],
              [126.908118385688269, 37.744948622510783],
              [126.908727778643652, 37.745604832022309],
              [126.909220343915706, 37.746686371435707],
              [126.909362973348578, 37.746900740445909],
              [126.91042452122754, 37.746644233312061],
              [126.910943741136137, 37.746539896787773],
              [126.911731682616434, 37.747347985453175],
              [126.912303300494727, 37.747463291954972],
              [126.912390558124429, 37.747431824355829],
              [126.912469706418634, 37.747369096249436],
              [126.91271790108938, 37.747223724336038],
              [126.91281708856576, 37.747159326293627],
              [126.913028370734551, 37.747043765746447],
              [126.914493805779912, 37.747210966907069],
              [126.914728333251375, 37.747387674017808],
              [126.915225840987475, 37.747806147987433],
              [126.915416544722561, 37.747861750570827],
              [126.915678083409077, 37.747673018959276],
              [126.917056619672465, 37.748032714213075],
              [126.91793722913404, 37.747864691196767],
              [126.917940634498791, 37.747833159999004],
              [126.918063398453427, 37.747756665354977],
              [126.918203047919746, 37.747647802002611],
              [126.918302765765233, 37.747572137716581],
              [126.918979629354268, 37.74728290081643],
              [126.919683757331683, 37.747010283647555],
              [126.920218956639488, 37.747027826328591],
              [126.921612265031271, 37.7470178054432],
              [126.922889439477913, 37.747022033963624],
              [126.923653179858363, 37.747373356980553],
              [126.924468305557113, 37.747581113561068],
              [126.925669856951117, 37.748205528684352],
              [126.926637826737988, 37.748241906738144],
              [126.927070030057038, 37.748273425834803],
              [126.928284486984268, 37.748420313443035],
              [126.928341939587852, 37.748415276720181],
              [126.928566377210757, 37.748424146155678],
              [126.928989163003806, 37.748480717102282],
              [126.929067174754877, 37.74847653957832],
              [126.929096417960722, 37.748485008558433],
              [126.929643694117061, 37.748136221428311],
              [126.930188663968877, 37.747785177937402]
            ],
            [
              [126.82277046193532, 37.663313780329759],
              [126.822745769474693, 37.663269820502435],
              [126.822732525583703, 37.663258258972938],
              [126.822745780808219, 37.663269820519602],
              [126.82277046193532, 37.663313780329759]
            ]
          ]
        ]
      }
    }
  ]
};

// 구 경계선
export const districtData = {
  type: 'FeatureCollection',
  name: 'SIGUNGU',
  crs: {
    type: 'name',
    properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' }
  },
  features: [
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        SIGUNGU_NM: '고양시 덕양구',
        SIGUNGU_CD: '31101'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.929096420734027, 37.748485009009173],
              [126.929643695818982, 37.74813622147839],
              [126.929771703942123, 37.748053764632743],
              [126.930188667454914, 37.747785178656429],
              [126.930665501759975, 37.747488148201235],
              [126.931289954628269, 37.74706507046087],
              [126.931846290617045, 37.746677987736099],
              [126.93216417822164, 37.746470954732615],
              [126.932550185192042, 37.746218912548372],
              [126.93304847542268, 37.745901056017928],
              [126.933072417532273, 37.745885861395898],
              [126.932611637247803, 37.745589964161887],
              [126.930460499100604, 37.744208527022415],
              [126.930362347821699, 37.744145400293426],
              [126.930383567313939, 37.743224811835873],
              [126.930457043475045, 37.740037073959861],
              [126.931140019814009, 37.737649935681141],
              [126.93102393028056, 37.737508440626534],
              [126.931007674121901, 37.737488625974343],
              [126.930437453335031, 37.736793599541649],
              [126.930619469261458, 37.736271151344972],
              [126.930627058491467, 37.736116586519735],
              [126.930631037145304, 37.73603690748515],
              [126.93024886070971, 37.73604209836062],
              [126.929995727507048, 37.736045534393163],
              [126.929885513771481, 37.736056742873686],
              [126.929383042715614, 37.736108227578832],
              [126.92856920138739, 37.736161506244898],
              [126.928566184911745, 37.736161783649187],
              [126.92848487734868, 37.736103728469821],
              [126.928379727380815, 37.736028724580002],
              [126.927971012790522, 37.735737181374887],
              [126.927137772280631, 37.735142802351895],
              [126.927046998247633, 37.73516076430564],
              [126.926795719307464, 37.735190504786033],
              [126.926597418180222, 37.735213975094936],
              [126.926593162513115, 37.73521453484306],
              [126.9265901865123, 37.735154387052248],
              [126.926586559598647, 37.735081075932364],
              [126.926537584553472, 37.734079288966456],
              [126.925528959768528, 37.733078573973842],
              [126.92515461840776, 37.733060312601545],
              [126.924267963613815, 37.732964092303028],
              [126.923317065915526, 37.732860894827752],
              [126.922841057521893, 37.732437127111247],
              [126.922761742839583, 37.732346978140811],
              [126.92244202542345, 37.732097012952771],
              [126.922104333286072, 37.731832987421924],
              [126.9220930190003, 37.731805950052298],
              [126.922074863545305, 37.731791507530041],
              [126.922070349790289, 37.731787915273856],
              [126.921968649877911, 37.731418452446363],
              [126.92183351557442, 37.73049036965152],
              [126.921629580050094, 37.730255981509515],
              [126.921675408756315, 37.729832555402581],
              [126.921689395497552, 37.729685604332637],
              [126.921732774176135, 37.729228952501934],
              [126.921873102739156, 37.729186336875465],
              [126.922413612953562, 37.729022189059421],
              [126.923328182279576, 37.728715254201035],
              [126.923378170004526, 37.728698477294984],
              [126.923375549164987, 37.728632254403763],
              [126.92334500939927, 37.727860568667495],
              [126.923356467043959, 37.727752460881746],
              [126.923447715333964, 37.727275010974289],
              [126.923357706852585, 37.726572198824869],
              [126.923426183542887, 37.726175821181201],
              [126.923222431550514, 37.725770250545843],
              [126.923098056468945, 37.725391763322982],
              [126.92308002910616, 37.725046024064049],
              [126.92307793894831, 37.725005934811328],
              [126.92306470267755, 37.724752057241311],
              [126.923155655066935, 37.724553905324377],
              [126.92364369258334, 37.724274930674028],
              [126.923734878765075, 37.72385153755868],
              [126.924117444031651, 37.723633754207697],
              [126.92417751001642, 37.723599558569326],
              [126.924117538392068, 37.723529382612377],
              [126.923792327338973, 37.723148824135038],
              [126.923838329528067, 37.722545207179493],
              [126.924013364481368, 37.722275412007392],
              [126.924042823563283, 37.722230004970008],
              [126.924038052221334, 37.72217709123688],
              [126.923986758727679, 37.721608302582247],
              [126.924134435010828, 37.721383158350399],
              [126.924168622531099, 37.72123902561669],
              [126.924656774200571, 37.720824901169813],
              [126.924861501890419, 37.7202754415272],
              [126.924872929690906, 37.720194367533985],
              [126.924850642371197, 37.719806937543865],
              [126.924763383514389, 37.719732029309185],
              [126.92398940667502, 37.719067584331547],
              [126.923926232666446, 37.718941827016593],
              [126.923695125021069, 37.718481763479389],
              [126.923683793936775, 37.718472745346524],
              [126.923661156213157, 37.718427682166386],
              [126.923649899051838, 37.718346587502111],
              [126.923650018617181, 37.718231418106029],
              [126.923650049139184, 37.71820243445422],
              [126.923672827182685, 37.718112352348754],
              [126.923695718797191, 37.71791415545048],
              [126.923749603892276, 37.717790827805715],
              [126.923798041271766, 37.71767997123559],
              [126.923798182543607, 37.71754482773985],
              [126.923492859930036, 37.716679699343445],
              [126.923266584574989, 37.716147979020697],
              [126.92331211109213, 37.715994845390647],
              [126.923265771580859, 37.715906408132021],
              [126.923142329383438, 37.715670386105373],
              [126.922417006112298, 37.715165360993282],
              [126.922190186075298, 37.715156198970867],
              [126.921726393097018, 37.714029678885183],
              [126.92105724631719, 37.714038232903391],
              [126.920999658818488, 37.71403453589727],
              [126.920909818348605, 37.714029122964071],
              [126.92072710432511, 37.713928139855575],
              [126.92050177685617, 37.713803602772423],
              [126.920549599404438, 37.713762065140664],
              [126.920740168248685, 37.713596543599138],
              [126.920955891755241, 37.713380458527283],
              [126.921398384692878, 37.713209577040168],
              [126.921534452165318, 37.713227688915289],
              [126.922453394765355, 37.71293999974953],
              [126.923066178332888, 37.712598042202664],
              [126.923769730863057, 37.712211093026532],
              [126.924563984013133, 37.71184221759551],
              [126.924602005062326, 37.711512964301384],
              [126.924674850617976, 37.710882127052187],
              [126.924678418222982, 37.710851229087886],
              [126.924804067270372, 37.709977375058472],
              [126.92447560737547, 37.709571725324153],
              [126.924577775930558, 37.70947268548634],
              [126.92456665752735, 37.709256447086375],
              [126.924611558709302, 37.709221866135302],
              [126.925349775608282, 37.708653308295993],
              [126.924432531308085, 37.707364338614106],
              [126.923752565213334, 37.706931410580012],
              [126.923639407871917, 37.706697093856377],
              [126.923496726021099, 37.706569348455666],
              [126.923458135911076, 37.706534798610392],
              [126.923356186936957, 37.70642661533855],
              [126.923361810151121, 37.705906416948253],
              [126.923368749350615, 37.705264371402642],
              [126.923562028529332, 37.70478699301956],
              [126.922965720869428, 37.704597021841913],
              [126.92288186738061, 37.704570308111698],
              [126.922530526327122, 37.70438087102395],
              [126.922236495331404, 37.703614859291875],
              [126.922248050285873, 37.703576748451987],
              [126.922373062801213, 37.703164459065455],
              [126.922357400788997, 37.703151339280126],
              [126.922157789559307, 37.702984121935792],
              [126.922164240319788, 37.702763109260324],
              [126.922165095819508, 37.70273357075736],
              [126.922169574280929, 37.70257869387035],
              [126.922489768862818, 37.702113218087163],
              [126.922510285584593, 37.702083391008266],
              [126.922476593104861, 37.701777038983366],
              [126.922175457836687, 37.701543837735144],
              [126.922057380111667, 37.701452399256802],
              [126.922056001773967, 37.701422162088789],
              [126.922035219860987, 37.700965871210023],
              [126.92212950372199, 37.700762637643344],
              [126.922317424935898, 37.700357565945794],
              [126.922319355030311, 37.700353404974038],
              [126.92221751297788, 37.700155128386037],
              [126.921848060920951, 37.69975236873519],
              [126.921696518416098, 37.699587162737728],
              [126.92167432377731, 37.699136663856635],
              [126.921447940667179, 37.698767113491449],
              [126.921413953727821, 37.698740062020853],
              [126.921040057899816, 37.698469516711505],
              [126.921068456734133, 37.69826800099451],
              [126.921097191414006, 37.698064120033649],
              [126.920921355885056, 37.697867496144987],
              [126.920621538020271, 37.697532223194507],
              [126.920440695241652, 37.697000528137963],
              [126.920395516743071, 37.696838312779214],
              [126.920202928086397, 37.696685025866898],
              [126.920100965878333, 37.696603868525095],
              [126.920026321525555, 37.696493447849782],
              [126.920021724714118, 37.696486677922792],
              [126.919180167670604, 37.696016752674154],
              [126.919149187811712, 37.695999557824337],
              [126.919070098012483, 37.695747231683235],
              [126.919021766254161, 37.695437790446292],
              [126.918991287234618, 37.695242635014637],
              [126.918925302919916, 37.695010110536096],
              [126.918720227853356, 37.6942874178471],
              [126.918733933596172, 37.69419643084148],
              [126.918738870627777, 37.694163655055185],
              [126.918857297418555, 37.693377535092544],
              [126.918788465225717, 37.693139857079601],
              [126.918703223565643, 37.692845008183006],
              [126.918687876283613, 37.692791786780624],
              [126.918653901013442, 37.692755724909233],
              [126.918115465730963, 37.692563041001442],
              [126.917871886937064, 37.692475872201165],
              [126.917656890825739, 37.692097312165892],
              [126.917634510653755, 37.691836015564554],
              [126.917306046469633, 37.691538460788081],
              [126.917341034529741, 37.691499146601522],
              [126.917390581418914, 37.691443474973667],
              [126.917714727838302, 37.691079258622921],
              [126.91689907216049, 37.69049304492215],
              [126.91703585767084, 37.689844446764909],
              [126.916082002600191, 37.688982774017269],
              [126.916027854837026, 37.688933740556102],
              [126.915644245230538, 37.688569936331561],
              [126.915257752795355, 37.688203394550648],
              [126.914907665958467, 37.687022867966014],
              [126.91481054996872, 37.686734533187959],
              [126.914704299042995, 37.686419068130441],
              [126.914195810183955, 37.684986151229438],
              [126.914218724681149, 37.684769935913877],
              [126.914230304662937, 37.684571730472712],
              [126.914287434733282, 37.684193365196116],
              [126.914356010883694, 37.683715901777006],
              [126.914390266180519, 37.683508703685355],
              [126.914401643876232, 37.683463663209615],
              [126.914391024762381, 37.682860006467145],
              [126.914391582928928, 37.682382491763541],
              [126.914391931129089, 37.682085171033549],
              [126.914392057519166, 37.681977056611963],
              [126.914377619936545, 37.681963139638803],
              [126.914176909100121, 37.681769674368162],
              [126.914178340909231, 37.681768157336883],
              [126.914210957529491, 37.681733659731044],
              [126.914239625838846, 37.681756484917713],
              [126.914278913039865, 37.681787768611109],
              [126.914279982343587, 37.681786921266728],
              [126.914287241724594, 37.681781863021385],
              [126.914483136431045, 37.681643762839435],
              [126.914630624347311, 37.68154476498399],
              [126.914574112020858, 37.681400569605259],
              [126.914521302149822, 37.681271015705271],
              [126.914415855539886, 37.681013035346069],
              [126.914398162282055, 37.680949481455798],
              [126.9143707016311, 37.680850826454851],
              [126.914291632713301, 37.680607509948132],
              [126.91428036104287, 37.680553439243511],
              [126.914167367149986, 37.680238015634153],
              [126.914065700566539, 37.679931610977484],
              [126.91390748095408, 37.679517047144586],
              [126.913869008289794, 37.679401864626008],
              [126.913862286467847, 37.679381866822141],
              [126.913762377715273, 37.679251719729237],
              [126.913613287770687, 37.679057331993377],
              [126.913420833502684, 37.678840955931399],
              [126.913160455389274, 37.678543441205029],
              [126.913136824371932, 37.678453884912201],
              [126.913115317650139, 37.678372222193055],
              [126.913104279499947, 37.678119941633057],
              [126.913093284900683, 37.677831622604906],
              [126.913093470614498, 37.677829929033635],
              [126.913127862272717, 37.677354134410564],
              [126.913107813030066, 37.677149824484815],
              [126.913094261750302, 37.677011740377004],
              [126.913091979264806, 37.676955992728693],
              [126.913083762793462, 37.676754955803851],
              [126.913083245554233, 37.676741440527458],
              [126.913004335133252, 37.676371983012977],
              [126.912994618164973, 37.676354237082805],
              [126.912789431092278, 37.675975393533413],
              [126.912751063121263, 37.675920459460137],
              [126.912732848905222, 37.675894264514284],
              [126.912472530882326, 37.675560708877754],
              [126.912449903997057, 37.675524653011927],
              [126.912212108833728, 37.675317249757541],
              [126.912026484812429, 37.675164501253121],
              [126.91187238119339, 37.675037690318476],
              [126.911453299676097, 37.67476708069978],
              [126.911034294624415, 37.674584591887687],
              [126.9101051132224, 37.674180410257861],
              [126.909504602628132, 37.673972719075266],
              [126.909096646093587, 37.673882305172413],
              [126.908905940343089, 37.67384836726589],
              [126.908884864484563, 37.673844642805641],
              [126.908790670875362, 37.67382799634769],
              [126.908575250965086, 37.673863874351177],
              [126.908325841558195, 37.673899717551009],
              [126.90799691894739, 37.674052622165462],
              [126.90799008857357, 37.67405739105579],
              [126.907777810504527, 37.674205614037639],
              [126.907713290371774, 37.674250610601021],
              [126.907596048703098, 37.674395250883556],
              [126.907594260760007, 37.674393784465053],
              [126.907160050231553, 37.674075365542883],
              [126.906921458347142, 37.673900397231435],
              [126.906877063229331, 37.674073942746787],
              [126.90687052544591, 37.674099497659689],
              [126.90683600415953, 37.674184513030461],
              [126.906830444826483, 37.674251252719422],
              [126.906827700341623, 37.674292880253724],
              [126.906816355199709, 37.674464915481231],
              [126.906757128060036, 37.674768081964878],
              [126.906658399193972, 37.674808369485611],
              [126.906601929474576, 37.674646148362598],
              [126.906534205307096, 37.674420851554451],
              [126.906296509795695, 37.674150369205414],
              [126.906351737250503, 37.673737372237781],
              [126.906410954029766, 37.673294537006136],
              [126.906410989038179, 37.673267509066157],
              [126.906513407399899, 37.672952251494308],
              [126.906842271438364, 37.672835388480678],
              [126.908430426606515, 37.671836573468532],
              [126.907819622738103, 37.670817990876301],
              [126.907793601080357, 37.670776522606943],
              [126.90777437519408, 37.670745878255062],
              [126.90774808395669, 37.670682977740384],
              [126.907627465684584, 37.670394382303627],
              [126.907752350486888, 37.670232305288039],
              [126.908183303805401, 37.670043444561038],
              [126.908444268913584, 37.669827416411657],
              [126.908974746271184, 37.669648309429817],
              [126.909055623043159, 37.669621002430581],
              [126.909056593995274, 37.669620674705136],
              [126.909060126944908, 37.669620854645331],
              [126.909146556758159, 37.669625249889918],
              [126.909237934631165, 37.669629826686077],
              [126.909241653728103, 37.669628981841434],
              [126.909302359752957, 37.669612929478269],
              [126.909541859978262, 37.669549597189743],
              [126.909646111622649, 37.669522029143806],
              [126.90968635092095, 37.669453966630407],
              [126.909816485680878, 37.669233849172862],
              [126.90982785266236, 37.669206830141533],
              [126.910077550862439, 37.669086587422314],
              [126.910145405517014, 37.669053911120002],
              [126.911222625892748, 37.668685341178033],
              [126.911237324697083, 37.668687325368715],
              [126.911363574003133, 37.668704145173656],
              [126.911766590372835, 37.668757834398427],
              [126.911902343618237, 37.668974170469845],
              [126.912038120747908, 37.669172487136805],
              [126.912158255159184, 37.669124718847343],
              [126.912174197775002, 37.669118532267071],
              [126.912212270362886, 37.669118561572098],
              [126.912287542459538, 37.669118617597469],
              [126.912570893811335, 37.669127841879316],
              [126.912639792476142, 37.669122263277998],
              [126.912963921337891, 37.669096040324135],
              [126.913012981726055, 37.669092136579827],
              [126.91308096612876, 37.66911020583801],
              [126.913194278265891, 37.669137320982479],
              [126.913284921776764, 37.669164416995208],
              [126.91330761216247, 37.669146414960565],
              [126.913326042822931, 37.669136292780699],
              [126.913680420001654, 37.668940201574003],
              [126.913712733755943, 37.668922320305732],
              [126.91379531284197, 37.668876487186807],
              [126.913851890965148, 37.668957615757542],
              [126.913872235951445, 37.668995448286175],
              [126.913919747482709, 37.669083803687272],
              [126.913897006859884, 37.669146854901619],
              [126.913919600290001, 37.669209938502107],
              [126.914043950997396, 37.66948933460155],
              [126.914395216507558, 37.66957968969016],
              [126.914644336426946, 37.66978709803643],
              [126.915063577917991, 37.66990453270126],
              [126.915244942433787, 37.669895656205902],
              [126.915403545026805, 37.669967848385596],
              [126.915471396791958, 37.670103043268007],
              [126.916015343345038, 37.670202545877963],
              [126.916031288946414, 37.670195243848362],
              [126.916403401465388, 37.670026570497242],
              [126.916412258596225, 37.670022640363989],
              [126.916775029556021, 37.669968843526213],
              [126.917126516263565, 37.669869986461471],
              [126.917141588310571, 37.669851699976945],
              [126.91734216789618, 37.669608859055536],
              [126.917705133140316, 37.669374864842133],
              [126.917734356031943, 37.669375732877626],
              [126.918271840695837, 37.669393285825507],
              [126.918509916478769, 37.669348405510505],
              [126.918623280836357, 37.66933046638426],
              [126.918670800038171, 37.669330498707083],
              [126.918759293588167, 37.669330561687552],
              [126.918802636643406, 37.669342079741718],
              [126.918997248312053, 37.669393795092653],
              [126.919223890375321, 37.66943900342168],
              [126.919233412715144, 37.669448481815543],
              [126.919314466895941, 37.669529163163773],
              [126.919371078442268, 37.669583260214111],
              [126.919699859812525, 37.66951141097492],
              [126.920141846889365, 37.669565775293215],
              [126.920197999278301, 37.670043331120375],
              [126.920379108751021, 37.670268696331497],
              [126.920254171227896, 37.670502864010871],
              [126.920339228916689, 37.670948904410068],
              [126.920355603679752, 37.671034507459758],
              [126.920229280536375, 37.67107581277542],
              [126.920162845587654, 37.671097444356612],
              [126.920105767395611, 37.671466802219477],
              [126.920354966117884, 37.671620139012525],
              [126.920598548840431, 37.671634135636751],
              [126.921148356911573, 37.671665730092073],
              [126.921397792783836, 37.671602831702039],
              [126.921794465491359, 37.67164814984573],
              [126.921941990127777, 37.671486074087944],
              [126.923120698619485, 37.671594981535613],
              [126.923551470377745, 37.671550218616247],
              [126.924333740800321, 37.671388557188429],
              [126.925104731908448, 37.67117282614965],
              [126.925478953291631, 37.671001881996091],
              [126.925773768620573, 37.670893956762932],
              [126.926132426672112, 37.670787308561877],
              [126.926227293453309, 37.670759100631805],
              [126.926782612852165, 37.670840540016698],
              [126.92741752707812, 37.670669757653926],
              [126.928346372575305, 37.671282998316435],
              [126.928640840377057, 37.671526442823946],
              [126.928708734458212, 37.671643610075748],
              [126.928720025487081, 37.671688666317394],
              [126.929139206863013, 37.67190515815112],
              [126.929241175530152, 37.671950269231239],
              [126.929444056565742, 37.671811212273987],
              [126.929490708898726, 37.671779237068584],
              [126.929524732120555, 37.671761239859961],
              [126.930114066027514, 37.671842683886119],
              [126.930442521166512, 37.672113175659192],
              [126.930691968417804, 37.67203223828129],
              [126.931599029169121, 37.671744467161425],
              [126.932132020658671, 37.671474489787116],
              [126.932778113880715, 37.671465858963032],
              [126.932891462414858, 37.671465924521854],
              [126.932993566937228, 37.671366878041752],
              [126.933231616421082, 37.671348996045509],
              [126.933854889875604, 37.671502521358391],
              [126.934127016442275, 37.671403568831785],
              [126.934580507156838, 37.671295710348048],
              [126.934875397874578, 37.671088653424775],
              [126.936314943142676, 37.671053419410953],
              [126.936586844821818, 37.671206735883104],
              [126.936949667065406, 37.671080798806805],
              [126.9369950160591, 37.671071812714793],
              [126.937267312714454, 37.670765630888525],
              [126.937754805753698, 37.670648769383924],
              [126.938196891851007, 37.670612968769497],
              [126.938809307689141, 37.670207857803824],
              [126.93886187528399, 37.670248151279544],
              [126.939420987039938, 37.670676688196558],
              [126.939644165643273, 37.670730032148093],
              [126.93979709389356, 37.670766584655333],
              [126.939859175726781, 37.670781423361888],
              [126.940502354849343, 37.670935155037661],
              [126.940894213393804, 37.671028834397795],
              [126.941064109914393, 37.671182086922123],
              [126.94177770226527, 37.67180412008473],
              [126.942731345865653, 37.671968730221096],
              [126.942877038198276, 37.67199387698404],
              [126.943250112918108, 37.6732464116239],
              [126.943317946210058, 37.673471689174796],
              [126.943374508519398, 37.673615872272926],
              [126.943634735210367, 37.674237671574794],
              [126.944143952901953, 37.675364134517324],
              [126.944129973274045, 37.675771463008601],
              [126.944120763209824, 37.676039851571154],
              [126.94429370695768, 37.676138778925925],
              [126.944483342877263, 37.676247250407201],
              [126.944505373988164, 37.67637232432336],
              [126.944584920463242, 37.67682392219367],
              [126.944478848440653, 37.677024336006944],
              [126.944346526858098, 37.677274292864247],
              [126.944319933984261, 37.677304407149606],
              [126.943892699623575, 37.67778762548739],
              [126.943086969009443, 37.678922451954584],
              [126.943077971643135, 37.679108870876924],
              [126.943040877040673, 37.679877458452793],
              [126.943108779580029, 37.680021646419718],
              [126.943527543209115, 37.680886786332778],
              [126.944195912504256, 37.681499773126028],
              [126.944467775268322, 37.68177019602512],
              [126.944715451417565, 37.682027009729232],
              [126.944841583691328, 37.682157794972476],
              [126.945045477020145, 37.682374124220445],
              [126.94537403059131, 37.682644572914882],
              [126.945453006974375, 37.683149154342232],
              [126.945531976299691, 37.68366274441528],
              [126.945508079346894, 37.683718343128412],
              [126.945361625682679, 37.684059090906651],
              [126.945225197001562, 37.684572579114928],
              [126.945225014757867, 37.684586236075177],
              [126.945225007253484, 37.684586939075444],
              [126.945216443809954, 37.685463474095272],
              [126.9452129145089, 37.685824921353316],
              [126.946289631775997, 37.686230866973496],
              [126.947116834789355, 37.686780842486918],
              [126.94792161372331, 37.687006454698071],
              [126.948499623459412, 37.687267997015333],
              [126.948873759872612, 37.687259153709597],
              [126.949293114668507, 37.687430523576616],
              [126.950267933807964, 37.687701242821284],
              [126.950642012174129, 37.687782493028315],
              [126.951302953646987, 37.688005658060064],
              [126.951390104307322, 37.68803508446549],
              [126.951483847560951, 37.68817961212924],
              [126.951752525813376, 37.688593837988662],
              [126.952069657324273, 37.689071487621483],
              [126.952273516653065, 37.689395920988218],
              [126.952330016532542, 37.689684254767172],
              [126.952352595573927, 37.689828420543193],
              [126.952363913579617, 37.689855453429473],
              [126.952295597023891, 37.690296899982705],
              [126.952420234594172, 37.69041407807071],
              [126.952952631518485, 37.691144083966059],
              [126.953122341190991, 37.691693744055414],
              [126.95345096733746, 37.691964170381709],
              [126.953733994960146, 37.69262199202872],
              [126.953851815782386, 37.692663899651251],
              [126.954041060652017, 37.692731211867788],
              [126.954041470175312, 37.69273135887741],
              [126.954067390756805, 37.692740568762623],
              [126.954266761771976, 37.692811409322161],
              [126.955071586065429, 37.693100038821207],
              [126.955888107264471, 37.692812048853533],
              [126.956353072500491, 37.692641043077487],
              [126.956455155121475, 37.692578015181056],
              [126.956852148095052, 37.69230787531712],
              [126.957332949175907, 37.691968583326393],
              [126.957362572615295, 37.691947679970802],
              [126.957895639365546, 37.691632538352252],
              [126.958518056604191, 37.691650590793557],
              [126.95915412757482, 37.691669036063942],
              [126.959879662439235, 37.691831469114781],
              [126.960582541315546, 37.691966859715393],
              [126.960877285663301, 37.692039040197407],
              [126.961897584974238, 37.692264630117762],
              [126.962203524091393, 37.692616110232116],
              [126.962645607159658, 37.692814470657908],
              [126.962736291812277, 37.692850538556208],
              [126.963099064904213, 37.692940755224384],
              [126.963858693682283, 37.692986047384643],
              [126.964040098948004, 37.692995115557153],
              [126.965128643233811, 37.692824271288288],
              [126.9651286444265, 37.692815262528974],
              [126.965124589125821, 37.692787674340231],
              [126.965106043248625, 37.692662090326323],
              [126.965083416774206, 37.692562977802432],
              [126.96509475824223, 37.692553970910758],
              [126.965117449162506, 37.692526949296365],
              [126.965094813507548, 37.692445855162852],
              [126.965098735700181, 37.692431336349152],
              [126.965128863998174, 37.692319817495765],
              [126.965132282972192, 37.692317875320754],
              [126.965134559272585, 37.692316631899331],
              [126.965162910370495, 37.692301720943099],
              [126.965208277094064, 37.692274704810288],
              [126.965242290927122, 37.6922747166522],
              [126.965287637010277, 37.692283739062887],
              [126.965321647404238, 37.69229276053327],
              [126.965332986494303, 37.692292763274232],
              [126.965378337157716, 37.692292777806507],
              [126.965389678548846, 37.692283770885439],
              [126.965446384251976, 37.692256759197136],
              [126.965514435852256, 37.692202721234231],
              [126.965571163050939, 37.692130661246395],
              [126.965593862765019, 37.692076610575263],
              [126.965627912843829, 37.692004543327691],
              [126.965786831136583, 37.69161717525764],
              [126.965797543573828, 37.691607121709481],
              [126.966410690377913, 37.691031732240759],
              [126.966807577923518, 37.690896704120377],
              [126.967533357897651, 37.690545538240407],
              [126.968213708707069, 37.690347518485602],
              [126.968364870738654, 37.690125125072306],
              [126.968599443216903, 37.689780016630991],
              [126.968697273969724, 37.689741179380263],
              [126.968735517910076, 37.689725996659682],
              [126.969472552105273, 37.689518975505436],
              [126.970425018533234, 37.68925794899215],
              [126.970799191048897, 37.689176960721149],
              [126.971332159739589, 37.688915817383041],
              [126.971570303312518, 37.688780731947929],
              [126.971740406811335, 37.68867265870513],
              [126.972371751102386, 37.688252171030101],
              [126.972443512206354, 37.68820433051561],
              [126.973135244778689, 37.687808071542655],
              [126.973357427623441, 37.687698882014892],
              [126.973373373230828, 37.687691001706426],
              [126.97337727374213, 37.687687624722699],
              [126.974280637906844, 37.686925391102541],
              [126.974325457522625, 37.686906823438534],
              [126.974949629303524, 37.686646243126496],
              [126.975335106489595, 37.686610290427382],
              [126.976287450913816, 37.686538420794577],
              [126.976474696955677, 37.686500168784264],
              [126.976572091015385, 37.686480152668636],
              [126.976634186520769, 37.686467392349186],
              [126.976638923959385, 37.686466418307063],
              [126.976741654871248, 37.686489243347538],
              [126.976982991690349, 37.686542475614026],
              [126.977047031767654, 37.686556600416992],
              [126.97912180489152, 37.686295730234662],
              [126.979383464128205, 37.686201719775873],
              [126.980074222101905, 37.685953539859057],
              [126.9823662856315, 37.685193550852205],
              [126.982409850940002, 37.685179106287045],
              [126.982459808999806, 37.685147580734686],
              [126.983180881325239, 37.684692705602373],
              [126.983670314190121, 37.684377145395068],
              [126.983963241895211, 37.684188281340852],
              [126.984110643586675, 37.684089195971723],
              [126.984802328149144, 37.68345861589976],
              [126.984849631309018, 37.683429062448205],
              [126.9848895475956, 37.683404149203952],
              [126.984938322077923, 37.683373711629507],
              [126.985167608759909, 37.683230610072989],
              [126.986131008635908, 37.682629336606148],
              [126.987024583389911, 37.682071415474198],
              [126.988521087710609, 37.681540017996539],
              [126.98930670572345, 37.68128051011179],
              [126.98947761632833, 37.681223986800426],
              [126.989938207077671, 37.681071661253881],
              [126.989953976362528, 37.681058427628514],
              [126.990629805753613, 37.68048609545108],
              [126.990788606007698, 37.679846419597084],
              [126.990858742295373, 37.679835443158844],
              [126.991407673980532, 37.67974989954206],
              [126.992107543877012, 37.679640981226818],
              [126.992197629428972, 37.679626950567737],
              [126.99243321398626, 37.679148301113216],
              [126.992924417412866, 37.678821736803776],
              [126.992953589215176, 37.678716826601615],
              [126.993247269642822, 37.677660632719856],
              [126.993119792792726, 37.677225336260548],
              [126.993266649184577, 37.67700151980052],
              [126.993072221194069, 37.676582554300431],
              [126.993156572791534, 37.676141921875988],
              [126.993141727396406, 37.675916397410376],
              [126.99312508331721, 37.675831930677774],
              [126.993199480741666, 37.675719882390268],
              [126.993809694684927, 37.67525226694999],
              [126.993981359945465, 37.674907087221705],
              [126.993958167666236, 37.674801221939155],
              [126.993953747505913, 37.674785175507246],
              [126.993795813088298, 37.674226281859085],
              [126.993562460413159, 37.674112238754489],
              [126.993543436536399, 37.674102940054397],
              [126.993548077185565, 37.674091199414597],
              [126.99371526162777, 37.673668262757317],
              [126.994073855164856, 37.672761086645565],
              [126.994038684702744, 37.672428869112039],
              [126.993976518618993, 37.671841674376005],
              [126.993802431196059, 37.670197243910138],
              [126.994350600601223, 37.669810986099307],
              [126.994357356058146, 37.669566876745613],
              [126.99408644486148, 37.669039791547625],
              [126.993830397764384, 37.668622795964183],
              [126.99373742071964, 37.668344726311368],
              [126.993628742762525, 37.66801969827587],
              [126.993625035914079, 37.667921429066716],
              [126.993560590192601, 37.667804018874769],
              [126.993733462384597, 37.667603245711319],
              [126.993961055023391, 37.667338917992929],
              [126.99412914914268, 37.667030065725591],
              [126.994084931844, 37.666932490744678],
              [126.994017249393011, 37.666783129096672],
              [126.993261112930227, 37.666189562699323],
              [126.993122998943264, 37.666056379678984],
              [126.992749039945323, 37.665532660918991],
              [126.992434541150431, 37.665383419193248],
              [126.99222451934466, 37.665231643033245],
              [126.99123850522534, 37.664993649862389],
              [126.989997217448007, 37.664499422860793],
              [126.989529212167454, 37.664526113014496],
              [126.989453286732925, 37.664530036887918],
              [126.988818284676583, 37.664468308846253],
              [126.988268256936891, 37.664391106062581],
              [126.988210840159155, 37.664292979469778],
              [126.988032985138716, 37.663989021066975],
              [126.987801952985251, 37.663593692353658],
              [126.987759734992153, 37.662883449219031],
              [126.987750759156185, 37.662732409099306],
              [126.987365268341392, 37.661618256479244],
              [126.987283719077666, 37.661170291191844],
              [126.987224984379139, 37.66084761742227],
              [126.987276744803722, 37.660600143542062],
              [126.98654402978309, 37.659485098227272],
              [126.98650969818506, 37.659442856530042],
              [126.986020522213465, 37.658943320014373],
              [126.984616817535112, 37.658228537502538],
              [126.984335209621051, 37.657733807049176],
              [126.983741572386279, 37.6573294069266],
              [126.983588239747576, 37.657285763795223],
              [126.98340604531549, 37.657233905506608],
              [126.983009990935969, 37.65700353817595],
              [126.98285678789523, 37.656449700367808],
              [126.981193622027718, 37.656487706402274],
              [126.979658391480498, 37.656038646536267],
              [126.979944358025591, 37.654815058671012],
              [126.980181286720708, 37.654315404995423],
              [126.980431756735825, 37.653787189487339],
              [126.980805716878194, 37.65317628601013],
              [126.981048068178666, 37.652712614803995],
              [126.981070190915332, 37.652698667540889],
              [126.981676735830462, 37.652316292467574],
              [126.981873793728354, 37.651688445265194],
              [126.982298498351383, 37.651254084022831],
              [126.982442538885735, 37.650732427185481],
              [126.982495568076232, 37.650540370996076],
              [126.983707081773346, 37.649758564989355],
              [126.983937209809753, 37.649610057683077],
              [126.983984526364765, 37.649442454223724],
              [126.984019794272186, 37.649317532110068],
              [126.983883984891861, 37.64860067814071],
              [126.983980933000836, 37.648182017721588],
              [126.984367125332923, 37.647828587715942],
              [126.984567144716294, 37.647645533644791],
              [126.984603218679183, 37.647612521233732],
              [126.984713077782473, 37.647251867852873],
              [126.984693387733458, 37.647086154367308],
              [126.984678786342698, 37.646963260282845],
              [126.984710873378859, 37.64693300923345],
              [126.984812839309939, 37.646836873837181],
              [126.984763101508079, 37.646759995385246],
              [126.984903932125022, 37.646172116195267],
              [126.984929152904769, 37.646066835018615],
              [126.984942243017898, 37.646056344748075],
              [126.985068580011529, 37.645955094078282],
              [126.985100516424737, 37.64592949855512],
              [126.985483042392659, 37.646005520430677],
              [126.985579363319019, 37.646102661837624],
              [126.9857182488592, 37.646092845234726],
              [126.985723390250953, 37.646056239196092],
              [126.985680548209217, 37.645994858990321],
              [126.985563528961379, 37.645965562830369],
              [126.985413409845123, 37.645946675009881],
              [126.985257968749181, 37.64584895194556],
              [126.985106105371401, 37.645728426093569],
              [126.984948368775605, 37.645672370333472],
              [126.984858270954163, 37.64559578529073],
              [126.984795440822225, 37.645445979433518],
              [126.984820600876688, 37.645368841288075],
              [126.984646157836082, 37.645338082044546],
              [126.984643211966002, 37.645337561724055],
              [126.984638637601094, 37.6453300931081],
              [126.984289122884292, 37.64475934197921],
              [126.98419368289359, 37.644603489484815],
              [126.984190886543402, 37.644463163707506],
              [126.984190187209478, 37.644428077078878],
              [126.984037202380705, 37.644341703734113],
              [126.983902358209377, 37.644265570303517],
              [126.983811017680594, 37.644186161440764],
              [126.983780699836828, 37.644137618068044],
              [126.983726081175405, 37.644050165863646],
              [126.983472753091846, 37.643940874224818],
              [126.983247438286526, 37.643683503600734],
              [126.983525578085064, 37.643279508266467],
              [126.983690903750357, 37.643039371673986],
              [126.983837156000277, 37.642657874269631],
              [126.983874604783608, 37.642560187328414],
              [126.983853937789959, 37.642365070447283],
              [126.983853497224644, 37.64236092237654],
              [126.983830978842775, 37.642148841461399],
              [126.984002576576614, 37.64195712091837],
              [126.984257049053568, 37.641644914965887],
              [126.984956300968676, 37.641464272106539],
              [126.985134460137886, 37.641520590054014],
              [126.985193517078798, 37.641596419358734],
              [126.985224263491304, 37.641635898624777],
              [126.985259749669055, 37.641606125509561],
              [126.98532945418529, 37.64154764089691],
              [126.985267882971826, 37.641350263683719],
              [126.985657140446136, 37.640740182424246],
              [126.985760350810139, 37.640672408431165],
              [126.985855325369357, 37.64061004072105],
              [126.985884751826944, 37.640590717228577],
              [126.98591238836039, 37.640572569383096],
              [126.986132538515548, 37.640428004200842],
              [126.985954493024849, 37.640223854744725],
              [126.985896785586647, 37.640192872244761],
              [126.985676231004902, 37.640044459604418],
              [126.985533443921611, 37.639701232041951],
              [126.985428368820052, 37.639281127984987],
              [126.98542338930298, 37.639234992112847],
              [126.985407518360944, 37.639087981680433],
              [126.985444917960336, 37.638875411462578],
              [126.985420878726472, 37.638761379536533],
              [126.985393826697674, 37.638590189752421],
              [126.985293843446058, 37.638418433037714],
              [126.985209362465625, 37.638322420061982],
              [126.985192252611895, 37.638302975690138],
              [126.985198160510208, 37.63826907066931],
              [126.985233556005198, 37.638065917017492],
              [126.98513665250384, 37.63797426813359],
              [126.984959930975151, 37.637807127254412],
              [126.985026269843189, 37.637589437677661],
              [126.985044784213784, 37.637528682797942],
              [126.985084303870408, 37.637314416564458],
              [126.984936701024978, 37.637164896755024],
              [126.984855693370079, 37.636814622675558],
              [126.984744383511739, 37.636648213201099],
              [126.984599416290322, 37.636596706776587],
              [126.984461349499853, 37.636547651316263],
              [126.984424142706473, 37.636517876224133],
              [126.9842022494974, 37.636340308412066],
              [126.984198774449695, 37.636337527018242],
              [126.983959437071164, 37.636438058336701],
              [126.983866788089685, 37.636444959297108],
              [126.983706648709997, 37.636456888156786],
              [126.983674123657636, 37.636444738218387],
              [126.983586804400673, 37.636412100826519],
              [126.983546821211164, 37.636320589789406],
              [126.983131932084689, 37.636105418494694],
              [126.982977599586846, 37.635975879113488],
              [126.98278787359061, 37.635809447705761],
              [126.98268344817798, 37.635818150162685],
              [126.982609080692683, 37.635824347935049],
              [126.982515974099286, 37.635786032991923],
              [126.982515434044785, 37.635758331652816],
              [126.98251510127821, 37.635741270598267],
              [126.982289801542464, 37.635534569274412],
              [126.982196905621691, 37.635382512648391],
              [126.982160643958522, 37.635281436258246],
              [126.982041004244607, 37.635156964699135],
              [126.981994634212583, 37.635156955809848],
              [126.981788092052895, 37.635011729507632],
              [126.981761538549748, 37.634993057961509],
              [126.981679420475672, 37.634935317247276],
              [126.981513605651188, 37.634721027800616],
              [126.981415680481803, 37.634829687088065],
              [126.98125529971523, 37.634842057733415],
              [126.981122357467072, 37.634860044283613],
              [126.980751500356021, 37.634836896013695],
              [126.980606887963887, 37.634815752275522],
              [126.980380157724795, 37.634685917795721],
              [126.980190612016841, 37.634552989281325],
              [126.980049737126549, 37.634417817016285],
              [126.979652392551657, 37.634206582035368],
              [126.979198210746887, 37.634078664967326],
              [126.978905776398477, 37.634062562993613],
              [126.978457929849483, 37.634045584129531],
              [126.977804767208426, 37.633910587070531],
              [126.977610278252385, 37.633773994739933],
              [126.97733171282654, 37.633583038700202],
              [126.977264822664395, 37.633462247178308],
              [126.977194960127747, 37.633336088506319],
              [126.977006021496976, 37.632968901068082],
              [126.976754515154909, 37.632729877233544],
              [126.976728366313168, 37.632705027685184],
              [126.97622422008105, 37.632231618738544],
              [126.976100681435028, 37.63219048846495],
              [126.975828489030675, 37.632009108618632],
              [126.975764975067293, 37.631922095301228],
              [126.975579874784046, 37.631752555097016],
              [126.975369074509757, 37.631698169588674],
              [126.97538835519407, 37.631733086578208],
              [126.974628909779085, 37.631912832866369],
              [126.974453458568007, 37.631980086187461],
              [126.973786966252561, 37.632023638955765],
              [126.973672112167563, 37.632031143146961],
              [126.973029771860865, 37.632374766908541],
              [126.972530907196784, 37.63215317945118],
              [126.971327558750573, 37.631618657669321],
              [126.970715076056237, 37.631508742762755],
              [126.970582748415865, 37.631484993734865],
              [126.969388830774832, 37.631015896402268],
              [126.968682098924504, 37.630840569701625],
              [126.968235550294494, 37.630730073097517],
              [126.967653534095248, 37.630743422042173],
              [126.967084332374768, 37.63070658008229],
              [126.966801610544607, 37.630688269316991],
              [126.966676191137196, 37.630704452007926],
              [126.966512000341865, 37.630725638345474],
              [126.966340942417361, 37.630758750525828],
              [126.966047313083578, 37.630815588031467],
              [126.965228478118789, 37.630768042160419],
              [126.964331418005585, 37.630360065954143],
              [126.963767223179545, 37.629793116812358],
              [126.961927542440066, 37.629924484771216],
              [126.961439669559866, 37.629959318237084],
              [126.960489731430599, 37.629826089098195],
              [126.960115738164134, 37.629775565622502],
              [126.959797653365342, 37.629730047892394],
              [126.959822440136307, 37.629761154731241],
              [126.960604374526326, 37.630718844418148],
              [126.961081466650768, 37.631266623271259],
              [126.961507353021233, 37.631758767643163],
              [126.961619136489389, 37.631887940818707],
              [126.962260272424558, 37.632396947795087],
              [126.963328788625574, 37.633245132753075],
              [126.963290905321429, 37.633375586565194],
              [126.963019990184677, 37.634308789651925],
              [126.96292222394267, 37.634645547935079],
              [126.962727979497728, 37.63524290538161],
              [126.962540778236374, 37.635818856253778],
              [126.962157559606965, 37.636350249043836],
              [126.961604341620315, 37.637117356375427],
              [126.961604099991575, 37.637188695974373],
              [126.961602753004144, 37.637610182212029],
              [126.961602892493786, 37.637877501793731],
              [126.961602958221988, 37.637989178443718],
              [126.961305453802822, 37.638296653046794],
              [126.961133076620698, 37.638474861115952],
              [126.960861868853485, 37.63875547680577],
              [126.960830124747403, 37.638852553356159],
              [126.96071912059422, 37.639192007092838],
              [126.960562291351735, 37.639671586346623],
              [126.960510256481172, 37.639830896430382],
              [126.960449535715085, 37.639897268869646],
              [126.960131621763892, 37.640538436589885],
              [126.959971239658771, 37.64146170144469],
              [126.959912596720329, 37.641517623105216],
              [126.959267781740465, 37.642132733533195],
              [126.959316563511123, 37.642666660747167],
              [126.959349578830938, 37.643027467536307],
              [126.959365334585115, 37.643199651359623],
              [126.959019705654882, 37.643879044022995],
              [126.959121090188091, 37.644341038021523],
              [126.959122231092564, 37.644346272793733],
              [126.958906541238818, 37.644760365253745],
              [126.958923755629783, 37.644831534238591],
              [126.95913777051139, 37.64571632240078],
              [126.959145887079643, 37.645749876620741],
              [126.959096662252762, 37.645834073486974],
              [126.958919918867153, 37.646136602438879],
              [126.959013937609868, 37.646704395403788],
              [126.959027015034181, 37.646783379775052],
              [126.958544630215115, 37.647427042734996],
              [126.958377343633913, 37.647650297710584],
              [126.95833212333217, 37.647683680992479],
              [126.957839162001164, 37.648047690561633],
              [126.957854690694901, 37.64844553217619],
              [126.957842470716102, 37.648470475405439],
              [126.957562261381725, 37.649042455260187],
              [126.957606489738211, 37.649191609388353],
              [126.957629052660351, 37.649267698241701],
              [126.957648435605037, 37.649333070036739],
              [126.957789461835063, 37.649808639008093],
              [126.957764678562995, 37.650022297035576],
              [126.957684987757659, 37.650710296981828],
              [126.957460148485467, 37.651871904492488],
              [126.957108105539746, 37.652837230385018],
              [126.956642298563935, 37.653049630006237],
              [126.956504696829768, 37.653121644443551],
              [126.956412356973672, 37.653170047775141],
              [126.956532465007243, 37.653170426323129],
              [126.9565084832265, 37.653191950527045],
              [126.956468675586322, 37.653227784375567],
              [126.956392329625999, 37.653267732971592],
              [126.956365790564334, 37.653267443021917],
              [126.956348236374041, 37.653288834573999],
              [126.956298291819721, 37.653295852004533],
              [126.956261876009606, 37.653305244593518],
              [126.956249305880661, 37.653308527893266],
              [126.95616326340685, 37.653360930425471],
              [126.956087120906631, 37.653422212504353],
              [126.956009570791309, 37.653484666985079],
              [126.956020278707356, 37.65350464573303],
              [126.956029198366167, 37.653521271387845],
              [126.95596365673569, 37.653570240489643],
              [126.95595051751323, 37.653580507362761],
              [126.955931945993783, 37.65359509540631],
              [126.955851004026883, 37.653662313903602],
              [126.955802361391875, 37.65369081768992],
              [126.955713976139933, 37.653682009490339],
              [126.955707277162858, 37.65368560374111],
              [126.955591390380832, 37.65374775936754],
              [126.955534857138034, 37.653778146465349],
              [126.955524538829479, 37.653789593536217],
              [126.955467215209794, 37.65385337854002],
              [126.955462782733647, 37.653856772327345],
              [126.955458667460647, 37.653860041392143],
              [126.955444196643256, 37.653875506355966],
              [126.955423977515508, 37.653898040973289],
              [126.955406617168848, 37.65395073205368],
              [126.955402474403826, 37.653963352316005],
              [126.955398906100243, 37.653998832125367],
              [126.955368251307917, 37.654035984137316],
              [126.955353357746901, 37.654079053154092],
              [126.955335236955392, 37.654171119032711],
              [126.955321367143299, 37.654224108331491],
              [126.955317283842902, 37.654230756634732],
              [126.95529054570035, 37.654274433429521],
              [126.955256171638922, 37.65429926053897],
              [126.955235923647123, 37.654313929058915],
              [126.955182442033745, 37.654339612500941],
              [126.95504435084041, 37.654406104086966],
              [126.954897700325233, 37.654460941386127],
              [126.954859435634404, 37.6544697472401],
              [126.954801032465696, 37.654483185694041],
              [126.95477803673495, 37.654488492813577],
              [126.954600434839747, 37.654529453075959],
              [126.954583546527715, 37.654534869514883],
              [126.954552021505279, 37.654532046792539],
              [126.954351549734469, 37.654595314364826],
              [126.954262603308862, 37.654680871094413],
              [126.954219350645786, 37.65476644448929],
              [126.954037239707205, 37.654981976545159],
              [126.954032192723176, 37.655076089249192],
              [126.954194126072622, 37.655211274157473],
              [126.954177926939167, 37.655216834333778],
              [126.953945507475808, 37.65529657754692],
              [126.953890620081452, 37.655266381193044],
              [126.953736215554699, 37.655181544914477],
              [126.953592540053478, 37.655100337322779],
              [126.953358849306198, 37.654968248655109],
              [126.953275670830678, 37.654948482722979],
              [126.953210497299509, 37.654933077915736],
              [126.952625018941688, 37.654924683299186],
              [126.952576052384543, 37.654923370867074],
              [126.952314574780203, 37.654916363379037],
              [126.951860003017657, 37.654864925657584],
              [126.951718364762058, 37.654889687692858],
              [126.95153086836126, 37.654922549335836],
              [126.951385943186111, 37.654947949154241],
              [126.951376054920942, 37.654949683297204],
              [126.951309797680011, 37.654994712143626],
              [126.951222664113928, 37.655063389434751],
              [126.951053096864996, 37.655197034175643],
              [126.950941963882443, 37.655311426007337],
              [126.950900526375321, 37.655354080661553],
              [126.950781099783896, 37.655477733677188],
              [126.950772707682546, 37.655486424006909],
              [126.950690334397081, 37.655532500521296],
              [126.950443353285266, 37.655670742078897],
              [126.950404852951849, 37.655692290222042],
              [126.950104038230393, 37.655799807993759],
              [126.949791162519432, 37.655911761878592],
              [126.949633575232596, 37.656087932094522],
              [126.94957177601998, 37.6561570553298],
              [126.949552484444609, 37.656178318768916],
              [126.949490278149213, 37.656246972269003],
              [126.949379404649235, 37.656450958016762],
              [126.949344875219182, 37.656514533628638],
              [126.949237622192214, 37.656712505319682],
              [126.949228214519749, 37.656729868819546],
              [126.94921383934043, 37.656753008294274],
              [126.949203084590081, 37.656770401555377],
              [126.949126242023439, 37.656812713584891],
              [126.948832817320422, 37.656974361430954],
              [126.948744197902727, 37.656986080152919],
              [126.948742795262362, 37.656986265581722],
              [126.948349004740606, 37.657038338708638],
              [126.948286373745134, 37.657047392603758],
              [126.948236335124548, 37.657054730645228],
              [126.948184677814666, 37.657058356619046],
              [126.948128011635916, 37.657062457572962],
              [126.948011559062138, 37.657092433595842],
              [126.947882433485958, 37.657125810400309],
              [126.947757842836992, 37.657280847537834],
              [126.947778820685272, 37.65733402427886],
              [126.94781335165797, 37.657421560137983],
              [126.947992144231534, 37.657711061099121],
              [126.948038341887823, 37.657892439563916],
              [126.948039133678733, 37.658005394562437],
              [126.948008017528238, 37.658069227288912],
              [126.947913263932207, 37.658114530039626],
              [126.947911718892868, 37.658115416929412],
              [126.947910382164679, 37.658116496777801],
              [126.947909292513415, 37.658117740022277],
              [126.947908480822377, 37.658119109852862],
              [126.947907967771684, 37.658120569411672],
              [126.947907770687038, 37.658122075515948],
              [126.947907892136186, 37.658123587617709],
              [126.94790833131249, 37.658125061547977],
              [126.947909074912062, 37.658126456684528],
              [126.947932306335872, 37.658161497427358],
              [126.947933298248941, 37.658162735992853],
              [126.947934529311794, 37.658163830563176],
              [126.947935966867632, 37.658164751241557],
              [126.947937573678132, 37.658165474419071],
              [126.9479393079435, 37.658165980070777],
              [126.947941121001747, 37.658166256251647],
              [126.947942967571976, 37.658166293736379],
              [126.947944796637984, 37.658166092286287],
              [126.947946560538043, 37.658165657987581],
              [126.947948212703935, 37.658165002339651],
              [126.947965359208013, 37.658156739858235],
              [126.947943258116695, 37.658202079769005],
              [126.947897346903986, 37.658216572822596],
              [126.947865380203098, 37.658226676748463],
              [126.9478636336449, 37.658227371611986],
              [126.947862063387703, 37.658228295337636],
              [126.94786071612647, 37.658229419301392],
              [126.947859630647045, 37.65823071123684],
              [126.947858841253904, 37.658232131645981],
              [126.94785837092796, 37.658233639175123],
              [126.947858232473635, 37.658235188817613],
              [126.947858430772712, 37.658236733727065],
              [126.947858960497257, 37.658238227910616],
              [126.94788189690523, 37.658286622314002],
              [126.947882657191897, 37.658287907566326],
              [126.947883660916602, 37.658289082192937],
              [126.94788488561916, 37.658290117246125],
              [126.947886298622635, 37.658290985532958],
              [126.947887866056419, 37.658291667966893],
              [126.947889548381411, 37.658292145434906],
              [126.947891304871305, 37.658292406029162],
              [126.947893090211807, 37.658292445030774],
              [126.947894861335868, 37.658292260435573],
              [126.947896575123053, 37.658291857449662],
              [126.947905604209495, 37.658289096568218],
              [126.947920493107688, 37.658284559547091],
              [126.947939463712359, 37.658380119191882],
              [126.947942535345163, 37.658395536425104],
              [126.948001905816227, 37.658572369827539],
              [126.947983441012255, 37.658641070347777],
              [126.947967039182799, 37.658708140095172],
              [126.947902303671668, 37.658773683904975],
              [126.947807582554915, 37.658869576656485],
              [126.947805800527789, 37.658871384497552],
              [126.947786164099028, 37.658891285910421],
              [126.947755345506479, 37.658922520589734],
              [126.947693296402861, 37.659017932566442],
              [126.947677605952904, 37.659042035516443],
              [126.947579475810514, 37.659192905059612],
              [126.947564852829842, 37.659215385188048],
              [126.947537416370054, 37.659200056634845],
              [126.947510963745216, 37.659185376234113],
              [126.947256906052843, 37.659083819129293],
              [126.947084084692818, 37.659014734204852],
              [126.946981533099702, 37.658973762940057],
              [126.946584450563904, 37.658815116713107],
              [126.946425922437143, 37.658756100861012],
              [126.94629279241984, 37.658712982731267],
              [126.946043347671747, 37.658632191519253],
              [126.945689157682153, 37.658517527691565],
              [126.945205497216833, 37.658376232489026],
              [126.945022612494455, 37.658325186039747],
              [126.94488238450846, 37.658288783898556],
              [126.944728198248413, 37.658248867390483],
              [126.944396449637992, 37.658163583277783],
              [126.944293901313415, 37.658145649532813],
              [126.944123229547031, 37.658115843222497],
              [126.943338297376016, 37.658058580007662],
              [126.943334074270851, 37.658057664154626],
              [126.942697218086096, 37.65791945817265],
              [126.942437969981739, 37.657833790003977],
              [126.942318967663709, 37.657794490020898],
              [126.942099958500222, 37.657722161771105],
              [126.942069826740749, 37.657705297752145],
              [126.941801474448908, 37.657554159266503],
              [126.941611139639178, 37.657447741335105],
              [126.941600513560559, 37.657441800831428],
              [126.941588951376232, 37.657435360162928],
              [126.941573098245016, 37.657426473696404],
              [126.941443453745492, 37.657354023750216],
              [126.941330043954537, 37.657290701724229],
              [126.941021503058366, 37.65711842777668],
              [126.94084891571471, 37.657022845600217],
              [126.940769195191635, 37.656978719749027],
              [126.940335213641006, 37.656767848198854],
              [126.940186065461504, 37.656672826022337],
              [126.940128161787655, 37.656635926498197],
              [126.940067676905528, 37.656584359040075],
              [126.939904730506413, 37.656445499638323],
              [126.939666233344283, 37.65624105989054],
              [126.939398466461981, 37.655944048087278],
              [126.939285113127454, 37.655816447689332],
              [126.939036250095072, 37.655536471748654],
              [126.938809821145171, 37.655275629120723],
              [126.938459656069512, 37.654771172778823],
              [126.938413939201126, 37.654675005657609],
              [126.938261615143915, 37.654354593275883],
              [126.938151063371166, 37.654122063929613],
              [126.938121574694875, 37.654070116713811],
              [126.937923332999262, 37.653720969706995],
              [126.937837036110906, 37.65359197625849],
              [126.937747240369319, 37.653457810448948],
              [126.937739941219192, 37.65344692181791],
              [126.937622900552896, 37.653160798460952],
              [126.937569803959306, 37.653034192977564],
              [126.937537098015781, 37.652956349418808],
              [126.937425072437023, 37.652691337326544],
              [126.937306164437089, 37.652520636532763],
              [126.937163320024027, 37.652315704185419],
              [126.937066529733613, 37.652226649492157],
              [126.937060735139127, 37.652221317499802],
              [126.936766096714265, 37.651969423651487],
              [126.936659459474541, 37.651883067965926],
              [126.936454736213236, 37.651717474416579],
              [126.936281693499438, 37.651583926449895],
              [126.936136764589875, 37.651485866470942],
              [126.935885147845596, 37.65132055612564],
              [126.935705691391803, 37.651202654415002],
              [126.935267068903002, 37.651060508218045],
              [126.934947473434349, 37.650985120827507],
              [126.934735898441346, 37.650935231555565],
              [126.934716763085518, 37.650931023477405],
              [126.934621119642898, 37.650910076037171],
              [126.934590495260551, 37.650903299959772],
              [126.934581973204686, 37.650901140982441],
              [126.934537032155376, 37.650889755357476],
              [126.934241774129617, 37.650814590874788],
              [126.933889723576243, 37.650725094143539],
              [126.933349178572328, 37.650614403893748],
              [126.933216426635852, 37.650584559373627],
              [126.932909743553324, 37.650515609940562],
              [126.932823204072847, 37.650496153249847],
              [126.932797451403545, 37.650490388891932],
              [126.932362810762768, 37.650412148306003],
              [126.932221401651105, 37.650386692861154],
              [126.93221507517076, 37.650378625231191],
              [126.932206371555083, 37.650367573565369],
              [126.932199252085837, 37.650358542198788],
              [126.932196117242668, 37.650354575669198],
              [126.932185715025028, 37.650341350825812],
              [126.932157612379541, 37.650305675120279],
              [126.931900567456012, 37.650230651766371],
              [126.931875016107711, 37.65022928487592],
              [126.931853102346679, 37.650228237445468],
              [126.931593217709207, 37.650293548002182],
              [126.931536864304903, 37.650307741037999],
              [126.931544756830917, 37.650313646743797],
              [126.931431072839132, 37.650331319303234],
              [126.93140192692502, 37.650329785364107],
              [126.930862933386095, 37.650301420705468],
              [126.93044866766968, 37.650246824986958],
              [126.930380810630922, 37.650237810308845],
              [126.930284731560349, 37.650225047659696],
              [126.930042489376618, 37.65017210318603],
              [126.930003097530715, 37.650163511539553],
              [126.929876172918526, 37.650115486312743],
              [126.929709853865049, 37.650052126326109],
              [126.929699375687321, 37.650048156678089],
              [126.929488258062449, 37.649940477608681],
              [126.929285942106645, 37.649837273495585],
              [126.929281094322604, 37.649834820050572],
              [126.929259163405234, 37.649818111407441],
              [126.929193632519102, 37.649766807778008],
              [126.929188619151802, 37.649762884356363],
              [126.929118061364463, 37.649665752456698],
              [126.928978020966923, 37.649568170635519],
              [126.928954637077553, 37.649551885739236],
              [126.928863693591779, 37.649489040830495],
              [126.928762823148674, 37.649419266110193],
              [126.928645823400828, 37.649338335811009],
              [126.92845391837588, 37.649206060447597],
              [126.928201907585105, 37.649033422016707],
              [126.928166746354151, 37.649009405934173],
              [126.928100844411176, 37.648963515101677],
              [126.927992079230236, 37.648887773384601],
              [126.927988089812104, 37.648884711142436],
              [126.927972830738682, 37.648873013570068],
              [126.927971997721102, 37.6488722524116],
              [126.927936533858727, 37.648840122801502],
              [126.927858428179448, 37.648769384210077],
              [126.927741661979212, 37.648661379021078],
              [126.927591784546777, 37.648522746219747],
              [126.92758755100229, 37.648518855356272],
              [126.927574498435092, 37.648507103542684],
              [126.92755344095211, 37.648488204976395],
              [126.927421650024925, 37.648369862185987],
              [126.927376050228418, 37.648327683827702],
              [126.927283635649104, 37.648242286429699],
              [126.927219797750098, 37.648183296144659],
              [126.927173450297502, 37.648139900883898],
              [126.927146474606019, 37.64811465696927],
              [126.927134010841556, 37.648103008915022],
              [126.927096823449034, 37.648077235890931],
              [126.926898866455929, 37.647940108272678],
              [126.926839119051152, 37.647903391550905],
              [126.926599838929576, 37.647756346287736],
              [126.926580882726981, 37.647766747460672],
              [126.926489619743222, 37.647699999999304],
              [126.926141238714649, 37.647445215266707],
              [126.92608169042262, 37.647403935682888],
              [126.925899577641388, 37.647277687737621],
              [126.925809490686476, 37.647215298907845],
              [126.925707253029501, 37.647145427483814],
              [126.925480594728413, 37.646990646667071],
              [126.925281530108293, 37.646858562002613],
              [126.925236869377429, 37.646828953767283],
              [126.925174160820518, 37.646787790823097],
              [126.924844897762227, 37.646571840087844],
              [126.924794039377957, 37.646534994412889],
              [126.924791996786936, 37.646533515281412],
              [126.924397271020581, 37.646280424653341],
              [126.924381204580001, 37.646270226999739],
              [126.924243587030801, 37.646182875181864],
              [126.924122911301239, 37.646106303823096],
              [126.92408190337315, 37.646097005583741],
              [126.923975225406991, 37.646072923913927],
              [126.923915697236296, 37.646059422404747],
              [126.923680156786759, 37.646006002782876],
              [126.923395348993367, 37.645944430902603],
              [126.923355661434229, 37.645939728450386],
              [126.923184760507652, 37.645919489974226],
              [126.923122999273389, 37.645912176512802],
              [126.923078791121398, 37.645906308972087],
              [126.922862331686318, 37.645877629773793],
              [126.922280083488317, 37.645832471317775],
              [126.922228741638861, 37.645828708287546],
              [126.922141754261631, 37.645822333434801],
              [126.922066699549362, 37.645816855215152],
              [126.92184623242467, 37.645800771374113],
              [126.921662011604397, 37.64579140128312],
              [126.921545907304846, 37.645785577272115],
              [126.921470859784876, 37.645781813043435],
              [126.921283272144649, 37.645758310081057],
              [126.921257568018092, 37.645755089710214],
              [126.920630816342026, 37.645676562788331],
              [126.920424077231701, 37.645650690342734],
              [126.919871035783629, 37.645581473527024],
              [126.919762467208571, 37.645565223953597],
              [126.919525353335885, 37.645529734739547],
              [126.919219925497245, 37.645484113975783],
              [126.919153307943859, 37.645474164270951],
              [126.918995722494969, 37.645455399481783],
              [126.918795774259991, 37.64543158923339],
              [126.918752480488862, 37.645426433926445],
              [126.918401785207436, 37.645384672843853],
              [126.918190752623119, 37.645359541454006],
              [126.918116251055181, 37.645350677144684],
              [126.917647273024713, 37.645294439909314],
              [126.917485525255543, 37.645275045961071],
              [126.917341505298452, 37.645257777262287],
              [126.917296310351446, 37.645254420104507],
              [126.916785667873796, 37.645216505941399],
              [126.91668076367155, 37.645208717135304],
              [126.916326631740418, 37.645182421393294],
              [126.916096336687005, 37.645165320974833],
              [126.915806125245638, 37.645143770979523],
              [126.915459593073095, 37.645128331666989],
              [126.915435074286194, 37.645127239441045],
              [126.915189328968594, 37.645116290703783],
              [126.914606747001699, 37.644908639298521],
              [126.91381185461141, 37.644771375285934],
              [126.913720825699116, 37.644755656172521],
              [126.913457480065162, 37.644952484341474],
              [126.913398610997803, 37.644996497530848],
              [126.913363229712928, 37.645000733618453],
              [126.913285706179167, 37.645010144675055],
              [126.913216203026593, 37.645019325173969],
              [126.913187126118615, 37.64502316623576],
              [126.913045658581808, 37.645041850126262],
              [126.912931708304441, 37.645056964506601],
              [126.912900544319143, 37.645060545442561],
              [126.9128812226121, 37.645063657126336],
              [126.912762200598678, 37.645082835446431],
              [126.912679756349618, 37.645096120773381],
              [126.912563235472959, 37.645120251546466],
              [126.912504196393144, 37.645134532092179],
              [126.912327744746449, 37.645177329997836],
              [126.912141684756463, 37.645268782347948],
              [126.912019273358922, 37.645329036566622],
              [126.911945590064136, 37.645365579756813],
              [126.911854311422559, 37.645439280932266],
              [126.911786442412819, 37.645493288379058],
              [126.911709520672304, 37.645546459273206],
              [126.911673709140814, 37.645571281838386],
              [126.911530798520346, 37.645670975656323],
              [126.91131376298847, 37.645822378403679],
              [126.911223931455453, 37.645859508442129],
              [126.911210586928746, 37.645865024692128],
              [126.911057734793147, 37.645928290545477],
              [126.911041627300634, 37.645934874581286],
              [126.911033417625433, 37.645938390251594],
              [126.911005473146233, 37.645950495333217],
              [126.910905209352038, 37.64599321628171],
              [126.910863230441308, 37.646011202742649],
              [126.910726093111037, 37.646102599699773],
              [126.910602588237566, 37.64618500711935],
              [126.910558610760887, 37.646214317736202],
              [126.910496575543888, 37.646255712394648],
              [126.910496246437006, 37.646255903635122],
              [126.91043589633037, 37.64627870679373],
              [126.910436798527428, 37.646281301730781],
              [126.910438516560191, 37.64628623158962],
              [126.910434577600086, 37.646289084449748],
              [126.910304555133763, 37.646383271837728],
              [126.910139039163099, 37.646505622196969],
              [126.910136580988464, 37.646507852419887],
              [126.909966583379614, 37.646662032294323],
              [126.909857537334346, 37.646759217367638],
              [126.909825156236749, 37.646788721817174],
              [126.909727266101783, 37.646877910077059],
              [126.909582103526262, 37.646949982430613],
              [126.90949150206383, 37.64699502367975],
              [126.909237457079968, 37.647123493978768],
              [126.9089473218568, 37.647263279398224],
              [126.908660460447649, 37.647403918746114],
              [126.90863541103775, 37.647416199110403],
              [126.908623564086383, 37.647410630782723],
              [126.90861282989961, 37.647405684543195],
              [126.908590920723753, 37.647390483711042],
              [126.908577327712237, 37.647378673014614],
              [126.908538270332357, 37.647349991509593],
              [126.908513285717106, 37.647331231207048],
              [126.908413666162673, 37.647256587227666],
              [126.908263604169974, 37.64734099964992],
              [126.9081232357332, 37.647420029088607],
              [126.90804726379308, 37.647462495406891],
              [126.907776275182513, 37.647610319238375],
              [126.907712725045485, 37.647645008815253],
              [126.907659437326018, 37.647674097635857],
              [126.907632571739782, 37.647692365226298],
              [126.907620980721646, 37.647700248656548],
              [126.90755223082563, 37.64774834277901],
              [126.907500580095643, 37.647785105573966],
              [126.907473209198386, 37.64780461442728],
              [126.907300630546416, 37.647927616594828],
              [126.907225663858057, 37.647981254926648],
              [126.907109300103414, 37.648063690014737],
              [126.907012585735828, 37.648117296421319],
              [126.907006707459828, 37.648106119203646],
              [126.907005874281694, 37.648104836025936],
              [126.907004793019382, 37.648103674182266],
              [126.907003492900913, 37.64810266446483],
              [126.907002005457443, 37.64810183317077],
              [126.907000371331222, 37.648101201234944],
              [126.906998630066795, 37.648100785080118],
              [126.906996829186426, 37.648100595761179],
              [126.90699486878178, 37.648100652605166],
              [126.906992948203708, 37.64810097554215],
              [126.906991127593827, 37.648101556762484],
              [126.90691698332374, 37.64813140928279],
              [126.906915459576936, 37.648132149727424],
              [126.906914099906501, 37.648133069460584],
              [126.90691293960964, 37.648134148830472],
              [126.906912004979674, 37.648135360027965],
              [126.906911322345877, 37.648136670737514],
              [126.906910906673531, 37.648138052191769],
              [126.906910769591832, 37.648139467494531],
              [126.906910913633027, 37.648140883309345],
              [126.906911335683162, 37.648142263567244],
              [126.906912023553048, 37.648143573055002],
              [126.906925928731027, 37.648165292046514],
              [126.906922928243574, 37.648166991294573],
              [126.906642870416746, 37.648322265070433],
              [126.906530232555809, 37.64838420401265],
              [126.90652220910647, 37.648388616226292],
              [126.90648679572584, 37.6484088856703],
              [126.90643778676295, 37.648437055369804],
              [126.906172069222151, 37.64858632248837],
              [126.906094153823474, 37.64863012794406],
              [126.906029314602321, 37.64865879913274],
              [126.90592692042226, 37.648705449307911],
              [126.905837992673369, 37.648747885895197],
              [126.905540532878362, 37.648889835124962],
              [126.905164546197071, 37.648969212019388],
              [126.904986721462365, 37.649082851015656],
              [126.904802024510531, 37.649201014901962],
              [126.904805681544744, 37.649168195147617],
              [126.904810163395553, 37.64912804361839],
              [126.904815580273905, 37.649123851816263],
              [126.904822392202718, 37.649118573737283],
              [126.90488426536723, 37.649070621394621],
              [126.905015640088564, 37.64896880179095],
              [126.905168498637636, 37.64885186147238],
              [126.905223005328921, 37.648810161294833],
              [126.905228495898612, 37.648805962634228],
              [126.905341039822886, 37.648718487047688],
              [126.905353503357887, 37.648708137692807],
              [126.905391403208313, 37.648676627099817],
              [126.90541450916966, 37.648657418113281],
              [126.90562516060595, 37.648481358102366],
              [126.905648348299522, 37.648460412614938],
              [126.905880763839093, 37.648250421199677],
              [126.905949104184899, 37.648187902890754],
              [126.906096637580987, 37.648052876349226],
              [126.906204932955973, 37.647954297560076],
              [126.906222334745763, 37.647938455015627],
              [126.906236095769188, 37.647925994867975],
              [126.906323302299313, 37.647847006620268],
              [126.906348716560885, 37.647821709751597],
              [126.906350459378416, 37.64782007809832],
              [126.906385064530141, 37.647787670898872],
              [126.90644442704361, 37.64773472613026],
              [126.906479401904264, 37.64770342759784],
              [126.906588742890946, 37.647602098336669],
              [126.906622004404682, 37.647571277861474],
              [126.906796186910228, 37.647409847454348],
              [126.90697390971809, 37.647245019433988],
              [126.907237311988837, 37.64699588477734],
              [126.907239728791197, 37.646993595820931],
              [126.907291561695359, 37.646944553403237],
              [126.907473404040189, 37.646655567916696],
              [126.907560308160427, 37.646517255149945],
              [126.907586598908367, 37.646475974582458],
              [126.907599549119979, 37.646455642473192],
              [126.907687631613356, 37.646317335523683],
              [126.907705177831815, 37.646289783374471],
              [126.907951143950001, 37.646178429962148],
              [126.908128120959418, 37.646098321612889],
              [126.908166897294748, 37.646080034339214],
              [126.908250965761582, 37.646040286399185],
              [126.908420820353371, 37.645962857593368],
              [126.908484888877481, 37.645933616967689],
              [126.908613950197847, 37.645879922712091],
              [126.908745636188158, 37.645825138238223],
              [126.908777164707132, 37.645810240399975],
              [126.909216213678874, 37.645547895344109],
              [126.909284067719341, 37.645517487985522],
              [126.909340321521086, 37.645492179930486],
              [126.909386645441316, 37.645461726905282],
              [126.90944139565174, 37.645425594387042],
              [126.909652187244589, 37.645331436286149],
              [126.909725324611045, 37.645314598526831],
              [126.909915020941426, 37.645255345746726],
              [126.910073575427177, 37.645170154329783],
              [126.910116221599338, 37.645145157315611],
              [126.91014072469909, 37.645130735937201],
              [126.910140739735482, 37.645117940700423],
              [126.910140791013688, 37.645076768101525],
              [126.910241496739417, 37.645043139036851],
              [126.910495363079292, 37.64495838382684],
              [126.910589636029087, 37.644930309677186],
              [126.910663630584494, 37.64490820011784],
              [126.910884529979498, 37.644846509409298],
              [126.910965302080911, 37.644823923350408],
              [126.911185190537822, 37.644764446108745],
              [126.911484036314846, 37.64462727544128],
              [126.91177516804342, 37.644495051717527],
              [126.911906384311123, 37.644444751781812],
              [126.912001170896133, 37.64440831289086],
              [126.912029604122012, 37.644397382637017],
              [126.912227127953102, 37.644332641761963],
              [126.912279862800773, 37.644315359152905],
              [126.912269033867446, 37.644283476452884],
              [126.912260634419255, 37.644258742605544],
              [126.912227877779941, 37.644221228192151],
              [126.91220848264166, 37.644199015565079],
              [126.912175259891256, 37.644141831740775],
              [126.912107197016027, 37.643914850736223],
              [126.912085055417649, 37.643840729283113],
              [126.912066739690559, 37.643779665567578],
              [126.91133440809277, 37.641538757338701],
              [126.91121521207225, 37.641174077243647],
              [126.911202816797683, 37.641136117940768],
              [126.911153951137194, 37.640986709771951],
              [126.911151478672096, 37.640979146064481],
              [126.911145697770692, 37.640963664759681],
              [126.911145566787127, 37.640963314388742],
              [126.910892067448756, 37.640284295145541],
              [126.910841471370205, 37.64015077499915],
              [126.91082376933106, 37.640104096694593],
              [126.910794205687438, 37.640026136071612],
              [126.910556537006784, 37.639523379561815],
              [126.910092004954166, 37.638509421270093],
              [126.910170314997046, 37.638334627875537],
              [126.910203408639049, 37.638260667674757],
              [126.91021817744614, 37.638227657167135],
              [126.910258465737883, 37.638098090908557],
              [126.910315965294629, 37.637912976762735],
              [126.910316170675713, 37.637912517207937],
              [126.910406674434299, 37.637709413311249],
              [126.910796909864644, 37.636833678596282],
              [126.91087452971496, 37.63665944546117],
              [126.911210373411691, 37.635905584510624],
              [126.91117815700332, 37.635852272084584],
              [126.910889654616341, 37.635374844774923],
              [126.910854460516902, 37.635316603317612],
              [126.910402731020937, 37.635155066651556],
              [126.910383246616405, 37.635148099314407],
              [126.909806595859251, 37.634941887830813],
              [126.909122459220498, 37.634332915081707],
              [126.908112671356093, 37.633829821614043],
              [126.908027141695413, 37.633793481565952],
              [126.90709624965038, 37.633397951033821],
              [126.906202194900942, 37.633220135492927],
              [126.906757401195108, 37.632736307291196],
              [126.906762906109989, 37.632706477382229],
              [126.906929397498658, 37.631804491370559],
              [126.907294157386886, 37.630867206210503],
              [126.908118272065749, 37.630060638082242],
              [126.90838209484366, 37.629994121406611],
              [126.908482623979765, 37.629821432223373],
              [126.908770042291806, 37.629327703062842],
              [126.908801834540824, 37.629087917191697],
              [126.908803260924131, 37.629065080013845],
              [126.908804144132247, 37.629050868590177],
              [126.908821281509404, 37.62877533354574],
              [126.908844101004931, 37.628408370327534],
              [126.908895635723468, 37.62757970339738],
              [126.90890317034976, 37.62745855667719],
              [126.908902196606803, 37.627454584123107],
              [126.908749987458123, 37.626833499410836],
              [126.908610827537274, 37.626265655912121],
              [126.908395755118136, 37.625992380885883],
              [126.90778997405549, 37.625502274957242],
              [126.907439859448431, 37.625094024086415],
              [126.907021832246528, 37.624786231704221],
              [126.906636548147588, 37.624502545566607],
              [126.906627149863354, 37.624495624722378],
              [126.906708540363752, 37.624325885343346],
              [126.906748880548179, 37.624241757446825],
              [126.906999540755294, 37.62393674913627],
              [126.907108988760157, 37.623448741137118],
              [126.907142807727524, 37.623312656194344],
              [126.907100194153301, 37.623007138144381],
              [126.907113111297051, 37.62286946998077],
              [126.907115882454022, 37.622836732979827],
              [126.907140424873432, 37.622546824563614],
              [126.907117469551721, 37.621941462406213],
              [126.906827673963861, 37.621737940933336],
              [126.906278220223953, 37.621352052218668],
              [126.906204026114608, 37.621279800963563],
              [126.905612695907138, 37.620703934788843],
              [126.905557581441428, 37.620623081341769],
              [126.905213923755696, 37.61986785001001],
              [126.90518925911924, 37.619813873570934],
              [126.905195814125491, 37.61969997776302],
              [126.905202932213228, 37.619576276453891],
              [126.905211315599232, 37.619430578107831],
              [126.905223473856381, 37.619219254489835],
              [126.905223868904216, 37.619211768182211],
              [126.905231092336734, 37.61907454555007],
              [126.905097693414305, 37.619041772908936],
              [126.904917583935301, 37.618997137327675],
              [126.904700327807845, 37.618942900523315],
              [126.904552970479727, 37.618909963108457],
              [126.904509411221966, 37.618900225185612],
              [126.904489014916919, 37.618894249148205],
              [126.904274287546286, 37.618831333641268],
              [126.903821830135797, 37.618803856192343],
              [126.903729055872702, 37.618798220969602],
              [126.903361230614053, 37.618839308575588],
              [126.903340162397001, 37.61830601857325],
              [126.903051609901809, 37.618093769139428],
              [126.903026637274081, 37.617918437504002],
              [126.903009462524111, 37.617797849197331],
              [126.903007773795366, 37.617785993492703],
              [126.903125572464916, 37.61730434575189],
              [126.903017422575488, 37.617232261413982],
              [126.902798623477892, 37.617086432148973],
              [126.902478363088704, 37.616899211358579],
              [126.902473192225884, 37.616665508363134],
              [126.902232220813914, 37.616445557051399],
              [126.902149011641455, 37.616369607137671],
              [126.901899030183941, 37.61589975907895],
              [126.901822603043044, 37.615753566114705],
              [126.901910136647885, 37.615141243493795],
              [126.901917096238662, 37.615092548916067],
              [126.901893089611235, 37.615055084872616],
              [126.901728868698342, 37.614776202730035],
              [126.901799181751031, 37.614470216554061],
              [126.901734240569297, 37.614069223414504],
              [126.901707727311617, 37.61402590956456],
              [126.901173429061458, 37.613153014823389],
              [126.901000723023841, 37.6128708545479],
              [126.900949495805889, 37.612785001771002],
              [126.90093720890215, 37.612764408749044],
              [126.900913493756349, 37.612724660185741],
              [126.900950192496538, 37.612441085851643],
              [126.900961950287765, 37.612350225894943],
              [126.900914751146885, 37.612266239482203],
              [126.900889920586764, 37.61222205607978],
              [126.900864619312145, 37.612176950918474],
              [126.900790527461936, 37.612044873189909],
              [126.900304323939821, 37.611191344880439],
              [126.900705263280344, 37.610318854053325],
              [126.900601150423853, 37.609319522037481],
              [126.900675625801199, 37.608942020953734],
              [126.900775752159845, 37.60843925148712],
              [126.900874744606085, 37.608121170988106],
              [126.901246746205572, 37.606778742899436],
              [126.90141386441762, 37.606190712511832],
              [126.901579423339925, 37.605978558817782],
              [126.901637560224614, 37.605905971429159],
              [126.901655425401046, 37.605882219821893],
              [126.901658844752333, 37.60587767458648],
              [126.901725502637333, 37.605789057698871],
              [126.901743386071388, 37.605765282735398],
              [126.901725884183534, 37.605752312090466],
              [126.901717632484377, 37.605745732854849],
              [126.901708563494481, 37.605738502314701],
              [126.901715527201901, 37.60573459516656],
              [126.901721926448744, 37.6057310033287],
              [126.901749529436117, 37.605715273405217],
              [126.901699118189157, 37.605641194670532],
              [126.901505409825504, 37.60535653890696],
              [126.901823464546197, 37.604763007934437],
              [126.901848602722026, 37.60462168297083],
              [126.902013034917871, 37.604301876984515],
              [126.902029916387264, 37.604269046557761],
              [126.902097029436888, 37.603818580543532],
              [126.902108299841387, 37.603741684997821],
              [126.901951116415177, 37.603674475274815],
              [126.901442038906552, 37.603456796382162],
              [126.901306839986162, 37.603398985590978],
              [126.901044543858319, 37.603384705156053],
              [126.900770912482983, 37.60336980865592],
              [126.90015265671903, 37.603116445560644],
              [126.900204541870636, 37.602567727609419],
              [126.899982304104697, 37.602040183934577],
              [126.900011893488781, 37.601963333035009],
              [126.900180413117837, 37.601525651580772],
              [126.90029321967873, 37.60123266524409],
              [126.900529024652684, 37.600880635901881],
              [126.901012490874422, 37.600175469560639],
              [126.901016795749356, 37.599873077869518],
              [126.901322688352295, 37.599366534499907],
              [126.901297260702293, 37.599192793241777],
              [126.901261222473337, 37.598488590873664],
              [126.901199178973243, 37.598294511672634],
              [126.901179253854167, 37.598232173125197],
              [126.901172101573579, 37.598209798952944],
              [126.901171371079755, 37.598207515760357],
              [126.901253507991029, 37.597908855597765],
              [126.901094608678292, 37.597524955054013],
              [126.901011881367481, 37.59733709275821],
              [126.901024756133566, 37.59725719670535],
              [126.901076295635434, 37.596937336863476],
              [126.901201110531872, 37.596746541975428],
              [126.901224701992945, 37.596439381618389],
              [126.901210550090113, 37.596301690644808],
              [126.901360242032155, 37.596193725958678],
              [126.901426992066646, 37.596145580567928],
              [126.901628153085454, 37.596000494410937],
              [126.901754999501961, 37.595640047388919],
              [126.901784261094079, 37.595556892120662],
              [126.901791812104292, 37.595473277086292],
              [126.90182975856888, 37.595141350177833],
              [126.901792663071134, 37.595117514625258],
              [126.901213450889813, 37.594745324861634],
              [126.901173394138894, 37.594719584883165],
              [126.900355099449968, 37.594211235394795],
              [126.900020700541234, 37.593831976565795],
              [126.90000348959498, 37.593801210179826],
              [126.899895726067868, 37.593608571524491],
              [126.899878093135001, 37.593577046749971],
              [126.899826585807588, 37.593467757906019],
              [126.899778212184117, 37.593386627501019],
              [126.899719011523231, 37.593346862170748],
              [126.899413912549349, 37.593141920971668],
              [126.899299471420562, 37.592868148842932],
              [126.899064939640922, 37.592736403124476],
              [126.898961630859873, 37.592678369812312],
              [126.89896628123887, 37.592670701460712],
              [126.898999981911103, 37.592615118900866],
              [126.899060988990186, 37.592514499316863],
              [126.899112165968688, 37.592430093427907],
              [126.899282406872487, 37.592149311167596],
              [126.899566455451705, 37.591689774379837],
              [126.899568293701236, 37.591685140929712],
              [126.899687587941344, 37.591384396465067],
              [126.899696411479724, 37.591362153910488],
              [126.899709796730775, 37.591004384624974],
              [126.899710356709988, 37.590989383767933],
              [126.899656143501261, 37.590862272371965],
              [126.899575450482587, 37.59067307714956],
              [126.899592381678374, 37.590601931278108],
              [126.89966647282904, 37.590290579693928],
              [126.899687599394611, 37.590201801495397],
              [126.899750355142444, 37.589938087496108],
              [126.899649289838763, 37.589823121312548],
              [126.899643711696953, 37.589816871890228],
              [126.899554216080787, 37.589801435469042],
              [126.899498096677078, 37.58979173907364],
              [126.89948428155445, 37.589809746063978],
              [126.899468744295774, 37.589787208320701],
              [126.898427997674872, 37.58960076618866],
              [126.898082090326582, 37.589538797677356],
              [126.89807010670394, 37.589493907512392],
              [126.897962409954133, 37.589090452881713],
              [126.897572669709433, 37.588962838225299],
              [126.896991760543017, 37.588639592357694],
              [126.89695840696325, 37.58862103191062],
              [126.896940709121139, 37.588611184481131],
              [126.89685903491997, 37.588566622217819],
              [126.896414215814517, 37.58873826015575],
              [126.896102058821612, 37.588735302429953],
              [126.895954433970601, 37.588733903475401],
              [126.895687388371542, 37.588798147783677],
              [126.895231219605151, 37.588864464636487],
              [126.89428257218124, 37.588820930883344],
              [126.894267472438798, 37.588820237778208],
              [126.893974248805819, 37.589001005197552],
              [126.893840577883878, 37.589071277692227],
              [126.893667934980954, 37.589055632406719],
              [126.893327886019861, 37.589067430414879],
              [126.893225907260302, 37.589003980060092],
              [126.892341535647134, 37.588573660826199],
              [126.892235727827114, 37.588522175021218],
              [126.892052064348078, 37.588548745278509],
              [126.891766736961443, 37.588530739317086],
              [126.89166330643927, 37.588485037431973],
              [126.891558759633043, 37.588483533218287],
              [126.891449218562045, 37.588466209883364],
              [126.891444138280704, 37.588465406735303],
              [126.89143970687536, 37.588466290635651],
              [126.891112570282274, 37.588531500959917],
              [126.891083303251364, 37.588537333973967],
              [126.890936616299427, 37.588566572832299],
              [126.890711768430563, 37.588679832068308],
              [126.890322435162901, 37.588875427821286],
              [126.890224547935219, 37.588924604011787],
              [126.889602083096037, 37.589124607182029],
              [126.889578865669748, 37.589132066477873],
              [126.889258390450777, 37.588832294109409],
              [126.889253453475078, 37.588827676237031],
              [126.889205183428672, 37.588821711061961],
              [126.889075616213873, 37.588805697896525],
              [126.888911368738363, 37.588785400341962],
              [126.888732026121843, 37.588788518708853],
              [126.888343654878028, 37.58879527229017],
              [126.887848492206345, 37.588708877051459],
              [126.887612994948995, 37.588667786490682],
              [126.887608605325255, 37.588667020110549],
              [126.887522317855186, 37.588636528931822],
              [126.887322935166225, 37.588523993010796],
              [126.887305600925558, 37.588523698427537],
              [126.887159281169204, 37.588532845430592],
              [126.887054724203452, 37.58864846718069],
              [126.886891712270938, 37.588814991221781],
              [126.886739862047648, 37.588974486691271],
              [126.886557532156047, 37.589162103083979],
              [126.886510950231084, 37.589187549156428],
              [126.886257055952939, 37.58932624062178],
              [126.886133823375786, 37.589393414760245],
              [126.885824445479628, 37.589530023042514],
              [126.885904776990941, 37.589591331917575],
              [126.885905996965079, 37.589592426181767],
              [126.885906977900333, 37.589593663416601],
              [126.885907213265753, 37.589594105396237],
              [126.885907695145235, 37.589595011045603],
              [126.885908127468511, 37.589596433806101],
              [126.885908266127473, 37.589597892894936],
              [126.885908104637238, 37.589599350422176],
              [126.885907648979455, 37.58960076766131],
              [126.885906910776711, 37.58960210861926],
              [126.885905910727516, 37.58960333554738],
              [126.885904672876421, 37.589604417023949],
              [126.885903174518901, 37.589605359240146],
              [126.885839861405501, 37.589639047272158],
              [126.885812463097636, 37.5896536253831],
              [126.885810762492326, 37.589654368251018],
              [126.885808925667774, 37.589654871555055],
              [126.885807007138823, 37.589655117552148],
              [126.885805062471405, 37.589655098419357],
              [126.885803151688052, 37.589654815370352],
              [126.885801329088849, 37.589654276799791],
              [126.885800146541825, 37.589653728039728],
              [126.885799650061912, 37.589653496515929],
              [126.8857981619486, 37.58965250270672],
              [126.885796910987082, 37.589651319948906],
              [126.885795932007696, 37.589649982674658],
              [126.885786425639225, 37.589633960567006],
              [126.885807618804449, 37.589684336755759],
              [126.885826533353963, 37.589729297553006],
              [126.885835705322492, 37.589751831898205],
              [126.885859558949846, 37.589810790054123],
              [126.885860203950301, 37.58981238337315],
              [126.885871885116615, 37.589806763910353],
              [126.885880876720393, 37.589936856864405],
              [126.885832510460048, 37.589997406862921],
              [126.885814580102547, 37.590019853358989],
              [126.88581051424174, 37.590072306385373],
              [126.885775958049607, 37.590518167919285],
              [126.885769466966252, 37.590542786674632],
              [126.885735730121979, 37.59067073740286],
              [126.885726924857565, 37.590726369115117],
              [126.885714118457926, 37.590807269782978],
              [126.885651938350264, 37.590863798131963],
              [126.885638629226051, 37.590889131304507],
              [126.88562993073387, 37.590905168625746],
              [126.885562295825864, 37.590936565329898],
              [126.88554034969944, 37.590946751587147],
              [126.885489361676719, 37.5909703499945],
              [126.88548453550294, 37.591001034400463],
              [126.885478742030102, 37.591035433597312],
              [126.885467039544963, 37.591104919109839],
              [126.885476481832711, 37.591143763719259],
              [126.885493052400335, 37.591211936264457],
              [126.885502582420727, 37.591227990963382],
              [126.885533638700352, 37.591280667380424],
              [126.885536399141756, 37.591325439557416],
              [126.885540203629532, 37.591384854018131],
              [126.885561185129248, 37.591427672306999],
              [126.885595394032336, 37.591498379285355],
              [126.885597655980206, 37.591501834176036],
              [126.885642659960325, 37.591477024724469],
              [126.885644307462542, 37.591476270766911],
              [126.885646090135523, 37.591475746453042],
              [126.885647956883275, 37.591475466839761],
              [126.885649857801823, 37.591475439779288],
              [126.885651736250452, 37.591475665878178],
              [126.885653541303569, 37.591476139463275],
              [126.885655222132073, 37.591476843144648],
              [126.885656730142301, 37.591477763149229],
              [126.885658024780625, 37.591478872225792],
              [126.885659067810479, 37.591480136825744],
              [126.885695329426227, 37.591533328818336],
              [126.885695935567128, 37.5915344144816],
              [126.885696224831989, 37.591534776522842],
              [126.885742474157297, 37.591605548827964],
              [126.885742745973332, 37.591605966661319],
              [126.885743422125415, 37.591607216732413],
              [126.885743853080271, 37.5916085313252],
              [126.8857440225879, 37.591609958126085],
              [126.885749165429431, 37.591629490773919],
              [126.885749296253834, 37.591629973671253],
              [126.88574952599312, 37.591631376449435],
              [126.885749478102539, 37.591632793105546],
              [126.885749150635249, 37.591634184871815],
              [126.885748554059418, 37.591635518453216],
              [126.88574770112325, 37.591636758763862],
              [126.885746613562304, 37.591637879777998],
              [126.88574531880505, 37.591638851894174],
              [126.8857438487737, 37.591639650041017],
              [126.88574223987662, 37.59164025457855],
              [126.885741651694616, 37.591640429081288],
              [126.885704486587031, 37.59165166914547],
              [126.885715268662395, 37.591666584740658],
              [126.885766764810811, 37.591744251645949],
              [126.885769444452635, 37.591748293676154],
              [126.885899492004043, 37.591854845295003],
              [126.885995086916907, 37.591936594761414],
              [126.886144148333017, 37.592100045616434],
              [126.886161733536341, 37.592142425206085],
              [126.886175351194467, 37.592175243483581],
              [126.886215094300482, 37.592327883750194],
              [126.886270324270356, 37.592308799369313],
              [126.886271598219452, 37.592310775419776],
              [126.886404642939439, 37.592517281648604],
              [126.886438885996085, 37.592567429005655],
              [126.886522633464367, 37.592661250279043],
              [126.886527966033256, 37.592667225031917],
              [126.886563403953232, 37.592706923929946],
              [126.886598381987824, 37.592816594087836],
              [126.886598496865133, 37.592816950713839],
              [126.886620446260864, 37.592885770260807],
              [126.886677517088941, 37.593044335677],
              [126.886721434161373, 37.593135892064495],
              [126.88671911435074, 37.593150241947477],
              [126.886710096109425, 37.593146575309738],
              [126.88671518342565, 37.593173330954563],
              [126.886762173277404, 37.593225741912093],
              [126.886882650234313, 37.593343275098931],
              [126.886919045256221, 37.593377934966021],
              [126.886955039738865, 37.593438226823913],
              [126.886986981584471, 37.593489786179944],
              [126.887007297383022, 37.593510637815868],
              [126.887170709219504, 37.593666494189918],
              [126.887181663218826, 37.593678334270784],
              [126.887251213803893, 37.593779203684399],
              [126.887317225428674, 37.593883717726747],
              [126.887321123551331, 37.593890586335732],
              [126.887337688601974, 37.593919777461622],
              [126.887303193786735, 37.593923122633264],
              [126.887287552524057, 37.593907399598791],
              [126.887272331673884, 37.593915143302702],
              [126.887214397560271, 37.593900163320129],
              [126.887154425433636, 37.593884657161034],
              [126.887036493305416, 37.593854162655234],
              [126.886869317360308, 37.593811084614394],
              [126.886845674743213, 37.593804991322656],
              [126.886799254370601, 37.59380598859704],
              [126.886732789863913, 37.593807413495341],
              [126.886683041152722, 37.593827357601945],
              [126.886465379395204, 37.59391417358448],
              [126.886365449207645, 37.593954030456473],
              [126.886272913935883, 37.593955908121458],
              [126.88619065608782, 37.593957376776252],
              [126.886093506008635, 37.593959112225193],
              [126.885976375244979, 37.593962094754843],
              [126.885796283949958, 37.593953757713379],
              [126.885720759603117, 37.593939878831307],
              [126.885409994726501, 37.593882705191994],
              [126.885294801995016, 37.593803639242694],
              [126.885281051322409, 37.593813166885191],
              [126.885314179522581, 37.593842526579287],
              [126.88531528018531, 37.593843673406631],
              [126.885316136264279, 37.593844946950284],
              [126.885316726519861, 37.593846312848804],
              [126.885317034250633, 37.593847735862902],
              [126.885317051831592, 37.593849178998084],
              [126.885316781832117, 37.593850605312753],
              [126.885316226791872, 37.593851981470635],
              [126.885315402894946, 37.593853267897074],
              [126.885314333054808, 37.593854433164587],
              [126.885313040170161, 37.593855447648181],
              [126.885261865251891, 37.593889671750624],
              [126.885257613315133, 37.593892513158103],
              [126.885256022396334, 37.593893404408028],
              [126.885254263455295, 37.593894065842655],
              [126.885252386501463, 37.593894476991707],
              [126.885250450524424, 37.59389462734638],
              [126.88524850993845, 37.593894511782032],
              [126.885246623621839, 37.593894133309341],
              [126.885244845855482, 37.593893503027147],
              [126.885243230853405, 37.59389264014591],
              [126.885241823730951, 37.593891568335486],
              [126.885219931850045, 37.5938717798808],
              [126.885173675693267, 37.59382995566078],
              [126.885172571894358, 37.593828777268911],
              [126.885171773237332, 37.593827546386272],
              [126.885168695427438, 37.593829076149426],
              [126.88516711666098, 37.593829729507959],
              [126.885165428565529, 37.593830178594359],
              [126.885163670882193, 37.593830411898224],
              [126.885161890110993, 37.593830422451099],
              [126.88516013041999, 37.593830211384365],
              [126.885158433689455, 37.593829782521588],
              [126.885156841733064, 37.593829147797969],
              [126.885155394069272, 37.593828322742141],
              [126.88515412678143, 37.593827327371592],
              [126.885153070259747, 37.59382618527939],
              [126.885088364582288, 37.593743259400988],
              [126.885087541356697, 37.593741986026807],
              [126.885086980474767, 37.593740630194922],
              [126.885086696453925, 37.593739217218456],
              [126.885086696896792, 37.593737786795906],
              [126.885086982639351, 37.593736374985184],
              [126.885087122477316, 37.593736037711636],
              [126.88502743226104, 37.593667212336648],
              [126.885026194893015, 37.593667261772389],
              [126.885024252648719, 37.593667073187284],
              [126.885022379414352, 37.593666619968062],
              [126.885020632846434, 37.593665915935247],
              [126.885019063716143, 37.593664985689557],
              [126.885017719411451, 37.593663852011346],
              [126.884946059384603, 37.593591884349976],
              [126.88494544492039, 37.593591946945686],
              [126.884943551478514, 37.593591884801008],
              [126.884941696345194, 37.593591572283707],
              [126.884939933823262, 37.593591017788825],
              [126.88493948476733, 37.593590801831368],
              [126.884938312492707, 37.593590236892304],
              [126.884936876343261, 37.593589252356807],
              [126.884935667054876, 37.593588092341115],
              [126.88493471723838, 37.593586785857951],
              [126.8849109932858, 37.593546563457828],
              [126.884910371982357, 37.59354525508116],
              [126.884910011711312, 37.593543885872464],
              [126.884909923532561, 37.593542488337562],
              [126.8849101419909, 37.593540994154758],
              [126.884910560059012, 37.593539745275017],
              [126.884911270630198, 37.593538465471468],
              [126.884912221627772, 37.593537288772161],
              [126.884913389027574, 37.593536243894768],
              [126.884914746592116, 37.593535353236],
              [126.884922293591785, 37.593531146358444],
              [126.884856563549206, 37.593444335649487],
              [126.88484416593495, 37.593428476871345],
              [126.884637617584616, 37.593164255151791],
              [126.884475278803734, 37.592891546779995],
              [126.884334192159983, 37.592719650239509],
              [126.88411238612953, 37.592454208701128],
              [126.883767316841471, 37.592054611482823],
              [126.883718062670994, 37.592002819348465],
              [126.883575149576046, 37.591852544489875],
              [126.883523550072127, 37.591837795879435],
              [126.883348797191331, 37.591787844739869],
              [126.882922862814283, 37.591514302038753],
              [126.882808856257284, 37.59144858598485],
              [126.882681590694943, 37.591376657847604],
              [126.882395682616348, 37.591177069420667],
              [126.882377450348613, 37.591164341747756],
              [126.882323027829386, 37.59112655403267],
              [126.88224954494487, 37.591054409335619],
              [126.882183581207229, 37.590989789573257],
              [126.882180846340503, 37.590987110899064],
              [126.88210861524928, 37.5909163508137],
              [126.882072021654565, 37.590880503160498],
              [126.882030897180698, 37.590817679928044],
              [126.882042878961229, 37.590805758454152],
              [126.882056063380446, 37.590792639086168],
              [126.882055831745006, 37.590792433269449],
              [126.881900952934032, 37.590652271649326],
              [126.88188690658383, 37.590639499121878],
              [126.88188674599381, 37.590639194529565],
              [126.881900921289088, 37.590625692791804],
              [126.881907656537408, 37.590619231209438],
              [126.881986134318169, 37.590539824954213],
              [126.881731234465903, 37.590358433063969],
              [126.881723640741043, 37.59034913653165],
              [126.88127834445126, 37.590125691055221],
              [126.881152315233606, 37.590062213296676],
              [126.881004368072993, 37.589982938057346],
              [126.880851837453022, 37.589888458232721],
              [126.880754990608111, 37.589828110250807],
              [126.88066591895182, 37.589771148759354],
              [126.880597192619234, 37.589714479646517],
              [126.880349139342826, 37.589506159835111],
              [126.880222427342204, 37.589320761435118],
              [126.880106293545225, 37.589154680976392],
              [126.880058394361527, 37.589086179984044],
              [126.880044512678225, 37.589066328080058],
              [126.880034454633446, 37.589047740106651],
              [126.8800341007599, 37.5890469784188],
              [126.880002896144845, 37.58897985400624],
              [126.879989239725731, 37.588950874513145],
              [126.879988334795058, 37.58894895351203],
              [126.879940266433863, 37.588846943386841],
              [126.879900815608522, 37.58876322432917],
              [126.879796937040297, 37.588577709263525],
              [126.879794807446928, 37.588573907941033],
              [126.87965615090161, 37.588326561984069],
              [126.879627226655003, 37.588275852027692],
              [126.879606750538358, 37.588244575182898],
              [126.879592289949557, 37.588217810512418],
              [126.879539581871995, 37.588102042117285],
              [126.879438985095632, 37.587878350611575],
              [126.879437351379295, 37.587874717735879],
              [126.87932282829675, 37.58758375172998],
              [126.879322762425986, 37.587583363807582],
              [126.879281748680128, 37.587308157524561],
              [126.879263844452524, 37.587188047289914],
              [126.879263790636799, 37.587187708103194],
              [126.879205009262108, 37.586998719723333],
              [126.879170802245909, 37.586890981140002],
              [126.879133022398719, 37.586771993059052],
              [126.879016281635288, 37.58667809658602],
              [126.878871545897439, 37.586561684135091],
              [126.878844525653506, 37.586548869107489],
              [126.878465156672064, 37.586368954816209],
              [126.878213601483381, 37.586335845989339],
              [126.877744135002104, 37.58627405391487],
              [126.877542272508705, 37.586247481647327],
              [126.877301728794649, 37.585659606555673],
              [126.877300125916591, 37.585655688988936],
              [126.877265721729245, 37.585571610774046],
              [126.877257021653946, 37.585359294837509],
              [126.87724720160719, 37.585119634444197],
              [126.877247131683234, 37.585119326718377],
              [126.877193305507731, 37.584935622757207],
              [126.877149862531255, 37.584891353156962],
              [126.877086811777147, 37.584827104619386],
              [126.877001926422423, 37.584785982757488],
              [126.876924893559917, 37.584748666281108],
              [126.876745712075149, 37.584637258392227],
              [126.876537933629507, 37.584440052964183],
              [126.876561753090684, 37.584244677234658],
              [126.876623441913239, 37.584164500762419],
              [126.876696354672546, 37.584149936181817],
              [126.876703787449472, 37.584149588756134],
              [126.876778793960895, 37.584146076595623],
              [126.876973765784598, 37.584238921765028],
              [126.877049690967624, 37.584238665415057],
              [126.877164988867634, 37.584238276972449],
              [126.877192127462223, 37.584224653102062],
              [126.877293709708752, 37.5841736594368],
              [126.877331245690442, 37.584047840201144],
              [126.87733116143427, 37.584047485530022],
              [126.877264947811796, 37.583822401498786],
              [126.87725077916906, 37.58377425197186],
              [126.877146679224992, 37.583609432575216],
              [126.87711434557211, 37.583564341867522],
              [126.876987752091352, 37.583387799773526],
              [126.876989308871075, 37.583354703569952],
              [126.876995814681322, 37.583216328115064],
              [126.877044871333936, 37.583076374549371],
              [126.877062110292158, 37.583027190161552],
              [126.877075273581369, 37.582982155664681],
              [126.877098415144133, 37.582892918920102],
              [126.877091060760037, 37.582740878095038],
              [126.877088050479259, 37.582653870257424],
              [126.87708430895978, 37.582545745565312],
              [126.877081798175112, 37.582521125726124],
              [126.877068914474947, 37.582394790778686],
              [126.877049655154167, 37.582206116130067],
              [126.876974027148904, 37.582066178681721],
              [126.87695832381047, 37.582037123112791],
              [126.876949499459869, 37.582024678598899],
              [126.876671371700297, 37.581632502611036],
              [126.876671300178018, 37.581632115558314],
              [126.876664417655817, 37.581593664086562],
              [126.876625448162528, 37.581375953136245],
              [126.876625376655767, 37.58137556428089],
              [126.87666271459166, 37.581259601210839],
              [126.876919232386996, 37.581033499563091],
              [126.877161167132599, 37.580854964355979],
              [126.877261007636136, 37.580771179727726],
              [126.877417845451419, 37.580639565864765],
              [126.877461946306269, 37.580499958171295],
              [126.877476823347436, 37.580383404058786],
              [126.877469541717403, 37.580307973021313],
              [126.877469510346657, 37.580307660090668],
              [126.877455085917063, 37.580260910797541],
              [126.877424489336946, 37.580222934583531],
              [126.877389243073722, 37.580179185086124],
              [126.877367657990959, 37.580115667327341],
              [126.877367533104191, 37.580115296217599],
              [126.877367420329534, 37.580114966633403],
              [126.877426116357512, 37.580021831533713],
              [126.877475050727384, 37.579965983861065],
              [126.877543442302894, 37.57988793239155],
              [126.87762910051319, 37.579752045456949],
              [126.877631558862305, 37.57974814659984],
              [126.877587926622724, 37.579625856081776],
              [126.877522132046849, 37.579514847933986],
              [126.877449806356523, 37.579399350877381],
              [126.877427209296556, 37.579363266400094],
              [126.87729305730187, 37.579200393159333],
              [126.877259162578653, 37.579159241200834],
              [126.877127667188887, 37.578984256046475],
              [126.877077802896537, 37.578904637070657],
              [126.877076497459655, 37.578902554393814],
              [126.87702730008418, 37.578908733942257],
              [126.876754149389285, 37.578943039241373],
              [126.876444083239164, 37.578977274194443],
              [126.876443739768376, 37.578977312039058],
              [126.876387922817926, 37.578983475280765],
              [126.87625922082178, 37.578962514215853],
              [126.876260486345302, 37.578185234318809],
              [126.87519538309499, 37.578274481160143],
              [126.873915250959186, 37.578280700295537],
              [126.873084866396127, 37.578221803474619],
              [126.872147247824131, 37.57814793429057],
              [126.871570679074225, 37.578102506800327],
              [126.871443071774863, 37.57809245073873],
              [126.870600532088233, 37.57800846944],
              [126.869791417559483, 37.577831028419894],
              [126.869570459418938, 37.577782434442817],
              [126.868799302917765, 37.577612834518469],
              [126.868379371465352, 37.577521135675283],
              [126.868219292621063, 37.577518672839311],
              [126.868009554344738, 37.577515445485645],
              [126.867332778462313, 37.577505031283962],
              [126.865819397791284, 37.577484413389278],
              [126.864941186377237, 37.577472439820539],
              [126.864818347530189, 37.577416552868627],
              [126.864248844003384, 37.577194303586005],
              [126.863730830692319, 37.576991856649826],
              [126.863730338774886, 37.576992304668593],
              [126.863728959556511, 37.576993217503123],
              [126.86342332653922, 37.57716481186101],
              [126.863421798310071, 37.577165534606827],
              [126.863420144935134, 37.577166058382844],
              [126.863418410702536, 37.577166369006441],
              [126.863416636434053, 37.577166460388398],
              [126.863414865193278, 37.577166329155538],
              [126.863413142262729, 37.577165977354795],
              [126.863411509435423, 37.57716541783028],
              [126.863410007395018, 37.577164660716157],
              [126.863408672218583, 37.57716372513508],
              [126.863407537672316, 37.577162635605447],
              [126.863246603072355, 37.576980703624606],
              [126.863245628005359, 37.576979372493064],
              [126.863244951745415, 37.576977928503105],
              [126.86324459431583, 37.576976414125745],
              [126.863244566664847, 37.576974873585669],
              [126.863244869548836, 37.576973351052743],
              [126.863245492376691, 37.576971893339461],
              [126.863246418956464, 37.576970540017513],
              [126.863247621748812, 37.576969333301285],
              [126.863249064231624, 37.576968306343424],
              [126.863415737556906, 37.576868713935482],
              [126.863387510107998, 37.576857681627303],
              [126.863253709744882, 37.576804592084301],
              [126.862692151895814, 37.576581162253952],
              [126.862611396247971, 37.576549031499511],
              [126.862025993662527, 37.57631971379103],
              [126.861855065960668, 37.576252784722499],
              [126.861115908204113, 37.575959935771571],
              [126.860693268068459, 37.575793721705153],
              [126.860362601184448, 37.575663675979477],
              [126.859294169146196, 37.575240902725461],
              [126.8592941270621, 37.575240527539371],
              [126.859285698981154, 37.574915677403254],
              [126.85928568974488, 37.57491530149408],
              [126.85835071444302, 37.574730316399879],
              [126.857749546732464, 37.57461232996809],
              [126.857654191450422, 37.574593613970563],
              [126.857340241984531, 37.57453199580894],
              [126.855766285773925, 37.57422147556484],
              [126.854834154289748, 37.574037312820522],
              [126.854133482734341, 37.573896785851524],
              [126.853697481682858, 37.57381212341425],
              [126.853628547630521, 37.57379873783858],
              [126.853628605807856, 37.573739337112123],
              [126.853631796611168, 37.571790547866058],
              [126.853522342525039, 37.571895932672042],
              [126.853060230603916, 37.572224496006456],
              [126.852924917582342, 37.572319772404597],
              [126.852640105049574, 37.572522701657071],
              [126.852541152214314, 37.572590100232361],
              [126.852347156698926, 37.572722234389708],
              [126.851849110508397, 37.573029080745854],
              [126.851345580549577, 37.57333929919993],
              [126.851287258560831, 37.573375231531692],
              [126.850856272903755, 37.573639516697106],
              [126.850127548018847, 37.574086372063121],
              [126.849601527154007, 37.574408921979924],
              [126.849324928368077, 37.574578526145224],
              [126.848503805894808, 37.575082015085428],
              [126.848381277631006, 37.575157145095552],
              [126.847706148621356, 37.575683377313112],
              [126.847296518239062, 37.576002661585697],
              [126.846827935820144, 37.576334967968407],
              [126.846392350690152, 37.576643868005448],
              [126.846074859133239, 37.57686901921312],
              [126.844579858621643, 37.577929174965476],
              [126.844081613324221, 37.578282487918287],
              [126.844071415026605, 37.57828971948021],
              [126.843240226617283, 37.578879040836803],
              [126.842411047557604, 37.579261971571917],
              [126.842333704811793, 37.579282977233312],
              [126.84197416592778, 37.579494503468283],
              [126.841830342250915, 37.579581876030105],
              [126.841630039254923, 37.57970031894444],
              [126.839738469922253, 37.580818791472211],
              [126.839703375767499, 37.580839542280316],
              [126.839657552400993, 37.580866636167471],
              [126.839256688443712, 37.581103655139486],
              [126.839171040951456, 37.581154296012649],
              [126.839137120289109, 37.581174351978078],
              [126.839059592009235, 37.581220500886452],
              [126.838097940397049, 37.581792911904209],
              [126.837149147767988, 37.582563341839908],
              [126.836655477576343, 37.582861394760037],
              [126.836217616001335, 37.583122347588763],
              [126.835815345245109, 37.583365328062534],
              [126.834586909334007, 37.584106631783882],
              [126.834180195275565, 37.584354662396557],
              [126.832955610238301, 37.585095116142107],
              [126.832716390190171, 37.585241699688019],
              [126.831930101285934, 37.585723488528501],
              [126.831927485497744, 37.585725325070911],
              [126.831759648312371, 37.585843184824789],
              [126.831301215631541, 37.586033702855694],
              [126.830907370950968, 37.58619768211058],
              [126.830055152885052, 37.586552498006789],
              [126.828808183142826, 37.587069593736587],
              [126.828373811079771, 37.587251405812793],
              [126.827811762436639, 37.5874848371],
              [126.827658465491382, 37.587605397543228],
              [126.827449226166337, 37.587738551591656],
              [126.827196762641222, 37.587896130286417],
              [126.826958466491604, 37.588046413577146],
              [126.826746224384095, 37.588179553743693],
              [126.826688318378288, 37.588215869095201],
              [126.826653274450948, 37.588237799711322],
              [126.826562139239385, 37.588295002295602],
              [126.826349533312808, 37.588429268135776],
              [126.826146855072096, 37.588554545348636],
              [126.826126519270758, 37.588567347209626],
              [126.825917068693073, 37.588699209282026],
              [126.825702861751907, 37.588835162914265],
              [126.825509564500578, 37.588956788112895],
              [126.825332572419399, 37.589068020038567],
              [126.825218866237066, 37.5891238838582],
              [126.824756958933804, 37.589416850067451],
              [126.823651893809441, 37.590116839183253],
              [126.82266835723668, 37.590740690749371],
              [126.822539175932704, 37.590826935529641],
              [126.822239907717716, 37.591015966347349],
              [126.822140522995213, 37.591078417950044],
              [126.821887674008067, 37.591237297945952],
              [126.821720415633209, 37.591340936959426],
              [126.821527627777741, 37.591466782122893],
              [126.821239688019716, 37.591651611261412],
              [126.82099816990393, 37.591807788281493],
              [126.82068861742205, 37.592004962604413],
              [126.820466959112792, 37.592145960751409],
              [126.820243520422508, 37.592286962642987],
              [126.820053936189581, 37.592403520218454],
              [126.819302531073745, 37.59284975611456],
              [126.818844314289748, 37.593490195919458],
              [126.818682867130576, 37.593715789596757],
              [126.818365179976539, 37.594160082263109],
              [126.81830960244676, 37.594237586207754],
              [126.81752598820502, 37.595330332808174],
              [126.816556266815283, 37.59568160699525],
              [126.815885716341484, 37.596181446011933],
              [126.815150451420408, 37.596727066622513],
              [126.814519584518536, 37.597198247399447],
              [126.813953233630045, 37.59761940072714],
              [126.813243358515123, 37.597990000375773],
              [126.812381601950946, 37.598439882832388],
              [126.811368920127791, 37.598960822925285],
              [126.811014475473144, 37.599213365078327],
              [126.810939727419566, 37.599266621189393],
              [126.810930712022696, 37.599273045543235],
              [126.810539356506936, 37.599551878980279],
              [126.810076880221104, 37.599886468323561],
              [126.809364809410084, 37.60039606053045],
              [126.809336079561092, 37.600416742069292],
              [126.809021439012568, 37.600643262393959],
              [126.808442663267471, 37.601060155494586],
              [126.808239682630017, 37.601208196202776],
              [126.807237041622002, 37.601792187671066],
              [126.806618018676232, 37.602143654534864],
              [126.806449369456132, 37.602239407332057],
              [126.806182405520332, 37.602394316939758],
              [126.806135345973274, 37.602421624691054],
              [126.805863859980519, 37.602580252924625],
              [126.80562397723817, 37.602760896781213],
              [126.805253796735471, 37.603036208188243],
              [126.804843194806139, 37.60334213838054],
              [126.804421584532619, 37.603655371536185],
              [126.803884379203907, 37.604058510800222],
              [126.803367724882278, 37.60444449752891],
              [126.803210671842919, 37.604562349874499],
              [126.803032917857507, 37.604695734111829],
              [126.802877670948519, 37.60481223025014],
              [126.802581401474043, 37.605032748586652],
              [126.802009354647524, 37.605395646823958],
              [126.801988685483423, 37.605408756146396],
              [126.801448367607108, 37.605637032279809],
              [126.80046166402704, 37.606043329352872],
              [126.798562025170028, 37.607044670527074],
              [126.797167400770945, 37.607773753188297],
              [126.796911273183696, 37.607930692591772],
              [126.795607023932206, 37.608796464684971],
              [126.794595374539455, 37.609477106404725],
              [126.794202896239696, 37.609741162853197],
              [126.793218225239514, 37.610399396267134],
              [126.792698059290387, 37.610742534506151],
              [126.79258087050583, 37.610819577343619],
              [126.792338158127421, 37.610979250512997],
              [126.791657333357136, 37.611437541835969],
              [126.791258232483699, 37.611705152990105],
              [126.790200636099343, 37.612412795258393],
              [126.789519809817932, 37.612862055296077],
              [126.789064344051837, 37.613158846244716],
              [126.788809720223099, 37.613331539317102],
              [126.788315322807577, 37.613623474263868],
              [126.787929945591429, 37.613848301130872],
              [126.787407540717169, 37.614153119894766],
              [126.78677736083813, 37.6145177192113],
              [126.786626519532533, 37.614603885013807],
              [126.786185446543726, 37.614861077789826],
              [126.786150618014076, 37.61488138770607],
              [126.78558150344854, 37.615213236838677],
              [126.783737149447077, 37.616287355814769],
              [126.782635177059632, 37.616932617596191],
              [126.782527261784679, 37.616994567912855],
              [126.782227437491585, 37.617166681482345],
              [126.781579481118186, 37.617542760853141],
              [126.781358598973895, 37.617665945453972],
              [126.780689870046416, 37.618061973196156],
              [126.779918113664579, 37.618519747675919],
              [126.779687798622916, 37.618659812942411],
              [126.778322187967987, 37.619461178603579],
              [126.777693435892587, 37.61983013051654],
              [126.776204824610076, 37.620708003741406],
              [126.774762197186789, 37.621552639933796],
              [126.77347169134265, 37.622308179231531],
              [126.773258041091736, 37.622435876192846],
              [126.773192655719953, 37.62247473535988],
              [126.772305155104263, 37.62300221402009],
              [126.771166418906901, 37.623680514788461],
              [126.770429685510592, 37.624109290926356],
              [126.769952226821886, 37.624371890839704],
              [126.769344597098211, 37.624715320641251],
              [126.768562135757662, 37.625157510083582],
              [126.766665990529432, 37.626121316744197],
              [126.765768073200661, 37.626577705686216],
              [126.765219492803638, 37.626856529885046],
              [126.763699351556355, 37.627625386203881],
              [126.763554418006365, 37.627698687996727],
              [126.762888573439128, 37.628016911687524],
              [126.761914580028943, 37.628494987248075],
              [126.759960322869958, 37.629465751856671],
              [126.759520427671546, 37.629693473959804],
              [126.759519247381235, 37.629694099284215],
              [126.759541582216229, 37.629730992885861],
              [126.760496662861954, 37.631308495922426],
              [126.762631008058662, 37.634916741618433],
              [126.762871115817163, 37.635326369126787],
              [126.764017713881557, 37.635332286271897],
              [126.764139003773323, 37.635300601381445],
              [126.765596470126994, 37.634922708013356],
              [126.766279487238691, 37.634494856067967],
              [126.766907241553497, 37.634161536743548],
              [126.767429315495647, 37.633965131978606],
              [126.767587569660023, 37.633905594885306],
              [126.768540795100023, 37.633718015172448],
              [126.769086295838818, 37.633275623691368],
              [126.769716888044684, 37.633106388094063],
              [126.770270318001224, 37.632957153648249],
              [126.770734310625031, 37.632845168187039],
              [126.771183328108009, 37.632736794646895],
              [126.77120789246564, 37.632731060716516],
              [126.771337071994296, 37.632700903404142],
              [126.772663751081325, 37.632262592486903],
              [126.773323662963278, 37.632101721238122],
              [126.773492820445682, 37.632058683541452],
              [126.77355433557733, 37.63203999923266],
              [126.773956572745533, 37.631927001568229],
              [126.774055329169855, 37.631899173255881],
              [126.774211008470033, 37.631855304392509],
              [126.774286125975621, 37.631839704797414],
              [126.774543718367227, 37.63178620970664],
              [126.77534216033996, 37.631527495125709],
              [126.775736675450545, 37.631399771210418],
              [126.775873338999389, 37.631331154619318],
              [126.776291791988541, 37.63132090433357],
              [126.776296124539058, 37.631320798413796],
              [126.776557182586615, 37.631317009483396],
              [126.777020280193483, 37.631304284905049],
              [126.777020763087151, 37.631304274344188],
              [126.777032190372026, 37.631298766948611],
              [126.778758604411621, 37.631250950536931],
              [126.779447313569918, 37.631231962028053],
              [126.779776855937527, 37.631131311945524],
              [126.779872323060374, 37.631124102690485],
              [126.7801658909612, 37.631079489246417],
              [126.780320146238381, 37.63106110759108],
              [126.780362546769851, 37.631056051984757],
              [126.780528466946123, 37.631028047418461],
              [126.781722578724384, 37.630850892338849],
              [126.781816245526187, 37.630819669072665],
              [126.782170736361294, 37.630702369681387],
              [126.782180838485502, 37.63069861491806],
              [126.782204848907028, 37.630690577832944],
              [126.782552803760026, 37.630579305266039],
              [126.782697830261128, 37.630533668588626],
              [126.782976056177034, 37.630441392649431],
              [126.783244764389707, 37.630342700258808],
              [126.783621399526467, 37.630193915999904],
              [126.783871221891943, 37.630091799623564],
              [126.784712836915574, 37.629722227948712],
              [126.785126890480242, 37.629531450146338],
              [126.785473275411846, 37.629373621979759],
              [126.785547156165535, 37.62933482551913],
              [126.785720343051977, 37.629241321997817],
              [126.785886238157687, 37.629150997238042],
              [126.786036403298738, 37.629069282993953],
              [126.78628573299271, 37.628934636722995],
              [126.786355242521438, 37.628899680009219],
              [126.786397069590464, 37.628878456125541],
              [126.786594498908116, 37.628765941978457],
              [126.786687372977795, 37.628719945350539],
              [126.786708728157848, 37.628697441181927],
              [126.786745036553995, 37.628676511893893],
              [126.787051646874943, 37.628499778373225],
              [126.787460706932691, 37.628264015534683],
              [126.787598582173558, 37.628183200293165],
              [126.787857293028111, 37.628031557756209],
              [126.788344850546977, 37.627758186215182],
              [126.788618162090771, 37.627461670162248],
              [126.788717355582421, 37.627340532840968],
              [126.789738098801621, 37.626588719685103],
              [126.789748957379473, 37.626598143553913],
              [126.789840155680494, 37.626677283919911],
              [126.789923146615791, 37.626609017366775],
              [126.789840151947573, 37.626533200970592],
              [126.789828245344637, 37.626522324168242],
              [126.789859362344089, 37.626499406774315],
              [126.79027882672743, 37.626193851692641],
              [126.790428307751526, 37.626082714569236],
              [126.790868296498147, 37.625685251413543],
              [126.790940051166984, 37.625623005076228],
              [126.791088900394854, 37.62571550071911],
              [126.79112283593787, 37.625736635283367],
              [126.791236908029973, 37.625807873946009],
              [126.791280795580747, 37.625843819407073],
              [126.791542036699795, 37.626022471620942],
              [126.791632239934955, 37.626083002096642],
              [126.791655961590564, 37.626098920482676],
              [126.791813527046941, 37.626209232745659],
              [126.791924857801774, 37.626430539193024],
              [126.79194261631261, 37.626465831868693],
              [126.792034498543501, 37.626648486048651],
              [126.792044315413634, 37.62666800465756],
              [126.792137004426877, 37.626802206314011],
              [126.792173169508445, 37.62685456609308],
              [126.792305523255138, 37.627046195820945],
              [126.792309304002728, 37.627051669738677],
              [126.792431916290397, 37.627068247762899],
              [126.792567768425755, 37.627086615766629],
              [126.792693691570847, 37.627019549256055],
              [126.79269497849225, 37.627018879170848],
              [126.792784934260425, 37.627099293314636],
              [126.793006212237501, 37.627297102957108],
              [126.793092994370639, 37.627374651449813],
              [126.793162194073176, 37.627436779157108],
              [126.793283329138845, 37.627545543545075],
              [126.793437885875548, 37.627684314020271],
              [126.793523631637967, 37.627761298967037],
              [126.793616516916572, 37.627844532248375],
              [126.793662190054405, 37.627885440316611],
              [126.793716048470827, 37.627933688218924],
              [126.793756410965955, 37.627969835184857],
              [126.79378098896764, 37.627991655667536],
              [126.793872349282452, 37.628072749777409],
              [126.793942478821563, 37.628136392316819],
              [126.794040559759225, 37.628225394217658],
              [126.79391839633459, 37.628333148125975],
              [126.793913959963362, 37.628337061288036],
              [126.793859709023437, 37.628512919477934],
              [126.793860287863382, 37.628513905391259],
              [126.793859688373146, 37.628515820733696],
              [126.793965971056153, 37.628703310467557],
              [126.794398966990443, 37.62892219001391],
              [126.7943999236862, 37.628923726192511],
              [126.794469186832416, 37.62903491286383],
              [126.794513833787249, 37.629106577734809],
              [126.794571538012406, 37.629241959915518],
              [126.794602041151904, 37.629308417932187],
              [126.794550883651496, 37.629337185348909],
              [126.794537907696366, 37.629400232362443],
              [126.794532212299842, 37.629426795635005],
              [126.79446403216194, 37.629744769898657],
              [126.794428601918497, 37.629902180681242],
              [126.794413950942186, 37.629966645363012],
              [126.794413551354012, 37.629968394256011],
              [126.794323359958327, 37.630366954401318],
              [126.794297238611364, 37.63048044134878],
              [126.794269780861015, 37.630601015557041],
              [126.794251738110958, 37.630682685222403],
              [126.794202810958339, 37.630909537388789],
              [126.794187314816512, 37.630979920508587],
              [126.794164250169786, 37.631088791065139],
              [126.794117258663562, 37.631308401763867],
              [126.79409628544208, 37.631400308281641],
              [126.794088326077656, 37.63143925575725],
              [126.794082495573605, 37.6314706024435],
              [126.794082448946952, 37.631470859043283],
              [126.794077276354727, 37.631474227907752],
              [126.794037284564141, 37.631501828872217],
              [126.794036548738987, 37.631502340029556],
              [126.794064635916783, 37.631550003615843],
              [126.794094815566282, 37.631603143570629],
              [126.794095040445498, 37.631604650135422],
              [126.794095263313991, 37.631606129648326],
              [126.794097254377874, 37.631605429511019],
              [126.79410341026913, 37.6316499108719],
              [126.794114681269221, 37.631731369209554],
              [126.794115025034003, 37.631733846315498],
              [126.794172203937705, 37.631723956509333],
              [126.794200667430644, 37.631719027967563],
              [126.794201616435458, 37.631718864194816],
              [126.794201485951049, 37.631724991549675],
              [126.794199843515528, 37.631770433038412],
              [126.794196822467143, 37.631854271003583],
              [126.794210946095404, 37.631908895806603],
              [126.794206759922233, 37.631910778877916],
              [126.794214139164296, 37.631921425352928],
              [126.79421949120254, 37.631964752702764],
              [126.794224564574549, 37.632005909787786],
              [126.79428647435833, 37.632553739147951],
              [126.794291794020438, 37.63260073831016],
              [126.794295064113882, 37.632626339384082],
              [126.794309949614998, 37.632761132908769],
              [126.794311430749715, 37.632774569764052],
              [126.794318248861785, 37.632840368788386],
              [126.794521851200415, 37.634805569777278],
              [126.7945301263414, 37.634885481468942],
              [126.794535035089041, 37.634932818848263],
              [126.794533019467295, 37.634933230420259],
              [126.79449062320279, 37.634941894648179],
              [126.794457785052941, 37.634976884336595],
              [126.794419059214874, 37.635023838190243],
              [126.794377644757745, 37.63507405047671],
              [126.794324634784616, 37.635233069359124],
              [126.794315282606448, 37.635418293190199],
              [126.794311623652121, 37.635511809360843],
              [126.79433079560043, 37.63558446113862],
              [126.794415109641406, 37.635820843940934],
              [126.794442342226745, 37.635875894093168],
              [126.794480251253091, 37.635952528199162],
              [126.794498077498815, 37.635988568955796],
              [126.794533632285862, 37.636060440768134],
              [126.794509857104416, 37.636144003156467],
              [126.794460866871447, 37.636316193781973],
              [126.794436983319812, 37.63655346621703],
              [126.794542987440295, 37.6368128642331],
              [126.79457632793634, 37.636895890240119],
              [126.794608698427851, 37.636976506595062],
              [126.794629421383306, 37.637143402341678],
              [126.794615152467827, 37.637191049565203],
              [126.794530847299669, 37.63747253679486],
              [126.79456721759891, 37.637635765762532],
              [126.794634347284486, 37.637736343439819],
              [126.794699870730611, 37.637835161997771],
              [126.794704865881769, 37.63784269451444],
              [126.794736171461437, 37.63787067771657],
              [126.794796023530182, 37.637921533297487],
              [126.794828401325674, 37.637949043377617],
              [126.794885049828082, 37.637998769328831],
              [126.794886415649657, 37.63799996273827],
              [126.794888827891128, 37.638002086262958],
              [126.79489598402526, 37.638063636557732],
              [126.794902360165068, 37.638118493434682],
              [126.794905677901596, 37.638147133131326],
              [126.79491662658495, 37.638240574343385],
              [126.79491702239666, 37.638244026091137],
              [126.794973970619125, 37.638733671231265],
              [126.794979537165247, 37.638781497409219],
              [126.794987189275645, 37.638856785089473],
              [126.794912852327897, 37.638894909560783],
              [126.794844434099502, 37.638893218951722],
              [126.794629264262426, 37.63888790012917],
              [126.794634757210133, 37.638936894903487],
              [126.794660997169245, 37.639198494547003],
              [126.794727805512153, 37.639233229133708],
              [126.79480383301977, 37.639289814699424],
              [126.794864990753794, 37.639333484545361],
              [126.794887511379955, 37.639363904663284],
              [126.794921414962758, 37.639411732551437],
              [126.794984921521916, 37.639501320997681],
              [126.795001684622562, 37.639523199053642],
              [126.795064012151826, 37.639611874576374],
              [126.795065413957644, 37.639625696702197],
              [126.795124538516461, 37.640075821533863],
              [126.795156032867254, 37.640162037826109],
              [126.795157506737837, 37.640231457878272],
              [126.795168003889032, 37.640327044203154],
              [126.795224348202396, 37.640429457975991],
              [126.795405349482991, 37.640540925628287],
              [126.795520367357042, 37.640561749968391],
              [126.79588276091728, 37.640727506195375],
              [126.795903579923959, 37.640737029493906],
              [126.796120237511815, 37.640839439595659],
              [126.796715772450128, 37.641120939989946],
              [126.796818098642277, 37.641205780816257],
              [126.79682023552111, 37.641207552944572],
              [126.796852455211081, 37.64123426835225],
              [126.79685512067023, 37.641236480774246],
              [126.796866506931252, 37.641245917036962],
              [126.796974785990017, 37.641408020759293],
              [126.796975281080805, 37.64140876730491],
              [126.79699776257992, 37.641442413535117],
              [126.796999240132152, 37.641444630592439],
              [126.79702782192237, 37.641487424688719],
              [126.79718179885549, 37.641802786443463],
              [126.797202102105302, 37.641844364859054],
              [126.797226905733453, 37.641848355878416],
              [126.797231486832786, 37.641857663236642],
              [126.797304998667784, 37.642007196923998],
              [126.79733360413249, 37.642065568728491],
              [126.79727590003921, 37.642110676263741],
              [126.797197468977373, 37.642162170583774],
              [126.797186648510234, 37.642169297814689],
              [126.797057513202162, 37.642254368160913],
              [126.797013566102876, 37.642269380189866],
              [126.797013990243556, 37.642270319196527],
              [126.797173115334417, 37.642622066647164],
              [126.797180390454969, 37.642638144603801],
              [126.797266616250596, 37.642646651231715],
              [126.797386227760484, 37.642638914622331],
              [126.797434790982052, 37.642635772487978],
              [126.797565039450191, 37.642584182763855],
              [126.797611116858974, 37.642689145392268],
              [126.797615834124272, 37.642699892063966],
              [126.7976502814372, 37.642778076395977],
              [126.797726546004768, 37.642951204918404],
              [126.797765307101116, 37.643039197000107],
              [126.797739775208598, 37.64306050472198],
              [126.797628429757779, 37.643208832175574],
              [126.797597948563833, 37.64324943846303],
              [126.797597558104869, 37.643346518107982],
              [126.797638614302912, 37.64343526207778],
              [126.797721316957364, 37.643605708711171],
              [126.797779492305054, 37.643723997295062],
              [126.797878370245428, 37.643863063942973],
              [126.798079143311654, 37.643941259087534],
              [126.798144353013015, 37.643966655289056],
              [126.798512099944901, 37.644047901601098],
              [126.798923358338911, 37.644092268868228],
              [126.799086175947721, 37.644109836990424],
              [126.799297889421624, 37.644014867165602],
              [126.799528583219342, 37.644050347900837],
              [126.799928726199227, 37.64411401068346],
              [126.800014271696014, 37.644127102633455],
              [126.800055183536898, 37.644137360927409],
              [126.800138228987862, 37.644163696479779],
              [126.800157189355829, 37.644170567112724],
              [126.800428343330765, 37.64421380948621],
              [126.800525652542049, 37.644229325992832],
              [126.8005373872017, 37.644231200928061],
              [126.800814584984295, 37.64430593998226],
              [126.801136998983722, 37.644458853388919],
              [126.801351511865576, 37.644567549576685],
              [126.801427872023311, 37.644602921898809],
              [126.801586026885346, 37.644676182627087],
              [126.801676799830133, 37.644718227014792],
              [126.801982660196899, 37.644859859158245],
              [126.802035691964591, 37.644884449279459],
              [126.802087573917291, 37.64490848276489],
              [126.802127325953307, 37.644839121940116],
              [126.802173654524864, 37.644758379283871],
              [126.802480522907501, 37.644223354465609],
              [126.802852317169169, 37.643575184861959],
              [126.80286098497983, 37.64355384667877],
              [126.802886394228878, 37.643545646028478],
              [126.803094030282793, 37.643478646805448],
              [126.803123790473549, 37.643469045004736],
              [126.803300252780588, 37.643412102149092],
              [126.803302095249165, 37.643411559614862],
              [126.803357834502606, 37.643395111463917],
              [126.803386862718952, 37.643386687815834],
              [126.803443812124058, 37.643368460338486],
              [126.803447713167216, 37.643367295421974],
              [126.803454608297329, 37.643365225223882],
              [126.803645169549938, 37.643303439337473],
              [126.803842949997517, 37.643292878535149],
              [126.803900452432373, 37.643306346583245],
              [126.80398665019905, 37.643329080102667],
              [126.80409180408796, 37.643309469242659],
              [126.804188364332788, 37.64331786593349],
              [126.804225986494316, 37.643344938378277],
              [126.804327316179354, 37.643423504404318],
              [126.804394176562582, 37.643459989618698],
              [126.804531105940342, 37.643534718411715],
              [126.804743418175647, 37.643595807185939],
              [126.804983012570986, 37.643626775307119],
              [126.805109143290707, 37.643643536718912],
              [126.805458842677737, 37.643690006256158],
              [126.805705468786982, 37.643774559483781],
              [126.805747293149764, 37.643788896079371],
              [126.80577422993386, 37.643798132769241],
              [126.805779331596284, 37.643803395899319],
              [126.805806514046907, 37.643831423104338],
              [126.805815555991387, 37.64384043911295],
              [126.805919763628324, 37.643948495926303],
              [126.805954367195042, 37.643970438631747],
              [126.805994058691269, 37.643995836303738],
              [126.806003819176325, 37.644002918670395],
              [126.806093331850931, 37.644059624969991],
              [126.806171006891319, 37.644108832838633],
              [126.80629530736168, 37.644237925501962],
              [126.806441618282165, 37.644399259936705],
              [126.80649712290176, 37.644470598236474],
              [126.806597231301723, 37.644599265343864],
              [126.806637767439, 37.644653116366399],
              [126.806655666501555, 37.644741804040827],
              [126.806658237366264, 37.6447546035809],
              [126.806984419880479, 37.645027414185478],
              [126.80707272975792, 37.645098377985434],
              [126.807322768275313, 37.645284031765406],
              [126.807403632359524, 37.645342817025345],
              [126.80746677075453, 37.645473354707789],
              [126.807530197447335, 37.645604485317634],
              [126.807568750091818, 37.645659423091388],
              [126.80758565218342, 37.645683846423104],
              [126.807598836280633, 37.645702898551683],
              [126.807600863049259, 37.645705828044477],
              [126.807738689416766, 37.645908324253327],
              [126.807880906205384, 37.64605602822197],
              [126.807961601524113, 37.646140347614477],
              [126.808164139321491, 37.646237480984851],
              [126.808251568113789, 37.646272698394426],
              [126.808354378937139, 37.646314908289725],
              [126.808389829655539, 37.646320011239091],
              [126.808460623947695, 37.646330038015066],
              [126.808536318626224, 37.64633797985897],
              [126.808594897429316, 37.646351969295573],
              [126.808670236775924, 37.646365231309652],
              [126.808739161443995, 37.646378613420133],
              [126.808744285138303, 37.646379706108291],
              [126.808864512546506, 37.646405361082437],
              [126.80896686525108, 37.646429294248563],
              [126.809282336193391, 37.646523818970358],
              [126.809367688610962, 37.646560690852844],
              [126.809652822946234, 37.646679398058474],
              [126.809752364216351, 37.646720572709789],
              [126.809920133730614, 37.64678578642711],
              [126.810024747102702, 37.646814552200524],
              [126.810165641046254, 37.64685801978154],
              [126.810301458083842, 37.646900494895085],
              [126.810432910871725, 37.646944343202229],
              [126.810540937013613, 37.646990115785741],
              [126.810615196680956, 37.647023779007057],
              [126.810619783453618, 37.647139383026946],
              [126.810760216599263, 37.647211965489873],
              [126.810793474761127, 37.647229182268575],
              [126.81097682683172, 37.647325712912171],
              [126.811133103124149, 37.647454157099496],
              [126.811153016257066, 37.647479453762791],
              [126.811254963891116, 37.647608989309639],
              [126.811287679184403, 37.647652250239297],
              [126.81141073942517, 37.647766863048737],
              [126.811488222755798, 37.64783763415825],
              [126.811547885799655, 37.647893627610408],
              [126.811617311581443, 37.64793889722543],
              [126.811719652904756, 37.647976180205561],
              [126.811872934125518, 37.648031827357336],
              [126.811887001649978, 37.648025692059186],
              [126.812319647080201, 37.64783704248368],
              [126.812630712733466, 37.64764698442459],
              [126.81268201327741, 37.64752840815337],
              [126.812686106420813, 37.647518952740356],
              [126.812720124830733, 37.647334039904777],
              [126.812865978662401, 37.64701217374455],
              [126.813068667735692, 37.646529035713129],
              [126.813093063964075, 37.64647069061941],
              [126.813146556863572, 37.646375814674272],
              [126.813235361420539, 37.646208463917056],
              [126.813330422257366, 37.646030942868009],
              [126.813352825178441, 37.645988992980186],
              [126.813271277255879, 37.645781053384496],
              [126.813232977671277, 37.645683394193298],
              [126.813224167321749, 37.645660922374972],
              [126.813231874875683, 37.645570118813616],
              [126.813247252828077, 37.645388354637689],
              [126.813247537611204, 37.645385001553443],
              [126.813149633977986, 37.645296818907234],
              [126.813124864559427, 37.645274246049851],
              [126.812775319909079, 37.644955833445088],
              [126.812788393631081, 37.644938644668848],
              [126.812793283371235, 37.644932153848501],
              [126.812854453404242, 37.644849645683969],
              [126.813041592379903, 37.644597671534967],
              [126.813641877675181, 37.644446950125975],
              [126.813884077770894, 37.644669909020351],
              [126.813996789094446, 37.64477505003341],
              [126.81404256474211, 37.64481774351966],
              [126.814094871557586, 37.644834206660519],
              [126.814685815024973, 37.645020205017467],
              [126.81508086913648, 37.64497297854242],
              [126.815147249540288, 37.644958517243893],
              [126.815183817584156, 37.644950550876018],
              [126.815557571865881, 37.64487518948561],
              [126.815612417211597, 37.644863464129749],
              [126.81578499437218, 37.644914290593292],
              [126.816003530669903, 37.644973376262648],
              [126.81603336618717, 37.645006128647879],
              [126.816275069856559, 37.645271485448077],
              [126.816287055183864, 37.645284658417317],
              [126.816346597738075, 37.645583877528438],
              [126.816483346382952, 37.645997096845754],
              [126.816500542632298, 37.646057039242265],
              [126.816454797567545, 37.646117639961446],
              [126.816377981422505, 37.6462648819168],
              [126.816350826557255, 37.646339794344961],
              [126.816331891400012, 37.646392029223875],
              [126.816230373399719, 37.646550050206486],
              [126.81622746628463, 37.646554577850928],
              [126.81617670759708, 37.646643426750899],
              [126.816163042470421, 37.646670524372766],
              [126.816102836423696, 37.646778185066296],
              [126.816066496156068, 37.646901652773927],
              [126.816329370121849, 37.647223082955954],
              [126.816293671648296, 37.647453136439232],
              [126.816111716527104, 37.647580518225354],
              [126.815987765309075, 37.64766735725226],
              [126.815519855811814, 37.648094133931018],
              [126.815627276907392, 37.648185118858116],
              [126.815691502171376, 37.648239550355356],
              [126.815666854911584, 37.648264380458784],
              [126.815483295385192, 37.648441491694605],
              [126.815494717430951, 37.648586476102345],
              [126.815673130564861, 37.648905782544269],
              [126.81574926480333, 37.648910912881846],
              [126.816471720943014, 37.648991694195487],
              [126.816858341650416, 37.649246330659061],
              [126.816922796471474, 37.649291985686581],
              [126.817088958442653, 37.649408296899246],
              [126.817791149214642, 37.649232398090099],
              [126.817802401186938, 37.64940118534718],
              [126.817828214159093, 37.649743353216365],
              [126.817620697041406, 37.649986789467796],
              [126.817519824832388, 37.650032868538183],
              [126.817395082911105, 37.650090147198043],
              [126.817045497201534, 37.650248236481829],
              [126.817061870836994, 37.650409174023714],
              [126.817023164827248, 37.650463408126392],
              [126.816929048688152, 37.650595292490642],
              [126.816928600825449, 37.650595920826341],
              [126.816607379448229, 37.650629293243973],
              [126.816544052609856, 37.650804073157474],
              [126.816374121925335, 37.65087777517023],
              [126.816327363487261, 37.651227372327853],
              [126.816232521673399, 37.65174526322847],
              [126.816181507037214, 37.651858166972545],
              [126.816049980324223, 37.652148974708545],
              [126.815953868039401, 37.652688353670335],
              [126.815947014821617, 37.652724354384127],
              [126.815567259561732, 37.653032538809114],
              [126.815619829986048, 37.65321894165492],
              [126.81574926773898, 37.653662696426096],
              [126.81575036392519, 37.653951909666674],
              [126.815730066686825, 37.654273246458324],
              [126.815724404374336, 37.654362894705415],
              [126.815738796419481, 37.654408956662081],
              [126.815832229536497, 37.65470588452127],
              [126.815925807855976, 37.655170933958381],
              [126.815993291402151, 37.655224667593338],
              [126.816113892239784, 37.655320699251142],
              [126.81676588318966, 37.655671571958656],
              [126.816799121416977, 37.655693752331935],
              [126.816975523962483, 37.655811462318049],
              [126.816986496333456, 37.655958427633962],
              [126.816845668216033, 37.656127319255134],
              [126.816892511057432, 37.656248483635771],
              [126.816855880991923, 37.656258424943779],
              [126.816830453896529, 37.656321095621763],
              [126.816640327745262, 37.656398371866231],
              [126.816414353981486, 37.657016218687424],
              [126.816361417198962, 37.656908101565769],
              [126.816300509503975, 37.656911375489209],
              [126.816147609954797, 37.656966400231333],
              [126.816105227957578, 37.657028148633941],
              [126.816130552463946, 37.657093681264186],
              [126.816058115049458, 37.657106073631688],
              [126.816019116345544, 37.657112408256303],
              [126.815927371892712, 37.6570931638053],
              [126.815856257244732, 37.657070617501645],
              [126.815843574440265, 37.657066542955043],
              [126.815716478792723, 37.657039151712695],
              [126.815628625361668, 37.657054492756252],
              [126.81545687145892, 37.657039383178407],
              [126.815456702668982, 37.657013314945075],
              [126.815456692814237, 37.657011424806903],
              [126.815456567353849, 37.656975204287001],
              [126.815380378436089, 37.656929453351019],
              [126.815379124684185, 37.656928698874935],
              [126.815366609549514, 37.656921185789621],
              [126.815206825139313, 37.65696427004103],
              [126.815213494809299, 37.656976604652385],
              [126.815213976348815, 37.656977499760785],
              [126.815236492079663, 37.657019185211134],
              [126.815193179844286, 37.657072273348568],
              [126.815171789438267, 37.657106297707259],
              [126.815064943595559, 37.657275935141676],
              [126.815027891723872, 37.657322575426583],
              [126.814849633324656, 37.657365808786032],
              [126.814595312721679, 37.657427429533925],
              [126.814573502911557, 37.657399746561936],
              [126.814368111838547, 37.657138992620943],
              [126.814362687743909, 37.657132136251967],
              [126.814322066615162, 37.657198744276627],
              [126.814051838649974, 37.657121290511597],
              [126.814008274024729, 37.657108806171394],
              [126.813855485995418, 37.65706500809172],
              [126.813813479586344, 37.657052968985461],
              [126.813665412732632, 37.656944616307953],
              [126.813648791742679, 37.656934120097596],
              [126.813489354087821, 37.65683342630193],
              [126.813296980187602, 37.656763545270458],
              [126.813255468865336, 37.656741971015343],
              [126.813175931603354, 37.656700634591736],
              [126.813108822627512, 37.656665758185355],
              [126.813078993090798, 37.656666945481263],
              [126.813055899106033, 37.656667861037661],
              [126.812763688656688, 37.6566794444973],
              [126.812754644959995, 37.65667860134446],
              [126.812402175409915, 37.656645619163321],
              [126.812254515602575, 37.656625939550096],
              [126.812200718308119, 37.656724850158255],
              [126.811997981345598, 37.657139056556815],
              [126.811983691156641, 37.657164711471111],
              [126.811882268604521, 37.657373149961693],
              [126.811867168021351, 37.657405037308415],
              [126.811808974409232, 37.657526404747003],
              [126.81139559291104, 37.657587039999171],
              [126.811257157801165, 37.657611773540417],
              [126.81112261760633, 37.657928086940856],
              [126.811046250887131, 37.658107626675971],
              [126.811012899991027, 37.658186037399318],
              [126.810887050453218, 37.658207559579694],
              [126.810757076988622, 37.658202033979869],
              [126.810737673127548, 37.658168575150022],
              [126.810692014884125, 37.658207424078554],
              [126.810697193213542, 37.65835438125054],
              [126.810693654242996, 37.658408523035085],
              [126.810711980060105, 37.65846441227233],
              [126.810765793004364, 37.658517208213226],
              [126.810770203260006, 37.658520636487424],
              [126.810770301553518, 37.658534530997493],
              [126.810770314837526, 37.658535792029767],
              [126.810770954801612, 37.658624520468074],
              [126.810783802239186, 37.658697611028693],
              [126.810805541264841, 37.658749272244684],
              [126.810829681970532, 37.658781757363649],
              [126.810836376136649, 37.658790767369261],
              [126.810909119541336, 37.658885124008471],
              [126.811002022190593, 37.659028170796198],
              [126.811094390185744, 37.65915869046659],
              [126.811113586882669, 37.659231790127272],
              [126.811075986377162, 37.659354847532448],
              [126.811109254442627, 37.659361766622027],
              [126.811133389467827, 37.659402140946554],
              [126.811207523580535, 37.659463022173732],
              [126.811438362278423, 37.659619704369142],
              [126.811568912500775, 37.659680171992129],
              [126.811569779122735, 37.659680369933739],
              [126.812010140881384, 37.659773383650027],
              [126.812133181281482, 37.659753066101906],
              [126.812448900677012, 37.659700928244995],
              [126.812698199338627, 37.659761780287617],
              [126.812876379645232, 37.659805270814729],
              [126.812977632890622, 37.659839301586288],
              [126.812994558165045, 37.659842673780261],
              [126.813169125149059, 37.659877473870992],
              [126.813233396793493, 37.659893909719273],
              [126.813254580166628, 37.659906420872453],
              [126.813457706673177, 37.660026405250029],
              [126.814072874870462, 37.660388170966741],
              [126.814083615399994, 37.660394530085135],
              [126.814158544033091, 37.660431036829415],
              [126.814203972912068, 37.660453185150942],
              [126.814260479720346, 37.660480762548175],
              [126.814321451504227, 37.660510538068444],
              [126.814384283364703, 37.660960360098841],
              [126.814434480183806, 37.66141867302273],
              [126.814500172656807, 37.661563731975349],
              [126.81453917307752, 37.661649829700863],
              [126.814552255246923, 37.661665047991697],
              [126.814625432664485, 37.661750129305702],
              [126.814631217727836, 37.661756854359972],
              [126.81470948495344, 37.66184784544253],
              [126.814721059484455, 37.662004624330166],
              [126.814725219814051, 37.662060934467185],
              [126.814742225673712, 37.662291049691405],
              [126.814744360898644, 37.662322343492988],
              [126.814744402078887, 37.662322920580451],
              [126.814760288937507, 37.662386166938298],
              [126.814770517871438, 37.662426879739463],
              [126.814811271706077, 37.662589100867478],
              [126.814847467939956, 37.662635486022197],
              [126.814908309342925, 37.662724814645145],
              [126.814930695113915, 37.662756132321881],
              [126.814946977962848, 37.662778907841393],
              [126.815039499324726, 37.662908298620316],
              [126.815437582716797, 37.663183020808198],
              [126.815524932387405, 37.663250867544072],
              [126.815771327386784, 37.663208296231176],
              [126.815814822181537, 37.663213120055801],
              [126.816296040191204, 37.663266500902786],
              [126.816340739058148, 37.663271458599972],
              [126.816551306230707, 37.663367435209793],
              [126.817251741240483, 37.663726667921942],
              [126.817292799921034, 37.664039973680914],
              [126.817300352941913, 37.664097585037666],
              [126.817342386095902, 37.664103236270414],
              [126.817412328326597, 37.6642808361728],
              [126.817972463895799, 37.664225758855387],
              [126.818012540370077, 37.664223213601375],
              [126.818048639361763, 37.664220922044045],
              [126.818143910830599, 37.664252989473972],
              [126.818157011412538, 37.664257400580432],
              [126.818452382271914, 37.664146014802547],
              [126.818469361863492, 37.664139612556369],
              [126.818740673265751, 37.664037298573966],
              [126.818823322535593, 37.664006038446679],
              [126.819406349377843, 37.663785514844029],
              [126.819814193539855, 37.663765339737957],
              [126.820545633030136, 37.663729154451282],
              [126.821680968632123, 37.663447737226875],
              [126.821735243813322, 37.663434284300124],
              [126.822373947248863, 37.663316326893714],
              [126.822654155765406, 37.663191741758688],
              [126.822727093036619, 37.663159310872878],
              [126.822738609428512, 37.663173494461589],
              [126.822761198168607, 37.663201319192069],
              [126.822772392689089, 37.663235457178835],
              [126.822782237778199, 37.663265500806538],
              [126.822804878990368, 37.663304277836552],
              [126.822810001016933, 37.663314690514049],
              [126.822829816883981, 37.663350714893575],
              [126.822846393813236, 37.663384707113764],
              [126.822858576284176, 37.663409321949679],
              [126.822842488981394, 37.663448859075046],
              [126.82280563073887, 37.663539396525842],
              [126.822607944507055, 37.663654718043389],
              [126.822380720665748, 37.663787265118494],
              [126.822060044656666, 37.663974325809626],
              [126.821966813853194, 37.664089584991473],
              [126.821961147956031, 37.664096589969887],
              [126.821941231973739, 37.66412120244194],
              [126.821945975212159, 37.664132615251624],
              [126.822030859653125, 37.664336896465812],
              [126.821938288818615, 37.664613246075966],
              [126.821855668126716, 37.664720318120168],
              [126.821883902461948, 37.664954631237642],
              [126.821889834276959, 37.665028700197837],
              [126.821920275198721, 37.665273738231662],
              [126.821811932893723, 37.665461912315557],
              [126.821732214734084, 37.665568619682169],
              [126.821428166888566, 37.665771172420861],
              [126.821257412552711, 37.665878037081868],
              [126.82104987374646, 37.666015504255256],
              [126.820867857041875, 37.666321332302935],
              [126.820738139185593, 37.666540789840639],
              [126.82060803746127, 37.666684728404654],
              [126.820564852708429, 37.666734319311317],
              [126.820548036203832, 37.666753631569975],
              [126.820532824310334, 37.666768069691493],
              [126.820493893346281, 37.666805012447682],
              [126.820487328694597, 37.666833824235958],
              [126.820431054164814, 37.667080680257385],
              [126.82041026319402, 37.667171885405203],
              [126.820383647830994, 37.667292898951786],
              [126.820382852925974, 37.667296512289425],
              [126.820373051358217, 37.667341076799261],
              [126.820365222790798, 37.667376679086793],
              [126.820636997580976, 37.667471834763575],
              [126.820737799728633, 37.66750712715406],
              [126.820945985098263, 37.667685999578481],
              [126.820954909103833, 37.667709718844549],
              [126.820967528373572, 37.667743272107153],
              [126.821066846564619, 37.668007237995333],
              [126.821015555899507, 37.668293975012425],
              [126.820987173133375, 37.6684345534701],
              [126.820973196393325, 37.668509529917763],
              [126.820928171363434, 37.668624856571306],
              [126.820898869323443, 37.668695809587405],
              [126.820868747510062, 37.668773597126268],
              [126.820804489191772, 37.66895262135391],
              [126.820800914254932, 37.668962924539464],
              [126.820798701529341, 37.668969325623152],
              [126.820796454032802, 37.668975809428431],
              [126.820808928359682, 37.668991315545533],
              [126.820792394156143, 37.669092570677371],
              [126.820738941254291, 37.66942026927692],
              [126.820732786352451, 37.669480741548355],
              [126.820732588775428, 37.669497193188391],
              [126.82079009326236, 37.669570216177569],
              [126.820845903077796, 37.669641080622284],
              [126.820877791204055, 37.66968157507528],
              [126.820924576131105, 37.669781470554035],
              [126.820968451516279, 37.669875151755036],
              [126.82086233949218, 37.670304573504048],
              [126.820691256909029, 37.670555050553268],
              [126.820682976224234, 37.670604402644976],
              [126.82066385490289, 37.670964140841072],
              [126.820957660913308, 37.67156869070751],
              [126.820997920295511, 37.671598264199105],
              [126.821651257324319, 37.672078169844255],
              [126.821694542839694, 37.672090152233416],
              [126.822309127538375, 37.672260293198342],
              [126.822326150033362, 37.67237926485106],
              [126.822960327405553, 37.67270591096959],
              [126.823065409657431, 37.672729553793793],
              [126.82312545862348, 37.672743064978484],
              [126.823129765625168, 37.67274403338299],
              [126.823130455433656, 37.67274361822615],
              [126.823324099211462, 37.672626760484903],
              [126.824004650960546, 37.672708341038771],
              [126.824320425584787, 37.672945159986966],
              [126.824609978899389, 37.673160908200423],
              [126.825290664324143, 37.67354838973089],
              [126.825737931963531, 37.67351212576316],
              [126.825824634322217, 37.673598108397215],
              [126.826015135211634, 37.673756287175458],
              [126.826314631917143, 37.673829107376584],
              [126.826656566331224, 37.673878123586086],
              [126.827152726020131, 37.674070169297089],
              [126.827162802399471, 37.674074067324611],
              [126.82714327329802, 37.674198628867373],
              [126.8271174424668, 37.674363393163823],
              [126.82739644817579, 37.674760339641246],
              [126.827436568980488, 37.674817422160459],
              [126.827243553219645, 37.675291930147402],
              [126.826959965120196, 37.67565849394321],
              [126.827077118622853, 37.675973286435735],
              [126.827421803774897, 37.676275981120092],
              [126.82787560085842, 37.676671723821492],
              [126.828032910110153, 37.676808905786679],
              [126.828216529662058, 37.676815969087123],
              [126.82857396309636, 37.676806817300204],
              [126.828768504393437, 37.676846474300568],
              [126.829348352683226, 37.676980755229749],
              [126.829511705991976, 37.676830532025988],
              [126.829616695342622, 37.676790895565041],
              [126.829688809204768, 37.676763667261731],
              [126.829754212047618, 37.676765800156502],
              [126.830383292028543, 37.67678630045522],
              [126.831101161458676, 37.676843471145069],
              [126.831210122238517, 37.676837585130492],
              [126.831275806391417, 37.676834036830989],
              [126.831640576007729, 37.676814335802007],
              [126.831897537111786, 37.67702129900848],
              [126.832228616682286, 37.677237106920394],
              [126.832539577345699, 37.677381896496215],
              [126.832873158382753, 37.677539855368082],
              [126.833069427371257, 37.677649468606582],
              [126.833328621299913, 37.67779421932719],
              [126.833553738656832, 37.677799407012337],
              [126.833509679389763, 37.678285687229106],
              [126.833515681560613, 37.678489676300906],
              [126.833472401771061, 37.678579982297499],
              [126.833362527807338, 37.678844801116263],
              [126.833248736909852, 37.679085197038944],
              [126.833248231992627, 37.679160112207235],
              [126.833244473669126, 37.679717130585288],
              [126.833239053313108, 37.679993175344471],
              [126.83323743152998, 37.680075795910859],
              [126.833813631651736, 37.680746904041548],
              [126.833879414266605, 37.680823523978006],
              [126.834447164880814, 37.681467172905307],
              [126.834415890616782, 37.681657405468115],
              [126.834385788774668, 37.681840536797594],
              [126.834361190835963, 37.681991776111019],
              [126.834325636840731, 37.682226337691077],
              [126.834251907718794, 37.682698072447486],
              [126.83422852218311, 37.68286408673341],
              [126.834323854304742, 37.682918190522209],
              [126.834469004590261, 37.683000565077911],
              [126.834528063785811, 37.683034369894841],
              [126.834748545050303, 37.683160565174155],
              [126.835443791595623, 37.683039641877222],
              [126.835498583512404, 37.683030109978993],
              [126.835864965233057, 37.682960342003447],
              [126.836010952041633, 37.682932282048306],
              [126.836665900779366, 37.682902440391238],
              [126.836784531196272, 37.682901930473875],
              [126.837062946736154, 37.682900734237897],
              [126.837866896980159, 37.682921988410726],
              [126.838163525129744, 37.682928706304644],
              [126.838380245517527, 37.682873001987744],
              [126.838676209158947, 37.682792194563028],
              [126.839108772979401, 37.682674089099322],
              [126.839292487892223, 37.682628177581662],
              [126.839360078608138, 37.682591007105309],
              [126.839698469387969, 37.682311325203976],
              [126.840306942195909, 37.682809548068661],
              [126.840282857852415, 37.682978896394026],
              [126.84001989499221, 37.68345973580427],
              [126.840081015494221, 37.683557673130188],
              [126.840433967608377, 37.684030452089523],
              [126.841105786664869, 37.684745459772408],
              [126.841307130564317, 37.684975832497237],
              [126.841584634169777, 37.685377987861102],
              [126.841614426970779, 37.685420830042666],
              [126.84170547641304, 37.685551766993875],
              [126.841857443495002, 37.68546474882006],
              [126.842038751423559, 37.68536092584494],
              [126.842343096560782, 37.685109045240253],
              [126.842536389625323, 37.685156571818034],
              [126.842989150293121, 37.685380550247778],
              [126.843212557704206, 37.685475973960791],
              [126.843233301593742, 37.685487498438505],
              [126.843423075925969, 37.685756042655449],
              [126.84352069461184, 37.685894176699684],
              [126.84377969477103, 37.686282723870363],
              [126.843895218058123, 37.686411904339906],
              [126.843915323508455, 37.686484100410205],
              [126.844007034156888, 37.686540443962777],
              [126.844031237299518, 37.686552265149928],
              [126.844248698910533, 37.686658476011402],
              [126.844268992559265, 37.686644161378055],
              [126.84449017614881, 37.686488119200298],
              [126.844944691325011, 37.686277707213385],
              [126.84511665239161, 37.686191461077144],
              [126.845249213393558, 37.686127063934968],
              [126.845518044804535, 37.686005076749275],
              [126.845973075144656, 37.685671012883724],
              [126.846283454369313, 37.685641392122378],
              [126.846432436630906, 37.68562717390369],
              [126.847030151948445, 37.685591324289355],
              [126.847075183358498, 37.685613158511785],
              [126.847434211164185, 37.685844736258872],
              [126.847663548203471, 37.68594249356061],
              [126.847813685802222, 37.68598767629399],
              [126.847889273066286, 37.686001837675377],
              [126.848076613559854, 37.686036626229765],
              [126.848119314564457, 37.686066917807452],
              [126.848158025988653, 37.686091988484606],
              [126.848148499959208, 37.686134267379096],
              [126.848148306254572, 37.686135018012003],
              [126.848143527806954, 37.686156170893369],
              [126.848199466790803, 37.686187751500569],
              [126.848223459061231, 37.686357479797969],
              [126.848209514020468, 37.68643388180454],
              [126.848226208742403, 37.686486764578682],
              [126.848235794445017, 37.686485101438343],
              [126.848431060151441, 37.687367789924075],
              [126.848635101654338, 37.688299936973323],
              [126.848643399072998, 37.688339130866702],
              [126.848652024827032, 37.688385135166577],
              [126.848702188548629, 37.688617686762527],
              [126.848758319640623, 37.688867219760326],
              [126.848797290609951, 37.689037383009484],
              [126.84884265567527, 37.689250430437426],
              [126.848862110171154, 37.689340372708443],
              [126.848884682918893, 37.689444940832857],
              [126.848905112722463, 37.689535036296306],
              [126.848928478146405, 37.689640930125229],
              [126.848961397597591, 37.689787109940873],
              [126.848985796600402, 37.689895969404709],
              [126.849049441586772, 37.690197256188938],
              [126.849060011624942, 37.690205655376872],
              [126.849085553624178, 37.69022595354825],
              [126.84912636046873, 37.69040034948587],
              [126.849125665889474, 37.690430505711973],
              [126.849277209814332, 37.690305241606495],
              [126.849283834242229, 37.690140066607107],
              [126.849284344479202, 37.690127364377339],
              [126.84944890753701, 37.69000529766938],
              [126.84961663097188, 37.689881296793864],
              [126.849834380665484, 37.689859233505743],
              [126.849959000979567, 37.689811379788409],
              [126.850110015978757, 37.689813480307571],
              [126.850135453172726, 37.689813833204376],
              [126.85023689730869, 37.689721468528759],
              [126.850305716354129, 37.689688322099371],
              [126.85032131938911, 37.689680808108186],
              [126.850342131154861, 37.68969209285909],
              [126.850505841863452, 37.689780844786952],
              [126.850577807043962, 37.689805064897342],
              [126.850677896785939, 37.689804749892687],
              [126.850814976363296, 37.689741080760044],
              [126.850843583433644, 37.689727795913072],
              [126.851042072409925, 37.689665251405245],
              [126.851114432873601, 37.689654397482492],
              [126.851123614913746, 37.689653021213012],
              [126.851284675369271, 37.689635972817058],
              [126.851457155278126, 37.689653266117674],
              [126.85151031762598, 37.68962957427906],
              [126.851549901457474, 37.689548024838736],
              [126.851569018234727, 37.689513271293841],
              [126.851641504049212, 37.689496201311414],
              [126.851780906940547, 37.689519099666775],
              [126.851832373662603, 37.68947753143604],
              [126.85186288181724, 37.689444126642435],
              [126.851918352125367, 37.689327493864781],
              [126.851928149141997, 37.689226119126559],
              [126.851959139537641, 37.689122160489937],
              [126.851981881069392, 37.688970339524595],
              [126.851984639001941, 37.688947708938578],
              [126.851989037839928, 37.688911423312071],
              [126.851997152595388, 37.688844601450256],
              [126.852002033035589, 37.688804322903877],
              [126.85200799684624, 37.688742380700816],
              [126.852008397989835, 37.68873812054828],
              [126.852073189510705, 37.688705823425025],
              [126.852113623514086, 37.688716501828274],
              [126.85219986091019, 37.688753494498386],
              [126.852213463550981, 37.688759329577337],
              [126.852357492248785, 37.688736638501105],
              [126.852411056363081, 37.688728198842426],
              [126.85246779853324, 37.688692619745368],
              [126.852608237314953, 37.688604562723391],
              [126.85268381800347, 37.688511588833705],
              [126.852760057508362, 37.688438054641892],
              [126.852767627204386, 37.688434776615303],
              [126.852837990458283, 37.688404298774813],
              [126.852924462025655, 37.688363954167755],
              [126.853309702766992, 37.688253078447666],
              [126.853404426116839, 37.688225809167349],
              [126.853839053960286, 37.687963361070217],
              [126.853772466291943, 37.688438088174578],
              [126.853747743956063, 37.688614196528029],
              [126.85373685194196, 37.688675539477707],
              [126.853659672981792, 37.689117457936838],
              [126.853655541476272, 37.689141329367182],
              [126.853659962466793, 37.689257648611452],
              [126.853703346250413, 37.689391137089345],
              [126.853715609292834, 37.689426678570385],
              [126.853876973031021, 37.689702147273735],
              [126.853431481773526, 37.689823648238502],
              [126.853431771028184, 37.689824036492034],
              [126.853733909403005, 37.690236619368804],
              [126.853952411000051, 37.690534986412779],
              [126.854251656372199, 37.690943604291554],
              [126.854351598340145, 37.691080075526401],
              [126.85467191325462, 37.691517461628564],
              [126.854805191913485, 37.691603667867447],
              [126.854805321357787, 37.691653134070236],
              [126.854890530826637, 37.69167918700397],
              [126.854975747929615, 37.691587304537791],
              [126.855074084900281, 37.691290944859851],
              [126.855083702478268, 37.691304645289087],
              [126.855222436506224, 37.691541012432317],
              [126.855598135523636, 37.692167003175726],
              [126.855745269933024, 37.692337258501254],
              [126.855771827841693, 37.692367989981769],
              [126.855923302365568, 37.692553361441497],
              [126.856070451141662, 37.69273091624644],
              [126.856023202621813, 37.693267330945723],
              [126.856008060871886, 37.693444304957751],
              [126.855992329714539, 37.693719578482572],
              [126.85598458483102, 37.693861745945206],
              [126.855972218435483, 37.694088680072497],
              [126.855889758617508, 37.694278853965244],
              [126.855678346808347, 37.69465908092041],
              [126.855414734989168, 37.694887870383049],
              [126.855263498935926, 37.694989204848135],
              [126.854935037972581, 37.69519513621379],
              [126.854707461998345, 37.695339232859986],
              [126.854277785420621, 37.695543261859122],
              [126.85373154292212, 37.695749215634621],
              [126.853576542794116, 37.695800493085407],
              [126.853385488826348, 37.695864690471851],
              [126.853311706504158, 37.695889969926533],
              [126.853243671711311, 37.695913281726916],
              [126.853128598719877, 37.695979917979685],
              [126.852890051610657, 37.696102472387473],
              [126.852660895627892, 37.69622386828884],
              [126.852490782509932, 37.696330580962943],
              [126.852111751661042, 37.696688768181907],
              [126.851907608669649, 37.696881973139497],
              [126.851696867201113, 37.697067647865595],
              [126.851293758454759, 37.697492480727654],
              [126.851135988510194, 37.697672753081363],
              [126.850809656121342, 37.698098646621027],
              [126.850664486561072, 37.698282776844174],
              [126.850644143358267, 37.698292011520444],
              [126.850572943404273, 37.698377172842491],
              [126.850572391720164, 37.698399583868287],
              [126.850362968596841, 37.6986652061059],
              [126.849896722215419, 37.699172051601174],
              [126.849314476940421, 37.699930392536643],
              [126.849242105537527, 37.700147774886801],
              [126.849018999605704, 37.700551085144781],
              [126.848995929745996, 37.700575099663041],
              [126.848662069734445, 37.700922650338484],
              [126.848346718855225, 37.701238939063963],
              [126.848144458037794, 37.701459484741868],
              [126.848033076346908, 37.701699203458105],
              [126.847999175816412, 37.701818664555972],
              [126.847978246885631, 37.701892417481375],
              [126.847885659542797, 37.702152233964661],
              [126.847754673593613, 37.702455122086661],
              [126.847790916209092, 37.702608576843836],
              [126.847806969193869, 37.70267654140244],
              [126.847912497173994, 37.702939003807273],
              [126.848087989711843, 37.703476815255286],
              [126.848106224965889, 37.7035326952061],
              [126.847966031014963, 37.703771157555373],
              [126.847946784655932, 37.703803895244668],
              [126.847672999920263, 37.704213081991313],
              [126.847399434214864, 37.704593318861669],
              [126.847272259303594, 37.704860107879085],
              [126.847039789009628, 37.705201144895739],
              [126.84699792773857, 37.705291438975735],
              [126.846804618998078, 37.705708395167179],
              [126.84655890977011, 37.706141636789788],
              [126.846163661320873, 37.707060472210543],
              [126.84599414020893, 37.707400199433593],
              [126.845943265001665, 37.707498266553799],
              [126.845836564562745, 37.707703938065222],
              [126.845784789941987, 37.707803737865106],
              [126.845633267982279, 37.708121335133207],
              [126.84551561218008, 37.708343142240231],
              [126.845301502978785, 37.708591813789802],
              [126.845027579924079, 37.70888489418433],
              [126.844724078886557, 37.709249148084687],
              [126.844154833675049, 37.709932329714228],
              [126.844032981357074, 37.710080322680049],
              [126.84388858722123, 37.710253746085314],
              [126.843814778750001, 37.710405187820996],
              [126.843837791232772, 37.710571178496103],
              [126.843860351401091, 37.710734823214949],
              [126.843866454264017, 37.710744831178438],
              [126.84377565751754, 37.711151947520143],
              [126.843732408355095, 37.711299072496203],
              [126.843547384569646, 37.711928508046256],
              [126.843521853151529, 37.712110430556336],
              [126.843537572224491, 37.712419075671122],
              [126.843539348254708, 37.712454002662028],
              [126.843515823731124, 37.712648003188022],
              [126.843478881036347, 37.712875007392803],
              [126.843439642583959, 37.713153090960212],
              [126.843401779508113, 37.713345738024877],
              [126.843396721352107, 37.713371481267892],
              [126.843372206549589, 37.713496213463273],
              [126.843338739627555, 37.713679855566582],
              [126.843258693419756, 37.714076652476059],
              [126.843190215014346, 37.71442134271166],
              [126.843185519033852, 37.714623888845161],
              [126.843110406067737, 37.71485803225206],
              [126.842949939735746, 37.715148935485942],
              [126.842948162652121, 37.715152054059153],
              [126.842808639531569, 37.715396608120386],
              [126.842381185714544, 37.715812512109295],
              [126.842161068184808, 37.715994462663026],
              [126.842003587310643, 37.716101562695492],
              [126.841961973807159, 37.716109972277138],
              [126.841575672706568, 37.716188053563251],
              [126.840849189805979, 37.716284693849715],
              [126.840583583431751, 37.716351103060418],
              [126.840664427501821, 37.716649576127367],
              [126.840718029710928, 37.716644377847416],
              [126.840755406120707, 37.716798809630895],
              [126.840831276170647, 37.71711045545895],
              [126.840863902522059, 37.717244763044789],
              [126.840870102294943, 37.717310054950765],
              [126.840906446161924, 37.717695086766447],
              [126.840912832751243, 37.717747496267904],
              [126.840941320058434, 37.718059863119414],
              [126.840931230578605, 37.718309380800115],
              [126.840919110236726, 37.718765575566167],
              [126.840888311834632, 37.71883954039545],
              [126.840627672820901, 37.719506330875888],
              [126.840552924278626, 37.719564889616649],
              [126.840577883202442, 37.719615216408236],
              [126.840591515700467, 37.719641252417738],
              [126.840649285332361, 37.719933182500533],
              [126.840662432056192, 37.720023250810279],
              [126.840688385520835, 37.720341867590264],
              [126.840872024311537, 37.720358640478295],
              [126.841056889918363, 37.720375524490549],
              [126.841185070282705, 37.720401557089772],
              [126.84079537126479, 37.721075686031888],
              [126.840765674074618, 37.72112705551239],
              [126.840664729019295, 37.721294894982108],
              [126.840465608513725, 37.721625959069073],
              [126.84025999463411, 37.721928492204171],
              [126.840170761100339, 37.722069822315312],
              [126.840021398649228, 37.722303317058817],
              [126.839943516722272, 37.722408994029266],
              [126.839455144742743, 37.723224954022328],
              [126.839300106575834, 37.723476562207082],
              [126.839179561285619, 37.723670374582973],
              [126.839172049711138, 37.723682347034767],
              [126.839102332628428, 37.723768383514773],
              [126.839047365538477, 37.723868610066937],
              [126.839042886837291, 37.723876775225449],
              [126.839041972587665, 37.723878396977028],
              [126.83892676293118, 37.723916799639738],
              [126.838681577444135, 37.723998537621377],
              [126.83866938830937, 37.724021496092618],
              [126.838574170885465, 37.724200656587996],
              [126.838370208583896, 37.724468410977565],
              [126.838334478046662, 37.724519176310523],
              [126.838308470567981, 37.724636444002321],
              [126.838218052317501, 37.724747913374621],
              [126.838183087221637, 37.724791950117478],
              [126.838087698088586, 37.724878211386482],
              [126.837874400150582, 37.724957560650353],
              [126.837804775624846, 37.724998230868763],
              [126.83757644418958, 37.72513157235359],
              [126.837366314697661, 37.725228583574541],
              [126.837239131025697, 37.725297743199732],
              [126.837260718699483, 37.725431172032316],
              [126.837273182959578, 37.725698576280919],
              [126.837221077781209, 37.72599381249983],
              [126.837212259131306, 37.726043776084389],
              [126.837076605044771, 37.726264827362066],
              [126.836921826038591, 37.72665425041788],
              [126.836883368426683, 37.726800641356895],
              [126.836941057653604, 37.726918781455311],
              [126.836922593137075, 37.727097670960738],
              [126.836921807335372, 37.72710893119536],
              [126.836869262661594, 37.727280230078016],
              [126.836808626024961, 37.727349429139728],
              [126.836762416335034, 37.727449480285038],
              [126.836759616897879, 37.727456725540677],
              [126.836768667870672, 37.727456602509143],
              [126.836888376933317, 37.727424668121536],
              [126.836902735522017, 37.727425534837792],
              [126.837037858813716, 37.727471052408269],
              [126.837133241170477, 37.727539603936314],
              [126.837148278156263, 37.727551445262172],
              [126.837174634034341, 37.727575700068584],
              [126.837234228834532, 37.727638283662564],
              [126.837232215076455, 37.727642686087734],
              [126.837218021325555, 37.727673740462407],
              [126.837210223756102, 37.727675702951956],
              [126.837171394460796, 37.727680712266455],
              [126.83717279463842, 37.72768887705363],
              [126.837191371454594, 37.727704111003682],
              [126.837310326412108, 37.72776930682933],
              [126.837344111311353, 37.727803429512498],
              [126.837381572358595, 37.727851627978829],
              [126.837433882854839, 37.727921527070421],
              [126.837479166596282, 37.72796241112507],
              [126.837525228664404, 37.727974017566659],
              [126.837573419449157, 37.727983094239853],
              [126.837619637407428, 37.728000898972702],
              [126.837836186854759, 37.728097765065833],
              [126.838005077222135, 37.728109549251883],
              [126.838036269742688, 37.728109872231649],
              [126.838087813739008, 37.728125988779951],
              [126.838110126564715, 37.728133065017147],
              [126.838178674471195, 37.728151458184293],
              [126.838183469087994, 37.728152591918942],
              [126.838219771924855, 37.728161931468321],
              [126.83831316150625, 37.728173321981302],
              [126.838365769248625, 37.728184935994172],
              [126.838412009876919, 37.728197676948163],
              [126.838464754418055, 37.728230400516686],
              [126.838507416793476, 37.728255804678298],
              [126.838680667480915, 37.728374016380734],
              [126.838722093945734, 37.728395750581043],
              [126.838734315805169, 37.728403649778052],
              [126.838754141915445, 37.728415506947805],
              [126.83888066546109, 37.728508868323949],
              [126.838925086214218, 37.728540751845351],
              [126.838974628453045, 37.728579389692172],
              [126.839059382458558, 37.728561204064107],
              [126.839085788417492, 37.728561802771878],
              [126.839096065113594, 37.728567169142273],
              [126.839109164225334, 37.728573665362418],
              [126.839141753024748, 37.728584683688965],
              [126.839153619577303, 37.728588645702999],
              [126.839214910813965, 37.72860337069023],
              [126.83927137182792, 37.728638081403041],
              [126.839298295951266, 37.728651633082443],
              [126.839405955262336, 37.72869879484081],
              [126.839415962956565, 37.728738791859158],
              [126.839426931409889, 37.728750348713277],
              [126.839463072569103, 37.7288382421102],
              [126.839480851367298, 37.728894856072934],
              [126.839483371055991, 37.728902473696657],
              [126.839515890734816, 37.729000767804088],
              [126.839568258418382, 37.729043076970854],
              [126.839593741970319, 37.729144749426844],
              [126.839601881628568, 37.72922950434662],
              [126.83962940410693, 37.729291483425875],
              [126.839659500697195, 37.729305887413453],
              [126.83965541166927, 37.729313206630913],
              [126.839664543160822, 37.729350941820393],
              [126.839699466998354, 37.729346205444521],
              [126.839706378543511, 37.729350999503744],
              [126.839737582302263, 37.729346537910864],
              [126.83974653932782, 37.729343090070628],
              [126.839836618572676, 37.729308411684542],
              [126.83986512140045, 37.729297437342098],
              [126.839941210017415, 37.729274460732547],
              [126.839953270899102, 37.72927278331634],
              [126.840042196889044, 37.729292333226226],
              [126.840186692476564, 37.729359819677711],
              [126.840241017006434, 37.729396779369871],
              [126.840279534555862, 37.729428522985756],
              [126.84030843007541, 37.72945233523712],
              [126.840317099206189, 37.729458824558122],
              [126.840319563003533, 37.729468404917938],
              [126.840297186857583, 37.729570858511892],
              [126.840294422134662, 37.729578953084165],
              [126.840312039922466, 37.729620036335703],
              [126.840312479402854, 37.729621408795978],
              [126.840312643912114, 37.729622820276717],
              [126.840312526969527, 37.729624233787646],
              [126.840312132275159, 37.729625616000767],
              [126.840311470353669, 37.729626931823645],
              [126.840310556244717, 37.72962814889307],
              [126.840309414050026, 37.729629236699161],
              [126.840308071227696, 37.729630170158678],
              [126.84030656091754, 37.729630923318908],
              [126.840304920718481, 37.729631479265286],
              [126.840273623097929, 37.729639859406014],
              [126.840203002650938, 37.729846650641491],
              [126.840214745836661, 37.729904834146353],
              [126.840285246461193, 37.730254158899143],
              [126.840547369012157, 37.730347713951957],
              [126.840552323152309, 37.730350330246829],
              [126.84057729376147, 37.730363520093],
              [126.840733427606281, 37.730454393434933],
              [126.840876536875882, 37.730666311718331],
              [126.840881727532263, 37.730821230129912],
              [126.840883526643594, 37.730874955930837],
              [126.840887452718519, 37.730947597829562],
              [126.84089449844808, 37.731046999221114],
              [126.841039663514763, 37.731211902616224],
              [126.841108051589131, 37.731244252774751],
              [126.84127782354912, 37.73132456248586],
              [126.841337947030866, 37.731392500762269],
              [126.841385149168829, 37.731445208479684],
              [126.841496606920046, 37.731544474805297],
              [126.841539209323571, 37.731564294779517],
              [126.841689612573845, 37.731634263963933],
              [126.84175549318924, 37.731611248118462],
              [126.841972187594365, 37.731535541437843],
              [126.842470472447246, 37.731683747478797],
              [126.842502670449676, 37.731713631750928],
              [126.842506731225996, 37.731718988389666],
              [126.842686113362973, 37.731773755904946],
              [126.842708671120207, 37.731780642461132],
              [126.842747111349567, 37.731788010543163],
              [126.84281276159858, 37.73184047173968],
              [126.842878071185197, 37.731887573577367],
              [126.842896668521036, 37.731902019891294],
              [126.842940891888745, 37.731936372203798],
              [126.843075689462907, 37.731975402387199],
              [126.843200412822114, 37.732004572669915],
              [126.843250912994321, 37.732016469298095],
              [126.84347676477455, 37.732078992552658],
              [126.843545171178846, 37.732088653471912],
              [126.843733018181979, 37.732104392679467],
              [126.843761725601297, 37.732106684111699],
              [126.844053757814194, 37.732143391440843],
              [126.84407878413036, 37.732141271099799],
              [126.844168286413208, 37.732133695100195],
              [126.844534967990583, 37.732156420713338],
              [126.844959135713751, 37.732155580668262],
              [126.845712778219323, 37.732340996535086],
              [126.845808194147921, 37.732383099314269],
              [126.845898364828642, 37.732422885370283],
              [126.845979062186913, 37.73244095386012],
              [126.845988186615145, 37.732442998195943],
              [126.846187398485597, 37.732537581499471],
              [126.846360322932327, 37.73257355933719],
              [126.84636170002463, 37.732592994843571],
              [126.846551013430215, 37.732754850062349],
              [126.846726375917882, 37.732909956119066],
              [126.846714474017887, 37.732922292268931],
              [126.846713429946135, 37.73292358347657],
              [126.846712679839925, 37.732924999793646],
              [126.846712239982566, 37.732926496244694],
              [126.84671212890315, 37.732928030570477],
              [126.846712347005564, 37.732929556806155],
              [126.846712887861131, 37.732931031652896],
              [126.84671373594675, 37.732932413564086],
              [126.846714866668819, 37.732933660041631],
              [126.846716249696357, 37.732934735766044],
              [126.84686272953607, 37.733030790450698],
              [126.84686420560881, 37.733031609813828],
              [126.846865827520531, 37.733032234399417],
              [126.846867552291002, 37.733032647745596],
              [126.846869338027176, 37.733032838804455],
              [126.846871276878289, 37.733032781948424],
              [126.846895867035116, 37.733053468201234],
              [126.846977480283527, 37.733102001963623],
              [126.847041868606354, 37.733163458867082],
              [126.847078178103558, 37.733174772316822],
              [126.847401276186929, 37.733365242125004],
              [126.847424525854223, 37.73338067969285],
              [126.847438949999258, 37.733390256712667],
              [126.84759244809203, 37.733492188068013],
              [126.847677607842598, 37.733528666943663],
              [126.848019647410965, 37.733675180841615],
              [126.848047095706775, 37.733686938001505],
              [126.848206265909724, 37.733755117700625],
              [126.849058907522647, 37.734165873951369],
              [126.849143843995193, 37.734152188691539],
              [126.849653808308076, 37.734061904615764],
              [126.849707140494175, 37.734078307168161],
              [126.84988169460209, 37.73411930826974],
              [126.850366386693054, 37.734233156009005],
              [126.851201097340123, 37.734403721611535],
              [126.851701936137346, 37.734538377602142],
              [126.852116222213823, 37.734606195477646],
              [126.852634640741471, 37.734720318415341],
              [126.853425421570435, 37.734884325020779],
              [126.85368534676175, 37.73494096150251],
              [126.853755657184408, 37.734970052111471],
              [126.85418532279877, 37.735060123024034],
              [126.854280272969604, 37.735091212235567],
              [126.854451948986792, 37.735135916854723],
              [126.854869896811707, 37.73523835385776],
              [126.855033562796606, 37.735302750813609],
              [126.855472402298489, 37.735420662025376],
              [126.855996808834405, 37.735561563017704],
              [126.856032916487749, 37.735586384241557],
              [126.856465351949808, 37.735392334676895],
              [126.856532966493546, 37.735361994149351],
              [126.857711073955286, 37.734833321424468],
              [126.857975897697699, 37.734649513852638],
              [126.858116494292062, 37.734541002137576],
              [126.858466069125271, 37.734248055738725],
              [126.858707249176462, 37.734207679502909],
              [126.858910243999446, 37.73417369634474],
              [126.858897937773207, 37.734073352544669],
              [126.858810062672319, 37.733356800195708],
              [126.859066698113807, 37.733327305949594],
              [126.859359291057913, 37.73329367664563],
              [126.860080377366785, 37.733210798399405],
              [126.860170465221699, 37.733097294291646],
              [126.860294478549406, 37.73294104631065],
              [126.860518448703857, 37.732886697172205],
              [126.860643496418987, 37.732843762565622],
              [126.860661016396278, 37.732837697991535],
              [126.861127359665289, 37.732676257028913],
              [126.861481177153763, 37.732757205170856],
              [126.861953178163333, 37.732580105057657],
              [126.862290445492576, 37.732698475801428],
              [126.862506237464729, 37.732641666708091],
              [126.862827069284336, 37.732557206610295],
              [126.863238951208714, 37.732629118471699],
              [126.863299467973363, 37.732639686121431],
              [126.863548326751896, 37.732742749052299],
              [126.864151773333518, 37.732600985554818],
              [126.864944910121608, 37.732635972621438],
              [126.865086091059027, 37.732340969550592],
              [126.865279423827673, 37.731936987651189],
              [126.865367601355231, 37.731892886869865],
              [126.865446163350896, 37.73186259832147],
              [126.865617348763209, 37.731796602311142],
              [126.865725018486543, 37.731680345756956],
              [126.865754096664915, 37.73164894709965],
              [126.865772724416232, 37.731649168100581],
              [126.86625694417944, 37.731654876491099],
              [126.866272675569192, 37.731583488412006],
              [126.866442276729344, 37.730813817053388],
              [126.867084161683081, 37.729993268145364],
              [126.867566176624123, 37.729671067168319],
              [126.867761735391213, 37.729540345347928],
              [126.867787615533203, 37.729523043653074],
              [126.867918401507424, 37.729435619570744],
              [126.867982669990269, 37.72907784001125],
              [126.867917059088171, 37.728698512970773],
              [126.867978305394871, 37.728448565243177],
              [126.868063755787006, 37.728478328795333],
              [126.868326485378518, 37.728569839856462],
              [126.868878492771572, 37.728762107473905],
              [126.869477778800629, 37.729142310401727],
              [126.869915721708765, 37.729163071364439],
              [126.869935210935495, 37.729168158017956],
              [126.870099715249609, 37.729252805629365],
              [126.870330454328112, 37.729271650101659],
              [126.871541728886044, 37.729381666328855],
              [126.871571325947542, 37.729485598239059],
              [126.871714750331094, 37.729670164875316],
              [126.871747824512838, 37.729710466013131],
              [126.872186122098967, 37.730062557463313],
              [126.872239522156619, 37.73010545615174],
              [126.872280080241538, 37.729920802852],
              [126.872326510509694, 37.729718706050065],
              [126.872475336194512, 37.729444632517506],
              [126.873000863848148, 37.729244462938006],
              [126.873021663770857, 37.728799356770025],
              [126.873255663611303, 37.728060543307173],
              [126.873186750944839, 37.727831279965351],
              [126.873068151897996, 37.727431628544366],
              [126.873005464014355, 37.726822871107011],
              [126.873002966488855, 37.726798630388913],
              [126.873197565547699, 37.726604847106763],
              [126.873405981829876, 37.72639730350312],
              [126.873567873236993, 37.726117359961641],
              [126.87365770887358, 37.725962013776844],
              [126.874366669599112, 37.725950392944185],
              [126.874781262481633, 37.725878662764593],
              [126.87494888427257, 37.72584966031858],
              [126.875164360070855, 37.725548060541513],
              [126.875294605345161, 37.725365754305223],
              [126.876463934525162, 37.725400796248607],
              [126.877322836193713, 37.725715068847713],
              [126.877633621465648, 37.726188400671823],
              [126.878831360107469, 37.72588445163418],
              [126.879782562296668, 37.726118283087722],
              [126.880754820866002, 37.725606576349371],
              [126.881159544432407, 37.725680810738993],
              [126.882142526958773, 37.725861102044931],
              [126.882690145192981, 37.725555606315972],
              [126.883182719737036, 37.72541955098832],
              [126.88363674449721, 37.725006969282589],
              [126.883867938228789, 37.725061824343207],
              [126.884591747753674, 37.725300175164946],
              [126.885020046909162, 37.724786771046197],
              [126.885252685199617, 37.724826975481697],
              [126.885295401507136, 37.724827657900185],
              [126.885384374058816, 37.724829079001999],
              [126.88576246339413, 37.725021752496609],
              [126.886041744719236, 37.72504651364256],
              [126.8862971754466, 37.724908807976391],
              [126.886481646769383, 37.724820577228982],
              [126.886632173348588, 37.724548749327695],
              [126.88696193064736, 37.724135753199086],
              [126.887177958589362, 37.723683227963143],
              [126.887342720826865, 37.723495870829531],
              [126.887573962583588, 37.723524816761888],
              [126.888332940882648, 37.723472895210008],
              [126.888533990398614, 37.722964891885667],
              [126.888644766593146, 37.723058259953092],
              [126.888696335119761, 37.723101725809357],
              [126.889135831360207, 37.723472158719822],
              [126.889516188290585, 37.723792743625573],
              [126.890104017790975, 37.724299531846235],
              [126.890402462889483, 37.724663299507618],
              [126.890755224711583, 37.725212930564773],
              [126.891249249159074, 37.725402317144514],
              [126.891635240347924, 37.725658324918676],
              [126.892298019719362, 37.725592774452423],
              [126.892454888229921, 37.725582224577479],
              [126.892454086572826, 37.725650077168034],
              [126.892578071630552, 37.725701150300587],
              [126.892586190297109, 37.725704687448754],
              [126.892753605840156, 37.725777614927246],
              [126.89313184732886, 37.726007429717107],
              [126.893353397056728, 37.726002839443787],
              [126.894023874333641, 37.726986916823058],
              [126.894111601380359, 37.727114258152291],
              [126.895219702101414, 37.726990253796302],
              [126.896860317766979, 37.727173040104695],
              [126.897069643569452, 37.727047370097353],
              [126.897336604952329, 37.726887685659605],
              [126.899275258617493, 37.726881219948247],
              [126.900034718707246, 37.727182476121435],
              [126.900144500137671, 37.727226021418112],
              [126.900398125989923, 37.727466121684742],
              [126.900875911032784, 37.727918417390434],
              [126.901819321286823, 37.728097819113373],
              [126.901922961265612, 37.728117526847832],
              [126.902640772479998, 37.72866574624652],
              [126.902652037759211, 37.728718571065464],
              [126.902717217114329, 37.729024222307899],
              [126.901983793653685, 37.730339574463393],
              [126.902128279173994, 37.731463736428381],
              [126.902166234016619, 37.731759028798322],
              [126.902671196312539, 37.732186570352418],
              [126.902754406672159, 37.732257020830829],
              [126.902939929211456, 37.732564625315128],
              [126.903054175811832, 37.732761814865263],
              [126.90314397203052, 37.732944614432121],
              [126.903056561219245, 37.733280175263403],
              [126.90302620987778, 37.733396681920368],
              [126.902686369092308, 37.733991578210841],
              [126.90265908981327, 37.734039332432552],
              [126.902373992410148, 37.734538389452879],
              [126.901274596098787, 37.735623960715806],
              [126.901204912338216, 37.736557523664878],
              [126.901127429214256, 37.736706963325751],
              [126.901041936756314, 37.736874131715922],
              [126.900994073235381, 37.737008393068642],
              [126.900934322662152, 37.737170209464168],
              [126.900918102336348, 37.737214134773389],
              [126.901592438988075, 37.737725636560263],
              [126.901597396793022, 37.737729395889069],
              [126.901975242379066, 37.738447385204289],
              [126.901880120912395, 37.738731902829194],
              [126.901798515714816, 37.738975988249486],
              [126.902578234049898, 37.73992730503798],
              [126.902799796447553, 37.740197626104141],
              [126.903044814366567, 37.740496557986873],
              [126.903533422437377, 37.740733476813062],
              [126.90352480359094, 37.741202464351943],
              [126.903517568751354, 37.74159613396742],
              [126.903747256686444, 37.741699112494167],
              [126.904009674635816, 37.74181676007094],
              [126.905727383944082, 37.742586835129991],
              [126.905766013076885, 37.74260415310998],
              [126.905883866218446, 37.742656985752234],
              [126.906804481187393, 37.743615279212491],
              [126.907884473890761, 37.744033116251273],
              [126.90790156268416, 37.744113657267505],
              [126.90792095797832, 37.744188348601497],
              [126.908118387690536, 37.74494862317863],
              [126.908285774527684, 37.745128871662679],
              [126.908727780514994, 37.745604833710559],
              [126.909220345698088, 37.746686372785739],
              [126.909362975124665, 37.746900741142966],
              [126.910424524677978, 37.74664423515388],
              [126.910943741644289, 37.746539898079654],
              [126.911415945733779, 37.747024176782382],
              [126.911699482797886, 37.747314961916217],
              [126.911731684894463, 37.74734798645634],
              [126.911762709550644, 37.747354243213714],
              [126.912303302561725, 37.747463293559058],
              [126.912390560588108, 37.747431825608011],
              [126.912442143331674, 37.747390943960553],
              [126.912469709156639, 37.747369097356859],
              [126.912717910050503, 37.747223726141357],
              [126.912817091960363, 37.747159327285466],
              [126.913028372387032, 37.747043767293214],
              [126.914493808183167, 37.747210973219538],
              [126.914728335210071, 37.747387674908261],
              [126.915225843635042, 37.747806148925406],
              [126.915416547269785, 37.747861750793959],
              [126.915678086395701, 37.74767301945797],
              [126.916654932457234, 37.74792790698519],
              [126.917056621568378, 37.74803271572776],
              [126.91793723087352, 37.747864691917258],
              [126.917940637942166, 37.747833161748424],
              [126.91806340055345, 37.747756666582482],
              [126.918109761591424, 37.74772052553331],
              [126.918203049988207, 37.747647802726696],
              [126.918302767682434, 37.747572139032208],
              [126.918979632531006, 37.747282901122887],
              [126.919683760971694, 37.747010284031212],
              [126.920218959094925, 37.747027827508212],
              [126.921612266470007, 37.747017805789802],
              [126.922350650173868, 37.747020255191281],
              [126.922889442525374, 37.74702204011944],
              [126.923653182291133, 37.747373357612275],
              [126.924468306509084, 37.74758111411424],
              [126.925419990017048, 37.748075680982708],
              [126.925669859528668, 37.748205529411059],
              [126.925798210268141, 37.748210353112484],
              [126.926313416547899, 37.748229716587851],
              [126.926637829045859, 37.748241907297498],
              [126.927070031860339, 37.748273426214055],
              [126.928284495364139, 37.748420314708213],
              [126.92834194321901, 37.748415277386556],
              [126.928566380735617, 37.748424147713315],
              [126.928989160020407, 37.748480717397825],
              [126.929067177345146, 37.748476540085839],
              [126.929096420734027, 37.748485009009173]
            ]
          ]
        ]
      },
      id: '31101'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        SIGUNGU_NM: '고양시 일산동구',
        SIGUNGU_CD: '31103'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.799249435025132, 37.736136401942851],
              [126.799498256797932, 37.73589412789385],
              [126.799536975109746, 37.73586716535516],
              [126.799562741206998, 37.735845811324303],
              [126.799922586515734, 37.735515045310535],
              [126.800469024878396, 37.735009744864406],
              [126.800504384763258, 37.734982725116112],
              [126.800753931119417, 37.734792035721888],
              [126.800798343168267, 37.734758326853601],
              [126.80085286768184, 37.734717308807255],
              [126.80099294508598, 37.73461431362368],
              [126.801121056015916, 37.734520115348225],
              [126.801269946737165, 37.734125914328693],
              [126.801289921473142, 37.734080901243274],
              [126.80130296529876, 37.734043757002283],
              [126.801337420371496, 37.733954009202414],
              [126.8013686938841, 37.733790753005962],
              [126.801383874213215, 37.733684925074058],
              [126.801417733836232, 37.733484229181698],
              [126.801358604327632, 37.733063496897763],
              [126.801350401650723, 37.73301758942516],
              [126.801348367337994, 37.732980700105529],
              [126.801344105295726, 37.732960356522881],
              [126.801316050478235, 37.733001043197511],
              [126.801315065564424, 37.733002235206008],
              [126.801313857239009, 37.733003289791853],
              [126.801312452999767, 37.733004179177328],
              [126.801310891641606, 37.733004881059493],
              [126.801309211900161, 37.733005379444293],
              [126.801307452485759, 37.733005661041453],
              [126.801305658866553, 37.73300571800867],
              [126.80130387530825, 37.733005549707002],
              [126.801302147160555, 37.733005160911794],
              [126.80130052196651, 37.733004564523277],
              [126.801015884852191, 37.732877066217917],
              [126.801014195692574, 37.732876152183643],
              [126.801012784025872, 37.732875055025438],
              [126.801011635014845, 37.732873783668502],
              [126.801010779019549, 37.732872368036183],
              [126.801010246213451, 37.732870857879718],
              [126.801010050933783, 37.732869297448531],
              [126.801010200683479, 37.732867733655404],
              [126.801010691592154, 37.732866216049672],
              [126.801011502826697, 37.732864787770879],
              [126.80101169950369, 37.73286450952979],
              [126.801203667029, 37.732592446800275],
              [126.801190337384867, 37.732557267905889],
              [126.801166932497978, 37.732493593722019],
              [126.801083994879249, 37.732289329307328],
              [126.801111440568974, 37.731840868332895],
              [126.80111318454118, 37.731787653367057],
              [126.801120579132728, 37.731737552594225],
              [126.80112191535504, 37.73170151786352],
              [126.801125753861228, 37.731594250883361],
              [126.801080371566613, 37.731528569836371],
              [126.801033771245642, 37.73145641461965],
              [126.801000232112344, 37.7314042730046],
              [126.800990676190992, 37.731389848489208],
              [126.800568981565803, 37.730753440192693],
              [126.800854780573786, 37.730132541906684],
              [126.801108586674943, 37.729969986095753],
              [126.80111245014497, 37.72996751236051],
              [126.801225686211453, 37.729894987010852],
              [126.801315803527785, 37.729664975746608],
              [126.801377123984537, 37.729508459980352],
              [126.801386853366111, 37.729483627840551],
              [126.80140223206331, 37.729475801833587],
              [126.801716087966753, 37.729316102589955],
              [126.80182671276782, 37.729306992997159],
              [126.801874393552836, 37.729305946848747],
              [126.801892146717762, 37.729295283080177],
              [126.802272854395611, 37.729299585758916],
              [126.802401296263781, 37.729253628084827],
              [126.802582387034732, 37.729142718475551],
              [126.802628025726065, 37.72911013916427],
              [126.802784093351733, 37.729009040730155],
              [126.802944006150952, 37.72892794336488],
              [126.80328781435631, 37.728746644849899],
              [126.803388260258487, 37.728695848206904],
              [126.803399793273883, 37.728693054685429],
              [126.803400878566464, 37.728685740620335],
              [126.803557379981086, 37.728488072338301],
              [126.803616116301285, 37.728412159992402],
              [126.803503418769239, 37.72836503681097],
              [126.803501755162713, 37.728364188758874],
              [126.80350030467558, 37.728363122045167],
              [126.803499107820485, 37.728361872961813],
              [126.803498202840629, 37.728360477787319],
              [126.803497616572699, 37.728358979042127],
              [126.80349736447242, 37.728357422784555],
              [126.8034974597429, 37.728355853257042],
              [126.803497893959886, 37.728354321784792],
              [126.80349865874129, 37.728352875186651],
              [126.803523930127, 37.728314633895913],
              [126.803524919911823, 37.728313395929341],
              [126.80352614665604, 37.728312301770565],
              [126.803527580586547, 37.728311380085628],
              [126.803529185172621, 37.728310654092965],
              [126.803530914797136, 37.728310147858544],
              [126.803532726298258, 37.728309865633911],
              [126.803534569587526, 37.728309824248093],
              [126.803536190848689, 37.728309983445008],
              [126.803537767251328, 37.728310328047741],
              [126.8035392637033, 37.728310848835775],
              [126.803656973326611, 37.728360056588031],
              [126.803820026775398, 37.728163600156307],
              [126.80387492512196, 37.727981812787675],
              [126.803956306935987, 37.727702651376532],
              [126.803971559664404, 37.727566965550885],
              [126.803980553771424, 37.727516301662952],
              [126.80401612557894, 37.727269443223506],
              [126.8042195786321, 37.727278515422057],
              [126.804296998160765, 37.727290463214274],
              [126.804305504672485, 37.72729104519216],
              [126.804309387006754, 37.727297809940161],
              [126.804352745841328, 37.727321811860833],
              [126.804570310718518, 37.72728360532976],
              [126.804655250816637, 37.72726966551825],
              [126.804761306605798, 37.727245913182884],
              [126.804768928441931, 37.727246204192895],
              [126.804861983753511, 37.72724410670088],
              [126.805015767320072, 37.727264635967408],
              [126.805152425951661, 37.727328778775046],
              [126.805343145646262, 37.727394135011885],
              [126.805505875130606, 37.727450995187922],
              [126.805621794423487, 37.727519606565821],
              [126.805654620012746, 37.727502489260715],
              [126.805713911365117, 37.727468792204924],
              [126.805760057003909, 37.727444131799302],
              [126.805764272167593, 37.727441879901484],
              [126.805772306180856, 37.727436797831082],
              [126.805788605282643, 37.727439077703806],
              [126.806066958843104, 37.727404626137812],
              [126.806123001131525, 37.727393178540254],
              [126.806206331457091, 37.727382333165671],
              [126.806228366464836, 37.727360692484716],
              [126.806249632377217, 37.72729230867759],
              [126.806332248794234, 37.727217836079447],
              [126.806391826220576, 37.727208647120442],
              [126.806477053866246, 37.727080960814604],
              [126.807002906164641, 37.726885310596259],
              [126.807123565895921, 37.726902403058162],
              [126.807622531071885, 37.72695556593564],
              [126.807837461498096, 37.726978465086269],
              [126.808216406697824, 37.72703877564679],
              [126.808721463457402, 37.726854054841567],
              [126.809173772727931, 37.726688622759241],
              [126.809179726656041, 37.726686444921341],
              [126.80955108879796, 37.726464060564794],
              [126.809564881530534, 37.726456150286047],
              [126.809894196480968, 37.726267245781123],
              [126.809981126222254, 37.726232484368325],
              [126.810029226942532, 37.726209191102399],
              [126.810160195727718, 37.726142957397059],
              [126.810138741586641, 37.726078162464283],
              [126.810125803927065, 37.725732116798376],
              [126.810125135897167, 37.725714097394267],
              [126.810124559737602, 37.725708927681907],
              [126.810106253034661, 37.725544856829998],
              [126.810210258396779, 37.725421422797019],
              [126.810305467360465, 37.725338804656559],
              [126.810539648033711, 37.725110560751943],
              [126.810663222622523, 37.724956186825146],
              [126.81078494408969, 37.724832502543755],
              [126.810941091769436, 37.72469254691719],
              [126.811099590323693, 37.72460523805043],
              [126.811359235852251, 37.724464314738405],
              [126.811459109925153, 37.724429571946956],
              [126.81155966880209, 37.724401010687941],
              [126.811737934097792, 37.724343015413723],
              [126.811862131958264, 37.724292816115394],
              [126.811958750283139, 37.724283681907345],
              [126.812100579662669, 37.724261664854701],
              [126.81227694793931, 37.72424062245716],
              [126.812345059212873, 37.724232496940608],
              [126.81255031208768, 37.724154837916693],
              [126.812984144715912, 37.72395647449089],
              [126.813099275066548, 37.723896645455731],
              [126.813232607304869, 37.72382735758098],
              [126.813304310628325, 37.723785802795405],
              [126.813470964386525, 37.723693440368798],
              [126.813565052942195, 37.723630523002058],
              [126.813621353447914, 37.723585006278412],
              [126.813725655748669, 37.723477895434016],
              [126.813732737498313, 37.723480727134287],
              [126.813778107821264, 37.72348050994092],
              [126.813801011885545, 37.723478444052105],
              [126.814287228958193, 37.723434586855042],
              [126.81438685857897, 37.723495001095152],
              [126.814463655739246, 37.723546405416315],
              [126.814743555719829, 37.723733755238705],
              [126.814957453497669, 37.724025213144863],
              [126.815322052691556, 37.724434322317926],
              [126.815677366149529, 37.724805404008031],
              [126.815908841111352, 37.724944857925585],
              [126.816245950383902, 37.725009862540816],
              [126.816405533107456, 37.725411040796665],
              [126.817095864844106, 37.725551214486543],
              [126.817161873370239, 37.725558866866876],
              [126.817305117121393, 37.725575469533361],
              [126.817562396389505, 37.725604586340154],
              [126.817867329224498, 37.725637720602904],
              [126.818185156055776, 37.725621889857003],
              [126.818437212102467, 37.72561242526362],
              [126.818482756041234, 37.725609749379707],
              [126.818482467421035, 37.725624528391258],
              [126.818482441408605, 37.72562526191205],
              [126.818482578150366, 37.72562680936074],
              [126.818483046635023, 37.725628313661467],
              [126.818483833626189, 37.725629733277266],
              [126.818484915691783, 37.725631024809417],
              [126.818486260289134, 37.725632148411904],
              [126.818487827984811, 37.725633073211995],
              [126.818489571386138, 37.725633770093616],
              [126.818491439605282, 37.725634219834888],
              [126.81849337830927, 37.725634407700689],
              [126.818494211082111, 37.725634422429522],
              [126.818733481580438, 37.725641219638959],
              [126.818734314493739, 37.725641219044519],
              [126.818734936643338, 37.725641185686037],
              [126.818991327791124, 37.72562226491916],
              [126.818993134343998, 37.725622013973762],
              [126.818994865825729, 37.725621534561739],
              [126.818996478994279, 37.72562083995301],
              [126.818997928305137, 37.725619947009456],
              [126.818999179478283, 37.725618881671267],
              [126.81900019710713, 37.725617668970742],
              [126.819000954780265, 37.725616343005314],
              [126.819001435180155, 37.725614936122319],
              [126.819001623209232, 37.725613486089806],
              [126.819001513965674, 37.725612032537391],
              [126.818994750327803, 37.725573920075874],
              [126.819104273229513, 37.725562500325509],
              [126.819132816920614, 37.725555788147282],
              [126.819217510272381, 37.725567181399441],
              [126.819262331151037, 37.725574566034012],
              [126.819297070021278, 37.725576592697585],
              [126.819346005822311, 37.725583696416273],
              [126.819457761943696, 37.7255999235799],
              [126.819468595912554, 37.725592056345036],
              [126.819468051141641, 37.725590082022087],
              [126.81946051210096, 37.725562772790063],
              [126.819443839810589, 37.725496858841844],
              [126.819444414521016, 37.72549692525179],
              [126.819468225617968, 37.725499707172816],
              [126.819534200945697, 37.72550741391543],
              [126.81954823661944, 37.725494768350281],
              [126.819742078077581, 37.725513365393823],
              [126.819919865673256, 37.725505477169392],
              [126.820568899456234, 37.725506474250793],
              [126.820860867098915, 37.725479893174011],
              [126.821139409960523, 37.725436956296555],
              [126.821300293891525, 37.725445371696814],
              [126.821444397250957, 37.725452909748974],
              [126.821467964177415, 37.725454352307715],
              [126.821484070840071, 37.725608954027457],
              [126.821490458070542, 37.725680753576697],
              [126.821537788575043, 37.725825546609833],
              [126.821609806206055, 37.725943061854004],
              [126.821661379207313, 37.726165570884682],
              [126.821682561373819, 37.726201073029038],
              [126.821720497194065, 37.726273775141543],
              [126.82176214893876, 37.726344789725474],
              [126.821808033885176, 37.726428198308398],
              [126.821893696352433, 37.726696365712321],
              [126.821897354952085, 37.72672199134081],
              [126.821914559291855, 37.726775495075493],
              [126.821920440167531, 37.726841402478598],
              [126.821920746186152, 37.726842936475897],
              [126.82192137732379, 37.726844407448574],
              [126.821922318121793, 37.726845768439254],
              [126.821923342623393, 37.726846785608743],
              [126.821922478635202, 37.726847454823329],
              [126.821921410631887, 37.726848595153911],
              [126.821920580967813, 37.726849855829172],
              [126.821920011514834, 37.726851202724852],
              [126.82191971617705, 37.72685260437494],
              [126.821919700948527, 37.726854025662519],
              [126.821928830966229, 37.726956368521535],
              [126.821929136985489, 37.726957902518805],
              [126.82192977041008, 37.726959371701938],
              [126.821930711201432, 37.72696073359382],
              [126.821931932512683, 37.726961945678163],
              [126.821933398337052, 37.726962974399179],
              [126.821935063573676, 37.726963787951405],
              [126.821936174722552, 37.726964138645407],
              [126.821935022705034, 37.726964785473044],
              [126.821933741570732, 37.726965778564598],
              [126.82193267356584, 37.726966918895279],
              [126.821931843908843, 37.726968178669289],
              [126.821931274455096, 37.726969525565003],
              [126.821930979108799, 37.726970928116344],
              [126.821930963888676, 37.726972348502642],
              [126.821940096203363, 37.727074691372081],
              [126.821940402223291, 37.727076225369281],
              [126.821941034514381, 37.727077694545819],
              [126.821941976433862, 37.727079057345385],
              [126.821943197739031, 37.727080270330887],
              [126.8219446635823, 37.727081297249235],
              [126.821946328813439, 37.727082111702593],
              [126.821948146029186, 37.72708268547742],
              [126.821950060902921, 37.727083002938905],
              [126.821952014511581, 37.72708305473499],
              [126.822015363722286, 37.727080391572606],
              [126.822052388099948, 37.727185939489623],
              [126.822062831037215, 37.727217851148012],
              [126.822063816889766, 37.727220868113896],
              [126.822073143622816, 37.727247631344994],
              [126.822085588216538, 37.727308186613151],
              [126.822086783687652, 37.727325927778701],
              [126.822103469184427, 37.727395672011909],
              [126.822112051369828, 37.727431541458238],
              [126.822190997007269, 37.7276211437459],
              [126.822210526460864, 37.72775463274828],
              [126.822333996453082, 37.72800483762105],
              [126.822546105503946, 37.728317117059277],
              [126.822540043428404, 37.72840805201659],
              [126.822556269543313, 37.728428889046782],
              [126.82260259231839, 37.728488387008994],
              [126.822631440805125, 37.72857712146849],
              [126.822636195628888, 37.728590075459969],
              [126.822643885801369, 37.728637954274355],
              [126.82274932765344, 37.728862508203328],
              [126.822770392177034, 37.729023587970516],
              [126.822799200849076, 37.729058544256006],
              [126.822815470538572, 37.729075741085595],
              [126.822846595305322, 37.729105347836494],
              [126.822884126739211, 37.729121172408426],
              [126.822904670555786, 37.72913022244888],
              [126.822978678745599, 37.729162426104914],
              [126.822979571923398, 37.729088377340403],
              [126.822978768491524, 37.729049237601387],
              [126.823135396807913, 37.728852958026586],
              [126.823161737582751, 37.728809968696069],
              [126.823190377572772, 37.728763223257786],
              [126.823214296772122, 37.728694281948172],
              [126.823249628242067, 37.728667027106901],
              [126.823267573754705, 37.728648467486181],
              [126.823299221132018, 37.72861079209941],
              [126.823448481095255, 37.728451376866474],
              [126.823570215758011, 37.728315569390325],
              [126.823598110459017, 37.7282885818206],
              [126.823619229264239, 37.728275945773554],
              [126.823696280271804, 37.72822144544066],
              [126.823752400066454, 37.728177040497108],
              [126.823768256624419, 37.72814158536638],
              [126.823781242266648, 37.728121342396626],
              [126.823803498583999, 37.728078571037535],
              [126.823812790728681, 37.72804931354807],
              [126.823821138072859, 37.728040595231313],
              [126.823824490994525, 37.727973866618484],
              [126.82382290871729, 37.727892785985048],
              [126.823816087066135, 37.727857016892784],
              [126.823810278379497, 37.727838140664353],
              [126.823772283386305, 37.72779359960586],
              [126.823738962698101, 37.727719224755447],
              [126.823721668035901, 37.727684844891712],
              [126.823721614208196, 37.727635858064872],
              [126.823726178982994, 37.727505505303007],
              [126.8237981715004, 37.727416922355303],
              [126.823821849999945, 37.727372470078699],
              [126.823837400662015, 37.727315345543389],
              [126.823898912862219, 37.727236036444083],
              [126.823969143481989, 37.727144360780478],
              [126.824001265023327, 37.727055159676425],
              [126.824026090109456, 37.72697692179095],
              [126.824041259077219, 37.726932175511955],
              [126.824114791433459, 37.726868091747221],
              [126.82416628368496, 37.726827910665968],
              [126.824210319033483, 37.726794753131735],
              [126.824276398606543, 37.726744334654796],
              [126.824319696275509, 37.726711299935921],
              [126.824371849828282, 37.726693358020505],
              [126.824488593699527, 37.72664088185558],
              [126.824523286682933, 37.726586313081668],
              [126.824569476822518, 37.726538517765967],
              [126.824629533721662, 37.726478352898404],
              [126.824749107345625, 37.726428982395035],
              [126.824840758446314, 37.726343528996409],
              [126.825074218543861, 37.726178890293397],
              [126.825096781715331, 37.72615442615934],
              [126.825131409978752, 37.726131675092581],
              [126.825129434601024, 37.72614152778813],
              [126.825136200715235, 37.726201228245479],
              [126.825138569527127, 37.726249936313437],
              [126.825138903619646, 37.726257262321141],
              [126.825164215392078, 37.726345701955331],
              [126.825198987404491, 37.726406290988685],
              [126.825200181666133, 37.726425996990429],
              [126.825233552118164, 37.726404936774664],
              [126.825263906190656, 37.726386395588129],
              [126.825301720909891, 37.726359143511608],
              [126.825377171912479, 37.726308288630747],
              [126.82550434444812, 37.726267656741676],
              [126.825632547226448, 37.726242791022969],
              [126.825866975899984, 37.726262006759441],
              [126.826018363844398, 37.726251807829733],
              [126.826186903769454, 37.726260510003918],
              [126.82626333686828, 37.726316366991938],
              [126.826297236507997, 37.726371888927439],
              [126.82636520454389, 37.72648235869287],
              [126.826468106953783, 37.726589779677248],
              [126.826522975147498, 37.726623367944519],
              [126.826599521700231, 37.726631924289293],
              [126.826646145962215, 37.726625524481683],
              [126.826695790993682, 37.726617993632168],
              [126.826761372536581, 37.726614991857346],
              [126.826819354579271, 37.726607472515589],
              [126.827114499167052, 37.726590458870824],
              [126.827182075602622, 37.726641516575924],
              [126.827384735619844, 37.726607182846543],
              [126.827555991018983, 37.726589138481472],
              [126.827576190513753, 37.726590573230155],
              [126.827823204086769, 37.726617966909345],
              [126.827999199298588, 37.726616251934523],
              [126.828064614711025, 37.726611285420574],
              [126.828079498376368, 37.726611027755787],
              [126.828098459281364, 37.726613028592368],
              [126.828314374504842, 37.726672747255037],
              [126.82836751189754, 37.726690005572962],
              [126.828472345343755, 37.726729577521027],
              [126.828539851555064, 37.726813290540406],
              [126.828562843197957, 37.726836410469019],
              [126.828644403740512, 37.726898755756054],
              [126.82866666775989, 37.726929199221317],
              [126.828696896639002, 37.726958525341473],
              [126.828703266939129, 37.726965851463298],
              [126.828724660979844, 37.726990096118257],
              [126.828781290145628, 37.727100546795128],
              [126.82879030595727, 37.727110979490007],
              [126.828747404817221, 37.72726773727959],
              [126.828849034850791, 37.727466098954253],
              [126.828861200417563, 37.72749229917423],
              [126.829007007395319, 37.727674398295989],
              [126.829051007734051, 37.727727952574128],
              [126.828942738146395, 37.727795086421168],
              [126.828932633426376, 37.727816153044394],
              [126.82891601966007, 37.727850790682517],
              [126.829003597108681, 37.727921310655653],
              [126.829139721142795, 37.727959356626478],
              [126.829178773979436, 37.72797027388286],
              [126.829301573644415, 37.72806026961004],
              [126.829597415943994, 37.728278621620284],
              [126.829737103197175, 37.728346113333473],
              [126.829754798357939, 37.728356276236802],
              [126.829889017667639, 37.728413906928019],
              [126.830012354230107, 37.728503055926453],
              [126.830363044096032, 37.728608871158706],
              [126.830481032370557, 37.728631565898553],
              [126.830558099538777, 37.728649130388838],
              [126.830630368571477, 37.728666974031164],
              [126.830748910408261, 37.72868403842125],
              [126.830803807160734, 37.728702985684215],
              [126.830990995250843, 37.728773927204244],
              [126.831090561055916, 37.728792648333062],
              [126.831183928688503, 37.728807703710999],
              [126.831315862219839, 37.728784812458748],
              [126.831389250689412, 37.728856147884329],
              [126.831478103984708, 37.728910613607539],
              [126.831601454397756, 37.728990192653576],
              [126.8316447658203, 37.728964631743501],
              [126.83164777049825, 37.72896491556866],
              [126.831700300877046, 37.728935709707642],
              [126.831704609501926, 37.728932830093839],
              [126.831749482411993, 37.728902841719446],
              [126.831883142934572, 37.728813784995779],
              [126.831967676525522, 37.728743802787392],
              [126.832063219439789, 37.728660592090435],
              [126.832127005699434, 37.728590299323962],
              [126.832383908031986, 37.728244077244256],
              [126.832511292778605, 37.728109963503407],
              [126.832648020285362, 37.728002601301121],
              [126.832763138611938, 37.727885645595833],
              [126.832874889129286, 37.727768117307164],
              [126.832898526032096, 37.727738590554381],
              [126.83297161947695, 37.727630579421486],
              [126.833141333416677, 37.727369344878554],
              [126.833170993974036, 37.727323691540931],
              [126.833228122438499, 37.727228098397532],
              [126.833262047093456, 37.727215891768552],
              [126.833263705918867, 37.727215156663334],
              [126.833265187341325, 37.727214210548034],
              [126.833266450260382, 37.727213081130948],
              [126.833267456923338, 37.727211802448629],
              [126.833268180940678, 37.727210406799195],
              [126.833268601523983, 37.727208934624336],
              [126.8332687069543, 37.727207427318291],
              [126.833268494582455, 37.727205927227985],
              [126.833267969718491, 37.727204474942681],
              [126.83326714671783, 37.727203114708054],
              [126.833254071328028, 37.727185449762736],
              [126.833256797919205, 37.727181050536494],
              [126.833261045327092, 37.727187006122698],
              [126.833262145990716, 37.72718827959882],
              [126.833263502342376, 37.727189385974874],
              [126.83326507837009, 37.727190291698484],
              [126.833266824380473, 37.72719097035047],
              [126.833268690631769, 37.727191400919153],
              [126.833270622730197, 37.727191574985042],
              [126.833272564028633, 37.727191482313295],
              [126.833274456599867, 37.727191128885451],
              [126.833276245888484, 37.727190524307503],
              [126.833406152630857, 37.727135473782994],
              [126.833407810282324, 37.727134616090083],
              [126.833409254251904, 37.727133543576848],
              [126.833410442272282, 37.727132287550347],
              [126.833411337677106, 37.727130887461279],
              [126.833411912861436, 37.727129384614003],
              [126.833412152653978, 37.727127825791236],
              [126.833412047564991, 37.727126256906423],
              [126.8334116005543, 37.727124727548727],
              [126.833410825977424, 37.727123281963863],
              [126.833362239271054, 37.72705088928209],
              [126.833361981703632, 37.727050506568709],
              [126.833360926638989, 37.727049209917141],
              [126.833359606841682, 37.727048076709401],
              [126.83335806287063, 37.727047139622222],
              [126.83335633988834, 37.727046424147872],
              [126.833354487604126, 37.727045954902941],
              [126.833352562656884, 37.727045743023609],
              [126.833351053257573, 37.727045759724618],
              [126.833349560583642, 37.727045936053202],
              [126.833348111892661, 37.727046269459542],
              [126.83334669920113, 37.72704676440511],
              [126.833333993647798, 37.727052023838986],
              [126.833335216294742, 37.727049690034619],
              [126.833354952067396, 37.72702072517621],
              [126.833365994558704, 37.726997937447983],
              [126.833401956343849, 37.726926190517972],
              [126.833437637290672, 37.726823478162288],
              [126.833443372471578, 37.72677460155446],
              [126.833444507767013, 37.726764920624383],
              [126.833465536837892, 37.726712303500804],
              [126.833504199342471, 37.726542021859501],
              [126.83352241648825, 37.726481792529711],
              [126.833547836090602, 37.726291498873188],
              [126.833559802025334, 37.726096119630022],
              [126.833568175942517, 37.725838231791862],
              [126.833605225918063, 37.725364437007563],
              [126.833825724391318, 37.725278311416858],
              [126.833938546672059, 37.725231171926865],
              [126.834021218982642, 37.725672761418224],
              [126.834053115396856, 37.725752767619902],
              [126.834142233125533, 37.726002351677465],
              [126.834213069458897, 37.726184608615135],
              [126.834305688147836, 37.726456723590047],
              [126.834351523255464, 37.726644018213655],
              [126.834400737556507, 37.726747419327005],
              [126.83455478907446, 37.727050296759508],
              [126.834584079816636, 37.727110037316201],
              [126.834591485351453, 37.727126652525065],
              [126.834636101703083, 37.727225540827192],
              [126.834725799577072, 37.727380237076908],
              [126.834753643009364, 37.727447289913343],
              [126.834789110291723, 37.727516461495625],
              [126.834887410625782, 37.727708191678794],
              [126.83496391681787, 37.727816415344456],
              [126.835005187092236, 37.727790611836674],
              [126.835199793203259, 37.727668936662006],
              [126.835335915885508, 37.727591140097161],
              [126.835342287298744, 37.7275928344433],
              [126.835349028280916, 37.727592284792244],
              [126.835368585102572, 37.727566130695216],
              [126.835572737554998, 37.727655100405123],
              [126.835671914550105, 37.727682377079539],
              [126.8357024027828, 37.727690762587997],
              [126.835881198551874, 37.727629352039095],
              [126.835910393797178, 37.727607164765935],
              [126.836050569130848, 37.72750063550243],
              [126.836213373763613, 37.727495060164664],
              [126.836239881278047, 37.727494152177918],
              [126.836307758654499, 37.727495374379025],
              [126.836322961237272, 37.727493603257734],
              [126.836453488943363, 37.727478397365566],
              [126.836595489670586, 37.727463396500461],
              [126.83668729683572, 37.727463523752824],
              [126.836737986055482, 37.727465847740611],
              [126.836750233378666, 37.727456854812296],
              [126.836759616897879, 37.727456725540677],
              [126.836762416335034, 37.727449480285038],
              [126.836808626024961, 37.727349429139728],
              [126.836869262661594, 37.727280230078016],
              [126.836921807335372, 37.72710893119536],
              [126.836922593137075, 37.727097670960738],
              [126.836941057653604, 37.726918781455311],
              [126.836883368426683, 37.726800641356895],
              [126.836921826038591, 37.72665425041788],
              [126.837076605044771, 37.726264827362066],
              [126.837212259131306, 37.726043776084389],
              [126.837221077781209, 37.72599381249983],
              [126.837273182959578, 37.725698576280919],
              [126.837260718699483, 37.725431172032316],
              [126.837239131025697, 37.725297743199732],
              [126.837366314697661, 37.725228583574541],
              [126.83757644418958, 37.72513157235359],
              [126.837804775624846, 37.724998230868763],
              [126.837874400150582, 37.724957560650353],
              [126.838087698088586, 37.724878211386482],
              [126.838183087221637, 37.724791950117478],
              [126.838218052317501, 37.724747913374621],
              [126.838308470567981, 37.724636444002321],
              [126.838334478046662, 37.724519176310523],
              [126.838370208583896, 37.724468410977565],
              [126.838574170885465, 37.724200656587996],
              [126.83866938830937, 37.724021496092618],
              [126.838681577444135, 37.723998537621377],
              [126.83892676293118, 37.723916799639738],
              [126.839041972587665, 37.723878396977028],
              [126.839042886837291, 37.723876775225449],
              [126.839047365538477, 37.723868610066937],
              [126.839102332628428, 37.723768383514773],
              [126.839172049711138, 37.723682347034767],
              [126.839179561285619, 37.723670374582973],
              [126.839300106575834, 37.723476562207082],
              [126.839455144742743, 37.723224954022328],
              [126.839943516722272, 37.722408994029266],
              [126.840021398649228, 37.722303317058817],
              [126.840170761100339, 37.722069822315312],
              [126.84025999463411, 37.721928492204171],
              [126.840465608513725, 37.721625959069073],
              [126.840664729019295, 37.721294894982108],
              [126.840765674074618, 37.72112705551239],
              [126.84079537126479, 37.721075686031888],
              [126.841185070282705, 37.720401557089772],
              [126.841056889918363, 37.720375524490549],
              [126.840872024311537, 37.720358640478295],
              [126.840688385520835, 37.720341867590264],
              [126.840662432056192, 37.720023250810279],
              [126.840649285332361, 37.719933182500533],
              [126.840591515700467, 37.719641252417738],
              [126.840577883202442, 37.719615216408236],
              [126.840552924278626, 37.719564889616649],
              [126.840627672820901, 37.719506330875888],
              [126.840888311834632, 37.71883954039545],
              [126.840919110236726, 37.718765575566167],
              [126.840931230578605, 37.718309380800115],
              [126.840941320058434, 37.718059863119414],
              [126.840912832751243, 37.717747496267904],
              [126.840906446161924, 37.717695086766447],
              [126.840870102294943, 37.717310054950765],
              [126.840863902522059, 37.717244763044789],
              [126.840831276170647, 37.71711045545895],
              [126.840755406120707, 37.716798809630895],
              [126.840718029710928, 37.716644377847416],
              [126.840664427501821, 37.716649576127367],
              [126.840583583431751, 37.716351103060418],
              [126.840849189805979, 37.716284693849715],
              [126.841575672706568, 37.716188053563251],
              [126.841961973807159, 37.716109972277138],
              [126.842003587310643, 37.716101562695492],
              [126.842161068184808, 37.715994462663026],
              [126.842381185714544, 37.715812512109295],
              [126.842808639531569, 37.715396608120386],
              [126.842948162652121, 37.715152054059153],
              [126.842949939735746, 37.715148935485942],
              [126.843110406067737, 37.71485803225206],
              [126.843185519033852, 37.714623888845161],
              [126.843190215014346, 37.71442134271166],
              [126.843258693419756, 37.714076652476059],
              [126.843338739627555, 37.713679855566582],
              [126.843372206549589, 37.713496213463273],
              [126.843396721352107, 37.713371481267892],
              [126.843401779508113, 37.713345738024877],
              [126.843439642583959, 37.713153090960212],
              [126.843478881036347, 37.712875007392803],
              [126.843515823731124, 37.712648003188022],
              [126.843539348254708, 37.712454002662028],
              [126.843537572224491, 37.712419075671122],
              [126.843521853151529, 37.712110430556336],
              [126.843547384569646, 37.711928508046256],
              [126.843732408355095, 37.711299072496203],
              [126.84377565751754, 37.711151947520143],
              [126.843866454264017, 37.710744831178438],
              [126.843860351401091, 37.710734823214949],
              [126.843837791232772, 37.710571178496103],
              [126.843814778750001, 37.710405187820996],
              [126.84388858722123, 37.710253746085314],
              [126.844032981357074, 37.710080322680049],
              [126.844154833675049, 37.709932329714228],
              [126.844724078886557, 37.709249148084687],
              [126.845027579924079, 37.70888489418433],
              [126.845301502978785, 37.708591813789802],
              [126.84551561218008, 37.708343142240231],
              [126.845633267982279, 37.708121335133207],
              [126.845784789941987, 37.707803737865106],
              [126.845836564562745, 37.707703938065222],
              [126.845943265001665, 37.707498266553799],
              [126.84599414020893, 37.707400199433593],
              [126.846163661320873, 37.707060472210543],
              [126.84655890977011, 37.706141636789788],
              [126.846804618998078, 37.705708395167179],
              [126.84699792773857, 37.705291438975735],
              [126.847039789009628, 37.705201144895739],
              [126.847272259303594, 37.704860107879085],
              [126.847399434214864, 37.704593318861669],
              [126.847672999920263, 37.704213081991313],
              [126.847946784655932, 37.703803895244668],
              [126.847966031014963, 37.703771157555373],
              [126.848106224965889, 37.7035326952061],
              [126.848087989711843, 37.703476815255286],
              [126.847912497173994, 37.702939003807273],
              [126.847806969193869, 37.70267654140244],
              [126.847790916209092, 37.702608576843836],
              [126.847754673593613, 37.702455122086661],
              [126.847885659542797, 37.702152233964661],
              [126.847978246885631, 37.701892417481375],
              [126.847999175816412, 37.701818664555972],
              [126.848033076346908, 37.701699203458105],
              [126.848144458037794, 37.701459484741868],
              [126.848346718855225, 37.701238939063963],
              [126.848662069734445, 37.700922650338484],
              [126.848995929745996, 37.700575099663041],
              [126.849018999605704, 37.700551085144781],
              [126.849242105537527, 37.700147774886801],
              [126.849314476940421, 37.699930392536643],
              [126.849896722215419, 37.699172051601174],
              [126.850362968596841, 37.6986652061059],
              [126.850572391720164, 37.698399583868287],
              [126.850572943404273, 37.698377172842491],
              [126.850644143358267, 37.698292011520444],
              [126.850664486561072, 37.698282776844174],
              [126.850809656121342, 37.698098646621027],
              [126.851135988510194, 37.697672753081363],
              [126.851293758454759, 37.697492480727654],
              [126.851696867201113, 37.697067647865595],
              [126.851907608669649, 37.696881973139497],
              [126.852111751661042, 37.696688768181907],
              [126.852490782509932, 37.696330580962943],
              [126.852660895627892, 37.69622386828884],
              [126.852890051610657, 37.696102472387473],
              [126.853128598719877, 37.695979917979685],
              [126.853243671711311, 37.695913281726916],
              [126.853311706504158, 37.695889969926533],
              [126.853385488826348, 37.695864690471851],
              [126.853576542794116, 37.695800493085407],
              [126.85373154292212, 37.695749215634621],
              [126.854277785420621, 37.695543261859122],
              [126.854707461998345, 37.695339232859986],
              [126.854935037972581, 37.69519513621379],
              [126.855263498935926, 37.694989204848135],
              [126.855414734989168, 37.694887870383049],
              [126.855678346808347, 37.69465908092041],
              [126.855889758617508, 37.694278853965244],
              [126.855972218435483, 37.694088680072497],
              [126.85598458483102, 37.693861745945206],
              [126.855992329714539, 37.693719578482572],
              [126.856008060871886, 37.693444304957751],
              [126.856023202621813, 37.693267330945723],
              [126.856070451141662, 37.69273091624644],
              [126.855923302365568, 37.692553361441497],
              [126.855771827841693, 37.692367989981769],
              [126.855745269933024, 37.692337258501254],
              [126.855598135523636, 37.692167003175726],
              [126.855222436506224, 37.691541012432317],
              [126.855083702478268, 37.691304645289087],
              [126.855074084900281, 37.691290944859851],
              [126.854975747929615, 37.691587304537791],
              [126.854890530826637, 37.69167918700397],
              [126.854805321357787, 37.691653134070236],
              [126.854805191913485, 37.691603667867447],
              [126.85467191325462, 37.691517461628564],
              [126.854351598340145, 37.691080075526401],
              [126.854251656372199, 37.690943604291554],
              [126.853952411000051, 37.690534986412779],
              [126.853733909403005, 37.690236619368804],
              [126.853431771028184, 37.689824036492034],
              [126.853431481773526, 37.689823648238502],
              [126.853876973031021, 37.689702147273735],
              [126.853715609292834, 37.689426678570385],
              [126.853703346250413, 37.689391137089345],
              [126.853659962466793, 37.689257648611452],
              [126.853655541476272, 37.689141329367182],
              [126.853659672981792, 37.689117457936838],
              [126.85373685194196, 37.688675539477707],
              [126.853747743956063, 37.688614196528029],
              [126.853772466291943, 37.688438088174578],
              [126.853839053960286, 37.687963361070217],
              [126.853404426116839, 37.688225809167349],
              [126.853309702766992, 37.688253078447666],
              [126.852924462025655, 37.688363954167755],
              [126.852837990458283, 37.688404298774813],
              [126.852767627204386, 37.688434776615303],
              [126.852760057508362, 37.688438054641892],
              [126.85268381800347, 37.688511588833705],
              [126.852608237314953, 37.688604562723391],
              [126.85246779853324, 37.688692619745368],
              [126.852411056363081, 37.688728198842426],
              [126.852357492248785, 37.688736638501105],
              [126.852213463550981, 37.688759329577337],
              [126.85219986091019, 37.688753494498386],
              [126.852113623514086, 37.688716501828274],
              [126.852073189510705, 37.688705823425025],
              [126.852008397989835, 37.68873812054828],
              [126.85200799684624, 37.688742380700816],
              [126.852002033035589, 37.688804322903877],
              [126.851997152595388, 37.688844601450256],
              [126.851989037839928, 37.688911423312071],
              [126.851984639001941, 37.688947708938578],
              [126.851981881069392, 37.688970339524595],
              [126.851959139537641, 37.689122160489937],
              [126.851928149141997, 37.689226119126559],
              [126.851918352125367, 37.689327493864781],
              [126.85186288181724, 37.689444126642435],
              [126.851832373662603, 37.68947753143604],
              [126.851780906940547, 37.689519099666775],
              [126.851641504049212, 37.689496201311414],
              [126.851569018234727, 37.689513271293841],
              [126.851549901457474, 37.689548024838736],
              [126.85151031762598, 37.68962957427906],
              [126.851457155278126, 37.689653266117674],
              [126.851284675369271, 37.689635972817058],
              [126.851123614913746, 37.689653021213012],
              [126.851114432873601, 37.689654397482492],
              [126.851042072409925, 37.689665251405245],
              [126.850843583433644, 37.689727795913072],
              [126.850814976363296, 37.689741080760044],
              [126.850677896785939, 37.689804749892687],
              [126.850577807043962, 37.689805064897342],
              [126.850505841863452, 37.689780844786952],
              [126.850342131154861, 37.68969209285909],
              [126.85032131938911, 37.689680808108186],
              [126.850305716354129, 37.689688322099371],
              [126.85023689730869, 37.689721468528759],
              [126.850135453172726, 37.689813833204376],
              [126.850110015978757, 37.689813480307571],
              [126.849959000979567, 37.689811379788409],
              [126.849834380665484, 37.689859233505743],
              [126.84961663097188, 37.689881296793864],
              [126.84944890753701, 37.69000529766938],
              [126.849284344479202, 37.690127364377339],
              [126.849283834242229, 37.690140066607107],
              [126.849277209814332, 37.690305241606495],
              [126.849125665889474, 37.690430505711973],
              [126.84912636046873, 37.69040034948587],
              [126.849085553624178, 37.69022595354825],
              [126.849060011624942, 37.690205655376872],
              [126.849049441586772, 37.690197256188938],
              [126.848985796600402, 37.689895969404709],
              [126.848961397597591, 37.689787109940873],
              [126.848928478146405, 37.689640930125229],
              [126.848905112722463, 37.689535036296306],
              [126.848884682918893, 37.689444940832857],
              [126.848862110171154, 37.689340372708443],
              [126.84884265567527, 37.689250430437426],
              [126.848797290609951, 37.689037383009484],
              [126.848758319640623, 37.688867219760326],
              [126.848702188548629, 37.688617686762527],
              [126.848652024827032, 37.688385135166577],
              [126.848643399072998, 37.688339130866702],
              [126.848635101654338, 37.688299936973323],
              [126.848431060151441, 37.687367789924075],
              [126.848235794445017, 37.686485101438343],
              [126.848226208742403, 37.686486764578682],
              [126.848209514020468, 37.68643388180454],
              [126.848223459061231, 37.686357479797969],
              [126.848199466790803, 37.686187751500569],
              [126.848143527806954, 37.686156170893369],
              [126.848148306254572, 37.686135018012003],
              [126.848148499959208, 37.686134267379096],
              [126.848158025988653, 37.686091988484606],
              [126.848119314564457, 37.686066917807452],
              [126.848076613559854, 37.686036626229765],
              [126.847889273066286, 37.686001837675377],
              [126.847813685802222, 37.68598767629399],
              [126.847663548203471, 37.68594249356061],
              [126.847434211164185, 37.685844736258872],
              [126.847075183358498, 37.685613158511785],
              [126.847030151948445, 37.685591324289355],
              [126.846432436630906, 37.68562717390369],
              [126.846283454369313, 37.685641392122378],
              [126.845973075144656, 37.685671012883724],
              [126.845518044804535, 37.686005076749275],
              [126.845249213393558, 37.686127063934968],
              [126.84511665239161, 37.686191461077144],
              [126.844944691325011, 37.686277707213385],
              [126.84449017614881, 37.686488119200298],
              [126.844268992559265, 37.686644161378055],
              [126.844248698910533, 37.686658476011402],
              [126.844031237299518, 37.686552265149928],
              [126.844007034156888, 37.686540443962777],
              [126.843915323508455, 37.686484100410205],
              [126.843895218058123, 37.686411904339906],
              [126.84377969477103, 37.686282723870363],
              [126.84352069461184, 37.685894176699684],
              [126.843423075925969, 37.685756042655449],
              [126.843233301593742, 37.685487498438505],
              [126.843212557704206, 37.685475973960791],
              [126.842989150293121, 37.685380550247778],
              [126.842536389625323, 37.685156571818034],
              [126.842343096560782, 37.685109045240253],
              [126.842038751423559, 37.68536092584494],
              [126.841857443495002, 37.68546474882006],
              [126.84170547641304, 37.685551766993875],
              [126.841614426970779, 37.685420830042666],
              [126.841584634169777, 37.685377987861102],
              [126.841307130564317, 37.684975832497237],
              [126.841105786664869, 37.684745459772408],
              [126.840433967608377, 37.684030452089523],
              [126.840081015494221, 37.683557673130188],
              [126.84001989499221, 37.68345973580427],
              [126.840282857852415, 37.682978896394026],
              [126.840306942195909, 37.682809548068661],
              [126.839698469387969, 37.682311325203976],
              [126.839360078608138, 37.682591007105309],
              [126.839292487892223, 37.682628177581662],
              [126.839108772979401, 37.682674089099322],
              [126.838676209158947, 37.682792194563028],
              [126.838380245517527, 37.682873001987744],
              [126.838163525129744, 37.682928706304644],
              [126.837866896980159, 37.682921988410726],
              [126.837062946736154, 37.682900734237897],
              [126.836784531196272, 37.682901930473875],
              [126.836665900779366, 37.682902440391238],
              [126.836010952041633, 37.682932282048306],
              [126.835864965233057, 37.682960342003447],
              [126.835498583512404, 37.683030109978993],
              [126.835443791595623, 37.683039641877222],
              [126.834748545050303, 37.683160565174155],
              [126.834528063785811, 37.683034369894841],
              [126.834469004590261, 37.683000565077911],
              [126.834323854304742, 37.682918190522209],
              [126.83422852218311, 37.68286408673341],
              [126.834251907718794, 37.682698072447486],
              [126.834325636840731, 37.682226337691077],
              [126.834361190835963, 37.681991776111019],
              [126.834385788774668, 37.681840536797594],
              [126.834415890616782, 37.681657405468115],
              [126.834447164880814, 37.681467172905307],
              [126.833879414266605, 37.680823523978006],
              [126.833813631651736, 37.680746904041548],
              [126.83323743152998, 37.680075795910859],
              [126.833239053313108, 37.679993175344471],
              [126.833244473669126, 37.679717130585288],
              [126.833248231992627, 37.679160112207235],
              [126.833248736909852, 37.679085197038944],
              [126.833362527807338, 37.678844801116263],
              [126.833472401771061, 37.678579982297499],
              [126.833515681560613, 37.678489676300906],
              [126.833509679389763, 37.678285687229106],
              [126.833553738656832, 37.677799407012337],
              [126.833328621299913, 37.67779421932719],
              [126.833069427371257, 37.677649468606582],
              [126.832873158382753, 37.677539855368082],
              [126.832539577345699, 37.677381896496215],
              [126.832228616682286, 37.677237106920394],
              [126.831897537111786, 37.67702129900848],
              [126.831640576007729, 37.676814335802007],
              [126.831275806391417, 37.676834036830989],
              [126.831210122238517, 37.676837585130492],
              [126.831101161458676, 37.676843471145069],
              [126.830383292028543, 37.67678630045522],
              [126.829754212047618, 37.676765800156502],
              [126.829688809204768, 37.676763667261731],
              [126.829616695342622, 37.676790895565041],
              [126.829511705991976, 37.676830532025988],
              [126.829348352683226, 37.676980755229749],
              [126.828768504393437, 37.676846474300568],
              [126.82857396309636, 37.676806817300204],
              [126.828216529662058, 37.676815969087123],
              [126.828032910110153, 37.676808905786679],
              [126.82787560085842, 37.676671723821492],
              [126.827421803774897, 37.676275981120092],
              [126.827077118622853, 37.675973286435735],
              [126.826959965120196, 37.67565849394321],
              [126.827243553219645, 37.675291930147402],
              [126.827436568980488, 37.674817422160459],
              [126.82739644817579, 37.674760339641246],
              [126.8271174424668, 37.674363393163823],
              [126.82714327329802, 37.674198628867373],
              [126.827162802399471, 37.674074067324611],
              [126.827152726020131, 37.674070169297089],
              [126.826656566331224, 37.673878123586086],
              [126.826314631917143, 37.673829107376584],
              [126.826015135211634, 37.673756287175458],
              [126.825824634322217, 37.673598108397215],
              [126.825737931963531, 37.67351212576316],
              [126.825290664324143, 37.67354838973089],
              [126.824609978899389, 37.673160908200423],
              [126.824320425584787, 37.672945159986966],
              [126.824004650960546, 37.672708341038771],
              [126.823324099211462, 37.672626760484903],
              [126.823130455433656, 37.67274361822615],
              [126.823129765625168, 37.67274403338299],
              [126.82312545862348, 37.672743064978484],
              [126.823065409657431, 37.672729553793793],
              [126.822960327405553, 37.67270591096959],
              [126.822326150033362, 37.67237926485106],
              [126.822309127538375, 37.672260293198342],
              [126.821694542839694, 37.672090152233416],
              [126.821651257324319, 37.672078169844255],
              [126.820997920295511, 37.671598264199105],
              [126.820957660913308, 37.67156869070751],
              [126.82066385490289, 37.670964140841072],
              [126.820682976224234, 37.670604402644976],
              [126.820691256909029, 37.670555050553268],
              [126.82086233949218, 37.670304573504048],
              [126.820968451516279, 37.669875151755036],
              [126.820924576131105, 37.669781470554035],
              [126.820877791204055, 37.66968157507528],
              [126.820845903077796, 37.669641080622284],
              [126.82079009326236, 37.669570216177569],
              [126.820732588775428, 37.669497193188391],
              [126.820732786352451, 37.669480741548355],
              [126.820738941254291, 37.66942026927692],
              [126.820792394156143, 37.669092570677371],
              [126.820808928359682, 37.668991315545533],
              [126.820796454032802, 37.668975809428431],
              [126.820798701529341, 37.668969325623152],
              [126.820800914254932, 37.668962924539464],
              [126.820804489191772, 37.66895262135391],
              [126.820868747510062, 37.668773597126268],
              [126.820898869323443, 37.668695809587405],
              [126.820928171363434, 37.668624856571306],
              [126.820973196393325, 37.668509529917763],
              [126.820987173133375, 37.6684345534701],
              [126.821015555899507, 37.668293975012425],
              [126.821066846564619, 37.668007237995333],
              [126.820967528373572, 37.667743272107153],
              [126.820954909103833, 37.667709718844549],
              [126.820945985098263, 37.667685999578481],
              [126.820737799728633, 37.66750712715406],
              [126.820636997580976, 37.667471834763575],
              [126.820365222790798, 37.667376679086793],
              [126.820373051358217, 37.667341076799261],
              [126.820382852925974, 37.667296512289425],
              [126.820383647830994, 37.667292898951786],
              [126.82041026319402, 37.667171885405203],
              [126.820431054164814, 37.667080680257385],
              [126.820487328694597, 37.666833824235958],
              [126.820493893346281, 37.666805012447682],
              [126.820532824310334, 37.666768069691493],
              [126.820548036203832, 37.666753631569975],
              [126.820564852708429, 37.666734319311317],
              [126.82060803746127, 37.666684728404654],
              [126.820738139185593, 37.666540789840639],
              [126.820867857041875, 37.666321332302935],
              [126.82104987374646, 37.666015504255256],
              [126.821257412552711, 37.665878037081868],
              [126.821428166888566, 37.665771172420861],
              [126.821732214734084, 37.665568619682169],
              [126.821811932893723, 37.665461912315557],
              [126.821920275198721, 37.665273738231662],
              [126.821889834276959, 37.665028700197837],
              [126.821883902461948, 37.664954631237642],
              [126.821855668126716, 37.664720318120168],
              [126.821938288818615, 37.664613246075966],
              [126.822030859653125, 37.664336896465812],
              [126.821945975212159, 37.664132615251624],
              [126.821941231973739, 37.66412120244194],
              [126.821961147956031, 37.664096589969887],
              [126.821966813853194, 37.664089584991473],
              [126.822060044656666, 37.663974325809626],
              [126.822380720665748, 37.663787265118494],
              [126.822607944507055, 37.663654718043389],
              [126.82280563073887, 37.663539396525842],
              [126.822842488981394, 37.663448859075046],
              [126.822858576284176, 37.663409321949679],
              [126.822846393813236, 37.663384707113764],
              [126.822829816883981, 37.663350714893575],
              [126.822810001016933, 37.663314690514049],
              [126.822804878990368, 37.663304277836552],
              [126.822782237778199, 37.663265500806538],
              [126.822772392689089, 37.663235457178835],
              [126.822761198168607, 37.663201319192069],
              [126.822738609428512, 37.663173494461589],
              [126.822727093036619, 37.663159310872878],
              [126.822654155765406, 37.663191741758688],
              [126.822373947248863, 37.663316326893714],
              [126.821735243813322, 37.663434284300124],
              [126.821680968632123, 37.663447737226875],
              [126.820545633030136, 37.663729154451282],
              [126.819814193539855, 37.663765339737957],
              [126.819406349377843, 37.663785514844029],
              [126.818823322535593, 37.664006038446679],
              [126.818740673265751, 37.664037298573966],
              [126.818469361863492, 37.664139612556369],
              [126.818452382271914, 37.664146014802547],
              [126.818157011412538, 37.664257400580432],
              [126.818143910830599, 37.664252989473972],
              [126.818048639361763, 37.664220922044045],
              [126.818012540370077, 37.664223213601375],
              [126.817972463895799, 37.664225758855387],
              [126.817412328326597, 37.6642808361728],
              [126.817342386095902, 37.664103236270414],
              [126.817300352941913, 37.664097585037666],
              [126.817292799921034, 37.664039973680914],
              [126.817251741240483, 37.663726667921942],
              [126.816551306230707, 37.663367435209793],
              [126.816340739058148, 37.663271458599972],
              [126.816296040191204, 37.663266500902786],
              [126.815814822181537, 37.663213120055801],
              [126.815771327386784, 37.663208296231176],
              [126.815524932387405, 37.663250867544072],
              [126.815437582716797, 37.663183020808198],
              [126.815039499324726, 37.662908298620316],
              [126.814946977962848, 37.662778907841393],
              [126.814930695113915, 37.662756132321881],
              [126.814908309342925, 37.662724814645145],
              [126.814847467939956, 37.662635486022197],
              [126.814811271706077, 37.662589100867478],
              [126.814770517871438, 37.662426879739463],
              [126.814760288937507, 37.662386166938298],
              [126.814744402078887, 37.662322920580451],
              [126.814744360898644, 37.662322343492988],
              [126.814742225673712, 37.662291049691405],
              [126.814725219814051, 37.662060934467185],
              [126.814721059484455, 37.662004624330166],
              [126.81470948495344, 37.66184784544253],
              [126.814631217727836, 37.661756854359972],
              [126.814625432664485, 37.661750129305702],
              [126.814552255246923, 37.661665047991697],
              [126.81453917307752, 37.661649829700863],
              [126.814500172656807, 37.661563731975349],
              [126.814434480183806, 37.66141867302273],
              [126.814384283364703, 37.660960360098841],
              [126.814321451504227, 37.660510538068444],
              [126.814260479720346, 37.660480762548175],
              [126.814203972912068, 37.660453185150942],
              [126.814158544033091, 37.660431036829415],
              [126.814083615399994, 37.660394530085135],
              [126.814072874870462, 37.660388170966741],
              [126.813457706673177, 37.660026405250029],
              [126.813254580166628, 37.659906420872453],
              [126.813233396793493, 37.659893909719273],
              [126.813169125149059, 37.659877473870992],
              [126.812994558165045, 37.659842673780261],
              [126.812977632890622, 37.659839301586288],
              [126.812876379645232, 37.659805270814729],
              [126.812698199338627, 37.659761780287617],
              [126.812448900677012, 37.659700928244995],
              [126.812133181281482, 37.659753066101906],
              [126.812010140881384, 37.659773383650027],
              [126.811569779122735, 37.659680369933739],
              [126.811568912500775, 37.659680171992129],
              [126.811438362278423, 37.659619704369142],
              [126.811207523580535, 37.659463022173732],
              [126.811133389467827, 37.659402140946554],
              [126.811109254442627, 37.659361766622027],
              [126.811075986377162, 37.659354847532448],
              [126.811113586882669, 37.659231790127272],
              [126.811094390185744, 37.65915869046659],
              [126.811002022190593, 37.659028170796198],
              [126.810909119541336, 37.658885124008471],
              [126.810836376136649, 37.658790767369261],
              [126.810829681970532, 37.658781757363649],
              [126.810805541264841, 37.658749272244684],
              [126.810783802239186, 37.658697611028693],
              [126.810770954801612, 37.658624520468074],
              [126.810770314837526, 37.658535792029767],
              [126.810770301553518, 37.658534530997493],
              [126.810770203260006, 37.658520636487424],
              [126.810765793004364, 37.658517208213226],
              [126.810711980060105, 37.65846441227233],
              [126.810693654242996, 37.658408523035085],
              [126.810697193213542, 37.65835438125054],
              [126.810692014884125, 37.658207424078554],
              [126.810737673127548, 37.658168575150022],
              [126.810757076988622, 37.658202033979869],
              [126.810887050453218, 37.658207559579694],
              [126.811012899991027, 37.658186037399318],
              [126.811046250887131, 37.658107626675971],
              [126.81112261760633, 37.657928086940856],
              [126.811257157801165, 37.657611773540417],
              [126.81139559291104, 37.657587039999171],
              [126.811808974409232, 37.657526404747003],
              [126.811867168021351, 37.657405037308415],
              [126.811882268604521, 37.657373149961693],
              [126.811983691156641, 37.657164711471111],
              [126.811997981345598, 37.657139056556815],
              [126.812200718308119, 37.656724850158255],
              [126.812254515602575, 37.656625939550096],
              [126.812402175409915, 37.656645619163321],
              [126.812754644959995, 37.65667860134446],
              [126.812763688656688, 37.6566794444973],
              [126.813055899106033, 37.656667861037661],
              [126.813078993090798, 37.656666945481263],
              [126.813108822627512, 37.656665758185355],
              [126.813175931603354, 37.656700634591736],
              [126.813255468865336, 37.656741971015343],
              [126.813296980187602, 37.656763545270458],
              [126.813489354087821, 37.65683342630193],
              [126.813648791742679, 37.656934120097596],
              [126.813665412732632, 37.656944616307953],
              [126.813813479586344, 37.657052968985461],
              [126.813855485995418, 37.65706500809172],
              [126.814008274024729, 37.657108806171394],
              [126.814051838649974, 37.657121290511597],
              [126.814322066615162, 37.657198744276627],
              [126.814362687743909, 37.657132136251967],
              [126.814368111838547, 37.657138992620943],
              [126.814573502911557, 37.657399746561936],
              [126.814595312721679, 37.657427429533925],
              [126.814849633324656, 37.657365808786032],
              [126.815027891723872, 37.657322575426583],
              [126.815064943595559, 37.657275935141676],
              [126.815171789438267, 37.657106297707259],
              [126.815193179844286, 37.657072273348568],
              [126.815236492079663, 37.657019185211134],
              [126.815213976348815, 37.656977499760785],
              [126.815213494809299, 37.656976604652385],
              [126.815206825139313, 37.65696427004103],
              [126.815366609549514, 37.656921185789621],
              [126.815379124684185, 37.656928698874935],
              [126.815380378436089, 37.656929453351019],
              [126.815456567353849, 37.656975204287001],
              [126.815456692814237, 37.657011424806903],
              [126.815456702668982, 37.657013314945075],
              [126.81545687145892, 37.657039383178407],
              [126.815628625361668, 37.657054492756252],
              [126.815716478792723, 37.657039151712695],
              [126.815843574440265, 37.657066542955043],
              [126.815856257244732, 37.657070617501645],
              [126.815927371892712, 37.6570931638053],
              [126.816019116345544, 37.657112408256303],
              [126.816058115049458, 37.657106073631688],
              [126.816130552463946, 37.657093681264186],
              [126.816105227957578, 37.657028148633941],
              [126.816147609954797, 37.656966400231333],
              [126.816300509503975, 37.656911375489209],
              [126.816361417198962, 37.656908101565769],
              [126.816414353981486, 37.657016218687424],
              [126.816640327745262, 37.656398371866231],
              [126.816830453896529, 37.656321095621763],
              [126.816855880991923, 37.656258424943779],
              [126.816892511057432, 37.656248483635771],
              [126.816845668216033, 37.656127319255134],
              [126.816986496333456, 37.655958427633962],
              [126.816975523962483, 37.655811462318049],
              [126.816799121416977, 37.655693752331935],
              [126.81676588318966, 37.655671571958656],
              [126.816113892239784, 37.655320699251142],
              [126.815993291402151, 37.655224667593338],
              [126.815925807855976, 37.655170933958381],
              [126.815832229536497, 37.65470588452127],
              [126.815738796419481, 37.654408956662081],
              [126.815724404374336, 37.654362894705415],
              [126.815730066686825, 37.654273246458324],
              [126.81575036392519, 37.653951909666674],
              [126.81574926773898, 37.653662696426096],
              [126.815619829986048, 37.65321894165492],
              [126.815567259561732, 37.653032538809114],
              [126.815947014821617, 37.652724354384127],
              [126.815953868039401, 37.652688353670335],
              [126.816049980324223, 37.652148974708545],
              [126.816181507037214, 37.651858166972545],
              [126.816232521673399, 37.65174526322847],
              [126.816327363487261, 37.651227372327853],
              [126.816374121925335, 37.65087777517023],
              [126.816544052609856, 37.650804073157474],
              [126.816607379448229, 37.650629293243973],
              [126.816928600825449, 37.650595920826341],
              [126.816929048688152, 37.650595292490642],
              [126.817023164827248, 37.650463408126392],
              [126.817061870836994, 37.650409174023714],
              [126.817045497201534, 37.650248236481829],
              [126.817395082911105, 37.650090147198043],
              [126.817519824832388, 37.650032868538183],
              [126.817620697041406, 37.649986789467796],
              [126.817828214159093, 37.649743353216365],
              [126.817802401186938, 37.64940118534718],
              [126.817791149214642, 37.649232398090099],
              [126.817088958442653, 37.649408296899246],
              [126.816922796471474, 37.649291985686581],
              [126.816858341650416, 37.649246330659061],
              [126.816471720943014, 37.648991694195487],
              [126.81574926480333, 37.648910912881846],
              [126.815673130564861, 37.648905782544269],
              [126.815494717430951, 37.648586476102345],
              [126.815483295385192, 37.648441491694605],
              [126.815666854911584, 37.648264380458784],
              [126.815691502171376, 37.648239550355356],
              [126.815627276907392, 37.648185118858116],
              [126.815519855811814, 37.648094133931018],
              [126.815987765309075, 37.64766735725226],
              [126.816111716527104, 37.647580518225354],
              [126.816293671648296, 37.647453136439232],
              [126.816329370121849, 37.647223082955954],
              [126.816066496156068, 37.646901652773927],
              [126.816102836423696, 37.646778185066296],
              [126.816163042470421, 37.646670524372766],
              [126.81617670759708, 37.646643426750899],
              [126.81622746628463, 37.646554577850928],
              [126.816230373399719, 37.646550050206486],
              [126.816331891400012, 37.646392029223875],
              [126.816350826557255, 37.646339794344961],
              [126.816377981422505, 37.6462648819168],
              [126.816454797567545, 37.646117639961446],
              [126.816500542632298, 37.646057039242265],
              [126.816483346382952, 37.645997096845754],
              [126.816346597738075, 37.645583877528438],
              [126.816287055183864, 37.645284658417317],
              [126.816275069856559, 37.645271485448077],
              [126.81603336618717, 37.645006128647879],
              [126.816003530669903, 37.644973376262648],
              [126.81578499437218, 37.644914290593292],
              [126.815612417211597, 37.644863464129749],
              [126.815557571865881, 37.64487518948561],
              [126.815183817584156, 37.644950550876018],
              [126.815147249540288, 37.644958517243893],
              [126.81508086913648, 37.64497297854242],
              [126.814685815024973, 37.645020205017467],
              [126.814094871557586, 37.644834206660519],
              [126.81404256474211, 37.64481774351966],
              [126.813996789094446, 37.64477505003341],
              [126.813884077770894, 37.644669909020351],
              [126.813641877675181, 37.644446950125975],
              [126.813041592379903, 37.644597671534967],
              [126.812854453404242, 37.644849645683969],
              [126.812793283371235, 37.644932153848501],
              [126.812788393631081, 37.644938644668848],
              [126.812775319909079, 37.644955833445088],
              [126.813124864559427, 37.645274246049851],
              [126.813149633977986, 37.645296818907234],
              [126.813247537611204, 37.645385001553443],
              [126.813247252828077, 37.645388354637689],
              [126.813231874875683, 37.645570118813616],
              [126.813224167321749, 37.645660922374972],
              [126.813232977671277, 37.645683394193298],
              [126.813271277255879, 37.645781053384496],
              [126.813352825178441, 37.645988992980186],
              [126.813330422257366, 37.646030942868009],
              [126.813235361420539, 37.646208463917056],
              [126.813146556863572, 37.646375814674272],
              [126.813093063964075, 37.64647069061941],
              [126.813068667735692, 37.646529035713129],
              [126.812865978662401, 37.64701217374455],
              [126.812720124830733, 37.647334039904777],
              [126.812686106420813, 37.647518952740356],
              [126.81268201327741, 37.64752840815337],
              [126.812630712733466, 37.64764698442459],
              [126.812319647080201, 37.64783704248368],
              [126.811887001649978, 37.648025692059186],
              [126.811872934125518, 37.648031827357336],
              [126.811719652904756, 37.647976180205561],
              [126.811617311581443, 37.64793889722543],
              [126.811547885799655, 37.647893627610408],
              [126.811488222755798, 37.64783763415825],
              [126.81141073942517, 37.647766863048737],
              [126.811287679184403, 37.647652250239297],
              [126.811254963891116, 37.647608989309639],
              [126.811153016257066, 37.647479453762791],
              [126.811133103124149, 37.647454157099496],
              [126.81097682683172, 37.647325712912171],
              [126.810793474761127, 37.647229182268575],
              [126.810760216599263, 37.647211965489873],
              [126.810619783453618, 37.647139383026946],
              [126.810615196680956, 37.647023779007057],
              [126.810540937013613, 37.646990115785741],
              [126.810432910871725, 37.646944343202229],
              [126.810301458083842, 37.646900494895085],
              [126.810165641046254, 37.64685801978154],
              [126.810024747102702, 37.646814552200524],
              [126.809920133730614, 37.64678578642711],
              [126.809752364216351, 37.646720572709789],
              [126.809652822946234, 37.646679398058474],
              [126.809367688610962, 37.646560690852844],
              [126.809282336193391, 37.646523818970358],
              [126.80896686525108, 37.646429294248563],
              [126.808864512546506, 37.646405361082437],
              [126.808744285138303, 37.646379706108291],
              [126.808739161443995, 37.646378613420133],
              [126.808670236775924, 37.646365231309652],
              [126.808594897429316, 37.646351969295573],
              [126.808536318626224, 37.64633797985897],
              [126.808460623947695, 37.646330038015066],
              [126.808389829655539, 37.646320011239091],
              [126.808354378937139, 37.646314908289725],
              [126.808251568113789, 37.646272698394426],
              [126.808164139321491, 37.646237480984851],
              [126.807961601524113, 37.646140347614477],
              [126.807880906205384, 37.64605602822197],
              [126.807738689416766, 37.645908324253327],
              [126.807600863049259, 37.645705828044477],
              [126.807598836280633, 37.645702898551683],
              [126.80758565218342, 37.645683846423104],
              [126.807568750091818, 37.645659423091388],
              [126.807530197447335, 37.645604485317634],
              [126.80746677075453, 37.645473354707789],
              [126.807403632359524, 37.645342817025345],
              [126.807322768275313, 37.645284031765406],
              [126.80707272975792, 37.645098377985434],
              [126.806984419880479, 37.645027414185478],
              [126.806658237366264, 37.6447546035809],
              [126.806655666501555, 37.644741804040827],
              [126.806637767439, 37.644653116366399],
              [126.806597231301723, 37.644599265343864],
              [126.80649712290176, 37.644470598236474],
              [126.806441618282165, 37.644399259936705],
              [126.80629530736168, 37.644237925501962],
              [126.806171006891319, 37.644108832838633],
              [126.806093331850931, 37.644059624969991],
              [126.806003819176325, 37.644002918670395],
              [126.805994058691269, 37.643995836303738],
              [126.805954367195042, 37.643970438631747],
              [126.805919763628324, 37.643948495926303],
              [126.805815555991387, 37.64384043911295],
              [126.805806514046907, 37.643831423104338],
              [126.805779331596284, 37.643803395899319],
              [126.80577422993386, 37.643798132769241],
              [126.805747293149764, 37.643788896079371],
              [126.805705468786982, 37.643774559483781],
              [126.805458842677737, 37.643690006256158],
              [126.805109143290707, 37.643643536718912],
              [126.804983012570986, 37.643626775307119],
              [126.804743418175647, 37.643595807185939],
              [126.804531105940342, 37.643534718411715],
              [126.804394176562582, 37.643459989618698],
              [126.804327316179354, 37.643423504404318],
              [126.804225986494316, 37.643344938378277],
              [126.804188364332788, 37.64331786593349],
              [126.80409180408796, 37.643309469242659],
              [126.80398665019905, 37.643329080102667],
              [126.803900452432373, 37.643306346583245],
              [126.803842949997517, 37.643292878535149],
              [126.803645169549938, 37.643303439337473],
              [126.803454608297329, 37.643365225223882],
              [126.803447713167216, 37.643367295421974],
              [126.803443812124058, 37.643368460338486],
              [126.803386862718952, 37.643386687815834],
              [126.803357834502606, 37.643395111463917],
              [126.803302095249165, 37.643411559614862],
              [126.803300252780588, 37.643412102149092],
              [126.803123790473549, 37.643469045004736],
              [126.803094030282793, 37.643478646805448],
              [126.802886394228878, 37.643545646028478],
              [126.80286098497983, 37.64355384667877],
              [126.802852317169169, 37.643575184861959],
              [126.802480522907501, 37.644223354465609],
              [126.802173654524864, 37.644758379283871],
              [126.802127325953307, 37.644839121940116],
              [126.802087573917291, 37.64490848276489],
              [126.802035691964591, 37.644884449279459],
              [126.801982660196899, 37.644859859158245],
              [126.801676799830133, 37.644718227014792],
              [126.801586026885346, 37.644676182627087],
              [126.801427872023311, 37.644602921898809],
              [126.801351511865576, 37.644567549576685],
              [126.801136998983722, 37.644458853388919],
              [126.800814584984295, 37.64430593998226],
              [126.8005373872017, 37.644231200928061],
              [126.800525652542049, 37.644229325992832],
              [126.800428343330765, 37.64421380948621],
              [126.800157189355829, 37.644170567112724],
              [126.800138228987862, 37.644163696479779],
              [126.800055183536898, 37.644137360927409],
              [126.800014271696014, 37.644127102633455],
              [126.799928726199227, 37.64411401068346],
              [126.799528583219342, 37.644050347900837],
              [126.799297889421624, 37.644014867165602],
              [126.799086175947721, 37.644109836990424],
              [126.798923358338911, 37.644092268868228],
              [126.798512099944901, 37.644047901601098],
              [126.798144353013015, 37.643966655289056],
              [126.798079143311654, 37.643941259087534],
              [126.797878370245428, 37.643863063942973],
              [126.797779492305054, 37.643723997295062],
              [126.797721316957364, 37.643605708711171],
              [126.797638614302912, 37.64343526207778],
              [126.797597558104869, 37.643346518107982],
              [126.797597948563833, 37.64324943846303],
              [126.797628429757779, 37.643208832175574],
              [126.797739775208598, 37.64306050472198],
              [126.797765307101116, 37.643039197000107],
              [126.797726546004768, 37.642951204918404],
              [126.7976502814372, 37.642778076395977],
              [126.797615834124272, 37.642699892063966],
              [126.797611116858974, 37.642689145392268],
              [126.797565039450191, 37.642584182763855],
              [126.797434790982052, 37.642635772487978],
              [126.797386227760484, 37.642638914622331],
              [126.797266616250596, 37.642646651231715],
              [126.797180390454969, 37.642638144603801],
              [126.797173115334417, 37.642622066647164],
              [126.797013990243556, 37.642270319196527],
              [126.797013566102876, 37.642269380189866],
              [126.797057513202162, 37.642254368160913],
              [126.797186648510234, 37.642169297814689],
              [126.797197468977373, 37.642162170583774],
              [126.79727590003921, 37.642110676263741],
              [126.79733360413249, 37.642065568728491],
              [126.797304998667784, 37.642007196923998],
              [126.797231486832786, 37.641857663236642],
              [126.797226905733453, 37.641848355878416],
              [126.797202102105302, 37.641844364859054],
              [126.79718179885549, 37.641802786443463],
              [126.79702782192237, 37.641487424688719],
              [126.796999240132152, 37.641444630592439],
              [126.79699776257992, 37.641442413535117],
              [126.796975281080805, 37.64140876730491],
              [126.796974785990017, 37.641408020759293],
              [126.796866506931252, 37.641245917036962],
              [126.79685512067023, 37.641236480774246],
              [126.796852455211081, 37.64123426835225],
              [126.79682023552111, 37.641207552944572],
              [126.796818098642277, 37.641205780816257],
              [126.796715772450128, 37.641120939989946],
              [126.796120237511815, 37.640839439595659],
              [126.795903579923959, 37.640737029493906],
              [126.79588276091728, 37.640727506195375],
              [126.795520367357042, 37.640561749968391],
              [126.795405349482991, 37.640540925628287],
              [126.795224348202396, 37.640429457975991],
              [126.795168003889032, 37.640327044203154],
              [126.795157506737837, 37.640231457878272],
              [126.795156032867254, 37.640162037826109],
              [126.795124538516461, 37.640075821533863],
              [126.795065413957644, 37.639625696702197],
              [126.795064012151826, 37.639611874576374],
              [126.795001684622562, 37.639523199053642],
              [126.794984921521916, 37.639501320997681],
              [126.794921414962758, 37.639411732551437],
              [126.794887511379955, 37.639363904663284],
              [126.794864990753794, 37.639333484545361],
              [126.79480383301977, 37.639289814699424],
              [126.794727805512153, 37.639233229133708],
              [126.794660997169245, 37.639198494547003],
              [126.794634757210133, 37.638936894903487],
              [126.794629264262426, 37.63888790012917],
              [126.794844434099502, 37.638893218951722],
              [126.794912852327897, 37.638894909560783],
              [126.794987189275645, 37.638856785089473],
              [126.794979537165247, 37.638781497409219],
              [126.794973970619125, 37.638733671231265],
              [126.79491702239666, 37.638244026091137],
              [126.79491662658495, 37.638240574343385],
              [126.794905677901596, 37.638147133131326],
              [126.794902360165068, 37.638118493434682],
              [126.79489598402526, 37.638063636557732],
              [126.794888827891128, 37.638002086262958],
              [126.794886415649657, 37.63799996273827],
              [126.794885049828082, 37.637998769328831],
              [126.794828401325674, 37.637949043377617],
              [126.794796023530182, 37.637921533297487],
              [126.794736171461437, 37.63787067771657],
              [126.794704865881769, 37.63784269451444],
              [126.794699870730611, 37.637835161997771],
              [126.794634347284486, 37.637736343439819],
              [126.79456721759891, 37.637635765762532],
              [126.794530847299669, 37.63747253679486],
              [126.794615152467827, 37.637191049565203],
              [126.794629421383306, 37.637143402341678],
              [126.794608698427851, 37.636976506595062],
              [126.79457632793634, 37.636895890240119],
              [126.794542987440295, 37.6368128642331],
              [126.794436983319812, 37.63655346621703],
              [126.794460866871447, 37.636316193781973],
              [126.794509857104416, 37.636144003156467],
              [126.794533632285862, 37.636060440768134],
              [126.794498077498815, 37.635988568955796],
              [126.794480251253091, 37.635952528199162],
              [126.794442342226745, 37.635875894093168],
              [126.794415109641406, 37.635820843940934],
              [126.79433079560043, 37.63558446113862],
              [126.794311623652121, 37.635511809360843],
              [126.794315282606448, 37.635418293190199],
              [126.794324634784616, 37.635233069359124],
              [126.794377644757745, 37.63507405047671],
              [126.794419059214874, 37.635023838190243],
              [126.794457785052941, 37.634976884336595],
              [126.79449062320279, 37.634941894648179],
              [126.794533019467295, 37.634933230420259],
              [126.794535035089041, 37.634932818848263],
              [126.7945301263414, 37.634885481468942],
              [126.794521851200415, 37.634805569777278],
              [126.794318248861785, 37.632840368788386],
              [126.794311430749715, 37.632774569764052],
              [126.794309949614998, 37.632761132908769],
              [126.794295064113882, 37.632626339384082],
              [126.794291794020438, 37.63260073831016],
              [126.79428647435833, 37.632553739147951],
              [126.794224564574549, 37.632005909787786],
              [126.79421949120254, 37.631964752702764],
              [126.794214139164296, 37.631921425352928],
              [126.794206759922233, 37.631910778877916],
              [126.794210946095404, 37.631908895806603],
              [126.794196822467143, 37.631854271003583],
              [126.794199843515528, 37.631770433038412],
              [126.794201485951049, 37.631724991549675],
              [126.794201616435458, 37.631718864194816],
              [126.794200667430644, 37.631719027967563],
              [126.794172203937705, 37.631723956509333],
              [126.794115025034003, 37.631733846315498],
              [126.794114681269221, 37.631731369209554],
              [126.79410341026913, 37.6316499108719],
              [126.794097254377874, 37.631605429511019],
              [126.794095263313991, 37.631606129648326],
              [126.794095040445498, 37.631604650135422],
              [126.794094815566282, 37.631603143570629],
              [126.794064635916783, 37.631550003615843],
              [126.794036548738987, 37.631502340029556],
              [126.794037284564141, 37.631501828872217],
              [126.794077276354727, 37.631474227907752],
              [126.794082448946952, 37.631470859043283],
              [126.794082495573605, 37.6314706024435],
              [126.794088326077656, 37.63143925575725],
              [126.79409628544208, 37.631400308281641],
              [126.794117258663562, 37.631308401763867],
              [126.794164250169786, 37.631088791065139],
              [126.794187314816512, 37.630979920508587],
              [126.794202810958339, 37.630909537388789],
              [126.794251738110958, 37.630682685222403],
              [126.794269780861015, 37.630601015557041],
              [126.794297238611364, 37.63048044134878],
              [126.794323359958327, 37.630366954401318],
              [126.794413551354012, 37.629968394256011],
              [126.794413950942186, 37.629966645363012],
              [126.794428601918497, 37.629902180681242],
              [126.79446403216194, 37.629744769898657],
              [126.794532212299842, 37.629426795635005],
              [126.794537907696366, 37.629400232362443],
              [126.794550883651496, 37.629337185348909],
              [126.794602041151904, 37.629308417932187],
              [126.794571538012406, 37.629241959915518],
              [126.794513833787249, 37.629106577734809],
              [126.794469186832416, 37.62903491286383],
              [126.7943999236862, 37.628923726192511],
              [126.794398966990443, 37.62892219001391],
              [126.793965971056153, 37.628703310467557],
              [126.793859688373146, 37.628515820733696],
              [126.793860287863382, 37.628513905391259],
              [126.793859709023437, 37.628512919477934],
              [126.793913959963362, 37.628337061288036],
              [126.79391839633459, 37.628333148125975],
              [126.794040559759225, 37.628225394217658],
              [126.793942478821563, 37.628136392316819],
              [126.793872349282452, 37.628072749777409],
              [126.79378098896764, 37.627991655667536],
              [126.793756410965955, 37.627969835184857],
              [126.793716048470827, 37.627933688218924],
              [126.793662190054405, 37.627885440316611],
              [126.793616516916572, 37.627844532248375],
              [126.793523631637967, 37.627761298967037],
              [126.793437885875548, 37.627684314020271],
              [126.793283329138845, 37.627545543545075],
              [126.793162194073176, 37.627436779157108],
              [126.793092994370639, 37.627374651449813],
              [126.793006212237501, 37.627297102957108],
              [126.792784934260425, 37.627099293314636],
              [126.79269497849225, 37.627018879170848],
              [126.792693691570847, 37.627019549256055],
              [126.792567768425755, 37.627086615766629],
              [126.792431916290397, 37.627068247762899],
              [126.792309304002728, 37.627051669738677],
              [126.792305523255138, 37.627046195820945],
              [126.792173169508445, 37.62685456609308],
              [126.792137004426877, 37.626802206314011],
              [126.792044315413634, 37.62666800465756],
              [126.792034498543501, 37.626648486048651],
              [126.79194261631261, 37.626465831868693],
              [126.791924857801774, 37.626430539193024],
              [126.791813527046941, 37.626209232745659],
              [126.791655961590564, 37.626098920482676],
              [126.791632239934955, 37.626083002096642],
              [126.791542036699795, 37.626022471620942],
              [126.791280795580747, 37.625843819407073],
              [126.791236908029973, 37.625807873946009],
              [126.79112283593787, 37.625736635283367],
              [126.791088900394854, 37.62571550071911],
              [126.790940051166984, 37.625623005076228],
              [126.790868296498147, 37.625685251413543],
              [126.790428307751526, 37.626082714569236],
              [126.79027882672743, 37.626193851692641],
              [126.789859362344089, 37.626499406774315],
              [126.789828245344637, 37.626522324168242],
              [126.789840151947573, 37.626533200970592],
              [126.789923146615791, 37.626609017366775],
              [126.789840155680494, 37.626677283919911],
              [126.789748957379473, 37.626598143553913],
              [126.789738098801621, 37.626588719685103],
              [126.788717355582421, 37.627340532840968],
              [126.788618162090771, 37.627461670162248],
              [126.788344850546977, 37.627758186215182],
              [126.787857293028111, 37.628031557756209],
              [126.787598582173558, 37.628183200293165],
              [126.787460706932691, 37.628264015534683],
              [126.787051646874943, 37.628499778373225],
              [126.786745036553995, 37.628676511893893],
              [126.786708728157848, 37.628697441181927],
              [126.786687372977795, 37.628719945350539],
              [126.786594498908116, 37.628765941978457],
              [126.786397069590464, 37.628878456125541],
              [126.786355242521438, 37.628899680009219],
              [126.78628573299271, 37.628934636722995],
              [126.786036403298738, 37.629069282993953],
              [126.785886238157687, 37.629150997238042],
              [126.785720343051977, 37.629241321997817],
              [126.785547156165535, 37.62933482551913],
              [126.785473275411846, 37.629373621979759],
              [126.785126890480242, 37.629531450146338],
              [126.784712836915574, 37.629722227948712],
              [126.783871221891943, 37.630091799623564],
              [126.783621399526467, 37.630193915999904],
              [126.783244764389707, 37.630342700258808],
              [126.782976056177034, 37.630441392649431],
              [126.782697830261128, 37.630533668588626],
              [126.782552803760026, 37.630579305266039],
              [126.782204848907028, 37.630690577832944],
              [126.782180838485502, 37.63069861491806],
              [126.782170736361294, 37.630702369681387],
              [126.781816245526187, 37.630819669072665],
              [126.781722578724384, 37.630850892338849],
              [126.780528466946123, 37.631028047418461],
              [126.780362546769851, 37.631056051984757],
              [126.780320146238381, 37.63106110759108],
              [126.7801658909612, 37.631079489246417],
              [126.779872323060374, 37.631124102690485],
              [126.779776855937527, 37.631131311945524],
              [126.779447313569918, 37.631231962028053],
              [126.778758604411621, 37.631250950536931],
              [126.777032190372026, 37.631298766948611],
              [126.777020763087151, 37.631304274344188],
              [126.777020280193483, 37.631304284905049],
              [126.776557182586615, 37.631317009483396],
              [126.776296124539058, 37.631320798413796],
              [126.776291791988541, 37.63132090433357],
              [126.775873338999389, 37.631331154619318],
              [126.775736675450545, 37.631399771210418],
              [126.77534216033996, 37.631527495125709],
              [126.774543718367227, 37.63178620970664],
              [126.774286125975621, 37.631839704797414],
              [126.774211008470033, 37.631855304392509],
              [126.774055329169855, 37.631899173255881],
              [126.773956572745533, 37.631927001568229],
              [126.77355433557733, 37.63203999923266],
              [126.773492820445682, 37.632058683541452],
              [126.773323662963278, 37.632101721238122],
              [126.772663751081325, 37.632262592486903],
              [126.771337071994296, 37.632700903404142],
              [126.77120789246564, 37.632731060716516],
              [126.771183328108009, 37.632736794646895],
              [126.770734310625031, 37.632845168187039],
              [126.770270318001224, 37.632957153648249],
              [126.769716888044684, 37.633106388094063],
              [126.769086295838818, 37.633275623691368],
              [126.768540795100023, 37.633718015172448],
              [126.767587569660023, 37.633905594885306],
              [126.767429315495647, 37.633965131978606],
              [126.766907241553497, 37.634161536743548],
              [126.766279487238691, 37.634494856067967],
              [126.765596470126994, 37.634922708013356],
              [126.764139003773323, 37.635300601381445],
              [126.764017713881557, 37.635332286271897],
              [126.762871115817163, 37.635326369126787],
              [126.762631008058662, 37.634916741618433],
              [126.760496662861954, 37.631308495922426],
              [126.759541582216229, 37.629730992885861],
              [126.759519247381235, 37.629694099284215],
              [126.759089031256622, 37.629922614184522],
              [126.759027222218251, 37.629985555995837],
              [126.758733553446191, 37.630152479476699],
              [126.758497259648507, 37.630206053161224],
              [126.758362343542515, 37.630284242570532],
              [126.757711087333462, 37.630661677103504],
              [126.757239618353665, 37.630850186113456],
              [126.757136338259883, 37.630912551266917],
              [126.756532839983791, 37.631276962785648],
              [126.755099258066906, 37.631964070272481],
              [126.754584898588817, 37.632059292551574],
              [126.753553627143006, 37.632255716145913],
              [126.752966124141579, 37.632367609994688],
              [126.751692790079787, 37.632610114958887],
              [126.751367498064724, 37.6327628361569],
              [126.748719925968658, 37.634005787092754],
              [126.74862769297718, 37.634049085310799],
              [126.746036318346867, 37.635242368233968],
              [126.744971897580442, 37.63609849225616],
              [126.743884687308494, 37.636972915029411],
              [126.743645080444409, 37.637155483907868],
              [126.743206873956993, 37.637489371090197],
              [126.742136523872148, 37.638304901894344],
              [126.741957972691011, 37.638440940079114],
              [126.740919548572293, 37.639691615333753],
              [126.740446520142982, 37.640261315916767],
              [126.740402248883612, 37.640314633568224],
              [126.736321789817211, 37.643113473671313],
              [126.73468424418877, 37.64636229772281],
              [126.732602932168078, 37.647478158563608],
              [126.732470070656461, 37.647518443212888],
              [126.732528593579843, 37.647568927632229],
              [126.733657592104592, 37.648542783372186],
              [126.734601254020504, 37.649350364771685],
              [126.73523916537124, 37.649840043957994],
              [126.735411218191402, 37.649971433795244],
              [126.735705830637414, 37.650196260013985],
              [126.735791896609811, 37.650261983271335],
              [126.736042482729601, 37.650453343916276],
              [126.736166597073705, 37.650555523057967],
              [126.736171249399789, 37.650570130280883],
              [126.736205753316113, 37.650681431849243],
              [126.73628706621956, 37.650742700275245],
              [126.736473339551281, 37.650811864050496],
              [126.736575679934575, 37.65082376230729],
              [126.736644263648429, 37.650831798888035],
              [126.736756163495826, 37.650832429217594],
              [126.736861260330798, 37.650913283658895],
              [126.737282185959927, 37.651047966917446],
              [126.737607540568078, 37.651160246061963],
              [126.737645347410009, 37.651219929053099],
              [126.737804552111214, 37.65139782265787],
              [126.737777347111688, 37.65143258395802],
              [126.737801718798238, 37.651494354075652],
              [126.737867507414748, 37.65152405411439],
              [126.737889581512448, 37.651530680846918],
              [126.737928546472375, 37.651541416768218],
              [126.738146084848324, 37.651587196768169],
              [126.73820215438424, 37.651598996667012],
              [126.738133899252148, 37.651833998555944],
              [126.7381165191848, 37.651893963087524],
              [126.738334511777865, 37.651951211405837],
              [126.738350340286445, 37.651955346126677],
              [126.738494030719181, 37.651992742242022],
              [126.738491579190935, 37.652000241647258],
              [126.738484605964715, 37.652014479896614],
              [126.738529268232682, 37.652042373931216],
              [126.738826076812686, 37.652227464498573],
              [126.739048050917603, 37.652393376355882],
              [126.739370023337997, 37.652610236376155],
              [126.739657083383989, 37.65282981060313],
              [126.739682575017, 37.652830858208787],
              [126.739883622519528, 37.652955278208701],
              [126.740028309436582, 37.653048664555413],
              [126.740138506825318, 37.653119796622747],
              [126.740151435763849, 37.653128164866068],
              [126.740184918675808, 37.653150894688238],
              [126.740271813320248, 37.653209885329574],
              [126.740307943183765, 37.653234413178659],
              [126.740582535875873, 37.653420828112083],
              [126.740624364517345, 37.653449389919381],
              [126.740670383420181, 37.653478593387533],
              [126.740797023329193, 37.653558970096249],
              [126.740865221599563, 37.653602598597409],
              [126.74095520513383, 37.65366016469487],
              [126.740964024833232, 37.653665742951162],
              [126.741279674120008, 37.65387807930145],
              [126.741490963514408, 37.654024502440663],
              [126.741585810228557, 37.654090211234305],
              [126.74202188788685, 37.654379213011389],
              [126.742034769292246, 37.654389511376451],
              [126.742065206497614, 37.654435618533611],
              [126.7421336531945, 37.654483064098855],
              [126.742162089188994, 37.654502774851757],
              [126.742278194899669, 37.65458249463029],
              [126.742377135458824, 37.654612174369618],
              [126.7424254926255, 37.654653725872954],
              [126.742437581716857, 37.654664022710755],
              [126.742771545915744, 37.65489243151324],
              [126.742890816895965, 37.654974501828988],
              [126.743056134581181, 37.655078115299503],
              [126.743190699240898, 37.655063814873678],
              [126.744217302243854, 37.654929650005336],
              [126.744222162240689, 37.654928983520186],
              [126.744281248597716, 37.654920869815633],
              [126.745420752664003, 37.654776219675995],
              [126.745483533241199, 37.655106564944511],
              [126.745525499028588, 37.655327214026833],
              [126.745569258693408, 37.655553001284694],
              [126.745595091635025, 37.655686580308952],
              [126.745605492245801, 37.655740005137012],
              [126.745649991325536, 37.655968614615432],
              [126.745671381206051, 37.65607623775032],
              [126.745702644055356, 37.656245868321697],
              [126.74576328696152, 37.656538634373774],
              [126.745887060969508, 37.657128140864124],
              [126.745656218552867, 37.657159624393856],
              [126.745724077298206, 37.657215902484204],
              [126.744768355241732, 37.657343024318251],
              [126.744748336707076, 37.657345665007796],
              [126.743580830887197, 37.657499635995251],
              [126.74331722491408, 37.657534647118027],
              [126.743352599520591, 37.657594910224681],
              [126.743368935893756, 37.65762287669444],
              [126.743378627431895, 37.657636113490391],
              [126.743587982497147, 37.657922117250386],
              [126.743633420280418, 37.65798942914936],
              [126.74370907735792, 37.6581012239687],
              [126.743819346740835, 37.658264361834874],
              [126.743874483540822, 37.658346019972861],
              [126.743990729636991, 37.65851808843356],
              [126.74399368518884, 37.658522455355417],
              [126.744027520321239, 37.65857240717456],
              [126.744047371777, 37.658601820165188],
              [126.744055514374381, 37.658613877962502],
              [126.744131219261234, 37.658725889128704],
              [126.744165496066572, 37.658776689177984],
              [126.74427396434821, 37.658937298576504],
              [126.744282891206481, 37.658950509667029],
              [126.744351313491819, 37.659051800571739],
              [126.74450533526668, 37.659279722993411],
              [126.744608066962257, 37.659428154524505],
              [126.744690833762562, 37.659549518503027],
              [126.74481454131535, 37.659718679378372],
              [126.744867363147378, 37.659790909713067],
              [126.7448992836202, 37.659835578988364],
              [126.744969648258731, 37.659937902257575],
              [126.74508861835946, 37.660111058720986],
              [126.745171725969769, 37.66023205769806],
              [126.745338143623314, 37.660473177077549],
              [126.745356396215882, 37.660499692678812],
              [126.745429515951415, 37.660606612010611],
              [126.745484543785679, 37.660687279931878],
              [126.745526600539463, 37.66074989880665],
              [126.745632248551146, 37.660906989956629],
              [126.745689087988836, 37.660991525222158],
              [126.745859232192643, 37.661244530882179],
              [126.746092454898445, 37.661579926434534],
              [126.746208849145304, 37.661747307634705],
              [126.74632895193001, 37.66192001489091],
              [126.746332290316559, 37.661924782583348],
              [126.746334211524328, 37.661927527667252],
              [126.746507157854879, 37.662174620479703],
              [126.746566361527172, 37.6622592055678],
              [126.746646772634591, 37.662373978854795],
              [126.746783354205604, 37.662569067057781],
              [126.746812238572105, 37.662611254906658],
              [126.746931313772023, 37.662785169956749],
              [126.746963226719686, 37.662831728685944],
              [126.746998073061988, 37.662882528825968],
              [126.747168033749603, 37.663130314534136],
              [126.747301213530221, 37.663324476386549],
              [126.747359293312428, 37.663408482458145],
              [126.747383029585563, 37.663442937322209],
              [126.747392111966533, 37.663456123927581],
              [126.747445005822001, 37.663532730363151],
              [126.747519744561913, 37.663640915143198],
              [126.747589251488307, 37.663741526944541],
              [126.747761687775153, 37.663991108582493],
              [126.747842888198718, 37.664108699095891],
              [126.747879322526245, 37.66416146226053],
              [126.747966275490185, 37.664287334659633],
              [126.748052896327422, 37.664412665885571],
              [126.748125169568269, 37.664517259914049],
              [126.748229282995325, 37.66466793230957],
              [126.748343422858142, 37.664833143732579],
              [126.748428682957424, 37.664956590354713],
              [126.748461845260266, 37.665004580718659],
              [126.748540620779522, 37.664970419606433],
              [126.748999389302398, 37.664771475274414],
              [126.749671816000443, 37.66447987435366],
              [126.749739111447994, 37.66445069131052],
              [126.749739713901235, 37.664450426548811],
              [126.749758294243279, 37.664442265193706],
              [126.749804468805891, 37.664421880766142],
              [126.750385251076267, 37.664164570356569],
              [126.750386369465019, 37.664164074526816],
              [126.750661238384168, 37.6640423553416],
              [126.75093556887191, 37.663920872742878],
              [126.751785603050152, 37.663544441906822],
              [126.751963343187299, 37.663474081816176],
              [126.752092393166407, 37.66341795375422],
              [126.752116788525285, 37.663409087108143],
              [126.752164578415659, 37.663386574053817],
              [126.752500820123799, 37.663240390195455],
              [126.752715814744406, 37.663146910896067],
              [126.75283457145602, 37.663095262166898],
              [126.752966465634842, 37.663037920851345],
              [126.753102885032064, 37.662978610712116],
              [126.753234848247445, 37.662921240688291],
              [126.753256566481824, 37.662911802541913],
              [126.753581738673432, 37.662770432458871],
              [126.753947195991955, 37.662611555731417],
              [126.753973425771832, 37.662600153164256],
              [126.754161263353282, 37.662518489725144],
              [126.75416257546911, 37.662518343895918],
              [126.754379575866622, 37.662423643912284],
              [126.754417089885166, 37.662407272383504],
              [126.755063912197912, 37.662126079920462],
              [126.755288520178496, 37.662028434805563],
              [126.755335665266813, 37.662096501083738],
              [126.755362268904079, 37.662134913948854],
              [126.755454556440725, 37.662268168480672],
              [126.755482272309351, 37.662308183679478],
              [126.755483438008284, 37.662308053135128],
              [126.755524273215187, 37.662366429049285],
              [126.755714438930113, 37.662643424791668],
              [126.755763889202882, 37.662715523535873],
              [126.755966779149645, 37.663011357467873],
              [126.756117879708825, 37.663231661709354],
              [126.75617204441788, 37.663310644566955],
              [126.756206929639248, 37.663361496334701],
              [126.756233064372353, 37.663399608605907],
              [126.756237192579519, 37.663405626627551],
              [126.756318655522961, 37.663524551490738],
              [126.756320705011674, 37.663527548690723],
              [126.756325336138573, 37.663533162482508],
              [126.756337882847532, 37.663548384703368],
              [126.7563829055144, 37.663602997556879],
              [126.756661064379543, 37.664007209270281],
              [126.756786496731394, 37.664189466495188],
              [126.757363129425258, 37.665027483941159],
              [126.757887436975537, 37.665789437519656],
              [126.757977227976397, 37.665919814432534],
              [126.757995052889299, 37.665945797213624],
              [126.758088576561136, 37.666081672151407],
              [126.758090258697393, 37.666084112696275],
              [126.758327158518995, 37.665980796888221],
              [126.759058169789782, 37.665661983493557],
              [126.759316425696781, 37.665549349473579],
              [126.759372940457666, 37.665524701685165],
              [126.759694889883818, 37.665384286926056],
              [126.759704121839832, 37.665380260078699],
              [126.759873242951386, 37.665304654980531],
              [126.760040218835101, 37.665226162764078],
              [126.76020504860135, 37.66514487176233],
              [126.760367622246235, 37.665060783096941],
              [126.760527935223706, 37.664973896748513],
              [126.760595795105473, 37.664935440935771],
              [126.76068587916275, 37.66488439231135],
              [126.76084144927988, 37.664792179632556],
              [126.76099442129771, 37.664697350152373],
              [126.761144795236476, 37.664599901176921],
              [126.761292570188061, 37.664499923743875],
              [126.761348408207951, 37.664460470145826],
              [126.761437518496578, 37.664397507470838],
              [126.761579754408444, 37.664292564753829],
              [126.761654579439536, 37.664234977195264],
              [126.76171916506398, 37.664185271555219],
              [126.761855521410681, 37.664075630056743],
              [126.76198905158941, 37.663963730026026],
              [126.762038417019156, 37.663920501416335],
              [126.762119415461598, 37.663849571147217],
              [126.762246726403077, 37.663733154139877],
              [126.7623708703929, 37.663614658565265],
              [126.762491734080172, 37.663494081921812],
              [126.762555294901162, 37.6634293407526],
              [126.762688825355923, 37.663293232188003],
              [126.762694587588697, 37.66328735913698],
              [126.762707766539975, 37.663295529986549],
              [126.763237249196763, 37.663623833819734],
              [126.763409644415646, 37.663730725812407],
              [126.763725400475678, 37.66392650505329],
              [126.763993920318555, 37.664092994059843],
              [126.764006700623057, 37.664100918018377],
              [126.764590143919165, 37.664462663463723],
              [126.764686033843475, 37.664522115853273],
              [126.765032484062999, 37.664736919684799],
              [126.765103377073913, 37.664780873659446],
              [126.765417735645045, 37.664975776198602],
              [126.765543244011994, 37.665053590222925],
              [126.765751962762351, 37.665182995578675],
              [126.766135654760859, 37.665420880131926],
              [126.766468894166522, 37.665627482042296],
              [126.766668131653958, 37.665751003368193],
              [126.767225532864202, 37.666096575394981],
              [126.767417697538463, 37.666215726968979],
              [126.767636609391346, 37.66635146460419],
              [126.768384516132073, 37.666815138847028],
              [126.768736035560394, 37.667033062898291],
              [126.768846548542811, 37.667103735702504],
              [126.768954107897144, 37.667177196813967],
              [126.769058825380881, 37.667253265767414],
              [126.769160587384519, 37.667331849931578],
              [126.769205117286859, 37.667368525748905],
              [126.769259056003236, 37.667412950825749],
              [126.769354342097586, 37.667496479012563],
              [126.76939451578653, 37.667533975015374],
              [126.769446335446233, 37.667582342783447],
              [126.769530187142266, 37.6676658495753],
              [126.769534807893805, 37.667670451501984],
              [126.769619874841212, 37.66776071485711],
              [126.769701306978604, 37.667853044007963],
              [126.769778994060729, 37.66794734904849],
              [126.769853048110249, 37.668043536941902],
              [126.769923242053778, 37.66814152156514],
              [126.769980078582478, 37.668223820682144],
              [126.770073218414382, 37.668358689635113],
              [126.77043054675093, 37.668876103458722],
              [126.770818524699195, 37.669437886674721],
              [126.771063715794625, 37.669792913429312],
              [126.771107127974346, 37.669855771329551],
              [126.7712888196817, 37.670118850672424],
              [126.771493342748713, 37.670414986132272],
              [126.771601999216841, 37.67057231239211],
              [126.771792090161668, 37.670847546667105],
              [126.77185990884719, 37.670945794857793],
              [126.772072495780407, 37.670853230832293],
              [126.772073962548703, 37.670855837494805],
              [126.772163355806697, 37.671014771274486],
              [126.772231922783973, 37.671114023434967],
              [126.772284898974874, 37.671190705799177],
              [126.772673501175987, 37.671753211319285],
              [126.772717963663624, 37.671817571287768],
              [126.772876867542138, 37.672047581432786],
              [126.773208768048775, 37.672527997394091],
              [126.77333623717314, 37.672656180153602],
              [126.773396271949991, 37.672742878622515],
              [126.773459034215662, 37.672833625474169],
              [126.773467048180791, 37.67284521626619],
              [126.773504723179499, 37.672899678166907],
              [126.773563292830303, 37.672984213365126],
              [126.773601377208948, 37.673095916188032],
              [126.77370510811501, 37.673246032012322],
              [126.773964989509878, 37.673622127653765],
              [126.773978772304076, 37.673642074022588],
              [126.774149127880079, 37.673888606535947],
              [126.774294857243419, 37.674099501339249],
              [126.774335934472347, 37.674158944776615],
              [126.774429208345495, 37.6742380525911],
              [126.774596463735307, 37.674479741500285],
              [126.774705315935464, 37.674504999017223],
              [126.774812610076623, 37.674660869969834],
              [126.774928317721844, 37.674828965796053],
              [126.774896542003745, 37.674915488377906],
              [126.774978199154447, 37.67503405268544],
              [126.775042725986893, 37.675127746028529],
              [126.77506921014853, 37.675166199660282],
              [126.775101546692412, 37.675268071080573],
              [126.77519653905037, 37.675405636833865],
              [126.775253232267644, 37.67548773779118],
              [126.775587966200973, 37.675972482551003],
              [126.775680382842651, 37.676066181901604],
              [126.775720977720042, 37.676107342186242],
              [126.775799528330168, 37.676220743894966],
              [126.775832369287144, 37.676268183802385],
              [126.775870970569144, 37.676323951224767],
              [126.775931821210989, 37.676411780936796],
              [126.775950537263213, 37.6764387975782],
              [126.775982862344463, 37.67654436048403],
              [126.776187572493654, 37.676840812359657],
              [126.776304701724527, 37.677010433350944],
              [126.776592145353163, 37.677426691035258],
              [126.776762017507878, 37.677672684945733],
              [126.777037008232824, 37.678070901823368],
              [126.777152332618556, 37.678237590609626],
              [126.777278094647457, 37.678419407924686],
              [126.777423134712407, 37.678628700115425],
              [126.777448323929207, 37.678665055103245],
              [126.777469294990738, 37.678695285904851],
              [126.777507471257593, 37.678779228173902],
              [126.777571660058342, 37.67892036356718],
              [126.777624705436494, 37.678997587399117],
              [126.777605524565033, 37.679005747641476],
              [126.777615232671124, 37.679019822648485],
              [126.777667413841527, 37.67909554497399],
              [126.777724149951354, 37.679177880223328],
              [126.77787578115128, 37.679393761206882],
              [126.777877434454965, 37.679396114764728],
              [126.777914791581665, 37.679451863969405],
              [126.777950782660497, 37.679508154604065],
              [126.777985869928017, 37.679564799321128],
              [126.778019593663188, 37.679622076519152],
              [126.778032896364849, 37.679645797801363],
              [126.778039560471356, 37.679657688263589],
              [126.77805195225227, 37.679679801418565],
              [126.778082727103381, 37.679738061913206],
              [126.778091742059758, 37.679756394082055],
              [126.778111683713291, 37.679796949404846],
              [126.778138939769576, 37.679856372680419],
              [126.778164834890433, 37.679916158963586],
              [126.778190159079557, 37.679976118405122],
              [126.778213217650205, 37.680036527253399],
              [126.778233780567575, 37.680097834762272],
              [126.778235008241467, 37.680101983845084],
              [126.778251959208632, 37.680159313347403],
              [126.778269004601626, 37.680221062600708],
              [126.778284392101824, 37.680278239219213],
              [126.778285597281538, 37.680282720751137],
              [126.778331740123946, 37.680432999129401],
              [126.778343119265529, 37.680532037389341],
              [126.778349391949632, 37.680595115950894],
              [126.77835037087425, 37.680607150066791],
              [126.778354530838115, 37.680658284916277],
              [126.778357174852744, 37.680721447627548],
              [126.778358911880744, 37.680784700328125],
              [126.778358920332394, 37.680787808133751],
              [126.778359064400433, 37.680847858608047],
              [126.778358761051365, 37.680911014097788],
              [126.778357551564056, 37.680974171252622],
              [126.778354754418245, 37.681037324099513],
              [126.778349715825144, 37.681130381668787],
              [126.778366127016042, 37.681177085778927],
              [126.778365709272123, 37.681180405490728],
              [126.77836066573829, 37.681220591557398],
              [126.778359659934338, 37.681228773901083],
              [126.778356600778011, 37.681253649658558],
              [126.778323381719929, 37.681519819980956],
              [126.778298055264344, 37.681723753723674],
              [126.778270179354948, 37.681946871781527],
              [126.778267767365804, 37.681966280478285],
              [126.778261722282721, 37.681970420201871],
              [126.778249428567889, 37.6820718066176],
              [126.778254243975681, 37.682075075434483],
              [126.778243926812436, 37.682158099624147],
              [126.778242197756555, 37.682171983799222],
              [126.778231034208801, 37.682261595965855],
              [126.778226388630515, 37.682298617214229],
              [126.778198042559183, 37.682527138474434],
              [126.778186491384361, 37.682619757767533],
              [126.778171789886201, 37.68273763260315],
              [126.778157679295077, 37.682850699802934],
              [126.778154440605675, 37.682876649724179],
              [126.778149220240678, 37.682886491735452],
              [126.778138623514579, 37.682979380045566],
              [126.778140895064809, 37.682985353480468],
              [126.778115453707599, 37.683189916449379],
              [126.778083276395122, 37.683448342534128],
              [126.778053537843917, 37.683687042810888],
              [126.778031725017698, 37.683862600930247],
              [126.77800950866397, 37.684041401322311],
              [126.777995104617901, 37.684156608216114],
              [126.777954216560033, 37.684484389752676],
              [126.777951615282674, 37.684505289861292],
              [126.777944654805921, 37.684524046078046],
              [126.777948542964893, 37.68452997263028],
              [126.777935975636751, 37.684630944486493],
              [126.777933145472588, 37.68465392794613],
              [126.777931561128099, 37.684666793603363],
              [126.777930808780667, 37.684672803506018],
              [126.777922269833326, 37.684741018109683],
              [126.777914790573533, 37.684853174049174],
              [126.777909033624169, 37.684894129935429],
              [126.777904711696067, 37.684924871835541],
              [126.7778770253435, 37.685121860661809],
              [126.77788265653048, 37.68528548833855],
              [126.777844890940059, 37.685554174917833],
              [126.777801609425637, 37.685659145281953],
              [126.777781450400028, 37.685802271309917],
              [126.777774237912951, 37.685853431124627],
              [126.777768826835327, 37.685891801425207],
              [126.77768269903757, 37.686507715426103],
              [126.777652039573084, 37.686726961149375],
              [126.777658873830731, 37.686790942639114],
              [126.777663225966705, 37.686831130305116],
              [126.777666816011688, 37.686864295626805],
              [126.777649454118745, 37.686986980578148],
              [126.777641565167201, 37.687042730256849],
              [126.777612356587582, 37.687249864789884],
              [126.777584572945287, 37.687446882611603],
              [126.777559322791589, 37.687625319913209],
              [126.777529722002043, 37.687699101759165],
              [126.777506661295703, 37.68775658093832],
              [126.777453133886809, 37.688136056444698],
              [126.777423948153384, 37.688342953982094],
              [126.777429350783748, 37.68850666941929],
              [126.777414703351127, 37.688610530650053],
              [126.777391470316175, 37.688775266661679],
              [126.777348184816745, 37.688880147537247],
              [126.777327123643389, 37.68902944281411],
              [126.777269871377555, 37.689435263420094],
              [126.777275379833284, 37.689485668315193],
              [126.777284877786499, 37.68957260019323],
              [126.777258268817988, 37.689760467649101],
              [126.777255803104396, 37.689777875940791],
              [126.777219726618583, 37.689919717168394],
              [126.777194762842129, 37.690017866544423],
              [126.777178815451265, 37.690080544780365],
              [126.777137689755833, 37.690372467941977],
              [126.777128840538239, 37.690449125876533],
              [126.777124299776929, 37.690526058984993],
              [126.777123840950864, 37.690603001792731],
              [126.777127462999829, 37.690679947984187],
              [126.777135281031164, 37.690756725211337],
              [126.777147184267434, 37.690833062398603],
              [126.777163167344511, 37.690909046038584],
              [126.777307452261425, 37.691514219793959],
              [126.77751305858385, 37.692376574652968],
              [126.777566381008, 37.692478575529584],
              [126.777629642303623, 37.692744210425566],
              [126.777639809653991, 37.692907665600941],
              [126.777724234497299, 37.693261813398848],
              [126.777789269276155, 37.693389515805229],
              [126.777831372477834, 37.693565916596043],
              [126.777881759774004, 37.693774440625482],
              [126.777927796257416, 37.693964956676425],
              [126.777970012394718, 37.694141356292398],
              [126.777968373866955, 37.694268565590932],
              [126.77796823670981, 37.694279200311605],
              [126.778084237039366, 37.69476344637399],
              [126.778180553631145, 37.695165524769763],
              [126.778197370019527, 37.695229343720676],
              [126.778206337812577, 37.695257527865621],
              [126.778217476239107, 37.695292539264763],
              [126.778240644319609, 37.69535511000494],
              [126.778266879140133, 37.695416787376438],
              [126.778296289905811, 37.695477656769555],
              [126.778418484818218, 37.695715923656365],
              [126.778472702067006, 37.695790332086474],
              [126.778521692883487, 37.695857570070579],
              [126.778649977773483, 37.69610792279488],
              [126.778675049516096, 37.696216535421783],
              [126.778700694412137, 37.696266581172885],
              [126.778850005575222, 37.696557971324943],
              [126.778921972083879, 37.69669846099243],
              [126.778995988361871, 37.696842953283706],
              [126.779234715401714, 37.697311016251625],
              [126.779384334973869, 37.697604368847195],
              [126.77942233851924, 37.697674179258442],
              [126.779439668846621, 37.697702291825458],
              [126.779464428038963, 37.697742458949811],
              [126.779510491755815, 37.697809037786271],
              [126.779560303105171, 37.697874002708083],
              [126.779613859928375, 37.697936991367506],
              [126.779671053079582, 37.697997912960915],
              [126.77973165541357, 37.698056679569021],
              [126.77974445024266, 37.698067976432526],
              [126.779795664402457, 37.698113201039448],
              [126.779862969499931, 37.698167296427854],
              [126.779925647338686, 37.698213812276286],
              [126.78010070050523, 37.698297121039474],
              [126.780318942087689, 37.698406071566815],
              [126.780388734362305, 37.698440914356844],
              [126.780491131249235, 37.698514803779013],
              [126.780770246743231, 37.698646689411284],
              [126.780933845718963, 37.698692583657845],
              [126.781128035638147, 37.698784303330569],
              [126.781342211902128, 37.698885550655383],
              [126.781567940404869, 37.698992256059149],
              [126.781581508813687, 37.698998665983062],
              [126.781762134114445, 37.699083980120278],
              [126.781806827599809, 37.699116047251387],
              [126.781824364663763, 37.699128619296602],
              [126.781825725516825, 37.699129597403548],
              [126.781794328564175, 37.699148818758914],
              [126.781784487901859, 37.699154837308484],
              [126.781424695350225, 37.699096866256305],
              [126.781382826017605, 37.699090119798079],
              [126.781305511763534, 37.699171502688046],
              [126.781218009457348, 37.699263608592915],
              [126.7811998037145, 37.699243582086382],
              [126.7811810165162, 37.699222921109637],
              [126.781123948714466, 37.699261680489201],
              [126.780974266372937, 37.699363338724524],
              [126.780844037567647, 37.699489554655599],
              [126.779812039786037, 37.699811012964254],
              [126.779740368709284, 37.69988935217593],
              [126.779333855032419, 37.700043192874816],
              [126.779049523802499, 37.700029095849793],
              [126.778232861274063, 37.699992730205501],
              [126.77711794467362, 37.700235136230873],
              [126.776836106682552, 37.700593273535326],
              [126.776151259766763, 37.700803244180022],
              [126.775492129870301, 37.701355626147368],
              [126.775465209508113, 37.701370936351196],
              [126.775155636393905, 37.701546951940216],
              [126.774741979456849, 37.701782145167783],
              [126.774393477941075, 37.702501795769138],
              [126.773686863189198, 37.702739820182863],
              [126.773601731204494, 37.703079456444357],
              [126.773599004512604, 37.7030903401754],
              [126.773436417512769, 37.703816620167316],
              [126.773429980553118, 37.703823854129915],
              [126.773418019426046, 37.703871367256667],
              [126.773805921750252, 37.70437891091818],
              [126.77380988556169, 37.704384013351138],
              [126.77381271779555, 37.704387661299592],
              [126.773905810424495, 37.704507491470487],
              [126.774147806228896, 37.704983497799823],
              [126.774047843364556, 37.70563003505827],
              [126.773779071083041, 37.706031003494395],
              [126.773490301626083, 37.70630326803218],
              [126.773540327971375, 37.706516501514443],
              [126.773642400203542, 37.706741943978706],
              [126.773685881545049, 37.70683775680623],
              [126.773566835771661, 37.707406540075937],
              [126.773499311111223, 37.707760037643119],
              [126.773564637634649, 37.708181923645462],
              [126.773597873323467, 37.70829699938357],
              [126.773652830953182, 37.708487295793084],
              [126.773761758353658, 37.708909444832869],
              [126.773834255278345, 37.709190398110152],
              [126.773813549437307, 37.70923935445834],
              [126.773892477028269, 37.709331854137211],
              [126.773990421846179, 37.709520120344934],
              [126.774101388875138, 37.709680255447793],
              [126.774133933421595, 37.709778639789683],
              [126.774220959677507, 37.710041709103173],
              [126.774369657902056, 37.710146174778977],
              [126.774505624437239, 37.710244416750896],
              [126.774778283417717, 37.71043695512202],
              [126.77473195968679, 37.710577362003313],
              [126.774483891428176, 37.711268653915596],
              [126.774421926568962, 37.711424238098914],
              [126.774425438951468, 37.711436351769215],
              [126.774443141566806, 37.711440604963705],
              [126.774454656576467, 37.711443448274714],
              [126.774492730319253, 37.711449153559769],
              [126.774562962388927, 37.711525148441517],
              [126.774593931852905, 37.711558655234754],
              [126.774627493592391, 37.711594972048054],
              [126.774870657221186, 37.711814202344883],
              [126.775125574461185, 37.712072035656256],
              [126.775204544857814, 37.712153273655105],
              [126.775383199146731, 37.712321618184106],
              [126.775560238735139, 37.712488438308704],
              [126.775857519112861, 37.712743245931378],
              [126.776065588292553, 37.712869047719664],
              [126.77655124636172, 37.713162677936651],
              [126.776798795721774, 37.713223964753155],
              [126.77699679769951, 37.713319501857015],
              [126.777165954824227, 37.713405136586154],
              [126.777357107472326, 37.713480675961179],
              [126.777875549402467, 37.714149501285291],
              [126.777997294131282, 37.71432682547448],
              [126.778211747727823, 37.714728160512784],
              [126.778254720733372, 37.71481861644687],
              [126.778569055072268, 37.715598824531504],
              [126.778362091153795, 37.715827620459642],
              [126.778198287158347, 37.715966391891257],
              [126.778303019496178, 37.71608597785513],
              [126.778480886151513, 37.716280012932756],
              [126.778643745890477, 37.716407619106185],
              [126.779050006327196, 37.716725943117908],
              [126.7793227661044, 37.717309831653992],
              [126.779639645231086, 37.717480489112901],
              [126.780103517782607, 37.71774601994494],
              [126.78025571667888, 37.717675679526955],
              [126.780710255621997, 37.717465605329181],
              [126.780782464051342, 37.717436170867458],
              [126.780896828311896, 37.717417528076943],
              [126.781337024803364, 37.717358940867378],
              [126.781823118473227, 37.717302692020887],
              [126.781824978378893, 37.717327347116779],
              [126.781845798376267, 37.717603709083882],
              [126.781837285656323, 37.71760566716091],
              [126.782181879619273, 37.718057352713856],
              [126.782153962360823, 37.718570566499999],
              [126.782368696578828, 37.71871089989699],
              [126.782530122985492, 37.718703071164228],
              [126.782586697839108, 37.718700325747221],
              [126.783177220022978, 37.718692411614128],
              [126.783467541360636, 37.718618898657631],
              [126.784054622931251, 37.718938699087666],
              [126.784374588905592, 37.718969137763459],
              [126.784377984170163, 37.719019814094729],
              [126.784420468255277, 37.719036504753049],
              [126.784439724408571, 37.719057380726206],
              [126.784477874804153, 37.719101372938795],
              [126.784482380241002, 37.719109775305199],
              [126.784490566227603, 37.719125046400322],
              [126.784496214402097, 37.719132372320715],
              [126.784513172676796, 37.719151269468213],
              [126.78451330607524, 37.719167600307735],
              [126.784519236001472, 37.719199427488299],
              [126.784539187941888, 37.719226773703276],
              [126.784547609017892, 37.719315759386973],
              [126.784556219744758, 37.71945569576669],
              [126.784561140859338, 37.719535672290732],
              [126.784562547043365, 37.719581175610614],
              [126.784563575529944, 37.71961451248815],
              [126.784519811321417, 37.719697846578647],
              [126.784514383523558, 37.719708185070935],
              [126.784291280190885, 37.719945681150641],
              [126.784278436114732, 37.719974659771196],
              [126.784252650788403, 37.720065826274272],
              [126.784289131267613, 37.720134592766414],
              [126.784364992057675, 37.720253550990705],
              [126.784370791500379, 37.720271580049662],
              [126.78446688720166, 37.720316805476998],
              [126.784568123908969, 37.720362887629754],
              [126.784741212507427, 37.720444850772125],
              [126.784887355695261, 37.72055875866284],
              [126.784968959204122, 37.720622367322797],
              [126.785012629455053, 37.720656238619149],
              [126.785023941699407, 37.720666674151815],
              [126.785040770682016, 37.720669795620225],
              [126.785166402011086, 37.720723731445837],
              [126.785250870492959, 37.720759996061396],
              [126.785289640775517, 37.72077527586498],
              [126.785298121865608, 37.720782897426751],
              [126.785383175133134, 37.720850902598556],
              [126.785453534944452, 37.720912126386303],
              [126.785462545058465, 37.720922558739062],
              [126.785468009460232, 37.720930730733947],
              [126.78550047767348, 37.720979225388106],
              [126.785701199637458, 37.721066591238902],
              [126.785773419041675, 37.721096563089631],
              [126.785838303840279, 37.721150180628356],
              [126.785926479887507, 37.721177370247993],
              [126.785935854868455, 37.721183018791784],
              [126.78605202597187, 37.721215044891814],
              [126.78616127455463, 37.721249309287771],
              [126.786269291511076, 37.721278787353882],
              [126.786312853420469, 37.721292949358428],
              [126.786317957779886, 37.721303653632091],
              [126.786339033410897, 37.721311575791148],
              [126.786339506743332, 37.721328470201442],
              [126.786484492243247, 37.721384765159826],
              [126.786678580540666, 37.721437491321026],
              [126.78669534645816, 37.721442363427315],
              [126.786921353353918, 37.721508036822428],
              [126.787225878982852, 37.721860246609815],
              [126.787214467536884, 37.722008361006544],
              [126.787193843706987, 37.722276036806562],
              [126.787189749270212, 37.722405262411044],
              [126.787371526262575, 37.722732471534634],
              [126.787446653811472, 37.722801306611395],
              [126.78745067955397, 37.722817080520151],
              [126.787513088627932, 37.722872379344402],
              [126.787916241937978, 37.72306822207392],
              [126.788102179370767, 37.723184844951454],
              [126.788349482745545, 37.72322414285923],
              [126.788710456307115, 37.723224050086365],
              [126.788755689491239, 37.723224038183368],
              [126.7888776385212, 37.723218906311359],
              [126.788944981534485, 37.723281807890821],
              [126.789232729311081, 37.723434372771557],
              [126.789495768420551, 37.723546335221172],
              [126.78977215838681, 37.72357724566389],
              [126.789774264676112, 37.723576981649892],
              [126.789925521501843, 37.723558086427111],
              [126.790111112358034, 37.723545750234202],
              [126.790378407124322, 37.723536652802899],
              [126.790401093555261, 37.723536413513735],
              [126.790418562516919, 37.723536177966743],
              [126.790418766989418, 37.723544718254807],
              [126.790418953910972, 37.723546177707398],
              [126.790419435137593, 37.72354759386576],
              [126.790420198523464, 37.723548931505405],
              [126.790421224031689, 37.723550149946661],
              [126.790422484697231, 37.723551221086353],
              [126.790423949033496, 37.723552114991612],
              [126.790425576399358, 37.723552809786241],
              [126.790427320454526, 37.723553286263694],
              [126.790429143901676, 37.72355352887714],
              [126.790430988882051, 37.723553536376961],
              [126.79058184504683, 37.723544266646506],
              [126.790583740123822, 37.723544018471976],
              [126.790584973371452, 37.723543681591067],
              [126.790820571867968, 37.723670623455753],
              [126.790875493623616, 37.72370107398087],
              [126.790900555361233, 37.723714967981742],
              [126.790968154579048, 37.723751696666959],
              [126.79106599749575, 37.723809865606668],
              [126.79119361982724, 37.723887843258041],
              [126.79151751832876, 37.724085745159513],
              [126.791665762054791, 37.724304774919119],
              [126.791797526665874, 37.724402993297289],
              [126.791813592665719, 37.724425824578333],
              [126.792024525866182, 37.724541911846174],
              [126.792303413104378, 37.724708707278431],
              [126.792336639752847, 37.724728577036018],
              [126.792356635244431, 37.724739875172865],
              [126.792429573041673, 37.724770970303098],
              [126.792662635027355, 37.725594164314018],
              [126.79268613708085, 37.725677181215524],
              [126.793175488771396, 37.725866960674331],
              [126.793495851007506, 37.725997195866441],
              [126.793834230603778, 37.7261347540462],
              [126.793834451882347, 37.726135471015567],
              [126.793877252119628, 37.726275243694808],
              [126.79399037378289, 37.726644634630873],
              [126.794168281833706, 37.726912988441988],
              [126.794208884976996, 37.726972181126115],
              [126.794240238904109, 37.727018075406988],
              [126.794291494869213, 37.727093109350641],
              [126.794309161266526, 37.727113411765757],
              [126.794301900167028, 37.727132990816933],
              [126.794294338697071, 37.72715337023935],
              [126.794346268273287, 37.727188283470205],
              [126.794347235722455, 37.727188935503939],
              [126.794446039135252, 37.727285962151178],
              [126.79450961693793, 37.727391853242352],
              [126.794607647194425, 37.727555128050561],
              [126.795024654459525, 37.728076729286911],
              [126.795076653339109, 37.728243781612747],
              [126.795090486791807, 37.728307432237379],
              [126.795110914622654, 37.728418124034867],
              [126.795131247633904, 37.72849812009769],
              [126.795167567039542, 37.728568845283419],
              [126.795221398909575, 37.728651999733046],
              [126.795255283317587, 37.728704990492261],
              [126.795297804499938, 37.728774889253799],
              [126.79529563199354, 37.728866909473375],
              [126.795312803588175, 37.728867648453303],
              [126.79531459024011, 37.728867837594024],
              [126.79531631349829, 37.728868249881707],
              [126.795317934884238, 37.728868874270574],
              [126.795319411431663, 37.728869694280107],
              [126.795320707034094, 37.728870688062926],
              [126.795321785584946, 37.728871833771699],
              [126.795322624679841, 37.728873100627737],
              [126.795323199662079, 37.728874456036401],
              [126.79532349722227, 37.728875867470784],
              [126.795323508624165, 37.72887729882612],
              [126.795320203464271, 37.728913529930551],
              [126.795326197079959, 37.728914530165824],
              [126.795324272446379, 37.728963630376711],
              [126.795323459769818, 37.728963647150202],
              [126.795293381891355, 37.728962327182579],
              [126.7952929756364, 37.728979570502034],
              [126.795304717004001, 37.729030269320084],
              [126.795318608001921, 37.729071117003222],
              [126.795315874053429, 37.729096167111862],
              [126.795338257441315, 37.729153473985548],
              [126.795356235461554, 37.72921043094933],
              [126.795370343404301, 37.729256533683092],
              [126.795418203782901, 37.729247675924128],
              [126.795473664160383, 37.72933689321254],
              [126.795566505932328, 37.729478388367866],
              [126.795623513285548, 37.729566899184697],
              [126.795631952945456, 37.729591690690086],
              [126.795638536467393, 37.729645192383302],
              [126.79567091984886, 37.729856974796533],
              [126.79569412401132, 37.730051847775734],
              [126.795699383985124, 37.730139422044914],
              [126.795706831655423, 37.730264165157614],
              [126.795664008060214, 37.730431894416874],
              [126.795636060796753, 37.730539113594887],
              [126.795545002069233, 37.730779681787752],
              [126.795492675017883, 37.730918393151448],
              [126.795419667751602, 37.731249080635855],
              [126.79539385984279, 37.731307586592187],
              [126.795374547430072, 37.731396898622627],
              [126.795368465536527, 37.731480997220515],
              [126.795337238076769, 37.731557524984908],
              [126.795323273895605, 37.731733188707651],
              [126.795286546884739, 37.731940345248177],
              [126.795270290224494, 37.731985364226119],
              [126.795260619693892, 37.732020259362066],
              [126.795191952496225, 37.732171330825821],
              [126.795161260965727, 37.7322472993174],
              [126.795136026841163, 37.732306631535657],
              [126.795226889337599, 37.732303579483336],
              [126.795227933350958, 37.732303584824038],
              [126.795228947167843, 37.732303662990589],
              [126.795230859434895, 37.732304017824269],
              [126.795232666010193, 37.73230462799912],
              [126.795234314838723, 37.732305477881617],
              [126.795235751732534, 37.732306537404362],
              [126.795236938348069, 37.732307781101404],
              [126.795237838765061, 37.732309167297544],
              [126.795238422720146, 37.73231065615402],
              [126.795238675888129, 37.732312202519537],
              [126.795244410392158, 37.732419830238072],
              [126.795244344797169, 37.732421243105932],
              [126.79524400367039, 37.73242263089228],
              [126.795243391875559, 37.732423959376327],
              [126.795242525624388, 37.732425194405046],
              [126.795241427886012, 37.732426307273684],
              [126.795240122747259, 37.732427271086841],
              [126.795238644491064, 37.732428060812737],
              [126.79523702961859, 37.732428656840668],
              [126.79523531686651, 37.732429043178563],
              [126.795233549408081, 37.732429214676671],
              [126.795081721136114, 37.732434314985326],
              [126.79508133109718, 37.732435235598196],
              [126.795026228007472, 37.732555074623242],
              [126.795154580309429, 37.732611330653491],
              [126.795156337687814, 37.732612462396013],
              [126.795575150029549, 37.732882075666659],
              [126.795624468063806, 37.732906593184083],
              [126.795653567307312, 37.732921060997903],
              [126.795740108249262, 37.732971043971673],
              [126.795659781397561, 37.733176157292043],
              [126.795634207883694, 37.73325550936238],
              [126.795599848797224, 37.73326771333322],
              [126.795548449036616, 37.733285973232185],
              [126.79535263808738, 37.733355528951734],
              [126.79535057205608, 37.733356906426941],
              [126.795172749426968, 37.733475438600777],
              [126.794951272606212, 37.733631871020386],
              [126.794648647095102, 37.73384138252765],
              [126.794303748744113, 37.734080936924975],
              [126.794268624048215, 37.734118976577818],
              [126.794251839124897, 37.734137154721424],
              [126.794021309586583, 37.734425335631258],
              [126.793933484960888, 37.73458229068445],
              [126.793959664389405, 37.734623415794182],
              [126.794008481926042, 37.73470010711619],
              [126.794050002726351, 37.734749733342603],
              [126.794332307858966, 37.735026149445169],
              [126.794579279551996, 37.735265058876493],
              [126.794683748334847, 37.735304379208081],
              [126.794794803552008, 37.735336108333314],
              [126.795063201175907, 37.735390346858566],
              [126.795236962308266, 37.735436547098715],
              [126.795546607695243, 37.735508606745661],
              [126.795712684231731, 37.735580404527205],
              [126.795998843880625, 37.735747023652962],
              [126.796008004373846, 37.735769843735476],
              [126.796060765826979, 37.73585665246803],
              [126.796165805621527, 37.735883300630832],
              [126.796192014895666, 37.735892919112679],
              [126.796211478153126, 37.735906747904117],
              [126.796235917569206, 37.735913547316656],
              [126.796326956029546, 37.735939608807719],
              [126.79641709508337, 37.735970173229298],
              [126.796536843041324, 37.736001076526193],
              [126.796543906111495, 37.736011215189706],
              [126.796578119941771, 37.736011842985889],
              [126.796650574060152, 37.736024635950592],
              [126.796664740498315, 37.736030580819644],
              [126.796689264275386, 37.736035069674401],
              [126.796689711788915, 37.73603515166014],
              [126.796729938230413, 37.736042513847813],
              [126.796809850900416, 37.736055320569832],
              [126.796932648360809, 37.73607017484624],
              [126.797033836192, 37.736081053317733],
              [126.797041700059125, 37.736081045235288],
              [126.797238040281314, 37.736080839671857],
              [126.797274897566936, 37.736085407344405],
              [126.797488764612467, 37.7361159063407],
              [126.797591078238412, 37.736166203673733],
              [126.79762205090843, 37.736248750248393],
              [126.797619728259775, 37.736255782875908],
              [126.797700568735962, 37.736318426334861],
              [126.79793895092034, 37.736394577886927],
              [126.798005160968486, 37.736491544336232],
              [126.798039423747568, 37.736537495441709],
              [126.798060568981228, 37.736583709118719],
              [126.798167739335966, 37.736739021647914],
              [126.798264383582435, 37.73685575469144],
              [126.798264520986976, 37.73685610702163],
              [126.798283039075613, 37.736903646171044],
              [126.798334625068435, 37.736969622943988],
              [126.798342583167127, 37.736976114705932],
              [126.79838114831918, 37.736999877554183],
              [126.798453009242678, 37.737044155965371],
              [126.798595973256241, 37.736837756985793],
              [126.798619937830807, 37.736803157562612],
              [126.798763529624225, 37.736605183974952],
              [126.799249435025132, 37.736136401942851]
            ]
          ]
        ]
      },
      id: '31103'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        SIGUNGU_NM: '고양시 일산서구',
        SIGUNGU_CD: '31104'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.767032567931722, 37.707167070211916],
              [126.767193917599386, 37.707134453162311],
              [126.767207373218724, 37.707139551715457],
              [126.76722190883099, 37.707137048067949],
              [126.767232899713633, 37.707136224073317],
              [126.767266941853691, 37.707127561279613],
              [126.767311458408798, 37.707116950027199],
              [126.767711625933302, 37.706995842215854],
              [126.767871551044877, 37.706947440550003],
              [126.768834607588019, 37.706487033308093],
              [126.769737100806211, 37.705956116089567],
              [126.769749167257743, 37.705947976225687],
              [126.770372941097321, 37.705582345025618],
              [126.770747160950123, 37.705468763494238],
              [126.770966153203986, 37.705415981087626],
              [126.771462578987823, 37.70525984096156],
              [126.771525785445689, 37.705047958790004],
              [126.772066019549442, 37.704812580700533],
              [126.772442531160053, 37.704648533250293],
              [126.772683048486087, 37.704681939989264],
              [126.772745371408931, 37.704637858851832],
              [126.772776610135281, 37.704621027457222],
              [126.773418019426046, 37.703871367256667],
              [126.773429980553118, 37.703823854129915],
              [126.773436417512769, 37.703816620167316],
              [126.773599004512604, 37.7030903401754],
              [126.773601731204494, 37.703079456444357],
              [126.773686863189198, 37.702739820182863],
              [126.774393477941075, 37.702501795769138],
              [126.774741979456849, 37.701782145167783],
              [126.775155636393905, 37.701546951940216],
              [126.775465209508113, 37.701370936351196],
              [126.775492129870301, 37.701355626147368],
              [126.776151259766763, 37.700803244180022],
              [126.776836106682552, 37.700593273535326],
              [126.77711794467362, 37.700235136230873],
              [126.778232861274063, 37.699992730205501],
              [126.779049523802499, 37.700029095849793],
              [126.779333855032419, 37.700043192874816],
              [126.779740368709284, 37.69988935217593],
              [126.779812039786037, 37.699811012964254],
              [126.780844037567647, 37.699489554655599],
              [126.780974266372937, 37.699363338724524],
              [126.781123948714466, 37.699261680489201],
              [126.7811810165162, 37.699222921109637],
              [126.7811998037145, 37.699243582086382],
              [126.781218009457348, 37.699263608592915],
              [126.781305511763534, 37.699171502688046],
              [126.781382826017605, 37.699090119798079],
              [126.781424695350225, 37.699096866256305],
              [126.781784487901859, 37.699154837308484],
              [126.781794328564175, 37.699148818758914],
              [126.781825725516825, 37.699129597403548],
              [126.781824364663763, 37.699128619296602],
              [126.781806827599809, 37.699116047251387],
              [126.781762134114445, 37.699083980120278],
              [126.781581508813687, 37.698998665983062],
              [126.781567940404869, 37.698992256059149],
              [126.781342211902128, 37.698885550655383],
              [126.781128035638147, 37.698784303330569],
              [126.780933845718963, 37.698692583657845],
              [126.780770246743231, 37.698646689411284],
              [126.780491131249235, 37.698514803779013],
              [126.780388734362305, 37.698440914356844],
              [126.780318942087689, 37.698406071566815],
              [126.78010070050523, 37.698297121039474],
              [126.779925647338686, 37.698213812276286],
              [126.779862969499931, 37.698167296427854],
              [126.779795664402457, 37.698113201039448],
              [126.77974445024266, 37.698067976432526],
              [126.77973165541357, 37.698056679569021],
              [126.779671053079582, 37.697997912960915],
              [126.779613859928375, 37.697936991367506],
              [126.779560303105171, 37.697874002708083],
              [126.779510491755815, 37.697809037786271],
              [126.779464428038963, 37.697742458949811],
              [126.779439668846621, 37.697702291825458],
              [126.77942233851924, 37.697674179258442],
              [126.779384334973869, 37.697604368847195],
              [126.779234715401714, 37.697311016251625],
              [126.778995988361871, 37.696842953283706],
              [126.778921972083879, 37.69669846099243],
              [126.778850005575222, 37.696557971324943],
              [126.778700694412137, 37.696266581172885],
              [126.778675049516096, 37.696216535421783],
              [126.778649977773483, 37.69610792279488],
              [126.778521692883487, 37.695857570070579],
              [126.778472702067006, 37.695790332086474],
              [126.778418484818218, 37.695715923656365],
              [126.778296289905811, 37.695477656769555],
              [126.778266879140133, 37.695416787376438],
              [126.778240644319609, 37.69535511000494],
              [126.778217476239107, 37.695292539264763],
              [126.778206337812577, 37.695257527865621],
              [126.778197370019527, 37.695229343720676],
              [126.778180553631145, 37.695165524769763],
              [126.778084237039366, 37.69476344637399],
              [126.77796823670981, 37.694279200311605],
              [126.777968373866955, 37.694268565590932],
              [126.777970012394718, 37.694141356292398],
              [126.777927796257416, 37.693964956676425],
              [126.777881759774004, 37.693774440625482],
              [126.777831372477834, 37.693565916596043],
              [126.777789269276155, 37.693389515805229],
              [126.777724234497299, 37.693261813398848],
              [126.777639809653991, 37.692907665600941],
              [126.777629642303623, 37.692744210425566],
              [126.777566381008, 37.692478575529584],
              [126.77751305858385, 37.692376574652968],
              [126.777307452261425, 37.691514219793959],
              [126.777163167344511, 37.690909046038584],
              [126.777147184267434, 37.690833062398603],
              [126.777135281031164, 37.690756725211337],
              [126.777127462999829, 37.690679947984187],
              [126.777123840950864, 37.690603001792731],
              [126.777124299776929, 37.690526058984993],
              [126.777128840538239, 37.690449125876533],
              [126.777137689755833, 37.690372467941977],
              [126.777178815451265, 37.690080544780365],
              [126.777194762842129, 37.690017866544423],
              [126.777219726618583, 37.689919717168394],
              [126.777255803104396, 37.689777875940791],
              [126.777258268817988, 37.689760467649101],
              [126.777284877786499, 37.68957260019323],
              [126.777275379833284, 37.689485668315193],
              [126.777269871377555, 37.689435263420094],
              [126.777327123643389, 37.68902944281411],
              [126.777348184816745, 37.688880147537247],
              [126.777391470316175, 37.688775266661679],
              [126.777414703351127, 37.688610530650053],
              [126.777429350783748, 37.68850666941929],
              [126.777423948153384, 37.688342953982094],
              [126.777453133886809, 37.688136056444698],
              [126.777506661295703, 37.68775658093832],
              [126.777529722002043, 37.687699101759165],
              [126.777559322791589, 37.687625319913209],
              [126.777584572945287, 37.687446882611603],
              [126.777612356587582, 37.687249864789884],
              [126.777641565167201, 37.687042730256849],
              [126.777649454118745, 37.686986980578148],
              [126.777666816011688, 37.686864295626805],
              [126.777663225966705, 37.686831130305116],
              [126.777658873830731, 37.686790942639114],
              [126.777652039573084, 37.686726961149375],
              [126.77768269903757, 37.686507715426103],
              [126.777768826835327, 37.685891801425207],
              [126.777774237912951, 37.685853431124627],
              [126.777781450400028, 37.685802271309917],
              [126.777801609425637, 37.685659145281953],
              [126.777844890940059, 37.685554174917833],
              [126.77788265653048, 37.68528548833855],
              [126.7778770253435, 37.685121860661809],
              [126.777904711696067, 37.684924871835541],
              [126.777909033624169, 37.684894129935429],
              [126.777914790573533, 37.684853174049174],
              [126.777922269833326, 37.684741018109683],
              [126.777930808780667, 37.684672803506018],
              [126.777931561128099, 37.684666793603363],
              [126.777933145472588, 37.68465392794613],
              [126.777935975636751, 37.684630944486493],
              [126.777948542964893, 37.68452997263028],
              [126.777944654805921, 37.684524046078046],
              [126.777951615282674, 37.684505289861292],
              [126.777954216560033, 37.684484389752676],
              [126.777995104617901, 37.684156608216114],
              [126.77800950866397, 37.684041401322311],
              [126.778031725017698, 37.683862600930247],
              [126.778053537843917, 37.683687042810888],
              [126.778083276395122, 37.683448342534128],
              [126.778115453707599, 37.683189916449379],
              [126.778140895064809, 37.682985353480468],
              [126.778138623514579, 37.682979380045566],
              [126.778149220240678, 37.682886491735452],
              [126.778154440605675, 37.682876649724179],
              [126.778171789886201, 37.68273763260315],
              [126.778198042559183, 37.682527138474434],
              [126.778226388630515, 37.682298617214229],
              [126.778231034208801, 37.682261595965855],
              [126.778242197756555, 37.682171983799222],
              [126.778243926812436, 37.682158099624147],
              [126.778254243975681, 37.682075075434483],
              [126.778249428567889, 37.6820718066176],
              [126.778261722282721, 37.681970420201871],
              [126.778267767365804, 37.681966280478285],
              [126.778270179354948, 37.681946871781527],
              [126.778298055264344, 37.681723753723674],
              [126.778323381719929, 37.681519819980956],
              [126.778356600778011, 37.681253649658558],
              [126.778359659934338, 37.681228773901083],
              [126.77836066573829, 37.681220591557398],
              [126.778365709272123, 37.681180405490728],
              [126.778366127016042, 37.681177085778927],
              [126.778349715825144, 37.681130381668787],
              [126.778354754418245, 37.681037324099513],
              [126.778357551564056, 37.680974171252622],
              [126.778358761051365, 37.680911014097788],
              [126.778359064400433, 37.680847858608047],
              [126.778358920332394, 37.680787808133751],
              [126.778358911880744, 37.680784700328125],
              [126.778357174852744, 37.680721447627548],
              [126.778354530838115, 37.680658284916277],
              [126.77835037087425, 37.680607150066791],
              [126.778349391949632, 37.680595115950894],
              [126.778343119265529, 37.680532037389341],
              [126.778331740123946, 37.680432999129401],
              [126.778285597281538, 37.680282720751137],
              [126.778284392101824, 37.680278239219213],
              [126.778269004601626, 37.680221062600708],
              [126.778251959208632, 37.680159313347403],
              [126.778235008241467, 37.680101983845084],
              [126.778233780567575, 37.680097834762272],
              [126.778213217650205, 37.680036527253399],
              [126.778190159079557, 37.679976118405122],
              [126.778164834890433, 37.679916158963586],
              [126.778138939769576, 37.679856372680419],
              [126.778111683713291, 37.679796949404846],
              [126.778091742059758, 37.679756394082055],
              [126.778082727103381, 37.679738061913206],
              [126.77805195225227, 37.679679801418565],
              [126.778039560471356, 37.679657688263589],
              [126.778032896364849, 37.679645797801363],
              [126.778019593663188, 37.679622076519152],
              [126.777985869928017, 37.679564799321128],
              [126.777950782660497, 37.679508154604065],
              [126.777914791581665, 37.679451863969405],
              [126.777877434454965, 37.679396114764728],
              [126.77787578115128, 37.679393761206882],
              [126.777724149951354, 37.679177880223328],
              [126.777667413841527, 37.67909554497399],
              [126.777615232671124, 37.679019822648485],
              [126.777605524565033, 37.679005747641476],
              [126.777624705436494, 37.678997587399117],
              [126.777571660058342, 37.67892036356718],
              [126.777507471257593, 37.678779228173902],
              [126.777469294990738, 37.678695285904851],
              [126.777448323929207, 37.678665055103245],
              [126.777423134712407, 37.678628700115425],
              [126.777278094647457, 37.678419407924686],
              [126.777152332618556, 37.678237590609626],
              [126.777037008232824, 37.678070901823368],
              [126.776762017507878, 37.677672684945733],
              [126.776592145353163, 37.677426691035258],
              [126.776304701724527, 37.677010433350944],
              [126.776187572493654, 37.676840812359657],
              [126.775982862344463, 37.67654436048403],
              [126.775950537263213, 37.6764387975782],
              [126.775931821210989, 37.676411780936796],
              [126.775870970569144, 37.676323951224767],
              [126.775832369287144, 37.676268183802385],
              [126.775799528330168, 37.676220743894966],
              [126.775720977720042, 37.676107342186242],
              [126.775680382842651, 37.676066181901604],
              [126.775587966200973, 37.675972482551003],
              [126.775253232267644, 37.67548773779118],
              [126.77519653905037, 37.675405636833865],
              [126.775101546692412, 37.675268071080573],
              [126.77506921014853, 37.675166199660282],
              [126.775042725986893, 37.675127746028529],
              [126.774978199154447, 37.67503405268544],
              [126.774896542003745, 37.674915488377906],
              [126.774928317721844, 37.674828965796053],
              [126.774812610076623, 37.674660869969834],
              [126.774705315935464, 37.674504999017223],
              [126.774596463735307, 37.674479741500285],
              [126.774429208345495, 37.6742380525911],
              [126.774335934472347, 37.674158944776615],
              [126.774294857243419, 37.674099501339249],
              [126.774149127880079, 37.673888606535947],
              [126.773978772304076, 37.673642074022588],
              [126.773964989509878, 37.673622127653765],
              [126.77370510811501, 37.673246032012322],
              [126.773601377208948, 37.673095916188032],
              [126.773563292830303, 37.672984213365126],
              [126.773504723179499, 37.672899678166907],
              [126.773467048180791, 37.67284521626619],
              [126.773459034215662, 37.672833625474169],
              [126.773396271949991, 37.672742878622515],
              [126.77333623717314, 37.672656180153602],
              [126.773208768048775, 37.672527997394091],
              [126.772876867542138, 37.672047581432786],
              [126.772717963663624, 37.671817571287768],
              [126.772673501175987, 37.671753211319285],
              [126.772284898974874, 37.671190705799177],
              [126.772231922783973, 37.671114023434967],
              [126.772163355806697, 37.671014771274486],
              [126.772073962548703, 37.670855837494805],
              [126.772072495780407, 37.670853230832293],
              [126.77185990884719, 37.670945794857793],
              [126.771792090161668, 37.670847546667105],
              [126.771601999216841, 37.67057231239211],
              [126.771493342748713, 37.670414986132272],
              [126.7712888196817, 37.670118850672424],
              [126.771107127974346, 37.669855771329551],
              [126.771063715794625, 37.669792913429312],
              [126.770818524699195, 37.669437886674721],
              [126.77043054675093, 37.668876103458722],
              [126.770073218414382, 37.668358689635113],
              [126.769980078582478, 37.668223820682144],
              [126.769923242053778, 37.66814152156514],
              [126.769853048110249, 37.668043536941902],
              [126.769778994060729, 37.66794734904849],
              [126.769701306978604, 37.667853044007963],
              [126.769619874841212, 37.66776071485711],
              [126.769534807893805, 37.667670451501984],
              [126.769530187142266, 37.6676658495753],
              [126.769446335446233, 37.667582342783447],
              [126.76939451578653, 37.667533975015374],
              [126.769354342097586, 37.667496479012563],
              [126.769259056003236, 37.667412950825749],
              [126.769205117286859, 37.667368525748905],
              [126.769160587384519, 37.667331849931578],
              [126.769058825380881, 37.667253265767414],
              [126.768954107897144, 37.667177196813967],
              [126.768846548542811, 37.667103735702504],
              [126.768736035560394, 37.667033062898291],
              [126.768384516132073, 37.666815138847028],
              [126.767636609391346, 37.66635146460419],
              [126.767417697538463, 37.666215726968979],
              [126.767225532864202, 37.666096575394981],
              [126.766668131653958, 37.665751003368193],
              [126.766468894166522, 37.665627482042296],
              [126.766135654760859, 37.665420880131926],
              [126.765751962762351, 37.665182995578675],
              [126.765543244011994, 37.665053590222925],
              [126.765417735645045, 37.664975776198602],
              [126.765103377073913, 37.664780873659446],
              [126.765032484062999, 37.664736919684799],
              [126.764686033843475, 37.664522115853273],
              [126.764590143919165, 37.664462663463723],
              [126.764006700623057, 37.664100918018377],
              [126.763993920318555, 37.664092994059843],
              [126.763725400475678, 37.66392650505329],
              [126.763409644415646, 37.663730725812407],
              [126.763237249196763, 37.663623833819734],
              [126.762707766539975, 37.663295529986549],
              [126.762694587588697, 37.66328735913698],
              [126.762688825355923, 37.663293232188003],
              [126.762555294901162, 37.6634293407526],
              [126.762491734080172, 37.663494081921812],
              [126.7623708703929, 37.663614658565265],
              [126.762246726403077, 37.663733154139877],
              [126.762119415461598, 37.663849571147217],
              [126.762038417019156, 37.663920501416335],
              [126.76198905158941, 37.663963730026026],
              [126.761855521410681, 37.664075630056743],
              [126.76171916506398, 37.664185271555219],
              [126.761654579439536, 37.664234977195264],
              [126.761579754408444, 37.664292564753829],
              [126.761437518496578, 37.664397507470838],
              [126.761348408207951, 37.664460470145826],
              [126.761292570188061, 37.664499923743875],
              [126.761144795236476, 37.664599901176921],
              [126.76099442129771, 37.664697350152373],
              [126.76084144927988, 37.664792179632556],
              [126.76068587916275, 37.66488439231135],
              [126.760595795105473, 37.664935440935771],
              [126.760527935223706, 37.664973896748513],
              [126.760367622246235, 37.665060783096941],
              [126.76020504860135, 37.66514487176233],
              [126.760040218835101, 37.665226162764078],
              [126.759873242951386, 37.665304654980531],
              [126.759704121839832, 37.665380260078699],
              [126.759694889883818, 37.665384286926056],
              [126.759372940457666, 37.665524701685165],
              [126.759316425696781, 37.665549349473579],
              [126.759058169789782, 37.665661983493557],
              [126.758327158518995, 37.665980796888221],
              [126.758090258697393, 37.666084112696275],
              [126.758088576561136, 37.666081672151407],
              [126.757995052889299, 37.665945797213624],
              [126.757977227976397, 37.665919814432534],
              [126.757887436975537, 37.665789437519656],
              [126.757363129425258, 37.665027483941159],
              [126.756786496731394, 37.664189466495188],
              [126.756661064379543, 37.664007209270281],
              [126.7563829055144, 37.663602997556879],
              [126.756337882847532, 37.663548384703368],
              [126.756325336138573, 37.663533162482508],
              [126.756320705011674, 37.663527548690723],
              [126.756318655522961, 37.663524551490738],
              [126.756237192579519, 37.663405626627551],
              [126.756233064372353, 37.663399608605907],
              [126.756206929639248, 37.663361496334701],
              [126.75617204441788, 37.663310644566955],
              [126.756117879708825, 37.663231661709354],
              [126.755966779149645, 37.663011357467873],
              [126.755763889202882, 37.662715523535873],
              [126.755714438930113, 37.662643424791668],
              [126.755524273215187, 37.662366429049285],
              [126.755483438008284, 37.662308053135128],
              [126.755482272309351, 37.662308183679478],
              [126.755454556440725, 37.662268168480672],
              [126.755362268904079, 37.662134913948854],
              [126.755335665266813, 37.662096501083738],
              [126.755288520178496, 37.662028434805563],
              [126.755063912197912, 37.662126079920462],
              [126.754417089885166, 37.662407272383504],
              [126.754379575866622, 37.662423643912284],
              [126.75416257546911, 37.662518343895918],
              [126.754161263353282, 37.662518489725144],
              [126.753973425771832, 37.662600153164256],
              [126.753947195991955, 37.662611555731417],
              [126.753581738673432, 37.662770432458871],
              [126.753256566481824, 37.662911802541913],
              [126.753234848247445, 37.662921240688291],
              [126.753102885032064, 37.662978610712116],
              [126.752966465634842, 37.663037920851345],
              [126.75283457145602, 37.663095262166898],
              [126.752715814744406, 37.663146910896067],
              [126.752500820123799, 37.663240390195455],
              [126.752164578415659, 37.663386574053817],
              [126.752116788525285, 37.663409087108143],
              [126.752092393166407, 37.66341795375422],
              [126.751963343187299, 37.663474081816176],
              [126.751785603050152, 37.663544441906822],
              [126.75093556887191, 37.663920872742878],
              [126.750661238384168, 37.6640423553416],
              [126.750386369465019, 37.664164074526816],
              [126.750385251076267, 37.664164570356569],
              [126.749804468805891, 37.664421880766142],
              [126.749758294243279, 37.664442265193706],
              [126.749739713901235, 37.664450426548811],
              [126.749739111447994, 37.66445069131052],
              [126.749671816000443, 37.66447987435366],
              [126.748999389302398, 37.664771475274414],
              [126.748540620779522, 37.664970419606433],
              [126.748461845260266, 37.665004580718659],
              [126.748428682957424, 37.664956590354713],
              [126.748343422858142, 37.664833143732579],
              [126.748229282995325, 37.66466793230957],
              [126.748125169568269, 37.664517259914049],
              [126.748052896327422, 37.664412665885571],
              [126.747966275490185, 37.664287334659633],
              [126.747879322526245, 37.66416146226053],
              [126.747842888198718, 37.664108699095891],
              [126.747761687775153, 37.663991108582493],
              [126.747589251488307, 37.663741526944541],
              [126.747519744561913, 37.663640915143198],
              [126.747445005822001, 37.663532730363151],
              [126.747392111966533, 37.663456123927581],
              [126.747383029585563, 37.663442937322209],
              [126.747359293312428, 37.663408482458145],
              [126.747301213530221, 37.663324476386549],
              [126.747168033749603, 37.663130314534136],
              [126.746998073061988, 37.662882528825968],
              [126.746963226719686, 37.662831728685944],
              [126.746931313772023, 37.662785169956749],
              [126.746812238572105, 37.662611254906658],
              [126.746783354205604, 37.662569067057781],
              [126.746646772634591, 37.662373978854795],
              [126.746566361527172, 37.6622592055678],
              [126.746507157854879, 37.662174620479703],
              [126.746334211524328, 37.661927527667252],
              [126.746332290316559, 37.661924782583348],
              [126.74632895193001, 37.66192001489091],
              [126.746208849145304, 37.661747307634705],
              [126.746092454898445, 37.661579926434534],
              [126.745859232192643, 37.661244530882179],
              [126.745689087988836, 37.660991525222158],
              [126.745632248551146, 37.660906989956629],
              [126.745526600539463, 37.66074989880665],
              [126.745484543785679, 37.660687279931878],
              [126.745429515951415, 37.660606612010611],
              [126.745356396215882, 37.660499692678812],
              [126.745338143623314, 37.660473177077549],
              [126.745171725969769, 37.66023205769806],
              [126.74508861835946, 37.660111058720986],
              [126.744969648258731, 37.659937902257575],
              [126.7448992836202, 37.659835578988364],
              [126.744867363147378, 37.659790909713067],
              [126.74481454131535, 37.659718679378372],
              [126.744690833762562, 37.659549518503027],
              [126.744608066962257, 37.659428154524505],
              [126.74450533526668, 37.659279722993411],
              [126.744351313491819, 37.659051800571739],
              [126.744282891206481, 37.658950509667029],
              [126.74427396434821, 37.658937298576504],
              [126.744165496066572, 37.658776689177984],
              [126.744131219261234, 37.658725889128704],
              [126.744055514374381, 37.658613877962502],
              [126.744047371777, 37.658601820165188],
              [126.744027520321239, 37.65857240717456],
              [126.74399368518884, 37.658522455355417],
              [126.743990729636991, 37.65851808843356],
              [126.743874483540822, 37.658346019972861],
              [126.743819346740835, 37.658264361834874],
              [126.74370907735792, 37.6581012239687],
              [126.743633420280418, 37.65798942914936],
              [126.743587982497147, 37.657922117250386],
              [126.743378627431895, 37.657636113490391],
              [126.743368935893756, 37.65762287669444],
              [126.743352599520591, 37.657594910224681],
              [126.74331722491408, 37.657534647118027],
              [126.743580830887197, 37.657499635995251],
              [126.744748336707076, 37.657345665007796],
              [126.744768355241732, 37.657343024318251],
              [126.745724077298206, 37.657215902484204],
              [126.745656218552867, 37.657159624393856],
              [126.745887060969508, 37.657128140864124],
              [126.74576328696152, 37.656538634373774],
              [126.745702644055356, 37.656245868321697],
              [126.745671381206051, 37.65607623775032],
              [126.745649991325536, 37.655968614615432],
              [126.745605492245801, 37.655740005137012],
              [126.745595091635025, 37.655686580308952],
              [126.745569258693408, 37.655553001284694],
              [126.745525499028588, 37.655327214026833],
              [126.745483533241199, 37.655106564944511],
              [126.745420752664003, 37.654776219675995],
              [126.744281248597716, 37.654920869815633],
              [126.744222162240689, 37.654928983520186],
              [126.744217302243854, 37.654929650005336],
              [126.743190699240898, 37.655063814873678],
              [126.743056134581181, 37.655078115299503],
              [126.742890816895965, 37.654974501828988],
              [126.742771545915744, 37.65489243151324],
              [126.742437581716857, 37.654664022710755],
              [126.7424254926255, 37.654653725872954],
              [126.742377135458824, 37.654612174369618],
              [126.742278194899669, 37.65458249463029],
              [126.742162089188994, 37.654502774851757],
              [126.7421336531945, 37.654483064098855],
              [126.742065206497614, 37.654435618533611],
              [126.742034769292246, 37.654389511376451],
              [126.74202188788685, 37.654379213011389],
              [126.741585810228557, 37.654090211234305],
              [126.741490963514408, 37.654024502440663],
              [126.741279674120008, 37.65387807930145],
              [126.740964024833232, 37.653665742951162],
              [126.74095520513383, 37.65366016469487],
              [126.740865221599563, 37.653602598597409],
              [126.740797023329193, 37.653558970096249],
              [126.740670383420181, 37.653478593387533],
              [126.740624364517345, 37.653449389919381],
              [126.740582535875873, 37.653420828112083],
              [126.740307943183765, 37.653234413178659],
              [126.740271813320248, 37.653209885329574],
              [126.740184918675808, 37.653150894688238],
              [126.740151435763849, 37.653128164866068],
              [126.740138506825318, 37.653119796622747],
              [126.740028309436582, 37.653048664555413],
              [126.739883622519528, 37.652955278208701],
              [126.739682575017, 37.652830858208787],
              [126.739657083383989, 37.65282981060313],
              [126.739370023337997, 37.652610236376155],
              [126.739048050917603, 37.652393376355882],
              [126.738826076812686, 37.652227464498573],
              [126.738529268232682, 37.652042373931216],
              [126.738484605964715, 37.652014479896614],
              [126.738491579190935, 37.652000241647258],
              [126.738494030719181, 37.651992742242022],
              [126.738350340286445, 37.651955346126677],
              [126.738334511777865, 37.651951211405837],
              [126.7381165191848, 37.651893963087524],
              [126.738133899252148, 37.651833998555944],
              [126.73820215438424, 37.651598996667012],
              [126.738146084848324, 37.651587196768169],
              [126.737928546472375, 37.651541416768218],
              [126.737889581512448, 37.651530680846918],
              [126.737867507414748, 37.65152405411439],
              [126.737801718798238, 37.651494354075652],
              [126.737777347111688, 37.65143258395802],
              [126.737804552111214, 37.65139782265787],
              [126.737645347410009, 37.651219929053099],
              [126.737607540568078, 37.651160246061963],
              [126.737282185959927, 37.651047966917446],
              [126.736861260330798, 37.650913283658895],
              [126.736756163495826, 37.650832429217594],
              [126.736644263648429, 37.650831798888035],
              [126.736575679934575, 37.65082376230729],
              [126.736473339551281, 37.650811864050496],
              [126.73628706621956, 37.650742700275245],
              [126.736205753316113, 37.650681431849243],
              [126.736171249399789, 37.650570130280883],
              [126.736166597073705, 37.650555523057967],
              [126.736042482729601, 37.650453343916276],
              [126.735791896609811, 37.650261983271335],
              [126.735705830637414, 37.650196260013985],
              [126.735411218191402, 37.649971433795244],
              [126.73523916537124, 37.649840043957994],
              [126.734601254020504, 37.649350364771685],
              [126.733657592104592, 37.648542783372186],
              [126.732528593579843, 37.647568927632229],
              [126.732470070656461, 37.647518443212888],
              [126.732460069815914, 37.647521475991972],
              [126.732235774241161, 37.647711853171167],
              [126.732118610323568, 37.647796332320027],
              [126.731931429963694, 37.647953295083845],
              [126.731526263715267, 37.648220974927064],
              [126.731380805153108, 37.648245978161576],
              [126.731097670626994, 37.648305019207591],
              [126.730631570416278, 37.64842980588061],
              [126.730525994501335, 37.648456037086461],
              [126.730149157784737, 37.648465303736607],
              [126.730115070833264, 37.648487751355852],
              [126.729818195307118, 37.648512674694452],
              [126.729771375176824, 37.648510611422104],
              [126.729535514757885, 37.648518229531263],
              [126.729332014625555, 37.64852648040209],
              [126.729254227812348, 37.648520118101764],
              [126.729187014065829, 37.648525030085359],
              [126.728461764777279, 37.648586978695725],
              [126.728416777320845, 37.648590533100865],
              [126.726512151454742, 37.648753355586194],
              [126.726221258716933, 37.648781038293869],
              [126.725224184619194, 37.648875916123778],
              [126.72483670718411, 37.648943149195766],
              [126.724214883328528, 37.649211971152539],
              [126.72122215435148, 37.650505687544111],
              [126.718904694495848, 37.651524167201032],
              [126.717516287270456, 37.652153489032614],
              [126.717492736939491, 37.652164112620696],
              [126.714562267617197, 37.653486025794265],
              [126.714522662559389, 37.653503890355168],
              [126.713578297424945, 37.653943444914695],
              [126.710513058447191, 37.655370068393466],
              [126.708591268626378, 37.656264440842513],
              [126.708589744235525, 37.656265148967513],
              [126.706206431367605, 37.657370391593282],
              [126.702195040362824, 37.659230457274788],
              [126.700922833990859, 37.659820325510459],
              [126.700850061363397, 37.659854066412919],
              [126.699292554890818, 37.660602996217477],
              [126.697784206321487, 37.661328250944116],
              [126.694776232837427, 37.662774466617627],
              [126.692019914797697, 37.6640995683642],
              [126.692005917565723, 37.664106299065232],
              [126.688777396764181, 37.665842844485866],
              [126.680262313477883, 37.670337372291669],
              [126.679343389569837, 37.670822337449508],
              [126.677971164685687, 37.671519653309275],
              [126.675638616285681, 37.67355843082008],
              [126.675454592594278, 37.673864585153609],
              [126.674638670395666, 37.67522194808619],
              [126.674621416521845, 37.67525065141961],
              [126.672832405608645, 37.677711162612667],
              [126.672592355451798, 37.678041298867171],
              [126.671446646849887, 37.68063552679039],
              [126.671042556517506, 37.681550459910788],
              [126.670453855143037, 37.683526710386246],
              [126.670356094832627, 37.685116642376201],
              [126.671207558022644, 37.689012800800832],
              [126.671491731525421, 37.690313047143086],
              [126.67149315274645, 37.690321400587742],
              [126.672328188780199, 37.695225160893401],
              [126.672461968124964, 37.696010705229462],
              [126.673065579625643, 37.698268464714936],
              [126.673609334992562, 37.700302210638057],
              [126.673951337564318, 37.700299249737121],
              [126.674867965389396, 37.699945042949778],
              [126.675939092601268, 37.699514694324328],
              [126.676018497253295, 37.699475187715016],
              [126.679995024098545, 37.697496594473499],
              [126.680219389979314, 37.697384949676561],
              [126.682881077126183, 37.695994270364096],
              [126.682926295010333, 37.69594399298947],
              [126.683083858772875, 37.695762819320429],
              [126.683334981506235, 37.695413247613679],
              [126.683354410223458, 37.695357132103801],
              [126.683364392876925, 37.695328294316113],
              [126.683341831115484, 37.695301491628669],
              [126.683436880672858, 37.695225348969139],
              [126.683429915357692, 37.69520949890638],
              [126.683429459711959, 37.69520812395195],
              [126.683475584073761, 37.695193640185941],
              [126.683488327584627, 37.695184134849683],
              [126.68490034781864, 37.694052943747835],
              [126.686961831451185, 37.692401365561693],
              [126.687342096218046, 37.691960347420739],
              [126.687361766527317, 37.69194242605289],
              [126.687693321896077, 37.691640313267492],
              [126.687849653382216, 37.69149543936615],
              [126.688083608275903, 37.691168191522152],
              [126.688193937463311, 37.691013864671199],
              [126.689374110472443, 37.689363030318205],
              [126.689915486022244, 37.68860572463646],
              [126.690005297735667, 37.688615155536596],
              [126.690255853460144, 37.688641466886992],
              [126.690256524128827, 37.688641537288326],
              [126.690313557200227, 37.688642813999117],
              [126.690310323784018, 37.688654068147393],
              [126.690321122602015, 37.688655501477854],
              [126.690675328816383, 37.688804247952568],
              [126.690696906160554, 37.68881190940143],
              [126.690743486759843, 37.688815690126333],
              [126.690811592681584, 37.688752802271431],
              [126.690812780626146, 37.688764346351917],
              [126.690822350842055, 37.688807456115946],
              [126.690834374928059, 37.688854788252215],
              [126.690891047629506, 37.689113685927154],
              [126.690892394444788, 37.689128042297156],
              [126.690913236809834, 37.689186659903228],
              [126.691003816821606, 37.689299797892289],
              [126.691071650798349, 37.689346748556069],
              [126.691073240319454, 37.689347849149584],
              [126.69115254046352, 37.689403521130345],
              [126.691186891174283, 37.689409522455207],
              [126.691203840703665, 37.689423081819498],
              [126.691264818777583, 37.689454780168745],
              [126.69141970084155, 37.68957174956752],
              [126.691637836952395, 37.689724081946224],
              [126.691731199041584, 37.689809072360426],
              [126.691858470824613, 37.689916396236164],
              [126.691949457403567, 37.69006304175543],
              [126.691954210982729, 37.690070658892616],
              [126.69203180569788, 37.690197402582392],
              [126.692110628817602, 37.690326151047678],
              [126.692195851240157, 37.690452509747374],
              [126.692398365671849, 37.690614656272579],
              [126.692478014619567, 37.690672579431229],
              [126.692528533207707, 37.690709318657916],
              [126.692716698199447, 37.690831154222558],
              [126.692749215171403, 37.690850384655327],
              [126.69279519262524, 37.690868813117966],
              [126.69280615820864, 37.690873625490852],
              [126.693072835118826, 37.690944427379293],
              [126.69316962440557, 37.69097227692702],
              [126.69318253032769, 37.690976806909994],
              [126.693225311152347, 37.690999163795716],
              [126.693355595993907, 37.691065670805692],
              [126.693429926049774, 37.691127527228851],
              [126.693588918815777, 37.691276313064279],
              [126.693680902539384, 37.691391146749829],
              [126.693721814371727, 37.691442221881509],
              [126.693749029204128, 37.691501693732214],
              [126.693880992282388, 37.691547932959757],
              [126.693937894954459, 37.691540198396467],
              [126.693952032526781, 37.691538675373948],
              [126.694548096691534, 37.691474494477319],
              [126.694657537313091, 37.69139819758928],
              [126.694695405341008, 37.691323966154805],
              [126.694706862043006, 37.691251081168907],
              [126.694714893258038, 37.69115114866905],
              [126.694739581577721, 37.6910918034554],
              [126.694794564551842, 37.691033946774354],
              [126.694972383193402, 37.691044261438471],
              [126.695231162041992, 37.691098149048536],
              [126.695621472693574, 37.691173495995145],
              [126.695740768770577, 37.691198582111312],
              [126.69620059587325, 37.691345045405754],
              [126.696272055258731, 37.691370574618581],
              [126.696391756293494, 37.691512785986561],
              [126.696391538738396, 37.691524326740613],
              [126.696415175730181, 37.691591402330275],
              [126.696413684255248, 37.691653618935433],
              [126.696392937864829, 37.691700307161383],
              [126.69637041061911, 37.691751207708549],
              [126.69633199961946, 37.691743783178929],
              [126.696255509549502, 37.691734017055701],
              [126.696153029376333, 37.691841021096558],
              [126.696120130445706, 37.691870496942514],
              [126.696105642653691, 37.691903407022174],
              [126.696074988192649, 37.691949493162035],
              [126.69592898848714, 37.691955313440722],
              [126.695766469515902, 37.691929553275834],
              [126.695522366098999, 37.691839386289857],
              [126.695450333939618, 37.691822026738265],
              [126.695362015289518, 37.691803211248853],
              [126.695169240393767, 37.691810593345103],
              [126.695096877606716, 37.691830965759685],
              [126.694938736536429, 37.691902632198897],
              [126.694790434477113, 37.692036824006841],
              [126.694738319746236, 37.692086241422814],
              [126.694679467476973, 37.692140704327265],
              [126.694652968197573, 37.69216316436956],
              [126.69470928306356, 37.692212858987951],
              [126.694699927824203, 37.692290263984667],
              [126.694695521647574, 37.692339314976266],
              [126.694688044952102, 37.692422567008357],
              [126.69471024394484, 37.692580852439882],
              [126.694735095222711, 37.692742252892593],
              [126.694797223289356, 37.69288374908875],
              [126.694798531000828, 37.692910501820847],
              [126.6948112889567, 37.693039202002034],
              [126.694920338434429, 37.693315969620009],
              [126.694921806837698, 37.693319473140143],
              [126.694932268036894, 37.693344439768332],
              [126.694976377902051, 37.693387350554005],
              [126.695197299886459, 37.69338763550634],
              [126.695278392846049, 37.693398271054185],
              [126.69535941484682, 37.693468583891224],
              [126.695365596019101, 37.69347366892417],
              [126.695394201517189, 37.693497109916834],
              [126.695544973396778, 37.693476631448291],
              [126.695652147478498, 37.693462072667202],
              [126.695780617723145, 37.693444622506625],
              [126.695757808877289, 37.693485275756032],
              [126.695745777609559, 37.693506719789298],
              [126.695676040502079, 37.693631018485455],
              [126.695670467835527, 37.693644569590532],
              [126.695642392259188, 37.693712859945627],
              [126.695608779774759, 37.693789074733075],
              [126.695553703259392, 37.693912251175853],
              [126.695828142196333, 37.694038255743742],
              [126.695911290367661, 37.693966839302497],
              [126.696076515822654, 37.693824920982919],
              [126.696192428197449, 37.693854215253239],
              [126.696300232391991, 37.693831969265474],
              [126.696402132631761, 37.693911355714015],
              [126.696414153741657, 37.693916169537566],
              [126.696552982717847, 37.6939764977555],
              [126.696615583723258, 37.694003408505488],
              [126.696627087588709, 37.694007385968796],
              [126.696712710539458, 37.694035473803268],
              [126.696821799884376, 37.694090098383256],
              [126.696888314749231, 37.694113917099962],
              [126.696920341308982, 37.694123006861759],
              [126.697027210242538, 37.694155105803027],
              [126.697068465842577, 37.694161402105948],
              [126.697124777947266, 37.694168303846524],
              [126.69713539118662, 37.694171150355864],
              [126.697147963605403, 37.69417428420325],
              [126.697194893543966, 37.694176936325924],
              [126.697329741780337, 37.694256678782402],
              [126.697374812975866, 37.694283823116592],
              [126.697470256920624, 37.694337279849812],
              [126.697520984920587, 37.694366413072302],
              [126.697689045768087, 37.694469051903525],
              [126.697834290372214, 37.694560926811697],
              [126.697888532323745, 37.694596827415332],
              [126.697952513667417, 37.69463584158445],
              [126.698014387028252, 37.694666695302551],
              [126.698141160479423, 37.694772605654109],
              [126.698202480478841, 37.694811045478858],
              [126.698246491489911, 37.694834530145563],
              [126.698283856024375, 37.694883053184498],
              [126.698247007822246, 37.694927727757062],
              [126.698210140152767, 37.694976628448096],
              [126.698188206422515, 37.695011196128306],
              [126.698240366345132, 37.695079748746949],
              [126.698321970240841, 37.695137953447244],
              [126.698352034242333, 37.695150705939511],
              [126.698390433562068, 37.695162346986116],
              [126.698459213625512, 37.695195463755731],
              [126.698453165673939, 37.695202204132805],
              [126.698482627432711, 37.695232129746081],
              [126.69854203209502, 37.695219894398534],
              [126.698558639200868, 37.69522978923856],
              [126.69856553577246, 37.695234306631747],
              [126.69855751101511, 37.695247241831332],
              [126.69847793185383, 37.695341358752685],
              [126.698514531873229, 37.695360311608297],
              [126.698539095646467, 37.69537613980647],
              [126.698572320797012, 37.695394814633403],
              [126.698660725438245, 37.695439519468799],
              [126.69867412473485, 37.695454484016857],
              [126.698730482389479, 37.695494884451733],
              [126.698774471988173, 37.695523152050662],
              [126.69879019774379, 37.695534743451965],
              [126.698792842014228, 37.695536722679172],
              [126.698822175013305, 37.695556503040081],
              [126.698873249978377, 37.695585352321928],
              [126.69891443167046, 37.695610518296604],
              [126.698956128306293, 37.695639905905956],
              [126.698970411794889, 37.695655141413361],
              [126.699003119616265, 37.695672686863197],
              [126.699011604151522, 37.695677214116003],
              [126.699040570478999, 37.69569755975985],
              [126.699149074462866, 37.695765967421195],
              [126.699263399432141, 37.695841160849461],
              [126.699295450940355, 37.695845179343593],
              [126.699332465890237, 37.695845842590465],
              [126.69936724235933, 37.6958766161545],
              [126.699407864034683, 37.695908533504998],
              [126.699420755137979, 37.695919830675194],
              [126.699575121687516, 37.69603622433732],
              [126.699571739297284, 37.6960395939238],
              [126.699587283077747, 37.696051463390972],
              [126.69966637625059, 37.696119515903725],
              [126.699890201790396, 37.696233836170819],
              [126.700014868228692, 37.696337765342321],
              [126.700073145672064, 37.696383522052663],
              [126.700200013041481, 37.696509423776106],
              [126.700290577763184, 37.696589894473952],
              [126.70031072844418, 37.696600365606031],
              [126.700378394595845, 37.696646149786496],
              [126.700513109432023, 37.696763340910813],
              [126.700533944241883, 37.696785636477671],
              [126.700539211531918, 37.69679127385804],
              [126.700640654787804, 37.6968962688942],
              [126.700721383778159, 37.696955605263895],
              [126.700811556398051, 37.696956683347857],
              [126.700845397557217, 37.696955074930777],
              [126.700890830762063, 37.696935765741316],
              [126.70092294502237, 37.696923739024925],
              [126.701092427511483, 37.696852378529904],
              [126.701404386850058, 37.696687057316588],
              [126.701473612008471, 37.696652600211308],
              [126.701745293192289, 37.696498152762523],
              [126.701765531390649, 37.696490322151263],
              [126.701766245335207, 37.696490278313426],
              [126.701831279110863, 37.696486273268455],
              [126.701843670996041, 37.696486304873325],
              [126.702069497150234, 37.696501797332743],
              [126.702223891411833, 37.696525282050636],
              [126.702276476025801, 37.696534424727076],
              [126.70252225378448, 37.696522371032792],
              [126.703330504412151, 37.696604377867644],
              [126.703437356444923, 37.696689113067009],
              [126.703453355720512, 37.696700993215572],
              [126.703479213177744, 37.696720193733569],
              [126.703553666224977, 37.696755853317647],
              [126.70366833095791, 37.696787964661908],
              [126.70384725036584, 37.696837402194362],
              [126.703902969245433, 37.696859509084916],
              [126.703917506120419, 37.69685954503958],
              [126.703990640529696, 37.696868450744553],
              [126.704225316459997, 37.696885376933267],
              [126.704268174720909, 37.696882863079757],
              [126.7044002226598, 37.696875115926424],
              [126.704703138732413, 37.696794231272563],
              [126.704780396355687, 37.696792731583358],
              [126.704798020507624, 37.696814458552936],
              [126.704813607778661, 37.696815340604559],
              [126.704819215843415, 37.696831689511271],
              [126.704844421524058, 37.696864691670065],
              [126.704845385195455, 37.696865693219749],
              [126.704882702438326, 37.696904485068721],
              [126.705015490793144, 37.696972394945703],
              [126.70517002831194, 37.697048514440993],
              [126.705270531277591, 37.697080301636859],
              [126.705378217366388, 37.697134350949753],
              [126.705482073580939, 37.69716868694772],
              [126.705646275738516, 37.697219772039553],
              [126.705718609776213, 37.697252610193402],
              [126.705878105501512, 37.697331285842438],
              [126.70597889378088, 37.69738052290316],
              [126.705991827256881, 37.697381689770616],
              [126.706038531601891, 37.697398696604246],
              [126.706135861893216, 37.697470453021289],
              [126.706190992543824, 37.697507197626763],
              [126.706268433505741, 37.697547935595388],
              [126.706365145443058, 37.697598570765948],
              [126.70641695126794, 37.697624600594885],
              [126.706458840251912, 37.697649203578706],
              [126.706595904467719, 37.697666439718873],
              [126.706696549418254, 37.697661619773591],
              [126.706735559774714, 37.697652994363224],
              [126.706796210864624, 37.697639343679292],
              [126.706845652111141, 37.697634682980542],
              [126.706897005119117, 37.697643822526487],
              [126.706993869193553, 37.697698969284943],
              [126.707021016552659, 37.6977325410502],
              [126.707028278088927, 37.697734812526164],
              [126.707057518598191, 37.697776556156178],
              [126.707144625884212, 37.697883220301826],
              [126.707146911218885, 37.697886019828786],
              [126.707285878277133, 37.698002646888177],
              [126.707505721181889, 37.698144818040355],
              [126.707791963847043, 37.698296163245956],
              [126.707977416015098, 37.698395444148503],
              [126.708038018714092, 37.698439798392464],
              [126.708165753270563, 37.698485447978463],
              [126.708381411635699, 37.69860817508804],
              [126.708494905134799, 37.698673775741703],
              [126.708557622685618, 37.698718137606058],
              [126.708693752160883, 37.698792809409689],
              [126.708730706391137, 37.698812886319914],
              [126.708774589591556, 37.698825665805273],
              [126.709017759248312, 37.698891872872181],
              [126.709253295355978, 37.698963409092904],
              [126.709362421876179, 37.699012664034953],
              [126.70936650342432, 37.699013286270599],
              [126.709536048530637, 37.69903913524076],
              [126.709561438176806, 37.699043006244139],
              [126.709654811574723, 37.699046613699856],
              [126.709810683022639, 37.699052633640093],
              [126.70999782926657, 37.699083782458523],
              [126.710008029999685, 37.699088415058689],
              [126.710008039027272, 37.69908970219609],
              [126.710008392441097, 37.699091245811488],
              [126.710009077842898, 37.699092717743362],
              [126.710010075294278, 37.699094072792498],
              [126.710011350063553, 37.699095270166914],
              [126.710163394418316, 37.699214934162924],
              [126.710164752746351, 37.699215847280108],
              [126.71016627406155, 37.699216580324851],
              [126.710167921114973, 37.699217115922671],
              [126.710169655494695, 37.699217439395241],
              [126.710171431906772, 37.699217543228642],
              [126.710173204999833, 37.699217425316441],
              [126.710174933911802, 37.699217088088872],
              [126.710176574320386, 37.699216539360911],
              [126.710178088632418, 37.699215794203013],
              [126.710179434688953, 37.699214870359143],
              [126.710184077366193, 37.699211145680842],
              [126.710192550058736, 37.699218281356991],
              [126.710233759321184, 37.699265563630831],
              [126.710270507155656, 37.699307723582898],
              [126.710332060101365, 37.699378827715471],
              [126.710396238635923, 37.699460355973457],
              [126.710453758094459, 37.699522154901302],
              [126.710608620397466, 37.699561396690306],
              [126.711006322755836, 37.699667114848566],
              [126.711067012137306, 37.699686967691456],
              [126.711082267356829, 37.699683067926593],
              [126.711122209951895, 37.69970737592562],
              [126.711245368517325, 37.699747096071015],
              [126.711366120753766, 37.699768232918217],
              [126.711412184829484, 37.699768625728204],
              [126.711484812407377, 37.699771334982998],
              [126.711546434572099, 37.699774360870805],
              [126.711593577487562, 37.699776675215681],
              [126.711669871665833, 37.699794881736636],
              [126.711859787184153, 37.699753395677931],
              [126.711862258920476, 37.699753396877199],
              [126.711865106751432, 37.699753124788103],
              [126.7119565132584, 37.699755321141929],
              [126.712026132030147, 37.699759151018142],
              [126.712108115658239, 37.699770335281819],
              [126.712185916514329, 37.699763768996831],
              [126.712306882384709, 37.699731405187933],
              [126.71236720492854, 37.699714457850668],
              [126.71245391825569, 37.699690095884101],
              [126.712514728034762, 37.699678983229575],
              [126.712689742559064, 37.699698578434706],
              [126.71271785928424, 37.699701726088342],
              [126.712795335151753, 37.699693378035903],
              [126.712848712650143, 37.6996876270805],
              [126.712937645975842, 37.699678045812007],
              [126.713033461482368, 37.699642809428418],
              [126.713147912774957, 37.699597194312318],
              [126.713193349590043, 37.699576754665252],
              [126.71320505290177, 37.699573404159473],
              [126.713264240331355, 37.699568197421051],
              [126.713278545075639, 37.699569748488578],
              [126.713521926399082, 37.699596135736201],
              [126.713543532703056, 37.699597602980411],
              [126.713691047228693, 37.699617098898216],
              [126.714028709384436, 37.699624121734921],
              [126.714041806741619, 37.699626684048432],
              [126.714047193718557, 37.699628944400509],
              [126.714071524421527, 37.699639145419241],
              [126.714133621751714, 37.699664641090052],
              [126.714162620447595, 37.699677946684254],
              [126.714457850579478, 37.699800288089207],
              [126.714744740591044, 37.69992684183925],
              [126.714869425617778, 37.700020485908183],
              [126.714876009453135, 37.700025429293468],
              [126.714916157340951, 37.70004213118608],
              [126.715000845192847, 37.700041777495876],
              [126.715129161955247, 37.700032232340504],
              [126.71534876778766, 37.700008837491993],
              [126.715483693581376, 37.699983541294863],
              [126.715563644373546, 37.699969940608149],
              [126.715592209242899, 37.699965782700225],
              [126.715746245732973, 37.699943355134373],
              [126.715796380096734, 37.69996376806651],
              [126.715911113447859, 37.700010480100488],
              [126.716034791058291, 37.700054417759304],
              [126.716047521644882, 37.700061768800033],
              [126.71621763129167, 37.700099633664934],
              [126.716247826604871, 37.700105245870361],
              [126.716328111257937, 37.700120164477418],
              [126.716410624579581, 37.70018005541111],
              [126.716421929141646, 37.700188718261792],
              [126.716512728106352, 37.700258295633688],
              [126.716640791863441, 37.700362494851866],
              [126.716732396867101, 37.700452533644224],
              [126.716948684786317, 37.700555261762524],
              [126.717017679272814, 37.700582735943769],
              [126.717066307612626, 37.700608468710506],
              [126.717139885267699, 37.700641312640336],
              [126.717215947504116, 37.70067499501797],
              [126.717307753950763, 37.700712102301551],
              [126.717394757791254, 37.700754261051394],
              [126.717433793025336, 37.700833190471386],
              [126.717673198376403, 37.701009174508705],
              [126.717893615454301, 37.701192716717003],
              [126.717928672842959, 37.70124403936213],
              [126.717984857059534, 37.701379888587681],
              [126.718050096128891, 37.701510117751198],
              [126.718056710800255, 37.701527060362871],
              [126.718103785669101, 37.701647643040154],
              [126.718106780211329, 37.701651875641701],
              [126.718156405042535, 37.701743217638644],
              [126.718196850865112, 37.701776533419626],
              [126.718299345626676, 37.70189362584393],
              [126.718374364317057, 37.701967009765276],
              [126.718378557725146, 37.701981092660361],
              [126.718513540362224, 37.701990713917382],
              [126.718818783590834, 37.701989673081471],
              [126.718901031708256, 37.701989391468267],
              [126.718924285840416, 37.701976490877904],
              [126.719097248506557, 37.701922568021104],
              [126.719240997734261, 37.701902008307187],
              [126.719244386706109, 37.701901521505356],
              [126.719692809565572, 37.701858674362406],
              [126.719916500517101, 37.701787692435488],
              [126.720163432033871, 37.701755059701611],
              [126.720513504479001, 37.701707818452675],
              [126.720514736855847, 37.701677336931127],
              [126.720514973256712, 37.701675777417144],
              [126.720515547113123, 37.70167427421292],
              [126.720516372882358, 37.701672979976436],
              [126.72051762704767, 37.701671615565516],
              [126.720519068755493, 37.70167054081508],
              [126.720520726012424, 37.701669681101144],
              [126.720522544079387, 37.701669064002594],
              [126.720524468321202, 37.701668707184496],
              [126.720526441918992, 37.7016686201857],
              [126.720572824455829, 37.701669810992975],
              [126.720681142222901, 37.701672593684648],
              [126.720682912492165, 37.701672749595595],
              [126.720684628290698, 37.701673124165914],
              [126.720686250021672, 37.70167370721947],
              [126.720687737001313, 37.701674484066295],
              [126.720689051976919, 37.701675437335219],
              [126.720690163452176, 37.701676541581286],
              [126.720691044477576, 37.70167777048821],
              [126.720691672678427, 37.701679094164781],
              [126.720692032523601, 37.701680479159705],
              [126.720692114162929, 37.701681891157897],
              [126.720692039389945, 37.701683723937499],
              [126.720710829728077, 37.701681187448685],
              [126.720777560392591, 37.701669629269297],
              [126.720778303868258, 37.70166904382166],
              [126.720779749755323, 37.701668219661101],
              [126.720781339995412, 37.701667584829231],
              [126.720783036986717, 37.701667155301251],
              [126.720784795225214, 37.701666943394791],
              [126.720785211834595, 37.701666915503317],
              [126.720797321961655, 37.70166620598247],
              [126.720875714751912, 37.701652627457264],
              [126.720900003886513, 37.701648420183531],
              [126.720904608674758, 37.701650988712139],
              [126.720919106503985, 37.701659073759785],
              [126.720939157606182, 37.701657900240768],
              [126.720939576399175, 37.701657880475025],
              [126.720940592733214, 37.701657887190258],
              [126.720942395520211, 37.701658079364734],
              [126.720944135812175, 37.701658498257515],
              [126.720945769484629, 37.701659132761343],
              [126.720947253613289, 37.701659965467591],
              [126.720948553232972, 37.701660977217621],
              [126.720949631204064, 37.701662139825331],
              [126.720950461730126, 37.701663425179511],
              [126.720951024752068, 37.701664798897696],
              [126.720951301345238, 37.701666226604857],
              [126.720952358445132, 37.70167762079776],
              [126.720972503154769, 37.701688856289408],
              [126.721009083353081, 37.701713152254136],
              [126.721063349352008, 37.701747347013196],
              [126.721187968272361, 37.701825918510828],
              [126.721273498912922, 37.701883558272549],
              [126.721482083555117, 37.702014694338466],
              [126.721485421569398, 37.702015824995335],
              [126.721608424918728, 37.702057510800785],
              [126.72163072227535, 37.702065442652298],
              [126.721679210242044, 37.702080761510324],
              [126.721741331438835, 37.702097801715347],
              [126.721771526631656, 37.702125460465552],
              [126.721780653045457, 37.702130425655987],
              [126.721815728493112, 37.702149503643611],
              [126.721914760086761, 37.702199571772908],
              [126.722058541407577, 37.702237788064018],
              [126.722080434416625, 37.702243607185508],
              [126.722203562171345, 37.702294299127395],
              [126.722326893255115, 37.702338792139273],
              [126.722423044522955, 37.702353092907288],
              [126.722523117056042, 37.702361780829733],
              [126.722635594953786, 37.702324882649627],
              [126.722786279996782, 37.70235057293656],
              [126.722934669222141, 37.702468617322445],
              [126.722969642665291, 37.702495445600711],
              [126.723102172577057, 37.702591202105197],
              [126.723114664033517, 37.702615160233755],
              [126.723168125543651, 37.702695942181059],
              [126.723190251282318, 37.702729374401407],
              [126.723243110985123, 37.702806639926258],
              [126.723261823482915, 37.702823865643133],
              [126.723297852944114, 37.702856601686676],
              [126.723344595431286, 37.70286185780504],
              [126.723366921414097, 37.702864369012019],
              [126.723408285037408, 37.702842509680302],
              [126.723513049744895, 37.702924969619964],
              [126.723558887159172, 37.703092139044152],
              [126.723589604406754, 37.703204161099961],
              [126.723701851750207, 37.70351497940689],
              [126.72377662262096, 37.703655651307685],
              [126.723817742432644, 37.703747818224315],
              [126.723885272537203, 37.703742625035922],
              [126.724066202527567, 37.703741826659979],
              [126.724093110876993, 37.703741708107536],
              [126.724304618279092, 37.703799642843734],
              [126.724426717788219, 37.703844130969919],
              [126.724485902369977, 37.703889317393973],
              [126.724719811476533, 37.704214214837869],
              [126.724766398873896, 37.704270067363595],
              [126.724998178366221, 37.704547941291075],
              [126.725024852319592, 37.704553365872563],
              [126.725419735441633, 37.704633678349495],
              [126.725488571401357, 37.704657210504088],
              [126.725824678288532, 37.70461322732092],
              [126.726077482423136, 37.7045749587476],
              [126.726341631838963, 37.704538420691236],
              [126.72638992058954, 37.704303901855724],
              [126.726510893068252, 37.70371638950364],
              [126.726511389958603, 37.703715951119179],
              [126.726553851679995, 37.703671453261045],
              [126.726554903465328, 37.703670125313664],
              [126.726555649110622, 37.703668673680582],
              [126.726556066623289, 37.703667140579327],
              [126.726556143029313, 37.703665573694494],
              [126.726555877851183, 37.703664018989947],
              [126.726555276264122, 37.703662524269312],
              [126.726554358189816, 37.703661137432981],
              [126.726553151593265, 37.703659896519575],
              [126.726551691245476, 37.70365883961238],
              [126.726529352441375, 37.703645302240638],
              [126.726562804619874, 37.703610302815342],
              [126.726563776842852, 37.703609098727341],
              [126.72656449607102, 37.70360778752601],
              [126.726564944942879, 37.703606402446006],
              [126.726565112884231, 37.703604978569274],
              [126.726564996126854, 37.703603551022375],
              [126.726564595430432, 37.703602155862868],
              [126.726563921754391, 37.703600830116528],
              [126.72656299292963, 37.703599604545047],
              [126.726561832449349, 37.703598510848522],
              [126.72656046951586, 37.70359757715925],
              [126.726539058660563, 37.703585132861768],
              [126.726546918381288, 37.703582243431249],
              [126.726547304437105, 37.703582099950772],
              [126.726549017542538, 37.703581321636911],
              [126.726550535596758, 37.703580318517503],
              [126.726551810620649, 37.703579122725152],
              [126.726552804825374, 37.703577768261894],
              [126.726553488268792, 37.703576298194498],
              [126.726553840083326, 37.703574755649129],
              [126.726553850707404, 37.703573187431402],
              [126.726553518500978, 37.703571642201602],
              [126.726552829814608, 37.703570123475423],
              [126.726542588857782, 37.703552391431309],
              [126.726541732603081, 37.703551169039649],
              [126.726540649352089, 37.703550066836364],
              [126.726539895118989, 37.703549506678854],
              [126.726541637993734, 37.703539095309942],
              [126.726423664658668, 37.703338635103975],
              [126.726421200188256, 37.703333557151844],
              [126.726410653194193, 37.703312423329201],
              [126.726421964065565, 37.703302518183818],
              [126.726427011580071, 37.703298099707034],
              [126.726449734444301, 37.703283512292359],
              [126.726478845528547, 37.703270633313622],
              [126.726492508239403, 37.703264744526052],
              [126.726584296844862, 37.703263275431262],
              [126.726663790150965, 37.703281191314979],
              [126.726674223857898, 37.703283755488016],
              [126.72677987397978, 37.703271893062698],
              [126.726862845332576, 37.703305872317912],
              [126.726925421359084, 37.703344867903631],
              [126.726975118946925, 37.703370607210601],
              [126.726995057889027, 37.70339064617113],
              [126.727135702756229, 37.703548075142081],
              [126.727150874249148, 37.70356359788434],
              [126.727183973004585, 37.703573531005368],
              [126.727292688065049, 37.703594614136527],
              [126.727378248304774, 37.703646338854981],
              [126.727481341610016, 37.703704015302073],
              [126.727582124782359, 37.703665120491301],
              [126.727671726896617, 37.703773724600453],
              [126.727717415073826, 37.703829292477643],
              [126.727846026082346, 37.704030903535603],
              [126.727847125825861, 37.704032496150077],
              [126.727886554931729, 37.704089560613262],
              [126.72789187236161, 37.704234853095159],
              [126.72791015593701, 37.704319919918909],
              [126.727934528597046, 37.70434390576748],
              [126.728050046823142, 37.704343895122278],
              [126.728145836496211, 37.704362136624738],
              [126.728224534182971, 37.704374550936336],
              [126.7282635975928, 37.704380716546325],
              [126.728377529179724, 37.704402993717913],
              [126.728391783792901, 37.704405781034076],
              [126.728398948262125, 37.704409882033012],
              [126.728408586985282, 37.70441539806361],
              [126.728417976382687, 37.704415418949509],
              [126.728448266125994, 37.70441830938978],
              [126.728464898907177, 37.7044251059842],
              [126.728472145160183, 37.704428212462609],
              [126.728507877074705, 37.704445188190384],
              [126.728576024848806, 37.704415785246269],
              [126.728611812933124, 37.704417273120413],
              [126.728597677567734, 37.704452999782966],
              [126.72862444521617, 37.704451376785407],
              [126.72890460313576, 37.704491153772388],
              [126.729009609891136, 37.704508568860746],
              [126.729015632517218, 37.704508024190908],
              [126.729333421423604, 37.704532408414472],
              [126.729351130798975, 37.704533296593368],
              [126.72951698936815, 37.704529453965868],
              [126.729615365973942, 37.704519544198106],
              [126.729675045555666, 37.704527564652892],
              [126.729666188989427, 37.704575692871273],
              [126.729584746798153, 37.704656311504799],
              [126.729479946615015, 37.704726734989535],
              [126.729413569219645, 37.704806262214333],
              [126.729390650219358, 37.704871248622752],
              [126.729418609179191, 37.704928462032633],
              [126.729470212472734, 37.70496743953538],
              [126.72947867971935, 37.704977315593126],
              [126.729480421097506, 37.704985202659664],
              [126.72948605593993, 37.704994504997103],
              [126.729512277783797, 37.705042992221784],
              [126.729709988894172, 37.705060088609727],
              [126.72973635325755, 37.705062375332439],
              [126.729772182013718, 37.705051196046647],
              [126.729814419736414, 37.705033274420366],
              [126.72989211624099, 37.705006703733602],
              [126.729902337008397, 37.705006528938625],
              [126.729921363657596, 37.705006203453522],
              [126.729992313809305, 37.705004287360623],
              [126.730036361332381, 37.705003098661578],
              [126.730131750093918, 37.704998593065504],
              [126.730133130233767, 37.704998528169412],
              [126.730404080680856, 37.704987610134033],
              [126.730463229214749, 37.704995797974718],
              [126.730635729047705, 37.705019676494857],
              [126.730717889004509, 37.705034505629285],
              [126.730897629577001, 37.705061947037613],
              [126.73122526656428, 37.705154489237309],
              [126.73128678814362, 37.705144484681888],
              [126.731558060689821, 37.705094715830008],
              [126.731580048597337, 37.705084795053828],
              [126.73157495108704, 37.705012544897151],
              [126.731575012614812, 37.70501098061591],
              [126.731575414151422, 37.70500944739269],
              [126.731576141591447, 37.705007992905195],
              [126.731577176338462, 37.705006660295361],
              [126.731578486202551, 37.705005490813853],
              [126.731580029993282, 37.705004518442202],
              [126.731581764288819, 37.705003773542025],
              [126.731583632148102, 37.705003277373848],
              [126.731585582348302, 37.705003046728777],
              [126.73180286730819, 37.70499244899797],
              [126.731808495904843, 37.704992272959956],
              [126.73200742370436, 37.704942291276026],
              [126.732027269579319, 37.704942057313282],
              [126.732104534904565, 37.704940260397777],
              [126.73215255283317, 37.704938965174144],
              [126.732156574277582, 37.704936556912365],
              [126.732169418217268, 37.704928867618143],
              [126.732168347378277, 37.704933369921996],
              [126.732178259683067, 37.704935932917493],
              [126.732197393330225, 37.704935130064918],
              [126.732226319010834, 37.704923366091748],
              [126.732294026118424, 37.704917889834697],
              [126.732338186500868, 37.704927748424382],
              [126.732348226264506, 37.704921803824639],
              [126.732349788454144, 37.704921026246573],
              [126.732351489119168, 37.704920461379025],
              [126.732353281620959, 37.704920121536745],
              [126.732355120464504, 37.704920018140598],
              [126.732356955728974, 37.704920151766899],
              [126.732358739799395, 37.704920519401675],
              [126.732360425116553, 37.704921112623474],
              [126.732361967607972, 37.704921914921705],
              [126.732363325525611, 37.704922904392966],
              [126.732364463964828, 37.704924055573265],
              [126.732374150091218, 37.704935679799142],
              [126.732474619686315, 37.7049540618248],
              [126.732597825225824, 37.704939143056542],
              [126.732928590321336, 37.704900480281601],
              [126.733050607208597, 37.704916236764021],
              [126.733132773934628, 37.704932757831713],
              [126.733199335996403, 37.704946144433883],
              [126.733325947688073, 37.704967826013899],
              [126.733501787802993, 37.70499834943147],
              [126.733584994739033, 37.705018530876977],
              [126.733661114624979, 37.705035876631868],
              [126.733744664120422, 37.705061689803436],
              [126.733936914330229, 37.705107741616715],
              [126.734022599257983, 37.70512989290463],
              [126.734120919546228, 37.705135178785227],
              [126.73424115029394, 37.705156012159833],
              [126.734298172511075, 37.705066044608678],
              [126.734347239318183, 37.705022233974823],
              [126.734484416550785, 37.705010155676341],
              [126.734530533284925, 37.70499815123754],
              [126.734564288735555, 37.704973731137805],
              [126.734669798111256, 37.70489944017767],
              [126.734696619643699, 37.704880555070865],
              [126.73474207629576, 37.704853629503368],
              [126.73478325958699, 37.704832324723867],
              [126.734825695338813, 37.704807923028241],
              [126.734875349402557, 37.704796214507937],
              [126.734937883775544, 37.704724688158315],
              [126.734985655721829, 37.704670049467644],
              [126.734996881068156, 37.704651488517136],
              [126.735015310608077, 37.704650970861678],
              [126.735041175069753, 37.704646821371739],
              [126.735067254291423, 37.704642637216814],
              [126.735147223084738, 37.704626771897793],
              [126.735159754683693, 37.704624603507384],
              [126.735232324778764, 37.704612043928527],
              [126.735302492989717, 37.70461276131941],
              [126.735438033689846, 37.704613914312546],
              [126.735709683386048, 37.704657322574661],
              [126.735797646015897, 37.704683143973298],
              [126.73580259969755, 37.704689366311229],
              [126.735819874304141, 37.70471106566783],
              [126.735830328298405, 37.704711373172422],
              [126.735876924419657, 37.704711199267301],
              [126.735901021414733, 37.704711249077405],
              [126.735981491541921, 37.704657034589147],
              [126.735985570785942, 37.704654286774797],
              [126.736004397023663, 37.704641093639232],
              [126.736048931262573, 37.704644845051838],
              [126.736110857085407, 37.704650063802049],
              [126.736114437712629, 37.704633005279241],
              [126.736139154838725, 37.704515261699207],
              [126.736236901494721, 37.704516356389576],
              [126.736265123882873, 37.704516670795236],
              [126.736317726924696, 37.704523546954228],
              [126.736813729463961, 37.704406131047271],
              [126.736972395903351, 37.704380862996956],
              [126.73730677836204, 37.704372882172841],
              [126.737701181674808, 37.704374891980294],
              [126.73780411460838, 37.704377662899972],
              [126.738530088429357, 37.704413349898452],
              [126.738613239175081, 37.704449852957772],
              [126.738714668528587, 37.704484403658903],
              [126.73879710808886, 37.704512483713806],
              [126.739151744061843, 37.704951034460166],
              [126.739197406273277, 37.705007500868447],
              [126.739240077088027, 37.70505930257972],
              [126.739286859204938, 37.705116093441795],
              [126.739287733945801, 37.705118637188704],
              [126.739306013875193, 37.705160625717802],
              [126.739321778376066, 37.705263146556597],
              [126.739359869586508, 37.705337159526842],
              [126.739429124418152, 37.70547173277906],
              [126.739439409863479, 37.705495539764186],
              [126.739540905088248, 37.705730440785196],
              [126.739739350451089, 37.706233457337689],
              [126.739751982376745, 37.706269235365923],
              [126.739780434040625, 37.706343349173579],
              [126.739888560218091, 37.706442614093937],
              [126.739902479913468, 37.706455393443761],
              [126.740074979444458, 37.706628370803479],
              [126.740381761668658, 37.706609908589556],
              [126.740449481672073, 37.706601040595785],
              [126.740529788973546, 37.706589675883478],
              [126.740717595428649, 37.706543358241134],
              [126.740938579582249, 37.706485283536168],
              [126.740957113970111, 37.706472858357181],
              [126.740979602901888, 37.706457779023843],
              [126.741160414476823, 37.706333168884974],
              [126.741166634527644, 37.706328399500848],
              [126.741211018173033, 37.706302873288436],
              [126.741202584332598, 37.706241818914712],
              [126.741196507516051, 37.706197825378098],
              [126.741169595064264, 37.706091334858961],
              [126.741201731973007, 37.70600329753978],
              [126.741225047129021, 37.705939428082203],
              [126.741082654249652, 37.705617298267121],
              [126.741117839487714, 37.70556562250988],
              [126.74112362094958, 37.705557132379504],
              [126.741145336012323, 37.705528466113009],
              [126.741181803234952, 37.705438165517521],
              [126.741238878100532, 37.705379734636047],
              [126.741302514456748, 37.705334213122818],
              [126.741370330970156, 37.705285702670281],
              [126.741456973661812, 37.705235492640973],
              [126.741519559329433, 37.705198871045113],
              [126.741524083204965, 37.705196224142249],
              [126.741728928042079, 37.70508911890785],
              [126.741890488288035, 37.704997692795246],
              [126.742098864569797, 37.704895387488229],
              [126.742205528785405, 37.704842970290763],
              [126.742418219116985, 37.704722654117063],
              [126.742597112934291, 37.704638581365266],
              [126.742735443755492, 37.704653525904263],
              [126.742847333959816, 37.704629274439739],
              [126.742882093529303, 37.704620057272365],
              [126.742980878760932, 37.704592681391347],
              [126.743105006219636, 37.704667562804588],
              [126.74316700070024, 37.70470802963456],
              [126.743167829054542, 37.704707449998104],
              [126.743169493550809, 37.704706640485483],
              [126.74317130536231, 37.704706069865679],
              [126.743173215546804, 37.704705754048206],
              [126.743175165008054, 37.704705703469514],
              [126.743176766346934, 37.704705861564037],
              [126.743178326150513, 37.704706199654971],
              [126.743179807049344, 37.704706711193289],
              [126.743181181947236, 37.704707383386619],
              [126.743361489801487, 37.704810305424402],
              [126.743362934186536, 37.70481128450875],
              [126.743364152155081, 37.704812440600527],
              [126.743365109988034, 37.70481374283861],
              [126.743365780863982, 37.704815151393085],
              [126.743366146981373, 37.704816631899668],
              [126.74336619745479, 37.704818139222745],
              [126.743365932705601, 37.70481963190457],
              [126.743365357692738, 37.704821068516424],
              [126.743364488700237, 37.704822409504914],
              [126.743363349952759, 37.704823615367637],
              [126.74336258706883, 37.704824338734767],
              [126.743364399270448, 37.704824064647234],
              [126.743366171610319, 37.704824029152448],
              [126.743367734448228, 37.704824180688107],
              [126.743369254699374, 37.70482450410168],
              [126.743370705221665, 37.704824991107152],
              [126.743372054335637, 37.704825633389405],
              [126.743427868614162, 37.704856512789362],
              [126.743429263758088, 37.704857425760885],
              [126.743430458722329, 37.704858501487301],
              [126.743431424279279, 37.704859713642904],
              [126.74343198546832, 37.704860752855197],
              [126.743967070731756, 37.704854994420849],
              [126.744025561877038, 37.70490101657731],
              [126.744173572756338, 37.70498552552715],
              [126.744237613972658, 37.705014100343313],
              [126.744279591579641, 37.70507050152365],
              [126.744295498486011, 37.705081238999341],
              [126.744329979469271, 37.70510214518356],
              [126.744367996639852, 37.705126725248803],
              [126.744374706968472, 37.705130397850553],
              [126.744440509686385, 37.705164048336421],
              [126.744517771010322, 37.705163650212299],
              [126.744678510415596, 37.705203984603529],
              [126.744832194059185, 37.705236132003762],
              [126.744935113973369, 37.705245925076504],
              [126.745035983281952, 37.70528218106702],
              [126.745049608861791, 37.705287003823436],
              [126.745063591373736, 37.705290413798636],
              [126.74509846661428, 37.705298652493553],
              [126.745149231393299, 37.705326070147159],
              [126.745152035593051, 37.705334807424606],
              [126.745161207088316, 37.705345811714928],
              [126.745173729125213, 37.705364704134766],
              [126.745206787878203, 37.705386731207547],
              [126.745207164595797, 37.705326765505298],
              [126.745230678810927, 37.705291903538168],
              [126.745252035371948, 37.705264642066304],
              [126.745378135558013, 37.705177630444346],
              [126.745521093189694, 37.705083339376259],
              [126.745615607082954, 37.705010061508482],
              [126.745635145841277, 37.70499714809128],
              [126.745636742603381, 37.704996303860433],
              [126.745638340473377, 37.704994902623717],
              [126.745777106432726, 37.704935802419719],
              [126.745849313728854, 37.704907516840073],
              [126.745913086923665, 37.704911321787954],
              [126.745960221687014, 37.704859897645704],
              [126.745997881143396, 37.704830693408027],
              [126.746024922959421, 37.704822813983867],
              [126.746030284178872, 37.704821255642855],
              [126.746031491410207, 37.704820900132589],
              [126.746054465625804, 37.70481420605855],
              [126.746065098520987, 37.704811977498032],
              [126.746074180218827, 37.704799040403323],
              [126.746094596070563, 37.704788111806089],
              [126.746109986878466, 37.704691570270327],
              [126.746140553166171, 37.704613927200818],
              [126.746158036294077, 37.704578486592396],
              [126.746160190824767, 37.704572301119597],
              [126.746161843568984, 37.704553159633534],
              [126.746164662208656, 37.704507837879689],
              [126.746167839549628, 37.704408170895803],
              [126.746178814104965, 37.704359209496523],
              [126.74618118732306, 37.704338375438624],
              [126.746180625379424, 37.704294172233439],
              [126.746216076537578, 37.70424047046771],
              [126.746236386254992, 37.704207295796451],
              [126.746292208837332, 37.704151953152177],
              [126.746310223492429, 37.704137718462874],
              [126.746327037394835, 37.704124432391204],
              [126.746415126764347, 37.704064655062176],
              [126.746566300434694, 37.70400022081116],
              [126.746613697116487, 37.703974708188845],
              [126.74662398917387, 37.703969378676135],
              [126.746629867418307, 37.703961787500241],
              [126.746662225206279, 37.703930323819222],
              [126.746787723194089, 37.703863869420353],
              [126.746971450111801, 37.703764033417571],
              [126.747050975447763, 37.703721130322251],
              [126.747084681669506, 37.70370627376326],
              [126.747266419899603, 37.703617695548395],
              [126.74741562392245, 37.703562553706568],
              [126.747589895686573, 37.703531673727589],
              [126.747684926599902, 37.703513580519711],
              [126.747731200747793, 37.703504670628355],
              [126.747981596344928, 37.703445315550979],
              [126.748017236346556, 37.703436866793709],
              [126.74820833403335, 37.7033564784779],
              [126.748277464852293, 37.703345356263775],
              [126.748326243078779, 37.703329982543373],
              [126.748439306707922, 37.703271383183406],
              [126.748517751277845, 37.703235503364887],
              [126.748523963161531, 37.703231021054606],
              [126.748550706158298, 37.70323501487804],
              [126.748641592696288, 37.703238589154346],
              [126.748649573709955, 37.703236070361179],
              [126.748671383921817, 37.703231337729839],
              [126.748694249242263, 37.703226593791285],
              [126.748794831926304, 37.703204952330182],
              [126.748829007475351, 37.703197600811862],
              [126.748967844835974, 37.703168616535564],
              [126.749075016159665, 37.703121545615417],
              [126.749165546573678, 37.703073033780207],
              [126.749270920722225, 37.70303440017085],
              [126.749305797080211, 37.703042358220813],
              [126.749505062357983, 37.703060523702284],
              [126.749606586393725, 37.703060461435243],
              [126.749779202599527, 37.703049568773338],
              [126.750020287666374, 37.703014043991786],
              [126.750227459141556, 37.703001534483789],
              [126.750646085166395, 37.703384771919858],
              [126.750892724648565, 37.703556337259975],
              [126.7513902811516, 37.703902434544716],
              [126.75150173010374, 37.704009661411163],
              [126.751573407865465, 37.704138200608909],
              [126.751700823643503, 37.704344008205759],
              [126.752218665872476, 37.704364808851786],
              [126.75248570348954, 37.704464200276284],
              [126.752493848023292, 37.704466748556413],
              [126.75252410676319, 37.704476101362076],
              [126.752607138173232, 37.704499089408941],
              [126.752750424928962, 37.704567811701281],
              [126.752996858226624, 37.704682915616722],
              [126.753101976387157, 37.704722841970721],
              [126.753321144936095, 37.7048186997485],
              [126.753611409994164, 37.70503220620688],
              [126.753852140183554, 37.704949373924279],
              [126.753940300381672, 37.704858150805705],
              [126.754033009095778, 37.70484041814656],
              [126.754135807444541, 37.704820755530278],
              [126.754197441197846, 37.704808965923164],
              [126.754219074270424, 37.704804827817824],
              [126.754427102506767, 37.704817525275175],
              [126.754753035058428, 37.704922270060429],
              [126.754805814414539, 37.70494652547589],
              [126.754956120434557, 37.705015598609329],
              [126.75505644994098, 37.705061705735758],
              [126.755180750724236, 37.705088146975463],
              [126.755678153811715, 37.705288803326845],
              [126.755871385740477, 37.704991887540018],
              [126.755939990267564, 37.704604057011409],
              [126.755959884703827, 37.704482748249561],
              [126.755954489087543, 37.704446123474199],
              [126.755951308634891, 37.704424515082479],
              [126.755948270366574, 37.704403890022384],
              [126.75594970443845, 37.704365404765603],
              [126.755950489931166, 37.704344363992952],
              [126.755959352944785, 37.704106872667474],
              [126.756232787309941, 37.703923577467705],
              [126.756286247461048, 37.703829043052473],
              [126.756334181855379, 37.70380443752353],
              [126.756503404693873, 37.703755501510081],
              [126.756672060199705, 37.703761760981962],
              [126.756700538864621, 37.703753662830337],
              [126.75711691521542, 37.703883477111255],
              [126.757131584039513, 37.703895329004574],
              [126.757351032591899, 37.704029804279813],
              [126.757479387685052, 37.70411018085899],
              [126.757478542339001, 37.704111224667415],
              [126.757477828382463, 37.704112516263876],
              [126.757477373445411, 37.704113884300753],
              [126.757477196037129, 37.70411529284199],
              [126.757477297660557, 37.70411670494309],
              [126.75747767525435, 37.704118086334695],
              [126.757478320076444, 37.704119403613134],
              [126.757479216551616, 37.704120626035504],
              [126.757480344585304, 37.704121721027917],
              [126.757481671551133, 37.704122661345416],
              [126.757543487963957, 37.704160001876708],
              [126.757545160825302, 37.704160838917566],
              [126.757546990394545, 37.704161432688529],
              [126.757548921259342, 37.704161765715639],
              [126.757550901356566, 37.704161825953918],
              [126.757552858078768, 37.704161613847496],
              [126.757554740335323, 37.704161133581259],
              [126.757556492363278, 37.704160402830382],
              [126.757558054969721, 37.704159441952413],
              [126.757594078098904, 37.704181999788425],
              [126.757593873631578, 37.70418225447478],
              [126.757593126305395, 37.704183707197402],
              [126.757592704862745, 37.704185243090443],
              [126.757592629055111, 37.704186815409827],
              [126.757592893750115, 37.704188370044193],
              [126.757593500513664, 37.704189863740318],
              [126.757594421382535, 37.704191256664181],
              [126.757595630752192, 37.704192499983456],
              [126.757597093862358, 37.704193552920074],
              [126.757650479131271, 37.704225799246856],
              [126.757652109024846, 37.704226622495959],
              [126.757653890998824, 37.704227211459028],
              [126.757655774142222, 37.704227552295762],
              [126.757657704096161, 37.70422763565076],
              [126.757659621919331, 37.704227456646372],
              [126.757661474234354, 37.704227021255733],
              [126.757663205314188, 37.704226343549038],
              [126.757664083869557, 37.704225837132832],
              [126.757667716291692, 37.704228111490096],
              [126.757689629909535, 37.704217760838688],
              [126.757708095981869, 37.704228911908565],
              [126.757709555317319, 37.704229661076496],
              [126.757711137915805, 37.704230221744027],
              [126.757712809818756, 37.704230586486844],
              [126.757714535996101, 37.70423074156465],
              [126.757716266500466, 37.704230690268261],
              [126.75771797074033, 37.704230428799868],
              [126.757796453171963, 37.704213275991371],
              [126.757797620250528, 37.704212958864119],
              [126.757798741683487, 37.704212554923188],
              [126.75779961588205, 37.704212144018058],
              [126.757800157691094, 37.704211847290338],
              [126.757843641200893, 37.704186475853945],
              [126.75784501076707, 37.704185533586035],
              [126.757846173221523, 37.704184428678857],
              [126.757863817445511, 37.704164688249101],
              [126.757864798318437, 37.704163363178971],
              [126.757865483106158, 37.70416192448203],
              [126.757865848708306, 37.704160413473382],
              [126.757865884484431, 37.704158873349236],
              [126.757865593343325, 37.704157353700303],
              [126.757864978292616, 37.704155894203389],
              [126.757864062704357, 37.70415454007145],
              [126.75786286549409, 37.704153328377501],
              [126.757861431676304, 37.704152295457462],
              [126.757846857052016, 37.704143494301604],
              [126.758115794233845, 37.704016462619229],
              [126.758332336679032, 37.703902603369464],
              [126.758325672162698, 37.703881751858454],
              [126.758875374208657, 37.703585286378569],
              [126.758936955314354, 37.70355331348037],
              [126.759117574104749, 37.703477174198767],
              [126.759151648414672, 37.703462810412447],
              [126.759229041601529, 37.703417638689636],
              [126.759232991461758, 37.703403292015885],
              [126.759279918529543, 37.703340029606522],
              [126.75931432265466, 37.703293653086938],
              [126.759359155086784, 37.703252887862639],
              [126.759366372687282, 37.703251811061087],
              [126.759436251943356, 37.70322971166717],
              [126.759479709376293, 37.703214600941671],
              [126.759524767369271, 37.703198927014533],
              [126.759595894493685, 37.703159025349933],
              [126.759645382248294, 37.703160233809889],
              [126.759646982251539, 37.703160184369573],
              [126.75964855795803, 37.703159954513382],
              [126.759650077516653, 37.703159553054512],
              [126.759651509130293, 37.703158983398552],
              [126.759652826565727, 37.703158259801846],
              [126.759701604100201, 37.703127198453629],
              [126.759703032881873, 37.703126115028901],
              [126.759704200272864, 37.703124851502345],
              [126.759705075269665, 37.703123445534565],
              [126.759705631352375, 37.703121940222296],
              [126.759705851057845, 37.703120380521675],
              [126.759705725961609, 37.703118815050964],
              [126.759705262403685, 37.703117290718353],
              [126.75970447014484, 37.703115852650981],
              [126.759703379363913, 37.703114546104132],
              [126.759702019177141, 37.703113409115907],
              [126.759700430061969, 37.703112477993045],
              [126.759698665081061, 37.70311177830564],
              [126.759682977400047, 37.703106873988112],
              [126.759729756418722, 37.703037452670678],
              [126.75988052669409, 37.703149814496086],
              [126.759909228055463, 37.70320505607414],
              [126.759918037430793, 37.703220842945782],
              [126.759920850694357, 37.703225641186272],
              [126.759931431745045, 37.70324339503388],
              [126.759946196900302, 37.703278336206765],
              [126.759956244602705, 37.703295538704083],
              [126.760019206994514, 37.703330859879472],
              [126.760034531232762, 37.703339123781674],
              [126.760160172913217, 37.703406883631772],
              [126.760328067857444, 37.703538146584989],
              [126.760349832867973, 37.703547487364979],
              [126.760516150037105, 37.703621591285895],
              [126.760573403558979, 37.703639089783472],
              [126.760671031943005, 37.70366892808056],
              [126.760739392200279, 37.703678355451501],
              [126.760809696492572, 37.703689761642899],
              [126.760865306600778, 37.703698326663762],
              [126.760947466909741, 37.703712566926086],
              [126.760965716802545, 37.703715136502382],
              [126.760976914222013, 37.703702492717397],
              [126.761016770059584, 37.703707357260392],
              [126.761108471177096, 37.703710935147349],
              [126.761127629679052, 37.703711682522936],
              [126.761161503776677, 37.703713003411139],
              [126.761281273174617, 37.703716063931488],
              [126.761281072801879, 37.703722536831876],
              [126.761277843663237, 37.703844439472661],
              [126.761308307977643, 37.703849854366275],
              [126.761363769094046, 37.703846587540063],
              [126.761444545550432, 37.703852952097982],
              [126.761970156345569, 37.703936512724589],
              [126.762134327777318, 37.703962748246148],
              [126.762416072578972, 37.704010058201526],
              [126.762548709100798, 37.704032009302971],
              [126.762727213685636, 37.704062777136386],
              [126.762803359051773, 37.704077005535503],
              [126.762889427577974, 37.704091541079997],
              [126.763037117702311, 37.704116057151168],
              [126.76313657971005, 37.704150602533034],
              [126.76338005927812, 37.704252732463893],
              [126.763465650634529, 37.704288465372784],
              [126.763694500936609, 37.704384008383037],
              [126.763774836817205, 37.704417675779752],
              [126.763804985387011, 37.704441291945876],
              [126.763819281469793, 37.704441974274353],
              [126.763821231537563, 37.704442204568664],
              [126.763823102982755, 37.704442699359085],
              [126.763824839239078, 37.704443442970046],
              [126.763826386053765, 37.704444415233873],
              [126.763827697203382, 37.704445587019812],
              [126.763828733360015, 37.704446920227042],
              [126.763829464279254, 37.70444837591014],
              [126.76382986544219, 37.704449909751503],
              [126.763829927094164, 37.704451475723062],
              [126.763829300594153, 37.704460337159809],
              [126.763903856021116, 37.704518736552735],
              [126.763943440858796, 37.704551756442839],
              [126.763990795888134, 37.704591268334966],
              [126.764087805602529, 37.704673388016161],
              [126.764118884480908, 37.704653187003061],
              [126.764131128783134, 37.70464729310207],
              [126.764167860729543, 37.704628789384763],
              [126.764518894501208, 37.704341465937006],
              [126.764614621534378, 37.704270986109265],
              [126.764782879687871, 37.704125764364285],
              [126.765109218427085, 37.703848291541313],
              [126.765111917148346, 37.703845999188751],
              [126.76511092844521, 37.70385399040849],
              [126.765088815495773, 37.704032623315761],
              [126.765200783310547, 37.704372683957317],
              [126.76521333501465, 37.704604986374989],
              [126.765133499831563, 37.704691256752987],
              [126.765065487593517, 37.704739834709862],
              [126.765041034806785, 37.70473950562991],
              [126.765031150271795, 37.704782840599357],
              [126.76500044070697, 37.704912014213335],
              [126.765113470327009, 37.70502823958433],
              [126.765259549850612, 37.70511412428997],
              [126.765344485251589, 37.705207768539225],
              [126.765356896555275, 37.705213802671878],
              [126.765447982441145, 37.705258089424667],
              [126.765518594727027, 37.705283574196486],
              [126.765641407216151, 37.705333652904393],
              [126.765740886624485, 37.705364537759174],
              [126.76584670790001, 37.705465549282422],
              [126.765867061378472, 37.705469526437746],
              [126.765885372189388, 37.705475067273674],
              [126.765966373467663, 37.70549957544192],
              [126.766011278418915, 37.705532323114276],
              [126.766087321533831, 37.705576120050395],
              [126.766142265491524, 37.705629439615258],
              [126.766214187572871, 37.705689552586733],
              [126.766227050356363, 37.705713785212502],
              [126.766241563867453, 37.705752505954493],
              [126.766303830620544, 37.705918630196685],
              [126.766314708268439, 37.705954132235746],
              [126.766276967740509, 37.706065261323253],
              [126.766237822312519, 37.706172742137788],
              [126.766224937611298, 37.706198820853885],
              [126.766188073003903, 37.706273437628305],
              [126.766090604179837, 37.706446116225599],
              [126.766065743111483, 37.706518420989021],
              [126.766051569310036, 37.706573857443765],
              [126.766024440362784, 37.706635465679362],
              [126.7660179973347, 37.706767218729624],
              [126.765984536024689, 37.706870204814095],
              [126.765991526013693, 37.706901204034359],
              [126.766077847888255, 37.707283988675194],
              [126.766638652639173, 37.707215311638151],
              [126.767032567931722, 37.707167070211916]
            ]
          ]
        ]
      },
      id: '31104'
    }
  ]
};

// 동 경계선
export const subDistrictData = {
  type: 'FeatureCollection',
  name: 'DONG',
  crs: {
    type: 'name',
    properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' }
  },
  features: [
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '행신3동',
        ADM_CD: '31101514'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.841232789562966, 37.62933130596425],
              [126.841262505558788, 37.629299968534532],
              [126.841305703865828, 37.629247871185257],
              [126.841722847565549, 37.6287447901531],
              [126.84186866806516, 37.628568926384432],
              [126.841996125891299, 37.628455484504563],
              [126.842162366689152, 37.628307525000615],
              [126.842250896458708, 37.628228728402433],
              [126.842545559868725, 37.627966464336581],
              [126.842592671915455, 37.627924532795696],
              [126.842840234285688, 37.62790504515899],
              [126.843042954122609, 37.627931805238418],
              [126.843102527036592, 37.627895821359616],
              [126.84311543327928, 37.627888025487671],
              [126.843139760409926, 37.627848055259179],
              [126.843384687638135, 37.627628634589293],
              [126.843408601214747, 37.627569562777786],
              [126.843445114744554, 37.627552313473196],
              [126.843728728466516, 37.6274660177477],
              [126.843807927717947, 37.627458375991715],
              [126.843806918498046, 37.627453419362524],
              [126.843582704995455, 37.626343113772613],
              [126.843552902738153, 37.626195606305608],
              [126.843520486634844, 37.626035166392448],
              [126.843494210458985, 37.62586300996157],
              [126.843858179530486, 37.62581722155744],
              [126.84382860916881, 37.625658248686641],
              [126.843753451457758, 37.625255139778957],
              [126.843712350382219, 37.625191870193134],
              [126.843671111866399, 37.625128387113641],
              [126.84333044018237, 37.624603963152794],
              [126.842644480217061, 37.62434181449774],
              [126.842752222190413, 37.623683023318435],
              [126.842835576612387, 37.623210154616046],
              [126.842790710543511, 37.623097217426348],
              [126.84279509312141, 37.623029969076661],
              [126.842770734452017, 37.622871994741118],
              [126.842722437125033, 37.622635276424298],
              [126.842666477342306, 37.622361000124641],
              [126.842596342605816, 37.622069144537406],
              [126.842570141670635, 37.621960114860272],
              [126.842518749611912, 37.621890670151025],
              [126.84245337151161, 37.621794267034083],
              [126.842150381168437, 37.620725566877056],
              [126.842149287142945, 37.620707366471656],
              [126.84215365041355, 37.620680162431604],
              [126.842160710597838, 37.620662602366245],
              [126.842166843106838, 37.620654772425411],
              [126.842204974596015, 37.620620856932447],
              [126.842223461642234, 37.620609710453984],
              [126.842293977192966, 37.620580974245158],
              [126.842327299296414, 37.620571648208518],
              [126.842656613958411, 37.620501546041631],
              [126.842667041797441, 37.620498135803722],
              [126.842683040375846, 37.620485183540048],
              [126.842692928837238, 37.62046915974561],
              [126.842692719326422, 37.620461681120219],
              [126.842354553671029, 37.619529972713117],
              [126.842306018717196, 37.619396112016574],
              [126.842266198914487, 37.619236521749393],
              [126.841580162513708, 37.61960808473372],
              [126.841467071964956, 37.619669343676847],
              [126.841458751219264, 37.619673850773957],
              [126.841434420143074, 37.619689555007639],
              [126.841046746802036, 37.619939756732734],
              [126.840987499419938, 37.619977987581933],
              [126.840844839273956, 37.620070075809352],
              [126.840545272588457, 37.620270859397465],
              [126.840456996259135, 37.620335059180881],
              [126.840387309612652, 37.620385737366981],
              [126.840303824161509, 37.620446454024595],
              [126.840291014277312, 37.620455769788443],
              [126.840216681051857, 37.620509829832727],
              [126.839769791031372, 37.620834831269427],
              [126.83973705544804, 37.620858637971885],
              [126.839729018392433, 37.620864482307773],
              [126.839724744997582, 37.620867586019926],
              [126.839204791365844, 37.621190253871632],
              [126.83907921541217, 37.621268195445495],
              [126.83905542877406, 37.621282957633326],
              [126.838515010777499, 37.621618322915985],
              [126.838491946240325, 37.621632645580924],
              [126.838395253751102, 37.621692638763875],
              [126.838179690017668, 37.621826410758544],
              [126.838065818713247, 37.621897071026098],
              [126.838018705037598, 37.621926301022022],
              [126.838014751171244, 37.62192875389902],
              [126.837976991098401, 37.621952186705613],
              [126.837853303018477, 37.622028944182617],
              [126.837581583945308, 37.622197567221974],
              [126.837525141108983, 37.622232594291837],
              [126.8367644243136, 37.622738964412804],
              [126.836763121306475, 37.622739839493114],
              [126.836752995221232, 37.622746081085403],
              [126.836742479425638, 37.622752565648611],
              [126.836108532115631, 37.623143557785859],
              [126.835421411311458, 37.623567324976456],
              [126.835363882453862, 37.623602804558935],
              [126.834449066116818, 37.624167002371664],
              [126.834461424820631, 37.624179211221083],
              [126.834633186503879, 37.624348915578167],
              [126.834705752627869, 37.624420645357624],
              [126.834957350289429, 37.624669220653203],
              [126.83522545570608, 37.624934126811304],
              [126.835464059506293, 37.625169886581432],
              [126.835546681976794, 37.625251457336546],
              [126.835609641422508, 37.625313616194902],
              [126.835874132289561, 37.625574913214436],
              [126.835996770167412, 37.625696087163675],
              [126.836001692788429, 37.625721070942511],
              [126.836038891267023, 37.625909856920963],
              [126.836118597148015, 37.626314535605992],
              [126.8361355097325, 37.626400401495673],
              [126.83620450604154, 37.626750697475359],
              [126.836227654565491, 37.626868224355363],
              [126.836275477528659, 37.627111012894375],
              [126.836312404311826, 37.627294309011248],
              [126.836347412348331, 37.627468081665519],
              [126.836386653392836, 37.627666185223205],
              [126.836454404078452, 37.628008213282136],
              [126.836501455773742, 37.628245751602002],
              [126.836489534307162, 37.628359527236704],
              [126.836532351033284, 37.628575674752618],
              [126.836561931413129, 37.628725006030287],
              [126.836580028099476, 37.628816360381471],
              [126.836647278472086, 37.629155851815547],
              [126.836700091188078, 37.629248365742932],
              [126.836727945374591, 37.629388985807438],
              [126.83678757618577, 37.629690014151642],
              [126.836841033590986, 37.629959885687228],
              [126.83687386773353, 37.630137141261123],
              [126.836893577799032, 37.630243546929883],
              [126.836907311021747, 37.630317666668965],
              [126.836946524016213, 37.630514403381625],
              [126.837133886676312, 37.630520252146226],
              [126.837436278451364, 37.630556891648126],
              [126.837544674334183, 37.630565601470515],
              [126.837632667468768, 37.630558591007009],
              [126.837853661854709, 37.630540984611216],
              [126.837897039641192, 37.630545906852163],
              [126.837918371660649, 37.630529193296944],
              [126.838047059798186, 37.630428358765066],
              [126.838147636396727, 37.630349590524965],
              [126.8383239535315, 37.630211515754233],
              [126.838420968864142, 37.630139123151103],
              [126.838480834077103, 37.630097463083843],
              [126.838598643951741, 37.630015483186121],
              [126.839019683686629, 37.629722525043128],
              [126.839084308171749, 37.629683411448696],
              [126.839127217922766, 37.629657441987227],
              [126.839266573661376, 37.629589413022835],
              [126.839474692813212, 37.629487815711975],
              [126.839558921816348, 37.629459821367718],
              [126.839717033125453, 37.629424537667802],
              [126.839727726961613, 37.629425932384144],
              [126.839853954414181, 37.629442385862113],
              [126.83994698651874, 37.629477831136484],
              [126.839985357765727, 37.62950470356872],
              [126.84003386811851, 37.629538677509068],
              [126.840053210659079, 37.629550776113049],
              [126.840075839523067, 37.629564950834613],
              [126.840107296584037, 37.629541972470861],
              [126.840220730348946, 37.629459104787252],
              [126.840264089067261, 37.629421773432298],
              [126.840347401296938, 37.62939873162177],
              [126.840427995160809, 37.629376409141209],
              [126.840516978924356, 37.629351753083739],
              [126.840580708640516, 37.629319765898089],
              [126.840709435196317, 37.629199569835194],
              [126.840827033231292, 37.629089811968655],
              [126.840990411180812, 37.62918499578322],
              [126.841232789562966, 37.62933130596425]
            ]
          ]
        ]
      },
      id: '31101514'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '식사동',
        ADM_CD: '31103510'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.820236831616015, 37.693948629415452],
              [126.820257254556353, 37.693942742546177],
              [126.820265773463504, 37.693959828419118],
              [126.820389134233281, 37.693922801157242],
              [126.820585462545978, 37.693863872094965],
              [126.820656280395156, 37.693864260277259],
              [126.821061093726769, 37.693866490812333],
              [126.821405311077697, 37.693721493808177],
              [126.821502828435655, 37.693680413930856],
              [126.82188520183233, 37.693519346382928],
              [126.822174590160955, 37.693470314507884],
              [126.822464932357065, 37.693421121558245],
              [126.822535699963467, 37.693176410294726],
              [126.822537646320754, 37.693169681398103],
              [126.822542594392871, 37.693167887354186],
              [126.822771641943319, 37.693084805128557],
              [126.823470498358859, 37.693095222375561],
              [126.82349805023469, 37.693095633714528],
              [126.823607902035249, 37.692665199839453],
              [126.823649011841482, 37.692504106358982],
              [126.824138859739904, 37.692342578849775],
              [126.824154078148425, 37.692331054351648],
              [126.824168883097798, 37.692319844746955],
              [126.824503010419292, 37.692066845754219],
              [126.824873191216582, 37.691724203094715],
              [126.824902304660696, 37.691697191328167],
              [126.825454438172443, 37.691658192590722],
              [126.825842277065519, 37.691743508741375],
              [126.826868025755559, 37.69146916388928],
              [126.82687639185437, 37.691460881632032],
              [126.82703707988982, 37.691301826817586],
              [126.827606123317665, 37.691084678145373],
              [126.828023548235691, 37.690714346659],
              [126.828034061571941, 37.690717209678887],
              [126.828198027743881, 37.690761848995166],
              [126.828214503803252, 37.690766334097262],
              [126.828583644104967, 37.690866819325628],
              [126.828603437600933, 37.690872208359885],
              [126.828732106872252, 37.690874130621118],
              [126.828756480281683, 37.690874496496654],
              [126.829056519986366, 37.690878978289177],
              [126.829104248125873, 37.69087960135483],
              [126.829409490420062, 37.690883594489719],
              [126.829464840784127, 37.690884316636669],
              [126.829589701398078, 37.690766048487717],
              [126.829600580267538, 37.69075376583325],
              [126.829608945019714, 37.690740298969295],
              [126.829615617751884, 37.690720506022906],
              [126.829623561838019, 37.690733860101368],
              [126.829666295068321, 37.69080564093413],
              [126.829744266567559, 37.690936629776786],
              [126.82979704456146, 37.690976418456344],
              [126.830005770956276, 37.691133778371984],
              [126.830109352028884, 37.691250642747733],
              [126.830111781838824, 37.691253383950396],
              [126.830279674768875, 37.691442812971474],
              [126.83031478735009, 37.691438630157904],
              [126.830549579619031, 37.691410663163069],
              [126.830798328581807, 37.691381037156376],
              [126.830817519875453, 37.691378752154549],
              [126.831601794180756, 37.691209484810948],
              [126.831785173959403, 37.691064367555988],
              [126.832100029811045, 37.69086210285063],
              [126.832094732652223, 37.690780196771833],
              [126.832065636953601, 37.690331112385543],
              [126.832057820522365, 37.690280467899228],
              [126.832051889992698, 37.690245410739863],
              [126.8320184543023, 37.690123164329485],
              [126.832003200332053, 37.690067399659497],
              [126.831972266378443, 37.689969735638677],
              [126.831971793763884, 37.689968052897186],
              [126.831945127956999, 37.689873291869091],
              [126.831928831721811, 37.689815381544548],
              [126.831942988161458, 37.689574484293225],
              [126.831974095765176, 37.689181660669419],
              [126.831817229172941, 37.689087148002677],
              [126.83153959126237, 37.688632570025618],
              [126.831472951855247, 37.688547558108205],
              [126.831158922140659, 37.688217755639869],
              [126.830875655472724, 37.687946875068121],
              [126.830957137756528, 37.687713236956853],
              [126.831016740374835, 37.68755344617886],
              [126.831145186681667, 37.687208786261237],
              [126.831015501517669, 37.686484535720865],
              [126.830975716534454, 37.686283652204686],
              [126.831232889429359, 37.685965440668276],
              [126.831604067066777, 37.685516481754902],
              [126.832348369414404, 37.685204014666922],
              [126.833234815025392, 37.685148251965138],
              [126.833027714355396, 37.684736212850815],
              [126.833639794521247, 37.684381292632594],
              [126.833836880108237, 37.684187298536472],
              [126.834021216127667, 37.684005852425258],
              [126.834224935986413, 37.683805324481256],
              [126.834364018908758, 37.683661456264844],
              [126.834678528615797, 37.683344310497191],
              [126.834748545050303, 37.683160565174148],
              [126.834528063785811, 37.683034369894841],
              [126.834469004590233, 37.683000565077911],
              [126.83432385430477, 37.682918190522209],
              [126.834228522183139, 37.68286408673341],
              [126.834251907718766, 37.682698072447486],
              [126.834325636840731, 37.682226337691077],
              [126.834361190835963, 37.681991776111026],
              [126.834385788774668, 37.681840536797594],
              [126.83441589061681, 37.681657405468115],
              [126.834447164880814, 37.681467172905307],
              [126.833879414266605, 37.680823523978006],
              [126.833813631651736, 37.680746904041548],
              [126.83323743152998, 37.680075795910859],
              [126.833239053313122, 37.679993175344471],
              [126.833244473669126, 37.679717130585303],
              [126.833248231992627, 37.679160112207242],
              [126.833248736909852, 37.679085197038944],
              [126.833362527807367, 37.678844801116263],
              [126.833472401771061, 37.678579982297499],
              [126.833515681560613, 37.678489676300906],
              [126.833509679389763, 37.678285687229106],
              [126.833553738656832, 37.677799407012344],
              [126.833328621299884, 37.67779421932719],
              [126.833069427371257, 37.677649468606575],
              [126.832873158382753, 37.677539855368082],
              [126.832539577345671, 37.677381896496236],
              [126.832228616682286, 37.677237106920394],
              [126.831897537111786, 37.677021299008473],
              [126.831640576007729, 37.676814335802007],
              [126.831275806391417, 37.676834036830989],
              [126.831210122238517, 37.676837585130492],
              [126.831101161458676, 37.676843471145069],
              [126.830383292028543, 37.676786300455205],
              [126.829754212047618, 37.676765800156502],
              [126.829688809204796, 37.676763667261731],
              [126.829616695342622, 37.676790895565055],
              [126.829511705991976, 37.676830532025988],
              [126.829348352683226, 37.676980755229756],
              [126.828768504393437, 37.676846474300568],
              [126.828573963096389, 37.676806817300211],
              [126.828216529662058, 37.676815969087123],
              [126.828032910110153, 37.676808905786693],
              [126.827875600858405, 37.676671723821507],
              [126.827421803774925, 37.676275981120071],
              [126.827077118622853, 37.67597328643572],
              [126.826959965120196, 37.675658493943203],
              [126.827243553219645, 37.675291930147381],
              [126.827436568980488, 37.674817422160466],
              [126.827396448175762, 37.674760339641246],
              [126.8271174424668, 37.674363393163851],
              [126.827143273297992, 37.674198628867366],
              [126.827162802399471, 37.674074067324611],
              [126.827152726020131, 37.674070169297082],
              [126.826656566331224, 37.673878123586093],
              [126.826314631917143, 37.673829107376584],
              [126.826015135211634, 37.673756287175458],
              [126.825824634322245, 37.673598108397243],
              [126.825737931963545, 37.673512125763153],
              [126.825290664324143, 37.67354838973089],
              [126.824609978899417, 37.67316090820043],
              [126.824320425584787, 37.672945159986966],
              [126.824004650960575, 37.672708341038771],
              [126.82332409921149, 37.672626760484903],
              [126.823130455433628, 37.67274361822615],
              [126.823129765625168, 37.672744033383012],
              [126.82312545862348, 37.672743064978469],
              [126.823065409657431, 37.672729553793815],
              [126.822960327405582, 37.672705910969576],
              [126.822326150033362, 37.67237926485106],
              [126.822309127538404, 37.672260293198342],
              [126.821694542839694, 37.672090152233423],
              [126.821651257324319, 37.672078169844255],
              [126.820997920295483, 37.671598264199112],
              [126.820957660913308, 37.67156869070751],
              [126.820663854902875, 37.670964140841058],
              [126.820682976224234, 37.670604402644976],
              [126.820691256909029, 37.670555050553268],
              [126.82086233949218, 37.670304573504033],
              [126.820968451516279, 37.669875151755036],
              [126.820924576131105, 37.669781470554035],
              [126.820877791204055, 37.66968157507528],
              [126.820845903077796, 37.669641080622284],
              [126.82079009326236, 37.669570216177569],
              [126.820732588775428, 37.669497193188406],
              [126.820732786352451, 37.669480741548355],
              [126.820738941254262, 37.66942026927692],
              [126.820792394156143, 37.669092570677371],
              [126.820808928359682, 37.668991315545533],
              [126.820796454032802, 37.668975809428431],
              [126.820798701529341, 37.668969325623152],
              [126.820800914254932, 37.668962924539443],
              [126.820804489191772, 37.668952621353931],
              [126.820868747510062, 37.668773597126268],
              [126.820898869323443, 37.668695809587405],
              [126.820928171363434, 37.668624856571306],
              [126.820973196393325, 37.668509529917756],
              [126.820987173133375, 37.6684345534701],
              [126.821015555899478, 37.668293975012439],
              [126.821066846564619, 37.668007237995312],
              [126.820967528373544, 37.667743272107145],
              [126.820954909103847, 37.667709718844549],
              [126.820945985098263, 37.667685999578481],
              [126.820737799728633, 37.667507127154053],
              [126.820636997580976, 37.667471834763575],
              [126.82036522279077, 37.667376679086772],
              [126.820373051358217, 37.667341076799261],
              [126.820382852925974, 37.667296512289425],
              [126.820383647830994, 37.667292898951793],
              [126.82041026319402, 37.667171885405196],
              [126.820431054164814, 37.667080680257357],
              [126.820487328694597, 37.666833824235958],
              [126.820493893346281, 37.666805012447703],
              [126.820532824310334, 37.666768069691479],
              [126.820548036203832, 37.666753631569982],
              [126.820564852708429, 37.666734319311317],
              [126.82060803746127, 37.666684728404647],
              [126.820738139185565, 37.666540789840631],
              [126.820867857041875, 37.666321332302957],
              [126.821049873746432, 37.666015504255263],
              [126.821257412552683, 37.665878037081853],
              [126.821428166888566, 37.665771172420861],
              [126.821732214734084, 37.665568619682169],
              [126.821811932893723, 37.665461912315557],
              [126.821920275198721, 37.665273738231662],
              [126.821889834276988, 37.665028700197823],
              [126.821883902461948, 37.664954631237634],
              [126.821855668126716, 37.664720318120168],
              [126.821938288818615, 37.664613246075966],
              [126.822030859653097, 37.664336896465812],
              [126.821945975212159, 37.664132615251603],
              [126.821941231973724, 37.66412120244194],
              [126.821961147956031, 37.664096589969887],
              [126.821966813853194, 37.664089584991487],
              [126.822060044656666, 37.66397432580964],
              [126.822380720665748, 37.663787265118494],
              [126.822607944507055, 37.663654718043389],
              [126.82280563073887, 37.663539396525827],
              [126.822842488981365, 37.663448859075032],
              [126.822858576284148, 37.663409321949665],
              [126.822846393813236, 37.663384707113757],
              [126.822829816883981, 37.663350714893575],
              [126.822810001016933, 37.663314690514056],
              [126.822804878990368, 37.663304277836524],
              [126.822782237778199, 37.663265500806538],
              [126.822772392689089, 37.663235457178835],
              [126.822761198168607, 37.663201319192083],
              [126.822738609428512, 37.663173494461596],
              [126.822727093036633, 37.663159310872864],
              [126.822654155765406, 37.663191741758688],
              [126.822373947248863, 37.663316326893714],
              [126.821735243813322, 37.663434284300124],
              [126.821680968632123, 37.663447737226875],
              [126.820545633030122, 37.663729154451282],
              [126.819814193539855, 37.663765339737978],
              [126.819406349377815, 37.663785514844029],
              [126.818823322535593, 37.664006038446679],
              [126.818740673265751, 37.664037298573966],
              [126.818469361863492, 37.664139612556369],
              [126.8184523822719, 37.664146014802547],
              [126.818157011412538, 37.664257400580432],
              [126.818143910830599, 37.664252989473987],
              [126.818048639361734, 37.664220922044045],
              [126.818012540370077, 37.664223213601375],
              [126.817972463895799, 37.664225758855387],
              [126.817412328326625, 37.6642808361728],
              [126.817342386095874, 37.664103236270414],
              [126.817300352941913, 37.664097585037659],
              [126.817292799921034, 37.664039973680921],
              [126.817251741240483, 37.663726667921928],
              [126.816551306230721, 37.663367435209786],
              [126.816340739058148, 37.663271458599951],
              [126.816296040191204, 37.663266500902786],
              [126.815814822181537, 37.663213120055801],
              [126.815771327386784, 37.663208296231176],
              [126.815524932387405, 37.663250867544072],
              [126.815437582716797, 37.663183020808198],
              [126.815039499324726, 37.662908298620316],
              [126.814946977962848, 37.662778907841371],
              [126.814930695113915, 37.662756132321881],
              [126.814908309342925, 37.662724814645131],
              [126.814847467939927, 37.662635486022197],
              [126.814811271706077, 37.662589100867471],
              [126.814770517871438, 37.662426879739463],
              [126.814760288937478, 37.662386166938298],
              [126.814744402078887, 37.662322920580451],
              [126.814744360898658, 37.662322343492988],
              [126.814742225673712, 37.662291049691405],
              [126.814725219814065, 37.662060934467192],
              [126.814721059484455, 37.662004624330166],
              [126.81470948495344, 37.661847845442544],
              [126.814631217727836, 37.661756854359972],
              [126.814625432664485, 37.66175012930568],
              [126.814552255246895, 37.661665047991697],
              [126.81453917307752, 37.661649829700856],
              [126.814500172656807, 37.661563731975349],
              [126.814434480183806, 37.66141867302273],
              [126.814384283364703, 37.660960360098841],
              [126.814321451504227, 37.660510538068429],
              [126.814260479720346, 37.660480762548161],
              [126.81420397291204, 37.660453185150942],
              [126.814158544033091, 37.660431036829422],
              [126.814083615399966, 37.660394530085128],
              [126.814072874870462, 37.660388170966755],
              [126.813457706673177, 37.660026405250029],
              [126.813254580166628, 37.659906420872453],
              [126.813233396793493, 37.659893909719258],
              [126.813169125149088, 37.659877473870992],
              [126.812994558165045, 37.659842673780261],
              [126.812977632890622, 37.659839301586274],
              [126.812876379645232, 37.659805270814736],
              [126.812698199338655, 37.659761780287617],
              [126.812448900677012, 37.659700928244987],
              [126.812133181281453, 37.659753066101899],
              [126.812010140881384, 37.659773383650027],
              [126.811569779122721, 37.659680369933724],
              [126.811568912500775, 37.659680171992129],
              [126.811438362278423, 37.659619704369142],
              [126.811207523580535, 37.659463022173725],
              [126.811133389467827, 37.659402140946554],
              [126.811109254442627, 37.659361766622034],
              [126.811054058626951, 37.659438890651437],
              [126.81095415082558, 37.659578494744814],
              [126.810919382744899, 37.659763287804012],
              [126.81091819042669, 37.659769624367655],
              [126.810920128445602, 37.659775095919393],
              [126.811005952806255, 37.66001753466395],
              [126.811016451831506, 37.660122245286814],
              [126.810996399085283, 37.66023204028901],
              [126.810996934532199, 37.660252124046536],
              [126.81099471825631, 37.660356246637498],
              [126.810970701762571, 37.660574234310211],
              [126.811048452519287, 37.660896411154745],
              [126.81108442608398, 37.661025805524943],
              [126.81108536310127, 37.66102918375708],
              [126.811086494190107, 37.661034626256416],
              [126.811102607019407, 37.661112192881134],
              [126.811104084182858, 37.661115520188254],
              [126.811104963788722, 37.661117504635939],
              [126.811110215032002, 37.661129322837837],
              [126.811237916120561, 37.661404415790535],
              [126.811286096934623, 37.661509952086874],
              [126.811281316553305, 37.661669525444971],
              [126.811274432288528, 37.66179346433811],
              [126.811270681347494, 37.66186100036763],
              [126.811270528919096, 37.661947935900251],
              [126.811269575050659, 37.662016816813519],
              [126.811268999620552, 37.662058481717466],
              [126.811259279925366, 37.662225957880111],
              [126.811292658042916, 37.662557838626533],
              [126.811329649176486, 37.662784618897057],
              [126.811348784260161, 37.662947960588859],
              [126.811483027331491, 37.663291482143407],
              [126.811594582072516, 37.663577036696154],
              [126.811756040616061, 37.66383588224361],
              [126.811744749384971, 37.66383719178031],
              [126.811761489980142, 37.663866797038729],
              [126.811747519067026, 37.663874667006539],
              [126.811732246911305, 37.663881759648135],
              [126.81171303343028, 37.663883962133937],
              [126.811628806426711, 37.663893620426286],
              [126.811628946518283, 37.663892938039574],
              [126.811587522387057, 37.663895064057243],
              [126.811586808278832, 37.663897744035047],
              [126.811482750615312, 37.663901540533914],
              [126.811398914030335, 37.663934470446307],
              [126.811369196105943, 37.663943385908013],
              [126.811369641789511, 37.663990870306435],
              [126.81137327696905, 37.664009750852045],
              [126.811429112088675, 37.664091558393778],
              [126.811543831443217, 37.664305634741105],
              [126.811548835972985, 37.664343213145663],
              [126.811576241681266, 37.664351906848211],
              [126.811380987933475, 37.664431327487883],
              [126.811270363314975, 37.664477730739542],
              [126.811090476181533, 37.664574383819264],
              [126.810793186466711, 37.664708732683067],
              [126.810496429352028, 37.664848636063965],
              [126.810496732850083, 37.664849166014449],
              [126.810436359610478, 37.664878261153341],
              [126.81040400525508, 37.664889973088279],
              [126.81040028670472, 37.664891400677682],
              [126.810295003770449, 37.664933351047011],
              [126.810098834778458, 37.665012364004596],
              [126.810022439211224, 37.665044022316877],
              [126.809852174253919, 37.665114584148185],
              [126.809757516515745, 37.665166865896786],
              [126.809710526774793, 37.665193234058115],
              [126.809554824272823, 37.665353129844938],
              [126.809371237151481, 37.665287210127381],
              [126.809278545439554, 37.665188700575534],
              [126.808854965552868, 37.665312343440448],
              [126.808827086523195, 37.665334147157083],
              [126.808825293300174, 37.665369551494301],
              [126.808821817214792, 37.665463427512925],
              [126.808826512638106, 37.665493115816638],
              [126.80882974305986, 37.665510831353053],
              [126.808816641329216, 37.665542282910032],
              [126.808813262396129, 37.665550852732245],
              [126.808731821765079, 37.665663024799734],
              [126.808720251153446, 37.6656922211912],
              [126.80871773201541, 37.665699040269395],
              [126.808717267387266, 37.665700236307877],
              [126.808708432028311, 37.665723027693105],
              [126.808692971021429, 37.665762878370181],
              [126.808689358434506, 37.665770965511683],
              [126.808689064011674, 37.665769947091974],
              [126.808674846181944, 37.665802897301724],
              [126.808646465776576, 37.665864792553151],
              [126.808647263366893, 37.665866327677222],
              [126.808649847887565, 37.665871285689853],
              [126.808643792243501, 37.665881409924403],
              [126.808641880408373, 37.665884607433291],
              [126.808640349506589, 37.66588267503338],
              [126.808635563998877, 37.665876638753737],
              [126.808634084615903, 37.665874775156389],
              [126.808627144019965, 37.66587542488773],
              [126.808626615194072, 37.66587547406472],
              [126.808626490635305, 37.665875211950514],
              [126.808558665900236, 37.665912682611754],
              [126.80850912163416, 37.665940052006228],
              [126.808372188600003, 37.666015699474826],
              [126.808369407772489, 37.666017235249221],
              [126.8083643567618, 37.666018307049171],
              [126.808364525750164, 37.666020379283694],
              [126.808331022201784, 37.666026814762894],
              [126.808260466647354, 37.666040366112142],
              [126.808205261200072, 37.666050968186241],
              [126.808109551266284, 37.666070506319677],
              [126.808040370436842, 37.666087169762385],
              [126.808019730021471, 37.666091802255522],
              [126.808004721979032, 37.666102845561682],
              [126.807909915232756, 37.666172608825171],
              [126.807885424919618, 37.666199879802534],
              [126.807871007686586, 37.666231056737345],
              [126.807856599511581, 37.666262233723344],
              [126.80783413061944, 37.666312110951353],
              [126.8078334454447, 37.666329727822195],
              [126.807833281523187, 37.666333948663649],
              [126.807832476438591, 37.666382058437101],
              [126.807807168514728, 37.666400906229143],
              [126.80770403909203, 37.666477712098242],
              [126.807516635465518, 37.666628405813405],
              [126.807482617830473, 37.666674024007307],
              [126.807480124109247, 37.666677359588078],
              [126.807461043405198, 37.666693003605786],
              [126.807352878485929, 37.666750577781684],
              [126.807214418879028, 37.666777921063769],
              [126.807066189816169, 37.666791978051684],
              [126.807021123588754, 37.666796251449085],
              [126.806803557324244, 37.666821120391582],
              [126.806138575415574, 37.666781278109198],
              [126.805988282175917, 37.66678283194846],
              [126.805718867125904, 37.666783556473078],
              [126.805690286066834, 37.666783190840867],
              [126.805256565888456, 37.666777652615721],
              [126.805093122806056, 37.666806868812657],
              [126.805029435749134, 37.666818254049616],
              [126.804911505346553, 37.666839334209392],
              [126.804705414409838, 37.666882271754915],
              [126.804636500771039, 37.6668966291804],
              [126.804422670277731, 37.666930283831192],
              [126.804374801126784, 37.666937815999539],
              [126.80423614298283, 37.666933075580737],
              [126.804230756624179, 37.666932890581663],
              [126.804217771952892, 37.666933349370922],
              [126.803693242193617, 37.666951901948941],
              [126.803613816727193, 37.666942308925776],
              [126.803610683996297, 37.666941932603706],
              [126.80356489129386, 37.666936433641986],
              [126.803562094265672, 37.666942601111032],
              [126.803561959966345, 37.666942897754993],
              [126.803559642556749, 37.666942634404656],
              [126.803424560021057, 37.666927284829754],
              [126.803396592539627, 37.666924107430084],
              [126.803346676514309, 37.666918435312532],
              [126.803341515790962, 37.666911336647239],
              [126.803252652936663, 37.666921070612659],
              [126.803122838794465, 37.666935267473477],
              [126.803111740014245, 37.666937756249396],
              [126.802976088951482, 37.666968174741484],
              [126.802808997346133, 37.666978076969279],
              [126.802711095949334, 37.66695695164529],
              [126.802636029717902, 37.666940754497013],
              [126.802626236932269, 37.666961198113093],
              [126.802616192048959, 37.666982166610921],
              [126.802598556168704, 37.667008083558784],
              [126.802597984775232, 37.667008803042108],
              [126.8026113268496, 37.667021529309721],
              [126.802641398645036, 37.667050410677284],
              [126.802666515722677, 37.667067392306265],
              [126.802687220508631, 37.667081393303967],
              [126.802701837364566, 37.667125112814944],
              [126.802674569188284, 37.667192370638844],
              [126.802637408798347, 37.667228887096556],
              [126.802627617513735, 37.66723595777642],
              [126.802620908836332, 37.667253366841614],
              [126.802607557504189, 37.667287671606367],
              [126.802541922377031, 37.667332787744385],
              [126.802520355685957, 37.667345455406362],
              [126.802514565979493, 37.667348869634175],
              [126.802485556950984, 37.667346477221365],
              [126.802469233646363, 37.667377272664829],
              [126.802462317451685, 37.66739031718383],
              [126.802441568559402, 37.667435781635845],
              [126.802410555975328, 37.667461855586609],
              [126.80234896829063, 37.667520315318328],
              [126.802281505782886, 37.66757164684492],
              [126.802269565779426, 37.667586222726278],
              [126.802247371111989, 37.667621142112615],
              [126.802237757027683, 37.66765590337949],
              [126.802226198640497, 37.667697779009593],
              [126.802177151837626, 37.667770944931625],
              [126.802136270412518, 37.667757722184781],
              [126.802134712870071, 37.667789163764844],
              [126.802143227436957, 37.667805232471736],
              [126.802151065018734, 37.667819734276897],
              [126.802140656944374, 37.667876072135471],
              [126.802139086657661, 37.667975716816272],
              [126.802139211196888, 37.667975978937058],
              [126.802144792449511, 37.667987810289297],
              [126.802159036882941, 37.668018005996409],
              [126.802180340893671, 37.668045045572455],
              [126.802213952119146, 37.668081032391271],
              [126.802273126423898, 37.668120305230033],
              [126.802330879272304, 37.66816123527736],
              [126.802366092448125, 37.668195441499293],
              [126.802397523145032, 37.66820855959817],
              [126.802409101024878, 37.66821499322517],
              [126.802436064200862, 37.668231994833285],
              [126.802460314792555, 37.668246370159032],
              [126.802504755811441, 37.668276672299108],
              [126.802522640350787, 37.668297885263051],
              [126.802529042353669, 37.668307563681111],
              [126.802548557631582, 37.668329723661245],
              [126.802561227805214, 37.668356840100891],
              [126.802584282648652, 37.668406186905045],
              [126.802604359255398, 37.668429920300987],
              [126.802621908049332, 37.668450667983947],
              [126.802662495502418, 37.668489298945516],
              [126.802774195713965, 37.668595531363572],
              [126.802789418848249, 37.668624658400539],
              [126.802795327061361, 37.668636462811719],
              [126.802798439110333, 37.668642693131375],
              [126.802939914337813, 37.668634371889873],
              [126.802949000460444, 37.668669615072446],
              [126.803035376094584, 37.668667327808159],
              [126.803081654155108, 37.66890985726981],
              [126.803095478792329, 37.668953234944816],
              [126.803102337059585, 37.668974763026611],
              [126.803146499169969, 37.669117910811224],
              [126.803193255528598, 37.669232709245826],
              [126.803214402613506, 37.66928461342404],
              [126.803233552355081, 37.669329424999624],
              [126.803246711572285, 37.669367828824598],
              [126.803291914434467, 37.669503770303521],
              [126.803362065965629, 37.66980535296355],
              [126.803379795042488, 37.669873586930379],
              [126.803410820138026, 37.669970222615873],
              [126.803399894957636, 37.669986601838083],
              [126.803404563501132, 37.670021388055204],
              [126.803446614486305, 37.670064434858155],
              [126.803463400115916, 37.670102843848234],
              [126.803481385984867, 37.670159634306607],
              [126.803537778886025, 37.670222077531434],
              [126.803596818465394, 37.670270198100965],
              [126.803597328158617, 37.67029137139874],
              [126.803576599334534, 37.670286381855291],
              [126.80355946359758, 37.670336626804477],
              [126.803565355422691, 37.670422768640222],
              [126.803541266326704, 37.670488320264269],
              [126.8035370419407, 37.670499664614248],
              [126.803516881035264, 37.670536301534987],
              [126.803530882819857, 37.670641828255391],
              [126.803519010518187, 37.670759294338815],
              [126.803519574677253, 37.670845247879114],
              [126.803512053535528, 37.670903347842952],
              [126.8035361321257, 37.670969969849018],
              [126.803541070880726, 37.671031424207243],
              [126.803543943356047, 37.671059180300247],
              [126.803526251142188, 37.671105369601058],
              [126.803522183967459, 37.671143565303105],
              [126.803537258078549, 37.67127170894441],
              [126.803520080742572, 37.671294832811149],
              [126.803536624608284, 37.671382436391156],
              [126.803573775203034, 37.671435475118493],
              [126.803615335863768, 37.671449060105296],
              [126.803620581465566, 37.671480601343802],
              [126.803602061751306, 37.671497150253359],
              [126.803656897594905, 37.671548687218149],
              [126.803669726230311, 37.671583215144402],
              [126.803694053346803, 37.671642451333007],
              [126.803709432334969, 37.671699417434191],
              [126.803720050519672, 37.671755475735566],
              [126.803717904733475, 37.671795564746724],
              [126.803713549732848, 37.671855832160858],
              [126.803725642864222, 37.671911803627665],
              [126.803747451108364, 37.671937967997607],
              [126.803768013610835, 37.671962508035584],
              [126.803806565864662, 37.672000775060539],
              [126.803843480437493, 37.672040047296399],
              [126.803854829442344, 37.672052121713314],
              [126.80391801393975, 37.67207646263013],
              [126.803965353602578, 37.672134473577223],
              [126.80400410596863, 37.672181929808083],
              [126.804034255202026, 37.672225136895776],
              [126.804086537213934, 37.672300096598967],
              [126.804125767226196, 37.672424404233837],
              [126.804171355222678, 37.672545571298762],
              [126.804180698022691, 37.672569102284847],
              [126.804179734160073, 37.672592175881768],
              [126.804176585337501, 37.672667571021258],
              [126.804172964607133, 37.672696598406667],
              [126.804171025382516, 37.672712160929947],
              [126.804142194858429, 37.672769594399817],
              [126.804064665386221, 37.67285451568938],
              [126.804038369001603, 37.672897268911576],
              [126.803999161499846, 37.672978380159833],
              [126.803985789199103, 37.67301998203493],
              [126.803985734231588, 37.67304043453624],
              [126.803986550133885, 37.673075212399532],
              [126.80399745533478, 37.673151815316139],
              [126.804005339724597, 37.673213362728788],
              [126.804006343593045, 37.673219762639334],
              [126.804034038593656, 37.673289397355497],
              [126.80405858936652, 37.67335112152572],
              [126.804090335614276, 37.673433433808476],
              [126.804117320248011, 37.673473391853179],
              [126.804206109296516, 37.673545978288786],
              [126.804341392230782, 37.673628371300033],
              [126.804385462035299, 37.673667278166178],
              [126.8043960820645, 37.673686780539882],
              [126.804477047997111, 37.673835462602618],
              [126.804460855842819, 37.674010669041749],
              [126.804459941270224, 37.674020572793864],
              [126.804457210336921, 37.674050129097807],
              [126.804428334508501, 37.674124772218548],
              [126.804402889261951, 37.674164633679517],
              [126.804356055327148, 37.6742379937359],
              [126.804358690992487, 37.674276097389971],
              [126.804360861115526, 37.674307465414977],
              [126.804383451303238, 37.674637439605867],
              [126.804513478127404, 37.674589724284374],
              [126.804532817000407, 37.674606965263422],
              [126.804545597306699, 37.674618339611008],
              [126.804608000227304, 37.674639077467319],
              [126.804644102587517, 37.674647578894898],
              [126.804702976129988, 37.674661444034868],
              [126.80472128134501, 37.674665753917793],
              [126.804812248527753, 37.674687168402372],
              [126.804886139635443, 37.674735402888842],
              [126.804911959111351, 37.674745006334938],
              [126.804920836897566, 37.674748299814468],
              [126.804950349223176, 37.674759295928403],
              [126.804874039137545, 37.674853410285614],
              [126.804820651864247, 37.674895666896305],
              [126.804778268129468, 37.674934698517539],
              [126.804537663695328, 37.675131790023393],
              [126.804428785932572, 37.67519710764936],
              [126.804245207434036, 37.67529684948439],
              [126.804190399452096, 37.675326629423708],
              [126.803969900213943, 37.675464829658452],
              [126.803699645735591, 37.675599341350242],
              [126.803555766129477, 37.675654330551069],
              [126.803428044770044, 37.675686369990885],
              [126.803276879113668, 37.675710171509451],
              [126.802936535154942, 37.675772668085678],
              [126.802863642290518, 37.675732181551083],
              [126.80277580790316, 37.6756834713022],
              [126.802630885257699, 37.675591748510094],
              [126.802615378004276, 37.675581931542034],
              [126.802550828239148, 37.675541082243491],
              [126.802465515943624, 37.675487076986258],
              [126.802452722894046, 37.675481200408903],
              [126.802388098687047, 37.675451711754199],
              [126.801601701799413, 37.675092685378118],
              [126.801159205742024, 37.674890646549088],
              [126.800616829967012, 37.674642999049269],
              [126.800382477376715, 37.674535992991807],
              [126.800371143589089, 37.674532945985753],
              [126.799493195005084, 37.674797376119216],
              [126.799430008229251, 37.674810334966686],
              [126.799345072899342, 37.674827755915139],
              [126.79910630632088, 37.674876571515391],
              [126.799074121125884, 37.674883150974281],
              [126.798837767327583, 37.675057767152822],
              [126.798770647553454, 37.67510735725098],
              [126.798654559795452, 37.675193121831143],
              [126.798542151054022, 37.675276168155733],
              [126.798517001283656, 37.675294748053403],
              [126.798513835074203, 37.675333304024512],
              [126.798504339262792, 37.675449152217062],
              [126.798497888079837, 37.675528516756103],
              [126.798472562665253, 37.67583696452386],
              [126.798366164496713, 37.676078792739347],
              [126.798317209850495, 37.676190058165695],
              [126.798368078314738, 37.676375780851551],
              [126.798408685688827, 37.676524024971833],
              [126.79844714641294, 37.676801022822467],
              [126.798455813357137, 37.676863434166783],
              [126.798459379729664, 37.676889455826654],
              [126.798469172723713, 37.676960783438567],
              [126.798475941685254, 37.677010034098707],
              [126.798493886273164, 37.677163949686204],
              [126.798499938033984, 37.677202418065491],
              [126.798507924417692, 37.677253172158359],
              [126.798528093118577, 37.677381413279711],
              [126.798570827409563, 37.67748595584461],
              [126.798610303615391, 37.677558595643383],
              [126.798677246293394, 37.67768178267783],
              [126.79868017391351, 37.677689537037317],
              [126.798685801801895, 37.67770450180813],
              [126.798683491145766, 37.677736375044951],
              [126.798682503326162, 37.677749997162643],
              [126.798673558678445, 37.677870890595671],
              [126.798672028878357, 37.677891249577279],
              [126.798673142259361, 37.67791970372916],
              [126.798674039994765, 37.677943599513576],
              [126.798674361673676, 37.677950988663106],
              [126.798700988226685, 37.677997163102852],
              [126.798770488548925, 37.678117832571218],
              [126.798838675825522, 37.678554198487973],
              [126.79888941601223, 37.678695288457995],
              [126.798927786012797, 37.678800498948192],
              [126.799002808791599, 37.679017130604478],
              [126.799111983149274, 37.679472846149544],
              [126.799311188782795, 37.679503101114022],
              [126.799304778731198, 37.67977635466282],
              [126.799269121523508, 37.679882246419112],
              [126.799177978082326, 37.680049761707949],
              [126.798941187655785, 37.680304706493281],
              [126.798640760707656, 37.680549687692469],
              [126.798670801642203, 37.680635737074795],
              [126.798699105525486, 37.680716818849199],
              [126.798803299421238, 37.681015318034056],
              [126.799091672128569, 37.681524050974176],
              [126.799082223453567, 37.68187207802243],
              [126.799082236413795, 37.681951452085229],
              [126.799325747507979, 37.681983477368497],
              [126.799364752618686, 37.681988606888396],
              [126.799387498621982, 37.682086672416162],
              [126.799319259017437, 37.682083225934335],
              [126.799088257758953, 37.682071563737459],
              [126.799086838905552, 37.682176793611426],
              [126.799070509202977, 37.682362031700819],
              [126.799069643895081, 37.682371914055011],
              [126.798974298056407, 37.682957739867994],
              [126.798900915898656, 37.683178621302559],
              [126.798958997833537, 37.683459147749566],
              [126.798961564483278, 37.683471541754827],
              [126.798914236083121, 37.683491282351397],
              [126.798941777452043, 37.683619175934311],
              [126.798936160753158, 37.68368310030629],
              [126.798926919150333, 37.683788262012321],
              [126.798970646425218, 37.683925104582606],
              [126.798934015023832, 37.683971802396961],
              [126.798903798817804, 37.684036260117381],
              [126.798745420223412, 37.684162662512442],
              [126.798729790973326, 37.684199037030673],
              [126.798721826331899, 37.684293083999698],
              [126.798778951367169, 37.68432193968885],
              [126.798824734733984, 37.684345065816032],
              [126.798844529376666, 37.684344255147877],
              [126.798938795275561, 37.684340397301],
              [126.799014230865566, 37.684365392308933],
              [126.799058395319051, 37.684466918261045],
              [126.799157142592946, 37.684464835439698],
              [126.79918979458067, 37.684464531955214],
              [126.799181244539994, 37.684368212826691],
              [126.799180114394943, 37.684355495678403],
              [126.79917120845208, 37.684253853031791],
              [126.7996416300074, 37.684148072762227],
              [126.800093717591594, 37.684449591644857],
              [126.800153506418624, 37.684727552521721],
              [126.800224751969793, 37.685211764869763],
              [126.80033627919255, 37.685306917518346],
              [126.800426981483, 37.685555558684968],
              [126.800572270694843, 37.685742847943203],
              [126.800972334922619, 37.685800006858948],
              [126.801635854477368, 37.685894805684825],
              [126.801917569690701, 37.685935054720545],
              [126.802120777967119, 37.686001260742913],
              [126.80265754624169, 37.686186414607405],
              [126.802689598256279, 37.686241066467602],
              [126.802781485432675, 37.686367970484042],
              [126.803030620157784, 37.686537880272212],
              [126.803046724783385, 37.68654349514339],
              [126.803096890381141, 37.686560985216779],
              [126.803196554675111, 37.686595732423534],
              [126.803388230843751, 37.686663708622106],
              [126.803587140096752, 37.686715045574097],
              [126.803650210120907, 37.686673611139547],
              [126.803843354504167, 37.686546722432261],
              [126.804033991508987, 37.686481272484919],
              [126.804215770512499, 37.686420671348529],
              [126.80427865701779, 37.686399304308026],
              [126.804412080270524, 37.686353967169218],
              [126.80457502448526, 37.68629973127112],
              [126.804615111800572, 37.686266247434226],
              [126.804641546299081, 37.686244169517423],
              [126.804669215365521, 37.686221052440004],
              [126.80469783625955, 37.686197296523304],
              [126.804876343811188, 37.686049131540962],
              [126.804908087000754, 37.686049545781103],
              [126.805194115295492, 37.686051644167215],
              [126.805229160929571, 37.686063021621329],
              [126.805305667958223, 37.686087859391158],
              [126.805366315502852, 37.686107547281658],
              [126.805422586034311, 37.686125815016879],
              [126.805763889386867, 37.686277112415794],
              [126.806036667361283, 37.68623926621661],
              [126.806091699188698, 37.686216805549023],
              [126.806731239312825, 37.685881161848656],
              [126.806979260656419, 37.685719936550186],
              [126.807143858702332, 37.685687270016224],
              [126.807390639035987, 37.685627500108751],
              [126.807676513142468, 37.685574281068256],
              [126.807739257967484, 37.685572367265799],
              [126.807833554053317, 37.685591378029379],
              [126.808045629827291, 37.685518928755933],
              [126.808046824290273, 37.685452618331105],
              [126.808079382692952, 37.685456084769299],
              [126.808332208975841, 37.685482999604851],
              [126.808541572963961, 37.685537399942696],
              [126.808722161800674, 37.685602802214866],
              [126.80882495847716, 37.685640032657801],
              [126.80906488043567, 37.685716197815928],
              [126.809336961883844, 37.685942146775375],
              [126.809757338107886, 37.686291241868894],
              [126.80982645028196, 37.686315922565399],
              [126.810005730261153, 37.686379941601153],
              [126.810060533514644, 37.686406157210897],
              [126.810196422483855, 37.68646746328124],
              [126.810492147504704, 37.68661459391511],
              [126.810831470935213, 37.686812447201376],
              [126.810959068933059, 37.686888640639509],
              [126.811094320157508, 37.687079515016009],
              [126.811115467317251, 37.687109359626277],
              [126.811156829344299, 37.687160962141725],
              [126.811320654668791, 37.687426651499017],
              [126.8114495153077, 37.687532543106748],
              [126.811735922716821, 37.687788068608526],
              [126.812031269982057, 37.688127019589736],
              [126.812467409504791, 37.688627539608994],
              [126.81249586424201, 37.68866019272788],
              [126.812499216574793, 37.688664405218518],
              [126.812559675503167, 37.688732975079297],
              [126.812506454557919, 37.688841365956414],
              [126.812491490403488, 37.688885310058389],
              [126.812481534554038, 37.688915646367853],
              [126.812479493397873, 37.688921869807523],
              [126.812454930523927, 37.688996159504846],
              [126.812293595755918, 37.6892369278426],
              [126.812151521020482, 37.689448951595161],
              [126.8121469418777, 37.689466060801614],
              [126.81199700318021, 37.690020563011103],
              [126.811990141515906, 37.690045942933843],
              [126.812034173683884, 37.690142450094534],
              [126.812069291234394, 37.690219416870086],
              [126.812080658343476, 37.690297008503613],
              [126.812158637384641, 37.690279609114171],
              [126.812202823016676, 37.69055945750322],
              [126.812566931645648, 37.690806276671751],
              [126.81269189754903, 37.69109201178486],
              [126.8128952324371, 37.691475879562347],
              [126.81296428758516, 37.69160744121902],
              [126.813001887864218, 37.6918027412543],
              [126.812973131669011, 37.691878951642131],
              [126.812972364319933, 37.69188098415637],
              [126.812951093349142, 37.691937354110337],
              [126.812886041371414, 37.692015903734195],
              [126.812700396267942, 37.69221174870966],
              [126.812783613238665, 37.692213232374456],
              [126.813279741452448, 37.692221773949143],
              [126.813833521552951, 37.692231369076531],
              [126.813873202381444, 37.692232053332923],
              [126.813938336021224, 37.692233184901966],
              [126.814589537617707, 37.692253859849245],
              [126.814661074905757, 37.692256135062038],
              [126.814694179508692, 37.69225717920559],
              [126.814704496205849, 37.692257466294514],
              [126.814898491253842, 37.692401593492477],
              [126.81503360348789, 37.692501977051343],
              [126.815262082532158, 37.692671712186261],
              [126.815289841649872, 37.692692981405813],
              [126.815310742632647, 37.692708998460006],
              [126.815344664557529, 37.692738365764335],
              [126.815539277530974, 37.692906850983157],
              [126.815783086958973, 37.693117972605911],
              [126.81597241407249, 37.693216565969031],
              [126.816218287765963, 37.693305539063701],
              [126.81630624876415, 37.693337369086535],
              [126.816922028663029, 37.6935181680619],
              [126.817565877732648, 37.693766670349206],
              [126.818123712876627, 37.693994338880181],
              [126.818152153571532, 37.69400597895325],
              [126.818650051069923, 37.69422280342355],
              [126.818692519009929, 37.694227430122069],
              [126.818840415789396, 37.694243540300512],
              [126.81888163050148, 37.694248029901466],
              [126.818918753366717, 37.694252081246589],
              [126.818926926706254, 37.694252974794615],
              [126.819033277813247, 37.694264577010777],
              [126.819075123647224, 37.694269142294523],
              [126.819081824534379, 37.694267821797318],
              [126.819171352902373, 37.694250189750463],
              [126.819632895141353, 37.694159365076381],
              [126.819833773675342, 37.694127689723111],
              [126.820039521401995, 37.694005475589286],
              [126.820101769296201, 37.693987553928103],
              [126.820134148198221, 37.693978231680447],
              [126.82015610645179, 37.693971903020305],
              [126.820180350601092, 37.693964915149294],
              [126.820200564312657, 37.693959086570842],
              [126.820236831616015, 37.693948629415452]
            ]
          ]
        ]
      },
      id: '31103510'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '주교동',
        ADM_CD: '31101510'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.835137702818159, 37.676834819034212],
              [126.835168890689175, 37.67658391839818],
              [126.835195537990799, 37.676321049602443],
              [126.835200545725527, 37.676302623217957],
              [126.835223436477108, 37.676218381093456],
              [126.835248479585161, 37.67596902759621],
              [126.835269816176606, 37.67585445524837],
              [126.8352414649883, 37.675555921964133],
              [126.835242500689063, 37.675497541816362],
              [126.835218402106989, 37.675428041866603],
              [126.835220386174356, 37.675395042948374],
              [126.835105379479032, 37.675151059601973],
              [126.835084875342844, 37.675113384918497],
              [126.834979224279095, 37.674919268581583],
              [126.835029236581505, 37.674872593376634],
              [126.835111193689983, 37.674796103971481],
              [126.835166394049807, 37.674739738973422],
              [126.835277285479847, 37.674626506019962],
              [126.835316549892482, 37.674590728414934],
              [126.835332495780577, 37.674575227171474],
              [126.835355017408276, 37.674592924512552],
              [126.835711416692575, 37.67425433094381],
              [126.83577289594335, 37.674103216829543],
              [126.835803798143601, 37.674090826399805],
              [126.83585626842779, 37.674069780319684],
              [126.835948802803912, 37.67402672772878],
              [126.836158439396456, 37.673745411125068],
              [126.836180519477537, 37.673716310854125],
              [126.836320929486547, 37.673531256932023],
              [126.836396287895994, 37.673431936774904],
              [126.836417288817188, 37.673350313801627],
              [126.836466731604673, 37.673158151942403],
              [126.836511389119238, 37.673103371894022],
              [126.836534572720936, 37.673081600122465],
              [126.836613453792424, 37.673009002279493],
              [126.836615975040388, 37.673006910966194],
              [126.836689822405376, 37.672945644153337],
              [126.836771525995175, 37.672907907291439],
              [126.83716292229613, 37.672727135974789],
              [126.83719927288665, 37.672710345782065],
              [126.837262866127105, 37.672692324228294],
              [126.837338975173267, 37.672537685246994],
              [126.837351336259417, 37.672512568977403],
              [126.837614795106532, 37.672337724528873],
              [126.837793072515936, 37.672294679690758],
              [126.837979633905135, 37.672249635073619],
              [126.838163308988044, 37.672095966110135],
              [126.838166583176502, 37.672093227330308],
              [126.838173613433071, 37.672090435718772],
              [126.838461980744967, 37.671975952052414],
              [126.838518223007355, 37.671928536935582],
              [126.838640350593451, 37.671825622629584],
              [126.83873693668049, 37.671744231045587],
              [126.839144161053923, 37.671563688733578],
              [126.839517429958349, 37.671261007367825],
              [126.839722555400328, 37.671131151887877],
              [126.839901890620993, 37.671091628532743],
              [126.840044124895286, 37.671123933022152],
              [126.840212605075465, 37.671163537082549],
              [126.840335349669061, 37.671192391881029],
              [126.84055131693394, 37.671245415888897],
              [126.840597562272976, 37.671342492096748],
              [126.840614319990806, 37.671377668966876],
              [126.840648259087445, 37.67144884527395],
              [126.840662108955286, 37.67147344234975],
              [126.840679821647768, 37.671504885851895],
              [126.84068648781421, 37.671524004170351],
              [126.840701230762036, 37.671562497457032],
              [126.841304634546574, 37.672019768167345],
              [126.841509977625122, 37.67211000719108],
              [126.84160943464272, 37.672153714274693],
              [126.841668247340564, 37.672194031662308],
              [126.842097607285353, 37.672196504105074],
              [126.842673304665539, 37.672199711341591],
              [126.8435019531086, 37.672222629948983],
              [126.843951591973052, 37.672230979704068],
              [126.844063611744971, 37.672050485059117],
              [126.844350519019656, 37.671557044559115],
              [126.844384645777538, 37.67149852824388],
              [126.844799191431548, 37.671429704684094],
              [126.845346411796172, 37.671339793270867],
              [126.846387306339381, 37.671209174085213],
              [126.847805512514626, 37.670827160149699],
              [126.84780629452942, 37.6708269454519],
              [126.848246735739053, 37.670360186963869],
              [126.848481276780433, 37.670116416838724],
              [126.848489631351868, 37.670107733648948],
              [126.848366570699497, 37.669985962502864],
              [126.848133738130485, 37.669855922411607],
              [126.847873974361718, 37.66971857823534],
              [126.84742587364363, 37.669508662380586],
              [126.847471929229627, 37.669387315724244],
              [126.84696103045421, 37.669155589190154],
              [126.846745696487915, 37.669082866617231],
              [126.846477191907226, 37.668916859267433],
              [126.846424583279671, 37.66875920077441],
              [126.846402083120964, 37.668736108506572],
              [126.8463264792328, 37.668658514398381],
              [126.846218376829526, 37.668672221974489],
              [126.846165140272973, 37.668586089101616],
              [126.846160722685212, 37.668544211546376],
              [126.846157245608964, 37.668546605120319],
              [126.846085563838599, 37.668552282661366],
              [126.846064111843319, 37.668554873111091],
              [126.846061292176643, 37.668553029588949],
              [126.845964359737209, 37.668487021610247],
              [126.845936855042652, 37.668466878581469],
              [126.845869287742289, 37.668445607532824],
              [126.845797488145706, 37.668402068248319],
              [126.845749295353613, 37.668357830650258],
              [126.845646044614469, 37.668311807612149],
              [126.845603096424028, 37.668291558996373],
              [126.845579949629865, 37.668287014072817],
              [126.845543821193573, 37.668263343084391],
              [126.845426863307495, 37.668170063892958],
              [126.845276942911511, 37.66800423415809],
              [126.845212040183384, 37.667932445588967],
              [126.845207795262581, 37.667926990633035],
              [126.845217160222361, 37.667926182758215],
              [126.845073518124067, 37.667879034554304],
              [126.844992785509646, 37.667849972747675],
              [126.844722464387814, 37.667891389667425],
              [126.844569685172956, 37.667869082004671],
              [126.844426596120087, 37.667768144056893],
              [126.843192079524982, 37.667584847662809],
              [126.841649131423139, 37.667355735137008],
              [126.841708597219451, 37.667236064521859],
              [126.841495551494191, 37.666955103723254],
              [126.841456017780573, 37.666754855347371],
              [126.841294411369887, 37.666419131979417],
              [126.840835635279987, 37.666143962922973],
              [126.840768166874881, 37.665672082385754],
              [126.842290908782303, 37.665512748317504],
              [126.842636898725758, 37.665243980929901],
              [126.842661626635007, 37.665213237504105],
              [126.842667746062844, 37.665213350924539],
              [126.842706553044636, 37.665214073734717],
              [126.8427557419854, 37.665213958465202],
              [126.842795962233055, 37.66522131026337],
              [126.842916409714732, 37.665251871104374],
              [126.843040117988338, 37.665283742486494],
              [126.842977749020434, 37.665225237912097],
              [126.842810536822384, 37.665103483580261],
              [126.842802402815764, 37.665097300227472],
              [126.842794301573349, 37.66509100168691],
              [126.842786186663474, 37.665084710280247],
              [126.842778074101957, 37.665078409872848],
              [126.842769972800113, 37.665072118540898],
              [126.842761860225437, 37.665065819934973],
              [126.842753747572687, 37.66505953034131],
              [126.842745644086861, 37.66505322998227],
              [126.842737531452727, 37.665046938584922],
              [126.842729396367034, 37.665040621824772],
              [126.842728468383953, 37.665039908211199],
              [126.842713014948288, 37.665026460836238],
              [126.842701907858668, 37.66501052838796],
              [126.842695778947331, 37.664992977859129],
              [126.842694946233351, 37.664974775441856],
              [126.842699471232208, 37.664956916543183],
              [126.842709927115664, 37.664938948285474],
              [126.842656181294643, 37.664901674562515],
              [126.842601772065152, 37.664868534205013],
              [126.842533707847139, 37.66482707958675],
              [126.842364924803405, 37.664721628067824],
              [126.842168604502859, 37.664608660696743],
              [126.8421067035504, 37.664542489786079],
              [126.842078779818564, 37.664515252782586],
              [126.842047647786728, 37.664498503735025],
              [126.841921568267423, 37.664425652804738],
              [126.841792336902813, 37.664352553411753],
              [126.841598028161528, 37.664243369268043],
              [126.841587654391105, 37.664239186891862],
              [126.841529325580694, 37.664337322163796],
              [126.841274066604683, 37.664205433503192],
              [126.841255600330129, 37.664196814641166],
              [126.84124607189645, 37.664179861557237],
              [126.84123286474005, 37.66412859786881],
              [126.841129163319152, 37.66410144393484],
              [126.841119471588371, 37.664128622469306],
              [126.840950109343211, 37.664040098007412],
              [126.840966592218365, 37.664021947259982],
              [126.840937067589195, 37.663980155437343],
              [126.840876873762411, 37.663923186947542],
              [126.840854191357735, 37.66389849544597],
              [126.840811079598296, 37.663850765435129],
              [126.840757685249471, 37.66379317691564],
              [126.84067938643328, 37.663800880047425],
              [126.8406045829161, 37.663854620783816],
              [126.840509418181057, 37.663857113585415],
              [126.8404945787222, 37.663846620294763],
              [126.840369855004937, 37.663733093023765],
              [126.840352446560246, 37.663728728788584],
              [126.840366841704224, 37.663720239482643],
              [126.840436291963286, 37.663679249768919],
              [126.840386556507752, 37.66364245350799],
              [126.840297314854055, 37.663576430690938],
              [126.840183286289957, 37.663492067767159],
              [126.840127107901409, 37.663450506095472],
              [126.839894414313079, 37.663278349550559],
              [126.839670925742794, 37.663113004026386],
              [126.839641200168671, 37.663046407151278],
              [126.839615468007864, 37.662988754856414],
              [126.83956524124379, 37.662877876381344],
              [126.839505652047677, 37.662746234778425],
              [126.839504072972858, 37.662742745914748],
              [126.8394711662785, 37.66267051771937],
              [126.839468034418644, 37.662663644692088],
              [126.839410074923649, 37.662536213246383],
              [126.839400030105367, 37.662514142992698],
              [126.839377402369664, 37.662464963372216],
              [126.839372630362305, 37.662454587614789],
              [126.839320295744159, 37.662359155873041],
              [126.839247067008159, 37.662195256770971],
              [126.839212936178114, 37.662119078337867],
              [126.83920972507596, 37.662108103823726],
              [126.83920744605625, 37.66210031441112],
              [126.839189521206734, 37.662041320415533],
              [126.839105461360575, 37.661977758400674],
              [126.838946990099984, 37.661857975489745],
              [126.838916316017233, 37.661834793611455],
              [126.838866315868543, 37.661797000157627],
              [126.838578884731177, 37.661579735547058],
              [126.838531369372589, 37.661543819827649],
              [126.838480723170449, 37.661505536771983],
              [126.838480337989722, 37.661505247088584],
              [126.838462226118807, 37.661491554406766],
              [126.838339214774436, 37.661398572201691],
              [126.838233835577952, 37.661324047913936],
              [126.838190001382216, 37.661293044208833],
              [126.838144360001863, 37.661260762180525],
              [126.837756938701816, 37.660986754774449],
              [126.837575961236269, 37.660858754250029],
              [126.837570031468346, 37.660854563128886],
              [126.837558904280385, 37.660846698754781],
              [126.837465255479529, 37.660780461559348],
              [126.837374395354345, 37.660716199439413],
              [126.837373231516949, 37.660715373595451],
              [126.837314371522595, 37.66067374053334],
              [126.837249744042182, 37.660628037994108],
              [126.837112687183165, 37.660531104391261],
              [126.83709972477277, 37.660520280519265],
              [126.836618670065448, 37.660118308142302],
              [126.83661711584125, 37.660117016813423],
              [126.836616566662897, 37.660116556752172],
              [126.836615253381908, 37.660115454254282],
              [126.836608495291671, 37.660109823514063],
              [126.836508081812141, 37.660025902238893],
              [126.836000246944408, 37.659531584484697],
              [126.835982790148805, 37.659514585436931],
              [126.835976270972949, 37.659508236746518],
              [126.835913618124948, 37.659447250876838],
              [126.835912461459515, 37.65944613302895],
              [126.83590446199463, 37.659438342890951],
              [126.835664386842851, 37.659204655108908],
              [126.83522315995296, 37.658775155775807],
              [126.835220963706163, 37.658773017168187],
              [126.835195615856634, 37.658748332081458],
              [126.835174989721367, 37.658728257750177],
              [126.835067483748176, 37.658623608013826],
              [126.834457392284563, 37.658029728788833],
              [126.834455914202238, 37.658028299056575],
              [126.834441344387542, 37.658014113786862],
              [126.834429487808293, 37.658002569390035],
              [126.834428852815023, 37.658001946595519],
              [126.834425703424216, 37.657998888649416],
              [126.833664481028237, 37.657257875193231],
              [126.833654307188624, 37.657247971625459],
              [126.833646916434219, 37.657240751706603],
              [126.833629270700499, 37.657223574580655],
              [126.833615362052001, 37.65721003468812],
              [126.833615095655958, 37.657209778107621],
              [126.833613945885645, 37.657208657572006],
              [126.833595642546797, 37.657190853909015],
              [126.832611223224674, 37.656573641505283],
              [126.83254394387302, 37.656531458184396],
              [126.832541930426814, 37.656530191242929],
              [126.832507532071673, 37.656508622528349],
              [126.832504511208413, 37.656399541255269],
              [126.832504440810197, 37.656397274019334],
              [126.832504222765522, 37.656389464589125],
              [126.832499094981273, 37.656204461624071],
              [126.832496988637303, 37.656128475041072],
              [126.832487803574097, 37.655797192555681],
              [126.832487024600553, 37.65576884140858],
              [126.832486281598477, 37.655722669424215],
              [126.832497375932476, 37.655697461695006],
              [126.832497629369428, 37.655696885378426],
              [126.832531541775111, 37.655619840746816],
              [126.83266107040123, 37.65532554397425],
              [126.832667976519986, 37.655048170739576],
              [126.832688882846298, 37.655033329681558],
              [126.832692571937457, 37.655031037745218],
              [126.832695894364718, 37.655028673431005],
              [126.832700993475882, 37.654458904775559],
              [126.832923882450828, 37.654866859658647],
              [126.832940281805691, 37.654856764694657],
              [126.83308019954427, 37.654762378377136],
              [126.833126013795592, 37.65473256190014],
              [126.832947226466914, 37.654508556318014],
              [126.832928593970621, 37.654513823764638],
              [126.832917499042949, 37.65451695893146],
              [126.832911302143131, 37.654507611372722],
              [126.83290782215569, 37.654502373908564],
              [126.832831554756154, 37.654383760530905],
              [126.832796587317375, 37.654322254466535],
              [126.832719138524013, 37.654196979831625],
              [126.832805335282515, 37.654119979377811],
              [126.83280961385816, 37.65409411835531],
              [126.832793267028123, 37.653999537938894],
              [126.83279059858738, 37.653983552224858],
              [126.832741186973706, 37.653850262940956],
              [126.832737738700118, 37.653844906675559],
              [126.832734368609465, 37.653827773206395],
              [126.832722953554267, 37.653769653231571],
              [126.832718777724537, 37.65375945542543],
              [126.832685651252191, 37.653678240494507],
              [126.832641645243839, 37.653547742509318],
              [126.832578256026977, 37.653331010289108],
              [126.832571265582203, 37.653307111696314],
              [126.832527299099894, 37.6531567927914],
              [126.832495003518986, 37.653096027315158],
              [126.832494016622121, 37.653060890686177],
              [126.832451232957055, 37.652859776913999],
              [126.832414055650645, 37.652761579593253],
              [126.83238170322845, 37.652672750925952],
              [126.832360565328401, 37.652654774189763],
              [126.832327620132247, 37.652556195194443],
              [126.832296368009608, 37.652523778714311],
              [126.832248552262541, 37.652442882493467],
              [126.832134825682957, 37.652248974188794],
              [126.832103232421389, 37.652194042362503],
              [126.832066111630624, 37.652109366060898],
              [126.832046762481781, 37.652065231149031],
              [126.831998745269061, 37.651957532702745],
              [126.831949102609528, 37.651846539690681],
              [126.831836953198433, 37.651496530951],
              [126.831819241943379, 37.651437267475636],
              [126.831758576361224, 37.651224837798232],
              [126.831739106058322, 37.651158087829721],
              [126.831689313564354, 37.65098738538564],
              [126.831642519428328, 37.650821013659673],
              [126.831571505806622, 37.650581425012497],
              [126.831477331151063, 37.650236584985912],
              [126.83140144603297, 37.649974021700707],
              [126.831367037207201, 37.649880911477055],
              [126.831335543660217, 37.649788606428942],
              [126.831303634123714, 37.649709580081591],
              [126.831217334281362, 37.649574229648699],
              [126.831207546844453, 37.649568666144923],
              [126.831153755152258, 37.649500455113532],
              [126.831042901349221, 37.649359896262368],
              [126.830881119061573, 37.649158077204994],
              [126.830809015611834, 37.649064137388706],
              [126.830589406023662, 37.648790578549161],
              [126.830540480506642, 37.64872896462763],
              [126.830498347052341, 37.648663105220287],
              [126.830477270166242, 37.648631310225269],
              [126.830460324777519, 37.648605748826306],
              [126.830407155978122, 37.648650124981181],
              [126.830360395312354, 37.648652957418228],
              [126.830301107947236, 37.648658890496932],
              [126.830104291293324, 37.648690435331858],
              [126.8299536268336, 37.648734878608948],
              [126.829653228097953, 37.648792104410504],
              [126.829314161033025, 37.648884019844814],
              [126.82930817472338, 37.648885642451134],
              [126.829308515850229, 37.648887290215619],
              [126.829306902741294, 37.648887796604015],
              [126.829296413646475, 37.648891079092778],
              [126.82886252271706, 37.648980319716131],
              [126.828831088286918, 37.648987639022359],
              [126.828740562426091, 37.649011823934721],
              [126.828738006471795, 37.649006880929178],
              [126.828727307928332, 37.648986200370281],
              [126.828708848724943, 37.648957223990557],
              [126.828686232913867, 37.648921799287798],
              [126.828664565152636, 37.648888350278902],
              [126.828654894081424, 37.6488734170199],
              [126.828639397974499, 37.648849745583355],
              [126.828460108770557, 37.648871717971396],
              [126.828390563253606, 37.648892257200025],
              [126.828267075748414, 37.648906544080432],
              [126.828241292006126, 37.64890922923081],
              [126.828168775964926, 37.648925916261931],
              [126.828095592348873, 37.648943594512062],
              [126.827952969235199, 37.64896511428806],
              [126.827856698079842, 37.648970939088997],
              [126.827830373245362, 37.648975548105518],
              [126.827506562003464, 37.649026642993242],
              [126.827536802773224, 37.648838428827169],
              [126.827469907979292, 37.648660658019168],
              [126.827415514570504, 37.648516576178331],
              [126.827158080730271, 37.648108279319665],
              [126.82715218524406, 37.648100087773585],
              [126.827125978641718, 37.648055938164589],
              [126.826985925766181, 37.647807486725881],
              [126.826732112121476, 37.64721491499143],
              [126.826712934704105, 37.64718313286852],
              [126.826712462263629, 37.647182351424597],
              [126.826684762216431, 37.647135940770312],
              [126.826583372811513, 37.646958117650939],
              [126.826542610092645, 37.646889083866057],
              [126.826458769306626, 37.646747094873902],
              [126.826276824578983, 37.646329293454087],
              [126.826270046891764, 37.646246620067004],
              [126.826234383055237, 37.645840083150425],
              [126.826111466616041, 37.645472501659846],
              [126.826071768542832, 37.645384254755676],
              [126.826057444775486, 37.645351564667763],
              [126.826043310424126, 37.64531886304156],
              [126.826041195680546, 37.64531430015289],
              [126.826027210868574, 37.645284118591867],
              [126.825956442736128, 37.645294368871191],
              [126.825827290336392, 37.644901499810764],
              [126.825819085892647, 37.644876543810192],
              [126.82579343138039, 37.644838741495185],
              [126.825746627008002, 37.644768282139928],
              [126.82571666434778, 37.64472317515456],
              [126.825581774886984, 37.644515130082063],
              [126.825556567339234, 37.64447606119883],
              [126.825539236874064, 37.644449625299231],
              [126.825524954351494, 37.644427837849037],
              [126.825514870094082, 37.644409090245446],
              [126.825508031455655, 37.644396570449906],
              [126.82547647441092, 37.644338783409317],
              [126.825358581232592, 37.644126329509938],
              [126.825285157014704, 37.644150881807533],
              [126.825252320151918, 37.644161427019846],
              [126.825146316894305, 37.644197082929345],
              [126.825152760188303, 37.644208939810561],
              [126.825110397096395, 37.644237667069277],
              [126.825081184054085, 37.644257477891472],
              [126.825068785263497, 37.644344339193232],
              [126.82505091900552, 37.644372503540545],
              [126.825018681610388, 37.644378041631363],
              [126.824943446337315, 37.644391749382919],
              [126.824936554643045, 37.644392993438835],
              [126.824923122716029, 37.644395097792078],
              [126.824885590200012, 37.644401186902513],
              [126.82478687326514, 37.644441159183806],
              [126.824697971167311, 37.644475766975667],
              [126.824521766864493, 37.644499838565864],
              [126.82450812141326, 37.644501485576875],
              [126.824228080160211, 37.644535428347972],
              [126.824132268815447, 37.644525886019757],
              [126.82408890511249, 37.644520118743124],
              [126.823743319946971, 37.644553718162094],
              [126.823709615367704, 37.644566533822861],
              [126.823567866614496, 37.644618170340635],
              [126.823495149208497, 37.644659532641427],
              [126.823358262215834, 37.644735739055719],
              [126.823301203692751, 37.644768032650546],
              [126.823195230025974, 37.644827713676122],
              [126.823137071156196, 37.644851089444217],
              [126.823082676663034, 37.644900696510739],
              [126.823039126004034, 37.645036912226317],
              [126.822949087719067, 37.645128340921147],
              [126.82292793240444, 37.645152726622477],
              [126.822845295042725, 37.645195541306038],
              [126.822741589361314, 37.645249269943633],
              [126.822727363040869, 37.645252700747008],
              [126.822717042063402, 37.64525571952953],
              [126.822691881568971, 37.645254050029891],
              [126.822649198023669, 37.64524961739032],
              [126.822425494884996, 37.645380919931853],
              [126.82226957221134, 37.645532118002059],
              [126.822230905023744, 37.645556458851907],
              [126.822206378526104, 37.645570457975204],
              [126.822172665614332, 37.64558972394989],
              [126.822089080414315, 37.645640691473666],
              [126.822040229514243, 37.645670511499667],
              [126.821995871886699, 37.645697590251729],
              [126.821987312863143, 37.645703792672734],
              [126.821824228619761, 37.645843585034363],
              [126.821813704957222, 37.645867012246512],
              [126.821780001013266, 37.645942734649267],
              [126.821707968776906, 37.646104572186466],
              [126.821684118248328, 37.646158135277105],
              [126.821628152553288, 37.646283817328765],
              [126.821428135924151, 37.646433524061045],
              [126.821307466879787, 37.646379551495563],
              [126.821306751306707, 37.646370740502427],
              [126.821259528073284, 37.646350160988995],
              [126.821146137231494, 37.646356401892916],
              [126.821083568366731, 37.646370416203411],
              [126.8210267863887, 37.646357391498405],
              [126.821026721822591, 37.646340451567646],
              [126.821018697688686, 37.646336566628264],
              [126.820589859132454, 37.646276283751575],
              [126.820562770391291, 37.646272476458854],
              [126.820360712168835, 37.646237477591065],
              [126.819994478132145, 37.646149699470726],
              [126.819887485560514, 37.646119353286885],
              [126.819624602913876, 37.646075961735015],
              [126.819584889466398, 37.646070604129285],
              [126.819570490816034, 37.646068662542667],
              [126.819488166102474, 37.646079869790327],
              [126.81934340515177, 37.646093153043907],
              [126.819073625218039, 37.645999735523091],
              [126.818950525154122, 37.646019349865277],
              [126.818895907163181, 37.646037298146723],
              [126.818880285447662, 37.646042432049789],
              [126.818716881172662, 37.646093561812826],
              [126.818504433846499, 37.646117044627189],
              [126.818351794812358, 37.646126329730016],
              [126.818180445819962, 37.64608077245969],
              [126.818104122959966, 37.646085411201646],
              [126.817989484014078, 37.646090809931799],
              [126.817851594516, 37.646082504616508],
              [126.81770831008734, 37.64607635455954],
              [126.817625806446785, 37.646048979031214],
              [126.817448440606753, 37.646000148325967],
              [126.817428992562341, 37.646000758666304],
              [126.817330291030117, 37.646005191711261],
              [126.817254777201669, 37.646007638950728],
              [126.81682411851898, 37.645975217147843],
              [126.816718172017829, 37.645967581305889],
              [126.816602571244715, 37.645959939916438],
              [126.816500542632269, 37.646057039242258],
              [126.816454797567516, 37.646117639961446],
              [126.816377981422505, 37.6462648819168],
              [126.816350826557255, 37.646339794344961],
              [126.816331891400012, 37.646392029223875],
              [126.816230373399691, 37.646550050206471],
              [126.81622746628463, 37.646554577850928],
              [126.81617670759708, 37.646643426750892],
              [126.816163042470393, 37.64667052437278],
              [126.816102836423696, 37.646778185066303],
              [126.816066496156068, 37.646901652773927],
              [126.816329370121849, 37.647223082955954],
              [126.81629367164831, 37.647453136439232],
              [126.816111716527104, 37.647580518225368],
              [126.815987765309075, 37.64766735725226],
              [126.815519855811843, 37.648094133931004],
              [126.815627276907364, 37.648185118858123],
              [126.815691502171376, 37.648239550355356],
              [126.815666854911584, 37.648264380458784],
              [126.815483295385192, 37.648441491694605],
              [126.815494717430951, 37.648586476102338],
              [126.815673130564861, 37.648905782544254],
              [126.81574926480333, 37.648910912881853],
              [126.816471720943014, 37.64899169419548],
              [126.816858341650416, 37.649246330659061],
              [126.816922796471474, 37.649291985686574],
              [126.817088958442653, 37.649408296899232],
              [126.817791149214628, 37.649232398090099],
              [126.817802401186938, 37.649401185347187],
              [126.817828214159093, 37.649743353216351],
              [126.817620697041392, 37.649986789467796],
              [126.817519824832416, 37.65003286853819],
              [126.817395082911105, 37.650090147198043],
              [126.817045497201534, 37.650248236481829],
              [126.817061870836994, 37.650409174023693],
              [126.817023164827248, 37.650463408126392],
              [126.816929048688124, 37.650595292490642],
              [126.816928600825449, 37.650595920826326],
              [126.816607379448229, 37.650629293243988],
              [126.816544052609856, 37.650804073157474],
              [126.816374121925335, 37.650877775170216],
              [126.816327363487261, 37.651227372327845],
              [126.816232521673399, 37.65174526322847],
              [126.816181507037214, 37.651858166972545],
              [126.816049980324223, 37.652148974708545],
              [126.815953868039401, 37.652688353670307],
              [126.815947014821589, 37.652724354384105],
              [126.815567259561718, 37.653032538809114],
              [126.815619829986034, 37.653218941654906],
              [126.81574926773898, 37.653662696426096],
              [126.81575036392519, 37.653951909666674],
              [126.815730066686825, 37.654273246458324],
              [126.815724404374336, 37.654362894705415],
              [126.81573879641951, 37.654408956662074],
              [126.815832229536497, 37.65470588452127],
              [126.815925807855976, 37.655170933958381],
              [126.815993291402179, 37.655224667593338],
              [126.816113892239784, 37.655320699251142],
              [126.81676588318966, 37.655671571958656],
              [126.816799121416977, 37.655693752331935],
              [126.816975523962483, 37.655811462318049],
              [126.816986496333456, 37.655958427633948],
              [126.816845668216033, 37.656127319255148],
              [126.816892511057432, 37.656248483635771],
              [126.816855880991923, 37.656258424943779],
              [126.816830453896529, 37.656321095621763],
              [126.816640327745262, 37.656398371866253],
              [126.816414353981486, 37.657016218687417],
              [126.816361417198962, 37.656908101565769],
              [126.816300509503975, 37.656911375489209],
              [126.816147609954797, 37.656966400231333],
              [126.816105227957578, 37.65702814863392],
              [126.81613055246396, 37.657093681264193],
              [126.816058115049472, 37.657106073631688],
              [126.816019116345558, 37.657112408256296],
              [126.815927371892712, 37.657093163805307],
              [126.815856257244761, 37.657070617501645],
              [126.815843574440265, 37.657066542955015],
              [126.815716478792723, 37.657039151712695],
              [126.815628625361668, 37.657054492756252],
              [126.81545687145892, 37.657039383178407],
              [126.815456702668982, 37.657013314945075],
              [126.815456692814223, 37.657011424806925],
              [126.815456567353849, 37.656975204287001],
              [126.815380378436089, 37.656929453351033],
              [126.815379124684156, 37.656928698874943],
              [126.815366609549514, 37.656921185789621],
              [126.815206825139313, 37.65696427004103],
              [126.815213494809299, 37.656976604652385],
              [126.815213976348815, 37.656977499760785],
              [126.815236492079663, 37.657019185211119],
              [126.815193179844286, 37.657072273348561],
              [126.815171789438267, 37.657106297707259],
              [126.815064943595559, 37.657275935141676],
              [126.815027891723872, 37.657322575426562],
              [126.814849633324627, 37.657365808786011],
              [126.814595312721707, 37.657427429533925],
              [126.814573502911557, 37.657399746561936],
              [126.814368111838547, 37.657138992620943],
              [126.814362687743909, 37.657132136251981],
              [126.814322066615162, 37.657198744276627],
              [126.814051838649988, 37.657121290511597],
              [126.814008274024701, 37.657108806171379],
              [126.813855485995418, 37.65706500809172],
              [126.813813479586358, 37.657052968985461],
              [126.813665412732632, 37.656944616307953],
              [126.813648791742679, 37.656934120097596],
              [126.813489354087821, 37.65683342630193],
              [126.813296980187602, 37.656763545270479],
              [126.813255468865336, 37.656741971015364],
              [126.813175931603354, 37.656700634591736],
              [126.813108822627498, 37.656665758185376],
              [126.813078993090826, 37.65666694548127],
              [126.813055899106004, 37.656667861037661],
              [126.812763688656659, 37.6566794444973],
              [126.812754644959995, 37.65667860134446],
              [126.812402175409915, 37.656645619163335],
              [126.812254515602575, 37.656625939550104],
              [126.812200718308119, 37.656724850158255],
              [126.811997981345598, 37.657139056556822],
              [126.811983691156641, 37.657164711471111],
              [126.811882268604521, 37.657373149961693],
              [126.811867168021323, 37.657405037308415],
              [126.811808974409232, 37.657526404747003],
              [126.81139559291104, 37.657587039999157],
              [126.811257157801165, 37.657611773540438],
              [126.81112261760633, 37.657928086940856],
              [126.811046250887131, 37.658107626675971],
              [126.811012899991027, 37.658186037399318],
              [126.810887050453189, 37.658207559579701],
              [126.810757076988622, 37.658202033979869],
              [126.810737673127562, 37.658168575150022],
              [126.810692014884125, 37.658207424078554],
              [126.810697193213542, 37.658354381250554],
              [126.810693654242996, 37.658408523035085],
              [126.810711980060105, 37.65846441227233],
              [126.810765793004364, 37.658517208213226],
              [126.810770203260006, 37.65852063648741],
              [126.81077030155349, 37.658534530997493],
              [126.810770314837526, 37.658535792029774],
              [126.810770954801612, 37.658624520468074],
              [126.810783802239186, 37.658697611028693],
              [126.810805541264841, 37.658749272244684],
              [126.810829681970532, 37.658781757363649],
              [126.810836376136649, 37.658790767369261],
              [126.810909119541336, 37.658885124008471],
              [126.811002022190593, 37.659028170796198],
              [126.811094390185744, 37.659158690466583],
              [126.811113586882641, 37.659231790127279],
              [126.811075986377162, 37.659354847532448],
              [126.811109254442627, 37.659361766622034],
              [126.811133389467827, 37.659402140946554],
              [126.811207523580535, 37.659463022173725],
              [126.811438362278423, 37.659619704369142],
              [126.811568912500775, 37.659680171992129],
              [126.811569779122721, 37.659680369933724],
              [126.812010140881384, 37.659773383650027],
              [126.812133181281453, 37.659753066101899],
              [126.812448900677012, 37.659700928244987],
              [126.812698199338655, 37.659761780287617],
              [126.812876379645232, 37.659805270814736],
              [126.812977632890622, 37.659839301586274],
              [126.812994558165045, 37.659842673780261],
              [126.813169125149088, 37.659877473870992],
              [126.813233396793493, 37.659893909719258],
              [126.813254580166628, 37.659906420872453],
              [126.813457706673177, 37.660026405250029],
              [126.814072874870462, 37.660388170966755],
              [126.814083615399966, 37.660394530085128],
              [126.814158544033091, 37.660431036829422],
              [126.81420397291204, 37.660453185150942],
              [126.814260479720346, 37.660480762548161],
              [126.814321451504227, 37.660510538068429],
              [126.814384283364703, 37.660960360098841],
              [126.814434480183806, 37.66141867302273],
              [126.814500172656807, 37.661563731975349],
              [126.81453917307752, 37.661649829700856],
              [126.814552255246895, 37.661665047991697],
              [126.814625432664485, 37.66175012930568],
              [126.814631217727836, 37.661756854359972],
              [126.81470948495344, 37.661847845442544],
              [126.814721059484455, 37.662004624330166],
              [126.814725219814065, 37.662060934467192],
              [126.814742225673712, 37.662291049691405],
              [126.814744360898658, 37.662322343492988],
              [126.814744402078887, 37.662322920580451],
              [126.814760288937478, 37.662386166938298],
              [126.814770517871438, 37.662426879739463],
              [126.814811271706077, 37.662589100867471],
              [126.814847467939927, 37.662635486022197],
              [126.814908309342925, 37.662724814645131],
              [126.814930695113915, 37.662756132321881],
              [126.814946977962848, 37.662778907841371],
              [126.815039499324726, 37.662908298620316],
              [126.815437582716797, 37.663183020808198],
              [126.815524932387405, 37.663250867544072],
              [126.815771327386784, 37.663208296231176],
              [126.815814822181537, 37.663213120055801],
              [126.816296040191204, 37.663266500902786],
              [126.816340739058148, 37.663271458599951],
              [126.816551306230721, 37.663367435209786],
              [126.817251741240483, 37.663726667921928],
              [126.817292799921034, 37.664039973680921],
              [126.817300352941913, 37.664097585037659],
              [126.817342386095874, 37.664103236270414],
              [126.817412328326625, 37.6642808361728],
              [126.817972463895799, 37.664225758855387],
              [126.818012540370077, 37.664223213601375],
              [126.818048639361734, 37.664220922044045],
              [126.818143910830599, 37.664252989473987],
              [126.818157011412538, 37.664257400580432],
              [126.8184523822719, 37.664146014802547],
              [126.818469361863492, 37.664139612556369],
              [126.818740673265751, 37.664037298573966],
              [126.818823322535593, 37.664006038446679],
              [126.819406349377815, 37.663785514844029],
              [126.819814193539855, 37.663765339737978],
              [126.820545633030122, 37.663729154451282],
              [126.821680968632123, 37.663447737226875],
              [126.821735243813322, 37.663434284300124],
              [126.822373947248863, 37.663316326893714],
              [126.822654155765406, 37.663191741758688],
              [126.822727093036633, 37.663159310872864],
              [126.822738609428512, 37.663173494461596],
              [126.822761198168607, 37.663201319192083],
              [126.822772392689089, 37.663235457178835],
              [126.822782237778199, 37.663265500806538],
              [126.822804878990368, 37.663304277836524],
              [126.822810001016933, 37.663314690514056],
              [126.822829816883981, 37.663350714893575],
              [126.822846393813236, 37.663384707113757],
              [126.822858576284148, 37.663409321949665],
              [126.822842488981365, 37.663448859075032],
              [126.82280563073887, 37.663539396525827],
              [126.822607944507055, 37.663654718043389],
              [126.822380720665748, 37.663787265118494],
              [126.822060044656666, 37.66397432580964],
              [126.821966813853194, 37.664089584991487],
              [126.821961147956031, 37.664096589969887],
              [126.821941231973724, 37.66412120244194],
              [126.821945975212159, 37.664132615251603],
              [126.822030859653097, 37.664336896465812],
              [126.821938288818615, 37.664613246075966],
              [126.821855668126716, 37.664720318120168],
              [126.821883902461948, 37.664954631237634],
              [126.821889834276988, 37.665028700197823],
              [126.821920275198721, 37.665273738231662],
              [126.821811932893723, 37.665461912315557],
              [126.821732214734084, 37.665568619682169],
              [126.821428166888566, 37.665771172420861],
              [126.821257412552683, 37.665878037081853],
              [126.821049873746432, 37.666015504255263],
              [126.820867857041875, 37.666321332302957],
              [126.820738139185565, 37.666540789840631],
              [126.82060803746127, 37.666684728404647],
              [126.820564852708429, 37.666734319311317],
              [126.820548036203832, 37.666753631569982],
              [126.820532824310334, 37.666768069691479],
              [126.820493893346281, 37.666805012447703],
              [126.820487328694597, 37.666833824235958],
              [126.820431054164814, 37.667080680257357],
              [126.82041026319402, 37.667171885405196],
              [126.820383647830994, 37.667292898951793],
              [126.820382852925974, 37.667296512289425],
              [126.820373051358217, 37.667341076799261],
              [126.82036522279077, 37.667376679086772],
              [126.820636997580976, 37.667471834763575],
              [126.820737799728633, 37.667507127154053],
              [126.820945985098263, 37.667685999578481],
              [126.820954909103847, 37.667709718844549],
              [126.820967528373544, 37.667743272107145],
              [126.821066846564619, 37.668007237995312],
              [126.821015555899478, 37.668293975012439],
              [126.820987173133375, 37.6684345534701],
              [126.820973196393325, 37.668509529917756],
              [126.820928171363434, 37.668624856571306],
              [126.820898869323443, 37.668695809587405],
              [126.820868747510062, 37.668773597126268],
              [126.820804489191772, 37.668952621353931],
              [126.820800914254932, 37.668962924539443],
              [126.820798701529341, 37.668969325623152],
              [126.820796454032802, 37.668975809428431],
              [126.820808928359682, 37.668991315545533],
              [126.820792394156143, 37.669092570677371],
              [126.820738941254262, 37.66942026927692],
              [126.820732786352451, 37.669480741548355],
              [126.820732588775428, 37.669497193188406],
              [126.82079009326236, 37.669570216177569],
              [126.820845903077796, 37.669641080622284],
              [126.820877791204055, 37.66968157507528],
              [126.820924576131105, 37.669781470554035],
              [126.820968451516279, 37.669875151755036],
              [126.82086233949218, 37.670304573504033],
              [126.820691256909029, 37.670555050553268],
              [126.820682976224234, 37.670604402644976],
              [126.820663854902875, 37.670964140841058],
              [126.820957660913308, 37.67156869070751],
              [126.820997920295483, 37.671598264199112],
              [126.821651257324319, 37.672078169844255],
              [126.821694542839694, 37.672090152233423],
              [126.822309127538404, 37.672260293198342],
              [126.822326150033362, 37.67237926485106],
              [126.822960327405582, 37.672705910969576],
              [126.823065409657431, 37.672729553793815],
              [126.82312545862348, 37.672743064978469],
              [126.823129765625168, 37.672744033383012],
              [126.823130455433628, 37.67274361822615],
              [126.82332409921149, 37.672626760484903],
              [126.824004650960575, 37.672708341038771],
              [126.824320425584787, 37.672945159986966],
              [126.824609978899417, 37.67316090820043],
              [126.825290664324143, 37.67354838973089],
              [126.825737931963545, 37.673512125763153],
              [126.825824634322245, 37.673598108397243],
              [126.826015135211634, 37.673756287175458],
              [126.826314631917143, 37.673829107376584],
              [126.826656566331224, 37.673878123586093],
              [126.827152726020131, 37.674070169297082],
              [126.827162802399471, 37.674074067324611],
              [126.827143273297992, 37.674198628867366],
              [126.8271174424668, 37.674363393163851],
              [126.827396448175762, 37.674760339641246],
              [126.827436568980488, 37.674817422160466],
              [126.827243553219645, 37.675291930147381],
              [126.826959965120196, 37.675658493943203],
              [126.827077118622853, 37.67597328643572],
              [126.827421803774925, 37.676275981120071],
              [126.827875600858405, 37.676671723821507],
              [126.828032910110153, 37.676808905786693],
              [126.828216529662058, 37.676815969087123],
              [126.828573963096389, 37.676806817300211],
              [126.828768504393437, 37.676846474300568],
              [126.829348352683226, 37.676980755229756],
              [126.829511705991976, 37.676830532025988],
              [126.829616695342622, 37.676790895565055],
              [126.829688809204796, 37.676763667261731],
              [126.829754212047618, 37.676765800156502],
              [126.830383292028543, 37.676786300455205],
              [126.831101161458676, 37.676843471145069],
              [126.831210122238517, 37.676837585130492],
              [126.831275806391417, 37.676834036830989],
              [126.831640576007729, 37.676814335802007],
              [126.831897537111786, 37.677021299008473],
              [126.832228616682286, 37.677237106920394],
              [126.832539577345671, 37.677381896496236],
              [126.832873158382753, 37.677539855368082],
              [126.833069427371257, 37.677649468606575],
              [126.833328621299884, 37.67779421932719],
              [126.833553738656832, 37.677799407012344],
              [126.833944760916793, 37.677474219097448],
              [126.833964160249934, 37.677462458218834],
              [126.834321386211968, 37.67745218462894],
              [126.834449556208696, 37.677452075177271],
              [126.834769702063582, 37.677306908827575],
              [126.83487100556124, 37.677260974579255],
              [126.835083780249732, 37.677052023464825],
              [126.835100197909156, 37.677015872882855],
              [126.83512583138257, 37.676962471534971],
              [126.835137702818159, 37.676834819034212]
            ]
          ]
        ]
      },
      id: '31101510'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '원신동',
        ADM_CD: '31101520'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.853839053960314, 37.687963361070217],
              [126.853631214483173, 37.687626332210257],
              [126.855043666074849, 37.686002891792874],
              [126.856881961128138, 37.68551711847774],
              [126.857287261797012, 37.685438771361596],
              [126.857484292414696, 37.685400684302962],
              [126.857681425559932, 37.68532714480051],
              [126.857914051354626, 37.68524135003463],
              [126.858417352903658, 37.685054335371383],
              [126.858599975119219, 37.68498620894502],
              [126.858776876610506, 37.684820049272219],
              [126.858917354107959, 37.68478217235635],
              [126.859076034509258, 37.684741630744071],
              [126.859500553502315, 37.684630026035478],
              [126.859946831105106, 37.684520473931293],
              [126.860375474365739, 37.684412465798616],
              [126.860981962585825, 37.684321155231039],
              [126.861226371368573, 37.684341338649105],
              [126.861547125315866, 37.684423412425708],
              [126.861792692743194, 37.684484338993528],
              [126.861820424888052, 37.684494873226143],
              [126.861948590676263, 37.684543553229567],
              [126.86213355050667, 37.6846138061426],
              [126.862369794220029, 37.684721366966414],
              [126.862620485616887, 37.684835504853233],
              [126.862662899871353, 37.684863341643577],
              [126.862792344477938, 37.684948275293934],
              [126.863150341621733, 37.685143151976987],
              [126.863334600107919, 37.685185901625019],
              [126.863582862731022, 37.685243522149435],
              [126.864000125611469, 37.68528792274838],
              [126.864094602431109, 37.685306698468246],
              [126.864361756387694, 37.685359790020669],
              [126.864552134497856, 37.68537346339204],
              [126.864752911084608, 37.685355152199307],
              [126.86494179456443, 37.685351255490588],
              [126.865442290126722, 37.685349726049616],
              [126.865584263464697, 37.685354325940104],
              [126.865670012565559, 37.685357105416387],
              [126.865717615366265, 37.685358892809191],
              [126.865900155891623, 37.685365748398631],
              [126.866056202413176, 37.685372664083616],
              [126.866157123294172, 37.685377136330949],
              [126.866082829503526, 37.685148294563469],
              [126.866337410239836, 37.685149118369495],
              [126.867232010212831, 37.685152013799893],
              [126.86724475302232, 37.685152056034653],
              [126.867818948609226, 37.685155537517204],
              [126.869074048027514, 37.685161781355475],
              [126.869678756852821, 37.685163900998326],
              [126.869903366609407, 37.68516517341488],
              [126.86990319866932, 37.685185025089112],
              [126.869902423577898, 37.685276645981482],
              [126.870098409905694, 37.685277692982105],
              [126.870104872981855, 37.685195263530474],
              [126.87010714100181, 37.685166326906895],
              [126.87041213236644, 37.68516805290654],
              [126.870663309712512, 37.685172367974161],
              [126.870939220262642, 37.685407663461099],
              [126.871456836667832, 37.685541400286013],
              [126.871489734277617, 37.68556336604086],
              [126.871976597625334, 37.685876186673816],
              [126.872436097958811, 37.686181735615243],
              [126.872556011675385, 37.686261473012699],
              [126.873065879949948, 37.686674573912569],
              [126.873270579990233, 37.686880261697404],
              [126.873347777465696, 37.686957832513279],
              [126.873421700081138, 37.687032114070817],
              [126.873678998055297, 37.687396402709979],
              [126.873680174906028, 37.687398071002242],
              [126.873680818593428, 37.687402179051539],
              [126.874316956571306, 37.68721977496844],
              [126.874371111237764, 37.687204611783208],
              [126.874384885227556, 37.687200755082742],
              [126.874483092056948, 37.687172500361143],
              [126.875269702042374, 37.686950154712463],
              [126.875390582212532, 37.686886764941391],
              [126.876078281490294, 37.686539788374986],
              [126.876463845856307, 37.686478805249948],
              [126.876834894378533, 37.68641893931229],
              [126.877071370806561, 37.686381772434792],
              [126.878072564201631, 37.686023941943667],
              [126.878652322538429, 37.685746264245623],
              [126.879065710146378, 37.685549544470064],
              [126.879283763156977, 37.685503524265563],
              [126.879295080692586, 37.685501220561285],
              [126.879646759572935, 37.685428064616893],
              [126.879659746178305, 37.685424170707286],
              [126.880429278640946, 37.685193357954454],
              [126.880685390022364, 37.685116138340859],
              [126.881063191307007, 37.685012731673893],
              [126.881749607676127, 37.684822156208817],
              [126.882832756775713, 37.6845917005651],
              [126.883188147059897, 37.684567946057641],
              [126.88370646781317, 37.684533294184966],
              [126.884058413590509, 37.684377146835828],
              [126.884353228948569, 37.684246348304001],
              [126.884378247154572, 37.684228580391995],
              [126.884447192592077, 37.68417961796581],
              [126.885020116789434, 37.683772736587315],
              [126.88527687654269, 37.683565768155155],
              [126.88540558497435, 37.683396421086847],
              [126.8855209533194, 37.683130028059999],
              [126.885622254485398, 37.682796678766266],
              [126.885647161407562, 37.682599660696674],
              [126.885701497757509, 37.682395933221713],
              [126.885701669814097, 37.682395285163572],
              [126.885744430569943, 37.682234968295312],
              [126.885760963037356, 37.682172983718765],
              [126.885797683710578, 37.682032018371629],
              [126.885850489115853, 37.681828179179895],
              [126.885840864746058, 37.681747083662074],
              [126.885801923707106, 37.681639650151176],
              [126.885661603619297, 37.681476254331379],
              [126.885331646670281, 37.681231496190584],
              [126.885156512690841, 37.68107780044479],
              [126.884902285875754, 37.680897439030595],
              [126.884876478122365, 37.680879129190934],
              [126.884368568789881, 37.680547157933589],
              [126.884024764797246, 37.680315629214334],
              [126.883931672748503, 37.680035341686541],
              [126.883909917979693, 37.679973644955609],
              [126.883865539924997, 37.679847775507298],
              [126.88381397181864, 37.679697261376759],
              [126.883729568706116, 37.6794504916029],
              [126.883685553469576, 37.679319221426432],
              [126.883606777948927, 37.679083736719242],
              [126.88352896331979, 37.678851145724394],
              [126.883453169844458, 37.678686543243451],
              [126.883441862469397, 37.678661313700431],
              [126.883385661131967, 37.67853834760033],
              [126.883354516349868, 37.678459790126809],
              [126.883166362520768, 37.677985218837712],
              [126.883140967687822, 37.677933372430395],
              [126.883058840580176, 37.677765708093553],
              [126.882830518167054, 37.677351453751179],
              [126.882556684558139, 37.676837319075482],
              [126.882555992309449, 37.676836011232275],
              [126.88260835168866, 37.676677292291664],
              [126.882644171039132, 37.676568700532172],
              [126.882644426207349, 37.676565550831207],
              [126.882650507641813, 37.676490582403666],
              [126.882665596200042, 37.676304514068526],
              [126.882685532053515, 37.676086886819384],
              [126.882694111607947, 37.675994671960403],
              [126.882687262391755, 37.6757633476495],
              [126.882684549339345, 37.675644336731388],
              [126.882681704834084, 37.675495204595983],
              [126.882686806070978, 37.675123946984236],
              [126.882632848881485, 37.674741548074678],
              [126.882626300879352, 37.674654417711942],
              [126.882622284489329, 37.674510560288937],
              [126.882623752409941, 37.674271811062582],
              [126.882626198684434, 37.673874058502292],
              [126.882627347126117, 37.673829200076874],
              [126.882638451474108, 37.673574975912331],
              [126.882650063777817, 37.67325874671792],
              [126.882674733387248, 37.673000283384866],
              [126.882757329418908, 37.672597541360332],
              [126.882759031063927, 37.672589514035302],
              [126.882866966234658, 37.672080944513148],
              [126.882961798342436, 37.671820389346678],
              [126.883185493168639, 37.671429139660844],
              [126.883325522145313, 37.671259088435804],
              [126.883477068118481, 37.670964816844958],
              [126.883529249705049, 37.670909075721724],
              [126.883473692068591, 37.670886052045759],
              [126.883571789802616, 37.670637393787679],
              [126.883591363839926, 37.670587588699135],
              [126.883715138932047, 37.670587981341299],
              [126.883756002072388, 37.670578634475],
              [126.883978652608519, 37.670527699968602],
              [126.883998044864953, 37.670520151687995],
              [126.88405668195756, 37.670497235227316],
              [126.884086619031223, 37.67048879650762],
              [126.884244043910996, 37.670425253803259],
              [126.884546871866817, 37.670303021768646],
              [126.88500632693443, 37.670118831902478],
              [126.885220858118004, 37.670038144952237],
              [126.88527537988081, 37.670016832424764],
              [126.885310750531289, 37.670003005635657],
              [126.885468750975079, 37.669933767894349],
              [126.885730395477637, 37.669818619864067],
              [126.885829395638808, 37.66979494920399],
              [126.885993808349454, 37.669755459107037],
              [126.886197153090976, 37.669705688955318],
              [126.886452177085033, 37.669643291102815],
              [126.88661951203359, 37.6697243823047],
              [126.886736038012472, 37.669720892613938],
              [126.886781869294254, 37.669620568897372],
              [126.886880536549072, 37.669583814654935],
              [126.886945140948043, 37.669564335687909],
              [126.887168042297077, 37.669455795297267],
              [126.887275128599398, 37.669390875617736],
              [126.88729547807786, 37.669409843412815],
              [126.887321679636401, 37.669434050375557],
              [126.887433899186945, 37.66953270760807],
              [126.887559698005205, 37.669565075906398],
              [126.887791431186514, 37.669624699415728],
              [126.88798140242146, 37.66967404876236],
              [126.88836872368968, 37.66975868894211],
              [126.888703810626353, 37.669843970183827],
              [126.889312531317032, 37.669998889075224],
              [126.889755386589243, 37.670114012115782],
              [126.889756744396607, 37.670114364349487],
              [126.890077115131234, 37.670217115302137],
              [126.890169228191496, 37.670246657617206],
              [126.890212519146488, 37.670259626215632],
              [126.890471222993952, 37.670333433799669],
              [126.890578419821836, 37.670354222538933],
              [126.890887681337219, 37.670416761551117],
              [126.891289306304927, 37.6704696013893],
              [126.891356025433964, 37.67048867619522],
              [126.891715673743676, 37.670591501904283],
              [126.891858031672029, 37.670632593855224],
              [126.891963878759924, 37.670662146097399],
              [126.892010200671706, 37.670548986163766],
              [126.892029721141014, 37.670501301102803],
              [126.892207933792704, 37.670015572079024],
              [126.892259332578789, 37.669894761586399],
              [126.892280044662442, 37.669846076735929],
              [126.892281299140308, 37.669841719872259],
              [126.89228330632956, 37.669834745825995],
              [126.892292234672595, 37.669803741089559],
              [126.892311309038561, 37.669805571719614],
              [126.892313086738184, 37.669805743117834],
              [126.892342341910634, 37.6697189166211],
              [126.892365018954365, 37.669646310724602],
              [126.892399158529798, 37.66954408585395],
              [126.892400979326311, 37.669460670138712],
              [126.892392986068145, 37.669422890367237],
              [126.892404799517792, 37.669330204595745],
              [126.892384245614934, 37.669211402853342],
              [126.892384091905384, 37.669210507042983],
              [126.892374226948064, 37.669153495008175],
              [126.89234398430888, 37.668985706647973],
              [126.892310615791033, 37.668938466488306],
              [126.89230784830022, 37.668935643691889],
              [126.892302930839008, 37.668930609676799],
              [126.892220221212028, 37.6687772696256],
              [126.892196725194452, 37.668754553159673],
              [126.89219403191801, 37.668751947061644],
              [126.892132823818045, 37.668677109837617],
              [126.892119864812642, 37.668655815876562],
              [126.892097430975227, 37.668618962129138],
              [126.892095183475035, 37.668605355816879],
              [126.892080982640636, 37.668554622588623],
              [126.89205073557882, 37.668462962074841],
              [126.892013591035081, 37.668364091427357],
              [126.891991332932193, 37.668316137809569],
              [126.891970614245238, 37.668224039672928],
              [126.892042219376449, 37.668011026599785],
              [126.892117607720962, 37.667846126933298],
              [126.892139870321927, 37.667814974600596],
              [126.892169284853935, 37.667773738108167],
              [126.892225856087251, 37.667694486529371],
              [126.892272185818868, 37.667629588078952],
              [126.892557982708865, 37.667280828550957],
              [126.89267492701407, 37.667138118692854],
              [126.892835534321208, 37.666903742178427],
              [126.8929606853238, 37.666966115615921],
              [126.892967154500781, 37.666960625571306],
              [126.893001423189318, 37.666932365930819],
              [126.892916773173397, 37.666844444648369],
              [126.892895047236195, 37.66682189966528],
              [126.892988236460752, 37.6667260318141],
              [126.893001732613087, 37.666721179116642],
              [126.893052644537988, 37.666705099131484],
              [126.893167062858424, 37.666665740989842],
              [126.893138239376498, 37.666612646869915],
              [126.893111252249497, 37.666621723194389],
              [126.893100819448293, 37.666625227055938],
              [126.893091771275962, 37.666612694398715],
              [126.893378465468032, 37.666338432195815],
              [126.893564035288293, 37.666161019887966],
              [126.893662697886569, 37.666043444095543],
              [126.893738229957634, 37.66593350435749],
              [126.893831405819668, 37.665847094289319],
              [126.893866248604255, 37.665814781361988],
              [126.893859017829868, 37.665807701223393],
              [126.893851421482708, 37.665800261378287],
              [126.893822107124919, 37.665771674717547],
              [126.893901090494637, 37.665607725177772],
              [126.893907138511423, 37.665595162895393],
              [126.893943945777053, 37.665491214342111],
              [126.893981796266687, 37.665384312975199],
              [126.894030045832054, 37.665248307944744],
              [126.894067542554581, 37.665184195297734],
              [126.894126100680765, 37.665131179822623],
              [126.894172841058065, 37.66510058906119],
              [126.894268205702829, 37.665068061376829],
              [126.894384566167773, 37.665028188334013],
              [126.894513732517211, 37.66498392564705],
              [126.894537401763571, 37.664976046956077],
              [126.894627556055298, 37.664937386633895],
              [126.894685060685717, 37.664912660877398],
              [126.894750844949897, 37.66487866505215],
              [126.894771271682146, 37.664861114643685],
              [126.894865163725868, 37.664794689863847],
              [126.894839175754029, 37.664771582568001],
              [126.894842489285111, 37.664769288561935],
              [126.895011109085843, 37.664652481507154],
              [126.89522196833812, 37.664506411937417],
              [126.895238702421807, 37.66449481742012],
              [126.895257320095908, 37.664481926532481],
              [126.895283527204214, 37.664462653486673],
              [126.895309259803625, 37.664443728619091],
              [126.895317589315212, 37.664436825682458],
              [126.895358257314626, 37.664403138796459],
              [126.895404140244437, 37.664360302199825],
              [126.895422833684052, 37.664340578701243],
              [126.895446728193193, 37.664315373838015],
              [126.895473738295323, 37.664283055427973],
              [126.895485884877104, 37.664268521564516],
              [126.89550367892825, 37.664244211989455],
              [126.895521473055396, 37.664219891596019],
              [126.89553742094472, 37.664194777582424],
              [126.895553357397773, 37.664169674323865],
              [126.895565574046628, 37.664147194696966],
              [126.895581426640192, 37.664118028716103],
              [126.895605596408529, 37.664065153536725],
              [126.895622474051919, 37.664024507170261],
              [126.895678722919016, 37.663888970539453],
              [126.895732616841926, 37.663759144350067],
              [126.895740276814351, 37.663740691029346],
              [126.895742273909832, 37.663735880052684],
              [126.895744421382872, 37.66373070120288],
              [126.895682490931947, 37.663709779547268],
              [126.895621432516947, 37.663685489555142],
              [126.895613632101984, 37.663682386870825],
              [126.895551077228987, 37.663653746572372],
              [126.895512470627523, 37.66363351218633],
              [126.895488799772934, 37.663621103058794],
              [126.895456954753371, 37.663603586563688],
              [126.895378112027799, 37.663560226914946],
              [126.89529114971387, 37.663511474397801],
              [126.89526714490394, 37.663498032393527],
              [126.894447508923633, 37.663039077203969],
              [126.894352961597733, 37.662986133533813],
              [126.893654880510269, 37.662595231635997],
              [126.893454503731959, 37.662483056751526],
              [126.893454092911014, 37.662482825701439],
              [126.893427262582833, 37.662467805888845],
              [126.893538625087658, 37.662342222216218],
              [126.89377867751692, 37.662069059237076],
              [126.893874102467905, 37.661960470803194],
              [126.893919800180711, 37.661678166020927],
              [126.893906495183884, 37.661287580623885],
              [126.893904795361252, 37.661237694141192],
              [126.893904264441787, 37.661232712471296],
              [126.893904281745023, 37.661232397095013],
              [126.89390452320805, 37.661229890838243],
              [126.893904424317299, 37.661226993458456],
              [126.893897597199654, 37.661026549656064],
              [126.893893961148123, 37.660907746610341],
              [126.893893741523669, 37.66090171018196],
              [126.893893346973599, 37.660901709957251],
              [126.89383583125506, 37.660901657759709],
              [126.893821592173069, 37.660873082755181],
              [126.893808894786957, 37.660568330391996],
              [126.893799245629609, 37.660336704095862],
              [126.893798903979942, 37.660169020853857],
              [126.893798843897699, 37.660138067096334],
              [126.893802802019195, 37.660017997933906],
              [126.893808467011112, 37.659963249662546],
              [126.893820215632942, 37.659847440555779],
              [126.893832884840876, 37.659738740453882],
              [126.893819943209323, 37.659566660655173],
              [126.893813793707665, 37.659469620778786],
              [126.893802034426955, 37.659285856804892],
              [126.893797064518751, 37.659179976437443],
              [126.893792489714684, 37.659082533640301],
              [126.893702675647873, 37.659044214393212],
              [126.893648540731277, 37.659042110034314],
              [126.893597784712654, 37.659032018314754],
              [126.893585271006202, 37.659001707170525],
              [126.89356578309976, 37.658997808779738],
              [126.893241427363506, 37.658932944962501],
              [126.892660713583936, 37.658587912708157],
              [126.892620242430979, 37.658563865962392],
              [126.892459248237842, 37.658602330325671],
              [126.89214184973001, 37.658678161690695],
              [126.892096961860261, 37.658610431609809],
              [126.891963243966828, 37.658408668434525],
              [126.891829311246994, 37.65819719307224],
              [126.89174718524032, 37.658067520773798],
              [126.891724536710967, 37.658032929033013],
              [126.891700419061593, 37.657996093513162],
              [126.891693013222067, 37.657991689281644],
              [126.891585175322106, 37.657927529898288],
              [126.891344519668223, 37.657784347910869],
              [126.891132970292745, 37.657685529491559],
              [126.891122109869855, 37.657680455731317],
              [126.891024249194885, 37.65762828814843],
              [126.890786810026867, 37.65751877817388],
              [126.890539166895877, 37.657404562338122],
              [126.889915407720252, 37.657129509526918],
              [126.889952211623353, 37.656989956837727],
              [126.889953081083618, 37.656846837410079],
              [126.889502686076924, 37.656712264176996],
              [126.889313986788025, 37.656655882685364],
              [126.889183754907734, 37.656616970385414],
              [126.889073013699559, 37.656583881429718],
              [126.888931769983472, 37.656626773275406],
              [126.88863471850263, 37.656716978608664],
              [126.88794894823701, 37.656666235065416],
              [126.887794750337093, 37.656654824432586],
              [126.887286307523382, 37.656621988192924],
              [126.887188447279257, 37.656609162270051],
              [126.886912105356586, 37.656580533209521],
              [126.886874438401932, 37.656576630263231],
              [126.886351498169276, 37.656561083260428],
              [126.885932288991981, 37.656850525938466],
              [126.885806155331977, 37.657303943658313],
              [126.885792973569465, 37.657349503016341],
              [126.885849345421107, 37.657661222026469],
              [126.885856722865469, 37.657704261053937],
              [126.885901020228985, 37.657962675639162],
              [126.885230960837447, 37.658171358239066],
              [126.885041053732849, 37.658152223675103],
              [126.884962232293645, 37.658146059538034],
              [126.884765574742062, 37.658130679422612],
              [126.884421602045023, 37.65805912558443],
              [126.884323049631277, 37.65803934081697],
              [126.884012038015072, 37.657976909821969],
              [126.883648985774059, 37.657952581988816],
              [126.883598548254497, 37.65794920073543],
              [126.883480188316739, 37.657979804214428],
              [126.883061495625512, 37.658089843218249],
              [126.882898988324342, 37.65822915254067],
              [126.882802101207517, 37.658295275479773],
              [126.882644479300467, 37.658402827341533],
              [126.882582405739399, 37.658449587877641],
              [126.882462278757515, 37.658540078897758],
              [126.882424162885613, 37.658568791274845],
              [126.88244508860231, 37.658806757942138],
              [126.882512723518232, 37.659144227968973],
              [126.882527844098263, 37.659219669034364],
              [126.882486327850387, 37.659454373157082],
              [126.882481487658225, 37.659495188831123],
              [126.882475095920356, 37.65954907646622],
              [126.882464776599434, 37.659635981746824],
              [126.882447039788588, 37.659785490543847],
              [126.88226630856667, 37.659937887261393],
              [126.882000914702473, 37.660161644510453],
              [126.881558582834231, 37.660493563203232],
              [126.881199256405665, 37.660391750193895],
              [126.880743413879529, 37.660331579161941],
              [126.880677136629174, 37.660322830809598],
              [126.880644899956039, 37.660337352302527],
              [126.880638924217919, 37.660340043839014],
              [126.880363127013069, 37.660464276620047],
              [126.880229318131384, 37.660524550307656],
              [126.88005971971171, 37.660511040465295],
              [126.879953064732092, 37.660502542767496],
              [126.879747823194208, 37.660484943257266],
              [126.879444727544865, 37.66032273502767],
              [126.87938837226713, 37.660292572108311],
              [126.878992286773297, 37.660259465546368],
              [126.878943781711854, 37.660255451628196],
              [126.87890378852272, 37.660252165716891],
              [126.878814207500469, 37.660244871836127],
              [126.878639359940806, 37.660230634682065],
              [126.878476313670376, 37.660217358076849],
              [126.878014949551243, 37.660322577256451],
              [126.877559099789892, 37.660426178799241],
              [126.877416636403296, 37.660458556173836],
              [126.877310886021107, 37.660541064393406],
              [126.877300639382156, 37.660513880313601],
              [126.876868365142712, 37.659367169828357],
              [126.876782523299866, 37.659126067879448],
              [126.876593483549811, 37.658629111933166],
              [126.876556713135102, 37.658532447479139],
              [126.876538418334746, 37.658237547777674],
              [126.876525563773825, 37.658030302199542],
              [126.876506526371131, 37.657723364902523],
              [126.875792364022772, 37.657175759383748],
              [126.875755690598297, 37.657149232085828],
              [126.875714602521015, 37.657119726856237],
              [126.875717189173315, 37.65706575995678],
              [126.875727567363086, 37.656709901710471],
              [126.875731021117218, 37.656591501583542],
              [126.875510611744659, 37.656613482848066],
              [126.874859511922111, 37.65667841517692],
              [126.873768190319055, 37.656343337713317],
              [126.872999282615098, 37.65660432498693],
              [126.872597034294131, 37.656636411446975],
              [126.872522417232005, 37.656705123036545],
              [126.872453560845457, 37.656768527555478],
              [126.871549340375424, 37.656895562676951],
              [126.871368337948269, 37.656626945924039],
              [126.870913179897812, 37.656709700378656],
              [126.87083060470799, 37.656726304095052],
              [126.870809654580739, 37.656730515122149],
              [126.869815996212907, 37.656906478804117],
              [126.870492541266302, 37.656968733493471],
              [126.870697474534978, 37.656987592167042],
              [126.870170678943296, 37.657093428529933],
              [126.869130226167613, 37.657302455049269],
              [126.868608964194323, 37.657470026173414],
              [126.86843254275891, 37.6575416810253],
              [126.868219516482796, 37.657628202564162],
              [126.868033946110117, 37.65767500415452],
              [126.867846149798908, 37.657722365866171],
              [126.867060789007326, 37.657604319305811],
              [126.866267260294862, 37.657485041999117],
              [126.865498469370053, 37.657138382708077],
              [126.865498580171092, 37.657138951141008],
              [126.865353580256468, 37.657073753148687],
              [126.864936410215932, 37.656887667570636],
              [126.864951126239319, 37.656789644402863],
              [126.864964140292599, 37.6567029634001],
              [126.865068583725503, 37.656007388120216],
              [126.864758808797433, 37.655764265193284],
              [126.864580811670194, 37.655624566788752],
              [126.864387211037879, 37.655476636928633],
              [126.863659705217941, 37.654923950569305],
              [126.863652441355228, 37.654905266498318],
              [126.863494444909961, 37.654784729333301],
              [126.863165575491564, 37.654537097988197],
              [126.863068031272661, 37.654461418546688],
              [126.862908062048845, 37.654341654999563],
              [126.862904265086769, 37.654338771872482],
              [126.862898398071664, 37.654340264332212],
              [126.862622029392526, 37.654124571478157],
              [126.862173335524062, 37.653784032544408],
              [126.862066939347429, 37.653777796861647],
              [126.861748157044147, 37.653758264015991],
              [126.861495296879639, 37.653743665901359],
              [126.861209202999945, 37.653725962469693],
              [126.861011029999958, 37.653713700957823],
              [126.860880961259696, 37.653706769937045],
              [126.860798086427195, 37.65356048679611],
              [126.860796761863469, 37.65355842368772],
              [126.860796316653705, 37.653557725449424],
              [126.860770646945923, 37.653517561943318],
              [126.86074080852211, 37.653470556386765],
              [126.860068018786535, 37.652372888150325],
              [126.860025745847551, 37.652302432675882],
              [126.860008149554773, 37.652273106212228],
              [126.859900627250127, 37.652096166991669],
              [126.859891286551161, 37.652080795516554],
              [126.85989691938731, 37.651998276064504],
              [126.859916276495966, 37.65171468058314],
              [126.859900873347016, 37.651633257465669],
              [126.859772734652083, 37.651716611020269],
              [126.859705041718954, 37.65176064488081],
              [126.859554092521861, 37.651760346418214],
              [126.859247352388863, 37.651759751456979],
              [126.859059438366685, 37.651759390376128],
              [126.857865021675792, 37.651757067793625],
              [126.857084428564065, 37.651932624871272],
              [126.856218544787524, 37.652550737850667],
              [126.855823747428289, 37.652542096630839],
              [126.854085964999896, 37.649814397206171],
              [126.853709164215786, 37.649222923728566],
              [126.85354494454522, 37.649486687495525],
              [126.853435691867858, 37.64963630334158],
              [126.85327295784252, 37.649728401413078],
              [126.852786742041857, 37.649775805454205],
              [126.851924558285717, 37.649606552410745],
              [126.851049536701836, 37.650857438236017],
              [126.85198236561277, 37.651301508843922],
              [126.852543752726973, 37.65141671668907],
              [126.852752029661232, 37.651459457773363],
              [126.85275250496268, 37.651459552316787],
              [126.852754952209494, 37.651470682733077],
              [126.852811242826036, 37.651726642651894],
              [126.852811047091947, 37.651726859699593],
              [126.852310250889829, 37.652282630215524],
              [126.852323508348846, 37.652685074106984],
              [126.852421502785745, 37.652805550090967],
              [126.852461646179137, 37.652854907284457],
              [126.852356709574522, 37.652832933665699],
              [126.851918504306184, 37.652742341022098],
              [126.851838891325627, 37.652881310282481],
              [126.84942310377113, 37.65195073694634],
              [126.849372258902932, 37.652010937468788],
              [126.849308973869142, 37.65203159656842],
              [126.849052357064167, 37.651980020049784],
              [126.848965004954294, 37.652003536763168],
              [126.848896906308909, 37.652021870687527],
              [126.848841094164669, 37.652071596263021],
              [126.848697426387233, 37.652199596656914],
              [126.848708875146798, 37.652673982142346],
              [126.848716196832271, 37.652676766209822],
              [126.848787744595214, 37.652710104761859],
              [126.848884420313624, 37.652759333585777],
              [126.848913614990167, 37.652783098714437],
              [126.848917454553032, 37.652786224982776],
              [126.848956312926376, 37.652846549940953],
              [126.848968876470636, 37.652862205049267],
              [126.849015656365395, 37.652920506959767],
              [126.849082274327017, 37.652982669648139],
              [126.84914740008621, 37.653055012681058],
              [126.849236829120969, 37.653155676093498],
              [126.849189144473058, 37.653199313119373],
              [126.849279346159435, 37.653256280758924],
              [126.849335601176321, 37.65328851791903],
              [126.849469346177557, 37.65338608526347],
              [126.849517506794285, 37.653442007869501],
              [126.849596210740117, 37.65341744079852],
              [126.849600889821843, 37.653428691740004],
              [126.849648297690536, 37.653549619346826],
              [126.851214362982702, 37.65397144528221],
              [126.851085922436539, 37.654195639124758],
              [126.8519215896807, 37.655334396146927],
              [126.851993553876468, 37.655432459414342],
              [126.851990282488799, 37.655433193150763],
              [126.852054334365434, 37.65552048710942],
              [126.852214669681587, 37.655740183131442],
              [126.85206826560227, 37.656551836115362],
              [126.851644273176674, 37.658902317952602],
              [126.852443971184044, 37.659474201095009],
              [126.852509369865146, 37.659520969457894],
              [126.852634950125804, 37.660185433238965],
              [126.852699000216191, 37.660524311597129],
              [126.852825353640014, 37.660812908707356],
              [126.852981115752428, 37.661169172801415],
              [126.852990903176533, 37.661191559575961],
              [126.853176799310759, 37.66159616282291],
              [126.853522591144241, 37.661619337072899],
              [126.853815463118394, 37.661627056481031],
              [126.854140122534773, 37.661624219820212],
              [126.854412826958026, 37.661762198897186],
              [126.854876562725053, 37.66199799601462],
              [126.855063756755044, 37.662339306773205],
              [126.855236210215423, 37.662631670292406],
              [126.855398859729107, 37.662919336335229],
              [126.855473188568055, 37.663283820981242],
              [126.855550935049095, 37.66368742740552],
              [126.855566705046371, 37.663923374620467],
              [126.855573702248947, 37.664001092814622],
              [126.855523089768823, 37.664022743764605],
              [126.854807769912796, 37.664312144739029],
              [126.854791757598946, 37.664318622415387],
              [126.854757205910843, 37.664333384617706],
              [126.854752262558108, 37.664335468482818],
              [126.85472716856836, 37.664345979479549],
              [126.854677117744743, 37.664433662540361],
              [126.854490405447436, 37.664744896534927],
              [126.854366375264618, 37.665026433919259],
              [126.854365741520752, 37.665026295251273],
              [126.854269534385935, 37.665110505926904],
              [126.853945350792969, 37.665380843036651],
              [126.853776141751027, 37.665377173355239],
              [126.853516868719026, 37.665371547871381],
              [126.853438121614715, 37.665369843557613],
              [126.853427648076035, 37.665369616695116],
              [126.853111412523603, 37.6654889940015],
              [126.853077829360046, 37.665517084427769],
              [126.853041886546421, 37.665547146622728],
              [126.852958669676411, 37.665616755670513],
              [126.852676368946675, 37.66585289012346],
              [126.852605591644718, 37.665958690551228],
              [126.852274459422588, 37.666453665713838],
              [126.85226348401271, 37.666470072675047],
              [126.851968550306054, 37.666704223387491],
              [126.851711885700666, 37.66690463433595],
              [126.851550089722622, 37.666974877605547],
              [126.851538150447155, 37.666980059487472],
              [126.851491925234981, 37.667000128444357],
              [126.851394647831881, 37.667071542822576],
              [126.851299902504095, 37.66712376738689],
              [126.851257429362391, 37.66716660095468],
              [126.851128061791599, 37.667218507855615],
              [126.851114491920342, 37.667223953825555],
              [126.850590661336184, 37.667434149752538],
              [126.850555060615861, 37.667497079015561],
              [126.850471564308393, 37.667644550300466],
              [126.850114461240011, 37.667849066218785],
              [126.849834881532587, 37.667942590075654],
              [126.849718922777413, 37.667982188713395],
              [126.84968743079736, 37.667992943661936],
              [126.849563128772999, 37.668033328067644],
              [126.849400825318099, 37.668308094469467],
              [126.84937654763938, 37.668350715851851],
              [126.849319493832851, 37.668450884544619],
              [126.849264685320236, 37.668538026112067],
              [126.849232176211245, 37.668558879189646],
              [126.849230918498833, 37.668559685246265],
              [126.849191926936413, 37.668584695513886],
              [126.849149024610966, 37.668612298934733],
              [126.848979815233733, 37.668720917646546],
              [126.848734109807097, 37.668876645168105],
              [126.84872051391693, 37.668885532879322],
              [126.84865761046585, 37.6689266411029],
              [126.848611163361298, 37.669285421452742],
              [126.84858596189062, 37.669480079512795],
              [126.848578026333669, 37.669541369103982],
              [126.848541604685863, 37.669787008155318],
              [126.848521826531027, 37.669920419507633],
              [126.848524853297931, 37.670001833152504],
              [126.848517247999482, 37.670028185566977],
              [126.848509241294408, 37.670087353033679],
              [126.848489631351868, 37.670107733648948],
              [126.848481276780433, 37.670116416838724],
              [126.848246735739053, 37.670360186963869],
              [126.84780629452942, 37.6708269454519],
              [126.847805512514626, 37.670827160149699],
              [126.846387306339381, 37.671209174085213],
              [126.845346411796172, 37.671339793270867],
              [126.844799191431548, 37.671429704684094],
              [126.844384645777538, 37.67149852824388],
              [126.844350519019656, 37.671557044559115],
              [126.844063611744971, 37.672050485059117],
              [126.843951591973052, 37.672230979704068],
              [126.8435019531086, 37.672222629948983],
              [126.842673304665539, 37.672199711341591],
              [126.842097607285353, 37.672196504105074],
              [126.841668247340564, 37.672194031662308],
              [126.84160943464272, 37.672153714274693],
              [126.841509977625122, 37.67211000719108],
              [126.841304634546574, 37.672019768167345],
              [126.840701230762036, 37.671562497457032],
              [126.84068648781421, 37.671524004170351],
              [126.840679821647768, 37.671504885851895],
              [126.840662108955286, 37.67147344234975],
              [126.840648259087445, 37.67144884527395],
              [126.840614319990806, 37.671377668966876],
              [126.840597562272976, 37.671342492096748],
              [126.84055131693394, 37.671245415888897],
              [126.840335349669061, 37.671192391881029],
              [126.840212605075465, 37.671163537082549],
              [126.840044124895286, 37.671123933022152],
              [126.839901890620993, 37.671091628532743],
              [126.839722555400328, 37.671131151887877],
              [126.839517429958349, 37.671261007367825],
              [126.839144161053923, 37.671563688733578],
              [126.83873693668049, 37.671744231045587],
              [126.838640350593451, 37.671825622629584],
              [126.838518223007355, 37.671928536935582],
              [126.838461980744967, 37.671975952052414],
              [126.838173613433071, 37.672090435718772],
              [126.838166583176502, 37.672093227330308],
              [126.838163308988044, 37.672095966110135],
              [126.837979633905135, 37.672249635073619],
              [126.837793072515936, 37.672294679690758],
              [126.837614795106532, 37.672337724528873],
              [126.837351336259417, 37.672512568977403],
              [126.837338975173267, 37.672537685246994],
              [126.837262866127105, 37.672692324228294],
              [126.83719927288665, 37.672710345782065],
              [126.83716292229613, 37.672727135974789],
              [126.836771525995175, 37.672907907291439],
              [126.836689822405376, 37.672945644153337],
              [126.836615975040388, 37.673006910966194],
              [126.836613453792424, 37.673009002279493],
              [126.836534572720936, 37.673081600122465],
              [126.836511389119238, 37.673103371894022],
              [126.836466731604673, 37.673158151942403],
              [126.836417288817188, 37.673350313801627],
              [126.836396287895994, 37.673431936774904],
              [126.836320929486547, 37.673531256932023],
              [126.836180519477537, 37.673716310854125],
              [126.836158439396456, 37.673745411125068],
              [126.835948802803912, 37.67402672772878],
              [126.83585626842779, 37.674069780319684],
              [126.835803798143601, 37.674090826399805],
              [126.83577289594335, 37.674103216829543],
              [126.835711416692575, 37.67425433094381],
              [126.835355017408276, 37.674592924512552],
              [126.835332495780577, 37.674575227171474],
              [126.835316549892482, 37.674590728414934],
              [126.835277285479847, 37.674626506019962],
              [126.835166394049807, 37.674739738973422],
              [126.835111193689983, 37.674796103971481],
              [126.835029236581505, 37.674872593376634],
              [126.834979224279095, 37.674919268581583],
              [126.835084875342844, 37.675113384918497],
              [126.835105379479032, 37.675151059601973],
              [126.835220386174356, 37.675395042948374],
              [126.835218402106989, 37.675428041866603],
              [126.835242500689063, 37.675497541816362],
              [126.8352414649883, 37.675555921964133],
              [126.835269816176606, 37.67585445524837],
              [126.835248479585161, 37.67596902759621],
              [126.835223436477108, 37.676218381093456],
              [126.835200545725527, 37.676302623217957],
              [126.835195537990799, 37.676321049602443],
              [126.835168890689175, 37.67658391839818],
              [126.835137702818159, 37.676834819034212],
              [126.83512583138257, 37.676962471534971],
              [126.835100197909156, 37.677015872882855],
              [126.835083780249732, 37.677052023464825],
              [126.83487100556124, 37.677260974579255],
              [126.834769702063582, 37.677306908827575],
              [126.834449556208696, 37.677452075177271],
              [126.834321386211968, 37.67745218462894],
              [126.833964160249934, 37.677462458218834],
              [126.833944760916793, 37.677474219097448],
              [126.833553738656832, 37.677799407012344],
              [126.833509679389763, 37.678285687229106],
              [126.833515681560613, 37.678489676300906],
              [126.833472401771061, 37.678579982297499],
              [126.833362527807367, 37.678844801116263],
              [126.833248736909852, 37.679085197038944],
              [126.833248231992627, 37.679160112207242],
              [126.833244473669126, 37.679717130585303],
              [126.833239053313122, 37.679993175344471],
              [126.83323743152998, 37.680075795910859],
              [126.833813631651736, 37.680746904041548],
              [126.833879414266605, 37.680823523978006],
              [126.834447164880814, 37.681467172905307],
              [126.83441589061681, 37.681657405468115],
              [126.834385788774668, 37.681840536797594],
              [126.834361190835963, 37.681991776111026],
              [126.834325636840731, 37.682226337691077],
              [126.834251907718766, 37.682698072447486],
              [126.834228522183139, 37.68286408673341],
              [126.83432385430477, 37.682918190522209],
              [126.834469004590233, 37.683000565077911],
              [126.834528063785811, 37.683034369894841],
              [126.834748545050303, 37.683160565174148],
              [126.835443791595623, 37.683039641877237],
              [126.835498583512404, 37.683030109979001],
              [126.835864965233057, 37.682960342003447],
              [126.836010952041633, 37.682932282048291],
              [126.836665900779366, 37.682902440391238],
              [126.836784531196244, 37.682901930473875],
              [126.837062946736154, 37.682900734237911],
              [126.837866896980145, 37.682921988410726],
              [126.838163525129744, 37.682928706304629],
              [126.838380245517556, 37.682873001987751],
              [126.838676209158947, 37.682792194563028],
              [126.839108772979401, 37.682674089099322],
              [126.839292487892251, 37.682628177581662],
              [126.839360078608138, 37.682591007105309],
              [126.839698469387969, 37.682311325203976],
              [126.840306942195923, 37.682809548068654],
              [126.840282857852415, 37.682978896394026],
              [126.840019894992182, 37.68345973580427],
              [126.840081015494221, 37.683557673130188],
              [126.840433967608377, 37.684030452089516],
              [126.841105786664897, 37.684745459772408],
              [126.841307130564317, 37.684975832497237],
              [126.841584634169777, 37.685377987861102],
              [126.841614426970779, 37.685420830042673],
              [126.84170547641304, 37.685551766993875],
              [126.841857443495002, 37.685464748820046],
              [126.842038751423559, 37.68536092584494],
              [126.842343096560782, 37.685109045240253],
              [126.842536389625323, 37.685156571818034],
              [126.842989150293121, 37.685380550247771],
              [126.843212557704206, 37.685475973960791],
              [126.843233301593742, 37.685487498438519],
              [126.843423075925969, 37.685756042655449],
              [126.84352069461184, 37.685894176699684],
              [126.84377969477103, 37.686282723870342],
              [126.843895218058094, 37.686411904339906],
              [126.843915323508455, 37.686484100410205],
              [126.844007034156888, 37.686540443962777],
              [126.844031237299518, 37.686552265149928],
              [126.844248698910533, 37.686658476011402],
              [126.844268992559236, 37.686644161378069],
              [126.84449017614881, 37.686488119200298],
              [126.84494469132504, 37.686277707213392],
              [126.84511665239161, 37.686191461077144],
              [126.845249213393558, 37.686127063934968],
              [126.845518044804564, 37.686005076749275],
              [126.845973075144627, 37.685671012883724],
              [126.846283454369313, 37.685641392122378],
              [126.846432436630906, 37.685627173903697],
              [126.847030151948445, 37.685591324289369],
              [126.847075183358498, 37.685613158511785],
              [126.847434211164199, 37.685844736258872],
              [126.847663548203457, 37.68594249356061],
              [126.847813685802222, 37.685987676293998],
              [126.847889273066286, 37.686001837675377],
              [126.848076613559854, 37.686036626229765],
              [126.848119314564428, 37.686066917807452],
              [126.848158025988653, 37.68609198848462],
              [126.848148499959208, 37.686134267379096],
              [126.8481483062546, 37.686135018011989],
              [126.848143527806954, 37.686156170893362],
              [126.848199466790803, 37.686187751500576],
              [126.848223459061231, 37.686357479797984],
              [126.848209514020468, 37.686433881804533],
              [126.848226208742403, 37.686486764578682],
              [126.848235794445046, 37.686485101438329],
              [126.848431060151412, 37.687367789924075],
              [126.848635101654338, 37.688299936973323],
              [126.848643399072998, 37.688339130866702],
              [126.848652024827032, 37.688385135166577],
              [126.848702188548629, 37.688617686762527],
              [126.848758319640623, 37.68886721976034],
              [126.848797290609951, 37.689037383009492],
              [126.84884265567527, 37.689250430437426],
              [126.848862110171154, 37.689340372708443],
              [126.848884682918893, 37.689444940832857],
              [126.848905112722463, 37.68953503629632],
              [126.848928478146405, 37.689640930125229],
              [126.848961397597591, 37.689787109940866],
              [126.848985796600402, 37.689895969404738],
              [126.849049441586772, 37.690197256188938],
              [126.849060011624971, 37.6902056553769],
              [126.849085553624164, 37.69022595354825],
              [126.84912636046873, 37.690400349485863],
              [126.849125665889446, 37.690430505711973],
              [126.849277209814332, 37.690305241606495],
              [126.849283834242229, 37.6901400666071],
              [126.849284344479202, 37.690127364377318],
              [126.849448907536981, 37.69000529766938],
              [126.84961663097188, 37.689881296793864],
              [126.849834380665484, 37.689859233505743],
              [126.849959000979567, 37.689811379788409],
              [126.850110015978757, 37.689813480307571],
              [126.850135453172726, 37.689813833204347],
              [126.85023689730869, 37.689721468528759],
              [126.850305716354129, 37.689688322099371],
              [126.85032131938911, 37.689680808108172],
              [126.850342131154875, 37.68969209285909],
              [126.850505841863452, 37.689780844786952],
              [126.850577807043962, 37.689805064897342],
              [126.850677896785939, 37.689804749892701],
              [126.850814976363296, 37.689741080760044],
              [126.850843583433644, 37.689727795913072],
              [126.851042072409925, 37.689665251405266],
              [126.851114432873601, 37.689654397482506],
              [126.851123614913746, 37.689653021212997],
              [126.851284675369271, 37.689635972817058],
              [126.851457155278126, 37.689653266117674],
              [126.851510317626008, 37.68962957427906],
              [126.85154990145746, 37.68954802483875],
              [126.851569018234727, 37.689513271293826],
              [126.851641504049212, 37.689496201311414],
              [126.851780906940547, 37.689519099666789],
              [126.851832373662603, 37.68947753143604],
              [126.851862881817212, 37.689444126642435],
              [126.851918352125367, 37.689327493864781],
              [126.851928149142026, 37.689226119126559],
              [126.851959139537655, 37.689122160489937],
              [126.851981881069392, 37.68897033952458],
              [126.851984639001941, 37.688947708938578],
              [126.851989037839928, 37.688911423312071],
              [126.851997152595388, 37.688844601450256],
              [126.852002033035589, 37.688804322903863],
              [126.85200799684624, 37.688742380700816],
              [126.852008397989835, 37.68873812054828],
              [126.852073189510705, 37.688705823425025],
              [126.852113623514086, 37.68871650182826],
              [126.852199860910218, 37.688753494498386],
              [126.852213463550981, 37.68875932957733],
              [126.852357492248785, 37.688736638501105],
              [126.852411056363081, 37.688728198842441],
              [126.85246779853324, 37.688692619745382],
              [126.852608237314953, 37.688604562723391],
              [126.852683818003442, 37.688511588833713],
              [126.852760057508362, 37.688438054641907],
              [126.852767627204386, 37.688434776615303],
              [126.852837990458255, 37.688404298774813],
              [126.852924462025655, 37.688363954167755],
              [126.853309702766964, 37.688253078447651],
              [126.853404426116839, 37.688225809167363],
              [126.853839053960314, 37.687963361070217]
            ]
          ]
        ]
      },
      id: '31101520'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '효자동',
        ADM_CD: '31101560'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.957362572615295, 37.691947679970802],
              [126.957895639365546, 37.691632538352252],
              [126.95851805660422, 37.69165059079355],
              [126.95915412757482, 37.691669036063928],
              [126.959879662439263, 37.691831469114781],
              [126.960582541315546, 37.691966859715393],
              [126.960877285663301, 37.692039040197422],
              [126.961897584974267, 37.692264630117762],
              [126.962203524091393, 37.692616110232123],
              [126.962645607159658, 37.692814470657893],
              [126.962736291812249, 37.692850538556208],
              [126.963099064904213, 37.692940755224377],
              [126.963858693682312, 37.692986047384643],
              [126.964040098948004, 37.692995115557139],
              [126.965128643233811, 37.692824271288302],
              [126.9651286444265, 37.692815262528974],
              [126.965124589125821, 37.692787674340238],
              [126.965106043248596, 37.692662090326309],
              [126.965083416774206, 37.692562977802453],
              [126.96509475824223, 37.692553970910758],
              [126.965117449162477, 37.692526949296365],
              [126.965094813507548, 37.692445855162852],
              [126.965098735700209, 37.692431336349166],
              [126.965128863998146, 37.692319817495765],
              [126.965132282972178, 37.69231787532074],
              [126.965134559272585, 37.692316631899331],
              [126.965162910370495, 37.692301720943121],
              [126.965208277094092, 37.692274704810288],
              [126.965242290927094, 37.692274716652207],
              [126.965287637010249, 37.692283739062887],
              [126.965321647404238, 37.69229276053327],
              [126.965332986494303, 37.692292763274232],
              [126.965378337157716, 37.692292777806507],
              [126.965389678548846, 37.692283770885453],
              [126.965446384251976, 37.69225675919715],
              [126.965514435852256, 37.692202721234231],
              [126.965571163050939, 37.692130661246395],
              [126.965593862765019, 37.692076610575256],
              [126.965627912843829, 37.692004543327691],
              [126.965786831136583, 37.69161717525764],
              [126.965797543573828, 37.691607121709481],
              [126.966410690377913, 37.691031732240774],
              [126.966807577923518, 37.690896704120384],
              [126.967533357897622, 37.690545538240407],
              [126.968213708707069, 37.690347518485602],
              [126.968364870738682, 37.690125125072306],
              [126.968599443216903, 37.689780016630991],
              [126.968697273969696, 37.689741179380263],
              [126.968735517910076, 37.689725996659682],
              [126.969472552105273, 37.689518975505436],
              [126.970425018533234, 37.68925794899215],
              [126.970799191048869, 37.689176960721149],
              [126.971332159739589, 37.688915817383041],
              [126.971570303312518, 37.688780731947929],
              [126.971740406811335, 37.688672658705137],
              [126.972371751102386, 37.688252171030101],
              [126.972443512206354, 37.688204330515603],
              [126.973135244778689, 37.687808071542662],
              [126.973357427623426, 37.687698882014899],
              [126.973373373230828, 37.687691001706426],
              [126.97337727374213, 37.687687624722706],
              [126.974280637906844, 37.686925391102541],
              [126.974325457522625, 37.68690682343852],
              [126.974949629303524, 37.686646243126511],
              [126.975335106489595, 37.686610290427382],
              [126.976287450913816, 37.686538420794562],
              [126.976474696955677, 37.686500168784264],
              [126.976572091015385, 37.686480152668651],
              [126.976634186520769, 37.686467392349186],
              [126.976638923959385, 37.686466418307056],
              [126.976741654871248, 37.686489243347516],
              [126.976982991690349, 37.68654247561404],
              [126.977047031767654, 37.686556600416985],
              [126.97912180489152, 37.686295730234662],
              [126.97938346412819, 37.686201719775873],
              [126.980074222101905, 37.685953539859057],
              [126.9823662856315, 37.68519355085219],
              [126.982409850940002, 37.685179106287038],
              [126.982459808999806, 37.685147580734679],
              [126.983180881325239, 37.68469270560238],
              [126.983670314190093, 37.684377145395075],
              [126.983963241895211, 37.684188281340852],
              [126.984110643586675, 37.68408919597173],
              [126.984802328149144, 37.68345861589976],
              [126.984849631309018, 37.683429062448205],
              [126.9848895475956, 37.683404149203952],
              [126.984938322077923, 37.683373711629507],
              [126.985167608759909, 37.683230610072989],
              [126.986131008635923, 37.682629336606148],
              [126.987024583389911, 37.682071415474198],
              [126.988521087710637, 37.681540017996539],
              [126.98930670572345, 37.681280510111797],
              [126.98947761632833, 37.681223986800426],
              [126.989938207077671, 37.681071661253881],
              [126.989953976362528, 37.681058427628528],
              [126.990629805753613, 37.680486095451101],
              [126.990788606007698, 37.679846419597084],
              [126.990858742295359, 37.679835443158844],
              [126.991407673980532, 37.679749899542074],
              [126.992107543877012, 37.679640981226818],
              [126.992197629428972, 37.679626950567737],
              [126.99243321398626, 37.679148301113216],
              [126.992924417412866, 37.678821736803762],
              [126.992953589215176, 37.678716826601615],
              [126.99324726964285, 37.677660632719849],
              [126.993119792792726, 37.677225336260548],
              [126.993266649184577, 37.677001519800527],
              [126.993072221194069, 37.676582554300431],
              [126.993156572791506, 37.676141921875988],
              [126.993141727396406, 37.675916397410376],
              [126.99312508331721, 37.675831930677774],
              [126.993199480741666, 37.675719882390275],
              [126.993809694684927, 37.67525226694999],
              [126.993981359945465, 37.674907087221705],
              [126.993958167666264, 37.674801221939148],
              [126.993953747505913, 37.674785175507246],
              [126.993795813088298, 37.674226281859085],
              [126.993562460413159, 37.674112238754489],
              [126.993543436536399, 37.674102940054389],
              [126.993548077185565, 37.674091199414605],
              [126.993715261627798, 37.673668262757317],
              [126.994073855164856, 37.672761086645565],
              [126.994038684702758, 37.672428869112039],
              [126.993976518618993, 37.671841674376005],
              [126.993802431196059, 37.670197243910138],
              [126.994350600601223, 37.669810986099307],
              [126.994357356058174, 37.669566876745613],
              [126.994086444861452, 37.669039791547611],
              [126.993830397764384, 37.668622795964183],
              [126.99373742071964, 37.668344726311368],
              [126.993628742762553, 37.66801969827587],
              [126.993625035914107, 37.667921429066709],
              [126.993560590192573, 37.667804018874776],
              [126.993733462384625, 37.667603245711334],
              [126.993961055023391, 37.667338917992929],
              [126.99412914914268, 37.667030065725591],
              [126.994084931844, 37.666932490744678],
              [126.994017249393011, 37.666783129096672],
              [126.993261112930227, 37.666189562699316],
              [126.993122998943264, 37.666056379678984],
              [126.992749039945323, 37.665532660918984],
              [126.992434541150431, 37.665383419193248],
              [126.99222451934466, 37.665231643033252],
              [126.991238505225311, 37.664993649862382],
              [126.989997217448007, 37.664499422860793],
              [126.989529212167454, 37.664526113014489],
              [126.989453286732925, 37.664530036887918],
              [126.988818284676583, 37.664468308846239],
              [126.988268256936877, 37.664391106062581],
              [126.988210840159155, 37.664292979469771],
              [126.988032985138716, 37.663989021066975],
              [126.987801952985251, 37.663593692353672],
              [126.987759734992153, 37.662883449219038],
              [126.987750759156185, 37.662732409099306],
              [126.987365268341364, 37.661618256479244],
              [126.987283719077652, 37.661170291191844],
              [126.987224984379111, 37.66084761742227],
              [126.987276744803722, 37.660600143542055],
              [126.98654402978309, 37.659485098227286],
              [126.98650969818506, 37.659442856530042],
              [126.986020522213437, 37.658943320014373],
              [126.984616817535112, 37.658228537502538],
              [126.984335209621051, 37.657733807049169],
              [126.983741572386251, 37.657329406926578],
              [126.983588239747576, 37.657285763795208],
              [126.98340604531549, 37.657233905506608],
              [126.983009990935969, 37.65700353817595],
              [126.98285678789523, 37.656449700367808],
              [126.981193622027718, 37.656487706402295],
              [126.97965839148047, 37.656038646536267],
              [126.979944358025591, 37.654815058670998],
              [126.980181286720708, 37.654315404995423],
              [126.980431756735825, 37.653787189487339],
              [126.980805716878194, 37.65317628601013],
              [126.98104806817868, 37.652712614804003],
              [126.981070190915361, 37.652698667540889],
              [126.981676735830462, 37.652316292467567],
              [126.981873793728354, 37.651688445265201],
              [126.982298498351383, 37.651254084022824],
              [126.982442538885735, 37.650732427185496],
              [126.982495568076232, 37.650540370996062],
              [126.983707081773318, 37.649758564989348],
              [126.983937209809753, 37.649610057683091],
              [126.983984526364765, 37.649442454223724],
              [126.984019794272186, 37.649317532110068],
              [126.983883984891833, 37.648600678140696],
              [126.983980933000836, 37.648182017721602],
              [126.984367125332923, 37.647828587715935],
              [126.984567144716266, 37.647645533644791],
              [126.984603218679183, 37.647612521233754],
              [126.984713077782502, 37.647251867852887],
              [126.984693387733458, 37.647086154367308],
              [126.984678786342698, 37.646963260282845],
              [126.984710873378859, 37.646933009233443],
              [126.984812839309939, 37.646836873837181],
              [126.984763101508051, 37.646759995385253],
              [126.98490393212505, 37.646172116195274],
              [126.984929152904769, 37.646066835018608],
              [126.984942243017898, 37.646056344748075],
              [126.9850685800115, 37.645955094078296],
              [126.985100516424708, 37.64592949855512],
              [126.985483042392687, 37.646005520430663],
              [126.985579363319019, 37.64610266183761],
              [126.985718248859172, 37.646092845234726],
              [126.985723390250953, 37.646056239196099],
              [126.985680548209217, 37.645994858990306],
              [126.985563528961407, 37.645965562830369],
              [126.985413409845151, 37.645946675009874],
              [126.985257968749181, 37.64584895194556],
              [126.985106105371415, 37.645728426093569],
              [126.984948368775605, 37.645672370333465],
              [126.984858270954135, 37.64559578529073],
              [126.984795440822225, 37.645445979433518],
              [126.984820600876688, 37.645368841288075],
              [126.984646157836082, 37.645338082044546],
              [126.984643211966002, 37.645337561724055],
              [126.984638637601094, 37.6453300931081],
              [126.984289122884263, 37.64475934197921],
              [126.98419368289359, 37.644603489484815],
              [126.984190886543402, 37.644463163707485],
              [126.984190187209478, 37.644428077078878],
              [126.984037202380705, 37.644341703734113],
              [126.983902358209406, 37.644265570303517],
              [126.983811017680566, 37.644186161440778],
              [126.983780699836828, 37.644137618068058],
              [126.983726081175405, 37.644050165863653],
              [126.983472753091817, 37.643940874224818],
              [126.983247438286526, 37.643683503600734],
              [126.983525578085064, 37.643279508266453],
              [126.983690903750357, 37.643039371673986],
              [126.983837156000277, 37.642657874269631],
              [126.983874604783637, 37.642560187328428],
              [126.983853937789959, 37.642365070447283],
              [126.983853497224644, 37.642360922376525],
              [126.983830978842747, 37.642148841461399],
              [126.984002576576614, 37.64195712091837],
              [126.984257049053539, 37.641644914965873],
              [126.984956300968676, 37.641464272106532],
              [126.985134460137886, 37.641520590054014],
              [126.985193517078798, 37.641596419358734],
              [126.985224263491304, 37.641635898624784],
              [126.985259749669055, 37.641606125509561],
              [126.98532945418529, 37.64154764089691],
              [126.985267882971826, 37.641350263683719],
              [126.985657140446136, 37.640740182424253],
              [126.985760350810139, 37.640672408431165],
              [126.985855325369357, 37.640610040721036],
              [126.985884751826944, 37.640590717228584],
              [126.985912388360418, 37.640572569383096],
              [126.986132538515548, 37.640428004200842],
              [126.985954493024849, 37.640223854744718],
              [126.985896785586647, 37.640192872244761],
              [126.985676231004902, 37.640044459604418],
              [126.985533443921625, 37.639701232041965],
              [126.985428368820052, 37.639281127984987],
              [126.98542338930298, 37.639234992112847],
              [126.985407518360944, 37.639087981680433],
              [126.985444917960336, 37.638875411462578],
              [126.985420878726472, 37.638761379536533],
              [126.985393826697674, 37.638590189752414],
              [126.985293843446058, 37.638418433037714],
              [126.985209362465625, 37.638322420061982],
              [126.985192252611895, 37.638302975690138],
              [126.985198160510208, 37.63826907066931],
              [126.98523355600517, 37.638065917017492],
              [126.98513665250384, 37.637974268133597],
              [126.984959930975151, 37.637807127254412],
              [126.985026269843189, 37.63758943767764],
              [126.985044784213784, 37.637528682797942],
              [126.985084303870408, 37.637314416564458],
              [126.984936701024949, 37.637164896755024],
              [126.984855693370079, 37.636814622675558],
              [126.984744383511739, 37.636648213201092],
              [126.984599416290322, 37.636596706776587],
              [126.984461349499853, 37.636547651316249],
              [126.984424142706473, 37.636517876224147],
              [126.9842022494974, 37.636340308412066],
              [126.984198774449695, 37.636337527018242],
              [126.983959437071164, 37.636438058336687],
              [126.983866788089685, 37.636444959297108],
              [126.983706648709997, 37.636456888156786],
              [126.983674123657636, 37.636444738218387],
              [126.983586804400673, 37.636412100826519],
              [126.983546821211164, 37.636320589789406],
              [126.983131932084689, 37.636105418494694],
              [126.982977599586832, 37.635975879113488],
              [126.98278787359061, 37.635809447705768],
              [126.98268344817798, 37.635818150162685],
              [126.982609080692683, 37.635824347935063],
              [126.982515974099286, 37.635786032991909],
              [126.982515434044785, 37.635758331652809],
              [126.98251510127821, 37.635741270598274],
              [126.982289801542464, 37.635534569274412],
              [126.982196905621691, 37.635382512648413],
              [126.982160643958522, 37.635281436258246],
              [126.982041004244607, 37.635156964699142],
              [126.981994634212583, 37.635156955809848],
              [126.981788092052895, 37.635011729507632],
              [126.981761538549748, 37.634993057961495],
              [126.9816794204757, 37.634935317247283],
              [126.981513605651159, 37.634721027800623],
              [126.981415680481803, 37.634829687088065],
              [126.98125529971523, 37.634842057733415],
              [126.9811223574671, 37.634860044283613],
              [126.980751500356021, 37.634836896013695],
              [126.980606887963887, 37.634815752275522],
              [126.980380157724795, 37.634685917795721],
              [126.980190612016841, 37.634552989281339],
              [126.980049737126549, 37.634417817016285],
              [126.979652392551657, 37.634206582035368],
              [126.979198210746887, 37.634078664967312],
              [126.978905776398477, 37.634062562993606],
              [126.978457929849483, 37.634045584129531],
              [126.977804767208426, 37.633910587070531],
              [126.977610278252385, 37.63377399473994],
              [126.97733171282654, 37.633583038700202],
              [126.977264822664395, 37.633462247178294],
              [126.977194960127747, 37.633336088506319],
              [126.977006021496976, 37.632968901068075],
              [126.976754515154909, 37.632729877233544],
              [126.976728366313168, 37.632705027685184],
              [126.97622422008105, 37.632231618738544],
              [126.976100681435057, 37.632190488464964],
              [126.975828489030675, 37.632009108618632],
              [126.975764975067293, 37.631922095301228],
              [126.975579874784046, 37.631752555097016],
              [126.975369074509757, 37.631698169588674],
              [126.97538835519407, 37.631733086578201],
              [126.974628909779113, 37.631912832866355],
              [126.974453458568036, 37.631980086187461],
              [126.97378696625259, 37.632023638955786],
              [126.973672112167563, 37.632031143146961],
              [126.973029771860865, 37.632374766908541],
              [126.972530907196784, 37.632153179451173],
              [126.971327558750573, 37.631618657669321],
              [126.970715076056237, 37.631508742762755],
              [126.970582748415865, 37.631484993734865],
              [126.969388830774832, 37.631015896402268],
              [126.968682098924504, 37.63084056970164],
              [126.968235550294494, 37.630730073097517],
              [126.967653534095234, 37.630743422042173],
              [126.967084332374768, 37.63070658008229],
              [126.966801610544607, 37.630688269317005],
              [126.966676191137196, 37.630704452007926],
              [126.966512000341865, 37.630725638345474],
              [126.966340942417361, 37.630758750525843],
              [126.966047313083578, 37.630815588031467],
              [126.965228478118789, 37.630768042160419],
              [126.964331418005585, 37.630360065954143],
              [126.963767223179573, 37.629793116812358],
              [126.961927542440066, 37.629924484771209],
              [126.961439669559866, 37.629959318237084],
              [126.960489731430599, 37.629826089098209],
              [126.960115738164134, 37.629775565622502],
              [126.959797653365314, 37.629730047892387],
              [126.959822440136278, 37.629761154731241],
              [126.960604374526326, 37.630718844418148],
              [126.961081466650768, 37.631266623271259],
              [126.961507353021233, 37.631758767643177],
              [126.961619136489389, 37.631887940818707],
              [126.962260272424558, 37.632396947795115],
              [126.963328788625574, 37.633245132753075],
              [126.963290905321429, 37.633375586565194],
              [126.963019990184677, 37.634308789651925],
              [126.962922223942698, 37.634645547935079],
              [126.962727979497728, 37.63524290538161],
              [126.962540778236402, 37.635818856253785],
              [126.962157559606965, 37.636350249043836],
              [126.961604341620344, 37.637117356375427],
              [126.961604099991547, 37.637188695974373],
              [126.961602753004144, 37.637610182212029],
              [126.961602892493786, 37.637877501793746],
              [126.961602958222002, 37.637989178443718],
              [126.961305453802851, 37.638296653046794],
              [126.961133076620698, 37.638474861115952],
              [126.960861868853485, 37.63875547680577],
              [126.960830124747403, 37.638852553356159],
              [126.960719120594248, 37.639192007092824],
              [126.960562291351735, 37.639671586346623],
              [126.960510256481172, 37.639830896430382],
              [126.960449535715085, 37.639897268869646],
              [126.960131621763907, 37.640538436589885],
              [126.959971239658771, 37.641461701444683],
              [126.959912596720301, 37.641517623105216],
              [126.959267781740465, 37.642132733533188],
              [126.959316563511123, 37.642666660747167],
              [126.959349578830938, 37.643027467536299],
              [126.959365334585115, 37.64319965135963],
              [126.959019705654882, 37.643879044022995],
              [126.959121090188091, 37.644341038021523],
              [126.959122231092564, 37.644346272793733],
              [126.958906541238846, 37.644760365253745],
              [126.958923755629783, 37.644831534238577],
              [126.95913777051139, 37.64571632240078],
              [126.959145887079643, 37.645749876620741],
              [126.959096662252762, 37.645834073486974],
              [126.958919918867153, 37.646136602438879],
              [126.959013937609839, 37.646704395403795],
              [126.959027015034181, 37.646783379775044],
              [126.958544630215115, 37.647427042734982],
              [126.958377343633884, 37.647650297710584],
              [126.958332123332156, 37.647683680992472],
              [126.957839162001136, 37.648047690561633],
              [126.957854690694901, 37.648445532176204],
              [126.957842470716074, 37.648470475405439],
              [126.957562261381696, 37.649042455260187],
              [126.957606489738211, 37.649191609388339],
              [126.957629052660351, 37.649267698241715],
              [126.957648435605037, 37.649333070036739],
              [126.957789461835063, 37.649808639008093],
              [126.957764678562995, 37.650022297035576],
              [126.957684987757659, 37.650710296981828],
              [126.957460148485467, 37.651871904492488],
              [126.957108105539746, 37.652837230385011],
              [126.956642298563935, 37.653049630006237],
              [126.956504696829768, 37.653121644443559],
              [126.956412356973672, 37.653170047775141],
              [126.956532465007243, 37.653170426323129],
              [126.9565084832265, 37.653191950527038],
              [126.956468675586322, 37.653227784375567],
              [126.956392329625999, 37.653267732971599],
              [126.956365790564334, 37.653267443021917],
              [126.956348236374041, 37.653288834574006],
              [126.956298291819692, 37.65329585200454],
              [126.956261876009577, 37.653305244593533],
              [126.956249305880661, 37.653308527893252],
              [126.95616326340685, 37.653360930425471],
              [126.956087120906659, 37.653422212504353],
              [126.956009570791309, 37.653484666985079],
              [126.956020278707356, 37.653504645733051],
              [126.956029198366195, 37.653521271387838],
              [126.95596365673569, 37.653570240489636],
              [126.95595051751323, 37.653580507362761],
              [126.955931945993783, 37.65359509540631],
              [126.955851004026883, 37.653662313903595],
              [126.955802361391875, 37.653690817689927],
              [126.955713976139933, 37.653682009490339],
              [126.955707277162858, 37.653685603741124],
              [126.955591390380832, 37.65374775936754],
              [126.955534857138034, 37.653778146465349],
              [126.955524538829451, 37.653789593536217],
              [126.955467215209794, 37.65385337854002],
              [126.955462782733647, 37.65385677232733],
              [126.955458667460661, 37.653860041392129],
              [126.955444196643256, 37.653875506355973],
              [126.95542397751548, 37.653898040973282],
              [126.955406617168848, 37.65395073205368],
              [126.955402474403826, 37.653963352316005],
              [126.955398906100243, 37.653998832125367],
              [126.955368251307917, 37.654035984137337],
              [126.955353357746901, 37.654079053154128],
              [126.955335236955392, 37.654171119032711],
              [126.955321367143299, 37.654224108331483],
              [126.955317283842902, 37.654230756634739],
              [126.955290545700379, 37.654274433429521],
              [126.955256171638922, 37.65429926053897],
              [126.955235923647123, 37.654313929058915],
              [126.955182442033745, 37.654339612500948],
              [126.95504435084041, 37.654406104086966],
              [126.954897700325233, 37.654460941386127],
              [126.954859435634404, 37.654469747240093],
              [126.954801032465696, 37.654483185694026],
              [126.95477803673495, 37.654488492813563],
              [126.954600434839719, 37.654529453075959],
              [126.954583546527715, 37.654534869514883],
              [126.954552021505279, 37.654532046792539],
              [126.954351549734469, 37.654595314364826],
              [126.954262603308862, 37.654680871094413],
              [126.954219350645786, 37.654766444489276],
              [126.954037239707205, 37.654981976545159],
              [126.954032192723176, 37.655076089249192],
              [126.954194126072622, 37.655211274157473],
              [126.954177926939167, 37.655216834333778],
              [126.953945507475808, 37.65529657754692],
              [126.953890620081481, 37.655266381193044],
              [126.953736215554699, 37.655181544914463],
              [126.953592540053478, 37.655100337322786],
              [126.953358849306198, 37.654968248655109],
              [126.953275670830678, 37.654948482722986],
              [126.953210497299509, 37.654933077915736],
              [126.952625018941688, 37.654924683299207],
              [126.952576052384543, 37.654923370867074],
              [126.952314574780232, 37.654916363379023],
              [126.951860003017657, 37.654864925657584],
              [126.951718364762058, 37.654889687692865],
              [126.951530868361289, 37.654922549335829],
              [126.951385943186111, 37.654947949154241],
              [126.951376054920942, 37.654949683297211],
              [126.951309797680011, 37.654994712143626],
              [126.951222664113899, 37.655063389434751],
              [126.951053096864996, 37.655197034175643],
              [126.950941963882443, 37.655311426007337],
              [126.950900526375321, 37.655354080661553],
              [126.950781099783896, 37.655477733677195],
              [126.950772707682518, 37.655486424006924],
              [126.950690334397109, 37.655532500521296],
              [126.950443353285294, 37.655670742078897],
              [126.950404852951849, 37.655692290222042],
              [126.950104038230393, 37.655799807993766],
              [126.949791162519432, 37.655911761878585],
              [126.949633575232596, 37.656087932094522],
              [126.94957177601998, 37.6561570553298],
              [126.949552484444609, 37.656178318768902],
              [126.949490278149213, 37.656246972269024],
              [126.949379404649235, 37.656450958016762],
              [126.949344875219182, 37.656514533628638],
              [126.949237622192214, 37.656712505319689],
              [126.949228214519749, 37.656729868819546],
              [126.94921383934043, 37.656753008294274],
              [126.949203084590081, 37.656770401555377],
              [126.949126242023439, 37.656812713584891],
              [126.948832817320422, 37.656974361430947],
              [126.948744197902727, 37.656986080152919],
              [126.94874279526239, 37.656986265581722],
              [126.948349004740606, 37.657038338708645],
              [126.948286373745134, 37.657047392603758],
              [126.948236335124548, 37.657054730645235],
              [126.948184677814666, 37.657058356619054],
              [126.948128011635916, 37.657062457572948],
              [126.948011559062138, 37.657092433595842],
              [126.947882433485958, 37.657125810400309],
              [126.947757842836992, 37.657280847537827],
              [126.947778820685272, 37.65733402427886],
              [126.94781335165797, 37.657421560137983],
              [126.947992144231534, 37.657711061099107],
              [126.948038341887823, 37.657892439563895],
              [126.948039133678762, 37.658005394562458],
              [126.948008017528238, 37.658069227288912],
              [126.947913263932236, 37.658114530039612],
              [126.947911718892868, 37.658115416929412],
              [126.947910382164679, 37.658116496777801],
              [126.947909292513415, 37.658117740022291],
              [126.947908480822377, 37.658119109852855],
              [126.947907967771684, 37.658120569411693],
              [126.947907770687038, 37.658122075515948],
              [126.947907892136158, 37.658123587617709],
              [126.94790833131249, 37.658125061547977],
              [126.947909074912062, 37.658126456684521],
              [126.947932306335872, 37.658161497427358],
              [126.947933298248941, 37.658162735992853],
              [126.947934529311823, 37.658163830563176],
              [126.947935966867632, 37.658164751241557],
              [126.947937573678132, 37.658165474419071],
              [126.9479393079435, 37.658165980070777],
              [126.947941121001776, 37.658166256251633],
              [126.947942967571976, 37.658166293736393],
              [126.947944796637984, 37.658166092286287],
              [126.947946560538014, 37.658165657987581],
              [126.947948212703935, 37.658165002339629],
              [126.947965359208013, 37.658156739858242],
              [126.947943258116695, 37.658202079768991],
              [126.947897346903957, 37.658216572822596],
              [126.947865380203069, 37.658226676748463],
              [126.9478636336449, 37.658227371611986],
              [126.947862063387703, 37.658228295337636],
              [126.94786071612647, 37.658229419301385],
              [126.947859630647045, 37.65823071123684],
              [126.947858841253904, 37.658232131645981],
              [126.94785837092796, 37.658233639175116],
              [126.947858232473635, 37.658235188817613],
              [126.947858430772712, 37.658236733727065],
              [126.947858960497285, 37.658238227910623],
              [126.94788189690523, 37.658286622314009],
              [126.947882657191869, 37.658287907566326],
              [126.947883660916602, 37.658289082192951],
              [126.94788488561916, 37.658290117246118],
              [126.947886298622635, 37.658290985532943],
              [126.947887866056419, 37.658291667966893],
              [126.947889548381383, 37.658292145434906],
              [126.947891304871305, 37.658292406029162],
              [126.947893090211807, 37.658292445030774],
              [126.947894861335868, 37.658292260435566],
              [126.947896575123053, 37.658291857449662],
              [126.947905604209495, 37.658289096568218],
              [126.947920493107688, 37.658284559547077],
              [126.947939463712359, 37.658380119191875],
              [126.947942535345163, 37.658395536425104],
              [126.948001905816227, 37.658572369827553],
              [126.947983441012255, 37.658641070347777],
              [126.947967039182799, 37.658708140095172],
              [126.94790230367164, 37.658773683904975],
              [126.947807582554915, 37.658869576656492],
              [126.947805800527789, 37.658871384497559],
              [126.947786164099028, 37.658891285910421],
              [126.947755345506479, 37.658922520589734],
              [126.947693296402861, 37.659017932566442],
              [126.947677605952904, 37.659042035516443],
              [126.947579475810514, 37.659192905059612],
              [126.947564852829814, 37.659215385188048],
              [126.947537416370082, 37.659200056634845],
              [126.947510963745216, 37.659185376234113],
              [126.947256906052843, 37.659083819129293],
              [126.947084084692818, 37.65901473420486],
              [126.946981533099702, 37.658973762940057],
              [126.946584450563904, 37.658815116713093],
              [126.946425922437143, 37.658756100861012],
              [126.94629279241984, 37.658712982731245],
              [126.946043347671747, 37.658632191519239],
              [126.945689157682125, 37.658517527691551],
              [126.945205497216833, 37.658376232489047],
              [126.945022612494483, 37.658325186039733],
              [126.94488238450846, 37.658288783898556],
              [126.944728198248413, 37.658248867390469],
              [126.944396449638006, 37.658163583277783],
              [126.944293901313387, 37.65814564953282],
              [126.944123229547003, 37.658115843222497],
              [126.943338297376016, 37.658058580007662],
              [126.943334074270851, 37.658057664154619],
              [126.942697218086096, 37.65791945817265],
              [126.942437969981711, 37.657833790003963],
              [126.942318967663738, 37.657794490020898],
              [126.942099958500222, 37.657722161771105],
              [126.942069826740763, 37.657705297752145],
              [126.941801474448908, 37.657554159266518],
              [126.941611139639178, 37.657447741335105],
              [126.941600513560559, 37.657441800831428],
              [126.941588951376232, 37.65743536016295],
              [126.941573098245001, 37.657426473696404],
              [126.941443453745492, 37.657354023750216],
              [126.941330043954537, 37.657290701724207],
              [126.941021503058366, 37.65711842777668],
              [126.94084891571471, 37.657022845600238],
              [126.940769195191635, 37.656978719749027],
              [126.940335213641006, 37.656767848198861],
              [126.940186065461504, 37.656672826022337],
              [126.940128161787683, 37.656635926498183],
              [126.940067676905528, 37.656584359040075],
              [126.939904730506413, 37.656445499638323],
              [126.939666233344283, 37.656241059890526],
              [126.939398466461981, 37.655944048087278],
              [126.939285113127454, 37.655816447689332],
              [126.939036250095072, 37.655536471748654],
              [126.938809821145171, 37.655275629120723],
              [126.938459656069512, 37.654771172778837],
              [126.938413939201155, 37.654675005657609],
              [126.938261615143915, 37.654354593275876],
              [126.938151063371166, 37.654122063929613],
              [126.938121574694875, 37.654070116713797],
              [126.937923332999262, 37.653720969707003],
              [126.937837036110906, 37.65359197625849],
              [126.937747240369319, 37.653457810448948],
              [126.937739941219192, 37.653446921817888],
              [126.937622900552896, 37.653160798460952],
              [126.937569803959306, 37.65303419297755],
              [126.93753709801581, 37.652956349418808],
              [126.937425072437051, 37.652691337326544],
              [126.937306164437089, 37.652520636532763],
              [126.937163320024027, 37.652315704185412],
              [126.937066529733642, 37.652226649492135],
              [126.937060735139127, 37.652221317499802],
              [126.936766096714265, 37.651969423651479],
              [126.936659459474541, 37.651883067965933],
              [126.936454736213236, 37.651717474416579],
              [126.93628169349941, 37.651583926449909],
              [126.936136764589904, 37.651485866470956],
              [126.935885147845596, 37.65132055612564],
              [126.935705691391803, 37.651202654415016],
              [126.935267068903002, 37.651060508218066],
              [126.934947473434349, 37.650985120827514],
              [126.934735898441346, 37.650935231555565],
              [126.934716763085518, 37.650931023477412],
              [126.934621119642898, 37.650910076037171],
              [126.934590495260551, 37.650903299959772],
              [126.934581973204686, 37.650901140982427],
              [126.934537032155376, 37.650889755357476],
              [126.934241774129646, 37.650814590874788],
              [126.933889723576243, 37.650725094143553],
              [126.933349178572328, 37.650614403893741],
              [126.933216426635852, 37.650584559373641],
              [126.932909743553324, 37.650515609940562],
              [126.932823204072847, 37.650496153249847],
              [126.932797451403545, 37.650490388891932],
              [126.932362810762768, 37.650412148306003],
              [126.932221401651105, 37.650386692861154],
              [126.93221507517076, 37.650378625231191],
              [126.932206371555111, 37.650367573565376],
              [126.932199252085837, 37.650358542198816],
              [126.932196117242654, 37.650354575669198],
              [126.932185715025028, 37.650341350825798],
              [126.932157612379513, 37.650305675120279],
              [126.931900567456012, 37.650230651766385],
              [126.931875016107711, 37.65022928487592],
              [126.931853102346707, 37.650228237445468],
              [126.931593217709207, 37.650293548002182],
              [126.931536864304903, 37.650307741037984],
              [126.931544756830945, 37.650313646743797],
              [126.931431072839132, 37.650331319303234],
              [126.93140192692502, 37.650329785364114],
              [126.930862933386095, 37.650301420705453],
              [126.93044866766968, 37.650246824986958],
              [126.930380810630894, 37.650237810308838],
              [126.930284731560349, 37.650225047659696],
              [126.930042489376632, 37.65017210318603],
              [126.930003097530715, 37.650163511539553],
              [126.929876172918526, 37.65011548631275],
              [126.929709853865049, 37.650052126326102],
              [126.929699375687321, 37.65004815667811],
              [126.929488258062449, 37.649940477608681],
              [126.929285942106645, 37.649837273495592],
              [126.929281094322604, 37.649834820050572],
              [126.929259163405206, 37.649818111407441],
              [126.929193632519102, 37.649766807778008],
              [126.929188619151773, 37.64976288435637],
              [126.929118061364463, 37.649665752456698],
              [126.928978020966895, 37.649568170635519],
              [126.928954637077553, 37.649551885739236],
              [126.928863693591751, 37.649489040830503],
              [126.928762823148674, 37.649419266110186],
              [126.928645823400799, 37.649338335811009],
              [126.928453918375908, 37.649206060447597],
              [126.928201907585105, 37.649033422016707],
              [126.928166746354151, 37.649009405934173],
              [126.928100844411176, 37.648963515101677],
              [126.927992079230236, 37.648887773384601],
              [126.927988089812104, 37.648884711142436],
              [126.927972830738682, 37.648873013570075],
              [126.927971997721102, 37.6488722524116],
              [126.927936533858727, 37.648840122801502],
              [126.927858428179448, 37.648769384210077],
              [126.927741661979212, 37.648661379021078],
              [126.927591784546792, 37.648522746219747],
              [126.92758755100229, 37.648518855356279],
              [126.927574498435121, 37.648507103542684],
              [126.92755344095211, 37.64848820497641],
              [126.927421650024897, 37.648369862185973],
              [126.927376050228418, 37.648327683827702],
              [126.927283635649104, 37.648242286429699],
              [126.927219797750098, 37.648183296144673],
              [126.927173450297502, 37.648139900883905],
              [126.927146474606019, 37.648114656969277],
              [126.927134010841556, 37.648103008915029],
              [126.927096823449034, 37.648077235890938],
              [126.926898866455915, 37.647940108272671],
              [126.926839119051152, 37.647903391550905],
              [126.926599838929576, 37.647756346287743],
              [126.926580882726981, 37.647766747460672],
              [126.926489619743222, 37.647699999999304],
              [126.926141238714678, 37.647445215266707],
              [126.926081690422592, 37.647403935682874],
              [126.92589957764136, 37.647277687737621],
              [126.92580949068649, 37.647215298907824],
              [126.925707253029501, 37.647145427483814],
              [126.925480594728413, 37.646990646667071],
              [126.925281530108279, 37.646858562002613],
              [126.925236869377429, 37.646828953767283],
              [126.925174160820518, 37.646787790823097],
              [126.924844897762227, 37.646571840087844],
              [126.924794039377957, 37.646534994412889],
              [126.924791996786908, 37.646533515281405],
              [126.924397271020581, 37.646280424653341],
              [126.924381204579987, 37.646270226999718],
              [126.924243587030801, 37.64618287518185],
              [126.924122911301239, 37.646106303823103],
              [126.92408190337315, 37.646097005583748],
              [126.923975225406963, 37.646072923913948],
              [126.923915697236268, 37.646059422404761],
              [126.923680156786759, 37.646006002782897],
              [126.923395348993395, 37.645944430902603],
              [126.923355661434229, 37.645939728450386],
              [126.923184760507681, 37.645919489974226],
              [126.923122999273389, 37.645912176512816],
              [126.923078791121398, 37.645906308972087],
              [126.922862331686318, 37.645877629773793],
              [126.922280083488317, 37.64583247131776],
              [126.922228741638861, 37.645828708287546],
              [126.922141754261659, 37.645822333434801],
              [126.922066699549362, 37.645816855215166],
              [126.92184623242467, 37.645800771374113],
              [126.921662011604397, 37.645791401283113],
              [126.921545907304846, 37.645785577272122],
              [126.921470859784876, 37.645781813043428],
              [126.921283272144649, 37.64575831008105],
              [126.921257568018092, 37.645755089710214],
              [126.920630816341998, 37.645676562788331],
              [126.920424077231701, 37.645650690342734],
              [126.919871035783629, 37.645581473527024],
              [126.919762467208542, 37.645565223953589],
              [126.919525353335885, 37.645529734739554],
              [126.919219925497273, 37.64548411397579],
              [126.919153307943859, 37.645474164270951],
              [126.918995722494969, 37.645455399481783],
              [126.918795774259976, 37.645431589233397],
              [126.918752480488834, 37.645426433926431],
              [126.918401785207465, 37.645384672843853],
              [126.918190752623119, 37.645359541454006],
              [126.918116251055196, 37.645350677144698],
              [126.917647273024713, 37.645294439909314],
              [126.917485525255543, 37.645275045961071],
              [126.917341505298452, 37.645257777262287],
              [126.91729631035146, 37.645254420104514],
              [126.916785667873796, 37.64521650594142],
              [126.91668076367155, 37.645208717135304],
              [126.916326631740446, 37.645182421393294],
              [126.916096336687005, 37.645165320974833],
              [126.915806125245638, 37.645143770979516],
              [126.915459593073095, 37.645128331666989],
              [126.915435074286194, 37.645127239441045],
              [126.915189328968594, 37.645116290703776],
              [126.914606747001699, 37.644908639298528],
              [126.91381185461141, 37.644771375285913],
              [126.913720825699116, 37.644755656172521],
              [126.913457480065162, 37.644952484341474],
              [126.913398610997803, 37.644996497530848],
              [126.913363229712928, 37.64500073361846],
              [126.913285706179167, 37.645010144675048],
              [126.913216203026593, 37.645019325173962],
              [126.913187126118586, 37.64502316623576],
              [126.913045658581808, 37.645041850126276],
              [126.912931708304413, 37.645056964506587],
              [126.912900544319172, 37.645060545442561],
              [126.9128812226121, 37.645063657126336],
              [126.912762200598678, 37.645082835446424],
              [126.912679756349618, 37.645096120773395],
              [126.912563235472931, 37.645120251546466],
              [126.912504196393115, 37.645134532092179],
              [126.912327744746449, 37.645177329997836],
              [126.912141684756492, 37.645268782347955],
              [126.912019273358922, 37.645329036566608],
              [126.911945590064136, 37.645365579756813],
              [126.911854311422559, 37.645439280932273],
              [126.911786442412819, 37.645493288379065],
              [126.911709520672304, 37.645546459273206],
              [126.911673709140814, 37.645571281838393],
              [126.911530798520346, 37.64567097565633],
              [126.911313762988442, 37.645822378403679],
              [126.911223931455453, 37.645859508442129],
              [126.91121058692876, 37.645865024692128],
              [126.911057734793147, 37.645928290545484],
              [126.911041627300634, 37.645934874581286],
              [126.911033417625433, 37.645938390251594],
              [126.911005473146233, 37.64595049533321],
              [126.910905209352038, 37.64599321628171],
              [126.910863230441308, 37.646011202742649],
              [126.910726093111009, 37.646102599699773],
              [126.910602588237566, 37.64618500711935],
              [126.910558610760887, 37.646214317736202],
              [126.910496575543888, 37.646255712394648],
              [126.910496246437035, 37.646255903635122],
              [126.91043589633037, 37.64627870679373],
              [126.910436798527428, 37.646281301730781],
              [126.910438516560163, 37.64628623158962],
              [126.910434577600057, 37.646289084449727],
              [126.910304555133735, 37.646383271837728],
              [126.910139039163099, 37.646505622196969],
              [126.910136580988464, 37.646507852419894],
              [126.909966583379585, 37.646662032294337],
              [126.909857537334346, 37.646759217367652],
              [126.909825156236749, 37.646788721817153],
              [126.909727266101783, 37.646877910077066],
              [126.909582103526262, 37.64694998243062],
              [126.909491502063858, 37.64699502367975],
              [126.909237457079968, 37.647123493978761],
              [126.9089473218568, 37.647263279398224],
              [126.908660460447649, 37.647403918746114],
              [126.90863541103775, 37.647416199110403],
              [126.908623564086383, 37.647410630782723],
              [126.908612829899596, 37.647405684543166],
              [126.908590920723753, 37.647390483711042],
              [126.908577327712209, 37.647378673014614],
              [126.908538270332357, 37.647349991509593],
              [126.908513285717106, 37.647331231207041],
              [126.908413666162673, 37.647256587227666],
              [126.908263604169974, 37.64734099964992],
              [126.9081232357332, 37.647420029088607],
              [126.90804726379308, 37.647462495406884],
              [126.907776275182513, 37.647610319238375],
              [126.907712725045457, 37.647645008815253],
              [126.907659437326018, 37.647674097635857],
              [126.907632571739782, 37.647692365226298],
              [126.907620980721646, 37.647700248656562],
              [126.90755223082563, 37.647748342778996],
              [126.907500580095643, 37.647785105573966],
              [126.907473209198386, 37.647804614427301],
              [126.907300630546445, 37.647927616594828],
              [126.907225663858085, 37.647981254926648],
              [126.907109300103414, 37.648063690014737],
              [126.907012585735828, 37.648117296421319],
              [126.907006707459828, 37.648106119203646],
              [126.907005874281722, 37.648104836025929],
              [126.907004793019354, 37.648103674182266],
              [126.907003492900941, 37.64810266446483],
              [126.907002005457471, 37.64810183317077],
              [126.907000371331222, 37.64810120123493],
              [126.906998630066795, 37.648100785080118],
              [126.906996829186426, 37.648100595761179],
              [126.90699486878178, 37.648100652605144],
              [126.906992948203708, 37.64810097554215],
              [126.906991127593827, 37.648101556762484],
              [126.90691698332374, 37.648131409282776],
              [126.906915459576936, 37.648132149727424],
              [126.906914099906516, 37.648133069460606],
              [126.90691293960964, 37.648134148830472],
              [126.906912004979674, 37.648135360027972],
              [126.906911322345906, 37.648136670737522],
              [126.906910906673559, 37.648138052191747],
              [126.906910769591832, 37.648139467494531],
              [126.906910913633027, 37.648140883309338],
              [126.906911335683191, 37.648142263567223],
              [126.906912023553048, 37.648143573055002],
              [126.906925928731027, 37.648165292046514],
              [126.906922928243574, 37.648166991294566],
              [126.906642870416746, 37.648322265070433],
              [126.906530232555809, 37.64838420401265],
              [126.90652220910647, 37.648388616226292],
              [126.90648679572584, 37.648408885670285],
              [126.90643778676295, 37.648437055369804],
              [126.90617206922218, 37.64858632248837],
              [126.906094153823474, 37.64863012794406],
              [126.906029314602321, 37.64865879913274],
              [126.905926920422232, 37.648705449307904],
              [126.905837992673398, 37.648747885895204],
              [126.905540532878362, 37.648889835124962],
              [126.905164546197071, 37.648969212019374],
              [126.904986721462365, 37.649082851015649],
              [126.904802024510502, 37.649201014901983],
              [126.904805681544744, 37.649168195147617],
              [126.904810163395581, 37.649128043618397],
              [126.904571864469801, 37.649300115714929],
              [126.904469789248424, 37.649381127677991],
              [126.904220379043579, 37.649462010252648],
              [126.904061649233398, 37.64947764517688],
              [126.904039052469642, 37.649479870878011],
              [126.903665031167563, 37.64953361993809],
              [126.903154627213851, 37.649911604167329],
              [126.903128928467055, 37.649931995651691],
              [126.90304788940017, 37.649996293961557],
              [126.902989801835091, 37.650042383406998],
              [126.902940189605303, 37.650081746722478],
              [126.902859696667051, 37.650145613222797],
              [126.902689590728968, 37.650244585270393],
              [126.902508154111445, 37.650343532128694],
              [126.901827849479275, 37.650649292388756],
              [126.901295031339103, 37.650820024422693],
              [126.901290243396431, 37.650821238551366],
              [126.901348026177828, 37.650973320441913],
              [126.901352427842696, 37.65098548731261],
              [126.901408494849974, 37.651133015051194],
              [126.901435399933703, 37.651203810508967],
              [126.90146923158089, 37.651291404957661],
              [126.901591673553853, 37.651619814945334],
              [126.901655891104355, 37.65178868505123],
              [126.90169522800079, 37.651887041300895],
              [126.901852452064617, 37.652306875422383],
              [126.901887108893817, 37.652397714206636],
              [126.901957982094089, 37.652590492325089],
              [126.902053008164302, 37.652830610838599],
              [126.902135467557329, 37.653047776285668],
              [126.902136594420782, 37.653050743769434],
              [126.902194786482767, 37.653202679456292],
              [126.902207917903141, 37.653238558572539],
              [126.902270934217924, 37.653401210249285],
              [126.902336977432341, 37.653571671030505],
              [126.902406749484783, 37.653751759746342],
              [126.902639801963247, 37.653727560482892],
              [126.90271187546908, 37.653720076652746],
              [126.902793435844046, 37.653708720019729],
              [126.902792891697459, 37.653726270678227],
              [126.902886334467055, 37.653701968837908],
              [126.902937277301064, 37.65368872025465],
              [126.903009875394531, 37.653676734046911],
              [126.903015503055855, 37.653706165734576],
              [126.903018915796693, 37.653705546666288],
              [126.903021922771302, 37.65372729026074],
              [126.903025712487448, 37.653726737995825],
              [126.903068688648801, 37.653720482121322],
              [126.903063537947659, 37.653698025543406],
              [126.903075247578158, 37.65369617848382],
              [126.903068952629681, 37.65366697236017],
              [126.903214303892725, 37.65364471575419],
              [126.903214419853782, 37.653645666343301],
              [126.903216192823336, 37.653644400831439],
              [126.903364712440364, 37.653619611105619],
              [126.903373136583681, 37.653618204373508],
              [126.903384341680038, 37.653615795005017],
              [126.903421134404013, 37.653607884431267],
              [126.903436382592886, 37.653581752653345],
              [126.903848495890813, 37.653522776335798],
              [126.903866009400048, 37.653520270813232],
              [126.903864277573447, 37.653536811393707],
              [126.903874446856321, 37.65355917071863],
              [126.903891035537697, 37.653595634056614],
              [126.903897434801294, 37.653609703717926],
              [126.903900391408214, 37.653615940544064],
              [126.903891776988473, 37.653625125737193],
              [126.903873755731709, 37.653644344775842],
              [126.903878322904987, 37.653643111401095],
              [126.903884806135792, 37.653642140081402],
              [126.903892178088682, 37.653641035348812],
              [126.903912120373249, 37.653638048163778],
              [126.903991124489281, 37.653626214500257],
              [126.903995138430346, 37.653647958652201],
              [126.903997445707304, 37.653659554211238],
              [126.904000263057611, 37.653673712126363],
              [126.904031490234246, 37.653806839600229],
              [126.904043763227662, 37.65385847574364],
              [126.904060424007469, 37.653883950397777],
              [126.904114375260519, 37.653971028293213],
              [126.904148799502124, 37.654025584865472],
              [126.904166654249082, 37.654053737079003],
              [126.904169571849479, 37.654058334180682],
              [126.904176630539027, 37.65407104254232],
              [126.904197514570569, 37.654112802117197],
              [126.904222858602054, 37.654169018121834],
              [126.904276455874808, 37.654259868924974],
              [126.904312376450349, 37.654249760733116],
              [126.904333038116377, 37.654285312597935],
              [126.904473374932152, 37.654206127222992],
              [126.904721693153988, 37.654658905738032],
              [126.904467567616081, 37.654682858504529],
              [126.904501741991425, 37.654763664057612],
              [126.904513272424097, 37.654790801839184],
              [126.904539943591388, 37.654815468889701],
              [126.904789201691898, 37.655045996496213],
              [126.904765364073214, 37.655083308468129],
              [126.904750262931543, 37.655106944468464],
              [126.904912658369554, 37.655242175634839],
              [126.904984244138348, 37.655301792013958],
              [126.905092535232143, 37.655391407677875],
              [126.905663534397249, 37.656277216628567],
              [126.905909074612055, 37.656656961772555],
              [126.905936663518361, 37.656699626381801],
              [126.905986635501307, 37.656693733669769],
              [126.906285765919051, 37.656658463554635],
              [126.906403959642546, 37.656648025204035],
              [126.906451415298818, 37.656669688622067],
              [126.906689135831087, 37.65677818701527],
              [126.906836640843196, 37.656811177538572],
              [126.907011029426798, 37.656850179699624],
              [126.907019738080905, 37.65691139496252],
              [126.907026324096165, 37.656957686143052],
              [126.9072734859554, 37.657050462046747],
              [126.907365678695598, 37.657085068557265],
              [126.907418009930083, 37.657129690430658],
              [126.907638549025336, 37.657317744843049],
              [126.907728999368231, 37.657306443879285],
              [126.908050184526616, 37.657266312802108],
              [126.908261355412222, 37.657256531124943],
              [126.908270049884351, 37.657252958603145],
              [126.908530849881899, 37.657145753635071],
              [126.90877559432387, 37.657041876446037],
              [126.909154821580714, 37.656986003992913],
              [126.909743521373642, 37.65710029350091],
              [126.909751061521973, 37.657097594791864],
              [126.909802244085199, 37.657079267260528],
              [126.910243999346676, 37.656921081363436],
              [126.91053787734009, 37.656810102102824],
              [126.910576326190522, 37.656801751421511],
              [126.911201935152377, 37.656818217319177],
              [126.91126142991844, 37.656820226801948],
              [126.911388150591918, 37.656819746958057],
              [126.911652322917092, 37.65714098310589],
              [126.911921939571869, 37.657469665546984],
              [126.912020468396619, 37.657600534159258],
              [126.912263171929979, 37.657749379810696],
              [126.912316509123229, 37.657763754079546],
              [126.912625289980753, 37.657746480392689],
              [126.912907084841436, 37.657723285947768],
              [126.913153964959065, 37.657812785332297],
              [126.913320751030071, 37.657868904389026],
              [126.913597359532844, 37.657869327101999],
              [126.913737767061662, 37.658064943130157],
              [126.913820980431183, 37.658180874785991],
              [126.913873641329218, 37.658254239767842],
              [126.914570641420553, 37.658088985529211],
              [126.915071955142835, 37.657972861014727],
              [126.915154553215416, 37.657953727923562],
              [126.915711624170726, 37.658244661080083],
              [126.915877559383034, 37.658331321105109],
              [126.915874825793608, 37.658334814645187],
              [126.91587767856781, 37.658335788688952],
              [126.915901673390721, 37.658343970809923],
              [126.915917564223278, 37.658410337843058],
              [126.915945126148983, 37.658525423688644],
              [126.916038859789097, 37.658916828101319],
              [126.915969581930398, 37.65912733782563],
              [126.915959498736711, 37.659157977297212],
              [126.91584571659584, 37.659503735614202],
              [126.915787630442125, 37.659720703681081],
              [126.915751644973426, 37.659855119285517],
              [126.915672587144186, 37.659916733787753],
              [126.915342440921563, 37.660174046407782],
              [126.915331885731476, 37.66018227190569],
              [126.915548829679949, 37.660374309135506],
              [126.915820110572824, 37.660628402161684],
              [126.915837080939824, 37.660644299142042],
              [126.916172711834264, 37.660677507397388],
              [126.91619335515567, 37.660733575860334],
              [126.916268244426519, 37.660936950361396],
              [126.916318460639033, 37.661073313216491],
              [126.916315345971682, 37.66122755322796],
              [126.916308561749901, 37.661563598571092],
              [126.916306683862658, 37.66165674894043],
              [126.916391202403673, 37.6616785241606],
              [126.916857925657339, 37.66179876788798],
              [126.917533589408109, 37.661947272701084],
              [126.918033725418439, 37.662053302362082],
              [126.918320401879868, 37.662115745482296],
              [126.918425346852004, 37.662148117766407],
              [126.918851649402043, 37.662366588557873],
              [126.918982355366794, 37.662380554120134],
              [126.919357400881921, 37.662471940849024],
              [126.919527658279478, 37.662519415456266],
              [126.919593407663683, 37.662536525372637],
              [126.919684239656235, 37.662561987800736],
              [126.919686357302211, 37.662561733228273],
              [126.919879338444147, 37.662538579143288],
              [126.919997579671644, 37.662527517239283],
              [126.92008448908544, 37.662544497484262],
              [126.920216048688829, 37.662566128083739],
              [126.920307812648858, 37.662580981452813],
              [126.921125435017558, 37.662713314738383],
              [126.921994855518562, 37.662274534970813],
              [126.922792575181859, 37.662118822956529],
              [126.923135879655831, 37.662040622931606],
              [126.923139501308938, 37.66203852882694],
              [126.92341600814521, 37.661878658038987],
              [126.923423445027623, 37.661874358092106],
              [126.923842526207352, 37.661780500731751],
              [126.924197855952201, 37.661590061403672],
              [126.924327505202839, 37.661144741216262],
              [126.924365755889838, 37.66103293759214],
              [126.924367613981133, 37.660866306894995],
              [126.924367970047285, 37.660834436688226],
              [126.924619899759932, 37.660663710939325],
              [126.924765249953609, 37.660564821942678],
              [126.925449942375181, 37.660098981306838],
              [126.927336894205439, 37.660757992341878],
              [126.927381833282368, 37.661166279783906],
              [126.927492836259177, 37.661311017054643],
              [126.927539032925282, 37.661569274145087],
              [126.927787132363406, 37.662370908910702],
              [126.927701561642095, 37.662763427795326],
              [126.927746357514209, 37.663329743594872],
              [126.927848775779097, 37.663793096895297],
              [126.927885391237567, 37.663954260165703],
              [126.928012866872308, 37.664271871678231],
              [126.928245570147325, 37.66466464762285],
              [126.928092921800783, 37.66524507790912],
              [126.928049191425487, 37.665456886592175],
              [126.928026989912681, 37.665564422052576],
              [126.928009478774527, 37.665649239700961],
              [126.928133134765361, 37.665705528860421],
              [126.928177148728096, 37.665701932327231],
              [126.928874404406557, 37.665652189786208],
              [126.929537184412823, 37.665858919249054],
              [126.93028488780115, 37.665919242100692],
              [126.930649498320491, 37.666195457601013],
              [126.930799795484589, 37.666316691699862],
              [126.93085988433738, 37.666395059357257],
              [126.930972022333918, 37.666552227187822],
              [126.931101510175679, 37.666713084813587],
              [126.931315316910315, 37.666942626395873],
              [126.931361560010146, 37.666966159535697],
              [126.931458523515388, 37.66701550968039],
              [126.931678577670567, 37.667074628443096],
              [126.932003815141414, 37.667091217573279],
              [126.932046871019978, 37.667064191227084],
              [126.932771801536887, 37.666609140787152],
              [126.932983293158117, 37.666610959861991],
              [126.933131113105944, 37.666524354049898],
              [126.933253198651855, 37.666279844793372],
              [126.933775948795855, 37.666081123612017],
              [126.933925497302056, 37.665934764204358],
              [126.934380094175623, 37.665781668429013],
              [126.934533424640364, 37.665825548479262],
              [126.934793260051777, 37.665899904360003],
              [126.934837539382968, 37.665916313435496],
              [126.935074465040529, 37.666004108616981],
              [126.935158708173105, 37.666059267872718],
              [126.935556731096199, 37.666524375775538],
              [126.936009931744962, 37.667116538953167],
              [126.936365850500934, 37.667581592363504],
              [126.936420534191853, 37.66768155877395],
              [126.936621541295239, 37.668065790634849],
              [126.936672755443553, 37.668268438766383],
              [126.936771230985869, 37.668658090617413],
              [126.936773647611957, 37.668669002838982],
              [126.936842828763233, 37.668981086892117],
              [126.936864816129756, 37.669080275943557],
              [126.93722492607094, 37.669232645676658],
              [126.937377546650708, 37.669297221285952],
              [126.93803607187381, 37.669575652923072],
              [126.938268640756775, 37.669674254907804],
              [126.938574385748893, 37.66990893149562],
              [126.938847055959911, 37.67011822081566],
              [126.939644165643301, 37.670730032148093],
              [126.93979709389356, 37.670766584655333],
              [126.939859175726781, 37.670781423361873],
              [126.940502354849315, 37.670935155037668],
              [126.940894213393804, 37.671028834397788],
              [126.941064109914379, 37.671182086922123],
              [126.94177770226527, 37.67180412008473],
              [126.942731345865653, 37.671968730221096],
              [126.942877038198276, 37.67199387698404],
              [126.943250112918136, 37.673246411623907],
              [126.943317946210058, 37.673471689174796],
              [126.943374508519398, 37.673615872272926],
              [126.943634735210395, 37.674237671574772],
              [126.944143952901953, 37.675364134517324],
              [126.944129973274045, 37.675771463008608],
              [126.944120763209824, 37.676039851571147],
              [126.94429370695768, 37.676138778925925],
              [126.944483342877263, 37.676247250407187],
              [126.944505373988164, 37.67637232432336],
              [126.944584920463214, 37.67682392219367],
              [126.944478848440653, 37.677024336006944],
              [126.944346526858098, 37.677274292864247],
              [126.944319933984261, 37.677304407149606],
              [126.943892699623547, 37.67778762548739],
              [126.943086969009443, 37.67892245195457],
              [126.943077971643135, 37.679108870876924],
              [126.943040877040673, 37.679877458452765],
              [126.943108779580029, 37.680021646419718],
              [126.943527543209115, 37.680886786332763],
              [126.944195912504284, 37.681499773126035],
              [126.944467775268322, 37.68177019602512],
              [126.944715451417565, 37.682027009729225],
              [126.944841583691328, 37.682157794972461],
              [126.945045477020145, 37.682374124220459],
              [126.94537403059131, 37.682644572914882],
              [126.945453006974404, 37.683149154342246],
              [126.945531976299691, 37.683662744415265],
              [126.945508079346894, 37.683718343128412],
              [126.945361625682708, 37.684059090906651],
              [126.945225197001562, 37.684572579114928],
              [126.945225014757867, 37.684586236075177],
              [126.945225007253484, 37.684586939075423],
              [126.945216443809926, 37.685463474095272],
              [126.9452129145089, 37.685824921353323],
              [126.946289631775997, 37.686230866973496],
              [126.947116834789355, 37.686780842486918],
              [126.94792161372331, 37.687006454698071],
              [126.948499623459412, 37.687267997015347],
              [126.948873759872612, 37.687259153709597],
              [126.949293114668507, 37.687430523576616],
              [126.950267933807979, 37.687701242821284],
              [126.950642012174129, 37.687782493028315],
              [126.951302953646987, 37.688005658060064],
              [126.951390104307293, 37.68803508446549],
              [126.951483847560951, 37.68817961212924],
              [126.951752525813376, 37.688593837988662],
              [126.952069657324273, 37.689071487621476],
              [126.952273516653065, 37.689395920988218],
              [126.952330016532542, 37.689684254767165],
              [126.952352595573927, 37.689828420543193],
              [126.952363913579617, 37.689855453429473],
              [126.952295597023863, 37.690296899982705],
              [126.9524202345942, 37.690414078070724],
              [126.952952631518485, 37.691144083966059],
              [126.953122341190991, 37.691693744055414],
              [126.95345096733746, 37.691964170381702],
              [126.953733994960146, 37.692621992028727],
              [126.9538518157824, 37.692663899651251],
              [126.954041060652017, 37.692731211867788],
              [126.954041470175326, 37.69273135887741],
              [126.954067390756805, 37.692740568762616],
              [126.954266761771976, 37.692811409322161],
              [126.955071586065429, 37.693100038821207],
              [126.955888107264499, 37.692812048853533],
              [126.956353072500491, 37.692641043077494],
              [126.956455155121475, 37.692578015181056],
              [126.956852148095081, 37.692307875317127],
              [126.957332949175893, 37.691968583326393],
              [126.957362572615295, 37.691947679970802]
            ]
          ]
        ]
      },
      id: '31101560'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '창릉동',
        ADM_CD: '31101580'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.901236010992406, 37.650678516946002],
              [126.901221519253454, 37.650640347733123],
              [126.901167872389493, 37.650499136991932],
              [126.901091111387771, 37.650297091612579],
              [126.901083400340468, 37.650276795048534],
              [126.901041914477858, 37.650167588255599],
              [126.901042367506932, 37.650167498620029],
              [126.90151820564374, 37.650056776779948],
              [126.901562423987926, 37.650046488880797],
              [126.901939786991363, 37.649945628337022],
              [126.902383621220366, 37.649818587079238],
              [126.902510918743445, 37.649819188632222],
              [126.902706459978333, 37.649841760339129],
              [126.902740607679817, 37.649845698092371],
              [126.903159768493296, 37.649892008199224],
              [126.903172111234525, 37.649898656672391],
              [126.903665031167563, 37.64953361993809],
              [126.904039052469642, 37.649479870878011],
              [126.904061649233398, 37.64947764517688],
              [126.904220379043579, 37.649462010252648],
              [126.904469789248424, 37.649381127677991],
              [126.904571864469801, 37.649300115714929],
              [126.904810163395581, 37.649128043618397],
              [126.904815580273905, 37.649123851816263],
              [126.904822392202703, 37.649118573737283],
              [126.904884265367201, 37.649070621394621],
              [126.905015640088564, 37.64896880179095],
              [126.905168498637636, 37.648851861472387],
              [126.905223005328921, 37.648810161294833],
              [126.905228495898626, 37.648805962634221],
              [126.905341039822886, 37.648718487047688],
              [126.905353503357887, 37.648708137692807],
              [126.905391403208313, 37.648676627099817],
              [126.90541450916966, 37.648657418113267],
              [126.905625160605922, 37.648481358102366],
              [126.905648348299522, 37.648460412614938],
              [126.905880763839079, 37.648250421199677],
              [126.905949104184927, 37.648187902890754],
              [126.906096637580987, 37.648052876349233],
              [126.906204932955973, 37.647954297560098],
              [126.906222334745735, 37.647938455015627],
              [126.906236095769188, 37.647925994867983],
              [126.906323302299342, 37.647847006620268],
              [126.906348716560885, 37.647821709751589],
              [126.906350459378444, 37.64782007809832],
              [126.906385064530141, 37.647787670898872],
              [126.90644442704361, 37.64773472613026],
              [126.906479401904264, 37.64770342759784],
              [126.906588742890946, 37.647602098336677],
              [126.906622004404682, 37.647571277861474],
              [126.906796186910256, 37.647409847454348],
              [126.906973909718104, 37.647245019433988],
              [126.907237311988837, 37.64699588477734],
              [126.907239728791197, 37.646993595820931],
              [126.907291561695388, 37.646944553403259],
              [126.907473404040189, 37.64665556791671],
              [126.907560308160399, 37.646517255149945],
              [126.907586598908338, 37.646475974582479],
              [126.907599549119979, 37.646455642473185],
              [126.907687631613356, 37.646317335523683],
              [126.907705177831815, 37.646289783374471],
              [126.907951143950029, 37.646178429962156],
              [126.908128120959418, 37.646098321612904],
              [126.908166897294748, 37.646080034339221],
              [126.908250965761553, 37.646040286399185],
              [126.908420820353371, 37.645962857593368],
              [126.90848488887751, 37.645933616967689],
              [126.908613950197818, 37.645879922712076],
              [126.908745636188158, 37.64582513823823],
              [126.908777164707132, 37.645810240399975],
              [126.909216213678874, 37.645547895344109],
              [126.909284067719341, 37.645517487985522],
              [126.909340321521086, 37.645492179930486],
              [126.909386645441316, 37.645461726905282],
              [126.90944139565174, 37.645425594387042],
              [126.909652187244617, 37.645331436286149],
              [126.909725324611045, 37.645314598526816],
              [126.909915020941426, 37.64525534574674],
              [126.910073575427177, 37.645170154329783],
              [126.910116221599338, 37.645145157315611],
              [126.910140724699119, 37.645130735937201],
              [126.910140739735482, 37.645117940700409],
              [126.910140791013688, 37.645076768101532],
              [126.910241496739417, 37.645043139036851],
              [126.910495363079292, 37.64495838382684],
              [126.910589636029115, 37.644930309677193],
              [126.910663630584494, 37.64490820011784],
              [126.910884529979498, 37.644846509409284],
              [126.910965302080911, 37.644823923350408],
              [126.911185190537822, 37.644764446108766],
              [126.911484036314874, 37.64462727544128],
              [126.91177516804342, 37.644495051717527],
              [126.911906384311123, 37.644444751781833],
              [126.912001170896133, 37.64440831289086],
              [126.912029604122012, 37.644397382637024],
              [126.91222712795313, 37.644332641761963],
              [126.912279862800773, 37.644315359152905],
              [126.912269033867474, 37.64428347645287],
              [126.912260634419255, 37.644258742605544],
              [126.912227877779912, 37.644221228192151],
              [126.91220848264166, 37.644199015565086],
              [126.912175259891228, 37.644141831740768],
              [126.912107197016056, 37.643914850736223],
              [126.912085055417649, 37.643840729283113],
              [126.912066739690559, 37.643779665567578],
              [126.91133440809277, 37.641538757338701],
              [126.91121521207225, 37.641174077243647],
              [126.911202816797683, 37.641136117940775],
              [126.911153951137223, 37.640986709771951],
              [126.911151478672096, 37.640979146064495],
              [126.911145697770692, 37.640963664759681],
              [126.911145566787127, 37.640963314388742],
              [126.910892067448756, 37.640284295145541],
              [126.910841471370205, 37.64015077499915],
              [126.910823769331031, 37.640104096694593],
              [126.910794205687438, 37.640026136071612],
              [126.910556537006784, 37.63952337956183],
              [126.910092004954166, 37.638509421270093],
              [126.910170314997046, 37.638334627875516],
              [126.910203408639049, 37.638260667674757],
              [126.91021817744614, 37.638227657167135],
              [126.910258465737883, 37.638098090908557],
              [126.910315965294629, 37.637912976762735],
              [126.910316170675713, 37.637912517207937],
              [126.910406674434299, 37.637709413311221],
              [126.910796909864644, 37.636833678596282],
              [126.91087452971496, 37.63665944546117],
              [126.911210373411691, 37.635905584510624],
              [126.91117815700332, 37.635852272084584],
              [126.910889654616341, 37.635374844774923],
              [126.910854460516902, 37.635316603317612],
              [126.910402731020937, 37.635155066651556],
              [126.910383246616405, 37.635148099314399],
              [126.909806595859251, 37.634941887830813],
              [126.909122459220498, 37.634332915081721],
              [126.908112671356122, 37.633829821614043],
              [126.908027141695413, 37.633793481565945],
              [126.90709624965038, 37.633397951033821],
              [126.906202194900942, 37.633220135492913],
              [126.906757401195108, 37.632736307291182],
              [126.906762906109989, 37.632706477382229],
              [126.906929397498658, 37.631804491370531],
              [126.907294157386886, 37.630867206210503],
              [126.908118272065749, 37.630060638082234],
              [126.90838209484366, 37.629994121406611],
              [126.908482623979765, 37.629821432223345],
              [126.908770042291806, 37.629327703062827],
              [126.908801834540824, 37.629087917191711],
              [126.908803260924145, 37.629065080013845],
              [126.908804144132247, 37.629050868590177],
              [126.908821281509404, 37.628775333545718],
              [126.908844101004959, 37.628408370327513],
              [126.908895635723468, 37.627579703397394],
              [126.90890317034976, 37.62745855667719],
              [126.908902196606832, 37.627454584123107],
              [126.908749987458094, 37.626833499410843],
              [126.908610827537274, 37.626265655912121],
              [126.908395755118121, 37.625992380885897],
              [126.90778997405549, 37.625502274957249],
              [126.907439859448431, 37.625094024086394],
              [126.907021832246528, 37.624786231704221],
              [126.906636548147588, 37.624502545566607],
              [126.906627149863354, 37.624495624722378],
              [126.906708540363752, 37.624325885343346],
              [126.906748880548207, 37.624241757446846],
              [126.906999540755294, 37.62393674913627],
              [126.907108988760186, 37.623448741137096],
              [126.907142807727553, 37.623312656194322],
              [126.907100194153273, 37.623007138144381],
              [126.907113111297051, 37.622869469980778],
              [126.907115882454022, 37.622836732979827],
              [126.907140424873432, 37.622546824563614],
              [126.907117469551721, 37.621941462406213],
              [126.906827673963875, 37.621737940933322],
              [126.906278220223982, 37.621352052218668],
              [126.906204026114594, 37.621279800963563],
              [126.905612695907138, 37.620703934788843],
              [126.905557581441428, 37.620623081341769],
              [126.905213923755696, 37.61986785001001],
              [126.90518925911924, 37.619813873570919],
              [126.905195814125491, 37.61969997776302],
              [126.905202932213228, 37.619576276453891],
              [126.905211315599232, 37.619430578107846],
              [126.905223473856381, 37.619219254489842],
              [126.905223868904216, 37.619211768182225],
              [126.905231092336734, 37.619074545550056],
              [126.905097693414319, 37.619041772908936],
              [126.904917583935301, 37.618997137327675],
              [126.904700327807845, 37.618942900523315],
              [126.904552970479727, 37.618909963108457],
              [126.904509411221966, 37.618900225185612],
              [126.904489014916891, 37.618894249148205],
              [126.904274287546286, 37.618831333641268],
              [126.903821830135797, 37.618803856192343],
              [126.903729055872702, 37.618798220969602],
              [126.903361230614053, 37.618839308575566],
              [126.903340162397001, 37.61830601857325],
              [126.903051609901809, 37.618093769139428],
              [126.903026637274053, 37.617918437504024],
              [126.903009462524139, 37.617797849197331],
              [126.903007773795395, 37.617785993492717],
              [126.903125572464916, 37.61730434575189],
              [126.903017422575502, 37.617232261413967],
              [126.902798623477892, 37.617086432148973],
              [126.902478363088704, 37.616899211358579],
              [126.902473192225884, 37.616665508363127],
              [126.902232220813914, 37.616445557051399],
              [126.902149011641427, 37.616369607137671],
              [126.901899030183912, 37.61589975907895],
              [126.901822603043044, 37.615753566114712],
              [126.901910136647913, 37.615141243493795],
              [126.901917096238662, 37.615092548916067],
              [126.901893089611235, 37.615055084872616],
              [126.901728868698342, 37.614776202730035],
              [126.901799181751031, 37.614470216554061],
              [126.901734240569297, 37.614069223414504],
              [126.901707727311617, 37.61402590956456],
              [126.90117342906143, 37.613153014823375],
              [126.901000723023841, 37.612870854547907],
              [126.900949495805889, 37.612785001770995],
              [126.90093720890215, 37.612764408749044],
              [126.900913493756349, 37.612724660185741],
              [126.900950192496538, 37.612441085851643],
              [126.900961950287765, 37.612350225894922],
              [126.900914751146885, 37.612266239482203],
              [126.900889920586764, 37.612222056079766],
              [126.900864619312145, 37.612176950918474],
              [126.900839105258754, 37.61217644642182],
              [126.900514629776566, 37.612170032237273],
              [126.900224865027937, 37.612191653904134],
              [126.900194594978657, 37.612193912930159],
              [126.899212903496945, 37.612567432756663],
              [126.898689366679406, 37.612548796317931],
              [126.898680524390457, 37.612548806242685],
              [126.89861623952352, 37.612548363281419],
              [126.898465139581702, 37.612547311934165],
              [126.898027438327659, 37.612488069511208],
              [126.89789865766393, 37.612470638633646],
              [126.897607521095281, 37.612221179785728],
              [126.89755338495759, 37.612172155531432],
              [126.89722169754495, 37.611871727221576],
              [126.897204307799484, 37.611845206695214],
              [126.897026611196722, 37.611574207010229],
              [126.896906664890707, 37.611391279785671],
              [126.896884033980854, 37.611356768213469],
              [126.8966690974855, 37.611028974595023],
              [126.896261828278057, 37.610762822530241],
              [126.895991023063644, 37.610561417503767],
              [126.895844475817626, 37.610452430298757],
              [126.895767310699128, 37.610399031205503],
              [126.89541597963894, 37.610584120372849],
              [126.895090687979803, 37.610755491120344],
              [126.895001399790175, 37.610802528436672],
              [126.894949456244618, 37.610829892602567],
              [126.894796383145035, 37.610922608738306],
              [126.894627514982261, 37.611024890653226],
              [126.894597658486603, 37.611042981444427],
              [126.894523689377692, 37.611116119475085],
              [126.894389025211055, 37.611249252623963],
              [126.894368852139692, 37.611269198427905],
              [126.89434863803676, 37.611289172860474],
              [126.894178521519422, 37.611297669044312],
              [126.893584013871106, 37.611316139419692],
              [126.893430611668549, 37.611440673764115],
              [126.893167643733889, 37.611654155366743],
              [126.893078850476968, 37.61162314161809],
              [126.892907263058419, 37.611562286776632],
              [126.892790496169752, 37.611522165104915],
              [126.892679853765557, 37.611483249278876],
              [126.89263930113799, 37.611453527205576],
              [126.892622594661901, 37.611441282189446],
              [126.892594513109415, 37.611416030020933],
              [126.892556666251352, 37.611396191481617],
              [126.892553567152433, 37.611397760078695],
              [126.892534042539779, 37.611381518438598],
              [126.892520580074404, 37.611374408797467],
              [126.892432430334949, 37.611298211169149],
              [126.892234453227076, 37.611132337250019],
              [126.891996085389977, 37.610967686224924],
              [126.891792789599933, 37.61082726134007],
              [126.891658913452616, 37.610809433260989],
              [126.891540053313065, 37.610707169527551],
              [126.891263281216709, 37.610469052948801],
              [126.891067901141355, 37.610300962358622],
              [126.890516336647408, 37.610308642580549],
              [126.889614846016428, 37.609885294288894],
              [126.889156318745265, 37.609842053329814],
              [126.888304752681776, 37.609756276895652],
              [126.887732503550765, 37.609589404828327],
              [126.887405877292764, 37.611136584422219],
              [126.887386396196092, 37.611228857651973],
              [126.887177369409699, 37.611708698371231],
              [126.886996098395471, 37.612265510050641],
              [126.886830628503262, 37.612863688293608],
              [126.886777090230353, 37.613131905491166],
              [126.886711569027071, 37.613460203822854],
              [126.887039224397469, 37.613825956740122],
              [126.88706680767099, 37.61385685359739],
              [126.886754264120299, 37.613934467136566],
              [126.886363261271413, 37.614031536416675],
              [126.886422792872679, 37.614523729291527],
              [126.886457788620348, 37.614813058877722],
              [126.886160380590368, 37.614861028862769],
              [126.886137194436856, 37.614864767769234],
              [126.886071075960231, 37.614875433615261],
              [126.885930145841499, 37.614898166602501],
              [126.885927900522447, 37.614898528071286],
              [126.884950204233192, 37.615056223793992],
              [126.883621926055838, 37.615270451186056],
              [126.883287228504372, 37.615324428429375],
              [126.883237368198948, 37.615384560925875],
              [126.883199273071099, 37.615430503880908],
              [126.882169820223424, 37.615244966728604],
              [126.881983412771618, 37.6152113694734],
              [126.880919495157798, 37.615019610986515],
              [126.880700315041793, 37.614980103668714],
              [126.880127049570234, 37.614590471238913],
              [126.879198552311365, 37.614713129185454],
              [126.877856405273292, 37.615323945952284],
              [126.877114798640477, 37.615299379584606],
              [126.876080295931757, 37.615410547169603],
              [126.875151069825421, 37.615619663260013],
              [126.875209102734829, 37.615827537212482],
              [126.875302521348473, 37.615984326918209],
              [126.875365180523431, 37.616056445450589],
              [126.875458337187624, 37.616135167206735],
              [126.875564615397835, 37.61622497894956],
              [126.875569377874257, 37.616228354413714],
              [126.875633158298044, 37.616274029958838],
              [126.875492855447916, 37.616390421414756],
              [126.875450865438012, 37.616573013365894],
              [126.875308121725539, 37.616859428522979],
              [126.875250304508768, 37.616976393320435],
              [126.87512271530646, 37.616933304522256],
              [126.87510399048584, 37.616926980744189],
              [126.875025338440125, 37.61688557239043],
              [126.874911758550027, 37.616825773802887],
              [126.874866045441635, 37.616813652043788],
              [126.874685023434964, 37.616759579042778],
              [126.874392162607776, 37.6165025307306],
              [126.874255252316587, 37.61648734648319],
              [126.87402101715395, 37.616421221541749],
              [126.873900044868847, 37.616492556751467],
              [126.873887418665774, 37.616576757367916],
              [126.87388340862833, 37.616582299130222],
              [126.873831477655685, 37.616654054658689],
              [126.873804729744023, 37.616737286202593],
              [126.873717051122924, 37.616804368732033],
              [126.873617634950421, 37.61679954889118],
              [126.873515839995534, 37.616661623418651],
              [126.873460439187184, 37.616670168668669],
              [126.873333428566127, 37.617037468709597],
              [126.873332964854299, 37.617069362685527],
              [126.873395649725254, 37.617345687905562],
              [126.873450873766487, 37.61762481807925],
              [126.873434786620393, 37.617692915031554],
              [126.873409571112774, 37.617767759573155],
              [126.873358458101521, 37.617797120430744],
              [126.873211120047259, 37.617798113530412],
              [126.872949037976085, 37.617754223193486],
              [126.872869582536651, 37.617740917264683],
              [126.872753721311781, 37.617721411274125],
              [126.872661719977444, 37.617692883473268],
              [126.872486451343818, 37.617587585094157],
              [126.871967630409287, 37.617387565115386],
              [126.871914787760076, 37.617357009829085],
              [126.871682892240244, 37.617217049052556],
              [126.871608479883889, 37.61716456827665],
              [126.871607311779997, 37.617163745446838],
              [126.871542547412403, 37.617103111156766],
              [126.871504361350517, 37.61706736183725],
              [126.871493010159725, 37.616997875164294],
              [126.871312015017153, 37.616871682243804],
              [126.871028685771876, 37.6166754236746],
              [126.870659615434519, 37.616375411494033],
              [126.870526871265014, 37.616271670064549],
              [126.870395275336577, 37.61631950128028],
              [126.869844354455964, 37.616494651677463],
              [126.869870293253925, 37.616543656960957],
              [126.869919507541951, 37.616636640064954],
              [126.870044540051865, 37.617065746282073],
              [126.870057531825452, 37.617265769533795],
              [126.870058991226472, 37.617279070208809],
              [126.870215038858291, 37.617748878398423],
              [126.870282377076222, 37.617943062571449],
              [126.870318690270338, 37.618052535963166],
              [126.870333703967503, 37.618101187197539],
              [126.870401193281239, 37.618233048420251],
              [126.87041220302622, 37.61825195406886],
              [126.870422683831833, 37.618272580245964],
              [126.87055513507417, 37.618207976148653],
              [126.8707723436317, 37.618430936811919],
              [126.871071895574673, 37.618694649973129],
              [126.871263786747789, 37.618863581287734],
              [126.871579640683365, 37.619211219443478],
              [126.871653742250444, 37.619213889026554],
              [126.872230178894426, 37.619240503611849],
              [126.872657035812409, 37.619304131217639],
              [126.872803290266262, 37.619395792051151],
              [126.873012309347189, 37.619624849451036],
              [126.873142359961719, 37.619767368087658],
              [126.873333621474245, 37.620045948133274],
              [126.873502175237547, 37.620351629399863],
              [126.873696472112783, 37.620673676482511],
              [126.873709037024184, 37.620930805525418],
              [126.873754762101285, 37.621324471608951],
              [126.873789833421398, 37.621680326024588],
              [126.873820527046618, 37.622011657113624],
              [126.873846592591619, 37.622220365955599],
              [126.873927723735321, 37.622870020175895],
              [126.873972743330441, 37.623226188621786],
              [126.873974452187738, 37.623239701473715],
              [126.873998753636997, 37.623431985789011],
              [126.874025259326231, 37.623641697361144],
              [126.874063684919122, 37.623899165334535],
              [126.874090836624163, 37.624133232541446],
              [126.87432462483703, 37.624579902618201],
              [126.874397261974025, 37.624766200472394],
              [126.874485205584079, 37.624991753188837],
              [126.874547771947547, 37.625171087927626],
              [126.874675734640206, 37.625574332423682],
              [126.874724450715519, 37.625885121659707],
              [126.874649710610527, 37.626183887191985],
              [126.874587471158634, 37.626305425715927],
              [126.874703716939919, 37.626598970703043],
              [126.874738980980879, 37.626688027259178],
              [126.874808200175849, 37.62687631486412],
              [126.874817636904865, 37.626902004196999],
              [126.874824030265458, 37.626942376349575],
              [126.874832529370906, 37.626973800404336],
              [126.875109745101952, 37.627998808649089],
              [126.875260952696891, 37.628494590953579],
              [126.875264199981729, 37.628505233063628],
              [126.875267469497999, 37.628522696581129],
              [126.875298062875444, 37.628711233599859],
              [126.87530457814573, 37.628740067493581],
              [126.8753360969759, 37.628855154347875],
              [126.875378110859117, 37.629003327007233],
              [126.875379031632335, 37.629006646985069],
              [126.875422705704111, 37.629164598865465],
              [126.875423938741207, 37.629169065188947],
              [126.875492527481157, 37.62941041407651],
              [126.87552314392596, 37.629523976446102],
              [126.875537468562655, 37.629661608307281],
              [126.875551747772619, 37.629811447578049],
              [126.875552551669585, 37.629819527776696],
              [126.875552943595096, 37.629869259999964],
              [126.875553217222034, 37.629901978034503],
              [126.87554714038518, 37.630027411429097],
              [126.875501649210278, 37.630224396736949],
              [126.875476612247454, 37.630177137119261],
              [126.875467307056809, 37.630202826329871],
              [126.87545145892193, 37.630246572461111],
              [126.875444420026852, 37.630266473588193],
              [126.875416885400611, 37.630344324680635],
              [126.875282662180609, 37.630723442977079],
              [126.875288798175916, 37.630733701988838],
              [126.875337910866037, 37.630811473041284],
              [126.875401837476943, 37.631072148852674],
              [126.875432072375816, 37.63128796540493],
              [126.875469368486335, 37.631516322607681],
              [126.87546751479006, 37.631535231770599],
              [126.875449002123332, 37.631723851224969],
              [126.875370995357315, 37.631915577175477],
              [126.875249421705945, 37.63221642566667],
              [126.875225069298935, 37.632280783460672],
              [126.875046506382503, 37.632692514709454],
              [126.874962867922434, 37.632885358818399],
              [126.874911863846748, 37.633074263884815],
              [126.874888302559228, 37.633158705674965],
              [126.874875915535299, 37.633395948270028],
              [126.874884305951497, 37.633552723623936],
              [126.874894856421093, 37.633749834227558],
              [126.874905658035544, 37.633967840841933],
              [126.874907742593322, 37.634016103813927],
              [126.874911275070787, 37.634097846335578],
              [126.874962229359085, 37.634331443290463],
              [126.875080734590455, 37.634771193824996],
              [126.875111357650852, 37.634808216907089],
              [126.875243776573839, 37.634968322399239],
              [126.875345332668303, 37.635091109624476],
              [126.875467912735644, 37.635482751063854],
              [126.875747857351485, 37.636050745102111],
              [126.875788940350688, 37.636165514569264],
              [126.875885598583295, 37.636435536835862],
              [126.8758924140093, 37.636490512682883],
              [126.875906855184681, 37.636621206508124],
              [126.875880780295063, 37.636861971185439],
              [126.875867956134897, 37.636980356393373],
              [126.87594542700478, 37.637045764853816],
              [126.876161203830506, 37.637227942135958],
              [126.876171658308408, 37.637236763769842],
              [126.876273581811205, 37.637364413893188],
              [126.876363299785794, 37.637472013275563],
              [126.876740608021734, 37.637944626747746],
              [126.8768915735196, 37.6382292589781],
              [126.876887423870514, 37.638281800930812],
              [126.876870119880948, 37.638500965708829],
              [126.876868308748882, 37.638523889628814],
              [126.876698776173853, 37.638696457180117],
              [126.876593986961751, 37.639025542154222],
              [126.876557628523017, 37.639175021560987],
              [126.876544779998795, 37.63931118093037],
              [126.876543899164631, 37.639404649774754],
              [126.876564012280227, 37.63951842659818],
              [126.876653251220205, 37.639772839301429],
              [126.876691741532298, 37.639902043041147],
              [126.876730956394567, 37.640104763194337],
              [126.876750678869669, 37.640253551034654],
              [126.876758874925002, 37.640467507337291],
              [126.876781491441619, 37.640913820131111],
              [126.87676520522939, 37.640998823386937],
              [126.876749202229476, 37.64108234814919],
              [126.876760050834761, 37.64122166296125],
              [126.876869083839168, 37.641172945095953],
              [126.87686942515974, 37.641172791869778],
              [126.877302800655926, 37.641855769140648],
              [126.877414567515174, 37.642031905003044],
              [126.877559865456092, 37.642165271942332],
              [126.877826023796118, 37.642409576568291],
              [126.878212664363801, 37.642794425695712],
              [126.878400410382397, 37.642992233354164],
              [126.878658650075892, 37.643264301331904],
              [126.879095285921949, 37.643704972501993],
              [126.879421048896958, 37.643976413511986],
              [126.879576851726355, 37.644106227884947],
              [126.879742285603911, 37.644244065862708],
              [126.880157543411357, 37.644538438302568],
              [126.880194771103746, 37.644564828378336],
              [126.880690956739215, 37.644835899257515],
              [126.881117559229153, 37.645043234960987],
              [126.8812727878571, 37.645118678496836],
              [126.881862909622441, 37.645369711972101],
              [126.881874043607795, 37.645374448218313],
              [126.882500044587715, 37.645696835590414],
              [126.88253647307927, 37.645715597286667],
              [126.883073387919211, 37.646041947147459],
              [126.883133222740597, 37.646078315978905],
              [126.883163483604449, 37.646096709935819],
              [126.883488968035905, 37.64633097997006],
              [126.88362749337432, 37.646430685274481],
              [126.883829102436778, 37.646575793680377],
              [126.884232643303434, 37.646885281594209],
              [126.884791349467861, 37.647313763511143],
              [126.884898606655995, 37.647396021233803],
              [126.885004296917543, 37.647465563728638],
              [126.885120992419559, 37.647542347097065],
              [126.885344977464712, 37.647667205766503],
              [126.885571431655478, 37.647776716392244],
              [126.885806061899402, 37.647874793408647],
              [126.88604796312228, 37.647961073327274],
              [126.886193616714365, 37.648004633667568],
              [126.88629610817523, 37.648035285768778],
              [126.886549613083346, 37.648096979037099],
              [126.88682764934974, 37.648167590852545],
              [126.8868785449272, 37.64818051727957],
              [126.886910369378441, 37.648188599967135],
              [126.887266242907458, 37.648291836022374],
              [126.887411495585596, 37.648339243263898],
              [126.88758630212017, 37.648396298373086],
              [126.887960600326409, 37.648532436423714],
              [126.888103933264645, 37.64859066504679],
              [126.888297839380016, 37.648669437489801],
              [126.88871898229911, 37.648856746628077],
              [126.88883379177885, 37.648907808798889],
              [126.888874846903391, 37.648927176570744],
              [126.889361799580598, 37.649156892131238],
              [126.889688142664909, 37.649319056565318],
              [126.889821402329687, 37.649385274463818],
              [126.890229170553766, 37.649569727528323],
              [126.890548189803368, 37.64969706667263],
              [126.890686905876848, 37.649746485742398],
              [126.890736004304216, 37.649763976379802],
              [126.890874027748495, 37.64981314829334],
              [126.891205886163036, 37.64991770142089],
              [126.891480657320045, 37.649984173565784],
              [126.892133837313793, 37.650142188744248],
              [126.892365382326091, 37.650198639521186],
              [126.893020448902064, 37.650358340496908],
              [126.893128310614998, 37.650389277491321],
              [126.893688851208978, 37.650550049542588],
              [126.893852429743475, 37.650605772202155],
              [126.894210311928191, 37.650727682246263],
              [126.894264852283001, 37.650746261130486],
              [126.894299837490749, 37.650758177896996],
              [126.894413383751029, 37.650796856493542],
              [126.894978855859648, 37.651019098663241],
              [126.895033628965194, 37.651043133239476],
              [126.895450642931635, 37.651226115880327],
              [126.895532745357855, 37.651264881020225],
              [126.895718681649385, 37.651352673236808],
              [126.896032632507627, 37.651470352605848],
              [126.896157201232668, 37.651503650935581],
              [126.896382313872365, 37.651563825771618],
              [126.896709369639623, 37.651617408274525],
              [126.896724569584052, 37.651619899771958],
              [126.897072308071444, 37.651646246075643],
              [126.897595648574082, 37.651615444329373],
              [126.897983050124324, 37.651560285759849],
              [126.898134952177429, 37.651523431216006],
              [126.898554364805094, 37.651421673945926],
              [126.898847534912235, 37.651326787208347],
              [126.899116920188447, 37.65122241736502],
              [126.899369097280896, 37.651106952233981],
              [126.899545740022688, 37.651037729755323],
              [126.899771116091785, 37.650963233430787],
              [126.90000236962274, 37.650901264421577],
              [126.90023835996459, 37.650852005272718],
              [126.900478079585795, 37.650815720334045],
              [126.900720378786289, 37.650792592121],
              [126.900866922258501, 37.650760171113816],
              [126.901030908921712, 37.650723892465734],
              [126.901236010992406, 37.650678516946002]
            ]
          ]
        ]
      },
      id: '31101580'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '고양동',
        ADM_CD: '31101590'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.929096420734012, 37.748485009009158],
              [126.929643695818982, 37.74813622147839],
              [126.929771703942123, 37.748053764632772],
              [126.9301886674549, 37.747785178656429],
              [126.930665501760004, 37.747488148201256],
              [126.931289954628269, 37.747065070460842],
              [126.931846290617059, 37.746677987736099],
              [126.93216417822164, 37.746470954732615],
              [126.932550185192042, 37.746218912548372],
              [126.93304847542268, 37.745901056017928],
              [126.933072417532273, 37.745885861395891],
              [126.932611637247803, 37.74558996416188],
              [126.930460499100604, 37.744208527022415],
              [126.930362347821671, 37.744145400293426],
              [126.930383567313939, 37.743224811835873],
              [126.930457043475073, 37.740037073959861],
              [126.931140019814009, 37.737649935681141],
              [126.93102393028056, 37.737508440626534],
              [126.931007674121901, 37.737488625974336],
              [126.930437453335031, 37.736793599541649],
              [126.930619469261458, 37.736271151344972],
              [126.930627058491496, 37.736116586519735],
              [126.930631037145304, 37.73603690748515],
              [126.93024886070971, 37.736042098360613],
              [126.929995727507048, 37.736045534393163],
              [126.929885513771509, 37.7360567428737],
              [126.929383042715614, 37.736108227578832],
              [126.928569201387418, 37.736161506244912],
              [126.928566184911745, 37.736161783649187],
              [126.928484877348708, 37.736103728469821],
              [126.928379727380815, 37.736028724580017],
              [126.927971012790522, 37.735737181374873],
              [126.927137772280631, 37.735142802351881],
              [126.927046998247604, 37.73516076430564],
              [126.926795719307464, 37.735190504786019],
              [126.926597418180236, 37.73521397509495],
              [126.926593162513115, 37.735214534843053],
              [126.9265901865123, 37.735154387052248],
              [126.926586559598647, 37.735081075932342],
              [126.926537584553472, 37.734079288966441],
              [126.925528959768528, 37.733078573973863],
              [126.92515461840776, 37.733060312601545],
              [126.924267963613815, 37.732964092303028],
              [126.923317065915526, 37.732860894827759],
              [126.922841057521893, 37.732437127111247],
              [126.922761742839583, 37.732346978140811],
              [126.92244202542345, 37.732097012952771],
              [126.922104333286043, 37.731832987421924],
              [126.922093019000329, 37.731805950052312],
              [126.922074863545305, 37.731791507530026],
              [126.922070349790289, 37.731787915273848],
              [126.92196864987794, 37.731418452446363],
              [126.92183351557442, 37.73049036965152],
              [126.921629580050094, 37.730255981509515],
              [126.921675408756315, 37.729832555402581],
              [126.921689395497552, 37.729685604332651],
              [126.921732774176135, 37.729228952501927],
              [126.921873102739156, 37.729186336875465],
              [126.922413612953562, 37.729022189059421],
              [126.923328182279576, 37.728715254201035],
              [126.923378170004526, 37.728698477294984],
              [126.923375549164987, 37.728632254403749],
              [126.92334500939927, 37.727860568667495],
              [126.923356467043973, 37.727752460881739],
              [126.923447715333978, 37.727275010974289],
              [126.923357706852585, 37.726572198824869],
              [126.923426183542873, 37.72617582118118],
              [126.923222431550542, 37.725770250545843],
              [126.923098056468945, 37.725391763322982],
              [126.92308002910616, 37.725046024064035],
              [126.92307793894831, 37.725005934811314],
              [126.923064702677522, 37.724752057241325],
              [126.923155655066935, 37.724553905324385],
              [126.92364369258334, 37.724274930674035],
              [126.923734878765075, 37.72385153755868],
              [126.92411744403168, 37.723633754207697],
              [126.924177510016392, 37.723599558569326],
              [126.924117538392068, 37.723529382612377],
              [126.923792327338973, 37.723148824135038],
              [126.923838329528067, 37.722545207179486],
              [126.924013364481368, 37.722275412007399],
              [126.924042823563312, 37.722230004970008],
              [126.924038052221334, 37.72217709123688],
              [126.923986758727708, 37.721608302582261],
              [126.924134435010828, 37.721383158350399],
              [126.924168622531099, 37.721239025616676],
              [126.924656774200571, 37.720824901169827],
              [126.924861501890391, 37.7202754415272],
              [126.924872929690906, 37.720194367533978],
              [126.924850642371169, 37.719806937543865],
              [126.924763383514389, 37.719732029309178],
              [126.92398940667502, 37.719067584331547],
              [126.923926232666446, 37.718941827016593],
              [126.923695125021084, 37.718481763479375],
              [126.923683793936775, 37.718472745346538],
              [126.923661156213157, 37.718427682166379],
              [126.92364989905181, 37.718346587502111],
              [126.923650018617181, 37.718231418106022],
              [126.923650049139184, 37.718202434454234],
              [126.923672827182713, 37.718112352348754],
              [126.923695718797191, 37.71791415545048],
              [126.923749603892261, 37.717790827805715],
              [126.923798041271738, 37.71767997123559],
              [126.923798182543607, 37.71754482773985],
              [126.923492859930036, 37.716679699343445],
              [126.923266584574989, 37.716147979020697],
              [126.923312111092102, 37.715994845390661],
              [126.923265771580844, 37.715906408132021],
              [126.923142329383438, 37.715670386105373],
              [126.922417006112269, 37.715165360993282],
              [126.922190186075298, 37.71515619897086],
              [126.921726393096989, 37.71402967888519],
              [126.921057246317218, 37.714038232903384],
              [126.920999658818488, 37.714034535897262],
              [126.920909818348605, 37.714029122964064],
              [126.92072710432511, 37.713928139855575],
              [126.92050177685617, 37.713803602772416],
              [126.920549599404467, 37.713762065140678],
              [126.920740168248713, 37.713596543599152],
              [126.92095589175527, 37.713380458527283],
              [126.921398384692878, 37.713209577040168],
              [126.921534452165318, 37.713227688915289],
              [126.922453394765355, 37.71293999974953],
              [126.92306617833286, 37.712598042202657],
              [126.923769730863029, 37.712211093026532],
              [126.924563984013133, 37.711842217595503],
              [126.924602005062297, 37.711512964301384],
              [126.924674850617976, 37.710882127052187],
              [126.924678418222982, 37.710851229087872],
              [126.924804067270372, 37.709977375058472],
              [126.92447560737547, 37.709571725324153],
              [126.924577775930558, 37.709472685486354],
              [126.924566657527322, 37.709256447086375],
              [126.924611558709316, 37.709221866135302],
              [126.925349775608282, 37.708653308295993],
              [126.924432531308057, 37.707364338614099],
              [126.92375256521332, 37.706931410580012],
              [126.923639407871946, 37.706697093856377],
              [126.923496726021099, 37.706569348455666],
              [126.923458135911076, 37.706534798610399],
              [126.923356186936957, 37.706426615338557],
              [126.923361810151121, 37.705906416948253],
              [126.923368749350615, 37.705264371402642],
              [126.923562028529332, 37.70478699301956],
              [126.922965720869428, 37.704597021841913],
              [126.922881867380624, 37.704570308111698],
              [126.922530526327122, 37.70438087102395],
              [126.922236495331376, 37.703614859291861],
              [126.922248050285873, 37.703576748451979],
              [126.922373062801213, 37.703164459065441],
              [126.922357400788997, 37.703151339280119],
              [126.922157789559307, 37.702984121935792],
              [126.922164240319788, 37.702763109260317],
              [126.922165095819537, 37.702733570757374],
              [126.9221695742809, 37.70257869387035],
              [126.922489768862818, 37.702113218087163],
              [126.922510285584593, 37.702083391008266],
              [126.922476593104861, 37.701777038983366],
              [126.922175457836715, 37.701543837735144],
              [126.922057380111667, 37.701452399256802],
              [126.922056001773967, 37.701422162088789],
              [126.922035219860959, 37.700965871210023],
              [126.92212950372199, 37.700762637643351],
              [126.922317424935898, 37.700357565945794],
              [126.922319355030311, 37.700353404974038],
              [126.92221751297788, 37.700155128386044],
              [126.921848060920951, 37.699752368735183],
              [126.921696518416098, 37.699587162737728],
              [126.921674323777339, 37.699136663856635],
              [126.921447940667179, 37.698767113491442],
              [126.921413953727821, 37.698740062020853],
              [126.921040057899788, 37.698469516711505],
              [126.921068456734105, 37.698268000994481],
              [126.921097191414006, 37.698064120033649],
              [126.920921355885085, 37.697867496144987],
              [126.920621538020299, 37.697532223194507],
              [126.920440695241652, 37.697000528137963],
              [126.920395516743071, 37.696838312779235],
              [126.920202928086397, 37.696685025866898],
              [126.920100965878305, 37.696603868525081],
              [126.920026321525555, 37.696493447849761],
              [126.920021724714118, 37.696486677922792],
              [126.919180167670604, 37.69601675267414],
              [126.919149187811684, 37.695999557824337],
              [126.919070098012483, 37.695747231683235],
              [126.919021766254161, 37.695437790446285],
              [126.918991287234618, 37.695242635014651],
              [126.918925302919916, 37.695010110536096],
              [126.918720227853356, 37.6942874178471],
              [126.918733933596172, 37.694196430841473],
              [126.918738870627777, 37.694163655055192],
              [126.918857297418555, 37.693377535092559],
              [126.918788465225717, 37.693139857079615],
              [126.918703223565643, 37.692845008182999],
              [126.918687876283613, 37.692791786780617],
              [126.918653901013414, 37.692755724909233],
              [126.918115465730935, 37.692563041001442],
              [126.917871886937064, 37.692475872201165],
              [126.917656890825739, 37.692097312165892],
              [126.917634510653755, 37.691836015564554],
              [126.917306046469633, 37.691538460788081],
              [126.917341034529713, 37.691499146601522],
              [126.917390581418914, 37.691443474973667],
              [126.917714727838273, 37.691079258622921],
              [126.91689907216049, 37.69049304492215],
              [126.917035857670868, 37.689844446764909],
              [126.91608200260022, 37.688982774017269],
              [126.916027854836997, 37.688933740556095],
              [126.915644245230538, 37.688569936331561],
              [126.915257752795355, 37.688203394550634],
              [126.914907665958438, 37.687022867966014],
              [126.91481054996872, 37.686734533187959],
              [126.914704299042995, 37.686419068130441],
              [126.914195810183955, 37.684986151229438],
              [126.914218724681149, 37.684769935913877],
              [126.914230304662922, 37.684571730472726],
              [126.914287434733296, 37.684193365196109],
              [126.914356010883694, 37.683715901777006],
              [126.914390266180519, 37.683508703685369],
              [126.914401643876232, 37.683463663209615],
              [126.914391024762381, 37.682860006467145],
              [126.914391582928928, 37.682382491763541],
              [126.914391931129089, 37.682085171033549],
              [126.914392057519194, 37.68197705661197],
              [126.914377619936545, 37.681963139638803],
              [126.914176909100121, 37.681769674368162],
              [126.91417834090926, 37.681768157336883],
              [126.914210957529491, 37.681733659731051],
              [126.914239625838846, 37.681756484917713],
              [126.914278913039865, 37.681787768611123],
              [126.914279982343615, 37.681786921266728],
              [126.914287241724566, 37.681781863021385],
              [126.914483136431016, 37.681643762839428],
              [126.914630624347311, 37.68154476498399],
              [126.914574112020858, 37.681400569605245],
              [126.914521302149822, 37.681271015705271],
              [126.914415855539914, 37.681013035346055],
              [126.914398162282055, 37.680949481455798],
              [126.9143707016311, 37.680850826454872],
              [126.914291632713301, 37.680607509948132],
              [126.914280361042856, 37.680553439243511],
              [126.914167367149957, 37.680238015634153],
              [126.914065700566539, 37.679931610977484],
              [126.91390748095408, 37.679517047144586],
              [126.913869008289794, 37.679401864626008],
              [126.913862286467847, 37.679381866822155],
              [126.913762377715273, 37.679251719729237],
              [126.913613287770659, 37.679057331993377],
              [126.913420833502698, 37.678840955931378],
              [126.913160455389288, 37.678543441205036],
              [126.913136824371932, 37.678453884912216],
              [126.913115317650139, 37.678372222193055],
              [126.913104279499947, 37.678119941633057],
              [126.913093284900683, 37.677831622604906],
              [126.913093470614527, 37.677829929033649],
              [126.913127862272745, 37.677354134410578],
              [126.913107813030066, 37.677149824484815],
              [126.913094261750302, 37.677011740377004],
              [126.913091979264806, 37.676955992728693],
              [126.913083762793448, 37.676754955803858],
              [126.913083245554233, 37.676741440527458],
              [126.913004335133252, 37.676371983012963],
              [126.912994618164973, 37.676354237082819],
              [126.912789431092278, 37.675975393533413],
              [126.912751063121263, 37.675920459460158],
              [126.912732848905236, 37.675894264514305],
              [126.912472530882326, 37.675560708877732],
              [126.912449903997057, 37.675524653011919],
              [126.912212108833728, 37.675317249757541],
              [126.912026484812429, 37.675164501253121],
              [126.911872381193362, 37.675037690318497],
              [126.911453299676097, 37.674767080699787],
              [126.911034294624386, 37.67458459188768],
              [126.9101051132224, 37.674180410257847],
              [126.909504602628132, 37.673972719075259],
              [126.909096646093587, 37.673882305172413],
              [126.908905940343089, 37.673848367265869],
              [126.908884864484563, 37.673844642805641],
              [126.908790670875362, 37.67382799634769],
              [126.908575250965086, 37.673863874351184],
              [126.908325841558209, 37.673899717551016],
              [126.90799691894739, 37.674052622165455],
              [126.90799008857357, 37.67405739105579],
              [126.907777810504527, 37.674205614037632],
              [126.907713290371746, 37.674250610601028],
              [126.907508937154859, 37.674502719353015],
              [126.907293234642552, 37.674763828141316],
              [126.907055562225764, 37.675107972630613],
              [126.907032043332634, 37.675142029121282],
              [126.906952547814129, 37.675259092217999],
              [126.906866653652813, 37.675402845635702],
              [126.906850311713086, 37.675430194449277],
              [126.90677094016479, 37.675448149874427],
              [126.906589574077842, 37.675448003602639],
              [126.906304034503705, 37.675447773448127],
              [126.90630254999823, 37.675451345146556],
              [126.906290042683992, 37.67548143789751],
              [126.906265762000245, 37.675606336991038],
              [126.906231421391865, 37.675654700588908],
              [126.906150684933579, 37.675765319600998],
              [126.906049266170839, 37.675906690440172],
              [126.905851581763599, 37.67602472941774],
              [126.905833836115463, 37.676028812262309],
              [126.905817063879198, 37.676052178498736],
              [126.905546893108195, 37.676404610551558],
              [126.905598617148414, 37.67647400845766],
              [126.905604595932274, 37.676735231508204],
              [126.905573339152184, 37.676950285673129],
              [126.905534540368706, 37.677036080333018],
              [126.905316680453666, 37.677244874964479],
              [126.904887320515186, 37.677523690709698],
              [126.904715333416974, 37.677603168833038],
              [126.904489268642891, 37.677625633316659],
              [126.904342159341425, 37.677706364067298],
              [126.904274167924328, 37.677731860791951],
              [126.904210975187937, 37.677754864312632],
              [126.903798396608906, 37.677851836984331],
              [126.903056430902794, 37.678024711455258],
              [126.902729850066137, 37.678074124474449],
              [126.902410078580246, 37.678091455011277],
              [126.902333170249292, 37.678095624199997],
              [126.90210257175805, 37.678037677187191],
              [126.901689420542695, 37.677813284079818],
              [126.901387559159843, 37.677612193125505],
              [126.901075412203298, 37.677262620445902],
              [126.90091913501486, 37.677070148722287],
              [126.900561929313241, 37.676466993563785],
              [126.900197017712614, 37.675755004301671],
              [126.900188626911984, 37.67573997706446],
              [126.899992003389002, 37.675522269929118],
              [126.899868719741519, 37.675339588998725],
              [126.899760234218547, 37.675102134784602],
              [126.899557502346752, 37.674662825245598],
              [126.899349877297425, 37.674213549674711],
              [126.899283886731126, 37.674070382035865],
              [126.899276402374497, 37.674056616699346],
              [126.899161641115313, 37.673844698786802],
              [126.899002486833098, 37.673546050552609],
              [126.898888906926459, 37.67333266651405],
              [126.89879435078295, 37.673157245004226],
              [126.898778486718925, 37.673133073455595],
              [126.898771038605517, 37.673121725630523],
              [126.898672066227348, 37.672970897661308],
              [126.898488939307356, 37.672690536091878],
              [126.89847016423586, 37.672659525703445],
              [126.89838432884477, 37.672516826860779],
              [126.898322921382601, 37.672414693339057],
              [126.898274287628368, 37.672338158663528],
              [126.898204183341647, 37.672213672849814],
              [126.898151829449077, 37.672122808761806],
              [126.898116567100402, 37.672076925197992],
              [126.89811217726016, 37.672071213615858],
              [126.898075954402856, 37.672078688239601],
              [126.89802279171694, 37.672089658269016],
              [126.89795464663699, 37.672105634383371],
              [126.897833479974892, 37.672142050470065],
              [126.897544888100583, 37.672228787816863],
              [126.897519068364886, 37.672218954036225],
              [126.897474954157104, 37.672185072209061],
              [126.897220125966143, 37.67198934834807],
              [126.896932593638013, 37.671817925958379],
              [126.896864531506623, 37.671780785730711],
              [126.896745190388543, 37.671715661962047],
              [126.896554794218105, 37.671630603120491],
              [126.895851166277467, 37.671409722445389],
              [126.894984761625324, 37.671153517902823],
              [126.894057222322758, 37.671004917006542],
              [126.893498553950835, 37.670990442711343],
              [126.893106628309511, 37.670969811551181],
              [126.892383079232914, 37.670803164972774],
              [126.892245325171501, 37.670759197807158],
              [126.891963878759924, 37.670662146097399],
              [126.891858031672029, 37.670632593855224],
              [126.891715673743676, 37.670591501904283],
              [126.891356025433964, 37.67048867619522],
              [126.891289306304927, 37.6704696013893],
              [126.890887681337219, 37.670416761551117],
              [126.890578419821836, 37.670354222538933],
              [126.890471222993952, 37.670333433799669],
              [126.890212519146488, 37.670259626215632],
              [126.890169228191496, 37.670246657617206],
              [126.890077115131234, 37.670217115302137],
              [126.889756744396607, 37.670114364349487],
              [126.889755386589243, 37.670114012115782],
              [126.889312531317032, 37.669998889075224],
              [126.888703810626353, 37.669843970183827],
              [126.88836872368968, 37.66975868894211],
              [126.88798140242146, 37.66967404876236],
              [126.887791431186514, 37.669624699415728],
              [126.887559698005205, 37.669565075906398],
              [126.887433899186945, 37.66953270760807],
              [126.887321679636401, 37.669434050375557],
              [126.88729547807786, 37.669409843412815],
              [126.887275128599398, 37.669390875617736],
              [126.887168042297077, 37.669455795297267],
              [126.886945140948043, 37.669564335687909],
              [126.886880536549072, 37.669583814654935],
              [126.886781869294254, 37.669620568897372],
              [126.886736038012472, 37.669720892613938],
              [126.88661951203359, 37.6697243823047],
              [126.886452177085033, 37.669643291102815],
              [126.886197153090976, 37.669705688955318],
              [126.885993808349454, 37.669755459107037],
              [126.885829395638808, 37.66979494920399],
              [126.885730395477637, 37.669818619864067],
              [126.885468750975079, 37.669933767894349],
              [126.885310750531289, 37.670003005635657],
              [126.88527537988081, 37.670016832424764],
              [126.885220858118004, 37.670038144952237],
              [126.88500632693443, 37.670118831902478],
              [126.884546871866817, 37.670303021768646],
              [126.884244043910996, 37.670425253803259],
              [126.884086619031223, 37.67048879650762],
              [126.88405668195756, 37.670497235227316],
              [126.883998044864953, 37.670520151687995],
              [126.883978652608519, 37.670527699968602],
              [126.883756002072388, 37.670578634475],
              [126.883715138932047, 37.670587981341299],
              [126.883591363839926, 37.670587588699135],
              [126.883571789802616, 37.670637393787679],
              [126.883473692068591, 37.670886052045759],
              [126.883529249705049, 37.670909075721724],
              [126.883477068118481, 37.670964816844958],
              [126.883325522145313, 37.671259088435804],
              [126.883185493168639, 37.671429139660844],
              [126.882961798342436, 37.671820389346678],
              [126.882866966234658, 37.672080944513148],
              [126.882759031063927, 37.672589514035302],
              [126.882757329418908, 37.672597541360332],
              [126.882674733387248, 37.673000283384866],
              [126.882650063777817, 37.67325874671792],
              [126.882638451474108, 37.673574975912331],
              [126.882627347126117, 37.673829200076874],
              [126.882626198684434, 37.673874058502292],
              [126.882623752409941, 37.674271811062582],
              [126.882622284489329, 37.674510560288937],
              [126.882626300879352, 37.674654417711942],
              [126.882632848881485, 37.674741548074678],
              [126.882686806070978, 37.675123946984236],
              [126.882681704834084, 37.675495204595983],
              [126.882684549339345, 37.675644336731388],
              [126.882687262391755, 37.6757633476495],
              [126.882694111607947, 37.675994671960403],
              [126.882685532053515, 37.676086886819384],
              [126.882665596200042, 37.676304514068526],
              [126.882650507641813, 37.676490582403666],
              [126.882644426207349, 37.676565550831207],
              [126.882644171039132, 37.676568700532172],
              [126.88260835168866, 37.676677292291664],
              [126.882555992309449, 37.676836011232275],
              [126.882556684558139, 37.676837319075482],
              [126.882830518167054, 37.677351453751179],
              [126.883058840580176, 37.677765708093553],
              [126.883140967687822, 37.677933372430395],
              [126.883166362520768, 37.677985218837712],
              [126.883354516349868, 37.678459790126809],
              [126.883385661131967, 37.67853834760033],
              [126.883441862469397, 37.678661313700431],
              [126.883453169844458, 37.678686543243451],
              [126.88352896331979, 37.678851145724394],
              [126.883606777948927, 37.679083736719242],
              [126.883685553469576, 37.679319221426432],
              [126.883729568706116, 37.6794504916029],
              [126.88381397181864, 37.679697261376759],
              [126.883865539924997, 37.679847775507298],
              [126.883909917979693, 37.679973644955609],
              [126.883931672748503, 37.680035341686541],
              [126.884024764797246, 37.680315629214334],
              [126.884368568789881, 37.680547157933589],
              [126.884876478122365, 37.680879129190934],
              [126.884902285875754, 37.680897439030595],
              [126.885156512690841, 37.68107780044479],
              [126.885331646670281, 37.681231496190584],
              [126.885661603619297, 37.681476254331379],
              [126.885801923707106, 37.681639650151176],
              [126.885840864746058, 37.681747083662074],
              [126.885850489115853, 37.681828179179895],
              [126.885797683710578, 37.682032018371629],
              [126.885760963037356, 37.682172983718765],
              [126.885744430569943, 37.682234968295312],
              [126.885701669814097, 37.682395285163572],
              [126.885701497757509, 37.682395933221713],
              [126.885647161407562, 37.682599660696674],
              [126.885622254485398, 37.682796678766266],
              [126.8855209533194, 37.683130028059999],
              [126.88540558497435, 37.683396421086847],
              [126.88527687654269, 37.683565768155155],
              [126.885020116789434, 37.683772736587315],
              [126.884447192592077, 37.68417961796581],
              [126.884467807770051, 37.684214587925261],
              [126.884488896365454, 37.684250324675645],
              [126.884589894749539, 37.684421488853609],
              [126.884625746107488, 37.68448224498772],
              [126.884636894640423, 37.684501045317255],
              [126.884644029849142, 37.684513076482816],
              [126.88464427637571, 37.684513437397925],
              [126.884703386185478, 37.684613502682751],
              [126.884713208140056, 37.684615515211853],
              [126.884394034395172, 37.684704847303657],
              [126.884086713833511, 37.684856859093088],
              [126.883065864471376, 37.685019751758574],
              [126.882877812328445, 37.6850592950099],
              [126.882919773002243, 37.685214818285914],
              [126.883087907165603, 37.685715664702968],
              [126.883343784971331, 37.686101329720678],
              [126.883559538007262, 37.686403785033754],
              [126.884207340506919, 37.686597320197045],
              [126.88450821378369, 37.686717428646155],
              [126.88448354474086, 37.686823907686687],
              [126.884468029540486, 37.687214855640129],
              [126.884468225332071, 37.687216415953898],
              [126.884505372820016, 37.687511609818742],
              [126.884647276295695, 37.688639238685191],
              [126.884672668801002, 37.688662821846279],
              [126.884826081188379, 37.688805287926222],
              [126.88625764261316, 37.689221349155019],
              [126.886576039410571, 37.689277864046517],
              [126.886660348381696, 37.689292828323495],
              [126.887100043721034, 37.689350505310308],
              [126.88757023505832, 37.68941218409195],
              [126.887674387906074, 37.689601842669802],
              [126.887706828149504, 37.689776937468203],
              [126.887799592564221, 37.69027765326171],
              [126.887842820964181, 37.690510970079494],
              [126.887877554700339, 37.690698426659232],
              [126.887878640422755, 37.690704326956372],
              [126.887879198495725, 37.690707358303072],
              [126.887957284260125, 37.691128651425188],
              [126.888211329760239, 37.691663387822445],
              [126.888537263438636, 37.692349436671982],
              [126.888708586521076, 37.692683036096554],
              [126.888805053083431, 37.692870897071913],
              [126.888868667275659, 37.693042747840394],
              [126.8889982044074, 37.693392435200842],
              [126.888951833155815, 37.694458658502633],
              [126.888806053096161, 37.695505786489342],
              [126.888683944832479, 37.696382904796053],
              [126.888676362929885, 37.696437631709237],
              [126.888948048951718, 37.696508470666885],
              [126.889189770935715, 37.696571496144678],
              [126.889526687740812, 37.696598902476779],
              [126.890175002052558, 37.69662660456796],
              [126.890349848770995, 37.696653865188438],
              [126.890647712940478, 37.696734230974876],
              [126.890660198548929, 37.69679310866033],
              [126.890664572717441, 37.696813729211115],
              [126.890695573681455, 37.696959941674194],
              [126.890743325417191, 37.696971683890141],
              [126.891065753946194, 37.697018614758157],
              [126.891168484988256, 37.696995002375971],
              [126.891543510019815, 37.697111609915467],
              [126.891771072636331, 37.697148998213457],
              [126.892559424187056, 37.697343175481585],
              [126.892555307697393, 37.697382383481695],
              [126.89240949587095, 37.697565712249663],
              [126.890993380486407, 37.698364431531729],
              [126.891399972793423, 37.700281939042547],
              [126.890476542649537, 37.700730980820097],
              [126.89013280478207, 37.700898010407457],
              [126.889972143742796, 37.700976098257158],
              [126.889859135498696, 37.701031066582232],
              [126.889790075843123, 37.703127476075053],
              [126.889782614060053, 37.703353532415036],
              [126.889685203061333, 37.703478858274615],
              [126.889684937131037, 37.703479193091432],
              [126.889679467909417, 37.703479908372962],
              [126.889672562106213, 37.703480811805171],
              [126.889319221912331, 37.703527226491936],
              [126.889218015247181, 37.703530346322509],
              [126.889014503454419, 37.703536625097051],
              [126.887552187401454, 37.703361032978968],
              [126.886444239481619, 37.703293480899148],
              [126.886338623036025, 37.703287032110538],
              [126.885462392905595, 37.703233610533431],
              [126.883853313622126, 37.703377158955497],
              [126.883400527699933, 37.703431619480938],
              [126.882975591625367, 37.703482729580863],
              [126.881634923335326, 37.703913407145599],
              [126.880272750539888, 37.704350975848001],
              [126.880202130153677, 37.70437029762958],
              [126.879955714552139, 37.704437726960585],
              [126.879478395056154, 37.704721217071942],
              [126.879475183454304, 37.704723123590902],
              [126.879375551933322, 37.704782263111895],
              [126.879352217754544, 37.704798173000263],
              [126.879097354029952, 37.704971949885767],
              [126.879081673096735, 37.704982640808616],
              [126.878999287626726, 37.705038812640559],
              [126.878998632711131, 37.705039261654768],
              [126.878998324680879, 37.705039469139074],
              [126.87872115090542, 37.70505812648517],
              [126.878614009638795, 37.705065338492744],
              [126.878260832032737, 37.704861754557065],
              [126.878106507156758, 37.704816729050542],
              [126.878058214962877, 37.704827492086139],
              [126.877971344502527, 37.704813209355684],
              [126.877979882740661, 37.704782204764626],
              [126.877127867603846, 37.704889513167004],
              [126.877127248698343, 37.704889589213813],
              [126.877042084230908, 37.704802424020237],
              [126.876860550100616, 37.704028077471683],
              [126.876566812448658, 37.703753915417082],
              [126.876507644199577, 37.703698689519335],
              [126.87663620752555, 37.703573731314265],
              [126.876572112406322, 37.703564306211995],
              [126.876234574893545, 37.703514670770652],
              [126.875620225246124, 37.7035467302181],
              [126.875463166528291, 37.703569267894579],
              [126.874707642866113, 37.703677681014206],
              [126.87405460717234, 37.703932358208078],
              [126.874035745638238, 37.70393971380949],
              [126.873991003537057, 37.703957162907209],
              [126.873999980596054, 37.704008240701704],
              [126.874070019579378, 37.704406776806628],
              [126.873604534282123, 37.704965078277617],
              [126.87358529739889, 37.704988149957494],
              [126.87352851360798, 37.705285532240772],
              [126.873525015109692, 37.705303846532196],
              [126.873274155234768, 37.705414329520302],
              [126.873240497307364, 37.705429152204722],
              [126.873219606094395, 37.705438357210454],
              [126.873041378274166, 37.705782238103467],
              [126.872867831610691, 37.705860379072895],
              [126.87285134697315, 37.705871406487582],
              [126.87289421638603, 37.70620361280141],
              [126.873200228863539, 37.706881274240182],
              [126.873248783729096, 37.706988822031363],
              [126.873500378635867, 37.707100680080707],
              [126.873512231990361, 37.707092413052777],
              [126.873533758348628, 37.707104437489555],
              [126.873553469491952, 37.707116874096336],
              [126.873631311756625, 37.707154564500549],
              [126.873949606384286, 37.707308704476546],
              [126.874059981324436, 37.70746144703476],
              [126.874080873559549, 37.70749237321774],
              [126.874086125875081, 37.707499560294835],
              [126.874108410378227, 37.707530053117758],
              [126.874171022158393, 37.70761573247848],
              [126.8742273592744, 37.707690347232898],
              [126.874275808250601, 37.707755135354795],
              [126.874318800026131, 37.708158670473559],
              [126.874358063076841, 37.708523215345892],
              [126.87437676469392, 37.708695067619637],
              [126.874932693829948, 37.708957811166471],
              [126.874511607595451, 37.7096030536539],
              [126.874596211756781, 37.709985992400043],
              [126.874455825897797, 37.710233475015642],
              [126.874372756133241, 37.710379913488282],
              [126.874311859984502, 37.710487262118235],
              [126.874265358596617, 37.710569236517415],
              [126.874359540028465, 37.710661809844794],
              [126.874517368122127, 37.710816946147915],
              [126.874894277772754, 37.711187417627464],
              [126.875111285902321, 37.711881285189641],
              [126.875255600346165, 37.712101375536854],
              [126.875876770817271, 37.712154464299005],
              [126.876163977532329, 37.712588307240871],
              [126.876170887068724, 37.712634713136872],
              [126.876224568957824, 37.7129956154997],
              [126.875903066113551, 37.713091885802456],
              [126.875716540393569, 37.713147739157215],
              [126.875312503555648, 37.713268717629354],
              [126.874739623211042, 37.713156292986724],
              [126.87462656590192, 37.713188715926208],
              [126.874268508534485, 37.713291380112246],
              [126.873913561003974, 37.713603732058857],
              [126.873792592330958, 37.713710175468918],
              [126.873718932700427, 37.714072735051573],
              [126.873716034515567, 37.714082091583734],
              [126.873691472892972, 37.714133745558676],
              [126.873697898691205, 37.714168844654978],
              [126.873864057925971, 37.714825828795178],
              [126.874004621557731, 37.714733542765359],
              [126.874078745058839, 37.714683004514988],
              [126.874270785404704, 37.71455821403481],
              [126.87452468928899, 37.714385529020831],
              [126.874553653277289, 37.714365242538506],
              [126.874743949194425, 37.714072643232178],
              [126.87537803077997, 37.713965648434581],
              [126.875600587439209, 37.713976022593407],
              [126.875275049611858, 37.714448473639536],
              [126.875544167611224, 37.714463583197194],
              [126.875222182135204, 37.71492682154414],
              [126.875473095658009, 37.714939020874361],
              [126.875694175893315, 37.714951269000878],
              [126.876108670191755, 37.71512547109964],
              [126.876793691363446, 37.714975191899832],
              [126.876787424978431, 37.715043057028609],
              [126.87695869981664, 37.715003222601581],
              [126.876935455390964, 37.715057418724712],
              [126.876924479764, 37.715100856629931],
              [126.876936688255, 37.71506302604385],
              [126.877032460139205, 37.714992194448911],
              [126.877151717603013, 37.714889818063078],
              [126.877298984797719, 37.71476339355425],
              [126.877614703973947, 37.71484784243021],
              [126.877756652746996, 37.714885810817151],
              [126.877639232804967, 37.715180953861008],
              [126.877323901621963, 37.715558623866642],
              [126.87720954114657, 37.715606541479318],
              [126.877244269608511, 37.715647410518201],
              [126.877288218637517, 37.7156876486534],
              [126.877364189712353, 37.715740486898532],
              [126.877464313988114, 37.715791336335165],
              [126.877532443851862, 37.715832940473994],
              [126.8775744758439, 37.715867053825008],
              [126.877696470135106, 37.71589835171914],
              [126.87773551349531, 37.7158607257889],
              [126.877805399025618, 37.715793360421664],
              [126.877836056926853, 37.715753366599493],
              [126.877820484879805, 37.71574707793112],
              [126.877790826638034, 37.715735101498609],
              [126.877881357773077, 37.715585851947296],
              [126.877999497590906, 37.715583073707755],
              [126.878077639686012, 37.715610786932693],
              [126.878218881614941, 37.715670813550446],
              [126.878258246127587, 37.715716225961792],
              [126.878204315769793, 37.715761613235593],
              [126.878020825575135, 37.715813012049928],
              [126.877996640619344, 37.715931590118643],
              [126.877973862782213, 37.715987166118524],
              [126.877922357563534, 37.716118211292255],
              [126.877953976867133, 37.716222024527454],
              [126.877919557436996, 37.71634413221993],
              [126.877825005917742, 37.716375837443387],
              [126.877824246749611, 37.71637964962602],
              [126.877820593747785, 37.716394790550808],
              [126.877816337970231, 37.716409830951797],
              [126.877812609305451, 37.716489915189896],
              [126.877814792283957, 37.716521936189437],
              [126.877793740833184, 37.716598019078226],
              [126.877768117979087, 37.716639642003543],
              [126.877841796061986, 37.716719898607586],
              [126.877850216600223, 37.716818066144853],
              [126.877816743066816, 37.716814940376722],
              [126.877848190748026, 37.716871860650798],
              [126.877887641311659, 37.71694339388457],
              [126.877924827492777, 37.717028737799183],
              [126.877960744532302, 37.717111181767898],
              [126.877962012578237, 37.717114087103262],
              [126.877993652724527, 37.717197956015021],
              [126.87802915391255, 37.717278180520132],
              [126.878084426874253, 37.717350574094588],
              [126.878144568244039, 37.717420300178155],
              [126.878216619957783, 37.71759319913965],
              [126.878272861801122, 37.71764311611205],
              [126.878398149589415, 37.717749661115306],
              [126.878523428599422, 37.717856213149076],
              [126.878674946387875, 37.71799035393223],
              [126.878753106353372, 37.718059873621065],
              [126.878788835095406, 37.718091660359832],
              [126.878788820025122, 37.718095367408843],
              [126.878788676034233, 37.718128372981745],
              [126.878788643966899, 37.718136017807403],
              [126.878829659728638, 37.718136741365171],
              [126.878926764660733, 37.718139175475656],
              [126.878940211851742, 37.718123206428118],
              [126.87895154868481, 37.718124353141171],
              [126.878991737642721, 37.718128432407333],
              [126.879016753421183, 37.718130972502259],
              [126.879014961388492, 37.718121917417328],
              [126.878947767026872, 37.717782245384342],
              [126.878859283330613, 37.717596238927349],
              [126.878812160777557, 37.71695351086462],
              [126.879042201745463, 37.716674884487091],
              [126.879586036680763, 37.716478985813424],
              [126.879935974203917, 37.716103596384634],
              [126.879937248424497, 37.716102228573014],
              [126.879941769922695, 37.716104669693976],
              [126.880150303249863, 37.716217296520753],
              [126.880735514328748, 37.716241700713624],
              [126.881207787884065, 37.715909617590263],
              [126.881422574695051, 37.715706417223188],
              [126.881513120781847, 37.715668182359735],
              [126.88156373381581, 37.71564681077016],
              [126.881602206246143, 37.715639674925491],
              [126.881805679264531, 37.715668196234581],
              [126.882016079069203, 37.715697689072023],
              [126.882131406307465, 37.715717446588648],
              [126.882670190789199, 37.715798995728946],
              [126.88288374242039, 37.716140053001624],
              [126.883020541605305, 37.716411047895946],
              [126.882012012186237, 37.71730516691958],
              [126.882016150084681, 37.717319929699613],
              [126.883736686232652, 37.718476081982168],
              [126.883414611458235, 37.71938613711508],
              [126.883265601787187, 37.719807172646497],
              [126.883211103495697, 37.719961157421025],
              [126.882762248901159, 37.720021875387005],
              [126.882813987383003, 37.720195308698067],
              [126.882741768236414, 37.720346487495618],
              [126.882421757674791, 37.720765445688016],
              [126.882419072221765, 37.720768958483582],
              [126.882428665926966, 37.720768004670475],
              [126.882813980787262, 37.721084205381011],
              [126.882859681177621, 37.721121706075252],
              [126.883088778294038, 37.721562330849594],
              [126.883214312914376, 37.721649488227094],
              [126.883500257643291, 37.721970877501413],
              [126.883527375108542, 37.722001358621178],
              [126.883793666088309, 37.722308222386943],
              [126.88380020069792, 37.722315911448639],
              [126.884077722347328, 37.722642312579907],
              [126.884252764095351, 37.722842950324626],
              [126.884478383491839, 37.723123373492825],
              [126.884648728549976, 37.723425097015685],
              [126.88485535712816, 37.723810463664122],
              [126.885077997089354, 37.7243294574046],
              [126.885177655596351, 37.724577052206456],
              [126.885281723366262, 37.724798545174643],
              [126.885288107469336, 37.724812132500531],
              [126.885295401507136, 37.724827657900185],
              [126.885384374058816, 37.724829079001999],
              [126.885762463394158, 37.725021752496609],
              [126.886041744719265, 37.72504651364256],
              [126.886297175446629, 37.724908807976391],
              [126.886481646769383, 37.724820577228982],
              [126.886632173348588, 37.724548749327695],
              [126.88696193064736, 37.7241357531991],
              [126.887177958589334, 37.723683227963143],
              [126.887342720826865, 37.723495870829531],
              [126.887573962583616, 37.723524816761881],
              [126.888332940882648, 37.723472895210001],
              [126.888533990398585, 37.722964891885667],
              [126.888644766593146, 37.723058259953099],
              [126.888696335119789, 37.723101725809357],
              [126.889135831360207, 37.723472158719822],
              [126.889516188290585, 37.723792743625573],
              [126.890104017790975, 37.724299531846242],
              [126.890402462889469, 37.724663299507618],
              [126.890755224711583, 37.725212930564773],
              [126.891249249159074, 37.725402317144514],
              [126.891635240347924, 37.725658324918683],
              [126.892298019719362, 37.725592774452423],
              [126.892454888229949, 37.725582224577472],
              [126.89245408657284, 37.725650077168034],
              [126.892578071630581, 37.725701150300587],
              [126.892586190297109, 37.725704687448754],
              [126.892753605840156, 37.72577761492726],
              [126.89313184732886, 37.726007429717107],
              [126.893353397056728, 37.726002839443787],
              [126.894023874333641, 37.726986916823058],
              [126.894111601380345, 37.727114258152291],
              [126.895219702101443, 37.726990253796302],
              [126.896860317766979, 37.727173040104695],
              [126.897069643569466, 37.727047370097353],
              [126.897336604952358, 37.726887685659605],
              [126.899275258617493, 37.72688121994824],
              [126.900034718707246, 37.727182476121428],
              [126.900144500137671, 37.727226021418112],
              [126.900398125989923, 37.727466121684749],
              [126.900875911032784, 37.727918417390434],
              [126.901819321286823, 37.728097819113366],
              [126.901922961265612, 37.728117526847832],
              [126.902640772479998, 37.72866574624652],
              [126.902652037759211, 37.728718571065471],
              [126.902717217114329, 37.729024222307899],
              [126.901983793653685, 37.730339574463393],
              [126.902128279174008, 37.731463736428381],
              [126.902166234016619, 37.731759028798322],
              [126.902671196312539, 37.732186570352418],
              [126.902754406672159, 37.732257020830829],
              [126.902939929211456, 37.732564625315121],
              [126.903054175811832, 37.732761814865256],
              [126.90314397203052, 37.732944614432121],
              [126.903056561219245, 37.733280175263403],
              [126.903026209877808, 37.733396681920375],
              [126.902686369092308, 37.733991578210841],
              [126.90265908981327, 37.734039332432552],
              [126.902373992410148, 37.734538389452872],
              [126.901274596098759, 37.735623960715813],
              [126.901204912338216, 37.736557523664878],
              [126.901127429214256, 37.736706963325751],
              [126.901041936756286, 37.736874131715922],
              [126.900994073235381, 37.737008393068621],
              [126.900934322662152, 37.737170209464175],
              [126.900918102336348, 37.737214134773389],
              [126.901592438988075, 37.737725636560263],
              [126.901597396793022, 37.737729395889083],
              [126.901975242379066, 37.738447385204289],
              [126.901880120912395, 37.738731902829215],
              [126.901798515714816, 37.738975988249486],
              [126.902578234049898, 37.739927305037988],
              [126.902799796447553, 37.740197626104113],
              [126.903044814366567, 37.740496557986887],
              [126.903533422437405, 37.740733476813062],
              [126.90352480359094, 37.741202464351929],
              [126.903517568751383, 37.741596133967406],
              [126.903747256686444, 37.741699112494175],
              [126.904009674635816, 37.741816760070932],
              [126.905727383944068, 37.742586835129991],
              [126.905766013076885, 37.74260415310998],
              [126.905883866218446, 37.742656985752234],
              [126.906804481187393, 37.743615279212491],
              [126.907884473890789, 37.744033116251266],
              [126.907901562684131, 37.744113657267505],
              [126.90792095797832, 37.744188348601497],
              [126.908118387690536, 37.74494862317863],
              [126.908285774527684, 37.7451288716627],
              [126.908727780514994, 37.745604833710566],
              [126.909220345698088, 37.746686372785732],
              [126.909362975124665, 37.746900741142966],
              [126.910424524677978, 37.746644235153873],
              [126.910943741644289, 37.746539898079654],
              [126.911415945733779, 37.747024176782382],
              [126.911699482797871, 37.747314961916217],
              [126.911731684894463, 37.74734798645634],
              [126.911762709550644, 37.747354243213707],
              [126.912303302561725, 37.747463293559051],
              [126.912390560588108, 37.747431825608011],
              [126.912442143331646, 37.747390943960553],
              [126.912469709156639, 37.747369097356831],
              [126.912717910050503, 37.747223726141357],
              [126.912817091960363, 37.747159327285466],
              [126.913028372387032, 37.747043767293206],
              [126.914493808183195, 37.747210973219538],
              [126.914728335210071, 37.747387674908246],
              [126.915225843635042, 37.747806148925406],
              [126.915416547269785, 37.747861750793959],
              [126.915678086395701, 37.747673019457977],
              [126.916654932457234, 37.74792790698519],
              [126.917056621568378, 37.74803271572776],
              [126.91793723087352, 37.747864691917236],
              [126.917940637942166, 37.747833161748432],
              [126.91806340055345, 37.747756666582482],
              [126.918109761591424, 37.747720525533317],
              [126.918203049988207, 37.747647802726682],
              [126.918302767682434, 37.747572139032208],
              [126.918979632530977, 37.747282901122887],
              [126.919683760971694, 37.747010284031234],
              [126.920218959094925, 37.747027827508226],
              [126.921612266470007, 37.747017805789795],
              [126.922350650173868, 37.747020255191281],
              [126.922889442525374, 37.747022040119418],
              [126.923653182291133, 37.747373357612275],
              [126.92446830650907, 37.74758111411424],
              [126.925419990017076, 37.748075680982701],
              [126.925669859528639, 37.748205529411059],
              [126.925798210268113, 37.748210353112469],
              [126.926313416547899, 37.748229716587851],
              [126.926637829045859, 37.748241907297484],
              [126.927070031860339, 37.748273426214048],
              [126.928284495364139, 37.748420314708213],
              [126.92834194321901, 37.748415277386556],
              [126.928566380735617, 37.748424147713322],
              [126.928989160020407, 37.748480717397825],
              [126.929067177345146, 37.748476540085839],
              [126.929096420734012, 37.748485009009158]
            ]
          ]
        ]
      },
      id: '31101590'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '관산동',
        ADM_CD: '31101600'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.858910243999475, 37.734173696344747],
              [126.858897937773207, 37.734073352544655],
              [126.858810062672319, 37.733356800195708],
              [126.859066698113807, 37.733327305949594],
              [126.859359291057913, 37.73329367664563],
              [126.860080377366785, 37.733210798399405],
              [126.860170465221671, 37.733097294291646],
              [126.860294478549406, 37.73294104631065],
              [126.860518448703829, 37.732886697172212],
              [126.860643496419016, 37.732843762565608],
              [126.860661016396307, 37.732837697991535],
              [126.861127359665261, 37.732676257028913],
              [126.861481177153763, 37.732757205170856],
              [126.861953178163333, 37.73258010505765],
              [126.862290445492604, 37.732698475801428],
              [126.862506237464729, 37.732641666708091],
              [126.862827069284322, 37.732557206610309],
              [126.863238951208714, 37.732629118471699],
              [126.863299467973363, 37.732639686121431],
              [126.863548326751896, 37.732742749052299],
              [126.864151773333518, 37.732600985554839],
              [126.864944910121608, 37.732635972621438],
              [126.865086091059027, 37.732340969550584],
              [126.865279423827673, 37.731936987651189],
              [126.865367601355231, 37.731892886869872],
              [126.865446163350924, 37.73186259832147],
              [126.865617348763209, 37.731796602311142],
              [126.865725018486543, 37.731680345756956],
              [126.865754096664915, 37.73164894709965],
              [126.865772724416232, 37.731649168100567],
              [126.86625694417944, 37.731654876491099],
              [126.866272675569192, 37.731583488412006],
              [126.866442276729344, 37.73081381705336],
              [126.867084161683081, 37.729993268145364],
              [126.867566176624109, 37.729671067168333],
              [126.867761735391184, 37.729540345347914],
              [126.867787615533203, 37.729523043653074],
              [126.86791840150741, 37.729435619570758],
              [126.867982669990269, 37.729077840011264],
              [126.867917059088143, 37.728698512970773],
              [126.867978305394871, 37.728448565243177],
              [126.868063755787034, 37.728478328795333],
              [126.868326485378546, 37.728569839856441],
              [126.868878492771572, 37.728762107473891],
              [126.869477778800629, 37.729142310401727],
              [126.869915721708765, 37.729163071364439],
              [126.869935210935495, 37.729168158017956],
              [126.870099715249609, 37.729252805629365],
              [126.870330454328112, 37.729271650101666],
              [126.871541728886044, 37.729381666328862],
              [126.871571325947514, 37.729485598239059],
              [126.871714750331122, 37.729670164875337],
              [126.871747824512838, 37.729710466013131],
              [126.872186122098967, 37.730062557463313],
              [126.87223952215659, 37.73010545615174],
              [126.872280080241538, 37.729920802852],
              [126.872326510509694, 37.729718706050065],
              [126.872475336194512, 37.729444632517506],
              [126.873000863848162, 37.729244462938006],
              [126.873021663770857, 37.728799356770025],
              [126.873255663611303, 37.728060543307173],
              [126.873186750944839, 37.727831279965351],
              [126.873068151897996, 37.727431628544366],
              [126.873005464014369, 37.726822871107011],
              [126.873002966488869, 37.726798630388927],
              [126.873197565547699, 37.726604847106771],
              [126.873405981829904, 37.72639730350312],
              [126.873567873236993, 37.726117359961648],
              [126.87365770887358, 37.725962013776844],
              [126.874366669599112, 37.725950392944192],
              [126.874781262481633, 37.725878662764586],
              [126.87494888427257, 37.72584966031858],
              [126.875164360070855, 37.725548060541513],
              [126.875294605345161, 37.725365754305237],
              [126.876463934525162, 37.725400796248607],
              [126.877322836193741, 37.725715068847713],
              [126.877633621465648, 37.726188400671809],
              [126.878831360107469, 37.72588445163418],
              [126.879782562296668, 37.726118283087722],
              [126.880754820866002, 37.725606576349364],
              [126.881159544432407, 37.725680810738993],
              [126.882142526958802, 37.725861102044931],
              [126.882690145192981, 37.725555606315972],
              [126.883182719737007, 37.725419550988306],
              [126.88363674449721, 37.725006969282589],
              [126.883867938228789, 37.725061824343207],
              [126.884591747753674, 37.725300175164946],
              [126.885020046909162, 37.72478677104619],
              [126.885252685199617, 37.724826975481697],
              [126.885295401507136, 37.724827657900185],
              [126.885288107469336, 37.724812132500531],
              [126.885281723366262, 37.724798545174643],
              [126.885177655596351, 37.724577052206456],
              [126.885077997089354, 37.7243294574046],
              [126.88485535712816, 37.723810463664122],
              [126.884648728549976, 37.723425097015685],
              [126.884478383491839, 37.723123373492825],
              [126.884252764095351, 37.722842950324626],
              [126.884077722347328, 37.722642312579907],
              [126.88380020069792, 37.722315911448639],
              [126.883793666088309, 37.722308222386943],
              [126.883527375108542, 37.722001358621178],
              [126.883500257643291, 37.721970877501413],
              [126.883214312914376, 37.721649488227094],
              [126.883088778294038, 37.721562330849594],
              [126.882859681177621, 37.721121706075252],
              [126.882813980787262, 37.721084205381011],
              [126.882428665926966, 37.720768004670475],
              [126.882419072221765, 37.720768958483582],
              [126.882421757674791, 37.720765445688016],
              [126.882741768236414, 37.720346487495618],
              [126.882813987383003, 37.720195308698067],
              [126.882762248901159, 37.720021875387005],
              [126.883211103495697, 37.719961157421025],
              [126.883265601787187, 37.719807172646497],
              [126.883414611458235, 37.71938613711508],
              [126.883736686232652, 37.718476081982168],
              [126.882016150084681, 37.717319929699613],
              [126.882012012186237, 37.71730516691958],
              [126.883020541605305, 37.716411047895946],
              [126.88288374242039, 37.716140053001624],
              [126.882670190789199, 37.715798995728946],
              [126.882131406307465, 37.715717446588648],
              [126.882016079069203, 37.715697689072023],
              [126.881805679264531, 37.715668196234581],
              [126.881602206246143, 37.715639674925491],
              [126.88156373381581, 37.71564681077016],
              [126.881513120781847, 37.715668182359735],
              [126.881422574695051, 37.715706417223188],
              [126.881207787884065, 37.715909617590263],
              [126.880735514328748, 37.716241700713624],
              [126.880150303249863, 37.716217296520753],
              [126.879941769922695, 37.716104669693976],
              [126.879937248424497, 37.716102228573014],
              [126.879935974203917, 37.716103596384634],
              [126.879586036680763, 37.716478985813424],
              [126.879042201745463, 37.716674884487091],
              [126.878812160777557, 37.71695351086462],
              [126.878859283330613, 37.717596238927349],
              [126.878947767026872, 37.717782245384342],
              [126.879014961388492, 37.718121917417328],
              [126.879016753421183, 37.718130972502259],
              [126.878991737642721, 37.718128432407333],
              [126.87895154868481, 37.718124353141171],
              [126.878940211851742, 37.718123206428118],
              [126.878926764660733, 37.718139175475656],
              [126.878829659728638, 37.718136741365171],
              [126.878788643966899, 37.718136017807403],
              [126.878788676034233, 37.718128372981745],
              [126.878788820025122, 37.718095367408843],
              [126.878788835095406, 37.718091660359832],
              [126.878753106353372, 37.718059873621065],
              [126.878674946387875, 37.71799035393223],
              [126.878523428599422, 37.717856213149076],
              [126.878398149589415, 37.717749661115306],
              [126.878272861801122, 37.71764311611205],
              [126.878216619957783, 37.71759319913965],
              [126.878144568244039, 37.717420300178155],
              [126.878084426874253, 37.717350574094588],
              [126.87802915391255, 37.717278180520132],
              [126.877993652724527, 37.717197956015021],
              [126.877962012578237, 37.717114087103262],
              [126.877960744532302, 37.717111181767898],
              [126.877924827492777, 37.717028737799183],
              [126.877887641311659, 37.71694339388457],
              [126.877848190748026, 37.716871860650798],
              [126.877816743066816, 37.716814940376722],
              [126.877850216600223, 37.716818066144853],
              [126.877841796061986, 37.716719898607586],
              [126.877768117979087, 37.716639642003543],
              [126.877793740833184, 37.716598019078226],
              [126.877814792283957, 37.716521936189437],
              [126.877812609305451, 37.716489915189896],
              [126.877816337970231, 37.716409830951797],
              [126.877820593747785, 37.716394790550808],
              [126.877824246749611, 37.71637964962602],
              [126.877825005917742, 37.716375837443387],
              [126.877919557436996, 37.71634413221993],
              [126.877953976867133, 37.716222024527454],
              [126.877922357563534, 37.716118211292255],
              [126.877973862782213, 37.715987166118524],
              [126.877996640619344, 37.715931590118643],
              [126.878020825575135, 37.715813012049928],
              [126.878204315769793, 37.715761613235593],
              [126.878258246127587, 37.715716225961792],
              [126.878218881614941, 37.715670813550446],
              [126.878077639686012, 37.715610786932693],
              [126.877999497590906, 37.715583073707755],
              [126.877881357773077, 37.715585851947296],
              [126.877790826638034, 37.715735101498609],
              [126.877820484879805, 37.71574707793112],
              [126.877836056926853, 37.715753366599493],
              [126.877805399025618, 37.715793360421664],
              [126.87773551349531, 37.7158607257889],
              [126.877696470135106, 37.71589835171914],
              [126.8775744758439, 37.715867053825008],
              [126.877532443851862, 37.715832940473994],
              [126.877464313988114, 37.715791336335165],
              [126.877364189712353, 37.715740486898532],
              [126.877288218637517, 37.7156876486534],
              [126.877244269608511, 37.715647410518201],
              [126.87720954114657, 37.715606541479318],
              [126.877323901621963, 37.715558623866642],
              [126.877639232804967, 37.715180953861008],
              [126.877756652746996, 37.714885810817151],
              [126.877614703973947, 37.71484784243021],
              [126.877298984797719, 37.71476339355425],
              [126.877151717603013, 37.714889818063078],
              [126.877032460139205, 37.714992194448911],
              [126.876936688255, 37.71506302604385],
              [126.876924479764, 37.715100856629931],
              [126.876935455390964, 37.715057418724712],
              [126.87695869981664, 37.715003222601581],
              [126.876787424978431, 37.715043057028609],
              [126.876793691363446, 37.714975191899832],
              [126.876108670191755, 37.71512547109964],
              [126.875694175893315, 37.714951269000878],
              [126.875473095658009, 37.714939020874361],
              [126.875222182135204, 37.71492682154414],
              [126.875544167611224, 37.714463583197194],
              [126.875275049611858, 37.714448473639536],
              [126.875600587439209, 37.713976022593407],
              [126.87537803077997, 37.713965648434581],
              [126.874743949194425, 37.714072643232178],
              [126.874553653277289, 37.714365242538506],
              [126.87452468928899, 37.714385529020831],
              [126.874270785404704, 37.71455821403481],
              [126.874078745058839, 37.714683004514988],
              [126.874004621557731, 37.714733542765359],
              [126.873864057925971, 37.714825828795178],
              [126.873697898691205, 37.714168844654978],
              [126.873691472892972, 37.714133745558676],
              [126.873716034515567, 37.714082091583734],
              [126.873718932700427, 37.714072735051573],
              [126.873792592330958, 37.713710175468918],
              [126.873913561003974, 37.713603732058857],
              [126.874268508534485, 37.713291380112246],
              [126.87462656590192, 37.713188715926208],
              [126.874739623211042, 37.713156292986724],
              [126.875312503555648, 37.713268717629354],
              [126.875716540393569, 37.713147739157215],
              [126.875903066113551, 37.713091885802456],
              [126.876224568957824, 37.7129956154997],
              [126.876170887068724, 37.712634713136872],
              [126.876163977532329, 37.712588307240871],
              [126.875876770817271, 37.712154464299005],
              [126.875255600346165, 37.712101375536854],
              [126.875111285902321, 37.711881285189641],
              [126.874894277772754, 37.711187417627464],
              [126.874517368122127, 37.710816946147915],
              [126.874359540028465, 37.710661809844794],
              [126.874265358596617, 37.710569236517415],
              [126.874311859984502, 37.710487262118235],
              [126.874372756133241, 37.710379913488282],
              [126.874455825897797, 37.710233475015642],
              [126.874596211756781, 37.709985992400043],
              [126.874511607595451, 37.7096030536539],
              [126.874932693829948, 37.708957811166471],
              [126.87437676469392, 37.708695067619637],
              [126.874358063076841, 37.708523215345892],
              [126.874318800026131, 37.708158670473559],
              [126.874275808250601, 37.707755135354795],
              [126.8742273592744, 37.707690347232898],
              [126.874171022158393, 37.70761573247848],
              [126.874108410378227, 37.707530053117758],
              [126.874086125875081, 37.707499560294835],
              [126.874080873559549, 37.70749237321774],
              [126.874059981324436, 37.70746144703476],
              [126.873949606384286, 37.707308704476546],
              [126.873631311756625, 37.707154564500549],
              [126.873553469491952, 37.707116874096336],
              [126.873533758348628, 37.707104437489555],
              [126.873512231990361, 37.707092413052777],
              [126.873500378635867, 37.707100680080707],
              [126.873248783729096, 37.706988822031363],
              [126.873200228863539, 37.706881274240182],
              [126.87289421638603, 37.70620361280141],
              [126.87285134697315, 37.705871406487582],
              [126.872867831610691, 37.705860379072895],
              [126.873041378274166, 37.705782238103467],
              [126.873219606094395, 37.705438357210454],
              [126.873240497307364, 37.705429152204722],
              [126.873274155234768, 37.705414329520302],
              [126.873525015109692, 37.705303846532196],
              [126.87352851360798, 37.705285532240772],
              [126.87358529739889, 37.704988149957494],
              [126.873604534282123, 37.704965078277617],
              [126.874070019579378, 37.704406776806628],
              [126.873999980596054, 37.704008240701704],
              [126.873991003537057, 37.703957162907209],
              [126.874035745638238, 37.70393971380949],
              [126.87405460717234, 37.703932358208078],
              [126.874707642866113, 37.703677681014206],
              [126.875463166528291, 37.703569267894579],
              [126.875620225246124, 37.7035467302181],
              [126.876234574893545, 37.703514670770652],
              [126.876572112406322, 37.703564306211995],
              [126.87663620752555, 37.703573731314265],
              [126.876507644199577, 37.703698689519335],
              [126.876566812448658, 37.703753915417082],
              [126.876860550100616, 37.704028077471683],
              [126.877042084230908, 37.704802424020237],
              [126.877127248698343, 37.704889589213813],
              [126.877127867603846, 37.704889513167004],
              [126.877979882740661, 37.704782204764626],
              [126.877971344502527, 37.704813209355684],
              [126.878058214962877, 37.704827492086139],
              [126.878106507156758, 37.704816729050542],
              [126.878260832032737, 37.704861754557065],
              [126.878614009638795, 37.705065338492744],
              [126.87872115090542, 37.70505812648517],
              [126.878998324680879, 37.705039469139074],
              [126.878998632711131, 37.705039261654768],
              [126.878999287626726, 37.705038812640559],
              [126.879081673096735, 37.704982640808616],
              [126.879097354029952, 37.704971949885767],
              [126.879352217754544, 37.704798173000263],
              [126.879375551933322, 37.704782263111895],
              [126.879475183454304, 37.704723123590902],
              [126.879478395056154, 37.704721217071942],
              [126.879955714552139, 37.704437726960585],
              [126.880202130153677, 37.70437029762958],
              [126.880272750539888, 37.704350975848001],
              [126.881634923335326, 37.703913407145599],
              [126.882975591625367, 37.703482729580863],
              [126.883400527699933, 37.703431619480938],
              [126.883853313622126, 37.703377158955497],
              [126.885462392905595, 37.703233610533431],
              [126.886338623036025, 37.703287032110538],
              [126.886444239481619, 37.703293480899148],
              [126.887552187401454, 37.703361032978968],
              [126.889014503454419, 37.703536625097051],
              [126.889218015247181, 37.703530346322509],
              [126.889319221912331, 37.703527226491936],
              [126.889672562106213, 37.703480811805171],
              [126.889679467909417, 37.703479908372962],
              [126.889684937131037, 37.703479193091432],
              [126.889685203061333, 37.703478858274615],
              [126.889782614060053, 37.703353532415036],
              [126.889790075843123, 37.703127476075053],
              [126.889859135498696, 37.701031066582232],
              [126.889972143742796, 37.700976098257158],
              [126.89013280478207, 37.700898010407457],
              [126.890476542649537, 37.700730980820097],
              [126.891399972793423, 37.700281939042547],
              [126.890993380486407, 37.698364431531729],
              [126.89240949587095, 37.697565712249663],
              [126.892555307697393, 37.697382383481695],
              [126.892559424187056, 37.697343175481585],
              [126.891771072636331, 37.697148998213457],
              [126.891543510019815, 37.697111609915467],
              [126.891168484988256, 37.696995002375971],
              [126.891065753946194, 37.697018614758157],
              [126.890743325417191, 37.696971683890141],
              [126.890695573681455, 37.696959941674194],
              [126.890664572717441, 37.696813729211115],
              [126.890660198548929, 37.69679310866033],
              [126.890647712940478, 37.696734230974876],
              [126.890349848770995, 37.696653865188438],
              [126.890175002052558, 37.69662660456796],
              [126.889526687740812, 37.696598902476779],
              [126.889189770935715, 37.696571496144678],
              [126.888948048951718, 37.696508470666885],
              [126.888676362929885, 37.696437631709237],
              [126.888683944832479, 37.696382904796053],
              [126.888806053096161, 37.695505786489342],
              [126.888951833155815, 37.694458658502633],
              [126.8889982044074, 37.693392435200842],
              [126.888868667275659, 37.693042747840394],
              [126.888805053083431, 37.692870897071913],
              [126.888708586521076, 37.692683036096554],
              [126.888537263438636, 37.692349436671982],
              [126.888211329760239, 37.691663387822445],
              [126.887957284260125, 37.691128651425188],
              [126.887879198495725, 37.690707358303072],
              [126.887878640422755, 37.690704326956372],
              [126.887877554700339, 37.690698426659232],
              [126.887842820964181, 37.690510970079494],
              [126.887799592564221, 37.69027765326171],
              [126.887706828149504, 37.689776937468203],
              [126.887674387906074, 37.689601842669802],
              [126.88757023505832, 37.68941218409195],
              [126.887100043721034, 37.689350505310308],
              [126.886660348381696, 37.689292828323495],
              [126.886576039410571, 37.689277864046517],
              [126.88625764261316, 37.689221349155019],
              [126.884826081188379, 37.688805287926222],
              [126.884672668801002, 37.688662821846279],
              [126.884647276295695, 37.688639238685191],
              [126.884505372820016, 37.687511609818742],
              [126.884468225332071, 37.687216415953898],
              [126.884468029540486, 37.687214855640129],
              [126.88448354474086, 37.686823907686687],
              [126.88450821378369, 37.686717428646155],
              [126.884207340506919, 37.686597320197045],
              [126.883559538007262, 37.686403785033754],
              [126.883343784971331, 37.686101329720678],
              [126.883087907165603, 37.685715664702968],
              [126.882919773002243, 37.685214818285914],
              [126.882877812328445, 37.6850592950099],
              [126.883065864471376, 37.685019751758574],
              [126.884086713833511, 37.684856859093088],
              [126.884394034395172, 37.684704847303657],
              [126.884713208140056, 37.684615515211853],
              [126.884703386185478, 37.684613502682751],
              [126.88464427637571, 37.684513437397925],
              [126.884644029849142, 37.684513076482816],
              [126.884636894640423, 37.684501045317255],
              [126.884625746107488, 37.68448224498772],
              [126.884589894749539, 37.684421488853609],
              [126.884488896365454, 37.684250324675645],
              [126.884467807770051, 37.684214587925261],
              [126.884447192592077, 37.68417961796581],
              [126.884378247154572, 37.684228580391995],
              [126.884353228948569, 37.684246348304001],
              [126.884058413590509, 37.684377146835828],
              [126.88370646781317, 37.684533294184966],
              [126.883188147059897, 37.684567946057641],
              [126.882832756775713, 37.6845917005651],
              [126.881749607676127, 37.684822156208817],
              [126.881063191307007, 37.685012731673893],
              [126.880685390022364, 37.685116138340859],
              [126.880429278640946, 37.685193357954454],
              [126.879659746178305, 37.685424170707286],
              [126.879646759572935, 37.685428064616893],
              [126.879295080692586, 37.685501220561285],
              [126.879283763156977, 37.685503524265563],
              [126.879065710146378, 37.685549544470064],
              [126.878652322538429, 37.685746264245623],
              [126.878072564201631, 37.686023941943667],
              [126.877071370806561, 37.686381772434792],
              [126.876834894378533, 37.68641893931229],
              [126.876463845856307, 37.686478805249948],
              [126.876078281490294, 37.686539788374986],
              [126.875390582212532, 37.686886764941391],
              [126.875269702042374, 37.686950154712463],
              [126.874483092056948, 37.687172500361143],
              [126.874384885227556, 37.687200755082742],
              [126.874371111237764, 37.687204611783208],
              [126.874316956571306, 37.68721977496844],
              [126.873680818593428, 37.687402179051539],
              [126.873680174906028, 37.687398071002242],
              [126.873678998055297, 37.687396402709979],
              [126.873421700081138, 37.687032114070817],
              [126.873347777465696, 37.686957832513279],
              [126.873270579990233, 37.686880261697404],
              [126.873065879949948, 37.686674573912569],
              [126.872556011675385, 37.686261473012699],
              [126.872436097958811, 37.686181735615243],
              [126.871976597625334, 37.685876186673816],
              [126.871489734277617, 37.68556336604086],
              [126.871456836667832, 37.685541400286013],
              [126.870939220262642, 37.685407663461099],
              [126.870663309712512, 37.685172367974161],
              [126.87041213236644, 37.68516805290654],
              [126.87010714100181, 37.685166326906895],
              [126.870104872981855, 37.685195263530474],
              [126.870098409905694, 37.685277692982105],
              [126.869902423577898, 37.685276645981482],
              [126.86990319866932, 37.685185025089112],
              [126.869903366609407, 37.68516517341488],
              [126.869678756852821, 37.685163900998326],
              [126.869074048027514, 37.685161781355475],
              [126.867818948609226, 37.685155537517204],
              [126.86724475302232, 37.685152056034653],
              [126.867232010212831, 37.685152013799893],
              [126.866337410239836, 37.685149118369495],
              [126.866082829503526, 37.685148294563469],
              [126.866157123294172, 37.685377136330949],
              [126.866056202413176, 37.685372664083616],
              [126.865900155891623, 37.685365748398631],
              [126.865717615366265, 37.685358892809191],
              [126.865670012565559, 37.685357105416387],
              [126.865584263464697, 37.685354325940104],
              [126.865442290126722, 37.685349726049616],
              [126.86494179456443, 37.685351255490588],
              [126.864752911084608, 37.685355152199307],
              [126.864552134497856, 37.68537346339204],
              [126.864361756387694, 37.685359790020669],
              [126.864094602431109, 37.685306698468246],
              [126.864000125611469, 37.68528792274838],
              [126.863582862731022, 37.685243522149435],
              [126.863334600107919, 37.685185901625019],
              [126.863150341621733, 37.685143151976987],
              [126.862792344477938, 37.684948275293934],
              [126.862662899871353, 37.684863341643577],
              [126.862620485616887, 37.684835504853233],
              [126.862369794220029, 37.684721366966414],
              [126.86213355050667, 37.6846138061426],
              [126.861948590676263, 37.684543553229567],
              [126.861820424888052, 37.684494873226143],
              [126.861792692743194, 37.684484338993528],
              [126.861547125315866, 37.684423412425708],
              [126.861226371368573, 37.684341338649105],
              [126.860981962585825, 37.684321155231039],
              [126.860375474365739, 37.684412465798616],
              [126.859946831105106, 37.684520473931293],
              [126.859500553502315, 37.684630026035478],
              [126.859076034509258, 37.684741630744071],
              [126.858917354107959, 37.68478217235635],
              [126.858776876610506, 37.684820049272219],
              [126.858599975119219, 37.68498620894502],
              [126.858417352903658, 37.685054335371383],
              [126.857914051354626, 37.68524135003463],
              [126.857681425559932, 37.68532714480051],
              [126.857484292414696, 37.685400684302962],
              [126.857287261797012, 37.685438771361596],
              [126.856881961128138, 37.68551711847774],
              [126.855043666074849, 37.686002891792874],
              [126.853631214483173, 37.687626332210257],
              [126.853839053960314, 37.687963361070217],
              [126.853772466291915, 37.688438088174578],
              [126.853747743956063, 37.688614196528029],
              [126.853736851941989, 37.688675539477693],
              [126.853659672981792, 37.689117457936838],
              [126.853655541476272, 37.689141329367182],
              [126.853659962466793, 37.689257648611452],
              [126.853703346250384, 37.689391137089345],
              [126.853715609292806, 37.689426678570385],
              [126.853876973031049, 37.689702147273749],
              [126.853431481773526, 37.689823648238495],
              [126.853431771028184, 37.689824036492034],
              [126.853733909403005, 37.690236619368797],
              [126.853952411000051, 37.6905349864128],
              [126.854251656372199, 37.690943604291554],
              [126.854351598340145, 37.691080075526394],
              [126.85467191325462, 37.691517461628571],
              [126.854805191913485, 37.691603667867447],
              [126.854805321357787, 37.691653134070236],
              [126.854890530826651, 37.69167918700397],
              [126.854975747929601, 37.691587304537819],
              [126.855074084900309, 37.691290944859851],
              [126.855083702478268, 37.691304645289087],
              [126.855222436506224, 37.691541012432317],
              [126.855598135523636, 37.692167003175726],
              [126.855745269933024, 37.692337258501254],
              [126.855771827841693, 37.692367989981769],
              [126.855923302365568, 37.692553361441497],
              [126.856070451141662, 37.69273091624644],
              [126.856023202621813, 37.693267330945723],
              [126.856008060871886, 37.693444304957751],
              [126.855992329714539, 37.693719578482572],
              [126.85598458483102, 37.693861745945206],
              [126.855972218435511, 37.694088680072497],
              [126.855889758617508, 37.694278853965244],
              [126.855678346808375, 37.69465908092041],
              [126.855414734989196, 37.694887870383042],
              [126.855263498935926, 37.69498920484812],
              [126.854935037972609, 37.695195136213783],
              [126.854707461998345, 37.695339232859986],
              [126.854277785420621, 37.695543261859122],
              [126.85373154292212, 37.695749215634621],
              [126.853576542794116, 37.695800493085407],
              [126.853385488826348, 37.695864690471851],
              [126.853311706504158, 37.695889969926533],
              [126.853243671711311, 37.695913281726916],
              [126.853128598719877, 37.695979917979685],
              [126.852890051610657, 37.696102472387473],
              [126.852660895627892, 37.696223868288818],
              [126.852490782509932, 37.696330580962943],
              [126.85211175166107, 37.696688768181907],
              [126.851907608669649, 37.696881973139497],
              [126.851696867201113, 37.697067647865595],
              [126.85129375845473, 37.697492480727647],
              [126.851135988510194, 37.697672753081363],
              [126.850809656121342, 37.698098646621027],
              [126.850664486561072, 37.698282776844174],
              [126.850644143358267, 37.698292011520444],
              [126.850572943404245, 37.698377172842498],
              [126.850572391720135, 37.698399583868301],
              [126.850362968596869, 37.698665206105922],
              [126.849896722215419, 37.699172051601181],
              [126.849314476940393, 37.699930392536643],
              [126.849242105537527, 37.700147774886794],
              [126.849018999605704, 37.700551085144774],
              [126.848995929745996, 37.700575099663034],
              [126.848662069734431, 37.700922650338484],
              [126.848346718855225, 37.701238939063963],
              [126.848144458037822, 37.701459484741882],
              [126.84803307634688, 37.701699203458105],
              [126.847999175816412, 37.701818664555972],
              [126.847978246885617, 37.701892417481375],
              [126.847885659542797, 37.702152233964661],
              [126.847754673593613, 37.702455122086654],
              [126.847790916209064, 37.702608576843829],
              [126.847806969193869, 37.702676541402447],
              [126.847912497173994, 37.702939003807273],
              [126.848087989711843, 37.703476815255286],
              [126.848106224965889, 37.703532695206079],
              [126.847966031014963, 37.703771157555366],
              [126.847946784655917, 37.703803895244668],
              [126.847672999920263, 37.704213081991313],
              [126.847399434214864, 37.704593318861683],
              [126.847272259303622, 37.704860107879085],
              [126.847039789009628, 37.70520114489571],
              [126.84699792773857, 37.705291438975735],
              [126.846804618998078, 37.705708395167179],
              [126.84655890977011, 37.706141636789788],
              [126.846163661320873, 37.707060472210543],
              [126.845994140208958, 37.707400199433586],
              [126.845943265001637, 37.707498266553799],
              [126.845836564562745, 37.707703938065222],
              [126.845784789941973, 37.707803737865092],
              [126.845633267982279, 37.7081213351332],
              [126.84551561218008, 37.708343142240217],
              [126.845301502978785, 37.708591813789802],
              [126.845027579924093, 37.708884894184315],
              [126.844724078886529, 37.709249148084687],
              [126.844154833675049, 37.709932329714228],
              [126.844032981357074, 37.710080322680042],
              [126.843888587221201, 37.710253746085314],
              [126.843814778750001, 37.710405187820982],
              [126.843837791232801, 37.710571178496117],
              [126.843860351401062, 37.710734823214949],
              [126.843866454264017, 37.710744831178424],
              [126.84377565751754, 37.711151947520143],
              [126.843732408355081, 37.711299072496203],
              [126.843547384569646, 37.711928508046256],
              [126.843521853151557, 37.712110430556315],
              [126.843537572224491, 37.712419075671122],
              [126.84353934825468, 37.712454002662042],
              [126.843515823731124, 37.712648003188029],
              [126.843478881036347, 37.712875007392789],
              [126.843439642583931, 37.713153090960212],
              [126.843401779508113, 37.713345738024877],
              [126.843396721352107, 37.713371481267885],
              [126.843372206549617, 37.713496213463273],
              [126.843338739627555, 37.713679855566589],
              [126.843258693419756, 37.714076652476066],
              [126.843190215014346, 37.714421342711667],
              [126.843185519033852, 37.714623888845161],
              [126.843110406067737, 37.71485803225206],
              [126.842949939735746, 37.715148935485949],
              [126.842948162652121, 37.715152054059153],
              [126.842808639531569, 37.715396608120379],
              [126.842381185714544, 37.715812512109295],
              [126.842161068184808, 37.715994462663026],
              [126.842003587310671, 37.716101562695492],
              [126.841961973807159, 37.716109972277138],
              [126.841575672706568, 37.716188053563229],
              [126.840849189805979, 37.71628469384973],
              [126.84058358343178, 37.716351103060418],
              [126.840664427501821, 37.716649576127367],
              [126.840718029710928, 37.71664437784743],
              [126.840755406120707, 37.716798809630887],
              [126.840831276170647, 37.717110455458943],
              [126.840863902522059, 37.717244763044803],
              [126.840870102294943, 37.717310054950765],
              [126.840906446161924, 37.717695086766447],
              [126.840912832751243, 37.717747496267904],
              [126.840941320058434, 37.718059863119414],
              [126.840931230578633, 37.718309380800115],
              [126.84091911023674, 37.718765575566159],
              [126.840888311834632, 37.718839540395457],
              [126.840627672820887, 37.719506330875873],
              [126.840552924278654, 37.719564889616649],
              [126.840577883202442, 37.719615216408236],
              [126.840591515700467, 37.719641252417738],
              [126.840649285332333, 37.71993318250054],
              [126.840662432056192, 37.720023250810279],
              [126.840688385520835, 37.720341867590264],
              [126.840872024311537, 37.720358640478295],
              [126.841056889918363, 37.720375524490528],
              [126.841185070282705, 37.720401557089779],
              [126.84079537126479, 37.721075686031888],
              [126.840765674074618, 37.72112705551239],
              [126.840664729019295, 37.721294894982094],
              [126.840465608513753, 37.721625959069073],
              [126.840259994634081, 37.721928492204171],
              [126.840170761100339, 37.722069822315312],
              [126.840021398649228, 37.722303317058802],
              [126.839943516722272, 37.722408994029259],
              [126.839455144742743, 37.723224954022335],
              [126.839300106575834, 37.723476562207082],
              [126.839179561285619, 37.723670374582973],
              [126.839172049711109, 37.723682347034767],
              [126.8391023326284, 37.723768383514773],
              [126.839047365538477, 37.723868610066937],
              [126.839042886837262, 37.723876775225449],
              [126.839041972587637, 37.723878396977028],
              [126.838926762931152, 37.723916799639738],
              [126.838681577444135, 37.72399853762137],
              [126.83866938830937, 37.724021496092618],
              [126.838574170885437, 37.724200656588003],
              [126.838370208583925, 37.724468410977551],
              [126.838334478046662, 37.724519176310523],
              [126.838308470567981, 37.724636444002307],
              [126.838218052317501, 37.724747913374621],
              [126.838183087221609, 37.724791950117478],
              [126.838087698088586, 37.724878211386482],
              [126.837874400150582, 37.724957560650338],
              [126.837804775624861, 37.724998230868763],
              [126.83757644418958, 37.72513157235359],
              [126.837366314697633, 37.725228583574562],
              [126.837239131025697, 37.725297743199732],
              [126.837260718699468, 37.725431172032316],
              [126.837273182959578, 37.725698576280919],
              [126.837221077781209, 37.725993812499823],
              [126.837212259131334, 37.726043776084367],
              [126.837076605044771, 37.726264827362066],
              [126.836921826038591, 37.726654250417894],
              [126.836883368426683, 37.726800641356895],
              [126.836941057653604, 37.726918781455311],
              [126.836922593137075, 37.727097670960745],
              [126.836921807335372, 37.72710893119536],
              [126.836869262661594, 37.727280230078016],
              [126.836808626024961, 37.727349429139736],
              [126.836762416335063, 37.727449480285038],
              [126.836759616897879, 37.727456725540655],
              [126.836768667870672, 37.727456602509157],
              [126.836888376933317, 37.727424668121536],
              [126.836902735522017, 37.727425534837792],
              [126.837037858813744, 37.727471052408269],
              [126.837133241170505, 37.727539603936329],
              [126.837148278156263, 37.727551445262151],
              [126.837174634034341, 37.727575700068584],
              [126.837234228834532, 37.727638283662564],
              [126.837232215076455, 37.727642686087712],
              [126.837218021325555, 37.727673740462436],
              [126.837210223756102, 37.727675702951963],
              [126.837171394460796, 37.727680712266455],
              [126.837172794638391, 37.72768887705363],
              [126.837191371454622, 37.727704111003661],
              [126.837310326412108, 37.727769306829323],
              [126.837344111311353, 37.727803429512491],
              [126.837381572358581, 37.727851627978822],
              [126.837433882854839, 37.727921527070407],
              [126.837479166596282, 37.727962411125084],
              [126.837525228664404, 37.727974017566666],
              [126.837573419449157, 37.727983094239853],
              [126.837619637407428, 37.728000898972709],
              [126.837836186854759, 37.728097765065833],
              [126.838005077222135, 37.728109549251869],
              [126.838036269742688, 37.728109872231627],
              [126.838087813739008, 37.728125988779958],
              [126.838110126564715, 37.728133065017147],
              [126.838178674471195, 37.728151458184293],
              [126.838183469087994, 37.728152591918942],
              [126.838219771924855, 37.728161931468328],
              [126.83831316150625, 37.728173321981302],
              [126.838365769248625, 37.728184935994172],
              [126.838412009876919, 37.728197676948163],
              [126.838464754418055, 37.728230400516701],
              [126.838507416793476, 37.728255804678298],
              [126.838680667480915, 37.728374016380734],
              [126.838722093945734, 37.728395750581043],
              [126.838734315805169, 37.728403649778052],
              [126.838754141915445, 37.728415506947805],
              [126.83888066546109, 37.728508868323949],
              [126.838925086214218, 37.728540751845351],
              [126.838974628453045, 37.728579389692172],
              [126.839059382458558, 37.728561204064121],
              [126.839085788417464, 37.728561802771864],
              [126.839096065113594, 37.728567169142281],
              [126.839109164225334, 37.728573665362433],
              [126.839141753024748, 37.728584683688965],
              [126.839153619577303, 37.728588645703013],
              [126.839214910813965, 37.72860337069023],
              [126.839271371827905, 37.728638081403062],
              [126.839298295951266, 37.728651633082443],
              [126.839405955262336, 37.72869879484081],
              [126.839415962956565, 37.728738791859158],
              [126.839426931409889, 37.728750348713277],
              [126.839463072569103, 37.7288382421102],
              [126.839480851367313, 37.728894856072948],
              [126.839483371055991, 37.728902473696657],
              [126.839515890734816, 37.729000767804088],
              [126.839568258418382, 37.729043076970861],
              [126.839593741970319, 37.729144749426844],
              [126.839601881628568, 37.72922950434662],
              [126.839629404106915, 37.729291483425868],
              [126.839659500697167, 37.729305887413446],
              [126.83965541166927, 37.729313206630906],
              [126.839664543160822, 37.729350941820393],
              [126.839699466998326, 37.729346205444521],
              [126.839706378543511, 37.729350999503744],
              [126.839737582302291, 37.729346537910864],
              [126.83974653932782, 37.729343090070628],
              [126.839836618572676, 37.729308411684528],
              [126.83986512140045, 37.729297437342098],
              [126.839941210017415, 37.729274460732547],
              [126.839953270899102, 37.72927278331634],
              [126.840042196889016, 37.729292333226226],
              [126.840186692476564, 37.729359819677725],
              [126.840241017006434, 37.729396779369871],
              [126.840279534555862, 37.729428522985749],
              [126.84030843007541, 37.729452335237113],
              [126.840317099206189, 37.729458824558137],
              [126.840319563003504, 37.729468404917938],
              [126.840297186857583, 37.729570858511892],
              [126.84029442213469, 37.729578953084157],
              [126.840312039922466, 37.729620036335696],
              [126.840312479402854, 37.729621408795985],
              [126.840312643912114, 37.72962282027671],
              [126.840312526969527, 37.729624233787646],
              [126.840312132275159, 37.72962561600076],
              [126.840311470353669, 37.729626931823638],
              [126.840310556244688, 37.729628148893084],
              [126.840309414050026, 37.729629236699161],
              [126.840308071227682, 37.729630170158664],
              [126.840306560917526, 37.729630923318908],
              [126.840304920718481, 37.729631479265286],
              [126.840273623097929, 37.729639859406014],
              [126.840203002650938, 37.729846650641498],
              [126.840214745836661, 37.729904834146353],
              [126.840285246461193, 37.730254158899129],
              [126.840547369012157, 37.730347713951943],
              [126.840552323152309, 37.730350330246829],
              [126.84057729376147, 37.730363520092993],
              [126.840733427606281, 37.730454393434933],
              [126.840876536875882, 37.730666311718331],
              [126.840881727532263, 37.730821230129912],
              [126.840883526643594, 37.730874955930837],
              [126.840887452718519, 37.730947597829555],
              [126.840894498448051, 37.731046999221093],
              [126.841039663514763, 37.731211902616209],
              [126.841108051589131, 37.731244252774751],
              [126.84127782354912, 37.73132456248586],
              [126.841337947030866, 37.731392500762269],
              [126.841385149168858, 37.731445208479684],
              [126.841496606920046, 37.731544474805304],
              [126.841539209323571, 37.731564294779517],
              [126.841689612573845, 37.731634263963926],
              [126.84175549318924, 37.731611248118462],
              [126.841972187594337, 37.731535541437843],
              [126.842470472447246, 37.731683747478804],
              [126.842502670449676, 37.731713631750935],
              [126.842506731225996, 37.73171898838968],
              [126.842686113362973, 37.731773755904946],
              [126.842708671120207, 37.731780642461139],
              [126.842747111349567, 37.731788010543177],
              [126.84281276159858, 37.731840471739666],
              [126.842878071185197, 37.731887573577374],
              [126.842896668521036, 37.731902019891294],
              [126.842940891888745, 37.731936372203798],
              [126.843075689462907, 37.731975402387185],
              [126.843200412822114, 37.732004572669915],
              [126.843250912994321, 37.732016469298081],
              [126.843476764774579, 37.732078992552658],
              [126.843545171178874, 37.732088653471919],
              [126.843733018181979, 37.732104392679467],
              [126.843761725601325, 37.732106684111699],
              [126.844053757814194, 37.732143391440822],
              [126.84407878413036, 37.732141271099778],
              [126.844168286413208, 37.732133695100217],
              [126.844534967990583, 37.732156420713331],
              [126.844959135713751, 37.732155580668262],
              [126.845712778219323, 37.732340996535115],
              [126.845808194147921, 37.732383099314269],
              [126.845898364828642, 37.732422885370312],
              [126.845979062186942, 37.732440953860106],
              [126.845988186615145, 37.732442998195943],
              [126.846187398485569, 37.732537581499471],
              [126.846360322932313, 37.73257355933719],
              [126.84636170002463, 37.73259299484355],
              [126.846551013430229, 37.732754850062342],
              [126.846726375917882, 37.732909956119066],
              [126.846714474017872, 37.732922292268917],
              [126.846713429946135, 37.73292358347657],
              [126.846712679839953, 37.732924999793667],
              [126.846712239982537, 37.732926496244694],
              [126.846712128903121, 37.732928030570477],
              [126.846712347005564, 37.732929556806155],
              [126.846712887861131, 37.732931031652896],
              [126.84671373594675, 37.732932413564086],
              [126.846714866668847, 37.732933660041631],
              [126.846716249696357, 37.732934735766058],
              [126.84686272953607, 37.733030790450698],
              [126.84686420560881, 37.733031609813828],
              [126.846865827520546, 37.733032234399403],
              [126.846867552291002, 37.73303264774561],
              [126.846869338027176, 37.733032838804455],
              [126.846871276878289, 37.73303278194841],
              [126.846895867035116, 37.733053468201234],
              [126.846977480283527, 37.733102001963623],
              [126.847041868606354, 37.733163458867075],
              [126.847078178103573, 37.733174772316836],
              [126.847401276186929, 37.733365242125011],
              [126.847424525854223, 37.733380679692871],
              [126.847438949999258, 37.733390256712667],
              [126.84759244809203, 37.733492188068013],
              [126.847677607842598, 37.733528666943641],
              [126.848019647410965, 37.733675180841622],
              [126.848047095706775, 37.733686938001505],
              [126.848206265909724, 37.733755117700632],
              [126.849058907522647, 37.734165873951362],
              [126.849143843995193, 37.734152188691539],
              [126.849653808308048, 37.734061904615771],
              [126.849707140494175, 37.734078307168161],
              [126.849881694602061, 37.73411930826974],
              [126.850366386693054, 37.734233156009005],
              [126.851201097340123, 37.734403721611528],
              [126.851701936137346, 37.734538377602142],
              [126.852116222213823, 37.734606195477646],
              [126.852634640741471, 37.734720318415341],
              [126.853425421570435, 37.734884325020793],
              [126.85368534676175, 37.73494096150251],
              [126.853755657184408, 37.734970052111471],
              [126.85418532279877, 37.735060123024041],
              [126.854280272969604, 37.735091212235567],
              [126.854451948986821, 37.73513591685473],
              [126.854869896811707, 37.73523835385776],
              [126.855033562796606, 37.735302750813609],
              [126.855472402298489, 37.735420662025376],
              [126.855996808834405, 37.735561563017704],
              [126.856032916487749, 37.735586384241557],
              [126.856465351949808, 37.735392334676895],
              [126.856532966493546, 37.735361994149351],
              [126.857711073955286, 37.734833321424468],
              [126.857975897697699, 37.734649513852652],
              [126.858116494292062, 37.734541002137576],
              [126.858466069125271, 37.734248055738725],
              [126.858707249176462, 37.734207679502923],
              [126.858910243999475, 37.734173696344747]
            ]
          ]
        ]
      },
      id: '31101600'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '능곡동',
        ADM_CD: '31101610'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.813041592379903, 37.644597671534953],
              [126.813641877675181, 37.644446950125975],
              [126.813884077770894, 37.644669909020351],
              [126.813996789094446, 37.644775050033417],
              [126.81404256474211, 37.64481774351966],
              [126.814094871557558, 37.644834206660519],
              [126.814685815024973, 37.645020205017467],
              [126.81508086913648, 37.644972978542441],
              [126.815147249540288, 37.644958517243893],
              [126.815183817584142, 37.644950550876011],
              [126.815557571865881, 37.64487518948561],
              [126.815612417211582, 37.644863464129756],
              [126.81578499437218, 37.64491429059327],
              [126.816003530669903, 37.644973376262648],
              [126.816033366187199, 37.645006128647879],
              [126.816275069856559, 37.645271485448077],
              [126.816287055183892, 37.645284658417324],
              [126.816346597738075, 37.645583877528459],
              [126.816483346382952, 37.645997096845761],
              [126.816500542632269, 37.646057039242258],
              [126.816602571244715, 37.645959939916438],
              [126.816718172017829, 37.645967581305889],
              [126.81682411851898, 37.645975217147843],
              [126.817254777201669, 37.646007638950728],
              [126.817330291030117, 37.646005191711261],
              [126.817428992562341, 37.646000758666304],
              [126.817448440606753, 37.646000148325967],
              [126.817625806446785, 37.646048979031214],
              [126.81770831008734, 37.64607635455954],
              [126.817851594516, 37.646082504616508],
              [126.817989484014078, 37.646090809931799],
              [126.818104122959966, 37.646085411201646],
              [126.818180445819962, 37.64608077245969],
              [126.818351794812358, 37.646126329730016],
              [126.818504433846499, 37.646117044627189],
              [126.818716881172662, 37.646093561812826],
              [126.818880285447662, 37.646042432049789],
              [126.818895907163181, 37.646037298146723],
              [126.818950525154122, 37.646019349865277],
              [126.819073625218039, 37.645999735523091],
              [126.81934340515177, 37.646093153043907],
              [126.819488166102474, 37.646079869790327],
              [126.819570490816034, 37.646068662542667],
              [126.819584889466398, 37.646070604129285],
              [126.819624602913876, 37.646075961735015],
              [126.819887485560514, 37.646119353286885],
              [126.819994478132145, 37.646149699470726],
              [126.820360712168835, 37.646237477591065],
              [126.820562770391291, 37.646272476458854],
              [126.820589859132454, 37.646276283751575],
              [126.821018697688686, 37.646336566628264],
              [126.821026721822591, 37.646340451567646],
              [126.8210267863887, 37.646357391498405],
              [126.821083568366731, 37.646370416203411],
              [126.821146137231494, 37.646356401892916],
              [126.821259528073284, 37.646350160988995],
              [126.821306751306707, 37.646370740502427],
              [126.821307466879787, 37.646379551495563],
              [126.821428135924151, 37.646433524061045],
              [126.821628152553288, 37.646283817328765],
              [126.821684118248328, 37.646158135277105],
              [126.821707968776906, 37.646104572186466],
              [126.821780001013266, 37.645942734649267],
              [126.821813704957222, 37.645867012246512],
              [126.821824228619761, 37.645843585034363],
              [126.821987312863143, 37.645703792672734],
              [126.821995871886699, 37.645697590251729],
              [126.822040229514243, 37.645670511499667],
              [126.822089080414315, 37.645640691473666],
              [126.822172665614332, 37.64558972394989],
              [126.822206378526104, 37.645570457975204],
              [126.822230905023744, 37.645556458851907],
              [126.82226957221134, 37.645532118002059],
              [126.822425494884996, 37.645380919931853],
              [126.822649198023669, 37.64524961739032],
              [126.822691881568971, 37.645254050029891],
              [126.822717042063402, 37.64525571952953],
              [126.822727363040869, 37.645252700747008],
              [126.822741589361314, 37.645249269943633],
              [126.822845295042725, 37.645195541306038],
              [126.82292793240444, 37.645152726622477],
              [126.822949087719067, 37.645128340921147],
              [126.823039126004034, 37.645036912226317],
              [126.823082676663034, 37.644900696510739],
              [126.823137071156196, 37.644851089444217],
              [126.823195230025974, 37.644827713676122],
              [126.823301203692751, 37.644768032650546],
              [126.823358262215834, 37.644735739055719],
              [126.823495149208497, 37.644659532641427],
              [126.823567866614496, 37.644618170340635],
              [126.823709615367704, 37.644566533822861],
              [126.823743319946971, 37.644553718162094],
              [126.82408890511249, 37.644520118743124],
              [126.824132268815447, 37.644525886019757],
              [126.824228080160211, 37.644535428347972],
              [126.82450812141326, 37.644501485576875],
              [126.824521766864493, 37.644499838565864],
              [126.824697971167311, 37.644475766975667],
              [126.82478687326514, 37.644441159183806],
              [126.824885590200012, 37.644401186902513],
              [126.824923122716029, 37.644395097792078],
              [126.824936554643045, 37.644392993438835],
              [126.824943446337315, 37.644391749382919],
              [126.825018681610388, 37.644378041631363],
              [126.82505091900552, 37.644372503540545],
              [126.825068785263497, 37.644344339193232],
              [126.825081184054085, 37.644257477891472],
              [126.825110397096395, 37.644237667069277],
              [126.825152760188303, 37.644208939810561],
              [126.825146316894305, 37.644197082929345],
              [126.825252320151918, 37.644161427019846],
              [126.824990849791121, 37.643478122106373],
              [126.824994355391468, 37.643477029924959],
              [126.824984632716053, 37.643444908625575],
              [126.824975310648469, 37.643414112767985],
              [126.824642027543888, 37.642557020997074],
              [126.824623564865092, 37.642510998009364],
              [126.824291773331964, 37.641615246679812],
              [126.824291081478506, 37.641613542810838],
              [126.824277217801651, 37.641579400382376],
              [126.82395678589674, 37.64071930655038],
              [126.823941574723904, 37.640678299045682],
              [126.823947359416934, 37.640676100504074],
              [126.823845574225643, 37.640417052066709],
              [126.823602474851342, 37.6397938027485],
              [126.823594459281551, 37.639772783764265],
              [126.823586457038971, 37.639751795501027],
              [126.823420449959315, 37.639317922563855],
              [126.823256621414558, 37.638889747450754],
              [126.823237149318416, 37.638837740818673],
              [126.822991523291918, 37.638182025963353],
              [126.822907387782053, 37.637959159933047],
              [126.822900084728161, 37.637935355371461],
              [126.822892528548152, 37.637910718330865],
              [126.822563564502715, 37.637042426929241],
              [126.822544875125885, 37.636993321538071],
              [126.822206261494941, 37.636105182232996],
              [126.822272564927815, 37.636088815519997],
              [126.822348074967195, 37.636070937843655],
              [126.822540575694532, 37.636022710890785],
              [126.82293194007346, 37.63592828809827],
              [126.823657210979917, 37.635753979367934],
              [126.824048704058626, 37.635661679829802],
              [126.824513833665193, 37.635551034920823],
              [126.824743676438672, 37.635496431230955],
              [126.825018738943399, 37.635430619989492],
              [126.825258083194456, 37.635373387294088],
              [126.825347284817454, 37.635352050075234],
              [126.825444113368434, 37.635328778877998],
              [126.82568049755281, 37.635272568532542],
              [126.825701986434169, 37.635266792145536],
              [126.825741706861976, 37.635256117567522],
              [126.825757741157346, 37.635251806205119],
              [126.825661732800853, 37.635108596793685],
              [126.825511269550361, 37.634884156398684],
              [126.825504855207114, 37.634874443049661],
              [126.825535292598872, 37.634875365158763],
              [126.825561973877583, 37.63487617565535],
              [126.825557242854984, 37.634869692383823],
              [126.825520801585242, 37.634790827697003],
              [126.825291445977768, 37.634294500433789],
              [126.825251893810474, 37.634208909268374],
              [126.825226471628909, 37.63415210987138],
              [126.825147086762215, 37.633974769790747],
              [126.825122926943521, 37.633925152178108],
              [126.825002661846341, 37.633678223498173],
              [126.824915188895915, 37.63339644672039],
              [126.824884678018279, 37.633330306484957],
              [126.824853759268933, 37.633263280603515],
              [126.824504777225684, 37.632471879124907],
              [126.824497783760194, 37.632458570601628],
              [126.824467841148348, 37.632401600033745],
              [126.824450753559091, 37.632369086752284],
              [126.824431260889696, 37.632328955173094],
              [126.824352844097035, 37.632167529443826],
              [126.824315991721463, 37.63209166794185],
              [126.824144333144602, 37.631738302430584],
              [126.824135620834156, 37.631719207412132],
              [126.824089347820788, 37.631617795035389],
              [126.824083701667135, 37.631604776321126],
              [126.824068741963103, 37.631570102965362],
              [126.824047346491611, 37.631520502757809],
              [126.824030508743974, 37.631481468816624],
              [126.823806423969501, 37.630961953999801],
              [126.823675766929114, 37.630659064704403],
              [126.823663703599379, 37.630631099373851],
              [126.823595380810858, 37.630472709867988],
              [126.823573794024824, 37.63042265420053],
              [126.823537215928894, 37.630337842898157],
              [126.823516663693866, 37.630290187986269],
              [126.823484384412453, 37.630215369128578],
              [126.823393902954308, 37.630005609993795],
              [126.823334016086633, 37.629866753666434],
              [126.823324539168055, 37.629844789769891],
              [126.823323897609328, 37.629843302500205],
              [126.823314293938438, 37.629823194616947],
              [126.823254617313367, 37.629698178482954],
              [126.823179572012194, 37.629540957942588],
              [126.823095121455538, 37.629364041594542],
              [126.822953562526607, 37.629067483836771],
              [126.822932761000033, 37.629023901400323],
              [126.822956984189801, 37.629029974763071],
              [126.822993658935516, 37.628901100220467],
              [126.823003189423517, 37.628898020076946],
              [126.823004204406132, 37.628897693309689],
              [126.823149264436793, 37.628850553122355],
              [126.823294788921061, 37.62821033882522],
              [126.823295112397446, 37.628208909367039],
              [126.823243351894305, 37.628199864174157],
              [126.823227665100333, 37.628197124284384],
              [126.823192457987886, 37.628190984366505],
              [126.823190753973094, 37.628190687968683],
              [126.823185922556135, 37.628189845451402],
              [126.822540648374456, 37.62807723982506],
              [126.822524106188212, 37.628074356125495],
              [126.822451350138252, 37.627911356283917],
              [126.822227260252291, 37.627161137142302],
              [126.822226523519532, 37.627158654250003],
              [126.82222610565718, 37.627157247575994],
              [126.822221444740322, 37.627141631381114],
              [126.822215516461071, 37.62712181491397],
              [126.821992403120305, 37.626362906615654],
              [126.821990424370199, 37.626356182131126],
              [126.821959209480681, 37.626249995402468],
              [126.821902130359547, 37.626059657764927],
              [126.821888071350713, 37.626012765432094],
              [126.821887499392403, 37.626010858533562],
              [126.821883034368454, 37.625996272768894],
              [126.821825561487202, 37.625804288796651],
              [126.821764389475646, 37.625600268783231],
              [126.821637420784015, 37.625312414407901],
              [126.821618973353381, 37.625268112471488],
              [126.821615335896084, 37.625259363066462],
              [126.821615156222379, 37.625258932999749],
              [126.821615038678985, 37.625258649305948],
              [126.821612756868106, 37.625244878266585],
              [126.821606162112985, 37.62520491137564],
              [126.821593185483678, 37.625193076195579],
              [126.821591755878643, 37.625191771861566],
              [126.821587590679727, 37.625187973137876],
              [126.82154324153575, 37.625147504341818],
              [126.821362643696574, 37.625040640457534],
              [126.821288089483303, 37.62499598150341],
              [126.821270132834655, 37.62498522447649],
              [126.821032370652617, 37.624842846876987],
              [126.821009766802462, 37.624833454724289],
              [126.820895475441631, 37.624786075567194],
              [126.820808364992843, 37.624749966177191],
              [126.820783980058508, 37.624734815037989],
              [126.820734484092156, 37.624704110175962],
              [126.820748029323042, 37.624682078496527],
              [126.820782888414612, 37.624625385331775],
              [126.820792121457572, 37.62460076904302],
              [126.820805049924402, 37.624566311657986],
              [126.820817207804666, 37.624518153204939],
              [126.820841217826825, 37.624423051331192],
              [126.820845372319553, 37.624406595301885],
              [126.820886866853712, 37.62433824101479],
              [126.820891677232112, 37.624334888708475],
              [126.820983599081359, 37.624270813902662],
              [126.821107569184377, 37.624226284021482],
              [126.821039119430395, 37.624096403094292],
              [126.82101354936313, 37.624091962012315],
              [126.821003928313374, 37.624084501309206],
              [126.8209347860561, 37.624030279501049],
              [126.821047414544708, 37.623939666029948],
              [126.82110271501989, 37.623958342543574],
              [126.821166185517228, 37.623864167062706],
              [126.82120517909317, 37.62380658630277],
              [126.821225440308339, 37.623816603986199],
              [126.82126646272981, 37.623835492119767],
              [126.821306579896387, 37.623852992389679],
              [126.821394194047926, 37.623884963594278],
              [126.821435224452557, 37.623897497328493],
              [126.821482417196776, 37.623893079992534],
              [126.821619007846138, 37.623859039729943],
              [126.82168576252198, 37.623840088142295],
              [126.821694362348182, 37.62383074029831],
              [126.821703325204638, 37.623815860365411],
              [126.821704381399798, 37.623814108840676],
              [126.821704828779517, 37.623804532061577],
              [126.82170494456291, 37.623802016209687],
              [126.821706324047227, 37.623772667773551],
              [126.821706743642721, 37.623763903835666],
              [126.821702337352775, 37.623746239448643],
              [126.821693673202134, 37.623683103648077],
              [126.821693616123824, 37.623682650851556],
              [126.821684960031746, 37.623586704826664],
              [126.821687982006793, 37.62353423225737],
              [126.821690489963416, 37.62349093769545],
              [126.821691350249395, 37.623476575416497],
              [126.821695898574546, 37.623400289428076],
              [126.821701767187221, 37.623321869464512],
              [126.821701839864303, 37.623320854982971],
              [126.821702747883123, 37.623308472302],
              [126.821702799946308, 37.623307980474273],
              [126.821703338228929, 37.623303613913258],
              [126.821713534858461, 37.623221287219323],
              [126.821727706085923, 37.623182429355211],
              [126.82173158969924, 37.623171810559022],
              [126.821731729131059, 37.62317143099861],
              [126.821732324723698, 37.623169736313599],
              [126.821734203020085, 37.623164557243435],
              [126.821758068781335, 37.62309912255639],
              [126.821809705386471, 37.62301487625674],
              [126.821810947656701, 37.623012848190129],
              [126.821872955214843, 37.622959274296072],
              [126.822011648956135, 37.622839446092456],
              [126.822105371371919, 37.62275847174962],
              [126.822126393268448, 37.62272738120889],
              [126.822132845395672, 37.622722664652755],
              [126.822169272037314, 37.622696039468416],
              [126.82217600582274, 37.622625608435847],
              [126.82211651280393, 37.622525372071252],
              [126.822115325264406, 37.622523371503092],
              [126.82188334855654, 37.622375992180686],
              [126.821862678542118, 37.622362858158581],
              [126.821777096163999, 37.622308486464377],
              [126.821699091982182, 37.622258922711424],
              [126.821667391922887, 37.622238777782982],
              [126.82134947256732, 37.622036781946647],
              [126.821262538985707, 37.621981545842566],
              [126.821143060019679, 37.621965772019905],
              [126.821153120548345, 37.62192462642161],
              [126.821152441732124, 37.621921765294665],
              [126.821150129590805, 37.621913093799876],
              [126.82112787466238, 37.621829540297895],
              [126.821127701646517, 37.621828877724965],
              [126.821117145937393, 37.621798123042254],
              [126.821108875354909, 37.621774026123695],
              [126.821104943564151, 37.621771499602346],
              [126.820907042608511, 37.621644376253194],
              [126.82072129578448, 37.621525072192192],
              [126.82072074149373, 37.621524717481847],
              [126.820693935523764, 37.621507506359507],
              [126.820685580042323, 37.62151567119151],
              [126.82059961941134, 37.621599685141518],
              [126.820590954012459, 37.621593839684067],
              [126.820403227344997, 37.621468776832693],
              [126.8203203638666, 37.621413466132402],
              [126.82025384118262, 37.621370801456607],
              [126.820258301437534, 37.621365561570748],
              [126.820321291305163, 37.621277247794843],
              [126.820330117997472, 37.621264872877802],
              [126.820401087012328, 37.621166147765891],
              [126.820398748180224, 37.621164652505882],
              [126.820391139362457, 37.621108473737124],
              [126.820389671849114, 37.62110860589263],
              [126.820324291287037, 37.621114518802457],
              [126.820252221755453, 37.621110839025462],
              [126.820234371461083, 37.621109924994265],
              [126.820235813774801, 37.621090408653579],
              [126.820236167953098, 37.621085610200701],
              [126.820236683973107, 37.621078469217487],
              [126.820236973240384, 37.621074577130464],
              [126.820238298571851, 37.621056707750292],
              [126.820214835264807, 37.621002460221561],
              [126.820214131232618, 37.62096180429743],
              [126.820128312468398, 37.620883427576928],
              [126.81995090604795, 37.620688045669887],
              [126.819881734370085, 37.620639220234935],
              [126.819861413887068, 37.620624876486325],
              [126.819634725357304, 37.620464854097101],
              [126.819529123612227, 37.620543547937665],
              [126.819499639872419, 37.620590690617298],
              [126.819440213002224, 37.620695580176346],
              [126.819427629890285, 37.620718761962365],
              [126.819429384854146, 37.620719415635428],
              [126.81938282967738, 37.620864162385516],
              [126.819264638595314, 37.620953446118357],
              [126.81914194059776, 37.620835613734165],
              [126.819101068772255, 37.620871021957498],
              [126.818973838435369, 37.620782020283407],
              [126.819024575563645, 37.620742135339178],
              [126.819022610006527, 37.620740469150476],
              [126.818959359121621, 37.620684866883899],
              [126.819339622797287, 37.620392082160997],
              [126.819340848416019, 37.620393125762568],
              [126.819497776549625, 37.6205243526625],
              [126.819592446785506, 37.620452000680075],
              [126.819605505158094, 37.620442060378181],
              [126.819602278675291, 37.62042780613784],
              [126.819583840419412, 37.62037480064874],
              [126.819433507737429, 37.620106549962429],
              [126.819293356250824, 37.61985644574041],
              [126.819317558961643, 37.619837686230525],
              [126.81933062278182, 37.619827389061733],
              [126.819360608049195, 37.619803765061505],
              [126.819573159563376, 37.619640485213225],
              [126.819767426902828, 37.619492512348742],
              [126.819747953122487, 37.619535011457984],
              [126.820156761047173, 37.619193134368125],
              [126.820202249357735, 37.61915830397475],
              [126.820212328735792, 37.619150942276541],
              [126.820215652729701, 37.619148520618666],
              [126.820219221356609, 37.619167269055822],
              [126.820256142742394, 37.619343064848572],
              [126.820712107725839, 37.619635389560187],
              [126.821612011903355, 37.618968273847926],
              [126.821710283710843, 37.618895423472388],
              [126.821301768084723, 37.618560421620693],
              [126.821548912992156, 37.618373172395955],
              [126.821600782557027, 37.618333777743615],
              [126.821901914518151, 37.618104596293477],
              [126.821965143965301, 37.618055757701711],
              [126.822051080498937, 37.617990245097047],
              [126.822108769858843, 37.617946285055567],
              [126.82230350427173, 37.617796495335583],
              [126.822419556512003, 37.617708202082277],
              [126.822498218843847, 37.617647307263461],
              [126.822564690621078, 37.617596952891681],
              [126.822617943035326, 37.617556139813729],
              [126.822784968166843, 37.617428148474538],
              [126.822897454604288, 37.617341344909946],
              [126.822942770028732, 37.617371047855258],
              [126.822983076091958, 37.61739712031779],
              [126.823109183839605, 37.617299382219315],
              [126.823598227307301, 37.616929334084553],
              [126.823653535480034, 37.61689087217389],
              [126.824022010635005, 37.616612908891859],
              [126.824073519272616, 37.616574051862806],
              [126.824202015274679, 37.616479039263275],
              [126.824254704315692, 37.616439691378091],
              [126.824253366728385, 37.616438123511351],
              [126.824151337953325, 37.616420059643794],
              [126.824140009466078, 37.616420043431681],
              [126.824128685510999, 37.616420027244438],
              [126.824071968412881, 37.616455981015761],
              [126.824038050882066, 37.616428901774782],
              [126.82397016834139, 37.616392760632209],
              [126.823936241833394, 37.616374689229531],
              [126.823902412793842, 37.616311567964182],
              [126.823891129645304, 37.616293532544439],
              [126.823838968610048, 37.616189228885254],
              [126.823831260435128, 37.616173815190699],
              [126.823755857144491, 37.616023035384835],
              [126.823710616050221, 37.615995938024703],
              [126.823573749206474, 37.615930333290905],
              [126.823540914567303, 37.615914593471821],
              [126.823518303191051, 37.615896538141556],
              [126.823506988410159, 37.615887514027996],
              [126.823405587182819, 37.615671124754144],
              [126.823360553203528, 37.615553938431809],
              [126.82333798524688, 37.615517854856769],
              [126.823326743274961, 37.615481800172319],
              [126.823247890620181, 37.615301485206203],
              [126.823191537967361, 37.615184271756128],
              [126.823180257423772, 37.615166236281304],
              [126.822977525598233, 37.614688408828783],
              [126.822954853180818, 37.614697385815298],
              [126.822682722444981, 37.614823110376861],
              [126.822376612830325, 37.614948783401267],
              [126.822308613461516, 37.614966700772577],
              [126.822070543352524, 37.615056443620432],
              [126.822025195890504, 37.615074388209216],
              [126.821821197132309, 37.615128135984897],
              [126.821220428696705, 37.615325433790304],
              [126.820920317822114, 37.615418689536853],
              [126.82090305081519, 37.615424055848933],
              [126.820710353234517, 37.61548684039915],
              [126.820200278042208, 37.615648224961944],
              [126.819962237825067, 37.615728944964907],
              [126.819871550502739, 37.61575583543474],
              [126.819565497434439, 37.61585447227705],
              [126.818749385350344, 37.616105484157558],
              [126.818715369976204, 37.616114443509403],
              [126.817627188659898, 37.616464133483852],
              [126.817603115655643, 37.616471751139429],
              [126.817578705828026, 37.616422743338987],
              [126.81757856121952, 37.616422448668324],
              [126.816449915346013, 37.616792797404599],
              [126.813438365195779, 37.617780922760154],
              [126.813350584962748, 37.617769145342983],
              [126.813280015808374, 37.617759681696086],
              [126.813162396981241, 37.617743890810139],
              [126.813030801525784, 37.617726228393693],
              [126.812995150444522, 37.617721433055628],
              [126.810283162480133, 37.617357408126693],
              [126.807261125007713, 37.616354605653477],
              [126.807006662490934, 37.616270167361996],
              [126.807001052853209, 37.61626830651668],
              [126.804052488754792, 37.615289774850439],
              [126.803942709685444, 37.615253340868094],
              [126.803660761096907, 37.615159754172396],
              [126.803574260672292, 37.615131042823705],
              [126.803321156310531, 37.614949053463128],
              [126.803297826302028, 37.614932313188135],
              [126.803288988805292, 37.61492595348934],
              [126.803265409201913, 37.614909006230619],
              [126.801690589233644, 37.613709409216973],
              [126.801936264325008, 37.613502047586756],
              [126.8020986947148, 37.613366570103345],
              [126.802583958015418, 37.612736741727097],
              [126.803001997611346, 37.612275892587867],
              [126.803711162671476, 37.611668090668495],
              [126.804314166237532, 37.611026550823659],
              [126.804531537553714, 37.610716610962669],
              [126.804688787678558, 37.610366295479899],
              [126.804729816150754, 37.610102646831656],
              [126.804763678832714, 37.609587839219714],
              [126.804739339616205, 37.609074284023222],
              [126.802732093136811, 37.606352457271768],
              [126.802685429126271, 37.606289178360228],
              [126.802034935053939, 37.60542945570517],
              [126.802009354647524, 37.605395646823958],
              [126.801988685483423, 37.60540875614641],
              [126.801448367607108, 37.605637032279809],
              [126.80046166402704, 37.606043329352872],
              [126.798562025170028, 37.607044670527074],
              [126.797167400770974, 37.607773753188319],
              [126.796911273183724, 37.607930692591772],
              [126.795607023932234, 37.608796464684978],
              [126.794595374539455, 37.609477106404725],
              [126.794202896239696, 37.609741162853197],
              [126.793218225239514, 37.610399396267134],
              [126.792698059290387, 37.610742534506151],
              [126.79258087050583, 37.610819577343619],
              [126.792338158127421, 37.610979250512997],
              [126.791657333357151, 37.611437541835969],
              [126.791258232483699, 37.611705152990105],
              [126.790200636099343, 37.612412795258393],
              [126.789519809817932, 37.612862055296077],
              [126.789064344051809, 37.613158846244716],
              [126.788809720223099, 37.613331539317102],
              [126.788315322807577, 37.613623474263868],
              [126.787929945591429, 37.613848301130886],
              [126.787407540717169, 37.614153119894766],
              [126.78677736083813, 37.6145177192113],
              [126.786626519532533, 37.614603885013793],
              [126.786185446543726, 37.614861077789826],
              [126.786150618014076, 37.614881387706042],
              [126.78558150344854, 37.615213236838692],
              [126.783737149447106, 37.616287355814777],
              [126.782635177059632, 37.616932617596198],
              [126.782527261784679, 37.616994567912855],
              [126.782227437491585, 37.617166681482338],
              [126.781579481118186, 37.617542760853141],
              [126.781358598973895, 37.617665945453979],
              [126.780689870046416, 37.618061973196156],
              [126.779918113664579, 37.618519747675919],
              [126.779687798622916, 37.618659812942411],
              [126.778322187967987, 37.619461178603586],
              [126.777693435892587, 37.619830130516526],
              [126.776204824610076, 37.620708003741406],
              [126.774762197186789, 37.621552639933796],
              [126.77347169134265, 37.622308179231545],
              [126.773258041091736, 37.622435876192846],
              [126.773192655719981, 37.62247473535988],
              [126.772305155104291, 37.623002214020104],
              [126.771166418906901, 37.623680514788461],
              [126.770429685510564, 37.624109290926356],
              [126.769952226821886, 37.624371890839711],
              [126.769344597098225, 37.624715320641251],
              [126.768562135757662, 37.625157510083589],
              [126.766665990529404, 37.626121316744197],
              [126.765768073200661, 37.626577705686223],
              [126.765219492803638, 37.626856529885046],
              [126.763699351556383, 37.627625386203881],
              [126.763554418006365, 37.627698687996727],
              [126.762888573439142, 37.628016911687524],
              [126.761914580028943, 37.628494987248068],
              [126.759960322869929, 37.629465751856671],
              [126.759520427671518, 37.629693473959804],
              [126.759519247381235, 37.629694099284194],
              [126.759541582216201, 37.629730992885868],
              [126.760496662861954, 37.631308495922418],
              [126.762631008058662, 37.634916741618412],
              [126.762871115817134, 37.635326369126801],
              [126.764017713881557, 37.635332286271897],
              [126.764139003773323, 37.635300601381445],
              [126.765596470126965, 37.634922708013342],
              [126.766279487238705, 37.634494856067988],
              [126.766907241553469, 37.634161536743548],
              [126.767429315495619, 37.633965131978606],
              [126.767587569660023, 37.633905594885306],
              [126.768540795100051, 37.633718015172455],
              [126.769086295838818, 37.633275623691382],
              [126.769716888044712, 37.633106388094049],
              [126.770270318001224, 37.632957153648235],
              [126.770734310625031, 37.632845168187039],
              [126.771183328108023, 37.632736794646895],
              [126.771207892465668, 37.632731060716516],
              [126.771337071994296, 37.63270090340415],
              [126.772663751081325, 37.632262592486903],
              [126.773323662963278, 37.632101721238115],
              [126.773492820445682, 37.632058683541445],
              [126.77355433557733, 37.63203999923266],
              [126.773956572745533, 37.631927001568236],
              [126.77405532916984, 37.631899173255889],
              [126.774211008470033, 37.631855304392509],
              [126.774286125975621, 37.631839704797414],
              [126.774543718367255, 37.63178620970664],
              [126.77534216033996, 37.631527495125731],
              [126.775736675450545, 37.631399771210418],
              [126.775873338999418, 37.631331154619332],
              [126.776291791988541, 37.631320904333577],
              [126.776296124539087, 37.631320798413796],
              [126.776557182586615, 37.631317009483396],
              [126.777020280193483, 37.631304284905056],
              [126.777020763087151, 37.631304274344195],
              [126.77703219037204, 37.631298766948611],
              [126.778758604411621, 37.631250950536909],
              [126.779447313569918, 37.631231962028053],
              [126.779776855937527, 37.631131311945524],
              [126.779872323060374, 37.631124102690499],
              [126.780165890961172, 37.631079489246396],
              [126.780320146238367, 37.631061107591087],
              [126.780362546769851, 37.631056051984757],
              [126.780528466946123, 37.631028047418461],
              [126.781722578724384, 37.630850892338849],
              [126.781816245526159, 37.630819669072665],
              [126.782170736361294, 37.630702369681394],
              [126.782180838485502, 37.630698614918039],
              [126.782204848907028, 37.630690577832944],
              [126.782552803760026, 37.630579305266039],
              [126.782697830261114, 37.630533668588633],
              [126.782976056177034, 37.630441392649409],
              [126.783244764389707, 37.630342700258808],
              [126.783621399526467, 37.630193915999904],
              [126.783871221891943, 37.630091799623571],
              [126.784712836915574, 37.629722227948697],
              [126.785126890480242, 37.629531450146352],
              [126.785473275411874, 37.629373621979745],
              [126.785547156165535, 37.62933482551913],
              [126.785720343051977, 37.629241321997803],
              [126.785886238157687, 37.629150997238042],
              [126.786036403298738, 37.629069282993953],
              [126.78628573299271, 37.628934636722995],
              [126.786355242521438, 37.628899680009191],
              [126.786397069590464, 37.628878456125541],
              [126.786594498908116, 37.628765941978479],
              [126.786687372977781, 37.628719945350547],
              [126.786708728157834, 37.628697441181934],
              [126.786745036553995, 37.628676511893893],
              [126.787051646874943, 37.628499778373211],
              [126.787460706932663, 37.628264015534668],
              [126.787598582173558, 37.628183200293165],
              [126.787857293028111, 37.628031557756209],
              [126.788344850546977, 37.627758186215175],
              [126.788618162090771, 37.627461670162262],
              [126.788717355582421, 37.627340532840968],
              [126.789738098801621, 37.626588719685117],
              [126.789748957379473, 37.626598143553913],
              [126.789840155680494, 37.626677283919904],
              [126.789923146615791, 37.626609017366789],
              [126.789840151947573, 37.626533200970592],
              [126.789828245344637, 37.626522324168242],
              [126.78985936234406, 37.626499406774307],
              [126.79027882672743, 37.626193851692641],
              [126.790428307751526, 37.626082714569257],
              [126.790868296498147, 37.625685251413543],
              [126.790940051166984, 37.62562300507625],
              [126.791088900394854, 37.62571550071911],
              [126.79112283593787, 37.625736635283374],
              [126.791236908029973, 37.625807873946009],
              [126.791280795580747, 37.625843819407073],
              [126.791542036699795, 37.626022471620935],
              [126.791632239934927, 37.626083002096642],
              [126.791655961590536, 37.626098920482676],
              [126.791813527046941, 37.626209232745651],
              [126.791924857801803, 37.626430539193038],
              [126.79194261631261, 37.626465831868693],
              [126.792034498543529, 37.626648486048651],
              [126.792044315413634, 37.62666800465756],
              [126.792137004426877, 37.626802206314004],
              [126.792173169508445, 37.62685456609308],
              [126.792305523255138, 37.627046195820945],
              [126.792309304002728, 37.627051669738691],
              [126.792431916290369, 37.627068247762899],
              [126.792567768425755, 37.627086615766643],
              [126.792693691570818, 37.627019549256055],
              [126.79269497849225, 37.627018879170848],
              [126.792784934260425, 37.627099293314636],
              [126.793006212237501, 37.627297102957108],
              [126.793092994370639, 37.627374651449799],
              [126.793162194073176, 37.627436779157108],
              [126.793283329138816, 37.627545543545075],
              [126.793437885875548, 37.627684314020243],
              [126.793523631637967, 37.627761298967023],
              [126.7936165169166, 37.627844532248375],
              [126.793662190054377, 37.627885440316597],
              [126.793716048470856, 37.627933688218924],
              [126.793756410965955, 37.627969835184857],
              [126.793780988967612, 37.627991655667536],
              [126.793872349282452, 37.628072749777417],
              [126.793942478821563, 37.628136392316797],
              [126.794040559759225, 37.628225394217658],
              [126.793918396334561, 37.628333148125975],
              [126.79391395996339, 37.628337061288057],
              [126.793859709023408, 37.628512919477942],
              [126.793860287863382, 37.628513905391259],
              [126.793859688373175, 37.628515820733703],
              [126.793965971056153, 37.628703310467557],
              [126.794398966990443, 37.62892219001391],
              [126.7943999236862, 37.628923726192518],
              [126.794469186832416, 37.62903491286383],
              [126.794513833787278, 37.629106577734788],
              [126.794571538012406, 37.629241959915518],
              [126.794602041151904, 37.629308417932172],
              [126.794550883651496, 37.629337185348916],
              [126.794537907696366, 37.629400232362464],
              [126.794532212299842, 37.629426795634991],
              [126.79446403216194, 37.629744769898657],
              [126.794428601918526, 37.629902180681242],
              [126.794413950942186, 37.629966645363005],
              [126.794413551354012, 37.629968394256011],
              [126.794323359958327, 37.630366954401296],
              [126.794297238611364, 37.63048044134878],
              [126.794269780861015, 37.630601015557048],
              [126.79425173811093, 37.630682685222418],
              [126.794202810958339, 37.630909537388789],
              [126.794187314816512, 37.630979920508587],
              [126.794164250169786, 37.631088791065139],
              [126.794117258663562, 37.631308401763867],
              [126.79409628544208, 37.631400308281641],
              [126.794088326077656, 37.631439255757257],
              [126.794082495573576, 37.631470602443514],
              [126.794082448946952, 37.631470859043297],
              [126.794077276354741, 37.631474227907752],
              [126.794037284564141, 37.631501828872217],
              [126.794036548738987, 37.631502340029556],
              [126.794064635916783, 37.631550003615843],
              [126.794094815566282, 37.631603143570629],
              [126.794095040445498, 37.631604650135422],
              [126.794095263313963, 37.631606129648326],
              [126.79409725437786, 37.631605429511033],
              [126.79410341026913, 37.631649910871914],
              [126.794114681269221, 37.631731369209575],
              [126.794115025034003, 37.631733846315505],
              [126.794172203937705, 37.631723956509333],
              [126.794200667430644, 37.631719027967563],
              [126.794201616435487, 37.631718864194802],
              [126.794201485951049, 37.631724991549667],
              [126.794199843515528, 37.631770433038419],
              [126.794196822467143, 37.631854271003583],
              [126.794210946095404, 37.631908895806625],
              [126.794206759922233, 37.631910778877902],
              [126.794214139164296, 37.631921425352942],
              [126.79421949120254, 37.631964752702771],
              [126.794224564574549, 37.632005909787786],
              [126.79428647435833, 37.632553739147951],
              [126.79429179402041, 37.63260073831016],
              [126.794295064113882, 37.632626339384068],
              [126.794309949614998, 37.632761132908762],
              [126.794311430749715, 37.632774569764038],
              [126.794318248861785, 37.632840368788386],
              [126.794521851200415, 37.634805569777271],
              [126.794530126341428, 37.634885481468942],
              [126.794535035089041, 37.634932818848263],
              [126.794533019467295, 37.634933230420266],
              [126.79449062320279, 37.634941894648172],
              [126.794457785052941, 37.634976884336609],
              [126.794419059214874, 37.635023838190243],
              [126.794377644757745, 37.635074050476696],
              [126.794324634784644, 37.635233069359117],
              [126.794315282606448, 37.635418293190199],
              [126.794311623652121, 37.635511809360843],
              [126.794330795600459, 37.63558446113862],
              [126.794415109641406, 37.635820843940934],
              [126.794442342226773, 37.635875894093168],
              [126.794480251253091, 37.635952528199148],
              [126.794498077498815, 37.635988568955796],
              [126.794533632285862, 37.636060440768134],
              [126.794509857104416, 37.636144003156467],
              [126.794460866871447, 37.636316193781958],
              [126.794436983319812, 37.63655346621703],
              [126.794542987440323, 37.6368128642331],
              [126.79457632793634, 37.636895890240112],
              [126.794608698427851, 37.636976506595062],
              [126.794629421383306, 37.637143402341678],
              [126.794615152467856, 37.637191049565203],
              [126.794530847299669, 37.63747253679486],
              [126.79456721759891, 37.637635765762532],
              [126.794634347284486, 37.637736343439819],
              [126.794699870730611, 37.637835161997764],
              [126.794704865881769, 37.63784269451444],
              [126.794736171461437, 37.63787067771657],
              [126.794796023530182, 37.637921533297494],
              [126.794828401325674, 37.637949043377617],
              [126.794885049828082, 37.637998769328831],
              [126.794886415649657, 37.637999962738256],
              [126.794888827891128, 37.638002086262972],
              [126.794895984025288, 37.638063636557725],
              [126.794902360165068, 37.638118493434682],
              [126.794905677901596, 37.638147133131326],
              [126.79491662658495, 37.638240574343378],
              [126.79491702239666, 37.638244026091122],
              [126.794973970619125, 37.638733671231272],
              [126.794979537165247, 37.638781497409212],
              [126.794987189275673, 37.638856785089473],
              [126.794912852327883, 37.638894909560776],
              [126.794844434099474, 37.638893218951722],
              [126.794629264262426, 37.638887900129163],
              [126.794634757210133, 37.638936894903487],
              [126.794660997169245, 37.639198494547003],
              [126.794727805512181, 37.639233229133708],
              [126.79480383301977, 37.639289814699445],
              [126.794864990753823, 37.639333484545361],
              [126.794887511379955, 37.639363904663263],
              [126.794921414962758, 37.639411732551437],
              [126.794984921521944, 37.639501320997681],
              [126.795001684622562, 37.639523199053656],
              [126.795064012151826, 37.639611874576389],
              [126.795065413957644, 37.639625696702183],
              [126.795124538516461, 37.640075821533863],
              [126.795156032867254, 37.640162037826109],
              [126.795157506737837, 37.640231457878272],
              [126.79516800388906, 37.640327044203154],
              [126.795224348202396, 37.640429457976012],
              [126.795405349482991, 37.640540925628301],
              [126.795520367357042, 37.640561749968377],
              [126.79588276091728, 37.640727506195375],
              [126.795903579923973, 37.640737029493891],
              [126.796120237511843, 37.640839439595659],
              [126.796715772450128, 37.641120939989946],
              [126.796818098642277, 37.641205780816286],
              [126.796820235521139, 37.641207552944586],
              [126.796852455211081, 37.64123426835225],
              [126.796855120670202, 37.641236480774253],
              [126.796866506931252, 37.641245917036976],
              [126.796974785990017, 37.641408020759293],
              [126.796975281080805, 37.641408767304917],
              [126.79699776257992, 37.641442413535131],
              [126.796999240132152, 37.641444630592439],
              [126.79702782192237, 37.641487424688698],
              [126.79718179885549, 37.641802786443449],
              [126.797202102105274, 37.64184436485904],
              [126.797226905733453, 37.641848355878416],
              [126.797231486832786, 37.641857663236628],
              [126.797304998667812, 37.642007196923998],
              [126.79733360413249, 37.642065568728491],
              [126.79727590003921, 37.642110676263741],
              [126.797197468977402, 37.642162170583774],
              [126.797186648510234, 37.642169297814689],
              [126.797057513202191, 37.642254368160913],
              [126.797013566102876, 37.642269380189866],
              [126.797013990243556, 37.642270319196527],
              [126.797173115334417, 37.642622066647164],
              [126.797180390454969, 37.642638144603801],
              [126.797266616250596, 37.642646651231715],
              [126.797386227760484, 37.642638914622317],
              [126.797434790982052, 37.642635772487971],
              [126.797565039450191, 37.642584182763855],
              [126.797611116858988, 37.642689145392268],
              [126.797615834124272, 37.642699892063966],
              [126.797650281437171, 37.642778076395977],
              [126.797726546004768, 37.642951204918404],
              [126.797765307101116, 37.643039197000107],
              [126.797739775208598, 37.643060504721994],
              [126.797628429757779, 37.643208832175574],
              [126.797597948563833, 37.643249438463044],
              [126.797597558104869, 37.643346518107982],
              [126.797638614302912, 37.64343526207778],
              [126.797721316957336, 37.643605708711171],
              [126.797779492305054, 37.643723997295062],
              [126.797878370245428, 37.64386306394298],
              [126.798079143311654, 37.643941259087541],
              [126.798144353013015, 37.643966655289049],
              [126.798512099944901, 37.644047901601091],
              [126.798923358338911, 37.644092268868228],
              [126.799086175947721, 37.644109836990424],
              [126.799297889421609, 37.644014867165595],
              [126.799528583219342, 37.644050347900844],
              [126.799928726199227, 37.644114010683438],
              [126.800014271696014, 37.644127102633448],
              [126.800055183536898, 37.644137360927409],
              [126.800138228987862, 37.644163696479779],
              [126.800157189355829, 37.644170567112724],
              [126.800428343330736, 37.644213809486189],
              [126.800525652542049, 37.644229325992825],
              [126.800537387201729, 37.644231200928061],
              [126.800814584984266, 37.64430593998226],
              [126.801136998983722, 37.644458853388912],
              [126.801351511865576, 37.644567549576685],
              [126.801427872023311, 37.644602921898809],
              [126.801586026885317, 37.644676182627094],
              [126.801676799830133, 37.644718227014785],
              [126.801982660196899, 37.644859859158245],
              [126.802035691964591, 37.644884449279459],
              [126.802087573917291, 37.644908482764897],
              [126.802127325953307, 37.644839121940116],
              [126.802173654524836, 37.644758379283886],
              [126.802480522907501, 37.644223354465609],
              [126.802852317169169, 37.643575184861959],
              [126.802860984979858, 37.643553846678763],
              [126.80288639422885, 37.643545646028478],
              [126.803094030282764, 37.643478646805455],
              [126.803123790473549, 37.643469045004721],
              [126.803300252780588, 37.643412102149092],
              [126.803302095249165, 37.643411559614862],
              [126.803357834502606, 37.643395111463917],
              [126.803386862718952, 37.643386687815834],
              [126.803443812124058, 37.643368460338479],
              [126.803447713167216, 37.643367295421974],
              [126.803454608297329, 37.643365225223896],
              [126.803645169549938, 37.643303439337473],
              [126.803842949997517, 37.643292878535163],
              [126.803900452432373, 37.643306346583259],
              [126.80398665019905, 37.643329080102674],
              [126.804091804087932, 37.643309469242645],
              [126.804188364332788, 37.64331786593349],
              [126.804225986494316, 37.643344938378277],
              [126.804327316179354, 37.643423504404318],
              [126.804394176562582, 37.643459989618698],
              [126.804531105940342, 37.643534718411708],
              [126.804743418175647, 37.643595807185939],
              [126.804983012570986, 37.643626775307133],
              [126.805109143290736, 37.643643536718912],
              [126.805458842677737, 37.643690006256158],
              [126.805705468786982, 37.643774559483774],
              [126.805747293149764, 37.643788896079386],
              [126.80577422993386, 37.643798132769241],
              [126.805779331596284, 37.643803395899305],
              [126.805806514046907, 37.643831423104338],
              [126.805815555991359, 37.643840439112935],
              [126.805919763628324, 37.643948495926303],
              [126.805954367195042, 37.643970438631754],
              [126.805994058691269, 37.643995836303731],
              [126.806003819176325, 37.644002918670395],
              [126.806093331850931, 37.644059624969991],
              [126.806171006891319, 37.644108832838612],
              [126.806295307361665, 37.644237925501983],
              [126.806441618282193, 37.644399259936705],
              [126.806497122901732, 37.644470598236488],
              [126.806597231301723, 37.644599265343871],
              [126.806637767439, 37.644653116366406],
              [126.806655666501555, 37.644741804040827],
              [126.806658237366236, 37.6447546035809],
              [126.806984419880479, 37.645027414185492],
              [126.80707272975792, 37.645098377985441],
              [126.807322768275341, 37.645284031765406],
              [126.807403632359495, 37.645342817025345],
              [126.80746677075453, 37.645473354707789],
              [126.807530197447363, 37.645604485317641],
              [126.807568750091818, 37.645659423091409],
              [126.80758565218342, 37.645683846423104],
              [126.807598836280633, 37.645702898551683],
              [126.807600863049245, 37.645705828044477],
              [126.807738689416766, 37.645908324253327],
              [126.807880906205384, 37.64605602822197],
              [126.807961601524113, 37.646140347614477],
              [126.808164139321491, 37.646237480984851],
              [126.808251568113789, 37.646272698394419],
              [126.808354378937139, 37.646314908289703],
              [126.808389829655539, 37.646320011239091],
              [126.808460623947667, 37.646330038015066],
              [126.808536318626253, 37.646337979858977],
              [126.808594897429288, 37.646351969295573],
              [126.808670236775924, 37.64636523130963],
              [126.808739161443967, 37.64637861342014],
              [126.808744285138303, 37.646379706108306],
              [126.808864512546506, 37.646405361082437],
              [126.808966865251051, 37.646429294248563],
              [126.809282336193363, 37.646523818970358],
              [126.809367688610962, 37.646560690852844],
              [126.809652822946234, 37.646679398058474],
              [126.809752364216351, 37.646720572709789],
              [126.809920133730628, 37.646785786427131],
              [126.810024747102702, 37.646814552200524],
              [126.810165641046254, 37.64685801978154],
              [126.810301458083813, 37.646900494895085],
              [126.810432910871711, 37.646944343202236],
              [126.810540937013599, 37.646990115785741],
              [126.810615196680956, 37.647023779007071],
              [126.810619783453618, 37.647139383026939],
              [126.810760216599263, 37.647211965489873],
              [126.810793474761127, 37.647229182268575],
              [126.81097682683172, 37.647325712912171],
              [126.811133103124121, 37.647454157099496],
              [126.811153016257066, 37.647479453762799],
              [126.811254963891116, 37.647608989309653],
              [126.811287679184403, 37.647652250239275],
              [126.81141073942517, 37.647766863048723],
              [126.811488222755798, 37.647837634158229],
              [126.811547885799655, 37.647893627610408],
              [126.811617311581443, 37.647938897225437],
              [126.81171965290477, 37.647976180205561],
              [126.811872934125503, 37.648031827357336],
              [126.811887001649978, 37.648025692059186],
              [126.812319647080201, 37.647837042483687],
              [126.812630712733466, 37.647646984424583],
              [126.81268201327741, 37.64752840815337],
              [126.812686106420813, 37.647518952740356],
              [126.812720124830733, 37.647334039904777],
              [126.81286597866243, 37.647012173744557],
              [126.813068667735692, 37.646529035713129],
              [126.813093063964075, 37.646470690619395],
              [126.813146556863572, 37.646375814674286],
              [126.813235361420539, 37.646208463917027],
              [126.813330422257366, 37.646030942867995],
              [126.81335282517847, 37.6459889929802],
              [126.813271277255879, 37.645781053384503],
              [126.813232977671277, 37.645683394193298],
              [126.813224167321749, 37.645660922374958],
              [126.813231874875683, 37.645570118813602],
              [126.813247252828077, 37.645388354637689],
              [126.813247537611204, 37.645385001553443],
              [126.813149633977986, 37.645296818907234],
              [126.813124864559398, 37.645274246049851],
              [126.812775319909079, 37.644955833445074],
              [126.812788393631081, 37.644938644668862],
              [126.812793283371235, 37.644932153848494],
              [126.812854453404242, 37.644849645683969],
              [126.813041592379903, 37.644597671534953]
            ]
          ]
        ]
      },
      id: '31101610'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '행주동',
        ADM_CD: '31101640'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.830299010328957, 37.625409311262096],
              [126.830140012130698, 37.625280978440976],
              [126.830163306146773, 37.62500423240823],
              [126.82991927274422, 37.624944522516614],
              [126.829830494829366, 37.624919695875825],
              [126.829797253686237, 37.624923024065239],
              [126.82977717539282, 37.624925033555172],
              [126.829706811239078, 37.624932259116029],
              [126.829608805027732, 37.624942321809321],
              [126.829449320944533, 37.625005062202568],
              [126.829446388008279, 37.625006215463138],
              [126.829248195916193, 37.625159155384431],
              [126.829153489651233, 37.625234871425356],
              [126.829019782165332, 37.62535520865746],
              [126.828987852951443, 37.625181940769835],
              [126.828932640499261, 37.625052815663139],
              [126.828915665928236, 37.62501311641163],
              [126.828348482383717, 37.624945838746747],
              [126.828221895930568, 37.624933137606433],
              [126.828182172804617, 37.624929151084295],
              [126.828133103045062, 37.624896190300447],
              [126.828065520827764, 37.624850793857661],
              [126.827865143042217, 37.624751106399181],
              [126.827782200549422, 37.624709843230576],
              [126.827754164435461, 37.624675907901924],
              [126.827525023574637, 37.624398563057191],
              [126.827497040391194, 37.624364690160156],
              [126.827383256349052, 37.624241763330929],
              [126.827353894109152, 37.624023035631211],
              [126.827641318061794, 37.623747264623567],
              [126.828146706926518, 37.62326035335834],
              [126.828175990448202, 37.623166727499282],
              [126.828442727836276, 37.622313857136994],
              [126.828446684265003, 37.622301205123634],
              [126.828361385282292, 37.622078779229966],
              [126.828331648104694, 37.622001237813471],
              [126.828292704635231, 37.621899691982328],
              [126.828277510963161, 37.621856757063405],
              [126.828079223866183, 37.621619310535671],
              [126.828076950492374, 37.621600200165297],
              [126.828004054029321, 37.620987605645659],
              [126.827957117655657, 37.620600880421691],
              [126.82795342162305, 37.620568242860678],
              [126.828209664078301, 37.62050789584363],
              [126.828690622501782, 37.620394622613716],
              [126.828704724964709, 37.620391301216429],
              [126.828653181519812, 37.620220537183378],
              [126.828534122455309, 37.619826102774837],
              [126.828480266747619, 37.619647685801603],
              [126.828389817305364, 37.619392609505773],
              [126.828382231452636, 37.619322897836341],
              [126.828359726748928, 37.619257724183569],
              [126.828345866070549, 37.619260768405553],
              [126.828312706308083, 37.619268045390314],
              [126.828299147648409, 37.61927102192336],
              [126.828090831066419, 37.619316746654746],
              [126.827934116037625, 37.619351144950087],
              [126.827666468613344, 37.619409892344336],
              [126.827650381409853, 37.619413424037418],
              [126.827656238834209, 37.619401946496318],
              [126.827681059604245, 37.619353312316044],
              [126.827684162478434, 37.619347233382676],
              [126.827687455471249, 37.619339671939287],
              [126.827700663120268, 37.619308105915906],
              [126.82770295840858, 37.619302618161477],
              [126.827704792930092, 37.619298468063135],
              [126.827751533214609, 37.61919279804966],
              [126.827765882427784, 37.619158653500065],
              [126.8277766333605, 37.619134892530269],
              [126.827770320801847, 37.619103165718499],
              [126.827759526699467, 37.619048914313538],
              [126.827727947321719, 37.618892638936025],
              [126.827776700058564, 37.618793478075858],
              [126.827816544150153, 37.618712428296057],
              [126.827843260861144, 37.618664612468955],
              [126.827870733031617, 37.61861544533425],
              [126.827876511417571, 37.618605310315615],
              [126.827624559965443, 37.618671529850637],
              [126.827605148453728, 37.618666744027536],
              [126.827426966990757, 37.618622333905073],
              [126.827430881931718, 37.618611390607889],
              [126.827438152695123, 37.618591067342201],
              [126.827316778408573, 37.61856463432423],
              [126.827334359107155, 37.618535297025453],
              [126.827316453316442, 37.618508907586822],
              [126.827323700637336, 37.618495087308588],
              [126.827326652487471, 37.61847910728612],
              [126.827329011292719, 37.618466318205336],
              [126.827289145065166, 37.618461120086558],
              [126.827220958030935, 37.618452229064104],
              [126.827223151589294, 37.618440175431978],
              [126.827231201955271, 37.618395962297555],
              [126.827085656115941, 37.618380492939785],
              [126.826912105052941, 37.618361152873355],
              [126.826895457548957, 37.618359235417593],
              [126.826829998348686, 37.618351696406144],
              [126.826871073478415, 37.618330757524227],
              [126.826933277503045, 37.61829841921729],
              [126.826946579205298, 37.618291503452625],
              [126.82695491702367, 37.618279022487648],
              [126.826961127626646, 37.618269722810204],
              [126.827008633642322, 37.618200327966157],
              [126.827059009786154, 37.618066687160201],
              [126.827006206860162, 37.61802643354941],
              [126.827011208037831, 37.618023711205694],
              [126.827060027602485, 37.617994744991101],
              [126.82723518723104, 37.617893449736286],
              [126.827246101610911, 37.617884368844877],
              [126.827309355854496, 37.617841803493306],
              [126.827330842650412, 37.617827887726811],
              [126.827369051721007, 37.6178022940719],
              [126.827361218771927, 37.617793965273364],
              [126.827417494723207, 37.617803400327404],
              [126.827470197719123, 37.617812236335425],
              [126.827649624032986, 37.617855603431614],
              [126.82768686385657, 37.617866162913153],
              [126.827841124152016, 37.617918196857353],
              [126.827856762723115, 37.617835825716554],
              [126.827863832096156, 37.617798580555807],
              [126.827887232332714, 37.617750205019469],
              [126.827906385564077, 37.617715307252062],
              [126.827914861031161, 37.617699971579199],
              [126.827965649173166, 37.617608083346326],
              [126.828036170331501, 37.617480429012339],
              [126.828036501488512, 37.617435197514858],
              [126.828038728202429, 37.617110850277776],
              [126.827671269036614, 37.616927410835402],
              [126.827443441943757, 37.616705342781046],
              [126.827133757113756, 37.616568026760291],
              [126.826849804917742, 37.616710592574044],
              [126.826744575342033, 37.616763143827384],
              [126.826132350397174, 37.61677430936296],
              [126.826064349160006, 37.616632664139665],
              [126.82603499428059, 37.616632143160075],
              [126.825565019368625, 37.616623811106471],
              [126.82552432799028, 37.616587530626262],
              [126.825397453911634, 37.616259385423255],
              [126.825350213185899, 37.616204837984014],
              [126.825180590324592, 37.616008977392887],
              [126.825154124658653, 37.615978537652104],
              [126.825010683710772, 37.615914921376501],
              [126.824997874568808, 37.615909241815338],
              [126.82496644537882, 37.615895302280954],
              [126.824966062791219, 37.615895133345788],
              [126.824967296560558, 37.615894148036155],
              [126.825070621294557, 37.615811652399081],
              [126.825182072263686, 37.615731160836191],
              [126.825411383880066, 37.615554812054626],
              [126.825734076215326, 37.615305992970299],
              [126.825929853193728, 37.615156496934496],
              [126.826077913877413, 37.615044005396356],
              [126.826257255226722, 37.614908475734666],
              [126.826869776882404, 37.614442630091901],
              [126.827291555712208, 37.614120713090522],
              [126.827376374270045, 37.614056526831398],
              [126.827506671797607, 37.613957924508213],
              [126.827641340354546, 37.613855665898214],
              [126.827767666647517, 37.613759742850704],
              [126.828028247678304, 37.61356747910596],
              [126.828402199278642, 37.613298669862459],
              [126.828473543684055, 37.613244732689154],
              [126.828773219939478, 37.613074913917977],
              [126.828850084776363, 37.613031357024404],
              [126.828923454434815, 37.612989780262524],
              [126.82899132115746, 37.612951321422479],
              [126.829541129137198, 37.61265938776215],
              [126.829809028452431, 37.612518574563346],
              [126.830243748648883, 37.61237519428105],
              [126.830356925413312, 37.61235274440827],
              [126.830500044637731, 37.61232435596942],
              [126.830677404943984, 37.612289904516771],
              [126.830821844164618, 37.612251245265647],
              [126.830765133596529, 37.612182878379386],
              [126.830703729731965, 37.61215147197489],
              [126.830640452443888, 37.61212619840218],
              [126.830488766008543, 37.61206722531508],
              [126.830520742563834, 37.612055559601004],
              [126.83055407246988, 37.612002378014665],
              [126.830487064019749, 37.612008984997068],
              [126.830300554276278, 37.612051238465163],
              [126.830222764137048, 37.612058577282838],
              [126.830211521725687, 37.612057627461837],
              [126.830174991297653, 37.612054535846163],
              [126.830138744401808, 37.612029561447564],
              [126.830128280425299, 37.611990586967771],
              [126.830122269705342, 37.611955981293328],
              [126.830108463900103, 37.611920859069095],
              [126.830036601718902, 37.611842079239729],
              [126.830035600923608, 37.611840980240117],
              [126.82988660481729, 37.61167764180513],
              [126.829749521282835, 37.611528888623418],
              [126.829582337319167, 37.611344312924572],
              [126.829398873215368, 37.611141775530356],
              [126.829407161855059, 37.610915587855629],
              [126.8294365568958, 37.610113135126845],
              [126.829438981447382, 37.610039826291214],
              [126.829472028238484, 37.610015385119645],
              [126.829749531672491, 37.609806132356532],
              [126.829979618709146, 37.609628883786868],
              [126.830280727130841, 37.609396777047159],
              [126.83041582437086, 37.609305254269493],
              [126.830635143390012, 37.609179253543225],
              [126.830688758473769, 37.609159511071383],
              [126.830785214373734, 37.609125412618617],
              [126.830907026719075, 37.609097839301654],
              [126.831136772372957, 37.609067717583855],
              [126.831439505014288, 37.609064729887557],
              [126.831861465304669, 37.609075698571488],
              [126.832210949654836, 37.609082417762565],
              [126.833030976436461, 37.609099898377643],
              [126.833086583230028, 37.60910114913117],
              [126.834257614670804, 37.609125590537147],
              [126.834261180593188, 37.608978989702322],
              [126.834269103726214, 37.608719158080326],
              [126.834284589519697, 37.60817953632106],
              [126.834312832167228, 37.607228932851392],
              [126.834316711430716, 37.607090112252614],
              [126.83431705860518, 37.607077726268237],
              [126.834319243562533, 37.607021086108013],
              [126.834371203847425, 37.605007791025294],
              [126.834378926583852, 37.604698882203778],
              [126.834380894574636, 37.604623219923724],
              [126.834382766938091, 37.60455066039966],
              [126.834395268070054, 37.604066046111328],
              [126.834468320421024, 37.60141197438201],
              [126.834469339959981, 37.601378025021809],
              [126.834134276124473, 37.601374651440111],
              [126.833922741691623, 37.601374350077016],
              [126.833731814240693, 37.601373897877892],
              [126.833232457561721, 37.601357782106064],
              [126.83323344357899, 37.601321051047947],
              [126.833236432658552, 37.601265896292823],
              [126.833138589051089, 37.601252394881591],
              [126.832872222606113, 37.601197758453878],
              [126.832607293040354, 37.601126507123752],
              [126.832644950229565, 37.601099512358623],
              [126.832604964706618, 37.601073914555151],
              [126.832548556193998, 37.601065940508065],
              [126.832497822573444, 37.601062993731922],
              [126.832445926023681, 37.601070766247503],
              [126.832326296536934, 37.601115607856762],
              [126.83199089075373, 37.601141121269215],
              [126.831135606509903, 37.601032527049007],
              [126.830999207450915, 37.600934573755055],
              [126.830931605651159, 37.600857766299747],
              [126.830922410933468, 37.600847319973127],
              [126.83091795311411, 37.600832903979729],
              [126.830818452789842, 37.600511094888638],
              [126.830808454795289, 37.600297800259376],
              [126.830890331381312, 37.599781574814372],
              [126.830910937484333, 37.599644604810791],
              [126.830911156693602, 37.599643149495286],
              [126.830905696314787, 37.599607616733365],
              [126.830897789364826, 37.599583809094902],
              [126.830895961261362, 37.599577704800808],
              [126.830886010852524, 37.59954451799554],
              [126.830873019904033, 37.599521958723777],
              [126.830851622391549, 37.599484959260671],
              [126.830810914536414, 37.599414580203316],
              [126.830788406628528, 37.599289333676758],
              [126.830824544347507, 37.599111075031807],
              [126.830825845628993, 37.599104662196339],
              [126.830847658063291, 37.598995017610378],
              [126.830928040738229, 37.598888421700565],
              [126.831153317799831, 37.598679223203447],
              [126.83118715224461, 37.598654746288084],
              [126.831305304592476, 37.598545358188623],
              [126.831414798154853, 37.598443474109722],
              [126.83142813029994, 37.598431066134907],
              [126.831572238176605, 37.598257096263985],
              [126.83183567312733, 37.597937751168701],
              [126.831989658038239, 37.597820574876472],
              [126.832074392436482, 37.597755275856876],
              [126.832239633980976, 37.597696327946387],
              [126.832637285818663, 37.597662533648709],
              [126.833225678869397, 37.597615181682997],
              [126.833548722757371, 37.597591738253961],
              [126.833775714163011, 37.597491123786561],
              [126.833807257662528, 37.597463741691392],
              [126.833909230440312, 37.597376049410357],
              [126.833939894140713, 37.597350010712148],
              [126.833979771600539, 37.597197315078219],
              [126.833968810081572, 37.597144383549441],
              [126.833935287210025, 37.596982483854703],
              [126.833868782713921, 37.596862316438688],
              [126.833861490610076, 37.596849009416026],
              [126.833794888953292, 37.59672744163317],
              [126.833769698136678, 37.596732373056426],
              [126.833314554961447, 37.596821481424982],
              [126.832816980036924, 37.596915491196235],
              [126.832734241516462, 37.596915147829108],
              [126.832515204133898, 37.596912508573375],
              [126.832459893551345, 37.596912085784169],
              [126.832130366678214, 37.596867385510073],
              [126.832114740300952, 37.596853640939585],
              [126.831965915060778, 37.596722749377662],
              [126.831906811026869, 37.596654152134811],
              [126.831794913170427, 37.596524529356593],
              [126.83178659837057, 37.596512383646569],
              [126.831705694208296, 37.596351070419701],
              [126.831633014147641, 37.596199916699106],
              [126.831571561289252, 37.596073718717186],
              [126.831414362851433, 37.595698728744232],
              [126.831280259876635, 37.595378027093268],
              [126.831434859426508, 37.59448732992432],
              [126.8314578261554, 37.594356530094906],
              [126.831458764272185, 37.594351190484005],
              [126.831481741529174, 37.594166023896591],
              [126.83150833307586, 37.593908069613967],
              [126.83154661166013, 37.593362958004626],
              [126.831546645911303, 37.593328863341213],
              [126.831535896305994, 37.593011637443446],
              [126.831518751310497, 37.592466356060029],
              [126.831116699508073, 37.592371876003938],
              [126.830945310552437, 37.592327931557087],
              [126.829939356828021, 37.592295710258149],
              [126.828350056228629, 37.592244783613793],
              [126.827597067411546, 37.592220809239279],
              [126.825284276844741, 37.592147157056004],
              [126.824196997674974, 37.591778370332918],
              [126.823148973624043, 37.591421414256089],
              [126.822785016995553, 37.591297626281765],
              [126.822140522995184, 37.591078417950044],
              [126.821887674008039, 37.591237297945931],
              [126.821720415633209, 37.591340936959433],
              [126.821527627777712, 37.591466782122879],
              [126.821239688019688, 37.591651611261412],
              [126.82099816990393, 37.591807788281493],
              [126.82068861742205, 37.59200496260442],
              [126.820466959112792, 37.592145960751409],
              [126.820243520422508, 37.592286962642987],
              [126.820053936189581, 37.592403520218447],
              [126.819302531073717, 37.592849756114568],
              [126.818844314289748, 37.593490195919458],
              [126.818682867130576, 37.593715789596757],
              [126.818365179976539, 37.594160082263116],
              [126.81830960244676, 37.594237586207754],
              [126.81752598820502, 37.595330332808174],
              [126.816556266815283, 37.59568160699525],
              [126.815885716341484, 37.596181446011933],
              [126.815150451420379, 37.596727066622499],
              [126.814519584518507, 37.597198247399447],
              [126.813953233630045, 37.597619400727147],
              [126.813243358515123, 37.597990000375773],
              [126.812381601950946, 37.598439882832388],
              [126.811368920127791, 37.598960822925307],
              [126.811014475473144, 37.599213365078327],
              [126.810939727419566, 37.599266621189379],
              [126.810930712022724, 37.599273045543235],
              [126.810539356506936, 37.599551878980279],
              [126.810076880221104, 37.599886468323561],
              [126.809364809410084, 37.600396060530457],
              [126.809336079561092, 37.600416742069285],
              [126.809021439012568, 37.600643262393959],
              [126.808442663267499, 37.601060155494586],
              [126.808239682629988, 37.601208196202776],
              [126.807237041622002, 37.601792187671066],
              [126.806618018676261, 37.602143654534864],
              [126.806449369456132, 37.602239407332057],
              [126.80618240552036, 37.602394316939773],
              [126.806135345973274, 37.602421624691033],
              [126.805863859980519, 37.602580252924625],
              [126.80562397723817, 37.602760896781213],
              [126.805253796735471, 37.603036208188257],
              [126.804843194806139, 37.603342138380548],
              [126.804421584532648, 37.603655371536185],
              [126.803884379203907, 37.604058510800222],
              [126.803367724882278, 37.60444449752891],
              [126.803210671842933, 37.60456234987452],
              [126.803032917857479, 37.604695734111829],
              [126.802877670948519, 37.60481223025014],
              [126.802581401474043, 37.605032748586652],
              [126.802009354647524, 37.605395646823958],
              [126.802034935053939, 37.60542945570517],
              [126.802685429126271, 37.606289178360228],
              [126.802732093136811, 37.606352457271768],
              [126.804739339616205, 37.609074284023222],
              [126.804763678832714, 37.609587839219714],
              [126.804729816150754, 37.610102646831656],
              [126.804688787678558, 37.610366295479899],
              [126.804531537553714, 37.610716610962669],
              [126.804314166237532, 37.611026550823659],
              [126.803711162671476, 37.611668090668495],
              [126.803001997611346, 37.612275892587867],
              [126.802583958015418, 37.612736741727097],
              [126.8020986947148, 37.613366570103345],
              [126.801936264325008, 37.613502047586756],
              [126.801690589233644, 37.613709409216973],
              [126.803265409201913, 37.614909006230619],
              [126.803288988805292, 37.61492595348934],
              [126.803297826302028, 37.614932313188135],
              [126.803321156310531, 37.614949053463128],
              [126.803574260672292, 37.615131042823705],
              [126.803660761096907, 37.615159754172396],
              [126.803942709685444, 37.615253340868094],
              [126.804052488754792, 37.615289774850439],
              [126.807001052853209, 37.61626830651668],
              [126.807006662490934, 37.616270167361996],
              [126.807261125007713, 37.616354605653477],
              [126.810283162480133, 37.617357408126693],
              [126.812995150444522, 37.617721433055628],
              [126.813030801525784, 37.617726228393693],
              [126.813162396981241, 37.617743890810139],
              [126.813280015808374, 37.617759681696086],
              [126.813350584962748, 37.617769145342983],
              [126.813438365195779, 37.617780922760154],
              [126.816449915346013, 37.616792797404599],
              [126.81757856121952, 37.616422448668324],
              [126.817578705828026, 37.616422743338987],
              [126.817603115655643, 37.616471751139429],
              [126.817627188659898, 37.616464133483852],
              [126.818715369976204, 37.616114443509403],
              [126.818749385350344, 37.616105484157558],
              [126.819565497434439, 37.61585447227705],
              [126.819871550502739, 37.61575583543474],
              [126.819962237825067, 37.615728944964907],
              [126.820200278042208, 37.615648224961944],
              [126.820710353234517, 37.61548684039915],
              [126.82090305081519, 37.615424055848933],
              [126.820920317822114, 37.615418689536853],
              [126.821220428696705, 37.615325433790304],
              [126.821821197132309, 37.615128135984897],
              [126.822025195890504, 37.615074388209216],
              [126.822070543352524, 37.615056443620432],
              [126.822308613461516, 37.614966700772577],
              [126.822376612830325, 37.614948783401267],
              [126.822682722444981, 37.614823110376861],
              [126.822954853180818, 37.614697385815298],
              [126.822977525598233, 37.614688408828783],
              [126.823180257423772, 37.615166236281304],
              [126.823191537967361, 37.615184271756128],
              [126.823247890620181, 37.615301485206203],
              [126.823326743274961, 37.615481800172319],
              [126.82333798524688, 37.615517854856769],
              [126.823360553203528, 37.615553938431809],
              [126.823405587182819, 37.615671124754144],
              [126.823506988410159, 37.615887514027996],
              [126.823518303191051, 37.615896538141556],
              [126.823540914567303, 37.615914593471821],
              [126.823573749206474, 37.615930333290905],
              [126.823710616050221, 37.615995938024703],
              [126.823755857144491, 37.616023035384835],
              [126.823831260435128, 37.616173815190699],
              [126.823838968610048, 37.616189228885254],
              [126.823891129645304, 37.616293532544439],
              [126.823902412793842, 37.616311567964182],
              [126.823936241833394, 37.616374689229531],
              [126.82397016834139, 37.616392760632209],
              [126.824038050882066, 37.616428901774782],
              [126.824071968412881, 37.616455981015761],
              [126.824128685510999, 37.616420027244438],
              [126.824140009466078, 37.616420043431681],
              [126.824151337953325, 37.616420059643794],
              [126.824253366728385, 37.616438123511351],
              [126.824254704315692, 37.616439691378091],
              [126.824202015274679, 37.616479039263275],
              [126.824073519272616, 37.616574051862806],
              [126.824022010635005, 37.616612908891859],
              [126.823653535480034, 37.61689087217389],
              [126.823598227307301, 37.616929334084553],
              [126.823109183839605, 37.617299382219315],
              [126.822983076091958, 37.61739712031779],
              [126.822942770028732, 37.617371047855258],
              [126.822897454604288, 37.617341344909946],
              [126.822784968166843, 37.617428148474538],
              [126.822617943035326, 37.617556139813729],
              [126.822564690621078, 37.617596952891681],
              [126.822498218843847, 37.617647307263461],
              [126.822419556512003, 37.617708202082277],
              [126.82230350427173, 37.617796495335583],
              [126.822108769858843, 37.617946285055567],
              [126.822051080498937, 37.617990245097047],
              [126.821965143965301, 37.618055757701711],
              [126.821901914518151, 37.618104596293477],
              [126.821600782557027, 37.618333777743615],
              [126.821548912992156, 37.618373172395955],
              [126.821301768084723, 37.618560421620693],
              [126.821710283710843, 37.618895423472388],
              [126.821612011903355, 37.618968273847926],
              [126.820712107725839, 37.619635389560187],
              [126.820256142742394, 37.619343064848572],
              [126.820219221356609, 37.619167269055822],
              [126.820215652729701, 37.619148520618666],
              [126.820212328735792, 37.619150942276541],
              [126.820202249357735, 37.61915830397475],
              [126.820156761047173, 37.619193134368125],
              [126.819747953122487, 37.619535011457984],
              [126.819767426902828, 37.619492512348742],
              [126.819573159563376, 37.619640485213225],
              [126.819360608049195, 37.619803765061505],
              [126.81933062278182, 37.619827389061733],
              [126.819317558961643, 37.619837686230525],
              [126.819293356250824, 37.61985644574041],
              [126.819433507737429, 37.620106549962429],
              [126.819583840419412, 37.62037480064874],
              [126.819602278675291, 37.62042780613784],
              [126.819605505158094, 37.620442060378181],
              [126.819592446785506, 37.620452000680075],
              [126.819497776549625, 37.6205243526625],
              [126.819340848416019, 37.620393125762568],
              [126.819339622797287, 37.620392082160997],
              [126.818959359121621, 37.620684866883899],
              [126.819022610006527, 37.620740469150476],
              [126.819024575563645, 37.620742135339178],
              [126.818973838435369, 37.620782020283407],
              [126.819101068772255, 37.620871021957498],
              [126.81914194059776, 37.620835613734165],
              [126.819264638595314, 37.620953446118357],
              [126.81938282967738, 37.620864162385516],
              [126.819429384854146, 37.620719415635428],
              [126.819427629890285, 37.620718761962365],
              [126.819440213002224, 37.620695580176346],
              [126.819499639872419, 37.620590690617298],
              [126.819529123612227, 37.620543547937665],
              [126.819634725357304, 37.620464854097101],
              [126.819861413887068, 37.620624876486325],
              [126.819881734370085, 37.620639220234935],
              [126.81995090604795, 37.620688045669887],
              [126.820128312468398, 37.620883427576928],
              [126.820214131232618, 37.62096180429743],
              [126.820214835264807, 37.621002460221561],
              [126.820238298571851, 37.621056707750292],
              [126.820236973240384, 37.621074577130464],
              [126.820236683973107, 37.621078469217487],
              [126.820236167953098, 37.621085610200701],
              [126.820235813774801, 37.621090408653579],
              [126.820234371461083, 37.621109924994265],
              [126.820252221755453, 37.621110839025462],
              [126.820324291287037, 37.621114518802457],
              [126.820389671849114, 37.62110860589263],
              [126.820391139362457, 37.621108473737124],
              [126.820398748180224, 37.621164652505882],
              [126.820401087012328, 37.621166147765891],
              [126.820330117997472, 37.621264872877802],
              [126.820321291305163, 37.621277247794843],
              [126.820258301437534, 37.621365561570748],
              [126.82025384118262, 37.621370801456607],
              [126.8203203638666, 37.621413466132402],
              [126.820403227344997, 37.621468776832693],
              [126.820590954012459, 37.621593839684067],
              [126.82059961941134, 37.621599685141518],
              [126.820685580042323, 37.62151567119151],
              [126.820693935523764, 37.621507506359507],
              [126.82072074149373, 37.621524717481847],
              [126.82072129578448, 37.621525072192192],
              [126.820907042608511, 37.621644376253194],
              [126.821104943564151, 37.621771499602346],
              [126.821108875354909, 37.621774026123695],
              [126.821117145937393, 37.621798123042254],
              [126.821127701646517, 37.621828877724965],
              [126.82112787466238, 37.621829540297895],
              [126.821150129590805, 37.621913093799876],
              [126.821152441732124, 37.621921765294665],
              [126.821153120548345, 37.62192462642161],
              [126.821143060019679, 37.621965772019905],
              [126.821262538985707, 37.621981545842566],
              [126.82134947256732, 37.622036781946647],
              [126.821667391922887, 37.622238777782982],
              [126.821699091982182, 37.622258922711424],
              [126.821777096163999, 37.622308486464377],
              [126.821862678542118, 37.622362858158581],
              [126.82188334855654, 37.622375992180686],
              [126.822115325264406, 37.622523371503092],
              [126.82211651280393, 37.622525372071252],
              [126.82217600582274, 37.622625608435847],
              [126.822169272037314, 37.622696039468416],
              [126.822132845395672, 37.622722664652755],
              [126.822126393268448, 37.62272738120889],
              [126.822105371371919, 37.62275847174962],
              [126.822011648956135, 37.622839446092456],
              [126.821872955214843, 37.622959274296072],
              [126.821810947656701, 37.623012848190129],
              [126.821809705386471, 37.62301487625674],
              [126.821758068781335, 37.62309912255639],
              [126.821734203020085, 37.623164557243435],
              [126.821732324723698, 37.623169736313599],
              [126.821731729131059, 37.62317143099861],
              [126.82173158969924, 37.623171810559022],
              [126.821727706085923, 37.623182429355211],
              [126.821713534858461, 37.623221287219323],
              [126.821703338228929, 37.623303613913258],
              [126.821702799946308, 37.623307980474273],
              [126.821702747883123, 37.623308472302],
              [126.821701839864303, 37.623320854982971],
              [126.821701767187221, 37.623321869464512],
              [126.821695898574546, 37.623400289428076],
              [126.821691350249395, 37.623476575416497],
              [126.821690489963416, 37.62349093769545],
              [126.821687982006793, 37.62353423225737],
              [126.821684960031746, 37.623586704826664],
              [126.821693616123824, 37.623682650851556],
              [126.821693673202134, 37.623683103648077],
              [126.821702337352775, 37.623746239448643],
              [126.821706743642721, 37.623763903835666],
              [126.821706324047227, 37.623772667773551],
              [126.82170494456291, 37.623802016209687],
              [126.821704828779517, 37.623804532061577],
              [126.821704381399798, 37.623814108840676],
              [126.821703325204638, 37.623815860365411],
              [126.821694362348182, 37.62383074029831],
              [126.82168576252198, 37.623840088142295],
              [126.821619007846138, 37.623859039729943],
              [126.821482417196776, 37.623893079992534],
              [126.821435224452557, 37.623897497328493],
              [126.821394194047926, 37.623884963594278],
              [126.821306579896387, 37.623852992389679],
              [126.82126646272981, 37.623835492119767],
              [126.821225440308339, 37.623816603986199],
              [126.82120517909317, 37.62380658630277],
              [126.821166185517228, 37.623864167062706],
              [126.82110271501989, 37.623958342543574],
              [126.821047414544708, 37.623939666029948],
              [126.8209347860561, 37.624030279501049],
              [126.821003928313374, 37.624084501309206],
              [126.82101354936313, 37.624091962012315],
              [126.821039119430395, 37.624096403094292],
              [126.821107569184377, 37.624226284021482],
              [126.820983599081359, 37.624270813902662],
              [126.820891677232112, 37.624334888708475],
              [126.820886866853712, 37.62433824101479],
              [126.820845372319553, 37.624406595301885],
              [126.820841217826825, 37.624423051331192],
              [126.820817207804666, 37.624518153204939],
              [126.820805049924402, 37.624566311657986],
              [126.820792121457572, 37.62460076904302],
              [126.820782888414612, 37.624625385331775],
              [126.820748029323042, 37.624682078496527],
              [126.820734484092156, 37.624704110175962],
              [126.820783980058508, 37.624734815037989],
              [126.820808364992843, 37.624749966177191],
              [126.820895475441631, 37.624786075567194],
              [126.821009766802462, 37.624833454724289],
              [126.821032370652617, 37.624842846876987],
              [126.821270132834655, 37.62498522447649],
              [126.821288089483303, 37.62499598150341],
              [126.821362643696574, 37.625040640457534],
              [126.82154324153575, 37.625147504341818],
              [126.821587590679727, 37.625187973137876],
              [126.821591755878643, 37.625191771861566],
              [126.821593185483678, 37.625193076195579],
              [126.821606162112985, 37.62520491137564],
              [126.821612756868106, 37.625244878266585],
              [126.821615038678985, 37.625258649305948],
              [126.821615156222379, 37.625258932999749],
              [126.821615335896084, 37.625259363066462],
              [126.821618973353381, 37.625268112471488],
              [126.821637420784015, 37.625312414407901],
              [126.821764389475646, 37.625600268783231],
              [126.821825561487202, 37.625804288796651],
              [126.821883034368454, 37.625996272768894],
              [126.821887499392403, 37.626010858533562],
              [126.821888071350713, 37.626012765432094],
              [126.821902130359547, 37.626059657764927],
              [126.821959209480681, 37.626249995402468],
              [126.821990424370199, 37.626356182131126],
              [126.821992403120305, 37.626362906615654],
              [126.822215516461071, 37.62712181491397],
              [126.822221444740322, 37.627141631381114],
              [126.82222610565718, 37.627157247575994],
              [126.822226523519532, 37.627158654250003],
              [126.822227260252291, 37.627161137142302],
              [126.822451350138252, 37.627911356283917],
              [126.822524106188212, 37.628074356125495],
              [126.822540648374456, 37.62807723982506],
              [126.823185922556135, 37.628189845451402],
              [126.823190753973094, 37.628190687968683],
              [126.823192457987886, 37.628190984366505],
              [126.823227665100333, 37.628197124284384],
              [126.823243351894305, 37.628199864174157],
              [126.823295112397446, 37.628208909367039],
              [126.823294788921061, 37.62821033882522],
              [126.823149264436793, 37.628850553122355],
              [126.823157027630586, 37.628848027962427],
              [126.823663926590953, 37.628683281780098],
              [126.824318072229275, 37.628470674219926],
              [126.825022356891608, 37.628241767164241],
              [126.825415096110703, 37.628114113430982],
              [126.826477255893167, 37.627768874096084],
              [126.826538945781678, 37.627748821893348],
              [126.826710197673577, 37.627693156892093],
              [126.82672033379805, 37.627689864484282],
              [126.826921118652791, 37.627624597265147],
              [126.827028373088453, 37.627589736268249],
              [126.827237210159595, 37.627521849198452],
              [126.827362581485474, 37.627481101849249],
              [126.827648051043695, 37.62738830816194],
              [126.827797119772654, 37.627339848393397],
              [126.82793122968323, 37.627296258750157],
              [126.828010690488313, 37.627270417696465],
              [126.828044277701764, 37.62725948835655],
              [126.828053664750669, 37.627256434025597],
              [126.82809617961172, 37.627242622581122],
              [126.828126333511051, 37.627233025663244],
              [126.830599023922588, 37.626445754974547],
              [126.830594660450245, 37.626421422209795],
              [126.830586143620849, 37.626412002746711],
              [126.830564359032508, 37.626402304306474],
              [126.830542182450927, 37.62622682443633],
              [126.83053362767356, 37.626168825688275],
              [126.830462661938611, 37.626097581962377],
              [126.830394416317546, 37.626050947362977],
              [126.830352013034215, 37.625731732682361],
              [126.830348496613794, 37.625697501783137],
              [126.83034426640441, 37.625656307651234],
              [126.830330881751181, 37.62549994168544],
              [126.830319027766848, 37.625493293784594],
              [126.830306015876786, 37.625452388708538],
              [126.830299010328957, 37.625409311262096]
            ]
          ]
        ]
      },
      id: '31101640'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '행신2동',
        ADM_CD: '31101660'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.830747684734092, 37.619182953442014],
              [126.830837357582922, 37.619164478138345],
              [126.83085900182077, 37.619160274483058],
              [126.830908562294027, 37.619158302186619],
              [126.831051195184543, 37.619152622183691],
              [126.831080661468192, 37.61915144804523],
              [126.831116837163407, 37.61915000547932],
              [126.831243698012472, 37.619144952396951],
              [126.831422112095879, 37.619137842416372],
              [126.831809470945061, 37.619141129866513],
              [126.832123074162411, 37.619143797610754],
              [126.832124819089017, 37.619143808388444],
              [126.832166029187817, 37.61914364143994],
              [126.832362377428026, 37.619142848930196],
              [126.832605387465108, 37.619141866649692],
              [126.832635176306937, 37.619141746703875],
              [126.83265550894879, 37.619141650785657],
              [126.832855920720974, 37.619140717294712],
              [126.833478620710324, 37.619137815198954],
              [126.833573426972578, 37.619136995074804],
              [126.83362597436367, 37.61913653922489],
              [126.833626013195698, 37.619138028445889],
              [126.834073181075667, 37.619130653554656],
              [126.834087304274433, 37.619130416890492],
              [126.834090718943997, 37.619130355942175],
              [126.834094317187734, 37.619130294227034],
              [126.834120734368327, 37.619129859241511],
              [126.834138066964982, 37.619129581194265],
              [126.834143284661778, 37.619129496168156],
              [126.834156222382745, 37.61912928435283],
              [126.834870159712011, 37.619117485205557],
              [126.835750010576049, 37.618821345780262],
              [126.835776848591877, 37.618812314076607],
              [126.835786893336774, 37.61880892302031],
              [126.835791764517438, 37.618807282072773],
              [126.83579906332848, 37.618804823760946],
              [126.836121050913846, 37.618696448700092],
              [126.83673089370177, 37.618491172730579],
              [126.836887131100923, 37.618438581954756],
              [126.836963975695738, 37.618412713772102],
              [126.837390751214301, 37.61826905182587],
              [126.837602404195607, 37.61819778738505],
              [126.837864901831495, 37.618109419360991],
              [126.837914555907034, 37.618092705142836],
              [126.837967858174437, 37.618074760321321],
              [126.838856805844344, 37.618042507595099],
              [126.838868115692009, 37.618042098664127],
              [126.838874315792893, 37.618041877423401],
              [126.838877074744602, 37.618041770299833],
              [126.838885855874892, 37.618041444543344],
              [126.838896124129548, 37.618041064925983],
              [126.839401744319559, 37.61802272250295],
              [126.839997820577324, 37.618001077559533],
              [126.840013207548751, 37.618000519168376],
              [126.840171158902464, 37.617994787618045],
              [126.84020170835953, 37.617993684855939],
              [126.840428921036434, 37.617985420797524],
              [126.841031364558262, 37.617963550241647],
              [126.841038516798946, 37.617963290940338],
              [126.84143809250682, 37.617948786608068],
              [126.841844528049904, 37.61793401273016],
              [126.841845634296178, 37.61793397563634],
              [126.841848925881379, 37.617933859696699],
              [126.841856134793844, 37.617933597957936],
              [126.841860237974515, 37.617933449589984],
              [126.841927879299817, 37.617930997551866],
              [126.841948599319011, 37.617930237908524],
              [126.842343542421901, 37.618042456475514],
              [126.842533143656638, 37.61809632937031],
              [126.842577449844583, 37.618108918182649],
              [126.842590495773749, 37.618112609719127],
              [126.842987368035068, 37.618225367768197],
              [126.843254595370638, 37.618281852021312],
              [126.843762040328713, 37.618389101872523],
              [126.843762712664684, 37.618389185830289],
              [126.844113563063928, 37.618433702880935],
              [126.844129668301264, 37.618435746489645],
              [126.844133368202264, 37.618436233043894],
              [126.844206901981053, 37.618445539700375],
              [126.844439231566923, 37.618428126195255],
              [126.844457180646259, 37.618426786482836],
              [126.844495629070323, 37.618420408624822],
              [126.844700337831014, 37.618386437797923],
              [126.84490231726808, 37.618352921057266],
              [126.844995623493091, 37.618321602129825],
              [126.844995989462802, 37.618321476171928],
              [126.845094326747002, 37.618288468052619],
              [126.845095123404803, 37.618288201172767],
              [126.845190744477279, 37.618256101764175],
              [126.845288970290198, 37.618223122607006],
              [126.84531573093912, 37.618201049608651],
              [126.845386238268929, 37.618142872128246],
              [126.845570529905686, 37.617990811881867],
              [126.84560163827571, 37.617958511162584],
              [126.845758818977245, 37.617795367085137],
              [126.84579051472646, 37.617768818276204],
              [126.845830799993209, 37.617748672380124],
              [126.845855381778406, 37.617736378363276],
              [126.845870541007997, 37.61772908227988],
              [126.845934391546592, 37.617657196134942],
              [126.846046431368208, 37.617528324319075],
              [126.846135617960158, 37.617506368136929],
              [126.846246834001988, 37.617348573173274],
              [126.846286887077099, 37.617314657961387],
              [126.846324756295843, 37.617260330754128],
              [126.846425537840233, 37.61717698204847],
              [126.846431024400161, 37.617172442675326],
              [126.846440408907881, 37.617164658458066],
              [126.846449909584067, 37.61715680638207],
              [126.846459751672313, 37.617152538997424],
              [126.846525427603638, 37.617124035708237],
              [126.846526124867154, 37.617123730412146],
              [126.846555835476565, 37.617110835518609],
              [126.846552009985473, 37.617045208961386],
              [126.846546296475893, 37.616947246517093],
              [126.846490846755799, 37.616578324600958],
              [126.846311459915583, 37.616158331577104],
              [126.84630290038119, 37.616138292576636],
              [126.846278795194763, 37.616081852716299],
              [126.846198707605581, 37.615894335376261],
              [126.846149653715315, 37.615779482417246],
              [126.846099998184201, 37.61567253620818],
              [126.846036699499464, 37.615536210201419],
              [126.846036554787688, 37.615535914663255],
              [126.846015240611877, 37.615499026624207],
              [126.846010327218622, 37.615490530460406],
              [126.845977723943449, 37.615434140780081],
              [126.845947964706511, 37.615382670110421],
              [126.845878432999143, 37.615309909389019],
              [126.845819269374203, 37.615262719262375],
              [126.845772902181807, 37.615237320396503],
              [126.845695919043052, 37.615195150988967],
              [126.845572584782602, 37.615127584478508],
              [126.845511976744106, 37.615097855840986],
              [126.8453291555861, 37.615008179292744],
              [126.845109759104716, 37.614868152868709],
              [126.845080588817936, 37.614849617284875],
              [126.844851201726925, 37.614703798842406],
              [126.844826376501132, 37.61468801745967],
              [126.844747425003618, 37.614637735315483],
              [126.844672385232116, 37.61459013197458],
              [126.84427181145783, 37.614333444830095],
              [126.844173668883428, 37.614255949842089],
              [126.844105791297039, 37.614202354218449],
              [126.844061627014625, 37.614172811774964],
              [126.844051001337064, 37.6141657024761],
              [126.843997910099333, 37.614130186819203],
              [126.843952895428018, 37.614100077175131],
              [126.843850945014253, 37.614041053417857],
              [126.843759220710851, 37.614008702834923],
              [126.843724265542903, 37.613996375581451],
              [126.84348399013858, 37.613948936722892],
              [126.84324244044565, 37.613919008611042],
              [126.843200153532152, 37.613913753725086],
              [126.8431980423657, 37.613657304339036],
              [126.843197982510489, 37.613649939205615],
              [126.843197544686831, 37.613597562051133],
              [126.843197541234801, 37.613597052778196],
              [126.843263632976431, 37.613484591624584],
              [126.843274833948016, 37.613469812594737],
              [126.84328212581714, 37.613460197183812],
              [126.84332471195934, 37.613427015412718],
              [126.843382729356136, 37.613381810068191],
              [126.843382745068453, 37.613374224528059],
              [126.843382753756359, 37.613368726438566],
              [126.843492794345536, 37.613276659912522],
              [126.843812571980607, 37.613009112677283],
              [126.843966618112773, 37.612880226628739],
              [126.84398862025337, 37.61286181841492],
              [126.844311850804431, 37.612609672962172],
              [126.84431004665413, 37.61260377812637],
              [126.84428438347787, 37.612519384211609],
              [126.844280031389587, 37.612505073880776],
              [126.844290572356499, 37.612297019780016],
              [126.844291974474032, 37.612269341285938],
              [126.844302655130008, 37.612269516003963],
              [126.844646737284634, 37.612265945469275],
              [126.84480568348603, 37.612264599980108],
              [126.8449271196707, 37.612264544075622],
              [126.845088289951804, 37.612265142111383],
              [126.845132570159649, 37.612558529705296],
              [126.8451434297492, 37.612558589969559],
              [126.845135347642412, 37.612595563818736],
              [126.845285864156381, 37.612766688086268],
              [126.845367012228266, 37.612747682981094],
              [126.845517465270831, 37.612712444319406],
              [126.845543823432735, 37.612713824430642],
              [126.84554124040848, 37.612654578021903],
              [126.845674550861304, 37.612665190501161],
              [126.845668863373703, 37.612605926880477],
              [126.845828616113124, 37.612608046513323],
              [126.845538345238225, 37.61227696598295],
              [126.845526549305418, 37.612266491051869],
              [126.845546558133123, 37.612266542533028],
              [126.845672874303219, 37.612266871656054],
              [126.845771462629813, 37.612267373206343],
              [126.845965149732848, 37.612268356719504],
              [126.846071939661002, 37.612268903474892],
              [126.846387680577564, 37.612273832630713],
              [126.846941691372223, 37.612282046504554],
              [126.847285398705807, 37.612285190155333],
              [126.847660098229738, 37.612293084716043],
              [126.847886841865403, 37.612299093381459],
              [126.848093815519221, 37.612302353289515],
              [126.848441401426427, 37.612310165885766],
              [126.84884958853803, 37.612313569045568],
              [126.84906917176653, 37.612314619148911],
              [126.849152416520965, 37.612314752922224],
              [126.849359079818143, 37.612314893887422],
              [126.849850362190281, 37.612263773910499],
              [126.850188837320161, 37.612217303271059],
              [126.85036856398898, 37.612190847176244],
              [126.850490540754365, 37.612172788869835],
              [126.850615405866833, 37.612154302874899],
              [126.850641948333617, 37.612150373099148],
              [126.850977502114972, 37.612070199439849],
              [126.851107869937223, 37.612038425066039],
              [126.851396462514472, 37.611963051162633],
              [126.851402424406899, 37.611961494878045],
              [126.851467335275927, 37.611944542398014],
              [126.851906764310371, 37.611785724913595],
              [126.852204183490741, 37.611637087439242],
              [126.85254262483528, 37.611462942079569],
              [126.852693404934087, 37.611385103455866],
              [126.853038226878169, 37.611207093384827],
              [126.853477833674305, 37.610974243438932],
              [126.853736632185985, 37.610837162797999],
              [126.854004986515321, 37.610697215370585],
              [126.854230262800158, 37.610577216095692],
              [126.854342239679923, 37.610737504807886],
              [126.854563352522149, 37.610632231562725],
              [126.854595405743055, 37.610684873581548],
              [126.854604695968163, 37.610700129912281],
              [126.854673760500475, 37.610813555749957],
              [126.854715303506126, 37.610881721945887],
              [126.855165214401609, 37.610657955197098],
              [126.855573104246801, 37.610454920501667],
              [126.855822531651157, 37.610331775502566],
              [126.855877416096661, 37.610304881809718],
              [126.856091868646828, 37.610199797363492],
              [126.856186439890379, 37.610152883291391],
              [126.856820618816215, 37.60983952572888],
              [126.857235684458118, 37.609630115840282],
              [126.857328859020413, 37.609581288713805],
              [126.857552545396061, 37.609464794997848],
              [126.857772233081647, 37.609354457745212],
              [126.85801831635537, 37.609229358293149],
              [126.858167784429867, 37.609155345157433],
              [126.858297997509581, 37.609086702741557],
              [126.858412305089516, 37.609030778979701],
              [126.858432982777998, 37.609020661239931],
              [126.858412662044927, 37.608964694105765],
              [126.858389485003229, 37.608907815510747],
              [126.858352852195623, 37.608817901010873],
              [126.858309506443973, 37.608711520453788],
              [126.858074435600059, 37.608581813415306],
              [126.858012239184632, 37.60854749563098],
              [126.857787646672207, 37.608348528719681],
              [126.857720731395659, 37.608322290811628],
              [126.857520479988594, 37.608243689514801],
              [126.856986349539227, 37.608092032069059],
              [126.856401013002397, 37.607925849152323],
              [126.856137404371822, 37.607822793744994],
              [126.855860895997807, 37.607713895187324],
              [126.855621108329544, 37.607725860982335],
              [126.855295137832428, 37.607875732122608],
              [126.854768162082365, 37.608062346983765],
              [126.854495517099835, 37.607937194550324],
              [126.854267863588817, 37.607715251711532],
              [126.854274327639146, 37.607561471484253],
              [126.854280489037691, 37.607401283805309],
              [126.854289287081045, 37.607183267342187],
              [126.854297174832539, 37.607002394433863],
              [126.854192098786484, 37.606698867963644],
              [126.854056693470724, 37.606586634389032],
              [126.853888827921637, 37.606553334071499],
              [126.85368591168077, 37.606513082444387],
              [126.853563653793103, 37.606487414273673],
              [126.853290732476424, 37.606361574326783],
              [126.853213363802183, 37.606274989848949],
              [126.853179085015711, 37.606233980484468],
              [126.85310772648613, 37.606142595014681],
              [126.853289577547557, 37.605689036410041],
              [126.853419937171182, 37.605419778125366],
              [126.853543186032255, 37.604956728685266],
              [126.853562992211593, 37.604877467348118],
              [126.853666759852672, 37.60448238981968],
              [126.853691216995742, 37.604301576072473],
              [126.853649629002604, 37.604217264482628],
              [126.853621027003669, 37.604154755212988],
              [126.853581759904642, 37.604068940262415],
              [126.853364706076718, 37.603912753831885],
              [126.852948034059978, 37.603894020663589],
              [126.852568596197443, 37.603892280382809],
              [126.852241439808154, 37.603926563823563],
              [126.851972040060133, 37.603835296463203],
              [126.851840968828128, 37.603734660881635],
              [126.851736144694129, 37.603589795664064],
              [126.851740489535189, 37.603432985272576],
              [126.851744516242874, 37.60327797580122],
              [126.851946714322565, 37.603147445267666],
              [126.852027039128274, 37.603128827572341],
              [126.852363013441007, 37.603050954013078],
              [126.85304084856638, 37.60289679469355],
              [126.853116040438877, 37.602887121817794],
              [126.853250559099891, 37.602883750789587],
              [126.85378742828803, 37.603077053891759],
              [126.853838993665008, 37.603079975345544],
              [126.853889201311631, 37.603082696457633],
              [126.853996998196706, 37.603088831591073],
              [126.854139448609246, 37.602727201117396],
              [126.85414080479687, 37.602693903266122],
              [126.854145741930566, 37.60263126398528],
              [126.854133669803929, 37.602528022865677],
              [126.854120526127105, 37.602474560283504],
              [126.854035586187933, 37.602336073780009],
              [126.853900373115735, 37.602196910001361],
              [126.853769884341688, 37.60212771382632],
              [126.853571642324368, 37.602074848175135],
              [126.85330677664976, 37.602037386013613],
              [126.853184579267648, 37.602046412025246],
              [126.853101234653806, 37.602052064467046],
              [126.852916751235568, 37.602118444203022],
              [126.852692006136209, 37.602258442989935],
              [126.852531894708008, 37.602255791762417],
              [126.852286131811354, 37.602074824020342],
              [126.852123769921775, 37.601862754252657],
              [126.852048282967843, 37.601590984445352],
              [126.852072273867435, 37.601345524558994],
              [126.85221300510679, 37.601008681751189],
              [126.852490031466985, 37.600704704835771],
              [126.852784994423203, 37.600434834838886],
              [126.852871040628358, 37.600255974975688],
              [126.852821288630921, 37.600087231553793],
              [126.85264588942492, 37.599861160795321],
              [126.85250422675945, 37.599771079245699],
              [126.85222751043969, 37.599670731012417],
              [126.851844824222994, 37.59972872025412],
              [126.851635793239268, 37.599877493190192],
              [126.851459264932842, 37.600203751663464],
              [126.851308846527203, 37.600407452639743],
              [126.851069817099557, 37.60059402935012],
              [126.850934556754368, 37.600698622914834],
              [126.85075101932992, 37.600881051538003],
              [126.850674544478736, 37.600957074038149],
              [126.850667780612255, 37.600963803155281],
              [126.85045893538063, 37.601235433415127],
              [126.850413057305886, 37.601460249965918],
              [126.850419147213884, 37.601708624136336],
              [126.850466195947646, 37.60191780108962],
              [126.850448020896053, 37.602045664648301],
              [126.850196416524895, 37.602276321344299],
              [126.850176233122852, 37.602294822802044],
              [126.84991228108008, 37.602369760971115],
              [126.849694918431254, 37.602331967397866],
              [126.849671180316435, 37.602317368345993],
              [126.84944907942041, 37.602180799636983],
              [126.849414409167764, 37.602036502909058],
              [126.849412092559987, 37.602026862967904],
              [126.849492593572236, 37.601850032835188],
              [126.84958848593341, 37.60169720712387],
              [126.849649967764989, 37.601599221888122],
              [126.849720816821517, 37.601484609006363],
              [126.849776335606109, 37.601252477426328],
              [126.849825548992868, 37.600862786339306],
              [126.849793548403341, 37.600834894852063],
              [126.849534931300141, 37.600609522842646],
              [126.849307419789554, 37.600505181951966],
              [126.849174634210172, 37.600444286472609],
              [126.849067524967083, 37.600342420713055],
              [126.849013564850551, 37.60029110294262],
              [126.848967518916538, 37.600188168713736],
              [126.848978058540624, 37.600140629084507],
              [126.849037179572591, 37.599873817126714],
              [126.849154372682491, 37.599672425315006],
              [126.84918970322947, 37.599466471888128],
              [126.84910449960239, 37.599229914951472],
              [126.848945077961886, 37.599048766515345],
              [126.848678071646304, 37.598927903152678],
              [126.848674960702695, 37.598926497024671],
              [126.848410856429325, 37.598958390457675],
              [126.848236424965705, 37.599019810595181],
              [126.847774270476407, 37.599330666553158],
              [126.847769540244371, 37.599333467002857],
              [126.847511520487117, 37.599486168409285],
              [126.847319364631645, 37.599473971685832],
              [126.846912173057717, 37.599346832648088],
              [126.846607959589491, 37.599219674696606],
              [126.846527686986178, 37.599153905905212],
              [126.846361021188329, 37.599017357982071],
              [126.846256576279643, 37.598900635078842],
              [126.846119064511825, 37.598604254775744],
              [126.846109991638542, 37.598399105399771],
              [126.846105971342311, 37.598159504283785],
              [126.846261651555153, 37.597918192021069],
              [126.846332158159768, 37.597891205349974],
              [126.846639289590939, 37.597773647465829],
              [126.847066132804855, 37.597616940884294],
              [126.847150471312887, 37.597439196962966],
              [126.847243655982538, 37.597252704855073],
              [126.847335017710165, 37.596948304135623],
              [126.847360775955011, 37.596949776948897],
              [126.847378225480341, 37.5968797485396],
              [126.847377451843784, 37.596673135341533],
              [126.847351973847765, 37.596529350213252],
              [126.84734937082878, 37.596519219214699],
              [126.84729578683303, 37.59641323033383],
              [126.8470978846279, 37.59621250222073],
              [126.846963541700092, 37.596098864721],
              [126.846918212913991, 37.596066775981825],
              [126.84672120993369, 37.595956859193876],
              [126.846655366725386, 37.595917769686594],
              [126.846385085210088, 37.595898747584094],
              [126.846242614174756, 37.595887242500268],
              [126.846010699569277, 37.595898181580523],
              [126.845678907050967, 37.595940755967469],
              [126.845459554219502, 37.595997533487754],
              [126.845305237269841, 37.596052913773747],
              [126.845253422656484, 37.596133714199951],
              [126.845233751063816, 37.596172458991965],
              [126.845237431695665, 37.596296666489742],
              [126.845309004889074, 37.596488615619016],
              [126.845365191663149, 37.596630017121612],
              [126.845367474315381, 37.596751677787147],
              [126.845319576978667, 37.597025577085013],
              [126.845277240803, 37.597117253333394],
              [126.845203666424567, 37.597292176761478],
              [126.845138929875219, 37.597438918413069],
              [126.84509099052238, 37.597489609467608],
              [126.845024297019393, 37.597560129482844],
              [126.844897445380113, 37.597609756970492],
              [126.844816927573717, 37.597623993687037],
              [126.844694825002634, 37.597602514743031],
              [126.844487789089001, 37.597551876231712],
              [126.844198208459389, 37.597472048616744],
              [126.843996720368025, 37.597367888965969],
              [126.843810800924331, 37.597259729773867],
              [126.843729357319447, 37.597199989716394],
              [126.843664806030347, 37.597152642280911],
              [126.843567217118107, 37.597036786146134],
              [126.843507858058814, 37.596966316673679],
              [126.843396610588201, 37.596830199889418],
              [126.843299644943244, 37.596549431243069],
              [126.843264700485577, 37.596440284131852],
              [126.843299323444285, 37.596214417047904],
              [126.84331915891697, 37.596139867895104],
              [126.84346270640485, 37.596065732977578],
              [126.843896286002149, 37.59601409092091],
              [126.844288267868819, 37.595990752489833],
              [126.844594232536679, 37.595934652841045],
              [126.844791537107255, 37.595829076937186],
              [126.845006362528451, 37.595493645277628],
              [126.845084578547599, 37.595090496720829],
              [126.845128216330636, 37.594734117685462],
              [126.845164426836192, 37.594442426019619],
              [126.845181946959372, 37.59402530331834],
              [126.84518592995596, 37.593912551922507],
              [126.845092505935526, 37.593428554524941],
              [126.845024494156917, 37.592771970279919],
              [126.845013770357014, 37.592670154298148],
              [126.845009466908181, 37.592654022617793],
              [126.844929419231406, 37.592354077502954],
              [126.844786391294875, 37.591807413857758],
              [126.844698866845391, 37.591646895491493],
              [126.844601543784464, 37.591480551383675],
              [126.844320990117183, 37.591254734103018],
              [126.844183227550161, 37.591184275502037],
              [126.844041421222229, 37.591126493210389],
              [126.843786490038823, 37.591069158051511],
              [126.843597230481876, 37.591025324578951],
              [126.843391259640285, 37.591016252557125],
              [126.843272193011202, 37.591030817310269],
              [126.843145921404698, 37.59111843129832],
              [126.843054218510147, 37.591219847757593],
              [126.843026264748872, 37.591475322262298],
              [126.843024885694575, 37.591559009167909],
              [126.843028178242548, 37.591806048004642],
              [126.843022946840478, 37.591998093863985],
              [126.842976394815366, 37.592166300076762],
              [126.842541879048468, 37.59251867292717],
              [126.842414048737723, 37.59267143237399],
              [126.842375305173661, 37.592717730913556],
              [126.842328431464679, 37.592801925022606],
              [126.84226744386936, 37.592913522227953],
              [126.842288201304186, 37.59321455109211],
              [126.842309284066346, 37.593470909685223],
              [126.842328146782151, 37.593711307636696],
              [126.842322847242713, 37.594109849311678],
              [126.84226727011368, 37.594190899683532],
              [126.841880904798671, 37.594555789216642],
              [126.841810016931262, 37.594621610628671],
              [126.841588849673926, 37.594828888689804],
              [126.841492533056737, 37.594920668055885],
              [126.841326040396297, 37.595082976502553],
              [126.841245683946482, 37.595201727098285],
              [126.841108918766608, 37.595403838329261],
              [126.841023128207553, 37.595484639193948],
              [126.840816575500696, 37.595651374115896],
              [126.840597964777515, 37.59573567535886],
              [126.840379552232662, 37.595814152864364],
              [126.84023493804726, 37.595836894666427],
              [126.840024966257999, 37.595841759669831],
              [126.839893440746877, 37.595825184032094],
              [126.839719839752448, 37.59580497662926],
              [126.839473040301883, 37.595714496738211],
              [126.839316526748419, 37.59565134014408],
              [126.839114795153023, 37.595572452397626],
              [126.838832278813385, 37.595555305805568],
              [126.838404489595533, 37.595533214361026],
              [126.838059247454112, 37.595582551995001],
              [126.837661482332763, 37.595670641573683],
              [126.837422333497301, 37.595733429340221],
              [126.837085437886529, 37.595827638941579],
              [126.836594840328843, 37.595992519188158],
              [126.836234867026064, 37.596101729163372],
              [126.835799322523357, 37.596213216774579],
              [126.835610461369626, 37.596270417046831],
              [126.835517011143835, 37.596299459285902],
              [126.835231935544485, 37.596385398923623],
              [126.834980239979004, 37.59645940929596],
              [126.834700617039928, 37.596537644000875],
              [126.834364915093246, 37.596627664916141],
              [126.834320814564009, 37.596639475162554],
              [126.834141720882698, 37.596670181108294],
              [126.834030743871082, 37.596688962518158],
              [126.8339887003565, 37.596695821490293],
              [126.833794888953292, 37.59672744163317],
              [126.833861490610076, 37.596849009416026],
              [126.833868782713921, 37.596862316438688],
              [126.833935287210025, 37.596982483854703],
              [126.833968810081572, 37.597144383549441],
              [126.833979771600539, 37.597197315078219],
              [126.833939894140713, 37.597350010712148],
              [126.833909230440312, 37.597376049410357],
              [126.833807257662528, 37.597463741691392],
              [126.833775714163011, 37.597491123786561],
              [126.833548722757371, 37.597591738253961],
              [126.833225678869397, 37.597615181682997],
              [126.832637285818663, 37.597662533648709],
              [126.832239633980976, 37.597696327946387],
              [126.832074392436482, 37.597755275856876],
              [126.831989658038239, 37.597820574876472],
              [126.83183567312733, 37.597937751168701],
              [126.831572238176605, 37.598257096263985],
              [126.83142813029994, 37.598431066134907],
              [126.831414798154853, 37.598443474109722],
              [126.831305304592476, 37.598545358188623],
              [126.83118715224461, 37.598654746288084],
              [126.831153317799831, 37.598679223203447],
              [126.830928040738229, 37.598888421700565],
              [126.830847658063291, 37.598995017610378],
              [126.830825845628993, 37.599104662196339],
              [126.830824544347507, 37.599111075031807],
              [126.830788406628528, 37.599289333676758],
              [126.830810914536414, 37.599414580203316],
              [126.830851622391549, 37.599484959260671],
              [126.830873019904033, 37.599521958723777],
              [126.830886010852524, 37.59954451799554],
              [126.830895961261362, 37.599577704800808],
              [126.830897789364826, 37.599583809094902],
              [126.830905696314787, 37.599607616733365],
              [126.830911156693602, 37.599643149495286],
              [126.830910937484333, 37.599644604810791],
              [126.830890331381312, 37.599781574814372],
              [126.830808454795289, 37.600297800259376],
              [126.830818452789842, 37.600511094888638],
              [126.83091795311411, 37.600832903979729],
              [126.830922410933468, 37.600847319973127],
              [126.830931605651159, 37.600857766299747],
              [126.830999207450915, 37.600934573755055],
              [126.831135606509903, 37.601032527049007],
              [126.83199089075373, 37.601141121269215],
              [126.832326296536934, 37.601115607856762],
              [126.832445926023681, 37.601070766247503],
              [126.832497822573444, 37.601062993731922],
              [126.832548556193998, 37.601065940508065],
              [126.832604964706618, 37.601073914555151],
              [126.832644950229565, 37.601099512358623],
              [126.832607293040354, 37.601126507123752],
              [126.832872222606113, 37.601197758453878],
              [126.833138589051089, 37.601252394881591],
              [126.833236432658552, 37.601265896292823],
              [126.83323344357899, 37.601321051047947],
              [126.833232457561721, 37.601357782106064],
              [126.833731814240693, 37.601373897877892],
              [126.833922741691623, 37.601374350077016],
              [126.834134276124473, 37.601374651440111],
              [126.834469339959981, 37.601378025021809],
              [126.834468320421024, 37.60141197438201],
              [126.834395268070054, 37.604066046111328],
              [126.834382766938091, 37.60455066039966],
              [126.834380894574636, 37.604623219923724],
              [126.834378926583852, 37.604698882203778],
              [126.834371203847425, 37.605007791025294],
              [126.834319243562533, 37.607021086108013],
              [126.83431705860518, 37.607077726268237],
              [126.834316711430716, 37.607090112252614],
              [126.834312832167228, 37.607228932851392],
              [126.834284589519697, 37.60817953632106],
              [126.834269103726214, 37.608719158080326],
              [126.834261180593188, 37.608978989702322],
              [126.834257614670804, 37.609125590537147],
              [126.833086583230028, 37.60910114913117],
              [126.833030976436461, 37.609099898377643],
              [126.832210949654836, 37.609082417762565],
              [126.831861465304669, 37.609075698571488],
              [126.831439505014288, 37.609064729887557],
              [126.831136772372957, 37.609067717583855],
              [126.830907026719075, 37.609097839301654],
              [126.830785214373734, 37.609125412618617],
              [126.830688758473769, 37.609159511071383],
              [126.830635143390012, 37.609179253543225],
              [126.83041582437086, 37.609305254269493],
              [126.830280727130841, 37.609396777047159],
              [126.829979618709146, 37.609628883786868],
              [126.829749531672491, 37.609806132356532],
              [126.829472028238484, 37.610015385119645],
              [126.829438981447382, 37.610039826291214],
              [126.8294365568958, 37.610113135126845],
              [126.829407161855059, 37.610915587855629],
              [126.829398873215368, 37.611141775530356],
              [126.829582337319167, 37.611344312924572],
              [126.829749521282835, 37.611528888623418],
              [126.82988660481729, 37.61167764180513],
              [126.830035600923608, 37.611840980240117],
              [126.830036601718902, 37.611842079239729],
              [126.830108463900103, 37.611920859069095],
              [126.830122269705342, 37.611955981293328],
              [126.830128280425299, 37.611990586967771],
              [126.830138744401808, 37.612029561447564],
              [126.830174991297653, 37.612054535846163],
              [126.830211521725687, 37.612057627461837],
              [126.830222764137048, 37.612058577282838],
              [126.830300554276278, 37.612051238465163],
              [126.830487064019749, 37.612008984997068],
              [126.83055407246988, 37.612002378014665],
              [126.830520742563834, 37.612055559601004],
              [126.830488766008543, 37.61206722531508],
              [126.830640452443888, 37.61212619840218],
              [126.830703729731965, 37.61215147197489],
              [126.830765133596529, 37.612182878379386],
              [126.830821844164618, 37.612251245265647],
              [126.830677404943984, 37.612289904516771],
              [126.830500044637731, 37.61232435596942],
              [126.830356925413312, 37.61235274440827],
              [126.830243748648883, 37.61237519428105],
              [126.829809028452431, 37.612518574563346],
              [126.829541129137198, 37.61265938776215],
              [126.82899132115746, 37.612951321422479],
              [126.828923454434815, 37.612989780262524],
              [126.828850084776363, 37.613031357024404],
              [126.828773219939478, 37.613074913917977],
              [126.828473543684055, 37.613244732689154],
              [126.828402199278642, 37.613298669862459],
              [126.828028247678304, 37.61356747910596],
              [126.827767666647517, 37.613759742850704],
              [126.827641340354546, 37.613855665898214],
              [126.827506671797607, 37.613957924508213],
              [126.827376374270045, 37.614056526831398],
              [126.827291555712208, 37.614120713090522],
              [126.826869776882404, 37.614442630091901],
              [126.826257255226722, 37.614908475734666],
              [126.826077913877413, 37.615044005396356],
              [126.825929853193728, 37.615156496934496],
              [126.825734076215326, 37.615305992970299],
              [126.825411383880066, 37.615554812054626],
              [126.825182072263686, 37.615731160836191],
              [126.825070621294557, 37.615811652399081],
              [126.824967296560558, 37.615894148036155],
              [126.824966062791219, 37.615895133345788],
              [126.82496644537882, 37.615895302280954],
              [126.824997874568808, 37.615909241815338],
              [126.825010683710772, 37.615914921376501],
              [126.825154124658653, 37.615978537652104],
              [126.825180590324592, 37.616008977392887],
              [126.825350213185899, 37.616204837984014],
              [126.825397453911634, 37.616259385423255],
              [126.82552432799028, 37.616587530626262],
              [126.825565019368625, 37.616623811106471],
              [126.82603499428059, 37.616632143160075],
              [126.826064349160006, 37.616632664139665],
              [126.826132350397174, 37.61677430936296],
              [126.826744575342033, 37.616763143827384],
              [126.826849804917742, 37.616710592574044],
              [126.827133757113756, 37.616568026760291],
              [126.827443441943757, 37.616705342781046],
              [126.827671269036614, 37.616927410835402],
              [126.828038728202429, 37.617110850277776],
              [126.828036501488512, 37.617435197514858],
              [126.828131780082217, 37.617450403831455],
              [126.828255095730441, 37.617470080756739],
              [126.828261590426251, 37.617471118208528],
              [126.828452983123199, 37.617532123686168],
              [126.828618277238348, 37.617549656904856],
              [126.82856880243078, 37.617603201187976],
              [126.828332617070373, 37.617798971289375],
              [126.828321518200781, 37.617808171109075],
              [126.828288835926259, 37.617835244075259],
              [126.828283920103132, 37.617839317155699],
              [126.828260872971441, 37.617858420396828],
              [126.828253245845104, 37.617864734076946],
              [126.828214503562904, 37.617896852434619],
              [126.828328327439081, 37.617986484459585],
              [126.828578219063303, 37.618183265963381],
              [126.828600514591699, 37.618202887747614],
              [126.828690917739337, 37.618282448065251],
              [126.828731861805835, 37.618322278338105],
              [126.828749887423314, 37.618339801837088],
              [126.828785170118891, 37.618374126994205],
              [126.828845526501553, 37.618431397571271],
              [126.828894911596137, 37.61847826022418],
              [126.829070626816403, 37.618630352541622],
              [126.829343305117021, 37.618866372604025],
              [126.829448727584278, 37.618957624160764],
              [126.829510087709849, 37.619010736844928],
              [126.829591525506643, 37.619056443752413],
              [126.829741596060927, 37.619140137758514],
              [126.829767151030339, 37.619153326097326],
              [126.829767532253626, 37.619153523851843],
              [126.829801725836546, 37.619171174174468],
              [126.82987003788152, 37.619166498461674],
              [126.829886229171038, 37.619169910922345],
              [126.829937355689978, 37.619180687362025],
              [126.830097116551784, 37.619177848529148],
              [126.830241575356041, 37.619201182049849],
              [126.830377024177139, 37.619223059086508],
              [126.830565038569361, 37.619202537171468],
              [126.830747684734092, 37.619182953442014]
            ]
          ]
        ]
      },
      id: '31101660'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '화전동',
        ADM_CD: '31101670'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.873460439187184, 37.616670168668669],
              [126.873515839995534, 37.616661623418651],
              [126.873617634950421, 37.61679954889118],
              [126.873717051122924, 37.616804368732033],
              [126.873804729744023, 37.616737286202593],
              [126.873831477655685, 37.616654054658689],
              [126.87388340862833, 37.616582299130222],
              [126.873887418665774, 37.616576757367916],
              [126.873900044868847, 37.616492556751467],
              [126.87402101715395, 37.616421221541749],
              [126.874255252316587, 37.61648734648319],
              [126.874392162607776, 37.6165025307306],
              [126.874685023434964, 37.616759579042778],
              [126.874866045441635, 37.616813652043788],
              [126.874911758550027, 37.616825773802887],
              [126.875025338440125, 37.61688557239043],
              [126.87510399048584, 37.616926980744189],
              [126.87512271530646, 37.616933304522256],
              [126.875250304508768, 37.616976393320435],
              [126.875308121725539, 37.616859428522979],
              [126.875450865438012, 37.616573013365894],
              [126.875492855447916, 37.616390421414756],
              [126.875633158298044, 37.616274029958838],
              [126.875569377874257, 37.616228354413714],
              [126.875564615397835, 37.61622497894956],
              [126.875458337187624, 37.616135167206735],
              [126.875365180523431, 37.616056445450589],
              [126.875302521348473, 37.615984326918209],
              [126.875209102734829, 37.615827537212482],
              [126.875151069825421, 37.615619663260013],
              [126.876080295931757, 37.615410547169603],
              [126.877114798640477, 37.615299379584606],
              [126.877856405273292, 37.615323945952284],
              [126.879198552311365, 37.614713129185454],
              [126.880127049570234, 37.614590471238913],
              [126.880700315041793, 37.614980103668714],
              [126.880919495157798, 37.615019610986515],
              [126.881983412771618, 37.6152113694734],
              [126.882169820223424, 37.615244966728604],
              [126.883199273071099, 37.615430503880908],
              [126.883237368198948, 37.615384560925875],
              [126.883287228504372, 37.615324428429375],
              [126.883621926055838, 37.615270451186056],
              [126.884950204233192, 37.615056223793992],
              [126.885927900522447, 37.614898528071286],
              [126.885930145841499, 37.614898166602501],
              [126.886071075960231, 37.614875433615261],
              [126.886137194436856, 37.614864767769234],
              [126.886160380590368, 37.614861028862769],
              [126.886457788620348, 37.614813058877722],
              [126.886422792872679, 37.614523729291527],
              [126.886363261271413, 37.614031536416675],
              [126.886754264120299, 37.613934467136566],
              [126.88706680767099, 37.61385685359739],
              [126.887039224397469, 37.613825956740122],
              [126.886711569027071, 37.613460203822854],
              [126.886777090230353, 37.613131905491166],
              [126.886830628503262, 37.612863688293608],
              [126.886996098395471, 37.612265510050641],
              [126.887177369409699, 37.611708698371231],
              [126.887386396196092, 37.611228857651973],
              [126.887405877292764, 37.611136584422219],
              [126.887732503550765, 37.609589404828327],
              [126.888304752681776, 37.609756276895652],
              [126.889156318745265, 37.609842053329814],
              [126.889614846016428, 37.609885294288894],
              [126.890516336647408, 37.610308642580549],
              [126.891067901141355, 37.610300962358622],
              [126.891263281216709, 37.610469052948801],
              [126.891540053313065, 37.610707169527551],
              [126.891658913452616, 37.610809433260989],
              [126.891792789599933, 37.61082726134007],
              [126.891996085389977, 37.610967686224924],
              [126.892234453227076, 37.611132337250019],
              [126.892432430334949, 37.611298211169149],
              [126.892520580074404, 37.611374408797467],
              [126.892534042539779, 37.611381518438598],
              [126.892553567152433, 37.611397760078695],
              [126.892556666251352, 37.611396191481617],
              [126.892594513109415, 37.611416030020933],
              [126.892622594661901, 37.611441282189446],
              [126.89263930113799, 37.611453527205576],
              [126.892679853765557, 37.611483249278876],
              [126.892790496169752, 37.611522165104915],
              [126.892907263058419, 37.611562286776632],
              [126.893078850476968, 37.61162314161809],
              [126.893167643733889, 37.611654155366743],
              [126.893430611668549, 37.611440673764115],
              [126.893584013871106, 37.611316139419692],
              [126.894178521519422, 37.611297669044312],
              [126.89434863803676, 37.611289172860474],
              [126.894368852139692, 37.611269198427905],
              [126.894389025211055, 37.611249252623963],
              [126.894523689377692, 37.611116119475085],
              [126.894597658486603, 37.611042981444427],
              [126.894627514982261, 37.611024890653226],
              [126.894796383145035, 37.610922608738306],
              [126.894949456244618, 37.610829892602567],
              [126.895001399790175, 37.610802528436672],
              [126.895090687979803, 37.610755491120344],
              [126.89541597963894, 37.610584120372849],
              [126.895767310699128, 37.610399031205503],
              [126.895844475817626, 37.610452430298757],
              [126.895991023063644, 37.610561417503767],
              [126.896261828278057, 37.610762822530241],
              [126.8966690974855, 37.611028974595023],
              [126.896884033980854, 37.611356768213469],
              [126.896906664890707, 37.611391279785671],
              [126.897026611196722, 37.611574207010229],
              [126.897204307799484, 37.611845206695214],
              [126.89722169754495, 37.611871727221576],
              [126.89755338495759, 37.612172155531432],
              [126.897607521095281, 37.612221179785728],
              [126.89789865766393, 37.612470638633646],
              [126.898027438327659, 37.612488069511208],
              [126.898465139581702, 37.612547311934165],
              [126.89861623952352, 37.612548363281419],
              [126.898680524390457, 37.612548806242685],
              [126.898689366679406, 37.612548796317931],
              [126.899212903496945, 37.612567432756663],
              [126.900194594978657, 37.612193912930159],
              [126.900224865027937, 37.612191653904134],
              [126.900514629776566, 37.612170032237273],
              [126.900839105258754, 37.61217644642182],
              [126.900864619312145, 37.612176950918474],
              [126.90079052746195, 37.612044873189888],
              [126.900304323939821, 37.611191344880432],
              [126.900705263280344, 37.610318854053325],
              [126.900601150423853, 37.609319522037488],
              [126.900675625801199, 37.608942020953734],
              [126.900775752159845, 37.60843925148712],
              [126.900874744606057, 37.608121170988113],
              [126.901246746205572, 37.606778742899415],
              [126.90141386441762, 37.606190712511832],
              [126.901579423339953, 37.605978558817782],
              [126.901637560224614, 37.605905971429159],
              [126.901655425401046, 37.605882219821893],
              [126.901658844752362, 37.605877674586488],
              [126.901725502637333, 37.605789057698864],
              [126.901743386071388, 37.605765282735398],
              [126.901725884183563, 37.605752312090466],
              [126.901717632484377, 37.605745732854849],
              [126.901708563494495, 37.605738502314715],
              [126.901715527201901, 37.60573459516656],
              [126.901721926448744, 37.6057310033287],
              [126.901749529436145, 37.605715273405238],
              [126.901699118189157, 37.605641194670532],
              [126.901505409825504, 37.605356538906982],
              [126.901823464546226, 37.604763007934437],
              [126.901848602722055, 37.604621682970823],
              [126.902013034917871, 37.604301876984493],
              [126.902029916387292, 37.604269046557739],
              [126.902097029436916, 37.603818580543532],
              [126.902108299841359, 37.603741684997821],
              [126.901951116415177, 37.603674475274822],
              [126.901442038906524, 37.603456796382169],
              [126.901306839986162, 37.603398985590978],
              [126.901044543858291, 37.60338470515606],
              [126.900770912482983, 37.603369808655899],
              [126.90015265671903, 37.603116445560637],
              [126.900204541870636, 37.602567727609419],
              [126.899982304104697, 37.602040183934584],
              [126.900011893488767, 37.601963333035009],
              [126.900180413117837, 37.601525651580758],
              [126.90029321967873, 37.60123266524409],
              [126.900529024652656, 37.600880635901881],
              [126.90101249087445, 37.600175469560625],
              [126.901016795749356, 37.59987307786951],
              [126.901322688352295, 37.599366534499914],
              [126.901297260702293, 37.599192793241777],
              [126.901261222473337, 37.598488590873664],
              [126.901199178973243, 37.598294511672634],
              [126.901179253854167, 37.598232173125197],
              [126.901172101573579, 37.598209798952944],
              [126.901171371079755, 37.598207515760343],
              [126.901253507991029, 37.597908855597765],
              [126.901094608678292, 37.597524955054013],
              [126.901011881367481, 37.597337092758217],
              [126.901024756133566, 37.597257196705336],
              [126.901076295635434, 37.596937336863476],
              [126.901201110531872, 37.596746541975428],
              [126.901224701992916, 37.59643938161836],
              [126.901210550090127, 37.596301690644808],
              [126.901360242032126, 37.596193725958663],
              [126.901426992066646, 37.596145580567935],
              [126.90162815308544, 37.596000494410937],
              [126.901754999501961, 37.595640047388926],
              [126.901784261094079, 37.595556892120662],
              [126.901791812104292, 37.595473277086292],
              [126.901829758568852, 37.595141350177819],
              [126.901792663071134, 37.595117514625258],
              [126.901213450889813, 37.594745324861641],
              [126.901173394138894, 37.59471958488318],
              [126.900355099449968, 37.594211235394816],
              [126.900020700541234, 37.593831976565795],
              [126.90000348959498, 37.593801210179841],
              [126.899895726067868, 37.593608571524491],
              [126.899878093135001, 37.593577046749964],
              [126.899826585807602, 37.593467757906012],
              [126.899778212184145, 37.593386627501019],
              [126.899719011523231, 37.593346862170748],
              [126.899413912549349, 37.593141920971682],
              [126.899299471420562, 37.592868148842932],
              [126.899064939640922, 37.592736403124476],
              [126.898961630859844, 37.592678369812333],
              [126.89896628123887, 37.592670701460712],
              [126.898999981911103, 37.592615118900873],
              [126.899060988990186, 37.592514499316863],
              [126.899112165968688, 37.592430093427907],
              [126.899282406872487, 37.592149311167596],
              [126.899566455451705, 37.591689774379837],
              [126.899568293701236, 37.591685140929712],
              [126.899687587941344, 37.591384396465074],
              [126.899696411479724, 37.591362153910509],
              [126.899709796730775, 37.591004384624974],
              [126.899710356709988, 37.590989383767919],
              [126.899656143501289, 37.590862272371965],
              [126.899575450482587, 37.59067307714956],
              [126.899592381678374, 37.590601931278108],
              [126.89966647282904, 37.590290579693928],
              [126.899687599394596, 37.590201801495397],
              [126.899750355142444, 37.589938087496108],
              [126.899649289838763, 37.589823121312548],
              [126.899643711696953, 37.589816871890235],
              [126.899554216080787, 37.589801435469042],
              [126.899498096677078, 37.58979173907364],
              [126.89948428155445, 37.589809746063978],
              [126.899468744295746, 37.589787208320701],
              [126.898427997674872, 37.58960076618866],
              [126.898082090326596, 37.589538797677356],
              [126.898070106703969, 37.589493907512392],
              [126.897962409954118, 37.589090452881713],
              [126.897572669709433, 37.588962838225292],
              [126.896991760543045, 37.588639592357694],
              [126.896958406963222, 37.588621031910641],
              [126.896940709121139, 37.588611184481117],
              [126.896859034919984, 37.588566622217805],
              [126.896414215814517, 37.588738260155736],
              [126.896102058821612, 37.588735302429953],
              [126.895954433970601, 37.588733903475394],
              [126.895687388371513, 37.588798147783692],
              [126.895231219605151, 37.588864464636501],
              [126.89428257218124, 37.588820930883344],
              [126.894267472438798, 37.588820237778208],
              [126.893974248805819, 37.589001005197552],
              [126.893840577883878, 37.589071277692227],
              [126.893667934980954, 37.589055632406719],
              [126.893327886019861, 37.589067430414879],
              [126.893225907260273, 37.589003980060092],
              [126.892341535647134, 37.588573660826199],
              [126.892235727827114, 37.588522175021218],
              [126.892052064348107, 37.588548745278509],
              [126.891766736961443, 37.588530739317086],
              [126.89166330643927, 37.588485037431994],
              [126.891558759633043, 37.588483533218287],
              [126.891449218562045, 37.588466209883364],
              [126.891444138280676, 37.588465406735303],
              [126.891439706875389, 37.588466290635658],
              [126.891112570282303, 37.588531500959917],
              [126.891083303251364, 37.588537333973967],
              [126.890936616299399, 37.588566572832299],
              [126.890711768430549, 37.588679832068308],
              [126.890322435162901, 37.588875427821272],
              [126.890224547935219, 37.58892460401178],
              [126.889602083096037, 37.589124607182029],
              [126.889578865669748, 37.589132066477873],
              [126.889258390450777, 37.588832294109409],
              [126.889253453475078, 37.588827676237031],
              [126.889205183428672, 37.588821711061961],
              [126.889075616213873, 37.588805697896525],
              [126.888911368738363, 37.588785400341962],
              [126.888732026121843, 37.588788518708839],
              [126.888343654878028, 37.58879527229017],
              [126.887848492206345, 37.588708877051474],
              [126.887612994948995, 37.588667786490674],
              [126.887608605325283, 37.588667020110549],
              [126.887522317855215, 37.588636528931815],
              [126.887322935166225, 37.588523993010796],
              [126.887305600925558, 37.588523698427537],
              [126.887159281169204, 37.588532845430571],
              [126.887054724203452, 37.588648467180704],
              [126.886891712270938, 37.588814991221781],
              [126.886739862047648, 37.588974486691285],
              [126.886557532156047, 37.589162103083986],
              [126.886510950231084, 37.589187549156428],
              [126.88625705595291, 37.58932624062178],
              [126.886133823375786, 37.589393414760231],
              [126.885824445479628, 37.589530023042514],
              [126.885904776990969, 37.589591331917575],
              [126.885905996965079, 37.589592426181767],
              [126.885906977900333, 37.589593663416601],
              [126.885907213265753, 37.589594105396237],
              [126.885907695145264, 37.589595011045603],
              [126.885908127468539, 37.589596433806094],
              [126.885908266127473, 37.589597892894936],
              [126.885908104637238, 37.589599350422176],
              [126.885907648979455, 37.58960076766131],
              [126.885906910776711, 37.589602108619253],
              [126.885905910727516, 37.58960333554738],
              [126.88590467287645, 37.589604417023949],
              [126.885903174518901, 37.589605359240153],
              [126.885839861405501, 37.589639047272144],
              [126.885812463097622, 37.5896536253831],
              [126.885810762492326, 37.589654368251018],
              [126.885808925667774, 37.589654871555069],
              [126.885807007138823, 37.589655117552148],
              [126.885805062471405, 37.589655098419357],
              [126.885803151688066, 37.589654815370359],
              [126.885801329088821, 37.589654276799791],
              [126.88580014654184, 37.589653728039728],
              [126.885799650061912, 37.589653496515929],
              [126.8857981619486, 37.58965250270672],
              [126.885796910987082, 37.589651319948906],
              [126.885795932007696, 37.589649982674658],
              [126.885786425639225, 37.589633960567006],
              [126.885807618804449, 37.589684336755759],
              [126.885826533353935, 37.589729297553006],
              [126.885835705322492, 37.589751831898205],
              [126.885859558949818, 37.589810790054102],
              [126.885860203950301, 37.589812383373157],
              [126.885871885116615, 37.589806763910353],
              [126.885880876720393, 37.589936856864405],
              [126.885832510460048, 37.589997406862921],
              [126.885814580102547, 37.590019853358996],
              [126.885810514241768, 37.590072306385359],
              [126.885775958049607, 37.590518167919285],
              [126.885769466966252, 37.590542786674632],
              [126.885735730121979, 37.59067073740286],
              [126.885726924857536, 37.590726369115103],
              [126.885714118457926, 37.590807269782978],
              [126.885651938350264, 37.590863798131963],
              [126.885638629226051, 37.590889131304507],
              [126.88562993073387, 37.590905168625746],
              [126.885562295825864, 37.590936565329883],
              [126.88554034969944, 37.590946751587147],
              [126.885489361676719, 37.590970349994471],
              [126.885484535502925, 37.591001034400463],
              [126.885478742030102, 37.591035433597312],
              [126.885467039544992, 37.591104919109839],
              [126.885476481832711, 37.591143763719259],
              [126.885493052400335, 37.591211936264472],
              [126.885502582420727, 37.591227990963382],
              [126.885533638700338, 37.591280667380424],
              [126.885536399141756, 37.591325439557416],
              [126.885540203629532, 37.591384854018131],
              [126.885561185129248, 37.591427672307006],
              [126.885595394032336, 37.591498379285348],
              [126.885597655980206, 37.591501834176036],
              [126.885642659960325, 37.591477024724469],
              [126.885644307462542, 37.591476270766911],
              [126.885646090135495, 37.591475746453057],
              [126.885647956883275, 37.591475466839739],
              [126.885649857801795, 37.591475439779288],
              [126.885651736250423, 37.591475665878157],
              [126.885653541303569, 37.591476139463275],
              [126.885655222132044, 37.59147684314464],
              [126.885656730142301, 37.591477763149229],
              [126.885658024780625, 37.591478872225792],
              [126.885659067810479, 37.59148013682573],
              [126.885695329426227, 37.591533328818336],
              [126.885695935567128, 37.591534414481593],
              [126.885696224831989, 37.591534776522842],
              [126.885742474157325, 37.591605548827957],
              [126.885742745973332, 37.591605966661319],
              [126.885743422125415, 37.591607216732413],
              [126.885743853080271, 37.5916085313252],
              [126.885744022587929, 37.591609958126107],
              [126.885749165429431, 37.591629490773919],
              [126.885749296253834, 37.591629973671253],
              [126.88574952599312, 37.591631376449428],
              [126.885749478102539, 37.591632793105546],
              [126.885749150635277, 37.591634184871815],
              [126.885748554059418, 37.591635518453231],
              [126.88574770112325, 37.591636758763876],
              [126.885746613562276, 37.591637879777998],
              [126.88574531880505, 37.591638851894174],
              [126.885743848773714, 37.591639650041017],
              [126.885742239876649, 37.59164025457855],
              [126.885741651694616, 37.591640429081302],
              [126.885704486587031, 37.59165166914547],
              [126.885715268662395, 37.591666584740658],
              [126.885766764810811, 37.591744251645949],
              [126.885769444452606, 37.591748293676154],
              [126.885899492004043, 37.591854845295003],
              [126.885995086916893, 37.591936594761414],
              [126.886144148333017, 37.592100045616434],
              [126.886161733536341, 37.592142425206077],
              [126.886175351194467, 37.592175243483602],
              [126.886215094300482, 37.592327883750194],
              [126.886270324270356, 37.59230879936932],
              [126.886271598219452, 37.592310775419776],
              [126.886404642939439, 37.592517281648604],
              [126.886438885996085, 37.592567429005655],
              [126.886522633464367, 37.59266125027905],
              [126.886527966033256, 37.592667225031903],
              [126.886563403953232, 37.592706923929946],
              [126.886598381987852, 37.592816594087836],
              [126.886598496865133, 37.592816950713839],
              [126.886620446260864, 37.592885770260807],
              [126.886677517088941, 37.593044335677],
              [126.886721434161373, 37.593135892064495],
              [126.88671911435074, 37.593150241947477],
              [126.886710096109425, 37.593146575309753],
              [126.88671518342565, 37.593173330954563],
              [126.886762173277404, 37.593225741912093],
              [126.886882650234313, 37.593343275098917],
              [126.886919045256235, 37.593377934966028],
              [126.886955039738879, 37.59343822682392],
              [126.886986981584471, 37.59348978617993],
              [126.887007297383022, 37.593510637815868],
              [126.887170709219475, 37.593666494189918],
              [126.887181663218826, 37.593678334270784],
              [126.887251213803893, 37.593779203684385],
              [126.887317225428674, 37.593883717726747],
              [126.887321123551331, 37.593890586335739],
              [126.887337688601974, 37.593919777461629],
              [126.887303193786707, 37.593923122633264],
              [126.887287552524057, 37.593907399598805],
              [126.887272331673856, 37.593915143302702],
              [126.887214397560243, 37.593900163320129],
              [126.887154425433636, 37.593884657161034],
              [126.88703649330543, 37.593854162655234],
              [126.886869317360308, 37.593811084614394],
              [126.886845674743185, 37.593804991322678],
              [126.886799254370601, 37.59380598859704],
              [126.886732789863913, 37.593807413495327],
              [126.886683041152722, 37.593827357601945],
              [126.886465379395204, 37.593914173584466],
              [126.886365449207673, 37.593954030456487],
              [126.886272913935883, 37.593955908121444],
              [126.88619065608782, 37.593957376776252],
              [126.886093506008635, 37.593959112225193],
              [126.885976375244979, 37.593962094754851],
              [126.885796283949958, 37.593953757713379],
              [126.885720759603117, 37.593939878831335],
              [126.885409994726501, 37.593882705191994],
              [126.885294801995016, 37.593803639242694],
              [126.885281051322409, 37.593813166885212],
              [126.885314179522553, 37.593842526579287],
              [126.88531528018531, 37.593843673406624],
              [126.885316136264279, 37.593844946950298],
              [126.885316726519861, 37.593846312848804],
              [126.885317034250633, 37.593847735862902],
              [126.885317051831592, 37.593849178998084],
              [126.885316781832117, 37.593850605312753],
              [126.885316226791886, 37.593851981470628],
              [126.885315402894932, 37.593853267897074],
              [126.885314333054808, 37.593854433164587],
              [126.885313040170161, 37.593855447648181],
              [126.885261865251891, 37.59388967175061],
              [126.885257613315133, 37.593892513158103],
              [126.885256022396334, 37.593893404408021],
              [126.88525426345528, 37.593894065842655],
              [126.885252386501463, 37.593894476991707],
              [126.885250450524424, 37.593894627346366],
              [126.88524850993845, 37.593894511782032],
              [126.885246623621867, 37.593894133309334],
              [126.885244845855482, 37.59389350302714],
              [126.885243230853405, 37.59389264014591],
              [126.885241823730951, 37.593891568335479],
              [126.885219931850045, 37.5938717798808],
              [126.885173675693281, 37.59382995566078],
              [126.885172571894358, 37.593828777268911],
              [126.885171773237332, 37.593827546386272],
              [126.885168695427438, 37.593829076149426],
              [126.88516711666098, 37.593829729507959],
              [126.885165428565557, 37.593830178594352],
              [126.885163670882193, 37.59383041189821],
              [126.885161890110993, 37.593830422451092],
              [126.885160130419962, 37.593830211384365],
              [126.885158433689455, 37.593829782521588],
              [126.885156841733036, 37.593829147797962],
              [126.885155394069272, 37.593828322742155],
              [126.885154126781458, 37.593827327371592],
              [126.885153070259747, 37.593826185279397],
              [126.885088364582288, 37.593743259400988],
              [126.885087541356668, 37.593741986026807],
              [126.885086980474739, 37.593740630194922],
              [126.885086696453925, 37.593739217218456],
              [126.885086696896792, 37.593737786795899],
              [126.885086982639365, 37.593736374985184],
              [126.885087122477316, 37.593736037711636],
              [126.885027432261069, 37.593667212336626],
              [126.885026194892987, 37.593667261772403],
              [126.885024252648705, 37.593667073187277],
              [126.885022379414352, 37.593666619968062],
              [126.885020632846434, 37.593665915935247],
              [126.885019063716129, 37.593664985689557],
              [126.885017719411437, 37.593663852011346],
              [126.884946059384603, 37.593591884349976],
              [126.88494544492039, 37.593591946945672],
              [126.884943551478486, 37.593591884801008],
              [126.884941696345194, 37.593591572283707],
              [126.884939933823276, 37.593591017788825],
              [126.88493948476733, 37.593590801831368],
              [126.884938312492707, 37.59359023689229],
              [126.884936876343261, 37.593589252356807],
              [126.884935667054876, 37.593588092341115],
              [126.88493471723838, 37.593586785857951],
              [126.884910993285828, 37.593546563457828],
              [126.884910371982357, 37.593545255081168],
              [126.884910011711312, 37.593543885872464],
              [126.884909923532561, 37.593542488337562],
              [126.8849101419909, 37.593540994154736],
              [126.884910560059012, 37.593539745275017],
              [126.884911270630212, 37.593538465471468],
              [126.884912221627744, 37.593537288772161],
              [126.884913389027545, 37.593536243894768],
              [126.884914746592116, 37.593535353236],
              [126.884922293591799, 37.593531146358437],
              [126.884856563549206, 37.593444335649487],
              [126.88484416593495, 37.59342847687136],
              [126.884637617584644, 37.593164255151784],
              [126.884475278803734, 37.592891546779988],
              [126.884334192159983, 37.592719650239495],
              [126.88411238612953, 37.592454208701113],
              [126.883767316841499, 37.592054611482823],
              [126.883718062670994, 37.592002819348465],
              [126.883575149576046, 37.591852544489882],
              [126.883523550072155, 37.591837795879435],
              [126.883348797191346, 37.591787844739869],
              [126.882922862814283, 37.591514302038739],
              [126.882808856257284, 37.59144858598485],
              [126.882681590694943, 37.591376657847604],
              [126.88239568261632, 37.591177069420667],
              [126.882377450348613, 37.591164341747728],
              [126.882323027829386, 37.59112655403267],
              [126.882249544944841, 37.591054409335619],
              [126.882183581207229, 37.590989789573257],
              [126.882180846340503, 37.590987110899064],
              [126.88210861524928, 37.5909163508137],
              [126.879840446123097, 37.593005266136196],
              [126.879791500221387, 37.593050341780788],
              [126.878693166199341, 37.594061815075683],
              [126.87769623247182, 37.594979873268208],
              [126.876750675873438, 37.595850592651161],
              [126.876266795588265, 37.596296165556417],
              [126.876247608762412, 37.596284591022844],
              [126.876111705841296, 37.596218127294371],
              [126.875953914349111, 37.596353627687243],
              [126.87594904238756, 37.596357812978525],
              [126.875946224154021, 37.59636023709804],
              [126.87581929887719, 37.59646922991341],
              [126.875819077514365, 37.596469421629067],
              [126.87567063297854, 37.596596896879255],
              [126.875194732693643, 37.597005556252526],
              [126.874121359154216, 37.597908195856746],
              [126.873832964271827, 37.598150717750755],
              [126.873214568423137, 37.59867071041824],
              [126.872715358512266, 37.599107818974232],
              [126.87175928458619, 37.599944890972345],
              [126.871754951239879, 37.599948689575612],
              [126.871754651102478, 37.59994895116845],
              [126.871745146724152, 37.599957283011889],
              [126.871588599563324, 37.600094341559533],
              [126.871556588753336, 37.600122355027182],
              [126.871554057473347, 37.600124569654277],
              [126.871500622870798, 37.600171362857822],
              [126.870930262577517, 37.600670709664286],
              [126.870731378125214, 37.600844826086565],
              [126.870438014248862, 37.601101656866881],
              [126.870437624953183, 37.601102011719711],
              [126.870436871693798, 37.601102672888338],
              [126.870430098204906, 37.601108601804626],
              [126.870427500653818, 37.601110875541693],
              [126.870348902378581, 37.601179745623064],
              [126.869906672993949, 37.601566845963013],
              [126.869592048964577, 37.601842281848555],
              [126.869420813883679, 37.601987955088468],
              [126.86922678178928, 37.602153021979333],
              [126.868765211045982, 37.602545678559693],
              [126.868548367858537, 37.60273015787169],
              [126.867381794121684, 37.603722557136436],
              [126.867360546830795, 37.603739249561954],
              [126.867295796576599, 37.603790134414538],
              [126.867139058625014, 37.603913292686215],
              [126.864585856318641, 37.605919437499857],
              [126.864353725304028, 37.606032979786725],
              [126.863966043844485, 37.606222606999765],
              [126.863700451791502, 37.606352514371984],
              [126.862825970305735, 37.606780234589998],
              [126.861738075663411, 37.607312323517171],
              [126.859591964183068, 37.608265146724733],
              [126.859591181723189, 37.608265493999461],
              [126.859528148078141, 37.608293482697718],
              [126.859481523415567, 37.608314177585449],
              [126.858694790823805, 37.608663450114847],
              [126.858480945236693, 37.608760014212677],
              [126.858352852195623, 37.608817901010873],
              [126.858389485003229, 37.608907815510747],
              [126.858412662044927, 37.608964694105765],
              [126.858432982777998, 37.609020661239931],
              [126.858487236570156, 37.609107727741943],
              [126.858504255956035, 37.609133743567526],
              [126.858723802264322, 37.609469368291848],
              [126.858966319239627, 37.60984503932098],
              [126.859223813689709, 37.610137358162781],
              [126.859471010559872, 37.610396192722128],
              [126.85961685830928, 37.610491068346924],
              [126.859644043757626, 37.610508752246936],
              [126.859793101732507, 37.610561863678221],
              [126.859887268903208, 37.61058844833375],
              [126.860052549159178, 37.610608287211058],
              [126.860206310358748, 37.610605100988757],
              [126.860327739299109, 37.610577542064284],
              [126.860355286441219, 37.610561485374198],
              [126.860361118809521, 37.61055808470276],
              [126.860527684612308, 37.610678808228016],
              [126.860817196367165, 37.610890542850193],
              [126.861279402009984, 37.611225374069015],
              [126.861828828513779, 37.611628313183253],
              [126.861965196251091, 37.611727536759531],
              [126.86222819716869, 37.61191943220156],
              [126.862797982621842, 37.612335970711712],
              [126.863104015470071, 37.612557672961245],
              [126.863477027279032, 37.612829763420933],
              [126.863483099260478, 37.612850629631879],
              [126.863533534723729, 37.613023920142744],
              [126.863723314985435, 37.613286631781499],
              [126.863769527573325, 37.613445259929463],
              [126.86383963835398, 37.61354899129352],
              [126.86400845916306, 37.61369893070809],
              [126.864185368453931, 37.613824642313617],
              [126.864343946601252, 37.613940242881895],
              [126.864615902021342, 37.614104077594334],
              [126.864870098670664, 37.614157654944073],
              [126.865784982772411, 37.61446025222255],
              [126.866518703231833, 37.614734316206508],
              [126.866663437025679, 37.614788378212104],
              [126.867098348089144, 37.615029273338578],
              [126.867432458454132, 37.615214733456767],
              [126.867619532985387, 37.615316748739957],
              [126.867751173979372, 37.615389651056333],
              [126.868004019351659, 37.615530840401256],
              [126.868060437675396, 37.615560556713071],
              [126.868301703812094, 37.615685307957889],
              [126.868644794485206, 37.615861747092296],
              [126.868673789339397, 37.61587738354865],
              [126.868747923320726, 37.615917363170972],
              [126.868929449204458, 37.616014025215001],
              [126.869112983460326, 37.616110142495231],
              [126.869486208635124, 37.616306099161214],
              [126.869611098411994, 37.616371669728672],
              [126.869844354455964, 37.616494651677463],
              [126.870395275336577, 37.61631950128028],
              [126.870526871265014, 37.616271670064549],
              [126.870659615434519, 37.616375411494033],
              [126.871028685771876, 37.6166754236746],
              [126.871312015017153, 37.616871682243804],
              [126.871493010159725, 37.616997875164294],
              [126.871504361350517, 37.61706736183725],
              [126.871542547412403, 37.617103111156766],
              [126.871607311779997, 37.617163745446838],
              [126.871608479883889, 37.61716456827665],
              [126.871682892240244, 37.617217049052556],
              [126.871914787760076, 37.617357009829085],
              [126.871967630409287, 37.617387565115386],
              [126.872486451343818, 37.617587585094157],
              [126.872661719977444, 37.617692883473268],
              [126.872753721311781, 37.617721411274125],
              [126.872869582536651, 37.617740917264683],
              [126.872949037976085, 37.617754223193486],
              [126.873211120047259, 37.617798113530412],
              [126.873358458101521, 37.617797120430744],
              [126.873409571112774, 37.617767759573155],
              [126.873434786620393, 37.617692915031554],
              [126.873450873766487, 37.61762481807925],
              [126.873395649725254, 37.617345687905562],
              [126.873332964854299, 37.617069362685527],
              [126.873333428566127, 37.617037468709597],
              [126.873460439187184, 37.616670168668669]
            ]
          ]
        ]
      },
      id: '31101670'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '풍산동',
        ADM_CD: '31103540'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.79694231179316, 37.684822392399269],
              [126.796997346764968, 37.684597426274671],
              [126.79778001843836, 37.684066848836054],
              [126.797960322524503, 37.683757858817295],
              [126.798409565260158, 37.683436085502649],
              [126.798786160072922, 37.683273116752638],
              [126.798900915898656, 37.683178621302559],
              [126.798974298056407, 37.682957739867994],
              [126.799069643895081, 37.682371914055011],
              [126.799070509202977, 37.682362031700819],
              [126.799086838905552, 37.682176793611426],
              [126.799088257758953, 37.682071563737459],
              [126.799319259017437, 37.682083225934335],
              [126.799387498621982, 37.682086672416162],
              [126.799364752618686, 37.681988606888396],
              [126.799325747507979, 37.681983477368497],
              [126.799082236413795, 37.681951452085229],
              [126.799082223453567, 37.68187207802243],
              [126.799091672128569, 37.681524050974176],
              [126.798803299421238, 37.681015318034056],
              [126.798699105525486, 37.680716818849199],
              [126.798670801642203, 37.680635737074795],
              [126.798640760707656, 37.680549687692469],
              [126.798941187655785, 37.680304706493281],
              [126.799177978082326, 37.680049761707949],
              [126.799269121523508, 37.679882246419112],
              [126.799304778731198, 37.67977635466282],
              [126.799311188782795, 37.679503101114022],
              [126.799111983149274, 37.679472846149544],
              [126.799002808791599, 37.679017130604478],
              [126.798927786012797, 37.678800498948192],
              [126.79888941601223, 37.678695288457995],
              [126.798838675825522, 37.678554198487973],
              [126.798770488548925, 37.678117832571218],
              [126.798700988226685, 37.677997163102852],
              [126.798674361673676, 37.677950988663106],
              [126.798674039994765, 37.677943599513576],
              [126.798673142259361, 37.67791970372916],
              [126.798672028878357, 37.677891249577279],
              [126.798673558678445, 37.677870890595671],
              [126.798682503326162, 37.677749997162643],
              [126.798683491145766, 37.677736375044951],
              [126.798685801801895, 37.67770450180813],
              [126.79868017391351, 37.677689537037317],
              [126.798677246293394, 37.67768178267783],
              [126.798610303615391, 37.677558595643383],
              [126.798570827409563, 37.67748595584461],
              [126.798528093118577, 37.677381413279711],
              [126.798507924417692, 37.677253172158359],
              [126.798499938033984, 37.677202418065491],
              [126.798493886273164, 37.677163949686204],
              [126.798475941685254, 37.677010034098707],
              [126.798469172723713, 37.676960783438567],
              [126.798459379729664, 37.676889455826654],
              [126.798455813357137, 37.676863434166783],
              [126.79844714641294, 37.676801022822467],
              [126.798408685688827, 37.676524024971833],
              [126.798368078314738, 37.676375780851551],
              [126.798317209850495, 37.676190058165695],
              [126.798366164496713, 37.676078792739347],
              [126.798472562665253, 37.67583696452386],
              [126.798497888079837, 37.675528516756103],
              [126.798504339262792, 37.675449152217062],
              [126.798513835074203, 37.675333304024512],
              [126.798517001283656, 37.675294748053403],
              [126.798542151054022, 37.675276168155733],
              [126.798654559795452, 37.675193121831143],
              [126.798770647553454, 37.67510735725098],
              [126.798837767327583, 37.675057767152822],
              [126.799074121125884, 37.674883150974281],
              [126.79910630632088, 37.674876571515391],
              [126.799345072899342, 37.674827755915139],
              [126.799430008229251, 37.674810334966686],
              [126.799493195005084, 37.674797376119216],
              [126.800371143589089, 37.674532945985753],
              [126.800382477376715, 37.674535992991807],
              [126.800616829967012, 37.674642999049269],
              [126.801159205742024, 37.674890646549088],
              [126.801601701799413, 37.675092685378118],
              [126.802388098687047, 37.675451711754199],
              [126.802452722894046, 37.675481200408903],
              [126.802465515943624, 37.675487076986258],
              [126.802550828239148, 37.675541082243491],
              [126.802615378004276, 37.675581931542034],
              [126.802630885257699, 37.675591748510094],
              [126.80277580790316, 37.6756834713022],
              [126.802863642290518, 37.675732181551083],
              [126.802936535154942, 37.675772668085678],
              [126.803276879113668, 37.675710171509451],
              [126.803428044770044, 37.675686369990885],
              [126.803555766129477, 37.675654330551069],
              [126.803699645735591, 37.675599341350242],
              [126.803969900213943, 37.675464829658452],
              [126.804190399452096, 37.675326629423708],
              [126.804245207434036, 37.67529684948439],
              [126.804428785932572, 37.67519710764936],
              [126.804537663695328, 37.675131790023393],
              [126.804778268129468, 37.674934698517539],
              [126.804820651864247, 37.674895666896305],
              [126.804874039137545, 37.674853410285614],
              [126.804950349223176, 37.674759295928403],
              [126.804920836897566, 37.674748299814468],
              [126.804911959111351, 37.674745006334938],
              [126.804886139635443, 37.674735402888842],
              [126.804812248527753, 37.674687168402372],
              [126.80472128134501, 37.674665753917793],
              [126.804702976129988, 37.674661444034868],
              [126.804644102587517, 37.674647578894898],
              [126.804608000227304, 37.674639077467319],
              [126.804545597306699, 37.674618339611008],
              [126.804532817000407, 37.674606965263422],
              [126.804513478127404, 37.674589724284374],
              [126.804383451303238, 37.674637439605867],
              [126.804360861115526, 37.674307465414977],
              [126.804358690992487, 37.674276097389971],
              [126.804356055327148, 37.6742379937359],
              [126.804402889261951, 37.674164633679517],
              [126.804428334508501, 37.674124772218548],
              [126.804457210336921, 37.674050129097807],
              [126.804459941270224, 37.674020572793864],
              [126.804460855842819, 37.674010669041749],
              [126.804477047997111, 37.673835462602618],
              [126.8043960820645, 37.673686780539882],
              [126.804385462035299, 37.673667278166178],
              [126.804341392230782, 37.673628371300033],
              [126.804206109296516, 37.673545978288786],
              [126.804117320248011, 37.673473391853179],
              [126.804090335614276, 37.673433433808476],
              [126.80405858936652, 37.67335112152572],
              [126.804034038593656, 37.673289397355497],
              [126.804006343593045, 37.673219762639334],
              [126.804005339724597, 37.673213362728788],
              [126.80399745533478, 37.673151815316139],
              [126.803986550133885, 37.673075212399532],
              [126.803985734231588, 37.67304043453624],
              [126.803985789199103, 37.67301998203493],
              [126.803999161499846, 37.672978380159833],
              [126.804038369001603, 37.672897268911576],
              [126.804064665386221, 37.67285451568938],
              [126.804142194858429, 37.672769594399817],
              [126.804171025382516, 37.672712160929947],
              [126.804172964607133, 37.672696598406667],
              [126.804176585337501, 37.672667571021258],
              [126.804179734160073, 37.672592175881768],
              [126.804180698022691, 37.672569102284847],
              [126.804171355222678, 37.672545571298762],
              [126.804125767226196, 37.672424404233837],
              [126.804086537213934, 37.672300096598967],
              [126.804034255202026, 37.672225136895776],
              [126.80400410596863, 37.672181929808083],
              [126.803965353602578, 37.672134473577223],
              [126.80391801393975, 37.67207646263013],
              [126.803854829442344, 37.672052121713314],
              [126.803843480437493, 37.672040047296399],
              [126.803806565864662, 37.672000775060539],
              [126.803768013610835, 37.671962508035584],
              [126.803747451108364, 37.671937967997607],
              [126.803725642864222, 37.671911803627665],
              [126.803713549732848, 37.671855832160858],
              [126.803717904733475, 37.671795564746724],
              [126.803720050519672, 37.671755475735566],
              [126.803709432334969, 37.671699417434191],
              [126.803694053346803, 37.671642451333007],
              [126.803669726230311, 37.671583215144402],
              [126.803656897594905, 37.671548687218149],
              [126.803602061751306, 37.671497150253359],
              [126.803620581465566, 37.671480601343802],
              [126.803615335863768, 37.671449060105296],
              [126.803573775203034, 37.671435475118493],
              [126.803536624608284, 37.671382436391156],
              [126.803520080742572, 37.671294832811149],
              [126.803537258078549, 37.67127170894441],
              [126.803522183967459, 37.671143565303105],
              [126.803526251142188, 37.671105369601058],
              [126.803543943356047, 37.671059180300247],
              [126.803541070880726, 37.671031424207243],
              [126.8035361321257, 37.670969969849018],
              [126.803512053535528, 37.670903347842952],
              [126.803519574677253, 37.670845247879114],
              [126.803519010518187, 37.670759294338815],
              [126.803530882819857, 37.670641828255391],
              [126.803516881035264, 37.670536301534987],
              [126.8035370419407, 37.670499664614248],
              [126.803541266326704, 37.670488320264269],
              [126.803565355422691, 37.670422768640222],
              [126.80355946359758, 37.670336626804477],
              [126.803576599334534, 37.670286381855291],
              [126.803597328158617, 37.67029137139874],
              [126.803596818465394, 37.670270198100965],
              [126.803537778886025, 37.670222077531434],
              [126.803481385984867, 37.670159634306607],
              [126.803463400115916, 37.670102843848234],
              [126.803446614486305, 37.670064434858155],
              [126.803404563501132, 37.670021388055204],
              [126.803399894957636, 37.669986601838083],
              [126.803410820138026, 37.669970222615873],
              [126.803379795042488, 37.669873586930379],
              [126.803362065965629, 37.66980535296355],
              [126.803291914434467, 37.669503770303521],
              [126.803246711572285, 37.669367828824598],
              [126.803233552355081, 37.669329424999624],
              [126.803214402613506, 37.66928461342404],
              [126.803193255528598, 37.669232709245826],
              [126.803146499169969, 37.669117910811224],
              [126.803102337059585, 37.668974763026611],
              [126.803095478792329, 37.668953234944816],
              [126.803081654155108, 37.66890985726981],
              [126.803035376094584, 37.668667327808159],
              [126.802949000460444, 37.668669615072446],
              [126.802939914337813, 37.668634371889873],
              [126.802798439110333, 37.668642693131375],
              [126.802795327061361, 37.668636462811719],
              [126.802789418848249, 37.668624658400539],
              [126.802774195713965, 37.668595531363572],
              [126.802662495502418, 37.668489298945516],
              [126.802621908049332, 37.668450667983947],
              [126.802604359255398, 37.668429920300987],
              [126.802584282648652, 37.668406186905045],
              [126.802561227805214, 37.668356840100891],
              [126.802548557631582, 37.668329723661245],
              [126.802529042353669, 37.668307563681111],
              [126.802522640350787, 37.668297885263051],
              [126.802504755811441, 37.668276672299108],
              [126.802460314792555, 37.668246370159032],
              [126.802436064200862, 37.668231994833285],
              [126.802409101024878, 37.66821499322517],
              [126.802397523145032, 37.66820855959817],
              [126.802366092448125, 37.668195441499293],
              [126.802330879272304, 37.66816123527736],
              [126.802273126423898, 37.668120305230033],
              [126.802213952119146, 37.668081032391271],
              [126.802180340893671, 37.668045045572455],
              [126.802159036882941, 37.668018005996409],
              [126.802144792449511, 37.667987810289297],
              [126.802139211196888, 37.667975978937058],
              [126.802139086657661, 37.667975716816272],
              [126.802140656944374, 37.667876072135471],
              [126.802151065018734, 37.667819734276897],
              [126.802143227436957, 37.667805232471736],
              [126.802134712870071, 37.667789163764844],
              [126.802136270412518, 37.667757722184781],
              [126.802177151837626, 37.667770944931625],
              [126.802226198640497, 37.667697779009593],
              [126.802237757027683, 37.66765590337949],
              [126.802247371111989, 37.667621142112615],
              [126.802269565779426, 37.667586222726278],
              [126.802281505782886, 37.66757164684492],
              [126.80234896829063, 37.667520315318328],
              [126.802410555975328, 37.667461855586609],
              [126.802441568559402, 37.667435781635845],
              [126.802462317451685, 37.66739031718383],
              [126.802469233646363, 37.667377272664829],
              [126.802485556950984, 37.667346477221365],
              [126.802514565979493, 37.667348869634175],
              [126.802520355685957, 37.667345455406362],
              [126.802541922377031, 37.667332787744385],
              [126.802607557504189, 37.667287671606367],
              [126.802620908836332, 37.667253366841614],
              [126.802627617513735, 37.66723595777642],
              [126.802637408798347, 37.667228887096556],
              [126.802674569188284, 37.667192370638844],
              [126.802701837364566, 37.667125112814944],
              [126.802687220508631, 37.667081393303967],
              [126.802666515722677, 37.667067392306265],
              [126.802641398645036, 37.667050410677284],
              [126.8026113268496, 37.667021529309721],
              [126.802597984775232, 37.667008803042108],
              [126.802598556168704, 37.667008083558784],
              [126.802616192048959, 37.666982166610921],
              [126.802626236932269, 37.666961198113093],
              [126.802636029717902, 37.666940754497013],
              [126.802711095949334, 37.66695695164529],
              [126.802808997346133, 37.666978076969279],
              [126.802976088951482, 37.666968174741484],
              [126.803111740014245, 37.666937756249396],
              [126.803122838794465, 37.666935267473477],
              [126.803252652936663, 37.666921070612659],
              [126.803341515790962, 37.666911336647239],
              [126.803346676514309, 37.666918435312532],
              [126.803396592539627, 37.666924107430084],
              [126.803424560021057, 37.666927284829754],
              [126.803559642556749, 37.666942634404656],
              [126.803561959966345, 37.666942897754993],
              [126.803562094265672, 37.666942601111032],
              [126.80356489129386, 37.666936433641986],
              [126.803610683996297, 37.666941932603706],
              [126.803613816727193, 37.666942308925776],
              [126.803693242193617, 37.666951901948941],
              [126.804217771952892, 37.666933349370922],
              [126.804230756624179, 37.666932890581663],
              [126.80423614298283, 37.666933075580737],
              [126.804374801126784, 37.666937815999539],
              [126.804422670277731, 37.666930283831192],
              [126.804636500771039, 37.6668966291804],
              [126.804705414409838, 37.666882271754915],
              [126.804911505346553, 37.666839334209392],
              [126.805029435749134, 37.666818254049616],
              [126.805093122806056, 37.666806868812657],
              [126.805256565888456, 37.666777652615721],
              [126.805690286066834, 37.666783190840867],
              [126.805718867125904, 37.666783556473078],
              [126.805988282175917, 37.66678283194846],
              [126.806138575415574, 37.666781278109198],
              [126.806803557324244, 37.666821120391582],
              [126.807021123588754, 37.666796251449085],
              [126.807066189816169, 37.666791978051684],
              [126.807214418879028, 37.666777921063769],
              [126.807352878485929, 37.666750577781684],
              [126.807461043405198, 37.666693003605786],
              [126.807480124109247, 37.666677359588078],
              [126.807482617830473, 37.666674024007307],
              [126.807516635465518, 37.666628405813405],
              [126.80770403909203, 37.666477712098242],
              [126.807807168514728, 37.666400906229143],
              [126.807832476438591, 37.666382058437101],
              [126.807833281523187, 37.666333948663649],
              [126.8078334454447, 37.666329727822195],
              [126.80783413061944, 37.666312110951353],
              [126.807856599511581, 37.666262233723344],
              [126.807871007686586, 37.666231056737345],
              [126.807885424919618, 37.666199879802534],
              [126.807909915232756, 37.666172608825171],
              [126.808004721979032, 37.666102845561682],
              [126.808019730021471, 37.666091802255522],
              [126.808040370436842, 37.666087169762385],
              [126.808109551266284, 37.666070506319677],
              [126.808205261200072, 37.666050968186241],
              [126.808260466647354, 37.666040366112142],
              [126.808331022201784, 37.666026814762894],
              [126.808364525750164, 37.666020379283694],
              [126.8083643567618, 37.666018307049171],
              [126.808369407772489, 37.666017235249221],
              [126.808372188600003, 37.666015699474826],
              [126.80850912163416, 37.665940052006228],
              [126.808558665900236, 37.665912682611754],
              [126.808626490635305, 37.665875211950514],
              [126.808626615194072, 37.66587547406472],
              [126.808627144019965, 37.66587542488773],
              [126.808634084615903, 37.665874775156389],
              [126.808635563998877, 37.665876638753737],
              [126.808640349506589, 37.66588267503338],
              [126.808641880408373, 37.665884607433291],
              [126.808643792243501, 37.665881409924403],
              [126.808649847887565, 37.665871285689853],
              [126.808647263366893, 37.665866327677222],
              [126.808646465776576, 37.665864792553151],
              [126.808674846181944, 37.665802897301724],
              [126.808689064011674, 37.665769947091974],
              [126.808689358434506, 37.665770965511683],
              [126.808692971021429, 37.665762878370181],
              [126.808708432028311, 37.665723027693105],
              [126.808717267387266, 37.665700236307877],
              [126.80871773201541, 37.665699040269395],
              [126.808720251153446, 37.6656922211912],
              [126.808731821765079, 37.665663024799734],
              [126.808813262396129, 37.665550852732245],
              [126.808816641329216, 37.665542282910032],
              [126.80882974305986, 37.665510831353053],
              [126.808826512638106, 37.665493115816638],
              [126.808821817214792, 37.665463427512925],
              [126.808825293300174, 37.665369551494301],
              [126.808827086523195, 37.665334147157083],
              [126.808854965552868, 37.665312343440448],
              [126.809278545439554, 37.665188700575534],
              [126.809371237151481, 37.665287210127381],
              [126.809554824272823, 37.665353129844938],
              [126.809710526774793, 37.665193234058115],
              [126.809757516515745, 37.665166865896786],
              [126.809852174253919, 37.665114584148185],
              [126.810022439211224, 37.665044022316877],
              [126.810098834778458, 37.665012364004596],
              [126.810295003770449, 37.664933351047011],
              [126.81040028670472, 37.664891400677682],
              [126.81040400525508, 37.664889973088279],
              [126.810436359610478, 37.664878261153341],
              [126.810496732850083, 37.664849166014449],
              [126.810496429352028, 37.664848636063965],
              [126.810793186466711, 37.664708732683067],
              [126.811090476181533, 37.664574383819264],
              [126.811270363314975, 37.664477730739542],
              [126.811380987933475, 37.664431327487883],
              [126.811576241681266, 37.664351906848211],
              [126.811548835972985, 37.664343213145663],
              [126.811543831443217, 37.664305634741105],
              [126.811429112088675, 37.664091558393778],
              [126.81137327696905, 37.664009750852045],
              [126.811369641789511, 37.663990870306435],
              [126.811369196105943, 37.663943385908013],
              [126.811398914030335, 37.663934470446307],
              [126.811482750615312, 37.663901540533914],
              [126.811586808278832, 37.663897744035047],
              [126.811587522387057, 37.663895064057243],
              [126.811628946518283, 37.663892938039574],
              [126.811628806426711, 37.663893620426286],
              [126.81171303343028, 37.663883962133937],
              [126.811732246911305, 37.663881759648135],
              [126.811747519067026, 37.663874667006539],
              [126.811761489980142, 37.663866797038729],
              [126.811744749384971, 37.66383719178031],
              [126.811756040616061, 37.66383588224361],
              [126.811594582072516, 37.663577036696154],
              [126.811483027331491, 37.663291482143407],
              [126.811348784260161, 37.662947960588859],
              [126.811329649176486, 37.662784618897057],
              [126.811292658042916, 37.662557838626533],
              [126.811259279925366, 37.662225957880111],
              [126.811268999620552, 37.662058481717466],
              [126.811269575050659, 37.662016816813519],
              [126.811270528919096, 37.661947935900251],
              [126.811270681347494, 37.66186100036763],
              [126.811274432288528, 37.66179346433811],
              [126.811281316553305, 37.661669525444971],
              [126.811286096934623, 37.661509952086874],
              [126.811237916120561, 37.661404415790535],
              [126.811110215032002, 37.661129322837837],
              [126.811104963788722, 37.661117504635939],
              [126.811104084182858, 37.661115520188254],
              [126.811102607019407, 37.661112192881134],
              [126.811086494190107, 37.661034626256416],
              [126.81108536310127, 37.66102918375708],
              [126.81108442608398, 37.661025805524943],
              [126.811048452519287, 37.660896411154745],
              [126.810970701762571, 37.660574234310211],
              [126.81099471825631, 37.660356246637498],
              [126.810996934532199, 37.660252124046536],
              [126.810996399085283, 37.66023204028901],
              [126.811016451831506, 37.660122245286814],
              [126.811005952806255, 37.66001753466395],
              [126.810920128445602, 37.659775095919393],
              [126.81091819042669, 37.659769624367655],
              [126.810919382744899, 37.659763287804012],
              [126.81095415082558, 37.659578494744814],
              [126.811054058626951, 37.659438890651437],
              [126.811109254442627, 37.659361766622034],
              [126.811075986377162, 37.659354847532448],
              [126.811113586882641, 37.659231790127279],
              [126.811094390185744, 37.659158690466583],
              [126.811002022190593, 37.659028170796198],
              [126.810909119541336, 37.658885124008471],
              [126.810836376136649, 37.658790767369261],
              [126.810829681970532, 37.658781757363649],
              [126.810805541264841, 37.658749272244684],
              [126.810783802239186, 37.658697611028693],
              [126.810770954801612, 37.658624520468074],
              [126.810770314837526, 37.658535792029774],
              [126.81077030155349, 37.658534530997493],
              [126.810770203260006, 37.65852063648741],
              [126.810765793004364, 37.658517208213226],
              [126.810711980060105, 37.65846441227233],
              [126.810693654242996, 37.658408523035085],
              [126.810697193213542, 37.658354381250554],
              [126.810692014884125, 37.658207424078554],
              [126.810737673127562, 37.658168575150022],
              [126.810757076988622, 37.658202033979869],
              [126.810887050453189, 37.658207559579701],
              [126.811012899991027, 37.658186037399318],
              [126.811046250887131, 37.658107626675971],
              [126.81112261760633, 37.657928086940856],
              [126.811257157801165, 37.657611773540438],
              [126.81139559291104, 37.657587039999157],
              [126.811808974409232, 37.657526404747003],
              [126.811867168021323, 37.657405037308415],
              [126.811882268604521, 37.657373149961693],
              [126.811983691156641, 37.657164711471111],
              [126.811997981345598, 37.657139056556822],
              [126.812200718308119, 37.656724850158255],
              [126.812254515602575, 37.656625939550104],
              [126.812402175409915, 37.656645619163335],
              [126.812754644959995, 37.65667860134446],
              [126.812763688656659, 37.6566794444973],
              [126.813055899106004, 37.656667861037661],
              [126.813078993090826, 37.65666694548127],
              [126.813108822627498, 37.656665758185376],
              [126.813175931603354, 37.656700634591736],
              [126.813255468865336, 37.656741971015364],
              [126.813296980187602, 37.656763545270479],
              [126.813489354087821, 37.65683342630193],
              [126.813648791742679, 37.656934120097596],
              [126.813665412732632, 37.656944616307953],
              [126.813813479586358, 37.657052968985461],
              [126.813855485995418, 37.65706500809172],
              [126.814008274024701, 37.657108806171379],
              [126.814051838649988, 37.657121290511597],
              [126.814322066615162, 37.657198744276627],
              [126.814362687743909, 37.657132136251981],
              [126.814368111838547, 37.657138992620943],
              [126.814573502911557, 37.657399746561936],
              [126.814595312721707, 37.657427429533925],
              [126.814849633324627, 37.657365808786011],
              [126.815027891723872, 37.657322575426562],
              [126.815064943595559, 37.657275935141676],
              [126.815171789438267, 37.657106297707259],
              [126.815193179844286, 37.657072273348561],
              [126.815236492079663, 37.657019185211119],
              [126.815213976348815, 37.656977499760785],
              [126.815213494809299, 37.656976604652385],
              [126.815206825139313, 37.65696427004103],
              [126.815366609549514, 37.656921185789621],
              [126.815379124684156, 37.656928698874943],
              [126.815380378436089, 37.656929453351033],
              [126.815456567353849, 37.656975204287001],
              [126.815456692814223, 37.657011424806925],
              [126.815456702668982, 37.657013314945075],
              [126.81545687145892, 37.657039383178407],
              [126.815628625361668, 37.657054492756252],
              [126.815716478792723, 37.657039151712695],
              [126.815843574440265, 37.657066542955015],
              [126.815856257244761, 37.657070617501645],
              [126.815927371892712, 37.657093163805307],
              [126.816019116345558, 37.657112408256296],
              [126.816058115049472, 37.657106073631688],
              [126.81613055246396, 37.657093681264193],
              [126.816105227957578, 37.65702814863392],
              [126.816147609954797, 37.656966400231333],
              [126.816300509503975, 37.656911375489209],
              [126.816361417198962, 37.656908101565769],
              [126.816414353981486, 37.657016218687417],
              [126.816640327745262, 37.656398371866253],
              [126.816830453896529, 37.656321095621763],
              [126.816855880991923, 37.656258424943779],
              [126.816892511057432, 37.656248483635771],
              [126.816845668216033, 37.656127319255148],
              [126.816986496333456, 37.655958427633948],
              [126.816975523962483, 37.655811462318049],
              [126.816799121416977, 37.655693752331935],
              [126.81676588318966, 37.655671571958656],
              [126.816113892239784, 37.655320699251142],
              [126.815993291402179, 37.655224667593338],
              [126.815925807855976, 37.655170933958381],
              [126.815832229536497, 37.65470588452127],
              [126.81573879641951, 37.654408956662074],
              [126.815724404374336, 37.654362894705415],
              [126.815730066686825, 37.654273246458324],
              [126.81575036392519, 37.653951909666674],
              [126.81574926773898, 37.653662696426096],
              [126.815619829986034, 37.653218941654906],
              [126.815567259561718, 37.653032538809114],
              [126.815947014821589, 37.652724354384105],
              [126.815953868039401, 37.652688353670307],
              [126.816049980324223, 37.652148974708545],
              [126.816181507037214, 37.651858166972545],
              [126.816232521673399, 37.65174526322847],
              [126.816327363487261, 37.651227372327845],
              [126.816374121925335, 37.650877775170216],
              [126.816544052609856, 37.650804073157474],
              [126.816607379448229, 37.650629293243988],
              [126.816928600825449, 37.650595920826326],
              [126.816929048688124, 37.650595292490642],
              [126.817023164827248, 37.650463408126392],
              [126.817061870836994, 37.650409174023693],
              [126.817045497201534, 37.650248236481829],
              [126.817395082911105, 37.650090147198043],
              [126.817519824832416, 37.65003286853819],
              [126.817620697041392, 37.649986789467796],
              [126.817828214159093, 37.649743353216351],
              [126.817802401186938, 37.649401185347187],
              [126.817791149214628, 37.649232398090099],
              [126.817088958442653, 37.649408296899232],
              [126.816922796471474, 37.649291985686574],
              [126.816858341650416, 37.649246330659061],
              [126.816471720943014, 37.64899169419548],
              [126.81574926480333, 37.648910912881853],
              [126.815673130564861, 37.648905782544254],
              [126.815494717430951, 37.648586476102338],
              [126.815483295385192, 37.648441491694605],
              [126.815666854911584, 37.648264380458784],
              [126.815691502171376, 37.648239550355356],
              [126.815627276907364, 37.648185118858123],
              [126.815519855811843, 37.648094133931004],
              [126.815987765309075, 37.64766735725226],
              [126.816111716527104, 37.647580518225368],
              [126.81629367164831, 37.647453136439232],
              [126.816329370121849, 37.647223082955954],
              [126.816066496156068, 37.646901652773927],
              [126.816102836423696, 37.646778185066303],
              [126.816163042470393, 37.64667052437278],
              [126.81617670759708, 37.646643426750892],
              [126.81622746628463, 37.646554577850928],
              [126.816230373399691, 37.646550050206471],
              [126.816331891400012, 37.646392029223875],
              [126.816350826557255, 37.646339794344961],
              [126.816377981422505, 37.6462648819168],
              [126.816454797567516, 37.646117639961446],
              [126.816500542632269, 37.646057039242258],
              [126.816483346382952, 37.645997096845761],
              [126.816346597738075, 37.645583877528459],
              [126.816287055183892, 37.645284658417324],
              [126.816275069856559, 37.645271485448077],
              [126.816033366187199, 37.645006128647879],
              [126.816003530669903, 37.644973376262648],
              [126.81578499437218, 37.64491429059327],
              [126.815612417211582, 37.644863464129756],
              [126.815557571865881, 37.64487518948561],
              [126.815183817584142, 37.644950550876011],
              [126.815147249540288, 37.644958517243893],
              [126.81508086913648, 37.644972978542441],
              [126.814685815024973, 37.645020205017467],
              [126.814094871557558, 37.644834206660519],
              [126.81404256474211, 37.64481774351966],
              [126.813996789094446, 37.644775050033417],
              [126.813884077770894, 37.644669909020351],
              [126.813641877675181, 37.644446950125975],
              [126.813041592379903, 37.644597671534953],
              [126.812854453404242, 37.644849645683969],
              [126.812793283371235, 37.644932153848494],
              [126.812788393631081, 37.644938644668862],
              [126.812775319909079, 37.644955833445074],
              [126.813124864559398, 37.645274246049851],
              [126.813149633977986, 37.645296818907234],
              [126.813247537611204, 37.645385001553443],
              [126.813247252828077, 37.645388354637689],
              [126.813231874875683, 37.645570118813602],
              [126.813224167321749, 37.645660922374958],
              [126.813232977671277, 37.645683394193298],
              [126.813271277255879, 37.645781053384503],
              [126.81335282517847, 37.6459889929802],
              [126.813330422257366, 37.646030942867995],
              [126.813235361420539, 37.646208463917027],
              [126.813146556863572, 37.646375814674286],
              [126.813093063964075, 37.646470690619395],
              [126.813068667735692, 37.646529035713129],
              [126.81286597866243, 37.647012173744557],
              [126.812720124830733, 37.647334039904777],
              [126.812686106420813, 37.647518952740356],
              [126.81268201327741, 37.64752840815337],
              [126.812630712733466, 37.647646984424583],
              [126.812319647080201, 37.647837042483687],
              [126.811887001649978, 37.648025692059186],
              [126.811872934125503, 37.648031827357336],
              [126.81171965290477, 37.647976180205561],
              [126.811617311581443, 37.647938897225437],
              [126.811547885799655, 37.647893627610408],
              [126.811488222755798, 37.647837634158229],
              [126.81141073942517, 37.647766863048723],
              [126.811287679184403, 37.647652250239275],
              [126.811254963891116, 37.647608989309653],
              [126.811153016257066, 37.647479453762799],
              [126.811133103124121, 37.647454157099496],
              [126.81097682683172, 37.647325712912171],
              [126.810793474761127, 37.647229182268575],
              [126.810760216599263, 37.647211965489873],
              [126.810619783453618, 37.647139383026939],
              [126.810615196680956, 37.647023779007071],
              [126.810540937013599, 37.646990115785741],
              [126.810432910871711, 37.646944343202236],
              [126.810301458083813, 37.646900494895085],
              [126.810165641046254, 37.64685801978154],
              [126.810024747102702, 37.646814552200524],
              [126.809920133730628, 37.646785786427131],
              [126.809752364216351, 37.646720572709789],
              [126.809652822946234, 37.646679398058474],
              [126.809367688610962, 37.646560690852844],
              [126.809282336193363, 37.646523818970358],
              [126.808966865251051, 37.646429294248563],
              [126.808864512546506, 37.646405361082437],
              [126.808744285138303, 37.646379706108306],
              [126.808739161443967, 37.64637861342014],
              [126.808670236775924, 37.64636523130963],
              [126.808594897429288, 37.646351969295573],
              [126.808536318626253, 37.646337979858977],
              [126.808460623947667, 37.646330038015066],
              [126.808389829655539, 37.646320011239091],
              [126.808354378937139, 37.646314908289703],
              [126.808251568113789, 37.646272698394419],
              [126.808164139321491, 37.646237480984851],
              [126.807961601524113, 37.646140347614477],
              [126.807880906205384, 37.64605602822197],
              [126.807738689416766, 37.645908324253327],
              [126.807600863049245, 37.645705828044477],
              [126.807598836280633, 37.645702898551683],
              [126.80758565218342, 37.645683846423104],
              [126.807568750091818, 37.645659423091409],
              [126.807530197447363, 37.645604485317641],
              [126.80746677075453, 37.645473354707789],
              [126.807403632359495, 37.645342817025345],
              [126.807322768275341, 37.645284031765406],
              [126.80707272975792, 37.645098377985441],
              [126.806984419880479, 37.645027414185492],
              [126.806658237366236, 37.6447546035809],
              [126.806655666501555, 37.644741804040827],
              [126.806637767439, 37.644653116366406],
              [126.806597231301723, 37.644599265343871],
              [126.806497122901732, 37.644470598236488],
              [126.806441618282193, 37.644399259936705],
              [126.806295307361665, 37.644237925501983],
              [126.806171006891319, 37.644108832838612],
              [126.806093331850931, 37.644059624969991],
              [126.806003819176325, 37.644002918670395],
              [126.805994058691269, 37.643995836303731],
              [126.805954367195042, 37.643970438631754],
              [126.805919763628324, 37.643948495926303],
              [126.805815555991359, 37.643840439112935],
              [126.805806514046907, 37.643831423104338],
              [126.805779331596284, 37.643803395899305],
              [126.80577422993386, 37.643798132769241],
              [126.805747293149764, 37.643788896079386],
              [126.805705468786982, 37.643774559483774],
              [126.805458842677737, 37.643690006256158],
              [126.805109143290736, 37.643643536718912],
              [126.804983012570986, 37.643626775307133],
              [126.804743418175647, 37.643595807185939],
              [126.804531105940342, 37.643534718411708],
              [126.804394176562582, 37.643459989618698],
              [126.804327316179354, 37.643423504404318],
              [126.804225986494316, 37.643344938378277],
              [126.804188364332788, 37.64331786593349],
              [126.804091804087932, 37.643309469242645],
              [126.80398665019905, 37.643329080102674],
              [126.803900452432373, 37.643306346583259],
              [126.803842949997517, 37.643292878535163],
              [126.803645169549938, 37.643303439337473],
              [126.803454608297329, 37.643365225223896],
              [126.803447713167216, 37.643367295421974],
              [126.803443812124058, 37.643368460338479],
              [126.803386862718952, 37.643386687815834],
              [126.803357834502606, 37.643395111463917],
              [126.803302095249165, 37.643411559614862],
              [126.803300252780588, 37.643412102149092],
              [126.803123790473549, 37.643469045004721],
              [126.803094030282764, 37.643478646805455],
              [126.80288639422885, 37.643545646028478],
              [126.802860984979858, 37.643553846678763],
              [126.802852317169169, 37.643575184861959],
              [126.802480522907501, 37.644223354465609],
              [126.802173654524836, 37.644758379283886],
              [126.802127325953307, 37.644839121940116],
              [126.802087573917291, 37.644908482764897],
              [126.802006371444023, 37.645013546872093],
              [126.802006192431392, 37.645013780158038],
              [126.802063620008823, 37.645037412926996],
              [126.802261430156875, 37.645128159658945],
              [126.802432775418524, 37.64520676462444],
              [126.802723204255742, 37.645339999672593],
              [126.802657963034932, 37.645436618486507],
              [126.802609124257572, 37.6455089452134],
              [126.802190292256441, 37.646129212591873],
              [126.80215571172107, 37.646180421539853],
              [126.802097729007301, 37.646159931164121],
              [126.801760609452401, 37.646040795310235],
              [126.801589155363231, 37.646336610833579],
              [126.801571259634528, 37.646333878417089],
              [126.801319715083366, 37.646754747144165],
              [126.801161033233981, 37.647024859689111],
              [126.801041953194982, 37.647232692417283],
              [126.800979390589504, 37.647340749544604],
              [126.800923443863852, 37.647437373753206],
              [126.800797432659124, 37.647651411335566],
              [126.80065604796691, 37.647894632730292],
              [126.800596619904681, 37.647996864017557],
              [126.800472512168142, 37.648218290771226],
              [126.800389398562743, 37.648365007981248],
              [126.800276329728689, 37.648568165188877],
              [126.800171392972615, 37.648765481253683],
              [126.800133042873341, 37.648837592412121],
              [126.800122802799933, 37.648856845286993],
              [126.800090291737575, 37.648853458097292],
              [126.799901747443315, 37.649189430031115],
              [126.799833986192738, 37.64931017336707],
              [126.79977673532143, 37.649401703962894],
              [126.799699686223022, 37.649525096063876],
              [126.799647091684307, 37.649615192282724],
              [126.799504681284787, 37.649856500693168],
              [126.799462130597277, 37.649919495892945],
              [126.799333155714066, 37.650156808067671],
              [126.799307590875472, 37.650203847867196],
              [126.799270369564113, 37.650264961132308],
              [126.799193625007462, 37.650401237344603],
              [126.799181392186867, 37.650421995224548],
              [126.799062823681027, 37.650623192365089],
              [126.798945801515373, 37.650821657633621],
              [126.798674848033997, 37.651285014228847],
              [126.798314484965402, 37.65191408434147],
              [126.798260151479525, 37.652008874470319],
              [126.798010542150962, 37.652444328750342],
              [126.79769086452211, 37.653002015940828],
              [126.797367565166908, 37.65353978836017],
              [126.797309910978584, 37.65363566957069],
              [126.79704516970294, 37.654075939220789],
              [126.797038338759492, 37.654087190321789],
              [126.797029005069916, 37.654102489683886],
              [126.79680487125276, 37.65446744711479],
              [126.796764077222093, 37.654539587580992],
              [126.796650798029731, 37.654739904200099],
              [126.796483821586364, 37.655047297004053],
              [126.796346265839063, 37.655275797178334],
              [126.796107194947623, 37.655187731813811],
              [126.796053775201187, 37.655280044956562],
              [126.795288942347909, 37.656601732445779],
              [126.795185381571471, 37.656758681573507],
              [126.795073870425597, 37.656954882672792],
              [126.795062130616259, 37.656975538486186],
              [126.795042348287851, 37.657010343907508],
              [126.794950865467356, 37.657143618683968],
              [126.794855664864542, 37.657309140402027],
              [126.794774802288174, 37.657449809897734],
              [126.794665153408786, 37.657640556979317],
              [126.794548544031784, 37.65784340947998],
              [126.794533379248136, 37.657869787094668],
              [126.794523478662384, 37.657887021604367],
              [126.794201531503731, 37.657790568296846],
              [126.793959553646232, 37.658261248596546],
              [126.793834879863809, 37.658489190038772],
              [126.793827203208153, 37.658503213762124],
              [126.793755428714661, 37.658634431553473],
              [126.793906899863757, 37.658692810448471],
              [126.794004611152772, 37.658730445176104],
              [126.794034032996649, 37.658741777868983],
              [126.793980777375012, 37.658833931444704],
              [126.793945602733899, 37.658894806390229],
              [126.79391456597692, 37.658948518929961],
              [126.793190176887535, 37.660201936718074],
              [126.792848939319114, 37.660767080549284],
              [126.792831292858864, 37.660796304754392],
              [126.792825941722754, 37.660805125481531],
              [126.792705953426648, 37.660996909121693],
              [126.792640181449329, 37.661109938276439],
              [126.7925727149793, 37.661225881125141],
              [126.792317277181496, 37.66166474404352],
              [126.792179830725459, 37.661897579534916],
              [126.792112183229037, 37.662014046484728],
              [126.792103192247126, 37.662027906267724],
              [126.792011900399956, 37.662169105095799],
              [126.791926545334476, 37.662335183587984],
              [126.791753560329042, 37.66263075548153],
              [126.7916129174329, 37.662865032113089],
              [126.791597900698434, 37.662890047406805],
              [126.79140422787917, 37.663205674242995],
              [126.791042171275762, 37.663802012889661],
              [126.790673550089792, 37.664409150183772],
              [126.790623493653129, 37.664505481872318],
              [126.790524720096883, 37.664695573646874],
              [126.790476581028713, 37.664805315879036],
              [126.790005610674385, 37.665462182251304],
              [126.789616301037171, 37.666296232154032],
              [126.789580297025807, 37.666373420060111],
              [126.789536019031033, 37.666468353845701],
              [126.789391912970146, 37.666684868713872],
              [126.789375980993469, 37.66670772492661],
              [126.789326247010948, 37.66677917241779],
              [126.788940368724383, 37.667402129879243],
              [126.788721506092571, 37.667755456589347],
              [126.788586680032068, 37.667987213665818],
              [126.788528669348253, 37.668086936759003],
              [126.788485729973758, 37.668160649323916],
              [126.788464324708784, 37.668196039535921],
              [126.788421318168332, 37.668267154057041],
              [126.788305339761621, 37.66845890479955],
              [126.788129846140748, 37.668749240764505],
              [126.787763552204154, 37.669409710881581],
              [126.787406465509591, 37.670018389498985],
              [126.787301250268655, 37.670217148896562],
              [126.787262186752571, 37.670290941183623],
              [126.786962251659332, 37.670809625020375],
              [126.786859231399163, 37.671002245730747],
              [126.786831177477154, 37.67105469982117],
              [126.786799971510462, 37.671113047520244],
              [126.786751609491347, 37.671190921366531],
              [126.786702722222529, 37.671269639258156],
              [126.786424723612001, 37.671764575540543],
              [126.786197261543279, 37.672108151453571],
              [126.785997028744433, 37.672441685171343],
              [126.785982638807099, 37.672478869538189],
              [126.785915822786549, 37.672575872723854],
              [126.785896811732044, 37.672603497616102],
              [126.785819230093267, 37.672716208701111],
              [126.785759990071782, 37.672802271450884],
              [126.785725785427289, 37.67285175420384],
              [126.785725330000602, 37.672852420226846],
              [126.785719810557126, 37.672860401357603],
              [126.785556467459699, 37.673096515634789],
              [126.785657583311746, 37.673214629896378],
              [126.785820362919907, 37.673404770803472],
              [126.785919795400716, 37.673475228948767],
              [126.785958828734991, 37.673499806382239],
              [126.786172077468393, 37.673599654140169],
              [126.786186197816292, 37.673582930844454],
              [126.786251849703234, 37.67349718790841],
              [126.78628495882819, 37.673449370331198],
              [126.786334032520131, 37.673378499787567],
              [126.78645438188866, 37.673176892837432],
              [126.786468938100811, 37.673152412659398],
              [126.786495999758145, 37.673107063206665],
              [126.786631453051456, 37.673163952941955],
              [126.786670644681323, 37.673180412888811],
              [126.786771790166966, 37.673222889215673],
              [126.787128525468034, 37.67337282859873],
              [126.787978208982992, 37.673729803314849],
              [126.788097531656945, 37.673779932311923],
              [126.788190425066517, 37.673818925377958],
              [126.788194104745315, 37.673820470820836],
              [126.788180299982926, 37.673851800387652],
              [126.788170261060685, 37.673874468441475],
              [126.788160676624543, 37.673896091901007],
              [126.787925988147819, 37.674427600863588],
              [126.787515361052641, 37.675357648586626],
              [126.787503837328273, 37.67538375606437],
              [126.788028944758977, 37.675631749717191],
              [126.788109215899794, 37.675669657164406],
              [126.788850519211692, 37.676019743197052],
              [126.788851501350791, 37.676020207892165],
              [126.789056856213222, 37.676117188910197],
              [126.789783799578373, 37.676460487501515],
              [126.789813580458812, 37.676474550802233],
              [126.789870332359939, 37.676501351742715],
              [126.790633703758473, 37.676861838136887],
              [126.79096774046009, 37.67701957363915],
              [126.790824609526695, 37.677326550534019],
              [126.790809668880925, 37.677358598224572],
              [126.790792874546085, 37.677374279379059],
              [126.79073341230611, 37.677429808250167],
              [126.791036517550182, 37.677872284288064],
              [126.791151568589029, 37.678196117748364],
              [126.791157397434631, 37.678215670004192],
              [126.791188669548461, 37.678320897998255],
              [126.791192325702241, 37.678334481259562],
              [126.791300448897388, 37.678744172481103],
              [126.79150274278777, 37.678722099597216],
              [126.791537783871689, 37.678718108141588],
              [126.791658097654192, 37.678704447544689],
              [126.792205753191453, 37.678617484474501],
              [126.792350125902516, 37.678633597611366],
              [126.792530821128864, 37.678653840206614],
              [126.79262674490856, 37.678665983162489],
              [126.792647577412112, 37.678675029397475],
              [126.792663769627623, 37.678681907158534],
              [126.792710416855797, 37.678701989866695],
              [126.792774814192484, 37.678744632729149],
              [126.792805913548321, 37.678765226047041],
              [126.792813833503061, 37.678770737351428],
              [126.792844837891352, 37.67879106329837],
              [126.792862828193506, 37.678802537738946],
              [126.792987530434218, 37.678881502094391],
              [126.792993189294705, 37.678884846573808],
              [126.793008013866242, 37.678893882866262],
              [126.793032683710024, 37.678908702470167],
              [126.793060859513872, 37.678926681487319],
              [126.793065659152404, 37.678929747712296],
              [126.793143834246763, 37.67897968984397],
              [126.793197893960709, 37.679014226218108],
              [126.793280901009638, 37.679065522160698],
              [126.793293518080162, 37.679073320292531],
              [126.793334256391162, 37.679098527412577],
              [126.793519960288634, 37.679213549055369],
              [126.793600308046166, 37.679262973922633],
              [126.793634916406987, 37.679284302788268],
              [126.793644668902061, 37.679290312627927],
              [126.793653381885576, 37.679295681710265],
              [126.79369536724468, 37.679321435164994],
              [126.793761822839357, 37.679389078960185],
              [126.793804120522424, 37.679432139623529],
              [126.793871369336756, 37.679500586677428],
              [126.793796009079458, 37.679898050926795],
              [126.793751479646673, 37.680134388406074],
              [126.7937349192825, 37.680220843145534],
              [126.793720995625165, 37.680293536070373],
              [126.793722373939204, 37.680326145126472],
              [126.793731634284839, 37.680544962143919],
              [126.793731841850487, 37.680549862970906],
              [126.793737798214437, 37.680690622874636],
              [126.793617063242152, 37.680936284109862],
              [126.793537830538668, 37.681092918843973],
              [126.793501359989989, 37.681165016989056],
              [126.793690852563159, 37.681340793882555],
              [126.793708731641544, 37.68135737891987],
              [126.793720564889284, 37.681388923932566],
              [126.79377201731144, 37.68152607068118],
              [126.793805979786399, 37.681616600884439],
              [126.793820920231283, 37.681656427806907],
              [126.79370029466925, 37.681941480183895],
              [126.793651573674623, 37.682056610686359],
              [126.793795988030354, 37.682304721523444],
              [126.793799823208303, 37.682311214173772],
              [126.793796738177676, 37.682319859199886],
              [126.793663627394906, 37.682692805688497],
              [126.793649917918088, 37.682730892726312],
              [126.7937907706971, 37.683200005092239],
              [126.794200310364758, 37.683381550282718],
              [126.794510491011962, 37.68362793741467],
              [126.794614318015164, 37.68371041016988],
              [126.794618756553874, 37.683832122278226],
              [126.794649607835353, 37.684677751344502],
              [126.794689595141165, 37.684677001760477],
              [126.795351395888062, 37.684664566614266],
              [126.795471157335228, 37.684709405425757],
              [126.795647318991996, 37.684775360047404],
              [126.795985798489014, 37.685036060338184],
              [126.7961598273736, 37.685061883666208],
              [126.796657349852936, 37.68513570668042],
              [126.79671225620325, 37.685075337380525],
              [126.79694231179316, 37.684822392399269]
            ]
          ]
        ]
      },
      id: '31103540'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '장항1동',
        ADM_CD: '31103580'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.755063912197912, 37.662126079920462],
              [126.755288520178496, 37.66202843480557],
              [126.755335665266827, 37.662096501083738],
              [126.755362268904079, 37.662134913948854],
              [126.755454556440725, 37.662268168480672],
              [126.755482272309351, 37.662308183679457],
              [126.755483438008284, 37.662308053135128],
              [126.755524273215187, 37.662366429049285],
              [126.755714438930113, 37.662643424791682],
              [126.755763889202882, 37.66271552353588],
              [126.755966779149645, 37.663011357467873],
              [126.756117879708825, 37.663231661709347],
              [126.756172044417909, 37.663310644566955],
              [126.756206929639248, 37.663361496334723],
              [126.756233064372353, 37.663399608605907],
              [126.756237192579491, 37.663405626627551],
              [126.756318655522961, 37.663524551490738],
              [126.756320705011703, 37.663527548690723],
              [126.756325336138573, 37.663533162482508],
              [126.756337882847504, 37.663548384703368],
              [126.756382905514371, 37.663602997556879],
              [126.756906226975232, 37.663036382406851],
              [126.757245666460292, 37.662670827752443],
              [126.757509595592779, 37.662386589988159],
              [126.75766216570193, 37.662206350237859],
              [126.758099273856743, 37.661553238636685],
              [126.758153825052176, 37.661472715078816],
              [126.75816559140037, 37.661454250677799],
              [126.758360923552345, 37.661147529513954],
              [126.758410884496556, 37.661083664055653],
              [126.758480136328373, 37.661081462164326],
              [126.75867513835253, 37.661095921122545],
              [126.758689649677024, 37.661038774727601],
              [126.758786279136842, 37.660658052164187],
              [126.758836208769353, 37.660461336749123],
              [126.758944444555183, 37.660356336703771],
              [126.75910497171418, 37.660200608688662],
              [126.759201513364374, 37.660205581059458],
              [126.759412372051429, 37.660081048033156],
              [126.759405257480751, 37.660038509594067],
              [126.759386598175766, 37.659956121484768],
              [126.759499031081177, 37.659848686656474],
              [126.759555916185818, 37.659750237135299],
              [126.759603542635205, 37.65966780546465],
              [126.759606206063125, 37.659666066405805],
              [126.75967686972605, 37.659619856560212],
              [126.759681396581627, 37.659616906099927],
              [126.759755980947205, 37.659568266511194],
              [126.759920060164845, 37.659461411350357],
              [126.759920291211245, 37.659460978362318],
              [126.759986682634533, 37.659417615841598],
              [126.760230335163669, 37.65925851401277],
              [126.760286115188109, 37.659193139354933],
              [126.760322482240724, 37.659150496882951],
              [126.760366257826036, 37.659046299026777],
              [126.760385502632801, 37.659000506300927],
              [126.760406275668913, 37.658951058373979],
              [126.760682580296944, 37.65829341673642],
              [126.761167774661786, 37.657191978817231],
              [126.76125730133235, 37.656980971891493],
              [126.761445487990514, 37.656537716718191],
              [126.761568724398899, 37.656219581959846],
              [126.761572563837291, 37.656209660790758],
              [126.761578144532805, 37.656197230421164],
              [126.761586270728628, 37.6561791538713],
              [126.761776305539229, 37.655756074076038],
              [126.761914586863114, 37.655420381534512],
              [126.761948679227046, 37.655337653238703],
              [126.762445754689494, 37.654130960384819],
              [126.762617024404364, 37.653715183217336],
              [126.762631669195301, 37.653682140289028],
              [126.762722234199771, 37.653477848038484],
              [126.763000781225529, 37.652849494625706],
              [126.763086071194934, 37.652630677610709],
              [126.76308994424646, 37.652620756594601],
              [126.763098125735993, 37.652599759981513],
              [126.763119580921312, 37.652587371010775],
              [126.763108181994568, 37.65257374208484],
              [126.763134725582916, 37.652515857208826],
              [126.763212289350363, 37.652346690843437],
              [126.763228002136358, 37.652312434108879],
              [126.763235159736411, 37.652307400753052],
              [126.763232007756798, 37.652299647704275],
              [126.763260654624915, 37.652232638307645],
              [126.76335725424839, 37.65200672437129],
              [126.763469391906227, 37.651844504807542],
              [126.763595496242914, 37.651743489323941],
              [126.76390377882646, 37.651555769376507],
              [126.763996450288175, 37.651505431800778],
              [126.764039244867206, 37.651482183246458],
              [126.764154052001274, 37.651419813963528],
              [126.764898079616586, 37.650982111593059],
              [126.765260441613037, 37.65076893814058],
              [126.765515186179954, 37.65061906596025],
              [126.765582974039816, 37.650579187985265],
              [126.765862886216837, 37.650414509739221],
              [126.765878809534811, 37.650396701316552],
              [126.766253921911883, 37.650183218347962],
              [126.766277271118582, 37.650169921222307],
              [126.766359167389425, 37.650123312925977],
              [126.766567440252444, 37.650003529466424],
              [126.766761316038284, 37.649892022242923],
              [126.766816293838986, 37.649860356111965],
              [126.766878695580417, 37.649824415142064],
              [126.766880500545781, 37.649823463744568],
              [126.766899651436489, 37.64981332027638],
              [126.76693022494193, 37.649797125991135],
              [126.766950231531808, 37.649784806626144],
              [126.767064767334972, 37.64971423613644],
              [126.7678122824056, 37.649287663636564],
              [126.768232542392624, 37.649041811214026],
              [126.768276687113399, 37.649057214687417],
              [126.768493132922274, 37.648978897966259],
              [126.769637134998206, 37.648218295324071],
              [126.769739214761927, 37.648151194496975],
              [126.769770266081011, 37.648134692367528],
              [126.769993080155601, 37.648016346573876],
              [126.770045774756895, 37.64798835661518],
              [126.770174868978174, 37.647872601377323],
              [126.770209401050764, 37.647841639972789],
              [126.770382032809977, 37.647786119008913],
              [126.77041166205197, 37.647768951748034],
              [126.77060698004567, 37.647655765158824],
              [126.770801905201807, 37.64754281376316],
              [126.770992691541778, 37.647432257423951],
              [126.771300859691891, 37.647253674611008],
              [126.771333290700667, 37.647234878601864],
              [126.771355313443223, 37.647222111255822],
              [126.771620437786098, 37.647068480806951],
              [126.771653183324275, 37.647049497372265],
              [126.772083275498829, 37.646800260388034],
              [126.773301421222769, 37.646081886197379],
              [126.773863076366482, 37.645750650003791],
              [126.774169284045129, 37.645550321962425],
              [126.774422415474902, 37.645400164359906],
              [126.774438376204429, 37.645380052997318],
              [126.774456653123252, 37.645356986910215],
              [126.774509695138079, 37.645352581159628],
              [126.774602835159996, 37.645315822800512],
              [126.775228230857522, 37.644951040620775],
              [126.776113247035127, 37.644434814084157],
              [126.776272342729143, 37.64434201263937],
              [126.776292064072976, 37.644330510122671],
              [126.776352721359856, 37.644295137425985],
              [126.776405946228337, 37.644264081528362],
              [126.776502719687272, 37.644207631935814],
              [126.776657033323929, 37.644117630801048],
              [126.776796397419957, 37.644036331154915],
              [126.776882842328163, 37.643985905485039],
              [126.776947358963241, 37.643948277581678],
              [126.777096028929563, 37.643861552746245],
              [126.777261292038062, 37.643765103119108],
              [126.777272778145985, 37.643758400921421],
              [126.777297590198046, 37.643743926202127],
              [126.777453728283035, 37.6436528409766],
              [126.778252297320194, 37.64318698097491],
              [126.778911537135272, 37.642802392812932],
              [126.779104134835649, 37.642690033385172],
              [126.779212093332092, 37.642620863667439],
              [126.779539202400386, 37.642354340372648],
              [126.779550860704944, 37.642343613676807],
              [126.779707681011956, 37.642279421569654],
              [126.77977366077593, 37.642225546506381],
              [126.779786391484933, 37.642215152614071],
              [126.77976854695828, 37.642157996908395],
              [126.779791558151942, 37.642139517784983],
              [126.779844948849018, 37.642107413982728],
              [126.780100275621237, 37.641929273923154],
              [126.78012493717759, 37.641912068464244],
              [126.780379857743327, 37.64172478585634],
              [126.781063484636263, 37.641207458822635],
              [126.781406509393179, 37.640947873701172],
              [126.781646109728953, 37.640766548293065],
              [126.781666779276961, 37.640750910842414],
              [126.781827592556368, 37.640629215701082],
              [126.781887960321441, 37.640583534379871],
              [126.781903516971141, 37.640571760398437],
              [126.782227906939568, 37.64032626342037],
              [126.782469751391659, 37.640143236534286],
              [126.782517898075213, 37.640106810190588],
              [126.782770423303774, 37.63991570143677],
              [126.78344335324843, 37.639406430662632],
              [126.783447634810614, 37.639403184849797],
              [126.783465530157059, 37.639389899816805],
              [126.783486854732288, 37.639374074937422],
              [126.783505840162462, 37.639359991633455],
              [126.783557391227532, 37.639321736909778],
              [126.783873896268375, 37.639086873561325],
              [126.783889872442742, 37.639075018035477],
              [126.784262389321455, 37.638798575569041],
              [126.784306521808347, 37.638765824075442],
              [126.784579788361839, 37.638563039715585],
              [126.784594990821248, 37.638551590405683],
              [126.784625150265299, 37.638528876866218],
              [126.784672281853048, 37.638493366428669],
              [126.784714489275302, 37.638461576540664],
              [126.785000197423315, 37.638246372417235],
              [126.785522442580145, 37.637852996365545],
              [126.785839851499972, 37.637616181732206],
              [126.785871371120408, 37.637592669412719],
              [126.785888709131356, 37.637579735754009],
              [126.785934702611257, 37.637545419382285],
              [126.786355217245614, 37.637231677106911],
              [126.787577357464798, 37.636319822603255],
              [126.787600045276449, 37.636302888451532],
              [126.787625168973577, 37.636284148321167],
              [126.78763844259062, 37.636274243557516],
              [126.787729202628029, 37.636206517744881],
              [126.787811204327781, 37.636263520633968],
              [126.787844965005988, 37.63628698860235],
              [126.787867951873338, 37.636302957377346],
              [126.787893607087682, 37.636320790877193],
              [126.788037027669631, 37.636420415857856],
              [126.78812924579772, 37.636484473581156],
              [126.788195380533438, 37.636530434736692],
              [126.788312941217981, 37.636612121059905],
              [126.788487057782561, 37.636733114566717],
              [126.788666480377671, 37.636857790186312],
              [126.788828399623156, 37.63697030746826],
              [126.788996739575225, 37.637087277926604],
              [126.789110635896961, 37.637166419455596],
              [126.789161661652074, 37.637201875085729],
              [126.789268841997128, 37.637276352185033],
              [126.78933759828702, 37.637324127543188],
              [126.789394091845836, 37.637363387164541],
              [126.789580456734569, 37.637492885653486],
              [126.789603026579059, 37.637508567660809],
              [126.789606547426359, 37.637511373964287],
              [126.789633647922187, 37.637532965311948],
              [126.789692881685838, 37.637580154947656],
              [126.790329013199312, 37.638086940317997],
              [126.790941107337233, 37.638574565177741],
              [126.791184709127521, 37.638771231358348],
              [126.791522872933427, 37.639043473431002],
              [126.792151149925346, 37.639549268009766],
              [126.792221882663526, 37.63960620742705],
              [126.792387651874932, 37.639739668138169],
              [126.792414777693409, 37.639761507763815],
              [126.792564294540341, 37.63988188261677],
              [126.792669237143556, 37.639966360559583],
              [126.793831805812928, 37.640902236022718],
              [126.793860492732705, 37.640925327590182],
              [126.793920181634661, 37.64097337588661],
              [126.79394823503057, 37.640932872279883],
              [126.793978083376487, 37.640889778176728],
              [126.794006520500972, 37.640848603560087],
              [126.794010277265286, 37.640843163095262],
              [126.79403845613092, 37.640797295515021],
              [126.794057054759591, 37.640767022154421],
              [126.794062336397857, 37.640758427152711],
              [126.794066729366733, 37.640751273463877],
              [126.79409343961396, 37.64073770426716],
              [126.794100316473234, 37.640734212210816],
              [126.794582778303791, 37.640638916408697],
              [126.794810265996645, 37.640593986041438],
              [126.794912791430463, 37.640573733103118],
              [126.795017550903339, 37.640627718657676],
              [126.795084536129309, 37.640662238679667],
              [126.795123765976783, 37.640693570682558],
              [126.795310582034688, 37.640789836275957],
              [126.795342013991316, 37.640806033403059],
              [126.795402631578355, 37.640814050429221],
              [126.795488194007447, 37.640860149664853],
              [126.795573755503028, 37.64090623891834],
              [126.795643623828397, 37.64094541947815],
              [126.795647633942295, 37.640954430557748],
              [126.795737925254898, 37.640998272980084],
              [126.795766852329933, 37.641012320697186],
              [126.795775131717164, 37.641016341369046],
              [126.795775493134968, 37.641016357947684],
              [126.795825904424618, 37.641018698561076],
              [126.795946072888341, 37.641035395406668],
              [126.795986127610988, 37.641054565925693],
              [126.796021413383656, 37.641078593169546],
              [126.796066316526094, 37.641024431735183],
              [126.795965545611921, 37.640958666224762],
              [126.795773809732893, 37.640886704539177],
              [126.795833549281099, 37.64079910777351],
              [126.795834445783555, 37.640797793580859],
              [126.796103992518184, 37.640838265584499],
              [126.796120237511843, 37.640839439595659],
              [126.795903579923973, 37.640737029493891],
              [126.79588276091728, 37.640727506195375],
              [126.795520367357042, 37.640561749968377],
              [126.795405349482991, 37.640540925628301],
              [126.795224348202396, 37.640429457976012],
              [126.79516800388906, 37.640327044203154],
              [126.795157506737837, 37.640231457878272],
              [126.795156032867254, 37.640162037826109],
              [126.795124538516461, 37.640075821533863],
              [126.795065413957644, 37.639625696702183],
              [126.795064012151826, 37.639611874576389],
              [126.795001684622562, 37.639523199053656],
              [126.794984921521944, 37.639501320997681],
              [126.794921414962758, 37.639411732551437],
              [126.794887511379955, 37.639363904663263],
              [126.794864990753823, 37.639333484545361],
              [126.79480383301977, 37.639289814699445],
              [126.794727805512181, 37.639233229133708],
              [126.794660997169245, 37.639198494547003],
              [126.794634757210133, 37.638936894903487],
              [126.794629264262426, 37.638887900129163],
              [126.794844434099474, 37.638893218951722],
              [126.794912852327883, 37.638894909560776],
              [126.794987189275673, 37.638856785089473],
              [126.794979537165247, 37.638781497409212],
              [126.794973970619125, 37.638733671231272],
              [126.79491702239666, 37.638244026091122],
              [126.79491662658495, 37.638240574343378],
              [126.794905677901596, 37.638147133131326],
              [126.794902360165068, 37.638118493434682],
              [126.794895984025288, 37.638063636557725],
              [126.794888827891128, 37.638002086262972],
              [126.794886415649657, 37.637999962738256],
              [126.794885049828082, 37.637998769328831],
              [126.794828401325674, 37.637949043377617],
              [126.794796023530182, 37.637921533297494],
              [126.794736171461437, 37.63787067771657],
              [126.794704865881769, 37.63784269451444],
              [126.794699870730611, 37.637835161997764],
              [126.794634347284486, 37.637736343439819],
              [126.79456721759891, 37.637635765762532],
              [126.794530847299669, 37.63747253679486],
              [126.794615152467856, 37.637191049565203],
              [126.794629421383306, 37.637143402341678],
              [126.794608698427851, 37.636976506595062],
              [126.79457632793634, 37.636895890240112],
              [126.794542987440323, 37.6368128642331],
              [126.794436983319812, 37.63655346621703],
              [126.794460866871447, 37.636316193781958],
              [126.794509857104416, 37.636144003156467],
              [126.794533632285862, 37.636060440768134],
              [126.794498077498815, 37.635988568955796],
              [126.794480251253091, 37.635952528199148],
              [126.794442342226773, 37.635875894093168],
              [126.794415109641406, 37.635820843940934],
              [126.794330795600459, 37.63558446113862],
              [126.794311623652121, 37.635511809360843],
              [126.794315282606448, 37.635418293190199],
              [126.794324634784644, 37.635233069359117],
              [126.794377644757745, 37.635074050476696],
              [126.794419059214874, 37.635023838190243],
              [126.794457785052941, 37.634976884336609],
              [126.79449062320279, 37.634941894648172],
              [126.794533019467295, 37.634933230420266],
              [126.794535035089041, 37.634932818848263],
              [126.794530126341428, 37.634885481468942],
              [126.794521851200415, 37.634805569777271],
              [126.794318248861785, 37.632840368788386],
              [126.794311430749715, 37.632774569764038],
              [126.794309949614998, 37.632761132908762],
              [126.794295064113882, 37.632626339384068],
              [126.79429179402041, 37.63260073831016],
              [126.79428647435833, 37.632553739147951],
              [126.794224564574549, 37.632005909787786],
              [126.79421949120254, 37.631964752702771],
              [126.794214139164296, 37.631921425352942],
              [126.794206759922233, 37.631910778877902],
              [126.794210946095404, 37.631908895806625],
              [126.794196822467143, 37.631854271003583],
              [126.794199843515528, 37.631770433038419],
              [126.794201485951049, 37.631724991549667],
              [126.794201616435487, 37.631718864194802],
              [126.794200667430644, 37.631719027967563],
              [126.794172203937705, 37.631723956509333],
              [126.794115025034003, 37.631733846315505],
              [126.794114681269221, 37.631731369209575],
              [126.79410341026913, 37.631649910871914],
              [126.79409725437786, 37.631605429511033],
              [126.794095263313963, 37.631606129648326],
              [126.794095040445498, 37.631604650135422],
              [126.794094815566282, 37.631603143570629],
              [126.794064635916783, 37.631550003615843],
              [126.794036548738987, 37.631502340029556],
              [126.794037284564141, 37.631501828872217],
              [126.794077276354741, 37.631474227907752],
              [126.794082448946952, 37.631470859043297],
              [126.794082495573576, 37.631470602443514],
              [126.794088326077656, 37.631439255757257],
              [126.79409628544208, 37.631400308281641],
              [126.794117258663562, 37.631308401763867],
              [126.794164250169786, 37.631088791065139],
              [126.794187314816512, 37.630979920508587],
              [126.794202810958339, 37.630909537388789],
              [126.79425173811093, 37.630682685222418],
              [126.794269780861015, 37.630601015557048],
              [126.794297238611364, 37.63048044134878],
              [126.794323359958327, 37.630366954401296],
              [126.794413551354012, 37.629968394256011],
              [126.794413950942186, 37.629966645363005],
              [126.794428601918526, 37.629902180681242],
              [126.79446403216194, 37.629744769898657],
              [126.794532212299842, 37.629426795634991],
              [126.794537907696366, 37.629400232362464],
              [126.794550883651496, 37.629337185348916],
              [126.794602041151904, 37.629308417932172],
              [126.794571538012406, 37.629241959915518],
              [126.794513833787278, 37.629106577734788],
              [126.794469186832416, 37.62903491286383],
              [126.7943999236862, 37.628923726192518],
              [126.794398966990443, 37.62892219001391],
              [126.793965971056153, 37.628703310467557],
              [126.793859688373175, 37.628515820733703],
              [126.793860287863382, 37.628513905391259],
              [126.793859709023408, 37.628512919477942],
              [126.79391395996339, 37.628337061288057],
              [126.793918396334561, 37.628333148125975],
              [126.794040559759225, 37.628225394217658],
              [126.793942478821563, 37.628136392316797],
              [126.793872349282452, 37.628072749777417],
              [126.793780988967612, 37.627991655667536],
              [126.793756410965955, 37.627969835184857],
              [126.793716048470856, 37.627933688218924],
              [126.793662190054377, 37.627885440316597],
              [126.7936165169166, 37.627844532248375],
              [126.793523631637967, 37.627761298967023],
              [126.793437885875548, 37.627684314020243],
              [126.793283329138816, 37.627545543545075],
              [126.793162194073176, 37.627436779157108],
              [126.793092994370639, 37.627374651449799],
              [126.793006212237501, 37.627297102957108],
              [126.792784934260425, 37.627099293314636],
              [126.79269497849225, 37.627018879170848],
              [126.792693691570818, 37.627019549256055],
              [126.792567768425755, 37.627086615766643],
              [126.792431916290369, 37.627068247762899],
              [126.792309304002728, 37.627051669738691],
              [126.792305523255138, 37.627046195820945],
              [126.792173169508445, 37.62685456609308],
              [126.792137004426877, 37.626802206314004],
              [126.792044315413634, 37.62666800465756],
              [126.792034498543529, 37.626648486048651],
              [126.79194261631261, 37.626465831868693],
              [126.791924857801803, 37.626430539193038],
              [126.791813527046941, 37.626209232745651],
              [126.791655961590536, 37.626098920482676],
              [126.791632239934927, 37.626083002096642],
              [126.791542036699795, 37.626022471620935],
              [126.791280795580747, 37.625843819407073],
              [126.791236908029973, 37.625807873946009],
              [126.79112283593787, 37.625736635283374],
              [126.791088900394854, 37.62571550071911],
              [126.790940051166984, 37.62562300507625],
              [126.790868296498147, 37.625685251413543],
              [126.790428307751526, 37.626082714569257],
              [126.79027882672743, 37.626193851692641],
              [126.78985936234406, 37.626499406774307],
              [126.789828245344637, 37.626522324168242],
              [126.789840151947573, 37.626533200970592],
              [126.789923146615791, 37.626609017366789],
              [126.789840155680494, 37.626677283919904],
              [126.789748957379473, 37.626598143553913],
              [126.789738098801621, 37.626588719685117],
              [126.788717355582421, 37.627340532840968],
              [126.788618162090771, 37.627461670162262],
              [126.788344850546977, 37.627758186215175],
              [126.787857293028111, 37.628031557756209],
              [126.787598582173558, 37.628183200293165],
              [126.787460706932663, 37.628264015534668],
              [126.787051646874943, 37.628499778373211],
              [126.786745036553995, 37.628676511893893],
              [126.786708728157834, 37.628697441181934],
              [126.786687372977781, 37.628719945350547],
              [126.786594498908116, 37.628765941978479],
              [126.786397069590464, 37.628878456125541],
              [126.786355242521438, 37.628899680009191],
              [126.78628573299271, 37.628934636722995],
              [126.786036403298738, 37.629069282993953],
              [126.785886238157687, 37.629150997238042],
              [126.785720343051977, 37.629241321997803],
              [126.785547156165535, 37.62933482551913],
              [126.785473275411874, 37.629373621979745],
              [126.785126890480242, 37.629531450146352],
              [126.784712836915574, 37.629722227948697],
              [126.783871221891943, 37.630091799623571],
              [126.783621399526467, 37.630193915999904],
              [126.783244764389707, 37.630342700258808],
              [126.782976056177034, 37.630441392649409],
              [126.782697830261114, 37.630533668588633],
              [126.782552803760026, 37.630579305266039],
              [126.782204848907028, 37.630690577832944],
              [126.782180838485502, 37.630698614918039],
              [126.782170736361294, 37.630702369681394],
              [126.781816245526159, 37.630819669072665],
              [126.781722578724384, 37.630850892338849],
              [126.780528466946123, 37.631028047418461],
              [126.780362546769851, 37.631056051984757],
              [126.780320146238367, 37.631061107591087],
              [126.780165890961172, 37.631079489246396],
              [126.779872323060374, 37.631124102690499],
              [126.779776855937527, 37.631131311945524],
              [126.779447313569918, 37.631231962028053],
              [126.778758604411621, 37.631250950536909],
              [126.77703219037204, 37.631298766948611],
              [126.777020763087151, 37.631304274344195],
              [126.777020280193483, 37.631304284905056],
              [126.776557182586615, 37.631317009483396],
              [126.776296124539087, 37.631320798413796],
              [126.776291791988541, 37.631320904333577],
              [126.775873338999418, 37.631331154619332],
              [126.775736675450545, 37.631399771210418],
              [126.77534216033996, 37.631527495125731],
              [126.774543718367255, 37.63178620970664],
              [126.774286125975621, 37.631839704797414],
              [126.774211008470033, 37.631855304392509],
              [126.77405532916984, 37.631899173255889],
              [126.773956572745533, 37.631927001568236],
              [126.77355433557733, 37.63203999923266],
              [126.773492820445682, 37.632058683541445],
              [126.773323662963278, 37.632101721238115],
              [126.772663751081325, 37.632262592486903],
              [126.771337071994296, 37.63270090340415],
              [126.771207892465668, 37.632731060716516],
              [126.771183328108023, 37.632736794646895],
              [126.770734310625031, 37.632845168187039],
              [126.770270318001224, 37.632957153648235],
              [126.769716888044712, 37.633106388094049],
              [126.769086295838818, 37.633275623691382],
              [126.768540795100051, 37.633718015172455],
              [126.767587569660023, 37.633905594885306],
              [126.767429315495619, 37.633965131978606],
              [126.766907241553469, 37.634161536743548],
              [126.766279487238705, 37.634494856067988],
              [126.765596470126965, 37.634922708013342],
              [126.764139003773323, 37.635300601381445],
              [126.764017713881557, 37.635332286271897],
              [126.762871115817134, 37.635326369126801],
              [126.762631008058662, 37.634916741618412],
              [126.760496662861954, 37.631308495922418],
              [126.759541582216201, 37.629730992885868],
              [126.759519247381235, 37.629694099284194],
              [126.759089031256622, 37.629922614184522],
              [126.759027222218251, 37.629985555995837],
              [126.758733553446191, 37.630152479476685],
              [126.758497259648507, 37.630206053161217],
              [126.758362343542515, 37.630284242570539],
              [126.75771108733349, 37.630661677103504],
              [126.757239618353665, 37.630850186113456],
              [126.757136338259883, 37.630912551266917],
              [126.756532839983805, 37.631276962785648],
              [126.755099258066934, 37.631964070272474],
              [126.754584898588845, 37.632059292551574],
              [126.753553627143006, 37.632255716145913],
              [126.752966124141579, 37.632367609994688],
              [126.751692790079787, 37.632610114958887],
              [126.751367498064724, 37.632762836156886],
              [126.748719925968658, 37.634005787092754],
              [126.74862769297718, 37.634049085310799],
              [126.746036318346839, 37.635242368233968],
              [126.744971897580442, 37.63609849225616],
              [126.743884687308494, 37.636972915029411],
              [126.743645080444409, 37.63715548390784],
              [126.743206873956993, 37.637489371090211],
              [126.742136523872148, 37.638304901894344],
              [126.741957972691011, 37.638440940079121],
              [126.740919548572293, 37.639691615333753],
              [126.740446520142982, 37.640261315916781],
              [126.740402248883612, 37.640314633568217],
              [126.736321789817211, 37.643113473671306],
              [126.73468424418877, 37.64636229772281],
              [126.732602932168078, 37.647478158563615],
              [126.732470070656461, 37.647518443212888],
              [126.732528593579843, 37.647568927632221],
              [126.733657592104564, 37.648542783372186],
              [126.734601254020504, 37.649350364771692],
              [126.73523916537124, 37.649840043957994],
              [126.735411218191373, 37.649971433795244],
              [126.735705830637414, 37.650196260013985],
              [126.735791896609811, 37.650261983271342],
              [126.736042482729601, 37.650453343916276],
              [126.736166597073705, 37.650555523057967],
              [126.736171249399789, 37.650570130280883],
              [126.736205753316113, 37.650681431849243],
              [126.73628706621956, 37.650742700275231],
              [126.73647333955131, 37.650811864050496],
              [126.736575679934575, 37.650823762307304],
              [126.736644263648429, 37.650831798888035],
              [126.736756163495798, 37.650832429217594],
              [126.736861260330798, 37.650913283658895],
              [126.737282185959927, 37.651047966917453],
              [126.737607540568078, 37.651160246061949],
              [126.737645347410009, 37.651219929053099],
              [126.737804552111243, 37.651397822657877],
              [126.737777347111688, 37.65143258395802],
              [126.737801718798238, 37.651494354075638],
              [126.737867507414748, 37.651524054114404],
              [126.737889581512448, 37.651530680846939],
              [126.737928546472375, 37.651541416768232],
              [126.738146084848324, 37.651587196768176],
              [126.73820215438424, 37.651598996667012],
              [126.738133899252148, 37.651833998555944],
              [126.7381165191848, 37.651893963087517],
              [126.738334511777865, 37.651951211405837],
              [126.738350340286445, 37.651955346126677],
              [126.738494030719181, 37.651992742242022],
              [126.738491579190935, 37.652000241647258],
              [126.738484605964686, 37.652014479896607],
              [126.738529268232682, 37.652042373931216],
              [126.738826076812686, 37.652227464498573],
              [126.739048050917603, 37.652393376355874],
              [126.739370023337997, 37.652610236376155],
              [126.739657083384017, 37.65282981060313],
              [126.739682575017, 37.652830858208773],
              [126.739883622519528, 37.652955278208701],
              [126.740028309436582, 37.653048664555413],
              [126.740138506825318, 37.653119796622747],
              [126.740151435763863, 37.653128164866068],
              [126.740184918675808, 37.653150894688231],
              [126.740271813320248, 37.653209885329581],
              [126.740307943183765, 37.653234413178659],
              [126.740582535875902, 37.653420828112083],
              [126.740624364517345, 37.653449389919381],
              [126.740670383420181, 37.653478593387533],
              [126.740797023329193, 37.653558970096249],
              [126.740865221599591, 37.653602598597409],
              [126.74095520513383, 37.653660164694863],
              [126.740964024833261, 37.653665742951162],
              [126.741279674120008, 37.65387807930145],
              [126.74149096351438, 37.654024502440649],
              [126.741585810228557, 37.654090211234326],
              [126.74202188788685, 37.654379213011374],
              [126.742034769292246, 37.654389511376451],
              [126.742065206497614, 37.654435618533611],
              [126.742133653194472, 37.654483064098841],
              [126.742162089188994, 37.654502774851743],
              [126.742278194899683, 37.65458249463029],
              [126.742377135458824, 37.654612174369618],
              [126.7424254926255, 37.654653725872947],
              [126.742437581716857, 37.654664022710762],
              [126.742771545915744, 37.65489243151324],
              [126.742890816895965, 37.654974501828995],
              [126.743056134581181, 37.655078115299503],
              [126.743190699240898, 37.655063814873692],
              [126.744217302243825, 37.654929650005329],
              [126.744222162240661, 37.654928983520186],
              [126.744281248597716, 37.654920869815633],
              [126.745420752664003, 37.654776219676009],
              [126.745483533241199, 37.655106564944525],
              [126.745525499028588, 37.655327214026833],
              [126.745569258693436, 37.655553001284694],
              [126.745595091635025, 37.655686580308952],
              [126.745605492245801, 37.655740005137019],
              [126.745649991325564, 37.655968614615432],
              [126.745671381206051, 37.656076237750305],
              [126.745702644055385, 37.656245868321697],
              [126.74576328696152, 37.656538634373796],
              [126.745887060969508, 37.657128140864131],
              [126.745656218552867, 37.657159624393856],
              [126.745724077298206, 37.657215902484204],
              [126.744768355241703, 37.657343024318251],
              [126.744748336707076, 37.657345665007796],
              [126.743580830887197, 37.657499635995258],
              [126.74331722491408, 37.657534647118027],
              [126.743352599520563, 37.657594910224681],
              [126.74336893589377, 37.65762287669444],
              [126.743378627431895, 37.657636113490391],
              [126.743587982497147, 37.657922117250394],
              [126.743633420280446, 37.657989429149367],
              [126.74370907735792, 37.658101223968707],
              [126.743819346740835, 37.658264361834874],
              [126.743874483540822, 37.658346019972861],
              [126.743990729636991, 37.65851808843356],
              [126.74399368518884, 37.658522455355417],
              [126.744027520321239, 37.65857240717456],
              [126.744047371777, 37.658601820165174],
              [126.744055514374381, 37.658613877962502],
              [126.744131219261263, 37.658725889128704],
              [126.744165496066572, 37.658776689177948],
              [126.744273964348181, 37.65893729857649],
              [126.744282891206481, 37.658950509667036],
              [126.744351313491791, 37.659051800571739],
              [126.74450533526668, 37.659279722993411],
              [126.744608066962229, 37.659428154524505],
              [126.744690833762562, 37.659549518503027],
              [126.74481454131535, 37.659718679378358],
              [126.744867363147378, 37.659790909713067],
              [126.744899283620171, 37.659835578988364],
              [126.744969648258731, 37.65993790225756],
              [126.74508861835946, 37.660111058721],
              [126.745171725969769, 37.66023205769806],
              [126.745338143623314, 37.660473177077549],
              [126.745356396215882, 37.66049969267879],
              [126.745429515951415, 37.660606612010596],
              [126.745484543785679, 37.660687279931878],
              [126.745526600539463, 37.66074989880665],
              [126.745632248551146, 37.660906989956629],
              [126.745689087988836, 37.660991525222158],
              [126.745859232192643, 37.661244530882186],
              [126.746092454898445, 37.661579926434548],
              [126.746208849145304, 37.661747307634705],
              [126.74632895193001, 37.66192001489091],
              [126.746332290316559, 37.661924782583348],
              [126.746334211524328, 37.661927527667252],
              [126.746507157854879, 37.662174620479696],
              [126.746566361527172, 37.6622592055678],
              [126.74664677263462, 37.662373978854795],
              [126.746783354205604, 37.662569067057802],
              [126.746812238572105, 37.662611254906658],
              [126.746931313772023, 37.662785169956749],
              [126.746963226719657, 37.662831728685937],
              [126.746998073061988, 37.662882528825989],
              [126.747168033749603, 37.663130314534143],
              [126.747301213530221, 37.663324476386549],
              [126.747359293312456, 37.663408482458145],
              [126.747383029585578, 37.663442937322209],
              [126.747392111966533, 37.663456123927581],
              [126.747445005822001, 37.663532730363166],
              [126.747519744561913, 37.663640915143198],
              [126.747589251488307, 37.663741526944548],
              [126.747761687775153, 37.663991108582486],
              [126.747842888198718, 37.664108699095891],
              [126.747879322526245, 37.66416146226053],
              [126.747966275490185, 37.664287334659647],
              [126.748052896327422, 37.664412665885571],
              [126.748125169568297, 37.664517259914049],
              [126.748229282995325, 37.66466793230957],
              [126.748343422858142, 37.664833143732579],
              [126.748428682957424, 37.664956590354713],
              [126.748461845260266, 37.665004580718659],
              [126.748540620779522, 37.664970419606448],
              [126.748999389302398, 37.664771475274399],
              [126.749671816000443, 37.66447987435366],
              [126.749739111447994, 37.664450691310542],
              [126.749739713901235, 37.664450426548811],
              [126.749758294243279, 37.664442265193706],
              [126.749804468805891, 37.664421880766142],
              [126.750385251076239, 37.664164570356569],
              [126.750386369465048, 37.664164074526816],
              [126.750661238384168, 37.664042355341621],
              [126.75093556887191, 37.663920872742878],
              [126.751785603050152, 37.663544441906822],
              [126.751963343187299, 37.663474081816176],
              [126.752092393166407, 37.663417953754227],
              [126.752116788525299, 37.663409087108143],
              [126.752164578415659, 37.663386574053817],
              [126.752500820123799, 37.663240390195455],
              [126.752715814744377, 37.663146910896074],
              [126.75283457145602, 37.663095262166898],
              [126.752966465634842, 37.663037920851345],
              [126.753102885032064, 37.662978610712109],
              [126.753234848247445, 37.662921240688306],
              [126.753256566481824, 37.662911802541913],
              [126.753581738673432, 37.662770432458871],
              [126.753947195991955, 37.662611555731409],
              [126.753973425771832, 37.662600153164256],
              [126.754161263353311, 37.662518489725144],
              [126.75416257546911, 37.662518343895918],
              [126.754379575866622, 37.66242364391227],
              [126.754417089885166, 37.662407272383504],
              [126.755063912197912, 37.662126079920462]
            ]
          ]
        ]
      },
      id: '31103580'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '고봉동',
        ADM_CD: '31103600'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.799249435025104, 37.736136401942844],
              [126.799498256797932, 37.73589412789385],
              [126.799536975109746, 37.73586716535516],
              [126.799562741206998, 37.735845811324317],
              [126.799922586515734, 37.735515045310535],
              [126.800469024878424, 37.735009744864421],
              [126.800504384763258, 37.734982725116112],
              [126.800753931119417, 37.734792035721888],
              [126.800798343168267, 37.734758326853601],
              [126.80085286768184, 37.734717308807255],
              [126.80099294508598, 37.734614313623673],
              [126.801121056015916, 37.734520115348225],
              [126.801269946737165, 37.734125914328686],
              [126.801289921473142, 37.734080901243274],
              [126.80130296529876, 37.734043757002283],
              [126.801337420371496, 37.733954009202428],
              [126.8013686938841, 37.733790753005962],
              [126.801383874213215, 37.733684925074058],
              [126.801417733836232, 37.733484229181698],
              [126.801358604327604, 37.733063496897763],
              [126.801350401650723, 37.733017589425174],
              [126.801348367337965, 37.732980700105536],
              [126.801344105295726, 37.732960356522881],
              [126.801316050478235, 37.733001043197518],
              [126.801315065564424, 37.733002235206008],
              [126.801313857239009, 37.733003289791853],
              [126.801312452999767, 37.733004179177328],
              [126.801310891641606, 37.733004881059493],
              [126.801309211900161, 37.733005379444293],
              [126.801307452485759, 37.73300566104146],
              [126.801305658866553, 37.73300571800867],
              [126.80130387530825, 37.733005549707002],
              [126.801302147160555, 37.733005160911809],
              [126.801300521966482, 37.733004564523277],
              [126.80101588485222, 37.732877066217917],
              [126.801014195692602, 37.732876152183643],
              [126.801012784025843, 37.732875055025438],
              [126.801011635014845, 37.732873783668488],
              [126.801010779019549, 37.732872368036183],
              [126.801010246213451, 37.732870857879718],
              [126.801010050933783, 37.732869297448531],
              [126.801010200683479, 37.732867733655397],
              [126.801010691592154, 37.732866216049672],
              [126.801011502826697, 37.732864787770858],
              [126.80101169950369, 37.73286450952979],
              [126.801203667029, 37.732592446800275],
              [126.801190337384838, 37.732557267905889],
              [126.801166932497978, 37.732493593722019],
              [126.801083994879249, 37.732289329307335],
              [126.801111440568974, 37.731840868332888],
              [126.801113184541151, 37.731787653367057],
              [126.801120579132728, 37.731737552594225],
              [126.80112191535504, 37.73170151786352],
              [126.801125753861243, 37.731594250883347],
              [126.801080371566613, 37.731528569836371],
              [126.801033771245642, 37.73145641461965],
              [126.801000232112344, 37.7314042730046],
              [126.800990676190963, 37.731389848489208],
              [126.800568981565803, 37.730753440192707],
              [126.800854780573786, 37.730132541906684],
              [126.801108586674943, 37.729969986095753],
              [126.80111245014497, 37.72996751236051],
              [126.801225686211453, 37.729894987010873],
              [126.801315803527785, 37.729664975746601],
              [126.801377123984537, 37.729508459980352],
              [126.801386853366111, 37.729483627840551],
              [126.80140223206331, 37.729475801833587],
              [126.801716087966753, 37.729316102589955],
              [126.80182671276782, 37.729306992997159],
              [126.801874393552836, 37.729305946848747],
              [126.801892146717762, 37.729295283080184],
              [126.802272854395611, 37.729299585758916],
              [126.802401296263781, 37.729253628084813],
              [126.802582387034704, 37.729142718475558],
              [126.802628025726065, 37.72911013916427],
              [126.802784093351733, 37.729009040730169],
              [126.802944006150952, 37.72892794336488],
              [126.80328781435631, 37.728746644849892],
              [126.803388260258487, 37.728695848206904],
              [126.803399793273883, 37.728693054685429],
              [126.803400878566464, 37.728685740620357],
              [126.803557379981115, 37.728488072338273],
              [126.803616116301299, 37.728412159992402],
              [126.803503418769239, 37.72836503681097],
              [126.803501755162713, 37.728364188758874],
              [126.80350030467558, 37.728363122045167],
              [126.803499107820485, 37.728361872961827],
              [126.803498202840629, 37.728360477787334],
              [126.803497616572699, 37.728358979042127],
              [126.80349736447242, 37.728357422784555],
              [126.8034974597429, 37.72835585325705],
              [126.803497893959886, 37.728354321784806],
              [126.803498658741262, 37.728352875186665],
              [126.803523930127, 37.72831463389592],
              [126.803524919911823, 37.728313395929348],
              [126.80352614665604, 37.728312301770565],
              [126.803527580586547, 37.728311380085614],
              [126.803529185172621, 37.728310654092972],
              [126.803530914797136, 37.728310147858544],
              [126.803532726298258, 37.728309865633911],
              [126.803534569587526, 37.728309824248107],
              [126.803536190848661, 37.728309983445023],
              [126.803537767251299, 37.728310328047726],
              [126.8035392637033, 37.728310848835775],
              [126.803656973326611, 37.728360056588023],
              [126.803820026775398, 37.728163600156321],
              [126.80387492512196, 37.727981812787661],
              [126.803956306935987, 37.727702651376532],
              [126.803971559664404, 37.727566965550871],
              [126.803980553771453, 37.727516301662959],
              [126.80401612557894, 37.727269443223506],
              [126.8042195786321, 37.727278515422057],
              [126.804296998160765, 37.727290463214267],
              [126.804305504672485, 37.72729104519216],
              [126.804309387006754, 37.727297809940175],
              [126.804352745841328, 37.727321811860833],
              [126.804570310718518, 37.727283605329774],
              [126.804655250816637, 37.72726966551825],
              [126.804761306605798, 37.727245913182884],
              [126.804768928441931, 37.727246204192888],
              [126.804861983753511, 37.727244106700866],
              [126.805015767320072, 37.727264635967408],
              [126.805152425951661, 37.727328778775046],
              [126.805343145646262, 37.727394135011885],
              [126.805505875130606, 37.727450995187922],
              [126.805621794423516, 37.727519606565821],
              [126.805654620012746, 37.727502489260722],
              [126.805713911365089, 37.727468792204917],
              [126.805760057003909, 37.727444131799309],
              [126.805764272167593, 37.727441879901484],
              [126.805772306180856, 37.727436797831082],
              [126.805788605282643, 37.727439077703828],
              [126.806066958843104, 37.727404626137833],
              [126.806123001131525, 37.727393178540268],
              [126.806206331457091, 37.727382333165686],
              [126.806228366464836, 37.727360692484716],
              [126.806249632377217, 37.727292308677598],
              [126.806332248794234, 37.727217836079447],
              [126.806391826220576, 37.72720864712042],
              [126.806477053866274, 37.727080960814604],
              [126.807002906164641, 37.726885310596259],
              [126.807123565895921, 37.726902403058155],
              [126.807622531071857, 37.72695556593564],
              [126.807837461498067, 37.726978465086269],
              [126.808216406697824, 37.727038775646804],
              [126.808721463457402, 37.726854054841567],
              [126.809173772727931, 37.726688622759241],
              [126.809179726656041, 37.726686444921341],
              [126.80955108879796, 37.726464060564794],
              [126.809564881530505, 37.726456150286047],
              [126.809894196480968, 37.726267245781123],
              [126.809981126222254, 37.726232484368325],
              [126.810029226942532, 37.726209191102377],
              [126.810160195727718, 37.726142957397059],
              [126.810138741586641, 37.726078162464283],
              [126.810125803927065, 37.725732116798369],
              [126.810125135897152, 37.725714097394267],
              [126.810124559737602, 37.725708927681922],
              [126.810106253034661, 37.725544856829998],
              [126.810210258396779, 37.725421422797019],
              [126.810305467360465, 37.725338804656552],
              [126.810539648033739, 37.725110560751958],
              [126.810663222622523, 37.72495618682516],
              [126.81078494408969, 37.724832502543741],
              [126.810941091769436, 37.72469254691719],
              [126.811099590323693, 37.72460523805043],
              [126.811359235852279, 37.724464314738398],
              [126.811459109925153, 37.724429571946949],
              [126.81155966880209, 37.724401010687934],
              [126.811737934097792, 37.724343015413723],
              [126.811862131958264, 37.724292816115408],
              [126.811958750283139, 37.724283681907352],
              [126.812100579662669, 37.724261664854701],
              [126.81227694793931, 37.72424062245716],
              [126.812345059212873, 37.724232496940616],
              [126.81255031208768, 37.7241548379167],
              [126.812984144715912, 37.72395647449089],
              [126.81309927506652, 37.723896645455739],
              [126.813232607304869, 37.723827357580973],
              [126.813304310628354, 37.723785802795398],
              [126.813470964386497, 37.723693440368798],
              [126.813565052942209, 37.723630523002058],
              [126.813621353447928, 37.723585006278412],
              [126.813725655748669, 37.723477895434016],
              [126.813732737498313, 37.72348072713428],
              [126.813778107821264, 37.72348050994092],
              [126.813801011885516, 37.723478444052105],
              [126.814287228958193, 37.723434586855042],
              [126.81438685857897, 37.723495001095152],
              [126.814463655739246, 37.723546405416329],
              [126.814743555719829, 37.723733755238683],
              [126.814957453497698, 37.724025213144863],
              [126.815322052691556, 37.724434322317926],
              [126.815677366149529, 37.724805404008031],
              [126.815908841111352, 37.724944857925571],
              [126.816245950383916, 37.725009862540823],
              [126.816405533107456, 37.725411040796665],
              [126.817095864844106, 37.725551214486558],
              [126.817161873370239, 37.725558866866884],
              [126.817305117121393, 37.725575469533361],
              [126.817562396389505, 37.725604586340154],
              [126.817867329224526, 37.72563772060289],
              [126.818185156055776, 37.725621889857003],
              [126.818437212102467, 37.72561242526362],
              [126.818482756041234, 37.7256097493797],
              [126.818482467421063, 37.725624528391258],
              [126.818482441408605, 37.72562526191205],
              [126.818482578150366, 37.72562680936074],
              [126.818483046635023, 37.725628313661467],
              [126.818483833626189, 37.725629733277266],
              [126.818484915691783, 37.725631024809417],
              [126.818486260289134, 37.725632148411904],
              [126.818487827984811, 37.725633073211988],
              [126.818489571386124, 37.725633770093616],
              [126.818491439605282, 37.725634219834888],
              [126.81849337830927, 37.725634407700689],
              [126.818494211082097, 37.725634422429515],
              [126.818733481580438, 37.725641219638966],
              [126.818734314493739, 37.725641219044533],
              [126.818734936643338, 37.725641185686037],
              [126.818991327791096, 37.72562226491916],
              [126.81899313434397, 37.725622013973762],
              [126.818994865825729, 37.725621534561732],
              [126.818996478994279, 37.725620839952995],
              [126.818997928305137, 37.725619947009456],
              [126.818999179478283, 37.725618881671267],
              [126.81900019710713, 37.725617668970742],
              [126.819000954780236, 37.725616343005314],
              [126.819001435180155, 37.725614936122305],
              [126.819001623209246, 37.725613486089806],
              [126.819001513965702, 37.725612032537391],
              [126.818994750327803, 37.725573920075874],
              [126.819104273229541, 37.725562500325502],
              [126.819132816920614, 37.725555788147282],
              [126.819217510272381, 37.725567181399441],
              [126.819262331151037, 37.725574566033998],
              [126.819297070021278, 37.725576592697585],
              [126.819346005822325, 37.725583696416273],
              [126.819457761943696, 37.7255999235799],
              [126.819468595912554, 37.725592056345036],
              [126.819468051141641, 37.72559008202208],
              [126.81946051210096, 37.725562772790063],
              [126.819443839810589, 37.725496858841851],
              [126.819444414521016, 37.72549692525179],
              [126.819468225617968, 37.725499707172816],
              [126.819534200945697, 37.72550741391543],
              [126.81954823661944, 37.725494768350295],
              [126.819742078077581, 37.725513365393823],
              [126.819919865673256, 37.725505477169392],
              [126.820568899456234, 37.725506474250793],
              [126.820860867098943, 37.725479893174025],
              [126.821139409960523, 37.725436956296555],
              [126.821300293891497, 37.725445371696814],
              [126.821444397250986, 37.725452909748974],
              [126.821467964177387, 37.725454352307715],
              [126.821484070840071, 37.725608954027443],
              [126.821490458070542, 37.725680753576697],
              [126.821537788575043, 37.725825546609848],
              [126.821609806206055, 37.725943061854011],
              [126.821661379207313, 37.726165570884682],
              [126.821682561373819, 37.726201073029038],
              [126.821720497194065, 37.726273775141543],
              [126.82176214893876, 37.726344789725459],
              [126.821808033885176, 37.726428198308398],
              [126.821893696352447, 37.726696365712314],
              [126.821897354952085, 37.72672199134081],
              [126.821914559291855, 37.726775495075493],
              [126.821920440167531, 37.726841402478598],
              [126.82192074618618, 37.726842936475897],
              [126.82192137732379, 37.72684440744856],
              [126.821922318121793, 37.726845768439254],
              [126.821923342623393, 37.726846785608743],
              [126.821922478635202, 37.726847454823329],
              [126.821921410631887, 37.726848595153918],
              [126.821920580967841, 37.726849855829172],
              [126.821920011514834, 37.726851202724859],
              [126.821919716177035, 37.72685260437494],
              [126.821919700948527, 37.726854025662519],
              [126.821928830966229, 37.726956368521535],
              [126.821929136985489, 37.726957902518805],
              [126.82192977041008, 37.726959371701938],
              [126.821930711201432, 37.72696073359382],
              [126.821931932512683, 37.726961945678177],
              [126.821933398337052, 37.726962974399179],
              [126.821935063573676, 37.726963787951398],
              [126.821936174722524, 37.726964138645378],
              [126.821935022705048, 37.726964785473029],
              [126.821933741570703, 37.726965778564612],
              [126.82193267356584, 37.726966918895265],
              [126.821931843908843, 37.726968178669289],
              [126.821931274455096, 37.726969525564996],
              [126.821930979108799, 37.726970928116344],
              [126.821930963888676, 37.72697234850267],
              [126.821940096203363, 37.727074691372081],
              [126.821940402223291, 37.727076225369295],
              [126.821941034514353, 37.727077694545819],
              [126.821941976433862, 37.727079057345392],
              [126.821943197739003, 37.727080270330902],
              [126.821944663582286, 37.727081297249249],
              [126.821946328813439, 37.727082111702593],
              [126.821948146029186, 37.72708268547742],
              [126.821950060902893, 37.727083002938919],
              [126.821952014511609, 37.72708305473499],
              [126.822015363722286, 37.727080391572613],
              [126.822052388099948, 37.727185939489637],
              [126.822062831037215, 37.727217851148012],
              [126.822063816889766, 37.727220868113875],
              [126.822073143622816, 37.727247631345001],
              [126.822085588216538, 37.727308186613136],
              [126.822086783687652, 37.727325927778715],
              [126.822103469184427, 37.727395672011895],
              [126.822112051369828, 37.727431541458238],
              [126.822190997007269, 37.7276211437459],
              [126.822210526460864, 37.727754632748272],
              [126.822333996453082, 37.72800483762105],
              [126.822546105503946, 37.728317117059269],
              [126.822540043428404, 37.72840805201659],
              [126.822556269543313, 37.728428889046761],
              [126.82260259231839, 37.728488387009001],
              [126.822631440805125, 37.72857712146849],
              [126.822636195628888, 37.728590075459969],
              [126.822643885801341, 37.728637954274355],
              [126.82274932765344, 37.728862508203328],
              [126.822770392177034, 37.729023587970516],
              [126.822799200849047, 37.729058544256013],
              [126.822815470538544, 37.729075741085573],
              [126.822846595305322, 37.729105347836501],
              [126.822884126739211, 37.729121172408412],
              [126.822904670555786, 37.729130222448894],
              [126.822978678745599, 37.729162426104914],
              [126.822979571923398, 37.729088377340403],
              [126.822978768491524, 37.729049237601373],
              [126.823135396807885, 37.728852958026586],
              [126.823161737582751, 37.728809968696069],
              [126.823190377572772, 37.728763223257786],
              [126.82321429677215, 37.728694281948172],
              [126.823249628242067, 37.728667027106916],
              [126.823267573754705, 37.728648467486181],
              [126.823299221132018, 37.728610792099389],
              [126.823448481095255, 37.728451376866474],
              [126.823570215758011, 37.728315569390325],
              [126.823598110459017, 37.7282885818206],
              [126.823619229264239, 37.728275945773554],
              [126.823696280271804, 37.72822144544066],
              [126.823752400066454, 37.728177040497108],
              [126.823768256624419, 37.72814158536638],
              [126.823781242266648, 37.728121342396626],
              [126.823803498583999, 37.72807857103755],
              [126.823812790728681, 37.728049313548084],
              [126.823821138072859, 37.728040595231313],
              [126.823824490994497, 37.727973866618484],
              [126.82382290871729, 37.727892785985063],
              [126.823816087066163, 37.727857016892784],
              [126.823810278379497, 37.727838140664353],
              [126.823772283386305, 37.727793599605867],
              [126.823738962698101, 37.727719224755461],
              [126.823721668035901, 37.727684844891712],
              [126.823721614208196, 37.727635858064851],
              [126.823726178982994, 37.727505505302993],
              [126.823798171500371, 37.727416922355303],
              [126.823821849999931, 37.727372470078699],
              [126.823837400662015, 37.727315345543389],
              [126.823898912862219, 37.727236036444083],
              [126.82396914348196, 37.727144360780478],
              [126.824001265023327, 37.727055159676439],
              [126.824026090109456, 37.72697692179095],
              [126.824041259077219, 37.726932175511955],
              [126.824114791433459, 37.726868091747235],
              [126.82416628368496, 37.726827910665968],
              [126.824210319033483, 37.726794753131735],
              [126.824276398606514, 37.726744334654796],
              [126.824319696275509, 37.726711299935921],
              [126.824371849828282, 37.726693358020505],
              [126.824488593699527, 37.72664088185558],
              [126.824523286682904, 37.726586313081668],
              [126.824569476822518, 37.726538517765967],
              [126.824629533721662, 37.726478352898397],
              [126.824749107345625, 37.726428982395056],
              [126.824840758446314, 37.726343528996402],
              [126.825074218543861, 37.726178890293397],
              [126.825096781715359, 37.726154426159326],
              [126.825131409978724, 37.726131675092581],
              [126.825129434600996, 37.72614152778813],
              [126.825136200715235, 37.726201228245493],
              [126.825138569527127, 37.726249936313437],
              [126.825138903619646, 37.726257262321155],
              [126.825164215392078, 37.726345701955331],
              [126.825198987404491, 37.726406290988685],
              [126.825200181666133, 37.726425996990429],
              [126.825233552118164, 37.726404936774685],
              [126.825263906190628, 37.726386395588115],
              [126.825301720909891, 37.726359143511587],
              [126.825377171912479, 37.726308288630761],
              [126.82550434444812, 37.726267656741676],
              [126.825632547226448, 37.726242791022969],
              [126.825866975899984, 37.726262006759441],
              [126.826018363844412, 37.726251807829733],
              [126.826186903769454, 37.726260510003911],
              [126.82626333686828, 37.726316366991931],
              [126.826297236507969, 37.726371888927439],
              [126.82636520454389, 37.72648235869287],
              [126.826468106953783, 37.726589779677241],
              [126.826522975147498, 37.726623367944505],
              [126.826599521700231, 37.726631924289293],
              [126.826646145962215, 37.726625524481683],
              [126.826695790993682, 37.726617993632168],
              [126.826761372536581, 37.726614991857367],
              [126.826819354579271, 37.726607472515589],
              [126.827114499167052, 37.726590458870824],
              [126.827182075602622, 37.726641516575917],
              [126.827384735619844, 37.726607182846543],
              [126.827555991018983, 37.726589138481472],
              [126.827576190513781, 37.726590573230155],
              [126.827823204086741, 37.726617966909345],
              [126.827999199298588, 37.726616251934523],
              [126.828064614711053, 37.726611285420574],
              [126.828079498376368, 37.726611027755787],
              [126.828098459281392, 37.726613028592368],
              [126.828314374504842, 37.726672747255051],
              [126.828367511897511, 37.726690005572934],
              [126.828472345343755, 37.726729577521027],
              [126.828539851555064, 37.726813290540406],
              [126.828562843197929, 37.726836410469019],
              [126.828644403740512, 37.726898755756054],
              [126.82866666775989, 37.726929199221317],
              [126.82869689663903, 37.726958525341473],
              [126.828703266939101, 37.726965851463298],
              [126.828724660979844, 37.726990096118257],
              [126.828781290145628, 37.727100546795128],
              [126.828790305957298, 37.727110979490007],
              [126.828747404817221, 37.727267737279597],
              [126.828849034850791, 37.727466098954253],
              [126.828861200417563, 37.72749229917423],
              [126.829007007395319, 37.727674398295989],
              [126.829051007734051, 37.727727952574128],
              [126.828942738146395, 37.727795086421168],
              [126.82893263342639, 37.727816153044408],
              [126.828916019660042, 37.727850790682517],
              [126.829003597108681, 37.727921310655653],
              [126.829139721142766, 37.727959356626485],
              [126.829178773979436, 37.727970273882853],
              [126.829301573644415, 37.728060269610033],
              [126.829597415943994, 37.728278621620284],
              [126.829737103197175, 37.728346113333473],
              [126.829754798357939, 37.728356276236823],
              [126.829889017667668, 37.728413906928019],
              [126.830012354230121, 37.728503055926453],
              [126.83036304409606, 37.728608871158706],
              [126.830481032370557, 37.728631565898553],
              [126.830558099538777, 37.72864913038886],
              [126.830630368571477, 37.728666974031164],
              [126.830748910408261, 37.72868403842125],
              [126.830803807160734, 37.728702985684215],
              [126.830990995250843, 37.728773927204244],
              [126.831090561055916, 37.728792648333062],
              [126.831183928688475, 37.728807703710999],
              [126.831315862219839, 37.728784812458755],
              [126.831389250689412, 37.728856147884315],
              [126.831478103984708, 37.728910613607539],
              [126.831601454397756, 37.728990192653576],
              [126.8316447658203, 37.728964631743501],
              [126.83164777049825, 37.72896491556866],
              [126.831700300877046, 37.728935709707642],
              [126.831704609501926, 37.728932830093839],
              [126.831749482411993, 37.728902841719446],
              [126.831883142934572, 37.728813784995765],
              [126.831967676525494, 37.728743802787392],
              [126.832063219439789, 37.728660592090435],
              [126.832127005699419, 37.728590299323962],
              [126.832383908031986, 37.728244077244256],
              [126.832511292778605, 37.728109963503407],
              [126.83264802028539, 37.728002601301107],
              [126.832763138611966, 37.727885645595833],
              [126.832874889129286, 37.727768117307164],
              [126.832898526032096, 37.727738590554381],
              [126.83297161947695, 37.727630579421486],
              [126.833141333416677, 37.727369344878554],
              [126.833170993974036, 37.727323691540931],
              [126.833228122438499, 37.727228098397532],
              [126.833262047093456, 37.727215891768552],
              [126.833263705918867, 37.727215156663334],
              [126.833265187341325, 37.727214210548034],
              [126.833266450260396, 37.727213081130955],
              [126.833267456923338, 37.727211802448629],
              [126.833268180940706, 37.727210406799195],
              [126.833268601523983, 37.727208934624336],
              [126.8332687069543, 37.727207427318305],
              [126.833268494582455, 37.727205927227992],
              [126.833267969718491, 37.727204474942681],
              [126.83326714671783, 37.727203114708047],
              [126.833254071328028, 37.727185449762736],
              [126.833256797919205, 37.72718105053648],
              [126.833261045327092, 37.727187006122698],
              [126.833262145990716, 37.727188279598813],
              [126.833263502342405, 37.727189385974867],
              [126.83326507837009, 37.727190291698498],
              [126.833266824380473, 37.72719097035047],
              [126.833268690631797, 37.727191400919153],
              [126.833270622730197, 37.727191574985042],
              [126.833272564028633, 37.727191482313287],
              [126.833274456599867, 37.727191128885451],
              [126.833276245888456, 37.727190524307524],
              [126.833406152630857, 37.727135473783001],
              [126.833407810282324, 37.727134616090069],
              [126.833409254251904, 37.727133543576848],
              [126.833410442272282, 37.727132287550347],
              [126.833411337677077, 37.727130887461264],
              [126.83341191286145, 37.727129384614003],
              [126.833412152653978, 37.727127825791221],
              [126.833412047564977, 37.727126256906423],
              [126.8334116005543, 37.727124727548727],
              [126.833410825977424, 37.727123281963863],
              [126.833362239271054, 37.727050889282097],
              [126.833361981703632, 37.727050506568709],
              [126.833360926638989, 37.727049209917141],
              [126.833359606841682, 37.727048076709423],
              [126.83335806287063, 37.727047139622222],
              [126.833356339888311, 37.727046424147872],
              [126.833354487604126, 37.727045954902941],
              [126.833352562656884, 37.727045743023595],
              [126.833351053257545, 37.727045759724611],
              [126.833349560583656, 37.727045936053187],
              [126.833348111892661, 37.727046269459557],
              [126.83334669920113, 37.727046764405102],
              [126.833333993647827, 37.727052023838986],
              [126.833335216294742, 37.727049690034619],
              [126.833354952067396, 37.72702072517621],
              [126.833365994558704, 37.726997937447983],
              [126.833401956343877, 37.726926190517993],
              [126.833437637290672, 37.726823478162288],
              [126.833443372471578, 37.726774601554453],
              [126.833444507766984, 37.726764920624383],
              [126.833465536837892, 37.726712303500804],
              [126.833504199342499, 37.726542021859501],
              [126.83352241648825, 37.726481792529711],
              [126.833547836090602, 37.726291498873188],
              [126.833559802025334, 37.726096119630022],
              [126.833568175942517, 37.725838231791876],
              [126.833605225918063, 37.725364437007563],
              [126.833825724391289, 37.72527831141683],
              [126.833938546672059, 37.725231171926865],
              [126.834021218982642, 37.72567276141821],
              [126.834053115396856, 37.725752767619916],
              [126.834142233125561, 37.726002351677465],
              [126.834213069458883, 37.726184608615135],
              [126.834305688147836, 37.726456723590047],
              [126.834351523255464, 37.726644018213634],
              [126.834400737556507, 37.726747419327005],
              [126.83455478907446, 37.727050296759508],
              [126.834584079816608, 37.727110037316201],
              [126.834591485351439, 37.727126652525065],
              [126.834636101703083, 37.727225540827177],
              [126.834725799577072, 37.727380237076922],
              [126.834753643009364, 37.727447289913343],
              [126.834789110291723, 37.727516461495625],
              [126.834887410625782, 37.727708191678794],
              [126.83496391681787, 37.727816415344456],
              [126.835005187092207, 37.72779061183666],
              [126.835199793203259, 37.727668936661999],
              [126.835335915885508, 37.72759114009714],
              [126.835342287298744, 37.7275928344433],
              [126.835349028280888, 37.727592284792259],
              [126.835368585102572, 37.727566130695216],
              [126.835572737554998, 37.727655100405123],
              [126.835671914550133, 37.727682377079539],
              [126.8357024027828, 37.727690762587997],
              [126.835881198551874, 37.727629352039095],
              [126.83591039379715, 37.727607164765935],
              [126.836050569130848, 37.72750063550243],
              [126.836213373763613, 37.727495060164642],
              [126.836239881278075, 37.727494152177918],
              [126.836307758654485, 37.727495374379025],
              [126.836322961237272, 37.727493603257734],
              [126.836453488943363, 37.727478397365566],
              [126.836595489670586, 37.727463396500475],
              [126.836687296835706, 37.727463523752824],
              [126.836737986055482, 37.727465847740604],
              [126.836750233378666, 37.727456854812296],
              [126.836759616897879, 37.727456725540655],
              [126.836762416335063, 37.727449480285038],
              [126.836808626024961, 37.727349429139736],
              [126.836869262661594, 37.727280230078016],
              [126.836921807335372, 37.72710893119536],
              [126.836922593137075, 37.727097670960745],
              [126.836941057653604, 37.726918781455311],
              [126.836883368426683, 37.726800641356895],
              [126.836921826038591, 37.726654250417894],
              [126.837076605044771, 37.726264827362066],
              [126.837212259131334, 37.726043776084367],
              [126.837221077781209, 37.725993812499823],
              [126.837273182959578, 37.725698576280919],
              [126.837260718699468, 37.725431172032316],
              [126.837239131025697, 37.725297743199732],
              [126.837366314697633, 37.725228583574562],
              [126.83757644418958, 37.72513157235359],
              [126.837804775624861, 37.724998230868763],
              [126.837874400150582, 37.724957560650338],
              [126.838087698088586, 37.724878211386482],
              [126.838183087221609, 37.724791950117478],
              [126.838218052317501, 37.724747913374621],
              [126.838308470567981, 37.724636444002307],
              [126.838334478046662, 37.724519176310523],
              [126.838370208583925, 37.724468410977551],
              [126.838574170885437, 37.724200656588003],
              [126.83866938830937, 37.724021496092618],
              [126.838681577444135, 37.72399853762137],
              [126.838926762931152, 37.723916799639738],
              [126.839041972587637, 37.723878396977028],
              [126.839042886837262, 37.723876775225449],
              [126.839047365538477, 37.723868610066937],
              [126.8391023326284, 37.723768383514773],
              [126.839172049711109, 37.723682347034767],
              [126.839179561285619, 37.723670374582973],
              [126.839300106575834, 37.723476562207082],
              [126.839455144742743, 37.723224954022335],
              [126.839943516722272, 37.722408994029259],
              [126.840021398649228, 37.722303317058802],
              [126.840170761100339, 37.722069822315312],
              [126.840259994634081, 37.721928492204171],
              [126.840465608513753, 37.721625959069073],
              [126.840664729019295, 37.721294894982094],
              [126.840765674074618, 37.72112705551239],
              [126.84079537126479, 37.721075686031888],
              [126.841185070282705, 37.720401557089779],
              [126.841056889918363, 37.720375524490528],
              [126.840872024311537, 37.720358640478295],
              [126.840688385520835, 37.720341867590264],
              [126.840662432056192, 37.720023250810279],
              [126.840649285332333, 37.71993318250054],
              [126.840591515700467, 37.719641252417738],
              [126.840577883202442, 37.719615216408236],
              [126.840552924278654, 37.719564889616649],
              [126.840627672820887, 37.719506330875873],
              [126.840888311834632, 37.718839540395457],
              [126.84091911023674, 37.718765575566159],
              [126.840931230578633, 37.718309380800115],
              [126.840941320058434, 37.718059863119414],
              [126.840912832751243, 37.717747496267904],
              [126.840906446161924, 37.717695086766447],
              [126.840870102294943, 37.717310054950765],
              [126.840863902522059, 37.717244763044803],
              [126.840831276170647, 37.717110455458943],
              [126.840755406120707, 37.716798809630887],
              [126.840718029710928, 37.71664437784743],
              [126.840664427501821, 37.716649576127367],
              [126.84058358343178, 37.716351103060418],
              [126.840849189805979, 37.71628469384973],
              [126.841575672706568, 37.716188053563229],
              [126.841961973807159, 37.716109972277138],
              [126.842003587310671, 37.716101562695492],
              [126.842161068184808, 37.715994462663026],
              [126.842381185714544, 37.715812512109295],
              [126.842808639531569, 37.715396608120379],
              [126.842948162652121, 37.715152054059153],
              [126.842949939735746, 37.715148935485949],
              [126.843110406067737, 37.71485803225206],
              [126.843185519033852, 37.714623888845161],
              [126.843190215014346, 37.714421342711667],
              [126.843258693419756, 37.714076652476066],
              [126.843338739627555, 37.713679855566589],
              [126.843372206549617, 37.713496213463273],
              [126.843396721352107, 37.713371481267885],
              [126.843401779508113, 37.713345738024877],
              [126.843439642583931, 37.713153090960212],
              [126.843478881036347, 37.712875007392789],
              [126.843515823731124, 37.712648003188029],
              [126.84353934825468, 37.712454002662042],
              [126.843537572224491, 37.712419075671122],
              [126.843521853151557, 37.712110430556315],
              [126.843547384569646, 37.711928508046256],
              [126.843732408355081, 37.711299072496203],
              [126.84377565751754, 37.711151947520143],
              [126.843866454264017, 37.710744831178424],
              [126.843860351401062, 37.710734823214949],
              [126.843837791232801, 37.710571178496117],
              [126.843814778750001, 37.710405187820982],
              [126.843888587221201, 37.710253746085314],
              [126.844032981357074, 37.710080322680042],
              [126.844154833675049, 37.709932329714228],
              [126.844724078886529, 37.709249148084687],
              [126.845027579924093, 37.708884894184315],
              [126.845301502978785, 37.708591813789802],
              [126.84551561218008, 37.708343142240217],
              [126.845633267982279, 37.7081213351332],
              [126.845784789941973, 37.707803737865092],
              [126.845836564562745, 37.707703938065222],
              [126.845943265001637, 37.707498266553799],
              [126.845994140208958, 37.707400199433586],
              [126.846163661320873, 37.707060472210543],
              [126.84655890977011, 37.706141636789788],
              [126.846804618998078, 37.705708395167179],
              [126.84699792773857, 37.705291438975735],
              [126.847039789009628, 37.70520114489571],
              [126.847272259303622, 37.704860107879085],
              [126.847399434214864, 37.704593318861683],
              [126.847672999920263, 37.704213081991313],
              [126.847946784655917, 37.703803895244668],
              [126.847966031014963, 37.703771157555366],
              [126.848106224965889, 37.703532695206079],
              [126.848087989711843, 37.703476815255286],
              [126.847912497173994, 37.702939003807273],
              [126.847806969193869, 37.702676541402447],
              [126.847790916209064, 37.702608576843829],
              [126.847754673593613, 37.702455122086654],
              [126.847885659542797, 37.702152233964661],
              [126.847978246885617, 37.701892417481375],
              [126.847999175816412, 37.701818664555972],
              [126.84803307634688, 37.701699203458105],
              [126.848144458037822, 37.701459484741882],
              [126.848346718855225, 37.701238939063963],
              [126.848662069734431, 37.700922650338484],
              [126.848995929745996, 37.700575099663034],
              [126.849018999605704, 37.700551085144774],
              [126.849242105537527, 37.700147774886794],
              [126.849314476940393, 37.699930392536643],
              [126.849896722215419, 37.699172051601181],
              [126.850362968596869, 37.698665206105922],
              [126.850572391720135, 37.698399583868301],
              [126.850572943404245, 37.698377172842498],
              [126.850644143358267, 37.698292011520444],
              [126.850664486561072, 37.698282776844174],
              [126.850809656121342, 37.698098646621027],
              [126.851135988510194, 37.697672753081363],
              [126.85129375845473, 37.697492480727647],
              [126.851696867201113, 37.697067647865595],
              [126.851907608669649, 37.696881973139497],
              [126.85211175166107, 37.696688768181907],
              [126.852490782509932, 37.696330580962943],
              [126.852660895627892, 37.696223868288818],
              [126.852890051610657, 37.696102472387473],
              [126.853128598719877, 37.695979917979685],
              [126.853243671711311, 37.695913281726916],
              [126.853311706504158, 37.695889969926533],
              [126.853385488826348, 37.695864690471851],
              [126.853576542794116, 37.695800493085407],
              [126.85373154292212, 37.695749215634621],
              [126.854277785420621, 37.695543261859122],
              [126.854707461998345, 37.695339232859986],
              [126.854935037972609, 37.695195136213783],
              [126.855263498935926, 37.69498920484812],
              [126.855414734989196, 37.694887870383042],
              [126.855678346808375, 37.69465908092041],
              [126.855889758617508, 37.694278853965244],
              [126.855972218435511, 37.694088680072497],
              [126.85598458483102, 37.693861745945206],
              [126.855992329714539, 37.693719578482572],
              [126.856008060871886, 37.693444304957751],
              [126.856023202621813, 37.693267330945723],
              [126.856070451141662, 37.69273091624644],
              [126.855923302365568, 37.692553361441497],
              [126.855771827841693, 37.692367989981769],
              [126.855745269933024, 37.692337258501254],
              [126.855598135523636, 37.692167003175726],
              [126.855222436506224, 37.691541012432317],
              [126.855083702478268, 37.691304645289087],
              [126.855074084900309, 37.691290944859851],
              [126.854975747929601, 37.691587304537819],
              [126.854890530826651, 37.69167918700397],
              [126.854805321357787, 37.691653134070236],
              [126.854805191913485, 37.691603667867447],
              [126.85467191325462, 37.691517461628571],
              [126.854351598340145, 37.691080075526394],
              [126.854251656372199, 37.690943604291554],
              [126.853952411000051, 37.6905349864128],
              [126.853733909403005, 37.690236619368797],
              [126.853431771028184, 37.689824036492034],
              [126.853431481773526, 37.689823648238495],
              [126.853876973031049, 37.689702147273749],
              [126.853715609292806, 37.689426678570385],
              [126.853703346250384, 37.689391137089345],
              [126.853659962466793, 37.689257648611452],
              [126.853655541476272, 37.689141329367182],
              [126.853659672981792, 37.689117457936838],
              [126.853736851941989, 37.688675539477693],
              [126.853747743956063, 37.688614196528029],
              [126.853772466291915, 37.688438088174578],
              [126.853839053960314, 37.687963361070217],
              [126.853404426116839, 37.688225809167363],
              [126.853309702766964, 37.688253078447651],
              [126.852924462025655, 37.688363954167755],
              [126.852837990458255, 37.688404298774813],
              [126.852767627204386, 37.688434776615303],
              [126.852760057508362, 37.688438054641907],
              [126.852683818003442, 37.688511588833713],
              [126.852608237314953, 37.688604562723391],
              [126.85246779853324, 37.688692619745382],
              [126.852411056363081, 37.688728198842441],
              [126.852357492248785, 37.688736638501105],
              [126.852213463550981, 37.68875932957733],
              [126.852199860910218, 37.688753494498386],
              [126.852113623514086, 37.68871650182826],
              [126.852073189510705, 37.688705823425025],
              [126.852008397989835, 37.68873812054828],
              [126.85200799684624, 37.688742380700816],
              [126.852002033035589, 37.688804322903863],
              [126.851997152595388, 37.688844601450256],
              [126.851989037839928, 37.688911423312071],
              [126.851984639001941, 37.688947708938578],
              [126.851981881069392, 37.68897033952458],
              [126.851959139537655, 37.689122160489937],
              [126.851928149142026, 37.689226119126559],
              [126.851918352125367, 37.689327493864781],
              [126.851862881817212, 37.689444126642435],
              [126.851832373662603, 37.68947753143604],
              [126.851780906940547, 37.689519099666789],
              [126.851641504049212, 37.689496201311414],
              [126.851569018234727, 37.689513271293826],
              [126.85154990145746, 37.68954802483875],
              [126.851510317626008, 37.68962957427906],
              [126.851457155278126, 37.689653266117674],
              [126.851284675369271, 37.689635972817058],
              [126.851123614913746, 37.689653021212997],
              [126.851114432873601, 37.689654397482506],
              [126.851042072409925, 37.689665251405266],
              [126.850843583433644, 37.689727795913072],
              [126.850814976363296, 37.689741080760044],
              [126.850677896785939, 37.689804749892701],
              [126.850577807043962, 37.689805064897342],
              [126.850505841863452, 37.689780844786952],
              [126.850342131154875, 37.68969209285909],
              [126.85032131938911, 37.689680808108172],
              [126.850305716354129, 37.689688322099371],
              [126.85023689730869, 37.689721468528759],
              [126.850135453172726, 37.689813833204347],
              [126.850110015978757, 37.689813480307571],
              [126.849959000979567, 37.689811379788409],
              [126.849834380665484, 37.689859233505743],
              [126.84961663097188, 37.689881296793864],
              [126.849448907536981, 37.69000529766938],
              [126.849284344479202, 37.690127364377318],
              [126.849283834242229, 37.6901400666071],
              [126.849277209814332, 37.690305241606495],
              [126.849125665889446, 37.690430505711973],
              [126.84912636046873, 37.690400349485863],
              [126.849085553624164, 37.69022595354825],
              [126.849060011624971, 37.6902056553769],
              [126.849049441586772, 37.690197256188938],
              [126.848985796600402, 37.689895969404738],
              [126.848961397597591, 37.689787109940866],
              [126.848928478146405, 37.689640930125229],
              [126.848905112722463, 37.68953503629632],
              [126.848884682918893, 37.689444940832857],
              [126.848862110171154, 37.689340372708443],
              [126.84884265567527, 37.689250430437426],
              [126.848797290609951, 37.689037383009492],
              [126.848758319640623, 37.68886721976034],
              [126.848702188548629, 37.688617686762527],
              [126.848652024827032, 37.688385135166577],
              [126.848643399072998, 37.688339130866702],
              [126.848635101654338, 37.688299936973323],
              [126.848431060151412, 37.687367789924075],
              [126.848235794445046, 37.686485101438329],
              [126.848226208742403, 37.686486764578682],
              [126.848209514020468, 37.686433881804533],
              [126.848223459061231, 37.686357479797984],
              [126.848199466790803, 37.686187751500576],
              [126.848143527806954, 37.686156170893362],
              [126.8481483062546, 37.686135018011989],
              [126.848148499959208, 37.686134267379096],
              [126.848158025988653, 37.68609198848462],
              [126.848119314564428, 37.686066917807452],
              [126.848076613559854, 37.686036626229765],
              [126.847889273066286, 37.686001837675377],
              [126.847813685802222, 37.685987676293998],
              [126.847663548203457, 37.68594249356061],
              [126.847434211164199, 37.685844736258872],
              [126.847075183358498, 37.685613158511785],
              [126.847030151948445, 37.685591324289369],
              [126.846432436630906, 37.685627173903697],
              [126.846283454369313, 37.685641392122378],
              [126.845973075144627, 37.685671012883724],
              [126.845518044804564, 37.686005076749275],
              [126.845249213393558, 37.686127063934968],
              [126.84511665239161, 37.686191461077144],
              [126.84494469132504, 37.686277707213392],
              [126.84449017614881, 37.686488119200298],
              [126.844268992559236, 37.686644161378069],
              [126.844248698910533, 37.686658476011402],
              [126.844031237299518, 37.686552265149928],
              [126.844007034156888, 37.686540443962777],
              [126.843915323508455, 37.686484100410205],
              [126.843895218058094, 37.686411904339906],
              [126.84377969477103, 37.686282723870342],
              [126.84352069461184, 37.685894176699684],
              [126.843423075925969, 37.685756042655449],
              [126.843233301593742, 37.685487498438519],
              [126.843212557704206, 37.685475973960791],
              [126.842989150293121, 37.685380550247771],
              [126.842536389625323, 37.685156571818034],
              [126.842343096560782, 37.685109045240253],
              [126.842038751423559, 37.68536092584494],
              [126.841857443495002, 37.685464748820046],
              [126.84170547641304, 37.685551766993875],
              [126.841614426970779, 37.685420830042673],
              [126.841584634169777, 37.685377987861102],
              [126.841307130564317, 37.684975832497237],
              [126.841105786664897, 37.684745459772408],
              [126.840433967608377, 37.684030452089516],
              [126.840081015494221, 37.683557673130188],
              [126.840019894992182, 37.68345973580427],
              [126.840282857852415, 37.682978896394026],
              [126.840306942195923, 37.682809548068654],
              [126.839698469387969, 37.682311325203976],
              [126.839360078608138, 37.682591007105309],
              [126.839292487892251, 37.682628177581662],
              [126.839108772979401, 37.682674089099322],
              [126.838676209158947, 37.682792194563028],
              [126.838380245517556, 37.682873001987751],
              [126.838163525129744, 37.682928706304629],
              [126.837866896980145, 37.682921988410726],
              [126.837062946736154, 37.682900734237911],
              [126.836784531196244, 37.682901930473875],
              [126.836665900779366, 37.682902440391238],
              [126.836010952041633, 37.682932282048291],
              [126.835864965233057, 37.682960342003447],
              [126.835498583512404, 37.683030109979001],
              [126.835443791595623, 37.683039641877237],
              [126.834748545050303, 37.683160565174148],
              [126.834678528615797, 37.683344310497191],
              [126.834364018908758, 37.683661456264844],
              [126.834224935986413, 37.683805324481256],
              [126.834021216127667, 37.684005852425258],
              [126.833836880108237, 37.684187298536472],
              [126.833639794521247, 37.684381292632594],
              [126.833027714355396, 37.684736212850815],
              [126.833234815025392, 37.685148251965138],
              [126.832348369414404, 37.685204014666922],
              [126.831604067066777, 37.685516481754902],
              [126.831232889429359, 37.685965440668276],
              [126.830975716534454, 37.686283652204686],
              [126.831015501517669, 37.686484535720865],
              [126.831145186681667, 37.687208786261237],
              [126.831016740374835, 37.68755344617886],
              [126.830957137756528, 37.687713236956853],
              [126.830875655472724, 37.687946875068121],
              [126.831158922140659, 37.688217755639869],
              [126.831472951855247, 37.688547558108205],
              [126.83153959126237, 37.688632570025618],
              [126.831817229172941, 37.689087148002677],
              [126.831974095765176, 37.689181660669419],
              [126.831942988161458, 37.689574484293225],
              [126.831928831721811, 37.689815381544548],
              [126.831945127956999, 37.689873291869091],
              [126.831971793763884, 37.689968052897186],
              [126.831972266378443, 37.689969735638677],
              [126.832003200332053, 37.690067399659497],
              [126.8320184543023, 37.690123164329485],
              [126.832051889992698, 37.690245410739863],
              [126.832057820522365, 37.690280467899228],
              [126.832065636953601, 37.690331112385543],
              [126.832094732652223, 37.690780196771833],
              [126.832100029811045, 37.69086210285063],
              [126.831785173959403, 37.691064367555988],
              [126.831601794180756, 37.691209484810948],
              [126.830817519875453, 37.691378752154549],
              [126.830798328581807, 37.691381037156376],
              [126.830549579619031, 37.691410663163069],
              [126.83031478735009, 37.691438630157904],
              [126.830279674768875, 37.691442812971474],
              [126.830111781838824, 37.691253383950396],
              [126.830109352028884, 37.691250642747733],
              [126.830005770956276, 37.691133778371984],
              [126.82979704456146, 37.690976418456344],
              [126.829744266567559, 37.690936629776786],
              [126.829666295068321, 37.69080564093413],
              [126.829623561838019, 37.690733860101368],
              [126.829615617751884, 37.690720506022906],
              [126.829608945019714, 37.690740298969295],
              [126.829600580267538, 37.69075376583325],
              [126.829589701398078, 37.690766048487717],
              [126.829464840784127, 37.690884316636669],
              [126.829409490420062, 37.690883594489719],
              [126.829104248125873, 37.69087960135483],
              [126.829056519986366, 37.690878978289177],
              [126.828756480281683, 37.690874496496654],
              [126.828732106872252, 37.690874130621118],
              [126.828603437600933, 37.690872208359885],
              [126.828583644104967, 37.690866819325628],
              [126.828214503803252, 37.690766334097262],
              [126.828198027743881, 37.690761848995166],
              [126.828034061571941, 37.690717209678887],
              [126.828023548235691, 37.690714346659],
              [126.827606123317665, 37.691084678145373],
              [126.82703707988982, 37.691301826817586],
              [126.82687639185437, 37.691460881632032],
              [126.826868025755559, 37.69146916388928],
              [126.825842277065519, 37.691743508741375],
              [126.825454438172443, 37.691658192590722],
              [126.824902304660696, 37.691697191328167],
              [126.824873191216582, 37.691724203094715],
              [126.824503010419292, 37.692066845754219],
              [126.824168883097798, 37.692319844746955],
              [126.824154078148425, 37.692331054351648],
              [126.824138859739904, 37.692342578849775],
              [126.823649011841482, 37.692504106358982],
              [126.823607902035249, 37.692665199839453],
              [126.82349805023469, 37.693095633714528],
              [126.823470498358859, 37.693095222375561],
              [126.822771641943319, 37.693084805128557],
              [126.822542594392871, 37.693167887354186],
              [126.822537646320754, 37.693169681398103],
              [126.822535699963467, 37.693176410294726],
              [126.822464932357065, 37.693421121558245],
              [126.822174590160955, 37.693470314507884],
              [126.82188520183233, 37.693519346382928],
              [126.821502828435655, 37.693680413930856],
              [126.821405311077697, 37.693721493808177],
              [126.821061093726769, 37.693866490812333],
              [126.820656280395156, 37.693864260277259],
              [126.820585462545978, 37.693863872094965],
              [126.820389134233281, 37.693922801157242],
              [126.820265773463504, 37.693959828419118],
              [126.820257254556353, 37.693942742546177],
              [126.820236831616015, 37.693948629415452],
              [126.820200564312657, 37.693959086570842],
              [126.820180350601092, 37.693964915149294],
              [126.82015610645179, 37.693971903020305],
              [126.820134148198221, 37.693978231680447],
              [126.820101769296201, 37.693987553928103],
              [126.820039521401995, 37.694005475589286],
              [126.819833773675342, 37.694127689723111],
              [126.819632895141353, 37.694159365076381],
              [126.819171352902373, 37.694250189750463],
              [126.819081824534379, 37.694267821797318],
              [126.819075123647224, 37.694269142294523],
              [126.819033277813247, 37.694264577010777],
              [126.818926926706254, 37.694252974794615],
              [126.818918753366717, 37.694252081246589],
              [126.81888163050148, 37.694248029901466],
              [126.818840415789396, 37.694243540300512],
              [126.818692519009929, 37.694227430122069],
              [126.818650051069923, 37.69422280342355],
              [126.818152153571532, 37.69400597895325],
              [126.818123712876627, 37.693994338880181],
              [126.817565877732648, 37.693766670349206],
              [126.816922028663029, 37.6935181680619],
              [126.81630624876415, 37.693337369086535],
              [126.816218287765963, 37.693305539063701],
              [126.81597241407249, 37.693216565969031],
              [126.815783086958973, 37.693117972605911],
              [126.815539277530974, 37.692906850983157],
              [126.815344664557529, 37.692738365764335],
              [126.815310742632647, 37.692708998460006],
              [126.815289841649872, 37.692692981405813],
              [126.815262082532158, 37.692671712186261],
              [126.81503360348789, 37.692501977051343],
              [126.814898491253842, 37.692401593492477],
              [126.814704496205849, 37.692257466294514],
              [126.814694179508692, 37.69225717920559],
              [126.814661074905757, 37.692256135062038],
              [126.814589537617707, 37.692253859849245],
              [126.813938336021224, 37.692233184901966],
              [126.813873202381444, 37.692232053332923],
              [126.813833521552951, 37.692231369076531],
              [126.813279741452448, 37.692221773949143],
              [126.812783613238665, 37.692213232374456],
              [126.812700396267942, 37.69221174870966],
              [126.812886041371414, 37.692015903734195],
              [126.812951093349142, 37.691937354110337],
              [126.812972364319933, 37.69188098415637],
              [126.812973131669011, 37.691878951642131],
              [126.813001887864218, 37.6918027412543],
              [126.81296428758516, 37.69160744121902],
              [126.8128952324371, 37.691475879562347],
              [126.81269189754903, 37.69109201178486],
              [126.812566931645648, 37.690806276671751],
              [126.812202823016676, 37.69055945750322],
              [126.812158637384641, 37.690279609114171],
              [126.812080658343476, 37.690297008503613],
              [126.812069291234394, 37.690219416870086],
              [126.812034173683884, 37.690142450094534],
              [126.811990141515906, 37.690045942933843],
              [126.81199700318021, 37.690020563011103],
              [126.8121469418777, 37.689466060801614],
              [126.812151521020482, 37.689448951595161],
              [126.812293595755918, 37.6892369278426],
              [126.812454930523927, 37.688996159504846],
              [126.812479493397873, 37.688921869807523],
              [126.812481534554038, 37.688915646367853],
              [126.812491490403488, 37.688885310058389],
              [126.812506454557919, 37.688841365956414],
              [126.812559675503167, 37.688732975079297],
              [126.812499216574793, 37.688664405218518],
              [126.81249586424201, 37.68866019272788],
              [126.812467409504791, 37.688627539608994],
              [126.812031269982057, 37.688127019589736],
              [126.811735922716821, 37.687788068608526],
              [126.8114495153077, 37.687532543106748],
              [126.811320654668791, 37.687426651499017],
              [126.811156829344299, 37.687160962141725],
              [126.811115467317251, 37.687109359626277],
              [126.811094320157508, 37.687079515016009],
              [126.810959068933059, 37.686888640639509],
              [126.810831470935213, 37.686812447201376],
              [126.810492147504704, 37.68661459391511],
              [126.810196422483855, 37.68646746328124],
              [126.810060533514644, 37.686406157210897],
              [126.810005730261153, 37.686379941601153],
              [126.80982645028196, 37.686315922565399],
              [126.809757338107886, 37.686291241868894],
              [126.809336961883844, 37.685942146775375],
              [126.80906488043567, 37.685716197815928],
              [126.80882495847716, 37.685640032657801],
              [126.808722161800674, 37.685602802214866],
              [126.808541572963961, 37.685537399942696],
              [126.808332208975841, 37.685482999604851],
              [126.808079382692952, 37.685456084769299],
              [126.808046824290273, 37.685452618331105],
              [126.808045629827291, 37.685518928755933],
              [126.807833554053317, 37.685591378029379],
              [126.807739257967484, 37.685572367265799],
              [126.807676513142468, 37.685574281068256],
              [126.807390639035987, 37.685627500108751],
              [126.807143858702332, 37.685687270016224],
              [126.806979260656419, 37.685719936550186],
              [126.806731239312825, 37.685881161848656],
              [126.806091699188698, 37.686216805549023],
              [126.806036667361283, 37.68623926621661],
              [126.805763889386867, 37.686277112415794],
              [126.805422586034311, 37.686125815016879],
              [126.805366315502852, 37.686107547281658],
              [126.805305667958223, 37.686087859391158],
              [126.805229160929571, 37.686063021621329],
              [126.805194115295492, 37.686051644167215],
              [126.804908087000754, 37.686049545781103],
              [126.804876343811188, 37.686049131540962],
              [126.80469783625955, 37.686197296523304],
              [126.804669215365521, 37.686221052440004],
              [126.804641546299081, 37.686244169517423],
              [126.804615111800572, 37.686266247434226],
              [126.80457502448526, 37.68629973127112],
              [126.804412080270524, 37.686353967169218],
              [126.80427865701779, 37.686399304308026],
              [126.804215770512499, 37.686420671348529],
              [126.804033991508987, 37.686481272484919],
              [126.803843354504167, 37.686546722432261],
              [126.803650210120907, 37.686673611139547],
              [126.803587140096752, 37.686715045574097],
              [126.803388230843751, 37.686663708622106],
              [126.803196554675111, 37.686595732423534],
              [126.803096890381141, 37.686560985216779],
              [126.803046724783385, 37.68654349514339],
              [126.803030620157784, 37.686537880272212],
              [126.802781485432675, 37.686367970484042],
              [126.802689598256279, 37.686241066467602],
              [126.80265754624169, 37.686186414607405],
              [126.802120777967119, 37.686001260742913],
              [126.801917569690701, 37.685935054720545],
              [126.801635854477368, 37.685894805684825],
              [126.800972334922619, 37.685800006858948],
              [126.800572270694843, 37.685742847943203],
              [126.800426981483, 37.685555558684968],
              [126.80033627919255, 37.685306917518346],
              [126.800224751969793, 37.685211764869763],
              [126.800153506418624, 37.684727552521721],
              [126.800093717591594, 37.684449591644857],
              [126.7996416300074, 37.684148072762227],
              [126.79917120845208, 37.684253853031791],
              [126.799180114394943, 37.684355495678403],
              [126.799181244539994, 37.684368212826691],
              [126.79918979458067, 37.684464531955214],
              [126.799157142592946, 37.684464835439698],
              [126.799058395319051, 37.684466918261045],
              [126.799014230865566, 37.684365392308933],
              [126.798938795275561, 37.684340397301],
              [126.798844529376666, 37.684344255147877],
              [126.798824734733984, 37.684345065816032],
              [126.798778951367169, 37.68432193968885],
              [126.798721826331899, 37.684293083999698],
              [126.798729790973326, 37.684199037030673],
              [126.798745420223412, 37.684162662512442],
              [126.798903798817804, 37.684036260117381],
              [126.798934015023832, 37.683971802396961],
              [126.798970646425218, 37.683925104582606],
              [126.798926919150333, 37.683788262012321],
              [126.798936160753158, 37.68368310030629],
              [126.798941777452043, 37.683619175934311],
              [126.798914236083121, 37.683491282351397],
              [126.798961564483278, 37.683471541754827],
              [126.798958997833537, 37.683459147749566],
              [126.798900915898656, 37.683178621302559],
              [126.798786160072922, 37.683273116752638],
              [126.798409565260158, 37.683436085502649],
              [126.797960322524503, 37.683757858817295],
              [126.79778001843836, 37.684066848836054],
              [126.796997346764968, 37.684597426274671],
              [126.79694231179316, 37.684822392399269],
              [126.79671225620325, 37.685075337380525],
              [126.796657349852936, 37.68513570668042],
              [126.7961598273736, 37.685061883666208],
              [126.795985798489014, 37.685036060338184],
              [126.795647318991996, 37.684775360047404],
              [126.795471157335228, 37.684709405425757],
              [126.795351395888062, 37.684664566614266],
              [126.794689595141165, 37.684677001760477],
              [126.794649607835353, 37.684677751344502],
              [126.794464859705684, 37.684992411356539],
              [126.794415638140947, 37.685076245256326],
              [126.794411541357718, 37.685083220402447],
              [126.794409739073416, 37.685103947210074],
              [126.794398647440801, 37.685231424960904],
              [126.794397487973995, 37.685402178668149],
              [126.794395402289808, 37.685709365167561],
              [126.794050431379716, 37.685867329163578],
              [126.793668993223008, 37.685929815838797],
              [126.793208565922015, 37.68642741993699],
              [126.793100946196304, 37.686519308949507],
              [126.792934688338448, 37.686620372348216],
              [126.792908340931064, 37.686636363331324],
              [126.792877476587236, 37.686655177199178],
              [126.792810334939475, 37.686696105532903],
              [126.792766045108607, 37.686722966066661],
              [126.792739470241344, 37.686739135010107],
              [126.792634153658184, 37.68681805387822],
              [126.792624869268053, 37.686830012789194],
              [126.792547110456056, 37.686930162542382],
              [126.792513332614078, 37.687008035887771],
              [126.792505933846158, 37.687035204167898],
              [126.792494743859436, 37.687076310254909],
              [126.792486757334245, 37.68710565349879],
              [126.792451377580036, 37.687108112740646],
              [126.792421049312622, 37.687210138708295],
              [126.792351888702228, 37.687292183715229],
              [126.792317194055897, 37.687333297451318],
              [126.79223013208555, 37.687410986130018],
              [126.792193194785227, 37.687443895168137],
              [126.792026691834579, 37.687445358077959],
              [126.791937191709735, 37.687446143860463],
              [126.791862657076379, 37.687544127703923],
              [126.791735389014832, 37.687728330557775],
              [126.791307600569723, 37.687900102844139],
              [126.790820642591214, 37.688067804304033],
              [126.790694923027473, 37.688103347950481],
              [126.790474234478708, 37.688165750871917],
              [126.790336375919637, 37.688204698389072],
              [126.790036199897401, 37.688311013929479],
              [126.789835073670005, 37.688382220345147],
              [126.789778697072165, 37.688402180351851],
              [126.789802421922133, 37.688449512755675],
              [126.789813592052269, 37.688471799371854],
              [126.78988754934015, 37.688619349211436],
              [126.789924668370844, 37.688693399860895],
              [126.789997740120356, 37.688839183115924],
              [126.789675150816876, 37.689331569391321],
              [126.789575764018807, 37.689670586249036],
              [126.789471426571211, 37.690143694979106],
              [126.789539162180645, 37.690404692695118],
              [126.789866134958771, 37.690826480757494],
              [126.789688287164665, 37.691070469441115],
              [126.789549851118778, 37.691588988510716],
              [126.789636382654251, 37.692021416781422],
              [126.789652507301525, 37.692101994547222],
              [126.789844402537611, 37.692768208829357],
              [126.78947572616525, 37.693535134288723],
              [126.789293847632436, 37.69390635161686],
              [126.789233596648614, 37.694029321389309],
              [126.788843273460003, 37.694169572948937],
              [126.788840728231349, 37.694169802757578],
              [126.788793919817451, 37.694174009919216],
              [126.788231076304612, 37.69422460738604],
              [126.788173917307546, 37.694229675675302],
              [126.787871272565368, 37.694436350497021],
              [126.787641434361007, 37.694594052270126],
              [126.787166397208779, 37.69491913369086],
              [126.786575137253266, 37.695200854221653],
              [126.786194884243201, 37.695285761001841],
              [126.785810212522804, 37.695369109348427],
              [126.785507069430608, 37.695650422225732],
              [126.785465501731821, 37.695688995557056],
              [126.785483701863086, 37.69572027525107],
              [126.785571035634348, 37.695870401716007],
              [126.785630675524899, 37.695972922127631],
              [126.785562896012536, 37.696054084437037],
              [126.785472546009018, 37.696169053604045],
              [126.785338501302704, 37.696336604003044],
              [126.785497398960217, 37.696837274262144],
              [126.785533573431209, 37.696945880630494],
              [126.785498784625446, 37.697059760551831],
              [126.785450826246631, 37.697210756271851],
              [126.785441738836511, 37.697235498280605],
              [126.785273249378378, 37.697784474327193],
              [126.785208400596929, 37.698050941812518],
              [126.78519739088739, 37.698089446094187],
              [126.785157853734404, 37.698299613795825],
              [126.785171992978803, 37.698346535624538],
              [126.784636170881626, 37.698503219625806],
              [126.784571523722917, 37.698411293112009],
              [126.783485269707995, 37.698754900983367],
              [126.783395708531927, 37.698783236758338],
              [126.783330689761641, 37.69880380806071],
              [126.783086862796182, 37.698817051576242],
              [126.782684718924827, 37.698838918003027],
              [126.782529341242508, 37.698847413077111],
              [126.782487046611323, 37.698849724473895],
              [126.782332586720059, 37.698858086070324],
              [126.782257635265978, 37.698896779517696],
              [126.781986219918167, 37.699036643050292],
              [126.781939898913237, 37.699060523512685],
              [126.781929438221013, 37.699065909178636],
              [126.781926031398527, 37.699068066016224],
              [126.781830764592357, 37.699126507731705],
              [126.781825725516825, 37.699129597403548],
              [126.781794328564175, 37.699148818758914],
              [126.781784487901859, 37.699154837308491],
              [126.781424695350225, 37.699096866256298],
              [126.781382826017577, 37.699090119798079],
              [126.781305511763534, 37.699171502688046],
              [126.781218009457348, 37.699263608592922],
              [126.7811998037145, 37.699243582086382],
              [126.7811810165162, 37.699222921109637],
              [126.781123948714495, 37.699261680489201],
              [126.780974266372937, 37.699363338724517],
              [126.780844037567675, 37.699489554655599],
              [126.779812039786066, 37.699811012964261],
              [126.779740368709284, 37.699889352175937],
              [126.779333855032419, 37.700043192874801],
              [126.779049523802499, 37.700029095849786],
              [126.778232861274063, 37.699992730205494],
              [126.77711794467362, 37.700235136230873],
              [126.77683610668258, 37.700593273535326],
              [126.776151259766735, 37.700803244180015],
              [126.775492129870301, 37.701355626147368],
              [126.775465209508113, 37.701370936351196],
              [126.775155636393905, 37.701546951940216],
              [126.774741979456849, 37.701782145167783],
              [126.774393477941075, 37.70250179576913],
              [126.773686863189198, 37.702739820182863],
              [126.773601731204494, 37.703079456444357],
              [126.773599004512633, 37.703090340175414],
              [126.773436417512769, 37.703816620167302],
              [126.773429980553118, 37.703823854129915],
              [126.773418019426046, 37.703871367256639],
              [126.773805921750224, 37.70437891091818],
              [126.77380988556169, 37.704384013351138],
              [126.77381271779555, 37.704387661299577],
              [126.773905810424495, 37.704507491470487],
              [126.774147806228896, 37.704983497799809],
              [126.774047843364528, 37.705630035058263],
              [126.773779071083041, 37.706031003494395],
              [126.773490301626055, 37.706303268032187],
              [126.773540327971347, 37.706516501514443],
              [126.773642400203542, 37.706741943978685],
              [126.773685881545049, 37.706837756806237],
              [126.773566835771661, 37.707406540075937],
              [126.773499311111223, 37.707760037643112],
              [126.773564637634649, 37.708181923645455],
              [126.773597873323467, 37.70829699938357],
              [126.773652830953182, 37.708487295793084],
              [126.773761758353658, 37.708909444832869],
              [126.773834255278345, 37.709190398110152],
              [126.773813549437321, 37.70923935445834],
              [126.773892477028269, 37.709331854137218],
              [126.77399042184615, 37.709520120344934],
              [126.774101388875152, 37.709680255447793],
              [126.774133933421595, 37.709778639789683],
              [126.774220959677507, 37.710041709103173],
              [126.774369657902056, 37.710146174778977],
              [126.774505624437239, 37.710244416750896],
              [126.774778283417717, 37.710436955122042],
              [126.77473195968679, 37.710577362003313],
              [126.774483891428147, 37.711268653915575],
              [126.774421926568962, 37.711424238098914],
              [126.774425438951454, 37.711436351769215],
              [126.774443141566834, 37.711440604963713],
              [126.774454656576467, 37.711443448274714],
              [126.774492730319253, 37.711449153559748],
              [126.774562962388927, 37.711525148441517],
              [126.774593931852905, 37.711558655234754],
              [126.774627493592391, 37.711594972048047],
              [126.774870657221186, 37.711814202344875],
              [126.775125574461157, 37.712072035656256],
              [126.775204544857814, 37.712153273655112],
              [126.775383199146731, 37.712321618184106],
              [126.775560238735153, 37.712488438308711],
              [126.775857519112833, 37.712743245931385],
              [126.776065588292553, 37.712869047719664],
              [126.77655124636172, 37.713162677936644],
              [126.776798795721774, 37.713223964753148],
              [126.776996797699539, 37.713319501857015],
              [126.777165954824227, 37.713405136586147],
              [126.777357107472326, 37.713480675961186],
              [126.777875549402495, 37.714149501285291],
              [126.777997294131254, 37.714326825474473],
              [126.778211747727823, 37.714728160512784],
              [126.778254720733372, 37.714818616446877],
              [126.778569055072239, 37.715598824531504],
              [126.778362091153795, 37.715827620459628],
              [126.778198287158361, 37.715966391891257],
              [126.778303019496178, 37.716085977855109],
              [126.778480886151513, 37.716280012932756],
              [126.778643745890506, 37.716407619106171],
              [126.779050006327196, 37.716725943117908],
              [126.7793227661044, 37.717309831653964],
              [126.779639645231086, 37.717480489112901],
              [126.780103517782607, 37.71774601994494],
              [126.780255716678909, 37.717675679526948],
              [126.780710255621997, 37.717465605329174],
              [126.780782464051313, 37.717436170867458],
              [126.780896828311896, 37.717417528076943],
              [126.781337024803364, 37.717358940867378],
              [126.781823118473227, 37.717302692020866],
              [126.781824978378893, 37.717327347116779],
              [126.781845798376267, 37.717603709083882],
              [126.781837285656323, 37.71760566716091],
              [126.782181879619245, 37.718057352713856],
              [126.782153962360809, 37.718570566499992],
              [126.782368696578828, 37.718710899896983],
              [126.782530122985492, 37.718703071164228],
              [126.782586697839108, 37.718700325747221],
              [126.783177220022978, 37.718692411614121],
              [126.783467541360636, 37.718618898657631],
              [126.784054622931279, 37.718938699087666],
              [126.784374588905564, 37.718969137763473],
              [126.784377984170163, 37.719019814094729],
              [126.784420468255277, 37.719036504753056],
              [126.784439724408571, 37.719057380726198],
              [126.784477874804153, 37.719101372938816],
              [126.784482380241002, 37.719109775305185],
              [126.784490566227603, 37.719125046400315],
              [126.784496214402097, 37.719132372320715],
              [126.784513172676796, 37.719151269468192],
              [126.78451330607524, 37.719167600307735],
              [126.784519236001472, 37.719199427488299],
              [126.784539187941888, 37.719226773703269],
              [126.78454760901792, 37.719315759386973],
              [126.784556219744758, 37.71945569576669],
              [126.784561140859338, 37.719535672290746],
              [126.784562547043365, 37.719581175610614],
              [126.784563575529944, 37.71961451248815],
              [126.784519811321417, 37.719697846578647],
              [126.784514383523558, 37.719708185070935],
              [126.784291280190885, 37.719945681150641],
              [126.784278436114732, 37.719974659771175],
              [126.784252650788403, 37.720065826274279],
              [126.784289131267613, 37.720134592766414],
              [126.784364992057661, 37.720253550990705],
              [126.784370791500379, 37.720271580049662],
              [126.78446688720166, 37.720316805476998],
              [126.784568123908969, 37.720362887629747],
              [126.784741212507427, 37.720444850772125],
              [126.784887355695261, 37.720558758662854],
              [126.784968959204093, 37.720622367322797],
              [126.785012629455053, 37.720656238619128],
              [126.785023941699407, 37.720666674151836],
              [126.785040770682016, 37.720669795620232],
              [126.785166402011114, 37.720723731445837],
              [126.785250870492931, 37.720759996061396],
              [126.785289640775517, 37.72077527586498],
              [126.785298121865608, 37.720782897426751],
              [126.785383175133134, 37.720850902598571],
              [126.785453534944423, 37.720912126386303],
              [126.785462545058465, 37.720922558739062],
              [126.785468009460232, 37.720930730733947],
              [126.78550047767348, 37.720979225388106],
              [126.785701199637458, 37.721066591238902],
              [126.785773419041675, 37.721096563089631],
              [126.785838303840279, 37.721150180628356],
              [126.785926479887507, 37.721177370247993],
              [126.785935854868455, 37.721183018791784],
              [126.78605202597187, 37.721215044891807],
              [126.78616127455463, 37.721249309287778],
              [126.786269291511047, 37.721278787353882],
              [126.786312853420469, 37.721292949358428],
              [126.786317957779886, 37.721303653632098],
              [126.786339033410911, 37.721311575791148],
              [126.786339506743332, 37.721328470201442],
              [126.786484492243247, 37.721384765159826],
              [126.786678580540666, 37.721437491321041],
              [126.786695346458131, 37.721442363427315],
              [126.786921353353918, 37.721508036822449],
              [126.787225878982866, 37.721860246609815],
              [126.787214467536884, 37.722008361006544],
              [126.787193843706987, 37.722276036806562],
              [126.787189749270212, 37.722405262411051],
              [126.787371526262575, 37.722732471534634],
              [126.787446653811472, 37.722801306611395],
              [126.78745067955397, 37.722817080520151],
              [126.787513088627932, 37.722872379344395],
              [126.787916241937978, 37.72306822207392],
              [126.788102179370767, 37.723184844951454],
              [126.788349482745573, 37.723224142859223],
              [126.788710456307101, 37.723224050086358],
              [126.788755689491239, 37.723224038183368],
              [126.7888776385212, 37.723218906311367],
              [126.788944981534485, 37.723281807890821],
              [126.789232729311109, 37.72343437277155],
              [126.789495768420551, 37.723546335221172],
              [126.789772158386782, 37.72357724566389],
              [126.78977426467614, 37.723576981649892],
              [126.789925521501871, 37.723558086427111],
              [126.790111112358062, 37.723545750234209],
              [126.790378407124322, 37.723536652802899],
              [126.790401093555261, 37.723536413513727],
              [126.790418562516948, 37.723536177966757],
              [126.790418766989418, 37.723544718254807],
              [126.790418953910972, 37.723546177707398],
              [126.790419435137593, 37.723547593865739],
              [126.790420198523464, 37.723548931505405],
              [126.790421224031689, 37.723550149946654],
              [126.790422484697231, 37.723551221086346],
              [126.790423949033496, 37.723552114991612],
              [126.790425576399329, 37.723552809786241],
              [126.790427320454526, 37.723553286263694],
              [126.79042914390169, 37.72355352887714],
              [126.790430988882051, 37.723553536376961],
              [126.79058184504683, 37.723544266646506],
              [126.790583740123822, 37.723544018471955],
              [126.790584973371452, 37.723543681591067],
              [126.790820571867968, 37.723670623455753],
              [126.790875493623616, 37.72370107398087],
              [126.790900555361262, 37.723714967981742],
              [126.790968154579048, 37.723751696666959],
              [126.791065997495778, 37.723809865606682],
              [126.791193619827268, 37.723887843258048],
              [126.791517518328789, 37.724085745159513],
              [126.791665762054791, 37.724304774919119],
              [126.791797526665874, 37.724402993297289],
              [126.791813592665719, 37.724425824578347],
              [126.792024525866182, 37.724541911846174],
              [126.792303413104378, 37.724708707278431],
              [126.792336639752847, 37.724728577036018],
              [126.792356635244431, 37.724739875172865],
              [126.792429573041673, 37.724770970303098],
              [126.792662635027355, 37.725594164314018],
              [126.792686137080878, 37.725677181215531],
              [126.793175488771368, 37.725866960674331],
              [126.793495851007506, 37.725997195866441],
              [126.793834230603778, 37.7261347540462],
              [126.793834451882347, 37.726135471015574],
              [126.793877252119628, 37.726275243694808],
              [126.79399037378289, 37.726644634630873],
              [126.794168281833706, 37.726912988441988],
              [126.794208884977024, 37.726972181126101],
              [126.794240238904109, 37.727018075406988],
              [126.794291494869213, 37.727093109350641],
              [126.794309161266526, 37.727113411765757],
              [126.794301900167028, 37.727132990816926],
              [126.794294338697071, 37.72715337023935],
              [126.794346268273316, 37.727188283470198],
              [126.794347235722455, 37.727188935503932],
              [126.794446039135224, 37.727285962151171],
              [126.79450961693793, 37.727391853242352],
              [126.794607647194425, 37.727555128050575],
              [126.795024654459525, 37.728076729286911],
              [126.795076653339137, 37.72824378161274],
              [126.795090486791779, 37.728307432237408],
              [126.795110914622654, 37.728418124034874],
              [126.795131247633904, 37.72849812009769],
              [126.795167567039542, 37.728568845283412],
              [126.795221398909575, 37.728651999733046],
              [126.795255283317587, 37.728704990492254],
              [126.795297804499938, 37.728774889253799],
              [126.79529563199354, 37.728866909473375],
              [126.795312803588175, 37.728867648453303],
              [126.79531459024011, 37.728867837594038],
              [126.79531631349829, 37.728868249881707],
              [126.795317934884238, 37.728868874270574],
              [126.795319411431677, 37.728869694280114],
              [126.795320707034094, 37.728870688062941],
              [126.795321785584918, 37.728871833771699],
              [126.795322624679841, 37.728873100627737],
              [126.795323199662079, 37.728874456036401],
              [126.79532349722227, 37.728875867470784],
              [126.795323508624179, 37.728877298826106],
              [126.795320203464271, 37.728913529930551],
              [126.795326197079959, 37.728914530165824],
              [126.795324272446379, 37.728963630376711],
              [126.795323459769818, 37.728963647150202],
              [126.795293381891355, 37.728962327182579],
              [126.7952929756364, 37.728979570502034],
              [126.795304717004001, 37.729030269320084],
              [126.795318608001921, 37.729071117003222],
              [126.795315874053429, 37.729096167111877],
              [126.795338257441315, 37.729153473985548],
              [126.795356235461554, 37.729210430949344],
              [126.795370343404301, 37.729256533683113],
              [126.795418203782901, 37.729247675924114],
              [126.795473664160411, 37.72933689321254],
              [126.795566505932356, 37.729478388367866],
              [126.795623513285548, 37.729566899184697],
              [126.795631952945456, 37.729591690690086],
              [126.795638536467422, 37.72964519238333],
              [126.79567091984886, 37.729856974796533],
              [126.79569412401132, 37.730051847775741],
              [126.795699383985124, 37.730139422044914],
              [126.795706831655423, 37.730264165157621],
              [126.795664008060243, 37.730431894416874],
              [126.795636060796753, 37.730539113594887],
              [126.795545002069204, 37.730779681787752],
              [126.795492675017911, 37.730918393151448],
              [126.795419667751602, 37.731249080635855],
              [126.79539385984279, 37.731307586592187],
              [126.795374547430072, 37.731396898622627],
              [126.795368465536527, 37.731480997220515],
              [126.795337238076769, 37.731557524984908],
              [126.79532327389559, 37.731733188707658],
              [126.795286546884768, 37.731940345248198],
              [126.795270290224494, 37.731985364226119],
              [126.795260619693892, 37.732020259362066],
              [126.795191952496225, 37.732171330825821],
              [126.795161260965727, 37.7322472993174],
              [126.795136026841163, 37.732306631535657],
              [126.795226889337627, 37.73230357948335],
              [126.795227933350958, 37.732303584824038],
              [126.795228947167828, 37.732303662990589],
              [126.795230859434895, 37.732304017824269],
              [126.795232666010193, 37.73230462799912],
              [126.795234314838694, 37.732305477881603],
              [126.795235751732534, 37.732306537404369],
              [126.795236938348069, 37.732307781101426],
              [126.795237838765061, 37.732309167297544],
              [126.795238422720146, 37.73231065615402],
              [126.795238675888129, 37.732312202519537],
              [126.795244410392186, 37.732419830238086],
              [126.795244344797169, 37.732421243105932],
              [126.795244003670362, 37.73242263089228],
              [126.795243391875559, 37.732423959376327],
              [126.795242525624388, 37.732425194405032],
              [126.795241427886012, 37.732426307273684],
              [126.795240122747259, 37.73242727108687],
              [126.795238644491064, 37.732428060812737],
              [126.795237029618619, 37.732428656840646],
              [126.795235316866538, 37.732429043178563],
              [126.795233549408081, 37.732429214676671],
              [126.795081721136114, 37.732434314985326],
              [126.79508133109718, 37.732435235598196],
              [126.795026228007458, 37.732555074623264],
              [126.795154580309429, 37.732611330653491],
              [126.795156337687814, 37.732612462396013],
              [126.795575150029549, 37.732882075666659],
              [126.795624468063806, 37.732906593184083],
              [126.795653567307326, 37.732921060997903],
              [126.795740108249262, 37.732971043971673],
              [126.795659781397561, 37.733176157292043],
              [126.795634207883694, 37.73325550936238],
              [126.795599848797224, 37.733267713333206],
              [126.795548449036616, 37.733285973232185],
              [126.79535263808738, 37.733355528951734],
              [126.79535057205608, 37.733356906426941],
              [126.795172749426968, 37.73347543860077],
              [126.794951272606212, 37.733631871020386],
              [126.794648647095102, 37.73384138252765],
              [126.794303748744113, 37.734080936924975],
              [126.7942686240482, 37.734118976577832],
              [126.794251839124897, 37.734137154721424],
              [126.794021309586583, 37.734425335631258],
              [126.793933484960903, 37.73458229068445],
              [126.793959664389405, 37.734623415794182],
              [126.794008481926042, 37.73470010711619],
              [126.794050002726351, 37.734749733342611],
              [126.794332307858966, 37.735026149445169],
              [126.794579279551996, 37.735265058876493],
              [126.794683748334862, 37.735304379208081],
              [126.794794803552008, 37.735336108333321],
              [126.795063201175907, 37.735390346858566],
              [126.795236962308238, 37.735436547098715],
              [126.795546607695243, 37.735508606745661],
              [126.795712684231702, 37.735580404527191],
              [126.795998843880625, 37.735747023652955],
              [126.796008004373846, 37.735769843735476],
              [126.796060765826979, 37.73585665246803],
              [126.796165805621499, 37.735883300630832],
              [126.796192014895638, 37.735892919112679],
              [126.796211478153126, 37.735906747904117],
              [126.796235917569206, 37.735913547316635],
              [126.796326956029546, 37.735939608807719],
              [126.796417095083356, 37.735970173229305],
              [126.796536843041324, 37.7360010765262],
              [126.796543906111495, 37.736011215189706],
              [126.796578119941771, 37.736011842985889],
              [126.79665057406018, 37.736024635950578],
              [126.796664740498315, 37.736030580819644],
              [126.796689264275386, 37.736035069674401],
              [126.796689711788886, 37.73603515166014],
              [126.796729938230413, 37.736042513847806],
              [126.796809850900445, 37.736055320569832],
              [126.796932648360809, 37.73607017484624],
              [126.797033836192, 37.736081053317733],
              [126.797041700059125, 37.736081045235288],
              [126.797238040281314, 37.73608083967185],
              [126.797274897566936, 37.736085407344405],
              [126.797488764612467, 37.736115906340693],
              [126.797591078238412, 37.736166203673733],
              [126.79762205090843, 37.736248750248393],
              [126.797619728259775, 37.736255782875908],
              [126.797700568735962, 37.736318426334869],
              [126.797938950920326, 37.736394577886927],
              [126.798005160968486, 37.736491544336239],
              [126.798039423747568, 37.736537495441709],
              [126.798060568981228, 37.736583709118705],
              [126.798167739335966, 37.736739021647928],
              [126.798264383582435, 37.736855754691454],
              [126.798264520986976, 37.736856107021637],
              [126.798283039075613, 37.736903646171037],
              [126.798334625068435, 37.736969622943988],
              [126.798342583167098, 37.736976114705946],
              [126.79838114831918, 37.736999877554183],
              [126.798453009242678, 37.737044155965357],
              [126.798595973256241, 37.736837756985793],
              [126.798619937830807, 37.736803157562612],
              [126.79876352962421, 37.736605183974952],
              [126.799249435025104, 37.736136401942844]
            ]
          ]
        ]
      },
      id: '31103600'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '덕이동',
        ADM_CD: '31104513'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.753101976387157, 37.704722841970721],
              [126.753321144936095, 37.704818699748515],
              [126.753611409994136, 37.705032206206873],
              [126.753852140183554, 37.704949373924279],
              [126.7539403003817, 37.704858150805705],
              [126.754033009095778, 37.70484041814656],
              [126.754135807444541, 37.704820755530264],
              [126.754197441197874, 37.704808965923164],
              [126.754219074270395, 37.704804827817838],
              [126.754427102506781, 37.704817525275175],
              [126.754753035058428, 37.704922270060408],
              [126.754805814414539, 37.704946525475904],
              [126.754956120434557, 37.705015598609322],
              [126.75505644994098, 37.705061705735758],
              [126.755180750724264, 37.705088146975456],
              [126.755678153811715, 37.705288803326845],
              [126.755871385740463, 37.704991887540018],
              [126.755939990267564, 37.704604057011409],
              [126.755959884703827, 37.704482748249561],
              [126.755954489087543, 37.704446123474199],
              [126.755951308634891, 37.704424515082486],
              [126.755948270366574, 37.704403890022384],
              [126.75594970443845, 37.704365404765596],
              [126.755950489931166, 37.704344363992952],
              [126.755959352944757, 37.70410687266746],
              [126.756232787309941, 37.703923577467712],
              [126.756286247461048, 37.703829043052473],
              [126.756334181855379, 37.70380443752353],
              [126.756503404693902, 37.703755501510095],
              [126.756672060199705, 37.703761760981962],
              [126.756700538864621, 37.703753662830351],
              [126.75711691521542, 37.703883477111255],
              [126.757131584039513, 37.703895329004574],
              [126.757351032591913, 37.704029804279799],
              [126.757479387685066, 37.70411018085899],
              [126.757478542339001, 37.704111224667415],
              [126.757477828382463, 37.704112516263876],
              [126.757477373445411, 37.704113884300753],
              [126.757477196037129, 37.70411529284199],
              [126.757477297660557, 37.704116704943068],
              [126.757477675254378, 37.704118086334695],
              [126.757478320076444, 37.70411940361312],
              [126.757479216551616, 37.704120626035504],
              [126.757480344585304, 37.704121721027917],
              [126.757481671551133, 37.704122661345416],
              [126.757543487963957, 37.704160001876701],
              [126.757545160825302, 37.704160838917566],
              [126.757546990394516, 37.704161432688529],
              [126.757548921259342, 37.704161765715654],
              [126.757550901356538, 37.704161825953918],
              [126.757552858078768, 37.704161613847511],
              [126.757554740335323, 37.704161133581266],
              [126.757556492363278, 37.704160402830382],
              [126.757558054969692, 37.704159441952399],
              [126.757594078098904, 37.704181999788439],
              [126.757593873631578, 37.70418225447478],
              [126.757593126305395, 37.704183707197409],
              [126.757592704862745, 37.704185243090443],
              [126.757592629055111, 37.704186815409805],
              [126.757592893750115, 37.704188370044193],
              [126.757593500513664, 37.704189863740297],
              [126.757594421382564, 37.704191256664167],
              [126.75759563075222, 37.704192499983463],
              [126.757597093862358, 37.704193552920074],
              [126.7576504791313, 37.704225799246863],
              [126.757652109024846, 37.704226622495966],
              [126.757653890998796, 37.704227211459006],
              [126.757655774142222, 37.704227552295741],
              [126.757657704096161, 37.704227635650774],
              [126.757659621919331, 37.704227456646379],
              [126.757661474234354, 37.704227021255733],
              [126.757663205314188, 37.704226343549038],
              [126.757664083869557, 37.704225837132832],
              [126.757667716291721, 37.704228111490096],
              [126.757689629909535, 37.704217760838695],
              [126.757708095981869, 37.704228911908586],
              [126.757709555317319, 37.704229661076496],
              [126.757711137915805, 37.704230221744034],
              [126.757712809818756, 37.704230586486823],
              [126.757714535996101, 37.704230741564643],
              [126.757716266500466, 37.704230690268261],
              [126.75771797074033, 37.704230428799846],
              [126.757796453171963, 37.704213275991371],
              [126.757797620250528, 37.704212958864119],
              [126.757798741683487, 37.704212554923181],
              [126.757799615882078, 37.704212144018058],
              [126.757800157691094, 37.704211847290338],
              [126.757843641200893, 37.704186475853945],
              [126.75784501076707, 37.704185533586035],
              [126.757846173221523, 37.704184428678857],
              [126.757863817445511, 37.704164688249101],
              [126.757864798318437, 37.704163363178985],
              [126.757865483106158, 37.70416192448203],
              [126.757865848708306, 37.704160413473367],
              [126.757865884484431, 37.704158873349236],
              [126.757865593343297, 37.704157353700303],
              [126.757864978292602, 37.704155894203403],
              [126.757864062704357, 37.70415454007145],
              [126.75786286549409, 37.704153328377501],
              [126.757861431676275, 37.704152295457462],
              [126.757846857052016, 37.704143494301604],
              [126.758115794233845, 37.704016462619229],
              [126.758332336679032, 37.703902603369457],
              [126.758325672162698, 37.703881751858475],
              [126.758875374208657, 37.703585286378576],
              [126.758936955314354, 37.70355331348037],
              [126.759117574104721, 37.703477174198767],
              [126.759151648414672, 37.703462810412447],
              [126.759229041601529, 37.70341763868965],
              [126.75923299146173, 37.703403292015892],
              [126.759279918529558, 37.703340029606537],
              [126.75931432265466, 37.703293653086938],
              [126.759359155086784, 37.703252887862639],
              [126.759366372687282, 37.703251811061087],
              [126.759436251943356, 37.703229711667163],
              [126.759479709376293, 37.703214600941671],
              [126.759524767369271, 37.703198927014533],
              [126.759595894493685, 37.703159025349933],
              [126.759645382248294, 37.703160233809889],
              [126.759646982251539, 37.703160184369573],
              [126.75964855795803, 37.703159954513382],
              [126.759650077516682, 37.703159553054512],
              [126.759651509130293, 37.703158983398538],
              [126.759652826565713, 37.703158259801846],
              [126.759701604100201, 37.703127198453629],
              [126.759703032881873, 37.703126115028901],
              [126.759704200272836, 37.703124851502345],
              [126.759705075269665, 37.703123445534565],
              [126.759705631352404, 37.703121940222296],
              [126.759705851057845, 37.703120380521675],
              [126.759705725961638, 37.703118815050964],
              [126.759705262403685, 37.703117290718353],
              [126.75970447014484, 37.703115852650981],
              [126.759703379363913, 37.703114546104132],
              [126.759702019177141, 37.703113409115907],
              [126.75970043006194, 37.703112477993052],
              [126.759698665081061, 37.703111778305647],
              [126.759682977400047, 37.703106873988112],
              [126.759729756418722, 37.703037452670678],
              [126.75988052669409, 37.703149814496065],
              [126.759909228055463, 37.703205056074154],
              [126.759918037430793, 37.703220842945782],
              [126.759920850694357, 37.703225641186279],
              [126.759931431745045, 37.70324339503388],
              [126.759946196900302, 37.703278336206765],
              [126.759956244602677, 37.703295538704054],
              [126.7600192069945, 37.703330859879472],
              [126.76003453123279, 37.703339123781674],
              [126.760160172913245, 37.703406883631772],
              [126.760328067857444, 37.703538146584989],
              [126.760349832867973, 37.703547487364972],
              [126.760516150037134, 37.703621591285909],
              [126.760573403558979, 37.703639089783472],
              [126.760671031942977, 37.703668928080539],
              [126.76073939220025, 37.703678355451501],
              [126.760809696492572, 37.703689761642899],
              [126.760865306600778, 37.703698326663762],
              [126.760947466909741, 37.7037125669261],
              [126.760965716802545, 37.703715136502389],
              [126.760976914222042, 37.703702492717412],
              [126.761016770059584, 37.703707357260392],
              [126.761108471177096, 37.703710935147349],
              [126.761127629679066, 37.703711682522936],
              [126.761161503776677, 37.703713003411139],
              [126.761281273174617, 37.703716063931488],
              [126.761285227907024, 37.70371604724825],
              [126.761291232206901, 37.703548611261489],
              [126.761303233159225, 37.703062474881882],
              [126.761302771790866, 37.703029407195096],
              [126.761301780261221, 37.702915794541781],
              [126.761298831097434, 37.702777800726025],
              [126.761297050635363, 37.70269441778953],
              [126.761297255790524, 37.702560355444632],
              [126.76129704910295, 37.702518999941979],
              [126.761293688134145, 37.70199351399269],
              [126.761293656646231, 37.701988685442004],
              [126.761293836507662, 37.701968504285901],
              [126.761292480003235, 37.701804567012104],
              [126.76129244877815, 37.70180074072708],
              [126.761292172525927, 37.701781368799097],
              [126.761291055048346, 37.701635410681135],
              [126.761292550948909, 37.701488104338992],
              [126.761293139677647, 37.701407290559345],
              [126.761294178896406, 37.701264850263634],
              [126.761295942735217, 37.701223253827365],
              [126.761312040017742, 37.700843691189938],
              [126.761322283280137, 37.700602156474901],
              [126.761341550867812, 37.7004230980843],
              [126.761370445282438, 37.700154564967747],
              [126.761366348280234, 37.700024892969672],
              [126.761358737408045, 37.699784064767805],
              [126.761353750558442, 37.699626324681404],
              [126.76134078233018, 37.699215948593469],
              [126.761340404197341, 37.699203984845518],
              [126.761333412750005, 37.69898008091652],
              [126.76131214147982, 37.698263319382491],
              [126.761284268964715, 37.697097501719924],
              [126.761257696413139, 37.697074652689835],
              [126.761225215867967, 37.696634285411157],
              [126.761204805819304, 37.696282955564058],
              [126.761203435271426, 37.696179882218047],
              [126.761195874642539, 37.695642351230994],
              [126.761203710791349, 37.695286574540766],
              [126.761203844811689, 37.695280384229349],
              [126.761205354342863, 37.695209875492893],
              [126.761212861896823, 37.694859081004289],
              [126.761259933294355, 37.694855312772546],
              [126.761312435371735, 37.694851111659972],
              [126.761308353523319, 37.69472359469227],
              [126.761304746431705, 37.694610992097346],
              [126.761455799808417, 37.694611938280694],
              [126.761449302452618, 37.694161880411137],
              [126.761443408560496, 37.693753674478586],
              [126.761443398842488, 37.693752938942303],
              [126.761437892018421, 37.693371464892202],
              [126.761319079254235, 37.693370720667346],
              [126.761255602281238, 37.693370323008303],
              [126.761244467326193, 37.692987571653113],
              [126.761244449154688, 37.692803172684059],
              [126.761244431296333, 37.69257609900388],
              [126.761244579536182, 37.692460324721708],
              [126.761251013542491, 37.692315531320588],
              [126.76125766006831, 37.692166005523973],
              [126.761253680033889, 37.69206436958585],
              [126.761252020880661, 37.691899717202539],
              [126.761247925963076, 37.691493323215312],
              [126.761250085107122, 37.691421340340916],
              [126.761239958245397, 37.691081385264546],
              [126.761238894908104, 37.691004501558162],
              [126.761230858350089, 37.690422760163742],
              [126.761207997895156, 37.689882853374357],
              [126.76120046868057, 37.689791661270995],
              [126.761189882924626, 37.689629463971578],
              [126.76118498160325, 37.689566746559507],
              [126.761183165190928, 37.689288887509427],
              [126.76118109756149, 37.68897210072376],
              [126.76118088572737, 37.688939035423893],
              [126.761183974619655, 37.688917606998373],
              [126.761238291155138, 37.688540743627172],
              [126.761256978318272, 37.688463301320638],
              [126.761277819277851, 37.688376937729771],
              [126.761346608731003, 37.688069306900196],
              [126.761376401983355, 37.687991471757066],
              [126.761510909854351, 37.687640059856228],
              [126.761543872249277, 37.687547291630175],
              [126.76159465860313, 37.687404357632531],
              [126.761597406850655, 37.687395532902876],
              [126.761624063800639, 37.687320537031162],
              [126.761632210955696, 37.68729658231603],
              [126.76163894458962, 37.68727678104046],
              [126.761498947937909, 37.687277816094571],
              [126.76141335995672, 37.687278449080985],
              [126.761342808692731, 37.687278969791286],
              [126.761233401151316, 37.687315146572665],
              [126.761000023692546, 37.687397267197092],
              [126.760992324175831, 37.687399976981823],
              [126.760895947457755, 37.687433907903255],
              [126.760884567201558, 37.68743791143828],
              [126.76071702103954, 37.687496905247912],
              [126.760666915355387, 37.687514546290728],
              [126.760098612206463, 37.687706034509723],
              [126.759855756918014, 37.687787862647724],
              [126.759620582881809, 37.687869459099886],
              [126.759429729938589, 37.687918170307455],
              [126.759328180017789, 37.687944117819534],
              [126.759197122064052, 37.687977606881745],
              [126.759111223167025, 37.688035632212937],
              [126.758923354577945, 37.688156836378035],
              [126.758898654670048, 37.688172773294824],
              [126.758806505271281, 37.68823520171761],
              [126.758581258159154, 37.688331861822945],
              [126.758352751324239, 37.688383828039335],
              [126.758293777130362, 37.688393872759875],
              [126.757926342521102, 37.68845646687479],
              [126.757835046733007, 37.68847181862747],
              [126.757516157583481, 37.688525444212146],
              [126.757031611856149, 37.688658952921038],
              [126.756923202829668, 37.688699903547786],
              [126.756637333967106, 37.688853736093449],
              [126.756432340220826, 37.68892673935057],
              [126.756374702407626, 37.688974280970704],
              [126.756316852734329, 37.689017135271662],
              [126.75622490908421, 37.68908532868528],
              [126.756136703964756, 37.68915370874727],
              [126.756079530550579, 37.68919809673239],
              [126.755947720567292, 37.689187521877535],
              [126.755831645116388, 37.689178210674939],
              [126.755778938639111, 37.689173955513553],
              [126.75552445697484, 37.689091436933666],
              [126.755194844410582, 37.688998761338382],
              [126.755072075620106, 37.688959491457709],
              [126.754919802865189, 37.688893854443151],
              [126.754702235527375, 37.68879203846145],
              [126.754558170281229, 37.688678938055645],
              [126.754140552777585, 37.688594181872048],
              [126.754159978632686, 37.688549265861603],
              [126.751905921604532, 37.687925821108927],
              [126.751852912038231, 37.687911204323719],
              [126.751211576818307, 37.688116771353194],
              [126.748263057668225, 37.689061805825531],
              [126.74602885405865, 37.68977782929133],
              [126.745689968684829, 37.689886430368844],
              [126.745381107215266, 37.689954713782285],
              [126.745369981843268, 37.689957173486789],
              [126.745159706821511, 37.690003664192517],
              [126.744907439903784, 37.690059436902992],
              [126.744870768234719, 37.690067545572745],
              [126.744703842266702, 37.69007799535558],
              [126.744461476642968, 37.69006845706113],
              [126.744252193736187, 37.690032862297983],
              [126.744046244323286, 37.689983761559724],
              [126.743822069601407, 37.689894346631839],
              [126.743455185673326, 37.689697763067386],
              [126.743269490734122, 37.689595458155139],
              [126.74295990637475, 37.689426491178331],
              [126.74292819559335, 37.689409181489275],
              [126.741264681281422, 37.68850805204054],
              [126.740977096496252, 37.688353336047548],
              [126.739798059038037, 37.687719018960564],
              [126.739134184521575, 37.687354362638288],
              [126.738971418235849, 37.687265375269106],
              [126.737047372831071, 37.686213418726012],
              [126.735340455062826, 37.685280123825542],
              [126.735046149696956, 37.685119400215271],
              [126.733996265595181, 37.684546037606054],
              [126.7330536623558, 37.684028360934981],
              [126.732620662998414, 37.683790691317569],
              [126.732071925812775, 37.683489505033968],
              [126.731715874798638, 37.683295137638503],
              [126.731515684675401, 37.683185853572297],
              [126.731321306424249, 37.683079740030898],
              [126.731218041144103, 37.683023374449526],
              [126.730723056915423, 37.682751137432945],
              [126.730024755124489, 37.682370133928522],
              [126.729414478252323, 37.682038071639951],
              [126.729050045847018, 37.68183742685499],
              [126.729025175473922, 37.681823733679927],
              [126.729023796788553, 37.681955184600056],
              [126.729031797724303, 37.682532843805319],
              [126.729034200329394, 37.682706345178929],
              [126.729034844835709, 37.683428923268266],
              [126.72904176989293, 37.683581202912123],
              [126.729052375518293, 37.684555984211798],
              [126.729065953941046, 37.685272825024406],
              [126.72906643873408, 37.685298413893143],
              [126.729076652663551, 37.685881546522857],
              [126.729078912394371, 37.68601056775443],
              [126.72908837263968, 37.686430620949395],
              [126.729088534048145, 37.686435955099114],
              [126.729094791994257, 37.686649533399304],
              [126.729107925922307, 37.687097742477498],
              [126.72904433573774, 37.687466540528526],
              [126.728851607620456, 37.688084699725465],
              [126.72868823321339, 37.688611658793739],
              [126.728454874690726, 37.689301618925604],
              [126.728318098462793, 37.689715295422786],
              [126.728163668046804, 37.690182360136333],
              [126.728113853539384, 37.690334693683837],
              [126.727951194816583, 37.690832094790856],
              [126.727879369071019, 37.69124889531939],
              [126.727858625250875, 37.69144841191261],
              [126.727819855885613, 37.692094315406052],
              [126.727848825619958, 37.692877764326461],
              [126.727873142193502, 37.693360070960452],
              [126.727879078294762, 37.693586542989706],
              [126.72790146941847, 37.694000796601969],
              [126.727907118771327, 37.694136032810604],
              [126.727908155609455, 37.694160851028904],
              [126.727918477261056, 37.694407963939],
              [126.727933504258999, 37.694721769880445],
              [126.727950162923321, 37.695048219331838],
              [126.727956477576839, 37.69523974344596],
              [126.727964258558245, 37.695417953902691],
              [126.727999277993646, 37.696107382460291],
              [126.728013944923291, 37.696374579811611],
              [126.728030648240988, 37.696719643429198],
              [126.728045690039181, 37.697023672765312],
              [126.728050765700686, 37.697157280073078],
              [126.728075124688459, 37.697672139425073],
              [126.72809443734144, 37.698052109065877],
              [126.728095544227642, 37.698073900206587],
              [126.72810663741285, 37.698283014027773],
              [126.728123254287723, 37.698618103003703],
              [126.728138191622463, 37.698919905302219],
              [126.728139837622564, 37.698953156588367],
              [126.728147944850875, 37.69915379288647],
              [126.728164737685134, 37.699499839350139],
              [126.728179003130109, 37.699854546210254],
              [126.728188254569574, 37.700068032806577],
              [126.728199226378891, 37.700294696166637],
              [126.728208443686881, 37.700502074309995],
              [126.728216335104747, 37.700692152036481],
              [126.728240104673546, 37.701264698575841],
              [126.728244892226996, 37.701369358165891],
              [126.728290079251451, 37.70235714629699],
              [126.728299110612511, 37.702358851207968],
              [126.728351423911633, 37.70238864134317],
              [126.728366256472356, 37.703020755692663],
              [126.728381099602629, 37.703653288253896],
              [126.728396435902724, 37.704306875078544],
              [126.728398365055824, 37.704389102014161],
              [126.728398811272484, 37.704408104645516],
              [126.728398948262125, 37.704409882033012],
              [126.728408586985282, 37.70441539806361],
              [126.728417976382687, 37.704415418949502],
              [126.728448266125994, 37.70441830938978],
              [126.728464898907177, 37.7044251059842],
              [126.728472145160183, 37.704428212462602],
              [126.728507877074676, 37.704445188190384],
              [126.728576024848778, 37.704415785246269],
              [126.728611812933096, 37.704417273120413],
              [126.728597677567763, 37.704452999782973],
              [126.72862444521617, 37.704451376785407],
              [126.72890460313576, 37.704491153772395],
              [126.729009609891136, 37.704508568860753],
              [126.729015632517218, 37.704508024190915],
              [126.729333421423632, 37.704532408414487],
              [126.729351130798975, 37.704533296593382],
              [126.72951698936815, 37.704529453965868],
              [126.729615365973942, 37.704519544198099],
              [126.729675045555666, 37.704527564652892],
              [126.729666188989412, 37.704575692871259],
              [126.729584746798153, 37.704656311504792],
              [126.729479946615015, 37.704726734989542],
              [126.729413569219616, 37.704806262214333],
              [126.729390650219358, 37.704871248622752],
              [126.729418609179163, 37.704928462032633],
              [126.729470212472734, 37.70496743953538],
              [126.729478679719321, 37.70497731559314],
              [126.729480421097506, 37.704985202659657],
              [126.72948605593993, 37.704994504997103],
              [126.729512277783797, 37.705042992221784],
              [126.729709988894172, 37.705060088609713],
              [126.72973635325755, 37.705062375332439],
              [126.729772182013718, 37.705051196046654],
              [126.729814419736428, 37.705033274420366],
              [126.72989211624099, 37.705006703733616],
              [126.729902337008397, 37.705006528938625],
              [126.729921363657596, 37.705006203453522],
              [126.729992313809319, 37.705004287360623],
              [126.730036361332381, 37.705003098661571],
              [126.730131750093932, 37.704998593065525],
              [126.730133130233767, 37.704998528169419],
              [126.730404080680856, 37.70498761013404],
              [126.730463229214749, 37.704995797974711],
              [126.730635729047705, 37.705019676494857],
              [126.730717889004481, 37.705034505629285],
              [126.730897629577001, 37.705061947037613],
              [126.73122526656428, 37.705154489237309],
              [126.73128678814362, 37.705144484681909],
              [126.731558060689807, 37.705094715830008],
              [126.731580048597337, 37.705084795053828],
              [126.73157495108704, 37.705012544897151],
              [126.731575012614812, 37.70501098061591],
              [126.73157541415145, 37.70500944739269],
              [126.731576141591432, 37.705007992905195],
              [126.731577176338476, 37.705006660295361],
              [126.731578486202551, 37.705005490813853],
              [126.731580029993282, 37.705004518442195],
              [126.731581764288819, 37.705003773542025],
              [126.731583632148102, 37.705003277373848],
              [126.73158558234833, 37.705003046728791],
              [126.73180286730819, 37.70499244899797],
              [126.731808495904858, 37.704992272959956],
              [126.73200742370436, 37.704942291276041],
              [126.732027269579291, 37.704942057313275],
              [126.732104534904565, 37.704940260397777],
              [126.732152552833185, 37.704938965174151],
              [126.732156574277582, 37.704936556912351],
              [126.732169418217268, 37.70492886761815],
              [126.732168347378305, 37.704933369921996],
              [126.732178259683096, 37.7049359329175],
              [126.73219739333021, 37.704935130064918],
              [126.732226319010834, 37.704923366091748],
              [126.732294026118424, 37.704917889834704],
              [126.732338186500868, 37.70492774842436],
              [126.732348226264506, 37.704921803824632],
              [126.732349788454115, 37.704921026246573],
              [126.732351489119154, 37.704920461379032],
              [126.732353281620959, 37.704920121536745],
              [126.732355120464504, 37.704920018140598],
              [126.732356955728974, 37.704920151766892],
              [126.732358739799395, 37.704920519401661],
              [126.732360425116553, 37.704921112623474],
              [126.732361967607972, 37.704921914921698],
              [126.732363325525611, 37.704922904392966],
              [126.732364463964828, 37.704924055573279],
              [126.732374150091218, 37.704935679799149],
              [126.732474619686315, 37.704954061824807],
              [126.732597825225795, 37.704939143056542],
              [126.732928590321336, 37.704900480281587],
              [126.733050607208597, 37.704916236763999],
              [126.733132773934628, 37.704932757831727],
              [126.733199335996403, 37.704946144433883],
              [126.733325947688073, 37.704967826013885],
              [126.733501787802965, 37.704998349431463],
              [126.733584994739019, 37.705018530876977],
              [126.733661114624979, 37.705035876631868],
              [126.733744664120422, 37.705061689803436],
              [126.733936914330229, 37.7051077416167],
              [126.734022599257983, 37.705129892904623],
              [126.7341209195462, 37.705135178785213],
              [126.73424115029394, 37.705156012159826],
              [126.734298172511046, 37.705066044608671],
              [126.734347239318183, 37.705022233974823],
              [126.734484416550785, 37.705010155676341],
              [126.734530533284925, 37.704998151237518],
              [126.734564288735527, 37.704973731137805],
              [126.734669798111241, 37.704899440177677],
              [126.734696619643699, 37.704880555070872],
              [126.73474207629576, 37.704853629503368],
              [126.73478325958699, 37.704832324723867],
              [126.734825695338813, 37.704807923028262],
              [126.734875349402557, 37.704796214507937],
              [126.73493788377553, 37.704724688158315],
              [126.734985655721829, 37.704670049467659],
              [126.734996881068156, 37.704651488517136],
              [126.735015310608077, 37.704650970861685],
              [126.735041175069753, 37.704646821371739],
              [126.735067254291423, 37.704642637216814],
              [126.735147223084738, 37.704626771897793],
              [126.735159754683664, 37.704624603507384],
              [126.735232324778764, 37.704612043928527],
              [126.735302492989717, 37.704612761319424],
              [126.735438033689846, 37.704613914312553],
              [126.735709683386048, 37.704657322574661],
              [126.735797646015868, 37.704683143973291],
              [126.735802599697578, 37.704689366311229],
              [126.735819874304113, 37.70471106566783],
              [126.735830328298434, 37.704711373172422],
              [126.735876924419657, 37.704711199267301],
              [126.735901021414705, 37.704711249077413],
              [126.735981491541921, 37.704657034589147],
              [126.735985570785942, 37.704654286774797],
              [126.736004397023663, 37.704641093639246],
              [126.736048931262573, 37.704644845051853],
              [126.736110857085436, 37.704650063802049],
              [126.736114437712629, 37.704633005279241],
              [126.736139154838725, 37.704515261699207],
              [126.736236901494749, 37.70451635638959],
              [126.736265123882859, 37.704516670795236],
              [126.736317726924696, 37.704523546954228],
              [126.736813729463961, 37.704406131047271],
              [126.736972395903379, 37.704380862996956],
              [126.73730677836204, 37.704372882172841],
              [126.737701181674808, 37.704374891980294],
              [126.73780411460838, 37.704377662899958],
              [126.738530088429343, 37.704413349898445],
              [126.738613239175052, 37.704449852957765],
              [126.738714668528587, 37.704484403658903],
              [126.738797108088889, 37.704512483713785],
              [126.739151744061843, 37.704951034460144],
              [126.739197406273277, 37.705007500868447],
              [126.739240077088027, 37.70505930257972],
              [126.739286859204924, 37.705116093441795],
              [126.739287733945801, 37.705118637188704],
              [126.739306013875193, 37.705160625717802],
              [126.739321778376066, 37.705263146556597],
              [126.739359869586508, 37.705337159526842],
              [126.739429124418152, 37.705471732779074],
              [126.739439409863451, 37.705495539764186],
              [126.739540905088248, 37.705730440785189],
              [126.73973935045106, 37.706233457337703],
              [126.739751982376745, 37.706269235365923],
              [126.739780434040625, 37.706343349173586],
              [126.739888560218091, 37.706442614093937],
              [126.739902479913439, 37.706455393443761],
              [126.740074979444458, 37.706628370803479],
              [126.740381761668658, 37.706609908589549],
              [126.740449481672073, 37.706601040595814],
              [126.740529788973546, 37.706589675883471],
              [126.740717595428649, 37.706543358241134],
              [126.740938579582277, 37.706485283536146],
              [126.74095711397014, 37.706472858357181],
              [126.740979602901888, 37.706457779023843],
              [126.741160414476838, 37.706333168884974],
              [126.741166634527659, 37.706328399500869],
              [126.741211018173033, 37.706302873288436],
              [126.741202584332598, 37.706241818914698],
              [126.741196507516051, 37.706197825378105],
              [126.741169595064264, 37.706091334858968],
              [126.741201731973007, 37.70600329753978],
              [126.741225047129021, 37.705939428082203],
              [126.741082654249652, 37.705617298267114],
              [126.741117839487728, 37.705565622509873],
              [126.74112362094958, 37.705557132379511],
              [126.741145336012295, 37.705528466113002],
              [126.741181803234952, 37.705438165517521],
              [126.741238878100532, 37.705379734636068],
              [126.741302514456748, 37.705334213122818],
              [126.741370330970156, 37.705285702670274],
              [126.741456973661812, 37.705235492640973],
              [126.741519559329433, 37.705198871045113],
              [126.741524083204965, 37.705196224142249],
              [126.741728928042079, 37.705089118907843],
              [126.741890488288035, 37.704997692795246],
              [126.742098864569797, 37.704895387488229],
              [126.742205528785405, 37.704842970290748],
              [126.742418219116985, 37.704722654117077],
              [126.742597112934291, 37.704638581365266],
              [126.742735443755492, 37.704653525904263],
              [126.742847333959816, 37.704629274439739],
              [126.742882093529303, 37.704620057272365],
              [126.742980878760932, 37.70459268139134],
              [126.743105006219636, 37.70466756280458],
              [126.74316700070024, 37.704708029634553],
              [126.743167829054556, 37.704707449998104],
              [126.743169493550809, 37.704706640485483],
              [126.743171305362281, 37.704706069865672],
              [126.743173215546804, 37.704705754048192],
              [126.743175165008054, 37.704705703469507],
              [126.743176766346934, 37.70470586156403],
              [126.743178326150542, 37.704706199654986],
              [126.743179807049373, 37.704706711193289],
              [126.743181181947236, 37.704707383386619],
              [126.743361489801515, 37.704810305424402],
              [126.743362934186536, 37.70481128450875],
              [126.743364152155081, 37.70481244060052],
              [126.743365109988034, 37.704813742838617],
              [126.743365780863982, 37.704815151393063],
              [126.743366146981373, 37.704816631899668],
              [126.743366197454776, 37.704818139222745],
              [126.743365932705601, 37.70481963190457],
              [126.743365357692738, 37.704821068516424],
              [126.743364488700237, 37.704822409504914],
              [126.743363349952759, 37.704823615367637],
              [126.74336258706883, 37.704824338734767],
              [126.743364399270448, 37.704824064647234],
              [126.743366171610319, 37.704824029152448],
              [126.743367734448228, 37.704824180688107],
              [126.743369254699374, 37.70482450410168],
              [126.743370705221665, 37.704824991107152],
              [126.743372054335637, 37.70482563338939],
              [126.743427868614177, 37.704856512789377],
              [126.743429263758088, 37.704857425760892],
              [126.743430458722329, 37.704858501487294],
              [126.743431424279308, 37.704859713642904],
              [126.74343198546832, 37.704860752855183],
              [126.743967070731756, 37.704854994420849],
              [126.744025561877038, 37.704901016577296],
              [126.744173572756338, 37.70498552552715],
              [126.744237613972643, 37.705014100343298],
              [126.744279591579641, 37.705070501523643],
              [126.744295498486011, 37.705081238999334],
              [126.744329979469271, 37.70510214518356],
              [126.744367996639852, 37.705126725248803],
              [126.744374706968472, 37.705130397850553],
              [126.744440509686385, 37.705164048336407],
              [126.744517771010322, 37.70516365021232],
              [126.744678510415596, 37.705203984603543],
              [126.744832194059185, 37.705236132003776],
              [126.744935113973369, 37.705245925076504],
              [126.745035983281952, 37.70528218106702],
              [126.745049608861791, 37.705287003823443],
              [126.745063591373736, 37.705290413798636],
              [126.74509846661428, 37.70529865249356],
              [126.745149231393299, 37.705326070147159],
              [126.745152035593023, 37.705334807424606],
              [126.745161207088316, 37.705345811714928],
              [126.745173729125213, 37.705364704134773],
              [126.745206787878203, 37.705386731207533],
              [126.745207164595769, 37.705326765505276],
              [126.745230678810927, 37.705291903538168],
              [126.745252035371919, 37.705264642066304],
              [126.745378135558013, 37.705177630444325],
              [126.745521093189694, 37.705083339376259],
              [126.745615607082954, 37.705010061508482],
              [126.745635145841277, 37.704997148091294],
              [126.745636742603381, 37.704996303860433],
              [126.745638340473349, 37.704994902623717],
              [126.745777106432755, 37.704935802419719],
              [126.745849313728854, 37.704907516840088],
              [126.745913086923665, 37.704911321787954],
              [126.745960221687014, 37.704859897645719],
              [126.745997881143396, 37.70483069340802],
              [126.74602492295945, 37.704822813983867],
              [126.746030284178858, 37.704821255642877],
              [126.746031491410207, 37.704820900132589],
              [126.746054465625804, 37.70481420605855],
              [126.746065098520958, 37.704811977498046],
              [126.746074180218827, 37.704799040403323],
              [126.746094596070563, 37.704788111806074],
              [126.746109986878466, 37.704691570270327],
              [126.7461405531662, 37.704613927200818],
              [126.746158036294077, 37.704578486592418],
              [126.746160190824767, 37.704572301119605],
              [126.746161843568984, 37.704553159633541],
              [126.746164662208642, 37.704507837879682],
              [126.746167839549628, 37.704408170895817],
              [126.746178814104965, 37.704359209496516],
              [126.74618118732306, 37.704338375438624],
              [126.746180625379424, 37.704294172233439],
              [126.746216076537578, 37.70424047046771],
              [126.746236386254992, 37.704207295796465],
              [126.746292208837332, 37.70415195315217],
              [126.746310223492429, 37.70413771846286],
              [126.746327037394835, 37.704124432391218],
              [126.746415126764347, 37.704064655062169],
              [126.746566300434679, 37.70400022081116],
              [126.746613697116487, 37.703974708188859],
              [126.74662398917387, 37.703969378676121],
              [126.746629867418307, 37.703961787500255],
              [126.746662225206279, 37.703930323819229],
              [126.746787723194089, 37.703863869420353],
              [126.746971450111801, 37.703764033417563],
              [126.747050975447763, 37.703721130322251],
              [126.747084681669506, 37.70370627376326],
              [126.747266419899603, 37.703617695548374],
              [126.74741562392245, 37.703562553706568],
              [126.747589895686573, 37.703531673727589],
              [126.747684926599902, 37.703513580519697],
              [126.747731200747793, 37.703504670628362],
              [126.747981596344928, 37.703445315550979],
              [126.748017236346584, 37.703436866793709],
              [126.74820833403335, 37.7033564784779],
              [126.748277464852293, 37.703345356263746],
              [126.748326243078779, 37.703329982543373],
              [126.748439306707922, 37.703271383183406],
              [126.748517751277845, 37.703235503364887],
              [126.748523963161517, 37.703231021054599],
              [126.748550706158298, 37.70323501487804],
              [126.748641592696288, 37.703238589154367],
              [126.74864957370994, 37.703236070361179],
              [126.748671383921788, 37.703231337729854],
              [126.748694249242263, 37.703226593791285],
              [126.748794831926304, 37.703204952330182],
              [126.748829007475379, 37.703197600811869],
              [126.748967844835946, 37.703168616535564],
              [126.749075016159665, 37.703121545615417],
              [126.749165546573678, 37.703073033780207],
              [126.749270920722225, 37.70303440017085],
              [126.749305797080183, 37.703042358220813],
              [126.749505062358011, 37.70306052370227],
              [126.749606586393696, 37.70306046143525],
              [126.749779202599527, 37.703049568773338],
              [126.750020287666374, 37.703014043991772],
              [126.750227459141527, 37.703001534483789],
              [126.750646085166366, 37.703384771919865],
              [126.750892724648565, 37.703556337259975],
              [126.7513902811516, 37.703902434544716],
              [126.751501730103769, 37.704009661411149],
              [126.751573407865465, 37.704138200608909],
              [126.751700823643503, 37.704344008205766],
              [126.752218665872476, 37.704364808851793],
              [126.75248570348954, 37.704464200276277],
              [126.752493848023292, 37.704466748556413],
              [126.75252410676319, 37.70447610136209],
              [126.752607138173232, 37.704499089408941],
              [126.752750424928962, 37.704567811701295],
              [126.752996858226624, 37.704682915616722],
              [126.753101976387157, 37.704722841970721]
            ]
          ]
        ]
      },
      id: '31104513'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '일산3동',
        ADM_CD: '31104530'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.762640406261298, 37.685693870277639],
              [126.762823474919969, 37.685524928663689],
              [126.762829799959675, 37.685519088896932],
              [126.76283381061431, 37.685515387012806],
              [126.762883341737137, 37.685469666250889],
              [126.762927303081696, 37.685429100451337],
              [126.762946765675437, 37.685411138026545],
              [126.762962702912162, 37.685396430761969],
              [126.763235561304796, 37.685191520972296],
              [126.763344477991666, 37.685109727972431],
              [126.763506192305286, 37.684988290206022],
              [126.763821830636815, 37.684803542825087],
              [126.763829572727062, 37.684799012453411],
              [126.764167804722533, 37.684601049422405],
              [126.764274403668225, 37.68453865730919],
              [126.764315534079728, 37.684519791512301],
              [126.76431809302629, 37.684518606017249],
              [126.764320430782789, 37.684517539017783],
              [126.76432183662979, 37.684516897035365],
              [126.764756014835896, 37.684317659666711],
              [126.764765384276572, 37.684313359300759],
              [126.764824116334538, 37.684286411871],
              [126.765029368374968, 37.684192232504302],
              [126.765069125629012, 37.684173991511457],
              [126.765105642785528, 37.684157235517375],
              [126.765118862931601, 37.684151168197516],
              [126.765480567968353, 37.683985189443341],
              [126.765492427942419, 37.683979498472759],
              [126.765497665177321, 37.683976978555272],
              [126.765504581999934, 37.683973657009595],
              [126.765734063731088, 37.683863373814773],
              [126.765776794186507, 37.683842841010822],
              [126.765851173354847, 37.68380709625994],
              [126.765887692304872, 37.683789546870997],
              [126.765906006451686, 37.683780747726281],
              [126.765906706137059, 37.683780408678977],
              [126.765988363201799, 37.683741166958335],
              [126.766009086347069, 37.683731199358782],
              [126.76602703586741, 37.683722570978986],
              [126.766330515790628, 37.683595558977558],
              [126.76642402275273, 37.683556428528753],
              [126.766466210794434, 37.68353876911079],
              [126.766495184174374, 37.683526640903743],
              [126.766538558792362, 37.683508486804946],
              [126.766541442243494, 37.6835072789438],
              [126.766543571758334, 37.683506391769903],
              [126.766619937503876, 37.683474530052827],
              [126.767022991024305, 37.683306358371418],
              [126.767337932329667, 37.683174950753326],
              [126.767602932853819, 37.683064379808613],
              [126.768250956509206, 37.682793988509914],
              [126.76831179318512, 37.682767279517336],
              [126.768395539829385, 37.682346003878123],
              [126.768669324067687, 37.682230330344311],
              [126.768805033789008, 37.682172992547521],
              [126.76864291307534, 37.681955923707797],
              [126.769500993550437, 37.681588705710233],
              [126.769638250763549, 37.68152996415759],
              [126.76973461625613, 37.681521558536367],
              [126.769766992803056, 37.681507811988979],
              [126.770183920426049, 37.681330783196671],
              [126.770281169724853, 37.681232356025419],
              [126.770429569391496, 37.681453911969399],
              [126.770459795097509, 37.68149903878124],
              [126.769766038729841, 37.68178282951137],
              [126.770580409647948, 37.681783375352431],
              [126.77099810171994, 37.681602245606236],
              [126.771639012398154, 37.681324315994964],
              [126.771750431169323, 37.681275998394199],
              [126.772535349126684, 37.680935612764188],
              [126.772541635813951, 37.68093288539125],
              [126.772587493236941, 37.68091299850429],
              [126.772612668388447, 37.680902081963936],
              [126.77261763181049, 37.680899915158371],
              [126.773453899595637, 37.680537257478107],
              [126.774102477142407, 37.680255987038876],
              [126.774167295911013, 37.680227876321524],
              [126.774182420080606, 37.680221318559731],
              [126.774211622923303, 37.680208657170844],
              [126.774256505731358, 37.68018919175784],
              [126.77445586438337, 37.68010273073876],
              [126.774906824291236, 37.679907157838628],
              [126.774927427386004, 37.679898223535119],
              [126.775022536834925, 37.679856980657313],
              [126.775138859722233, 37.679806531308664],
              [126.776059360643387, 37.679407308563299],
              [126.777294363691226, 37.678871664320823],
              [126.77730593189456, 37.678866648155307],
              [126.777306273121269, 37.678867127946432],
              [126.777375966270995, 37.678836282769645],
              [126.777507471257564, 37.678779228173902],
              [126.777469294990738, 37.678695285904837],
              [126.777448323929178, 37.678665055103238],
              [126.777423134712407, 37.678628700115425],
              [126.777278094647457, 37.678419407924686],
              [126.777152332618527, 37.678237590609626],
              [126.777037008232824, 37.678070901823361],
              [126.776762017507878, 37.67767268494574],
              [126.776592145353149, 37.677426691035258],
              [126.776304701724527, 37.677010433350937],
              [126.776187572493669, 37.676840812359671],
              [126.775982862344463, 37.67654436048403],
              [126.775950537263213, 37.6764387975782],
              [126.775931821210989, 37.676411780936796],
              [126.775870970569144, 37.676323951224767],
              [126.775832369287144, 37.676268183802371],
              [126.775799528330168, 37.676220743894945],
              [126.775720977720042, 37.676107342186242],
              [126.775680382842651, 37.676066181901611],
              [126.775587966200973, 37.675972482551003],
              [126.775253232267644, 37.67548773779118],
              [126.775196539050398, 37.675405636833887],
              [126.775101546692412, 37.675268071080573],
              [126.77506921014853, 37.675166199660289],
              [126.775042725986893, 37.675127746028515],
              [126.774978199154475, 37.67503405268544],
              [126.774896542003745, 37.674915488377906],
              [126.774928317721844, 37.67482896579606],
              [126.774812610076623, 37.674660869969841],
              [126.774705315935464, 37.674504999017223],
              [126.774596463735335, 37.674479741500278],
              [126.774429208345495, 37.6742380525911],
              [126.774335934472333, 37.674158944776615],
              [126.77429485724339, 37.674099501339249],
              [126.774149127880094, 37.673888606535968],
              [126.773978772304076, 37.673642074022567],
              [126.773964989509878, 37.673622127653779],
              [126.77370510811501, 37.673246032012308],
              [126.773601377208948, 37.673095916188053],
              [126.773563292830303, 37.672984213365112],
              [126.773504723179499, 37.6728996781669],
              [126.773467048180791, 37.672845216266168],
              [126.773240141652551, 37.672944125450208],
              [126.773226410176079, 37.672950108531985],
              [126.773009842235837, 37.67304449595364],
              [126.772285683082416, 37.673360186598281],
              [126.771798539243193, 37.673572546974157],
              [126.771788742099034, 37.673576816431009],
              [126.770831221898533, 37.673994113146755],
              [126.770804466491697, 37.674005773062348],
              [126.770381234525615, 37.674190242805885],
              [126.770058645750794, 37.674330842934943],
              [126.769600190509905, 37.674530659461695],
              [126.769269515312587, 37.674674781971298],
              [126.768963961756199, 37.674807953183922],
              [126.768667137519145, 37.674937319172244],
              [126.768535417586008, 37.674995027787631],
              [126.768419006236996, 37.675046028945921],
              [126.767435280583257, 37.675475313013052],
              [126.76667244580338, 37.675808194414657],
              [126.766305022763689, 37.675968527332415],
              [126.766234365158212, 37.675999358169534],
              [126.766232577097739, 37.676000139305778],
              [126.765468606488724, 37.676333497898952],
              [126.765239006936639, 37.67643368258291],
              [126.764742046576856, 37.676650540091146],
              [126.764731085744657, 37.676655322664466],
              [126.764690158015398, 37.676673179533267],
              [126.764421810677192, 37.676790267743023],
              [126.764333961932621, 37.676828598253699],
              [126.763893677047804, 37.6770207020895],
              [126.763712426151343, 37.677099785480827],
              [126.763597686743992, 37.67714984949032],
              [126.762875537131151, 37.677464928309341],
              [126.762628625728496, 37.677572655472702],
              [126.762433293985879, 37.677657858035978],
              [126.762300904046342, 37.677715605002234],
              [126.761556501971327, 37.678040400521034],
              [126.761264379423565, 37.678167857322826],
              [126.761080415753511, 37.678248112083367],
              [126.76106933151209, 37.678227102261175],
              [126.761049518405713, 37.678189542683235],
              [126.761046938253159, 37.678184651275323],
              [126.760789823632237, 37.678293853682199],
              [126.760827365436214, 37.678342443916037],
              [126.760831178958014, 37.678347380013037],
              [126.760836648445505, 37.678354459012454],
              [126.760796915903441, 37.678371792932893],
              [126.760352675570545, 37.678565597273519],
              [126.760320244705085, 37.678579745592799],
              [126.759787157486386, 37.67881232140936],
              [126.759738006283641, 37.678833765359471],
              [126.75921989987728, 37.67905980070617],
              [126.759164015846679, 37.67908418098596],
              [126.758581807198183, 37.679338178050273],
              [126.758282437445246, 37.679468780615885],
              [126.758159262471125, 37.679522494258158],
              [126.758182717500432, 37.679556474641394],
              [126.758615983710314, 37.680184159937305],
              [126.758762221439014, 37.680396015951153],
              [126.758983328062101, 37.680716332062786],
              [126.759437788754141, 37.681374697679175],
              [126.759892693408432, 37.682033695215956],
              [126.759950899488715, 37.682118013906226],
              [126.760237447515422, 37.682533111831994],
              [126.760520349471662, 37.682942925181585],
              [126.76056026275657, 37.68300074543297],
              [126.760830002316311, 37.683391482887416],
              [126.761179603305337, 37.683897901478652],
              [126.761210204292496, 37.683942227195125],
              [126.761542667123067, 37.68442381412828],
              [126.761696767969823, 37.684647032627311],
              [126.761714104778576, 37.684672143763351],
              [126.761793499485449, 37.684787106536312],
              [126.761877726751905, 37.684909070305665],
              [126.761928039251899, 37.68498193960658],
              [126.762382928931132, 37.685640782120949],
              [126.762416894831446, 37.685689954227506],
              [126.762418539665646, 37.685689233545439],
              [126.762507090347341, 37.685816892292969],
              [126.762599193927528, 37.685731898818268],
              [126.762640406261298, 37.685693870277639]
            ]
          ]
        ]
      },
      id: '31104530'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '삼송2동',
        ADM_CD: '31101513'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.878014949551243, 37.660322577256451],
              [126.878476313670376, 37.660217358076849],
              [126.878639359940806, 37.660230634682065],
              [126.878814207500469, 37.660244871836127],
              [126.87890378852272, 37.660252165716891],
              [126.878943781711854, 37.660255451628196],
              [126.878992286773297, 37.660259465546368],
              [126.87938837226713, 37.660292572108311],
              [126.879444727544865, 37.66032273502767],
              [126.879747823194208, 37.660484943257266],
              [126.879953064732092, 37.660502542767496],
              [126.88005971971171, 37.660511040465295],
              [126.880229318131384, 37.660524550307656],
              [126.880363127013069, 37.660464276620047],
              [126.880638924217919, 37.660340043839014],
              [126.880644899956039, 37.660337352302527],
              [126.880677136629174, 37.660322830809598],
              [126.880743413879529, 37.660331579161941],
              [126.881199256405665, 37.660391750193895],
              [126.881558582834231, 37.660493563203232],
              [126.882000914702473, 37.660161644510453],
              [126.88226630856667, 37.659937887261393],
              [126.882447039788588, 37.659785490543847],
              [126.882464776599434, 37.659635981746824],
              [126.882475095920356, 37.65954907646622],
              [126.882481487658225, 37.659495188831123],
              [126.882486327850387, 37.659454373157082],
              [126.882527844098263, 37.659219669034364],
              [126.882512723518232, 37.659144227968973],
              [126.88244508860231, 37.658806757942138],
              [126.882424162885613, 37.658568791274845],
              [126.882462278757515, 37.658540078897758],
              [126.882582405739399, 37.658449587877641],
              [126.882644479300467, 37.658402827341533],
              [126.882802101207517, 37.658295275479773],
              [126.882898988324342, 37.65822915254067],
              [126.883061495625512, 37.658089843218249],
              [126.883480188316739, 37.657979804214428],
              [126.883598548254497, 37.65794920073543],
              [126.883648985774059, 37.657952581988816],
              [126.884012038015072, 37.657976909821969],
              [126.8838812783723, 37.657031705998897],
              [126.883425680002773, 37.656325660356629],
              [126.883481205837626, 37.655790457250994],
              [126.883473318983192, 37.655704874456077],
              [126.883473938780597, 37.655564106254701],
              [126.883478518659373, 37.655509015695628],
              [126.883491487679194, 37.655353027286068],
              [126.883544231043544, 37.655320552222072],
              [126.883766068286945, 37.655208875678987],
              [126.883837848307806, 37.655108755998668],
              [126.883948802010664, 37.655032014047151],
              [126.883974534802363, 37.654954284372508],
              [126.883957294432747, 37.654892462272365],
              [126.883952365235558, 37.65485650772878],
              [126.883951412054387, 37.654670277232817],
              [126.883918979574531, 37.65439589812943],
              [126.883992871549793, 37.654392547110717],
              [126.884048806298495, 37.654390005413575],
              [126.884051124388421, 37.65438990034486],
              [126.884177267888376, 37.654162172814601],
              [126.884151416470985, 37.654155908811688],
              [126.884022957086884, 37.654124782156956],
              [126.883976766495152, 37.654113589792317],
              [126.884018655492937, 37.654080649518782],
              [126.884056869043562, 37.654050599531026],
              [126.884270747041029, 37.653882331008788],
              [126.884319422734691, 37.653842284814736],
              [126.884365499043895, 37.653800434993258],
              [126.884409082918637, 37.653756872238432],
              [126.884450061793146, 37.653711774428928],
              [126.88448832570937, 37.653665140993674],
              [126.884523756390493, 37.65361715518948],
              [126.884556242761249, 37.653567814636688],
              [126.884585787143351, 37.653517387946117],
              [126.884612383828795, 37.653465880498189],
              [126.884637198585679, 37.653414209620692],
              [126.884682557782412, 37.653319766035445],
              [126.884732050941778, 37.653216709252277],
              [126.884811049597218, 37.653052216647517],
              [126.884955342866846, 37.65275175720943],
              [126.884978356594161, 37.652703836309328],
              [126.885007168073159, 37.652641580232945],
              [126.885027703793952, 37.652597208459397],
              [126.885073765805686, 37.652489588222153],
              [126.885116428929834, 37.652381155274867],
              [126.885150122873071, 37.652287948598897],
              [126.885155922209307, 37.652271905406998],
              [126.885191904394802, 37.652161843152541],
              [126.885224483266427, 37.652051234968788],
              [126.885253666375618, 37.651939991666239],
              [126.885279449157863, 37.651828118631627],
              [126.88530172518378, 37.651715876698354],
              [126.885319911560899, 37.651607418864558],
              [126.885322284205145, 37.651589957139677],
              [126.885328750482756, 37.651546763939393],
              [126.885329418160651, 37.651541403602508],
              [126.885333930105631, 37.651504531161478],
              [126.885336302674077, 37.6514851523063],
              [126.885339979768816, 37.651455216658427],
              [126.885342393175463, 37.651431983026477],
              [126.885342713250992, 37.651428634449907],
              [126.885342853506046, 37.651427159703175],
              [126.885344814469065, 37.651409855367646],
              [126.885355793476108, 37.651272196527522],
              [126.885359064599655, 37.651209402571837],
              [126.885361196174884, 37.651149940303803],
              [126.885376559152505, 37.650599189472075],
              [126.885380176472253, 37.650532070926666],
              [126.885386965265312, 37.650465133847526],
              [126.885397031473758, 37.65039927741239],
              [126.885397154871043, 37.650398472265962],
              [126.885410630805708, 37.650332083781969],
              [126.885427276335648, 37.650266239086719],
              [126.885447321572556, 37.650200938476267],
              [126.885470426089029, 37.650136360441572],
              [126.885496814314536, 37.650072507051213],
              [126.885785425620014, 37.649417601778467],
              [126.885804762971802, 37.649369778715965],
              [126.885821155906854, 37.649321322007395],
              [126.885834376182004, 37.649272142135352],
              [126.88584442538324, 37.649222597838687],
              [126.885851301234808, 37.649172690909054],
              [126.885855119028179, 37.649122600410706],
              [126.885855650170754, 37.649072417089577],
              [126.885853008429351, 37.649022229868422],
              [126.885847193452065, 37.648972219912672],
              [126.885838318255665, 37.648922567175049],
              [126.885826157257313, 37.648873272283119],
              [126.885825255875872, 37.648869577542776],
              [126.885811048319994, 37.648824604690148],
              [126.885794347383495, 37.648780260601043],
              [126.885775037449761, 37.648736544672403],
              [126.885765322850716, 37.648717523785145],
              [126.88574091654219, 37.648672271585852],
              [126.885713789859452, 37.648628096727101],
              [126.885683829438022, 37.648585000419331],
              [126.885651259377184, 37.648543163192294],
              [126.885616195691071, 37.648502675781124],
              [126.88557852316012, 37.648463626815264],
              [126.885538581867905, 37.648426015359668],
              [126.885496258464698, 37.648390115727466],
              [126.885451779686719, 37.648355925491963],
              [126.885110416470198, 37.648106052714233],
              [126.88491698677467, 37.647964464690212],
              [126.884912685973561, 37.647961306536409],
              [126.88488965211809, 37.647981195075992],
              [126.884621182679808, 37.647784697382086],
              [126.883946392677188, 37.647290742462133],
              [126.883826758374084, 37.647204759826487],
              [126.883705194885621, 37.647120577764341],
              [126.883581816320429, 37.647038104930324],
              [126.88345650743193, 37.646957432652279],
              [126.883329271625428, 37.646878560040534],
              [126.883280821851756, 37.646849140602463],
              [126.883022378793441, 37.646693372342412],
              [126.882762009653007, 37.646539673709896],
              [126.882683667349781, 37.646496709252119],
              [126.882607368682187, 37.646451583230359],
              [126.882533225862147, 37.646404297137146],
              [126.882461238884375, 37.646354851877867],
              [126.882391521897276, 37.646303425616175],
              [126.882345576860871, 37.646266889379774],
              [126.88222342819796, 37.646196400250062],
              [126.881939957336485, 37.646036009022573],
              [126.881661123663477, 37.645881659605948],
              [126.880987079921056, 37.645509139674175],
              [126.880240018810923, 37.645101133591737],
              [126.880104622202367, 37.645026933996768],
              [126.880053340092331, 37.644998861162371],
              [126.880035112091477, 37.644990102680239],
              [126.879759680633015, 37.644838544551384],
              [126.879694020723605, 37.644802618383416],
              [126.879766816773582, 37.644701333699892],
              [126.879566874977925, 37.644533815525357],
              [126.879444533428696, 37.644446564956908],
              [126.879742286737383, 37.644244065868655],
              [126.879576851726355, 37.644106227884947],
              [126.879421048896958, 37.643976413511986],
              [126.879095285921949, 37.643704972501993],
              [126.878658650075892, 37.643264301331904],
              [126.878400410382397, 37.642992233354164],
              [126.878212664363801, 37.642794425695712],
              [126.877826023796118, 37.642409576568291],
              [126.877559865456092, 37.642165271942332],
              [126.877414567515174, 37.642031905003044],
              [126.877302800655926, 37.641855769140648],
              [126.87686942515974, 37.641172791869778],
              [126.876869083839168, 37.641172945095953],
              [126.876760050834761, 37.64122166296125],
              [126.87665376080345, 37.641269145972601],
              [126.876427403518164, 37.641324045912448],
              [126.876349149968362, 37.641343007286977],
              [126.87547504584937, 37.64155494345524],
              [126.87539674295779, 37.641573919256892],
              [126.875312294203752, 37.641594398332153],
              [126.875074089646873, 37.641652197410508],
              [126.875123023627708, 37.641816661130022],
              [126.875124759820466, 37.641869740707307],
              [126.875127517212562, 37.641979932234342],
              [126.87519462606825, 37.642294444841795],
              [126.875112529205353, 37.64240779045528],
              [126.875115893847507, 37.642426545148318],
              [126.875134967350633, 37.642532597646571],
              [126.875184309967707, 37.642582722424571],
              [126.875218322221045, 37.642617279323552],
              [126.875241853831966, 37.642641193675558],
              [126.875174650419538, 37.642664993438657],
              [126.874235801467051, 37.642977330865342],
              [126.873911296959889, 37.643085284914747],
              [126.873854335875521, 37.643291823637213],
              [126.873775800806612, 37.643374375197091],
              [126.873705708716855, 37.643448053757275],
              [126.87385712744387, 37.643726133929135],
              [126.874059247612607, 37.6440946448459],
              [126.874008611854791, 37.644294886220067],
              [126.874066860448409, 37.644519588793116],
              [126.874072262500022, 37.644540427379432],
              [126.874099369137227, 37.644533986074364],
              [126.874204102936417, 37.644824880151418],
              [126.874322627839959, 37.645120258380373],
              [126.874337124741345, 37.64517415371995],
              [126.874340538167871, 37.645180190909223],
              [126.874351013011477, 37.645198717431228],
              [126.874579638934748, 37.645481699185162],
              [126.874620165429519, 37.645477598457333],
              [126.874610996404044, 37.645619806862406],
              [126.874616964085291, 37.645734498566512],
              [126.874740005480362, 37.64585480240126],
              [126.875064041479519, 37.646180042984248],
              [126.875183892878397, 37.646278486609411],
              [126.875207930029433, 37.646299290439522],
              [126.875208246239239, 37.646299567020058],
              [126.875298399719313, 37.646381013680568],
              [126.875401825201735, 37.646463111900026],
              [126.875558655563395, 37.646535905272074],
              [126.875632529431897, 37.646568222308076],
              [126.875682197665512, 37.646589957568516],
              [126.875713681220404, 37.646603735172995],
              [126.875751074646416, 37.646643303166414],
              [126.875771193006273, 37.646671736638048],
              [126.875787656988507, 37.6466950014687],
              [126.875798914930897, 37.64671090913135],
              [126.87581553955593, 37.646712406775251],
              [126.875922562637527, 37.64672204939],
              [126.875948032272561, 37.646743204955321],
              [126.875978028552211, 37.646768112362679],
              [126.875833286619226, 37.646885770644154],
              [126.87562045348912, 37.647041232509309],
              [126.875623166121045, 37.647044479037426],
              [126.875577002083276, 37.6470728988063],
              [126.87548011456127, 37.647143701898493],
              [126.875463323846006, 37.647155936899324],
              [126.875419755180673, 37.647189406980011],
              [126.875300992130889, 37.647263970723934],
              [126.875297248790844, 37.64726630968287],
              [126.875164846089305, 37.647365815680345],
              [126.875125029165929, 37.647392261624695],
              [126.875085098098793, 37.647418527585963],
              [126.875044488015035, 37.647445062138125],
              [126.874971549449157, 37.647491654854392],
              [126.874906892892156, 37.64753212901438],
              [126.874862201878543, 37.647559471102603],
              [126.87476465514473, 37.647617208925745],
              [126.874706356456755, 37.64765039246069],
              [126.87463342918079, 37.647690316983109],
              [126.874577061957083, 37.647719988293474],
              [126.874478627585901, 37.647767003452721],
              [126.87437860912668, 37.647812035313514],
              [126.87436772355187, 37.647816618533163],
              [126.874229608995009, 37.647875033071863],
              [126.874132774068684, 37.647913760580558],
              [126.874054424079176, 37.647943317713697],
              [126.873946372380928, 37.647981492523364],
              [126.873868482121637, 37.648007176251994],
              [126.873801139276509, 37.64802818573618],
              [126.873797057682467, 37.648029352924752],
              [126.873429986893285, 37.648125089121066],
              [126.873341797605789, 37.648143643257399],
              [126.87323694862684, 37.648163621248081],
              [126.873144003987377, 37.648179737110013],
              [126.873063300321121, 37.648194425350617],
              [126.871969943825093, 37.648324772720386],
              [126.870917168174586, 37.648444613141208],
              [126.870490573181556, 37.648492521732848],
              [126.870119851655559, 37.648534545804019],
              [126.869756272785736, 37.648575720860826],
              [126.869153206979391, 37.648644015256735],
              [126.86881288008793, 37.648669670964161],
              [126.868720849858434, 37.648680558710168],
              [126.868474453570983, 37.648709833753053],
              [126.86807461085121, 37.64875501147835],
              [126.867979160126211, 37.648765796498196],
              [126.867731531854545, 37.648793775385862],
              [126.867284731366581, 37.648859309449591],
              [126.866977588200541, 37.64889382664947],
              [126.866816876872946, 37.648911122338724],
              [126.866609365646966, 37.648929986324283],
              [126.866401294864914, 37.64894398336375],
              [126.866192890269616, 37.648955367090089],
              [126.865984265761497, 37.648962876252227],
              [126.865763634365479, 37.648967307346993],
              [126.865274967584952, 37.648936561195178],
              [126.865176096180747, 37.648932300373076],
              [126.865062293004243, 37.648927395770386],
              [126.864987841586185, 37.648923505076688],
              [126.864854268246944, 37.648916523543129],
              [126.864741195112956, 37.648909094331678],
              [126.864782144806327, 37.648885266463431],
              [126.8647907094538, 37.648880293977527],
              [126.86477749754593, 37.648879891146287],
              [126.864754349025773, 37.648878909330797],
              [126.864555022586174, 37.648870416057107],
              [126.864554318884743, 37.648870386127967],
              [126.864467359102079, 37.648866683705059],
              [126.864430987678617, 37.648865127646864],
              [126.864438468004366, 37.648815401931799],
              [126.864431306660563, 37.648802689752522],
              [126.863851302310479, 37.64877614781296],
              [126.863525802455101, 37.648761045189445],
              [126.863440952131185, 37.648763357148383],
              [126.863349816891969, 37.648765839729606],
              [126.863346417077409, 37.648765862842495],
              [126.863225510651915, 37.648766604622033],
              [126.862119783144422, 37.648717902416202],
              [126.862077071981517, 37.648713797543557],
              [126.861942587209185, 37.648709945314494],
              [126.861501809197748, 37.648697529531162],
              [126.86127160696941, 37.648676083640957],
              [126.861257908272378, 37.648717701368895],
              [126.861306324666458, 37.648773699929031],
              [126.86133750740693, 37.648809956185239],
              [126.861370406344804, 37.648850457972749],
              [126.861394223681557, 37.648905446330019],
              [126.861480256258744, 37.649186643722281],
              [126.861494861690687, 37.649240634897843],
              [126.861395515666686, 37.649279073327619],
              [126.861369655260333, 37.649292286527398],
              [126.861035522000336, 37.649462083088537],
              [126.860725850306807, 37.649758694000191],
              [126.860645528806472, 37.649826702947614],
              [126.860652510193091, 37.649830193777959],
              [126.860551365460026, 37.649891721909434],
              [126.860539941689225, 37.649922449395071],
              [126.860327025464272, 37.650495485059267],
              [126.85974999135945, 37.650813386478106],
              [126.859900873347016, 37.651633257465669],
              [126.859916276495966, 37.65171468058314],
              [126.85989691938731, 37.651998276064504],
              [126.859891286551161, 37.652080795516554],
              [126.859900627250127, 37.652096166991669],
              [126.860008149554773, 37.652273106212228],
              [126.860025745847551, 37.652302432675882],
              [126.860068018786535, 37.652372888150325],
              [126.86074080852211, 37.653470556386765],
              [126.860770646945923, 37.653517561943318],
              [126.860796316653705, 37.653557725449424],
              [126.860796761863469, 37.65355842368772],
              [126.860798086427195, 37.65356048679611],
              [126.860880961259696, 37.653706769937045],
              [126.861011029999958, 37.653713700957823],
              [126.861209202999945, 37.653725962469693],
              [126.861495296879639, 37.653743665901359],
              [126.861748157044147, 37.653758264015991],
              [126.862066939347429, 37.653777796861647],
              [126.862173335524062, 37.653784032544408],
              [126.862622029392526, 37.654124571478157],
              [126.862898398071664, 37.654340264332212],
              [126.862904265086769, 37.654338771872482],
              [126.862908062048845, 37.654341654999563],
              [126.863068031272661, 37.654461418546688],
              [126.863165575491564, 37.654537097988197],
              [126.863494444909961, 37.654784729333301],
              [126.863652441355228, 37.654905266498318],
              [126.863659705217941, 37.654923950569305],
              [126.864387211037879, 37.655476636928633],
              [126.864580811670194, 37.655624566788752],
              [126.864758808797433, 37.655764265193284],
              [126.865068583725503, 37.656007388120216],
              [126.864964140292599, 37.6567029634001],
              [126.864951126239319, 37.656789644402863],
              [126.864936410215932, 37.656887667570636],
              [126.865353580256468, 37.657073753148687],
              [126.865498580171092, 37.657138951141008],
              [126.865498469370053, 37.657138382708077],
              [126.866267260294862, 37.657485041999117],
              [126.867060789007326, 37.657604319305811],
              [126.867846149798908, 37.657722365866171],
              [126.868033946110117, 37.65767500415452],
              [126.868219516482796, 37.657628202564162],
              [126.86843254275891, 37.6575416810253],
              [126.868608964194323, 37.657470026173414],
              [126.869130226167613, 37.657302455049269],
              [126.870170678943296, 37.657093428529933],
              [126.870697474534978, 37.656987592167042],
              [126.870492541266302, 37.656968733493471],
              [126.869815996212907, 37.656906478804117],
              [126.870809654580739, 37.656730515122149],
              [126.87083060470799, 37.656726304095052],
              [126.870913179897812, 37.656709700378656],
              [126.871368337948269, 37.656626945924039],
              [126.871549340375424, 37.656895562676951],
              [126.872453560845457, 37.656768527555478],
              [126.872522417232005, 37.656705123036545],
              [126.872597034294131, 37.656636411446975],
              [126.872999282615098, 37.65660432498693],
              [126.873768190319055, 37.656343337713317],
              [126.874859511922111, 37.65667841517692],
              [126.875510611744659, 37.656613482848066],
              [126.875731021117218, 37.656591501583542],
              [126.875727567363086, 37.656709901710471],
              [126.875717189173315, 37.65706575995678],
              [126.875714602521015, 37.657119726856237],
              [126.875755690598297, 37.657149232085828],
              [126.875792364022772, 37.657175759383748],
              [126.876506526371131, 37.657723364902523],
              [126.876525563773825, 37.658030302199542],
              [126.876538418334746, 37.658237547777674],
              [126.876556713135102, 37.658532447479139],
              [126.876593483549811, 37.658629111933166],
              [126.876782523299866, 37.659126067879448],
              [126.876868365142712, 37.659367169828357],
              [126.877300639382156, 37.660513880313601],
              [126.877310886021107, 37.660541064393406],
              [126.877416636403296, 37.660458556173836],
              [126.877559099789892, 37.660426178799241],
              [126.878014949551243, 37.660322577256451]
            ]
          ]
        ]
      },
      id: '31101513'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '중산1동',
        ADM_CD: '31103511'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.789778697072165, 37.688402180351851],
              [126.789525495795374, 37.68770070877968],
              [126.789494516487963, 37.687631100641823],
              [126.789176390910583, 37.687631792804929],
              [126.78880886058235, 37.68759522122],
              [126.788470052251299, 37.687561506828708],
              [126.78786201544284, 37.686975872061176],
              [126.78683075383168, 37.686318536025766],
              [126.786465470860634, 37.686201018665734],
              [126.7860711641937, 37.686074130101296],
              [126.785265430102058, 37.685814838239558],
              [126.784898279688974, 37.685819217303127],
              [126.784526344893337, 37.685823651821437],
              [126.784314838136069, 37.685809656175948],
              [126.784134326483112, 37.685800139494944],
              [126.784058849002577, 37.685796159831547],
              [126.784034971531668, 37.685794900835432],
              [126.783629947798531, 37.685817302245979],
              [126.783454443268965, 37.685819947612885],
              [126.783415476341162, 37.685820542754215],
              [126.7831817755944, 37.685824124760316],
              [126.782998315556597, 37.685717740659456],
              [126.782561524081828, 37.685362848182997],
              [126.782460620469251, 37.685271921075966],
              [126.782397913089298, 37.685215415198073],
              [126.782141769681587, 37.68492176178453],
              [126.782108435972162, 37.684888399293946],
              [126.782238434487809, 37.684896265059649],
              [126.782248510409644, 37.684824205437671],
              [126.782253445331648, 37.684788745985308],
              [126.782370697073176, 37.683946167966688],
              [126.782206648979383, 37.683986496016935],
              [126.782135630192357, 37.684004201899519],
              [126.781992005594986, 37.684038350136518],
              [126.781959108671188, 37.68404513597352],
              [126.781927122637796, 37.684050842158001],
              [126.78189570310262, 37.684056370617945],
              [126.781862699930329, 37.684061353140144],
              [126.781829810892901, 37.684065436830394],
              [126.781797036143402, 37.684069429272775],
              [126.781772112962884, 37.684071530785033],
              [126.781763584285855, 37.68407224947088],
              [126.781730361122669, 37.684074169732],
              [126.781695894797295, 37.684075637159346],
              [126.781662450930781, 37.684075393789314],
              [126.781628330143377, 37.684074429736569],
              [126.781597270899653, 37.684073289639258],
              [126.781565644871009, 37.684072238916841],
              [126.781532659737479, 37.684070645422757],
              [126.781498997231694, 37.684067790450463],
              [126.781432469601484, 37.684061718498313],
              [126.781393294605024, 37.684098433023699],
              [126.781383275228507, 37.68410354915369],
              [126.781315258591874, 37.684019420024732],
              [126.781236981762149, 37.683922599921246],
              [126.781127851771842, 37.683754905639262],
              [126.781129762051123, 37.683684002293177],
              [126.781167551958021, 37.683408638591658],
              [126.781213879607961, 37.683071050160073],
              [126.781239487452339, 37.682883606279212],
              [126.781260119296363, 37.682730660750302],
              [126.781306797817237, 37.682388938218381],
              [126.781308669784949, 37.682375228822323],
              [126.781262075856432, 37.682340892064516],
              [126.781208898900928, 37.682301703914945],
              [126.780815629382531, 37.682380794659942],
              [126.780466606648119, 37.682450972309439],
              [126.780424061369771, 37.682459526732082],
              [126.780234633902595, 37.682495931480155],
              [126.779948895478967, 37.682553596823382],
              [126.779904752307772, 37.682568469473232],
              [126.779869715570428, 37.682532815605285],
              [126.779865311612966, 37.68252686162883],
              [126.779864636386606, 37.682525147700481],
              [126.779282339202254, 37.682657576318384],
              [126.779036215311834, 37.682698286144522],
              [126.778649102624442, 37.68276687354745],
              [126.778635709784979, 37.682769246822524],
              [126.778418159627265, 37.682807757858583],
              [126.778309598701867, 37.682827024450745],
              [126.778200155017473, 37.682846446875978],
              [126.778157679295077, 37.68285069980292],
              [126.778154440605675, 37.682876649724179],
              [126.778149220240678, 37.682886491735445],
              [126.778138623514579, 37.682979380045566],
              [126.778140895064809, 37.682985353480468],
              [126.778115453707599, 37.683189916449372],
              [126.778083276395122, 37.683448342534128],
              [126.778053537843917, 37.683687042810888],
              [126.778031725017698, 37.683862600930247],
              [126.77800950866397, 37.684041401322297],
              [126.777995104617915, 37.684156608216114],
              [126.777954216560005, 37.684484389752676],
              [126.777951615282703, 37.684505289861292],
              [126.777944654805921, 37.684524046078046],
              [126.777948542964893, 37.684529972630308],
              [126.777935975636737, 37.684630944486479],
              [126.777933145472588, 37.68465392794613],
              [126.777931561128099, 37.684666793603363],
              [126.777930808780638, 37.684672803506018],
              [126.777922269833326, 37.684741018109683],
              [126.777914790573533, 37.684853174049174],
              [126.777909033624169, 37.684894129935429],
              [126.777904711696067, 37.684924871835527],
              [126.7778770253435, 37.685121860661823],
              [126.777882656530508, 37.685285488338558],
              [126.777844890940059, 37.685554174917833],
              [126.777801609425637, 37.685659145281974],
              [126.777781450400028, 37.685802271309917],
              [126.777774237912951, 37.68585343112462],
              [126.777768826835299, 37.685891801425214],
              [126.77768269903757, 37.68650771542611],
              [126.777652039573084, 37.686726961149361],
              [126.777658873830759, 37.686790942639114],
              [126.777663225966705, 37.686831130305116],
              [126.777666816011688, 37.686864295626783],
              [126.777649454118745, 37.686986980578148],
              [126.777641565167173, 37.68704273025687],
              [126.777612356587582, 37.687249864789884],
              [126.777584572945287, 37.687446882611603],
              [126.777559322791589, 37.687625319913209],
              [126.777529722002043, 37.687699101759151],
              [126.777506661295703, 37.687756580938327],
              [126.777453133886809, 37.688136056444698],
              [126.777423948153384, 37.688342953982101],
              [126.777429350783748, 37.688506669419297],
              [126.777414703351099, 37.688610530650053],
              [126.777391470316175, 37.688775266661693],
              [126.777348184816759, 37.688880147537247],
              [126.777327123643389, 37.689029442814117],
              [126.777269871377527, 37.689435263420094],
              [126.777275379833313, 37.689485668315214],
              [126.777284877786499, 37.689572600193223],
              [126.777258268817988, 37.689760467649101],
              [126.777255803104396, 37.689777875940784],
              [126.777219726618583, 37.689919717168387],
              [126.777194762842129, 37.69001786654443],
              [126.777178815451236, 37.690080544780379],
              [126.777137689755833, 37.690372467941984],
              [126.777128840538211, 37.690449125876526],
              [126.777124299776929, 37.690526058984979],
              [126.777123840950892, 37.690603001792731],
              [126.777127462999829, 37.690679947984201],
              [126.777135281031192, 37.690756725211344],
              [126.777147184267434, 37.690833062398625],
              [126.777163167344511, 37.690909046038563],
              [126.777307452261411, 37.691514219793966],
              [126.77751305858385, 37.692376574652961],
              [126.777566381008, 37.692478575529577],
              [126.777629642303623, 37.692744210425559],
              [126.777639809653991, 37.692907665600941],
              [126.777724234497299, 37.693261813398827],
              [126.777789269276155, 37.693389515805229],
              [126.777831372477834, 37.693565916596043],
              [126.777881759773976, 37.693774440625496],
              [126.777927796257387, 37.69396495667641],
              [126.777970012394718, 37.69414135629242],
              [126.777968373866955, 37.694268565590932],
              [126.77796823670981, 37.694279200311605],
              [126.778084237039366, 37.69476344637399],
              [126.778180553631145, 37.695165524769763],
              [126.778197370019498, 37.695229343720676],
              [126.778206337812577, 37.695257527865621],
              [126.778217476239107, 37.695292539264763],
              [126.778240644319609, 37.69535511000494],
              [126.778266879140133, 37.695416787376438],
              [126.778296289905811, 37.695477656769555],
              [126.778418484818189, 37.695715923656351],
              [126.778472702067006, 37.695790332086474],
              [126.778521692883487, 37.695857570070579],
              [126.778649977773483, 37.696107922794887],
              [126.778675049516096, 37.696216535421783],
              [126.778700694412109, 37.6962665811729],
              [126.778850005575222, 37.696557971324957],
              [126.778921972083879, 37.69669846099243],
              [126.778995988361871, 37.696842953283706],
              [126.779234715401685, 37.697311016251625],
              [126.779384334973869, 37.69760436884718],
              [126.77942233851924, 37.697674179258442],
              [126.779439668846621, 37.697702291825443],
              [126.779464428038963, 37.697742458949818],
              [126.779510491755815, 37.697809037786271],
              [126.779560303105171, 37.697874002708083],
              [126.779613859928375, 37.697936991367506],
              [126.779671053079582, 37.697997912960901],
              [126.77973165541357, 37.698056679569021],
              [126.77974445024266, 37.698067976432526],
              [126.779795664402457, 37.698113201039426],
              [126.779862969499931, 37.698167296427854],
              [126.779925647338686, 37.698213812276279],
              [126.78010070050523, 37.698297121039474],
              [126.780318942087689, 37.698406071566815],
              [126.780388734362305, 37.698440914356844],
              [126.780491131249235, 37.69851480377902],
              [126.780770246743202, 37.698646689411284],
              [126.780933845718963, 37.698692583657845],
              [126.781128035638147, 37.698784303330562],
              [126.781342211902128, 37.698885550655369],
              [126.781567940404869, 37.698992256059149],
              [126.781581508813687, 37.698998665983041],
              [126.781762134114445, 37.699083980120278],
              [126.781806827599809, 37.699116047251366],
              [126.781824364663763, 37.699128619296602],
              [126.781825725516825, 37.699129597403548],
              [126.781830764592357, 37.699126507731705],
              [126.781926031398527, 37.699068066016224],
              [126.781929438221013, 37.699065909178636],
              [126.781939898913237, 37.699060523512685],
              [126.781986219918167, 37.699036643050292],
              [126.782257635265978, 37.698896779517696],
              [126.782332586720059, 37.698858086070324],
              [126.782487046611323, 37.698849724473895],
              [126.782529341242508, 37.698847413077111],
              [126.782684718924827, 37.698838918003027],
              [126.783086862796182, 37.698817051576242],
              [126.783330689761641, 37.69880380806071],
              [126.783395708531927, 37.698783236758338],
              [126.783485269707995, 37.698754900983367],
              [126.784571523722917, 37.698411293112009],
              [126.784636170881626, 37.698503219625806],
              [126.785171992978803, 37.698346535624538],
              [126.785157853734404, 37.698299613795825],
              [126.78519739088739, 37.698089446094187],
              [126.785208400596929, 37.698050941812518],
              [126.785273249378378, 37.697784474327193],
              [126.785441738836511, 37.697235498280605],
              [126.785450826246631, 37.697210756271851],
              [126.785498784625446, 37.697059760551831],
              [126.785533573431209, 37.696945880630494],
              [126.785497398960217, 37.696837274262144],
              [126.785338501302704, 37.696336604003044],
              [126.785472546009018, 37.696169053604045],
              [126.785562896012536, 37.696054084437037],
              [126.785630675524899, 37.695972922127631],
              [126.785571035634348, 37.695870401716007],
              [126.785483701863086, 37.69572027525107],
              [126.785465501731821, 37.695688995557056],
              [126.785507069430608, 37.695650422225732],
              [126.785810212522804, 37.695369109348427],
              [126.786194884243201, 37.695285761001841],
              [126.786575137253266, 37.695200854221653],
              [126.787166397208779, 37.69491913369086],
              [126.787641434361007, 37.694594052270126],
              [126.787871272565368, 37.694436350497021],
              [126.788173917307546, 37.694229675675302],
              [126.788231076304612, 37.69422460738604],
              [126.788793919817451, 37.694174009919216],
              [126.788840728231349, 37.694169802757578],
              [126.788843273460003, 37.694169572948937],
              [126.789233596648614, 37.694029321389309],
              [126.789293847632436, 37.69390635161686],
              [126.78947572616525, 37.693535134288723],
              [126.789844402537611, 37.692768208829357],
              [126.789652507301525, 37.692101994547222],
              [126.789636382654251, 37.692021416781422],
              [126.789549851118778, 37.691588988510716],
              [126.789688287164665, 37.691070469441115],
              [126.789866134958771, 37.690826480757494],
              [126.789539162180645, 37.690404692695118],
              [126.789471426571211, 37.690143694979106],
              [126.789575764018807, 37.689670586249036],
              [126.789675150816876, 37.689331569391321],
              [126.789997740120356, 37.688839183115924],
              [126.789924668370844, 37.688693399860895],
              [126.78988754934015, 37.688619349211436],
              [126.789813592052269, 37.688471799371854],
              [126.789802421922133, 37.688449512755675],
              [126.789778697072165, 37.688402180351851]
            ]
          ]
        ]
      },
      id: '31103511'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '탄현1동',
        ADM_CD: '31104511'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.776062824250062, 37.69383500013835],
              [126.776018774551915, 37.693741034014394],
              [126.775990339019373, 37.693680448541443],
              [126.775842892412825, 37.69336631145665],
              [126.775632573097468, 37.692918215233263],
              [126.775488175307459, 37.692610565171172],
              [126.775395633365648, 37.692496338439319],
              [126.775342915221287, 37.692431266438525],
              [126.775163876207387, 37.692210454254393],
              [126.775092515670565, 37.692125738670761],
              [126.775033507263956, 37.692055688633083],
              [126.774562865857902, 37.692168846220589],
              [126.774505452098936, 37.692183602708432],
              [126.774466419237385, 37.692193527628106],
              [126.774465689229004, 37.692193715117448],
              [126.774402647100445, 37.692209892554608],
              [126.774170263815407, 37.692269268968779],
              [126.774004818723455, 37.692314899144719],
              [126.773872619709778, 37.692351403363539],
              [126.773574745837465, 37.692433537390201],
              [126.773522709691065, 37.6924309139483],
              [126.773485980779569, 37.692429040052666],
              [126.773451747642611, 37.692427353674958],
              [126.77339744522628, 37.692417530000128],
              [126.773381264322168, 37.692414603759076],
              [126.773296171449644, 37.692395516727395],
              [126.772783601380212, 37.692195333925881],
              [126.772707757168305, 37.692165713011946],
              [126.772616315536865, 37.692129996660675],
              [126.772584886611668, 37.692117722413236],
              [126.772580925004817, 37.692116183759602],
              [126.772541740778507, 37.692101061262456],
              [126.772487609230964, 37.692080054660735],
              [126.772421587784521, 37.692054608684849],
              [126.772262592494656, 37.691993122933333],
              [126.772105492051764, 37.691943198162797],
              [126.772063623247902, 37.691916244449672],
              [126.771943809117261, 37.69186988090599],
              [126.771855944283672, 37.691835921989878],
              [126.771829112186495, 37.69182555157056],
              [126.771821278158953, 37.691822523434432],
              [126.771706337926943, 37.691778151918029],
              [126.771604528887835, 37.691738760970942],
              [126.771573728033019, 37.691726898631529],
              [126.771517445454776, 37.691705074321519],
              [126.771478336423016, 37.691689961850777],
              [126.771416205607494, 37.691665955321504],
              [126.770934215662706, 37.691482306062625],
              [126.770689411205979, 37.691389028094335],
              [126.770665618848724, 37.691379963294018],
              [126.770379451940897, 37.691269574190265],
              [126.770237447415823, 37.691214788494371],
              [126.770138912185061, 37.691176783845322],
              [126.770070980640682, 37.691150582948318],
              [126.769838266258375, 37.691060840018984],
              [126.769807691685259, 37.691048976817726],
              [126.769768282631119, 37.691033763679449],
              [126.769715139887893, 37.691013287399706],
              [126.769696599657678, 37.691006143193455],
              [126.769536477342555, 37.690944382707613],
              [126.769410545875459, 37.690898274333748],
              [126.769102170050502, 37.690785586780322],
              [126.76909354477263, 37.69078219032756],
              [126.768647456541771, 37.6906065957374],
              [126.768203117169008, 37.690431687206676],
              [126.768164356840956, 37.690416429789451],
              [126.768002892555131, 37.690352871581958],
              [126.767930565951161, 37.690324400592317],
              [126.76775222006188, 37.690254196367981],
              [126.767653634068466, 37.690215384453637],
              [126.767615300771553, 37.690201360709835],
              [126.767587634784391, 37.690191250938653],
              [126.767573297793803, 37.69018600724857],
              [126.767521645733083, 37.69016711925299],
              [126.767435550379247, 37.690135634049383],
              [126.767256767776914, 37.690070253827642],
              [126.767177920235042, 37.690041418283883],
              [126.767176744892708, 37.690040988261245],
              [126.767121035820054, 37.690020618347098],
              [126.766859795111415, 37.689991268992202],
              [126.766614319231607, 37.689944958746771],
              [126.766257849273941, 37.689877706578805],
              [126.766146859465579, 37.689856766891353],
              [126.765466648505978, 37.689728434668368],
              [126.765013281514186, 37.689642899374448],
              [126.764801273709537, 37.689602899489607],
              [126.764737531380902, 37.689590872348724],
              [126.764636052828209, 37.689572215901258],
              [126.764591415830822, 37.689564008635593],
              [126.764559954715509, 37.689558224251805],
              [126.76447076678096, 37.689541828209229],
              [126.764296532575585, 37.689509780112438],
              [126.763708709702613, 37.689393921449785],
              [126.763704178093249, 37.689393054027278],
              [126.763557034737403, 37.68936491446167],
              [126.762919758249069, 37.689243038571711],
              [126.762303917406442, 37.68912761609225],
              [126.762265639180356, 37.689120442782261],
              [126.762185869776616, 37.689105504586372],
              [126.762176138454407, 37.689103669003011],
              [126.762154484109303, 37.689099584836164],
              [126.762017382430471, 37.689073898282984],
              [126.761963650189173, 37.689063822355038],
              [126.76178181903876, 37.68902972387739],
              [126.761620921436261, 37.688999550749472],
              [126.761183974619655, 37.688917606998373],
              [126.76118088572737, 37.688939035423893],
              [126.76118109756149, 37.68897210072376],
              [126.761183165190928, 37.689288887509427],
              [126.76118498160325, 37.689566746559507],
              [126.761189882924626, 37.689629463971578],
              [126.76120046868057, 37.689791661270995],
              [126.761207997895156, 37.689882853374357],
              [126.761230858350089, 37.690422760163742],
              [126.761238894908104, 37.691004501558162],
              [126.761239958245397, 37.691081385264546],
              [126.761250085107122, 37.691421340340916],
              [126.761247925963076, 37.691493323215312],
              [126.761252020880661, 37.691899717202539],
              [126.761253680033889, 37.69206436958585],
              [126.76125766006831, 37.692166005523973],
              [126.761251013542491, 37.692315531320588],
              [126.761244579536182, 37.692460324721708],
              [126.761244431296333, 37.69257609900388],
              [126.761244449154688, 37.692803172684059],
              [126.761244467326193, 37.692987571653113],
              [126.761255602281238, 37.693370323008303],
              [126.761319079254235, 37.693370720667346],
              [126.761437892018421, 37.693371464892202],
              [126.761443398842488, 37.693752938942303],
              [126.761443408560496, 37.693753674478586],
              [126.761449302452618, 37.694161880411137],
              [126.761455799808417, 37.694611938280694],
              [126.761304746431705, 37.694610992097346],
              [126.761308353523319, 37.69472359469227],
              [126.761312435371735, 37.694851111659972],
              [126.761259933294355, 37.694855312772546],
              [126.761212861896823, 37.694859081004289],
              [126.761205354342863, 37.695209875492893],
              [126.761203844811689, 37.695280384229349],
              [126.761203710791349, 37.695286574540766],
              [126.761195874642539, 37.695642351230994],
              [126.761203435271426, 37.696179882218047],
              [126.761204805819304, 37.696282955564058],
              [126.761225215867967, 37.696634285411157],
              [126.761257696413139, 37.697074652689835],
              [126.761284268964715, 37.697097501719924],
              [126.76131214147982, 37.698263319382491],
              [126.761333412750005, 37.69898008091652],
              [126.761340405331623, 37.699203984852616],
              [126.761465400837565, 37.699123514984478],
              [126.7614670215345, 37.699091985345788],
              [126.761776800733784, 37.699092535384096],
              [126.765578143631529, 37.699099213378055],
              [126.765647044420504, 37.699040607125518],
              [126.765662509060775, 37.699062441409282],
              [126.765678666220623, 37.699116532743233],
              [126.765763274180117, 37.699163966883411],
              [126.765768477932326, 37.699185996837656],
              [126.76588229538298, 37.699380556338753],
              [126.76589259169377, 37.699398172696093],
              [126.76591625360264, 37.699438609794861],
              [126.765950843319487, 37.699497737937094],
              [126.765982971698662, 37.699485639470574],
              [126.765988419890206, 37.699483577823472],
              [126.766140318035553, 37.699426309924426],
              [126.766598947340569, 37.699511439299116],
              [126.767432144974038, 37.699666089635308],
              [126.768061286224821, 37.699782861482028],
              [126.768105880541697, 37.699845567138844],
              [126.768199002287687, 37.699619607114222],
              [126.768564053868801, 37.698734672953037],
              [126.768804229840143, 37.698152582861368],
              [126.768899618454995, 37.697921397800236],
              [126.768854459068407, 37.697858871889174],
              [126.768925441424628, 37.697640703707584],
              [126.769008757538558, 37.69761203557637],
              [126.769155404428034, 37.697418885483458],
              [126.769194732983991, 37.697315172148826],
              [126.769677581078909, 37.696682201976493],
              [126.769796733381213, 37.696578102208264],
              [126.769805500811785, 37.696566497953967],
              [126.769853705236457, 37.696506023288137],
              [126.769860138773666, 37.696497951263879],
              [126.769918399117486, 37.696431304420891],
              [126.769980167790393, 37.696366736075852],
              [126.770045333616224, 37.696304247337686],
              [126.770113779150847, 37.696244015934838],
              [126.770185277762081, 37.696186134195997],
              [126.770259831112099, 37.696130779685618],
              [126.770389377116544, 37.696038413890314],
              [126.770472787730583, 37.695979023865931],
              [126.771513235060553, 37.695237309127101],
              [126.771791987593076, 37.694962607437553],
              [126.772048381103886, 37.694694438596322],
              [126.772064652344682, 37.694675640201822],
              [126.772103795170594, 37.694630848365982],
              [126.772155473016753, 37.694565267468761],
              [126.7721602564271, 37.694558519981619],
              [126.772206832163661, 37.694492479441756],
              [126.772247392925479, 37.694428859655027],
              [126.772287294358165, 37.694358301942067],
              [126.772323118571748, 37.694286294069322],
              [126.772354750638684, 37.694213106628133],
              [126.772550019294343, 37.693726242978315],
              [126.772671540368535, 37.693366902321443],
              [126.772690977322085, 37.693370236310209],
              [126.772726268453852, 37.693376289113466],
              [126.773107549150325, 37.69345748472891],
              [126.773643183715365, 37.693554187205287],
              [126.773696469279358, 37.693563807116732],
              [126.773962087743513, 37.693611759823021],
              [126.774164680417542, 37.693655848514283],
              [126.774558080499673, 37.693741567462133],
              [126.775392976233022, 37.693870116613084],
              [126.775642917369169, 37.693892129297247],
              [126.775928048904262, 37.693900063530805],
              [126.776014147569967, 37.69388653251653],
              [126.776062824250062, 37.69383500013835]
            ]
          ]
        ]
      },
      id: '31104511'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '삼송1동',
        ADM_CD: '31101512'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.907055562225764, 37.675107972630613],
              [126.907293234642552, 37.674763828141316],
              [126.907508937154859, 37.674502719353015],
              [126.907596048720791, 37.674395250892644],
              [126.907594260760007, 37.674393784465046],
              [126.907160050231553, 37.674075365542883],
              [126.906921458347142, 37.673900397231435],
              [126.906877063229331, 37.67407394274678],
              [126.90687052544591, 37.674099497659697],
              [126.90683600415953, 37.674184513030454],
              [126.906830444826483, 37.674251252719436],
              [126.906827700341651, 37.674292880253709],
              [126.906816355199709, 37.674464915481231],
              [126.906757128060036, 37.674768081964899],
              [126.906658399194001, 37.674808369485611],
              [126.906601929474576, 37.674646148362598],
              [126.906534205307125, 37.674420851554451],
              [126.906296509795695, 37.674150369205421],
              [126.906351737250503, 37.673737372237781],
              [126.906410954029766, 37.673294537006143],
              [126.90641098903815, 37.673267509066179],
              [126.906513407399899, 37.672952251494308],
              [126.906842271438364, 37.672835388480699],
              [126.908430426606515, 37.671836573468532],
              [126.907819622738103, 37.670817990876316],
              [126.907793601080357, 37.670776522606943],
              [126.90777437519408, 37.670745878255055],
              [126.90774808395669, 37.670682977740384],
              [126.907627465684584, 37.670394382303627],
              [126.907752350486888, 37.670232305288025],
              [126.908183303805373, 37.670043444561045],
              [126.908444268913556, 37.669827416411657],
              [126.908974746271213, 37.669648309429824],
              [126.90905562304313, 37.669621002430588],
              [126.90905659399526, 37.669620674705151],
              [126.909060126944908, 37.669620854645331],
              [126.909146556758159, 37.669625249889897],
              [126.909237934631193, 37.669629826686077],
              [126.909241653728131, 37.669628981841434],
              [126.909302359752957, 37.669612929478269],
              [126.909541859978276, 37.669549597189743],
              [126.909646111622649, 37.66952202914382],
              [126.90968635092095, 37.669453966630414],
              [126.909816485680878, 37.669233849172876],
              [126.90982785266236, 37.669206830141526],
              [126.910077550862425, 37.669086587422321],
              [126.910145405517014, 37.669053911119988],
              [126.911222625892748, 37.66868534117804],
              [126.911237324697083, 37.668687325368708],
              [126.911363574003133, 37.668704145173656],
              [126.911766590372835, 37.668757834398441],
              [126.911902343618237, 37.668974170469845],
              [126.912038120747908, 37.669172487136805],
              [126.912158255159184, 37.669124718847343],
              [126.912174197774974, 37.669118532267071],
              [126.912212270362858, 37.669118561572098],
              [126.912287542459538, 37.669118617597469],
              [126.912570893811306, 37.669127841879302],
              [126.912639792476142, 37.669122263277998],
              [126.912963921337891, 37.669096040324135],
              [126.913012981726055, 37.669092136579842],
              [126.91308096612876, 37.66911020583801],
              [126.913194278265891, 37.669137320982479],
              [126.913284921776764, 37.669164416995208],
              [126.91330761216247, 37.669146414960544],
              [126.913326042822931, 37.669136292780699],
              [126.913680420001654, 37.668940201574003],
              [126.913712733755943, 37.66892232030574],
              [126.91379531284197, 37.668876487186807],
              [126.913851890965148, 37.66895761575752],
              [126.913872235951473, 37.668995448286189],
              [126.913919747482709, 37.669083803687272],
              [126.913897006859884, 37.669146854901619],
              [126.913919600290001, 37.669209938502107],
              [126.914043950997396, 37.66948933460155],
              [126.914395216507558, 37.66957968969016],
              [126.914644336426946, 37.66978709803643],
              [126.915063577917991, 37.669904532701246],
              [126.915244942433787, 37.669895656205902],
              [126.915403545026834, 37.669967848385596],
              [126.915471396791958, 37.670103043268028],
              [126.916015343345038, 37.670202545877977],
              [126.916031288946414, 37.670195243848362],
              [126.916403401465388, 37.670026570497249],
              [126.916412258596225, 37.670022640364003],
              [126.916775029556021, 37.669968843526213],
              [126.917126516263565, 37.669869986461457],
              [126.917141588310571, 37.669851699976945],
              [126.91734216789618, 37.669608859055536],
              [126.917705133140316, 37.669374864842112],
              [126.917734356031943, 37.669375732877626],
              [126.918271840695866, 37.669393285825507],
              [126.918509916478769, 37.669348405510505],
              [126.918623280836357, 37.669330466384245],
              [126.918670800038171, 37.669330498707083],
              [126.918759293588167, 37.669330561687552],
              [126.918802636643406, 37.66934207974171],
              [126.918997248312039, 37.66939379509266],
              [126.919223890375321, 37.66943900342168],
              [126.919233412715144, 37.669448481815557],
              [126.919314466895941, 37.669529163163787],
              [126.919371078442268, 37.669583260214111],
              [126.919699859812553, 37.66951141097492],
              [126.920141846889365, 37.669565775293208],
              [126.920197999278301, 37.670043331120375],
              [126.920379108751021, 37.670268696331505],
              [126.920254171227896, 37.670502864010871],
              [126.920339228916689, 37.670948904410068],
              [126.920355603679738, 37.671034507459758],
              [126.920229280536404, 37.67107581277542],
              [126.920162845587654, 37.671097444356612],
              [126.920105767395611, 37.671466802219477],
              [126.920354966117884, 37.671620139012525],
              [126.920598548840445, 37.671634135636737],
              [126.921148356911573, 37.671665730092066],
              [126.921397792783807, 37.671602831702032],
              [126.921794465491359, 37.671648149845716],
              [126.921941990127806, 37.671486074087944],
              [126.923120698619485, 37.671594981535613],
              [126.923551470377745, 37.671550218616247],
              [126.92433374080035, 37.671388557188429],
              [126.925104731908419, 37.67117282614965],
              [126.925478953291631, 37.671001881996084],
              [126.925773768620545, 37.670893956762917],
              [126.926132426672112, 37.67078730856187],
              [126.926227293453309, 37.670759100631805],
              [126.926782612852165, 37.670840540016698],
              [126.92741752707812, 37.670669757653926],
              [126.928346372575305, 37.671282998316435],
              [126.928640840377057, 37.671526442823925],
              [126.928708734458212, 37.671643610075748],
              [126.928720025487081, 37.671688666317394],
              [126.929139206863013, 37.67190515815112],
              [126.929241175530152, 37.671950269231239],
              [126.929444056565742, 37.671811212273987],
              [126.929490708898754, 37.671779237068577],
              [126.929524732120555, 37.671761239859933],
              [126.930114066027514, 37.671842683886119],
              [126.930442521166512, 37.672113175659199],
              [126.930691968417804, 37.672032238281304],
              [126.931599029169149, 37.671744467161439],
              [126.932132020658642, 37.671474489787116],
              [126.932778113880715, 37.671465858963032],
              [126.932891462414858, 37.671465924521847],
              [126.932993566937228, 37.671366878041752],
              [126.933231616421082, 37.671348996045509],
              [126.933854889875576, 37.671502521358398],
              [126.934127016442275, 37.671403568831785],
              [126.934580507156838, 37.671295710348048],
              [126.934875397874578, 37.671088653424775],
              [126.936314943142676, 37.671053419410953],
              [126.936586844821818, 37.671206735883096],
              [126.936949667065434, 37.67108079880682],
              [126.9369950160591, 37.671071812714793],
              [126.937267312714454, 37.670765630888525],
              [126.937754805753698, 37.670648769383938],
              [126.938196891851007, 37.670612968769511],
              [126.938809307689112, 37.67020785780381],
              [126.93886187528399, 37.67024815127953],
              [126.939420987039938, 37.670676688196558],
              [126.939644165643301, 37.670730032148093],
              [126.938847055959911, 37.67011822081566],
              [126.938574385748893, 37.66990893149562],
              [126.938268640756775, 37.669674254907804],
              [126.93803607187381, 37.669575652923072],
              [126.937377546650708, 37.669297221285952],
              [126.93722492607094, 37.669232645676658],
              [126.936864816129756, 37.669080275943557],
              [126.936842828763233, 37.668981086892117],
              [126.936773647611957, 37.668669002838982],
              [126.936771230985869, 37.668658090617413],
              [126.936672755443553, 37.668268438766383],
              [126.936621541295239, 37.668065790634849],
              [126.936420534191853, 37.66768155877395],
              [126.936365850500934, 37.667581592363504],
              [126.936009931744962, 37.667116538953167],
              [126.935556731096199, 37.666524375775538],
              [126.935158708173105, 37.666059267872718],
              [126.935074465040529, 37.666004108616981],
              [126.934837539382968, 37.665916313435496],
              [126.934793260051777, 37.665899904360003],
              [126.934533424640364, 37.665825548479262],
              [126.934380094175623, 37.665781668429013],
              [126.933925497302056, 37.665934764204358],
              [126.933775948795855, 37.666081123612017],
              [126.933253198651855, 37.666279844793372],
              [126.933131113105944, 37.666524354049898],
              [126.932983293158117, 37.666610959861991],
              [126.932771801536887, 37.666609140787152],
              [126.932046871019978, 37.667064191227084],
              [126.932003815141414, 37.667091217573279],
              [126.931678577670567, 37.667074628443096],
              [126.931458523515388, 37.66701550968039],
              [126.931361560010146, 37.666966159535697],
              [126.931315316910315, 37.666942626395873],
              [126.931101510175679, 37.666713084813587],
              [126.930972022333918, 37.666552227187822],
              [126.93085988433738, 37.666395059357257],
              [126.930799795484589, 37.666316691699862],
              [126.930649498320491, 37.666195457601013],
              [126.93028488780115, 37.665919242100692],
              [126.929537184412823, 37.665858919249054],
              [126.928874404406557, 37.665652189786208],
              [126.928177148728096, 37.665701932327231],
              [126.928133134765361, 37.665705528860421],
              [126.928009478774527, 37.665649239700961],
              [126.928026989912681, 37.665564422052576],
              [126.928049191425487, 37.665456886592175],
              [126.928092921800783, 37.66524507790912],
              [126.928245570147325, 37.66466464762285],
              [126.928012866872308, 37.664271871678231],
              [126.927885391237567, 37.663954260165703],
              [126.927848775779097, 37.663793096895297],
              [126.927746357514209, 37.663329743594872],
              [126.927701561642095, 37.662763427795326],
              [126.927787132363406, 37.662370908910702],
              [126.927539032925282, 37.661569274145087],
              [126.927492836259177, 37.661311017054643],
              [126.927381833282368, 37.661166279783906],
              [126.927336894205439, 37.660757992341878],
              [126.925449942375181, 37.660098981306838],
              [126.924765249953609, 37.660564821942678],
              [126.924619899759932, 37.660663710939325],
              [126.924367970047285, 37.660834436688226],
              [126.924367613981133, 37.660866306894995],
              [126.924365755889838, 37.66103293759214],
              [126.924327505202839, 37.661144741216262],
              [126.924197855952201, 37.661590061403672],
              [126.923842526207352, 37.661780500731751],
              [126.923423445027623, 37.661874358092106],
              [126.92341600814521, 37.661878658038987],
              [126.923139501308938, 37.66203852882694],
              [126.923135879655831, 37.662040622931606],
              [126.922792575181859, 37.662118822956529],
              [126.921994855518562, 37.662274534970813],
              [126.921125435017558, 37.662713314738383],
              [126.920307812648858, 37.662580981452813],
              [126.920216048688829, 37.662566128083739],
              [126.92008448908544, 37.662544497484262],
              [126.919997579671644, 37.662527517239283],
              [126.919879338444147, 37.662538579143288],
              [126.919686357302211, 37.662561733228273],
              [126.919684239656235, 37.662561987800736],
              [126.919593407663683, 37.662536525372637],
              [126.919527658279478, 37.662519415456266],
              [126.919357400881921, 37.662471940849024],
              [126.918982355366794, 37.662380554120134],
              [126.918851649402043, 37.662366588557873],
              [126.918425346852004, 37.662148117766407],
              [126.918320401879868, 37.662115745482296],
              [126.918033725418439, 37.662053302362082],
              [126.917533589408109, 37.661947272701084],
              [126.916857925657339, 37.66179876788798],
              [126.916391202403673, 37.6616785241606],
              [126.916306683862658, 37.66165674894043],
              [126.916308561749901, 37.661563598571092],
              [126.916315345971682, 37.66122755322796],
              [126.916318460639033, 37.661073313216491],
              [126.916268244426519, 37.660936950361396],
              [126.91619335515567, 37.660733575860334],
              [126.916172711834264, 37.660677507397388],
              [126.915837080939824, 37.660644299142042],
              [126.915820110572824, 37.660628402161684],
              [126.915548829679949, 37.660374309135506],
              [126.915331885731476, 37.66018227190569],
              [126.915342440921563, 37.660174046407782],
              [126.915672587144186, 37.659916733787753],
              [126.915751644973426, 37.659855119285517],
              [126.915787630442125, 37.659720703681081],
              [126.91584571659584, 37.659503735614202],
              [126.915959498736711, 37.659157977297212],
              [126.915969581930398, 37.65912733782563],
              [126.916038859789097, 37.658916828101319],
              [126.915945126148983, 37.658525423688644],
              [126.915917564223278, 37.658410337843058],
              [126.915901673390721, 37.658343970809923],
              [126.91587767856781, 37.658335788688952],
              [126.915874825793608, 37.658334814645187],
              [126.915877559383034, 37.658331321105109],
              [126.915711624170726, 37.658244661080083],
              [126.915154553215416, 37.657953727923562],
              [126.915071955142835, 37.657972861014727],
              [126.914570641420553, 37.658088985529211],
              [126.913873641329218, 37.658254239767842],
              [126.913820980431183, 37.658180874785991],
              [126.913737767061662, 37.658064943130157],
              [126.913597359532844, 37.657869327101999],
              [126.913320751030071, 37.657868904389026],
              [126.913153964959065, 37.657812785332297],
              [126.912907084841436, 37.657723285947768],
              [126.912625289980753, 37.657746480392689],
              [126.912316509123229, 37.657763754079546],
              [126.912263171929979, 37.657749379810696],
              [126.912020468396619, 37.657600534159258],
              [126.911921939571869, 37.657469665546984],
              [126.911652322917092, 37.65714098310589],
              [126.911388150591918, 37.656819746958057],
              [126.91126142991844, 37.656820226801948],
              [126.911201935152377, 37.656818217319177],
              [126.910576326190522, 37.656801751421511],
              [126.91053787734009, 37.656810102102824],
              [126.910243999346676, 37.656921081363436],
              [126.909802244085199, 37.657079267260528],
              [126.909751061521973, 37.657097594791864],
              [126.909743521373642, 37.65710029350091],
              [126.909154821580714, 37.656986003992913],
              [126.90877559432387, 37.657041876446037],
              [126.908530849881899, 37.657145753635071],
              [126.908270049884351, 37.657252958603145],
              [126.908261355412222, 37.657256531124943],
              [126.908050184526616, 37.657266312802108],
              [126.907728999368231, 37.657306443879285],
              [126.907638549025336, 37.657317744843049],
              [126.907418009930083, 37.657129690430658],
              [126.907365678695598, 37.657085068557265],
              [126.9072734859554, 37.657050462046747],
              [126.907026324096165, 37.656957686143052],
              [126.907019738080905, 37.65691139496252],
              [126.907011029426798, 37.656850179699624],
              [126.906836640843196, 37.656811177538572],
              [126.906689135831087, 37.65677818701527],
              [126.906451415298818, 37.656669688622067],
              [126.906403959642546, 37.656648025204035],
              [126.906285765919051, 37.656658463554635],
              [126.905986635501307, 37.656693733669769],
              [126.905936663518361, 37.656699626381801],
              [126.905909074612055, 37.656656961772555],
              [126.905663534397249, 37.656277216628567],
              [126.905092535232143, 37.655391407677875],
              [126.904984244138348, 37.655301792013958],
              [126.904912658369554, 37.655242175634839],
              [126.904750262931543, 37.655106944468464],
              [126.904765364073214, 37.655083308468129],
              [126.904789201691898, 37.655045996496213],
              [126.904539943591388, 37.654815468889701],
              [126.904513272424097, 37.654790801839184],
              [126.904501741991425, 37.654763664057612],
              [126.904467567616081, 37.654682858504529],
              [126.904721693153988, 37.654658905738032],
              [126.904473374932152, 37.654206127222992],
              [126.904333038116377, 37.654285312597935],
              [126.904312376450349, 37.654249760733116],
              [126.904276455874808, 37.654259868924974],
              [126.904222858602054, 37.654169018121834],
              [126.904197514570569, 37.654112802117197],
              [126.904176630539027, 37.65407104254232],
              [126.904169571849479, 37.654058334180682],
              [126.904166654249082, 37.654053737079003],
              [126.904148799502124, 37.654025584865472],
              [126.904114375260519, 37.653971028293213],
              [126.904060424007469, 37.653883950397777],
              [126.904043763227662, 37.65385847574364],
              [126.904031490234246, 37.653806839600229],
              [126.904000263057611, 37.653673712126363],
              [126.903997445707304, 37.653659554211238],
              [126.903995138430346, 37.653647958652201],
              [126.903991124489281, 37.653626214500257],
              [126.903912120373249, 37.653638048163778],
              [126.903892178088682, 37.653641035348812],
              [126.903884806135792, 37.653642140081402],
              [126.903878322904987, 37.653643111401095],
              [126.903873755731709, 37.653644344775842],
              [126.903891776988473, 37.653625125737193],
              [126.903900391408214, 37.653615940544064],
              [126.903897434801294, 37.653609703717926],
              [126.903891035537697, 37.653595634056614],
              [126.903874446856321, 37.65355917071863],
              [126.903864277573447, 37.653536811393707],
              [126.903866009400048, 37.653520270813232],
              [126.903848495890813, 37.653522776335798],
              [126.903436382592886, 37.653581752653345],
              [126.903421134404013, 37.653607884431267],
              [126.903384341680038, 37.653615795005017],
              [126.903373136583681, 37.653618204373508],
              [126.903364712440364, 37.653619611105619],
              [126.903216192823336, 37.653644400831439],
              [126.903214419853782, 37.653645666343301],
              [126.903214303892725, 37.65364471575419],
              [126.903068952629681, 37.65366697236017],
              [126.903075247578158, 37.65369617848382],
              [126.903063537947659, 37.653698025543406],
              [126.903068688648801, 37.653720482121322],
              [126.903025712487448, 37.653726737995825],
              [126.903021922771302, 37.65372729026074],
              [126.903018915796693, 37.653705546666288],
              [126.903015503055855, 37.653706165734576],
              [126.903009875394531, 37.653676734046911],
              [126.902937277301064, 37.65368872025465],
              [126.902886334467055, 37.653701968837908],
              [126.902792891697459, 37.653726270678227],
              [126.902793435844046, 37.653708720019729],
              [126.90271187546908, 37.653720076652746],
              [126.902639801963247, 37.653727560482892],
              [126.902406749484783, 37.653751759746342],
              [126.902336977432341, 37.653571671030505],
              [126.902270934217924, 37.653401210249285],
              [126.902207917903141, 37.653238558572539],
              [126.902194786482767, 37.653202679456292],
              [126.902136594420782, 37.653050743769434],
              [126.902135467557329, 37.653047776285668],
              [126.902053008164302, 37.652830610838599],
              [126.901957982094089, 37.652590492325089],
              [126.901887108893817, 37.652397714206636],
              [126.901852452064617, 37.652306875422383],
              [126.90169522800079, 37.651887041300895],
              [126.901655891104355, 37.65178868505123],
              [126.901591673553853, 37.651619814945334],
              [126.90146923158089, 37.651291404957661],
              [126.901435399933703, 37.651203810508967],
              [126.901408494849974, 37.651133015051194],
              [126.901352427842696, 37.65098548731261],
              [126.901348026177828, 37.650973320441913],
              [126.901290243396431, 37.650821238551366],
              [126.901295031339103, 37.650820024422693],
              [126.901827849479275, 37.650649292388756],
              [126.902508154111445, 37.650343532128694],
              [126.902689590728968, 37.650244585270393],
              [126.902859696667051, 37.650145613222797],
              [126.902940189605303, 37.650081746722478],
              [126.902989801835091, 37.650042383406998],
              [126.90304788940017, 37.649996293961557],
              [126.903128928467055, 37.649931995651691],
              [126.903154627213851, 37.649911604167329],
              [126.903172111234525, 37.649898656672391],
              [126.903159768493296, 37.649892008199224],
              [126.902740607679817, 37.649845698092371],
              [126.902706459978333, 37.649841760339129],
              [126.902510918743445, 37.649819188632222],
              [126.902383621220366, 37.649818587079238],
              [126.901939786991363, 37.649945628337022],
              [126.901562423987926, 37.650046488880797],
              [126.90151820564374, 37.650056776779948],
              [126.901042367506932, 37.650167498620029],
              [126.901041914477858, 37.650167588255599],
              [126.901083400340468, 37.650276795048534],
              [126.901091111387771, 37.650297091612579],
              [126.901167872389493, 37.650499136991932],
              [126.901221519253454, 37.650640347733123],
              [126.901236010992406, 37.650678516946002],
              [126.900866922258501, 37.650760171113816],
              [126.900720378786289, 37.650792592121],
              [126.900478079585795, 37.650815720334045],
              [126.90023835996459, 37.650852005272718],
              [126.90000236962274, 37.650901264421577],
              [126.899771116091785, 37.650963233430787],
              [126.899545740022688, 37.651037729755323],
              [126.899369097280896, 37.651106952233981],
              [126.899116920188447, 37.65122241736502],
              [126.898847534912235, 37.651326787208347],
              [126.898554364805094, 37.651421673945926],
              [126.898134952177429, 37.651523431216006],
              [126.897983050124324, 37.651560285759849],
              [126.897595648574082, 37.651615444329373],
              [126.897072308071444, 37.651646246075643],
              [126.896724569584052, 37.651619899771958],
              [126.896709369639623, 37.651617408274525],
              [126.896382313872365, 37.651563825771618],
              [126.896157201232668, 37.651503650935581],
              [126.896032632507627, 37.651470352605848],
              [126.895718681649385, 37.651352673236808],
              [126.895532745357855, 37.651264881020225],
              [126.895450642931635, 37.651226115880327],
              [126.895033628965194, 37.651043133239476],
              [126.894978855859648, 37.651019098663241],
              [126.894413383751029, 37.650796856493542],
              [126.894299837490749, 37.650758177896996],
              [126.894264852283001, 37.650746261130486],
              [126.894210311928191, 37.650727682246263],
              [126.893852429743475, 37.650605772202155],
              [126.893688851208978, 37.650550049542588],
              [126.893128310614998, 37.650389277491321],
              [126.893020448902064, 37.650358340496908],
              [126.892365382326091, 37.650198639521186],
              [126.892133837313793, 37.650142188744248],
              [126.891480657320045, 37.649984173565784],
              [126.891205886163036, 37.64991770142089],
              [126.890874027748495, 37.64981314829334],
              [126.890736004304216, 37.649763976379802],
              [126.890686905876848, 37.649746485742398],
              [126.890548189803368, 37.64969706667263],
              [126.890229170553766, 37.649569727528323],
              [126.889821402329687, 37.649385274463818],
              [126.889688142664909, 37.649319056565318],
              [126.889361799580598, 37.649156892131238],
              [126.888874846903391, 37.648927176570744],
              [126.88883379177885, 37.648907808798889],
              [126.88871898229911, 37.648856746628077],
              [126.888297839380016, 37.648669437489801],
              [126.888103933264645, 37.64859066504679],
              [126.887960600326409, 37.648532436423714],
              [126.88758630212017, 37.648396298373086],
              [126.887411495585596, 37.648339243263898],
              [126.887266242907458, 37.648291836022374],
              [126.886910369378441, 37.648188599967135],
              [126.8868785449272, 37.64818051727957],
              [126.88682764934974, 37.648167590852545],
              [126.886549613083346, 37.648096979037099],
              [126.88629610817523, 37.648035285768778],
              [126.886193616714365, 37.648004633667568],
              [126.88604796312228, 37.647961073327274],
              [126.885806061899402, 37.647874793408647],
              [126.885571431655478, 37.647776716392244],
              [126.885344977464712, 37.647667205766503],
              [126.885120992419559, 37.647542347097065],
              [126.885004296917543, 37.647465563728638],
              [126.884898606655995, 37.647396021233803],
              [126.884791349467861, 37.647313763511143],
              [126.884232643303434, 37.646885281594209],
              [126.883829102436778, 37.646575793680377],
              [126.88362749337432, 37.646430685274481],
              [126.883488968035905, 37.64633097997006],
              [126.883163483604449, 37.646096709935819],
              [126.883133222740597, 37.646078315978905],
              [126.883073387919211, 37.646041947147459],
              [126.88253647307927, 37.645715597286667],
              [126.882500044587715, 37.645696835590414],
              [126.881874043607795, 37.645374448218313],
              [126.881862909622441, 37.645369711972101],
              [126.8812727878571, 37.645118678496836],
              [126.881117559229153, 37.645043234960987],
              [126.880690956739215, 37.644835899257515],
              [126.880194771103746, 37.644564828378336],
              [126.880157543411357, 37.644538438302568],
              [126.879742286737383, 37.644244065868655],
              [126.879444533428696, 37.644446564956908],
              [126.879566874977925, 37.644533815525357],
              [126.879766816773582, 37.644701333699892],
              [126.879694020723605, 37.644802618383416],
              [126.879759680633015, 37.644838544551384],
              [126.880035112091477, 37.644990102680239],
              [126.880053340092331, 37.644998861162371],
              [126.880104622202367, 37.645026933996768],
              [126.880240018810923, 37.645101133591737],
              [126.880987079921056, 37.645509139674175],
              [126.881661123663477, 37.645881659605948],
              [126.881939957336485, 37.646036009022573],
              [126.88222342819796, 37.646196400250062],
              [126.882345576860871, 37.646266889379774],
              [126.882391521897276, 37.646303425616175],
              [126.882461238884375, 37.646354851877867],
              [126.882533225862147, 37.646404297137146],
              [126.882607368682187, 37.646451583230359],
              [126.882683667349781, 37.646496709252119],
              [126.882762009653007, 37.646539673709896],
              [126.883022378793441, 37.646693372342412],
              [126.883280821851756, 37.646849140602463],
              [126.883329271625428, 37.646878560040534],
              [126.88345650743193, 37.646957432652279],
              [126.883581816320429, 37.647038104930324],
              [126.883705194885621, 37.647120577764341],
              [126.883826758374084, 37.647204759826487],
              [126.883946392677188, 37.647290742462133],
              [126.884621182679808, 37.647784697382086],
              [126.88488965211809, 37.647981195075992],
              [126.884912685973561, 37.647961306536409],
              [126.88491698677467, 37.647964464690212],
              [126.885110416470198, 37.648106052714233],
              [126.885451779686719, 37.648355925491963],
              [126.885496258464698, 37.648390115727466],
              [126.885538581867905, 37.648426015359668],
              [126.88557852316012, 37.648463626815264],
              [126.885616195691071, 37.648502675781124],
              [126.885651259377184, 37.648543163192294],
              [126.885683829438022, 37.648585000419331],
              [126.885713789859452, 37.648628096727101],
              [126.88574091654219, 37.648672271585852],
              [126.885765322850716, 37.648717523785145],
              [126.885775037449761, 37.648736544672403],
              [126.885794347383495, 37.648780260601043],
              [126.885811048319994, 37.648824604690148],
              [126.885825255875872, 37.648869577542776],
              [126.885826157257313, 37.648873272283119],
              [126.885838318255665, 37.648922567175049],
              [126.885847193452065, 37.648972219912672],
              [126.885853008429351, 37.649022229868422],
              [126.885855650170754, 37.649072417089577],
              [126.885855119028179, 37.649122600410706],
              [126.885851301234808, 37.649172690909054],
              [126.88584442538324, 37.649222597838687],
              [126.885834376182004, 37.649272142135352],
              [126.885821155906854, 37.649321322007395],
              [126.885804762971802, 37.649369778715965],
              [126.885785425620014, 37.649417601778467],
              [126.885496814314536, 37.650072507051213],
              [126.885470426089029, 37.650136360441572],
              [126.885447321572556, 37.650200938476267],
              [126.885427276335648, 37.650266239086719],
              [126.885410630805708, 37.650332083781969],
              [126.885397154871043, 37.650398472265962],
              [126.885397031473758, 37.65039927741239],
              [126.885386965265312, 37.650465133847526],
              [126.885380176472253, 37.650532070926666],
              [126.885376559152505, 37.650599189472075],
              [126.885361196174884, 37.651149940303803],
              [126.885359064599655, 37.651209402571837],
              [126.885355793476108, 37.651272196527522],
              [126.885344814469065, 37.651409855367646],
              [126.885342853506046, 37.651427159703175],
              [126.885342713250992, 37.651428634449907],
              [126.885342393175463, 37.651431983026477],
              [126.885339979768816, 37.651455216658427],
              [126.885336302674077, 37.6514851523063],
              [126.885336038947628, 37.65148730330862],
              [126.885333930105631, 37.651504531161478],
              [126.885330740604061, 37.651530599037763],
              [126.885329418160651, 37.651541403602508],
              [126.885328750482756, 37.651546763939393],
              [126.885322284205145, 37.651589957139677],
              [126.885319911560899, 37.651607418864558],
              [126.88530172518378, 37.651715876698354],
              [126.885279449157863, 37.651828118631627],
              [126.885253666375618, 37.651939991666239],
              [126.885224483266427, 37.652051234968788],
              [126.885191904394802, 37.652161843152541],
              [126.885155922209307, 37.652271905406998],
              [126.885150122873071, 37.652287948598897],
              [126.885116428929834, 37.652381155274867],
              [126.885073765805686, 37.652489588222153],
              [126.885027703793952, 37.652597208459397],
              [126.885007168073159, 37.652641580232945],
              [126.884978356594161, 37.652703836309328],
              [126.884955342866846, 37.65275175720943],
              [126.884811049597218, 37.653052216647517],
              [126.884732050941778, 37.653216709252277],
              [126.884682557782412, 37.653319766035445],
              [126.884637198585679, 37.653414209620692],
              [126.884612383828795, 37.653465880498189],
              [126.884585787143351, 37.653517387946117],
              [126.884556242761249, 37.653567814636688],
              [126.884523756390493, 37.65361715518948],
              [126.88448832570937, 37.653665140993674],
              [126.884450061793146, 37.653711774428928],
              [126.884409082918637, 37.653756872238432],
              [126.884365499043895, 37.653800434993258],
              [126.884319422734691, 37.653842284814736],
              [126.884270747041029, 37.653882331008788],
              [126.884056869043562, 37.654050599531026],
              [126.884018655492937, 37.654080649518782],
              [126.883976766495152, 37.654113589792317],
              [126.884022957086884, 37.654124782156956],
              [126.884151416470985, 37.654155908811688],
              [126.884177267888376, 37.654162172814601],
              [126.884051124388421, 37.65438990034486],
              [126.884048806298495, 37.654390005413575],
              [126.883992871549793, 37.654392547110717],
              [126.883918979574531, 37.65439589812943],
              [126.883951412054387, 37.654670277232817],
              [126.883952365235558, 37.65485650772878],
              [126.883957294432747, 37.654892462272365],
              [126.883974534802363, 37.654954284372508],
              [126.883948802010664, 37.655032014047151],
              [126.883837848307806, 37.655108755998668],
              [126.883766068286945, 37.655208875678987],
              [126.883544231043544, 37.655320552222072],
              [126.883491487679194, 37.655353027286068],
              [126.883478518659373, 37.655509015695628],
              [126.883473938780597, 37.655564106254701],
              [126.883473318983192, 37.655704874456077],
              [126.883481205837626, 37.655790457250994],
              [126.883425680002773, 37.656325660356629],
              [126.8838812783723, 37.657031705998897],
              [126.884012038015072, 37.657976909821969],
              [126.884323049631277, 37.65803934081697],
              [126.884421602045023, 37.65805912558443],
              [126.884765574742062, 37.658130679422612],
              [126.884962232293645, 37.658146059538034],
              [126.885041053732849, 37.658152223675103],
              [126.885230960837447, 37.658171358239066],
              [126.885901020228985, 37.657962675639162],
              [126.885856722865469, 37.657704261053937],
              [126.885849345421107, 37.657661222026469],
              [126.885792973569465, 37.657349503016341],
              [126.885806155331977, 37.657303943658313],
              [126.885932288991981, 37.656850525938466],
              [126.886351498169276, 37.656561083260428],
              [126.886874438401932, 37.656576630263231],
              [126.886912105356586, 37.656580533209521],
              [126.887188447279257, 37.656609162270051],
              [126.887286307523382, 37.656621988192924],
              [126.887794750337093, 37.656654824432586],
              [126.88794894823701, 37.656666235065416],
              [126.88863471850263, 37.656716978608664],
              [126.888931769983472, 37.656626773275406],
              [126.889073013699559, 37.656583881429718],
              [126.889183754907734, 37.656616970385414],
              [126.889313986788025, 37.656655882685364],
              [126.889502686076924, 37.656712264176996],
              [126.889953081083618, 37.656846837410079],
              [126.889952211623353, 37.656989956837727],
              [126.889915407720252, 37.657129509526918],
              [126.890539166895877, 37.657404562338122],
              [126.890786810026867, 37.65751877817388],
              [126.891024249194885, 37.65762828814843],
              [126.891122109869855, 37.657680455731317],
              [126.891132970292745, 37.657685529491559],
              [126.891344519668223, 37.657784347910869],
              [126.891585175322106, 37.657927529898288],
              [126.891693013222067, 37.657991689281644],
              [126.891700419061593, 37.657996093513162],
              [126.891724536710967, 37.658032929033013],
              [126.89174718524032, 37.658067520773798],
              [126.891829311246994, 37.65819719307224],
              [126.891963243966828, 37.658408668434525],
              [126.892096961860261, 37.658610431609809],
              [126.89214184973001, 37.658678161690695],
              [126.892459248237842, 37.658602330325671],
              [126.892620242430979, 37.658563865962392],
              [126.892660713583936, 37.658587912708157],
              [126.893241427363506, 37.658932944962501],
              [126.89356578309976, 37.658997808779738],
              [126.893585271006202, 37.659001707170525],
              [126.893597784712654, 37.659032018314754],
              [126.893648540731277, 37.659042110034314],
              [126.893702675647873, 37.659044214393212],
              [126.893792489714684, 37.659082533640301],
              [126.893797064518751, 37.659179976437443],
              [126.893802034426955, 37.659285856804892],
              [126.893813793707665, 37.659469620778786],
              [126.893819943209323, 37.659566660655173],
              [126.893832884840876, 37.659738740453882],
              [126.893820215632942, 37.659847440555779],
              [126.893808467011112, 37.659963249662546],
              [126.893802802019195, 37.660017997933906],
              [126.893798843897699, 37.660138067096334],
              [126.893798903979942, 37.660169020853857],
              [126.893799245629609, 37.660336704095862],
              [126.893808894786957, 37.660568330391996],
              [126.893821592173069, 37.660873082755181],
              [126.89383583125506, 37.660901657759709],
              [126.893893346973599, 37.660901709957251],
              [126.893893741523669, 37.66090171018196],
              [126.893893961148123, 37.660907746610341],
              [126.893897597199654, 37.661026549656064],
              [126.893904424317299, 37.661226993458456],
              [126.89390452320805, 37.661229890838243],
              [126.893904281745023, 37.661232397095013],
              [126.893904264441787, 37.661232712471296],
              [126.893904795361252, 37.661237694141192],
              [126.893906495183884, 37.661287580623885],
              [126.893919800180711, 37.661678166020927],
              [126.893874102467905, 37.661960470803194],
              [126.89377867751692, 37.662069059237076],
              [126.893538625087658, 37.662342222216218],
              [126.893427262582833, 37.662467805888845],
              [126.893454092911014, 37.662482825701439],
              [126.893454503731959, 37.662483056751526],
              [126.893654880510269, 37.662595231635997],
              [126.894352961597733, 37.662986133533813],
              [126.894447508923633, 37.663039077203969],
              [126.89526714490394, 37.663498032393527],
              [126.89529114971387, 37.663511474397801],
              [126.895378112027799, 37.663560226914946],
              [126.895456954753371, 37.663603586563688],
              [126.895488799772934, 37.663621103058794],
              [126.895512470627523, 37.66363351218633],
              [126.895551077228987, 37.663653746572372],
              [126.895613632101984, 37.663682386870825],
              [126.895621432516947, 37.663685489555142],
              [126.895682490931947, 37.663709779547268],
              [126.895744421382872, 37.66373070120288],
              [126.895742273909832, 37.663735880052684],
              [126.895740276814351, 37.663740691029346],
              [126.895732616841926, 37.663759144350067],
              [126.895678722919016, 37.663888970539453],
              [126.895622474051919, 37.664024507170261],
              [126.895605596408529, 37.664065153536725],
              [126.895581426640192, 37.664118028716103],
              [126.895565574046628, 37.664147194696966],
              [126.895553357397773, 37.664169674323865],
              [126.89553742094472, 37.664194777582424],
              [126.895521473055396, 37.664219891596019],
              [126.89550367892825, 37.664244211989455],
              [126.895485884877104, 37.664268521564516],
              [126.895473738295323, 37.664283055427973],
              [126.895446728193193, 37.664315373838015],
              [126.895422833684052, 37.664340578701243],
              [126.895404140244437, 37.664360302199825],
              [126.895358257314626, 37.664403138796459],
              [126.895317589315212, 37.664436825682458],
              [126.895309259803625, 37.664443728619091],
              [126.895283527204214, 37.664462653486673],
              [126.895257320095908, 37.664481926532481],
              [126.895238702421807, 37.66449481742012],
              [126.89522196833812, 37.664506411937417],
              [126.895011109085843, 37.664652481507154],
              [126.894842489285111, 37.664769288561935],
              [126.894839175754029, 37.664771582568001],
              [126.894865163725868, 37.664794689863847],
              [126.894771271682146, 37.664861114643685],
              [126.894750844949897, 37.66487866505215],
              [126.894685060685717, 37.664912660877398],
              [126.894627556055298, 37.664937386633895],
              [126.894537401763571, 37.664976046956077],
              [126.894513732517211, 37.66498392564705],
              [126.894384566167773, 37.665028188334013],
              [126.894268205702829, 37.665068061376829],
              [126.894172841058065, 37.66510058906119],
              [126.894126100680765, 37.665131179822623],
              [126.894067542554581, 37.665184195297734],
              [126.894030045832054, 37.665248307944744],
              [126.893981796266687, 37.665384312975199],
              [126.893943945777053, 37.665491214342111],
              [126.893907138511423, 37.665595162895393],
              [126.893901090494637, 37.665607725177772],
              [126.893822107124919, 37.665771674717547],
              [126.893851421482708, 37.665800261378287],
              [126.893859017829868, 37.665807701223393],
              [126.893866248604255, 37.665814781361988],
              [126.893831405819668, 37.665847094289319],
              [126.893738229957634, 37.66593350435749],
              [126.893662697886569, 37.666043444095543],
              [126.893564035288293, 37.666161019887966],
              [126.893378465468032, 37.666338432195815],
              [126.893091771275962, 37.666612694398715],
              [126.893100819448293, 37.666625227055938],
              [126.893111252249497, 37.666621723194389],
              [126.893138239376498, 37.666612646869915],
              [126.893167062858424, 37.666665740989842],
              [126.893052644537988, 37.666705099131484],
              [126.893001732613087, 37.666721179116642],
              [126.892988236460752, 37.6667260318141],
              [126.892895047236195, 37.66682189966528],
              [126.892916773173397, 37.666844444648369],
              [126.893001423189318, 37.666932365930819],
              [126.892967154500781, 37.666960625571306],
              [126.8929606853238, 37.666966115615921],
              [126.892835534321208, 37.666903742178427],
              [126.89267492701407, 37.667138118692854],
              [126.892557982708865, 37.667280828550957],
              [126.892272185818868, 37.667629588078952],
              [126.892225856087251, 37.667694486529371],
              [126.892169284853935, 37.667773738108167],
              [126.892139870321927, 37.667814974600596],
              [126.892117607720962, 37.667846126933298],
              [126.892042219376449, 37.668011026599785],
              [126.891970614245238, 37.668224039672928],
              [126.891991332932193, 37.668316137809569],
              [126.892013591035081, 37.668364091427357],
              [126.89205073557882, 37.668462962074841],
              [126.892080982640636, 37.668554622588623],
              [126.892095183475035, 37.668605355816879],
              [126.892097430975227, 37.668618962129138],
              [126.892119864812642, 37.668655815876562],
              [126.892132823818045, 37.668677109837617],
              [126.89219403191801, 37.668751947061644],
              [126.892196725194452, 37.668754553159673],
              [126.892220221212028, 37.6687772696256],
              [126.892302930839008, 37.668930609676799],
              [126.89230784830022, 37.668935643691889],
              [126.892310615791033, 37.668938466488306],
              [126.89234398430888, 37.668985706647973],
              [126.892374226948064, 37.669153495008175],
              [126.892384091905384, 37.669210507042983],
              [126.892384245614934, 37.669211402853342],
              [126.892404799517792, 37.669330204595745],
              [126.892392986068145, 37.669422890367237],
              [126.892400979326311, 37.669460670138712],
              [126.892399158529798, 37.66954408585395],
              [126.892365018954365, 37.669646310724602],
              [126.892342341910634, 37.6697189166211],
              [126.892313086738184, 37.669805743117834],
              [126.892311309038561, 37.669805571719614],
              [126.892292234672595, 37.669803741089559],
              [126.89228330632956, 37.669834745825995],
              [126.892281299140308, 37.669841719872259],
              [126.892280044662442, 37.669846076735929],
              [126.892259332578789, 37.669894761586399],
              [126.892207933792704, 37.670015572079024],
              [126.892029721141014, 37.670501301102803],
              [126.892010200671706, 37.670548986163766],
              [126.891963878759924, 37.670662146097399],
              [126.892245325171501, 37.670759197807158],
              [126.892383079232914, 37.670803164972774],
              [126.893106628309511, 37.670969811551181],
              [126.893498553950835, 37.670990442711343],
              [126.894057222322758, 37.671004917006542],
              [126.894984761625324, 37.671153517902823],
              [126.895851166277467, 37.671409722445389],
              [126.896554794218105, 37.671630603120491],
              [126.896745190388543, 37.671715661962047],
              [126.896864531506623, 37.671780785730711],
              [126.896932593638013, 37.671817925958379],
              [126.897220125966143, 37.67198934834807],
              [126.897474954157104, 37.672185072209061],
              [126.897519068364886, 37.672218954036225],
              [126.897544888100583, 37.672228787816863],
              [126.897833479974892, 37.672142050470065],
              [126.89795464663699, 37.672105634383371],
              [126.89802279171694, 37.672089658269016],
              [126.898075954402856, 37.672078688239601],
              [126.89811217726016, 37.672071213615858],
              [126.898116567100402, 37.672076925197992],
              [126.898151829449077, 37.672122808761806],
              [126.898204183341647, 37.672213672849814],
              [126.898274287628368, 37.672338158663528],
              [126.898322921382601, 37.672414693339057],
              [126.89838432884477, 37.672516826860779],
              [126.89847016423586, 37.672659525703445],
              [126.898488939307356, 37.672690536091878],
              [126.898672066227348, 37.672970897661308],
              [126.898771038605517, 37.673121725630523],
              [126.898778486718925, 37.673133073455595],
              [126.89879435078295, 37.673157245004226],
              [126.898888906926459, 37.67333266651405],
              [126.899002486833098, 37.673546050552609],
              [126.899161641115313, 37.673844698786802],
              [126.899276402374497, 37.674056616699346],
              [126.899283886731126, 37.674070382035865],
              [126.899349877297425, 37.674213549674711],
              [126.899557502346752, 37.674662825245598],
              [126.899760234218547, 37.675102134784602],
              [126.899868719741519, 37.675339588998725],
              [126.899992003389002, 37.675522269929118],
              [126.900188626911984, 37.67573997706446],
              [126.900197017712614, 37.675755004301671],
              [126.900561929313241, 37.676466993563785],
              [126.90091913501486, 37.677070148722287],
              [126.901075412203298, 37.677262620445902],
              [126.901387559159843, 37.677612193125505],
              [126.901689420542695, 37.677813284079818],
              [126.90210257175805, 37.678037677187191],
              [126.902333170249292, 37.678095624199997],
              [126.902410078580246, 37.678091455011277],
              [126.902729850066137, 37.678074124474449],
              [126.903056430902794, 37.678024711455258],
              [126.903798396608906, 37.677851836984331],
              [126.904210975187937, 37.677754864312632],
              [126.904274167924328, 37.677731860791951],
              [126.904342159341425, 37.677706364067298],
              [126.904489268642891, 37.677625633316659],
              [126.904715333416974, 37.677603168833038],
              [126.904887320515186, 37.677523690709698],
              [126.905316680453666, 37.677244874964479],
              [126.905534540368706, 37.677036080333018],
              [126.905573339152184, 37.676950285673129],
              [126.905604595932274, 37.676735231508204],
              [126.905598617148414, 37.67647400845766],
              [126.905546893108195, 37.676404610551558],
              [126.905817063879198, 37.676052178498736],
              [126.905833836115463, 37.676028812262309],
              [126.905851581763599, 37.67602472941774],
              [126.906049266170839, 37.675906690440172],
              [126.906150684933579, 37.675765319600998],
              [126.906231421391865, 37.675654700588908],
              [126.906265762000245, 37.675606336991038],
              [126.906290042683992, 37.67548143789751],
              [126.90630254999823, 37.675451345146556],
              [126.906304034503705, 37.675447773448127],
              [126.906589574077842, 37.675448003602639],
              [126.90677094016479, 37.675448149874427],
              [126.906850311713086, 37.675430194449277],
              [126.906866653652813, 37.675402845635702],
              [126.906952547814129, 37.675259092217999],
              [126.907032043332634, 37.675142029121282],
              [126.907055562225764, 37.675107972630613]
            ]
          ]
        ]
      },
      id: '31101512'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '흥도동',
        ADM_CD: '31101511'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.842773929018847, 37.644254282271298],
              [126.842923684141539, 37.643937113032024],
              [126.842922361488078, 37.643791946558416],
              [126.843085347386477, 37.643618177487454],
              [126.843308743119863, 37.643376611166424],
              [126.843456731294893, 37.643168296643402],
              [126.843496946707603, 37.643113552858949],
              [126.843546779416627, 37.643112817967143],
              [126.843706836791569, 37.643111689508814],
              [126.843786230767293, 37.643111119865004],
              [126.843926617744813, 37.643110343172879],
              [126.844389506148659, 37.643125286333408],
              [126.844643216114406, 37.643201865088685],
              [126.84475971077984, 37.643237031297623],
              [126.844940409388016, 37.643318386521038],
              [126.844957060623102, 37.643325859064916],
              [126.84511140828883, 37.643395177370195],
              [126.845259327137711, 37.64346086619328],
              [126.845320853089888, 37.64349011508547],
              [126.845442915178978, 37.643548143097064],
              [126.845444759711967, 37.643549010495683],
              [126.84553848474151, 37.643593174661419],
              [126.845769743220529, 37.643728374206745],
              [126.845898590674864, 37.643860015811661],
              [126.846157624977536, 37.644124665224069],
              [126.846173185500689, 37.644215225122181],
              [126.84624490905172, 37.644395586568827],
              [126.846242905703804, 37.644595320900301],
              [126.846302876849364, 37.644776135438235],
              [126.84646011161955, 37.644925111248966],
              [126.846558246976414, 37.645088605308771],
              [126.846632313377143, 37.645258960299593],
              [126.846682528816274, 37.645364940905026],
              [126.846712209162945, 37.645427583620354],
              [126.846840732965916, 37.645581819612381],
              [126.846902998160871, 37.645755663025419],
              [126.846931613484415, 37.645939463684883],
              [126.846933531531178, 37.645986965344306],
              [126.847036314499007, 37.646085594413982],
              [126.847053892181435, 37.646094546364175],
              [126.847155623286298, 37.646115887731227],
              [126.847240157707574, 37.646135000411505],
              [126.847255244740637, 37.646138416105664],
              [126.847287659326213, 37.646193749598147],
              [126.847542560647113, 37.646628859475683],
              [126.847573667811773, 37.646681958471113],
              [126.847678162512551, 37.646742550435697],
              [126.847968038617083, 37.646910635695008],
              [126.848148328740152, 37.647015112752342],
              [126.848179283653849, 37.647034985047185],
              [126.848256259156784, 37.647084384061685],
              [126.848450832766943, 37.647209253725414],
              [126.848719853728227, 37.647638785870441],
              [126.848996675150971, 37.648218105439646],
              [126.849011265143687, 37.648248652678404],
              [126.849015642438175, 37.648257809099874],
              [126.849015762671385, 37.648258060332836],
              [126.84902748967211, 37.64828258711632],
              [126.849152759246778, 37.648761208031885],
              [126.849208591521204, 37.648974523527563],
              [126.849270869043139, 37.649212468205043],
              [126.849274221566773, 37.649225272950375],
              [126.849275498646065, 37.649230134552546],
              [126.849275578688932, 37.649230445051245],
              [126.849275786160689, 37.649230831964466],
              [126.849830967628435, 37.650277543635816],
              [126.849831102650867, 37.650277789541434],
              [126.851049536701836, 37.650857438236017],
              [126.851924558285717, 37.649606552410745],
              [126.852786742041857, 37.649775805454205],
              [126.85327295784252, 37.649728401413078],
              [126.853435691867858, 37.64963630334158],
              [126.85354494454522, 37.649486687495525],
              [126.853709164215786, 37.649222923728566],
              [126.854085964999896, 37.649814397206171],
              [126.855823747428289, 37.652542096630839],
              [126.856218544787524, 37.652550737850667],
              [126.857084428564065, 37.651932624871272],
              [126.857865021675792, 37.651757067793625],
              [126.859059438366685, 37.651759390376128],
              [126.859247352388863, 37.651759751456979],
              [126.859554092521861, 37.651760346418214],
              [126.859705041718954, 37.65176064488081],
              [126.859772734652083, 37.651716611020269],
              [126.859900873347016, 37.651633257465669],
              [126.85974999135945, 37.650813386478106],
              [126.860327025464272, 37.650495485059267],
              [126.860539941689225, 37.649922449395071],
              [126.860551365460026, 37.649891721909434],
              [126.860652510193091, 37.649830193777959],
              [126.860645528806472, 37.649826702947614],
              [126.860725850306807, 37.649758694000191],
              [126.861035522000336, 37.649462083088537],
              [126.861369655260333, 37.649292286527398],
              [126.861395515666686, 37.649279073327619],
              [126.861494861690687, 37.649240634897843],
              [126.861480256258744, 37.649186643722281],
              [126.861394223681557, 37.648905446330019],
              [126.861370406344804, 37.648850457972749],
              [126.86133750740693, 37.648809956185239],
              [126.861306324666458, 37.648773699929031],
              [126.861257908272378, 37.648717701368895],
              [126.86127160696941, 37.648676083640957],
              [126.861501809197748, 37.648697529531162],
              [126.861942587209185, 37.648709945314494],
              [126.862077071981517, 37.648713797543557],
              [126.862119783144422, 37.648717902416202],
              [126.863225510651915, 37.648766604622033],
              [126.863346417077409, 37.648765862842495],
              [126.863349816891969, 37.648765839729606],
              [126.863440952131185, 37.648763357148383],
              [126.863525802455101, 37.648761045189445],
              [126.863851302310479, 37.64877614781296],
              [126.864431306660563, 37.648802689752522],
              [126.864438468004366, 37.648815401931799],
              [126.864430987678617, 37.648865127646864],
              [126.864467359102079, 37.648866683705059],
              [126.864554318884743, 37.648870386127967],
              [126.864555022586174, 37.648870416057107],
              [126.864754349025773, 37.648878909330797],
              [126.86477749754593, 37.648879891146287],
              [126.8647907094538, 37.648880293977527],
              [126.864782144806327, 37.648885266463431],
              [126.864741195112956, 37.648909094331678],
              [126.864854268246944, 37.648916523543129],
              [126.864987841586185, 37.648923505076688],
              [126.865062293004243, 37.648927395770386],
              [126.865176096180747, 37.648932300373076],
              [126.865274967584952, 37.648936561195178],
              [126.865763634365479, 37.648967307346993],
              [126.865984265761497, 37.648962876252227],
              [126.866192890269616, 37.648955367090089],
              [126.866401294864914, 37.64894398336375],
              [126.866609365646966, 37.648929986324283],
              [126.866816876872946, 37.648911122338724],
              [126.866977588200541, 37.64889382664947],
              [126.867284731366581, 37.648859309449591],
              [126.867731531854545, 37.648793775385862],
              [126.867979160126211, 37.648765796498196],
              [126.86807461085121, 37.64875501147835],
              [126.868474453570983, 37.648709833753053],
              [126.868720849858434, 37.648680558710168],
              [126.86881288008793, 37.648669670964161],
              [126.869153206979391, 37.648644015256735],
              [126.869756272785736, 37.648575720860826],
              [126.870119851655559, 37.648534545804019],
              [126.870490573181556, 37.648492521732848],
              [126.870917168174586, 37.648444613141208],
              [126.871969943825093, 37.648324772720386],
              [126.873063300321121, 37.648194425350617],
              [126.873144003987377, 37.648179737110013],
              [126.87323694862684, 37.648163621248081],
              [126.873341797605789, 37.648143643257399],
              [126.873429986893285, 37.648125089121066],
              [126.873797057682467, 37.648029352924752],
              [126.873801139276509, 37.64802818573618],
              [126.873868482121637, 37.648007176251994],
              [126.873946372380928, 37.647981492523364],
              [126.874054424079176, 37.647943317713697],
              [126.874132774068684, 37.647913760580558],
              [126.874229608995009, 37.647875033071863],
              [126.87436772355187, 37.647816618533163],
              [126.87437860912668, 37.647812035313514],
              [126.874478627585901, 37.647767003452721],
              [126.874577061957083, 37.647719988293474],
              [126.87463342918079, 37.647690316983109],
              [126.874706356456755, 37.64765039246069],
              [126.87476465514473, 37.647617208925745],
              [126.874862201878543, 37.647559471102603],
              [126.874906892892156, 37.64753212901438],
              [126.874971549449157, 37.647491654854392],
              [126.875044488015035, 37.647445062138125],
              [126.875085098098793, 37.647418527585963],
              [126.875125029165929, 37.647392261624695],
              [126.875164846089305, 37.647365815680345],
              [126.875297248790844, 37.64726630968287],
              [126.875300992130889, 37.647263970723934],
              [126.875419755180673, 37.647189406980011],
              [126.875463323846006, 37.647155936899324],
              [126.87548011456127, 37.647143701898493],
              [126.875577002083276, 37.6470728988063],
              [126.875623166121045, 37.647044479037426],
              [126.87562045348912, 37.647041232509309],
              [126.875833286619226, 37.646885770644154],
              [126.875978028552211, 37.646768112362679],
              [126.875948032272561, 37.646743204955321],
              [126.875922562637527, 37.64672204939],
              [126.87581553955593, 37.646712406775251],
              [126.875798914930897, 37.64671090913135],
              [126.875787656988507, 37.6466950014687],
              [126.875771193006273, 37.646671736638048],
              [126.875751074646416, 37.646643303166414],
              [126.875713681220404, 37.646603735172995],
              [126.875682197665512, 37.646589957568516],
              [126.875632529431897, 37.646568222308076],
              [126.875558655563395, 37.646535905272074],
              [126.875401825201735, 37.646463111900026],
              [126.875298399719313, 37.646381013680568],
              [126.875208246239239, 37.646299567020058],
              [126.875207930029433, 37.646299290439522],
              [126.875183892878397, 37.646278486609411],
              [126.875064041479519, 37.646180042984248],
              [126.874740005480362, 37.64585480240126],
              [126.874616964085291, 37.645734498566512],
              [126.874610996404044, 37.645619806862406],
              [126.874620165429519, 37.645477598457333],
              [126.874579638934748, 37.645481699185162],
              [126.874351013011477, 37.645198717431228],
              [126.874340538167871, 37.645180190909223],
              [126.874337124741345, 37.64517415371995],
              [126.874322627839959, 37.645120258380373],
              [126.874204102936417, 37.644824880151418],
              [126.874099369137227, 37.644533986074364],
              [126.874072262500022, 37.644540427379432],
              [126.874066860448409, 37.644519588793116],
              [126.874008611854791, 37.644294886220067],
              [126.874059247612607, 37.6440946448459],
              [126.87385712744387, 37.643726133929135],
              [126.873705708716855, 37.643448053757275],
              [126.873775800806612, 37.643374375197091],
              [126.873854335875521, 37.643291823637213],
              [126.873911296959889, 37.643085284914747],
              [126.874235801467051, 37.642977330865342],
              [126.875174650419538, 37.642664993438657],
              [126.875241853831966, 37.642641193675558],
              [126.875218322221045, 37.642617279323552],
              [126.875184309967707, 37.642582722424571],
              [126.875134967350633, 37.642532597646571],
              [126.875115893847507, 37.642426545148318],
              [126.875112529205353, 37.64240779045528],
              [126.87519462606825, 37.642294444841795],
              [126.875127517212562, 37.641979932234342],
              [126.875124759820466, 37.641869740707307],
              [126.875123023627708, 37.641816661130022],
              [126.875074089646873, 37.641652197410508],
              [126.875312294203752, 37.641594398332153],
              [126.87539674295779, 37.641573919256892],
              [126.87547504584937, 37.64155494345524],
              [126.876349149968362, 37.641343007286977],
              [126.876427403518164, 37.641324045912448],
              [126.87665376080345, 37.641269145972601],
              [126.876760050834761, 37.64122166296125],
              [126.876749202229476, 37.64108234814919],
              [126.87676520522939, 37.640998823386937],
              [126.876781491441619, 37.640913820131111],
              [126.876758874925002, 37.640467507337291],
              [126.876750678869669, 37.640253551034654],
              [126.876730956394567, 37.640104763194337],
              [126.876691741532298, 37.639902043041147],
              [126.876653251220205, 37.639772839301429],
              [126.876564012280227, 37.63951842659818],
              [126.876543899164631, 37.639404649774754],
              [126.876544779998795, 37.63931118093037],
              [126.876557628523017, 37.639175021560987],
              [126.876593986961751, 37.639025542154222],
              [126.876698776173853, 37.638696457180117],
              [126.876868308748882, 37.638523889628814],
              [126.876870119880948, 37.638500965708829],
              [126.876887423870514, 37.638281800930812],
              [126.8768915735196, 37.6382292589781],
              [126.876740608021734, 37.637944626747746],
              [126.876363299785794, 37.637472013275563],
              [126.876273581811205, 37.637364413893188],
              [126.876171658308408, 37.637236763769842],
              [126.876161203830506, 37.637227942135958],
              [126.87594542700478, 37.637045764853816],
              [126.875867956134897, 37.636980356393373],
              [126.875880780295063, 37.636861971185439],
              [126.875906855184681, 37.636621206508124],
              [126.8758924140093, 37.636490512682883],
              [126.875885598583295, 37.636435536835862],
              [126.875788940350688, 37.636165514569264],
              [126.875747857351485, 37.636050745102111],
              [126.875467912735644, 37.635482751063854],
              [126.875345332668303, 37.635091109624476],
              [126.875243776573839, 37.634968322399239],
              [126.875111357650852, 37.634808216907089],
              [126.875080734590455, 37.634771193824996],
              [126.874962229359085, 37.634331443290463],
              [126.874911275070787, 37.634097846335578],
              [126.874907742593322, 37.634016103813927],
              [126.874905658035544, 37.633967840841933],
              [126.874894856421093, 37.633749834227558],
              [126.874884305951497, 37.633552723623936],
              [126.874875915535299, 37.633395948270028],
              [126.874888302559228, 37.633158705674965],
              [126.874911863846748, 37.633074263884815],
              [126.874962867922434, 37.632885358818399],
              [126.875046506382503, 37.632692514709454],
              [126.875225069298935, 37.632280783460672],
              [126.875249421705945, 37.63221642566667],
              [126.875370995357315, 37.631915577175477],
              [126.875449002123332, 37.631723851224969],
              [126.87546751479006, 37.631535231770599],
              [126.875469368486335, 37.631516322607681],
              [126.875432072375816, 37.63128796540493],
              [126.875401837476943, 37.631072148852674],
              [126.875337910866037, 37.630811473041284],
              [126.875288798175916, 37.630733701988838],
              [126.875282662180609, 37.630723442977079],
              [126.875416885400611, 37.630344324680635],
              [126.875444420026852, 37.630266473588193],
              [126.87545145892193, 37.630246572461111],
              [126.875467307056809, 37.630202826329871],
              [126.875476612247454, 37.630177137119261],
              [126.875501649210278, 37.630224396736949],
              [126.87554714038518, 37.630027411429097],
              [126.875553217222034, 37.629901978034503],
              [126.875552943595096, 37.629869259999964],
              [126.875552551669585, 37.629819527776696],
              [126.875551747772619, 37.629811447578049],
              [126.875537468562655, 37.629661608307281],
              [126.87552314392596, 37.629523976446102],
              [126.875492527481157, 37.62941041407651],
              [126.875423938741207, 37.629169065188947],
              [126.875422705704111, 37.629164598865465],
              [126.875379031632335, 37.629006646985069],
              [126.875378110859117, 37.629003327007233],
              [126.8753360969759, 37.628855154347875],
              [126.87530457814573, 37.628740067493581],
              [126.875298062875444, 37.628711233599859],
              [126.875267469497999, 37.628522696581129],
              [126.875264199981729, 37.628505233063628],
              [126.875260952696891, 37.628494590953579],
              [126.875109745101952, 37.627998808649089],
              [126.874832529370906, 37.626973800404336],
              [126.874824030265458, 37.626942376349575],
              [126.874817636904865, 37.626902004196999],
              [126.874808200175849, 37.62687631486412],
              [126.874738980980879, 37.626688027259178],
              [126.874703716939919, 37.626598970703043],
              [126.874587471158634, 37.626305425715927],
              [126.874649710610527, 37.626183887191985],
              [126.874724450715519, 37.625885121659707],
              [126.874675734640206, 37.625574332423682],
              [126.874547771947547, 37.625171087927626],
              [126.874485205584079, 37.624991753188837],
              [126.874397261974025, 37.624766200472394],
              [126.87432462483703, 37.624579902618201],
              [126.874090836624163, 37.624133232541446],
              [126.874063684919122, 37.623899165334535],
              [126.874025259326231, 37.623641697361144],
              [126.873998753636997, 37.623431985789011],
              [126.873974452187738, 37.623239701473715],
              [126.873972743330441, 37.623226188621786],
              [126.873927723735321, 37.622870020175895],
              [126.873846592591619, 37.622220365955599],
              [126.873820527046618, 37.622011657113624],
              [126.873789833421398, 37.621680326024588],
              [126.873754762101285, 37.621324471608951],
              [126.873709037024184, 37.620930805525418],
              [126.873696472112783, 37.620673676482511],
              [126.873502175237547, 37.620351629399863],
              [126.873333621474245, 37.620045948133274],
              [126.873142359961719, 37.619767368087658],
              [126.873012309347189, 37.619624849451036],
              [126.872803290266262, 37.619395792051151],
              [126.872657035812409, 37.619304131217639],
              [126.872230178894426, 37.619240503611849],
              [126.871653742250444, 37.619213889026554],
              [126.871579640683365, 37.619211219443478],
              [126.871263786747789, 37.618863581287734],
              [126.871071895574673, 37.618694649973129],
              [126.8707723436317, 37.618430936811919],
              [126.87055513507417, 37.618207976148653],
              [126.870422683831833, 37.618272580245964],
              [126.87041220302622, 37.61825195406886],
              [126.870401193281239, 37.618233048420251],
              [126.870333703967503, 37.618101187197539],
              [126.870318690270338, 37.618052535963166],
              [126.870282377076222, 37.617943062571449],
              [126.870215038858291, 37.617748878398423],
              [126.870058991226472, 37.617279070208809],
              [126.870057531825452, 37.617265769533795],
              [126.870044540051865, 37.617065746282073],
              [126.869919507541951, 37.616636640064954],
              [126.869870293253925, 37.616543656960957],
              [126.869844354455964, 37.616494651677463],
              [126.869611098411994, 37.616371669728672],
              [126.869486208635124, 37.616306099161214],
              [126.869112983460326, 37.616110142495231],
              [126.868929449204458, 37.616014025215001],
              [126.868747923320726, 37.615917363170972],
              [126.868673789339397, 37.61587738354865],
              [126.868644794485206, 37.615861747092296],
              [126.868301703812094, 37.615685307957889],
              [126.868060437675396, 37.615560556713071],
              [126.868004019351659, 37.615530840401256],
              [126.867751173979372, 37.615389651056333],
              [126.867619532985387, 37.615316748739957],
              [126.867432458454132, 37.615214733456767],
              [126.867098348089144, 37.615029273338578],
              [126.866663437025679, 37.614788378212104],
              [126.866518703231833, 37.614734316206508],
              [126.865784982772411, 37.61446025222255],
              [126.864870098670664, 37.614157654944073],
              [126.864615902021342, 37.614104077594334],
              [126.864343946601252, 37.613940242881895],
              [126.864185368453931, 37.613824642313617],
              [126.86400845916306, 37.61369893070809],
              [126.86383963835398, 37.61354899129352],
              [126.863769527573325, 37.613445259929463],
              [126.863723314985435, 37.613286631781499],
              [126.863533534723729, 37.613023920142744],
              [126.863483099260478, 37.612850629631879],
              [126.863477027279032, 37.612829763420933],
              [126.863104015470071, 37.612557672961245],
              [126.862797982621842, 37.612335970711712],
              [126.86222819716869, 37.61191943220156],
              [126.861965196251091, 37.611727536759531],
              [126.861828828513779, 37.611628313183253],
              [126.861279402009984, 37.611225374069015],
              [126.860817196367165, 37.610890542850193],
              [126.860527684612308, 37.610678808228016],
              [126.860361118809521, 37.61055808470276],
              [126.860355286441219, 37.610561485374198],
              [126.860327739299109, 37.610577542064284],
              [126.860206310358748, 37.610605100988757],
              [126.860052549159178, 37.610608287211058],
              [126.859887268903208, 37.61058844833375],
              [126.859793101732507, 37.610561863678221],
              [126.859644043757626, 37.610508752246936],
              [126.85961685830928, 37.610491068346924],
              [126.859471010559872, 37.610396192722128],
              [126.859223813689709, 37.610137358162781],
              [126.858966319239627, 37.60984503932098],
              [126.858723802264322, 37.609469368291848],
              [126.858504255956035, 37.609133743567526],
              [126.858487236570156, 37.609107727741943],
              [126.858432982777998, 37.609020661239931],
              [126.858412305089516, 37.609030778979701],
              [126.858297997509581, 37.609086702741557],
              [126.858167784429867, 37.609155345157433],
              [126.85801831635537, 37.609229358293149],
              [126.857772233081647, 37.609354457745212],
              [126.857552545396061, 37.609464794997848],
              [126.857328859020413, 37.609581288713805],
              [126.857235684458118, 37.609630115840282],
              [126.856820618816215, 37.60983952572888],
              [126.856186439890379, 37.610152883291391],
              [126.856091868646828, 37.610199797363492],
              [126.855877416096661, 37.610304881809718],
              [126.855822531651157, 37.610331775502566],
              [126.855573104246801, 37.610454920501667],
              [126.855165214401609, 37.610657955197098],
              [126.854715303506126, 37.610881721945887],
              [126.853983060128101, 37.611427613764555],
              [126.853734870515709, 37.61161263734008],
              [126.853687606807242, 37.611860707042858],
              [126.853637910312798, 37.611918682707127],
              [126.853551575369025, 37.612002809069992],
              [126.853397350844617, 37.612150224665726],
              [126.853401555863954, 37.612162484226708],
              [126.853381807618817, 37.612181802484571],
              [126.85348137504306, 37.612429635508441],
              [126.853489365226935, 37.612449523068214],
              [126.853550791227804, 37.612602419488205],
              [126.853555286314204, 37.612610887815748],
              [126.85358165814975, 37.612687798951939],
              [126.853589891074108, 37.612699676771634],
              [126.854048777645843, 37.61335075930505],
              [126.854050134481469, 37.613350168246114],
              [126.854059259743934, 37.613363037878202],
              [126.854115947771561, 37.613443005410325],
              [126.854154305720982, 37.613495694654119],
              [126.85418297680144, 37.613535077648145],
              [126.854232380095127, 37.613621930208105],
              [126.854252217198308, 37.613653807199647],
              [126.854296664819955, 37.613725574918398],
              [126.854390572094545, 37.613877202866441],
              [126.854453949688562, 37.613979535590296],
              [126.854475649879362, 37.614013098315951],
              [126.854472047857527, 37.614043248980792],
              [126.85446474035075, 37.614102017475048],
              [126.854464358003298, 37.614105090736651],
              [126.854462582306638, 37.614145482439035],
              [126.854450256298804, 37.614261807933204],
              [126.85443766261298, 37.614382092422744],
              [126.854437885174335, 37.614389769404312],
              [126.854516219158683, 37.61454393853051],
              [126.854576014619255, 37.614661621139803],
              [126.854583316653233, 37.614675976071048],
              [126.854569440890401, 37.614681510082505],
              [126.85452019321707, 37.614701185435422],
              [126.854487819629554, 37.614714119157419],
              [126.854487927460227, 37.614716587600824],
              [126.854491172575493, 37.614787626895911],
              [126.854494504002176, 37.614860609939093],
              [126.854517226350808, 37.615196450627643],
              [126.854537930836145, 37.615521415573028],
              [126.854541324300442, 37.615524835770366],
              [126.854627402462526, 37.61561156696704],
              [126.854564052395801, 37.615760168666718],
              [126.854554210552735, 37.615783226846773],
              [126.854599192921441, 37.615883494840553],
              [126.854735345741148, 37.61618699128055],
              [126.855000803826499, 37.616724215415736],
              [126.854918671392554, 37.616845655660491],
              [126.854660450512213, 37.6171211245602],
              [126.854655113046107, 37.617361884045735],
              [126.854652106710816, 37.617497461583753],
              [126.854651753669273, 37.617513398842831],
              [126.854685285666065, 37.617683186235475],
              [126.854703587218978, 37.617776455406833],
              [126.85472072317927, 37.617863785620258],
              [126.854728380226021, 37.618115710657712],
              [126.85473168922033, 37.618219778734534],
              [126.854739473173311, 37.618523237310349],
              [126.8548426191782, 37.618862079085901],
              [126.854855617831987, 37.618904767018179],
              [126.854883238181529, 37.619144189687411],
              [126.854884606910616, 37.619155085281882],
              [126.854886927159967, 37.619173623079057],
              [126.854917496405307, 37.619416815906987],
              [126.854993185579815, 37.619647641198135],
              [126.855010694597254, 37.619701056198942],
              [126.854943310171379, 37.619727535875676],
              [126.854873536838554, 37.619754956063865],
              [126.854810869603355, 37.619779598397741],
              [126.85480517897247, 37.619781826024514],
              [126.854726218941835, 37.619845192453973],
              [126.854438054689524, 37.620076239655987],
              [126.854166746779896, 37.620152035367383],
              [126.854002525043413, 37.620165416251183],
              [126.853972911987086, 37.620167829236038],
              [126.853955916526772, 37.62017024369608],
              [126.853950829903468, 37.620199435276817],
              [126.853938608325706, 37.620269555203173],
              [126.853920973243902, 37.620370755913761],
              [126.853899326890783, 37.62066860381622],
              [126.853855193024813, 37.620757597766875],
              [126.853853935616385, 37.620761217847161],
              [126.853796045504978, 37.620876869993758],
              [126.853688036496123, 37.620973165470879],
              [126.853571642791323, 37.621076937440883],
              [126.853733213273287, 37.62126573425445],
              [126.853756487321988, 37.621295584092714],
              [126.853899081103862, 37.621462437435838],
              [126.853971477060043, 37.621547148149965],
              [126.853984437371381, 37.621562309229319],
              [126.854481813912216, 37.6221443001442],
              [126.854482132508807, 37.622144673236456],
              [126.854489478470541, 37.622153241838973],
              [126.854325647817362, 37.622231873705829],
              [126.854313045525629, 37.622221284383592],
              [126.854259224493688, 37.622242058520094],
              [126.854134881892577, 37.622290243759771],
              [126.854062226878199, 37.622318399384788],
              [126.854009062437214, 37.622337792552827],
              [126.853976421147905, 37.622346041312191],
              [126.853749503018193, 37.622403724278641],
              [126.853473461693042, 37.622473892424026],
              [126.853419024174514, 37.622501033419617],
              [126.853278077276599, 37.622571313077671],
              [126.853171438338904, 37.62260433385471],
              [126.853122935127701, 37.622614995721527],
              [126.852964853769919, 37.622590379750179],
              [126.852715094150597, 37.622587362273713],
              [126.852659324332691, 37.622606845092946],
              [126.85259729076887, 37.622654228535239],
              [126.852573208567051, 37.622638517759945],
              [126.852571148770195, 37.622637172485085],
              [126.852547120797794, 37.622621481831324],
              [126.852505926131087, 37.622618995240856],
              [126.852420527529731, 37.622613837853407],
              [126.85234328351423, 37.62255040710987],
              [126.852268615585757, 37.622393179706705],
              [126.852221902141281, 37.622248255693336],
              [126.852086050567863, 37.622149320380366],
              [126.851962844459607, 37.622077278035398],
              [126.851913523623452, 37.622062967987901],
              [126.851835598689732, 37.622040358153924],
              [126.851810097446929, 37.622036338731981],
              [126.851808327846484, 37.62203811725594],
              [126.851756916799658, 37.622092136542605],
              [126.851567561135525, 37.622118420024911],
              [126.851358148126522, 37.62196889607916],
              [126.851154760995797, 37.621819768134124],
              [126.851070709893449, 37.621758393822937],
              [126.850983251101908, 37.621731343304546],
              [126.850774923711683, 37.621662729761432],
              [126.850450598369889, 37.621558199178885],
              [126.85032384219808, 37.621587606768081],
              [126.850126717325494, 37.621632747924806],
              [126.850027269169559, 37.621655520356235],
              [126.849933680803716, 37.621649638257253],
              [126.849640253843319, 37.621631200660936],
              [126.84939451693117, 37.621615314683773],
              [126.849068380882287, 37.621590686122978],
              [126.848781494082417, 37.621568590610998],
              [126.848717888952095, 37.621532517925743],
              [126.848587661497604, 37.621458655881455],
              [126.848579001925515, 37.621470851783648],
              [126.848564255168768, 37.621469000152246],
              [126.848543810273384, 37.621466434750687],
              [126.848532339327392, 37.62146499328086],
              [126.848380842073439, 37.621445980471428],
              [126.848348206324218, 37.621441885750698],
              [126.848299585516031, 37.621435782896185],
              [126.848298019386405, 37.621752649599976],
              [126.848298001912738, 37.621756079075645],
              [126.848229734587392, 37.622106571118408],
              [126.848157416898474, 37.622364888444714],
              [126.848086042852515, 37.622616563597091],
              [126.84808303264974, 37.622697586707076],
              [126.848080985736075, 37.622782233088159],
              [126.848078256857477, 37.622895011706454],
              [126.848074438783257, 37.623066721557009],
              [126.84806921142409, 37.623294825416927],
              [126.848064172687756, 37.623481474411065],
              [126.848062850916207, 37.623562752901236],
              [126.847999823708832, 37.623750071136492],
              [126.847915017987077, 37.624006559826391],
              [126.847919979013113, 37.624127479388314],
              [126.847922997547442, 37.624246863157836],
              [126.847939042009259, 37.624362395409413],
              [126.847945082807868, 37.624405894656505],
              [126.84795156182517, 37.624452546482132],
              [126.8479768122303, 37.62462144251942],
              [126.848002122657618, 37.624786722732367],
              [126.84798990173266, 37.624974612781912],
              [126.84798697231335, 37.625019635279415],
              [126.847977799305866, 37.625156453735052],
              [126.848049425540395, 37.625232781373029],
              [126.848103499545303, 37.625290404886002],
              [126.848148528140882, 37.625334800921699],
              [126.848176286131746, 37.625370533466807],
              [126.848215974118347, 37.625439823618848],
              [126.848264621021627, 37.625524741872717],
              [126.848285969023365, 37.625560668825017],
              [126.848319167636518, 37.625616539211912],
              [126.848336812947622, 37.625651871555462],
              [126.848335000139059, 37.62581719655693],
              [126.848334416456737, 37.625870292628591],
              [126.84828888115031, 37.626144112874158],
              [126.848252074513553, 37.626361518240742],
              [126.848253504594169, 37.626365290103905],
              [126.848301388178356, 37.626491628447653],
              [126.848385090841219, 37.626724882333079],
              [126.848433739790437, 37.62686342617895],
              [126.848512541979133, 37.627215991991825],
              [126.848571628168074, 37.627493667154823],
              [126.848567734525602, 37.627851613326783],
              [126.848549244471215, 37.627917276937801],
              [126.848508313608463, 37.628062635002678],
              [126.848447047281638, 37.628273927664871],
              [126.848412134865981, 37.62839370937381],
              [126.848322136791538, 37.628641904252859],
              [126.848252070613327, 37.62882361243846],
              [126.848327567552403, 37.629107839947835],
              [126.848322935446632, 37.629423657284988],
              [126.848322155544267, 37.629427573771025],
              [126.848332390724849, 37.629493066094611],
              [126.848332858679711, 37.629496062002332],
              [126.84836629624192, 37.629694537883772],
              [126.848414547382433, 37.62998555327362],
              [126.848571524962551, 37.630154076805553],
              [126.848660470622193, 37.630304994721968],
              [126.848726073988885, 37.630416300887319],
              [126.84874799509447, 37.63074738188142],
              [126.848722557942864, 37.630839458520143],
              [126.848670464062522, 37.631028019766021],
              [126.848536458126858, 37.631455484545612],
              [126.84854351316055, 37.631658647581069],
              [126.848554633220218, 37.631894944368597],
              [126.848569987181776, 37.632196187428619],
              [126.84842867544647, 37.63244852983037],
              [126.848274171850647, 37.632723577626109],
              [126.848233510592294, 37.63291463625756],
              [126.848224718982905, 37.632955930857918],
              [126.84816011334766, 37.633241434772223],
              [126.848164876307465, 37.633303639425428],
              [126.848176444876714, 37.633454738518431],
              [126.848176909210551, 37.633460745752558],
              [126.848177905254914, 37.633464787416791],
              [126.848189998275373, 37.633513812737419],
              [126.848230693236971, 37.633679154225902],
              [126.848233185432022, 37.633689280929751],
              [126.848256780099277, 37.633784727046326],
              [126.848282901297537, 37.633891697112425],
              [126.848285316853762, 37.633890746031135],
              [126.84828912629861, 37.633905642643768],
              [126.84824396523338, 37.634106141261171],
              [126.848188173050559, 37.634351133918585],
              [126.848182059799598, 37.634379016172367],
              [126.848124930077475, 37.634639547555992],
              [126.848079469562833, 37.634819861823075],
              [126.848015987801801, 37.635058718878177],
              [126.847986503662582, 37.635126615444932],
              [126.847880647562221, 37.635258109634158],
              [126.847841158015356, 37.635288240973324],
              [126.847802428949663, 37.635336394108784],
              [126.847690767086462, 37.635476440302178],
              [126.847515715887965, 37.635703706438647],
              [126.847516609177433, 37.635876064770244],
              [126.847518540483065, 37.63599037772336],
              [126.847520342621706, 37.636097169255528],
              [126.847599693941504, 37.636316705370561],
              [126.847622744924138, 37.636380479406938],
              [126.847709478130753, 37.63662807863161],
              [126.847716114048609, 37.636647021634161],
              [126.847732337374069, 37.636800930630109],
              [126.847747476563214, 37.636954137799172],
              [126.847753536740626, 37.637015478014732],
              [126.847743721955055, 37.63704796359449],
              [126.847715110452242, 37.637142714630897],
              [126.847714179034938, 37.6371457983415],
              [126.847679249974249, 37.637262313120672],
              [126.847663069049986, 37.637316285466206],
              [126.847593225057366, 37.637348684261696],
              [126.847507466835751, 37.637388466131341],
              [126.847401785069977, 37.637437489939821],
              [126.84728279173865, 37.637493842635728],
              [126.8471779178372, 37.637543509754501],
              [126.847113145589418, 37.637677826340635],
              [126.847106577271717, 37.637691446952665],
              [126.847027346283696, 37.637740749291432],
              [126.846953995654388, 37.637786391374497],
              [126.846703101324138, 37.637813993633429],
              [126.84667382374117, 37.637836938918554],
              [126.846614648835356, 37.637883313654186],
              [126.846601074774881, 37.637893955297855],
              [126.846497261737198, 37.637895371345678],
              [126.846447987489, 37.637896024965265],
              [126.846477974839843, 37.637870344524963],
              [126.846481833609133, 37.637867039088938],
              [126.84644728671222, 37.637827614603992],
              [126.846436011988018, 37.637814747844871],
              [126.846409021878856, 37.637806087061264],
              [126.846140103316301, 37.637719793488017],
              [126.845899158717359, 37.637642470313693],
              [126.845550454259779, 37.638119346684938],
              [126.845520143655719, 37.638149398348801],
              [126.84540816877869, 37.638274667741385],
              [126.845231011201804, 37.638470214346739],
              [126.845076904655699, 37.638645069712155],
              [126.844913439451403, 37.638847843886566],
              [126.844853796750087, 37.63892120095489],
              [126.844824721852035, 37.638956961111056],
              [126.844800825340528, 37.638996750122203],
              [126.844655939547536, 37.639211208295372],
              [126.844557592373633, 37.639299318594773],
              [126.844453291774613, 37.639346579893086],
              [126.844028212720858, 37.639449148206147],
              [126.843616286699145, 37.639434544939604],
              [126.843592851336638, 37.639436258672418],
              [126.843457975468596, 37.639448474700593],
              [126.843439152900615, 37.639456919969128],
              [126.843181842175881, 37.639567034524958],
              [126.843156644713716, 37.639587363445827],
              [126.842919856402432, 37.639770574180794],
              [126.842887285899181, 37.639795774226734],
              [126.842841675773258, 37.639829644531815],
              [126.84276516083797, 37.639842437206532],
              [126.842637836811761, 37.639863723887352],
              [126.842350220852936, 37.639911594952657],
              [126.842242786435946, 37.63993582766139],
              [126.842211343752169, 37.639959832333922],
              [126.842123626140349, 37.640026801463961],
              [126.842054321241164, 37.640089533311524],
              [126.842032457868086, 37.640118991225897],
              [126.842017713818976, 37.640137767522532],
              [126.841862497515422, 37.640350573720831],
              [126.841827261805705, 37.640398881318937],
              [126.841668950965783, 37.640624814979894],
              [126.841320212263781, 37.640803975333306],
              [126.84107844623216, 37.640928180502122],
              [126.840983094536668, 37.641069602835451],
              [126.840713475025382, 37.641520528787829],
              [126.84070891551454, 37.641538818326879],
              [126.840684068723704, 37.641652183015708],
              [126.840661756473224, 37.641753986986522],
              [126.840862123035024, 37.641936812884531],
              [126.840930653839663, 37.641999342433749],
              [126.841053369276082, 37.64211131779971],
              [126.841238925670879, 37.642179233642146],
              [126.841239327547427, 37.64219841618047],
              [126.841254338865809, 37.642226457443932],
              [126.841352885582381, 37.642392639603599],
              [126.841379938586243, 37.642457460133933],
              [126.841350301215897, 37.642645540957304],
              [126.841421583095638, 37.642801774276393],
              [126.841453364305764, 37.642829208795],
              [126.841596421742921, 37.642955901381654],
              [126.841642097846972, 37.642997676033502],
              [126.841844690721558, 37.643059987790437],
              [126.841848066179224, 37.643061026025258],
              [126.841848856844976, 37.64306378219527],
              [126.841854699057393, 37.643084189341423],
              [126.841859886270484, 37.643102394490512],
              [126.841950217619711, 37.643348752233855],
              [126.841991062796751, 37.643382089497287],
              [126.84204196005382, 37.643423633531846],
              [126.842143212712244, 37.643505460257359],
              [126.842350603702386, 37.643546762675847],
              [126.842387889420849, 37.643554318160561],
              [126.842428974661672, 37.643562643816622],
              [126.842487131614547, 37.643655326293718],
              [126.842488321867364, 37.643750991923042],
              [126.842488257784424, 37.643815871962595],
              [126.842361354650365, 37.644112888129769],
              [126.842773929018847, 37.644254282271298]
            ]
          ]
        ]
      },
      id: '31101511'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '대덕동',
        ADM_CD: '31101680'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.859591964183068, 37.608265146724733],
              [126.861738075663411, 37.607312323517171],
              [126.862825970305735, 37.606780234589998],
              [126.863700451791502, 37.606352514371984],
              [126.863966043844485, 37.606222606999765],
              [126.864353725304028, 37.606032979786725],
              [126.864585856318641, 37.605919437499857],
              [126.867139058625014, 37.603913292686215],
              [126.867295796576599, 37.603790134414538],
              [126.867360546830795, 37.603739249561954],
              [126.867381794121684, 37.603722557136436],
              [126.868548367858537, 37.60273015787169],
              [126.868765211045982, 37.602545678559693],
              [126.86922678178928, 37.602153021979333],
              [126.869420813883679, 37.601987955088468],
              [126.869592048964577, 37.601842281848555],
              [126.869906672993949, 37.601566845963013],
              [126.870348902378581, 37.601179745623064],
              [126.870427500653818, 37.601110875541693],
              [126.870430098204906, 37.601108601804626],
              [126.870436871693798, 37.601102672888338],
              [126.870437624953183, 37.601102011719711],
              [126.870438014248862, 37.601101656866881],
              [126.870731378125214, 37.600844826086565],
              [126.870930262577517, 37.600670709664286],
              [126.871500622870798, 37.600171362857822],
              [126.871554057473347, 37.600124569654277],
              [126.871556588753336, 37.600122355027182],
              [126.871588599563324, 37.600094341559533],
              [126.871745146724152, 37.599957283011889],
              [126.871754651102478, 37.59994895116845],
              [126.871754951239879, 37.599948689575612],
              [126.87175928458619, 37.599944890972345],
              [126.872715358512266, 37.599107818974232],
              [126.873214568423137, 37.59867071041824],
              [126.873832964271827, 37.598150717750755],
              [126.874121359154216, 37.597908195856746],
              [126.875194732693643, 37.597005556252526],
              [126.87567063297854, 37.596596896879255],
              [126.875819077514365, 37.596469421629067],
              [126.87581929887719, 37.59646922991341],
              [126.875946224154021, 37.59636023709804],
              [126.87594904238756, 37.596357812978525],
              [126.875953914349111, 37.596353627687243],
              [126.876111705841296, 37.596218127294371],
              [126.876247608762412, 37.596284591022844],
              [126.876266795588265, 37.596296165556417],
              [126.876750675873438, 37.595850592651161],
              [126.87769623247182, 37.594979873268208],
              [126.878693166199341, 37.594061815075683],
              [126.879791500221387, 37.593050341780788],
              [126.879840446123097, 37.593005266136196],
              [126.88210861524928, 37.5909163508137],
              [126.882072021654565, 37.590880503160491],
              [126.882030897180698, 37.590817679928044],
              [126.882042878961229, 37.590805758454152],
              [126.882056063380418, 37.590792639086175],
              [126.882055831745021, 37.590792433269449],
              [126.881900952934032, 37.590652271649326],
              [126.88188690658383, 37.590639499121899],
              [126.881886745993825, 37.590639194529565],
              [126.881900921289088, 37.590625692791804],
              [126.881907656537436, 37.590619231209438],
              [126.881986134318169, 37.590539824954199],
              [126.881731234465903, 37.590358433063969],
              [126.881723640741029, 37.590349136531643],
              [126.881278344451232, 37.590125691055221],
              [126.881152315233606, 37.590062213296683],
              [126.881004368072993, 37.589982938057346],
              [126.880851837452994, 37.589888458232728],
              [126.880754990608111, 37.589828110250807],
              [126.88066591895182, 37.589771148759368],
              [126.880597192619234, 37.589714479646517],
              [126.880349139342854, 37.589506159835125],
              [126.880222427342204, 37.589320761435133],
              [126.880106293545211, 37.589154680976392],
              [126.880058394361555, 37.589086179984044],
              [126.880044512678225, 37.589066328080058],
              [126.880034454633474, 37.589047740106651],
              [126.880034100759872, 37.5890469784188],
              [126.880002896144845, 37.58897985400624],
              [126.879989239725717, 37.588950874513145],
              [126.879988334795058, 37.588948953512023],
              [126.879940266433863, 37.588846943386841],
              [126.879900815608551, 37.58876322432917],
              [126.879796937040268, 37.588577709263525],
              [126.879794807446928, 37.588573907941033],
              [126.87965615090161, 37.588326561984069],
              [126.879627226655003, 37.588275852027692],
              [126.879606750538358, 37.588244575182891],
              [126.879592289949557, 37.588217810512418],
              [126.879539581871995, 37.588102042117271],
              [126.879438985095632, 37.587878350611568],
              [126.879437351379295, 37.587874717735879],
              [126.87932282829675, 37.587583751729994],
              [126.879322762425986, 37.587583363807582],
              [126.879281748680128, 37.587308157524568],
              [126.879263844452495, 37.587188047289914],
              [126.879263790636799, 37.587187708103201],
              [126.879205009262108, 37.586998719723333],
              [126.879170802245937, 37.586890981140002],
              [126.879133022398719, 37.586771993059052],
              [126.879016281635288, 37.58667809658602],
              [126.878871545897439, 37.586561684135106],
              [126.878844525653534, 37.586548869107496],
              [126.878465156672064, 37.586368954816209],
              [126.878213601483395, 37.586335845989346],
              [126.877744135002104, 37.58627405391487],
              [126.877542272508705, 37.586247481647327],
              [126.877301728794649, 37.58565960655568],
              [126.877300125916591, 37.585655688988936],
              [126.877265721729273, 37.585571610774046],
              [126.877257021653946, 37.585359294837509],
              [126.87724720160719, 37.585119634444176],
              [126.877247131683234, 37.585119326718363],
              [126.877193305507731, 37.584935622757207],
              [126.877149862531255, 37.584891353156962],
              [126.877086811777147, 37.5848271046194],
              [126.877001926422423, 37.584785982757481],
              [126.876924893559888, 37.584748666281101],
              [126.876745712075149, 37.58463725839222],
              [126.876537933629507, 37.584440052964176],
              [126.876561753090684, 37.584244677234658],
              [126.876623441913239, 37.584164500762419],
              [126.876696354672546, 37.584149936181802],
              [126.8767037874495, 37.584149588756134],
              [126.876778793960895, 37.584146076595623],
              [126.876973765784598, 37.584238921765028],
              [126.877049690967624, 37.584238665415057],
              [126.877164988867634, 37.584238276972478],
              [126.877192127462195, 37.584224653102062],
              [126.877293709708752, 37.584173659436793],
              [126.877331245690428, 37.584047840201123],
              [126.87733116143427, 37.584047485530022],
              [126.877264947811824, 37.583822401498765],
              [126.877250779169088, 37.583774251971853],
              [126.877146679224992, 37.583609432575216],
              [126.87711434557211, 37.583564341867522],
              [126.876987752091352, 37.583387799773526],
              [126.876989308871075, 37.583354703569938],
              [126.876995814681322, 37.583216328115064],
              [126.877044871333936, 37.583076374549371],
              [126.877062110292158, 37.583027190161559],
              [126.877075273581369, 37.582982155664666],
              [126.877098415144133, 37.582892918920102],
              [126.877091060760037, 37.582740878095038],
              [126.87708805047923, 37.582653870257424],
              [126.877084308959766, 37.582545745565312],
              [126.877081798175112, 37.582521125726132],
              [126.877068914474947, 37.582394790778686],
              [126.877049655154167, 37.582206116130081],
              [126.876974027148904, 37.582066178681721],
              [126.87695832381047, 37.582037123112784],
              [126.876949499459869, 37.582024678598899],
              [126.876671371700269, 37.581632502611029],
              [126.876671300178018, 37.5816321155583],
              [126.876664417655817, 37.581593664086562],
              [126.876625448162528, 37.581375953136231],
              [126.876625376655767, 37.58137556428089],
              [126.87666271459166, 37.581259601210839],
              [126.876919232386996, 37.581033499563091],
              [126.877161167132599, 37.580854964355979],
              [126.877261007636108, 37.580771179727726],
              [126.877417845451447, 37.580639565864765],
              [126.877461946306269, 37.580499958171295],
              [126.877476823347408, 37.580383404058772],
              [126.877469541717403, 37.580307973021299],
              [126.877469510346657, 37.580307660090668],
              [126.877455085917049, 37.580260910797541],
              [126.877424489336946, 37.580222934583517],
              [126.877389243073736, 37.580179185086124],
              [126.877367657990959, 37.580115667327334],
              [126.877367533104191, 37.580115296217606],
              [126.877367420329534, 37.580114966633403],
              [126.877426116357483, 37.580021831533735],
              [126.877475050727384, 37.579965983861051],
              [126.877543442302922, 37.57988793239155],
              [126.87762910051319, 37.579752045456964],
              [126.877631558862305, 37.57974814659984],
              [126.877587926622724, 37.579625856081798],
              [126.877522132046877, 37.579514847933972],
              [126.877449806356523, 37.579399350877367],
              [126.877427209296556, 37.579363266400108],
              [126.87729305730187, 37.57920039315934],
              [126.877259162578625, 37.579159241200827],
              [126.877127667188887, 37.578984256046475],
              [126.877077802896537, 37.578904637070657],
              [126.877076497459683, 37.578902554393828],
              [126.87702730008418, 37.578908733942249],
              [126.876754149389313, 37.578943039241359],
              [126.876444083239164, 37.578977274194443],
              [126.876443739768376, 37.578977312039058],
              [126.876387922817926, 37.578983475280751],
              [126.87625922082178, 37.578962514215846],
              [126.876260486345302, 37.578185234318788],
              [126.87519538309499, 37.578274481160143],
              [126.873915250959158, 37.578280700295522],
              [126.873084866396127, 37.578221803474619],
              [126.872147247824131, 37.578147934290548],
              [126.871570679074225, 37.578102506800327],
              [126.871443071774863, 37.578092450738716],
              [126.870600532088233, 37.57800846944],
              [126.869791417559483, 37.577831028419894],
              [126.86957045941891, 37.577782434442824],
              [126.868799302917765, 37.577612834518469],
              [126.86837937146538, 37.577521135675276],
              [126.868219292621063, 37.577518672839304],
              [126.868009554344709, 37.577515445485645],
              [126.867332778462313, 37.577505031283962],
              [126.865819397791284, 37.577484413389278],
              [126.864941186377237, 37.577472439820539],
              [126.864818347530189, 37.577416552868613],
              [126.864248844003384, 37.577194303585998],
              [126.863730830692319, 37.576991856649826],
              [126.863730338774886, 37.576992304668593],
              [126.863728959556482, 37.576993217503123],
              [126.86342332653922, 37.577164811860989],
              [126.863421798310071, 37.577165534606806],
              [126.863420144935134, 37.577166058382844],
              [126.863418410702565, 37.577166369006441],
              [126.863416636434053, 37.577166460388398],
              [126.863414865193292, 37.577166329155553],
              [126.863413142262729, 37.577165977354795],
              [126.863411509435423, 37.57716541783028],
              [126.86341000739499, 37.577164660716136],
              [126.863408672218583, 37.577163725135101],
              [126.863407537672344, 37.577162635605454],
              [126.863246603072355, 37.576980703624606],
              [126.863245628005359, 37.576979372493071],
              [126.863244951745443, 37.576977928503105],
              [126.86324459431583, 37.576976414125745],
              [126.863244566664847, 37.576974873585684],
              [126.863244869548836, 37.576973351052722],
              [126.863245492376691, 37.576971893339461],
              [126.863246418956464, 37.576970540017513],
              [126.863247621748812, 37.576969333301285],
              [126.863249064231624, 37.576968306343396],
              [126.863415737556906, 37.576868713935482],
              [126.863387510107998, 37.576857681627281],
              [126.863253709744882, 37.576804592084308],
              [126.862692151895814, 37.576581162253952],
              [126.862611396247942, 37.576549031499496],
              [126.862025993662542, 37.576319713791044],
              [126.86185506596064, 37.576252784722506],
              [126.861115908204113, 37.575959935771571],
              [126.860693268068459, 37.575793721705153],
              [126.860362601184448, 37.575663675979477],
              [126.859294169146196, 37.575240902725476],
              [126.8592941270621, 37.575240527539385],
              [126.859285698981154, 37.574915677403233],
              [126.85928568974488, 37.57491530149408],
              [126.85835071444302, 37.574730316399872],
              [126.857749546732492, 37.57461232996809],
              [126.857654191450422, 37.574593613970549],
              [126.857340241984531, 37.574531995808933],
              [126.855766285773925, 37.574221475564862],
              [126.854834154289733, 37.574037312820508],
              [126.854133482734312, 37.573896785851524],
              [126.853697481682886, 37.573812123414264],
              [126.853628547630549, 37.573798737838572],
              [126.853628605807828, 37.573739337112123],
              [126.853631796611168, 37.571790547866058],
              [126.853522342525054, 37.571895932672042],
              [126.853060230603944, 37.572224496006456],
              [126.852924917582342, 37.572319772404576],
              [126.852640105049574, 37.572522701657078],
              [126.852541152214314, 37.572590100232361],
              [126.852347156698926, 37.572722234389708],
              [126.851849110508397, 37.573029080745854],
              [126.851345580549577, 37.573339299199908],
              [126.851287258560802, 37.573375231531678],
              [126.850856272903755, 37.573639516697106],
              [126.850127548018875, 37.574086372063121],
              [126.849601527154007, 37.574408921979909],
              [126.849324928368077, 37.574578526145245],
              [126.848503805894836, 37.575082015085435],
              [126.848381277631006, 37.575157145095567],
              [126.847706148621356, 37.575683377313112],
              [126.847296518239062, 37.576002661585711],
              [126.846827935820144, 37.576334967968407],
              [126.846392350690152, 37.576643868005448],
              [126.846074859133239, 37.576869019213113],
              [126.844579858621657, 37.577929174965476],
              [126.844081613324221, 37.578282487918287],
              [126.844071415026605, 37.57828971948021],
              [126.843240226617283, 37.578879040836803],
              [126.842411047557576, 37.579261971571917],
              [126.842333704811793, 37.579282977233312],
              [126.84197416592778, 37.579494503468283],
              [126.841830342250915, 37.579581876030105],
              [126.841630039254923, 37.579700318944433],
              [126.839738469922224, 37.580818791472211],
              [126.839703375767499, 37.580839542280316],
              [126.839657552400993, 37.580866636167464],
              [126.839256688443712, 37.581103655139486],
              [126.839171040951456, 37.581154296012649],
              [126.839137120289109, 37.581174351978078],
              [126.839059592009235, 37.581220500886438],
              [126.83809794039702, 37.581792911904195],
              [126.83714914776796, 37.582563341839908],
              [126.836655477576343, 37.582861394760037],
              [126.836217616001306, 37.583122347588763],
              [126.835815345245109, 37.58336532806252],
              [126.834586909334007, 37.584106631783875],
              [126.834180195275565, 37.584354662396557],
              [126.832955610238301, 37.585095116142114],
              [126.8327163901902, 37.585241699688019],
              [126.831930101285934, 37.585723488528501],
              [126.831927485497744, 37.585725325070911],
              [126.831759648312371, 37.585843184824768],
              [126.831301215631541, 37.586033702855694],
              [126.830907370950939, 37.58619768211058],
              [126.83005515288508, 37.586552498006775],
              [126.828808183142826, 37.587069593736608],
              [126.828373811079771, 37.5872514058128],
              [126.827811762436639, 37.587484837099986],
              [126.827658465491382, 37.587605397543228],
              [126.827449226166308, 37.587738551591656],
              [126.82719676264125, 37.587896130286417],
              [126.826958466491632, 37.588046413577153],
              [126.826746224384095, 37.588179553743693],
              [126.826688318378316, 37.588215869095222],
              [126.826653274450919, 37.588237799711322],
              [126.826562139239385, 37.588295002295602],
              [126.826349533312808, 37.588429268135776],
              [126.826146855072096, 37.588554545348629],
              [126.826126519270758, 37.588567347209626],
              [126.825917068693073, 37.588699209282026],
              [126.825702861751907, 37.588835162914265],
              [126.825509564500578, 37.588956788112895],
              [126.825332572419399, 37.589068020038575],
              [126.825218866237066, 37.589123883858207],
              [126.824756958933804, 37.589416850067451],
              [126.823651893809441, 37.590116839183231],
              [126.822668357236665, 37.590740690749371],
              [126.822539175932704, 37.590826935529641],
              [126.822239907717687, 37.591015966347328],
              [126.822140522995184, 37.591078417950044],
              [126.822785016995553, 37.591297626281765],
              [126.823148973624043, 37.591421414256089],
              [126.824196997674974, 37.591778370332918],
              [126.825284276844741, 37.592147157056004],
              [126.827597067411546, 37.592220809239279],
              [126.828350056228629, 37.592244783613793],
              [126.829939356828021, 37.592295710258149],
              [126.830945310552437, 37.592327931557087],
              [126.831116699508073, 37.592371876003938],
              [126.831518751310497, 37.592466356060029],
              [126.831535896305994, 37.593011637443446],
              [126.831546645911303, 37.593328863341213],
              [126.83154661166013, 37.593362958004626],
              [126.83150833307586, 37.593908069613967],
              [126.831481741529174, 37.594166023896591],
              [126.831458764272185, 37.594351190484005],
              [126.8314578261554, 37.594356530094906],
              [126.831434859426508, 37.59448732992432],
              [126.831280259876635, 37.595378027093268],
              [126.831414362851433, 37.595698728744232],
              [126.831571561289252, 37.596073718717186],
              [126.831633014147641, 37.596199916699106],
              [126.831705694208296, 37.596351070419701],
              [126.83178659837057, 37.596512383646569],
              [126.831794913170427, 37.596524529356593],
              [126.831906811026869, 37.596654152134811],
              [126.831965915060778, 37.596722749377662],
              [126.832114740300952, 37.596853640939585],
              [126.832130366678214, 37.596867385510073],
              [126.832459893551345, 37.596912085784169],
              [126.832515204133898, 37.596912508573375],
              [126.832734241516462, 37.596915147829108],
              [126.832816980036924, 37.596915491196235],
              [126.833314554961447, 37.596821481424982],
              [126.833769698136678, 37.596732373056426],
              [126.833794888953292, 37.59672744163317],
              [126.8339887003565, 37.596695821490293],
              [126.834030743871082, 37.596688962518158],
              [126.834141720882698, 37.596670181108294],
              [126.834320814564009, 37.596639475162554],
              [126.834364915093246, 37.596627664916141],
              [126.834700617039928, 37.596537644000875],
              [126.834980239979004, 37.59645940929596],
              [126.835231935544485, 37.596385398923623],
              [126.835517011143835, 37.596299459285902],
              [126.835610461369626, 37.596270417046831],
              [126.835799322523357, 37.596213216774579],
              [126.836234867026064, 37.596101729163372],
              [126.836594840328843, 37.595992519188158],
              [126.837085437886529, 37.595827638941579],
              [126.837422333497301, 37.595733429340221],
              [126.837661482332763, 37.595670641573683],
              [126.838059247454112, 37.595582551995001],
              [126.838404489595533, 37.595533214361026],
              [126.838832278813385, 37.595555305805568],
              [126.839114795153023, 37.595572452397626],
              [126.839316526748419, 37.59565134014408],
              [126.839473040301883, 37.595714496738211],
              [126.839719839752448, 37.59580497662926],
              [126.839893440746877, 37.595825184032094],
              [126.840024966257999, 37.595841759669831],
              [126.84023493804726, 37.595836894666427],
              [126.840379552232662, 37.595814152864364],
              [126.840597964777515, 37.59573567535886],
              [126.840816575500696, 37.595651374115896],
              [126.841023128207553, 37.595484639193948],
              [126.841108918766608, 37.595403838329261],
              [126.841245683946482, 37.595201727098285],
              [126.841326040396297, 37.595082976502553],
              [126.841492533056737, 37.594920668055885],
              [126.841588849673926, 37.594828888689804],
              [126.841810016931262, 37.594621610628671],
              [126.841880904798671, 37.594555789216642],
              [126.84226727011368, 37.594190899683532],
              [126.842322847242713, 37.594109849311678],
              [126.842328146782151, 37.593711307636696],
              [126.842309284066346, 37.593470909685223],
              [126.842288201304186, 37.59321455109211],
              [126.84226744386936, 37.592913522227953],
              [126.842328431464679, 37.592801925022606],
              [126.842375305173661, 37.592717730913556],
              [126.842414048737723, 37.59267143237399],
              [126.842541879048468, 37.59251867292717],
              [126.842976394815366, 37.592166300076762],
              [126.843022946840478, 37.591998093863985],
              [126.843028178242548, 37.591806048004642],
              [126.843024885694575, 37.591559009167909],
              [126.843026264748872, 37.591475322262298],
              [126.843054218510147, 37.591219847757593],
              [126.843145921404698, 37.59111843129832],
              [126.843272193011202, 37.591030817310269],
              [126.843391259640285, 37.591016252557125],
              [126.843597230481876, 37.591025324578951],
              [126.843786490038823, 37.591069158051511],
              [126.844041421222229, 37.591126493210389],
              [126.844183227550161, 37.591184275502037],
              [126.844320990117183, 37.591254734103018],
              [126.844601543784464, 37.591480551383675],
              [126.844698866845391, 37.591646895491493],
              [126.844786391294875, 37.591807413857758],
              [126.844929419231406, 37.592354077502954],
              [126.845009466908181, 37.592654022617793],
              [126.845013770357014, 37.592670154298148],
              [126.845024494156917, 37.592771970279919],
              [126.845092505935526, 37.593428554524941],
              [126.84518592995596, 37.593912551922507],
              [126.845181946959372, 37.59402530331834],
              [126.845164426836192, 37.594442426019619],
              [126.845128216330636, 37.594734117685462],
              [126.845084578547599, 37.595090496720829],
              [126.845006362528451, 37.595493645277628],
              [126.844791537107255, 37.595829076937186],
              [126.844594232536679, 37.595934652841045],
              [126.844288267868819, 37.595990752489833],
              [126.843896286002149, 37.59601409092091],
              [126.84346270640485, 37.596065732977578],
              [126.84331915891697, 37.596139867895104],
              [126.843299323444285, 37.596214417047904],
              [126.843264700485577, 37.596440284131852],
              [126.843299644943244, 37.596549431243069],
              [126.843396610588201, 37.596830199889418],
              [126.843507858058814, 37.596966316673679],
              [126.843567217118107, 37.597036786146134],
              [126.843664806030347, 37.597152642280911],
              [126.843729357319447, 37.597199989716394],
              [126.843810800924331, 37.597259729773867],
              [126.843996720368025, 37.597367888965969],
              [126.844198208459389, 37.597472048616744],
              [126.844487789089001, 37.597551876231712],
              [126.844694825002634, 37.597602514743031],
              [126.844816927573717, 37.597623993687037],
              [126.844897445380113, 37.597609756970492],
              [126.845024297019393, 37.597560129482844],
              [126.84509099052238, 37.597489609467608],
              [126.845138929875219, 37.597438918413069],
              [126.845203666424567, 37.597292176761478],
              [126.845277240803, 37.597117253333394],
              [126.845319576978667, 37.597025577085013],
              [126.845367474315381, 37.596751677787147],
              [126.845365191663149, 37.596630017121612],
              [126.845309004889074, 37.596488615619016],
              [126.845237431695665, 37.596296666489742],
              [126.845233751063816, 37.596172458991965],
              [126.845253422656484, 37.596133714199951],
              [126.845305237269841, 37.596052913773747],
              [126.845459554219502, 37.595997533487754],
              [126.845678907050967, 37.595940755967469],
              [126.846010699569277, 37.595898181580523],
              [126.846242614174756, 37.595887242500268],
              [126.846385085210088, 37.595898747584094],
              [126.846655366725386, 37.595917769686594],
              [126.84672120993369, 37.595956859193876],
              [126.846918212913991, 37.596066775981825],
              [126.846963541700092, 37.596098864721],
              [126.8470978846279, 37.59621250222073],
              [126.84729578683303, 37.59641323033383],
              [126.84734937082878, 37.596519219214699],
              [126.847351973847765, 37.596529350213252],
              [126.847377451843784, 37.596673135341533],
              [126.847378225480341, 37.5968797485396],
              [126.847360775955011, 37.596949776948897],
              [126.847335017710165, 37.596948304135623],
              [126.847243655982538, 37.597252704855073],
              [126.847150471312887, 37.597439196962966],
              [126.847066132804855, 37.597616940884294],
              [126.846639289590939, 37.597773647465829],
              [126.846332158159768, 37.597891205349974],
              [126.846261651555153, 37.597918192021069],
              [126.846105971342311, 37.598159504283785],
              [126.846109991638542, 37.598399105399771],
              [126.846119064511825, 37.598604254775744],
              [126.846256576279643, 37.598900635078842],
              [126.846361021188329, 37.599017357982071],
              [126.846527686986178, 37.599153905905212],
              [126.846607959589491, 37.599219674696606],
              [126.846912173057717, 37.599346832648088],
              [126.847319364631645, 37.599473971685832],
              [126.847511520487117, 37.599486168409285],
              [126.847769540244371, 37.599333467002857],
              [126.847774270476407, 37.599330666553158],
              [126.848236424965705, 37.599019810595181],
              [126.848410856429325, 37.598958390457675],
              [126.848674960702695, 37.598926497024671],
              [126.848678071646304, 37.598927903152678],
              [126.848945077961886, 37.599048766515345],
              [126.84910449960239, 37.599229914951472],
              [126.84918970322947, 37.599466471888128],
              [126.849154372682491, 37.599672425315006],
              [126.849037179572591, 37.599873817126714],
              [126.848978058540624, 37.600140629084507],
              [126.848967518916538, 37.600188168713736],
              [126.849013564850551, 37.60029110294262],
              [126.849067524967083, 37.600342420713055],
              [126.849174634210172, 37.600444286472609],
              [126.849307419789554, 37.600505181951966],
              [126.849534931300141, 37.600609522842646],
              [126.849793548403341, 37.600834894852063],
              [126.849825548992868, 37.600862786339306],
              [126.849776335606109, 37.601252477426328],
              [126.849720816821517, 37.601484609006363],
              [126.849649967764989, 37.601599221888122],
              [126.84958848593341, 37.60169720712387],
              [126.849492593572236, 37.601850032835188],
              [126.849412092559987, 37.602026862967904],
              [126.849414409167764, 37.602036502909058],
              [126.84944907942041, 37.602180799636983],
              [126.849671180316435, 37.602317368345993],
              [126.849694918431254, 37.602331967397866],
              [126.84991228108008, 37.602369760971115],
              [126.850176233122852, 37.602294822802044],
              [126.850196416524895, 37.602276321344299],
              [126.850448020896053, 37.602045664648301],
              [126.850466195947646, 37.60191780108962],
              [126.850419147213884, 37.601708624136336],
              [126.850413057305886, 37.601460249965918],
              [126.85045893538063, 37.601235433415127],
              [126.850667780612255, 37.600963803155281],
              [126.850674544478736, 37.600957074038149],
              [126.85075101932992, 37.600881051538003],
              [126.850934556754368, 37.600698622914834],
              [126.851069817099557, 37.60059402935012],
              [126.851308846527203, 37.600407452639743],
              [126.851459264932842, 37.600203751663464],
              [126.851635793239268, 37.599877493190192],
              [126.851844824222994, 37.59972872025412],
              [126.85222751043969, 37.599670731012417],
              [126.85250422675945, 37.599771079245699],
              [126.85264588942492, 37.599861160795321],
              [126.852821288630921, 37.600087231553793],
              [126.852871040628358, 37.600255974975688],
              [126.852784994423203, 37.600434834838886],
              [126.852490031466985, 37.600704704835771],
              [126.85221300510679, 37.601008681751189],
              [126.852072273867435, 37.601345524558994],
              [126.852048282967843, 37.601590984445352],
              [126.852123769921775, 37.601862754252657],
              [126.852286131811354, 37.602074824020342],
              [126.852531894708008, 37.602255791762417],
              [126.852692006136209, 37.602258442989935],
              [126.852916751235568, 37.602118444203022],
              [126.853101234653806, 37.602052064467046],
              [126.853184579267648, 37.602046412025246],
              [126.85330677664976, 37.602037386013613],
              [126.853571642324368, 37.602074848175135],
              [126.853769884341688, 37.60212771382632],
              [126.853900373115735, 37.602196910001361],
              [126.854035586187933, 37.602336073780009],
              [126.854120526127105, 37.602474560283504],
              [126.854133669803929, 37.602528022865677],
              [126.854145741930566, 37.60263126398528],
              [126.85414080479687, 37.602693903266122],
              [126.854139448609246, 37.602727201117396],
              [126.853996998196706, 37.603088831591073],
              [126.853889201311631, 37.603082696457633],
              [126.853838993665008, 37.603079975345544],
              [126.85378742828803, 37.603077053891759],
              [126.853250559099891, 37.602883750789587],
              [126.853116040438877, 37.602887121817794],
              [126.85304084856638, 37.60289679469355],
              [126.852363013441007, 37.603050954013078],
              [126.852027039128274, 37.603128827572341],
              [126.851946714322565, 37.603147445267666],
              [126.851744516242874, 37.60327797580122],
              [126.851740489535189, 37.603432985272576],
              [126.851736144694129, 37.603589795664064],
              [126.851840968828128, 37.603734660881635],
              [126.851972040060133, 37.603835296463203],
              [126.852241439808154, 37.603926563823563],
              [126.852568596197443, 37.603892280382809],
              [126.852948034059978, 37.603894020663589],
              [126.853364706076718, 37.603912753831885],
              [126.853581759904642, 37.604068940262415],
              [126.853621027003669, 37.604154755212988],
              [126.853649629002604, 37.604217264482628],
              [126.853691216995742, 37.604301576072473],
              [126.853666759852672, 37.60448238981968],
              [126.853562992211593, 37.604877467348118],
              [126.853543186032255, 37.604956728685266],
              [126.853419937171182, 37.605419778125366],
              [126.853289577547557, 37.605689036410041],
              [126.85310772648613, 37.606142595014681],
              [126.853179085015711, 37.606233980484468],
              [126.853213363802183, 37.606274989848949],
              [126.853290732476424, 37.606361574326783],
              [126.853563653793103, 37.606487414273673],
              [126.85368591168077, 37.606513082444387],
              [126.853888827921637, 37.606553334071499],
              [126.854056693470724, 37.606586634389032],
              [126.854192098786484, 37.606698867963644],
              [126.854297174832539, 37.607002394433863],
              [126.854289287081045, 37.607183267342187],
              [126.854280489037691, 37.607401283805309],
              [126.854274327639146, 37.607561471484253],
              [126.854267863588817, 37.607715251711532],
              [126.854495517099835, 37.607937194550324],
              [126.854768162082365, 37.608062346983765],
              [126.855295137832428, 37.607875732122608],
              [126.855621108329544, 37.607725860982335],
              [126.855860895997807, 37.607713895187324],
              [126.856137404371822, 37.607822793744994],
              [126.856401013002397, 37.607925849152323],
              [126.856986349539227, 37.608092032069059],
              [126.857520479988594, 37.608243689514801],
              [126.857720731395659, 37.608322290811628],
              [126.857787646672207, 37.608348528719681],
              [126.858012239184632, 37.60854749563098],
              [126.858074435600059, 37.608581813415306],
              [126.858309506443973, 37.608711520453788],
              [126.858352852195623, 37.608817901010873],
              [126.858480945236693, 37.608760014212677],
              [126.858694790823805, 37.608663450114847],
              [126.859481523415567, 37.608314177585449],
              [126.859528148078141, 37.608293482697718],
              [126.859591181723189, 37.608265493999461],
              [126.859591964183068, 37.608265146724733]
            ]
          ]
        ]
      },
      id: '31101680'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '주엽2동',
        ADM_CD: '31104560'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.760789823632237, 37.678293853682199],
              [126.761046938253159, 37.678184651275323],
              [126.761049518405713, 37.678189542683235],
              [126.76106933151209, 37.678227102261175],
              [126.761080415753511, 37.678248112083367],
              [126.761264379423565, 37.678167857322826],
              [126.761556501971327, 37.678040400521034],
              [126.762300904046342, 37.677715605002234],
              [126.762433293985879, 37.677657858035978],
              [126.762628625728496, 37.677572655472702],
              [126.762875537131151, 37.677464928309341],
              [126.763597686743992, 37.67714984949032],
              [126.763712426151343, 37.677099785480827],
              [126.763893677047804, 37.6770207020895],
              [126.764333961932621, 37.676828598253699],
              [126.764421810677192, 37.676790267743023],
              [126.764690158015398, 37.676673179533267],
              [126.764731085744657, 37.676655322664466],
              [126.764742046576856, 37.676650540091146],
              [126.765239006936639, 37.67643368258291],
              [126.765468606488724, 37.676333497898952],
              [126.766232577097739, 37.676000139305778],
              [126.766178074803904, 37.675921194202346],
              [126.765969584601407, 37.675619197190045],
              [126.76594930351709, 37.675589820393796],
              [126.765646443123899, 37.675151124219454],
              [126.765085030035138, 37.674337896634064],
              [126.765079014558424, 37.674340518947794],
              [126.764919420893023, 37.674410092376981],
              [126.764753694876717, 37.674482378189879],
              [126.764588083963901, 37.674554573451928],
              [126.764424476635853, 37.674317650412064],
              [126.764358103442845, 37.674219781994957],
              [126.764289991322073, 37.674119346545659],
              [126.764126271358649, 37.673882422380309],
              [126.76429188233719, 37.673810227754657],
              [126.764457493006404, 37.673738031990943],
              [126.764603174508593, 37.673674523944463],
              [126.764623101071805, 37.673665837778785],
              [126.764585823005433, 37.673611877584264],
              [126.764364713480532, 37.673291836554917],
              [126.764050145246884, 37.672836513105111],
              [126.764035553176527, 37.672842876410364],
              [126.76355331971267, 37.673053186398683],
              [126.763155081967938, 37.672476631348282],
              [126.763116901204498, 37.672421355426316],
              [126.762939086378111, 37.672163976159403],
              [126.76282371872577, 37.671996986587232],
              [126.762722494371431, 37.671850467793263],
              [126.762513546224, 37.671548018260538],
              [126.762505031119986, 37.671535691722916],
              [126.762121221132077, 37.670980128568807],
              [126.762067023529951, 37.670901676462321],
              [126.762012073720115, 37.670822133531388],
              [126.762011215054031, 37.67082250671352],
              [126.761755243650782, 37.670934181038035],
              [126.761516440641373, 37.671038364162349],
              [126.761314829782449, 37.670746039716377],
              [126.761110513533779, 37.670449744773748],
              [126.761356691636422, 37.670342320623412],
              [126.761417164698514, 37.670315931890102],
              [126.761460506310868, 37.67029701776827],
              [126.761348229242415, 37.670124056798528],
              [126.761221285177157, 37.669928501455502],
              [126.761093367542884, 37.669984327453541],
              [126.760858014740862, 37.67008703829709],
              [126.760653476666235, 37.669790833991435],
              [126.760451644364181, 37.669498417418779],
              [126.760396258659284, 37.66941817066806],
              [126.760342666028762, 37.669340523731087],
              [126.76019140309873, 37.669121418253141],
              [126.759906693137282, 37.668709011571515],
              [126.759715707490486, 37.668432360993428],
              [126.759636530998023, 37.668317670164527],
              [126.759451442521737, 37.66804955833399],
              [126.759184070581682, 37.667662253281698],
              [126.759152661054742, 37.667616661826202],
              [126.759102395408831, 37.667543697748371],
              [126.758666108497422, 37.666911648953288],
              [126.758598316145182, 37.666813437528788],
              [126.758392670050185, 37.666515512444512],
              [126.758243809464759, 37.666299852654404],
              [126.758189888015494, 37.666221626705308],
              [126.75818546395881, 37.666222518229112],
              [126.758090258697393, 37.666084112696275],
              [126.758088576561136, 37.666081672151414],
              [126.757995052889285, 37.665945797213624],
              [126.757947873078464, 37.665966375913733],
              [126.757225988241032, 37.666281217251388],
              [126.756523109644277, 37.666587764602731],
              [126.755791068320065, 37.666907022533209],
              [126.75521631583689, 37.667157679961988],
              [126.754210081807742, 37.667596498804159],
              [126.753516585446974, 37.667898925985618],
              [126.753085796781221, 37.668086784911111],
              [126.753059761576765, 37.668098138019658],
              [126.753007198013989, 37.66812106011708],
              [126.752919003143987, 37.668159527822674],
              [126.752779490346668, 37.66818950438509],
              [126.752681845175815, 37.668232083355775],
              [126.752519784023363, 37.668302750197029],
              [126.752322969995973, 37.66841945953027],
              [126.751879850846464, 37.668612661795152],
              [126.751701502411237, 37.668690421238878],
              [126.751277828574331, 37.668875141478189],
              [126.751072431790064, 37.668964691487595],
              [126.751048027703902, 37.668975360487764],
              [126.750990029368296, 37.66899884293926],
              [126.750917950845746, 37.669030404610233],
              [126.750968611009171, 37.669103801859883],
              [126.751109588139428, 37.669308039628326],
              [126.751276655071536, 37.669550154122078],
              [126.751722058433188, 37.670195636175237],
              [126.75175057951175, 37.670236966028781],
              [126.752338657345703, 37.671089189040508],
              [126.752389168043351, 37.671162389132675],
              [126.753092229913662, 37.672181211694756],
              [126.753313398786389, 37.672501704904761],
              [126.753794263530651, 37.673198512855862],
              [126.7539052930303, 37.673359318672929],
              [126.754012434390816, 37.673514489970756],
              [126.754485919455647, 37.67420056140439],
              [126.754938411228196, 37.674856199486882],
              [126.75500320219966, 37.674950079314002],
              [126.755791896025684, 37.676092825205153],
              [126.755823807465944, 37.676139057898261],
              [126.756535375639373, 37.677170023302963],
              [126.756711062701072, 37.677424563905085],
              [126.756778619084727, 37.677522455716847],
              [126.756847236456707, 37.677621888221601],
              [126.757310990357226, 37.678293697263989],
              [126.757490131658713, 37.678553203074983],
              [126.757661921789008, 37.678802057405036],
              [126.757703667473606, 37.678862530144649],
              [126.758150005014755, 37.679509081168092],
              [126.758159262471125, 37.679522494258158],
              [126.758282437445246, 37.679468780615885],
              [126.758581807198183, 37.679338178050273],
              [126.759164015846679, 37.67908418098596],
              [126.75921989987728, 37.67905980070617],
              [126.759738006283641, 37.678833765359471],
              [126.759787157486386, 37.67881232140936],
              [126.760320244705085, 37.678579745592799],
              [126.760352675570545, 37.678565597273519],
              [126.760796915903441, 37.678371792932893],
              [126.760836648445505, 37.678354459012454],
              [126.760831178958014, 37.678347380013037],
              [126.760827365436214, 37.678342443916037],
              [126.760789823632237, 37.678293853682199]
            ]
          ]
        ]
      },
      id: '31104560'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '대화동',
        ADM_CD: '31104570'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.761350361241171, 37.687116000956436],
              [126.76186597118614, 37.686378705782481],
              [126.761855345355215, 37.686368862663784],
              [126.761994371750504, 37.686181835718088],
              [126.762100090968502, 37.686058347788375],
              [126.762224059617779, 37.685934716157085],
              [126.762416894831446, 37.685689954227506],
              [126.762382928931132, 37.685640782120949],
              [126.761928039251899, 37.68498193960658],
              [126.761877726751905, 37.684909070305665],
              [126.761793499485449, 37.684787106536312],
              [126.761714104778576, 37.684672143763351],
              [126.761696767969823, 37.684647032627311],
              [126.761542667123067, 37.68442381412828],
              [126.761210204292496, 37.683942227195125],
              [126.761179603305337, 37.683897901478652],
              [126.760830002316311, 37.683391482887416],
              [126.76056026275657, 37.68300074543297],
              [126.760520349471662, 37.682942925181585],
              [126.760237447515422, 37.682533111831994],
              [126.759950899488715, 37.682118013906226],
              [126.759892693408432, 37.682033695215956],
              [126.759437788754141, 37.681374697679175],
              [126.758983328062101, 37.680716332062786],
              [126.758762221439014, 37.680396015951153],
              [126.758615983710314, 37.680184159937305],
              [126.758182717500432, 37.679556474641394],
              [126.758159262471125, 37.679522494258158],
              [126.758150005014755, 37.679509081168092],
              [126.757703667473606, 37.678862530144649],
              [126.757661921789008, 37.678802057405036],
              [126.757490131658713, 37.678553203074983],
              [126.757310990357226, 37.678293697263989],
              [126.756847236456707, 37.677621888221601],
              [126.756778619084727, 37.677522455716847],
              [126.756711062701072, 37.677424563905085],
              [126.756535375639373, 37.677170023302963],
              [126.755823807465944, 37.676139057898261],
              [126.755791896025684, 37.676092825205153],
              [126.75500320219966, 37.674950079314002],
              [126.754938411228196, 37.674856199486882],
              [126.754485919455647, 37.67420056140439],
              [126.754012434390816, 37.673514489970756],
              [126.7539052930303, 37.673359318672929],
              [126.753794263530651, 37.673198512855862],
              [126.753313398786389, 37.672501704904761],
              [126.753092229913662, 37.672181211694756],
              [126.752389168043351, 37.671162389132675],
              [126.752338657345703, 37.671089189040508],
              [126.75175057951175, 37.670236966028781],
              [126.751722058433188, 37.670195636175237],
              [126.751276655071536, 37.669550154122078],
              [126.751109588139428, 37.669308039628326],
              [126.750968611009171, 37.669103801859883],
              [126.750917950845746, 37.669030404610233],
              [126.750990029368296, 37.66899884293926],
              [126.751048027703902, 37.668975360487764],
              [126.751072431790064, 37.668964691487595],
              [126.751277828574331, 37.668875141478189],
              [126.751701502411237, 37.668690421238878],
              [126.751879850846464, 37.668612661795152],
              [126.752322969995973, 37.66841945953027],
              [126.752519784023363, 37.668302750197029],
              [126.752681845175815, 37.668232083355775],
              [126.752779490346668, 37.66818950438509],
              [126.752919003143987, 37.668159527822674],
              [126.753007198013989, 37.66812106011708],
              [126.753059761576765, 37.668098138019658],
              [126.753085796781221, 37.668086784911111],
              [126.753516585446974, 37.667898925985618],
              [126.754210081807742, 37.667596498804159],
              [126.75521631583689, 37.667157679961988],
              [126.755791068320065, 37.666907022533209],
              [126.756523109644277, 37.666587764602731],
              [126.757225988241032, 37.666281217251388],
              [126.757947873078464, 37.665966375913733],
              [126.757995052889285, 37.665945797213624],
              [126.757977227976397, 37.665919814432534],
              [126.757887436975565, 37.665789437519656],
              [126.757363129425258, 37.665027483941152],
              [126.756786496731394, 37.664189466495202],
              [126.756661064379529, 37.664007209270281],
              [126.756382905514371, 37.663602997556879],
              [126.756337882847504, 37.663548384703368],
              [126.756325336138573, 37.663533162482508],
              [126.756320705011703, 37.663527548690723],
              [126.756318655522961, 37.663524551490738],
              [126.756237192579491, 37.663405626627551],
              [126.756233064372353, 37.663399608605907],
              [126.756206929639248, 37.663361496334723],
              [126.756172044417909, 37.663310644566955],
              [126.756117879708825, 37.663231661709347],
              [126.755966779149645, 37.663011357467873],
              [126.755763889202882, 37.66271552353588],
              [126.755714438930113, 37.662643424791682],
              [126.755524273215187, 37.662366429049285],
              [126.755483438008284, 37.662308053135128],
              [126.755482272309351, 37.662308183679457],
              [126.755454556440725, 37.662268168480672],
              [126.755362268904079, 37.662134913948854],
              [126.755335665266827, 37.662096501083738],
              [126.755288520178496, 37.66202843480557],
              [126.755063912197912, 37.662126079920462],
              [126.754417089885166, 37.662407272383504],
              [126.754379575866622, 37.66242364391227],
              [126.75416257546911, 37.662518343895918],
              [126.754161263353311, 37.662518489725144],
              [126.753973425771832, 37.662600153164256],
              [126.753947195991955, 37.662611555731409],
              [126.753581738673432, 37.662770432458871],
              [126.753256566481824, 37.662911802541913],
              [126.753234848247445, 37.662921240688306],
              [126.753102885032064, 37.662978610712109],
              [126.752966465634842, 37.663037920851345],
              [126.75283457145602, 37.663095262166898],
              [126.752715814744377, 37.663146910896074],
              [126.752500820123799, 37.663240390195455],
              [126.752164578415659, 37.663386574053817],
              [126.752116788525299, 37.663409087108143],
              [126.752092393166407, 37.663417953754227],
              [126.751963343187299, 37.663474081816176],
              [126.751785603050152, 37.663544441906822],
              [126.75093556887191, 37.663920872742878],
              [126.750661238384168, 37.664042355341621],
              [126.750386369465048, 37.664164074526816],
              [126.750385251076239, 37.664164570356569],
              [126.749804468805891, 37.664421880766142],
              [126.749758294243279, 37.664442265193706],
              [126.749739713901235, 37.664450426548811],
              [126.749739111447994, 37.664450691310542],
              [126.749671816000443, 37.66447987435366],
              [126.748999389302398, 37.664771475274399],
              [126.748540620779522, 37.664970419606448],
              [126.748461845260266, 37.665004580718659],
              [126.748569847546719, 37.665156415053872],
              [126.748402990863255, 37.665230685270672],
              [126.748322044142597, 37.66526671621299],
              [126.748300656481121, 37.665259323368524],
              [126.748293147674175, 37.665249489865687],
              [126.748192690396039, 37.665293122500216],
              [126.748178802526127, 37.665299153950251],
              [126.748026080153807, 37.665365487454928],
              [126.74796529905575, 37.665391888106328],
              [126.747867642908432, 37.665434302609484],
              [126.747704379028818, 37.665505214783806],
              [126.747526280509163, 37.665582568472821],
              [126.746690959115497, 37.665945364873181],
              [126.746249906984758, 37.666136918860467],
              [126.746068090554232, 37.666215884266933],
              [126.745982535905497, 37.666253042331057],
              [126.744296380264743, 37.666985332484828],
              [126.741076985536225, 37.668383391507824],
              [126.740510671731684, 37.668629303808963],
              [126.740256470509962, 37.668739686359054],
              [126.74023745124336, 37.668723516732193],
              [126.740154373479328, 37.668658191911909],
              [126.740126450193671, 37.668637990980855],
              [126.74023029274197, 37.668535918422357],
              [126.740143706712828, 37.668406341084285],
              [126.740143472216914, 37.668405986255848],
              [126.740031314180456, 37.668391500458462],
              [126.73989105895339, 37.668307849105581],
              [126.739730296958911, 37.668223073948717],
              [126.739720678481689, 37.668218279113084],
              [126.739710604122877, 37.668214379954613],
              [126.739067203812652, 37.66800788786675],
              [126.738913552278234, 37.667931597439164],
              [126.738325275946394, 37.667747747570594],
              [126.738167010427745, 37.667725410073388],
              [126.737771091434581, 37.667601544241535],
              [126.737768014179863, 37.667607304523635],
              [126.737674948587042, 37.667578082506758],
              [126.737751203669177, 37.667424369303035],
              [126.737458089056375, 37.667332444404451],
              [126.737490149586549, 37.667272420926054],
              [126.737256377529462, 37.667193332705715],
              [126.73722157895908, 37.667258304689909],
              [126.736924276597023, 37.66716510936476],
              [126.73691424594287, 37.667185319676392],
              [126.736914114880733, 37.667185717212156],
              [126.736848131713984, 37.667318551449561],
              [126.736800467975726, 37.667303579452451],
              [126.736803775730777, 37.667297461060173],
              [126.736296912443933, 37.667138291377888],
              [126.736042583974111, 37.66703942336909],
              [126.73582940598439, 37.666972178378124],
              [126.735706552735337, 37.666969113724122],
              [126.735599611897001, 37.666951022503248],
              [126.735407510149329, 37.666918524955619],
              [126.735429416803626, 37.6669731734263],
              [126.735434463017711, 37.666988591680067],
              [126.735462828933564, 37.667075327566742],
              [126.735515094587569, 37.667202052220375],
              [126.735552949167698, 37.667293836591746],
              [126.735586321078983, 37.667375178941406],
              [126.735599130005184, 37.667406380949046],
              [126.735679942239756, 37.667470081824604],
              [126.73596919961814, 37.667563264219481],
              [126.736381231722206, 37.66784087770322],
              [126.736420513965172, 37.667956356836875],
              [126.736435007140074, 37.667998956939577],
              [126.736440769449501, 37.668015890397925],
              [126.736501814475744, 37.66819531937174],
              [126.736508867002229, 37.668216031401876],
              [126.736514712070942, 37.668233190721992],
              [126.736515466063864, 37.668235407443177],
              [126.736529195479207, 37.668275758300872],
              [126.736542531776365, 37.668314970948337],
              [126.736559943155001, 37.668346110706246],
              [126.736593663536908, 37.66840642631221],
              [126.736682190466666, 37.668564764602806],
              [126.736716424748977, 37.668589979772349],
              [126.736741712161631, 37.668608610572804],
              [126.736764262669652, 37.668625222725616],
              [126.736831630386035, 37.668804757326249],
              [126.736825133142972, 37.668890800394088],
              [126.736822593094274, 37.668924475313574],
              [126.736804560472578, 37.669162018777463],
              [126.73679306341306, 37.669313428385244],
              [126.736786088785436, 37.66940533144259],
              [126.736783708718605, 37.669437220976626],
              [126.736760340481723, 37.669537627356569],
              [126.736745492946525, 37.669601652999624],
              [126.736731265194734, 37.669620440880912],
              [126.73660120847579, 37.669792152398777],
              [126.736559362476299, 37.669855754496503],
              [126.736497502194268, 37.6699497709563],
              [126.736483258066883, 37.6699713618058],
              [126.736489908936136, 37.67001336311818],
              [126.736484340474732, 37.670022980209339],
              [126.736471090191159, 37.670045843845628],
              [126.736479540916761, 37.670123888886224],
              [126.736491540340111, 37.670128600252895],
              [126.736587920808134, 37.670341445377474],
              [126.736692090794534, 37.670440695179906],
              [126.736779958068553, 37.670524413299944],
              [126.736948871592006, 37.67077944417121],
              [126.736953808309408, 37.670786895777333],
              [126.736983879887205, 37.670805474553084],
              [126.737015109662934, 37.670824783672529],
              [126.737034776372568, 37.670871948089513],
              [126.737041410635982, 37.670919263441945],
              [126.737052439536939, 37.670973707821553],
              [126.737045393997221, 37.671015291514571],
              [126.737034146058662, 37.671081692908778],
              [126.737053322532063, 37.671171652430537],
              [126.737060488121003, 37.671196535558508],
              [126.737068396531981, 37.671311672665212],
              [126.737068572288919, 37.671314220031185],
              [126.737067568521283, 37.671318120760347],
              [126.73703684990511, 37.671437581289297],
              [126.737030121820197, 37.671468088907545],
              [126.737013695093253, 37.671542582191918],
              [126.736998085115815, 37.671597328329632],
              [126.736977710767391, 37.671685035557331],
              [126.736983475659187, 37.671689463182865],
              [126.737092781797884, 37.671774488768925],
              [126.737126076098221, 37.671808827202568],
              [126.737127451778349, 37.671810244863948],
              [126.737183910611719, 37.671839203515653],
              [126.737223837552477, 37.671831092992541],
              [126.737365965315263, 37.671866189263604],
              [126.737356694102758, 37.671892026037966],
              [126.737343986011041, 37.671927495526347],
              [126.737366159070447, 37.671939438985795],
              [126.737368526265982, 37.671940147407682],
              [126.737519018014751, 37.671985010201809],
              [126.737750774884574, 37.672029136200997],
              [126.737817827179398, 37.672043251577826],
              [126.737944317472525, 37.672077140176846],
              [126.738041640739496, 37.672122134649641],
              [126.738142953424628, 37.672223901015045],
              [126.738180135353403, 37.672254707655867],
              [126.738275343984114, 37.672320689031295],
              [126.738331554428797, 37.672348292649303],
              [126.738346856036443, 37.672355807527929],
              [126.738365117629897, 37.672375041553657],
              [126.738375315313192, 37.672385782641157],
              [126.738368895900081, 37.672387910663225],
              [126.738334803147012, 37.672399212067361],
              [126.738331052971674, 37.672400456906857],
              [126.737853407108091, 37.672559045508024],
              [126.737789941333929, 37.672595002720158],
              [126.737610579628225, 37.672696620924604],
              [126.73757222797407, 37.672707436705466],
              [126.737541341271765, 37.672722773082576],
              [126.7375509346831, 37.672830462603351],
              [126.737553562307582, 37.672860134814741],
              [126.737579944719641, 37.673158127563781],
              [126.737580798665277, 37.673167760981897],
              [126.737549250487817, 37.673722687062252],
              [126.737504921430343, 37.673855262204818],
              [126.737472578730376, 37.673951991829604],
              [126.737464239506409, 37.673976931505912],
              [126.737350126928391, 37.674351389567725],
              [126.737519069106696, 37.674402851260574],
              [126.737843121011096, 37.674457318822249],
              [126.737902085715831, 37.674467227708035],
              [126.738161701249552, 37.674488348288008],
              [126.738185938317912, 37.674526423364192],
              [126.73821528423359, 37.674561807646697],
              [126.738258584045013, 37.674561993584085],
              [126.738694936625393, 37.675060032138802],
              [126.738698802466686, 37.675065458701411],
              [126.738736330585752, 37.675118146024673],
              [126.739098018184322, 37.675543308916524],
              [126.739680107718982, 37.676227372620652],
              [126.739742159672886, 37.676300291905029],
              [126.73972165317474, 37.676306116530895],
              [126.739716969830994, 37.676307444627788],
              [126.739708005169746, 37.676312820890786],
              [126.739680138020773, 37.676329523851386],
              [126.739679250480293, 37.676330931398333],
              [126.739651517799089, 37.676346679820426],
              [126.739398948715305, 37.676498074502504],
              [126.739156150006281, 37.676643610901138],
              [126.739005698841922, 37.676683909800573],
              [126.738972383251095, 37.676712198144521],
              [126.738903143879966, 37.6767709860328],
              [126.738659628149549, 37.676864438539837],
              [126.738493261407854, 37.676928282849971],
              [126.738401284740505, 37.676940692254391],
              [126.738220900830783, 37.677013898693176],
              [126.738065860102552, 37.67710166806463],
              [126.738063865780646, 37.677152746458695],
              [126.738030704313246, 37.677234210655413],
              [126.737949667709714, 37.677358634227581],
              [126.73794558097714, 37.677424575369187],
              [126.737753853368474, 37.677691825778616],
              [126.737743691727573, 37.677724938557496],
              [126.737700643492943, 37.677865231837792],
              [126.737725011090916, 37.678026289214188],
              [126.737758834277912, 37.678110426038259],
              [126.73777119576512, 37.678141176017434],
              [126.737803448546984, 37.678188280097629],
              [126.737826035793972, 37.678244279913237],
              [126.738049626021422, 37.678486960032338],
              [126.73825025228372, 37.678723553509116],
              [126.738634234714524, 37.679176343752019],
              [126.738845565939926, 37.679425545364928],
              [126.738887088741805, 37.679474510710278],
              [126.738918989485498, 37.679512129432574],
              [126.738880038742309, 37.679527643926178],
              [126.738797295548366, 37.679560601090685],
              [126.738725727811087, 37.679581509400613],
              [126.738267809371777, 37.679715288042807],
              [126.737945395054552, 37.680094676560437],
              [126.737853347197685, 37.68020299347787],
              [126.737722994523011, 37.680356372025805],
              [126.737629280846349, 37.680437687104344],
              [126.737617358065421, 37.680448034680438],
              [126.737322943369392, 37.680579815515941],
              [126.737292032543209, 37.680601279702344],
              [126.737321657662747, 37.680741060313693],
              [126.737326975611481, 37.680766172093264],
              [126.737336298885324, 37.680810132912832],
              [126.737185705735811, 37.680982871859612],
              [126.737381454022653, 37.681096470994881],
              [126.737519620099661, 37.681176652479934],
              [126.737562017207011, 37.681201255882421],
              [126.737785747041997, 37.681331088633513],
              [126.737853688039948, 37.681353043108466],
              [126.738118123227068, 37.68149283377204],
              [126.73813806175734, 37.681517080063522],
              [126.738150857145357, 37.681532640166431],
              [126.738326088160477, 37.681550655555974],
              [126.738348831947221, 37.68155299265262],
              [126.738384246722504, 37.681577516414471],
              [126.738397911973365, 37.68158697928056],
              [126.738401966152452, 37.681586771119839],
              [126.738439863076252, 37.68158482024144],
              [126.738563059114625, 37.681625728022588],
              [126.738621040678737, 37.681674690656394],
              [126.738643375789181, 37.681706364779025],
              [126.738682138337722, 37.681725803193856],
              [126.738854878151528, 37.681812429913322],
              [126.738922831352539, 37.681830600117365],
              [126.739013698565046, 37.681778177258451],
              [126.73906766532825, 37.681747041527643],
              [126.739122987789216, 37.681747164134464],
              [126.739171169785223, 37.681749648971866],
              [126.739232589731827, 37.681752814058612],
              [126.739369596215582, 37.681799158513826],
              [126.739398884341, 37.681806990595369],
              [126.739464732897147, 37.681824598010579],
              [126.739691194721274, 37.681860387675506],
              [126.739716826658167, 37.681864439410518],
              [126.739823784954069, 37.681881345435357],
              [126.739817706274977, 37.681917018296517],
              [126.739774705199736, 37.682169276137685],
              [126.73970930571565, 37.682552852258773],
              [126.739661741796155, 37.682751743291107],
              [126.739648556500953, 37.682806881158129],
              [126.739639151127008, 37.682902902862345],
              [126.739656143469645, 37.682874108942372],
              [126.739757020070783, 37.683479574573738],
              [126.739812465482899, 37.683812365229841],
              [126.739948035878285, 37.683946279052293],
              [126.739992009301133, 37.683950339880347],
              [126.74004195608363, 37.683963516362432],
              [126.740299090940809, 37.684030897256399],
              [126.741092588578198, 37.68423883273023],
              [126.741116226159278, 37.684254382966095],
              [126.741151349748904, 37.684283434948611],
              [126.741190742363557, 37.684309686053197],
              [126.741295158365304, 37.684405868771698],
              [126.741381814702819, 37.684504686172701],
              [126.741384136488676, 37.684507334760525],
              [126.741463969889736, 37.684601949526517],
              [126.74178187759091, 37.684978742252959],
              [126.741786179602528, 37.684983840729217],
              [126.742329222448831, 37.685567691579678],
              [126.742332754821717, 37.68559572003258],
              [126.74233409385829, 37.68559820996466],
              [126.742390215185281, 37.685702374435706],
              [126.742402930398796, 37.685725973316032],
              [126.74284204796966, 37.68621532325912],
              [126.74288063454874, 37.686258322322942],
              [126.742994194956822, 37.686334253738032],
              [126.743209722698154, 37.686429687831023],
              [126.743303480317323, 37.686462417871262],
              [126.743579608915368, 37.686543658610944],
              [126.743887788787916, 37.686634330108106],
              [126.743932832090763, 37.686647581664914],
              [126.744317913734832, 37.68676087242401],
              [126.744594915648776, 37.686816165637623],
              [126.744625564444618, 37.686837584399846],
              [126.74465415979428, 37.68683043894822],
              [126.744663878467293, 37.686838928523088],
              [126.744826807464108, 37.68683550056975],
              [126.744872067968174, 37.686828030053796],
              [126.744965101551543, 37.68677489637961],
              [126.745023215639492, 37.686715711152296],
              [126.745190367665273, 37.686545458618106],
              [126.745871720899444, 37.685886348450687],
              [126.745969564745565, 37.685818987675546],
              [126.746167345793054, 37.685767159084705],
              [126.746319373481796, 37.685774137197093],
              [126.746498900332412, 37.685782383223511],
              [126.746731868057822, 37.685807545594123],
              [126.746837636949778, 37.685818972986503],
              [126.746917306629285, 37.68582752425278],
              [126.748560142849783, 37.686043373075059],
              [126.748720104813458, 37.686064392522901],
              [126.749152954685485, 37.686130730128411],
              [126.749926579265988, 37.686168872345611],
              [126.749991499057018, 37.686171560181954],
              [126.750949040261915, 37.686211141066352],
              [126.751327370218206, 37.686232216187008],
              [126.751342625018523, 37.686233066536253],
              [126.7513761395752, 37.686234933220064],
              [126.752093399466915, 37.686272950358308],
              [126.752350635911839, 37.686286576268209],
              [126.752369542291831, 37.686287579425546],
              [126.752378799832826, 37.686299493159808],
              [126.753363627266879, 37.686340760575135],
              [126.753441501850631, 37.686343988207049],
              [126.753584260446615, 37.686349996058851],
              [126.753781793518385, 37.686358308020843],
              [126.753827701858754, 37.686360206466745],
              [126.753826430212968, 37.686367591035875],
              [126.753818106647188, 37.686416045534465],
              [126.754086766178318, 37.686458865150264],
              [126.754307414435601, 37.686518972506072],
              [126.754307422459306, 37.686516358732767],
              [126.754482679933062, 37.686589164926232],
              [126.754720269185825, 37.686709283852068],
              [126.754868854009374, 37.686784401329085],
              [126.755060972768163, 37.686870160600513],
              [126.755107941728113, 37.68689112633556],
              [126.755111349416509, 37.686892645852282],
              [126.755154081811114, 37.686911735302289],
              [126.755315445755144, 37.686983818877515],
              [126.755643218862289, 37.687115663740116],
              [126.755829696593381, 37.687190672180201],
              [126.756680582527352, 37.687526880298584],
              [126.756925175584328, 37.68761568206363],
              [126.757706118270804, 37.687779564189967],
              [126.757820828669836, 37.687800468525815],
              [126.758056911024553, 37.68784349229292],
              [126.758622975704512, 37.687946648157805],
              [126.758704917370054, 37.687961584387672],
              [126.758850968810052, 37.687988202608288],
              [126.759002448950227, 37.688015802948001],
              [126.759072245374938, 37.688028524677556],
              [126.759083370693077, 37.688030556749283],
              [126.759111223167025, 37.688035632212937],
              [126.759197122064052, 37.687977606881745],
              [126.759328180017789, 37.687944117819534],
              [126.759429729938589, 37.687918170307455],
              [126.759620582881809, 37.687869459099886],
              [126.759855756918014, 37.687787862647724],
              [126.760098612206463, 37.687706034509723],
              [126.760666915355387, 37.687514546290728],
              [126.76071702103954, 37.687496905247912],
              [126.760884567201558, 37.68743791143828],
              [126.760895947457755, 37.687433907903255],
              [126.760992324175831, 37.687399976981823],
              [126.761000023692546, 37.687397267197092],
              [126.761233401151316, 37.687315146572665],
              [126.761247433312278, 37.68728802005726],
              [126.761258365340083, 37.687266885014857],
              [126.761265801772637, 37.687252505991474],
              [126.761296603460195, 37.687192923473972],
              [126.761350361241171, 37.687116000956436]
            ]
          ]
        ]
      },
      id: '31104570'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '송포동',
        ADM_CD: '31104580'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.751211576818307, 37.688116771353194],
              [126.751852912038231, 37.687911204323719],
              [126.751905921604532, 37.687925821108927],
              [126.754159978632686, 37.688549265861603],
              [126.754140552777585, 37.688594181872048],
              [126.754558170281229, 37.688678938055645],
              [126.754702235527375, 37.68879203846145],
              [126.754919802865189, 37.688893854443151],
              [126.755072075620106, 37.688959491457709],
              [126.755194844410582, 37.688998761338382],
              [126.75552445697484, 37.689091436933666],
              [126.755778938639111, 37.689173955513553],
              [126.755831645116388, 37.689178210674939],
              [126.755947720567292, 37.689187521877535],
              [126.756079530550579, 37.68919809673239],
              [126.756136703964756, 37.68915370874727],
              [126.75622490908421, 37.68908532868528],
              [126.756316852734329, 37.689017135271662],
              [126.756374702407626, 37.688974280970704],
              [126.756432340220826, 37.68892673935057],
              [126.756637333967106, 37.688853736093449],
              [126.756923202829668, 37.688699903547786],
              [126.757031611856149, 37.688658952921038],
              [126.757516157583481, 37.688525444212146],
              [126.757835046733007, 37.68847181862747],
              [126.757926342521102, 37.68845646687479],
              [126.758293777130362, 37.688393872759875],
              [126.758352751324239, 37.688383828039335],
              [126.758581258159154, 37.688331861822945],
              [126.758806505271281, 37.68823520171761],
              [126.758898654670048, 37.688172773294824],
              [126.758923354577945, 37.688156836378035],
              [126.759111223167025, 37.688035632212937],
              [126.759083370693077, 37.688030556749283],
              [126.759072245374938, 37.688028524677556],
              [126.759002448950227, 37.688015802948001],
              [126.758850968810052, 37.687988202608288],
              [126.758704917370054, 37.687961584387672],
              [126.758622975704512, 37.687946648157805],
              [126.758056911024553, 37.68784349229292],
              [126.757820828669836, 37.687800468525815],
              [126.757706118270804, 37.687779564189967],
              [126.756925175584328, 37.68761568206363],
              [126.756680582527352, 37.687526880298584],
              [126.755829696593381, 37.687190672180201],
              [126.755643218862289, 37.687115663740116],
              [126.755315445755144, 37.686983818877515],
              [126.755154081811114, 37.686911735302289],
              [126.755111349416509, 37.686892645852282],
              [126.755107941728113, 37.68689112633556],
              [126.755060972768163, 37.686870160600513],
              [126.754868854009374, 37.686784401329085],
              [126.754720269185825, 37.686709283852068],
              [126.754482679933062, 37.686589164926232],
              [126.754307422459306, 37.686516358732767],
              [126.754307414435601, 37.686518972506072],
              [126.754086766178318, 37.686458865150264],
              [126.753818106647188, 37.686416045534465],
              [126.753826430212968, 37.686367591035875],
              [126.753827701858754, 37.686360206466745],
              [126.753781793518385, 37.686358308020843],
              [126.753584260446615, 37.686349996058851],
              [126.753441501850631, 37.686343988207049],
              [126.753363627266879, 37.686340760575135],
              [126.752378799832826, 37.686299493159808],
              [126.752369542291831, 37.686287579425546],
              [126.752350635911839, 37.686286576268209],
              [126.752093399466915, 37.686272950358308],
              [126.7513761395752, 37.686234933220064],
              [126.751342625018523, 37.686233066536253],
              [126.751327370218206, 37.686232216187008],
              [126.750949040261915, 37.686211141066352],
              [126.749991499057018, 37.686171560181954],
              [126.749926579265988, 37.686168872345611],
              [126.749152954685485, 37.686130730128411],
              [126.748720104813458, 37.686064392522901],
              [126.748560142849783, 37.686043373075059],
              [126.746917306629285, 37.68582752425278],
              [126.746837636949778, 37.685818972986503],
              [126.746731868057822, 37.685807545594123],
              [126.746498900332412, 37.685782383223511],
              [126.746319373481796, 37.685774137197093],
              [126.746167345793054, 37.685767159084705],
              [126.745969564745565, 37.685818987675546],
              [126.745871720899444, 37.685886348450687],
              [126.745190367665273, 37.686545458618106],
              [126.745023215639492, 37.686715711152296],
              [126.744965101551543, 37.68677489637961],
              [126.744872067968174, 37.686828030053796],
              [126.744826807464108, 37.68683550056975],
              [126.744663878467293, 37.686838928523088],
              [126.74465415979428, 37.68683043894822],
              [126.744625564444618, 37.686837584399846],
              [126.744594915648776, 37.686816165637623],
              [126.744317913734832, 37.68676087242401],
              [126.743932832090763, 37.686647581664914],
              [126.743887788787916, 37.686634330108106],
              [126.743579608915368, 37.686543658610944],
              [126.743303480317323, 37.686462417871262],
              [126.743209722698154, 37.686429687831023],
              [126.742994194956822, 37.686334253738032],
              [126.74288063454874, 37.686258322322942],
              [126.74284204796966, 37.68621532325912],
              [126.742402930398796, 37.685725973316032],
              [126.742390215185281, 37.685702374435706],
              [126.74233409385829, 37.68559820996466],
              [126.742332754821717, 37.68559572003258],
              [126.742329222448831, 37.685567691579678],
              [126.741786179602528, 37.684983840729217],
              [126.74178187759091, 37.684978742252959],
              [126.741463969889736, 37.684601949526517],
              [126.741384136488676, 37.684507334760525],
              [126.741381814702819, 37.684504686172701],
              [126.741295158365304, 37.684405868771698],
              [126.741190742363557, 37.684309686053197],
              [126.741151349748904, 37.684283434948611],
              [126.741116226159278, 37.684254382966095],
              [126.741092588578198, 37.68423883273023],
              [126.740299090940809, 37.684030897256399],
              [126.74004195608363, 37.683963516362432],
              [126.739992009301133, 37.683950339880347],
              [126.739948035878285, 37.683946279052293],
              [126.739812465482899, 37.683812365229841],
              [126.739757020070783, 37.683479574573738],
              [126.739656143469645, 37.682874108942372],
              [126.739639151127008, 37.682902902862345],
              [126.739648556500953, 37.682806881158129],
              [126.739661741796155, 37.682751743291107],
              [126.73970930571565, 37.682552852258773],
              [126.739774705199736, 37.682169276137685],
              [126.739817706274977, 37.681917018296517],
              [126.739823784954069, 37.681881345435357],
              [126.739716826658167, 37.681864439410518],
              [126.739691194721274, 37.681860387675506],
              [126.739464732897147, 37.681824598010579],
              [126.739398884341, 37.681806990595369],
              [126.739369596215582, 37.681799158513826],
              [126.739232589731827, 37.681752814058612],
              [126.739171169785223, 37.681749648971866],
              [126.739122987789216, 37.681747164134464],
              [126.73906766532825, 37.681747041527643],
              [126.739013698565046, 37.681778177258451],
              [126.738922831352539, 37.681830600117365],
              [126.738854878151528, 37.681812429913322],
              [126.738682138337722, 37.681725803193856],
              [126.738643375789181, 37.681706364779025],
              [126.738621040678737, 37.681674690656394],
              [126.738563059114625, 37.681625728022588],
              [126.738439863076252, 37.68158482024144],
              [126.738401966152452, 37.681586771119839],
              [126.738397911973365, 37.68158697928056],
              [126.738384246722504, 37.681577516414471],
              [126.738348831947221, 37.68155299265262],
              [126.738326088160477, 37.681550655555974],
              [126.738150857145357, 37.681532640166431],
              [126.73813806175734, 37.681517080063522],
              [126.738118123227068, 37.68149283377204],
              [126.737853688039948, 37.681353043108466],
              [126.737785747041997, 37.681331088633513],
              [126.737562017207011, 37.681201255882421],
              [126.737519620099661, 37.681176652479934],
              [126.737381454022653, 37.681096470994881],
              [126.737185705735811, 37.680982871859612],
              [126.737336298885324, 37.680810132912832],
              [126.737326975611481, 37.680766172093264],
              [126.737321657662747, 37.680741060313693],
              [126.737292032543209, 37.680601279702344],
              [126.737322943369392, 37.680579815515941],
              [126.737617358065421, 37.680448034680438],
              [126.737629280846349, 37.680437687104344],
              [126.737722994523011, 37.680356372025805],
              [126.737853347197685, 37.68020299347787],
              [126.737945395054552, 37.680094676560437],
              [126.738267809371777, 37.679715288042807],
              [126.738725727811087, 37.679581509400613],
              [126.738797295548366, 37.679560601090685],
              [126.738880038742309, 37.679527643926178],
              [126.738918989485498, 37.679512129432574],
              [126.738887088741805, 37.679474510710278],
              [126.738845565939926, 37.679425545364928],
              [126.738634234714524, 37.679176343752019],
              [126.73825025228372, 37.678723553509116],
              [126.738049626021422, 37.678486960032338],
              [126.737826035793972, 37.678244279913237],
              [126.737803448546984, 37.678188280097629],
              [126.73777119576512, 37.678141176017434],
              [126.737758834277912, 37.678110426038259],
              [126.737725011090916, 37.678026289214188],
              [126.737700643492943, 37.677865231837792],
              [126.737743691727573, 37.677724938557496],
              [126.737753853368474, 37.677691825778616],
              [126.73794558097714, 37.677424575369187],
              [126.737949667709714, 37.677358634227581],
              [126.738030704313246, 37.677234210655413],
              [126.738063865780646, 37.677152746458695],
              [126.738065860102552, 37.67710166806463],
              [126.738220900830783, 37.677013898693176],
              [126.738401284740505, 37.676940692254391],
              [126.738493261407854, 37.676928282849971],
              [126.738659628149549, 37.676864438539837],
              [126.738903143879966, 37.6767709860328],
              [126.738972383251095, 37.676712198144521],
              [126.739005698841922, 37.676683909800573],
              [126.739156150006281, 37.676643610901138],
              [126.739398948715305, 37.676498074502504],
              [126.739651517799089, 37.676346679820426],
              [126.739679250480293, 37.676330931398333],
              [126.739680138020773, 37.676329523851386],
              [126.739708005169746, 37.676312820890786],
              [126.739716969830994, 37.676307444627788],
              [126.73972165317474, 37.676306116530895],
              [126.739742159672886, 37.676300291905029],
              [126.739680107718982, 37.676227372620652],
              [126.739098018184322, 37.675543308916524],
              [126.738736330585752, 37.675118146024673],
              [126.738698802466686, 37.675065458701411],
              [126.738694936625393, 37.675060032138802],
              [126.738258584045013, 37.674561993584085],
              [126.73821528423359, 37.674561807646697],
              [126.738185938317912, 37.674526423364192],
              [126.738161701249552, 37.674488348288008],
              [126.737902085715831, 37.674467227708035],
              [126.737843121011096, 37.674457318822249],
              [126.737519069106696, 37.674402851260574],
              [126.737350126928391, 37.674351389567725],
              [126.737464239506409, 37.673976931505912],
              [126.737472578730376, 37.673951991829604],
              [126.737504921430343, 37.673855262204818],
              [126.737549250487817, 37.673722687062252],
              [126.737580798665277, 37.673167760981897],
              [126.737579944719641, 37.673158127563781],
              [126.737553562307582, 37.672860134814741],
              [126.7375509346831, 37.672830462603351],
              [126.737541341271765, 37.672722773082576],
              [126.73757222797407, 37.672707436705466],
              [126.737610579628225, 37.672696620924604],
              [126.737789941333929, 37.672595002720158],
              [126.737853407108091, 37.672559045508024],
              [126.738331052971674, 37.672400456906857],
              [126.738334803147012, 37.672399212067361],
              [126.738368895900081, 37.672387910663225],
              [126.738375315313192, 37.672385782641157],
              [126.738365117629897, 37.672375041553657],
              [126.738346856036443, 37.672355807527929],
              [126.738331554428797, 37.672348292649303],
              [126.738275343984114, 37.672320689031295],
              [126.738180135353403, 37.672254707655867],
              [126.738142953424628, 37.672223901015045],
              [126.738041640739496, 37.672122134649641],
              [126.737944317472525, 37.672077140176846],
              [126.737817827179398, 37.672043251577826],
              [126.737750774884574, 37.672029136200997],
              [126.737519018014751, 37.671985010201809],
              [126.737368526265982, 37.671940147407682],
              [126.737366159070447, 37.671939438985795],
              [126.737343986011041, 37.671927495526347],
              [126.737356694102758, 37.671892026037966],
              [126.737365965315263, 37.671866189263604],
              [126.737223837552477, 37.671831092992541],
              [126.737183910611719, 37.671839203515653],
              [126.737127451778349, 37.671810244863948],
              [126.737126076098221, 37.671808827202568],
              [126.737092781797884, 37.671774488768925],
              [126.736983475659187, 37.671689463182865],
              [126.736977710767391, 37.671685035557331],
              [126.736998085115815, 37.671597328329632],
              [126.737013695093253, 37.671542582191918],
              [126.737030121820197, 37.671468088907545],
              [126.73703684990511, 37.671437581289297],
              [126.737067568521283, 37.671318120760347],
              [126.737068572288919, 37.671314220031185],
              [126.737068396531981, 37.671311672665212],
              [126.737060488121003, 37.671196535558508],
              [126.737053322532063, 37.671171652430537],
              [126.737034146058662, 37.671081692908778],
              [126.737045393997221, 37.671015291514571],
              [126.737052439536939, 37.670973707821553],
              [126.737041410635982, 37.670919263441945],
              [126.737034776372568, 37.670871948089513],
              [126.737015109662934, 37.670824783672529],
              [126.736983879887205, 37.670805474553084],
              [126.736953808309408, 37.670786895777333],
              [126.736948871592006, 37.67077944417121],
              [126.736779958068553, 37.670524413299944],
              [126.736692090794534, 37.670440695179906],
              [126.736587920808134, 37.670341445377474],
              [126.736491540340111, 37.670128600252895],
              [126.736479540916761, 37.670123888886224],
              [126.736471090191159, 37.670045843845628],
              [126.736484340474732, 37.670022980209339],
              [126.736489908936136, 37.67001336311818],
              [126.736483258066883, 37.6699713618058],
              [126.736497502194268, 37.6699497709563],
              [126.736559362476299, 37.669855754496503],
              [126.73660120847579, 37.669792152398777],
              [126.736731265194734, 37.669620440880912],
              [126.736745492946525, 37.669601652999624],
              [126.736760340481723, 37.669537627356569],
              [126.736783708718605, 37.669437220976626],
              [126.736786088785436, 37.66940533144259],
              [126.73679306341306, 37.669313428385244],
              [126.736804560472578, 37.669162018777463],
              [126.736822593094274, 37.668924475313574],
              [126.736825133142972, 37.668890800394088],
              [126.736831630386035, 37.668804757326249],
              [126.736764262669652, 37.668625222725616],
              [126.736741712161631, 37.668608610572804],
              [126.736716424748977, 37.668589979772349],
              [126.736682190466666, 37.668564764602806],
              [126.736593663536908, 37.66840642631221],
              [126.736559943155001, 37.668346110706246],
              [126.736542531776365, 37.668314970948337],
              [126.736529195479207, 37.668275758300872],
              [126.736515466063864, 37.668235407443177],
              [126.736514712070942, 37.668233190721992],
              [126.736508867002229, 37.668216031401876],
              [126.736501814475744, 37.66819531937174],
              [126.736440769449501, 37.668015890397925],
              [126.736435007140074, 37.667998956939577],
              [126.736420513965172, 37.667956356836875],
              [126.736381231722206, 37.66784087770322],
              [126.73596919961814, 37.667563264219481],
              [126.735679942239756, 37.667470081824604],
              [126.735599130005184, 37.667406380949046],
              [126.735586321078983, 37.667375178941406],
              [126.735552949167698, 37.667293836591746],
              [126.735515094587569, 37.667202052220375],
              [126.735462828933564, 37.667075327566742],
              [126.735434463017711, 37.666988591680067],
              [126.735429416803626, 37.6669731734263],
              [126.735407510149329, 37.666918524955619],
              [126.735599611897001, 37.666951022503248],
              [126.735706552735337, 37.666969113724122],
              [126.73582940598439, 37.666972178378124],
              [126.736042583974111, 37.66703942336909],
              [126.736296912443933, 37.667138291377888],
              [126.736803775730777, 37.667297461060173],
              [126.736800467975726, 37.667303579452451],
              [126.736848131713984, 37.667318551449561],
              [126.736914114880733, 37.667185717212156],
              [126.73691424594287, 37.667185319676392],
              [126.736924276597023, 37.66716510936476],
              [126.73722157895908, 37.667258304689909],
              [126.737256377529462, 37.667193332705715],
              [126.737490149586549, 37.667272420926054],
              [126.737458089056375, 37.667332444404451],
              [126.737751203669177, 37.667424369303035],
              [126.737674948587042, 37.667578082506758],
              [126.737768014179863, 37.667607304523635],
              [126.737771091434581, 37.667601544241535],
              [126.738167010427745, 37.667725410073388],
              [126.738325275946394, 37.667747747570594],
              [126.738913552278234, 37.667931597439164],
              [126.739067203812652, 37.66800788786675],
              [126.739710604122877, 37.668214379954613],
              [126.739720678481689, 37.668218279113084],
              [126.739730296958911, 37.668223073948717],
              [126.73989105895339, 37.668307849105581],
              [126.740031314180456, 37.668391500458462],
              [126.740143472216914, 37.668405986255848],
              [126.740143706712828, 37.668406341084285],
              [126.74023029274197, 37.668535918422357],
              [126.740126450193671, 37.668637990980855],
              [126.740154373479328, 37.668658191911909],
              [126.74023745124336, 37.668723516732193],
              [126.740256470509962, 37.668739686359054],
              [126.740510671731684, 37.668629303808963],
              [126.741076985536225, 37.668383391507824],
              [126.744296380264743, 37.666985332484828],
              [126.745982535905497, 37.666253042331057],
              [126.746068090554232, 37.666215884266933],
              [126.746249906984758, 37.666136918860467],
              [126.746690959115497, 37.665945364873181],
              [126.747526280509163, 37.665582568472821],
              [126.747704379028818, 37.665505214783806],
              [126.747867642908432, 37.665434302609484],
              [126.74796529905575, 37.665391888106328],
              [126.748026080153807, 37.665365487454928],
              [126.748178802526127, 37.665299153950251],
              [126.748192690396039, 37.665293122500216],
              [126.748293147674175, 37.665249489865687],
              [126.748300656481121, 37.665259323368524],
              [126.748322044142597, 37.66526671621299],
              [126.748402990863255, 37.665230685270672],
              [126.748569847546719, 37.665156415053872],
              [126.748461845260266, 37.665004580718659],
              [126.748428682957424, 37.664956590354713],
              [126.748343422858142, 37.664833143732579],
              [126.748229282995325, 37.66466793230957],
              [126.748125169568297, 37.664517259914049],
              [126.748052896327422, 37.664412665885571],
              [126.747966275490185, 37.664287334659647],
              [126.747879322526245, 37.66416146226053],
              [126.747842888198718, 37.664108699095891],
              [126.747761687775153, 37.663991108582486],
              [126.747589251488307, 37.663741526944548],
              [126.747519744561913, 37.663640915143198],
              [126.747445005822001, 37.663532730363166],
              [126.747392111966533, 37.663456123927581],
              [126.747383029585578, 37.663442937322209],
              [126.747359293312456, 37.663408482458145],
              [126.747301213530221, 37.663324476386549],
              [126.747168033749603, 37.663130314534143],
              [126.746998073061988, 37.662882528825989],
              [126.746963226719657, 37.662831728685937],
              [126.746931313772023, 37.662785169956749],
              [126.746812238572105, 37.662611254906658],
              [126.746783354205604, 37.662569067057802],
              [126.74664677263462, 37.662373978854795],
              [126.746566361527172, 37.6622592055678],
              [126.746507157854879, 37.662174620479696],
              [126.746334211524328, 37.661927527667252],
              [126.746332290316559, 37.661924782583348],
              [126.74632895193001, 37.66192001489091],
              [126.746208849145304, 37.661747307634705],
              [126.746092454898445, 37.661579926434548],
              [126.745859232192643, 37.661244530882186],
              [126.745689087988836, 37.660991525222158],
              [126.745632248551146, 37.660906989956629],
              [126.745526600539463, 37.66074989880665],
              [126.745484543785679, 37.660687279931878],
              [126.745429515951415, 37.660606612010596],
              [126.745356396215882, 37.66049969267879],
              [126.745338143623314, 37.660473177077549],
              [126.745171725969769, 37.66023205769806],
              [126.74508861835946, 37.660111058721],
              [126.744969648258731, 37.65993790225756],
              [126.744899283620171, 37.659835578988364],
              [126.744867363147378, 37.659790909713067],
              [126.74481454131535, 37.659718679378358],
              [126.744690833762562, 37.659549518503027],
              [126.744608066962229, 37.659428154524505],
              [126.74450533526668, 37.659279722993411],
              [126.744351313491791, 37.659051800571739],
              [126.744282891206481, 37.658950509667036],
              [126.744273964348181, 37.65893729857649],
              [126.744165496066572, 37.658776689177948],
              [126.744131219261263, 37.658725889128704],
              [126.744055514374381, 37.658613877962502],
              [126.744047371777, 37.658601820165174],
              [126.744027520321239, 37.65857240717456],
              [126.74399368518884, 37.658522455355417],
              [126.743990729636991, 37.65851808843356],
              [126.743874483540822, 37.658346019972861],
              [126.743819346740835, 37.658264361834874],
              [126.74370907735792, 37.658101223968707],
              [126.743633420280446, 37.657989429149367],
              [126.743587982497147, 37.657922117250394],
              [126.743378627431895, 37.657636113490391],
              [126.74336893589377, 37.65762287669444],
              [126.743352599520563, 37.657594910224681],
              [126.74331722491408, 37.657534647118027],
              [126.743580830887197, 37.657499635995258],
              [126.744748336707076, 37.657345665007796],
              [126.744768355241703, 37.657343024318251],
              [126.745724077298206, 37.657215902484204],
              [126.745656218552867, 37.657159624393856],
              [126.745887060969508, 37.657128140864131],
              [126.74576328696152, 37.656538634373796],
              [126.745702644055385, 37.656245868321697],
              [126.745671381206051, 37.656076237750305],
              [126.745649991325564, 37.655968614615432],
              [126.745605492245801, 37.655740005137019],
              [126.745595091635025, 37.655686580308952],
              [126.745569258693436, 37.655553001284694],
              [126.745525499028588, 37.655327214026833],
              [126.745483533241199, 37.655106564944525],
              [126.745420752664003, 37.654776219676009],
              [126.744281248597716, 37.654920869815633],
              [126.744222162240661, 37.654928983520186],
              [126.744217302243825, 37.654929650005329],
              [126.743190699240898, 37.655063814873692],
              [126.743056134581181, 37.655078115299503],
              [126.742890816895965, 37.654974501828995],
              [126.742771545915744, 37.65489243151324],
              [126.742437581716857, 37.654664022710762],
              [126.7424254926255, 37.654653725872947],
              [126.742377135458824, 37.654612174369618],
              [126.742278194899683, 37.65458249463029],
              [126.742162089188994, 37.654502774851743],
              [126.742133653194472, 37.654483064098841],
              [126.742065206497614, 37.654435618533611],
              [126.742034769292246, 37.654389511376451],
              [126.74202188788685, 37.654379213011374],
              [126.741585810228557, 37.654090211234326],
              [126.74149096351438, 37.654024502440649],
              [126.741279674120008, 37.65387807930145],
              [126.740964024833261, 37.653665742951162],
              [126.74095520513383, 37.653660164694863],
              [126.740865221599591, 37.653602598597409],
              [126.740797023329193, 37.653558970096249],
              [126.740670383420181, 37.653478593387533],
              [126.740624364517345, 37.653449389919381],
              [126.740582535875902, 37.653420828112083],
              [126.740307943183765, 37.653234413178659],
              [126.740271813320248, 37.653209885329581],
              [126.740184918675808, 37.653150894688231],
              [126.740151435763863, 37.653128164866068],
              [126.740138506825318, 37.653119796622747],
              [126.740028309436582, 37.653048664555413],
              [126.739883622519528, 37.652955278208701],
              [126.739682575017, 37.652830858208773],
              [126.739657083384017, 37.65282981060313],
              [126.739370023337997, 37.652610236376155],
              [126.739048050917603, 37.652393376355874],
              [126.738826076812686, 37.652227464498573],
              [126.738529268232682, 37.652042373931216],
              [126.738484605964686, 37.652014479896607],
              [126.738491579190935, 37.652000241647258],
              [126.738494030719181, 37.651992742242022],
              [126.738350340286445, 37.651955346126677],
              [126.738334511777865, 37.651951211405837],
              [126.7381165191848, 37.651893963087517],
              [126.738133899252148, 37.651833998555944],
              [126.73820215438424, 37.651598996667012],
              [126.738146084848324, 37.651587196768176],
              [126.737928546472375, 37.651541416768232],
              [126.737889581512448, 37.651530680846939],
              [126.737867507414748, 37.651524054114404],
              [126.737801718798238, 37.651494354075638],
              [126.737777347111688, 37.65143258395802],
              [126.737804552111243, 37.651397822657877],
              [126.737645347410009, 37.651219929053099],
              [126.737607540568078, 37.651160246061949],
              [126.737282185959927, 37.651047966917453],
              [126.736861260330798, 37.650913283658895],
              [126.736756163495798, 37.650832429217594],
              [126.736644263648429, 37.650831798888035],
              [126.736575679934575, 37.650823762307304],
              [126.73647333955131, 37.650811864050496],
              [126.73628706621956, 37.650742700275231],
              [126.736205753316113, 37.650681431849243],
              [126.736171249399789, 37.650570130280883],
              [126.736166597073705, 37.650555523057967],
              [126.736042482729601, 37.650453343916276],
              [126.735791896609811, 37.650261983271342],
              [126.735705830637414, 37.650196260013985],
              [126.735411218191373, 37.649971433795244],
              [126.73523916537124, 37.649840043957994],
              [126.734601254020504, 37.649350364771692],
              [126.733657592104564, 37.648542783372186],
              [126.732528593579843, 37.647568927632221],
              [126.732470070656461, 37.647518443212888],
              [126.732460069815914, 37.647521475991972],
              [126.732235774241161, 37.647711853171181],
              [126.732118610323568, 37.647796332320027],
              [126.731931429963694, 37.647953295083823],
              [126.731526263715267, 37.648220974927064],
              [126.731380805153108, 37.648245978161597],
              [126.731097670626994, 37.648305019207605],
              [126.730631570416278, 37.648429805880617],
              [126.730525994501335, 37.648456037086461],
              [126.730149157784737, 37.648465303736621],
              [126.73011507083325, 37.648487751355852],
              [126.729818195307118, 37.648512674694452],
              [126.729771375176824, 37.648510611422097],
              [126.729535514757885, 37.648518229531263],
              [126.729332014625555, 37.648526480402062],
              [126.729254227812348, 37.648520118101757],
              [126.729187014065857, 37.648525030085359],
              [126.728461764777251, 37.648586978695718],
              [126.728416777320845, 37.648590533100865],
              [126.726512151454742, 37.648753355586194],
              [126.726221258716905, 37.648781038293869],
              [126.725224184619194, 37.648875916123778],
              [126.72483670718411, 37.648943149195759],
              [126.724214883328528, 37.649211971152532],
              [126.721222154351508, 37.650505687544111],
              [126.718904694495848, 37.651524167201032],
              [126.717516287270456, 37.652153489032614],
              [126.717492736939519, 37.652164112620696],
              [126.714562267617197, 37.653486025794265],
              [126.714522662559389, 37.653503890355175],
              [126.713578297424945, 37.653943444914695],
              [126.710513058447191, 37.655370068393445],
              [126.708591268626378, 37.656264440842534],
              [126.708589744235525, 37.656265148967513],
              [126.706206431367605, 37.657370391593282],
              [126.702195040362824, 37.659230457274788],
              [126.700922833990859, 37.659820325510459],
              [126.700850061363411, 37.659854066412926],
              [126.699292554890846, 37.660602996217463],
              [126.697784206321458, 37.661328250944102],
              [126.694776232837398, 37.662774466617627],
              [126.692019914797697, 37.664099568364186],
              [126.692190734732733, 37.664275987195666],
              [126.692379355505835, 37.664470791986815],
              [126.692776013845048, 37.664880443674583],
              [126.693438503417468, 37.6655316403562],
              [126.693674363604046, 37.66588795879089],
              [126.693817746675933, 37.666160425050798],
              [126.693868517071607, 37.666518782938887],
              [126.693830694899248, 37.666785192075217],
              [126.693619787317303, 37.667191654512287],
              [126.693589618503111, 37.66744339643342],
              [126.693522971320419, 37.667731982658687],
              [126.69351509485395, 37.668280247479615],
              [126.693566068204788, 37.668685635730782],
              [126.693610425075775, 37.669361975258241],
              [126.693630662061352, 37.669621597635171],
              [126.693632854345523, 37.669639577691747],
              [126.693649042486896, 37.669726697990612],
              [126.693703376208262, 37.66992289066733],
              [126.693713428527786, 37.669958595693139],
              [126.693755625833518, 37.670170253102903],
              [126.693640482200337, 37.670194008812587],
              [126.693597011208183, 37.670208221009766],
              [126.693583447672111, 37.670281344117122],
              [126.693616196402033, 37.670420448479639],
              [126.693653105053059, 37.670458475051539],
              [126.693716210645917, 37.670492697782215],
              [126.693766128866159, 37.67056382401811],
              [126.693788568947824, 37.670591902040499],
              [126.693813911077257, 37.670631160913103],
              [126.69391540804412, 37.670618270891985],
              [126.693985839621973, 37.67074531167713],
              [126.693967650932592, 37.670757967157471],
              [126.693939399696774, 37.670756388754093],
              [126.693774027160359, 37.670833639701449],
              [126.693695702678127, 37.670888844451518],
              [126.693713555961097, 37.670947961352546],
              [126.693748369428647, 37.671063229112576],
              [126.693782658185697, 37.67112597169119],
              [126.693817814599527, 37.671181572741276],
              [126.693837331658997, 37.67122872608789],
              [126.693843819363138, 37.671241564637398],
              [126.693847659549547, 37.671252395882057],
              [126.693882474540786, 37.67135057372505],
              [126.693898591096087, 37.671392168826664],
              [126.693909900732208, 37.671420056280944],
              [126.693929124916892, 37.671469137330348],
              [126.694018622563846, 37.671695081080465],
              [126.694056401992228, 37.671678673890199],
              [126.694077860926157, 37.671669810957283],
              [126.694679064108854, 37.671412923088496],
              [126.694893296120298, 37.671321522953427],
              [126.695265282374791, 37.671162815045875],
              [126.695370987770048, 37.671312380762245],
              [126.695477366285616, 37.671464020399213],
              [126.695571121149129, 37.671598236991656],
              [126.695601432581327, 37.671641562769999],
              [126.695782747974064, 37.671900520249004],
              [126.695840060866175, 37.671985752977136],
              [126.695872991114655, 37.672034727973106],
              [126.695908386010771, 37.671971842808887],
              [126.695994716344472, 37.671816198759153],
              [126.696200835260086, 37.671444181465468],
              [126.696311932310294, 37.671301483936233],
              [126.696374726216433, 37.671218848174696],
              [126.696404396715323, 37.671225321709599],
              [126.696556490331773, 37.671063630387664],
              [126.696583049233269, 37.671041085483196],
              [126.696879335608926, 37.671472154141632],
              [126.698162654558345, 37.673339205734514],
              [126.698180368679957, 37.673365059685842],
              [126.69951700706946, 37.67531580266207],
              [126.700210559317554, 37.676311704738772],
              [126.700266119651388, 37.67639185259636],
              [126.700463343175642, 37.676676161078277],
              [126.700438474041604, 37.676693753832645],
              [126.700396340218745, 37.676723561349334],
              [126.700550098173821, 37.676857014442191],
              [126.700577250699155, 37.676880580469799],
              [126.700606224488212, 37.676905728029361],
              [126.700657660091082, 37.676950370994653],
              [126.70073961558667, 37.676984510427516],
              [126.701298943876338, 37.676594852250311],
              [126.702433029994339, 37.675810980426519],
              [126.702487878963026, 37.675772170171278],
              [126.702565929116147, 37.675717261508716],
              [126.702616878159304, 37.675681419900421],
              [126.702678157567334, 37.675653329137532],
              [126.702822653015275, 37.675573310835858],
              [126.703082183467473, 37.675421956210513],
              [126.703125882961189, 37.675407597867832],
              [126.703810630986084, 37.675064464524652],
              [126.704349065178505, 37.674801459649402],
              [126.706071578939387, 37.673943470076033],
              [126.706505190895442, 37.673732375704319],
              [126.70653333658521, 37.673766999019293],
              [126.706555054355704, 37.67379295527244],
              [126.706544871440897, 37.674017001278777],
              [126.706531287318484, 37.674327440950776],
              [126.706532131872876, 37.674359515523541],
              [126.706532351049461, 37.674367897451894],
              [126.706532373824089, 37.674396638829975],
              [126.706237659943326, 37.674397717440442],
              [126.706079070090524, 37.674398297573831],
              [126.70607420297425, 37.675297135525788],
              [126.7060746159747, 37.675350429617708],
              [126.706063364925683, 37.676243981485669],
              [126.706061055048636, 37.676283349007313],
              [126.706062921325071, 37.676312851780779],
              [126.7060580150047, 37.67720444529153],
              [126.706057692764233, 37.67725958392019],
              [126.706056925954741, 37.677536700512725],
              [126.706055201097243, 37.67815973532926],
              [126.70605471881828, 37.678195412530137],
              [126.706054635637599, 37.678211517608787],
              [126.706054576677076, 37.678223009007269],
              [126.706514257923615, 37.678228241584385],
              [126.706958947381807, 37.678231991641709],
              [126.707322701768803, 37.678235099611598],
              [126.707415312182349, 37.678235798659344],
              [126.707787855279946, 37.67823890747664],
              [126.707861816452322, 37.678239524159942],
              [126.708208578504269, 37.678242441353333],
              [126.708323395145641, 37.678243374401198],
              [126.70839686792408, 37.678239368218605],
              [126.708413600855906, 37.678238793729939],
              [126.708444599324011, 37.678237729132199],
              [126.708446599675185, 37.679140776015025],
              [126.70844663709957, 37.679189337438473],
              [126.708435907309891, 37.679640426974807],
              [126.70842490152495, 37.680094847693894],
              [126.708423832077784, 37.680127828722675],
              [126.708422738938324, 37.680149485443827],
              [126.708491431492845, 37.680150690989379],
              [126.708492600513409, 37.680213040237092],
              [126.708417103271188, 37.680212718275406],
              [126.708416927823421, 37.680525488550572],
              [126.708416546399476, 37.680674507049048],
              [126.708416658987517, 37.680733920821666],
              [126.708417266330443, 37.681053276839322],
              [126.708417549274316, 37.68111725602089],
              [126.708417558632121, 37.681118509815626],
              [126.708705558043334, 37.681119583645696],
              [126.708861203210148, 37.681119878904617],
              [126.709319867038829, 37.681120746746934],
              [126.709642720918566, 37.681121816725934],
              [126.709887465608261, 37.681122603180235],
              [126.710224266160907, 37.681123254860957],
              [126.710252760107082, 37.681123418641178],
              [126.710577608214138, 37.681125297982149],
              [126.710864294078547, 37.681127265373142],
              [126.711313654326077, 37.681129723430523],
              [126.71216498806001, 37.681135060534935],
              [126.712369186396259, 37.681136292915845],
              [126.712843447759994, 37.68113915480572],
              [126.712960208976696, 37.681139800706198],
              [126.713416141998863, 37.681142626301302],
              [126.713740013197906, 37.68114467969113],
              [126.714097210229909, 37.681146721743922],
              [126.714428107832944, 37.681148698390686],
              [126.714773968884401, 37.681151161308811],
              [126.71511205083246, 37.68114216166245],
              [126.71523833538825, 37.681142648768088],
              [126.71525108180532, 37.68114269573249],
              [126.715690305056327, 37.68114428550966],
              [126.715713038816247, 37.681144369102228],
              [126.71590739337806, 37.681145081935533],
              [126.716135589242811, 37.681145724660354],
              [126.716168259168029, 37.681146074301324],
              [126.716222194969987, 37.681146653599576],
              [126.71630154947475, 37.681146935813132],
              [126.716662708830128, 37.681149971651095],
              [126.716677190790477, 37.681150086772455],
              [126.716759633929016, 37.681150747016211],
              [126.717210124207625, 37.681154176149775],
              [126.717562217435429, 37.681156826382363],
              [126.71793324368214, 37.681159610134529],
              [126.718250424101711, 37.681161994331973],
              [126.718565788208352, 37.681164553115039],
              [126.718817107880611, 37.681166596703449],
              [126.719031355440436, 37.68116837262837],
              [126.719373363329368, 37.681170721730147],
              [126.719494713489183, 37.681169926895073],
              [126.719585128678617, 37.681169336422442],
              [126.719932471842142, 37.681169896744926],
              [126.719962465249409, 37.681170152821132],
              [126.720005758960426, 37.681170521194524],
              [126.720005152416732, 37.68122606448572],
              [126.721149008462945, 37.681234150806283],
              [126.721183583471898, 37.681234413396091],
              [126.721203770637132, 37.681234559329582],
              [126.721230060901192, 37.681234749167778],
              [126.721230196467303, 37.681363993607476],
              [126.721492399430929, 37.68136614933308],
              [126.721769903156115, 37.681368427079143],
              [126.721809571901602, 37.68136874473111],
              [126.722368953175447, 37.681373225970894],
              [126.722433115627368, 37.681373105545603],
              [126.722879485466578, 37.681372498428118],
              [126.723161535528249, 37.681372080878369],
              [126.723352211027944, 37.681371900054899],
              [126.723421649281377, 37.681371117117536],
              [126.723482356284364, 37.681370990491864],
              [126.723509707981492, 37.681382288342057],
              [126.723516143355326, 37.681384945437294],
              [126.723607832827213, 37.681284206740422],
              [126.723657122138633, 37.681229545431755],
              [126.724479407794163, 37.680297895140264],
              [126.724581201065661, 37.680146952889253],
              [126.724758393555874, 37.67994282731879],
              [126.724825893919316, 37.679865063654326],
              [126.724837311999693, 37.67984561795874],
              [126.724860903187576, 37.679805440839139],
              [126.724867727505256, 37.679793815427047],
              [126.724888387736939, 37.679786497318339],
              [126.725012602817984, 37.67974250373009],
              [126.725054178177004, 37.679727778099696],
              [126.726066408181978, 37.680221516483769],
              [126.72651023953938, 37.680461669664531],
              [126.727058908724885, 37.680758427925653],
              [126.727127736552944, 37.68079565459518],
              [126.727816643364434, 37.681164760475305],
              [126.728328136929733, 37.681442994293491],
              [126.72902517433991, 37.681823733672509],
              [126.729050045847018, 37.68183742685499],
              [126.729414478252323, 37.682038071639951],
              [126.730024755124489, 37.682370133928522],
              [126.730723056915423, 37.682751137432945],
              [126.731218041144103, 37.683023374449526],
              [126.731321306424249, 37.683079740030898],
              [126.731515684675401, 37.683185853572297],
              [126.731715874798638, 37.683295137638503],
              [126.732071925812775, 37.683489505033968],
              [126.732620662998414, 37.683790691317569],
              [126.7330536623558, 37.684028360934981],
              [126.733996265595181, 37.684546037606054],
              [126.735046149696956, 37.685119400215271],
              [126.735340455062826, 37.685280123825542],
              [126.737047372831071, 37.686213418726012],
              [126.738971418235849, 37.687265375269106],
              [126.739134184521575, 37.687354362638288],
              [126.739798059038037, 37.687719018960564],
              [126.740977096496252, 37.688353336047548],
              [126.741264681281422, 37.68850805204054],
              [126.74292819559335, 37.689409181489275],
              [126.74295990637475, 37.689426491178331],
              [126.743269490734122, 37.689595458155139],
              [126.743455185673326, 37.689697763067386],
              [126.743822069601407, 37.689894346631839],
              [126.744046244323286, 37.689983761559724],
              [126.744252193736187, 37.690032862297983],
              [126.744461476642968, 37.69006845706113],
              [126.744703842266702, 37.69007799535558],
              [126.744870768234719, 37.690067545572745],
              [126.744907439903784, 37.690059436902992],
              [126.745159706821511, 37.690003664192517],
              [126.745369981843268, 37.689957173486789],
              [126.745381107215266, 37.689954713782285],
              [126.745689968684829, 37.689886430368844],
              [126.74602885405865, 37.68977782929133],
              [126.748263057668225, 37.689061805825531],
              [126.751211576818307, 37.688116771353194]
            ]
          ]
        ]
      },
      id: '31104580'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '가좌동',
        ADM_CD: '31104514'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.72638992058954, 37.704303901855724],
              [126.726510893068252, 37.703716389503654],
              [126.726511389958631, 37.703715951119186],
              [126.726553851679967, 37.703671453261045],
              [126.726554903465328, 37.703670125313664],
              [126.726555649110594, 37.703668673680582],
              [126.726556066623289, 37.703667140579327],
              [126.726556143029342, 37.703665573694472],
              [126.726555877851183, 37.703664018989933],
              [126.726555276264122, 37.703662524269319],
              [126.726554358189816, 37.703661137432981],
              [126.726553151593265, 37.703659896519575],
              [126.726551691245476, 37.703658839612402],
              [126.726529352441361, 37.703645302240631],
              [126.726562804619874, 37.703610302815342],
              [126.726563776842852, 37.703609098727355],
              [126.72656449607102, 37.703607787525989],
              [126.726564944942879, 37.703606402445999],
              [126.726565112884231, 37.703604978569274],
              [126.726564996126839, 37.703603551022375],
              [126.726564595430446, 37.703602155862868],
              [126.726563921754419, 37.703600830116535],
              [126.72656299292963, 37.703599604545047],
              [126.726561832449349, 37.703598510848515],
              [126.72656046951586, 37.70359757715925],
              [126.726539058660563, 37.703585132861782],
              [126.726546918381288, 37.703582243431249],
              [126.726547304437105, 37.703582099950758],
              [126.726549017542538, 37.703581321636896],
              [126.726550535596758, 37.703580318517503],
              [126.726551810620649, 37.703579122725152],
              [126.726552804825374, 37.703577768261908],
              [126.726553488268763, 37.703576298194498],
              [126.726553840083326, 37.703574755649129],
              [126.726553850707404, 37.703573187431402],
              [126.726553518500978, 37.703571642201609],
              [126.726552829814608, 37.703570123475423],
              [126.726542588857782, 37.703552391431302],
              [126.72654173260311, 37.703551169039649],
              [126.726540649352089, 37.703550066836364],
              [126.726539895118989, 37.703549506678861],
              [126.726541637993734, 37.703539095309942],
              [126.726423664658668, 37.703338635103968],
              [126.726421200188284, 37.703333557151851],
              [126.726410653194193, 37.703312423329201],
              [126.726421964065594, 37.703302518183818],
              [126.726427011580071, 37.703298099707034],
              [126.726449734444301, 37.703283512292337],
              [126.726478845528547, 37.703270633313601],
              [126.726492508239403, 37.703264744526052],
              [126.726584296844862, 37.703263275431262],
              [126.726663790150965, 37.703281191314971],
              [126.726674223857898, 37.70328375548803],
              [126.726779873979808, 37.703271893062706],
              [126.726862845332576, 37.703305872317927],
              [126.726925421359056, 37.703344867903624],
              [126.726975118946925, 37.703370607210594],
              [126.726995057889027, 37.70339064617113],
              [126.7271357027562, 37.703548075142066],
              [126.727150874249148, 37.703563597884319],
              [126.727183973004557, 37.703573531005368],
              [126.727292688065077, 37.703594614136527],
              [126.727378248304774, 37.703646338854981],
              [126.727481341610044, 37.70370401530208],
              [126.727582124782359, 37.703665120491301],
              [126.727671726896588, 37.703773724600453],
              [126.727717415073826, 37.703829292477643],
              [126.727846026082346, 37.704030903535603],
              [126.727847125825889, 37.704032496150077],
              [126.727886554931729, 37.704089560613269],
              [126.72789187236161, 37.704234853095159],
              [126.72791015593701, 37.704319919918909],
              [126.727934528597018, 37.704343905767466],
              [126.728050046823142, 37.704343895122278],
              [126.728145836496211, 37.704362136624738],
              [126.728224534182971, 37.704374550936315],
              [126.7282635975928, 37.704380716546325],
              [126.728377529179724, 37.704402993717906],
              [126.728391783792901, 37.704405781034076],
              [126.728398948262125, 37.704409882033012],
              [126.728398811272484, 37.704408104645516],
              [126.728398365055824, 37.704389102014161],
              [126.728396435902724, 37.704306875078544],
              [126.728381099602629, 37.703653288253896],
              [126.728366256472356, 37.703020755692663],
              [126.728351423911633, 37.70238864134317],
              [126.728299110612511, 37.702358851207968],
              [126.728290079251451, 37.70235714629699],
              [126.728244892226996, 37.701369358165891],
              [126.728240104673546, 37.701264698575841],
              [126.728216335104747, 37.700692152036481],
              [126.728208443686881, 37.700502074309995],
              [126.728199226378891, 37.700294696166637],
              [126.728188254569574, 37.700068032806577],
              [126.728179003130109, 37.699854546210254],
              [126.728164737685134, 37.699499839350139],
              [126.728147944850875, 37.69915379288647],
              [126.728139837622564, 37.698953156588367],
              [126.728138191622463, 37.698919905302219],
              [126.728123254287723, 37.698618103003703],
              [126.72810663741285, 37.698283014027773],
              [126.728095544227642, 37.698073900206587],
              [126.72809443734144, 37.698052109065877],
              [126.728075124688459, 37.697672139425073],
              [126.728050765700686, 37.697157280073078],
              [126.728045690039181, 37.697023672765312],
              [126.728030648240988, 37.696719643429198],
              [126.728013944923291, 37.696374579811611],
              [126.727999277993646, 37.696107382460291],
              [126.727964258558245, 37.695417953902691],
              [126.727956477576839, 37.69523974344596],
              [126.727950162923321, 37.695048219331838],
              [126.727933504258999, 37.694721769880445],
              [126.727918477261056, 37.694407963939],
              [126.727908155609455, 37.694160851028904],
              [126.727907118771327, 37.694136032810604],
              [126.72790146941847, 37.694000796601969],
              [126.727879078294762, 37.693586542989706],
              [126.727873142193502, 37.693360070960452],
              [126.727848825619958, 37.692877764326461],
              [126.727819855885613, 37.692094315406052],
              [126.727858625250875, 37.69144841191261],
              [126.727879369071019, 37.69124889531939],
              [126.727951194816583, 37.690832094790856],
              [126.728113853539384, 37.690334693683837],
              [126.728163668046804, 37.690182360136333],
              [126.728318098462793, 37.689715295422786],
              [126.728454874690726, 37.689301618925604],
              [126.72868823321339, 37.688611658793739],
              [126.728851607620456, 37.688084699725465],
              [126.72904433573774, 37.687466540528526],
              [126.729107925922307, 37.687097742477498],
              [126.729094791994257, 37.686649533399304],
              [126.729088534048145, 37.686435955099114],
              [126.72908837263968, 37.686430620949395],
              [126.729078912394371, 37.68601056775443],
              [126.729076652663551, 37.685881546522857],
              [126.72906643873408, 37.685298413893143],
              [126.729065953941046, 37.685272825024406],
              [126.729052375518293, 37.684555984211798],
              [126.72904176989293, 37.683581202912123],
              [126.729034844835709, 37.683428923268266],
              [126.729034200329394, 37.682706345178929],
              [126.729031797724303, 37.682532843805319],
              [126.729023796788553, 37.681955184600056],
              [126.729025175473922, 37.681823733679927],
              [126.728328136929733, 37.681442994293491],
              [126.727816643364434, 37.681164760475305],
              [126.727127736552944, 37.68079565459518],
              [126.727058908724885, 37.680758427925653],
              [126.72651023953938, 37.680461669664531],
              [126.726066408181978, 37.680221516483769],
              [126.725054178177004, 37.679727778099696],
              [126.725012602817984, 37.67974250373009],
              [126.724888387736939, 37.679786497318339],
              [126.724867727505256, 37.679793815427047],
              [126.724860903187576, 37.679805440839139],
              [126.724837311999693, 37.67984561795874],
              [126.724825893919316, 37.679865063654326],
              [126.724758393555874, 37.67994282731879],
              [126.724581201065661, 37.680146952889253],
              [126.724479407794163, 37.680297895140264],
              [126.723657122138633, 37.681229545431755],
              [126.723607832827213, 37.681284206740422],
              [126.723516143355326, 37.681384945437294],
              [126.723509707981492, 37.681382288342057],
              [126.723482356284364, 37.681370990491864],
              [126.723421649281377, 37.681371117117536],
              [126.723352211027944, 37.681371900054899],
              [126.723161535528249, 37.681372080878369],
              [126.722879485466578, 37.681372498428118],
              [126.722433115627368, 37.681373105545603],
              [126.722368953175447, 37.681373225970894],
              [126.721809571901602, 37.68136874473111],
              [126.721769903156115, 37.681368427079143],
              [126.721492399430929, 37.68136614933308],
              [126.721230196467303, 37.681363993607476],
              [126.721230060901192, 37.681234749167778],
              [126.721203770637132, 37.681234559329582],
              [126.721183583471898, 37.681234413396091],
              [126.721149008462945, 37.681234150806283],
              [126.720005152416732, 37.68122606448572],
              [126.720005758960426, 37.681170521194524],
              [126.719962465249409, 37.681170152821132],
              [126.719932471842142, 37.681169896744926],
              [126.719585128678617, 37.681169336422442],
              [126.719494713489183, 37.681169926895073],
              [126.719373363329368, 37.681170721730147],
              [126.719031355440436, 37.68116837262837],
              [126.718817107880611, 37.681166596703449],
              [126.718565788208352, 37.681164553115039],
              [126.718250424101711, 37.681161994331973],
              [126.71793324368214, 37.681159610134529],
              [126.717562217435429, 37.681156826382363],
              [126.717210124207625, 37.681154176149775],
              [126.716759633929016, 37.681150747016211],
              [126.716677190790477, 37.681150086772455],
              [126.716662708830128, 37.681149971651095],
              [126.71630154947475, 37.681146935813132],
              [126.716222194969987, 37.681146653599576],
              [126.716168259168029, 37.681146074301324],
              [126.716135589242811, 37.681145724660354],
              [126.71590739337806, 37.681145081935533],
              [126.715713038816247, 37.681144369102228],
              [126.715690305056327, 37.68114428550966],
              [126.71525108180532, 37.68114269573249],
              [126.71523833538825, 37.681142648768088],
              [126.71511205083246, 37.68114216166245],
              [126.714773968884401, 37.681151161308811],
              [126.714428107832944, 37.681148698390686],
              [126.714097210229909, 37.681146721743922],
              [126.713740013197906, 37.68114467969113],
              [126.713416141998863, 37.681142626301302],
              [126.712960208976696, 37.681139800706198],
              [126.712843447759994, 37.68113915480572],
              [126.712369186396259, 37.681136292915845],
              [126.71216498806001, 37.681135060534935],
              [126.711313654326077, 37.681129723430523],
              [126.710864294078547, 37.681127265373142],
              [126.710577608214138, 37.681125297982149],
              [126.710252760107082, 37.681123418641178],
              [126.710224266160907, 37.681123254860957],
              [126.709887465608261, 37.681122603180235],
              [126.709642720918566, 37.681121816725934],
              [126.709319867038829, 37.681120746746934],
              [126.708861203210148, 37.681119878904617],
              [126.708705558043334, 37.681119583645696],
              [126.708417558632121, 37.681118509815626],
              [126.708417549274316, 37.68111725602089],
              [126.708417266330443, 37.681053276839322],
              [126.708416658987517, 37.680733920821666],
              [126.708416546399476, 37.680674507049048],
              [126.708416927823421, 37.680525488550572],
              [126.708417103271188, 37.680212718275406],
              [126.708492600513409, 37.680213040237092],
              [126.708491431492845, 37.680150690989379],
              [126.708422738938324, 37.680149485443827],
              [126.708423832077784, 37.680127828722675],
              [126.70842490152495, 37.680094847693894],
              [126.708435907309891, 37.679640426974807],
              [126.70844663709957, 37.679189337438473],
              [126.708446599675185, 37.679140776015025],
              [126.708444599324011, 37.678237729132199],
              [126.708413600855906, 37.678238793729939],
              [126.70839686792408, 37.678239368218605],
              [126.708323395145641, 37.678243374401198],
              [126.708208578504269, 37.678242441353333],
              [126.707861816452322, 37.678239524159942],
              [126.707787855279946, 37.67823890747664],
              [126.707415312182349, 37.678235798659344],
              [126.707322701768803, 37.678235099611598],
              [126.706958947381807, 37.678231991641709],
              [126.706514257923615, 37.678228241584385],
              [126.706054576677076, 37.678223009007269],
              [126.706054635637599, 37.678211517608787],
              [126.70605471881828, 37.678195412530137],
              [126.706055201097243, 37.67815973532926],
              [126.706056925954741, 37.677536700512725],
              [126.706057692764233, 37.67725958392019],
              [126.7060580150047, 37.67720444529153],
              [126.706062921325071, 37.676312851780779],
              [126.706061055048636, 37.676283349007313],
              [126.706063364925683, 37.676243981485669],
              [126.7060746159747, 37.675350429617708],
              [126.70607420297425, 37.675297135525788],
              [126.706079070090524, 37.674398297573831],
              [126.706237659943326, 37.674397717440442],
              [126.706532373824089, 37.674396638829975],
              [126.706532351049461, 37.674367897451894],
              [126.706532131872876, 37.674359515523541],
              [126.706531287318484, 37.674327440950776],
              [126.706544871440897, 37.674017001278777],
              [126.706555054355704, 37.67379295527244],
              [126.70653333658521, 37.673766999019293],
              [126.706505190895442, 37.673732375704319],
              [126.706071578939387, 37.673943470076033],
              [126.704349065178505, 37.674801459649402],
              [126.703810630986084, 37.675064464524652],
              [126.703125882961189, 37.675407597867832],
              [126.703082183467473, 37.675421956210513],
              [126.702822653015275, 37.675573310835858],
              [126.702678157567334, 37.675653329137532],
              [126.702616878159304, 37.675681419900421],
              [126.702565929116147, 37.675717261508716],
              [126.702487878963026, 37.675772170171278],
              [126.702433029994339, 37.675810980426519],
              [126.701298943876338, 37.676594852250311],
              [126.70073961558667, 37.676984510427516],
              [126.700657660091082, 37.676950370994653],
              [126.700606224488212, 37.676905728029361],
              [126.700577250699155, 37.676880580469799],
              [126.700550098173821, 37.676857014442191],
              [126.700396340218745, 37.676723561349334],
              [126.700438474041604, 37.676693753832645],
              [126.700463343175642, 37.676676161078277],
              [126.700266119651388, 37.67639185259636],
              [126.700210559317554, 37.676311704738772],
              [126.69951700706946, 37.67531580266207],
              [126.698180368679957, 37.673365059685842],
              [126.698162654558345, 37.673339205734514],
              [126.696879335608926, 37.671472154141632],
              [126.696583049233269, 37.671041085483196],
              [126.696556490331773, 37.671063630387664],
              [126.696404396715323, 37.671225321709599],
              [126.696374726216433, 37.671218848174696],
              [126.696311932310294, 37.671301483936233],
              [126.696200835260086, 37.671444181465468],
              [126.695994716344472, 37.671816198759153],
              [126.695908386010771, 37.671971842808887],
              [126.695872991114655, 37.672034727973106],
              [126.695840060866175, 37.671985752977136],
              [126.695782747974064, 37.671900520249004],
              [126.695601432581327, 37.671641562769999],
              [126.695571121149129, 37.671598236991656],
              [126.695477366285616, 37.671464020399213],
              [126.695370987770048, 37.671312380762245],
              [126.695265282374791, 37.671162815045875],
              [126.694893296120298, 37.671321522953427],
              [126.694679064108854, 37.671412923088496],
              [126.694077860926157, 37.671669810957283],
              [126.694056401992228, 37.671678673890199],
              [126.694018622563846, 37.671695081080465],
              [126.693929124916892, 37.671469137330348],
              [126.693909900732208, 37.671420056280944],
              [126.693898591096087, 37.671392168826664],
              [126.693882474540786, 37.67135057372505],
              [126.693847659549547, 37.671252395882057],
              [126.693843819363138, 37.671241564637398],
              [126.693837331658997, 37.67122872608789],
              [126.693817814599527, 37.671181572741276],
              [126.693782658185697, 37.67112597169119],
              [126.693748369428647, 37.671063229112576],
              [126.693713555961097, 37.670947961352546],
              [126.693695702678127, 37.670888844451518],
              [126.693774027160359, 37.670833639701449],
              [126.693939399696774, 37.670756388754093],
              [126.693967650932592, 37.670757967157471],
              [126.693985839621973, 37.67074531167713],
              [126.69391540804412, 37.670618270891985],
              [126.693813911077257, 37.670631160913103],
              [126.693788568947824, 37.670591902040499],
              [126.693766128866159, 37.67056382401811],
              [126.693716210645917, 37.670492697782215],
              [126.693653105053059, 37.670458475051539],
              [126.693616196402033, 37.670420448479639],
              [126.693583447672111, 37.670281344117122],
              [126.693597011208183, 37.670208221009766],
              [126.693640482200337, 37.670194008812587],
              [126.693755625833518, 37.670170253102903],
              [126.693713428527786, 37.669958595693139],
              [126.693703376208262, 37.66992289066733],
              [126.693649042486896, 37.669726697990612],
              [126.693632854345523, 37.669639577691747],
              [126.693630662061352, 37.669621597635171],
              [126.693610425075775, 37.669361975258241],
              [126.693566068204788, 37.668685635730782],
              [126.69351509485395, 37.668280247479615],
              [126.693522971320419, 37.667731982658687],
              [126.693589618503111, 37.66744339643342],
              [126.693619787317303, 37.667191654512287],
              [126.693830694899248, 37.666785192075217],
              [126.693868517071607, 37.666518782938887],
              [126.693817746675933, 37.666160425050798],
              [126.693674363604046, 37.66588795879089],
              [126.693438503417468, 37.6655316403562],
              [126.692776013845048, 37.664880443674583],
              [126.692379355505835, 37.664470791986815],
              [126.692190734732733, 37.664275987195666],
              [126.692019914797697, 37.664099568364186],
              [126.692005917565723, 37.664106299065217],
              [126.688777396764181, 37.665842844485866],
              [126.680262313477883, 37.670337372291662],
              [126.679343389569837, 37.670822337449508],
              [126.677971164685658, 37.671519653309289],
              [126.675638616285681, 37.67355843082008],
              [126.675454592594249, 37.673864585153609],
              [126.674638670395666, 37.675221948086197],
              [126.674621416521845, 37.67525065141961],
              [126.672832405608617, 37.677711162612667],
              [126.672592355451798, 37.678041298867171],
              [126.671446646849887, 37.680635526790404],
              [126.671042556517506, 37.681550459910788],
              [126.670453855143037, 37.683526710386246],
              [126.670356094832599, 37.68511664237618],
              [126.671207558022644, 37.689012800800818],
              [126.671491731525421, 37.690313047143086],
              [126.67149315274645, 37.690321400587742],
              [126.672328188780199, 37.695225160893401],
              [126.672461968124964, 37.696010705229462],
              [126.673065579625629, 37.698268464714936],
              [126.673609334992562, 37.700302210638064],
              [126.673951337564318, 37.700299249737121],
              [126.674867965389382, 37.699945042949771],
              [126.675939092601268, 37.699514694324314],
              [126.676018497253295, 37.699475187715024],
              [126.679995024098545, 37.697496594473499],
              [126.680219389979342, 37.697384949676575],
              [126.682881077126183, 37.695994270364096],
              [126.682926295010333, 37.695943992989456],
              [126.683083858772875, 37.695762819320421],
              [126.683334981506235, 37.695413247613679],
              [126.683354410223458, 37.695357132103801],
              [126.683364392876925, 37.69532829431612],
              [126.683341831115484, 37.695301491628683],
              [126.683436880672858, 37.695225348969139],
              [126.683429915357692, 37.695209498906394],
              [126.683429459711931, 37.695208123951936],
              [126.683475584073733, 37.695193640185941],
              [126.683488327584627, 37.695184134849683],
              [126.68490034781864, 37.694052943747835],
              [126.686961831451185, 37.692401365561693],
              [126.687342096218046, 37.691960347420739],
              [126.687361766527317, 37.691942426052897],
              [126.687693321896077, 37.691640313267492],
              [126.687849653382216, 37.691495439366143],
              [126.688083608275903, 37.691168191522152],
              [126.688193937463282, 37.691013864671199],
              [126.689374110472443, 37.689363030318205],
              [126.689915486022244, 37.688605724636446],
              [126.690005297735667, 37.688615155536596],
              [126.690255853460144, 37.688641466886999],
              [126.690256524128799, 37.688641537288326],
              [126.690313557200199, 37.688642813999117],
              [126.690310323784018, 37.688654068147393],
              [126.690321122602015, 37.688655501477854],
              [126.690675328816383, 37.688804247952568],
              [126.690696906160525, 37.68881190940143],
              [126.690743486759843, 37.68881569012634],
              [126.690811592681584, 37.688752802271431],
              [126.690812780626146, 37.688764346351917],
              [126.690822350842055, 37.68880745611596],
              [126.690834374928031, 37.688854788252215],
              [126.690891047629492, 37.689113685927168],
              [126.690892394444759, 37.689128042297156],
              [126.690913236809834, 37.689186659903228],
              [126.691003816821606, 37.689299797892282],
              [126.691071650798349, 37.689346748556062],
              [126.691073240319454, 37.689347849149584],
              [126.69115254046352, 37.689403521130345],
              [126.691186891174269, 37.689409522455207],
              [126.691203840703665, 37.689423081819491],
              [126.691264818777569, 37.689454780168745],
              [126.691419700841578, 37.689571749567534],
              [126.691637836952395, 37.689724081946231],
              [126.691731199041584, 37.689809072360426],
              [126.691858470824613, 37.689916396236178],
              [126.691949457403581, 37.690063041755437],
              [126.691954210982729, 37.690070658892616],
              [126.69203180569788, 37.690197402582392],
              [126.692110628817602, 37.690326151047664],
              [126.692195851240157, 37.690452509747381],
              [126.692398365671849, 37.690614656272572],
              [126.692478014619567, 37.690672579431229],
              [126.692528533207707, 37.690709318657916],
              [126.692716698199447, 37.690831154222558],
              [126.692749215171375, 37.690850384655342],
              [126.69279519262524, 37.690868813117966],
              [126.692806158208654, 37.690873625490852],
              [126.693072835118826, 37.690944427379293],
              [126.69316962440557, 37.69097227692702],
              [126.69318253032769, 37.690976806909994],
              [126.693225311152375, 37.690999163795716],
              [126.693355595993907, 37.691065670805699],
              [126.693429926049774, 37.691127527228851],
              [126.693588918815777, 37.691276313064286],
              [126.693680902539384, 37.691391146749829],
              [126.693721814371727, 37.691442221881509],
              [126.693749029204128, 37.691501693732214],
              [126.693880992282388, 37.691547932959764],
              [126.693937894954459, 37.691540198396481],
              [126.693952032526781, 37.691538675373955],
              [126.694548096691506, 37.691474494477319],
              [126.694657537313091, 37.691398197589265],
              [126.694695405341037, 37.691323966154805],
              [126.694706862042977, 37.691251081168907],
              [126.694714893258038, 37.69115114866905],
              [126.694739581577721, 37.6910918034554],
              [126.694794564551842, 37.691033946774354],
              [126.694972383193402, 37.691044261438449],
              [126.69523116204202, 37.691098149048536],
              [126.695621472693574, 37.69117349599513],
              [126.695740768770605, 37.691198582111305],
              [126.69620059587325, 37.691345045405754],
              [126.696272055258746, 37.691370574618581],
              [126.69639175629348, 37.691512785986561],
              [126.696391538738396, 37.691524326740605],
              [126.696415175730181, 37.691591402330275],
              [126.696413684255248, 37.691653618935433],
              [126.6963929378648, 37.691700307161398],
              [126.69637041061911, 37.691751207708549],
              [126.696331999619431, 37.691743783178943],
              [126.696255509549474, 37.691734017055701],
              [126.696153029376347, 37.691841021096558],
              [126.696120130445706, 37.691870496942514],
              [126.696105642653691, 37.691903407022174],
              [126.696074988192649, 37.691949493162042],
              [126.69592898848714, 37.691955313440701],
              [126.695766469515902, 37.691929553275841],
              [126.695522366098999, 37.691839386289836],
              [126.695450333939618, 37.691822026738265],
              [126.695362015289518, 37.691803211248853],
              [126.695169240393767, 37.691810593345103],
              [126.695096877606687, 37.691830965759685],
              [126.6949387365364, 37.691902632198897],
              [126.694790434477142, 37.692036824006841],
              [126.694738319746236, 37.6920862414228],
              [126.694679467477002, 37.692140704327265],
              [126.694652968197573, 37.69216316436956],
              [126.69470928306356, 37.692212858987951],
              [126.694699927824203, 37.692290263984667],
              [126.69469552164756, 37.692339314976266],
              [126.694688044952102, 37.692422567008357],
              [126.69471024394484, 37.692580852439882],
              [126.694735095222711, 37.6927422528926],
              [126.694797223289356, 37.69288374908875],
              [126.694798531000828, 37.692910501820847],
              [126.694811288956728, 37.693039202002034],
              [126.694920338434429, 37.693315969620009],
              [126.694921806837698, 37.693319473140143],
              [126.694932268036908, 37.693344439768332],
              [126.694976377902066, 37.693387350554005],
              [126.695197299886459, 37.69338763550634],
              [126.695278392846049, 37.693398271054185],
              [126.69535941484682, 37.693468583891224],
              [126.695365596019101, 37.693473668924163],
              [126.695394201517189, 37.693497109916834],
              [126.695544973396792, 37.693476631448291],
              [126.695652147478498, 37.693462072667202],
              [126.695780617723145, 37.693444622506632],
              [126.695757808877289, 37.693485275756018],
              [126.695745777609588, 37.693506719789319],
              [126.695676040502079, 37.693631018485455],
              [126.695670467835555, 37.693644569590532],
              [126.695642392259188, 37.693712859945613],
              [126.69560877977473, 37.693789074733075],
              [126.695553703259392, 37.693912251175853],
              [126.695828142196333, 37.694038255743756],
              [126.695911290367661, 37.693966839302497],
              [126.696076515822682, 37.693824920982919],
              [126.696192428197477, 37.693854215253232],
              [126.696300232392019, 37.693831969265453],
              [126.696402132631775, 37.693911355714015],
              [126.696414153741657, 37.693916169537566],
              [126.696552982717876, 37.6939764977555],
              [126.696615583723258, 37.694003408505488],
              [126.696627087588709, 37.694007385968781],
              [126.696712710539458, 37.694035473803268],
              [126.696821799884376, 37.694090098383242],
              [126.696888314749231, 37.694113917099962],
              [126.696920341308982, 37.694123006861759],
              [126.697027210242538, 37.694155105803027],
              [126.697068465842577, 37.694161402105948],
              [126.697124777947266, 37.694168303846524],
              [126.69713539118662, 37.694171150355864],
              [126.697147963605403, 37.694174284203243],
              [126.697194893543966, 37.694176936325924],
              [126.697329741780337, 37.694256678782402],
              [126.697374812975866, 37.694283823116599],
              [126.697470256920624, 37.694337279849805],
              [126.697520984920587, 37.694366413072309],
              [126.697689045768115, 37.694469051903525],
              [126.697834290372185, 37.694560926811697],
              [126.697888532323745, 37.694596827415339],
              [126.697952513667417, 37.69463584158445],
              [126.698014387028252, 37.694666695302537],
              [126.698141160479423, 37.694772605654109],
              [126.698202480478855, 37.694811045478843],
              [126.698246491489911, 37.694834530145563],
              [126.698283856024375, 37.694883053184505],
              [126.698247007822246, 37.694927727757062],
              [126.698210140152767, 37.694976628448096],
              [126.6981882064225, 37.695011196128306],
              [126.698240366345132, 37.695079748746934],
              [126.698321970240841, 37.695137953447258],
              [126.698352034242333, 37.695150705939504],
              [126.698390433562068, 37.695162346986123],
              [126.698459213625512, 37.695195463755695],
              [126.698453165673968, 37.695202204132805],
              [126.698482627432682, 37.695232129746081],
              [126.698542032095048, 37.695219894398534],
              [126.698558639200868, 37.695229789238546],
              [126.69856553577246, 37.695234306631733],
              [126.698557511015139, 37.695247241831332],
              [126.69847793185383, 37.695341358752685],
              [126.698514531873229, 37.695360311608297],
              [126.698539095646439, 37.69537613980647],
              [126.698572320797012, 37.695394814633403],
              [126.698660725438245, 37.695439519468806],
              [126.69867412473485, 37.695454484016842],
              [126.698730482389479, 37.695494884451733],
              [126.698774471988173, 37.695523152050669],
              [126.69879019774379, 37.695534743451965],
              [126.698792842014228, 37.695536722679172],
              [126.698822175013305, 37.695556503040081],
              [126.698873249978377, 37.695585352321935],
              [126.69891443167046, 37.695610518296604],
              [126.698956128306293, 37.695639905905956],
              [126.698970411794861, 37.695655141413354],
              [126.699003119616265, 37.695672686863197],
              [126.699011604151494, 37.695677214116003],
              [126.699040570478999, 37.69569755975985],
              [126.699149074462895, 37.695765967421167],
              [126.699263399432141, 37.695841160849461],
              [126.699295450940383, 37.695845179343621],
              [126.699332465890237, 37.695845842590479],
              [126.69936724235933, 37.695876616154486],
              [126.699407864034683, 37.695908533504998],
              [126.699420755137979, 37.695919830675194],
              [126.699575121687488, 37.69603622433732],
              [126.699571739297312, 37.696039593923793],
              [126.699587283077747, 37.696051463390972],
              [126.699666376250619, 37.696119515903717],
              [126.699890201790396, 37.696233836170833],
              [126.700014868228692, 37.696337765342342],
              [126.700073145672064, 37.696383522052685],
              [126.700200013041481, 37.696509423776106],
              [126.700290577763184, 37.696589894473945],
              [126.700310728444151, 37.696600365606031],
              [126.700378394595845, 37.696646149786496],
              [126.700513109432023, 37.696763340910799],
              [126.700533944241883, 37.696785636477671],
              [126.700539211531918, 37.69679127385804],
              [126.700640654787804, 37.6968962688942],
              [126.700721383778131, 37.696955605263895],
              [126.700811556398079, 37.696956683347864],
              [126.700845397557217, 37.696955074930763],
              [126.700890830762063, 37.696935765741316],
              [126.70092294502237, 37.696923739024911],
              [126.701092427511483, 37.696852378529904],
              [126.701404386850058, 37.696687057316588],
              [126.701473612008442, 37.696652600211308],
              [126.701745293192317, 37.696498152762523],
              [126.701765531390649, 37.696490322151249],
              [126.701766245335179, 37.696490278313426],
              [126.701831279110863, 37.696486273268455],
              [126.701843670996041, 37.696486304873332],
              [126.702069497150234, 37.696501797332743],
              [126.702223891411833, 37.696525282050636],
              [126.702276476025801, 37.696534424727055],
              [126.702522253784451, 37.696522371032778],
              [126.703330504412151, 37.696604377867644],
              [126.703437356444923, 37.696689113067002],
              [126.703453355720512, 37.696700993215572],
              [126.703479213177744, 37.696720193733569],
              [126.703553666224977, 37.696755853317647],
              [126.70366833095791, 37.696787964661908],
              [126.70384725036584, 37.696837402194362],
              [126.703902969245433, 37.696859509084909],
              [126.703917506120419, 37.696859545039572],
              [126.703990640529696, 37.696868450744553],
              [126.704225316459997, 37.696885376933267],
              [126.704268174720909, 37.696882863079757],
              [126.7044002226598, 37.696875115926424],
              [126.704703138732413, 37.696794231272563],
              [126.704780396355687, 37.696792731583351],
              [126.704798020507624, 37.696814458552929],
              [126.704813607778661, 37.696815340604566],
              [126.704819215843415, 37.696831689511264],
              [126.704844421524058, 37.696864691670065],
              [126.704845385195455, 37.696865693219749],
              [126.704882702438326, 37.696904485068721],
              [126.705015490793144, 37.696972394945703],
              [126.70517002831194, 37.697048514440993],
              [126.70527053127762, 37.697080301636859],
              [126.705378217366388, 37.697134350949753],
              [126.705482073580939, 37.69716868694772],
              [126.705646275738516, 37.697219772039553],
              [126.705718609776213, 37.697252610193431],
              [126.70587810550154, 37.697331285842438],
              [126.705978893780909, 37.697380522903153],
              [126.705991827256881, 37.697381689770609],
              [126.706038531601891, 37.697398696604246],
              [126.706135861893216, 37.697470453021303],
              [126.706190992543824, 37.697507197626763],
              [126.706268433505713, 37.697547935595388],
              [126.706365145443058, 37.69759857076594],
              [126.70641695126794, 37.697624600594885],
              [126.706458840251912, 37.697649203578706],
              [126.706595904467719, 37.69766643971888],
              [126.706696549418254, 37.697661619773591],
              [126.7067355597747, 37.697652994363246],
              [126.706796210864653, 37.697639343679292],
              [126.706845652111141, 37.697634682980542],
              [126.706897005119117, 37.697643822526487],
              [126.706993869193553, 37.69769896928495],
              [126.707021016552659, 37.6977325410502],
              [126.707028278088927, 37.697734812526164],
              [126.707057518598219, 37.697776556156192],
              [126.707144625884212, 37.697883220301819],
              [126.707146911218885, 37.697886019828779],
              [126.707285878277133, 37.698002646888177],
              [126.707505721181889, 37.698144818040355],
              [126.707791963847043, 37.698296163245978],
              [126.707977416015069, 37.698395444148503],
              [126.708038018714092, 37.698439798392464],
              [126.708165753270563, 37.698485447978463],
              [126.708381411635699, 37.69860817508804],
              [126.708494905134799, 37.698673775741689],
              [126.708557622685618, 37.698718137606058],
              [126.708693752160883, 37.698792809409682],
              [126.708730706391137, 37.698812886319921],
              [126.708774589591556, 37.698825665805288],
              [126.709017759248312, 37.698891872872181],
              [126.709253295355978, 37.698963409092904],
              [126.709362421876179, 37.699012664034953],
              [126.70936650342432, 37.699013286270613],
              [126.709536048530637, 37.699039135240753],
              [126.709561438176806, 37.699043006244139],
              [126.709654811574694, 37.699046613699856],
              [126.709810683022639, 37.699052633640093],
              [126.70999782926657, 37.699083782458494],
              [126.710008029999713, 37.699088415058689],
              [126.710008039027272, 37.69908970219609],
              [126.710008392441097, 37.699091245811495],
              [126.710009077842926, 37.699092717743369],
              [126.710010075294278, 37.699094072792477],
              [126.710011350063553, 37.699095270166914],
              [126.710163394418316, 37.699214934162953],
              [126.71016475274638, 37.699215847280108],
              [126.71016627406155, 37.699216580324851],
              [126.710167921114973, 37.699217115922671],
              [126.710169655494695, 37.699217439395241],
              [126.710171431906758, 37.69921754322862],
              [126.710173204999833, 37.699217425316434],
              [126.710174933911773, 37.699217088088872],
              [126.710176574320386, 37.69921653936089],
              [126.710178088632418, 37.699215794203013],
              [126.710179434688953, 37.69921487035915],
              [126.710184077366193, 37.69921114568082],
              [126.710192550058736, 37.699218281357005],
              [126.710233759321184, 37.699265563630831],
              [126.710270507155656, 37.699307723582898],
              [126.710332060101365, 37.699378827715442],
              [126.710396238635894, 37.699460355973457],
              [126.710453758094431, 37.699522154901295],
              [126.710608620397437, 37.699561396690306],
              [126.711006322755836, 37.69966711484858],
              [126.711067012137335, 37.699686967691456],
              [126.711082267356801, 37.699683067926614],
              [126.711122209951895, 37.69970737592562],
              [126.711245368517325, 37.699747096071029],
              [126.711366120753766, 37.699768232918224],
              [126.711412184829484, 37.699768625728204],
              [126.711484812407406, 37.699771334982998],
              [126.711546434572099, 37.699774360870791],
              [126.711593577487562, 37.699776675215681],
              [126.711669871665833, 37.699794881736636],
              [126.711859787184153, 37.699753395677931],
              [126.711862258920476, 37.699753396877206],
              [126.711865106751432, 37.699753124788103],
              [126.7119565132584, 37.699755321141929],
              [126.712026132030147, 37.699759151018156],
              [126.712108115658239, 37.699770335281798],
              [126.712185916514329, 37.699763768996839],
              [126.712306882384709, 37.699731405187933],
              [126.712367204928555, 37.699714457850668],
              [126.712453918255662, 37.699690095884101],
              [126.712514728034762, 37.699678983229575],
              [126.712689742559064, 37.699698578434692],
              [126.71271785928424, 37.699701726088342],
              [126.712795335151753, 37.69969337803591],
              [126.712848712650143, 37.699687627080515],
              [126.712937645975856, 37.699678045812],
              [126.713033461482368, 37.699642809428418],
              [126.713147912774971, 37.699597194312318],
              [126.713193349590043, 37.699576754665252],
              [126.713205052901799, 37.699573404159473],
              [126.713264240331355, 37.699568197421058],
              [126.713278545075639, 37.699569748488585],
              [126.713521926399082, 37.699596135736201],
              [126.713543532703028, 37.699597602980411],
              [126.713691047228693, 37.699617098898216],
              [126.714028709384408, 37.699624121734907],
              [126.714041806741619, 37.699626684048418],
              [126.714047193718557, 37.699628944400509],
              [126.714071524421556, 37.699639145419241],
              [126.714133621751714, 37.699664641090052],
              [126.714162620447595, 37.699677946684254],
              [126.714457850579478, 37.699800288089207],
              [126.714744740591044, 37.69992684183925],
              [126.714869425617778, 37.700020485908198],
              [126.714876009453135, 37.700025429293468],
              [126.714916157340951, 37.70004213118608],
              [126.715000845192847, 37.70004177749589],
              [126.715129161955275, 37.700032232340504],
              [126.715348767787631, 37.700008837491978],
              [126.715483693581376, 37.699983541294863],
              [126.715563644373532, 37.699969940608149],
              [126.715592209242899, 37.699965782700218],
              [126.715746245732987, 37.699943355134366],
              [126.715796380096734, 37.699963768066517],
              [126.715911113447859, 37.700010480100481],
              [126.716034791058291, 37.700054417759311],
              [126.716047521644882, 37.700061768800033],
              [126.71621763129167, 37.700099633664934],
              [126.716247826604871, 37.700105245870382],
              [126.716328111257937, 37.700120164477404],
              [126.716410624579552, 37.700180055411103],
              [126.716421929141646, 37.700188718261778],
              [126.716512728106352, 37.700258295633709],
              [126.716640791863441, 37.700362494851852],
              [126.71673239686713, 37.700452533644224],
              [126.716948684786317, 37.700555261762531],
              [126.717017679272814, 37.700582735943762],
              [126.717066307612598, 37.700608468710506],
              [126.717139885267699, 37.700641312640322],
              [126.717215947504101, 37.70067499501797],
              [126.717307753950763, 37.700712102301559],
              [126.717394757791254, 37.700754261051394],
              [126.717433793025336, 37.700833190471371],
              [126.717673198376403, 37.701009174508705],
              [126.717893615454301, 37.701192716717003],
              [126.717928672842959, 37.701244039362145],
              [126.717984857059534, 37.701379888587681],
              [126.718050096128863, 37.701510117751212],
              [126.718056710800255, 37.701527060362871],
              [126.718103785669101, 37.701647643040154],
              [126.718106780211329, 37.701651875641701],
              [126.718156405042535, 37.701743217638644],
              [126.718196850865112, 37.701776533419633],
              [126.718299345626704, 37.70189362584393],
              [126.718374364317057, 37.701967009765269],
              [126.718378557725146, 37.701981092660361],
              [126.718513540362224, 37.701990713917382],
              [126.718818783590834, 37.701989673081471],
              [126.718901031708256, 37.701989391468267],
              [126.718924285840416, 37.701976490877911],
              [126.719097248506557, 37.701922568021104],
              [126.719240997734261, 37.701902008307187],
              [126.719244386706109, 37.701901521505356],
              [126.719692809565601, 37.701858674362413],
              [126.719916500517101, 37.701787692435474],
              [126.720163432033871, 37.701755059701604],
              [126.720513504479001, 37.701707818452675],
              [126.720514736855847, 37.701677336931127],
              [126.720514973256712, 37.701675777417144],
              [126.720515547113123, 37.70167427421292],
              [126.720516372882344, 37.701672979976422],
              [126.72051762704767, 37.701671615565509],
              [126.720519068755493, 37.70167054081508],
              [126.720520726012424, 37.701669681101144],
              [126.720522544079387, 37.701669064002594],
              [126.720524468321202, 37.701668707184496],
              [126.720526441918963, 37.7016686201857],
              [126.720572824455829, 37.701669810992975],
              [126.720681142222901, 37.701672593684648],
              [126.720682912492165, 37.701672749595595],
              [126.720684628290684, 37.701673124165914],
              [126.7206862500217, 37.70167370721947],
              [126.720687737001313, 37.701674484066288],
              [126.72068905197689, 37.701675437335219],
              [126.720690163452176, 37.701676541581293],
              [126.720691044477576, 37.701677770488217],
              [126.720691672678427, 37.701679094164788],
              [126.720692032523601, 37.701680479159705],
              [126.720692114162929, 37.701681891157897],
              [126.720692039389945, 37.701683723937499],
              [126.720710829728077, 37.701681187448685],
              [126.720777560392591, 37.701669629269297],
              [126.720778303868258, 37.70166904382166],
              [126.720779749755323, 37.701668219661101],
              [126.720781339995384, 37.701667584829245],
              [126.720783036986717, 37.701667155301237],
              [126.720784795225185, 37.701666943394791],
              [126.72078521183461, 37.701666915503317],
              [126.720797321961655, 37.70166620598247],
              [126.720875714751884, 37.701652627457264],
              [126.720900003886513, 37.701648420183517],
              [126.720904608674772, 37.701650988712139],
              [126.720919106503985, 37.701659073759778],
              [126.720939157606182, 37.701657900240775],
              [126.720939576399203, 37.701657880475025],
              [126.720940592733243, 37.701657887190251],
              [126.720942395520211, 37.701658079364734],
              [126.720944135812175, 37.701658498257515],
              [126.720945769484629, 37.701659132761336],
              [126.720947253613289, 37.701659965467584],
              [126.720948553232972, 37.701660977217621],
              [126.720949631204064, 37.701662139825331],
              [126.720950461730098, 37.701663425179518],
              [126.720951024752068, 37.701664798897689],
              [126.720951301345238, 37.701666226604857],
              [126.720952358445132, 37.701677620797767],
              [126.720972503154769, 37.701688856289415],
              [126.721009083353081, 37.701713152254136],
              [126.721063349352008, 37.701747347013189],
              [126.721187968272361, 37.701825918510828],
              [126.721273498912922, 37.701883558272556],
              [126.721482083555145, 37.702014694338466],
              [126.721485421569369, 37.702015824995343],
              [126.721608424918728, 37.702057510800785],
              [126.72163072227535, 37.702065442652298],
              [126.721679210242016, 37.702080761510324],
              [126.721741331438835, 37.702097801715347],
              [126.72177152663167, 37.702125460465538],
              [126.721780653045457, 37.702130425656009],
              [126.721815728493112, 37.702149503643611],
              [126.721914760086761, 37.702199571772915],
              [126.722058541407577, 37.702237788064018],
              [126.722080434416625, 37.702243607185508],
              [126.722203562171345, 37.702294299127395],
              [126.722326893255115, 37.702338792139273],
              [126.722423044522955, 37.702353092907288],
              [126.72252311705607, 37.702361780829754],
              [126.722635594953786, 37.702324882649627],
              [126.722786279996782, 37.70235057293656],
              [126.722934669222141, 37.702468617322445],
              [126.722969642665262, 37.702495445600718],
              [126.723102172577057, 37.702591202105197],
              [126.723114664033517, 37.702615160233776],
              [126.723168125543651, 37.702695942181059],
              [126.72319025128229, 37.702729374401407],
              [126.723243110985109, 37.702806639926258],
              [126.723261823482915, 37.702823865643147],
              [126.723297852944114, 37.702856601686676],
              [126.723344595431286, 37.70286185780504],
              [126.723366921414097, 37.702864369012012],
              [126.723408285037408, 37.702842509680295],
              [126.723513049744895, 37.702924969619971],
              [126.723558887159172, 37.703092139044152],
              [126.723589604406783, 37.703204161099961],
              [126.723701851750207, 37.70351497940689],
              [126.72377662262096, 37.703655651307677],
              [126.723817742432644, 37.703747818224315],
              [126.723885272537203, 37.703742625035915],
              [126.724066202527567, 37.703741826659979],
              [126.724093110876993, 37.703741708107529],
              [126.724304618279092, 37.703799642843755],
              [126.724426717788219, 37.703844130969919],
              [126.724485902370006, 37.703889317393973],
              [126.724719811476533, 37.704214214837869],
              [126.724766398873896, 37.704270067363595],
              [126.724998178366192, 37.704547941291075],
              [126.725024852319621, 37.704553365872549],
              [126.725419735441633, 37.704633678349495],
              [126.725488571401385, 37.704657210504088],
              [126.725824678288532, 37.70461322732092],
              [126.726077482423136, 37.7045749587476],
              [126.726341631838963, 37.704538420691236],
              [126.72638992058954, 37.704303901855724]
            ]
          ]
        ]
      },
      id: '31104514'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '성사1동',
        ADM_CD: '31101540'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.848979815233733, 37.668720917646546],
              [126.849149024610966, 37.668612298934733],
              [126.849191926936413, 37.668584695513886],
              [126.849230918498833, 37.668559685246265],
              [126.849232176211245, 37.668558879189646],
              [126.849264685320236, 37.668538026112067],
              [126.849319493832851, 37.668450884544619],
              [126.84937654763938, 37.668350715851851],
              [126.849400825318099, 37.668308094469467],
              [126.849563128772999, 37.668033328067644],
              [126.84968743079736, 37.667992943661936],
              [126.849718922777413, 37.667982188713395],
              [126.849834881532587, 37.667942590075654],
              [126.850114461240011, 37.667849066218785],
              [126.850471564308393, 37.667644550300466],
              [126.850555060615861, 37.667497079015561],
              [126.850590661336184, 37.667434149752538],
              [126.851114491920342, 37.667223953825555],
              [126.851128061791599, 37.667218507855615],
              [126.851257429362391, 37.66716660095468],
              [126.851299902504095, 37.66712376738689],
              [126.851394647831881, 37.667071542822576],
              [126.851491925234981, 37.667000128444357],
              [126.851538150447155, 37.666980059487472],
              [126.851550089722622, 37.666974877605547],
              [126.851711885700666, 37.66690463433595],
              [126.851968550306054, 37.666704223387491],
              [126.85226348401271, 37.666470072675047],
              [126.852274459422588, 37.666453665713838],
              [126.852605591644718, 37.665958690551228],
              [126.852676368946675, 37.66585289012346],
              [126.852958669676411, 37.665616755670513],
              [126.853041886546421, 37.665547146622728],
              [126.853077829360046, 37.665517084427769],
              [126.853111412523603, 37.6654889940015],
              [126.853427648076035, 37.665369616695116],
              [126.853438121614715, 37.665369843557613],
              [126.853516868719026, 37.665371547871381],
              [126.853776141751027, 37.665377173355239],
              [126.853945350792969, 37.665380843036651],
              [126.854269534385935, 37.665110505926904],
              [126.854365741520752, 37.665026295251273],
              [126.854366375264618, 37.665026433919259],
              [126.854490405447436, 37.664744896534927],
              [126.854677117744743, 37.664433662540361],
              [126.85472716856836, 37.664345979479549],
              [126.854752262558108, 37.664335468482818],
              [126.854757205910843, 37.664333384617706],
              [126.854791757598946, 37.664318622415387],
              [126.854807769912796, 37.664312144739029],
              [126.855523089768823, 37.664022743764605],
              [126.855573702248947, 37.664001092814622],
              [126.855566705046371, 37.663923374620467],
              [126.855550935049095, 37.66368742740552],
              [126.855473188568055, 37.663283820981242],
              [126.855398859729107, 37.662919336335229],
              [126.855236210215423, 37.662631670292406],
              [126.855063756755044, 37.662339306773205],
              [126.854876562725053, 37.66199799601462],
              [126.854412826958026, 37.661762198897186],
              [126.854140122534773, 37.661624219820212],
              [126.853815463118394, 37.661627056481031],
              [126.853522591144241, 37.661619337072899],
              [126.853176799310759, 37.66159616282291],
              [126.852990903176533, 37.661191559575961],
              [126.852981115752428, 37.661169172801415],
              [126.852825353640014, 37.660812908707356],
              [126.852699000216191, 37.660524311597129],
              [126.852634950125804, 37.660185433238965],
              [126.852509369865146, 37.659520969457894],
              [126.852443971184044, 37.659474201095009],
              [126.851644273176674, 37.658902317952602],
              [126.85206826560227, 37.656551836115362],
              [126.852214669681587, 37.655740183131442],
              [126.852054334365434, 37.65552048710942],
              [126.851990282488799, 37.655433193150763],
              [126.851993553876468, 37.655432459414342],
              [126.8519215896807, 37.655334396146927],
              [126.851085922436539, 37.654195639124758],
              [126.851214362982702, 37.65397144528221],
              [126.849648297690536, 37.653549619346826],
              [126.849600889821843, 37.653428691740004],
              [126.849596210740117, 37.65341744079852],
              [126.849517506794285, 37.653442007869501],
              [126.849469346177557, 37.65338608526347],
              [126.849335601176321, 37.65328851791903],
              [126.849279346159435, 37.653256280758924],
              [126.849189144473058, 37.653199313119373],
              [126.849236829120969, 37.653155676093498],
              [126.84914740008621, 37.653055012681058],
              [126.849082274327017, 37.652982669648139],
              [126.849015656365395, 37.652920506959767],
              [126.848968876470636, 37.652862205049267],
              [126.848956312926376, 37.652846549940953],
              [126.848917454553032, 37.652786224982776],
              [126.848913614990167, 37.652783098714437],
              [126.848884420313624, 37.652759333585777],
              [126.848787744595214, 37.652710104761859],
              [126.848716196832271, 37.652676766209822],
              [126.848708875146798, 37.652673982142346],
              [126.848697426387233, 37.652199596656914],
              [126.848841094164669, 37.652071596263021],
              [126.848896906308909, 37.652021870687527],
              [126.848965004954294, 37.652003536763168],
              [126.849052357064167, 37.651980020049784],
              [126.849308973869142, 37.65203159656842],
              [126.849372258902932, 37.652010937468788],
              [126.84942310377113, 37.65195073694634],
              [126.851838891325627, 37.652881310282481],
              [126.851918504306184, 37.652742341022098],
              [126.852356709574522, 37.652832933665699],
              [126.852461646179137, 37.652854907284457],
              [126.852421502785745, 37.652805550090967],
              [126.852323508348846, 37.652685074106984],
              [126.852310250889829, 37.652282630215524],
              [126.852811047091947, 37.651726859699593],
              [126.852811242826036, 37.651726642651894],
              [126.852754952209494, 37.651470682733077],
              [126.85275250496268, 37.651459552316787],
              [126.852752029661232, 37.651459457773363],
              [126.852543752726973, 37.65141671668907],
              [126.85198236561277, 37.651301508843922],
              [126.851049536701836, 37.650857438236017],
              [126.849831102650867, 37.650277789541434],
              [126.849830967628435, 37.650277543635816],
              [126.849275786160689, 37.649230831964466],
              [126.849275578688932, 37.649230445051245],
              [126.849275247510107, 37.649230724439022],
              [126.848766229170721, 37.649662909436046],
              [126.848247293985864, 37.6501035062781],
              [126.848122747980966, 37.650209250605656],
              [126.847725368360202, 37.650546636808727],
              [126.847610766235789, 37.650643936036339],
              [126.847565754728308, 37.650687641289878],
              [126.847487257406726, 37.650763863311447],
              [126.84734600026033, 37.650901023006043],
              [126.846988857422886, 37.651247802991371],
              [126.846594239004503, 37.651630966422346],
              [126.846348886895015, 37.651785286402031],
              [126.845800860859754, 37.652129979130144],
              [126.844725118410096, 37.65259079457649],
              [126.84470500788035, 37.652599408620752],
              [126.844623796923656, 37.652619697020398],
              [126.84448478610517, 37.652654426139584],
              [126.84385819854883, 37.652810964351744],
              [126.843596547187843, 37.65287633314972],
              [126.843108273089413, 37.652984902114795],
              [126.843067118196117, 37.652994052150405],
              [126.842655639088562, 37.653085542188393],
              [126.842653447190116, 37.653086248332464],
              [126.842541735043937, 37.653122271957649],
              [126.842512591928184, 37.653129518442469],
              [126.842210645390253, 37.653204606609087],
              [126.840434623199471, 37.653646251325462],
              [126.840358478022296, 37.653665187871404],
              [126.83985659617997, 37.653789984866378],
              [126.839774226849158, 37.653810466229061],
              [126.839506643782343, 37.65387700410087],
              [126.839206768196007, 37.65395156886386],
              [126.839195846252622, 37.653954283770553],
              [126.839136657743353, 37.653969000757542],
              [126.838050158494724, 37.654239149490671],
              [126.837749526780996, 37.654313895568677],
              [126.837745720529455, 37.654314721447719],
              [126.837744188255769, 37.654315054448411],
              [126.837740341106112, 37.654315890012292],
              [126.837734390268025, 37.654317191469012],
              [126.836284475744691, 37.654632838846133],
              [126.836282356200201, 37.654633301018599],
              [126.836271205348027, 37.654635727743241],
              [126.836268940484089, 37.654636213433605],
              [126.835420297465689, 37.654820956529775],
              [126.835419466147712, 37.654821121295754],
              [126.835416082306224, 37.654821864754396],
              [126.835277839059714, 37.654851961689445],
              [126.835272577609402, 37.65485311368063],
              [126.835270978302034, 37.654853460692586],
              [126.835024139260497, 37.654907181114488],
              [126.834527417597229, 37.655066798198888],
              [126.834510396441303, 37.655072273277156],
              [126.834502328706861, 37.655074867749398],
              [126.834494186024187, 37.655077476219482],
              [126.834492800110183, 37.655077919965457],
              [126.834491000271058, 37.655078505588499],
              [126.834458070014378, 37.655089082547519],
              [126.833082426989264, 37.655531123993583],
              [126.833066445924231, 37.655536265843608],
              [126.833058004062977, 37.65553897707639],
              [126.832529648568411, 37.655708736946707],
              [126.832486281598477, 37.655722669424215],
              [126.832487024600553, 37.65576884140858],
              [126.832487803574097, 37.655797192555681],
              [126.832496988637303, 37.656128475041072],
              [126.832499094981273, 37.656204461624071],
              [126.832504222765522, 37.656389464589125],
              [126.832504440810197, 37.656397274019334],
              [126.832504511208413, 37.656399541255269],
              [126.832507532071673, 37.656508622528349],
              [126.832541930426814, 37.656530191242929],
              [126.83254394387302, 37.656531458184396],
              [126.832611223224674, 37.656573641505283],
              [126.833595642546797, 37.657190853909015],
              [126.833613945885645, 37.657208657572006],
              [126.833615095655958, 37.657209778107621],
              [126.833615362052001, 37.65721003468812],
              [126.833629270700499, 37.657223574580655],
              [126.833646916434219, 37.657240751706603],
              [126.833654307188624, 37.657247971625459],
              [126.833664481028237, 37.657257875193231],
              [126.834425703424216, 37.657998888649416],
              [126.834428852815023, 37.658001946595519],
              [126.834429487808293, 37.658002569390035],
              [126.834441344387542, 37.658014113786862],
              [126.834455914202238, 37.658028299056575],
              [126.834457392284563, 37.658029728788833],
              [126.835067483748176, 37.658623608013826],
              [126.835174989721367, 37.658728257750177],
              [126.835195615856634, 37.658748332081458],
              [126.835220963706163, 37.658773017168187],
              [126.83522315995296, 37.658775155775807],
              [126.835664386842851, 37.659204655108908],
              [126.83590446199463, 37.659438342890951],
              [126.835912461459515, 37.65944613302895],
              [126.835913618124948, 37.659447250876838],
              [126.835976270972949, 37.659508236746518],
              [126.835982790148805, 37.659514585436931],
              [126.836000246944408, 37.659531584484697],
              [126.836508081812141, 37.660025902238893],
              [126.836608495291671, 37.660109823514063],
              [126.836615253381908, 37.660115454254282],
              [126.836616566662897, 37.660116556752172],
              [126.83661711584125, 37.660117016813423],
              [126.836618670065448, 37.660118308142302],
              [126.83709972477277, 37.660520280519265],
              [126.837112687183165, 37.660531104391261],
              [126.837249744042182, 37.660628037994108],
              [126.837314371522595, 37.66067374053334],
              [126.837373231516949, 37.660715373595451],
              [126.837374395354345, 37.660716199439413],
              [126.837465255479529, 37.660780461559348],
              [126.837558904280385, 37.660846698754781],
              [126.837570031468346, 37.660854563128886],
              [126.837575961236269, 37.660858754250029],
              [126.837756938701816, 37.660986754774449],
              [126.838144360001863, 37.661260762180525],
              [126.838190001382216, 37.661293044208833],
              [126.838233835577952, 37.661324047913936],
              [126.838339214774436, 37.661398572201691],
              [126.838462226118807, 37.661491554406766],
              [126.838480337989722, 37.661505247088584],
              [126.838480723170449, 37.661505536771983],
              [126.838531369372589, 37.661543819827649],
              [126.838578884731177, 37.661579735547058],
              [126.838866315868543, 37.661797000157627],
              [126.838916316017233, 37.661834793611455],
              [126.838946990099984, 37.661857975489745],
              [126.839105461360575, 37.661977758400674],
              [126.839189521206734, 37.662041320415533],
              [126.83920744605625, 37.66210031441112],
              [126.83920972507596, 37.662108103823726],
              [126.839212936178114, 37.662119078337867],
              [126.839247067008159, 37.662195256770971],
              [126.839320295744159, 37.662359155873041],
              [126.839372630362305, 37.662454587614789],
              [126.839377402369664, 37.662464963372216],
              [126.839400030105367, 37.662514142992698],
              [126.839410074923649, 37.662536213246383],
              [126.839468034418644, 37.662663644692088],
              [126.8394711662785, 37.66267051771937],
              [126.839504072972858, 37.662742745914748],
              [126.839505652047677, 37.662746234778425],
              [126.83956524124379, 37.662877876381344],
              [126.839615468007864, 37.662988754856414],
              [126.839641200168671, 37.663046407151278],
              [126.839670925742794, 37.663113004026386],
              [126.839894414313079, 37.663278349550559],
              [126.840127107901409, 37.663450506095472],
              [126.840183286289957, 37.663492067767159],
              [126.840297314854055, 37.663576430690938],
              [126.840386556507752, 37.66364245350799],
              [126.840436291963286, 37.663679249768919],
              [126.840366841704224, 37.663720239482643],
              [126.840352446560246, 37.663728728788584],
              [126.840369855004937, 37.663733093023765],
              [126.8404945787222, 37.663846620294763],
              [126.840509418181057, 37.663857113585415],
              [126.8406045829161, 37.663854620783816],
              [126.84067938643328, 37.663800880047425],
              [126.840757685249471, 37.66379317691564],
              [126.840811079598296, 37.663850765435129],
              [126.840854191357735, 37.66389849544597],
              [126.840876873762411, 37.663923186947542],
              [126.840937067589195, 37.663980155437343],
              [126.840966592218365, 37.664021947259982],
              [126.840950109343211, 37.664040098007412],
              [126.841119471588371, 37.664128622469306],
              [126.841129163319152, 37.66410144393484],
              [126.84123286474005, 37.66412859786881],
              [126.84124607189645, 37.664179861557237],
              [126.841255600330129, 37.664196814641166],
              [126.841274066604683, 37.664205433503192],
              [126.841529325580694, 37.664337322163796],
              [126.841587654391105, 37.664239186891862],
              [126.841598028161528, 37.664243369268043],
              [126.841792336902813, 37.664352553411753],
              [126.841921568267423, 37.664425652804738],
              [126.842047647786728, 37.664498503735025],
              [126.842078779818564, 37.664515252782586],
              [126.8421067035504, 37.664542489786079],
              [126.842168604502859, 37.664608660696743],
              [126.842364924803405, 37.664721628067824],
              [126.842533707847139, 37.66482707958675],
              [126.842601772065152, 37.664868534205013],
              [126.842656181294643, 37.664901674562515],
              [126.842709927115664, 37.664938948285474],
              [126.842699471232208, 37.664956916543183],
              [126.842694946233351, 37.664974775441856],
              [126.842695778947331, 37.664992977859129],
              [126.842701907858668, 37.66501052838796],
              [126.842713014948288, 37.665026460836238],
              [126.842728468383953, 37.665039908211199],
              [126.842729396367034, 37.665040621824772],
              [126.842737531452727, 37.665046938584922],
              [126.842745644086861, 37.66505322998227],
              [126.842753747572687, 37.66505953034131],
              [126.842761860225437, 37.665065819934973],
              [126.842769972800113, 37.665072118540898],
              [126.842778074101957, 37.665078409872848],
              [126.842786186663474, 37.665084710280247],
              [126.842794301573349, 37.66509100168691],
              [126.842802402815764, 37.665097300227472],
              [126.842810536822384, 37.665103483580261],
              [126.842977749020434, 37.665225237912097],
              [126.843040117988338, 37.665283742486494],
              [126.842916409714732, 37.665251871104374],
              [126.842795962233055, 37.66522131026337],
              [126.8427557419854, 37.665213958465202],
              [126.842706553044636, 37.665214073734717],
              [126.842667746062844, 37.665213350924539],
              [126.842661626635007, 37.665213237504105],
              [126.842636898725758, 37.665243980929901],
              [126.842290908782303, 37.665512748317504],
              [126.840768166874881, 37.665672082385754],
              [126.840835635279987, 37.666143962922973],
              [126.841294411369887, 37.666419131979417],
              [126.841456017780573, 37.666754855347371],
              [126.841495551494191, 37.666955103723254],
              [126.841708597219451, 37.667236064521859],
              [126.841649131423139, 37.667355735137008],
              [126.843192079524982, 37.667584847662809],
              [126.844426596120087, 37.667768144056893],
              [126.844569685172956, 37.667869082004671],
              [126.844722464387814, 37.667891389667425],
              [126.844992785509646, 37.667849972747675],
              [126.845073518124067, 37.667879034554304],
              [126.845217160222361, 37.667926182758215],
              [126.845207795262581, 37.667926990633035],
              [126.845212040183384, 37.667932445588967],
              [126.845276942911511, 37.66800423415809],
              [126.845426863307495, 37.668170063892958],
              [126.845543821193573, 37.668263343084391],
              [126.845579949629865, 37.668287014072817],
              [126.845603096424028, 37.668291558996373],
              [126.845646044614469, 37.668311807612149],
              [126.845749295353613, 37.668357830650258],
              [126.845797488145706, 37.668402068248319],
              [126.845869287742289, 37.668445607532824],
              [126.845936855042652, 37.668466878581469],
              [126.845964359737209, 37.668487021610247],
              [126.846061292176643, 37.668553029588949],
              [126.846064111843319, 37.668554873111091],
              [126.846085563838599, 37.668552282661366],
              [126.846157245608964, 37.668546605120319],
              [126.846160722685212, 37.668544211546376],
              [126.846165140272973, 37.668586089101616],
              [126.846218376829526, 37.668672221974489],
              [126.8463264792328, 37.668658514398381],
              [126.846402083120964, 37.668736108506572],
              [126.846424583279671, 37.66875920077441],
              [126.846477191907226, 37.668916859267433],
              [126.846745696487915, 37.669082866617231],
              [126.84696103045421, 37.669155589190154],
              [126.847471929229627, 37.669387315724244],
              [126.84742587364363, 37.669508662380586],
              [126.847873974361718, 37.66971857823534],
              [126.848133738130485, 37.669855922411607],
              [126.848366570699497, 37.669985962502864],
              [126.848489631351868, 37.670107733648948],
              [126.848509241294408, 37.670087353033679],
              [126.848517247999482, 37.670028185566977],
              [126.848524853297931, 37.670001833152504],
              [126.848521826531027, 37.669920419507633],
              [126.848541604685863, 37.669787008155318],
              [126.848578026333669, 37.669541369103982],
              [126.84858596189062, 37.669480079512795],
              [126.848611163361298, 37.669285421452742],
              [126.84865761046585, 37.6689266411029],
              [126.84872051391693, 37.668885532879322],
              [126.848734109807097, 37.668876645168105],
              [126.848979815233733, 37.668720917646546]
            ]
          ]
        ]
      },
      id: '31101540'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '성사2동',
        ADM_CD: '31101550'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.835024139260497, 37.654907181114488],
              [126.835270978302034, 37.654853460692586],
              [126.835272577609402, 37.65485311368063],
              [126.835277839059714, 37.654851961689445],
              [126.835416082306224, 37.654821864754396],
              [126.835419466147712, 37.654821121295754],
              [126.835420297465689, 37.654820956529775],
              [126.836268940484089, 37.654636213433605],
              [126.836271205348027, 37.654635727743241],
              [126.836282356200201, 37.654633301018599],
              [126.836284475744691, 37.654632838846133],
              [126.837734390268025, 37.654317191469012],
              [126.837740341106112, 37.654315890012292],
              [126.837744188255769, 37.654315054448411],
              [126.837745720529455, 37.654314721447719],
              [126.837749526780996, 37.654313895568677],
              [126.838050158494724, 37.654239149490671],
              [126.839136657743353, 37.653969000757542],
              [126.839195846252622, 37.653954283770553],
              [126.839206768196007, 37.65395156886386],
              [126.839506643782343, 37.65387700410087],
              [126.839774226849158, 37.653810466229061],
              [126.83985659617997, 37.653789984866378],
              [126.840358478022296, 37.653665187871404],
              [126.840434623199471, 37.653646251325462],
              [126.842210645390253, 37.653204606609087],
              [126.842512591928184, 37.653129518442469],
              [126.842541735043937, 37.653122271957649],
              [126.842653447190116, 37.653086248332464],
              [126.842655639088562, 37.653085542188393],
              [126.843067118196117, 37.652994052150405],
              [126.843108273089413, 37.652984902114795],
              [126.843596547187843, 37.65287633314972],
              [126.84385819854883, 37.652810964351744],
              [126.84448478610517, 37.652654426139584],
              [126.844623796923656, 37.652619697020398],
              [126.84470500788035, 37.652599408620752],
              [126.844725118410096, 37.65259079457649],
              [126.845800860859754, 37.652129979130144],
              [126.846348886895015, 37.651785286402031],
              [126.846594239004503, 37.651630966422346],
              [126.846988857422886, 37.651247802991371],
              [126.84734600026033, 37.650901023006043],
              [126.847487257406726, 37.650763863311447],
              [126.847565754728308, 37.650687641289878],
              [126.847610766235789, 37.650643936036339],
              [126.847725368360202, 37.650546636808727],
              [126.848122747980966, 37.650209250605656],
              [126.848247293985864, 37.6501035062781],
              [126.848766229170721, 37.649662909436046],
              [126.849275247510107, 37.649230724439022],
              [126.849275578688932, 37.649230445051245],
              [126.849275498646065, 37.649230134552546],
              [126.849274221566773, 37.649225272950375],
              [126.849270869043139, 37.649212468205043],
              [126.849208591521204, 37.648974523527563],
              [126.849152759246778, 37.648761208031885],
              [126.84902748967211, 37.64828258711632],
              [126.849015762671385, 37.648258060332836],
              [126.849015642438175, 37.648257809099874],
              [126.849011265143687, 37.648248652678404],
              [126.848996675150971, 37.648218105439646],
              [126.848719853728227, 37.647638785870441],
              [126.848450832766943, 37.647209253725414],
              [126.848179283653849, 37.647034985047185],
              [126.848148328740152, 37.647015112752342],
              [126.847968038617083, 37.646910635695008],
              [126.847678162512551, 37.646742550435697],
              [126.847573667811773, 37.646681958471113],
              [126.847542560647113, 37.646628859475683],
              [126.847287659326213, 37.646193749598147],
              [126.847255244740637, 37.646138416105664],
              [126.847240157707574, 37.646135000411505],
              [126.847155623286298, 37.646115887731227],
              [126.847053892181435, 37.646094546364175],
              [126.847036314499007, 37.646085594413982],
              [126.846933531531178, 37.645986965344306],
              [126.846931613484415, 37.645939463684883],
              [126.846902998160871, 37.645755663025419],
              [126.846840732965916, 37.645581819612381],
              [126.846712209162945, 37.645427583620354],
              [126.846682528816274, 37.645364940905026],
              [126.846632313377143, 37.645258960299593],
              [126.846558246976414, 37.645088605308771],
              [126.84646011161955, 37.644925111248966],
              [126.846302876849364, 37.644776135438235],
              [126.846242905703804, 37.644595320900301],
              [126.84624490905172, 37.644395586568827],
              [126.846173185500689, 37.644215225122181],
              [126.846157624977536, 37.644124665224069],
              [126.845898590674864, 37.643860015811661],
              [126.845769743220529, 37.643728374206745],
              [126.84553848474151, 37.643593174661419],
              [126.845444759711967, 37.643549010495683],
              [126.845442915178978, 37.643548143097064],
              [126.845320853089888, 37.64349011508547],
              [126.845259327137711, 37.64346086619328],
              [126.84511140828883, 37.643395177370195],
              [126.844957060623102, 37.643325859064916],
              [126.844940409388016, 37.643318386521038],
              [126.84475971077984, 37.643237031297623],
              [126.844643216114406, 37.643201865088685],
              [126.844389506148659, 37.643125286333408],
              [126.843926617744813, 37.643110343172879],
              [126.843786230767293, 37.643111119865004],
              [126.843706836791569, 37.643111689508814],
              [126.843546779416627, 37.643112817967143],
              [126.843496946707603, 37.643113552858949],
              [126.843456731294893, 37.643168296643402],
              [126.843308743119863, 37.643376611166424],
              [126.843085347386477, 37.643618177487454],
              [126.842922361488078, 37.643791946558416],
              [126.842923684141539, 37.643937113032024],
              [126.842773929018847, 37.644254282271298],
              [126.842772037012409, 37.644258289857213],
              [126.842364707569615, 37.644513764035388],
              [126.842304237739995, 37.644532539617451],
              [126.842302390737899, 37.644533110677742],
              [126.842308848540227, 37.644565104212234],
              [126.842366473055421, 37.644960981020482],
              [126.842598204406201, 37.645164914835902],
              [126.842931318122552, 37.64533123260766],
              [126.843083665287494, 37.645490361667825],
              [126.84308452791565, 37.64549126149123],
              [126.843108284728999, 37.645516075776904],
              [126.843230889045074, 37.645644137854809],
              [126.843221672144551, 37.645646340766064],
              [126.843194888680017, 37.645681903214552],
              [126.843159822343381, 37.645713743918847],
              [126.843107906108656, 37.645760883688069],
              [126.843015900156217, 37.645844423885578],
              [126.842943053892469, 37.645956695135013],
              [126.842635184461017, 37.646188722570386],
              [126.842604191431292, 37.646212480150297],
              [126.84248412340672, 37.64630451179309],
              [126.842475872942529, 37.646309843137118],
              [126.842477646569236, 37.646312071193513],
              [126.842174761900623, 37.646655006678401],
              [126.842174490967921, 37.646773121626111],
              [126.842174424221128, 37.64680240993868],
              [126.84216990704455, 37.647814510391775],
              [126.842169036123209, 37.648009255648176],
              [126.842174618393642, 37.648419296718799],
              [126.842114470062199, 37.648574101598392],
              [126.842024120697971, 37.648852371445329],
              [126.841709665332687, 37.649019626141204],
              [126.841452969876627, 37.64929749081233],
              [126.841235358233405, 37.649382797025915],
              [126.841218894085728, 37.649398180894124],
              [126.841161117144793, 37.649450024416332],
              [126.841082019933808, 37.649477056028047],
              [126.840935683399479, 37.649581712013159],
              [126.840893066330125, 37.649612191183053],
              [126.840794550823077, 37.649682645564582],
              [126.840713713260428, 37.649740441258949],
              [126.840711199104646, 37.649744654438301],
              [126.840638639708885, 37.649793901276901],
              [126.840494932738878, 37.649924481890586],
              [126.840398845184737, 37.65001178992852],
              [126.840399717441429, 37.650028008802202],
              [126.840383228918881, 37.650107631891345],
              [126.840290186072352, 37.650139299834713],
              [126.839253196649821, 37.650369204189786],
              [126.838842064726634, 37.650567141529628],
              [126.838797476593271, 37.650588606418218],
              [126.838735046312834, 37.650618662783238],
              [126.838686929688009, 37.650442694433146],
              [126.83864860511035, 37.650302546564092],
              [126.838646290907647, 37.650293784396389],
              [126.838578920362551, 37.650051745238457],
              [126.838180269919533, 37.649584362667184],
              [126.837963618922132, 37.649460825926873],
              [126.837845418582859, 37.649380205677822],
              [126.837469786182936, 37.649223365582898],
              [126.837430241144503, 37.649067801335796],
              [126.837404524797819, 37.648966981215757],
              [126.837071845392543, 37.648904676280758],
              [126.83674447092254, 37.648910527142419],
              [126.836742201528153, 37.648910522494923],
              [126.836511323632323, 37.648834158846128],
              [126.836305453621847, 37.648723590327407],
              [126.836285253731873, 37.648722766432179],
              [126.835994772580563, 37.648710901670022],
              [126.835961606934902, 37.648668430064525],
              [126.83592912724248, 37.648626838402208],
              [126.835911650262432, 37.648660894054757],
              [126.83590750423005, 37.648668973657053],
              [126.835496206449378, 37.648604427133854],
              [126.835421481748782, 37.64858728384354],
              [126.83509779160218, 37.648513026409233],
              [126.834827996600197, 37.648451129965544],
              [126.834765564689221, 37.648440089967565],
              [126.834718432338519, 37.648431757283042],
              [126.834617622568928, 37.648413929181864],
              [126.834580076542409, 37.648407289127412],
              [126.834556832989662, 37.648403175044777],
              [126.834590247647512, 37.648358172727306],
              [126.834524474732518, 37.648331051183625],
              [126.834382515627297, 37.648320038230338],
              [126.834226489126252, 37.648318015782223],
              [126.834194183868718, 37.648373830283255],
              [126.834117063969927, 37.648404352919208],
              [126.833921215220514, 37.64847399252141],
              [126.83377326932569, 37.648505765336772],
              [126.83368656665607, 37.648512851310706],
              [126.833511876792855, 37.648545938296209],
              [126.833316869651384, 37.64854313648253],
              [126.833171404649903, 37.6484814836612],
              [126.833088849855784, 37.648409197336946],
              [126.833050262137448, 37.648435270025416],
              [126.832959595560439, 37.648441447048654],
              [126.832885760344169, 37.648472967193847],
              [126.832788224359362, 37.648511567476795],
              [126.832738287103467, 37.648546635119942],
              [126.832664552208769, 37.648580856973581],
              [126.832577509854815, 37.648589651832197],
              [126.832548148875929, 37.648545462137228],
              [126.832505966293283, 37.64850846278997],
              [126.832242804682423, 37.648580163103226],
              [126.831926364266437, 37.648659713622209],
              [126.831689186678673, 37.648713700928226],
              [126.831472227900193, 37.648748527436005],
              [126.831314054233985, 37.648742894467418],
              [126.831190270430497, 37.648762626754269],
              [126.831128037456509, 37.648772538234702],
              [126.831012004897531, 37.648773451287909],
              [126.830933476225624, 37.648774059579509],
              [126.83083652531343, 37.648774333481931],
              [126.830814977304854, 37.648775948108806],
              [126.830753112357343, 37.648780590511947],
              [126.830676373873359, 37.648785336871185],
              [126.83064179582388, 37.648787528294349],
              [126.830589406023662, 37.648790578549161],
              [126.830809015611834, 37.649064137388706],
              [126.830881119061573, 37.649158077204994],
              [126.831042901349221, 37.649359896262368],
              [126.831153755152258, 37.649500455113532],
              [126.831207546844453, 37.649568666144923],
              [126.831217334281362, 37.649574229648699],
              [126.831303634123714, 37.649709580081591],
              [126.831335543660217, 37.649788606428942],
              [126.831367037207201, 37.649880911477055],
              [126.83140144603297, 37.649974021700707],
              [126.831477331151063, 37.650236584985912],
              [126.831571505806622, 37.650581425012497],
              [126.831642519428328, 37.650821013659673],
              [126.831689313564354, 37.65098738538564],
              [126.831739106058322, 37.651158087829721],
              [126.831758576361224, 37.651224837798232],
              [126.831819241943379, 37.651437267475636],
              [126.831836953198433, 37.651496530951],
              [126.831949102609528, 37.651846539690681],
              [126.831998745269061, 37.651957532702745],
              [126.832046762481781, 37.652065231149031],
              [126.832066111630624, 37.652109366060898],
              [126.832103232421389, 37.652194042362503],
              [126.832134825682957, 37.652248974188794],
              [126.832248552262541, 37.652442882493467],
              [126.832296368009608, 37.652523778714311],
              [126.832327620132247, 37.652556195194443],
              [126.832360565328401, 37.652654774189763],
              [126.83238170322845, 37.652672750925952],
              [126.832414055650645, 37.652761579593253],
              [126.832451232957055, 37.652859776913999],
              [126.832494016622121, 37.653060890686177],
              [126.832495003518986, 37.653096027315158],
              [126.832527299099894, 37.6531567927914],
              [126.832571265582203, 37.653307111696314],
              [126.832578256026977, 37.653331010289108],
              [126.832641645243839, 37.653547742509318],
              [126.832685651252191, 37.653678240494507],
              [126.832718777724537, 37.65375945542543],
              [126.832722953554267, 37.653769653231571],
              [126.832734368609465, 37.653827773206395],
              [126.832737738700118, 37.653844906675559],
              [126.832741186973706, 37.653850262940956],
              [126.83279059858738, 37.653983552224858],
              [126.832793267028123, 37.653999537938894],
              [126.83280961385816, 37.65409411835531],
              [126.832805335282515, 37.654119979377811],
              [126.832719138524013, 37.654196979831625],
              [126.832796587317375, 37.654322254466535],
              [126.832831554756154, 37.654383760530905],
              [126.83290782215569, 37.654502373908564],
              [126.832911302143131, 37.654507611372722],
              [126.832917499042949, 37.65451695893146],
              [126.832928593970621, 37.654513823764638],
              [126.832947226466914, 37.654508556318014],
              [126.833126013795592, 37.65473256190014],
              [126.83308019954427, 37.654762378377136],
              [126.832940281805691, 37.654856764694657],
              [126.832923882450828, 37.654866859658647],
              [126.832700993475882, 37.654458904775559],
              [126.832695894364718, 37.655028673431005],
              [126.832692571937457, 37.655031037745218],
              [126.832688882846298, 37.655033329681558],
              [126.832667976519986, 37.655048170739576],
              [126.83266107040123, 37.65532554397425],
              [126.832531541775111, 37.655619840746816],
              [126.832497629369428, 37.655696885378426],
              [126.832497375932476, 37.655697461695006],
              [126.832486281598477, 37.655722669424215],
              [126.832529648568411, 37.655708736946707],
              [126.833058004062977, 37.65553897707639],
              [126.833066445924231, 37.655536265843608],
              [126.833082426989264, 37.655531123993583],
              [126.834458070014378, 37.655089082547519],
              [126.834491000271058, 37.655078505588499],
              [126.834492800110183, 37.655077919965457],
              [126.834494186024187, 37.655077476219482],
              [126.834502328706861, 37.655074867749398],
              [126.834510396441303, 37.655072273277156],
              [126.834527417597229, 37.655066798198888],
              [126.835024139260497, 37.654907181114488]
            ]
          ]
        ]
      },
      id: '31101550'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '화정1동',
        ADM_CD: '31101620'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.841709665332687, 37.649019626141204],
              [126.842024120697971, 37.648852371445329],
              [126.842114470062199, 37.648574101598392],
              [126.842174618393642, 37.648419296718799],
              [126.842169036123209, 37.648009255648176],
              [126.84216990704455, 37.647814510391775],
              [126.842174424221128, 37.64680240993868],
              [126.842174490967921, 37.646773121626111],
              [126.842174761900623, 37.646655006678401],
              [126.842477646569236, 37.646312071193513],
              [126.842475872942529, 37.646309843137118],
              [126.84248412340672, 37.64630451179309],
              [126.842604191431292, 37.646212480150297],
              [126.842635184461017, 37.646188722570386],
              [126.842943053892469, 37.645956695135013],
              [126.843015900156217, 37.645844423885578],
              [126.843107906108656, 37.645760883688069],
              [126.843159822343381, 37.645713743918847],
              [126.843194888680017, 37.645681903214552],
              [126.843221672144551, 37.645646340766064],
              [126.843230889045074, 37.645644137854809],
              [126.843108284728999, 37.645516075776904],
              [126.84308452791565, 37.64549126149123],
              [126.843083665287494, 37.645490361667825],
              [126.842931318122552, 37.64533123260766],
              [126.842598204406201, 37.645164914835902],
              [126.842366473055421, 37.644960981020482],
              [126.842308848540227, 37.644565104212234],
              [126.842302390737899, 37.644533110677742],
              [126.842304237739995, 37.644532539617451],
              [126.842364707569615, 37.644513764035388],
              [126.842772037012409, 37.644258289857213],
              [126.842773929018847, 37.644254282271298],
              [126.842361354650365, 37.644112888129769],
              [126.842488257784424, 37.643815871962595],
              [126.842488321867364, 37.643750991923042],
              [126.842487131614547, 37.643655326293718],
              [126.842428974661672, 37.643562643816622],
              [126.842387889420849, 37.643554318160561],
              [126.842350603702386, 37.643546762675847],
              [126.842143212712244, 37.643505460257359],
              [126.84204196005382, 37.643423633531846],
              [126.841991062796751, 37.643382089497287],
              [126.841950217619711, 37.643348752233855],
              [126.841859886270484, 37.643102394490512],
              [126.841854699057393, 37.643084189341423],
              [126.841848856844976, 37.64306378219527],
              [126.841848066179224, 37.643061026025258],
              [126.841844690721558, 37.643059987790437],
              [126.841642097846972, 37.642997676033502],
              [126.841596421742921, 37.642955901381654],
              [126.841453364305764, 37.642829208795],
              [126.841421583095638, 37.642801774276393],
              [126.841350301215897, 37.642645540957304],
              [126.841379938586243, 37.642457460133933],
              [126.841352885582381, 37.642392639603599],
              [126.841254338865809, 37.642226457443932],
              [126.841239327547427, 37.64219841618047],
              [126.841238925670879, 37.642179233642146],
              [126.841053369276082, 37.64211131779971],
              [126.840930653839663, 37.641999342433749],
              [126.840862123035024, 37.641936812884531],
              [126.840661756473224, 37.641753986986522],
              [126.840684068723704, 37.641652183015708],
              [126.84070891551454, 37.641538818326879],
              [126.840713475025382, 37.641520528787829],
              [126.840983094536668, 37.641069602835451],
              [126.84107844623216, 37.640928180502122],
              [126.841320212263781, 37.640803975333306],
              [126.841668950965783, 37.640624814979894],
              [126.841827261805705, 37.640398881318937],
              [126.841862497515422, 37.640350573720831],
              [126.842017713818976, 37.640137767522532],
              [126.842032457868086, 37.640118991225897],
              [126.842054321241164, 37.640089533311524],
              [126.842123626140349, 37.640026801463961],
              [126.842211343752169, 37.639959832333922],
              [126.841902746228754, 37.639576447627469],
              [126.841553257654041, 37.639280882121277],
              [126.841266960288252, 37.639204624936589],
              [126.840513792052477, 37.639153005408033],
              [126.840009892752249, 37.639144252304824],
              [126.839646769891374, 37.639082977739754],
              [126.839626946258008, 37.639079631906043],
              [126.839586463751985, 37.639072801254962],
              [126.839576714820126, 37.639068605070584],
              [126.839247911117397, 37.638927053349768],
              [126.838977130153566, 37.638809674117901],
              [126.838887093965027, 37.638770644065872],
              [126.838610993108631, 37.638614349904657],
              [126.8385382397082, 37.638131341963039],
              [126.838466747401483, 37.637659856406621],
              [126.838454256612465, 37.637577472388735],
              [126.838577452660729, 37.637266301968815],
              [126.838586594247332, 37.637243214268658],
              [126.838481606013673, 37.63719369782784],
              [126.838419950484351, 37.636736403793229],
              [126.838382137480963, 37.63645590685794],
              [126.838375829852609, 37.636393309244021],
              [126.838372319270974, 37.636358467559646],
              [126.838372292505539, 37.636358162759862],
              [126.838343481705778, 37.636072116313493],
              [126.838302418445778, 37.63566436027503],
              [126.838269148536355, 37.635334068594034],
              [126.838244637268687, 37.635090715180858],
              [126.838190858573682, 37.634556712248845],
              [126.838173814621115, 37.634099677146857],
              [126.837716848007148, 37.634121973399793],
              [126.837713306973569, 37.63412214820994],
              [126.837585028789277, 37.634128405260562],
              [126.837448335143321, 37.634135072789405],
              [126.836934858114375, 37.634160122191162],
              [126.836623218948674, 37.634175322584838],
              [126.836606765622022, 37.634176125093333],
              [126.836157509482973, 37.634198040438946],
              [126.835991777623633, 37.634206123822224],
              [126.83598851782665, 37.63420627943276],
              [126.835815347865264, 37.634214724501888],
              [126.835790818090643, 37.634215920377692],
              [126.835675484791921, 37.634221546990844],
              [126.835615000563635, 37.634224491756285],
              [126.835589633675056, 37.634227546827873],
              [126.835416013978701, 37.634248428941923],
              [126.835374962715505, 37.634253366827487],
              [126.834601559175681, 37.634346397174539],
              [126.834597389607197, 37.634346897331888],
              [126.834570056505612, 37.634350178144594],
              [126.83456894348673, 37.634350311573293],
              [126.834560574818582, 37.634351318929603],
              [126.834535978414735, 37.634354260045576],
              [126.834354897047575, 37.634376049412005],
              [126.834330656735503, 37.634378964552887],
              [126.834288462784102, 37.634384042529369],
              [126.834287421234933, 37.634384168246704],
              [126.833972801758406, 37.6344220150796],
              [126.83369889548969, 37.63445494891559],
              [126.833622332658891, 37.634464160739824],
              [126.833544787254723, 37.634473491347507],
              [126.833409635621052, 37.6344897485518],
              [126.833417376192386, 37.63453723291012],
              [126.833427353749727, 37.634598451760127],
              [126.833323065394353, 37.634610304472297],
              [126.833281438769546, 37.634615034675889],
              [126.832644730349415, 37.634687387310734],
              [126.832595998180352, 37.634692919823763],
              [126.832586027841273, 37.634640979242519],
              [126.832574277083694, 37.634579779136011],
              [126.832264914282064, 37.634621225819217],
              [126.832014922361012, 37.634654722819697],
              [126.831962913214397, 37.634661691264775],
              [126.831882235123629, 37.63467250195206],
              [126.831614199453497, 37.634708424152159],
              [126.831605839907098, 37.634709543115122],
              [126.831160272967907, 37.634769251285213],
              [126.831152012159947, 37.63477035365041],
              [126.831141892596165, 37.634771712271231],
              [126.831140311930056, 37.634771922333407],
              [126.831097934212963, 37.634777602016172],
              [126.830959849863874, 37.634796092205832],
              [126.830910627400087, 37.634802690219146],
              [126.830874035648776, 37.634807595042204],
              [126.830868559423308, 37.634808329213385],
              [126.830863827612205, 37.634808960347989],
              [126.830667119245902, 37.634835322501502],
              [126.830457170714368, 37.634863452409796],
              [126.830367888626284, 37.634875416507413],
              [126.830310752443879, 37.634883071680349],
              [126.829864571968031, 37.634942847175843],
              [126.829647732721057, 37.634971899121837],
              [126.829599155715115, 37.634978408318496],
              [126.829299335003398, 37.635018584585808],
              [126.829259972041342, 37.635023857120046],
              [126.829161304200909, 37.635037072069053],
              [126.829090159410356, 37.635046603784033],
              [126.828916163969126, 37.635069916782371],
              [126.828657732286558, 37.635104542995805],
              [126.828480858949803, 37.635128232856118],
              [126.828398826110814, 37.635139225428702],
              [126.828266123529275, 37.635157009411479],
              [126.828091142703201, 37.635180445371674],
              [126.827669716618203, 37.635236902330888],
              [126.827614030109018, 37.635244361656945],
              [126.827181704108014, 37.635302273490204],
              [126.827181110634697, 37.635302353028116],
              [126.825864230773959, 37.635478753092869],
              [126.8258613802724, 37.635479135193059],
              [126.825848232939563, 37.635450297730181],
              [126.825757741157346, 37.635251806205119],
              [126.825741706861976, 37.635256117567522],
              [126.825701986434169, 37.635266792145536],
              [126.82568049755281, 37.635272568532542],
              [126.825444113368434, 37.635328778877998],
              [126.825347284817454, 37.635352050075234],
              [126.825258083194456, 37.635373387294088],
              [126.825018738943399, 37.635430619989492],
              [126.824743676438672, 37.635496431230955],
              [126.824513833665193, 37.635551034920823],
              [126.824048704058626, 37.635661679829802],
              [126.823657210979917, 37.635753979367934],
              [126.82293194007346, 37.63592828809827],
              [126.822540575694532, 37.636022710890785],
              [126.822348074967195, 37.636070937843655],
              [126.822272564927815, 37.636088815519997],
              [126.822206261494941, 37.636105182232996],
              [126.822544875125885, 37.636993321538071],
              [126.822563564502715, 37.637042426929241],
              [126.822892528548152, 37.637910718330865],
              [126.822900084728161, 37.637935355371461],
              [126.822907387782053, 37.637959159933047],
              [126.822991523291918, 37.638182025963353],
              [126.823237149318416, 37.638837740818673],
              [126.823256621414558, 37.638889747450754],
              [126.823420449959315, 37.639317922563855],
              [126.823586457038971, 37.639751795501027],
              [126.823594459281551, 37.639772783764265],
              [126.823602474851342, 37.6397938027485],
              [126.823845574225643, 37.640417052066709],
              [126.823947359416934, 37.640676100504074],
              [126.823941574723904, 37.640678299045682],
              [126.82395678589674, 37.64071930655038],
              [126.824277217801651, 37.641579400382376],
              [126.824291081478506, 37.641613542810838],
              [126.824291773331964, 37.641615246679812],
              [126.824623564865092, 37.642510998009364],
              [126.824642027543888, 37.642557020997074],
              [126.824975310648469, 37.643414112767985],
              [126.824984632716053, 37.643444908625575],
              [126.824994355391468, 37.643477029924959],
              [126.824990849791121, 37.643478122106373],
              [126.825252320151918, 37.644161427019846],
              [126.825285157014704, 37.644150881807533],
              [126.825358581232592, 37.644126329509938],
              [126.82547647441092, 37.644338783409317],
              [126.825508031455655, 37.644396570449906],
              [126.825514870094082, 37.644409090245446],
              [126.825524954351494, 37.644427837849037],
              [126.825539236874064, 37.644449625299231],
              [126.825556567339234, 37.64447606119883],
              [126.825581774886984, 37.644515130082063],
              [126.82571666434778, 37.64472317515456],
              [126.825746627008002, 37.644768282139928],
              [126.82579343138039, 37.644838741495185],
              [126.825819085892647, 37.644876543810192],
              [126.825827290336392, 37.644901499810764],
              [126.825956442736128, 37.645294368871191],
              [126.826027210868574, 37.645284118591867],
              [126.826041195680546, 37.64531430015289],
              [126.826043310424126, 37.64531886304156],
              [126.826057444775486, 37.645351564667763],
              [126.826071768542832, 37.645384254755676],
              [126.826111466616041, 37.645472501659846],
              [126.826234383055237, 37.645840083150425],
              [126.826270046891764, 37.646246620067004],
              [126.826276824578983, 37.646329293454087],
              [126.826458769306626, 37.646747094873902],
              [126.826542610092645, 37.646889083866057],
              [126.826583372811513, 37.646958117650939],
              [126.826684762216431, 37.647135940770312],
              [126.826712462263629, 37.647182351424597],
              [126.826712934704105, 37.64718313286852],
              [126.826732112121476, 37.64721491499143],
              [126.826985925766181, 37.647807486725881],
              [126.827125978641718, 37.648055938164589],
              [126.82715218524406, 37.648100087773585],
              [126.827158080730271, 37.648108279319665],
              [126.827415514570504, 37.648516576178331],
              [126.827469907979292, 37.648660658019168],
              [126.827536802773224, 37.648838428827169],
              [126.827506562003464, 37.649026642993242],
              [126.827830373245362, 37.648975548105518],
              [126.827856698079842, 37.648970939088997],
              [126.827952969235199, 37.64896511428806],
              [126.828095592348873, 37.648943594512062],
              [126.828168775964926, 37.648925916261931],
              [126.828241292006126, 37.64890922923081],
              [126.828267075748414, 37.648906544080432],
              [126.828390563253606, 37.648892257200025],
              [126.828460108770557, 37.648871717971396],
              [126.828639397974499, 37.648849745583355],
              [126.828654894081424, 37.6488734170199],
              [126.828664565152636, 37.648888350278902],
              [126.828686232913867, 37.648921799287798],
              [126.828708848724943, 37.648957223990557],
              [126.828727307928332, 37.648986200370281],
              [126.828738006471795, 37.649006880929178],
              [126.828740562426091, 37.649011823934721],
              [126.828831088286918, 37.648987639022359],
              [126.82886252271706, 37.648980319716131],
              [126.829296413646475, 37.648891079092778],
              [126.829306902741294, 37.648887796604015],
              [126.829308515850229, 37.648887290215619],
              [126.82930817472338, 37.648885642451134],
              [126.829314161033025, 37.648884019844814],
              [126.829653228097953, 37.648792104410504],
              [126.8299536268336, 37.648734878608948],
              [126.830104291293324, 37.648690435331858],
              [126.830301107947236, 37.648658890496932],
              [126.830360395312354, 37.648652957418228],
              [126.830407155978122, 37.648650124981181],
              [126.830460324777519, 37.648605748826306],
              [126.830477270166242, 37.648631310225269],
              [126.830498347052341, 37.648663105220287],
              [126.830540480506642, 37.64872896462763],
              [126.830589406023662, 37.648790578549161],
              [126.83064179582388, 37.648787528294349],
              [126.830676373873359, 37.648785336871185],
              [126.830753112357343, 37.648780590511947],
              [126.830814977304854, 37.648775948108806],
              [126.83083652531343, 37.648774333481931],
              [126.830933476225624, 37.648774059579509],
              [126.831012004897531, 37.648773451287909],
              [126.831128037456509, 37.648772538234702],
              [126.831190270430497, 37.648762626754269],
              [126.831314054233985, 37.648742894467418],
              [126.831472227900193, 37.648748527436005],
              [126.831689186678673, 37.648713700928226],
              [126.831926364266437, 37.648659713622209],
              [126.832242804682423, 37.648580163103226],
              [126.832505966293283, 37.64850846278997],
              [126.832548148875929, 37.648545462137228],
              [126.832577509854815, 37.648589651832197],
              [126.832664552208769, 37.648580856973581],
              [126.832738287103467, 37.648546635119942],
              [126.832788224359362, 37.648511567476795],
              [126.832885760344169, 37.648472967193847],
              [126.832959595560439, 37.648441447048654],
              [126.833050262137448, 37.648435270025416],
              [126.833088849855784, 37.648409197336946],
              [126.833171404649903, 37.6484814836612],
              [126.833316869651384, 37.64854313648253],
              [126.833511876792855, 37.648545938296209],
              [126.83368656665607, 37.648512851310706],
              [126.83377326932569, 37.648505765336772],
              [126.833921215220514, 37.64847399252141],
              [126.834117063969927, 37.648404352919208],
              [126.834194183868718, 37.648373830283255],
              [126.834226489126252, 37.648318015782223],
              [126.834382515627297, 37.648320038230338],
              [126.834524474732518, 37.648331051183625],
              [126.834590247647512, 37.648358172727306],
              [126.834556832989662, 37.648403175044777],
              [126.834580076542409, 37.648407289127412],
              [126.834617622568928, 37.648413929181864],
              [126.834718432338519, 37.648431757283042],
              [126.834765564689221, 37.648440089967565],
              [126.834827996600197, 37.648451129965544],
              [126.83509779160218, 37.648513026409233],
              [126.835421481748782, 37.64858728384354],
              [126.835496206449378, 37.648604427133854],
              [126.83590750423005, 37.648668973657053],
              [126.835911650262432, 37.648660894054757],
              [126.83592912724248, 37.648626838402208],
              [126.835961606934902, 37.648668430064525],
              [126.835994772580563, 37.648710901670022],
              [126.836285253731873, 37.648722766432179],
              [126.836305453621847, 37.648723590327407],
              [126.836511323632323, 37.648834158846128],
              [126.836742201528153, 37.648910522494923],
              [126.83674447092254, 37.648910527142419],
              [126.837071845392543, 37.648904676280758],
              [126.837404524797819, 37.648966981215757],
              [126.837430241144503, 37.649067801335796],
              [126.837469786182936, 37.649223365582898],
              [126.837845418582859, 37.649380205677822],
              [126.837963618922132, 37.649460825926873],
              [126.838180269919533, 37.649584362667184],
              [126.838578920362551, 37.650051745238457],
              [126.838646290907647, 37.650293784396389],
              [126.83864860511035, 37.650302546564092],
              [126.838686929688009, 37.650442694433146],
              [126.838735046312834, 37.650618662783238],
              [126.838797476593271, 37.650588606418218],
              [126.838842064726634, 37.650567141529628],
              [126.839253196649821, 37.650369204189786],
              [126.840290186072352, 37.650139299834713],
              [126.840383228918881, 37.650107631891345],
              [126.840399717441429, 37.650028008802202],
              [126.840398845184737, 37.65001178992852],
              [126.840494932738878, 37.649924481890586],
              [126.840638639708885, 37.649793901276901],
              [126.840711199104646, 37.649744654438301],
              [126.840713713260428, 37.649740441258949],
              [126.840794550823077, 37.649682645564582],
              [126.840893066330125, 37.649612191183053],
              [126.840935683399479, 37.649581712013159],
              [126.841082019933808, 37.649477056028047],
              [126.841161117144793, 37.649450024416332],
              [126.841218894085728, 37.649398180894124],
              [126.841235358233405, 37.649382797025915],
              [126.841452969876627, 37.64929749081233],
              [126.841709665332687, 37.649019626141204]
            ]
          ]
        ]
      },
      id: '31101620'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '화정2동',
        ADM_CD: '31101630'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.843592851336638, 37.639436258672418],
              [126.843616286699145, 37.639434544939604],
              [126.844028212720858, 37.639449148206147],
              [126.844453291774613, 37.639346579893086],
              [126.844557592373633, 37.639299318594773],
              [126.844655939547536, 37.639211208295372],
              [126.844800825340528, 37.638996750122203],
              [126.844824721852035, 37.638956961111056],
              [126.844853796750087, 37.63892120095489],
              [126.844913439451403, 37.638847843886566],
              [126.845076904655699, 37.638645069712155],
              [126.845231011201804, 37.638470214346739],
              [126.84540816877869, 37.638274667741385],
              [126.845520143655719, 37.638149398348801],
              [126.845550454259779, 37.638119346684938],
              [126.845899158717359, 37.637642470313693],
              [126.846140103316301, 37.637719793488017],
              [126.846409021878856, 37.637806087061264],
              [126.846436011988018, 37.637814747844871],
              [126.84644728671222, 37.637827614603992],
              [126.846481833609133, 37.637867039088938],
              [126.846477974839843, 37.637870344524963],
              [126.846447987489, 37.637896024965265],
              [126.846497261737198, 37.637895371345678],
              [126.846601074774881, 37.637893955297855],
              [126.846614648835356, 37.637883313654186],
              [126.84667382374117, 37.637836938918554],
              [126.846703101324138, 37.637813993633429],
              [126.846953995654388, 37.637786391374497],
              [126.847027346283696, 37.637740749291432],
              [126.847106577271717, 37.637691446952665],
              [126.847113145589418, 37.637677826340635],
              [126.8471779178372, 37.637543509754501],
              [126.84728279173865, 37.637493842635728],
              [126.847401785069977, 37.637437489939821],
              [126.847507466835751, 37.637388466131341],
              [126.847593225057366, 37.637348684261696],
              [126.847663069049986, 37.637316285466206],
              [126.847679249974249, 37.637262313120672],
              [126.847714179034938, 37.6371457983415],
              [126.847715110452242, 37.637142714630897],
              [126.847743721955055, 37.63704796359449],
              [126.847753536740626, 37.637015478014732],
              [126.847747476563214, 37.636954137799172],
              [126.847732337374069, 37.636800930630109],
              [126.847716114048609, 37.636647021634161],
              [126.847709478130753, 37.63662807863161],
              [126.847622744924138, 37.636380479406938],
              [126.847599693941504, 37.636316705370561],
              [126.847520342621706, 37.636097169255528],
              [126.847518540483065, 37.63599037772336],
              [126.847516609177433, 37.635876064770244],
              [126.847515715887965, 37.635703706438647],
              [126.847690767086462, 37.635476440302178],
              [126.847802428949663, 37.635336394108784],
              [126.847841158015356, 37.635288240973324],
              [126.847880647562221, 37.635258109634158],
              [126.847986503662582, 37.635126615444932],
              [126.848015987801801, 37.635058718878177],
              [126.848079469562833, 37.634819861823075],
              [126.848124930077475, 37.634639547555992],
              [126.848182059799598, 37.634379016172367],
              [126.848188173050559, 37.634351133918585],
              [126.84824396523338, 37.634106141261171],
              [126.84828912629861, 37.633905642643768],
              [126.848285316853762, 37.633890746031135],
              [126.848282901297537, 37.633891697112425],
              [126.848256780099277, 37.633784727046326],
              [126.848233185432022, 37.633689280929751],
              [126.848230693236971, 37.633679154225902],
              [126.848189998275373, 37.633513812737419],
              [126.848177905254914, 37.633464787416791],
              [126.848176909210551, 37.633460745752558],
              [126.848176444876714, 37.633454738518431],
              [126.848164876307465, 37.633303639425428],
              [126.84816011334766, 37.633241434772223],
              [126.848224718982905, 37.632955930857918],
              [126.848233510592294, 37.63291463625756],
              [126.848274171850647, 37.632723577626109],
              [126.84842867544647, 37.63244852983037],
              [126.848569987181776, 37.632196187428619],
              [126.848554633220218, 37.631894944368597],
              [126.84854351316055, 37.631658647581069],
              [126.848536458126858, 37.631455484545612],
              [126.848670464062522, 37.631028019766021],
              [126.848722557942864, 37.630839458520143],
              [126.84874799509447, 37.63074738188142],
              [126.848726073988885, 37.630416300887319],
              [126.848660470622193, 37.630304994721968],
              [126.848571524962551, 37.630154076805553],
              [126.848414547382433, 37.62998555327362],
              [126.84836629624192, 37.629694537883772],
              [126.848332858679711, 37.629496062002332],
              [126.848332390724849, 37.629493066094611],
              [126.848322155544267, 37.629427573771025],
              [126.848322935446632, 37.629423657284988],
              [126.848327567552403, 37.629107839947835],
              [126.848252070613327, 37.62882361243846],
              [126.848105099818568, 37.628849593498941],
              [126.848090459858085, 37.628912417468989],
              [126.848060108336213, 37.629085095486403],
              [126.847943719745771, 37.629220945523336],
              [126.847874867137435, 37.629276178070256],
              [126.847849343857746, 37.629259638166872],
              [126.8477737475597, 37.629199127604174],
              [126.847585513207065, 37.629130048974496],
              [126.847509783149533, 37.629080081283448],
              [126.847502682588583, 37.629088568615856],
              [126.847427193932319, 37.629131671033264],
              [126.847433288819332, 37.629323226947967],
              [126.847435043234071, 37.629378431509465],
              [126.847374067988525, 37.629612724445693],
              [126.847313719047165, 37.629668838327817],
              [126.84715565451792, 37.629644964553307],
              [126.84675962039401, 37.62978202471821],
              [126.84674922399185, 37.629787930368643],
              [126.84642502317071, 37.629891639431293],
              [126.846200871056823, 37.629628745463421],
              [126.845862147447846, 37.629619011114102],
              [126.84573297489132, 37.629615388099509],
              [126.845180734116852, 37.62964886900324],
              [126.845054378183505, 37.629631123489929],
              [126.845030547871602, 37.629628478293306],
              [126.845016048466292, 37.629569085671406],
              [126.844575092942577, 37.62964715563507],
              [126.844447735996866, 37.629332071713534],
              [126.844409776443783, 37.629238161059178],
              [126.84412015747823, 37.629241496165278],
              [126.843876423337633, 37.62924285583734],
              [126.84377492977751, 37.629238846504386],
              [126.843642367732443, 37.629200370388553],
              [126.843440608465031, 37.629141813187303],
              [126.843401324048173, 37.629152790919839],
              [126.843370331858935, 37.629161450594609],
              [126.843350497495535, 37.62916565758902],
              [126.842867007364447, 37.62926943288317],
              [126.842670086827837, 37.629311719766086],
              [126.842612228524985, 37.629324140440112],
              [126.842423780964737, 37.629349743601203],
              [126.84238230524646, 37.629355364785219],
              [126.842282596552579, 37.629365500601246],
              [126.842265374249678, 37.629367270351452],
              [126.842221976981136, 37.629371545894969],
              [126.842150251522611, 37.629378837969789],
              [126.84185768910406, 37.629408354619315],
              [126.841707229429574, 37.629470388535182],
              [126.841694958887985, 37.629475446846968],
              [126.841655546528841, 37.629492015143001],
              [126.841634090869746, 37.629501033122573],
              [126.841612916687694, 37.629497670176029],
              [126.841593308621171, 37.629494574647239],
              [126.84159139262465, 37.629494272825283],
              [126.841544966342639, 37.629486944599257],
              [126.841418856284619, 37.629437614811941],
              [126.841390578631717, 37.629426551758435],
              [126.841234788232896, 37.629332512289167],
              [126.840990411180812, 37.62918499578322],
              [126.840827033231292, 37.629089811968655],
              [126.840709435196317, 37.629199569835194],
              [126.840580708640516, 37.629319765898089],
              [126.840516978924356, 37.629351753083739],
              [126.840427995160809, 37.629376409141209],
              [126.840347401296938, 37.62939873162177],
              [126.840264089067261, 37.629421773432298],
              [126.840220730348946, 37.629459104787252],
              [126.840107296584037, 37.629541972470861],
              [126.840075839523067, 37.629564950834613],
              [126.840053210659079, 37.629550776113049],
              [126.84003386811851, 37.629538677509068],
              [126.839985357765727, 37.62950470356872],
              [126.83994698651874, 37.629477831136484],
              [126.839853954414181, 37.629442385862113],
              [126.839727726961613, 37.629425932384144],
              [126.839717033125453, 37.629424537667802],
              [126.839558921816348, 37.629459821367718],
              [126.839474692813212, 37.629487815711975],
              [126.839266573661376, 37.629589413022835],
              [126.839127217922766, 37.629657441987227],
              [126.839084308171749, 37.629683411448696],
              [126.839019683686629, 37.629722525043128],
              [126.838598643951741, 37.630015483186121],
              [126.838480834077103, 37.630097463083843],
              [126.838420968864142, 37.630139123151103],
              [126.8383239535315, 37.630211515754233],
              [126.838147636396727, 37.630349590524965],
              [126.838047059798186, 37.630428358765066],
              [126.837918371660649, 37.630529193296944],
              [126.837897039641192, 37.630545906852163],
              [126.837853661854709, 37.630540984611216],
              [126.837632667468768, 37.630558591007009],
              [126.837544674334183, 37.630565601470515],
              [126.837436278451364, 37.630556891648126],
              [126.837133886676312, 37.630520252146226],
              [126.836946524016213, 37.630514403381625],
              [126.836907311021747, 37.630317666668965],
              [126.836893577799032, 37.630243546929883],
              [126.83687386773353, 37.630137141261123],
              [126.836841033590986, 37.629959885687228],
              [126.83678757618577, 37.629690014151642],
              [126.836727945374591, 37.629388985807438],
              [126.836700091188078, 37.629248365742932],
              [126.836647278472086, 37.629155851815547],
              [126.836580028099476, 37.628816360381471],
              [126.836561931413129, 37.628725006030287],
              [126.836532351033284, 37.628575674752618],
              [126.836489534307162, 37.628359527236704],
              [126.836501455773742, 37.628245751602002],
              [126.836454404078452, 37.628008213282136],
              [126.836386653392836, 37.627666185223205],
              [126.836347412348331, 37.627468081665519],
              [126.836312404311826, 37.627294309011248],
              [126.836275477528659, 37.627111012894375],
              [126.836227654565491, 37.626868224355363],
              [126.83620450604154, 37.626750697475359],
              [126.8361355097325, 37.626400401495673],
              [126.836118597148015, 37.626314535605992],
              [126.836038891267023, 37.625909856920963],
              [126.836001692788429, 37.625721070942511],
              [126.835996770167412, 37.625696087163675],
              [126.835874132289561, 37.625574913214436],
              [126.835609641422508, 37.625313616194902],
              [126.835546681976794, 37.625251457336546],
              [126.835464059506293, 37.625169886581432],
              [126.83522545570608, 37.624934126811304],
              [126.834957350289429, 37.624669220653203],
              [126.834705752627869, 37.624420645357624],
              [126.834633186503879, 37.624348915578167],
              [126.834461424820631, 37.624179211221083],
              [126.834449066116818, 37.624167002371664],
              [126.83428506616788, 37.6240049695094],
              [126.834116603236069, 37.624113011600166],
              [126.833903796280964, 37.624249492520619],
              [126.83327193561172, 37.624654731047166],
              [126.832958607616561, 37.624855677791551],
              [126.832932061983854, 37.624872668347045],
              [126.832781390109844, 37.624969298171692],
              [126.832420265890605, 37.625200895168113],
              [126.832104278807122, 37.625403541599425],
              [126.831667922950075, 37.625683381392939],
              [126.831403300605245, 37.625853082946257],
              [126.831301434949381, 37.625918400233374],
              [126.831260020955568, 37.625944954896241],
              [126.831278555877333, 37.625963452518079],
              [126.83127106921458, 37.626017140266057],
              [126.83092858159425, 37.626234951426433],
              [126.830834766900878, 37.626292658947044],
              [126.8308236473204, 37.626299004350898],
              [126.830738356289743, 37.626347658488939],
              [126.830639454144844, 37.6263996838439],
              [126.830594660450245, 37.626421422209795],
              [126.830599023922588, 37.626445754974547],
              [126.828126333511051, 37.627233025663244],
              [126.82809617961172, 37.627242622581122],
              [126.828053664750669, 37.627256434025597],
              [126.828044277701764, 37.62725948835655],
              [126.828010690488313, 37.627270417696465],
              [126.82793122968323, 37.627296258750157],
              [126.827797119772654, 37.627339848393397],
              [126.827648051043695, 37.62738830816194],
              [126.827362581485474, 37.627481101849249],
              [126.827237210159595, 37.627521849198452],
              [126.827028373088453, 37.627589736268249],
              [126.826921118652791, 37.627624597265147],
              [126.82672033379805, 37.627689864484282],
              [126.826710197673577, 37.627693156892093],
              [126.826538945781678, 37.627748821893348],
              [126.826477255893167, 37.627768874096084],
              [126.825415096110703, 37.628114113430982],
              [126.825022356891608, 37.628241767164241],
              [126.824318072229275, 37.628470674219926],
              [126.823663926590953, 37.628683281780098],
              [126.823157027630586, 37.628848027962427],
              [126.823149264436793, 37.628850553122355],
              [126.823004204406132, 37.628897693309689],
              [126.823003189423517, 37.628898020076946],
              [126.822993658935516, 37.628901100220467],
              [126.822956984189801, 37.629029974763071],
              [126.822932761000033, 37.629023901400323],
              [126.822953562526607, 37.629067483836771],
              [126.823095121455538, 37.629364041594542],
              [126.823179572012194, 37.629540957942588],
              [126.823254617313367, 37.629698178482954],
              [126.823314293938438, 37.629823194616947],
              [126.823323897609328, 37.629843302500205],
              [126.823324539168055, 37.629844789769891],
              [126.823334016086633, 37.629866753666434],
              [126.823393902954308, 37.630005609993795],
              [126.823484384412453, 37.630215369128578],
              [126.823516663693866, 37.630290187986269],
              [126.823537215928894, 37.630337842898157],
              [126.823573794024824, 37.63042265420053],
              [126.823595380810858, 37.630472709867988],
              [126.823663703599379, 37.630631099373851],
              [126.823675766929114, 37.630659064704403],
              [126.823806423969501, 37.630961953999801],
              [126.824030508743974, 37.631481468816624],
              [126.824047346491611, 37.631520502757809],
              [126.824068741963103, 37.631570102965362],
              [126.824083701667135, 37.631604776321126],
              [126.824089347820788, 37.631617795035389],
              [126.824135620834156, 37.631719207412132],
              [126.824144333144602, 37.631738302430584],
              [126.824315991721463, 37.63209166794185],
              [126.824352844097035, 37.632167529443826],
              [126.824431260889696, 37.632328955173094],
              [126.824450753559091, 37.632369086752284],
              [126.824467841148348, 37.632401600033745],
              [126.824497783760194, 37.632458570601628],
              [126.824504777225684, 37.632471879124907],
              [126.824853759268933, 37.633263280603515],
              [126.824884678018279, 37.633330306484957],
              [126.824915188895915, 37.63339644672039],
              [126.825002661846341, 37.633678223498173],
              [126.825122926943521, 37.633925152178108],
              [126.825147086762215, 37.633974769790747],
              [126.825226471628909, 37.63415210987138],
              [126.825251893810474, 37.634208909268374],
              [126.825291445977768, 37.634294500433789],
              [126.825520801585242, 37.634790827697003],
              [126.825557242854984, 37.634869692383823],
              [126.825561973877583, 37.63487617565535],
              [126.825535292598872, 37.634875365158763],
              [126.825504855207114, 37.634874443049661],
              [126.825511269550361, 37.634884156398684],
              [126.825661732800853, 37.635108596793685],
              [126.825757741157346, 37.635251806205119],
              [126.825848232939563, 37.635450297730181],
              [126.8258613802724, 37.635479135193059],
              [126.825864230773959, 37.635478753092869],
              [126.827181110634697, 37.635302353028116],
              [126.827181704108014, 37.635302273490204],
              [126.827614030109018, 37.635244361656945],
              [126.827669716618203, 37.635236902330888],
              [126.828091142703201, 37.635180445371674],
              [126.828266123529275, 37.635157009411479],
              [126.828398826110814, 37.635139225428702],
              [126.828480858949803, 37.635128232856118],
              [126.828657732286558, 37.635104542995805],
              [126.828916163969126, 37.635069916782371],
              [126.829090159410356, 37.635046603784033],
              [126.829161304200909, 37.635037072069053],
              [126.829259972041342, 37.635023857120046],
              [126.829299335003398, 37.635018584585808],
              [126.829599155715115, 37.634978408318496],
              [126.829647732721057, 37.634971899121837],
              [126.829864571968031, 37.634942847175843],
              [126.830310752443879, 37.634883071680349],
              [126.830367888626284, 37.634875416507413],
              [126.830457170714368, 37.634863452409796],
              [126.830667119245902, 37.634835322501502],
              [126.830863827612205, 37.634808960347989],
              [126.830868559423308, 37.634808329213385],
              [126.830874035648776, 37.634807595042204],
              [126.830910627400087, 37.634802690219146],
              [126.830959849863874, 37.634796092205832],
              [126.831097934212963, 37.634777602016172],
              [126.831140311930056, 37.634771922333407],
              [126.831141892596165, 37.634771712271231],
              [126.831152012159947, 37.63477035365041],
              [126.831160272967907, 37.634769251285213],
              [126.831605839907098, 37.634709543115122],
              [126.831614199453497, 37.634708424152159],
              [126.831882235123629, 37.63467250195206],
              [126.831962913214397, 37.634661691264775],
              [126.832014922361012, 37.634654722819697],
              [126.832264914282064, 37.634621225819217],
              [126.832574277083694, 37.634579779136011],
              [126.832586027841273, 37.634640979242519],
              [126.832595998180352, 37.634692919823763],
              [126.832644730349415, 37.634687387310734],
              [126.833281438769546, 37.634615034675889],
              [126.833323065394353, 37.634610304472297],
              [126.833427353749727, 37.634598451760127],
              [126.833417376192386, 37.63453723291012],
              [126.833409635621052, 37.6344897485518],
              [126.833544787254723, 37.634473491347507],
              [126.833622332658891, 37.634464160739824],
              [126.83369889548969, 37.63445494891559],
              [126.833972801758406, 37.6344220150796],
              [126.834287421234933, 37.634384168246704],
              [126.834288462784102, 37.634384042529369],
              [126.834330656735503, 37.634378964552887],
              [126.834354897047575, 37.634376049412005],
              [126.834535978414735, 37.634354260045576],
              [126.834560574818582, 37.634351318929603],
              [126.83456894348673, 37.634350311573293],
              [126.834570056505612, 37.634350178144594],
              [126.834597389607197, 37.634346897331888],
              [126.834601559175681, 37.634346397174539],
              [126.835374962715505, 37.634253366827487],
              [126.835416013978701, 37.634248428941923],
              [126.835589633675056, 37.634227546827873],
              [126.835615000563635, 37.634224491756285],
              [126.835675484791921, 37.634221546990844],
              [126.835790818090643, 37.634215920377692],
              [126.835815347865264, 37.634214724501888],
              [126.83598851782665, 37.63420627943276],
              [126.835991777623633, 37.634206123822224],
              [126.836157509482973, 37.634198040438946],
              [126.836606765622022, 37.634176125093333],
              [126.836623218948674, 37.634175322584838],
              [126.836934858114375, 37.634160122191162],
              [126.837448335143321, 37.634135072789405],
              [126.837585028789277, 37.634128405260562],
              [126.837713306973569, 37.63412214820994],
              [126.837716848007148, 37.634121973399793],
              [126.838173814621115, 37.634099677146857],
              [126.838190858573682, 37.634556712248845],
              [126.838244637268687, 37.635090715180858],
              [126.838269148536355, 37.635334068594034],
              [126.838302418445778, 37.63566436027503],
              [126.838343481705778, 37.636072116313493],
              [126.838372292505539, 37.636358162759862],
              [126.838372319270974, 37.636358467559646],
              [126.838375829852609, 37.636393309244021],
              [126.838382137480963, 37.63645590685794],
              [126.838419950484351, 37.636736403793229],
              [126.838481606013673, 37.63719369782784],
              [126.838586594247332, 37.637243214268658],
              [126.838577452660729, 37.637266301968815],
              [126.838454256612465, 37.637577472388735],
              [126.838466747401483, 37.637659856406621],
              [126.8385382397082, 37.638131341963039],
              [126.838610993108631, 37.638614349904657],
              [126.838887093965027, 37.638770644065872],
              [126.838977130153566, 37.638809674117901],
              [126.839247911117397, 37.638927053349768],
              [126.839576714820126, 37.639068605070584],
              [126.839586463751985, 37.639072801254962],
              [126.839626946258008, 37.639079631906043],
              [126.839646769891374, 37.639082977739754],
              [126.840009892752249, 37.639144252304824],
              [126.840513792052477, 37.639153005408033],
              [126.841266960288252, 37.639204624936589],
              [126.841553257654041, 37.639280882121277],
              [126.841902746228754, 37.639576447627469],
              [126.842211343752169, 37.639959832333922],
              [126.842242786435946, 37.63993582766139],
              [126.842350220852936, 37.639911594952657],
              [126.842637836811761, 37.639863723887352],
              [126.84276516083797, 37.639842437206532],
              [126.842841675773258, 37.639829644531815],
              [126.842887285899181, 37.639795774226734],
              [126.842919856402432, 37.639770574180794],
              [126.843156644713716, 37.639587363445827],
              [126.843181842175881, 37.639567034524958],
              [126.843439152900615, 37.639456919969128],
              [126.843457975468596, 37.639448474700593],
              [126.843592851336638, 37.639436258672418]
            ]
          ]
        ]
      },
      id: '31101630'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '행신1동',
        ADM_CD: '31101650'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.832781390109844, 37.624969298171692],
              [126.832932061983854, 37.624872668347045],
              [126.832958607616561, 37.624855677791551],
              [126.83327193561172, 37.624654731047166],
              [126.833903796280964, 37.624249492520619],
              [126.834116603236069, 37.624113011600166],
              [126.83428506616788, 37.6240049695094],
              [126.834449066116818, 37.624167002371664],
              [126.835363882453862, 37.623602804558935],
              [126.835421411311458, 37.623567324976456],
              [126.836108532115631, 37.623143557785859],
              [126.836742479425638, 37.622752565648611],
              [126.836752995221232, 37.622746081085403],
              [126.836763121306475, 37.622739839493114],
              [126.8367644243136, 37.622738964412804],
              [126.837525141108983, 37.622232594291837],
              [126.837581583945308, 37.622197567221974],
              [126.837853303018477, 37.622028944182617],
              [126.837976991098401, 37.621952186705613],
              [126.838014751171244, 37.62192875389902],
              [126.838018705037598, 37.621926301022022],
              [126.838065818713247, 37.621897071026098],
              [126.838179690017668, 37.621826410758544],
              [126.838395253751102, 37.621692638763875],
              [126.838491946240325, 37.621632645580924],
              [126.838515010777499, 37.621618322915985],
              [126.83905542877406, 37.621282957633326],
              [126.83907921541217, 37.621268195445495],
              [126.839204791365844, 37.621190253871632],
              [126.839724744997582, 37.620867586019926],
              [126.839729018392433, 37.620864482307773],
              [126.83973705544804, 37.620858637971885],
              [126.839769791031372, 37.620834831269427],
              [126.840216681051857, 37.620509829832727],
              [126.840291014277312, 37.620455769788443],
              [126.840303824161509, 37.620446454024595],
              [126.840387309612652, 37.620385737366981],
              [126.840456996259135, 37.620335059180881],
              [126.840545272588457, 37.620270859397465],
              [126.840844839273956, 37.620070075809352],
              [126.840987499419938, 37.619977987581933],
              [126.841046746802036, 37.619939756732734],
              [126.841434420143074, 37.619689555007639],
              [126.841458751219264, 37.619673850773957],
              [126.841467071964956, 37.619669343676847],
              [126.841580162513708, 37.61960808473372],
              [126.842266198914487, 37.619236521749393],
              [126.842278447952935, 37.619232035589569],
              [126.842341972343732, 37.619208777431723],
              [126.842529736162078, 37.619140041658071],
              [126.842588355086264, 37.619118583036354],
              [126.842781246388441, 37.619047986232154],
              [126.842817753364116, 37.619034625396473],
              [126.842958239386419, 37.618983198009566],
              [126.843156558263004, 37.618910602809436],
              [126.843329341110802, 37.618847352600731],
              [126.843540196880809, 37.61877016752743],
              [126.843818563510055, 37.618668266198696],
              [126.844089885877537, 37.618568945228567],
              [126.844103956954015, 37.61856379210451],
              [126.844109058903072, 37.618561915044793],
              [126.84413938704094, 37.618550812444454],
              [126.844217100633898, 37.61852236474305],
              [126.844230867009671, 37.618517326182378],
              [126.844495629070323, 37.618420408624822],
              [126.844457180646259, 37.618426786482836],
              [126.844439231566923, 37.618428126195255],
              [126.844206901981053, 37.618445539700375],
              [126.844133368202264, 37.618436233043894],
              [126.844129668301264, 37.618435746489645],
              [126.844113563063928, 37.618433702880935],
              [126.843762712664684, 37.618389185830289],
              [126.843762040328713, 37.618389101872523],
              [126.843254595370638, 37.618281852021312],
              [126.842987368035068, 37.618225367768197],
              [126.842590495773749, 37.618112609719127],
              [126.842577449844583, 37.618108918182649],
              [126.842533143656638, 37.61809632937031],
              [126.842343542421901, 37.618042456475514],
              [126.841948599319011, 37.617930237908524],
              [126.841927879299817, 37.617930997551866],
              [126.841860237974515, 37.617933449589984],
              [126.841856134793844, 37.617933597957936],
              [126.841848925881379, 37.617933859696699],
              [126.841845634296178, 37.61793397563634],
              [126.841844528049904, 37.61793401273016],
              [126.84143809250682, 37.617948786608068],
              [126.841038516798946, 37.617963290940338],
              [126.841031364558262, 37.617963550241647],
              [126.840428921036434, 37.617985420797524],
              [126.84020170835953, 37.617993684855939],
              [126.840171158902464, 37.617994787618045],
              [126.840013207548751, 37.618000519168376],
              [126.839997820577324, 37.618001077559533],
              [126.839401744319559, 37.61802272250295],
              [126.838896124129548, 37.618041064925983],
              [126.838885855874892, 37.618041444543344],
              [126.838877074744602, 37.618041770299833],
              [126.838874315792893, 37.618041877423401],
              [126.838868115692009, 37.618042098664127],
              [126.838856805844344, 37.618042507595099],
              [126.837967858174437, 37.618074760321321],
              [126.837914555907034, 37.618092705142836],
              [126.837864901831495, 37.618109419360991],
              [126.837602404195607, 37.61819778738505],
              [126.837390751214301, 37.61826905182587],
              [126.836963975695738, 37.618412713772102],
              [126.836887131100923, 37.618438581954756],
              [126.83673089370177, 37.618491172730579],
              [126.836121050913846, 37.618696448700092],
              [126.83579906332848, 37.618804823760946],
              [126.835791764517438, 37.618807282072773],
              [126.835786893336774, 37.61880892302031],
              [126.835776848591877, 37.618812314076607],
              [126.835750010576049, 37.618821345780262],
              [126.834870159712011, 37.619117485205557],
              [126.834156222382745, 37.61912928435283],
              [126.834143284661778, 37.619129496168156],
              [126.834138066964982, 37.619129581194265],
              [126.834120734368327, 37.619129859241511],
              [126.834094317187734, 37.619130294227034],
              [126.834090718943997, 37.619130355942175],
              [126.834087304274433, 37.619130416890492],
              [126.834073181075667, 37.619130653554656],
              [126.833626013195698, 37.619138028445889],
              [126.83362597436367, 37.61913653922489],
              [126.833573426972578, 37.619136995074804],
              [126.833478620710324, 37.619137815198954],
              [126.832855920720974, 37.619140717294712],
              [126.83265550894879, 37.619141650785657],
              [126.832635176306937, 37.619141746703875],
              [126.832605387465108, 37.619141866649692],
              [126.832362377428026, 37.619142848930196],
              [126.832166029187817, 37.61914364143994],
              [126.832124819089017, 37.619143808388444],
              [126.832123074162411, 37.619143797610754],
              [126.831809470945061, 37.619141129866513],
              [126.831422112095879, 37.619137842416372],
              [126.831243698012472, 37.619144952396951],
              [126.831116837163407, 37.61915000547932],
              [126.831080661468192, 37.61915144804523],
              [126.831051195184543, 37.619152622183691],
              [126.830908562294027, 37.619158302186619],
              [126.83085900182077, 37.619160274483058],
              [126.830837357582922, 37.619164478138345],
              [126.830747684734092, 37.619182953442014],
              [126.830565038569361, 37.619202537171468],
              [126.830377024177139, 37.619223059086508],
              [126.830241575356041, 37.619201182049849],
              [126.830097116551784, 37.619177848529148],
              [126.829937355689978, 37.619180687362025],
              [126.829886229171038, 37.619169910922345],
              [126.82987003788152, 37.619166498461674],
              [126.829801725836546, 37.619171174174468],
              [126.829767532253626, 37.619153523851843],
              [126.829767151030339, 37.619153326097326],
              [126.829741596060927, 37.619140137758514],
              [126.829591525506643, 37.619056443752413],
              [126.829510087709849, 37.619010736844928],
              [126.829448727584278, 37.618957624160764],
              [126.829343305117021, 37.618866372604025],
              [126.829070626816403, 37.618630352541622],
              [126.828894911596137, 37.61847826022418],
              [126.828845526501553, 37.618431397571271],
              [126.828785170118891, 37.618374126994205],
              [126.828749887423314, 37.618339801837088],
              [126.828731861805835, 37.618322278338105],
              [126.828690917739337, 37.618282448065251],
              [126.828600514591699, 37.618202887747614],
              [126.828578219063303, 37.618183265963381],
              [126.828328327439081, 37.617986484459585],
              [126.828214503562904, 37.617896852434619],
              [126.828253245845104, 37.617864734076946],
              [126.828260872971441, 37.617858420396828],
              [126.828283920103132, 37.617839317155699],
              [126.828288835926259, 37.617835244075259],
              [126.828321518200781, 37.617808171109075],
              [126.828332617070373, 37.617798971289375],
              [126.82856880243078, 37.617603201187976],
              [126.828618277238348, 37.617549656904856],
              [126.828452983123199, 37.617532123686168],
              [126.828261590426251, 37.617471118208528],
              [126.828255095730441, 37.617470080756739],
              [126.828131780082217, 37.617450403831455],
              [126.828036501488512, 37.617435197514858],
              [126.828036170331501, 37.617480429012339],
              [126.827965649173166, 37.617608083346326],
              [126.827914861031161, 37.617699971579199],
              [126.827906385564077, 37.617715307252062],
              [126.827887232332714, 37.617750205019469],
              [126.827863832096156, 37.617798580555807],
              [126.827856762723115, 37.617835825716554],
              [126.827841124152016, 37.617918196857353],
              [126.82768686385657, 37.617866162913153],
              [126.827649624032986, 37.617855603431614],
              [126.827470197719123, 37.617812236335425],
              [126.827417494723207, 37.617803400327404],
              [126.827361218771927, 37.617793965273364],
              [126.827369051721007, 37.6178022940719],
              [126.827330842650412, 37.617827887726811],
              [126.827309355854496, 37.617841803493306],
              [126.827246101610911, 37.617884368844877],
              [126.82723518723104, 37.617893449736286],
              [126.827060027602485, 37.617994744991101],
              [126.827011208037831, 37.618023711205694],
              [126.827006206860162, 37.61802643354941],
              [126.827059009786154, 37.618066687160201],
              [126.827008633642322, 37.618200327966157],
              [126.826961127626646, 37.618269722810204],
              [126.82695491702367, 37.618279022487648],
              [126.826946579205298, 37.618291503452625],
              [126.826933277503045, 37.61829841921729],
              [126.826871073478415, 37.618330757524227],
              [126.826829998348686, 37.618351696406144],
              [126.826895457548957, 37.618359235417593],
              [126.826912105052941, 37.618361152873355],
              [126.827085656115941, 37.618380492939785],
              [126.827231201955271, 37.618395962297555],
              [126.827223151589294, 37.618440175431978],
              [126.827220958030935, 37.618452229064104],
              [126.827289145065166, 37.618461120086558],
              [126.827329011292719, 37.618466318205336],
              [126.827326652487471, 37.61847910728612],
              [126.827323700637336, 37.618495087308588],
              [126.827316453316442, 37.618508907586822],
              [126.827334359107155, 37.618535297025453],
              [126.827316778408573, 37.61856463432423],
              [126.827438152695123, 37.618591067342201],
              [126.827430881931718, 37.618611390607889],
              [126.827426966990757, 37.618622333905073],
              [126.827605148453728, 37.618666744027536],
              [126.827624559965443, 37.618671529850637],
              [126.827876511417571, 37.618605310315615],
              [126.827870733031617, 37.61861544533425],
              [126.827843260861144, 37.618664612468955],
              [126.827816544150153, 37.618712428296057],
              [126.827776700058564, 37.618793478075858],
              [126.827727947321719, 37.618892638936025],
              [126.827759526699467, 37.619048914313538],
              [126.827770320801847, 37.619103165718499],
              [126.8277766333605, 37.619134892530269],
              [126.827765882427784, 37.619158653500065],
              [126.827751533214609, 37.61919279804966],
              [126.827704792930092, 37.619298468063135],
              [126.82770295840858, 37.619302618161477],
              [126.827700663120268, 37.619308105915906],
              [126.827687455471249, 37.619339671939287],
              [126.827684162478434, 37.619347233382676],
              [126.827681059604245, 37.619353312316044],
              [126.827656238834209, 37.619401946496318],
              [126.827650381409853, 37.619413424037418],
              [126.827666468613344, 37.619409892344336],
              [126.827934116037625, 37.619351144950087],
              [126.828090831066419, 37.619316746654746],
              [126.828299147648409, 37.61927102192336],
              [126.828312706308083, 37.619268045390314],
              [126.828345866070549, 37.619260768405553],
              [126.828359726748928, 37.619257724183569],
              [126.828382231452636, 37.619322897836341],
              [126.828389817305364, 37.619392609505773],
              [126.828480266747619, 37.619647685801603],
              [126.828534122455309, 37.619826102774837],
              [126.828653181519812, 37.620220537183378],
              [126.828704724964709, 37.620391301216429],
              [126.828690622501782, 37.620394622613716],
              [126.828209664078301, 37.62050789584363],
              [126.82795342162305, 37.620568242860678],
              [126.827957117655657, 37.620600880421691],
              [126.828004054029321, 37.620987605645659],
              [126.828076950492374, 37.621600200165297],
              [126.828079223866183, 37.621619310535671],
              [126.828277510963161, 37.621856757063405],
              [126.828292704635231, 37.621899691982328],
              [126.828331648104694, 37.622001237813471],
              [126.828361385282292, 37.622078779229966],
              [126.828446684265003, 37.622301205123634],
              [126.828442727836276, 37.622313857136994],
              [126.828175990448202, 37.623166727499282],
              [126.828146706926518, 37.62326035335834],
              [126.827641318061794, 37.623747264623567],
              [126.827353894109152, 37.624023035631211],
              [126.827383256349052, 37.624241763330929],
              [126.827497040391194, 37.624364690160156],
              [126.827525023574637, 37.624398563057191],
              [126.827754164435461, 37.624675907901924],
              [126.827782200549422, 37.624709843230576],
              [126.827865143042217, 37.624751106399181],
              [126.828065520827764, 37.624850793857661],
              [126.828133103045062, 37.624896190300447],
              [126.828182172804617, 37.624929151084295],
              [126.828221895930568, 37.624933137606433],
              [126.828348482383717, 37.624945838746747],
              [126.828915665928236, 37.62501311641163],
              [126.828932640499261, 37.625052815663139],
              [126.828987852951443, 37.625181940769835],
              [126.829019782165332, 37.62535520865746],
              [126.829153489651233, 37.625234871425356],
              [126.829248195916193, 37.625159155384431],
              [126.829446388008279, 37.625006215463138],
              [126.829449320944533, 37.625005062202568],
              [126.829608805027732, 37.624942321809321],
              [126.829706811239078, 37.624932259116029],
              [126.82977717539282, 37.624925033555172],
              [126.829797253686237, 37.624923024065239],
              [126.829830494829366, 37.624919695875825],
              [126.82991927274422, 37.624944522516614],
              [126.830163306146773, 37.62500423240823],
              [126.830140012130698, 37.625280978440976],
              [126.830299010328957, 37.625409311262096],
              [126.830306015876786, 37.625452388708538],
              [126.830319027766848, 37.625493293784594],
              [126.830330881751181, 37.62549994168544],
              [126.83034426640441, 37.625656307651234],
              [126.830348496613794, 37.625697501783137],
              [126.830352013034215, 37.625731732682361],
              [126.830394416317546, 37.626050947362977],
              [126.830462661938611, 37.626097581962377],
              [126.83053362767356, 37.626168825688275],
              [126.830542182450927, 37.62622682443633],
              [126.830564359032508, 37.626402304306474],
              [126.830586143620849, 37.626412002746711],
              [126.830594660450245, 37.626421422209795],
              [126.830639454144844, 37.6263996838439],
              [126.830738356289743, 37.626347658488939],
              [126.8308236473204, 37.626299004350898],
              [126.830834766900878, 37.626292658947044],
              [126.83092858159425, 37.626234951426433],
              [126.83127106921458, 37.626017140266057],
              [126.831278555877333, 37.625963452518079],
              [126.831260020955568, 37.625944954896241],
              [126.831301434949381, 37.625918400233374],
              [126.831403300605245, 37.625853082946257],
              [126.831667922950075, 37.625683381392939],
              [126.832104278807122, 37.625403541599425],
              [126.832420265890605, 37.625200895168113],
              [126.832781390109844, 37.624969298171692]
            ]
          ]
        ]
      },
      id: '31101650'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '행신4동',
        ADM_CD: '31101515'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.842266198914487, 37.619236521749393],
              [126.842306018717196, 37.619396112016574],
              [126.842354553671029, 37.619529972713117],
              [126.842692719326422, 37.620461681120219],
              [126.842692928837238, 37.62046915974561],
              [126.842683040375846, 37.620485183540048],
              [126.842667041797441, 37.620498135803722],
              [126.842656613958411, 37.620501546041631],
              [126.842327299296414, 37.620571648208518],
              [126.842293977192966, 37.620580974245158],
              [126.842223461642234, 37.620609710453984],
              [126.842204974596015, 37.620620856932447],
              [126.842166843106838, 37.620654772425411],
              [126.842160710597838, 37.620662602366245],
              [126.84215365041355, 37.620680162431604],
              [126.842149287142945, 37.620707366471656],
              [126.842150381168437, 37.620725566877056],
              [126.84245337151161, 37.621794267034083],
              [126.842518749611912, 37.621890670151025],
              [126.842570141670635, 37.621960114860272],
              [126.842596342605816, 37.622069144537406],
              [126.842666477342306, 37.622361000124641],
              [126.842722437125033, 37.622635276424298],
              [126.842770734452017, 37.622871994741118],
              [126.84279509312141, 37.623029969076661],
              [126.842790710543511, 37.623097217426348],
              [126.842835576612387, 37.623210154616046],
              [126.842752222190413, 37.623683023318435],
              [126.842644480217061, 37.62434181449774],
              [126.84333044018237, 37.624603963152794],
              [126.843671111866399, 37.625128387113641],
              [126.843712350382219, 37.625191870193134],
              [126.843753451457758, 37.625255139778957],
              [126.84382860916881, 37.625658248686641],
              [126.843858179530486, 37.62581722155744],
              [126.843494210458985, 37.62586300996157],
              [126.843520486634844, 37.626035166392448],
              [126.843552902738153, 37.626195606305608],
              [126.843582704995455, 37.626343113772613],
              [126.843806918498046, 37.627453419362524],
              [126.843807927717947, 37.627458375991715],
              [126.843728728466516, 37.6274660177477],
              [126.843445114744554, 37.627552313473196],
              [126.843408601214747, 37.627569562777786],
              [126.843384687638135, 37.627628634589293],
              [126.843139760409926, 37.627848055259179],
              [126.84311543327928, 37.627888025487671],
              [126.843102527036592, 37.627895821359616],
              [126.843042954122609, 37.627931805238418],
              [126.842840234285688, 37.62790504515899],
              [126.842592671915455, 37.627924532795696],
              [126.842545559868725, 37.627966464336581],
              [126.842250896458708, 37.628228728402433],
              [126.842162366689152, 37.628307525000615],
              [126.841996125891299, 37.628455484504563],
              [126.84186866806516, 37.628568926384432],
              [126.841722847565549, 37.6287447901531],
              [126.841305703865828, 37.629247871185257],
              [126.841262505558788, 37.629299968534532],
              [126.841232789562966, 37.62933130596425],
              [126.841234788232896, 37.629332512289167],
              [126.841390578631717, 37.629426551758435],
              [126.841418856284619, 37.629437614811941],
              [126.841544966342639, 37.629486944599257],
              [126.84159139262465, 37.629494272825283],
              [126.841593308621171, 37.629494574647239],
              [126.841612916687694, 37.629497670176029],
              [126.841634090869746, 37.629501033122573],
              [126.841655546528841, 37.629492015143001],
              [126.841694958887985, 37.629475446846968],
              [126.841707229429574, 37.629470388535182],
              [126.84185768910406, 37.629408354619315],
              [126.842150251522611, 37.629378837969789],
              [126.842221976981136, 37.629371545894969],
              [126.842265374249678, 37.629367270351452],
              [126.842282596552579, 37.629365500601246],
              [126.84238230524646, 37.629355364785219],
              [126.842423780964737, 37.629349743601203],
              [126.842612228524985, 37.629324140440112],
              [126.842670086827837, 37.629311719766086],
              [126.842867007364447, 37.62926943288317],
              [126.843350497495535, 37.62916565758902],
              [126.843370331858935, 37.629161450594609],
              [126.843401324048173, 37.629152790919839],
              [126.843440608465031, 37.629141813187303],
              [126.843642367732443, 37.629200370388553],
              [126.84377492977751, 37.629238846504386],
              [126.843876423337633, 37.62924285583734],
              [126.84412015747823, 37.629241496165278],
              [126.844409776443783, 37.629238161059178],
              [126.844447735996866, 37.629332071713534],
              [126.844575092942577, 37.62964715563507],
              [126.845016048466292, 37.629569085671406],
              [126.845030547871602, 37.629628478293306],
              [126.845054378183505, 37.629631123489929],
              [126.845180734116852, 37.62964886900324],
              [126.84573297489132, 37.629615388099509],
              [126.845862147447846, 37.629619011114102],
              [126.846200871056823, 37.629628745463421],
              [126.84642502317071, 37.629891639431293],
              [126.84674922399185, 37.629787930368643],
              [126.84675962039401, 37.62978202471821],
              [126.84715565451792, 37.629644964553307],
              [126.847313719047165, 37.629668838327817],
              [126.847374067988525, 37.629612724445693],
              [126.847435043234071, 37.629378431509465],
              [126.847433288819332, 37.629323226947967],
              [126.847427193932319, 37.629131671033264],
              [126.847502682588583, 37.629088568615856],
              [126.847509783149533, 37.629080081283448],
              [126.847585513207065, 37.629130048974496],
              [126.8477737475597, 37.629199127604174],
              [126.847849343857746, 37.629259638166872],
              [126.847874867137435, 37.629276178070256],
              [126.847943719745771, 37.629220945523336],
              [126.848060108336213, 37.629085095486403],
              [126.848090459858085, 37.628912417468989],
              [126.848105099818568, 37.628849593498941],
              [126.848252070613327, 37.62882361243846],
              [126.848322136791538, 37.628641904252859],
              [126.848412134865981, 37.62839370937381],
              [126.848447047281638, 37.628273927664871],
              [126.848508313608463, 37.628062635002678],
              [126.848549244471215, 37.627917276937801],
              [126.848567734525602, 37.627851613326783],
              [126.848571628168074, 37.627493667154823],
              [126.848512541979133, 37.627215991991825],
              [126.848433739790437, 37.62686342617895],
              [126.848385090841219, 37.626724882333079],
              [126.848301388178356, 37.626491628447653],
              [126.848253504594169, 37.626365290103905],
              [126.848252074513553, 37.626361518240742],
              [126.84828888115031, 37.626144112874158],
              [126.848334416456737, 37.625870292628591],
              [126.848335000139059, 37.62581719655693],
              [126.848336812947622, 37.625651871555462],
              [126.848319167636518, 37.625616539211912],
              [126.848285969023365, 37.625560668825017],
              [126.848264621021627, 37.625524741872717],
              [126.848215974118347, 37.625439823618848],
              [126.848176286131746, 37.625370533466807],
              [126.848148528140882, 37.625334800921699],
              [126.848103499545303, 37.625290404886002],
              [126.848049425540395, 37.625232781373029],
              [126.847977799305866, 37.625156453735052],
              [126.84798697231335, 37.625019635279415],
              [126.84798990173266, 37.624974612781912],
              [126.848002122657618, 37.624786722732367],
              [126.8479768122303, 37.62462144251942],
              [126.84795156182517, 37.624452546482132],
              [126.847945082807868, 37.624405894656505],
              [126.847939042009259, 37.624362395409413],
              [126.847922997547442, 37.624246863157836],
              [126.847919979013113, 37.624127479388314],
              [126.847915017987077, 37.624006559826391],
              [126.847999823708832, 37.623750071136492],
              [126.848062850916207, 37.623562752901236],
              [126.848064172687756, 37.623481474411065],
              [126.84806921142409, 37.623294825416927],
              [126.848074438783257, 37.623066721557009],
              [126.848078256857477, 37.622895011706454],
              [126.848080985736075, 37.622782233088159],
              [126.84808303264974, 37.622697586707076],
              [126.848086042852515, 37.622616563597091],
              [126.848157416898474, 37.622364888444714],
              [126.848229734587392, 37.622106571118408],
              [126.848298001912738, 37.621756079075645],
              [126.848298019386405, 37.621752649599976],
              [126.848299585516031, 37.621435782896185],
              [126.848348206324218, 37.621441885750698],
              [126.848380842073439, 37.621445980471428],
              [126.848532339327392, 37.62146499328086],
              [126.848543810273384, 37.621466434750687],
              [126.848564255168768, 37.621469000152246],
              [126.848579001925515, 37.621470851783648],
              [126.848587661497604, 37.621458655881455],
              [126.848717888952095, 37.621532517925743],
              [126.848781494082417, 37.621568590610998],
              [126.849068380882287, 37.621590686122978],
              [126.84939451693117, 37.621615314683773],
              [126.849640253843319, 37.621631200660936],
              [126.849933680803716, 37.621649638257253],
              [126.850027269169559, 37.621655520356235],
              [126.850126717325494, 37.621632747924806],
              [126.85032384219808, 37.621587606768081],
              [126.850450598369889, 37.621558199178885],
              [126.850774923711683, 37.621662729761432],
              [126.850983251101908, 37.621731343304546],
              [126.851070709893449, 37.621758393822937],
              [126.851154760995797, 37.621819768134124],
              [126.851358148126522, 37.62196889607916],
              [126.851567561135525, 37.622118420024911],
              [126.851756916799658, 37.622092136542605],
              [126.851808327846484, 37.62203811725594],
              [126.851810097446929, 37.622036338731981],
              [126.851835598689732, 37.622040358153924],
              [126.851913523623452, 37.622062967987901],
              [126.851962844459607, 37.622077278035398],
              [126.852086050567863, 37.622149320380366],
              [126.852221902141281, 37.622248255693336],
              [126.852268615585757, 37.622393179706705],
              [126.85234328351423, 37.62255040710987],
              [126.852420527529731, 37.622613837853407],
              [126.852505926131087, 37.622618995240856],
              [126.852547120797794, 37.622621481831324],
              [126.852571148770195, 37.622637172485085],
              [126.852573208567051, 37.622638517759945],
              [126.85259729076887, 37.622654228535239],
              [126.852659324332691, 37.622606845092946],
              [126.852715094150597, 37.622587362273713],
              [126.852964853769919, 37.622590379750179],
              [126.853122935127701, 37.622614995721527],
              [126.853171438338904, 37.62260433385471],
              [126.853278077276599, 37.622571313077671],
              [126.853419024174514, 37.622501033419617],
              [126.853473461693042, 37.622473892424026],
              [126.853749503018193, 37.622403724278641],
              [126.853976421147905, 37.622346041312191],
              [126.854009062437214, 37.622337792552827],
              [126.854062226878199, 37.622318399384788],
              [126.854134881892577, 37.622290243759771],
              [126.854259224493688, 37.622242058520094],
              [126.854313045525629, 37.622221284383592],
              [126.854325647817362, 37.622231873705829],
              [126.854489478470541, 37.622153241838973],
              [126.854482132508807, 37.622144673236456],
              [126.854481813912216, 37.6221443001442],
              [126.853984437371381, 37.621562309229319],
              [126.853971477060043, 37.621547148149965],
              [126.853899081103862, 37.621462437435838],
              [126.853756487321988, 37.621295584092714],
              [126.853733213273287, 37.62126573425445],
              [126.853571642791323, 37.621076937440883],
              [126.853688036496123, 37.620973165470879],
              [126.853796045504978, 37.620876869993758],
              [126.853853935616385, 37.620761217847161],
              [126.853855193024813, 37.620757597766875],
              [126.853899326890783, 37.62066860381622],
              [126.853920973243902, 37.620370755913761],
              [126.853938608325706, 37.620269555203173],
              [126.853950829903468, 37.620199435276817],
              [126.853955916526772, 37.62017024369608],
              [126.853972911987086, 37.620167829236038],
              [126.854002525043413, 37.620165416251183],
              [126.854166746779896, 37.620152035367383],
              [126.854438054689524, 37.620076239655987],
              [126.854726218941835, 37.619845192453973],
              [126.85480517897247, 37.619781826024514],
              [126.854810869603355, 37.619779598397741],
              [126.854873536838554, 37.619754956063865],
              [126.854943310171379, 37.619727535875676],
              [126.855010694597254, 37.619701056198942],
              [126.854993185579815, 37.619647641198135],
              [126.854917496405307, 37.619416815906987],
              [126.854886927159967, 37.619173623079057],
              [126.854884606910616, 37.619155085281882],
              [126.854883238181529, 37.619144189687411],
              [126.854855617831987, 37.618904767018179],
              [126.8548426191782, 37.618862079085901],
              [126.854739473173311, 37.618523237310349],
              [126.85473168922033, 37.618219778734534],
              [126.854728380226021, 37.618115710657712],
              [126.85472072317927, 37.617863785620258],
              [126.854703587218978, 37.617776455406833],
              [126.854685285666065, 37.617683186235475],
              [126.854651753669273, 37.617513398842831],
              [126.854652106710816, 37.617497461583753],
              [126.854655113046107, 37.617361884045735],
              [126.854660450512213, 37.6171211245602],
              [126.854918671392554, 37.616845655660491],
              [126.855000803826499, 37.616724215415736],
              [126.854735345741148, 37.61618699128055],
              [126.854599192921441, 37.615883494840553],
              [126.854554210552735, 37.615783226846773],
              [126.854564052395801, 37.615760168666718],
              [126.854627402462526, 37.61561156696704],
              [126.854541324300442, 37.615524835770366],
              [126.854537930836145, 37.615521415573028],
              [126.854517226350808, 37.615196450627643],
              [126.854494504002176, 37.614860609939093],
              [126.854491172575493, 37.614787626895911],
              [126.854487927460227, 37.614716587600824],
              [126.854487819629554, 37.614714119157419],
              [126.85452019321707, 37.614701185435422],
              [126.854569440890401, 37.614681510082505],
              [126.854583316653233, 37.614675976071048],
              [126.854576014619255, 37.614661621139803],
              [126.854516219158683, 37.61454393853051],
              [126.854437885174335, 37.614389769404312],
              [126.85443766261298, 37.614382092422744],
              [126.854450256298804, 37.614261807933204],
              [126.854462582306638, 37.614145482439035],
              [126.854464358003298, 37.614105090736651],
              [126.85446474035075, 37.614102017475048],
              [126.854472047857527, 37.614043248980792],
              [126.854475649879362, 37.614013098315951],
              [126.854453949688562, 37.613979535590296],
              [126.854390572094545, 37.613877202866441],
              [126.854296664819955, 37.613725574918398],
              [126.854252217198308, 37.613653807199647],
              [126.854232380095127, 37.613621930208105],
              [126.85418297680144, 37.613535077648145],
              [126.854154305720982, 37.613495694654119],
              [126.854115947771561, 37.613443005410325],
              [126.854059259743934, 37.613363037878202],
              [126.854050134481469, 37.613350168246114],
              [126.854048777645843, 37.61335075930505],
              [126.853589891074108, 37.612699676771634],
              [126.85358165814975, 37.612687798951939],
              [126.853555286314204, 37.612610887815748],
              [126.853550791227804, 37.612602419488205],
              [126.853489365226935, 37.612449523068214],
              [126.85348137504306, 37.612429635508441],
              [126.853381807618817, 37.612181802484571],
              [126.853401555863954, 37.612162484226708],
              [126.853397350844617, 37.612150224665726],
              [126.853551575369025, 37.612002809069992],
              [126.853637910312798, 37.611918682707127],
              [126.853687606807242, 37.611860707042858],
              [126.853734870515709, 37.61161263734008],
              [126.853983060128101, 37.611427613764555],
              [126.854715303506126, 37.610881721945887],
              [126.854673760500475, 37.610813555749957],
              [126.854604695968163, 37.610700129912281],
              [126.854595405743055, 37.610684873581548],
              [126.854563352522149, 37.610632231562725],
              [126.854342239679923, 37.610737504807886],
              [126.854230262800158, 37.610577216095692],
              [126.854004986515321, 37.610697215370585],
              [126.853736632185985, 37.610837162797999],
              [126.853477833674305, 37.610974243438932],
              [126.853038226878169, 37.611207093384827],
              [126.852693404934087, 37.611385103455866],
              [126.85254262483528, 37.611462942079569],
              [126.852204183490741, 37.611637087439242],
              [126.851906764310371, 37.611785724913595],
              [126.851467335275927, 37.611944542398014],
              [126.851402424406899, 37.611961494878045],
              [126.851396462514472, 37.611963051162633],
              [126.851107869937223, 37.612038425066039],
              [126.850977502114972, 37.612070199439849],
              [126.850641948333617, 37.612150373099148],
              [126.850615405866833, 37.612154302874899],
              [126.850490540754365, 37.612172788869835],
              [126.85036856398898, 37.612190847176244],
              [126.850188837320161, 37.612217303271059],
              [126.849850362190281, 37.612263773910499],
              [126.849359079818143, 37.612314893887422],
              [126.849152416520965, 37.612314752922224],
              [126.84906917176653, 37.612314619148911],
              [126.84884958853803, 37.612313569045568],
              [126.848441401426427, 37.612310165885766],
              [126.848093815519221, 37.612302353289515],
              [126.847886841865403, 37.612299093381459],
              [126.847660098229738, 37.612293084716043],
              [126.847285398705807, 37.612285190155333],
              [126.846941691372223, 37.612282046504554],
              [126.846387680577564, 37.612273832630713],
              [126.846071939661002, 37.612268903474892],
              [126.845965149732848, 37.612268356719504],
              [126.845771462629813, 37.612267373206343],
              [126.845672874303219, 37.612266871656054],
              [126.845546558133123, 37.612266542533028],
              [126.845526549305418, 37.612266491051869],
              [126.845538345238225, 37.61227696598295],
              [126.845828616113124, 37.612608046513323],
              [126.845668863373703, 37.612605926880477],
              [126.845674550861304, 37.612665190501161],
              [126.84554124040848, 37.612654578021903],
              [126.845543823432735, 37.612713824430642],
              [126.845517465270831, 37.612712444319406],
              [126.845367012228266, 37.612747682981094],
              [126.845285864156381, 37.612766688086268],
              [126.845135347642412, 37.612595563818736],
              [126.8451434297492, 37.612558589969559],
              [126.845132570159649, 37.612558529705296],
              [126.845088289951804, 37.612265142111383],
              [126.8449271196707, 37.612264544075622],
              [126.84480568348603, 37.612264599980108],
              [126.844646737284634, 37.612265945469275],
              [126.844302655130008, 37.612269516003963],
              [126.844291974474032, 37.612269341285938],
              [126.844290572356499, 37.612297019780016],
              [126.844280031389587, 37.612505073880776],
              [126.84428438347787, 37.612519384211609],
              [126.84431004665413, 37.61260377812637],
              [126.844311850804431, 37.612609672962172],
              [126.84398862025337, 37.61286181841492],
              [126.843966618112773, 37.612880226628739],
              [126.843812571980607, 37.613009112677283],
              [126.843492794345536, 37.613276659912522],
              [126.843382753756359, 37.613368726438566],
              [126.843382745068453, 37.613374224528059],
              [126.843382729356136, 37.613381810068191],
              [126.84332471195934, 37.613427015412718],
              [126.84328212581714, 37.613460197183812],
              [126.843274833948016, 37.613469812594737],
              [126.843263632976431, 37.613484591624584],
              [126.843197541234801, 37.613597052778196],
              [126.843197544686831, 37.613597562051133],
              [126.843197982510489, 37.613649939205615],
              [126.8431980423657, 37.613657304339036],
              [126.843200153532152, 37.613913753725086],
              [126.84324244044565, 37.613919008611042],
              [126.84348399013858, 37.613948936722892],
              [126.843724265542903, 37.613996375581451],
              [126.843759220710851, 37.614008702834923],
              [126.843850945014253, 37.614041053417857],
              [126.843952895428018, 37.614100077175131],
              [126.843997910099333, 37.614130186819203],
              [126.844051001337064, 37.6141657024761],
              [126.844061627014625, 37.614172811774964],
              [126.844105791297039, 37.614202354218449],
              [126.844173668883428, 37.614255949842089],
              [126.84427181145783, 37.614333444830095],
              [126.844672385232116, 37.61459013197458],
              [126.844747425003618, 37.614637735315483],
              [126.844826376501132, 37.61468801745967],
              [126.844851201726925, 37.614703798842406],
              [126.845080588817936, 37.614849617284875],
              [126.845109759104716, 37.614868152868709],
              [126.8453291555861, 37.615008179292744],
              [126.845511976744106, 37.615097855840986],
              [126.845572584782602, 37.615127584478508],
              [126.845695919043052, 37.615195150988967],
              [126.845772902181807, 37.615237320396503],
              [126.845819269374203, 37.615262719262375],
              [126.845878432999143, 37.615309909389019],
              [126.845947964706511, 37.615382670110421],
              [126.845977723943449, 37.615434140780081],
              [126.846010327218622, 37.615490530460406],
              [126.846015240611877, 37.615499026624207],
              [126.846036554787688, 37.615535914663255],
              [126.846036699499464, 37.615536210201419],
              [126.846099998184201, 37.61567253620818],
              [126.846149653715315, 37.615779482417246],
              [126.846198707605581, 37.615894335376261],
              [126.846278795194763, 37.616081852716299],
              [126.84630290038119, 37.616138292576636],
              [126.846311459915583, 37.616158331577104],
              [126.846490846755799, 37.616578324600958],
              [126.846546296475893, 37.616947246517093],
              [126.846552009985473, 37.617045208961386],
              [126.846555835476565, 37.617110835518609],
              [126.846526124867154, 37.617123730412146],
              [126.846525427603638, 37.617124035708237],
              [126.846459751672313, 37.617152538997424],
              [126.846449909584067, 37.61715680638207],
              [126.846440408907881, 37.617164658458066],
              [126.846431024400161, 37.617172442675326],
              [126.846425537840233, 37.61717698204847],
              [126.846324756295843, 37.617260330754128],
              [126.846286887077099, 37.617314657961387],
              [126.846246834001988, 37.617348573173274],
              [126.846135617960158, 37.617506368136929],
              [126.846046431368208, 37.617528324319075],
              [126.845934391546592, 37.617657196134942],
              [126.845870541007997, 37.61772908227988],
              [126.845855381778406, 37.617736378363276],
              [126.845830799993209, 37.617748672380124],
              [126.84579051472646, 37.617768818276204],
              [126.845758818977245, 37.617795367085137],
              [126.84560163827571, 37.617958511162584],
              [126.845570529905686, 37.617990811881867],
              [126.845386238268929, 37.618142872128246],
              [126.84531573093912, 37.618201049608651],
              [126.845288970290198, 37.618223122607006],
              [126.845190744477279, 37.618256101764175],
              [126.845095123404803, 37.618288201172767],
              [126.845094326747002, 37.618288468052619],
              [126.844995989462802, 37.618321476171928],
              [126.844995623493091, 37.618321602129825],
              [126.84490231726808, 37.618352921057266],
              [126.844700337831014, 37.618386437797923],
              [126.844495629070323, 37.618420408624822],
              [126.844230867009671, 37.618517326182378],
              [126.844217100633898, 37.61852236474305],
              [126.84413938704094, 37.618550812444454],
              [126.844109058903072, 37.618561915044793],
              [126.844103956954015, 37.61856379210451],
              [126.844089885877537, 37.618568945228567],
              [126.843818563510055, 37.618668266198696],
              [126.843540196880809, 37.61877016752743],
              [126.843329341110802, 37.618847352600731],
              [126.843156558263004, 37.618910602809436],
              [126.842958239386419, 37.618983198009566],
              [126.842817753364116, 37.619034625396473],
              [126.842781246388441, 37.619047986232154],
              [126.842588355086264, 37.619118583036354],
              [126.842529736162078, 37.619140041658071],
              [126.842341972343732, 37.619208777431723],
              [126.842278447952935, 37.619232035589569],
              [126.842266198914487, 37.619236521749393]
            ]
          ]
        ]
      },
      id: '31101515'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '중산2동',
        ADM_CD: '31103512'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.778157679295077, 37.68285069980292],
              [126.778200155017473, 37.682846446875978],
              [126.778309598701867, 37.682827024450745],
              [126.778418159627265, 37.682807757858583],
              [126.778635709784979, 37.682769246822524],
              [126.778649102624442, 37.68276687354745],
              [126.779036215311834, 37.682698286144522],
              [126.779282339202254, 37.682657576318384],
              [126.779864636386606, 37.682525147700481],
              [126.779865311612966, 37.68252686162883],
              [126.779869715570428, 37.682532815605285],
              [126.779904752307772, 37.682568469473232],
              [126.779948895478967, 37.682553596823382],
              [126.780234633902595, 37.682495931480155],
              [126.780424061369771, 37.682459526732082],
              [126.780466606648119, 37.682450972309439],
              [126.780815629382531, 37.682380794659942],
              [126.781208898900928, 37.682301703914945],
              [126.781262075856432, 37.682340892064516],
              [126.781308669784949, 37.682375228822323],
              [126.781306797817237, 37.682388938218381],
              [126.781260119296363, 37.682730660750302],
              [126.781239487452339, 37.682883606279212],
              [126.781213879607961, 37.683071050160073],
              [126.781167551958021, 37.683408638591658],
              [126.781129762051123, 37.683684002293177],
              [126.781127851771842, 37.683754905639262],
              [126.781236981762149, 37.683922599921246],
              [126.781315258591874, 37.684019420024732],
              [126.781383275228507, 37.68410354915369],
              [126.781393294605024, 37.684098433023699],
              [126.781432469601484, 37.684061718498313],
              [126.781498997231694, 37.684067790450463],
              [126.781532659737479, 37.684070645422757],
              [126.781565644871009, 37.684072238916841],
              [126.781597270899653, 37.684073289639258],
              [126.781628330143377, 37.684074429736569],
              [126.781662450930781, 37.684075393789314],
              [126.781695894797295, 37.684075637159346],
              [126.781730361122669, 37.684074169732],
              [126.781763584285855, 37.68407224947088],
              [126.781772112962884, 37.684071530785033],
              [126.781797036143402, 37.684069429272775],
              [126.781829810892901, 37.684065436830394],
              [126.781862699930329, 37.684061353140144],
              [126.78189570310262, 37.684056370617945],
              [126.781927122637796, 37.684050842158001],
              [126.781959108671188, 37.68404513597352],
              [126.781992005594986, 37.684038350136518],
              [126.782135630192357, 37.684004201899519],
              [126.782206648979383, 37.683986496016935],
              [126.782370697073176, 37.683946167966688],
              [126.782253445331648, 37.684788745985308],
              [126.782248510409644, 37.684824205437671],
              [126.782238434487809, 37.684896265059649],
              [126.782108435972162, 37.684888399293946],
              [126.782141769681587, 37.68492176178453],
              [126.782397913089298, 37.685215415198073],
              [126.782460620469251, 37.685271921075966],
              [126.782561524081828, 37.685362848182997],
              [126.782998315556597, 37.685717740659456],
              [126.7831817755944, 37.685824124760316],
              [126.783415476341162, 37.685820542754215],
              [126.783454443268965, 37.685819947612885],
              [126.783629947798531, 37.685817302245979],
              [126.784034971531668, 37.685794900835432],
              [126.784058849002577, 37.685796159831547],
              [126.784134326483112, 37.685800139494944],
              [126.784314838136069, 37.685809656175948],
              [126.784526344893337, 37.685823651821437],
              [126.784898279688974, 37.685819217303127],
              [126.785265430102058, 37.685814838239558],
              [126.7860711641937, 37.686074130101296],
              [126.786465470860634, 37.686201018665734],
              [126.78683075383168, 37.686318536025766],
              [126.78786201544284, 37.686975872061176],
              [126.788470052251299, 37.687561506828708],
              [126.78880886058235, 37.68759522122],
              [126.789176390910583, 37.687631792804929],
              [126.789494516487963, 37.687631100641823],
              [126.789525495795374, 37.68770070877968],
              [126.789778697072165, 37.688402180351851],
              [126.789835073670005, 37.688382220345147],
              [126.790036199897401, 37.688311013929479],
              [126.790336375919637, 37.688204698389072],
              [126.790474234478708, 37.688165750871917],
              [126.790694923027473, 37.688103347950481],
              [126.790820642591214, 37.688067804304033],
              [126.791307600569723, 37.687900102844139],
              [126.791735389014832, 37.687728330557775],
              [126.791862657076379, 37.687544127703923],
              [126.791937191709735, 37.687446143860463],
              [126.792026691834579, 37.687445358077959],
              [126.792193194785227, 37.687443895168137],
              [126.79223013208555, 37.687410986130018],
              [126.792317194055897, 37.687333297451318],
              [126.792351888702228, 37.687292183715229],
              [126.792421049312622, 37.687210138708295],
              [126.792451377580036, 37.687108112740646],
              [126.792486757334245, 37.68710565349879],
              [126.792494743859436, 37.687076310254909],
              [126.792505933846158, 37.687035204167898],
              [126.792513332614078, 37.687008035887771],
              [126.792547110456056, 37.686930162542382],
              [126.792624869268053, 37.686830012789194],
              [126.792634153658184, 37.68681805387822],
              [126.792739470241344, 37.686739135010107],
              [126.792766045108607, 37.686722966066661],
              [126.792810334939475, 37.686696105532903],
              [126.792877476587236, 37.686655177199178],
              [126.792908340931064, 37.686636363331324],
              [126.792934688338448, 37.686620372348216],
              [126.793100946196304, 37.686519308949507],
              [126.793208565922015, 37.68642741993699],
              [126.793668993223008, 37.685929815838797],
              [126.794050431379716, 37.685867329163578],
              [126.794395402289808, 37.685709365167561],
              [126.794397487973995, 37.685402178668149],
              [126.794398647440801, 37.685231424960904],
              [126.794409739073416, 37.685103947210074],
              [126.794411541357718, 37.685083220402447],
              [126.794415638140947, 37.685076245256326],
              [126.794464859705684, 37.684992411356539],
              [126.794649607835353, 37.684677751344502],
              [126.794618756553874, 37.683832122278226],
              [126.794614318015164, 37.68371041016988],
              [126.794510491011962, 37.68362793741467],
              [126.794200310364758, 37.683381550282718],
              [126.7937907706971, 37.683200005092239],
              [126.793649917918088, 37.682730892726312],
              [126.793663627394906, 37.682692805688497],
              [126.793796738177676, 37.682319859199886],
              [126.793799823208303, 37.682311214173772],
              [126.793795988030354, 37.682304721523444],
              [126.793651573674623, 37.682056610686359],
              [126.79370029466925, 37.681941480183895],
              [126.793820920231283, 37.681656427806907],
              [126.793805979786399, 37.681616600884439],
              [126.79377201731144, 37.68152607068118],
              [126.793720564889284, 37.681388923932566],
              [126.793708731641544, 37.68135737891987],
              [126.793690852563159, 37.681340793882555],
              [126.793501359989989, 37.681165016989056],
              [126.793537830538668, 37.681092918843973],
              [126.793617063242152, 37.680936284109862],
              [126.793737798214437, 37.680690622874636],
              [126.793731841850487, 37.680549862970906],
              [126.793731634284839, 37.680544962143919],
              [126.793722373939204, 37.680326145126472],
              [126.793720995625165, 37.680293536070373],
              [126.7937349192825, 37.680220843145534],
              [126.793751479646673, 37.680134388406074],
              [126.793796009079458, 37.679898050926795],
              [126.793871369336756, 37.679500586677428],
              [126.793804120522424, 37.679432139623529],
              [126.793761822839357, 37.679389078960185],
              [126.79369536724468, 37.679321435164994],
              [126.793653381885576, 37.679295681710265],
              [126.793644668902061, 37.679290312627927],
              [126.793634916406987, 37.679284302788268],
              [126.793600308046166, 37.679262973922633],
              [126.793519960288634, 37.679213549055369],
              [126.793334256391162, 37.679098527412577],
              [126.793293518080162, 37.679073320292531],
              [126.793280901009638, 37.679065522160698],
              [126.793197893960709, 37.679014226218108],
              [126.793143834246763, 37.67897968984397],
              [126.793065659152404, 37.678929747712296],
              [126.793060859513872, 37.678926681487319],
              [126.793032683710024, 37.678908702470167],
              [126.793008013866242, 37.678893882866262],
              [126.792993189294705, 37.678884846573808],
              [126.792987530434218, 37.678881502094391],
              [126.792862828193506, 37.678802537738946],
              [126.792844837891352, 37.67879106329837],
              [126.792813833503061, 37.678770737351428],
              [126.792805913548321, 37.678765226047041],
              [126.792774814192484, 37.678744632729149],
              [126.792710416855797, 37.678701989866695],
              [126.792663769627623, 37.678681907158534],
              [126.792647577412112, 37.678675029397475],
              [126.79262674490856, 37.678665983162489],
              [126.792530821128864, 37.678653840206614],
              [126.792350125902516, 37.678633597611366],
              [126.792205753191453, 37.678617484474501],
              [126.791658097654192, 37.678704447544689],
              [126.791537783871689, 37.678718108141588],
              [126.79150274278777, 37.678722099597216],
              [126.791300448897388, 37.678744172481103],
              [126.791192325702241, 37.678334481259562],
              [126.791188669548461, 37.678320897998255],
              [126.791157397434631, 37.678215670004192],
              [126.791151568589029, 37.678196117748364],
              [126.791036517550182, 37.677872284288064],
              [126.79073341230611, 37.677429808250167],
              [126.790792874546085, 37.677374279379059],
              [126.790809668880925, 37.677358598224572],
              [126.790824609526695, 37.677326550534019],
              [126.79096774046009, 37.67701957363915],
              [126.790633703758473, 37.676861838136887],
              [126.789870332359939, 37.676501351742715],
              [126.789813580458812, 37.676474550802233],
              [126.789783799578373, 37.676460487501515],
              [126.789056856213222, 37.676117188910197],
              [126.788851501350791, 37.676020207892165],
              [126.788850519211692, 37.676019743197052],
              [126.788109215899794, 37.675669657164406],
              [126.788028944758977, 37.675631749717191],
              [126.787503837328273, 37.67538375606437],
              [126.787515361052641, 37.675357648586626],
              [126.787925988147819, 37.674427600863588],
              [126.788160676624543, 37.673896091901007],
              [126.788170261060685, 37.673874468441475],
              [126.788180299982926, 37.673851800387652],
              [126.788194104745315, 37.673820470820836],
              [126.788190425066517, 37.673818925377958],
              [126.788097531656945, 37.673779932311923],
              [126.787978208982992, 37.673729803314849],
              [126.787128525468034, 37.67337282859873],
              [126.786771790166966, 37.673222889215673],
              [126.786670644681323, 37.673180412888811],
              [126.786631453051456, 37.673163952941955],
              [126.786495999758145, 37.673107063206665],
              [126.786468938100811, 37.673152412659398],
              [126.78645438188866, 37.673176892837432],
              [126.786334032520131, 37.673378499787567],
              [126.78628495882819, 37.673449370331198],
              [126.786251849703234, 37.67349718790841],
              [126.786186197816292, 37.673582930844454],
              [126.786172077468393, 37.673599654140169],
              [126.785958828734991, 37.673499806382239],
              [126.785919795400716, 37.673475228948767],
              [126.785820362919907, 37.673404770803472],
              [126.785657583311746, 37.673214629896378],
              [126.785556467459699, 37.673096515634789],
              [126.785543036560668, 37.673115953308837],
              [126.785517774375506, 37.67316231751461],
              [126.785295304660266, 37.673570662887272],
              [126.784921102702341, 37.674257499576505],
              [126.784772901637396, 37.674529514020698],
              [126.78471691929856, 37.674602208629665],
              [126.784691835769237, 37.674634816848794],
              [126.784555698319167, 37.674811793966938],
              [126.784517115097501, 37.674861952814972],
              [126.784356956320636, 37.675040522613315],
              [126.784213644485249, 37.675200308135345],
              [126.784161501143942, 37.675258444182482],
              [126.78412210081467, 37.675293156489815],
              [126.783927964678711, 37.675464189889276],
              [126.783794310807949, 37.675581936386479],
              [126.782979238881822, 37.676180025747286],
              [126.782717970953385, 37.676329011820712],
              [126.781816812578313, 37.676745656142749],
              [126.781109468176155, 37.677058871320547],
              [126.780398901661826, 37.677373506470722],
              [126.779425133651429, 37.677802340218086],
              [126.779364613260157, 37.677830007827019],
              [126.779084028636561, 37.677958286048508],
              [126.77892227248735, 37.678032130242713],
              [126.778664218561033, 37.678150074562076],
              [126.778566748595068, 37.67819462372988],
              [126.777562487592576, 37.678653656968422],
              [126.777484589038735, 37.678688455655802],
              [126.777469294990738, 37.678695285904837],
              [126.777507471257564, 37.678779228173902],
              [126.777571660058342, 37.678920363567187],
              [126.777624705436494, 37.678997587399138],
              [126.777605524565033, 37.679005747641476],
              [126.777615232671096, 37.679019822648463],
              [126.777667413841556, 37.679095544974004],
              [126.777724149951325, 37.679177880223328],
              [126.777875781151252, 37.679393761206896],
              [126.777877434454965, 37.679396114764714],
              [126.777914791581665, 37.679451863969405],
              [126.777950782660497, 37.679508154604065],
              [126.777985869928017, 37.67956479932112],
              [126.778019593663188, 37.679622076519159],
              [126.778032896364849, 37.679645797801363],
              [126.778039560471356, 37.679657688263596],
              [126.77805195225227, 37.679679801418565],
              [126.778082727103381, 37.679738061913191],
              [126.778091742059758, 37.679756394082055],
              [126.778111683713291, 37.679796949404825],
              [126.778138939769548, 37.679856372680419],
              [126.778164834890433, 37.679916158963586],
              [126.778190159079529, 37.679976118405122],
              [126.778213217650205, 37.680036527253399],
              [126.778233780567575, 37.680097834762272],
              [126.778235008241467, 37.680101983845091],
              [126.778251959208603, 37.680159313347403],
              [126.778269004601654, 37.680221062600708],
              [126.778284392101824, 37.680278239219213],
              [126.778285597281524, 37.680282720751123],
              [126.778331740123946, 37.680432999129415],
              [126.778343119265529, 37.680532037389334],
              [126.778349391949632, 37.680595115950908],
              [126.77835037087425, 37.680607150066812],
              [126.778354530838115, 37.680658284916291],
              [126.778357174852715, 37.680721447627548],
              [126.778358911880744, 37.680784700328125],
              [126.778358920332394, 37.680787808133751],
              [126.778359064400433, 37.680847858608047],
              [126.778358761051365, 37.680911014097788],
              [126.778357551564056, 37.680974171252622],
              [126.778354754418245, 37.681037324099528],
              [126.778349715825144, 37.681130381668765],
              [126.778366127016042, 37.681177085778927],
              [126.778365709272123, 37.681180405490728],
              [126.77836066573829, 37.681220591557398],
              [126.778359659934338, 37.68122877390109],
              [126.778356600778011, 37.681253649658558],
              [126.778323381719929, 37.681519819980956],
              [126.778298055264344, 37.681723753723674],
              [126.778270179354919, 37.681946871781527],
              [126.778267767365804, 37.681966280478285],
              [126.778261722282721, 37.681970420201871],
              [126.778249428567889, 37.682071806617593],
              [126.778254243975681, 37.682075075434462],
              [126.778243926812436, 37.682158099624147],
              [126.778242197756583, 37.682171983799229],
              [126.778231034208829, 37.682261595965855],
              [126.778226388630515, 37.682298617214236],
              [126.778198042559183, 37.682527138474441],
              [126.778186491384361, 37.682619757767526],
              [126.778171789886173, 37.682737632603136],
              [126.778157679295077, 37.68285069980292]
            ]
          ]
        ]
      },
      id: '31103512'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '정발산동',
        ADM_CD: '31103530'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.786859231399163, 37.671002245730747],
              [126.786962251659332, 37.670809625020375],
              [126.787262186752571, 37.670290941183623],
              [126.787301250268655, 37.670217148896562],
              [126.787406465509591, 37.670018389498985],
              [126.787763552204154, 37.669409710881581],
              [126.788129846140748, 37.668749240764505],
              [126.788305339761621, 37.66845890479955],
              [126.788421318168332, 37.668267154057041],
              [126.788464324708784, 37.668196039535921],
              [126.788485729973758, 37.668160649323916],
              [126.788528669348253, 37.668086936759003],
              [126.788316138736192, 37.668008421960685],
              [126.788142918373794, 37.667944427310601],
              [126.7879864849603, 37.667886634803651],
              [126.787834611985403, 37.667830527159282],
              [126.787380010537476, 37.667662543593593],
              [126.787056363079898, 37.667542948800651],
              [126.786638050825275, 37.667388369891789],
              [126.786280077176485, 37.667256087438382],
              [126.784889959072117, 37.66674237836191],
              [126.784871723356176, 37.666735639466964],
              [126.784019113654921, 37.666420550529132],
              [126.783415173930763, 37.666197356584121],
              [126.78323527000741, 37.666130892279725],
              [126.783233012802896, 37.666134754252461],
              [126.783184332295676, 37.66621799664933],
              [126.782618522675051, 37.667185460050689],
              [126.782001436086588, 37.668240570276396],
              [126.781992228153186, 37.668256313509865],
              [126.781610652654891, 37.668908726164524],
              [126.781610392102706, 37.668909171633572],
              [126.781604346221172, 37.668906937383767],
              [126.781430596708176, 37.668842705246703],
              [126.781156536602822, 37.668741408433618],
              [126.780159668826727, 37.668372944568581],
              [126.779559077706637, 37.668150946521791],
              [126.779398202979579, 37.668089108240721],
              [126.779239943853895, 37.668023218956478],
              [126.77908430852932, 37.667953370664534],
              [126.778931515254001, 37.66787974317144],
              [126.778781679690042, 37.667802337195376],
              [126.778716727586854, 37.667766445917408],
              [126.778634916625933, 37.667721243581902],
              [126.77849145286757, 37.667636460124477],
              [126.778351513421185, 37.667548170278963],
              [126.778214984041185, 37.667456463490893],
              [126.778082204889046, 37.667361340051364],
              [126.777953288995604, 37.667263071061726],
              [126.777828239519849, 37.667161564618858],
              [126.777707275543534, 37.667056912208857],
              [126.777590516892673, 37.666949384974856],
              [126.777478076958204, 37.666838981821165],
              [126.777403628982256, 37.666761008375389],
              [126.777382204706413, 37.666738569378914],
              [126.777369836391358, 37.666725615501718],
              [126.777303301437726, 37.666651068630777],
              [126.777215317860652, 37.666548550226182],
              [126.777130967493846, 37.666444148625821],
              [126.777050247221354, 37.666337950345273],
              [126.776973274928565, 37.666229957917132],
              [126.776881647111438, 37.666096925167857],
              [126.77649155884923, 37.665530558984543],
              [126.776170722394298, 37.665064731842627],
              [126.776135166889816, 37.665016732704437],
              [126.776095976223331, 37.664970618444272],
              [126.776053154058346, 37.664926479213314],
              [126.776007152184576, 37.664884494440152],
              [126.775957859959433, 37.664844849114537],
              [126.775905609026495, 37.664807718322308],
              [126.775850628884598, 37.664773287338519],
              [126.775811394420913, 37.664751667068977],
              [126.77579303749458, 37.664741551475757],
              [126.775733057550227, 37.664712695967545],
              [126.77567091181389, 37.664686898837601],
              [126.77560694354942, 37.664664072058358],
              [126.775541380532374, 37.664644577555123],
              [126.775474335667724, 37.664628230346239],
              [126.775406258393787, 37.664615217060067],
              [126.775337263520996, 37.664605623124615],
              [126.775267580899367, 37.664599363423477],
              [126.775197660052484, 37.66459661557446],
              [126.77512750181198, 37.664597293055159],
              [126.775057673990503, 37.66460130201218],
              [126.774988288165531, 37.664608827001778],
              [126.774962760825659, 37.664612145588471],
              [126.774935128737809, 37.664615736049257],
              [126.774877303391307, 37.664623252721874],
              [126.774851662526459, 37.664626585007724],
              [126.774785339151748, 37.66463375555783],
              [126.774718569389165, 37.664637860630179],
              [126.774651698766988, 37.664638814019263],
              [126.774599973926144, 37.664637181787811],
              [126.77458483529179, 37.664636702916226],
              [126.774570111685293, 37.664635519958061],
              [126.774518209726565, 37.664631350278555],
              [126.774452158235903, 37.664622933031211],
              [126.77443584346868, 37.664620045781241],
              [126.774386797312687, 37.664611365364813],
              [126.774322465370304, 37.664596826021899],
              [126.774259390043483, 37.664579226272551],
              [126.774197569601341, 37.664558744570037],
              [126.774137459561473, 37.664535293587306],
              [126.774079170688594, 37.664509142602938],
              [126.774023043111782, 37.664480293713083],
              [126.773968963715518, 37.66444883635684],
              [126.773957763534597, 37.664442236427746],
              [126.773171441816757, 37.663955461573359],
              [126.772552068450366, 37.663572029430625],
              [126.772035596209307, 37.663252294740474],
              [126.771856867542311, 37.663141576224739],
              [126.77116480522271, 37.662713076162177],
              [126.770986078251624, 37.662602448273148],
              [126.770973104647439, 37.662594414812069],
              [126.770780428081522, 37.662475099966649],
              [126.770739500613118, 37.662516825152771],
              [126.77062000849395, 37.662638493190855],
              [126.770521299393678, 37.662782995770598],
              [126.770417953167353, 37.662888296278275],
              [126.770362240124228, 37.66294512798077],
              [126.770094490753905, 37.663217868359268],
              [126.769706901085399, 37.663612813827605],
              [126.769565683922622, 37.663756709869538],
              [126.769434029044575, 37.663890858572202],
              [126.769126816882476, 37.664203882027969],
              [126.768778217650038, 37.66455898862052],
              [126.76872239099211, 37.664615818817516],
              [126.768373103052951, 37.664971733796079],
              [126.768311176679603, 37.665034826200547],
              [126.768025178558005, 37.665326208642206],
              [126.767758434252571, 37.665597954713071],
              [126.767649190581253, 37.665665220850315],
              [126.767469312759488, 37.665848481056116],
              [126.767434974232941, 37.665883460822336],
              [126.767225532864202, 37.666096575394981],
              [126.767417697538477, 37.666215726968986],
              [126.767636609391317, 37.666351464604205],
              [126.768384516132073, 37.666815138847042],
              [126.768736035560394, 37.667033062898291],
              [126.768846548542811, 37.667103735702504],
              [126.768954107897144, 37.667177196813959],
              [126.769058825380853, 37.667253265767428],
              [126.769160587384519, 37.667331849931578],
              [126.769205117286859, 37.667368525748905],
              [126.769259056003236, 37.667412950825749],
              [126.769354342097586, 37.667496479012577],
              [126.76939451578653, 37.667533975015388],
              [126.769446335446233, 37.667582342783447],
              [126.76953018714228, 37.667665849575336],
              [126.769534807893777, 37.667670451501984],
              [126.769619874841212, 37.66776071485711],
              [126.769701306978604, 37.667853044007963],
              [126.769778994060729, 37.66794734904849],
              [126.769853048110249, 37.668043536941909],
              [126.769923242053807, 37.66814152156514],
              [126.769980078582478, 37.668223820682144],
              [126.770073218414382, 37.668358689635113],
              [126.77043054675093, 37.668876103458736],
              [126.770818524699195, 37.669437886674721],
              [126.771063715794625, 37.669792913429319],
              [126.771107127974346, 37.669855771329559],
              [126.7712888196817, 37.670118850672424],
              [126.771493342748713, 37.670414986132265],
              [126.771601999216841, 37.67057231239211],
              [126.771792090161668, 37.670847546667105],
              [126.77185990884719, 37.670945794857815],
              [126.772072495780407, 37.670853230832286],
              [126.772073962548703, 37.670855837494805],
              [126.772163355806697, 37.671014771274471],
              [126.772231922783973, 37.671114023434981],
              [126.772284898974874, 37.671190705799177],
              [126.772673501175987, 37.671753211319285],
              [126.772717963663624, 37.671817571287768],
              [126.772876867542138, 37.672047581432786],
              [126.773208768048775, 37.672527997394106],
              [126.773336237173154, 37.672656180153602],
              [126.773396271949991, 37.672742878622515],
              [126.773459034215662, 37.672833625474169],
              [126.773467048180791, 37.672845216266168],
              [126.773504723179499, 37.6728996781669],
              [126.773563292830303, 37.672984213365112],
              [126.773601377208948, 37.673095916188053],
              [126.77370510811501, 37.673246032012308],
              [126.773964989509878, 37.673622127653779],
              [126.773978772304076, 37.673642074022567],
              [126.774149127880094, 37.673888606535968],
              [126.77429485724339, 37.674099501339249],
              [126.774335934472333, 37.674158944776615],
              [126.774429208345495, 37.6742380525911],
              [126.774596463735335, 37.674479741500278],
              [126.774705315935464, 37.674504999017223],
              [126.774812610076623, 37.674660869969841],
              [126.774928317721844, 37.67482896579606],
              [126.774896542003745, 37.674915488377906],
              [126.774978199154475, 37.67503405268544],
              [126.775042725986893, 37.675127746028515],
              [126.77506921014853, 37.675166199660289],
              [126.775101546692412, 37.675268071080573],
              [126.775196539050398, 37.675405636833887],
              [126.775253232267644, 37.67548773779118],
              [126.775587966200973, 37.675972482551003],
              [126.775680382842651, 37.676066181901611],
              [126.775720977720042, 37.676107342186242],
              [126.775799528330168, 37.676220743894945],
              [126.775832369287144, 37.676268183802371],
              [126.775870970569144, 37.676323951224767],
              [126.775931821210989, 37.676411780936796],
              [126.775950537263213, 37.6764387975782],
              [126.775982862344463, 37.67654436048403],
              [126.776187572493669, 37.676840812359671],
              [126.776304701724527, 37.677010433350937],
              [126.776592145353149, 37.677426691035258],
              [126.776762017507878, 37.67767268494574],
              [126.777037008232824, 37.678070901823361],
              [126.777152332618527, 37.678237590609626],
              [126.777278094647457, 37.678419407924686],
              [126.777423134712407, 37.678628700115425],
              [126.777448323929178, 37.678665055103238],
              [126.777469294990738, 37.678695285904837],
              [126.777484589038735, 37.678688455655802],
              [126.777562487592576, 37.678653656968422],
              [126.778566748595068, 37.67819462372988],
              [126.778664218561033, 37.678150074562076],
              [126.77892227248735, 37.678032130242713],
              [126.779084028636561, 37.677958286048508],
              [126.779364613260157, 37.677830007827019],
              [126.779425133651429, 37.677802340218086],
              [126.780398901661826, 37.677373506470722],
              [126.781109468176155, 37.677058871320547],
              [126.781816812578313, 37.676745656142749],
              [126.782717970953385, 37.676329011820712],
              [126.782979238881822, 37.676180025747286],
              [126.783794310807949, 37.675581936386479],
              [126.783927964678711, 37.675464189889276],
              [126.78412210081467, 37.675293156489815],
              [126.784161501143942, 37.675258444182482],
              [126.784213644485249, 37.675200308135345],
              [126.784356956320636, 37.675040522613315],
              [126.784517115097501, 37.674861952814972],
              [126.784555698319167, 37.674811793966938],
              [126.784691835769237, 37.674634816848794],
              [126.78471691929856, 37.674602208629665],
              [126.784772901637396, 37.674529514020698],
              [126.784921102702341, 37.674257499576505],
              [126.785295304660266, 37.673570662887272],
              [126.785517774375506, 37.67316231751461],
              [126.785543036560668, 37.673115953308837],
              [126.785556467459699, 37.673096515634789],
              [126.785719810557126, 37.672860401357603],
              [126.785725330000602, 37.672852420226846],
              [126.785725785427289, 37.67285175420384],
              [126.785759990071782, 37.672802271450884],
              [126.785819230093267, 37.672716208701111],
              [126.785896811732044, 37.672603497616102],
              [126.785915822786549, 37.672575872723854],
              [126.785982638807099, 37.672478869538189],
              [126.785997028744433, 37.672441685171343],
              [126.786197261543279, 37.672108151453571],
              [126.786424723612001, 37.671764575540543],
              [126.786702722222529, 37.671269639258156],
              [126.786751609491347, 37.671190921366531],
              [126.786799971510462, 37.671113047520244],
              [126.786831177477154, 37.67105469982117],
              [126.786859231399163, 37.671002245730747]
            ]
          ]
        ]
      },
      id: '31103530'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '백석1동',
        ADM_CD: '31103550'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.799901747443315, 37.649189430031115],
              [126.800090291737575, 37.648853458097292],
              [126.800122802799933, 37.648856845286993],
              [126.800133042873341, 37.648837592412121],
              [126.800171392972615, 37.648765481253683],
              [126.800276329728689, 37.648568165188877],
              [126.800389398562743, 37.648365007981248],
              [126.800472512168142, 37.648218290771226],
              [126.800596619904681, 37.647996864017557],
              [126.80065604796691, 37.647894632730292],
              [126.800797432659124, 37.647651411335566],
              [126.800923443863852, 37.647437373753206],
              [126.800979390589504, 37.647340749544604],
              [126.801041953194982, 37.647232692417283],
              [126.801161033233981, 37.647024859689111],
              [126.801319715083366, 37.646754747144165],
              [126.801571259634528, 37.646333878417089],
              [126.801589155363231, 37.646336610833579],
              [126.801760609452401, 37.646040795310235],
              [126.802097729007301, 37.646159931164121],
              [126.80215571172107, 37.646180421539853],
              [126.802190292256441, 37.646129212591873],
              [126.802609124257572, 37.6455089452134],
              [126.802657963034932, 37.645436618486507],
              [126.802723204255742, 37.645339999672593],
              [126.802432775418524, 37.64520676462444],
              [126.802261430156875, 37.645128159658945],
              [126.802063620008823, 37.645037412926996],
              [126.802006192431392, 37.645013780158038],
              [126.802006371444023, 37.645013546872093],
              [126.802087573917291, 37.644908482764897],
              [126.802035691964591, 37.644884449279459],
              [126.801982660196899, 37.644859859158245],
              [126.801676799830133, 37.644718227014785],
              [126.801586026885317, 37.644676182627094],
              [126.801427872023311, 37.644602921898809],
              [126.801351511865576, 37.644567549576685],
              [126.801136998983722, 37.644458853388912],
              [126.800814584984266, 37.64430593998226],
              [126.800537387201729, 37.644231200928061],
              [126.800525652542049, 37.644229325992825],
              [126.800428343330736, 37.644213809486189],
              [126.800157189355829, 37.644170567112724],
              [126.800138228987862, 37.644163696479779],
              [126.800055183536898, 37.644137360927409],
              [126.800014271696014, 37.644127102633448],
              [126.799928726199227, 37.644114010683438],
              [126.799528583219342, 37.644050347900844],
              [126.799297889421609, 37.644014867165595],
              [126.799086175947721, 37.644109836990424],
              [126.798923358338911, 37.644092268868228],
              [126.798512099944901, 37.644047901601091],
              [126.798144353013015, 37.643966655289049],
              [126.798079143311654, 37.643941259087541],
              [126.797878370245428, 37.64386306394298],
              [126.797779492305054, 37.643723997295062],
              [126.797721316957336, 37.643605708711171],
              [126.797638614302912, 37.64343526207778],
              [126.797597558104869, 37.643346518107982],
              [126.797597948563833, 37.643249438463044],
              [126.797628429757779, 37.643208832175574],
              [126.797739775208598, 37.643060504721994],
              [126.797765307101116, 37.643039197000107],
              [126.797726546004768, 37.642951204918404],
              [126.797650281437171, 37.642778076395977],
              [126.797615834124272, 37.642699892063966],
              [126.797611116858988, 37.642689145392268],
              [126.797565039450191, 37.642584182763855],
              [126.797434790982052, 37.642635772487971],
              [126.797386227760484, 37.642638914622317],
              [126.797266616250596, 37.642646651231715],
              [126.797180390454969, 37.642638144603801],
              [126.797173115334417, 37.642622066647164],
              [126.797013990243556, 37.642270319196527],
              [126.797013566102876, 37.642269380189866],
              [126.797057513202191, 37.642254368160913],
              [126.797186648510234, 37.642169297814689],
              [126.797197468977402, 37.642162170583774],
              [126.79727590003921, 37.642110676263741],
              [126.79733360413249, 37.642065568728491],
              [126.797304998667812, 37.642007196923998],
              [126.797231486832786, 37.641857663236628],
              [126.797226905733453, 37.641848355878416],
              [126.797202102105274, 37.64184436485904],
              [126.79718179885549, 37.641802786443449],
              [126.79702782192237, 37.641487424688698],
              [126.796999240132152, 37.641444630592439],
              [126.79699776257992, 37.641442413535131],
              [126.796975281080805, 37.641408767304917],
              [126.796974785990017, 37.641408020759293],
              [126.796866506931252, 37.641245917036976],
              [126.796855120670202, 37.641236480774253],
              [126.796852455211081, 37.64123426835225],
              [126.796820235521139, 37.641207552944586],
              [126.796818098642277, 37.641205780816286],
              [126.796715772450128, 37.641120939989946],
              [126.796120237511843, 37.640839439595659],
              [126.796103992518184, 37.640838265584499],
              [126.795834445783555, 37.640797793580859],
              [126.795833549281099, 37.64079910777351],
              [126.795773809732893, 37.640886704539177],
              [126.795965545611921, 37.640958666224762],
              [126.796066316526094, 37.641024431735183],
              [126.796021413383656, 37.641078593169546],
              [126.795986127610988, 37.641054565925693],
              [126.795946072888341, 37.641035395406668],
              [126.795825904424618, 37.641018698561076],
              [126.795775493134968, 37.641016357947684],
              [126.795775131717164, 37.641016341369046],
              [126.795766852329933, 37.641012320697186],
              [126.795737925254898, 37.640998272980084],
              [126.795647633942295, 37.640954430557748],
              [126.795643623828397, 37.64094541947815],
              [126.795573755503028, 37.64090623891834],
              [126.795488194007447, 37.640860149664853],
              [126.795402631578355, 37.640814050429221],
              [126.795342013991316, 37.640806033403059],
              [126.795310582034688, 37.640789836275957],
              [126.795123765976783, 37.640693570682558],
              [126.795084536129309, 37.640662238679667],
              [126.795017550903339, 37.640627718657676],
              [126.794912791430463, 37.640573733103118],
              [126.794810265996645, 37.640593986041438],
              [126.794582778303791, 37.640638916408697],
              [126.794100316473234, 37.640734212210816],
              [126.79409343961396, 37.64073770426716],
              [126.794066729366733, 37.640751273463877],
              [126.794062336397857, 37.640758427152711],
              [126.794057054759591, 37.640767022154421],
              [126.79403845613092, 37.640797295515021],
              [126.794010277265286, 37.640843163095262],
              [126.794006520500972, 37.640848603560087],
              [126.793978083376487, 37.640889778176728],
              [126.79394823503057, 37.640932872279883],
              [126.793920181634661, 37.64097337588661],
              [126.793860492732705, 37.640925327590182],
              [126.793831805812928, 37.640902236022718],
              [126.792669237143556, 37.639966360559583],
              [126.792564294540341, 37.63988188261677],
              [126.792414777693409, 37.639761507763815],
              [126.792387651874932, 37.639739668138169],
              [126.792379932785053, 37.639746277242516],
              [126.792084395338847, 37.639970153404043],
              [126.792083886164789, 37.639970538820918],
              [126.791926324243846, 37.64008989254981],
              [126.79157455147481, 37.640356361910236],
              [126.79154660236361, 37.640377536609989],
              [126.791212993979883, 37.640630278569866],
              [126.79074409052707, 37.640985514599059],
              [126.789468433687745, 37.641951914578691],
              [126.789463617936704, 37.641955562989033],
              [126.789457123915525, 37.641960477570365],
              [126.789456123767891, 37.641961235871996],
              [126.788832821487446, 37.642433425896883],
              [126.788626850534612, 37.642590093869373],
              [126.788619236086248, 37.642595885849239],
              [126.788618834807295, 37.642595531911965],
              [126.788522542112347, 37.642668470664326],
              [126.788194095586192, 37.642917281814789],
              [126.788169976240965, 37.642935553999024],
              [126.788014590402028, 37.643053257981691],
              [126.78791279669268, 37.643130365011963],
              [126.787277248864868, 37.643617977351155],
              [126.787260678043637, 37.643630688696788],
              [126.786363889316746, 37.644318710136609],
              [126.786273925208931, 37.644387729374408],
              [126.785270484952207, 37.645157544077748],
              [126.784550140592245, 37.645654823438733],
              [126.784532773242162, 37.645666812106512],
              [126.782851045804449, 37.646970393532072],
              [126.782825546910118, 37.646990162329459],
              [126.782766787683883, 37.647035707827243],
              [126.7825342706154, 37.6472159354472],
              [126.782505048434103, 37.647238586502866],
              [126.782224918017818, 37.647455718919389],
              [126.782109080355056, 37.647545500861973],
              [126.781082564716655, 37.648341148303508],
              [126.781215419622484, 37.648455094994667],
              [126.781863707713356, 37.648996684130204],
              [126.782373501064484, 37.649422564118659],
              [126.782623327048924, 37.649631265961453],
              [126.782869974170026, 37.649837310669973],
              [126.782871822949375, 37.64983885506394],
              [126.783086754160109, 37.65001840221997],
              [126.783170257820132, 37.650088159420314],
              [126.78326816852281, 37.650169951167861],
              [126.783386417952173, 37.650265132100003],
              [126.783508983844698, 37.650356717604531],
              [126.783635867947879, 37.650444525610951],
              [126.783766844649122, 37.650528558341009],
              [126.783901569943751, 37.650608633427709],
              [126.784040048088741, 37.650684660751708],
              [126.784182050677046, 37.650756460451355],
              [126.784227552278466, 37.650777636488456],
              [126.784327238765471, 37.650824030457734],
              [126.78446809109964, 37.650884034398175],
              [126.78447550069599, 37.650887191619418],
              [126.78462672083748, 37.650945942322281],
              [126.784802553037707, 37.65101096295566],
              [126.785101275790339, 37.651121424883584],
              [126.785416227466413, 37.651237887148255],
              [126.785438448094283, 37.651246104838989],
              [126.785897003050081, 37.651415486259324],
              [126.78630899570318, 37.651567665420473],
              [126.786658320310195, 37.651696694010838],
              [126.786716572062929, 37.651718211180999],
              [126.788022769441412, 37.652200667138253],
              [126.788217859788105, 37.652272722907327],
              [126.789120510575245, 37.652606111527938],
              [126.789419902574878, 37.652716687352353],
              [126.78974513773889, 37.652836806231164],
              [126.789796356855831, 37.652855723380199],
              [126.790153943640718, 37.652987790092887],
              [126.790782394440981, 37.653219890364959],
              [126.790978765580704, 37.653292413460193],
              [126.791404897998049, 37.65344991006824],
              [126.791815278751628, 37.653601581750145],
              [126.791966509018224, 37.653657473132142],
              [126.792530988086796, 37.653866094463588],
              [126.793049937734864, 37.654057883797023],
              [126.793169494108014, 37.654102069377174],
              [126.793425272723354, 37.654196596910673],
              [126.794398534807527, 37.654556275859555],
              [126.794867210212587, 37.654729475794021],
              [126.795529043141329, 37.65497405346548],
              [126.795529396743589, 37.654974184466788],
              [126.795689115789315, 37.655033206117302],
              [126.796021946219795, 37.655156230104595],
              [126.796107194947623, 37.655187731813811],
              [126.796346265839063, 37.655275797178334],
              [126.796483821586364, 37.655047297004053],
              [126.796650798029731, 37.654739904200099],
              [126.796764077222093, 37.654539587580992],
              [126.79680487125276, 37.65446744711479],
              [126.797029005069916, 37.654102489683886],
              [126.797038338759492, 37.654087190321789],
              [126.79704516970294, 37.654075939220789],
              [126.797309910978584, 37.65363566957069],
              [126.797367565166908, 37.65353978836017],
              [126.79769086452211, 37.653002015940828],
              [126.798010542150962, 37.652444328750342],
              [126.798260151479525, 37.652008874470319],
              [126.798314484965402, 37.65191408434147],
              [126.798674848033997, 37.651285014228847],
              [126.798945801515373, 37.650821657633621],
              [126.799062823681027, 37.650623192365089],
              [126.799181392186867, 37.650421995224548],
              [126.799193625007462, 37.650401237344603],
              [126.799270369564113, 37.650264961132308],
              [126.799307590875472, 37.650203847867196],
              [126.799333155714066, 37.650156808067671],
              [126.799462130597277, 37.649919495892945],
              [126.799504681284787, 37.649856500693168],
              [126.799647091684307, 37.649615192282724],
              [126.799699686223022, 37.649525096063876],
              [126.79977673532143, 37.649401703962894],
              [126.799833986192738, 37.64931017336707],
              [126.799901747443315, 37.649189430031115]
            ]
          ]
        ]
      },
      id: '31103550'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '마두1동',
        ADM_CD: '31103560'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.783233012802896, 37.666134754252461],
              [126.78323527000741, 37.666130892279725],
              [126.783415173930763, 37.666197356584121],
              [126.784019113654921, 37.666420550529132],
              [126.784871723356176, 37.666735639466964],
              [126.784889959072117, 37.66674237836191],
              [126.786280077176485, 37.667256087438382],
              [126.786638050825275, 37.667388369891789],
              [126.787056363079898, 37.667542948800651],
              [126.787380010537476, 37.667662543593593],
              [126.787834611985403, 37.667830527159282],
              [126.7879864849603, 37.667886634803651],
              [126.788142918373794, 37.667944427310601],
              [126.788316138736192, 37.668008421960685],
              [126.788528669348253, 37.668086936759003],
              [126.788586680032068, 37.667987213665818],
              [126.788721506092571, 37.667755456589347],
              [126.788940368724383, 37.667402129879243],
              [126.789326247010948, 37.66677917241779],
              [126.789375980993469, 37.66670772492661],
              [126.789391912970146, 37.666684868713872],
              [126.789536019031033, 37.666468353845701],
              [126.789580297025807, 37.666373420060111],
              [126.789616301037171, 37.666296232154032],
              [126.790005610674385, 37.665462182251304],
              [126.790476581028713, 37.664805315879036],
              [126.790524720096883, 37.664695573646874],
              [126.790623493653129, 37.664505481872318],
              [126.790673550089792, 37.664409150183772],
              [126.791042171275762, 37.663802012889661],
              [126.79140422787917, 37.663205674242995],
              [126.791597900698434, 37.662890047406805],
              [126.7916129174329, 37.662865032113089],
              [126.791753560329042, 37.66263075548153],
              [126.791926545334476, 37.662335183587984],
              [126.792011900399956, 37.662169105095799],
              [126.792103192247126, 37.662027906267724],
              [126.792112183229037, 37.662014046484728],
              [126.792179830725459, 37.661897579534916],
              [126.792317277181496, 37.66166474404352],
              [126.7925727149793, 37.661225881125141],
              [126.792640181449329, 37.661109938276439],
              [126.792705953426648, 37.660996909121693],
              [126.792825941722754, 37.660805125481531],
              [126.792831292858864, 37.660796304754392],
              [126.792848939319114, 37.660767080549284],
              [126.793190176887535, 37.660201936718074],
              [126.79391456597692, 37.658948518929961],
              [126.793945602733899, 37.658894806390229],
              [126.793980777375012, 37.658833931444704],
              [126.794034032996649, 37.658741777868983],
              [126.794004611152772, 37.658730445176104],
              [126.793906899863757, 37.658692810448471],
              [126.793755428714661, 37.658634431553473],
              [126.793827203208153, 37.658503213762124],
              [126.793834879863809, 37.658489190038772],
              [126.793959553646232, 37.658261248596546],
              [126.794201531503731, 37.657790568296846],
              [126.794523478662384, 37.657887021604367],
              [126.794533379248136, 37.657869787094668],
              [126.794548544031784, 37.65784340947998],
              [126.794665153408786, 37.657640556979317],
              [126.794774802288174, 37.657449809897734],
              [126.794855664864542, 37.657309140402027],
              [126.794950865467356, 37.657143618683968],
              [126.795042348287851, 37.657010343907508],
              [126.795062130616259, 37.656975538486186],
              [126.795073870425597, 37.656954882672792],
              [126.795185381571471, 37.656758681573507],
              [126.795288942347909, 37.656601732445779],
              [126.796053775201187, 37.655280044956562],
              [126.796107194947623, 37.655187731813811],
              [126.796021946219795, 37.655156230104595],
              [126.795689115789315, 37.655033206117302],
              [126.795529396743589, 37.654974184466788],
              [126.795529043141329, 37.65497405346548],
              [126.794867210212587, 37.654729475794021],
              [126.794398534807527, 37.654556275859555],
              [126.793425272723354, 37.654196596910673],
              [126.793169494108014, 37.654102069377174],
              [126.793049937734864, 37.654057883797023],
              [126.792530988086796, 37.653866094463588],
              [126.791966509018224, 37.653657473132142],
              [126.791815278751628, 37.653601581750145],
              [126.791404897998049, 37.65344991006824],
              [126.790978765580704, 37.653292413460193],
              [126.790782394440981, 37.653219890364959],
              [126.790153943640718, 37.652987790092887],
              [126.789796356855831, 37.652855723380199],
              [126.78974513773889, 37.652836806231164],
              [126.789419902574878, 37.652716687352353],
              [126.789120510575245, 37.652606111527938],
              [126.788217859788105, 37.652272722907327],
              [126.788214589128629, 37.652278318430277],
              [126.788112458486793, 37.652453053206258],
              [126.787938830730283, 37.652750104235103],
              [126.78766091943514, 37.653225565467437],
              [126.787565905076875, 37.653388121634755],
              [126.78736739166483, 37.653727737063903],
              [126.787336083999747, 37.653781301158254],
              [126.787064491122493, 37.654245934470495],
              [126.7868568162325, 37.654601219020719],
              [126.786618001344237, 37.655009642652914],
              [126.786379415829899, 37.655418342955848],
              [126.786157515573336, 37.65579790923853],
              [126.786140592496452, 37.655826858329171],
              [126.785921339634214, 37.656202001128165],
              [126.785750077179756, 37.656495033171794],
              [126.785587645754816, 37.656772949137284],
              [126.785495793902527, 37.656930109300561],
              [126.785394997493739, 37.657102565659621],
              [126.785329048636797, 37.657215403133378],
              [126.785204510988322, 37.657428478780503],
              [126.78492185268648, 37.657912091006189],
              [126.784864448871303, 37.658010100935726],
              [126.784760487788617, 37.657971708711543],
              [126.784658978295894, 37.657934221772123],
              [126.784570110880125, 37.657901354098591],
              [126.784370895748353, 37.657857469736228],
              [126.783691442393135, 37.657606216852294],
              [126.783431074085712, 37.657509935078224],
              [126.782900872708936, 37.65731386830943],
              [126.782272879640445, 37.6570816361073],
              [126.782065554808625, 37.656988823291762],
              [126.781885248003874, 37.656908108135781],
              [126.781666655671543, 37.656827243384065],
              [126.781661123341095, 37.65682675903107],
              [126.78156128975651, 37.656818037297398],
              [126.781292142802116, 37.656718489529602],
              [126.780811156781652, 37.65654058740941],
              [126.780257649997054, 37.656335859086319],
              [126.78014887169536, 37.656295623553092],
              [126.780107815348643, 37.656268699197192],
              [126.779710036866902, 37.656118931383453],
              [126.779628621097075, 37.656088284899276],
              [126.779556315455281, 37.65606106855958],
              [126.77952348803683, 37.656048664123524],
              [126.779424389998056, 37.656027665432546],
              [126.778896316928694, 37.655832338196085],
              [126.778515299760826, 37.655691402660601],
              [126.778119904898233, 37.655545148424707],
              [126.778043158593249, 37.655516759301349],
              [126.777392810557515, 37.655276192939624],
              [126.777212613006725, 37.655193578543354],
              [126.777056363739391, 37.655121940963809],
              [126.776450987312174, 37.654898064461172],
              [126.776404377748278, 37.654796527359395],
              [126.776252897412832, 37.655055275046358],
              [126.776143522978956, 37.655242099244887],
              [126.775906486988006, 37.655154433648526],
              [126.775522351051279, 37.655811217147047],
              [126.775215460026004, 37.6563359188916],
              [126.774458443601546, 37.657630175846464],
              [126.774099084556752, 37.658244547114812],
              [126.773701553944548, 37.658924165145187],
              [126.773542647784609, 37.659195828014219],
              [126.773400361421864, 37.6594390742477],
              [126.773018387633385, 37.660092075792164],
              [126.772946379831069, 37.660215174586234],
              [126.772850919446597, 37.660374370976371],
              [126.772751724320884, 37.660532029131737],
              [126.77264890695281, 37.660688237165068],
              [126.772542241168537, 37.660842817913945],
              [126.772533678669348, 37.660854702233436],
              [126.772516753722357, 37.660878185483448],
              [126.77243195170368, 37.660995860182602],
              [126.772318040066267, 37.661147091770545],
              [126.772200622370335, 37.661296695451178],
              [126.772079581088136, 37.661444398289085],
              [126.77199745993056, 37.661540742262019],
              [126.771995455119949, 37.661543094960649],
              [126.771955145687855, 37.661590385559556],
              [126.771827200946959, 37.66173438073568],
              [126.771695864708661, 37.661876389941689],
              [126.771561132735457, 37.662016497864563],
              [126.771345998110348, 37.662235711122356],
              [126.770986078251624, 37.662602448273148],
              [126.77116480522271, 37.662713076162177],
              [126.771856867542311, 37.663141576224739],
              [126.772035596209307, 37.663252294740474],
              [126.772552068450366, 37.663572029430625],
              [126.773171441816757, 37.663955461573359],
              [126.773957763534597, 37.664442236427746],
              [126.773968963715518, 37.66444883635684],
              [126.774023043111782, 37.664480293713083],
              [126.774079170688594, 37.664509142602938],
              [126.774137459561473, 37.664535293587306],
              [126.774197569601341, 37.664558744570037],
              [126.774259390043483, 37.664579226272551],
              [126.774322465370304, 37.664596826021899],
              [126.774386797312687, 37.664611365364813],
              [126.77443584346868, 37.664620045781241],
              [126.774452158235903, 37.664622933031211],
              [126.774518209726565, 37.664631350278555],
              [126.774570111685293, 37.664635519958061],
              [126.77458483529179, 37.664636702916226],
              [126.774599973926144, 37.664637181787811],
              [126.774651698766988, 37.664638814019263],
              [126.774718569389165, 37.664637860630179],
              [126.774785339151748, 37.66463375555783],
              [126.774851662526459, 37.664626585007724],
              [126.774877303391307, 37.664623252721874],
              [126.774935128737809, 37.664615736049257],
              [126.774962760825659, 37.664612145588471],
              [126.774988288165531, 37.664608827001778],
              [126.775057673990503, 37.66460130201218],
              [126.77512750181198, 37.664597293055159],
              [126.775197660052484, 37.66459661557446],
              [126.775267580899367, 37.664599363423477],
              [126.775337263520996, 37.664605623124615],
              [126.775406258393787, 37.664615217060067],
              [126.775474335667724, 37.664628230346239],
              [126.775541380532374, 37.664644577555123],
              [126.77560694354942, 37.664664072058358],
              [126.77567091181389, 37.664686898837601],
              [126.775733057550227, 37.664712695967545],
              [126.77579303749458, 37.664741551475757],
              [126.775811394420913, 37.664751667068977],
              [126.775850628884598, 37.664773287338519],
              [126.775905609026495, 37.664807718322308],
              [126.775957859959433, 37.664844849114537],
              [126.776007152184576, 37.664884494440152],
              [126.776053154058346, 37.664926479213314],
              [126.776095976223331, 37.664970618444272],
              [126.776135166889816, 37.665016732704437],
              [126.776170722394298, 37.665064731842627],
              [126.77649155884923, 37.665530558984543],
              [126.776881647111438, 37.666096925167857],
              [126.776973274928565, 37.666229957917132],
              [126.777050247221354, 37.666337950345273],
              [126.777130967493846, 37.666444148625821],
              [126.777215317860652, 37.666548550226182],
              [126.777303301437726, 37.666651068630777],
              [126.777369836391358, 37.666725615501718],
              [126.777382204706413, 37.666738569378914],
              [126.777403628982256, 37.666761008375389],
              [126.777478076958204, 37.666838981821165],
              [126.777590516892673, 37.666949384974856],
              [126.777707275543534, 37.667056912208857],
              [126.777828239519849, 37.667161564618858],
              [126.777953288995604, 37.667263071061726],
              [126.778082204889046, 37.667361340051364],
              [126.778214984041185, 37.667456463490893],
              [126.778351513421185, 37.667548170278963],
              [126.77849145286757, 37.667636460124477],
              [126.778634916625933, 37.667721243581902],
              [126.778716727586854, 37.667766445917408],
              [126.778781679690042, 37.667802337195376],
              [126.778931515254001, 37.66787974317144],
              [126.77908430852932, 37.667953370664534],
              [126.779239943853895, 37.668023218956478],
              [126.779398202979579, 37.668089108240721],
              [126.779559077706637, 37.668150946521791],
              [126.780159668826727, 37.668372944568581],
              [126.781156536602822, 37.668741408433618],
              [126.781430596708176, 37.668842705246703],
              [126.781604346221172, 37.668906937383767],
              [126.781610392102706, 37.668909171633572],
              [126.781610652654891, 37.668908726164524],
              [126.781992228153186, 37.668256313509865],
              [126.782001436086588, 37.668240570276396],
              [126.782618522675051, 37.667185460050689],
              [126.783184332295676, 37.66621799664933],
              [126.783233012802896, 37.666134754252461]
            ]
          ]
        ]
      },
      id: '31103560'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '마두2동',
        ADM_CD: '31103570'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.786618001344237, 37.655009642652914],
              [126.7868568162325, 37.654601219020719],
              [126.787064491122493, 37.654245934470495],
              [126.787336083999747, 37.653781301158254],
              [126.78736739166483, 37.653727737063903],
              [126.787565905076875, 37.653388121634755],
              [126.78766091943514, 37.653225565467437],
              [126.787938830730283, 37.652750104235103],
              [126.788112458486793, 37.652453053206258],
              [126.788214589128629, 37.652278318430277],
              [126.788217859788105, 37.652272722907327],
              [126.788022769441412, 37.652200667138253],
              [126.786716572062929, 37.651718211180999],
              [126.786658320310195, 37.651696694010838],
              [126.78630899570318, 37.651567665420473],
              [126.785897003050081, 37.651415486259324],
              [126.785438448094283, 37.651246104838989],
              [126.785416227466413, 37.651237887148255],
              [126.785101275790339, 37.651121424883584],
              [126.784802553037707, 37.65101096295566],
              [126.78462672083748, 37.650945942322281],
              [126.78447550069599, 37.650887191619418],
              [126.78446809109964, 37.650884034398175],
              [126.784327238765471, 37.650824030457734],
              [126.784227552278466, 37.650777636488456],
              [126.784182050677046, 37.650756460451355],
              [126.784040048088741, 37.650684660751708],
              [126.783901569943751, 37.650608633427709],
              [126.783766844649122, 37.650528558341009],
              [126.783635867947879, 37.650444525610951],
              [126.783508983844698, 37.650356717604531],
              [126.783386417952173, 37.650265132100003],
              [126.78326816852281, 37.650169951167861],
              [126.783170257820132, 37.650088159420314],
              [126.783086754160109, 37.65001840221997],
              [126.782871822949375, 37.64983885506394],
              [126.782869974170026, 37.649837310669973],
              [126.782623327048924, 37.649631265961453],
              [126.782373501064484, 37.649422564118659],
              [126.781863707713356, 37.648996684130204],
              [126.781215419622484, 37.648455094994667],
              [126.781082564716655, 37.648341148303508],
              [126.781040302041163, 37.648304899897113],
              [126.781038871969386, 37.648303672588916],
              [126.781033358936369, 37.648307837364385],
              [126.780861013554301, 37.648438032498916],
              [126.780271497156761, 37.648883364140687],
              [126.78012771248261, 37.648994364745271],
              [126.779987090605886, 37.64910789376561],
              [126.779849637800965, 37.64922377098609],
              [126.779715461146381, 37.649342177336599],
              [126.779584563255511, 37.649462842446404],
              [126.779457173121344, 37.649585765922417],
              [126.779333057242965, 37.649710946349401],
              [126.779269527928179, 37.649778091834754],
              [126.779264655846418, 37.649783241985112],
              [126.779212565605462, 37.649838297546133],
              [126.779095577555211, 37.649967636718536],
              [126.778994549302581, 37.650084871175331],
              [126.778982323452382, 37.650099057220153],
              [126.778872688592855, 37.650232464622611],
              [126.778766788066378, 37.65036768117713],
              [126.778721858326676, 37.650427979136424],
              [126.778664619897341, 37.650504795211496],
              [126.778566299431517, 37.650643720012233],
              [126.778471939540211, 37.650784273310229],
              [126.778381426867469, 37.650926456224163],
              [126.778294989028055, 37.651070178220209],
              [126.778145373914526, 37.65132602959099],
              [126.778138039281146, 37.651338573516448],
              [126.777373022214974, 37.652646770461672],
              [126.776565624814936, 37.654027382259649],
              [126.776555033051906, 37.654045492513561],
              [126.776015688556924, 37.654967713161717],
              [126.775906486988006, 37.655154433648526],
              [126.776143522978956, 37.655242099244887],
              [126.776252897412832, 37.655055275046358],
              [126.776404377748278, 37.654796527359395],
              [126.776450987312174, 37.654898064461172],
              [126.777056363739391, 37.655121940963809],
              [126.777212613006725, 37.655193578543354],
              [126.777392810557515, 37.655276192939624],
              [126.778043158593249, 37.655516759301349],
              [126.778119904898233, 37.655545148424707],
              [126.778515299760826, 37.655691402660601],
              [126.778896316928694, 37.655832338196085],
              [126.779424389998056, 37.656027665432546],
              [126.77952348803683, 37.656048664123524],
              [126.779556315455281, 37.65606106855958],
              [126.779628621097075, 37.656088284899276],
              [126.779710036866902, 37.656118931383453],
              [126.780107815348643, 37.656268699197192],
              [126.78014887169536, 37.656295623553092],
              [126.780257649997054, 37.656335859086319],
              [126.780811156781652, 37.65654058740941],
              [126.781292142802116, 37.656718489529602],
              [126.78156128975651, 37.656818037297398],
              [126.781661123341095, 37.65682675903107],
              [126.781666655671543, 37.656827243384065],
              [126.781885248003874, 37.656908108135781],
              [126.782065554808625, 37.656988823291762],
              [126.782272879640445, 37.6570816361073],
              [126.782900872708936, 37.65731386830943],
              [126.783431074085712, 37.657509935078224],
              [126.783691442393135, 37.657606216852294],
              [126.784370895748353, 37.657857469736228],
              [126.784570110880125, 37.657901354098591],
              [126.784658978295894, 37.657934221772123],
              [126.784760487788617, 37.657971708711543],
              [126.784864448871303, 37.658010100935726],
              [126.78492185268648, 37.657912091006189],
              [126.785204510988322, 37.657428478780503],
              [126.785329048636797, 37.657215403133378],
              [126.785394997493739, 37.657102565659621],
              [126.785495793902527, 37.656930109300561],
              [126.785587645754816, 37.656772949137284],
              [126.785750077179756, 37.656495033171794],
              [126.785921339634214, 37.656202001128165],
              [126.786140592496452, 37.655826858329171],
              [126.786157515573336, 37.65579790923853],
              [126.786379415829899, 37.655418342955848],
              [126.786618001344237, 37.655009642652914]
            ]
          ]
        ]
      },
      id: '31103570'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '장항2동',
        ADM_CD: '31103590'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.762688825355923, 37.663293232188003],
              [126.762694587588697, 37.66328735913698],
              [126.762707766539975, 37.663295529986549],
              [126.763237249196763, 37.663623833819734],
              [126.763409644415646, 37.663730725812435],
              [126.763725400475678, 37.66392650505329],
              [126.763993920318555, 37.664092994059835],
              [126.764006700623028, 37.664100918018377],
              [126.764590143919165, 37.664462663463723],
              [126.764686033843475, 37.664522115853273],
              [126.765032484062999, 37.664736919684806],
              [126.765103377073913, 37.664780873659446],
              [126.765417735645016, 37.664975776198602],
              [126.765543244011994, 37.665053590222925],
              [126.765751962762323, 37.665182995578675],
              [126.766135654760859, 37.665420880131926],
              [126.76646889416655, 37.665627482042296],
              [126.766668131653958, 37.665751003368193],
              [126.767225532864202, 37.666096575394981],
              [126.767434974232941, 37.665883460822336],
              [126.767469312759488, 37.665848481056116],
              [126.767649190581253, 37.665665220850315],
              [126.767758434252571, 37.665597954713071],
              [126.768025178558005, 37.665326208642206],
              [126.768311176679603, 37.665034826200547],
              [126.768373103052951, 37.664971733796079],
              [126.76872239099211, 37.664615818817516],
              [126.768778217650038, 37.66455898862052],
              [126.769126816882476, 37.664203882027969],
              [126.769434029044575, 37.663890858572202],
              [126.769565683922622, 37.663756709869538],
              [126.769706901085399, 37.663612813827605],
              [126.770094490753905, 37.663217868359268],
              [126.770362240124228, 37.66294512798077],
              [126.770417953167353, 37.662888296278275],
              [126.770521299393678, 37.662782995770598],
              [126.77062000849395, 37.662638493190855],
              [126.770739500613118, 37.662516825152771],
              [126.770780428081522, 37.662475099966649],
              [126.770973104647439, 37.662594414812069],
              [126.770986078251624, 37.662602448273148],
              [126.771345998110348, 37.662235711122356],
              [126.771561132735457, 37.662016497864563],
              [126.771695864708661, 37.661876389941689],
              [126.771827200946959, 37.66173438073568],
              [126.771955145687855, 37.661590385559556],
              [126.771995455119949, 37.661543094960649],
              [126.77199745993056, 37.661540742262019],
              [126.772079581088136, 37.661444398289085],
              [126.772200622370335, 37.661296695451178],
              [126.772318040066267, 37.661147091770545],
              [126.77243195170368, 37.660995860182602],
              [126.772516753722357, 37.660878185483448],
              [126.772533678669348, 37.660854702233436],
              [126.772542241168537, 37.660842817913945],
              [126.77264890695281, 37.660688237165068],
              [126.772751724320884, 37.660532029131737],
              [126.772850919446597, 37.660374370976371],
              [126.772946379831069, 37.660215174586234],
              [126.773018387633385, 37.660092075792164],
              [126.773400361421864, 37.6594390742477],
              [126.773542647784609, 37.659195828014219],
              [126.773701553944548, 37.658924165145187],
              [126.774099084556752, 37.658244547114812],
              [126.774458443601546, 37.657630175846464],
              [126.775215460026004, 37.6563359188916],
              [126.775522351051279, 37.655811217147047],
              [126.775906486988006, 37.655154433648526],
              [126.776015688556924, 37.654967713161717],
              [126.776555033051906, 37.654045492513561],
              [126.776565624814936, 37.654027382259649],
              [126.777373022214974, 37.652646770461672],
              [126.778138039281146, 37.651338573516448],
              [126.778145373914526, 37.65132602959099],
              [126.778294989028055, 37.651070178220209],
              [126.778381426867469, 37.650926456224163],
              [126.778471939540211, 37.650784273310229],
              [126.778566299431517, 37.650643720012233],
              [126.778664619897341, 37.650504795211496],
              [126.778721858326676, 37.650427979136424],
              [126.778766788066378, 37.65036768117713],
              [126.778872688592855, 37.650232464622611],
              [126.778982323452382, 37.650099057220153],
              [126.778994549302581, 37.650084871175331],
              [126.779095577555211, 37.649967636718536],
              [126.779212565605462, 37.649838297546133],
              [126.779264655846418, 37.649783241985112],
              [126.779269527928179, 37.649778091834754],
              [126.779333057242965, 37.649710946349401],
              [126.779457173121344, 37.649585765922417],
              [126.779584563255511, 37.649462842446404],
              [126.779715461146381, 37.649342177336599],
              [126.779849637800965, 37.64922377098609],
              [126.779987090605886, 37.64910789376561],
              [126.78012771248261, 37.648994364745271],
              [126.780271497156761, 37.648883364140687],
              [126.780861013554301, 37.648438032498916],
              [126.781033358936369, 37.648307837364385],
              [126.781038871969386, 37.648303672588916],
              [126.780859373410294, 37.648153640701196],
              [126.780197483335883, 37.64759962298367],
              [126.779626868879021, 37.647121995612522],
              [126.779312281326369, 37.646858669819729],
              [126.778958611357169, 37.646562627738085],
              [126.778382996665073, 37.646080796039669],
              [126.777863161626016, 37.64564564917098],
              [126.777844930311502, 37.645630388080811],
              [126.777536004369608, 37.645371786301311],
              [126.777378630008073, 37.645240045410617],
              [126.777051010814063, 37.644965790792355],
              [126.776897415945484, 37.644837230666077],
              [126.776766192519091, 37.644727392836899],
              [126.776292064072976, 37.644330510122671],
              [126.776272342729143, 37.64434201263937],
              [126.776113247035127, 37.644434814084157],
              [126.775228230857522, 37.644951040620775],
              [126.774602835159996, 37.645315822800512],
              [126.774509695138079, 37.645352581159628],
              [126.774456653123252, 37.645356986910215],
              [126.774438376204429, 37.645380052997318],
              [126.774422415474902, 37.645400164359906],
              [126.774169284045129, 37.645550321962425],
              [126.773863076366482, 37.645750650003791],
              [126.773301421222769, 37.646081886197379],
              [126.772083275498829, 37.646800260388034],
              [126.771653183324275, 37.647049497372265],
              [126.771620437786098, 37.647068480806951],
              [126.771355313443223, 37.647222111255822],
              [126.771333290700667, 37.647234878601864],
              [126.771300859691891, 37.647253674611008],
              [126.770992691541778, 37.647432257423951],
              [126.770801905201807, 37.64754281376316],
              [126.77060698004567, 37.647655765158824],
              [126.77041166205197, 37.647768951748034],
              [126.770382032809977, 37.647786119008913],
              [126.770209401050764, 37.647841639972789],
              [126.770174868978174, 37.647872601377323],
              [126.770045774756895, 37.64798835661518],
              [126.769993080155601, 37.648016346573876],
              [126.769770266081011, 37.648134692367528],
              [126.769739214761927, 37.648151194496975],
              [126.769637134998206, 37.648218295324071],
              [126.768493132922274, 37.648978897966259],
              [126.768276687113399, 37.649057214687417],
              [126.768232542392624, 37.649041811214026],
              [126.7678122824056, 37.649287663636564],
              [126.767064767334972, 37.64971423613644],
              [126.766950231531808, 37.649784806626144],
              [126.76693022494193, 37.649797125991135],
              [126.766899651436489, 37.64981332027638],
              [126.766880500545781, 37.649823463744568],
              [126.766878695580417, 37.649824415142064],
              [126.766816293838986, 37.649860356111965],
              [126.766761316038284, 37.649892022242923],
              [126.766567440252444, 37.650003529466424],
              [126.766359167389425, 37.650123312925977],
              [126.766277271118582, 37.650169921222307],
              [126.766253921911883, 37.650183218347962],
              [126.765878809534811, 37.650396701316552],
              [126.765862886216837, 37.650414509739221],
              [126.765582974039816, 37.650579187985265],
              [126.765515186179954, 37.65061906596025],
              [126.765260441613037, 37.65076893814058],
              [126.764898079616586, 37.650982111593059],
              [126.764154052001274, 37.651419813963528],
              [126.764039244867206, 37.651482183246458],
              [126.763996450288175, 37.651505431800778],
              [126.76390377882646, 37.651555769376507],
              [126.763595496242914, 37.651743489323941],
              [126.763469391906227, 37.651844504807542],
              [126.76335725424839, 37.65200672437129],
              [126.763260654624915, 37.652232638307645],
              [126.763232007756798, 37.652299647704275],
              [126.763235159736411, 37.652307400753052],
              [126.763228002136358, 37.652312434108879],
              [126.763212289350363, 37.652346690843437],
              [126.763134725582916, 37.652515857208826],
              [126.763108181994568, 37.65257374208484],
              [126.763119580921312, 37.652587371010775],
              [126.763098125735993, 37.652599759981513],
              [126.76308994424646, 37.652620756594601],
              [126.763086071194934, 37.652630677610709],
              [126.763000781225529, 37.652849494625706],
              [126.762722234199771, 37.653477848038484],
              [126.762631669195301, 37.653682140289028],
              [126.762617024404364, 37.653715183217336],
              [126.762445754689494, 37.654130960384819],
              [126.761948679227046, 37.655337653238703],
              [126.761914586863114, 37.655420381534512],
              [126.761776305539229, 37.655756074076038],
              [126.761586270728628, 37.6561791538713],
              [126.761578144532805, 37.656197230421164],
              [126.761572563837291, 37.656209660790758],
              [126.761568724398899, 37.656219581959846],
              [126.761445487990514, 37.656537716718191],
              [126.76125730133235, 37.656980971891493],
              [126.761167774661786, 37.657191978817231],
              [126.760682580296944, 37.65829341673642],
              [126.760406275668913, 37.658951058373979],
              [126.760385502632801, 37.659000506300927],
              [126.760366257826036, 37.659046299026777],
              [126.760322482240724, 37.659150496882951],
              [126.760286115188109, 37.659193139354933],
              [126.760230335163669, 37.65925851401277],
              [126.759986682634533, 37.659417615841598],
              [126.759920291211245, 37.659460978362318],
              [126.759920060164845, 37.659461411350357],
              [126.759755980947205, 37.659568266511194],
              [126.759681396581627, 37.659616906099927],
              [126.75967686972605, 37.659619856560212],
              [126.759606206063125, 37.659666066405805],
              [126.759603542635205, 37.65966780546465],
              [126.759555916185818, 37.659750237135299],
              [126.759499031081177, 37.659848686656474],
              [126.759386598175766, 37.659956121484768],
              [126.759405257480751, 37.660038509594067],
              [126.759412372051429, 37.660081048033156],
              [126.759201513364374, 37.660205581059458],
              [126.75910497171418, 37.660200608688662],
              [126.758944444555183, 37.660356336703771],
              [126.758836208769353, 37.660461336749123],
              [126.758786279136842, 37.660658052164187],
              [126.758689649677024, 37.661038774727601],
              [126.75867513835253, 37.661095921122545],
              [126.758480136328373, 37.661081462164326],
              [126.758410884496556, 37.661083664055653],
              [126.758360923552345, 37.661147529513954],
              [126.75816559140037, 37.661454250677799],
              [126.758153825052176, 37.661472715078816],
              [126.758099273856743, 37.661553238636685],
              [126.75766216570193, 37.662206350237859],
              [126.757509595592779, 37.662386589988159],
              [126.757245666460292, 37.662670827752443],
              [126.756906226975232, 37.663036382406851],
              [126.756382905514371, 37.663602997556879],
              [126.756661064379529, 37.664007209270281],
              [126.756786496731394, 37.664189466495202],
              [126.757363129425258, 37.665027483941152],
              [126.757887436975565, 37.665789437519656],
              [126.757977227976397, 37.665919814432534],
              [126.757995052889285, 37.665945797213624],
              [126.758088576561136, 37.666081672151414],
              [126.758090258697393, 37.666084112696275],
              [126.758327158518995, 37.665980796888221],
              [126.759058169789753, 37.665661983493557],
              [126.759316425696781, 37.665549349473579],
              [126.759372940457666, 37.665524701685165],
              [126.759694889883818, 37.665384286926042],
              [126.759704121839832, 37.665380260078699],
              [126.759873242951386, 37.665304654980538],
              [126.760040218835101, 37.665226162764078],
              [126.76020504860135, 37.66514487176233],
              [126.760367622246235, 37.665060783096941],
              [126.760527935223735, 37.664973896748528],
              [126.760595795105473, 37.664935440935778],
              [126.760685879162736, 37.66488439231135],
              [126.760841449279908, 37.664792179632549],
              [126.760994421297738, 37.664697350152373],
              [126.761144795236504, 37.664599901176913],
              [126.761292570188061, 37.664499923743861],
              [126.761348408207922, 37.664460470145841],
              [126.761437518496578, 37.664397507470852],
              [126.761579754408444, 37.664292564753829],
              [126.761654579439536, 37.664234977195271],
              [126.76171916506398, 37.664185271555219],
              [126.761855521410681, 37.664075630056765],
              [126.76198905158941, 37.663963730026026],
              [126.762038417019156, 37.663920501416335],
              [126.762119415461598, 37.66384957114721],
              [126.762246726403077, 37.663733154139877],
              [126.7623708703929, 37.663614658565265],
              [126.762491734080172, 37.663494081921812],
              [126.762555294901162, 37.6634293407526],
              [126.762688825355923, 37.663293232188003]
            ]
          ]
        ]
      },
      id: '31103590'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '백석2동',
        ADM_CD: '31103610'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.788522542112347, 37.642668470664326],
              [126.788618834807295, 37.642595531911965],
              [126.788619236086248, 37.642595885849239],
              [126.788626850534612, 37.642590093869373],
              [126.788832821487446, 37.642433425896883],
              [126.789456123767891, 37.641961235871996],
              [126.789457123915525, 37.641960477570365],
              [126.789463617936704, 37.641955562989033],
              [126.789468433687745, 37.641951914578691],
              [126.79074409052707, 37.640985514599059],
              [126.791212993979883, 37.640630278569866],
              [126.79154660236361, 37.640377536609989],
              [126.79157455147481, 37.640356361910236],
              [126.791926324243846, 37.64008989254981],
              [126.792083886164789, 37.639970538820918],
              [126.792084395338847, 37.639970153404043],
              [126.792379932785053, 37.639746277242516],
              [126.792387651874932, 37.639739668138169],
              [126.792221882663526, 37.63960620742705],
              [126.792151149925346, 37.639549268009766],
              [126.791522872933427, 37.639043473431002],
              [126.791184709127521, 37.638771231358348],
              [126.790941107337233, 37.638574565177741],
              [126.790329013199312, 37.638086940317997],
              [126.789692881685838, 37.637580154947656],
              [126.789633647922187, 37.637532965311948],
              [126.789606547426359, 37.637511373964287],
              [126.789603026579059, 37.637508567660809],
              [126.789580456734569, 37.637492885653486],
              [126.789394091845836, 37.637363387164541],
              [126.78933759828702, 37.637324127543188],
              [126.789268841997128, 37.637276352185033],
              [126.789161661652074, 37.637201875085729],
              [126.789110635896961, 37.637166419455596],
              [126.788996739575225, 37.637087277926604],
              [126.788828399623156, 37.63697030746826],
              [126.788666480377671, 37.636857790186312],
              [126.788487057782561, 37.636733114566717],
              [126.788312941217981, 37.636612121059905],
              [126.788195380533438, 37.636530434736692],
              [126.78812924579772, 37.636484473581156],
              [126.788037027669631, 37.636420415857856],
              [126.787893607087682, 37.636320790877193],
              [126.787867951873338, 37.636302957377346],
              [126.787844965005988, 37.63628698860235],
              [126.787811204327781, 37.636263520633968],
              [126.787729202628029, 37.636206517744881],
              [126.78763844259062, 37.636274243557516],
              [126.787625168973577, 37.636284148321167],
              [126.787600045276449, 37.636302888451532],
              [126.787577357464798, 37.636319822603255],
              [126.786355217245614, 37.637231677106911],
              [126.785934702611257, 37.637545419382285],
              [126.785888709131356, 37.637579735754009],
              [126.785871371120408, 37.637592669412719],
              [126.785839851499972, 37.637616181732206],
              [126.785522442580145, 37.637852996365545],
              [126.785000197423315, 37.638246372417235],
              [126.784714489275302, 37.638461576540664],
              [126.784672281853048, 37.638493366428669],
              [126.784625150265299, 37.638528876866218],
              [126.784594990821248, 37.638551590405683],
              [126.784579788361839, 37.638563039715585],
              [126.784306521808347, 37.638765824075442],
              [126.784262389321455, 37.638798575569041],
              [126.783889872442742, 37.639075018035477],
              [126.783873896268375, 37.639086873561325],
              [126.783557391227532, 37.639321736909778],
              [126.783505840162462, 37.639359991633455],
              [126.783486854732288, 37.639374074937422],
              [126.783465530157059, 37.639389899816805],
              [126.783447634810614, 37.639403184849797],
              [126.78344335324843, 37.639406430662632],
              [126.782770423303774, 37.63991570143677],
              [126.782517898075213, 37.640106810190588],
              [126.782469751391659, 37.640143236534286],
              [126.782227906939568, 37.64032626342037],
              [126.781903516971141, 37.640571760398437],
              [126.781887960321441, 37.640583534379871],
              [126.781827592556368, 37.640629215701082],
              [126.781666779276961, 37.640750910842414],
              [126.781646109728953, 37.640766548293065],
              [126.781406509393179, 37.640947873701172],
              [126.781063484636263, 37.641207458822635],
              [126.780379857743327, 37.64172478585634],
              [126.78012493717759, 37.641912068464244],
              [126.780100275621237, 37.641929273923154],
              [126.779844948849018, 37.642107413982728],
              [126.779791558151942, 37.642139517784983],
              [126.77976854695828, 37.642157996908395],
              [126.779786391484933, 37.642215152614071],
              [126.77977366077593, 37.642225546506381],
              [126.779707681011956, 37.642279421569654],
              [126.779550860704944, 37.642343613676807],
              [126.779539202400386, 37.642354340372648],
              [126.779212093332092, 37.642620863667439],
              [126.779104134835649, 37.642690033385172],
              [126.778911537135272, 37.642802392812932],
              [126.778252297320194, 37.64318698097491],
              [126.777453728283035, 37.6436528409766],
              [126.777297590198046, 37.643743926202127],
              [126.777272778145985, 37.643758400921421],
              [126.777261292038062, 37.643765103119108],
              [126.777096028929563, 37.643861552746245],
              [126.776947358963241, 37.643948277581678],
              [126.776882842328163, 37.643985905485039],
              [126.776796397419957, 37.644036331154915],
              [126.776657033323929, 37.644117630801048],
              [126.776502719687272, 37.644207631935814],
              [126.776405946228337, 37.644264081528362],
              [126.776352721359856, 37.644295137425985],
              [126.776292064072976, 37.644330510122671],
              [126.776766192519091, 37.644727392836899],
              [126.776897415945484, 37.644837230666077],
              [126.777051010814063, 37.644965790792355],
              [126.777378630008073, 37.645240045410617],
              [126.777536004369608, 37.645371786301311],
              [126.777844930311502, 37.645630388080811],
              [126.777863161626016, 37.64564564917098],
              [126.778382996665073, 37.646080796039669],
              [126.778958611357169, 37.646562627738085],
              [126.779312281326369, 37.646858669819729],
              [126.779626868879021, 37.647121995612522],
              [126.780197483335883, 37.64759962298367],
              [126.780859373410294, 37.648153640701196],
              [126.781038871969386, 37.648303672588916],
              [126.781040302041163, 37.648304899897113],
              [126.781082564716655, 37.648341148303508],
              [126.782109080355056, 37.647545500861973],
              [126.782224918017818, 37.647455718919389],
              [126.782505048434103, 37.647238586502866],
              [126.7825342706154, 37.6472159354472],
              [126.782766787683883, 37.647035707827243],
              [126.782825546910118, 37.646990162329459],
              [126.782851045804449, 37.646970393532072],
              [126.784532773242162, 37.645666812106512],
              [126.784550140592245, 37.645654823438733],
              [126.785270484952207, 37.645157544077748],
              [126.786273925208931, 37.644387729374408],
              [126.786363889316746, 37.644318710136609],
              [126.787260678043637, 37.643630688696788],
              [126.787277248864868, 37.643617977351155],
              [126.78791279669268, 37.643130365011963],
              [126.788014590402028, 37.643053257981691],
              [126.788169976240965, 37.642935553999024],
              [126.788194095586192, 37.642917281814789],
              [126.788522542112347, 37.642668470664326]
            ]
          ]
        ]
      },
      id: '31103610'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '일산1동',
        ADM_CD: '31104510'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.77195268256186, 37.68745662592908],
              [126.771691089704618, 37.687308129794722],
              [126.771662012392412, 37.687291621004469],
              [126.77164332753307, 37.687281016945796],
              [126.771625714199317, 37.687271019781292],
              [126.771611042424865, 37.687262693803198],
              [126.77159516829289, 37.687253687110861],
              [126.771371627478004, 37.687128195336996],
              [126.771353992851189, 37.687118295342898],
              [126.771336215959508, 37.687108330473393],
              [126.771222784546353, 37.687044057659257],
              [126.771210803900232, 37.687037271492414],
              [126.771053488872468, 37.686943429913946],
              [126.770789340234586, 37.686785855919865],
              [126.770615606635914, 37.686682218538955],
              [126.770596622911427, 37.686670894114478],
              [126.770586514263528, 37.686664864854293],
              [126.770578825640385, 37.686656893797654],
              [126.770483620025075, 37.686558258438026],
              [126.770396626517083, 37.686468129087352],
              [126.770307665404502, 37.686385299000783],
              [126.770295543048519, 37.686374011575907],
              [126.770289339256408, 37.686368222769431],
              [126.77028042898722, 37.686359927770638],
              [126.77027921743273, 37.686358799032135],
              [126.770269316915076, 37.686349592078798],
              [126.770226888822862, 37.686310076158861],
              [126.770194205279921, 37.686267908459577],
              [126.770174417332058, 37.686242376898569],
              [126.770148621060585, 37.686167040890481],
              [126.770077891122369, 37.685960473144767],
              [126.770040145869942, 37.685777850286996],
              [126.770025978524515, 37.685709310974801],
              [126.77003239989817, 37.685617675706062],
              [126.770035693764029, 37.685570660732182],
              [126.770047185185589, 37.685406766504009],
              [126.770044498189279, 37.685343722392993],
              [126.770040760455998, 37.685256200047654],
              [126.770039631733809, 37.685229726710851],
              [126.770003394057866, 37.685149175887737],
              [126.769970798461799, 37.685113372883549],
              [126.769968740314127, 37.685111143797492],
              [126.769967007908562, 37.68510923669642],
              [126.769965423618018, 37.685107491848427],
              [126.769913931089505, 37.685050987505861],
              [126.769814286073668, 37.684926546364466],
              [126.769534436714835, 37.68457706248347],
              [126.769513835326265, 37.684544572988294],
              [126.769511780347571, 37.684541329929125],
              [126.769511135863297, 37.68454032006413],
              [126.769511008517, 37.684540121886279],
              [126.769509881802975, 37.684538325787329],
              [126.769507315012277, 37.68453430622538],
              [126.769159338640719, 37.683985335735564],
              [126.769155318053294, 37.683978994369497],
              [126.769146271787136, 37.683964720887772],
              [126.769073443722519, 37.683849827140328],
              [126.768992835084191, 37.683728312693134],
              [126.768832825575828, 37.683487101869183],
              [126.768827410975703, 37.683478937491799],
              [126.768825735737721, 37.683476412422714],
              [126.768814749953037, 37.68345986097146],
              [126.768809258231911, 37.683451581647432],
              [126.768766972868704, 37.68338783679782],
              [126.768754803711957, 37.683369490685912],
              [126.768657837242174, 37.683223315408846],
              [126.768564530051322, 37.683082653586972],
              [126.768481366933017, 37.68311171005859],
              [126.768467460627718, 37.683092537446569],
              [126.768463460941561, 37.683089275094808],
              [126.768437839902518, 37.683051690654196],
              [126.768280606011047, 37.682834886903898],
              [126.768251258490764, 37.682794418510667],
              [126.768250956509206, 37.682793988509914],
              [126.767602932853819, 37.683064379808613],
              [126.767337932329667, 37.683174950753326],
              [126.767022991024305, 37.683306358371418],
              [126.766619937503876, 37.683474530052827],
              [126.766543571758334, 37.683506391769903],
              [126.766541442243494, 37.6835072789438],
              [126.766538558792362, 37.683508486804946],
              [126.766495184174374, 37.683526640903743],
              [126.766466210794434, 37.68353876911079],
              [126.76642402275273, 37.683556428528753],
              [126.766330515790628, 37.683595558977558],
              [126.76602703586741, 37.683722570978986],
              [126.766009086347069, 37.683731199358782],
              [126.765988363201799, 37.683741166958335],
              [126.765906706137059, 37.683780408678977],
              [126.765906006451686, 37.683780747726281],
              [126.765887692304872, 37.683789546870997],
              [126.765851173354847, 37.68380709625994],
              [126.765776794186507, 37.683842841010822],
              [126.765734063731088, 37.683863373814773],
              [126.765504581999934, 37.683973657009595],
              [126.765497665177321, 37.683976978555272],
              [126.765492427942419, 37.683979498472759],
              [126.765480567968353, 37.683985189443341],
              [126.765118862931601, 37.684151168197516],
              [126.765105642785528, 37.684157235517375],
              [126.765069125629012, 37.684173991511457],
              [126.765029368374968, 37.684192232504302],
              [126.764824116334538, 37.684286411871],
              [126.764765384276572, 37.684313359300759],
              [126.764756014835896, 37.684317659666711],
              [126.76432183662979, 37.684516897035365],
              [126.764320430782789, 37.684517539017783],
              [126.76431809302629, 37.684518606017249],
              [126.764315534079728, 37.684519791512301],
              [126.764274403668225, 37.68453865730919],
              [126.764167804722533, 37.684601049422405],
              [126.763829572727062, 37.684799012453411],
              [126.763821830636815, 37.684803542825087],
              [126.763506192305286, 37.684988290206022],
              [126.763344477991666, 37.685109727972431],
              [126.763235561304796, 37.685191520972296],
              [126.762962702912162, 37.685396430761969],
              [126.762946765675437, 37.685411138026545],
              [126.762927303081696, 37.685429100451337],
              [126.762883341737137, 37.685469666250889],
              [126.76283381061431, 37.685515387012806],
              [126.762829799959675, 37.685519088896932],
              [126.762823474919969, 37.685524928663689],
              [126.762640406261298, 37.685693870277639],
              [126.762599193927528, 37.685731898818268],
              [126.762507090347341, 37.685816892292969],
              [126.762418539665646, 37.685689233545439],
              [126.762416894831446, 37.685689954227506],
              [126.762224059617779, 37.685934716157085],
              [126.762100090968502, 37.686058347788375],
              [126.761994371750504, 37.686181835718088],
              [126.761855345355215, 37.686368862663784],
              [126.76186597118614, 37.686378705782481],
              [126.761350361241171, 37.687116000956436],
              [126.761296603460195, 37.687192923473972],
              [126.761265801772637, 37.687252505991474],
              [126.761258365340083, 37.687266885014857],
              [126.761247433312278, 37.68728802005726],
              [126.761233401151316, 37.687315146572665],
              [126.761342808692731, 37.687278969791286],
              [126.76141335995672, 37.687278449080985],
              [126.761498947937909, 37.687277816094571],
              [126.76163894458962, 37.68727678104046],
              [126.761632210955696, 37.68729658231603],
              [126.761624063800639, 37.687320537031162],
              [126.761597406850655, 37.687395532902876],
              [126.76159465860313, 37.687404357632531],
              [126.761543872249277, 37.687547291630175],
              [126.761510909854351, 37.687640059856228],
              [126.761376401983355, 37.687991471757066],
              [126.761346608731003, 37.688069306900196],
              [126.761277819277851, 37.688376937729771],
              [126.761256978318272, 37.688463301320638],
              [126.761238291155138, 37.688540743627172],
              [126.761183974619655, 37.688917606998373],
              [126.761620921436261, 37.688999550749472],
              [126.76178181903876, 37.68902972387739],
              [126.761963650189173, 37.689063822355038],
              [126.762017382430471, 37.689073898282984],
              [126.762154484109303, 37.689099584836164],
              [126.762176138454407, 37.689103669003011],
              [126.762185869776616, 37.689105504586372],
              [126.762265639180356, 37.689120442782261],
              [126.762303917406442, 37.68912761609225],
              [126.762919758249069, 37.689243038571711],
              [126.763557034737403, 37.68936491446167],
              [126.763704178093249, 37.689393054027278],
              [126.763708709702613, 37.689393921449785],
              [126.764296532575585, 37.689509780112438],
              [126.76447076678096, 37.689541828209229],
              [126.764559954715509, 37.689558224251805],
              [126.764591415830822, 37.689564008635593],
              [126.764636052828209, 37.689572215901258],
              [126.764737531380902, 37.689590872348724],
              [126.764801273709537, 37.689602899489607],
              [126.765013281514186, 37.689642899374448],
              [126.765466648505978, 37.689728434668368],
              [126.766146859465579, 37.689856766891353],
              [126.766257849273941, 37.689877706578805],
              [126.766614319231607, 37.689944958746771],
              [126.766859795111415, 37.689991268992202],
              [126.767121035820054, 37.690020618347098],
              [126.767176744892708, 37.690040988261245],
              [126.767177920235042, 37.690041418283883],
              [126.767256767776914, 37.690070253827642],
              [126.767435550379247, 37.690135634049383],
              [126.767521645733083, 37.69016711925299],
              [126.767573297793803, 37.69018600724857],
              [126.767587634784391, 37.690191250938653],
              [126.767615300771553, 37.690201360709835],
              [126.767653634068466, 37.690215384453637],
              [126.76775222006188, 37.690254196367981],
              [126.767930565951161, 37.690324400592317],
              [126.768002892555131, 37.690352871581958],
              [126.768164356840956, 37.690416429789451],
              [126.768203117169008, 37.690431687206676],
              [126.768647456541771, 37.6906065957374],
              [126.76909354477263, 37.69078219032756],
              [126.769102170050502, 37.690785586780322],
              [126.769410545875459, 37.690898274333748],
              [126.769536477342555, 37.690944382707613],
              [126.769696599657678, 37.691006143193455],
              [126.769715139887893, 37.691013287399706],
              [126.769768282631119, 37.691033763679449],
              [126.769807691685259, 37.691048976817726],
              [126.769838266258375, 37.691060840018984],
              [126.770070980640682, 37.691150582948318],
              [126.770138912185061, 37.691176783845322],
              [126.770237447415823, 37.691214788494371],
              [126.770379451940897, 37.691269574190265],
              [126.770665618848724, 37.691379963294018],
              [126.770689411205979, 37.691389028094335],
              [126.770934215662706, 37.691482306062625],
              [126.771416205607494, 37.691665955321504],
              [126.771478336423016, 37.691689961850777],
              [126.771517445454776, 37.691705074321519],
              [126.771573728033019, 37.691726898631529],
              [126.771604528887835, 37.691738760970942],
              [126.771706337926943, 37.691778151918029],
              [126.771821278158953, 37.691822523434432],
              [126.771829112186495, 37.69182555157056],
              [126.771855944283672, 37.691835921989878],
              [126.771943809117261, 37.69186988090599],
              [126.772063623247902, 37.691916244449672],
              [126.772105492051764, 37.691943198162797],
              [126.772262592494656, 37.691993122933333],
              [126.772421587784521, 37.692054608684849],
              [126.772487609230964, 37.692080054660735],
              [126.772541740778507, 37.692101061262456],
              [126.772580925004817, 37.692116183759602],
              [126.772584886611668, 37.692117722413236],
              [126.772616315536865, 37.692129996660675],
              [126.772707757168305, 37.692165713011946],
              [126.772783601380212, 37.692195333925881],
              [126.773296171449644, 37.692395516727395],
              [126.773381264322168, 37.692414603759076],
              [126.77339744522628, 37.692417530000128],
              [126.773451747642611, 37.692427353674958],
              [126.773485980779569, 37.692429040052666],
              [126.773522709691065, 37.6924309139483],
              [126.773574745837465, 37.692433537390201],
              [126.773872619709778, 37.692351403363539],
              [126.774004818723455, 37.692314899144719],
              [126.774170263815407, 37.692269268968779],
              [126.774402647100445, 37.692209892554608],
              [126.774465689229004, 37.692193715117448],
              [126.774466419237385, 37.692193527628106],
              [126.774505452098936, 37.692183602708432],
              [126.774562865857902, 37.692168846220589],
              [126.775033507263956, 37.692055688633083],
              [126.775094311763127, 37.6920393447785],
              [126.775034129149873, 37.691948010087017],
              [126.77500394320478, 37.691906360227264],
              [126.774866590551611, 37.691752871911895],
              [126.774831210433206, 37.691708149016314],
              [126.774830375697675, 37.691707094747763],
              [126.774788633832955, 37.691654324497861],
              [126.774787735232522, 37.691653189618862],
              [126.774739730173508, 37.691592504206405],
              [126.774734799146671, 37.691586270100203],
              [126.774683833225964, 37.69151318773968],
              [126.77463017501411, 37.691436235656987],
              [126.774585715421892, 37.691338915348133],
              [126.774573373677839, 37.69131192784306],
              [126.774571810121742, 37.691308495916246],
              [126.774537085570202, 37.691232496688777],
              [126.774524878356772, 37.691189841476842],
              [126.774474138872179, 37.691012544505909],
              [126.774390005356494, 37.690691207734147],
              [126.774389410458042, 37.690688938157436],
              [126.774388054585117, 37.690683749933719],
              [126.774382877760928, 37.690663989106127],
              [126.774363124302084, 37.69058856054383],
              [126.774356908650603, 37.690564832025046],
              [126.774343956266492, 37.690524990629754],
              [126.774328444605558, 37.690477286599574],
              [126.774287092931999, 37.690350134505223],
              [126.774284898239813, 37.690343383638414],
              [126.774259216900973, 37.690264416949212],
              [126.77425091044509, 37.690238879227067],
              [126.774242243151861, 37.690212194608229],
              [126.774208828702655, 37.69013510733275],
              [126.774159624083921, 37.690021578964199],
              [126.774157000538153, 37.69001554110239],
              [126.774135803677311, 37.689966637545304],
              [126.774120247456878, 37.689930744096166],
              [126.774056123564122, 37.689782800708819],
              [126.773962324368128, 37.689541360102425],
              [126.773959339377896, 37.689393361333707],
              [126.773958805629206, 37.689367604653334],
              [126.773947852325563, 37.6893554279904],
              [126.773945488060164, 37.689352814028886],
              [126.773914114046477, 37.689318137281425],
              [126.773853784214523, 37.689251458445113],
              [126.773813454732974, 37.689187001917006],
              [126.773646820150745, 37.688938040738293],
              [126.773644123750486, 37.688934015061555],
              [126.773606740805832, 37.688878207643555],
              [126.773595570712587, 37.688861525719567],
              [126.773231071622845, 37.688488044459852],
              [126.773218674278567, 37.688475335162153],
              [126.773164968477744, 37.688425132352123],
              [126.773158952096679, 37.688419507990872],
              [126.772956977423419, 37.688230683040238],
              [126.772794312855723, 37.688078593682931],
              [126.772683683760206, 37.687986541062344],
              [126.772673385203916, 37.687977972694334],
              [126.772636040893147, 37.687946899209237],
              [126.77262764928804, 37.687939916303023],
              [126.77241429753191, 37.687762411117369],
              [126.772354954410545, 37.687717473814132],
              [126.772338408978555, 37.687704952440519],
              [126.772294591218085, 37.687671777584498],
              [126.772256598622718, 37.687643010060043],
              [126.772141388580465, 37.687563744519764],
              [126.772073052982606, 37.687524955351961],
              [126.77195268256186, 37.68745662592908]
            ]
          ]
        ]
      },
      id: '31104510'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '일산2동',
        ADM_CD: '31104520'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.781305511763534, 37.699171502688046],
              [126.781382826017577, 37.699090119798079],
              [126.781424695350225, 37.699096866256298],
              [126.781784487901859, 37.699154837308491],
              [126.781794328564175, 37.699148818758914],
              [126.781825725516825, 37.699129597403548],
              [126.781824364663763, 37.699128619296602],
              [126.781806827599809, 37.699116047251366],
              [126.781762134114445, 37.699083980120278],
              [126.781581508813687, 37.698998665983041],
              [126.781567940404869, 37.698992256059149],
              [126.781342211902128, 37.698885550655369],
              [126.781128035638147, 37.698784303330562],
              [126.780933845718963, 37.698692583657845],
              [126.780770246743202, 37.698646689411284],
              [126.780491131249235, 37.69851480377902],
              [126.780388734362305, 37.698440914356844],
              [126.780318942087689, 37.698406071566815],
              [126.78010070050523, 37.698297121039474],
              [126.779925647338686, 37.698213812276279],
              [126.779862969499931, 37.698167296427854],
              [126.779795664402457, 37.698113201039426],
              [126.77974445024266, 37.698067976432526],
              [126.77973165541357, 37.698056679569021],
              [126.779671053079582, 37.697997912960901],
              [126.779613859928375, 37.697936991367506],
              [126.779560303105171, 37.697874002708083],
              [126.779510491755815, 37.697809037786271],
              [126.779464428038963, 37.697742458949818],
              [126.779439668846621, 37.697702291825443],
              [126.77942233851924, 37.697674179258442],
              [126.779384334973869, 37.69760436884718],
              [126.779234715401685, 37.697311016251625],
              [126.778995988361871, 37.696842953283706],
              [126.778921972083879, 37.69669846099243],
              [126.778850005575222, 37.696557971324957],
              [126.778700694412109, 37.6962665811729],
              [126.778675049516096, 37.696216535421783],
              [126.778649977773483, 37.696107922794887],
              [126.778521692883487, 37.695857570070579],
              [126.778472702067006, 37.695790332086474],
              [126.778418484818189, 37.695715923656351],
              [126.778296289905811, 37.695477656769555],
              [126.778266879140133, 37.695416787376438],
              [126.778240644319609, 37.69535511000494],
              [126.778217476239107, 37.695292539264763],
              [126.778206337812577, 37.695257527865621],
              [126.778197370019498, 37.695229343720676],
              [126.778180553631145, 37.695165524769763],
              [126.778084237039366, 37.69476344637399],
              [126.77796823670981, 37.694279200311605],
              [126.777968373866955, 37.694268565590932],
              [126.777970012394718, 37.69414135629242],
              [126.777927796257387, 37.69396495667641],
              [126.777881759773976, 37.693774440625496],
              [126.777831372477834, 37.693565916596043],
              [126.777789269276155, 37.693389515805229],
              [126.777724234497299, 37.693261813398827],
              [126.777639809653991, 37.692907665600941],
              [126.777629642303623, 37.692744210425559],
              [126.777566381008, 37.692478575529577],
              [126.77751305858385, 37.692376574652961],
              [126.777307452261411, 37.691514219793966],
              [126.777163167344511, 37.690909046038563],
              [126.777147184267434, 37.690833062398625],
              [126.777135281031192, 37.690756725211344],
              [126.777127462999829, 37.690679947984201],
              [126.777123840950892, 37.690603001792731],
              [126.777124299776929, 37.690526058984979],
              [126.777128840538211, 37.690449125876526],
              [126.777137689755833, 37.690372467941984],
              [126.777178815451236, 37.690080544780379],
              [126.777194762842129, 37.69001786654443],
              [126.777219726618583, 37.689919717168387],
              [126.777255803104396, 37.689777875940784],
              [126.777258268817988, 37.689760467649101],
              [126.777284877786499, 37.689572600193223],
              [126.777275379833313, 37.689485668315214],
              [126.777269871377527, 37.689435263420094],
              [126.777327123643389, 37.689029442814117],
              [126.777348184816759, 37.688880147537247],
              [126.777391470316175, 37.688775266661693],
              [126.777414703351099, 37.688610530650053],
              [126.777429350783748, 37.688506669419297],
              [126.777423948153384, 37.688342953982101],
              [126.777453133886809, 37.688136056444698],
              [126.777506661295703, 37.687756580938327],
              [126.777529722002043, 37.687699101759151],
              [126.777559322791589, 37.687625319913209],
              [126.777584572945287, 37.687446882611603],
              [126.777612356587582, 37.687249864789884],
              [126.777641565167173, 37.68704273025687],
              [126.777649454118745, 37.686986980578148],
              [126.777666816011688, 37.686864295626783],
              [126.777663225966705, 37.686831130305116],
              [126.777658873830759, 37.686790942639114],
              [126.777652039573084, 37.686726961149361],
              [126.77768269903757, 37.68650771542611],
              [126.777768826835299, 37.685891801425214],
              [126.777774237912951, 37.68585343112462],
              [126.777781450400028, 37.685802271309917],
              [126.777801609425637, 37.685659145281974],
              [126.777844890940059, 37.685554174917833],
              [126.777882656530508, 37.685285488338558],
              [126.7778770253435, 37.685121860661823],
              [126.777904711696067, 37.684924871835527],
              [126.777909033624169, 37.684894129935429],
              [126.777914790573533, 37.684853174049174],
              [126.777922269833326, 37.684741018109683],
              [126.777930808780638, 37.684672803506018],
              [126.777931561128099, 37.684666793603363],
              [126.777933145472588, 37.68465392794613],
              [126.777935975636737, 37.684630944486479],
              [126.777948542964893, 37.684529972630308],
              [126.777944654805921, 37.684524046078046],
              [126.777951615282703, 37.684505289861292],
              [126.777954216560005, 37.684484389752676],
              [126.777995104617915, 37.684156608216114],
              [126.77800950866397, 37.684041401322297],
              [126.778031725017698, 37.683862600930247],
              [126.778053537843917, 37.683687042810888],
              [126.778083276395122, 37.683448342534128],
              [126.778115453707599, 37.683189916449372],
              [126.778140895064809, 37.682985353480468],
              [126.778138623514579, 37.682979380045566],
              [126.778149220240678, 37.682886491735445],
              [126.778154440605675, 37.682876649724179],
              [126.778171789886173, 37.682737632603136],
              [126.778198042559183, 37.682527138474441],
              [126.778226388630515, 37.682298617214236],
              [126.778231034208829, 37.682261595965855],
              [126.778242197756583, 37.682171983799229],
              [126.778243926812436, 37.682158099624147],
              [126.778254243975681, 37.682075075434462],
              [126.778249428567889, 37.682071806617593],
              [126.778261722282721, 37.681970420201871],
              [126.778267767365804, 37.681966280478285],
              [126.778270179354919, 37.681946871781527],
              [126.778298055264344, 37.681723753723674],
              [126.778323381719929, 37.681519819980956],
              [126.778356600778011, 37.681253649658558],
              [126.778359659934338, 37.68122877390109],
              [126.77836066573829, 37.681220591557398],
              [126.778365709272123, 37.681180405490728],
              [126.778366127016042, 37.681177085778927],
              [126.778349715825144, 37.681130381668765],
              [126.778354754418245, 37.681037324099528],
              [126.778357551564056, 37.680974171252622],
              [126.778358761051365, 37.680911014097788],
              [126.778359064400433, 37.680847858608047],
              [126.778358920332394, 37.680787808133751],
              [126.778358911880744, 37.680784700328125],
              [126.778357174852715, 37.680721447627548],
              [126.778354530838115, 37.680658284916291],
              [126.77835037087425, 37.680607150066812],
              [126.778349391949632, 37.680595115950908],
              [126.778343119265529, 37.680532037389334],
              [126.778331740123946, 37.680432999129415],
              [126.778285597281524, 37.680282720751123],
              [126.778284392101824, 37.680278239219213],
              [126.778269004601654, 37.680221062600708],
              [126.778251959208603, 37.680159313347403],
              [126.778235008241467, 37.680101983845091],
              [126.778233780567575, 37.680097834762272],
              [126.778213217650205, 37.680036527253399],
              [126.778190159079529, 37.679976118405122],
              [126.778164834890433, 37.679916158963586],
              [126.778138939769548, 37.679856372680419],
              [126.778111683713291, 37.679796949404825],
              [126.778091742059758, 37.679756394082055],
              [126.778082727103381, 37.679738061913191],
              [126.77805195225227, 37.679679801418565],
              [126.778039560471356, 37.679657688263596],
              [126.778032896364849, 37.679645797801363],
              [126.778019593663188, 37.679622076519159],
              [126.777985869928017, 37.67956479932112],
              [126.777950782660497, 37.679508154604065],
              [126.777914791581665, 37.679451863969405],
              [126.777877434454965, 37.679396114764714],
              [126.777875781151252, 37.679393761206896],
              [126.777724149951325, 37.679177880223328],
              [126.777667413841556, 37.679095544974004],
              [126.777615232671096, 37.679019822648463],
              [126.777605524565033, 37.679005747641476],
              [126.777624705436494, 37.678997587399138],
              [126.777571660058342, 37.678920363567187],
              [126.777507471257564, 37.678779228173902],
              [126.777375966270995, 37.678836282769645],
              [126.777306273121269, 37.678867127946432],
              [126.77730593189456, 37.678866648155307],
              [126.777294363691226, 37.678871664320823],
              [126.776059360643387, 37.679407308563299],
              [126.775138859722233, 37.679806531308664],
              [126.775022536834925, 37.679856980657313],
              [126.774927427386004, 37.679898223535119],
              [126.774906824291236, 37.679907157838628],
              [126.77445586438337, 37.68010273073876],
              [126.774256505731358, 37.68018919175784],
              [126.774211622923303, 37.680208657170844],
              [126.774182420080606, 37.680221318559731],
              [126.774167295911013, 37.680227876321524],
              [126.774102477142407, 37.680255987038876],
              [126.773453899595637, 37.680537257478107],
              [126.77261763181049, 37.680899915158371],
              [126.772612668388447, 37.680902081963936],
              [126.772587493236941, 37.68091299850429],
              [126.772541635813951, 37.68093288539125],
              [126.772535349126684, 37.680935612764188],
              [126.771750431169323, 37.681275998394199],
              [126.771639012398154, 37.681324315994964],
              [126.77099810171994, 37.681602245606236],
              [126.770580409647948, 37.681783375352431],
              [126.769766038729841, 37.68178282951137],
              [126.770459795097509, 37.68149903878124],
              [126.770429569391496, 37.681453911969399],
              [126.770281169724853, 37.681232356025419],
              [126.770183920426049, 37.681330783196671],
              [126.769766992803056, 37.681507811988979],
              [126.76973461625613, 37.681521558536367],
              [126.769638250763549, 37.68152996415759],
              [126.769500993550437, 37.681588705710233],
              [126.76864291307534, 37.681955923707797],
              [126.768805033789008, 37.682172992547521],
              [126.768669324067687, 37.682230330344311],
              [126.768395539829385, 37.682346003878123],
              [126.76831179318512, 37.682767279517336],
              [126.768250956509206, 37.682793988509914],
              [126.768251258490764, 37.682794418510667],
              [126.768280606011047, 37.682834886903898],
              [126.768437839902518, 37.683051690654196],
              [126.768463460941561, 37.683089275094808],
              [126.768467460627718, 37.683092537446569],
              [126.768481366933017, 37.68311171005859],
              [126.768564530051322, 37.683082653586972],
              [126.768657837242174, 37.683223315408846],
              [126.768754803711957, 37.683369490685912],
              [126.768766972868704, 37.68338783679782],
              [126.768809258231911, 37.683451581647432],
              [126.768814749953037, 37.68345986097146],
              [126.768825735737721, 37.683476412422714],
              [126.768827410975703, 37.683478937491799],
              [126.768832825575828, 37.683487101869183],
              [126.768992835084191, 37.683728312693134],
              [126.769073443722519, 37.683849827140328],
              [126.769146271787136, 37.683964720887772],
              [126.769155318053294, 37.683978994369497],
              [126.769159338640719, 37.683985335735564],
              [126.769507315012277, 37.68453430622538],
              [126.769509881802975, 37.684538325787329],
              [126.769511008517, 37.684540121886279],
              [126.769511135863297, 37.68454032006413],
              [126.769511780347571, 37.684541329929125],
              [126.769513835326265, 37.684544572988294],
              [126.769534436714835, 37.68457706248347],
              [126.769814286073668, 37.684926546364466],
              [126.769913931089505, 37.685050987505861],
              [126.769965423618018, 37.685107491848427],
              [126.769967007908562, 37.68510923669642],
              [126.769968740314127, 37.685111143797492],
              [126.769970798461799, 37.685113372883549],
              [126.770003394057866, 37.685149175887737],
              [126.770039631733809, 37.685229726710851],
              [126.770040760455998, 37.685256200047654],
              [126.770044498189279, 37.685343722392993],
              [126.770047185185589, 37.685406766504009],
              [126.770035693764029, 37.685570660732182],
              [126.77003239989817, 37.685617675706062],
              [126.770025978524515, 37.685709310974801],
              [126.770040145869942, 37.685777850286996],
              [126.770077891122369, 37.685960473144767],
              [126.770148621060585, 37.686167040890481],
              [126.770174417332058, 37.686242376898569],
              [126.770194205279921, 37.686267908459577],
              [126.770226888822862, 37.686310076158861],
              [126.770269316915076, 37.686349592078798],
              [126.77027921743273, 37.686358799032135],
              [126.77028042898722, 37.686359927770638],
              [126.770289339256408, 37.686368222769431],
              [126.770295543048519, 37.686374011575907],
              [126.770307665404502, 37.686385299000783],
              [126.770396626517083, 37.686468129087352],
              [126.770483620025075, 37.686558258438026],
              [126.770578825640385, 37.686656893797654],
              [126.770586514263528, 37.686664864854293],
              [126.770596622911427, 37.686670894114478],
              [126.770615606635914, 37.686682218538955],
              [126.770789340234586, 37.686785855919865],
              [126.771053488872468, 37.686943429913946],
              [126.771210803900232, 37.687037271492414],
              [126.771222784546353, 37.687044057659257],
              [126.771336215959508, 37.687108330473393],
              [126.771353992851189, 37.687118295342898],
              [126.771371627478004, 37.687128195336996],
              [126.77159516829289, 37.687253687110861],
              [126.771611042424865, 37.687262693803198],
              [126.771625714199317, 37.687271019781292],
              [126.77164332753307, 37.687281016945796],
              [126.771662012392412, 37.687291621004469],
              [126.771691089704618, 37.687308129794722],
              [126.77195268256186, 37.68745662592908],
              [126.772073052982606, 37.687524955351961],
              [126.772141388580465, 37.687563744519764],
              [126.772256598622718, 37.687643010060043],
              [126.772294591218085, 37.687671777584498],
              [126.772338408978555, 37.687704952440519],
              [126.772354954410545, 37.687717473814132],
              [126.77241429753191, 37.687762411117369],
              [126.77262764928804, 37.687939916303023],
              [126.772636040893147, 37.687946899209237],
              [126.772673385203916, 37.687977972694334],
              [126.772683683760206, 37.687986541062344],
              [126.772794312855723, 37.688078593682931],
              [126.772956977423419, 37.688230683040238],
              [126.773158952096679, 37.688419507990872],
              [126.773164968477744, 37.688425132352123],
              [126.773218674278567, 37.688475335162153],
              [126.773231071622845, 37.688488044459852],
              [126.773595570712587, 37.688861525719567],
              [126.773606740805832, 37.688878207643555],
              [126.773644123750486, 37.688934015061555],
              [126.773646820150745, 37.688938040738293],
              [126.773813454732974, 37.689187001917006],
              [126.773853784214523, 37.689251458445113],
              [126.773914114046477, 37.689318137281425],
              [126.773945488060164, 37.689352814028886],
              [126.773947852325563, 37.6893554279904],
              [126.773958805629206, 37.689367604653334],
              [126.773959339377896, 37.689393361333707],
              [126.773962324368128, 37.689541360102425],
              [126.774056123564122, 37.689782800708819],
              [126.774120247456878, 37.689930744096166],
              [126.774135803677311, 37.689966637545304],
              [126.774157000538153, 37.69001554110239],
              [126.774159624083921, 37.690021578964199],
              [126.774208828702655, 37.69013510733275],
              [126.774242243151861, 37.690212194608229],
              [126.77425091044509, 37.690238879227067],
              [126.774259216900973, 37.690264416949212],
              [126.774284898239813, 37.690343383638414],
              [126.774287092931999, 37.690350134505223],
              [126.774328444605558, 37.690477286599574],
              [126.774343956266492, 37.690524990629754],
              [126.774356908650603, 37.690564832025046],
              [126.774363124302084, 37.69058856054383],
              [126.774382877760928, 37.690663989106127],
              [126.774388054585117, 37.690683749933719],
              [126.774389410458042, 37.690688938157436],
              [126.774390005356494, 37.690691207734147],
              [126.774474138872179, 37.691012544505909],
              [126.774524878356772, 37.691189841476842],
              [126.774537085570202, 37.691232496688777],
              [126.774571810121742, 37.691308495916246],
              [126.774573373677839, 37.69131192784306],
              [126.774585715421892, 37.691338915348133],
              [126.77463017501411, 37.691436235656987],
              [126.774683833225964, 37.69151318773968],
              [126.774734799146671, 37.691586270100203],
              [126.774739730173508, 37.691592504206405],
              [126.774787735232522, 37.691653189618862],
              [126.774788633832955, 37.691654324497861],
              [126.774830375697675, 37.691707094747763],
              [126.774831210433206, 37.691708149016314],
              [126.774866590551611, 37.691752871911895],
              [126.77500394320478, 37.691906360227264],
              [126.775034129149873, 37.691948010087017],
              [126.775094311763127, 37.6920393447785],
              [126.775033507263956, 37.692055688633083],
              [126.775092515670565, 37.692125738670761],
              [126.775163876207387, 37.692210454254393],
              [126.775342915221287, 37.692431266438525],
              [126.775395633365648, 37.692496338439319],
              [126.775488175307459, 37.692610565171172],
              [126.775632573097468, 37.692918215233263],
              [126.775842892412825, 37.69336631145665],
              [126.775990339019373, 37.693680448541443],
              [126.776018774551915, 37.693741034014394],
              [126.776062823115879, 37.693835000131394],
              [126.776088039792327, 37.693894061524077],
              [126.776149712354822, 37.693972202290631],
              [126.776189131982576, 37.694014508806227],
              [126.776310904742274, 37.694145199324296],
              [126.77633245674437, 37.694168331440778],
              [126.776635347127652, 37.694435262305014],
              [126.777076143618373, 37.6948237263001],
              [126.777275646814616, 37.694999541858024],
              [126.777369220896901, 37.695082014556931],
              [126.777590236036488, 37.695276771928434],
              [126.777663672482433, 37.695341493031215],
              [126.777775059022147, 37.695439999017097],
              [126.777783386750585, 37.695449142524289],
              [126.777847611879807, 37.695519602571864],
              [126.777938108710586, 37.69562430411689],
              [126.777947610683569, 37.695635297097873],
              [126.778023351729701, 37.695786440587831],
              [126.778124351215084, 37.695987810505073],
              [126.778460572404015, 37.69665814367832],
              [126.778661226901022, 37.697058174059244],
              [126.778750608495471, 37.697236383268425],
              [126.778838708595842, 37.697412030232776],
              [126.77888605946751, 37.697506432768357],
              [126.779102813525981, 37.697938549501508],
              [126.779115803283673, 37.697958989695131],
              [126.779173914533985, 37.698050403326931],
              [126.779258681759757, 37.69814156075293],
              [126.779260660096568, 37.698143897344977],
              [126.779325120853599, 37.698220160698],
              [126.779376795694603, 37.6982586752831],
              [126.779383094560316, 37.698263370889123],
              [126.779393286725167, 37.698270968197015],
              [126.779430564096799, 37.698298761919723],
              [126.77943608699978, 37.698302880443975],
              [126.779468465474437, 37.698327014609418],
              [126.779729554697866, 37.698494454999661],
              [126.779764864929675, 37.698517135139696],
              [126.779800516073323, 37.698539637087805],
              [126.779836850620072, 37.698561148143831],
              [126.779864582982384, 37.698576968006257],
              [126.779901940898625, 37.698597758829919],
              [126.779939300120972, 37.698617830397858],
              [126.779977226337778, 37.698637452954557],
              [126.780016061253122, 37.698656716013801],
              [126.780054672278084, 37.69867543870501],
              [126.780354967988671, 37.698818589519419],
              [126.780406213865163, 37.698843021544974],
              [126.780528762295248, 37.698901480764945],
              [126.781164098200748, 37.699204312107462],
              [126.7811810165162, 37.699222921109637],
              [126.7811998037145, 37.699243582086382],
              [126.781218009457348, 37.699263608592922],
              [126.781305511763534, 37.699171502688046]
            ]
          ]
        ]
      },
      id: '31104520'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '탄현2동',
        ADM_CD: '31104512'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.776062824250062, 37.69383500013835],
              [126.776014147569967, 37.69388653251653],
              [126.775928048904262, 37.693900063530805],
              [126.775642917369169, 37.693892129297247],
              [126.775392976233022, 37.693870116613084],
              [126.774558080499673, 37.693741567462133],
              [126.774164680417542, 37.693655848514283],
              [126.773962087743513, 37.693611759823021],
              [126.773696469279358, 37.693563807116732],
              [126.773643183715365, 37.693554187205287],
              [126.773107549150325, 37.69345748472891],
              [126.772726268453852, 37.693376289113466],
              [126.772690977322085, 37.693370236310209],
              [126.772671540368535, 37.693366902321443],
              [126.772550019294343, 37.693726242978315],
              [126.772354750638684, 37.694213106628133],
              [126.772323118571748, 37.694286294069322],
              [126.772287294358165, 37.694358301942067],
              [126.772247392925479, 37.694428859655027],
              [126.772206832163661, 37.694492479441756],
              [126.7721602564271, 37.694558519981619],
              [126.772155473016753, 37.694565267468761],
              [126.772103795170594, 37.694630848365982],
              [126.772064652344682, 37.694675640201822],
              [126.772048381103886, 37.694694438596322],
              [126.771791987593076, 37.694962607437553],
              [126.771513235060553, 37.695237309127101],
              [126.770472787730583, 37.695979023865931],
              [126.770389377116544, 37.696038413890314],
              [126.770259831112099, 37.696130779685618],
              [126.770185277762081, 37.696186134195997],
              [126.770113779150847, 37.696244015934838],
              [126.770045333616224, 37.696304247337686],
              [126.769980167790393, 37.696366736075852],
              [126.769918399117486, 37.696431304420891],
              [126.769860138773666, 37.696497951263879],
              [126.769853705236457, 37.696506023288137],
              [126.769805500811785, 37.696566497953967],
              [126.769796733381213, 37.696578102208264],
              [126.769677581078909, 37.696682201976493],
              [126.769194732983991, 37.697315172148826],
              [126.769155404428034, 37.697418885483458],
              [126.769008757538558, 37.69761203557637],
              [126.768925441424628, 37.697640703707584],
              [126.768854459068407, 37.697858871889174],
              [126.768899618454995, 37.697921397800236],
              [126.768804229840143, 37.698152582861368],
              [126.768564053868801, 37.698734672953037],
              [126.768199002287687, 37.699619607114222],
              [126.768105880541697, 37.699845567138844],
              [126.768061286224821, 37.699782861482028],
              [126.767432144974038, 37.699666089635308],
              [126.766598947340569, 37.699511439299116],
              [126.766140318035553, 37.699426309924426],
              [126.765988419890206, 37.699483577823472],
              [126.765982971698662, 37.699485639470574],
              [126.765950843319487, 37.699497737937094],
              [126.76591625360264, 37.699438609794861],
              [126.76589259169377, 37.699398172696093],
              [126.76588229538298, 37.699380556338753],
              [126.765768477932326, 37.699185996837656],
              [126.765763274180117, 37.699163966883411],
              [126.765678666220623, 37.699116532743233],
              [126.765662509060775, 37.699062441409282],
              [126.765647044420504, 37.699040607125518],
              [126.765578143631529, 37.699099213378055],
              [126.761776800733784, 37.699092535384096],
              [126.7614670215345, 37.699091985345788],
              [126.761465400837565, 37.699123514984478],
              [126.761340405331623, 37.699203984852616],
              [126.76134078233018, 37.699215948593469],
              [126.761353750558442, 37.699626324681404],
              [126.761358737408045, 37.699784064767805],
              [126.761366348280234, 37.700024892969672],
              [126.761370445282438, 37.700154564967747],
              [126.761341550867812, 37.7004230980843],
              [126.761322283280137, 37.700602156474901],
              [126.761312040017742, 37.700843691189938],
              [126.761295942735217, 37.701223253827365],
              [126.761294178896406, 37.701264850263634],
              [126.761293139677647, 37.701407290559345],
              [126.761292550948909, 37.701488104338992],
              [126.761291055048346, 37.701635410681135],
              [126.761292172525927, 37.701781368799097],
              [126.76129244877815, 37.70180074072708],
              [126.761292480003235, 37.701804567012104],
              [126.761293836507662, 37.701968504285901],
              [126.761293656646231, 37.701988685442004],
              [126.761293688134145, 37.70199351399269],
              [126.76129704910295, 37.702518999941979],
              [126.761297255790524, 37.702560355444632],
              [126.761297050635363, 37.70269441778953],
              [126.761298831097434, 37.702777800726025],
              [126.761301780261221, 37.702915794541781],
              [126.761302771790866, 37.703029407195096],
              [126.761303233159225, 37.703062474881882],
              [126.761291232206901, 37.703548611261489],
              [126.761285227907024, 37.70371604724825],
              [126.761281273174617, 37.703716063931488],
              [126.761281072801907, 37.703722536831869],
              [126.761277843663237, 37.703844439472654],
              [126.761308307977643, 37.703849854366275],
              [126.761363769094018, 37.70384658754007],
              [126.761444545550432, 37.703852952097989],
              [126.761970156345569, 37.703936512724589],
              [126.762134327777318, 37.703962748246134],
              [126.762416072578972, 37.704010058201526],
              [126.762548709100798, 37.704032009302949],
              [126.762727213685636, 37.704062777136386],
              [126.762803359051773, 37.704077005535503],
              [126.762889427577974, 37.704091541079983],
              [126.763037117702311, 37.704116057151168],
              [126.76313657971005, 37.704150602533034],
              [126.76338005927812, 37.704252732463893],
              [126.763465650634529, 37.704288465372784],
              [126.763694500936609, 37.704384008383016],
              [126.763774836817177, 37.704417675779752],
              [126.763804985386983, 37.704441291945869],
              [126.763819281469793, 37.704441974274346],
              [126.763821231537563, 37.704442204568664],
              [126.763823102982727, 37.704442699359085],
              [126.763824839239106, 37.704443442970046],
              [126.763826386053765, 37.704444415233873],
              [126.763827697203382, 37.704445587019812],
              [126.763828733359986, 37.704446920227042],
              [126.763829464279254, 37.70444837591014],
              [126.76382986544219, 37.704449909751474],
              [126.763829927094164, 37.704451475723062],
              [126.763829300594153, 37.704460337159809],
              [126.763903856021116, 37.70451873655275],
              [126.763943440858796, 37.704551756442839],
              [126.763990795888134, 37.704591268334966],
              [126.764087805602529, 37.704673388016161],
              [126.764118884480908, 37.704653187003075],
              [126.764131128783134, 37.70464729310207],
              [126.764167860729515, 37.704628789384749],
              [126.764518894501208, 37.704341465937006],
              [126.764614621534378, 37.704270986109265],
              [126.7647828796879, 37.704125764364292],
              [126.765109218427114, 37.703848291541313],
              [126.765111917148346, 37.703845999188751],
              [126.76511092844521, 37.70385399040849],
              [126.765088815495773, 37.704032623315761],
              [126.765200783310547, 37.704372683957338],
              [126.765213335014678, 37.704604986374989],
              [126.765133499831563, 37.70469125675298],
              [126.765065487593546, 37.704739834709869],
              [126.765041034806814, 37.70473950562991],
              [126.765031150271795, 37.704782840599357],
              [126.76500044070697, 37.704912014213335],
              [126.765113470327009, 37.705028239584337],
              [126.765259549850612, 37.70511412428997],
              [126.765344485251575, 37.705207768539246],
              [126.765356896555275, 37.705213802671878],
              [126.765447982441117, 37.705258089424667],
              [126.765518594727027, 37.705283574196486],
              [126.765641407216151, 37.705333652904393],
              [126.765740886624485, 37.705364537759181],
              [126.76584670790001, 37.705465549282415],
              [126.765867061378444, 37.705469526437746],
              [126.765885372189388, 37.705475067273674],
              [126.765966373467663, 37.70549957544192],
              [126.766011278418944, 37.705532323114276],
              [126.766087321533831, 37.705576120050388],
              [126.766142265491524, 37.705629439615258],
              [126.766214187572871, 37.70568955258674],
              [126.766227050356363, 37.705713785212502],
              [126.766241563867453, 37.705752505954493],
              [126.766303830620544, 37.705918630196685],
              [126.766314708268467, 37.705954132235746],
              [126.766276967740509, 37.706065261323253],
              [126.766237822312519, 37.706172742137781],
              [126.766224937611298, 37.706198820853885],
              [126.766188073003903, 37.706273437628305],
              [126.766090604179837, 37.706446116225599],
              [126.766065743111483, 37.706518420989021],
              [126.766051569310036, 37.706573857443765],
              [126.76602444036277, 37.706635465679348],
              [126.7660179973347, 37.706767218729624],
              [126.765984536024689, 37.706870204814081],
              [126.765991526013721, 37.706901204034359],
              [126.766077847888283, 37.707283988675194],
              [126.766638652639173, 37.707215311638151],
              [126.767032567931722, 37.707167070211916],
              [126.767193917599386, 37.707134453162311],
              [126.767207373218724, 37.707139551715464],
              [126.767221908830962, 37.707137048067956],
              [126.767232899713633, 37.707136224073331],
              [126.76726694185372, 37.707127561279627],
              [126.767311458408798, 37.707116950027199],
              [126.767711625933302, 37.706995842215846],
              [126.767871551044877, 37.70694744055001],
              [126.768834607588047, 37.706487033308093],
              [126.769737100806211, 37.705956116089567],
              [126.769749167257743, 37.705947976225687],
              [126.770372941097321, 37.705582345025618],
              [126.770747160950123, 37.705468763494217],
              [126.770966153203986, 37.705415981087619],
              [126.771462578987851, 37.70525984096156],
              [126.771525785445689, 37.705047958790004],
              [126.772066019549442, 37.704812580700548],
              [126.772442531160024, 37.704648533250285],
              [126.772683048486087, 37.704681939989257],
              [126.772745371408931, 37.704637858851832],
              [126.77277661013531, 37.704621027457222],
              [126.773418019426046, 37.703871367256639],
              [126.773429980553118, 37.703823854129915],
              [126.773436417512769, 37.703816620167302],
              [126.773599004512633, 37.703090340175414],
              [126.773601731204494, 37.703079456444357],
              [126.773686863189198, 37.702739820182863],
              [126.774393477941075, 37.70250179576913],
              [126.774741979456849, 37.701782145167783],
              [126.775155636393905, 37.701546951940216],
              [126.775465209508113, 37.701370936351196],
              [126.775492129870301, 37.701355626147368],
              [126.776151259766735, 37.700803244180015],
              [126.77683610668258, 37.700593273535326],
              [126.77711794467362, 37.700235136230873],
              [126.778232861274063, 37.699992730205494],
              [126.779049523802499, 37.700029095849786],
              [126.779333855032419, 37.700043192874801],
              [126.779740368709284, 37.699889352175937],
              [126.779812039786066, 37.699811012964261],
              [126.780844037567675, 37.699489554655599],
              [126.780974266372937, 37.699363338724517],
              [126.781123948714495, 37.699261680489201],
              [126.7811810165162, 37.699222921109637],
              [126.781164098200748, 37.699204312107462],
              [126.780528762295248, 37.698901480764945],
              [126.780406213865163, 37.698843021544974],
              [126.780354967988671, 37.698818589519419],
              [126.780054672278084, 37.69867543870501],
              [126.780016061253122, 37.698656716013801],
              [126.779977226337778, 37.698637452954557],
              [126.779939300120972, 37.698617830397858],
              [126.779901940898625, 37.698597758829919],
              [126.779864582982384, 37.698576968006257],
              [126.779836850620072, 37.698561148143831],
              [126.779800516073323, 37.698539637087805],
              [126.779764864929675, 37.698517135139696],
              [126.779729554697866, 37.698494454999661],
              [126.779468465474437, 37.698327014609418],
              [126.77943608699978, 37.698302880443975],
              [126.779430564096799, 37.698298761919723],
              [126.779393286725167, 37.698270968197015],
              [126.779383094560316, 37.698263370889123],
              [126.779376795694603, 37.6982586752831],
              [126.779325120853599, 37.698220160698],
              [126.779260660096568, 37.698143897344977],
              [126.779258681759757, 37.69814156075293],
              [126.779173914533985, 37.698050403326931],
              [126.779115803283673, 37.697958989695131],
              [126.779102813525981, 37.697938549501508],
              [126.77888605946751, 37.697506432768357],
              [126.778838708595842, 37.697412030232776],
              [126.778750608495471, 37.697236383268425],
              [126.778661226901022, 37.697058174059244],
              [126.778460572404015, 37.69665814367832],
              [126.778124351215084, 37.695987810505073],
              [126.778023351729701, 37.695786440587831],
              [126.777947610683569, 37.695635297097873],
              [126.777938108710586, 37.69562430411689],
              [126.777847611879807, 37.695519602571864],
              [126.777783386750585, 37.695449142524289],
              [126.777775059022147, 37.695439999017097],
              [126.777663672482433, 37.695341493031215],
              [126.777590236036488, 37.695276771928434],
              [126.777369220896901, 37.695082014556931],
              [126.777275646814616, 37.694999541858024],
              [126.777076143618373, 37.6948237263001],
              [126.776635347127652, 37.694435262305014],
              [126.77633245674437, 37.694168331440778],
              [126.776310904742274, 37.694145199324296],
              [126.776189131982576, 37.694014508806227],
              [126.776149712354822, 37.693972202290631],
              [126.776088039792327, 37.693894061524077],
              [126.776062824250062, 37.69383500013835]
            ]
          ]
        ]
      },
      id: '31104512'
    },
    {
      type: 'Feature',
      properties: {
        BASE_DATE: '20230701',
        ADM_NM: '주엽1동',
        ADM_CD: '31104550'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.769600190509905, 37.674530659461695],
              [126.770058645750794, 37.674330842934943],
              [126.770381234525615, 37.674190242805885],
              [126.770804466491697, 37.674005773062348],
              [126.770831221898533, 37.673994113146755],
              [126.771788742099034, 37.673576816431009],
              [126.771798539243193, 37.673572546974157],
              [126.772285683082416, 37.673360186598281],
              [126.773009842235837, 37.67304449595364],
              [126.773226410176079, 37.672950108531985],
              [126.773240141652551, 37.672944125450208],
              [126.773467048180791, 37.672845216266168],
              [126.773459034215662, 37.672833625474169],
              [126.773396271949991, 37.672742878622515],
              [126.773336237173154, 37.672656180153602],
              [126.773208768048775, 37.672527997394106],
              [126.772876867542138, 37.672047581432786],
              [126.772717963663624, 37.671817571287768],
              [126.772673501175987, 37.671753211319285],
              [126.772284898974874, 37.671190705799177],
              [126.772231922783973, 37.671114023434981],
              [126.772163355806697, 37.671014771274471],
              [126.772073962548703, 37.670855837494805],
              [126.772072495780407, 37.670853230832286],
              [126.77185990884719, 37.670945794857815],
              [126.771792090161668, 37.670847546667105],
              [126.771601999216841, 37.67057231239211],
              [126.771493342748713, 37.670414986132265],
              [126.7712888196817, 37.670118850672424],
              [126.771107127974346, 37.669855771329559],
              [126.771063715794625, 37.669792913429319],
              [126.770818524699195, 37.669437886674721],
              [126.77043054675093, 37.668876103458736],
              [126.770073218414382, 37.668358689635113],
              [126.769980078582478, 37.668223820682144],
              [126.769923242053807, 37.66814152156514],
              [126.769853048110249, 37.668043536941909],
              [126.769778994060729, 37.66794734904849],
              [126.769701306978604, 37.667853044007963],
              [126.769619874841212, 37.66776071485711],
              [126.769534807893777, 37.667670451501984],
              [126.76953018714228, 37.667665849575336],
              [126.769446335446233, 37.667582342783447],
              [126.76939451578653, 37.667533975015388],
              [126.769354342097586, 37.667496479012577],
              [126.769259056003236, 37.667412950825749],
              [126.769205117286859, 37.667368525748905],
              [126.769160587384519, 37.667331849931578],
              [126.769058825380853, 37.667253265767428],
              [126.768954107897144, 37.667177196813959],
              [126.768846548542811, 37.667103735702504],
              [126.768736035560394, 37.667033062898291],
              [126.768384516132073, 37.666815138847042],
              [126.767636609391317, 37.666351464604205],
              [126.767417697538477, 37.666215726968986],
              [126.767225532864202, 37.666096575394981],
              [126.766668131653958, 37.665751003368193],
              [126.76646889416655, 37.665627482042296],
              [126.766135654760859, 37.665420880131926],
              [126.765751962762323, 37.665182995578675],
              [126.765543244011994, 37.665053590222925],
              [126.765417735645016, 37.664975776198602],
              [126.765103377073913, 37.664780873659446],
              [126.765032484062999, 37.664736919684806],
              [126.764686033843475, 37.664522115853273],
              [126.764590143919165, 37.664462663463723],
              [126.764006700623028, 37.664100918018377],
              [126.763993920318555, 37.664092994059835],
              [126.763725400475678, 37.66392650505329],
              [126.763409644415646, 37.663730725812435],
              [126.763237249196763, 37.663623833819734],
              [126.762707766539975, 37.663295529986549],
              [126.762694587588697, 37.66328735913698],
              [126.762688825355923, 37.663293232188003],
              [126.762555294901162, 37.6634293407526],
              [126.762491734080172, 37.663494081921812],
              [126.7623708703929, 37.663614658565265],
              [126.762246726403077, 37.663733154139877],
              [126.762119415461598, 37.66384957114721],
              [126.762038417019156, 37.663920501416335],
              [126.76198905158941, 37.663963730026026],
              [126.761855521410681, 37.664075630056765],
              [126.76171916506398, 37.664185271555219],
              [126.761654579439536, 37.664234977195271],
              [126.761579754408444, 37.664292564753829],
              [126.761437518496578, 37.664397507470852],
              [126.761348408207922, 37.664460470145841],
              [126.761292570188061, 37.664499923743861],
              [126.761144795236504, 37.664599901176913],
              [126.760994421297738, 37.664697350152373],
              [126.760841449279908, 37.664792179632549],
              [126.760685879162736, 37.66488439231135],
              [126.760595795105473, 37.664935440935778],
              [126.760527935223735, 37.664973896748528],
              [126.760367622246235, 37.665060783096941],
              [126.76020504860135, 37.66514487176233],
              [126.760040218835101, 37.665226162764078],
              [126.759873242951386, 37.665304654980538],
              [126.759704121839832, 37.665380260078699],
              [126.759694889883818, 37.665384286926042],
              [126.759372940457666, 37.665524701685165],
              [126.759316425696781, 37.665549349473579],
              [126.759058169789753, 37.665661983493557],
              [126.758327158518995, 37.665980796888221],
              [126.758090258697393, 37.666084112696275],
              [126.75818546395881, 37.666222518229112],
              [126.758189888015494, 37.666221626705308],
              [126.758243809464759, 37.666299852654404],
              [126.758392670050185, 37.666515512444512],
              [126.758598316145182, 37.666813437528788],
              [126.758666108497422, 37.666911648953288],
              [126.759102395408831, 37.667543697748371],
              [126.759152661054742, 37.667616661826202],
              [126.759184070581682, 37.667662253281698],
              [126.759451442521737, 37.66804955833399],
              [126.759636530998023, 37.668317670164527],
              [126.759715707490486, 37.668432360993428],
              [126.759906693137282, 37.668709011571515],
              [126.76019140309873, 37.669121418253141],
              [126.760342666028762, 37.669340523731087],
              [126.760396258659284, 37.66941817066806],
              [126.760451644364181, 37.669498417418779],
              [126.760653476666235, 37.669790833991435],
              [126.760858014740862, 37.67008703829709],
              [126.761093367542884, 37.669984327453541],
              [126.761221285177157, 37.669928501455502],
              [126.761348229242415, 37.670124056798528],
              [126.761460506310868, 37.67029701776827],
              [126.761417164698514, 37.670315931890102],
              [126.761356691636422, 37.670342320623412],
              [126.761110513533779, 37.670449744773748],
              [126.761314829782449, 37.670746039716377],
              [126.761516440641373, 37.671038364162349],
              [126.761755243650782, 37.670934181038035],
              [126.762011215054031, 37.67082250671352],
              [126.762012073720115, 37.670822133531388],
              [126.762067023529951, 37.670901676462321],
              [126.762121221132077, 37.670980128568807],
              [126.762505031119986, 37.671535691722916],
              [126.762513546224, 37.671548018260538],
              [126.762722494371431, 37.671850467793263],
              [126.76282371872577, 37.671996986587232],
              [126.762939086378111, 37.672163976159403],
              [126.763116901204498, 37.672421355426316],
              [126.763155081967938, 37.672476631348282],
              [126.76355331971267, 37.673053186398683],
              [126.764035553176527, 37.672842876410364],
              [126.764050145246884, 37.672836513105111],
              [126.764364713480532, 37.673291836554917],
              [126.764585823005433, 37.673611877584264],
              [126.764623101071805, 37.673665837778785],
              [126.764603174508593, 37.673674523944463],
              [126.764457493006404, 37.673738031990943],
              [126.76429188233719, 37.673810227754657],
              [126.764126271358649, 37.673882422380309],
              [126.764289991322073, 37.674119346545659],
              [126.764358103442845, 37.674219781994957],
              [126.764424476635853, 37.674317650412064],
              [126.764588083963901, 37.674554573451928],
              [126.764753694876717, 37.674482378189879],
              [126.764919420893023, 37.674410092376981],
              [126.765079014558424, 37.674340518947794],
              [126.765085030035138, 37.674337896634064],
              [126.765646443123899, 37.675151124219454],
              [126.76594930351709, 37.675589820393796],
              [126.765969584601407, 37.675619197190045],
              [126.766178074803904, 37.675921194202346],
              [126.766232577097739, 37.676000139305778],
              [126.766234365158212, 37.675999358169534],
              [126.766305022763689, 37.675968527332415],
              [126.76667244580338, 37.675808194414657],
              [126.767435280583257, 37.675475313013052],
              [126.768419006236996, 37.675046028945921],
              [126.768535417586008, 37.674995027787631],
              [126.768667137519145, 37.674937319172244],
              [126.768963961756199, 37.674807953183922],
              [126.769269515312587, 37.674674781971298],
              [126.769600190509905, 37.674530659461695]
            ]
          ]
        ]
      },
      id: '31104550'
    }
  ]
};

export const cityDistrictData = {
  "type": "FeatureCollection",
  "name": "goyang_index2",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  "features": [{
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.705], [126.725, 37.705], [126.725, 37.71], [126.73, 37.71], [126.73, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.705], [126.73, 37.705], [126.73, 37.71], [126.735, 37.71], [126.735, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.705], [126.735, 37.705], [126.735, 37.71], [126.74, 37.71], [126.74, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.705], [126.74, 37.705], [126.74, 37.71], [126.745, 37.71], [126.745, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 9,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 10
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.705], [126.745, 37.705], [126.745, 37.71], [126.75, 37.71], [126.75, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.675, 37.7], [126.67, 37.7], [126.67, 37.705], [126.675, 37.705], [126.675, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.7], [126.71, 37.7], [126.71, 37.705], [126.715, 37.705], [126.715, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.7], [126.715, 37.7], [126.715, 37.705], [126.72, 37.705], [126.72, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.7], [126.72, 37.7], [126.72, 37.705], [126.725, 37.705], [126.725, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.7], [126.725, 37.7], [126.725, 37.705], [126.73, 37.705], [126.73, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.7], [126.73, 37.7], [126.73, 37.705], [126.735, 37.705], [126.735, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.7], [126.735, 37.7], [126.735, 37.705], [126.74, 37.705], [126.74, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.7], [126.74, 37.7], [126.74, 37.705], [126.745, 37.705], [126.745, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.7], [126.745, 37.7], [126.745, 37.705], [126.75, 37.705], [126.75, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.675, 37.695], [126.67, 37.695], [126.67, 37.7], [126.675, 37.7], [126.675, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.68, 37.695], [126.675, 37.695], [126.675, 37.7], [126.68, 37.7], [126.68, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.685, 37.695], [126.68, 37.695], [126.68, 37.7], [126.685, 37.7], [126.685, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.7, 37.695], [126.695, 37.695], [126.695, 37.7], [126.7, 37.7], [126.7, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.695], [126.7, 37.695], [126.7, 37.7], [126.705, 37.7], [126.705, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.695], [126.705, 37.695], [126.705, 37.7], [126.71, 37.7], [126.71, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.695], [126.71, 37.695], [126.71, 37.7], [126.715, 37.7], [126.715, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 9,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.695], [126.715, 37.695], [126.715, 37.7], [126.72, 37.7], [126.72, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.695], [126.72, 37.695], [126.72, 37.7], [126.725, 37.7], [126.725, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.695], [126.725, 37.695], [126.725, 37.7], [126.73, 37.7], [126.73, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.695], [126.73, 37.695], [126.73, 37.7], [126.735, 37.7], [126.735, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.695], [126.735, 37.695], [126.735, 37.7], [126.74, 37.7], [126.74, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 1,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.695], [126.74, 37.695], [126.74, 37.7], [126.745, 37.7], [126.745, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.695], [126.745, 37.695], [126.745, 37.7], [126.75, 37.7], [126.75, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.675, 37.69], [126.67, 37.69], [126.67, 37.695], [126.675, 37.695], [126.675, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.68, 37.69], [126.675, 37.69], [126.675, 37.695], [126.68, 37.695], [126.68, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.685, 37.69], [126.68, 37.69], [126.68, 37.695], [126.685, 37.695], [126.685, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.69, 37.69], [126.685, 37.69], [126.685, 37.695], [126.69, 37.695], [126.69, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.695, 37.69], [126.69, 37.69], [126.69, 37.695], [126.695, 37.695], [126.695, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.7, 37.69], [126.695, 37.69], [126.695, 37.695], [126.7, 37.695], [126.7, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.69], [126.7, 37.69], [126.7, 37.695], [126.705, 37.695], [126.705, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.69], [126.705, 37.69], [126.705, 37.695], [126.71, 37.695], [126.71, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.69], [126.71, 37.69], [126.71, 37.695], [126.715, 37.695], [126.715, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.69], [126.715, 37.69], [126.715, 37.695], [126.72, 37.695], [126.72, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 0,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.69], [126.72, 37.69], [126.72, 37.695], [126.725, 37.695], [126.725, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.69], [126.725, 37.69], [126.725, 37.695], [126.73, 37.695], [126.73, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.69], [126.73, 37.69], [126.73, 37.695], [126.735, 37.695], [126.735, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.69], [126.735, 37.69], [126.735, 37.695], [126.74, 37.695], [126.74, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 1,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.69], [126.74, 37.69], [126.74, 37.695], [126.745, 37.695], [126.745, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.69], [126.745, 37.69], [126.745, 37.695], [126.75, 37.695], [126.75, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.675, 37.685], [126.67, 37.685], [126.67, 37.69], [126.675, 37.69], [126.675, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 1,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.68, 37.685], [126.675, 37.685], [126.675, 37.69], [126.68, 37.69], [126.68, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.685, 37.685], [126.68, 37.685], [126.68, 37.69], [126.685, 37.69], [126.685, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.69, 37.685], [126.685, 37.685], [126.685, 37.69], [126.69, 37.69], [126.69, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.695, 37.685], [126.69, 37.685], [126.69, 37.69], [126.695, 37.69], [126.695, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.7, 37.685], [126.695, 37.685], [126.695, 37.69], [126.7, 37.69], [126.7, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.685], [126.7, 37.685], [126.7, 37.69], [126.705, 37.69], [126.705, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 2,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.685], [126.705, 37.685], [126.705, 37.69], [126.71, 37.69], [126.71, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.685], [126.71, 37.685], [126.71, 37.69], [126.715, 37.69], [126.715, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.685], [126.715, 37.685], [126.715, 37.69], [126.72, 37.69], [126.72, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.685], [126.72, 37.685], [126.72, 37.69], [126.725, 37.69], [126.725, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.685], [126.725, 37.685], [126.725, 37.69], [126.73, 37.69], [126.73, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.685], [126.73, 37.685], [126.73, 37.69], [126.735, 37.69], [126.735, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.685], [126.735, 37.685], [126.735, 37.69], [126.74, 37.69], [126.74, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.685], [126.74, 37.685], [126.74, 37.69], [126.745, 37.69], [126.745, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 1,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.685], [126.745, 37.685], [126.745, 37.69], [126.75, 37.69], [126.75, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.675, 37.68], [126.67, 37.68], [126.67, 37.685], [126.675, 37.685], [126.675, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 1,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.68, 37.68], [126.675, 37.68], [126.675, 37.685], [126.68, 37.685], [126.68, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.685, 37.68], [126.68, 37.68], [126.68, 37.685], [126.685, 37.685], [126.685, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.69, 37.68], [126.685, 37.68], [126.685, 37.685], [126.69, 37.685], [126.69, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.695, 37.68], [126.69, 37.68], [126.69, 37.685], [126.695, 37.685], [126.695, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.7, 37.68], [126.695, 37.68], [126.695, 37.685], [126.7, 37.685], [126.7, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.68], [126.7, 37.68], [126.7, 37.685], [126.705, 37.685], [126.705, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.68], [126.705, 37.68], [126.705, 37.685], [126.71, 37.685], [126.71, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.68], [126.71, 37.68], [126.71, 37.685], [126.715, 37.685], [126.715, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.68], [126.715, 37.68], [126.715, 37.685], [126.72, 37.685], [126.72, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.68], [126.72, 37.68], [126.72, 37.685], [126.725, 37.685], [126.725, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.68], [126.725, 37.68], [126.725, 37.685], [126.73, 37.685], [126.73, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.68], [126.73, 37.68], [126.73, 37.685], [126.735, 37.685], [126.735, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.68], [126.735, 37.68], [126.735, 37.685], [126.74, 37.685], [126.74, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.68], [126.74, 37.68], [126.74, 37.685], [126.745, 37.685], [126.745, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.68], [126.745, 37.68], [126.745, 37.685], [126.75, 37.685], [126.75, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.675, 37.675], [126.67, 37.675], [126.67, 37.68], [126.675, 37.68], [126.675, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.68, 37.675], [126.675, 37.675], [126.675, 37.68], [126.68, 37.68], [126.68, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.685, 37.675], [126.68, 37.675], [126.68, 37.68], [126.685, 37.68], [126.685, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.69, 37.675], [126.685, 37.675], [126.685, 37.68], [126.69, 37.68], [126.69, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.695, 37.675], [126.69, 37.675], [126.69, 37.68], [126.695, 37.68], [126.695, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.7, 37.675], [126.695, 37.675], [126.695, 37.68], [126.7, 37.68], [126.7, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.675], [126.7, 37.675], [126.7, 37.68], [126.705, 37.68], [126.705, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.675], [126.705, 37.675], [126.705, 37.68], [126.71, 37.68], [126.71, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.675], [126.71, 37.675], [126.71, 37.68], [126.715, 37.68], [126.715, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.675], [126.715, 37.675], [126.715, 37.68], [126.72, 37.68], [126.72, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.675], [126.72, 37.675], [126.72, 37.68], [126.725, 37.68], [126.725, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 1,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.675], [126.725, 37.675], [126.725, 37.68], [126.73, 37.68], [126.73, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.675], [126.73, 37.675], [126.73, 37.68], [126.735, 37.68], [126.735, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.675], [126.735, 37.675], [126.735, 37.68], [126.74, 37.68], [126.74, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 0,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.675], [126.74, 37.675], [126.74, 37.68], [126.745, 37.68], [126.745, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.675], [126.745, 37.675], [126.745, 37.68], [126.75, 37.68], [126.75, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.675, 37.67], [126.67, 37.67], [126.67, 37.675], [126.675, 37.675], [126.675, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.68, 37.67], [126.675, 37.67], [126.675, 37.675], [126.68, 37.675], [126.68, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.685, 37.67], [126.68, 37.67], [126.68, 37.675], [126.685, 37.675], [126.685, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.69, 37.67], [126.685, 37.67], [126.685, 37.675], [126.69, 37.675], [126.69, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.695, 37.67], [126.69, 37.67], [126.69, 37.675], [126.695, 37.675], [126.695, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.7, 37.67], [126.695, 37.67], [126.695, 37.675], [126.7, 37.675], [126.7, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.67], [126.7, 37.67], [126.7, 37.675], [126.705, 37.675], [126.705, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.67], [126.705, 37.67], [126.705, 37.675], [126.71, 37.675], [126.71, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.67], [126.71, 37.67], [126.71, 37.675], [126.715, 37.675], [126.715, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.67], [126.715, 37.67], [126.715, 37.675], [126.72, 37.675], [126.72, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.67], [126.72, 37.67], [126.72, 37.675], [126.725, 37.675], [126.725, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.685, 37.665], [126.68, 37.665], [126.68, 37.67], [126.685, 37.67], [126.685, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.69, 37.665], [126.685, 37.665], [126.685, 37.67], [126.69, 37.67], [126.69, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.695, 37.665], [126.69, 37.665], [126.69, 37.67], [126.695, 37.67], [126.695, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.7, 37.665], [126.695, 37.665], [126.695, 37.67], [126.7, 37.67], [126.7, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 2,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.665], [126.7, 37.665], [126.7, 37.67], [126.705, 37.67], [126.705, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.665], [126.705, 37.665], [126.705, 37.67], [126.71, 37.67], [126.71, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.665], [126.71, 37.665], [126.71, 37.67], [126.715, 37.67], [126.715, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.665], [126.715, 37.665], [126.715, 37.67], [126.72, 37.67], [126.72, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.665], [126.72, 37.665], [126.72, 37.67], [126.725, 37.67], [126.725, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.695, 37.66], [126.69, 37.66], [126.69, 37.665], [126.695, 37.665], [126.695, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.7, 37.66], [126.695, 37.66], [126.695, 37.665], [126.7, 37.665], [126.7, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.66], [126.7, 37.66], [126.7, 37.665], [126.705, 37.665], [126.705, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.66], [126.705, 37.66], [126.705, 37.665], [126.71, 37.665], [126.71, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.66], [126.71, 37.66], [126.71, 37.665], [126.715, 37.665], [126.715, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.66], [126.715, 37.66], [126.715, 37.665], [126.72, 37.665], [126.72, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.66], [126.72, 37.66], [126.72, 37.665], [126.725, 37.665], [126.725, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.705, 37.655], [126.7, 37.655], [126.7, 37.66], [126.705, 37.66], [126.705, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 9,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.71, 37.655], [126.705, 37.655], [126.705, 37.66], [126.71, 37.66], [126.71, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.655], [126.71, 37.655], [126.71, 37.66], [126.715, 37.66], [126.715, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.655], [126.715, 37.655], [126.715, 37.66], [126.72, 37.66], [126.72, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.655], [126.72, 37.655], [126.72, 37.66], [126.725, 37.66], [126.725, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.715, 37.65], [126.71, 37.65], [126.71, 37.655], [126.715, 37.655], [126.715, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.72, 37.65], [126.715, 37.65], [126.715, 37.655], [126.72, 37.655], [126.72, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.65], [126.72, 37.65], [126.72, 37.655], [126.725, 37.655], [126.725, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.575], [126.86, 37.57], [126.855, 37.57], [126.855, 37.575], [126.86, 37.575]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.575], [126.855, 37.57], [126.85, 37.57], [126.85, 37.575], [126.855, 37.575]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 0,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.575], [126.85, 37.57], [126.845, 37.57], [126.845, 37.575], [126.85, 37.575]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.58], [126.88, 37.575], [126.875, 37.575], [126.875, 37.58], [126.88, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.58], [126.875, 37.575], [126.87, 37.575], [126.87, 37.58], [126.875, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.58], [126.87, 37.575], [126.865, 37.575], [126.865, 37.58], [126.87, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.58], [126.865, 37.575], [126.86, 37.575], [126.86, 37.58], [126.865, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.58], [126.86, 37.575], [126.855, 37.575], [126.855, 37.58], [126.86, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.58], [126.855, 37.575], [126.85, 37.575], [126.85, 37.58], [126.855, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.58], [126.85, 37.575], [126.845, 37.575], [126.845, 37.58], [126.85, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.58], [126.845, 37.575], [126.84, 37.575], [126.84, 37.58], [126.845, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.585], [126.88, 37.58], [126.875, 37.58], [126.875, 37.585], [126.88, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 9,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.585], [126.875, 37.58], [126.87, 37.58], [126.87, 37.585], [126.875, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 1,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.585], [126.87, 37.58], [126.865, 37.58], [126.865, 37.585], [126.87, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.585], [126.865, 37.58], [126.86, 37.58], [126.86, 37.585], [126.865, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.585], [126.86, 37.58], [126.855, 37.58], [126.855, 37.585], [126.86, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.585], [126.855, 37.58], [126.85, 37.58], [126.85, 37.585], [126.855, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.585], [126.85, 37.58], [126.845, 37.58], [126.845, 37.585], [126.85, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.585], [126.845, 37.58], [126.84, 37.58], [126.84, 37.585], [126.845, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.585], [126.84, 37.58], [126.835, 37.58], [126.835, 37.585], [126.84, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.585], [126.835, 37.58], [126.83, 37.58], [126.83, 37.585], [126.835, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.59], [126.9, 37.585], [126.895, 37.585], [126.895, 37.59], [126.9, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.59], [126.895, 37.585], [126.89, 37.585], [126.89, 37.59], [126.895, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.59], [126.89, 37.585], [126.885, 37.585], [126.885, 37.59], [126.89, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.59], [126.885, 37.585], [126.88, 37.585], [126.88, 37.59], [126.885, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.59], [126.88, 37.585], [126.875, 37.585], [126.875, 37.59], [126.88, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.59], [126.875, 37.585], [126.87, 37.585], [126.87, 37.59], [126.875, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.59], [126.87, 37.585], [126.865, 37.585], [126.865, 37.59], [126.87, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.59], [126.865, 37.585], [126.86, 37.585], [126.86, 37.59], [126.865, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.59], [126.86, 37.585], [126.855, 37.585], [126.855, 37.59], [126.86, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.59], [126.855, 37.585], [126.85, 37.585], [126.85, 37.59], [126.855, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.59], [126.85, 37.585], [126.845, 37.585], [126.845, 37.59], [126.85, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.59], [126.845, 37.585], [126.84, 37.585], [126.84, 37.59], [126.845, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 9,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.59], [126.84, 37.585], [126.835, 37.585], [126.835, 37.59], [126.84, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.59], [126.835, 37.585], [126.83, 37.585], [126.83, 37.59], [126.835, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.59], [126.83, 37.585], [126.825, 37.585], [126.825, 37.59], [126.83, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.59], [126.825, 37.585], [126.82, 37.585], [126.82, 37.59], [126.825, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.595], [126.905, 37.59], [126.9, 37.59], [126.9, 37.595], [126.905, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.595], [126.9, 37.59], [126.895, 37.59], [126.895, 37.595], [126.9, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.595], [126.895, 37.59], [126.89, 37.59], [126.89, 37.595], [126.895, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 9,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.595], [126.89, 37.59], [126.885, 37.59], [126.885, 37.595], [126.89, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.595], [126.885, 37.59], [126.88, 37.59], [126.88, 37.595], [126.885, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.595], [126.88, 37.59], [126.875, 37.59], [126.875, 37.595], [126.88, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.595], [126.875, 37.59], [126.87, 37.59], [126.87, 37.595], [126.875, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.595], [126.87, 37.59], [126.865, 37.59], [126.865, 37.595], [126.87, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.595], [126.865, 37.59], [126.86, 37.59], [126.86, 37.595], [126.865, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.595], [126.86, 37.59], [126.855, 37.59], [126.855, 37.595], [126.86, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.595], [126.855, 37.59], [126.85, 37.59], [126.85, 37.595], [126.855, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.595], [126.85, 37.59], [126.845, 37.59], [126.845, 37.595], [126.85, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.595], [126.845, 37.59], [126.84, 37.59], [126.84, 37.595], [126.845, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.595], [126.84, 37.59], [126.835, 37.59], [126.835, 37.595], [126.84, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.595], [126.835, 37.59], [126.83, 37.59], [126.83, 37.595], [126.835, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.595], [126.83, 37.59], [126.825, 37.59], [126.825, 37.595], [126.83, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.595], [126.825, 37.59], [126.82, 37.59], [126.82, 37.595], [126.825, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.595], [126.82, 37.59], [126.815, 37.59], [126.815, 37.595], [126.82, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.6], [126.905, 37.595], [126.9, 37.595], [126.9, 37.6], [126.905, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.6], [126.9, 37.595], [126.895, 37.595], [126.895, 37.6], [126.9, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.6], [126.895, 37.595], [126.89, 37.595], [126.89, 37.6], [126.895, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.6], [126.89, 37.595], [126.885, 37.595], [126.885, 37.6], [126.89, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.6], [126.885, 37.595], [126.88, 37.595], [126.88, 37.6], [126.885, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.6], [126.88, 37.595], [126.875, 37.595], [126.875, 37.6], [126.88, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.6], [126.875, 37.595], [126.87, 37.595], [126.87, 37.6], [126.875, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.6], [126.87, 37.595], [126.865, 37.595], [126.865, 37.6], [126.87, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.6], [126.865, 37.595], [126.86, 37.595], [126.86, 37.6], [126.865, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.6], [126.86, 37.595], [126.855, 37.595], [126.855, 37.6], [126.86, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.6], [126.855, 37.595], [126.85, 37.595], [126.85, 37.6], [126.855, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 1,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.6], [126.85, 37.595], [126.845, 37.595], [126.845, 37.6], [126.85, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 1,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.6], [126.845, 37.595], [126.84, 37.595], [126.84, 37.6], [126.845, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.6], [126.84, 37.595], [126.835, 37.595], [126.835, 37.6], [126.84, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.6], [126.835, 37.595], [126.83, 37.595], [126.83, 37.6], [126.835, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.6], [126.83, 37.595], [126.825, 37.595], [126.825, 37.6], [126.83, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 9,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.6], [126.825, 37.595], [126.82, 37.595], [126.82, 37.6], [126.825, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 9,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.6], [126.82, 37.595], [126.815, 37.595], [126.815, 37.6], [126.82, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.6], [126.815, 37.595], [126.81, 37.595], [126.81, 37.6], [126.815, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.6], [126.81, 37.595], [126.805, 37.595], [126.805, 37.6], [126.81, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 9,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.605], [126.905, 37.6], [126.9, 37.6], [126.9, 37.605], [126.905, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.605], [126.9, 37.6], [126.895, 37.6], [126.895, 37.605], [126.9, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.605], [126.895, 37.6], [126.89, 37.6], [126.89, 37.605], [126.895, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.605], [126.89, 37.6], [126.885, 37.6], [126.885, 37.605], [126.89, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.605], [126.885, 37.6], [126.88, 37.6], [126.88, 37.605], [126.885, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.605], [126.88, 37.6], [126.875, 37.6], [126.875, 37.605], [126.88, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 2,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.605], [126.875, 37.6], [126.87, 37.6], [126.87, 37.605], [126.875, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.605], [126.87, 37.6], [126.865, 37.6], [126.865, 37.605], [126.87, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 1,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.605], [126.865, 37.6], [126.86, 37.6], [126.86, 37.605], [126.865, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.605], [126.86, 37.6], [126.855, 37.6], [126.855, 37.605], [126.86, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 9,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.605], [126.855, 37.6], [126.85, 37.6], [126.85, 37.605], [126.855, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.605], [126.85, 37.6], [126.845, 37.6], [126.845, 37.605], [126.85, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.605], [126.845, 37.6], [126.84, 37.6], [126.84, 37.605], [126.845, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.605], [126.84, 37.6], [126.835, 37.6], [126.835, 37.605], [126.84, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 0,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.605], [126.835, 37.6], [126.83, 37.6], [126.83, 37.605], [126.835, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.605], [126.83, 37.6], [126.825, 37.6], [126.825, 37.605], [126.83, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.605], [126.825, 37.6], [126.82, 37.6], [126.82, 37.605], [126.825, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.605], [126.82, 37.6], [126.815, 37.6], [126.815, 37.605], [126.82, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.605], [126.815, 37.6], [126.81, 37.6], [126.81, 37.605], [126.815, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.605], [126.81, 37.6], [126.805, 37.6], [126.805, 37.605], [126.81, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.605], [126.805, 37.6], [126.8, 37.6], [126.8, 37.605], [126.805, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.61], [126.905, 37.605], [126.9, 37.605], [126.9, 37.61], [126.905, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.61], [126.9, 37.605], [126.895, 37.605], [126.895, 37.61], [126.9, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.61], [126.895, 37.605], [126.89, 37.605], [126.89, 37.61], [126.895, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.61], [126.89, 37.605], [126.885, 37.605], [126.885, 37.61], [126.89, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.61], [126.885, 37.605], [126.88, 37.605], [126.88, 37.61], [126.885, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.61], [126.88, 37.605], [126.875, 37.605], [126.875, 37.61], [126.88, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.61], [126.875, 37.605], [126.87, 37.605], [126.87, 37.61], [126.875, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.61], [126.87, 37.605], [126.865, 37.605], [126.865, 37.61], [126.87, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.61], [126.865, 37.605], [126.86, 37.605], [126.86, 37.61], [126.865, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.61], [126.86, 37.605], [126.855, 37.605], [126.855, 37.61], [126.86, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.61], [126.855, 37.605], [126.85, 37.605], [126.85, 37.61], [126.855, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.61], [126.85, 37.605], [126.845, 37.605], [126.845, 37.61], [126.85, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.61], [126.845, 37.605], [126.84, 37.605], [126.84, 37.61], [126.845, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.61], [126.84, 37.605], [126.835, 37.605], [126.835, 37.61], [126.84, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.61], [126.835, 37.605], [126.83, 37.605], [126.83, 37.61], [126.835, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.61], [126.83, 37.605], [126.825, 37.605], [126.825, 37.61], [126.83, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.61], [126.825, 37.605], [126.82, 37.605], [126.82, 37.61], [126.825, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.61], [126.82, 37.605], [126.815, 37.605], [126.815, 37.61], [126.82, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.61], [126.815, 37.605], [126.81, 37.605], [126.81, 37.61], [126.815, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.61], [126.81, 37.605], [126.805, 37.605], [126.805, 37.61], [126.81, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.61], [126.805, 37.605], [126.8, 37.605], [126.8, 37.61], [126.805, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.61], [126.8, 37.605], [126.795, 37.605], [126.795, 37.61], [126.8, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.61], [126.795, 37.605], [126.79, 37.605], [126.79, 37.61], [126.795, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.615], [126.905, 37.61], [126.9, 37.61], [126.9, 37.615], [126.905, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.615], [126.9, 37.61], [126.895, 37.61], [126.895, 37.615], [126.9, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.615], [126.895, 37.61], [126.89, 37.61], [126.89, 37.615], [126.895, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 1,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.615], [126.89, 37.61], [126.885, 37.61], [126.885, 37.615], [126.89, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.615], [126.885, 37.61], [126.88, 37.61], [126.88, 37.615], [126.885, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.615], [126.88, 37.61], [126.875, 37.61], [126.875, 37.615], [126.88, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.615], [126.875, 37.61], [126.87, 37.61], [126.87, 37.615], [126.875, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.615], [126.87, 37.61], [126.865, 37.61], [126.865, 37.615], [126.87, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.615], [126.865, 37.61], [126.86, 37.61], [126.86, 37.615], [126.865, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.615], [126.86, 37.61], [126.855, 37.61], [126.855, 37.615], [126.86, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.615], [126.855, 37.61], [126.85, 37.61], [126.85, 37.615], [126.855, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.615], [126.85, 37.61], [126.845, 37.61], [126.845, 37.615], [126.85, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.615], [126.845, 37.61], [126.84, 37.61], [126.84, 37.615], [126.845, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.615], [126.84, 37.61], [126.835, 37.61], [126.835, 37.615], [126.84, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.615], [126.835, 37.61], [126.83, 37.61], [126.83, 37.615], [126.835, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.615], [126.83, 37.61], [126.825, 37.61], [126.825, 37.615], [126.83, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.615], [126.825, 37.61], [126.82, 37.61], [126.82, 37.615], [126.825, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.615], [126.82, 37.61], [126.815, 37.61], [126.815, 37.615], [126.82, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.615], [126.815, 37.61], [126.81, 37.61], [126.81, 37.615], [126.815, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.615], [126.81, 37.61], [126.805, 37.61], [126.805, 37.615], [126.81, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.615], [126.805, 37.61], [126.8, 37.61], [126.8, 37.615], [126.805, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.615], [126.8, 37.61], [126.795, 37.61], [126.795, 37.615], [126.8, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.615], [126.795, 37.61], [126.79, 37.61], [126.79, 37.615], [126.795, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.615], [126.79, 37.61], [126.785, 37.61], [126.785, 37.615], [126.79, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.62], [126.91, 37.615], [126.905, 37.615], [126.905, 37.62], [126.91, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.62], [126.905, 37.615], [126.9, 37.615], [126.9, 37.62], [126.905, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.62], [126.9, 37.615], [126.895, 37.615], [126.895, 37.62], [126.9, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 1,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.62], [126.895, 37.615], [126.89, 37.615], [126.89, 37.62], [126.895, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.62], [126.89, 37.615], [126.885, 37.615], [126.885, 37.62], [126.89, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.62], [126.885, 37.615], [126.88, 37.615], [126.88, 37.62], [126.885, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.62], [126.88, 37.615], [126.875, 37.615], [126.875, 37.62], [126.88, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.62], [126.875, 37.615], [126.87, 37.615], [126.87, 37.62], [126.875, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 9,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.62], [126.87, 37.615], [126.865, 37.615], [126.865, 37.62], [126.87, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.62], [126.865, 37.615], [126.86, 37.615], [126.86, 37.62], [126.865, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 0,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.62], [126.86, 37.615], [126.855, 37.615], [126.855, 37.62], [126.86, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.62], [126.855, 37.615], [126.85, 37.615], [126.85, 37.62], [126.855, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.62], [126.85, 37.615], [126.845, 37.615], [126.845, 37.62], [126.85, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.62], [126.845, 37.615], [126.84, 37.615], [126.84, 37.62], [126.845, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.62], [126.84, 37.615], [126.835, 37.615], [126.835, 37.62], [126.84, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.62], [126.835, 37.615], [126.83, 37.615], [126.83, 37.62], [126.835, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.62], [126.83, 37.615], [126.825, 37.615], [126.825, 37.62], [126.83, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.62], [126.825, 37.615], [126.82, 37.615], [126.82, 37.62], [126.825, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.62], [126.82, 37.615], [126.815, 37.615], [126.815, 37.62], [126.82, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.62], [126.815, 37.615], [126.81, 37.615], [126.81, 37.62], [126.815, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.62], [126.81, 37.615], [126.805, 37.615], [126.805, 37.62], [126.81, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.62], [126.805, 37.615], [126.8, 37.615], [126.8, 37.62], [126.805, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.62], [126.8, 37.615], [126.795, 37.615], [126.795, 37.62], [126.8, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.62], [126.795, 37.615], [126.79, 37.615], [126.79, 37.62], [126.795, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.62], [126.79, 37.615], [126.785, 37.615], [126.785, 37.62], [126.79, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.62], [126.785, 37.615], [126.78, 37.615], [126.78, 37.62], [126.785, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.62], [126.78, 37.615], [126.775, 37.615], [126.775, 37.62], [126.78, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.625], [126.91, 37.62], [126.905, 37.62], [126.905, 37.625], [126.91, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.625], [126.905, 37.62], [126.9, 37.62], [126.9, 37.625], [126.905, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.625], [126.9, 37.62], [126.895, 37.62], [126.895, 37.625], [126.9, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.625], [126.895, 37.62], [126.89, 37.62], [126.89, 37.625], [126.895, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.625], [126.89, 37.62], [126.885, 37.62], [126.885, 37.625], [126.89, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.625], [126.885, 37.62], [126.88, 37.62], [126.88, 37.625], [126.885, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 8,
      "ITEM3": 8,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.625], [126.88, 37.62], [126.875, 37.62], [126.875, 37.625], [126.88, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.625], [126.875, 37.62], [126.87, 37.62], [126.87, 37.625], [126.875, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.625], [126.87, 37.62], [126.865, 37.62], [126.865, 37.625], [126.87, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.625], [126.865, 37.62], [126.86, 37.62], [126.86, 37.625], [126.865, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.625], [126.86, 37.62], [126.855, 37.62], [126.855, 37.625], [126.86, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.625], [126.855, 37.62], [126.85, 37.62], [126.85, 37.625], [126.855, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.625], [126.85, 37.62], [126.845, 37.62], [126.845, 37.625], [126.85, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 9,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.625], [126.845, 37.62], [126.84, 37.62], [126.84, 37.625], [126.845, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.625], [126.84, 37.62], [126.835, 37.62], [126.835, 37.625], [126.84, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.625], [126.835, 37.62], [126.83, 37.62], [126.83, 37.625], [126.835, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 8,
      "ITEM3": 9,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.625], [126.83, 37.62], [126.825, 37.62], [126.825, 37.625], [126.83, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.625], [126.825, 37.62], [126.82, 37.62], [126.82, 37.625], [126.825, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.625], [126.82, 37.62], [126.815, 37.62], [126.815, 37.625], [126.82, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.625], [126.815, 37.62], [126.81, 37.62], [126.81, 37.625], [126.815, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.625], [126.81, 37.62], [126.805, 37.62], [126.805, 37.625], [126.81, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.625], [126.805, 37.62], [126.8, 37.62], [126.8, 37.625], [126.805, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.625], [126.8, 37.62], [126.795, 37.62], [126.795, 37.625], [126.8, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.625], [126.795, 37.62], [126.79, 37.62], [126.79, 37.625], [126.795, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.625], [126.79, 37.62], [126.785, 37.62], [126.785, 37.625], [126.79, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.625], [126.785, 37.62], [126.78, 37.62], [126.78, 37.625], [126.785, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.625], [126.78, 37.62], [126.775, 37.62], [126.775, 37.625], [126.78, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.625], [126.775, 37.62], [126.77, 37.62], [126.77, 37.625], [126.775, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.625], [126.77, 37.62], [126.765, 37.62], [126.765, 37.625], [126.77, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.63], [126.965, 37.625], [126.96, 37.625], [126.96, 37.63], [126.965, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.63], [126.96, 37.625], [126.955, 37.625], [126.955, 37.63], [126.96, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.63], [126.91, 37.625], [126.905, 37.625], [126.905, 37.63], [126.91, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.63], [126.905, 37.625], [126.9, 37.625], [126.9, 37.63], [126.905, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.63], [126.9, 37.625], [126.895, 37.625], [126.895, 37.63], [126.9, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.63], [126.895, 37.625], [126.89, 37.625], [126.89, 37.63], [126.895, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.63], [126.89, 37.625], [126.885, 37.625], [126.885, 37.63], [126.89, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.63], [126.885, 37.625], [126.88, 37.625], [126.88, 37.63], [126.885, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.63], [126.88, 37.625], [126.875, 37.625], [126.875, 37.63], [126.88, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.63], [126.875, 37.625], [126.87, 37.625], [126.87, 37.63], [126.875, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.63], [126.87, 37.625], [126.865, 37.625], [126.865, 37.63], [126.87, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.63], [126.865, 37.625], [126.86, 37.625], [126.86, 37.63], [126.865, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.63], [126.86, 37.625], [126.855, 37.625], [126.855, 37.63], [126.86, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.63], [126.855, 37.625], [126.85, 37.625], [126.85, 37.63], [126.855, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.63], [126.85, 37.625], [126.845, 37.625], [126.845, 37.63], [126.85, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.63], [126.845, 37.625], [126.84, 37.625], [126.84, 37.63], [126.845, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.63], [126.84, 37.625], [126.835, 37.625], [126.835, 37.63], [126.84, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.63], [126.835, 37.625], [126.83, 37.625], [126.83, 37.63], [126.835, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.63], [126.83, 37.625], [126.825, 37.625], [126.825, 37.63], [126.83, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.63], [126.825, 37.625], [126.82, 37.625], [126.82, 37.63], [126.825, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.63], [126.82, 37.625], [126.815, 37.625], [126.815, 37.63], [126.82, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.63], [126.815, 37.625], [126.81, 37.625], [126.81, 37.63], [126.815, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.63], [126.81, 37.625], [126.805, 37.625], [126.805, 37.63], [126.81, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.63], [126.805, 37.625], [126.8, 37.625], [126.8, 37.63], [126.805, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.63], [126.8, 37.625], [126.795, 37.625], [126.795, 37.63], [126.8, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.63], [126.795, 37.625], [126.79, 37.625], [126.79, 37.63], [126.795, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.63], [126.79, 37.625], [126.785, 37.625], [126.785, 37.63], [126.79, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.63], [126.785, 37.625], [126.78, 37.625], [126.78, 37.63], [126.785, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.63], [126.78, 37.625], [126.775, 37.625], [126.775, 37.63], [126.78, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.63], [126.775, 37.625], [126.77, 37.625], [126.77, 37.63], [126.775, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.63], [126.77, 37.625], [126.765, 37.625], [126.765, 37.63], [126.77, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.63], [126.765, 37.625], [126.76, 37.625], [126.76, 37.63], [126.765, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.63], [126.76, 37.625], [126.755, 37.625], [126.755, 37.63], [126.76, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.635], [126.985, 37.63], [126.98, 37.63], [126.98, 37.635], [126.985, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.635], [126.98, 37.63], [126.975, 37.63], [126.975, 37.635], [126.98, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.635], [126.975, 37.63], [126.97, 37.63], [126.97, 37.635], [126.975, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.635], [126.97, 37.63], [126.965, 37.63], [126.965, 37.635], [126.97, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.635], [126.965, 37.63], [126.96, 37.63], [126.96, 37.635], [126.965, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.635], [126.91, 37.63], [126.905, 37.63], [126.905, 37.635], [126.91, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.635], [126.905, 37.63], [126.9, 37.63], [126.9, 37.635], [126.905, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.635], [126.9, 37.63], [126.895, 37.63], [126.895, 37.635], [126.9, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.635], [126.895, 37.63], [126.89, 37.63], [126.89, 37.635], [126.895, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.635], [126.89, 37.63], [126.885, 37.63], [126.885, 37.635], [126.89, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.635], [126.885, 37.63], [126.88, 37.63], [126.88, 37.635], [126.885, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.635], [126.88, 37.63], [126.875, 37.63], [126.875, 37.635], [126.88, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.635], [126.875, 37.63], [126.87, 37.63], [126.87, 37.635], [126.875, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.635], [126.87, 37.63], [126.865, 37.63], [126.865, 37.635], [126.87, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.635], [126.865, 37.63], [126.86, 37.63], [126.86, 37.635], [126.865, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.635], [126.86, 37.63], [126.855, 37.63], [126.855, 37.635], [126.86, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.635], [126.855, 37.63], [126.85, 37.63], [126.85, 37.635], [126.855, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.635], [126.85, 37.63], [126.845, 37.63], [126.845, 37.635], [126.85, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.635], [126.845, 37.63], [126.84, 37.63], [126.84, 37.635], [126.845, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.635], [126.84, 37.63], [126.835, 37.63], [126.835, 37.635], [126.84, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.635], [126.835, 37.63], [126.83, 37.63], [126.83, 37.635], [126.835, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.635], [126.83, 37.63], [126.825, 37.63], [126.825, 37.635], [126.83, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.635], [126.825, 37.63], [126.82, 37.63], [126.82, 37.635], [126.825, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.635], [126.82, 37.63], [126.815, 37.63], [126.815, 37.635], [126.82, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 3,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.635], [126.815, 37.63], [126.81, 37.63], [126.81, 37.635], [126.815, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.635], [126.81, 37.63], [126.805, 37.63], [126.805, 37.635], [126.81, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.635], [126.805, 37.63], [126.8, 37.63], [126.8, 37.635], [126.805, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.635], [126.8, 37.63], [126.795, 37.63], [126.795, 37.635], [126.8, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.635], [126.795, 37.63], [126.79, 37.63], [126.79, 37.635], [126.795, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.635], [126.79, 37.63], [126.785, 37.63], [126.785, 37.635], [126.79, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.635], [126.785, 37.63], [126.78, 37.63], [126.78, 37.635], [126.785, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.635], [126.78, 37.63], [126.775, 37.63], [126.775, 37.635], [126.78, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.635], [126.775, 37.63], [126.77, 37.63], [126.77, 37.635], [126.775, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.635], [126.77, 37.63], [126.765, 37.63], [126.765, 37.635], [126.77, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.635], [126.765, 37.63], [126.76, 37.63], [126.76, 37.635], [126.765, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.635], [126.76, 37.63], [126.755, 37.63], [126.755, 37.635], [126.76, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.635], [126.755, 37.63], [126.75, 37.63], [126.75, 37.635], [126.755, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.635], [126.75, 37.63], [126.745, 37.63], [126.745, 37.635], [126.75, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.64], [126.99, 37.635], [126.985, 37.635], [126.985, 37.64], [126.99, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.64], [126.985, 37.635], [126.98, 37.635], [126.98, 37.64], [126.985, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.64], [126.98, 37.635], [126.975, 37.635], [126.975, 37.64], [126.98, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.64], [126.975, 37.635], [126.97, 37.635], [126.97, 37.64], [126.975, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.64], [126.97, 37.635], [126.965, 37.635], [126.965, 37.64], [126.97, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.64], [126.965, 37.635], [126.96, 37.635], [126.96, 37.64], [126.965, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.64], [126.915, 37.635], [126.91, 37.635], [126.91, 37.64], [126.915, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.64], [126.91, 37.635], [126.905, 37.635], [126.905, 37.64], [126.91, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.64], [126.905, 37.635], [126.9, 37.635], [126.9, 37.64], [126.905, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.64], [126.9, 37.635], [126.895, 37.635], [126.895, 37.64], [126.9, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.64], [126.895, 37.635], [126.89, 37.635], [126.89, 37.64], [126.895, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.64], [126.89, 37.635], [126.885, 37.635], [126.885, 37.64], [126.89, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.64], [126.885, 37.635], [126.88, 37.635], [126.88, 37.64], [126.885, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.64], [126.88, 37.635], [126.875, 37.635], [126.875, 37.64], [126.88, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.64], [126.875, 37.635], [126.87, 37.635], [126.87, 37.64], [126.875, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.64], [126.87, 37.635], [126.865, 37.635], [126.865, 37.64], [126.87, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.64], [126.865, 37.635], [126.86, 37.635], [126.86, 37.64], [126.865, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.64], [126.86, 37.635], [126.855, 37.635], [126.855, 37.64], [126.86, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.64], [126.855, 37.635], [126.85, 37.635], [126.85, 37.64], [126.855, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.64], [126.85, 37.635], [126.845, 37.635], [126.845, 37.64], [126.85, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.64], [126.845, 37.635], [126.84, 37.635], [126.84, 37.64], [126.845, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.64], [126.84, 37.635], [126.835, 37.635], [126.835, 37.64], [126.84, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.64], [126.835, 37.635], [126.83, 37.635], [126.83, 37.64], [126.835, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.64], [126.83, 37.635], [126.825, 37.635], [126.825, 37.64], [126.83, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 9,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.64], [126.825, 37.635], [126.82, 37.635], [126.82, 37.64], [126.825, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.64], [126.82, 37.635], [126.815, 37.635], [126.815, 37.64], [126.82, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.64], [126.815, 37.635], [126.81, 37.635], [126.81, 37.64], [126.815, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.64], [126.81, 37.635], [126.805, 37.635], [126.805, 37.64], [126.81, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.64], [126.805, 37.635], [126.8, 37.635], [126.8, 37.64], [126.805, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.64], [126.8, 37.635], [126.795, 37.635], [126.795, 37.64], [126.8, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.64], [126.795, 37.635], [126.79, 37.635], [126.79, 37.64], [126.795, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.64], [126.79, 37.635], [126.785, 37.635], [126.785, 37.64], [126.79, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.64], [126.785, 37.635], [126.78, 37.635], [126.78, 37.64], [126.785, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.64], [126.78, 37.635], [126.775, 37.635], [126.775, 37.64], [126.78, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.64], [126.775, 37.635], [126.77, 37.635], [126.77, 37.64], [126.775, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.64], [126.77, 37.635], [126.765, 37.635], [126.765, 37.64], [126.77, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.64], [126.765, 37.635], [126.76, 37.635], [126.76, 37.64], [126.765, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.64], [126.76, 37.635], [126.755, 37.635], [126.755, 37.64], [126.76, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.64], [126.755, 37.635], [126.75, 37.635], [126.75, 37.64], [126.755, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.64], [126.75, 37.635], [126.745, 37.635], [126.745, 37.64], [126.75, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.64], [126.745, 37.635], [126.74, 37.635], [126.74, 37.64], [126.745, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.645], [126.99, 37.64], [126.985, 37.64], [126.985, 37.645], [126.99, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.645], [126.985, 37.64], [126.98, 37.64], [126.98, 37.645], [126.985, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.645], [126.98, 37.64], [126.975, 37.64], [126.975, 37.645], [126.98, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.645], [126.975, 37.64], [126.97, 37.64], [126.97, 37.645], [126.975, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.645], [126.97, 37.64], [126.965, 37.64], [126.965, 37.645], [126.97, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.645], [126.965, 37.64], [126.96, 37.64], [126.96, 37.645], [126.965, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.645], [126.96, 37.64], [126.955, 37.64], [126.955, 37.645], [126.96, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.645], [126.915, 37.64], [126.91, 37.64], [126.91, 37.645], [126.915, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.645], [126.91, 37.64], [126.905, 37.64], [126.905, 37.645], [126.91, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.645], [126.905, 37.64], [126.9, 37.64], [126.9, 37.645], [126.905, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.645], [126.9, 37.64], [126.895, 37.64], [126.895, 37.645], [126.9, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 9,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.645], [126.895, 37.64], [126.89, 37.64], [126.89, 37.645], [126.895, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.645], [126.89, 37.64], [126.885, 37.64], [126.885, 37.645], [126.89, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 9,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.645], [126.885, 37.64], [126.88, 37.64], [126.88, 37.645], [126.885, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.645], [126.88, 37.64], [126.875, 37.64], [126.875, 37.645], [126.88, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.645], [126.875, 37.64], [126.87, 37.64], [126.87, 37.645], [126.875, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.645], [126.87, 37.64], [126.865, 37.64], [126.865, 37.645], [126.87, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.645], [126.865, 37.64], [126.86, 37.64], [126.86, 37.645], [126.865, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.645], [126.86, 37.64], [126.855, 37.64], [126.855, 37.645], [126.86, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.645], [126.855, 37.64], [126.85, 37.64], [126.85, 37.645], [126.855, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.645], [126.85, 37.64], [126.845, 37.64], [126.845, 37.645], [126.85, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.645], [126.845, 37.64], [126.84, 37.64], [126.84, 37.645], [126.845, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.645], [126.84, 37.64], [126.835, 37.64], [126.835, 37.645], [126.84, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.645], [126.835, 37.64], [126.83, 37.64], [126.83, 37.645], [126.835, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.645], [126.83, 37.64], [126.825, 37.64], [126.825, 37.645], [126.83, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 1,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.645], [126.825, 37.64], [126.82, 37.64], [126.82, 37.645], [126.825, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.645], [126.82, 37.64], [126.815, 37.64], [126.815, 37.645], [126.82, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.645], [126.815, 37.64], [126.81, 37.64], [126.81, 37.645], [126.815, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.645], [126.81, 37.64], [126.805, 37.64], [126.805, 37.645], [126.81, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.645], [126.805, 37.64], [126.8, 37.64], [126.8, 37.645], [126.805, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.645], [126.8, 37.64], [126.795, 37.64], [126.795, 37.645], [126.8, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.645], [126.795, 37.64], [126.79, 37.64], [126.79, 37.645], [126.795, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 2,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.645], [126.79, 37.64], [126.785, 37.64], [126.785, 37.645], [126.79, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.645], [126.785, 37.64], [126.78, 37.64], [126.78, 37.645], [126.785, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.645], [126.78, 37.64], [126.775, 37.64], [126.775, 37.645], [126.78, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.645], [126.775, 37.64], [126.77, 37.64], [126.77, 37.645], [126.775, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 8,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.645], [126.77, 37.64], [126.765, 37.64], [126.765, 37.645], [126.77, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.645], [126.765, 37.64], [126.76, 37.64], [126.76, 37.645], [126.765, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.645], [126.76, 37.64], [126.755, 37.64], [126.755, 37.645], [126.76, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.645], [126.755, 37.64], [126.75, 37.64], [126.75, 37.645], [126.755, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 0,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.645], [126.75, 37.64], [126.745, 37.64], [126.745, 37.645], [126.75, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.645], [126.745, 37.64], [126.74, 37.64], [126.74, 37.645], [126.745, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.645], [126.74, 37.64], [126.735, 37.64], [126.735, 37.645], [126.74, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 1,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.65], [126.99, 37.645], [126.985, 37.645], [126.985, 37.65], [126.99, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.65], [126.985, 37.645], [126.98, 37.645], [126.98, 37.65], [126.985, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.65], [126.98, 37.645], [126.975, 37.645], [126.975, 37.65], [126.98, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.65], [126.975, 37.645], [126.97, 37.645], [126.97, 37.65], [126.975, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.65], [126.97, 37.645], [126.965, 37.645], [126.965, 37.65], [126.97, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.65], [126.965, 37.645], [126.96, 37.645], [126.96, 37.65], [126.965, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.65], [126.96, 37.645], [126.955, 37.645], [126.955, 37.65], [126.96, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.65], [126.93, 37.645], [126.925, 37.645], [126.925, 37.65], [126.93, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.65], [126.925, 37.645], [126.92, 37.645], [126.92, 37.65], [126.925, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.65], [126.92, 37.645], [126.915, 37.645], [126.915, 37.65], [126.92, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.65], [126.915, 37.645], [126.91, 37.645], [126.91, 37.65], [126.915, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.65], [126.91, 37.645], [126.905, 37.645], [126.905, 37.65], [126.91, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.65], [126.905, 37.645], [126.9, 37.645], [126.9, 37.65], [126.905, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.65], [126.9, 37.645], [126.895, 37.645], [126.895, 37.65], [126.9, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.65], [126.895, 37.645], [126.89, 37.645], [126.89, 37.65], [126.895, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.65], [126.89, 37.645], [126.885, 37.645], [126.885, 37.65], [126.89, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.65], [126.885, 37.645], [126.88, 37.645], [126.88, 37.65], [126.885, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.65], [126.88, 37.645], [126.875, 37.645], [126.875, 37.65], [126.88, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 2,
      "ITEM3": 1,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.65], [126.875, 37.645], [126.87, 37.645], [126.87, 37.65], [126.875, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.65], [126.87, 37.645], [126.865, 37.645], [126.865, 37.65], [126.87, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.65], [126.865, 37.645], [126.86, 37.645], [126.86, 37.65], [126.865, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.65], [126.86, 37.645], [126.855, 37.645], [126.855, 37.65], [126.86, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.65], [126.855, 37.645], [126.85, 37.645], [126.85, 37.65], [126.855, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.65], [126.85, 37.645], [126.845, 37.645], [126.845, 37.65], [126.85, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.65], [126.845, 37.645], [126.84, 37.645], [126.84, 37.65], [126.845, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.65], [126.84, 37.645], [126.835, 37.645], [126.835, 37.65], [126.84, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.65], [126.835, 37.645], [126.83, 37.645], [126.83, 37.65], [126.835, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.65], [126.83, 37.645], [126.825, 37.645], [126.825, 37.65], [126.83, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 0,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.65], [126.825, 37.645], [126.82, 37.645], [126.82, 37.65], [126.825, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.65], [126.82, 37.645], [126.815, 37.645], [126.815, 37.65], [126.82, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.65], [126.815, 37.645], [126.81, 37.645], [126.81, 37.65], [126.815, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.65], [126.81, 37.645], [126.805, 37.645], [126.805, 37.65], [126.81, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.65], [126.805, 37.645], [126.8, 37.645], [126.8, 37.65], [126.805, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.65], [126.8, 37.645], [126.795, 37.645], [126.795, 37.65], [126.8, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.65], [126.795, 37.645], [126.79, 37.645], [126.79, 37.65], [126.795, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.65], [126.79, 37.645], [126.785, 37.645], [126.785, 37.65], [126.79, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.65], [126.785, 37.645], [126.78, 37.645], [126.78, 37.65], [126.785, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 9,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.65], [126.78, 37.645], [126.775, 37.645], [126.775, 37.65], [126.78, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 1,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 9,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.65], [126.775, 37.645], [126.77, 37.645], [126.77, 37.65], [126.775, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.65], [126.77, 37.645], [126.765, 37.645], [126.765, 37.65], [126.77, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.65], [126.765, 37.645], [126.76, 37.645], [126.76, 37.65], [126.765, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.65], [126.76, 37.645], [126.755, 37.645], [126.755, 37.65], [126.76, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.65], [126.755, 37.645], [126.75, 37.645], [126.75, 37.65], [126.755, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.65], [126.75, 37.645], [126.745, 37.645], [126.745, 37.65], [126.75, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.65], [126.745, 37.645], [126.74, 37.645], [126.74, 37.65], [126.745, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.65], [126.74, 37.645], [126.735, 37.645], [126.735, 37.65], [126.74, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 2,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.65], [126.735, 37.645], [126.73, 37.645], [126.73, 37.65], [126.735, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.65], [126.73, 37.645], [126.725, 37.645], [126.725, 37.65], [126.73, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.65], [126.725, 37.645], [126.72, 37.645], [126.72, 37.65], [126.725, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.655], [126.985, 37.65], [126.98, 37.65], [126.98, 37.655], [126.985, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.655], [126.98, 37.65], [126.975, 37.65], [126.975, 37.655], [126.98, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.655], [126.975, 37.65], [126.97, 37.65], [126.97, 37.655], [126.975, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.655], [126.97, 37.65], [126.965, 37.65], [126.965, 37.655], [126.97, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.655], [126.965, 37.65], [126.96, 37.65], [126.96, 37.655], [126.965, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.655], [126.96, 37.65], [126.955, 37.65], [126.955, 37.655], [126.96, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.655], [126.955, 37.65], [126.95, 37.65], [126.95, 37.655], [126.955, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.655], [126.94, 37.65], [126.935, 37.65], [126.935, 37.655], [126.94, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 1,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.655], [126.935, 37.65], [126.93, 37.65], [126.93, 37.655], [126.935, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.655], [126.93, 37.65], [126.925, 37.65], [126.925, 37.655], [126.93, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.655], [126.925, 37.65], [126.92, 37.65], [126.92, 37.655], [126.925, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.655], [126.92, 37.65], [126.915, 37.65], [126.915, 37.655], [126.92, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.655], [126.915, 37.65], [126.91, 37.65], [126.91, 37.655], [126.915, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.655], [126.91, 37.65], [126.905, 37.65], [126.905, 37.655], [126.91, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.655], [126.905, 37.65], [126.9, 37.65], [126.9, 37.655], [126.905, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.655], [126.9, 37.65], [126.895, 37.65], [126.895, 37.655], [126.9, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.655], [126.895, 37.65], [126.89, 37.65], [126.89, 37.655], [126.895, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.655], [126.89, 37.65], [126.885, 37.65], [126.885, 37.655], [126.89, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.655], [126.885, 37.65], [126.88, 37.65], [126.88, 37.655], [126.885, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.655], [126.88, 37.65], [126.875, 37.65], [126.875, 37.655], [126.88, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.655], [126.875, 37.65], [126.87, 37.65], [126.87, 37.655], [126.875, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.655], [126.87, 37.65], [126.865, 37.65], [126.865, 37.655], [126.87, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.655], [126.865, 37.65], [126.86, 37.65], [126.86, 37.655], [126.865, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 9,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.655], [126.86, 37.65], [126.855, 37.65], [126.855, 37.655], [126.86, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.655], [126.855, 37.65], [126.85, 37.65], [126.85, 37.655], [126.855, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.655], [126.85, 37.65], [126.845, 37.65], [126.845, 37.655], [126.85, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.655], [126.845, 37.65], [126.84, 37.65], [126.84, 37.655], [126.845, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.655], [126.84, 37.65], [126.835, 37.65], [126.835, 37.655], [126.84, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.655], [126.835, 37.65], [126.83, 37.65], [126.83, 37.655], [126.835, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.655], [126.83, 37.65], [126.825, 37.65], [126.825, 37.655], [126.83, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.655], [126.825, 37.65], [126.82, 37.65], [126.82, 37.655], [126.825, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.655], [126.82, 37.65], [126.815, 37.65], [126.815, 37.655], [126.82, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.655], [126.815, 37.65], [126.81, 37.65], [126.81, 37.655], [126.815, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.655], [126.81, 37.65], [126.805, 37.65], [126.805, 37.655], [126.81, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.655], [126.805, 37.65], [126.8, 37.65], [126.8, 37.655], [126.805, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 9,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.655], [126.8, 37.65], [126.795, 37.65], [126.795, 37.655], [126.8, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.655], [126.795, 37.65], [126.79, 37.65], [126.79, 37.655], [126.795, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.655], [126.79, 37.65], [126.785, 37.65], [126.785, 37.655], [126.79, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.655], [126.785, 37.65], [126.78, 37.65], [126.78, 37.655], [126.785, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.655], [126.78, 37.65], [126.775, 37.65], [126.775, 37.655], [126.78, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 1,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.655], [126.775, 37.65], [126.77, 37.65], [126.77, 37.655], [126.775, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.655], [126.77, 37.65], [126.765, 37.65], [126.765, 37.655], [126.77, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.655], [126.765, 37.65], [126.76, 37.65], [126.76, 37.655], [126.765, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.655], [126.76, 37.65], [126.755, 37.65], [126.755, 37.655], [126.76, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.655], [126.755, 37.65], [126.75, 37.65], [126.75, 37.655], [126.755, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.655], [126.75, 37.65], [126.745, 37.65], [126.745, 37.655], [126.75, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.655], [126.745, 37.65], [126.74, 37.65], [126.74, 37.655], [126.745, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.655], [126.74, 37.65], [126.735, 37.65], [126.735, 37.655], [126.74, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.655], [126.735, 37.65], [126.73, 37.65], [126.73, 37.655], [126.735, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.655], [126.73, 37.65], [126.725, 37.65], [126.725, 37.655], [126.73, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.66], [126.99, 37.655], [126.985, 37.655], [126.985, 37.66], [126.99, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.66], [126.985, 37.655], [126.98, 37.655], [126.98, 37.66], [126.985, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.66], [126.98, 37.655], [126.975, 37.655], [126.975, 37.66], [126.98, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.66], [126.975, 37.655], [126.97, 37.655], [126.97, 37.66], [126.975, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.66], [126.97, 37.655], [126.965, 37.655], [126.965, 37.66], [126.97, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.66], [126.965, 37.655], [126.96, 37.655], [126.96, 37.66], [126.965, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.66], [126.96, 37.655], [126.955, 37.655], [126.955, 37.66], [126.96, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.66], [126.955, 37.655], [126.95, 37.655], [126.95, 37.66], [126.955, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.66], [126.95, 37.655], [126.945, 37.655], [126.945, 37.66], [126.95, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.66], [126.945, 37.655], [126.94, 37.655], [126.94, 37.66], [126.945, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.66], [126.94, 37.655], [126.935, 37.655], [126.935, 37.66], [126.94, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.66], [126.935, 37.655], [126.93, 37.655], [126.93, 37.66], [126.935, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.66], [126.93, 37.655], [126.925, 37.655], [126.925, 37.66], [126.93, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.66], [126.925, 37.655], [126.92, 37.655], [126.92, 37.66], [126.925, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.66], [126.92, 37.655], [126.915, 37.655], [126.915, 37.66], [126.92, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.66], [126.915, 37.655], [126.91, 37.655], [126.91, 37.66], [126.915, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.66], [126.91, 37.655], [126.905, 37.655], [126.905, 37.66], [126.91, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.66], [126.905, 37.655], [126.9, 37.655], [126.9, 37.66], [126.905, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.66], [126.9, 37.655], [126.895, 37.655], [126.895, 37.66], [126.9, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 1,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.66], [126.895, 37.655], [126.89, 37.655], [126.89, 37.66], [126.895, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.66], [126.89, 37.655], [126.885, 37.655], [126.885, 37.66], [126.89, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.66], [126.885, 37.655], [126.88, 37.655], [126.88, 37.66], [126.885, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 0,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.66], [126.88, 37.655], [126.875, 37.655], [126.875, 37.66], [126.88, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.66], [126.875, 37.655], [126.87, 37.655], [126.87, 37.66], [126.875, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.66], [126.87, 37.655], [126.865, 37.655], [126.865, 37.66], [126.87, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.66], [126.865, 37.655], [126.86, 37.655], [126.86, 37.66], [126.865, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 9,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 1,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.66], [126.86, 37.655], [126.855, 37.655], [126.855, 37.66], [126.86, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.66], [126.855, 37.655], [126.85, 37.655], [126.85, 37.66], [126.855, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.66], [126.85, 37.655], [126.845, 37.655], [126.845, 37.66], [126.85, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.66], [126.845, 37.655], [126.84, 37.655], [126.84, 37.66], [126.845, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.66], [126.84, 37.655], [126.835, 37.655], [126.835, 37.66], [126.84, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.66], [126.835, 37.655], [126.83, 37.655], [126.83, 37.66], [126.835, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.66], [126.83, 37.655], [126.825, 37.655], [126.825, 37.66], [126.83, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.66], [126.825, 37.655], [126.82, 37.655], [126.82, 37.66], [126.825, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.66], [126.82, 37.655], [126.815, 37.655], [126.815, 37.66], [126.82, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.66], [126.815, 37.655], [126.81, 37.655], [126.81, 37.66], [126.815, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.66], [126.81, 37.655], [126.805, 37.655], [126.805, 37.66], [126.81, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 0,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.66], [126.805, 37.655], [126.8, 37.655], [126.8, 37.66], [126.805, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.66], [126.8, 37.655], [126.795, 37.655], [126.795, 37.66], [126.8, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.66], [126.795, 37.655], [126.79, 37.655], [126.79, 37.66], [126.795, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.66], [126.79, 37.655], [126.785, 37.655], [126.785, 37.66], [126.79, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.66], [126.785, 37.655], [126.78, 37.655], [126.78, 37.66], [126.785, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.66], [126.78, 37.655], [126.775, 37.655], [126.775, 37.66], [126.78, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.66], [126.775, 37.655], [126.77, 37.655], [126.77, 37.66], [126.775, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.66], [126.77, 37.655], [126.765, 37.655], [126.765, 37.66], [126.77, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.66], [126.765, 37.655], [126.76, 37.655], [126.76, 37.66], [126.765, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.66], [126.76, 37.655], [126.755, 37.655], [126.755, 37.66], [126.76, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.66], [126.755, 37.655], [126.75, 37.655], [126.75, 37.66], [126.755, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.66], [126.75, 37.655], [126.745, 37.655], [126.745, 37.66], [126.75, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.66], [126.745, 37.655], [126.74, 37.655], [126.74, 37.66], [126.745, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.66], [126.74, 37.655], [126.735, 37.655], [126.735, 37.66], [126.74, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.66], [126.735, 37.655], [126.73, 37.655], [126.73, 37.66], [126.735, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.66], [126.73, 37.655], [126.725, 37.655], [126.725, 37.66], [126.73, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 1,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.665], [126.995, 37.66], [126.99, 37.66], [126.99, 37.665], [126.995, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.665], [126.99, 37.66], [126.985, 37.66], [126.985, 37.665], [126.99, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.665], [126.985, 37.66], [126.98, 37.66], [126.98, 37.665], [126.985, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.665], [126.98, 37.66], [126.975, 37.66], [126.975, 37.665], [126.98, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.665], [126.975, 37.66], [126.97, 37.66], [126.97, 37.665], [126.975, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.665], [126.97, 37.66], [126.965, 37.66], [126.965, 37.665], [126.97, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.665], [126.965, 37.66], [126.96, 37.66], [126.96, 37.665], [126.965, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.665], [126.96, 37.66], [126.955, 37.66], [126.955, 37.665], [126.96, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.665], [126.955, 37.66], [126.95, 37.66], [126.95, 37.665], [126.955, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.665], [126.95, 37.66], [126.945, 37.66], [126.945, 37.665], [126.95, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.665], [126.945, 37.66], [126.94, 37.66], [126.94, 37.665], [126.945, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.665], [126.94, 37.66], [126.935, 37.66], [126.935, 37.665], [126.94, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 1,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.665], [126.935, 37.66], [126.93, 37.66], [126.93, 37.665], [126.935, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.665], [126.93, 37.66], [126.925, 37.66], [126.925, 37.665], [126.93, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.665], [126.925, 37.66], [126.92, 37.66], [126.92, 37.665], [126.925, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.665], [126.92, 37.66], [126.915, 37.66], [126.915, 37.665], [126.92, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 1,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.665], [126.915, 37.66], [126.91, 37.66], [126.91, 37.665], [126.915, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.665], [126.91, 37.66], [126.905, 37.66], [126.905, 37.665], [126.91, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.665], [126.905, 37.66], [126.9, 37.66], [126.9, 37.665], [126.905, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.665], [126.9, 37.66], [126.895, 37.66], [126.895, 37.665], [126.9, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.665], [126.895, 37.66], [126.89, 37.66], [126.89, 37.665], [126.895, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.665], [126.89, 37.66], [126.885, 37.66], [126.885, 37.665], [126.89, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.665], [126.885, 37.66], [126.88, 37.66], [126.88, 37.665], [126.885, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.665], [126.88, 37.66], [126.875, 37.66], [126.875, 37.665], [126.88, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.665], [126.875, 37.66], [126.87, 37.66], [126.87, 37.665], [126.875, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.665], [126.87, 37.66], [126.865, 37.66], [126.865, 37.665], [126.87, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.665], [126.865, 37.66], [126.86, 37.66], [126.86, 37.665], [126.865, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.665], [126.86, 37.66], [126.855, 37.66], [126.855, 37.665], [126.86, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.665], [126.855, 37.66], [126.85, 37.66], [126.85, 37.665], [126.855, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.665], [126.85, 37.66], [126.845, 37.66], [126.845, 37.665], [126.85, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.665], [126.845, 37.66], [126.84, 37.66], [126.84, 37.665], [126.845, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.665], [126.84, 37.66], [126.835, 37.66], [126.835, 37.665], [126.84, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.665], [126.835, 37.66], [126.83, 37.66], [126.83, 37.665], [126.835, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.665], [126.83, 37.66], [126.825, 37.66], [126.825, 37.665], [126.83, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 9,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.665], [126.825, 37.66], [126.82, 37.66], [126.82, 37.665], [126.825, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.665], [126.82, 37.66], [126.815, 37.66], [126.815, 37.665], [126.82, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.665], [126.815, 37.66], [126.81, 37.66], [126.81, 37.665], [126.815, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.665], [126.81, 37.66], [126.805, 37.66], [126.805, 37.665], [126.81, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.665], [126.805, 37.66], [126.8, 37.66], [126.8, 37.665], [126.805, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.665], [126.8, 37.66], [126.795, 37.66], [126.795, 37.665], [126.8, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.665], [126.795, 37.66], [126.79, 37.66], [126.79, 37.665], [126.795, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.665], [126.79, 37.66], [126.785, 37.66], [126.785, 37.665], [126.79, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.665], [126.785, 37.66], [126.78, 37.66], [126.78, 37.665], [126.785, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.665], [126.78, 37.66], [126.775, 37.66], [126.775, 37.665], [126.78, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.665], [126.775, 37.66], [126.77, 37.66], [126.77, 37.665], [126.775, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.665], [126.77, 37.66], [126.765, 37.66], [126.765, 37.665], [126.77, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.665], [126.765, 37.66], [126.76, 37.66], [126.76, 37.665], [126.765, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 9,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.665], [126.76, 37.66], [126.755, 37.66], [126.755, 37.665], [126.76, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.665], [126.755, 37.66], [126.75, 37.66], [126.75, 37.665], [126.755, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 1,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.665], [126.75, 37.66], [126.745, 37.66], [126.745, 37.665], [126.75, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.665], [126.745, 37.66], [126.74, 37.66], [126.74, 37.665], [126.745, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.665], [126.74, 37.66], [126.735, 37.66], [126.735, 37.665], [126.74, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.665], [126.735, 37.66], [126.73, 37.66], [126.73, 37.665], [126.735, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.665], [126.73, 37.66], [126.725, 37.66], [126.725, 37.665], [126.73, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.67], [126.995, 37.665], [126.99, 37.665], [126.99, 37.67], [126.995, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.67], [126.99, 37.665], [126.985, 37.665], [126.985, 37.67], [126.99, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.67], [126.985, 37.665], [126.98, 37.665], [126.98, 37.67], [126.985, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.67], [126.98, 37.665], [126.975, 37.665], [126.975, 37.67], [126.98, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.67], [126.975, 37.665], [126.97, 37.665], [126.97, 37.67], [126.975, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.67], [126.97, 37.665], [126.965, 37.665], [126.965, 37.67], [126.97, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.67], [126.965, 37.665], [126.96, 37.665], [126.96, 37.67], [126.965, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.67], [126.96, 37.665], [126.955, 37.665], [126.955, 37.67], [126.96, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.67], [126.955, 37.665], [126.95, 37.665], [126.95, 37.67], [126.955, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.67], [126.95, 37.665], [126.945, 37.665], [126.945, 37.67], [126.95, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.67], [126.945, 37.665], [126.94, 37.665], [126.94, 37.67], [126.945, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.67], [126.94, 37.665], [126.935, 37.665], [126.935, 37.67], [126.94, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.67], [126.935, 37.665], [126.93, 37.665], [126.93, 37.67], [126.935, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.67], [126.93, 37.665], [126.925, 37.665], [126.925, 37.67], [126.93, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.67], [126.925, 37.665], [126.92, 37.665], [126.92, 37.67], [126.925, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.67], [126.92, 37.665], [126.915, 37.665], [126.915, 37.67], [126.92, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.67], [126.915, 37.665], [126.91, 37.665], [126.91, 37.67], [126.915, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.67], [126.91, 37.665], [126.905, 37.665], [126.905, 37.67], [126.91, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.67], [126.905, 37.665], [126.9, 37.665], [126.9, 37.67], [126.905, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.67], [126.9, 37.665], [126.895, 37.665], [126.895, 37.67], [126.9, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.67], [126.895, 37.665], [126.89, 37.665], [126.89, 37.67], [126.895, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.67], [126.89, 37.665], [126.885, 37.665], [126.885, 37.67], [126.89, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.67], [126.885, 37.665], [126.88, 37.665], [126.88, 37.67], [126.885, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.67], [126.88, 37.665], [126.875, 37.665], [126.875, 37.67], [126.88, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.67], [126.875, 37.665], [126.87, 37.665], [126.87, 37.67], [126.875, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.67], [126.87, 37.665], [126.865, 37.665], [126.865, 37.67], [126.87, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.67], [126.865, 37.665], [126.86, 37.665], [126.86, 37.67], [126.865, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.67], [126.86, 37.665], [126.855, 37.665], [126.855, 37.67], [126.86, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.67], [126.855, 37.665], [126.85, 37.665], [126.85, 37.67], [126.855, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.67], [126.85, 37.665], [126.845, 37.665], [126.845, 37.67], [126.85, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.67], [126.845, 37.665], [126.84, 37.665], [126.84, 37.67], [126.845, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.67], [126.84, 37.665], [126.835, 37.665], [126.835, 37.67], [126.84, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.67], [126.835, 37.665], [126.83, 37.665], [126.83, 37.67], [126.835, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 9,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.67], [126.83, 37.665], [126.825, 37.665], [126.825, 37.67], [126.83, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.67], [126.825, 37.665], [126.82, 37.665], [126.82, 37.67], [126.825, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.67], [126.82, 37.665], [126.815, 37.665], [126.815, 37.67], [126.82, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.67], [126.815, 37.665], [126.81, 37.665], [126.81, 37.67], [126.815, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.67], [126.81, 37.665], [126.805, 37.665], [126.805, 37.67], [126.81, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.67], [126.805, 37.665], [126.8, 37.665], [126.8, 37.67], [126.805, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.67], [126.8, 37.665], [126.795, 37.665], [126.795, 37.67], [126.8, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.67], [126.795, 37.665], [126.79, 37.665], [126.79, 37.67], [126.795, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.67], [126.79, 37.665], [126.785, 37.665], [126.785, 37.67], [126.79, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 9,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.67], [126.785, 37.665], [126.78, 37.665], [126.78, 37.67], [126.785, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.67], [126.78, 37.665], [126.775, 37.665], [126.775, 37.67], [126.78, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 1,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.67], [126.775, 37.665], [126.77, 37.665], [126.77, 37.67], [126.775, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.67], [126.77, 37.665], [126.765, 37.665], [126.765, 37.67], [126.77, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.67], [126.765, 37.665], [126.76, 37.665], [126.76, 37.67], [126.765, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.67], [126.76, 37.665], [126.755, 37.665], [126.755, 37.67], [126.76, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.67], [126.755, 37.665], [126.75, 37.665], [126.75, 37.67], [126.755, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.67], [126.75, 37.665], [126.745, 37.665], [126.745, 37.67], [126.75, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 1,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.67], [126.745, 37.665], [126.74, 37.665], [126.74, 37.67], [126.745, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.67], [126.74, 37.665], [126.735, 37.665], [126.735, 37.67], [126.74, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.67], [126.735, 37.665], [126.73, 37.665], [126.73, 37.67], [126.735, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.67], [126.73, 37.665], [126.725, 37.665], [126.725, 37.67], [126.73, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.675], [126.995, 37.67], [126.99, 37.67], [126.99, 37.675], [126.995, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 1,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.675], [126.99, 37.67], [126.985, 37.67], [126.985, 37.675], [126.99, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.675], [126.985, 37.67], [126.98, 37.67], [126.98, 37.675], [126.985, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.675], [126.98, 37.67], [126.975, 37.67], [126.975, 37.675], [126.98, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 9,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.675], [126.975, 37.67], [126.97, 37.67], [126.97, 37.675], [126.975, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.675], [126.97, 37.67], [126.965, 37.67], [126.965, 37.675], [126.97, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.675], [126.965, 37.67], [126.96, 37.67], [126.96, 37.675], [126.965, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.675], [126.96, 37.67], [126.955, 37.67], [126.955, 37.675], [126.96, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.675], [126.955, 37.67], [126.95, 37.67], [126.95, 37.675], [126.955, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.675], [126.95, 37.67], [126.945, 37.67], [126.945, 37.675], [126.95, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.675], [126.945, 37.67], [126.94, 37.67], [126.94, 37.675], [126.945, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.675], [126.94, 37.67], [126.935, 37.67], [126.935, 37.675], [126.94, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.675], [126.935, 37.67], [126.93, 37.67], [126.93, 37.675], [126.935, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.675], [126.93, 37.67], [126.925, 37.67], [126.925, 37.675], [126.93, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.675], [126.925, 37.67], [126.92, 37.67], [126.92, 37.675], [126.925, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.675], [126.92, 37.67], [126.915, 37.67], [126.915, 37.675], [126.92, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.675], [126.915, 37.67], [126.91, 37.67], [126.91, 37.675], [126.915, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.675], [126.91, 37.67], [126.905, 37.67], [126.905, 37.675], [126.91, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.675], [126.905, 37.67], [126.9, 37.67], [126.9, 37.675], [126.905, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.675], [126.9, 37.67], [126.895, 37.67], [126.895, 37.675], [126.9, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.675], [126.895, 37.67], [126.89, 37.67], [126.89, 37.675], [126.895, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.675], [126.89, 37.67], [126.885, 37.67], [126.885, 37.675], [126.89, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.675], [126.885, 37.67], [126.88, 37.67], [126.88, 37.675], [126.885, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.675], [126.88, 37.67], [126.875, 37.67], [126.875, 37.675], [126.88, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.675], [126.875, 37.67], [126.87, 37.67], [126.87, 37.675], [126.875, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.675], [126.87, 37.67], [126.865, 37.67], [126.865, 37.675], [126.87, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.675], [126.865, 37.67], [126.86, 37.67], [126.86, 37.675], [126.865, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.675], [126.86, 37.67], [126.855, 37.67], [126.855, 37.675], [126.86, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.675], [126.855, 37.67], [126.85, 37.67], [126.85, 37.675], [126.855, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.675], [126.85, 37.67], [126.845, 37.67], [126.845, 37.675], [126.85, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.675], [126.845, 37.67], [126.84, 37.67], [126.84, 37.675], [126.845, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.675], [126.84, 37.67], [126.835, 37.67], [126.835, 37.675], [126.84, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 9,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.675], [126.835, 37.67], [126.83, 37.67], [126.83, 37.675], [126.835, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.675], [126.83, 37.67], [126.825, 37.67], [126.825, 37.675], [126.83, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.675], [126.825, 37.67], [126.82, 37.67], [126.82, 37.675], [126.825, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.675], [126.82, 37.67], [126.815, 37.67], [126.815, 37.675], [126.82, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.675], [126.815, 37.67], [126.81, 37.67], [126.81, 37.675], [126.815, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.675], [126.81, 37.67], [126.805, 37.67], [126.805, 37.675], [126.81, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.675], [126.805, 37.67], [126.8, 37.67], [126.8, 37.675], [126.805, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.675], [126.8, 37.67], [126.795, 37.67], [126.795, 37.675], [126.8, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.675], [126.795, 37.67], [126.79, 37.67], [126.79, 37.675], [126.795, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 9,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.675], [126.79, 37.67], [126.785, 37.67], [126.785, 37.675], [126.79, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.675], [126.785, 37.67], [126.78, 37.67], [126.78, 37.675], [126.785, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.675], [126.78, 37.67], [126.775, 37.67], [126.775, 37.675], [126.78, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.675], [126.775, 37.67], [126.77, 37.67], [126.77, 37.675], [126.775, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 1,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.675], [126.77, 37.67], [126.765, 37.67], [126.765, 37.675], [126.77, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.675], [126.765, 37.67], [126.76, 37.67], [126.76, 37.675], [126.765, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.675], [126.76, 37.67], [126.755, 37.67], [126.755, 37.675], [126.76, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.675], [126.755, 37.67], [126.75, 37.67], [126.75, 37.675], [126.755, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.675], [126.75, 37.67], [126.745, 37.67], [126.745, 37.675], [126.75, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.675], [126.745, 37.67], [126.74, 37.67], [126.74, 37.675], [126.745, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.675], [126.74, 37.67], [126.735, 37.67], [126.735, 37.675], [126.74, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.675], [126.735, 37.67], [126.73, 37.67], [126.73, 37.675], [126.735, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.675], [126.73, 37.67], [126.725, 37.67], [126.725, 37.675], [126.73, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.68], [126.995, 37.675], [126.99, 37.675], [126.99, 37.68], [126.995, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.68], [126.99, 37.675], [126.985, 37.675], [126.985, 37.68], [126.99, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.68], [126.985, 37.675], [126.98, 37.675], [126.98, 37.68], [126.985, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.68], [126.98, 37.675], [126.975, 37.675], [126.975, 37.68], [126.98, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.68], [126.975, 37.675], [126.97, 37.675], [126.97, 37.68], [126.975, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.68], [126.97, 37.675], [126.965, 37.675], [126.965, 37.68], [126.97, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.68], [126.965, 37.675], [126.96, 37.675], [126.96, 37.68], [126.965, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.68], [126.96, 37.675], [126.955, 37.675], [126.955, 37.68], [126.96, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.68], [126.955, 37.675], [126.95, 37.675], [126.95, 37.68], [126.955, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.68], [126.95, 37.675], [126.945, 37.675], [126.945, 37.68], [126.95, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.68], [126.945, 37.675], [126.94, 37.675], [126.94, 37.68], [126.945, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.68], [126.915, 37.675], [126.91, 37.675], [126.91, 37.68], [126.915, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.68], [126.91, 37.675], [126.905, 37.675], [126.905, 37.68], [126.91, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 1,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.68], [126.905, 37.675], [126.9, 37.675], [126.9, 37.68], [126.905, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 9,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.68], [126.9, 37.675], [126.895, 37.675], [126.895, 37.68], [126.9, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.68], [126.895, 37.675], [126.89, 37.675], [126.89, 37.68], [126.895, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.68], [126.89, 37.675], [126.885, 37.675], [126.885, 37.68], [126.89, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.68], [126.885, 37.675], [126.88, 37.675], [126.88, 37.68], [126.885, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.68], [126.88, 37.675], [126.875, 37.675], [126.875, 37.68], [126.88, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.68], [126.875, 37.675], [126.87, 37.675], [126.87, 37.68], [126.875, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.68], [126.87, 37.675], [126.865, 37.675], [126.865, 37.68], [126.87, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.68], [126.865, 37.675], [126.86, 37.675], [126.86, 37.68], [126.865, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.68], [126.86, 37.675], [126.855, 37.675], [126.855, 37.68], [126.86, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.68], [126.855, 37.675], [126.85, 37.675], [126.85, 37.68], [126.855, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.68], [126.85, 37.675], [126.845, 37.675], [126.845, 37.68], [126.85, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.68], [126.845, 37.675], [126.84, 37.675], [126.84, 37.68], [126.845, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.68], [126.84, 37.675], [126.835, 37.675], [126.835, 37.68], [126.84, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.68], [126.835, 37.675], [126.83, 37.675], [126.83, 37.68], [126.835, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.68], [126.83, 37.675], [126.825, 37.675], [126.825, 37.68], [126.83, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.68], [126.825, 37.675], [126.82, 37.675], [126.82, 37.68], [126.825, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.68], [126.82, 37.675], [126.815, 37.675], [126.815, 37.68], [126.82, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.68], [126.815, 37.675], [126.81, 37.675], [126.81, 37.68], [126.815, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.68], [126.81, 37.675], [126.805, 37.675], [126.805, 37.68], [126.81, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.68], [126.805, 37.675], [126.8, 37.675], [126.8, 37.68], [126.805, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.68], [126.8, 37.675], [126.795, 37.675], [126.795, 37.68], [126.8, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.68], [126.795, 37.675], [126.79, 37.675], [126.79, 37.68], [126.795, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.68], [126.79, 37.675], [126.785, 37.675], [126.785, 37.68], [126.79, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.68], [126.785, 37.675], [126.78, 37.675], [126.78, 37.68], [126.785, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.68], [126.78, 37.675], [126.775, 37.675], [126.775, 37.68], [126.78, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.68], [126.775, 37.675], [126.77, 37.675], [126.77, 37.68], [126.775, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.68], [126.77, 37.675], [126.765, 37.675], [126.765, 37.68], [126.77, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.68], [126.765, 37.675], [126.76, 37.675], [126.76, 37.68], [126.765, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.68], [126.76, 37.675], [126.755, 37.675], [126.755, 37.68], [126.76, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.68], [126.755, 37.675], [126.75, 37.675], [126.75, 37.68], [126.755, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 1,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.685], [126.995, 37.68], [126.99, 37.68], [126.99, 37.685], [126.995, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.685], [126.99, 37.68], [126.985, 37.68], [126.985, 37.685], [126.99, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.685], [126.985, 37.68], [126.98, 37.68], [126.98, 37.685], [126.985, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.685], [126.98, 37.68], [126.975, 37.68], [126.975, 37.685], [126.98, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.685], [126.975, 37.68], [126.97, 37.68], [126.97, 37.685], [126.975, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 9,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.685], [126.97, 37.68], [126.965, 37.68], [126.965, 37.685], [126.97, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.685], [126.965, 37.68], [126.96, 37.68], [126.96, 37.685], [126.965, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.685], [126.96, 37.68], [126.955, 37.68], [126.955, 37.685], [126.96, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.685], [126.955, 37.68], [126.95, 37.68], [126.95, 37.685], [126.955, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.685], [126.95, 37.68], [126.945, 37.68], [126.945, 37.685], [126.95, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.685], [126.945, 37.68], [126.94, 37.68], [126.94, 37.685], [126.945, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.685], [126.915, 37.68], [126.91, 37.68], [126.91, 37.685], [126.915, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 9,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.685], [126.91, 37.68], [126.905, 37.68], [126.905, 37.685], [126.91, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.685], [126.905, 37.68], [126.9, 37.68], [126.9, 37.685], [126.905, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.685], [126.9, 37.68], [126.895, 37.68], [126.895, 37.685], [126.9, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.685], [126.895, 37.68], [126.89, 37.68], [126.89, 37.685], [126.895, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.685], [126.89, 37.68], [126.885, 37.68], [126.885, 37.685], [126.89, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.685], [126.885, 37.68], [126.88, 37.68], [126.88, 37.685], [126.885, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.685], [126.88, 37.68], [126.875, 37.68], [126.875, 37.685], [126.88, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.685], [126.875, 37.68], [126.87, 37.68], [126.87, 37.685], [126.875, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 9,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.685], [126.87, 37.68], [126.865, 37.68], [126.865, 37.685], [126.87, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.685], [126.865, 37.68], [126.86, 37.68], [126.86, 37.685], [126.865, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.685], [126.86, 37.68], [126.855, 37.68], [126.855, 37.685], [126.86, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.685], [126.855, 37.68], [126.85, 37.68], [126.85, 37.685], [126.855, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.685], [126.85, 37.68], [126.845, 37.68], [126.845, 37.685], [126.85, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 8,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.685], [126.845, 37.68], [126.84, 37.68], [126.84, 37.685], [126.845, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.685], [126.84, 37.68], [126.835, 37.68], [126.835, 37.685], [126.84, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.685], [126.835, 37.68], [126.83, 37.68], [126.83, 37.685], [126.835, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.685], [126.83, 37.68], [126.825, 37.68], [126.825, 37.685], [126.83, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.685], [126.825, 37.68], [126.82, 37.68], [126.82, 37.685], [126.825, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.685], [126.82, 37.68], [126.815, 37.68], [126.815, 37.685], [126.82, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.685], [126.815, 37.68], [126.81, 37.68], [126.81, 37.685], [126.815, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 0,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.685], [126.81, 37.68], [126.805, 37.68], [126.805, 37.685], [126.81, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.685], [126.805, 37.68], [126.8, 37.68], [126.8, 37.685], [126.805, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.685], [126.8, 37.68], [126.795, 37.68], [126.795, 37.685], [126.8, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.685], [126.795, 37.68], [126.79, 37.68], [126.79, 37.685], [126.795, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.685], [126.79, 37.68], [126.785, 37.68], [126.785, 37.685], [126.79, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.685], [126.785, 37.68], [126.78, 37.68], [126.78, 37.685], [126.785, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 1,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.685], [126.78, 37.68], [126.775, 37.68], [126.775, 37.685], [126.78, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.685], [126.775, 37.68], [126.77, 37.68], [126.77, 37.685], [126.775, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.685], [126.77, 37.68], [126.765, 37.68], [126.765, 37.685], [126.77, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.685], [126.765, 37.68], [126.76, 37.68], [126.76, 37.685], [126.765, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.685], [126.76, 37.68], [126.755, 37.68], [126.755, 37.685], [126.76, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.685], [126.755, 37.68], [126.75, 37.68], [126.75, 37.685], [126.755, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.69], [126.985, 37.685], [126.98, 37.685], [126.98, 37.69], [126.985, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.69], [126.98, 37.685], [126.975, 37.685], [126.975, 37.69], [126.98, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.69], [126.975, 37.685], [126.97, 37.685], [126.97, 37.69], [126.975, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 9,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.69], [126.97, 37.685], [126.965, 37.685], [126.965, 37.69], [126.97, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.69], [126.965, 37.685], [126.96, 37.685], [126.96, 37.69], [126.965, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.69], [126.96, 37.685], [126.955, 37.685], [126.955, 37.69], [126.96, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.69], [126.955, 37.685], [126.95, 37.685], [126.95, 37.69], [126.955, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.69], [126.95, 37.685], [126.945, 37.685], [126.945, 37.69], [126.95, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.69], [126.92, 37.685], [126.915, 37.685], [126.915, 37.69], [126.92, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 2,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.69], [126.915, 37.685], [126.91, 37.685], [126.91, 37.69], [126.915, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 1,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.69], [126.91, 37.685], [126.905, 37.685], [126.905, 37.69], [126.91, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 1,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.69], [126.905, 37.685], [126.9, 37.685], [126.9, 37.69], [126.905, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.69], [126.9, 37.685], [126.895, 37.685], [126.895, 37.69], [126.9, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.69], [126.895, 37.685], [126.89, 37.685], [126.89, 37.69], [126.895, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.69], [126.89, 37.685], [126.885, 37.685], [126.885, 37.69], [126.89, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.69], [126.885, 37.685], [126.88, 37.685], [126.88, 37.69], [126.885, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.69], [126.88, 37.685], [126.875, 37.685], [126.875, 37.69], [126.88, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.69], [126.875, 37.685], [126.87, 37.685], [126.87, 37.69], [126.875, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.69], [126.87, 37.685], [126.865, 37.685], [126.865, 37.69], [126.87, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.69], [126.865, 37.685], [126.86, 37.685], [126.86, 37.69], [126.865, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.69], [126.86, 37.685], [126.855, 37.685], [126.855, 37.69], [126.86, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.69], [126.855, 37.685], [126.85, 37.685], [126.85, 37.69], [126.855, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.69], [126.85, 37.685], [126.845, 37.685], [126.845, 37.69], [126.85, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.69], [126.845, 37.685], [126.84, 37.685], [126.84, 37.69], [126.845, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 8,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.69], [126.84, 37.685], [126.835, 37.685], [126.835, 37.69], [126.84, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.69], [126.835, 37.685], [126.83, 37.685], [126.83, 37.69], [126.835, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.69], [126.83, 37.685], [126.825, 37.685], [126.825, 37.69], [126.83, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.69], [126.825, 37.685], [126.82, 37.685], [126.82, 37.69], [126.825, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.69], [126.82, 37.685], [126.815, 37.685], [126.815, 37.69], [126.82, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.69], [126.815, 37.685], [126.81, 37.685], [126.81, 37.69], [126.815, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.69], [126.81, 37.685], [126.805, 37.685], [126.805, 37.69], [126.81, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.69], [126.805, 37.685], [126.8, 37.685], [126.8, 37.69], [126.805, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.69], [126.8, 37.685], [126.795, 37.685], [126.795, 37.69], [126.8, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.69], [126.795, 37.685], [126.79, 37.685], [126.79, 37.69], [126.795, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.69], [126.79, 37.685], [126.785, 37.685], [126.785, 37.69], [126.79, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.69], [126.785, 37.685], [126.78, 37.685], [126.78, 37.69], [126.785, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.69], [126.78, 37.685], [126.775, 37.685], [126.775, 37.69], [126.78, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.69], [126.775, 37.685], [126.77, 37.685], [126.77, 37.69], [126.775, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.69], [126.77, 37.685], [126.765, 37.685], [126.765, 37.69], [126.77, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.69], [126.765, 37.685], [126.76, 37.685], [126.76, 37.69], [126.765, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.69], [126.76, 37.685], [126.755, 37.685], [126.755, 37.69], [126.76, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.69], [126.755, 37.685], [126.75, 37.685], [126.75, 37.69], [126.755, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.695], [126.97, 37.69], [126.965, 37.69], [126.965, 37.695], [126.97, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.695], [126.965, 37.69], [126.96, 37.69], [126.96, 37.695], [126.965, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.695], [126.96, 37.69], [126.955, 37.69], [126.955, 37.695], [126.96, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.695], [126.955, 37.69], [126.95, 37.69], [126.95, 37.695], [126.955, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.695], [126.92, 37.69], [126.915, 37.69], [126.915, 37.695], [126.92, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.695], [126.915, 37.69], [126.91, 37.69], [126.91, 37.695], [126.915, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.695], [126.91, 37.69], [126.905, 37.69], [126.905, 37.695], [126.91, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.695], [126.905, 37.69], [126.9, 37.69], [126.9, 37.695], [126.905, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.695], [126.9, 37.69], [126.895, 37.69], [126.895, 37.695], [126.9, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.695], [126.895, 37.69], [126.89, 37.69], [126.89, 37.695], [126.895, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.695], [126.89, 37.69], [126.885, 37.69], [126.885, 37.695], [126.89, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.695], [126.885, 37.69], [126.88, 37.69], [126.88, 37.695], [126.885, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.695], [126.88, 37.69], [126.875, 37.69], [126.875, 37.695], [126.88, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.695], [126.875, 37.69], [126.87, 37.69], [126.87, 37.695], [126.875, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.695], [126.87, 37.69], [126.865, 37.69], [126.865, 37.695], [126.87, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.695], [126.865, 37.69], [126.86, 37.69], [126.86, 37.695], [126.865, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.695], [126.86, 37.69], [126.855, 37.69], [126.855, 37.695], [126.86, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.695], [126.855, 37.69], [126.85, 37.69], [126.85, 37.695], [126.855, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.695], [126.85, 37.69], [126.845, 37.69], [126.845, 37.695], [126.85, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.695], [126.845, 37.69], [126.84, 37.69], [126.84, 37.695], [126.845, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.695], [126.84, 37.69], [126.835, 37.69], [126.835, 37.695], [126.84, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.695], [126.835, 37.69], [126.83, 37.69], [126.83, 37.695], [126.835, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.695], [126.83, 37.69], [126.825, 37.69], [126.825, 37.695], [126.83, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.695], [126.825, 37.69], [126.82, 37.69], [126.82, 37.695], [126.825, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.695], [126.82, 37.69], [126.815, 37.69], [126.815, 37.695], [126.82, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.695], [126.815, 37.69], [126.81, 37.69], [126.81, 37.695], [126.815, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.695], [126.81, 37.69], [126.805, 37.69], [126.805, 37.695], [126.81, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.695], [126.805, 37.69], [126.8, 37.69], [126.8, 37.695], [126.805, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.695], [126.8, 37.69], [126.795, 37.69], [126.795, 37.695], [126.8, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.695], [126.795, 37.69], [126.79, 37.69], [126.79, 37.695], [126.795, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.695], [126.79, 37.69], [126.785, 37.69], [126.785, 37.695], [126.79, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.695], [126.785, 37.69], [126.78, 37.69], [126.78, 37.695], [126.785, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.695], [126.78, 37.69], [126.775, 37.69], [126.775, 37.695], [126.78, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.695], [126.775, 37.69], [126.77, 37.69], [126.77, 37.695], [126.775, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.695], [126.77, 37.69], [126.765, 37.69], [126.765, 37.695], [126.77, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 9,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.695], [126.765, 37.69], [126.76, 37.69], [126.76, 37.695], [126.765, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.695], [126.76, 37.69], [126.755, 37.69], [126.755, 37.695], [126.76, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.695], [126.755, 37.69], [126.75, 37.69], [126.75, 37.695], [126.755, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.7], [126.925, 37.695], [126.92, 37.695], [126.92, 37.7], [126.925, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.7], [126.92, 37.695], [126.915, 37.695], [126.915, 37.7], [126.92, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.7], [126.915, 37.695], [126.91, 37.695], [126.91, 37.7], [126.915, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.7], [126.91, 37.695], [126.905, 37.695], [126.905, 37.7], [126.91, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.7], [126.905, 37.695], [126.9, 37.695], [126.9, 37.7], [126.905, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.7], [126.9, 37.695], [126.895, 37.695], [126.895, 37.7], [126.9, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 9,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.7], [126.895, 37.695], [126.89, 37.695], [126.89, 37.7], [126.895, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.7], [126.89, 37.695], [126.885, 37.695], [126.885, 37.7], [126.89, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.7], [126.885, 37.695], [126.88, 37.695], [126.88, 37.7], [126.885, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.7], [126.88, 37.695], [126.875, 37.695], [126.875, 37.7], [126.88, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.7], [126.875, 37.695], [126.87, 37.695], [126.87, 37.7], [126.875, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.7], [126.87, 37.695], [126.865, 37.695], [126.865, 37.7], [126.87, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.7], [126.865, 37.695], [126.86, 37.695], [126.86, 37.7], [126.865, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.7], [126.86, 37.695], [126.855, 37.695], [126.855, 37.7], [126.86, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.7], [126.855, 37.695], [126.85, 37.695], [126.85, 37.7], [126.855, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.7], [126.85, 37.695], [126.845, 37.695], [126.845, 37.7], [126.85, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.7], [126.845, 37.695], [126.84, 37.695], [126.84, 37.7], [126.845, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.7], [126.84, 37.695], [126.835, 37.695], [126.835, 37.7], [126.84, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.7], [126.835, 37.695], [126.83, 37.695], [126.83, 37.7], [126.835, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.7], [126.83, 37.695], [126.825, 37.695], [126.825, 37.7], [126.83, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.7], [126.825, 37.695], [126.82, 37.695], [126.82, 37.7], [126.825, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.7], [126.82, 37.695], [126.815, 37.695], [126.815, 37.7], [126.82, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.7], [126.815, 37.695], [126.81, 37.695], [126.81, 37.7], [126.815, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.7], [126.81, 37.695], [126.805, 37.695], [126.805, 37.7], [126.81, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.7], [126.805, 37.695], [126.8, 37.695], [126.8, 37.7], [126.805, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.7], [126.8, 37.695], [126.795, 37.695], [126.795, 37.7], [126.8, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.7], [126.795, 37.695], [126.79, 37.695], [126.79, 37.7], [126.795, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.7], [126.79, 37.695], [126.785, 37.695], [126.785, 37.7], [126.79, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.7], [126.785, 37.695], [126.78, 37.695], [126.78, 37.7], [126.785, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 3,
      "ITEM5": 9,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.7], [126.78, 37.695], [126.775, 37.695], [126.775, 37.7], [126.78, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.7], [126.775, 37.695], [126.77, 37.695], [126.77, 37.7], [126.775, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.7], [126.77, 37.695], [126.765, 37.695], [126.765, 37.7], [126.77, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.7], [126.765, 37.695], [126.76, 37.695], [126.76, 37.7], [126.765, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.7], [126.76, 37.695], [126.755, 37.695], [126.755, 37.7], [126.76, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.7], [126.755, 37.695], [126.75, 37.695], [126.75, 37.7], [126.755, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.705], [126.925, 37.7], [126.92, 37.7], [126.92, 37.705], [126.925, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.705], [126.92, 37.7], [126.915, 37.7], [126.915, 37.705], [126.92, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.705], [126.915, 37.7], [126.91, 37.7], [126.91, 37.705], [126.915, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.705], [126.91, 37.7], [126.905, 37.7], [126.905, 37.705], [126.91, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.705], [126.905, 37.7], [126.9, 37.7], [126.9, 37.705], [126.905, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.705], [126.9, 37.7], [126.895, 37.7], [126.895, 37.705], [126.9, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.705], [126.895, 37.7], [126.89, 37.7], [126.89, 37.705], [126.895, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.705], [126.89, 37.7], [126.885, 37.7], [126.885, 37.705], [126.89, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.705], [126.885, 37.7], [126.88, 37.7], [126.88, 37.705], [126.885, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.705], [126.88, 37.7], [126.875, 37.7], [126.875, 37.705], [126.88, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.705], [126.875, 37.7], [126.87, 37.7], [126.87, 37.705], [126.875, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.705], [126.87, 37.7], [126.865, 37.7], [126.865, 37.705], [126.87, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.705], [126.865, 37.7], [126.86, 37.7], [126.86, 37.705], [126.865, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.705], [126.86, 37.7], [126.855, 37.7], [126.855, 37.705], [126.86, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.705], [126.855, 37.7], [126.85, 37.7], [126.85, 37.705], [126.855, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.705], [126.85, 37.7], [126.845, 37.7], [126.845, 37.705], [126.85, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.705], [126.845, 37.7], [126.84, 37.7], [126.84, 37.705], [126.845, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.705], [126.84, 37.7], [126.835, 37.7], [126.835, 37.705], [126.84, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.705], [126.835, 37.7], [126.83, 37.7], [126.83, 37.705], [126.835, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 1,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.705], [126.83, 37.7], [126.825, 37.7], [126.825, 37.705], [126.83, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.705], [126.825, 37.7], [126.82, 37.7], [126.82, 37.705], [126.825, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.705], [126.82, 37.7], [126.815, 37.7], [126.815, 37.705], [126.82, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.705], [126.815, 37.7], [126.81, 37.7], [126.81, 37.705], [126.815, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.705], [126.81, 37.7], [126.805, 37.7], [126.805, 37.705], [126.81, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.705], [126.805, 37.7], [126.8, 37.7], [126.8, 37.705], [126.805, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.705], [126.8, 37.7], [126.795, 37.7], [126.795, 37.705], [126.8, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.705], [126.795, 37.7], [126.79, 37.7], [126.79, 37.705], [126.795, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.705], [126.79, 37.7], [126.785, 37.7], [126.785, 37.705], [126.79, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.705], [126.785, 37.7], [126.78, 37.7], [126.78, 37.705], [126.785, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.705], [126.78, 37.7], [126.775, 37.7], [126.775, 37.705], [126.78, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.705], [126.775, 37.7], [126.77, 37.7], [126.77, 37.705], [126.775, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.705], [126.77, 37.7], [126.765, 37.7], [126.765, 37.705], [126.77, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.705], [126.765, 37.7], [126.76, 37.7], [126.76, 37.705], [126.765, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.705], [126.76, 37.7], [126.755, 37.7], [126.755, 37.705], [126.76, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 2,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.705], [126.755, 37.7], [126.75, 37.7], [126.75, 37.705], [126.755, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.71], [126.93, 37.705], [126.925, 37.705], [126.925, 37.71], [126.93, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.71], [126.925, 37.705], [126.92, 37.705], [126.92, 37.71], [126.925, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.71], [126.92, 37.705], [126.915, 37.705], [126.915, 37.71], [126.92, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.71], [126.915, 37.705], [126.91, 37.705], [126.91, 37.71], [126.915, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.71], [126.91, 37.705], [126.905, 37.705], [126.905, 37.71], [126.91, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.71], [126.905, 37.705], [126.9, 37.705], [126.9, 37.71], [126.905, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.71], [126.9, 37.705], [126.895, 37.705], [126.895, 37.71], [126.9, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.71], [126.895, 37.705], [126.89, 37.705], [126.89, 37.71], [126.895, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.71], [126.89, 37.705], [126.885, 37.705], [126.885, 37.71], [126.89, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.71], [126.885, 37.705], [126.88, 37.705], [126.88, 37.71], [126.885, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.71], [126.88, 37.705], [126.875, 37.705], [126.875, 37.71], [126.88, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.71], [126.875, 37.705], [126.87, 37.705], [126.87, 37.71], [126.875, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.71], [126.87, 37.705], [126.865, 37.705], [126.865, 37.71], [126.87, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.71], [126.865, 37.705], [126.86, 37.705], [126.86, 37.71], [126.865, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.71], [126.86, 37.705], [126.855, 37.705], [126.855, 37.71], [126.86, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.71], [126.855, 37.705], [126.85, 37.705], [126.85, 37.71], [126.855, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.71], [126.85, 37.705], [126.845, 37.705], [126.845, 37.71], [126.85, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 1,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.71], [126.845, 37.705], [126.84, 37.705], [126.84, 37.71], [126.845, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.71], [126.84, 37.705], [126.835, 37.705], [126.835, 37.71], [126.84, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.71], [126.835, 37.705], [126.83, 37.705], [126.83, 37.71], [126.835, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 1,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.71], [126.83, 37.705], [126.825, 37.705], [126.825, 37.71], [126.83, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.71], [126.825, 37.705], [126.82, 37.705], [126.82, 37.71], [126.825, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.71], [126.82, 37.705], [126.815, 37.705], [126.815, 37.71], [126.82, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.71], [126.815, 37.705], [126.81, 37.705], [126.81, 37.71], [126.815, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.71], [126.81, 37.705], [126.805, 37.705], [126.805, 37.71], [126.81, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 9,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.71], [126.805, 37.705], [126.8, 37.705], [126.8, 37.71], [126.805, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.71], [126.8, 37.705], [126.795, 37.705], [126.795, 37.71], [126.8, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 9,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.71], [126.795, 37.705], [126.79, 37.705], [126.79, 37.71], [126.795, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.71], [126.79, 37.705], [126.785, 37.705], [126.785, 37.71], [126.79, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.71], [126.785, 37.705], [126.78, 37.705], [126.78, 37.71], [126.785, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 1,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.71], [126.78, 37.705], [126.775, 37.705], [126.775, 37.71], [126.78, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.71], [126.775, 37.705], [126.77, 37.705], [126.77, 37.71], [126.775, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 1,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.71], [126.77, 37.705], [126.765, 37.705], [126.765, 37.71], [126.77, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.71], [126.76, 37.705], [126.755, 37.705], [126.755, 37.71], [126.76, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.71], [126.755, 37.705], [126.75, 37.705], [126.75, 37.71], [126.755, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.715], [126.925, 37.71], [126.92, 37.71], [126.92, 37.715], [126.925, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.715], [126.92, 37.71], [126.915, 37.71], [126.915, 37.715], [126.92, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.715], [126.915, 37.71], [126.91, 37.71], [126.91, 37.715], [126.915, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 1,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.715], [126.91, 37.71], [126.905, 37.71], [126.905, 37.715], [126.91, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.715], [126.905, 37.71], [126.9, 37.71], [126.9, 37.715], [126.905, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.715], [126.9, 37.71], [126.895, 37.71], [126.895, 37.715], [126.9, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.715], [126.895, 37.71], [126.89, 37.71], [126.89, 37.715], [126.895, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.715], [126.89, 37.71], [126.885, 37.71], [126.885, 37.715], [126.89, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.715], [126.885, 37.71], [126.88, 37.71], [126.88, 37.715], [126.885, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.715], [126.88, 37.71], [126.875, 37.71], [126.875, 37.715], [126.88, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.715], [126.875, 37.71], [126.87, 37.71], [126.87, 37.715], [126.875, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.715], [126.87, 37.71], [126.865, 37.71], [126.865, 37.715], [126.87, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 9,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.715], [126.865, 37.71], [126.86, 37.71], [126.86, 37.715], [126.865, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.715], [126.86, 37.71], [126.855, 37.71], [126.855, 37.715], [126.86, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 0,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.715], [126.855, 37.71], [126.85, 37.71], [126.85, 37.715], [126.855, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.715], [126.85, 37.71], [126.845, 37.71], [126.845, 37.715], [126.85, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.715], [126.845, 37.71], [126.84, 37.71], [126.84, 37.715], [126.845, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.715], [126.84, 37.71], [126.835, 37.71], [126.835, 37.715], [126.84, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.715], [126.835, 37.71], [126.83, 37.71], [126.83, 37.715], [126.835, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.715], [126.83, 37.71], [126.825, 37.71], [126.825, 37.715], [126.83, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.715], [126.825, 37.71], [126.82, 37.71], [126.82, 37.715], [126.825, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.715], [126.82, 37.71], [126.815, 37.71], [126.815, 37.715], [126.82, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 1,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.715], [126.815, 37.71], [126.81, 37.71], [126.81, 37.715], [126.815, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.715], [126.81, 37.71], [126.805, 37.71], [126.805, 37.715], [126.81, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.715], [126.805, 37.71], [126.8, 37.71], [126.8, 37.715], [126.805, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.715], [126.8, 37.71], [126.795, 37.71], [126.795, 37.715], [126.8, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.715], [126.795, 37.71], [126.79, 37.71], [126.79, 37.715], [126.795, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.715], [126.79, 37.71], [126.785, 37.71], [126.785, 37.715], [126.79, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 1,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.715], [126.785, 37.71], [126.78, 37.71], [126.78, 37.715], [126.785, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.715], [126.78, 37.71], [126.775, 37.71], [126.775, 37.715], [126.78, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.715], [126.775, 37.71], [126.77, 37.71], [126.77, 37.715], [126.775, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.72], [126.925, 37.715], [126.92, 37.715], [126.92, 37.72], [126.925, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 9,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.72], [126.92, 37.715], [126.915, 37.715], [126.915, 37.72], [126.92, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.72], [126.915, 37.715], [126.91, 37.715], [126.91, 37.72], [126.915, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.72], [126.91, 37.715], [126.905, 37.715], [126.905, 37.72], [126.91, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.72], [126.905, 37.715], [126.9, 37.715], [126.9, 37.72], [126.905, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.72], [126.9, 37.715], [126.895, 37.715], [126.895, 37.72], [126.9, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.72], [126.895, 37.715], [126.89, 37.715], [126.89, 37.72], [126.895, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.72], [126.89, 37.715], [126.885, 37.715], [126.885, 37.72], [126.89, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.72], [126.885, 37.715], [126.88, 37.715], [126.88, 37.72], [126.885, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.72], [126.88, 37.715], [126.875, 37.715], [126.875, 37.72], [126.88, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 8,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.72], [126.875, 37.715], [126.87, 37.715], [126.87, 37.72], [126.875, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.72], [126.87, 37.715], [126.865, 37.715], [126.865, 37.72], [126.87, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 9,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.72], [126.865, 37.715], [126.86, 37.715], [126.86, 37.72], [126.865, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.72], [126.86, 37.715], [126.855, 37.715], [126.855, 37.72], [126.86, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.72], [126.855, 37.715], [126.85, 37.715], [126.85, 37.72], [126.855, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 1,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.72], [126.85, 37.715], [126.845, 37.715], [126.845, 37.72], [126.85, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.72], [126.845, 37.715], [126.84, 37.715], [126.84, 37.72], [126.845, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.72], [126.84, 37.715], [126.835, 37.715], [126.835, 37.72], [126.84, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 9,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.72], [126.835, 37.715], [126.83, 37.715], [126.83, 37.72], [126.835, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.72], [126.83, 37.715], [126.825, 37.715], [126.825, 37.72], [126.83, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.72], [126.825, 37.715], [126.82, 37.715], [126.82, 37.72], [126.825, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.72], [126.82, 37.715], [126.815, 37.715], [126.815, 37.72], [126.82, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.72], [126.815, 37.715], [126.81, 37.715], [126.81, 37.72], [126.815, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.72], [126.81, 37.715], [126.805, 37.715], [126.805, 37.72], [126.81, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.72], [126.805, 37.715], [126.8, 37.715], [126.8, 37.72], [126.805, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.72], [126.8, 37.715], [126.795, 37.715], [126.795, 37.72], [126.8, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.72], [126.795, 37.715], [126.79, 37.715], [126.79, 37.72], [126.795, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.72], [126.79, 37.715], [126.785, 37.715], [126.785, 37.72], [126.79, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.72], [126.785, 37.715], [126.78, 37.715], [126.78, 37.72], [126.785, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.72], [126.78, 37.715], [126.775, 37.715], [126.775, 37.72], [126.78, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.725], [126.925, 37.72], [126.92, 37.72], [126.92, 37.725], [126.925, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.725], [126.92, 37.72], [126.915, 37.72], [126.915, 37.725], [126.92, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.725], [126.915, 37.72], [126.91, 37.72], [126.91, 37.725], [126.915, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.725], [126.91, 37.72], [126.905, 37.72], [126.905, 37.725], [126.91, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 1,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.725], [126.905, 37.72], [126.9, 37.72], [126.9, 37.725], [126.905, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.725], [126.9, 37.72], [126.895, 37.72], [126.895, 37.725], [126.9, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.725], [126.895, 37.72], [126.89, 37.72], [126.89, 37.725], [126.895, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.725], [126.89, 37.72], [126.885, 37.72], [126.885, 37.725], [126.89, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.725], [126.885, 37.72], [126.88, 37.72], [126.88, 37.725], [126.885, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.725], [126.88, 37.72], [126.875, 37.72], [126.875, 37.725], [126.88, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.725], [126.875, 37.72], [126.87, 37.72], [126.87, 37.725], [126.875, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.725], [126.87, 37.72], [126.865, 37.72], [126.865, 37.725], [126.87, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.725], [126.865, 37.72], [126.86, 37.72], [126.86, 37.725], [126.865, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 8,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.725], [126.86, 37.72], [126.855, 37.72], [126.855, 37.725], [126.86, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.725], [126.855, 37.72], [126.85, 37.72], [126.85, 37.725], [126.855, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.725], [126.85, 37.72], [126.845, 37.72], [126.845, 37.725], [126.85, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.725], [126.845, 37.72], [126.84, 37.72], [126.84, 37.725], [126.845, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.725], [126.84, 37.72], [126.835, 37.72], [126.835, 37.725], [126.84, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.725], [126.835, 37.72], [126.83, 37.72], [126.83, 37.725], [126.835, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.725], [126.83, 37.72], [126.825, 37.72], [126.825, 37.725], [126.83, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.725], [126.825, 37.72], [126.82, 37.72], [126.82, 37.725], [126.825, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.725], [126.82, 37.72], [126.815, 37.72], [126.815, 37.725], [126.82, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.725], [126.815, 37.72], [126.81, 37.72], [126.81, 37.725], [126.815, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.725], [126.81, 37.72], [126.805, 37.72], [126.805, 37.725], [126.81, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.725], [126.805, 37.72], [126.8, 37.72], [126.8, 37.725], [126.805, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.725], [126.8, 37.72], [126.795, 37.72], [126.795, 37.725], [126.8, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.725], [126.795, 37.72], [126.79, 37.72], [126.79, 37.725], [126.795, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.725], [126.79, 37.72], [126.785, 37.72], [126.785, 37.725], [126.79, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.725], [126.785, 37.72], [126.78, 37.72], [126.78, 37.725], [126.785, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 1,
      "ITEM4": 3,
      "ITEM5": 8,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.73], [126.925, 37.725], [126.92, 37.725], [126.92, 37.73], [126.925, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.73], [126.92, 37.725], [126.915, 37.725], [126.915, 37.73], [126.92, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.73], [126.915, 37.725], [126.91, 37.725], [126.91, 37.73], [126.915, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.73], [126.91, 37.725], [126.905, 37.725], [126.905, 37.73], [126.91, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.73], [126.905, 37.725], [126.9, 37.725], [126.9, 37.73], [126.905, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.73], [126.9, 37.725], [126.895, 37.725], [126.895, 37.73], [126.9, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.73], [126.895, 37.725], [126.89, 37.725], [126.89, 37.73], [126.895, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.73], [126.89, 37.725], [126.885, 37.725], [126.885, 37.73], [126.89, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.73], [126.885, 37.725], [126.88, 37.725], [126.88, 37.73], [126.885, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.73], [126.88, 37.725], [126.875, 37.725], [126.875, 37.73], [126.88, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.73], [126.875, 37.725], [126.87, 37.725], [126.87, 37.73], [126.875, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.73], [126.87, 37.725], [126.865, 37.725], [126.865, 37.73], [126.87, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.73], [126.865, 37.725], [126.86, 37.725], [126.86, 37.73], [126.865, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.73], [126.86, 37.725], [126.855, 37.725], [126.855, 37.73], [126.86, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.73], [126.855, 37.725], [126.85, 37.725], [126.85, 37.73], [126.855, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.73], [126.85, 37.725], [126.845, 37.725], [126.845, 37.73], [126.85, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.73], [126.845, 37.725], [126.84, 37.725], [126.84, 37.73], [126.845, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 9,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.73], [126.84, 37.725], [126.835, 37.725], [126.835, 37.73], [126.84, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.73], [126.835, 37.725], [126.83, 37.725], [126.83, 37.73], [126.835, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.73], [126.83, 37.725], [126.825, 37.725], [126.825, 37.73], [126.83, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.73], [126.825, 37.725], [126.82, 37.725], [126.82, 37.73], [126.825, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.73], [126.82, 37.725], [126.815, 37.725], [126.815, 37.73], [126.82, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.73], [126.815, 37.725], [126.81, 37.725], [126.81, 37.73], [126.815, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.73], [126.81, 37.725], [126.805, 37.725], [126.805, 37.73], [126.81, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.73], [126.805, 37.725], [126.8, 37.725], [126.8, 37.73], [126.805, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.73], [126.8, 37.725], [126.795, 37.725], [126.795, 37.73], [126.8, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.73], [126.795, 37.725], [126.79, 37.725], [126.79, 37.73], [126.795, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.735], [126.93, 37.73], [126.925, 37.73], [126.925, 37.735], [126.93, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.735], [126.925, 37.73], [126.92, 37.73], [126.92, 37.735], [126.925, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.735], [126.92, 37.73], [126.915, 37.73], [126.915, 37.735], [126.92, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.735], [126.915, 37.73], [126.91, 37.73], [126.91, 37.735], [126.915, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.735], [126.91, 37.73], [126.905, 37.73], [126.905, 37.735], [126.91, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.735], [126.905, 37.73], [126.9, 37.73], [126.9, 37.735], [126.905, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.735], [126.875, 37.73], [126.87, 37.73], [126.87, 37.735], [126.875, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.735], [126.87, 37.73], [126.865, 37.73], [126.865, 37.735], [126.87, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.735], [126.865, 37.73], [126.86, 37.73], [126.86, 37.735], [126.865, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.735], [126.86, 37.73], [126.855, 37.73], [126.855, 37.735], [126.86, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.735], [126.855, 37.73], [126.85, 37.73], [126.85, 37.735], [126.855, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.735], [126.85, 37.73], [126.845, 37.73], [126.845, 37.735], [126.85, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.735], [126.845, 37.73], [126.84, 37.73], [126.84, 37.735], [126.845, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.735], [126.805, 37.73], [126.8, 37.73], [126.8, 37.735], [126.805, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.735], [126.8, 37.73], [126.795, 37.73], [126.795, 37.735], [126.8, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.735], [126.795, 37.73], [126.79, 37.73], [126.79, 37.735], [126.795, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.74], [126.935, 37.735], [126.93, 37.735], [126.93, 37.74], [126.935, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.74], [126.93, 37.735], [126.925, 37.735], [126.925, 37.74], [126.93, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.74], [126.925, 37.735], [126.92, 37.735], [126.92, 37.74], [126.925, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.74], [126.92, 37.735], [126.915, 37.735], [126.915, 37.74], [126.92, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.74], [126.915, 37.735], [126.91, 37.735], [126.91, 37.74], [126.915, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.74], [126.91, 37.735], [126.905, 37.735], [126.905, 37.74], [126.91, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.74], [126.905, 37.735], [126.9, 37.735], [126.9, 37.74], [126.905, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.74], [126.86, 37.735], [126.855, 37.735], [126.855, 37.74], [126.86, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.74], [126.855, 37.735], [126.85, 37.735], [126.85, 37.74], [126.855, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.74], [126.805, 37.735], [126.8, 37.735], [126.8, 37.74], [126.805, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.74], [126.8, 37.735], [126.795, 37.735], [126.795, 37.74], [126.8, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.74], [126.795, 37.735], [126.79, 37.735], [126.79, 37.74], [126.795, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.745], [126.935, 37.74], [126.93, 37.74], [126.93, 37.745], [126.935, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.745], [126.93, 37.74], [126.925, 37.74], [126.925, 37.745], [126.93, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.745], [126.925, 37.74], [126.92, 37.74], [126.92, 37.745], [126.925, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.745], [126.92, 37.74], [126.915, 37.74], [126.915, 37.745], [126.92, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.745], [126.915, 37.74], [126.91, 37.74], [126.91, 37.745], [126.915, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 6,
      "ITEM2": 9,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.745], [126.91, 37.74], [126.905, 37.74], [126.905, 37.745], [126.91, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.745], [126.905, 37.74], [126.9, 37.74], [126.9, 37.745], [126.905, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.75], [126.935, 37.745], [126.93, 37.745], [126.93, 37.75], [126.935, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 9,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.75], [126.93, 37.745], [126.925, 37.745], [126.925, 37.75], [126.93, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.75], [126.925, 37.745], [126.92, 37.745], [126.92, 37.75], [126.925, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.75], [126.92, 37.745], [126.915, 37.745], [126.915, 37.75], [126.92, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.75], [126.915, 37.745], [126.91, 37.745], [126.91, 37.75], [126.915, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": 0,
      "MAPIDCD_NO": null,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.75], [126.91, 37.745], [126.905, 37.745], [126.905, 37.75], [126.91, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081852,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.575], [126.86, 37.57], [126.855, 37.57], [126.855, 37.575], [126.86, 37.575]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081851,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.575], [126.855, 37.57], [126.85, 37.57], [126.85, 37.575], [126.855, 37.575]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081760,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.575], [126.85, 37.57], [126.845, 37.57], [126.845, 37.575], [126.85, 37.575]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081846,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.58], [126.88, 37.575], [126.875, 37.575], [126.875, 37.58], [126.88, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081845,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.58], [126.875, 37.575], [126.87, 37.575], [126.87, 37.58], [126.875, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081844,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.58], [126.87, 37.575], [126.865, 37.575], [126.865, 37.58], [126.87, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081843,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.58], [126.865, 37.575], [126.86, 37.575], [126.86, 37.58], [126.865, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081842,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.58], [126.86, 37.575], [126.855, 37.575], [126.855, 37.58], [126.86, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081841,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.58], [126.855, 37.575], [126.85, 37.575], [126.85, 37.58], [126.855, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081750,
      "ITEM1": 6,
      "ITEM2": 1,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.58], [126.85, 37.575], [126.845, 37.575], [126.845, 37.58], [126.85, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081749,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.58], [126.845, 37.575], [126.84, 37.575], [126.84, 37.58], [126.845, 37.58]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081836,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.585], [126.88, 37.58], [126.875, 37.58], [126.875, 37.585], [126.88, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081835,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.585], [126.875, 37.58], [126.87, 37.58], [126.87, 37.585], [126.875, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081834,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.585], [126.87, 37.58], [126.865, 37.58], [126.865, 37.585], [126.87, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081833,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.585], [126.865, 37.58], [126.86, 37.58], [126.86, 37.585], [126.865, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081832,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.585], [126.86, 37.58], [126.855, 37.58], [126.855, 37.585], [126.86, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081831,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 2,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.585], [126.855, 37.58], [126.85, 37.58], [126.85, 37.585], [126.855, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081740,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.585], [126.85, 37.58], [126.845, 37.58], [126.845, 37.585], [126.85, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081739,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.585], [126.845, 37.58], [126.84, 37.58], [126.84, 37.585], [126.845, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081738,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 9,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.585], [126.84, 37.58], [126.835, 37.58], [126.835, 37.585], [126.84, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081737,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.585], [126.835, 37.58], [126.83, 37.58], [126.83, 37.585], [126.835, 37.585]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081830,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.59], [126.9, 37.585], [126.895, 37.585], [126.895, 37.59], [126.9, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081829,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.59], [126.895, 37.585], [126.89, 37.585], [126.89, 37.59], [126.895, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081828,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.59], [126.89, 37.585], [126.885, 37.585], [126.885, 37.59], [126.89, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081827,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.59], [126.885, 37.585], [126.88, 37.585], [126.88, 37.59], [126.885, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081826,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.59], [126.88, 37.585], [126.875, 37.585], [126.875, 37.59], [126.88, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081825,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.59], [126.875, 37.585], [126.87, 37.585], [126.87, 37.59], [126.875, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081824,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.59], [126.87, 37.585], [126.865, 37.585], [126.865, 37.59], [126.87, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081823,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.59], [126.865, 37.585], [126.86, 37.585], [126.86, 37.59], [126.865, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081822,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.59], [126.86, 37.585], [126.855, 37.585], [126.855, 37.59], [126.86, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081821,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.59], [126.855, 37.585], [126.85, 37.585], [126.85, 37.59], [126.855, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081730,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.59], [126.85, 37.585], [126.845, 37.585], [126.845, 37.59], [126.85, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081729,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.59], [126.845, 37.585], [126.84, 37.585], [126.84, 37.59], [126.845, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081728,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.59], [126.84, 37.585], [126.835, 37.585], [126.835, 37.59], [126.84, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081727,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.59], [126.835, 37.585], [126.83, 37.585], [126.83, 37.59], [126.835, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081726,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.59], [126.83, 37.585], [126.825, 37.585], [126.825, 37.59], [126.83, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081725,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.59], [126.825, 37.585], [126.82, 37.585], [126.82, 37.59], [126.825, 37.59]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081911,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.595], [126.905, 37.59], [126.9, 37.59], [126.9, 37.595], [126.905, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081820,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.595], [126.9, 37.59], [126.895, 37.59], [126.895, 37.595], [126.9, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081819,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.595], [126.895, 37.59], [126.89, 37.59], [126.89, 37.595], [126.895, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081818,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.595], [126.89, 37.59], [126.885, 37.59], [126.885, 37.595], [126.89, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081817,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.595], [126.885, 37.59], [126.88, 37.59], [126.88, 37.595], [126.885, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081816,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.595], [126.88, 37.59], [126.875, 37.59], [126.875, 37.595], [126.88, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081815,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.595], [126.875, 37.59], [126.87, 37.59], [126.87, 37.595], [126.875, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081814,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.595], [126.87, 37.59], [126.865, 37.59], [126.865, 37.595], [126.87, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081813,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.595], [126.865, 37.59], [126.86, 37.59], [126.86, 37.595], [126.865, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081812,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.595], [126.86, 37.59], [126.855, 37.59], [126.855, 37.595], [126.86, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081811,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.595], [126.855, 37.59], [126.85, 37.59], [126.85, 37.595], [126.855, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081720,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.595], [126.85, 37.59], [126.845, 37.59], [126.845, 37.595], [126.85, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081719,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.595], [126.845, 37.59], [126.84, 37.59], [126.84, 37.595], [126.845, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081718,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.595], [126.84, 37.59], [126.835, 37.59], [126.835, 37.595], [126.84, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081717,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.595], [126.835, 37.59], [126.83, 37.59], [126.83, 37.595], [126.835, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081716,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.595], [126.83, 37.59], [126.825, 37.59], [126.825, 37.595], [126.83, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081715,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.595], [126.825, 37.59], [126.82, 37.59], [126.82, 37.595], [126.825, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081714,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.595], [126.82, 37.59], [126.815, 37.59], [126.815, 37.595], [126.82, 37.595]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081901,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.6], [126.905, 37.595], [126.9, 37.595], [126.9, 37.6], [126.905, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081810,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.6], [126.9, 37.595], [126.895, 37.595], [126.895, 37.6], [126.9, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081809,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.6], [126.895, 37.595], [126.89, 37.595], [126.89, 37.6], [126.895, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081808,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.6], [126.89, 37.595], [126.885, 37.595], [126.885, 37.6], [126.89, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081807,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.6], [126.885, 37.595], [126.88, 37.595], [126.88, 37.6], [126.885, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081806,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.6], [126.88, 37.595], [126.875, 37.595], [126.875, 37.6], [126.88, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081805,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.6], [126.875, 37.595], [126.87, 37.595], [126.87, 37.6], [126.875, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081804,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.6], [126.87, 37.595], [126.865, 37.595], [126.865, 37.6], [126.87, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081803,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.6], [126.865, 37.595], [126.86, 37.595], [126.86, 37.6], [126.865, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081802,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.6], [126.86, 37.595], [126.855, 37.595], [126.855, 37.6], [126.86, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081801,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 9,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.6], [126.855, 37.595], [126.85, 37.595], [126.85, 37.6], [126.855, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081710,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.6], [126.85, 37.595], [126.845, 37.595], [126.845, 37.6], [126.85, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081709,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.6], [126.845, 37.595], [126.84, 37.595], [126.84, 37.6], [126.845, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081708,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.6], [126.84, 37.595], [126.835, 37.595], [126.835, 37.6], [126.84, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081707,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.6], [126.835, 37.595], [126.83, 37.595], [126.83, 37.6], [126.835, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081706,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.6], [126.83, 37.595], [126.825, 37.595], [126.825, 37.6], [126.83, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081705,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.6], [126.825, 37.595], [126.82, 37.595], [126.82, 37.6], [126.825, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081704,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.6], [126.82, 37.595], [126.815, 37.595], [126.815, 37.6], [126.82, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081703,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.6], [126.815, 37.595], [126.81, 37.595], [126.81, 37.6], [126.815, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081702,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.6], [126.81, 37.595], [126.805, 37.595], [126.805, 37.6], [126.81, 37.6]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081491,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.605], [126.905, 37.6], [126.9, 37.6], [126.9, 37.605], [126.905, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081300,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.605], [126.9, 37.6], [126.895, 37.6], [126.895, 37.605], [126.9, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081399,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.605], [126.895, 37.6], [126.89, 37.6], [126.89, 37.605], [126.895, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081398,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.605], [126.89, 37.6], [126.885, 37.6], [126.885, 37.605], [126.89, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081397,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.605], [126.885, 37.6], [126.88, 37.6], [126.88, 37.605], [126.885, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081396,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.605], [126.88, 37.6], [126.875, 37.6], [126.875, 37.605], [126.88, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081395,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.605], [126.875, 37.6], [126.87, 37.6], [126.87, 37.605], [126.875, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081394,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.605], [126.87, 37.6], [126.865, 37.6], [126.865, 37.605], [126.87, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081393,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.605], [126.865, 37.6], [126.86, 37.6], [126.86, 37.605], [126.865, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081392,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.605], [126.86, 37.6], [126.855, 37.6], [126.855, 37.605], [126.86, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081391,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.605], [126.855, 37.6], [126.85, 37.6], [126.85, 37.605], [126.855, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081200,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.605], [126.85, 37.6], [126.845, 37.6], [126.845, 37.605], [126.85, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081299,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.605], [126.845, 37.6], [126.84, 37.6], [126.84, 37.605], [126.845, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081298,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.605], [126.84, 37.6], [126.835, 37.6], [126.835, 37.605], [126.84, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081297,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.605], [126.835, 37.6], [126.83, 37.6], [126.83, 37.605], [126.835, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081296,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.605], [126.83, 37.6], [126.825, 37.6], [126.825, 37.605], [126.83, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081295,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.605], [126.825, 37.6], [126.82, 37.6], [126.82, 37.605], [126.825, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081294,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.605], [126.82, 37.6], [126.815, 37.6], [126.815, 37.605], [126.82, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081293,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 1,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.605], [126.815, 37.6], [126.81, 37.6], [126.81, 37.605], [126.815, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081292,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.605], [126.81, 37.6], [126.805, 37.6], [126.805, 37.605], [126.81, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081291,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.605], [126.805, 37.6], [126.8, 37.6], [126.8, 37.605], [126.805, 37.605]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081481,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.61], [126.905, 37.605], [126.9, 37.605], [126.9, 37.61], [126.905, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081390,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.61], [126.9, 37.605], [126.895, 37.605], [126.895, 37.61], [126.9, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081389,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.61], [126.895, 37.605], [126.89, 37.605], [126.89, 37.61], [126.895, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081388,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.61], [126.89, 37.605], [126.885, 37.605], [126.885, 37.61], [126.89, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081387,
      "ITEM1": 1,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.61], [126.885, 37.605], [126.88, 37.605], [126.88, 37.61], [126.885, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081386,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.61], [126.88, 37.605], [126.875, 37.605], [126.875, 37.61], [126.88, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081385,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.61], [126.875, 37.605], [126.87, 37.605], [126.87, 37.61], [126.875, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081384,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.61], [126.87, 37.605], [126.865, 37.605], [126.865, 37.61], [126.87, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081383,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.61], [126.865, 37.605], [126.86, 37.605], [126.86, 37.61], [126.865, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081382,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.61], [126.86, 37.605], [126.855, 37.605], [126.855, 37.61], [126.86, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081381,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.61], [126.855, 37.605], [126.85, 37.605], [126.85, 37.61], [126.855, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081290,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.61], [126.85, 37.605], [126.845, 37.605], [126.845, 37.61], [126.85, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081289,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.61], [126.845, 37.605], [126.84, 37.605], [126.84, 37.61], [126.845, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081288,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.61], [126.84, 37.605], [126.835, 37.605], [126.835, 37.61], [126.84, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081287,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.61], [126.835, 37.605], [126.83, 37.605], [126.83, 37.61], [126.835, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081286,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.61], [126.83, 37.605], [126.825, 37.605], [126.825, 37.61], [126.83, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081285,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.61], [126.825, 37.605], [126.82, 37.605], [126.82, 37.61], [126.825, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081284,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.61], [126.82, 37.605], [126.815, 37.605], [126.815, 37.61], [126.82, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081283,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.61], [126.815, 37.605], [126.81, 37.605], [126.81, 37.61], [126.815, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081282,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.61], [126.81, 37.605], [126.805, 37.605], [126.805, 37.61], [126.81, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081281,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.61], [126.805, 37.605], [126.8, 37.605], [126.8, 37.61], [126.805, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081190,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.61], [126.8, 37.605], [126.795, 37.605], [126.795, 37.61], [126.8, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081189,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.61], [126.795, 37.605], [126.79, 37.605], [126.79, 37.61], [126.795, 37.61]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081471,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 8,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.615], [126.905, 37.61], [126.9, 37.61], [126.9, 37.615], [126.905, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081380,
      "ITEM1": 9,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.615], [126.9, 37.61], [126.895, 37.61], [126.895, 37.615], [126.9, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081379,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.615], [126.895, 37.61], [126.89, 37.61], [126.89, 37.615], [126.895, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081378,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.615], [126.89, 37.61], [126.885, 37.61], [126.885, 37.615], [126.89, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081377,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.615], [126.885, 37.61], [126.88, 37.61], [126.88, 37.615], [126.885, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081376,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.615], [126.88, 37.61], [126.875, 37.61], [126.875, 37.615], [126.88, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081375,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.615], [126.875, 37.61], [126.87, 37.61], [126.87, 37.615], [126.875, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081374,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.615], [126.87, 37.61], [126.865, 37.61], [126.865, 37.615], [126.87, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081373,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.615], [126.865, 37.61], [126.86, 37.61], [126.86, 37.615], [126.865, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081372,
      "ITEM1": 3,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.615], [126.86, 37.61], [126.855, 37.61], [126.855, 37.615], [126.86, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081371,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.615], [126.855, 37.61], [126.85, 37.61], [126.85, 37.615], [126.855, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081280,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.615], [126.85, 37.61], [126.845, 37.61], [126.845, 37.615], [126.85, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081279,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.615], [126.845, 37.61], [126.84, 37.61], [126.84, 37.615], [126.845, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081278,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.615], [126.84, 37.61], [126.835, 37.61], [126.835, 37.615], [126.84, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081277,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.615], [126.835, 37.61], [126.83, 37.61], [126.83, 37.615], [126.835, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081276,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.615], [126.83, 37.61], [126.825, 37.61], [126.825, 37.615], [126.83, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081275,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 1,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.615], [126.825, 37.61], [126.82, 37.61], [126.82, 37.615], [126.825, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081274,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.615], [126.82, 37.61], [126.815, 37.61], [126.815, 37.615], [126.82, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081273,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.615], [126.815, 37.61], [126.81, 37.61], [126.81, 37.615], [126.815, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081272,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.615], [126.81, 37.61], [126.805, 37.61], [126.805, 37.615], [126.81, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081271,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.615], [126.805, 37.61], [126.8, 37.61], [126.8, 37.615], [126.805, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081180,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.615], [126.8, 37.61], [126.795, 37.61], [126.795, 37.615], [126.8, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081179,
      "ITEM1": 3,
      "ITEM2": 0,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.615], [126.795, 37.61], [126.79, 37.61], [126.79, 37.615], [126.795, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081178,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.615], [126.79, 37.61], [126.785, 37.61], [126.785, 37.615], [126.79, 37.615]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081462,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.62], [126.91, 37.615], [126.905, 37.615], [126.905, 37.62], [126.91, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081461,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.62], [126.905, 37.615], [126.9, 37.615], [126.9, 37.62], [126.905, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081370,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.62], [126.9, 37.615], [126.895, 37.615], [126.895, 37.62], [126.9, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081369,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.62], [126.895, 37.615], [126.89, 37.615], [126.89, 37.62], [126.895, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081368,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.62], [126.89, 37.615], [126.885, 37.615], [126.885, 37.62], [126.89, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081367,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.62], [126.885, 37.615], [126.88, 37.615], [126.88, 37.62], [126.885, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081366,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.62], [126.88, 37.615], [126.875, 37.615], [126.875, 37.62], [126.88, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081365,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.62], [126.875, 37.615], [126.87, 37.615], [126.87, 37.62], [126.875, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081364,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.62], [126.87, 37.615], [126.865, 37.615], [126.865, 37.62], [126.87, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081363,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.62], [126.865, 37.615], [126.86, 37.615], [126.86, 37.62], [126.865, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081362,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.62], [126.86, 37.615], [126.855, 37.615], [126.855, 37.62], [126.86, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081361,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.62], [126.855, 37.615], [126.85, 37.615], [126.85, 37.62], [126.855, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081270,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.62], [126.85, 37.615], [126.845, 37.615], [126.845, 37.62], [126.85, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081269,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.62], [126.845, 37.615], [126.84, 37.615], [126.84, 37.62], [126.845, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081268,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.62], [126.84, 37.615], [126.835, 37.615], [126.835, 37.62], [126.84, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081267,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 1,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.62], [126.835, 37.615], [126.83, 37.615], [126.83, 37.62], [126.835, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081266,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.62], [126.83, 37.615], [126.825, 37.615], [126.825, 37.62], [126.83, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081265,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.62], [126.825, 37.615], [126.82, 37.615], [126.82, 37.62], [126.825, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081264,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.62], [126.82, 37.615], [126.815, 37.615], [126.815, 37.62], [126.82, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081263,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.62], [126.815, 37.615], [126.81, 37.615], [126.81, 37.62], [126.815, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081262,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.62], [126.81, 37.615], [126.805, 37.615], [126.805, 37.62], [126.81, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081261,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.62], [126.805, 37.615], [126.8, 37.615], [126.8, 37.62], [126.805, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081170,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.62], [126.8, 37.615], [126.795, 37.615], [126.795, 37.62], [126.8, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081169,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.62], [126.795, 37.615], [126.79, 37.615], [126.79, 37.62], [126.795, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081168,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 2,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.62], [126.79, 37.615], [126.785, 37.615], [126.785, 37.62], [126.79, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081167,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.62], [126.785, 37.615], [126.78, 37.615], [126.78, 37.62], [126.785, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081166,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.62], [126.78, 37.615], [126.775, 37.615], [126.775, 37.62], [126.78, 37.62]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081452,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.625], [126.91, 37.62], [126.905, 37.62], [126.905, 37.625], [126.91, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081451,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.625], [126.905, 37.62], [126.9, 37.62], [126.9, 37.625], [126.905, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081360,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.625], [126.9, 37.62], [126.895, 37.62], [126.895, 37.625], [126.9, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081359,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.625], [126.895, 37.62], [126.89, 37.62], [126.89, 37.625], [126.895, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081358,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.625], [126.89, 37.62], [126.885, 37.62], [126.885, 37.625], [126.89, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081357,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.625], [126.885, 37.62], [126.88, 37.62], [126.88, 37.625], [126.885, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081356,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.625], [126.88, 37.62], [126.875, 37.62], [126.875, 37.625], [126.88, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081355,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.625], [126.875, 37.62], [126.87, 37.62], [126.87, 37.625], [126.875, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081354,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.625], [126.87, 37.62], [126.865, 37.62], [126.865, 37.625], [126.87, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081353,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.625], [126.865, 37.62], [126.86, 37.62], [126.86, 37.625], [126.865, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081352,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.625], [126.86, 37.62], [126.855, 37.62], [126.855, 37.625], [126.86, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081351,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.625], [126.855, 37.62], [126.85, 37.62], [126.85, 37.625], [126.855, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081260,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.625], [126.85, 37.62], [126.845, 37.62], [126.845, 37.625], [126.85, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081259,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.625], [126.845, 37.62], [126.84, 37.62], [126.84, 37.625], [126.845, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081258,
      "ITEM1": 6,
      "ITEM2": 1,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.625], [126.84, 37.62], [126.835, 37.62], [126.835, 37.625], [126.84, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081257,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.625], [126.835, 37.62], [126.83, 37.62], [126.83, 37.625], [126.835, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081256,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.625], [126.83, 37.62], [126.825, 37.62], [126.825, 37.625], [126.83, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081255,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.625], [126.825, 37.62], [126.82, 37.62], [126.82, 37.625], [126.825, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081254,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.625], [126.82, 37.62], [126.815, 37.62], [126.815, 37.625], [126.82, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081253,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.625], [126.815, 37.62], [126.81, 37.62], [126.81, 37.625], [126.815, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081252,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.625], [126.81, 37.62], [126.805, 37.62], [126.805, 37.625], [126.81, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081251,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.625], [126.805, 37.62], [126.8, 37.62], [126.8, 37.625], [126.805, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081160,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.625], [126.8, 37.62], [126.795, 37.62], [126.795, 37.625], [126.8, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081159,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.625], [126.795, 37.62], [126.79, 37.62], [126.79, 37.625], [126.795, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081158,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.625], [126.79, 37.62], [126.785, 37.62], [126.785, 37.625], [126.79, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081157,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.625], [126.785, 37.62], [126.78, 37.62], [126.78, 37.625], [126.785, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081156,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.625], [126.78, 37.62], [126.775, 37.62], [126.775, 37.625], [126.78, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081155,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.625], [126.775, 37.62], [126.77, 37.62], [126.77, 37.625], [126.775, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081154,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.625], [126.77, 37.62], [126.765, 37.62], [126.765, 37.625], [126.77, 37.625]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081543,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.63], [126.965, 37.625], [126.96, 37.625], [126.96, 37.63], [126.965, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081542,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.63], [126.96, 37.625], [126.955, 37.625], [126.955, 37.63], [126.96, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081442,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 9,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.63], [126.91, 37.625], [126.905, 37.625], [126.905, 37.63], [126.91, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081441,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.63], [126.905, 37.625], [126.9, 37.625], [126.9, 37.63], [126.905, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081350,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.63], [126.9, 37.625], [126.895, 37.625], [126.895, 37.63], [126.9, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081349,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.63], [126.895, 37.625], [126.89, 37.625], [126.89, 37.63], [126.895, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081348,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.63], [126.89, 37.625], [126.885, 37.625], [126.885, 37.63], [126.89, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081347,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.63], [126.885, 37.625], [126.88, 37.625], [126.88, 37.63], [126.885, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081346,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.63], [126.88, 37.625], [126.875, 37.625], [126.875, 37.63], [126.88, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081345,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.63], [126.875, 37.625], [126.87, 37.625], [126.87, 37.63], [126.875, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081344,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.63], [126.87, 37.625], [126.865, 37.625], [126.865, 37.63], [126.87, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081343,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.63], [126.865, 37.625], [126.86, 37.625], [126.86, 37.63], [126.865, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081342,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.63], [126.86, 37.625], [126.855, 37.625], [126.855, 37.63], [126.86, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081341,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.63], [126.855, 37.625], [126.85, 37.625], [126.85, 37.63], [126.855, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081250,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.63], [126.85, 37.625], [126.845, 37.625], [126.845, 37.63], [126.85, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081249,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.63], [126.845, 37.625], [126.84, 37.625], [126.84, 37.63], [126.845, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081248,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.63], [126.84, 37.625], [126.835, 37.625], [126.835, 37.63], [126.84, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081247,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.63], [126.835, 37.625], [126.83, 37.625], [126.83, 37.63], [126.835, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081246,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.63], [126.83, 37.625], [126.825, 37.625], [126.825, 37.63], [126.83, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081245,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.63], [126.825, 37.625], [126.82, 37.625], [126.82, 37.63], [126.825, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081244,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.63], [126.82, 37.625], [126.815, 37.625], [126.815, 37.63], [126.82, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081243,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.63], [126.815, 37.625], [126.81, 37.625], [126.81, 37.63], [126.815, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081242,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.63], [126.81, 37.625], [126.805, 37.625], [126.805, 37.63], [126.81, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081241,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.63], [126.805, 37.625], [126.8, 37.625], [126.8, 37.63], [126.805, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081150,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.63], [126.8, 37.625], [126.795, 37.625], [126.795, 37.63], [126.8, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081149,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.63], [126.795, 37.625], [126.79, 37.625], [126.79, 37.63], [126.795, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081148,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.63], [126.79, 37.625], [126.785, 37.625], [126.785, 37.63], [126.79, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081147,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.63], [126.785, 37.625], [126.78, 37.625], [126.78, 37.63], [126.785, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081146,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.63], [126.78, 37.625], [126.775, 37.625], [126.775, 37.63], [126.78, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081145,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.63], [126.775, 37.625], [126.77, 37.625], [126.77, 37.63], [126.775, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081144,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.63], [126.77, 37.625], [126.765, 37.625], [126.765, 37.63], [126.77, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081143,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.63], [126.765, 37.625], [126.76, 37.625], [126.76, 37.63], [126.765, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081142,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.63], [126.76, 37.625], [126.755, 37.625], [126.755, 37.63], [126.76, 37.63]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081537,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.635], [126.985, 37.63], [126.98, 37.63], [126.98, 37.635], [126.985, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081536,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.635], [126.98, 37.63], [126.975, 37.63], [126.975, 37.635], [126.98, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081535,
      "ITEM1": 8,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.635], [126.975, 37.63], [126.97, 37.63], [126.97, 37.635], [126.975, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081534,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.635], [126.97, 37.63], [126.965, 37.63], [126.965, 37.635], [126.97, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081533,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.635], [126.965, 37.63], [126.96, 37.63], [126.96, 37.635], [126.965, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081432,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.635], [126.91, 37.63], [126.905, 37.63], [126.905, 37.635], [126.91, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081431,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.635], [126.905, 37.63], [126.9, 37.63], [126.9, 37.635], [126.905, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081340,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.635], [126.9, 37.63], [126.895, 37.63], [126.895, 37.635], [126.9, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081339,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.635], [126.895, 37.63], [126.89, 37.63], [126.89, 37.635], [126.895, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081338,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.635], [126.89, 37.63], [126.885, 37.63], [126.885, 37.635], [126.89, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081337,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.635], [126.885, 37.63], [126.88, 37.63], [126.88, 37.635], [126.885, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081336,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.635], [126.88, 37.63], [126.875, 37.63], [126.875, 37.635], [126.88, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081335,
      "ITEM1": 2,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.635], [126.875, 37.63], [126.87, 37.63], [126.87, 37.635], [126.875, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081334,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.635], [126.87, 37.63], [126.865, 37.63], [126.865, 37.635], [126.87, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081333,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.635], [126.865, 37.63], [126.86, 37.63], [126.86, 37.635], [126.865, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081332,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.635], [126.86, 37.63], [126.855, 37.63], [126.855, 37.635], [126.86, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081331,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.635], [126.855, 37.63], [126.85, 37.63], [126.85, 37.635], [126.855, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081240,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.635], [126.85, 37.63], [126.845, 37.63], [126.845, 37.635], [126.85, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081239,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.635], [126.845, 37.63], [126.84, 37.63], [126.84, 37.635], [126.845, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081238,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.635], [126.84, 37.63], [126.835, 37.63], [126.835, 37.635], [126.84, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081237,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.635], [126.835, 37.63], [126.83, 37.63], [126.83, 37.635], [126.835, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081236,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.635], [126.83, 37.63], [126.825, 37.63], [126.825, 37.635], [126.83, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081235,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.635], [126.825, 37.63], [126.82, 37.63], [126.82, 37.635], [126.825, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081234,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.635], [126.82, 37.63], [126.815, 37.63], [126.815, 37.635], [126.82, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081233,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 0,
      "ITEM6": 6,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.635], [126.815, 37.63], [126.81, 37.63], [126.81, 37.635], [126.815, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081232,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.635], [126.81, 37.63], [126.805, 37.63], [126.805, 37.635], [126.81, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081231,
      "ITEM1": 1,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.635], [126.805, 37.63], [126.8, 37.63], [126.8, 37.635], [126.805, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081140,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.635], [126.8, 37.63], [126.795, 37.63], [126.795, 37.635], [126.8, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081139,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.635], [126.795, 37.63], [126.79, 37.63], [126.79, 37.635], [126.795, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081138,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.635], [126.79, 37.63], [126.785, 37.63], [126.785, 37.635], [126.79, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081137,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.635], [126.785, 37.63], [126.78, 37.63], [126.78, 37.635], [126.785, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081136,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.635], [126.78, 37.63], [126.775, 37.63], [126.775, 37.635], [126.78, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081135,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.635], [126.775, 37.63], [126.77, 37.63], [126.77, 37.635], [126.775, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081134,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.635], [126.77, 37.63], [126.765, 37.63], [126.765, 37.635], [126.77, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081133,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.635], [126.765, 37.63], [126.76, 37.63], [126.76, 37.635], [126.765, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081132,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.635], [126.76, 37.63], [126.755, 37.63], [126.755, 37.635], [126.76, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081131,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.635], [126.755, 37.63], [126.75, 37.63], [126.75, 37.635], [126.755, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071540,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.635], [126.75, 37.63], [126.745, 37.63], [126.745, 37.635], [126.75, 37.635]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081528,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.64], [126.99, 37.635], [126.985, 37.635], [126.985, 37.64], [126.99, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081527,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.64], [126.985, 37.635], [126.98, 37.635], [126.98, 37.64], [126.985, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081526,
      "ITEM1": 8,
      "ITEM2": 1,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.64], [126.98, 37.635], [126.975, 37.635], [126.975, 37.64], [126.98, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081525,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 1,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.64], [126.975, 37.635], [126.97, 37.635], [126.97, 37.64], [126.975, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081524,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.64], [126.97, 37.635], [126.965, 37.635], [126.965, 37.64], [126.97, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081523,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.64], [126.965, 37.635], [126.96, 37.635], [126.96, 37.64], [126.965, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081423,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.64], [126.915, 37.635], [126.91, 37.635], [126.91, 37.64], [126.915, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081422,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.64], [126.91, 37.635], [126.905, 37.635], [126.905, 37.64], [126.91, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081421,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.64], [126.905, 37.635], [126.9, 37.635], [126.9, 37.64], [126.905, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081330,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.64], [126.9, 37.635], [126.895, 37.635], [126.895, 37.64], [126.9, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081329,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.64], [126.895, 37.635], [126.89, 37.635], [126.89, 37.64], [126.895, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081328,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.64], [126.89, 37.635], [126.885, 37.635], [126.885, 37.64], [126.89, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081327,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.64], [126.885, 37.635], [126.88, 37.635], [126.88, 37.64], [126.885, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081326,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.64], [126.88, 37.635], [126.875, 37.635], [126.875, 37.64], [126.88, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081325,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.64], [126.875, 37.635], [126.87, 37.635], [126.87, 37.64], [126.875, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081324,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.64], [126.87, 37.635], [126.865, 37.635], [126.865, 37.64], [126.87, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081323,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.64], [126.865, 37.635], [126.86, 37.635], [126.86, 37.64], [126.865, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081322,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.64], [126.86, 37.635], [126.855, 37.635], [126.855, 37.64], [126.86, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081321,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.64], [126.855, 37.635], [126.85, 37.635], [126.85, 37.64], [126.855, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081230,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.64], [126.85, 37.635], [126.845, 37.635], [126.845, 37.64], [126.85, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081229,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.64], [126.845, 37.635], [126.84, 37.635], [126.84, 37.64], [126.845, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081228,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.64], [126.84, 37.635], [126.835, 37.635], [126.835, 37.64], [126.84, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081227,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.64], [126.835, 37.635], [126.83, 37.635], [126.83, 37.64], [126.835, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081226,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.64], [126.83, 37.635], [126.825, 37.635], [126.825, 37.64], [126.83, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081225,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.64], [126.825, 37.635], [126.82, 37.635], [126.82, 37.64], [126.825, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081224,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.64], [126.82, 37.635], [126.815, 37.635], [126.815, 37.64], [126.82, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081223,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.64], [126.815, 37.635], [126.81, 37.635], [126.81, 37.64], [126.815, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081222,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.64], [126.81, 37.635], [126.805, 37.635], [126.805, 37.64], [126.81, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081221,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.64], [126.805, 37.635], [126.8, 37.635], [126.8, 37.64], [126.805, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081130,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.64], [126.8, 37.635], [126.795, 37.635], [126.795, 37.64], [126.8, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081129,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.64], [126.795, 37.635], [126.79, 37.635], [126.79, 37.64], [126.795, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081128,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.64], [126.79, 37.635], [126.785, 37.635], [126.785, 37.64], [126.79, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081127,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.64], [126.785, 37.635], [126.78, 37.635], [126.78, 37.64], [126.785, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081126,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.64], [126.78, 37.635], [126.775, 37.635], [126.775, 37.64], [126.78, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081125,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.64], [126.775, 37.635], [126.77, 37.635], [126.77, 37.64], [126.775, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081124,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.64], [126.77, 37.635], [126.765, 37.635], [126.765, 37.64], [126.77, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081123,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 1,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.64], [126.765, 37.635], [126.76, 37.635], [126.76, 37.64], [126.765, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081122,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.64], [126.76, 37.635], [126.755, 37.635], [126.755, 37.64], [126.76, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081121,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.64], [126.755, 37.635], [126.75, 37.635], [126.75, 37.64], [126.755, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071530,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.64], [126.75, 37.635], [126.745, 37.635], [126.745, 37.64], [126.75, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071529,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.64], [126.745, 37.635], [126.74, 37.635], [126.74, 37.64], [126.745, 37.64]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081518,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.645], [126.99, 37.64], [126.985, 37.64], [126.985, 37.645], [126.99, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081517,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.645], [126.985, 37.64], [126.98, 37.64], [126.98, 37.645], [126.985, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081516,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.645], [126.98, 37.64], [126.975, 37.64], [126.975, 37.645], [126.98, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081515,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.645], [126.975, 37.64], [126.97, 37.64], [126.97, 37.645], [126.975, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081514,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.645], [126.97, 37.64], [126.965, 37.64], [126.965, 37.645], [126.97, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081513,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.645], [126.965, 37.64], [126.96, 37.64], [126.96, 37.645], [126.965, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081512,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 1,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.645], [126.96, 37.64], [126.955, 37.64], [126.955, 37.645], [126.96, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081413,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.645], [126.915, 37.64], [126.91, 37.64], [126.91, 37.645], [126.915, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081412,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.645], [126.91, 37.64], [126.905, 37.64], [126.905, 37.645], [126.91, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081411,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.645], [126.905, 37.64], [126.9, 37.64], [126.9, 37.645], [126.905, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081320,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.645], [126.9, 37.64], [126.895, 37.64], [126.895, 37.645], [126.9, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081319,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.645], [126.895, 37.64], [126.89, 37.64], [126.89, 37.645], [126.895, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081318,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.645], [126.89, 37.64], [126.885, 37.64], [126.885, 37.645], [126.89, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081317,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.645], [126.885, 37.64], [126.88, 37.64], [126.88, 37.645], [126.885, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081316,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.645], [126.88, 37.64], [126.875, 37.64], [126.875, 37.645], [126.88, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081315,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.645], [126.875, 37.64], [126.87, 37.64], [126.87, 37.645], [126.875, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081314,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.645], [126.87, 37.64], [126.865, 37.64], [126.865, 37.645], [126.87, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081313,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.645], [126.865, 37.64], [126.86, 37.64], [126.86, 37.645], [126.865, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081312,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.645], [126.86, 37.64], [126.855, 37.64], [126.855, 37.645], [126.86, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081311,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.645], [126.855, 37.64], [126.85, 37.64], [126.85, 37.645], [126.855, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081220,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 0,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.645], [126.85, 37.64], [126.845, 37.64], [126.845, 37.645], [126.85, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081219,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.645], [126.845, 37.64], [126.84, 37.64], [126.84, 37.645], [126.845, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081218,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.645], [126.84, 37.64], [126.835, 37.64], [126.835, 37.645], [126.84, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081217,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.645], [126.835, 37.64], [126.83, 37.64], [126.83, 37.645], [126.835, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081216,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.645], [126.83, 37.64], [126.825, 37.64], [126.825, 37.645], [126.83, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081215,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 1,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.645], [126.825, 37.64], [126.82, 37.64], [126.82, 37.645], [126.825, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081214,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.645], [126.82, 37.64], [126.815, 37.64], [126.815, 37.645], [126.82, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081213,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.645], [126.815, 37.64], [126.81, 37.64], [126.81, 37.645], [126.815, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081212,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.645], [126.81, 37.64], [126.805, 37.64], [126.805, 37.645], [126.81, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081211,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.645], [126.805, 37.64], [126.8, 37.64], [126.8, 37.645], [126.805, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081120,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.645], [126.8, 37.64], [126.795, 37.64], [126.795, 37.645], [126.8, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081119,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.645], [126.795, 37.64], [126.79, 37.64], [126.79, 37.645], [126.795, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081118,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.645], [126.79, 37.64], [126.785, 37.64], [126.785, 37.645], [126.79, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081117,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.645], [126.785, 37.64], [126.78, 37.64], [126.78, 37.645], [126.785, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081116,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.645], [126.78, 37.64], [126.775, 37.64], [126.775, 37.645], [126.78, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081115,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.645], [126.775, 37.64], [126.77, 37.64], [126.77, 37.645], [126.775, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081114,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.645], [126.77, 37.64], [126.765, 37.64], [126.765, 37.645], [126.77, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081113,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 9,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.645], [126.765, 37.64], [126.76, 37.64], [126.76, 37.645], [126.765, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081112,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.645], [126.76, 37.64], [126.755, 37.64], [126.755, 37.645], [126.76, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081111,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.645], [126.755, 37.64], [126.75, 37.64], [126.75, 37.645], [126.755, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071520,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.645], [126.75, 37.64], [126.745, 37.64], [126.745, 37.645], [126.75, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071519,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.645], [126.745, 37.64], [126.74, 37.64], [126.74, 37.645], [126.745, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071518,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.645], [126.74, 37.64], [126.735, 37.64], [126.735, 37.645], [126.74, 37.645]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081508,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.65], [126.99, 37.645], [126.985, 37.645], [126.985, 37.65], [126.99, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081507,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.65], [126.985, 37.645], [126.98, 37.645], [126.98, 37.65], [126.985, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081506,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.65], [126.98, 37.645], [126.975, 37.645], [126.975, 37.65], [126.98, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081505,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.65], [126.975, 37.645], [126.97, 37.645], [126.97, 37.65], [126.975, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081504,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.65], [126.97, 37.645], [126.965, 37.645], [126.965, 37.65], [126.97, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081503,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 1,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.65], [126.965, 37.645], [126.96, 37.645], [126.96, 37.65], [126.965, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081502,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.65], [126.96, 37.645], [126.955, 37.645], [126.955, 37.65], [126.96, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081406,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.65], [126.93, 37.645], [126.925, 37.645], [126.925, 37.65], [126.93, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081405,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.65], [126.925, 37.645], [126.92, 37.645], [126.92, 37.65], [126.925, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081404,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.65], [126.92, 37.645], [126.915, 37.645], [126.915, 37.65], [126.92, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081403,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.65], [126.915, 37.645], [126.91, 37.645], [126.91, 37.65], [126.915, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081402,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.65], [126.91, 37.645], [126.905, 37.645], [126.905, 37.65], [126.91, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081401,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.65], [126.905, 37.645], [126.9, 37.645], [126.9, 37.65], [126.905, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081310,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.65], [126.9, 37.645], [126.895, 37.645], [126.895, 37.65], [126.9, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081309,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.65], [126.895, 37.645], [126.89, 37.645], [126.89, 37.65], [126.895, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081308,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.65], [126.89, 37.645], [126.885, 37.645], [126.885, 37.65], [126.89, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081307,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.65], [126.885, 37.645], [126.88, 37.645], [126.88, 37.65], [126.885, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081306,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.65], [126.88, 37.645], [126.875, 37.645], [126.875, 37.65], [126.88, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081305,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.65], [126.875, 37.645], [126.87, 37.645], [126.87, 37.65], [126.875, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081304,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.65], [126.87, 37.645], [126.865, 37.645], [126.865, 37.65], [126.87, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081303,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 1,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.65], [126.865, 37.645], [126.86, 37.645], [126.86, 37.65], [126.865, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081302,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.65], [126.86, 37.645], [126.855, 37.645], [126.855, 37.65], [126.86, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081301,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.65], [126.855, 37.645], [126.85, 37.645], [126.85, 37.65], [126.855, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081210,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.65], [126.85, 37.645], [126.845, 37.645], [126.845, 37.65], [126.85, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081209,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.65], [126.845, 37.645], [126.84, 37.645], [126.84, 37.65], [126.845, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081208,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.65], [126.84, 37.645], [126.835, 37.645], [126.835, 37.65], [126.84, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081207,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.65], [126.835, 37.645], [126.83, 37.645], [126.83, 37.65], [126.835, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081206,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.65], [126.83, 37.645], [126.825, 37.645], [126.825, 37.65], [126.83, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081205,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.65], [126.825, 37.645], [126.82, 37.645], [126.82, 37.65], [126.825, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081204,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.65], [126.82, 37.645], [126.815, 37.645], [126.815, 37.65], [126.82, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081203,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.65], [126.815, 37.645], [126.81, 37.645], [126.81, 37.65], [126.815, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081202,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.65], [126.81, 37.645], [126.805, 37.645], [126.805, 37.65], [126.81, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081201,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.65], [126.805, 37.645], [126.8, 37.645], [126.8, 37.65], [126.805, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081110,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 0,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.65], [126.8, 37.645], [126.795, 37.645], [126.795, 37.65], [126.8, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081109,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.65], [126.795, 37.645], [126.79, 37.645], [126.79, 37.65], [126.795, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081108,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.65], [126.79, 37.645], [126.785, 37.645], [126.785, 37.65], [126.79, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081107,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.65], [126.785, 37.645], [126.78, 37.645], [126.78, 37.65], [126.785, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081106,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.65], [126.78, 37.645], [126.775, 37.645], [126.775, 37.65], [126.78, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081105,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 1,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.65], [126.775, 37.645], [126.77, 37.645], [126.77, 37.65], [126.775, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081104,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.65], [126.77, 37.645], [126.765, 37.645], [126.765, 37.65], [126.77, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081103,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.65], [126.765, 37.645], [126.76, 37.645], [126.76, 37.65], [126.765, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081102,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.65], [126.76, 37.645], [126.755, 37.645], [126.755, 37.65], [126.76, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081101,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.65], [126.755, 37.645], [126.75, 37.645], [126.75, 37.65], [126.755, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071510,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.65], [126.75, 37.645], [126.745, 37.645], [126.745, 37.65], [126.75, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071509,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.65], [126.745, 37.645], [126.74, 37.645], [126.74, 37.65], [126.745, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071508,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.65], [126.74, 37.645], [126.735, 37.645], [126.735, 37.65], [126.74, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071507,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.65], [126.735, 37.645], [126.73, 37.645], [126.73, 37.65], [126.735, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071506,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.65], [126.73, 37.645], [126.725, 37.645], [126.725, 37.65], [126.73, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071505,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.725, 37.65], [126.725, 37.645], [126.72, 37.645], [126.72, 37.65], [126.725, 37.65]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081097,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.655], [126.985, 37.65], [126.98, 37.65], [126.98, 37.655], [126.985, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081096,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.655], [126.98, 37.65], [126.975, 37.65], [126.975, 37.655], [126.98, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081095,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.655], [126.975, 37.65], [126.97, 37.65], [126.97, 37.655], [126.975, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081094,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.655], [126.97, 37.65], [126.965, 37.65], [126.965, 37.655], [126.97, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081093,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.655], [126.965, 37.65], [126.96, 37.65], [126.96, 37.655], [126.965, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081092,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.655], [126.96, 37.65], [126.955, 37.65], [126.955, 37.655], [126.96, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081091,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.655], [126.955, 37.65], [126.95, 37.65], [126.95, 37.655], [126.955, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080998,
      "ITEM1": 1,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.655], [126.94, 37.65], [126.935, 37.65], [126.935, 37.655], [126.94, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080997,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.655], [126.935, 37.65], [126.93, 37.65], [126.93, 37.655], [126.935, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080996,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.655], [126.93, 37.65], [126.925, 37.65], [126.925, 37.655], [126.93, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080995,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.655], [126.925, 37.65], [126.92, 37.65], [126.92, 37.655], [126.925, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080994,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.655], [126.92, 37.65], [126.915, 37.65], [126.915, 37.655], [126.92, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080993,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.655], [126.915, 37.65], [126.91, 37.65], [126.91, 37.655], [126.915, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080992,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.655], [126.91, 37.65], [126.905, 37.65], [126.905, 37.655], [126.91, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080991,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.655], [126.905, 37.65], [126.9, 37.65], [126.9, 37.655], [126.905, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080800,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.655], [126.9, 37.65], [126.895, 37.65], [126.895, 37.655], [126.9, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080899,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.655], [126.895, 37.65], [126.89, 37.65], [126.89, 37.655], [126.895, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080898,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.655], [126.89, 37.65], [126.885, 37.65], [126.885, 37.655], [126.89, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080897,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.655], [126.885, 37.65], [126.88, 37.65], [126.88, 37.655], [126.885, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080896,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.655], [126.88, 37.65], [126.875, 37.65], [126.875, 37.655], [126.88, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080895,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.655], [126.875, 37.65], [126.87, 37.65], [126.87, 37.655], [126.875, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080894,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 9,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.655], [126.87, 37.65], [126.865, 37.65], [126.865, 37.655], [126.87, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080893,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.655], [126.865, 37.65], [126.86, 37.65], [126.86, 37.655], [126.865, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080892,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.655], [126.86, 37.65], [126.855, 37.65], [126.855, 37.655], [126.86, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080891,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.655], [126.855, 37.65], [126.85, 37.65], [126.85, 37.655], [126.855, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080700,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.655], [126.85, 37.65], [126.845, 37.65], [126.845, 37.655], [126.85, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080799,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.655], [126.845, 37.65], [126.84, 37.65], [126.84, 37.655], [126.845, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080798,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.655], [126.84, 37.65], [126.835, 37.65], [126.835, 37.655], [126.84, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080797,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.655], [126.835, 37.65], [126.83, 37.65], [126.83, 37.655], [126.835, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080796,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.655], [126.83, 37.65], [126.825, 37.65], [126.825, 37.655], [126.83, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080795,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.655], [126.825, 37.65], [126.82, 37.65], [126.82, 37.655], [126.825, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080794,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.655], [126.82, 37.65], [126.815, 37.65], [126.815, 37.655], [126.82, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080793,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.655], [126.815, 37.65], [126.81, 37.65], [126.81, 37.655], [126.815, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080792,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.655], [126.81, 37.65], [126.805, 37.65], [126.805, 37.655], [126.81, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080791,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.655], [126.805, 37.65], [126.8, 37.65], [126.8, 37.655], [126.805, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080600,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.655], [126.8, 37.65], [126.795, 37.65], [126.795, 37.655], [126.8, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080699,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.655], [126.795, 37.65], [126.79, 37.65], [126.79, 37.655], [126.795, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080698,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.655], [126.79, 37.65], [126.785, 37.65], [126.785, 37.655], [126.79, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080697,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.655], [126.785, 37.65], [126.78, 37.65], [126.78, 37.655], [126.785, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080696,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.655], [126.78, 37.65], [126.775, 37.65], [126.775, 37.655], [126.78, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080695,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.655], [126.775, 37.65], [126.77, 37.65], [126.77, 37.655], [126.775, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080694,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.655], [126.77, 37.65], [126.765, 37.65], [126.765, 37.655], [126.77, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080693,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.655], [126.765, 37.65], [126.76, 37.65], [126.76, 37.655], [126.765, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080692,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.655], [126.76, 37.65], [126.755, 37.65], [126.755, 37.655], [126.76, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080691,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.655], [126.755, 37.65], [126.75, 37.65], [126.75, 37.655], [126.755, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071000,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.655], [126.75, 37.65], [126.745, 37.65], [126.745, 37.655], [126.75, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071099,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.655], [126.745, 37.65], [126.74, 37.65], [126.74, 37.655], [126.745, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071098,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.655], [126.74, 37.65], [126.735, 37.65], [126.735, 37.655], [126.74, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071097,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.655], [126.735, 37.65], [126.73, 37.65], [126.73, 37.655], [126.735, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071096,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.655], [126.73, 37.65], [126.725, 37.65], [126.725, 37.655], [126.73, 37.655]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081088,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.66], [126.99, 37.655], [126.985, 37.655], [126.985, 37.66], [126.99, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081087,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.66], [126.985, 37.655], [126.98, 37.655], [126.98, 37.66], [126.985, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081086,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 1,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.66], [126.98, 37.655], [126.975, 37.655], [126.975, 37.66], [126.98, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081085,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.66], [126.975, 37.655], [126.97, 37.655], [126.97, 37.66], [126.975, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081084,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.66], [126.97, 37.655], [126.965, 37.655], [126.965, 37.66], [126.97, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081083,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.66], [126.965, 37.655], [126.96, 37.655], [126.96, 37.66], [126.965, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081082,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.66], [126.96, 37.655], [126.955, 37.655], [126.955, 37.66], [126.96, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081081,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.66], [126.955, 37.655], [126.95, 37.655], [126.95, 37.66], [126.955, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080990,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.66], [126.95, 37.655], [126.945, 37.655], [126.945, 37.66], [126.95, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080989,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.66], [126.945, 37.655], [126.94, 37.655], [126.94, 37.66], [126.945, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080988,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.66], [126.94, 37.655], [126.935, 37.655], [126.935, 37.66], [126.94, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080987,
      "ITEM1": 4,
      "ITEM2": 0,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.66], [126.935, 37.655], [126.93, 37.655], [126.93, 37.66], [126.935, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080986,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.66], [126.93, 37.655], [126.925, 37.655], [126.925, 37.66], [126.93, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080985,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.66], [126.925, 37.655], [126.92, 37.655], [126.92, 37.66], [126.925, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080984,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.66], [126.92, 37.655], [126.915, 37.655], [126.915, 37.66], [126.92, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080983,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.66], [126.915, 37.655], [126.91, 37.655], [126.91, 37.66], [126.915, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080982,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.66], [126.91, 37.655], [126.905, 37.655], [126.905, 37.66], [126.91, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080981,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.66], [126.905, 37.655], [126.9, 37.655], [126.9, 37.66], [126.905, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080890,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.66], [126.9, 37.655], [126.895, 37.655], [126.895, 37.66], [126.9, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080889,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.66], [126.895, 37.655], [126.89, 37.655], [126.89, 37.66], [126.895, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080888,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.66], [126.89, 37.655], [126.885, 37.655], [126.885, 37.66], [126.89, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080887,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.66], [126.885, 37.655], [126.88, 37.655], [126.88, 37.66], [126.885, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080886,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.66], [126.88, 37.655], [126.875, 37.655], [126.875, 37.66], [126.88, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080885,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.66], [126.875, 37.655], [126.87, 37.655], [126.87, 37.66], [126.875, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080884,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.66], [126.87, 37.655], [126.865, 37.655], [126.865, 37.66], [126.87, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080883,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.66], [126.865, 37.655], [126.86, 37.655], [126.86, 37.66], [126.865, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080882,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.66], [126.86, 37.655], [126.855, 37.655], [126.855, 37.66], [126.86, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080881,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.66], [126.855, 37.655], [126.85, 37.655], [126.85, 37.66], [126.855, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080790,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 1,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.66], [126.85, 37.655], [126.845, 37.655], [126.845, 37.66], [126.85, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080789,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.66], [126.845, 37.655], [126.84, 37.655], [126.84, 37.66], [126.845, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080788,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.66], [126.84, 37.655], [126.835, 37.655], [126.835, 37.66], [126.84, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080787,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.66], [126.835, 37.655], [126.83, 37.655], [126.83, 37.66], [126.835, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080786,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.66], [126.83, 37.655], [126.825, 37.655], [126.825, 37.66], [126.83, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080785,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.66], [126.825, 37.655], [126.82, 37.655], [126.82, 37.66], [126.825, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080784,
      "ITEM1": 7,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.66], [126.82, 37.655], [126.815, 37.655], [126.815, 37.66], [126.82, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080783,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.66], [126.815, 37.655], [126.81, 37.655], [126.81, 37.66], [126.815, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080782,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 9,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.66], [126.81, 37.655], [126.805, 37.655], [126.805, 37.66], [126.81, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080781,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.66], [126.805, 37.655], [126.8, 37.655], [126.8, 37.66], [126.805, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080690,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.66], [126.8, 37.655], [126.795, 37.655], [126.795, 37.66], [126.8, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080689,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.66], [126.795, 37.655], [126.79, 37.655], [126.79, 37.66], [126.795, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080688,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.66], [126.79, 37.655], [126.785, 37.655], [126.785, 37.66], [126.79, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080687,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.66], [126.785, 37.655], [126.78, 37.655], [126.78, 37.66], [126.785, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080686,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.66], [126.78, 37.655], [126.775, 37.655], [126.775, 37.66], [126.78, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080685,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.66], [126.775, 37.655], [126.77, 37.655], [126.77, 37.66], [126.775, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080684,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.66], [126.77, 37.655], [126.765, 37.655], [126.765, 37.66], [126.77, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080683,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.66], [126.765, 37.655], [126.76, 37.655], [126.76, 37.66], [126.765, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080682,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.66], [126.76, 37.655], [126.755, 37.655], [126.755, 37.66], [126.76, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080681,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.66], [126.755, 37.655], [126.75, 37.655], [126.75, 37.66], [126.755, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071090,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.66], [126.75, 37.655], [126.745, 37.655], [126.745, 37.66], [126.75, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071089,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.66], [126.745, 37.655], [126.74, 37.655], [126.74, 37.66], [126.745, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071088,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.66], [126.74, 37.655], [126.735, 37.655], [126.735, 37.66], [126.74, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071087,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.66], [126.735, 37.655], [126.73, 37.655], [126.73, 37.66], [126.735, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071086,
      "ITEM1": 9,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.66], [126.73, 37.655], [126.725, 37.655], [126.725, 37.66], [126.73, 37.66]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081079,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.665], [126.995, 37.66], [126.99, 37.66], [126.99, 37.665], [126.995, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081078,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.665], [126.99, 37.66], [126.985, 37.66], [126.985, 37.665], [126.99, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081077,
      "ITEM1": 3,
      "ITEM2": 9,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 10
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.665], [126.985, 37.66], [126.98, 37.66], [126.98, 37.665], [126.985, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081076,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.665], [126.98, 37.66], [126.975, 37.66], [126.975, 37.665], [126.98, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081075,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.665], [126.975, 37.66], [126.97, 37.66], [126.97, 37.665], [126.975, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081074,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.665], [126.97, 37.66], [126.965, 37.66], [126.965, 37.665], [126.97, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081073,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 1,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.665], [126.965, 37.66], [126.96, 37.66], [126.96, 37.665], [126.965, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081072,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.665], [126.96, 37.66], [126.955, 37.66], [126.955, 37.665], [126.96, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081071,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.665], [126.955, 37.66], [126.95, 37.66], [126.95, 37.665], [126.955, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080980,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.665], [126.95, 37.66], [126.945, 37.66], [126.945, 37.665], [126.95, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080979,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.665], [126.945, 37.66], [126.94, 37.66], [126.94, 37.665], [126.945, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080978,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.665], [126.94, 37.66], [126.935, 37.66], [126.935, 37.665], [126.94, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080977,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.665], [126.935, 37.66], [126.93, 37.66], [126.93, 37.665], [126.935, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080976,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.665], [126.93, 37.66], [126.925, 37.66], [126.925, 37.665], [126.93, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080975,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.665], [126.925, 37.66], [126.92, 37.66], [126.92, 37.665], [126.925, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080974,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.665], [126.92, 37.66], [126.915, 37.66], [126.915, 37.665], [126.92, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080973,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.665], [126.915, 37.66], [126.91, 37.66], [126.91, 37.665], [126.915, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080972,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 1,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.665], [126.91, 37.66], [126.905, 37.66], [126.905, 37.665], [126.91, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080971,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.665], [126.905, 37.66], [126.9, 37.66], [126.9, 37.665], [126.905, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080880,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.665], [126.9, 37.66], [126.895, 37.66], [126.895, 37.665], [126.9, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080879,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.665], [126.895, 37.66], [126.89, 37.66], [126.89, 37.665], [126.895, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080878,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.665], [126.89, 37.66], [126.885, 37.66], [126.885, 37.665], [126.89, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080877,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.665], [126.885, 37.66], [126.88, 37.66], [126.88, 37.665], [126.885, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080876,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.665], [126.88, 37.66], [126.875, 37.66], [126.875, 37.665], [126.88, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080875,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.665], [126.875, 37.66], [126.87, 37.66], [126.87, 37.665], [126.875, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080874,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.665], [126.87, 37.66], [126.865, 37.66], [126.865, 37.665], [126.87, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080873,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.665], [126.865, 37.66], [126.86, 37.66], [126.86, 37.665], [126.865, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080872,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.665], [126.86, 37.66], [126.855, 37.66], [126.855, 37.665], [126.86, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080871,
      "ITEM1": 2,
      "ITEM2": 9,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 10
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.665], [126.855, 37.66], [126.85, 37.66], [126.85, 37.665], [126.855, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080780,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.665], [126.85, 37.66], [126.845, 37.66], [126.845, 37.665], [126.85, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080779,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.665], [126.845, 37.66], [126.84, 37.66], [126.84, 37.665], [126.845, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080778,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.665], [126.84, 37.66], [126.835, 37.66], [126.835, 37.665], [126.84, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080777,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.665], [126.835, 37.66], [126.83, 37.66], [126.83, 37.665], [126.835, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080776,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.665], [126.83, 37.66], [126.825, 37.66], [126.825, 37.665], [126.83, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080775,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.665], [126.825, 37.66], [126.82, 37.66], [126.82, 37.665], [126.825, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080774,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.665], [126.82, 37.66], [126.815, 37.66], [126.815, 37.665], [126.82, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080773,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.665], [126.815, 37.66], [126.81, 37.66], [126.81, 37.665], [126.815, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080772,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.665], [126.81, 37.66], [126.805, 37.66], [126.805, 37.665], [126.81, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080771,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.665], [126.805, 37.66], [126.8, 37.66], [126.8, 37.665], [126.805, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080680,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.665], [126.8, 37.66], [126.795, 37.66], [126.795, 37.665], [126.8, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080679,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.665], [126.795, 37.66], [126.79, 37.66], [126.79, 37.665], [126.795, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080678,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.665], [126.79, 37.66], [126.785, 37.66], [126.785, 37.665], [126.79, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080677,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.665], [126.785, 37.66], [126.78, 37.66], [126.78, 37.665], [126.785, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080676,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.665], [126.78, 37.66], [126.775, 37.66], [126.775, 37.665], [126.78, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080675,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.665], [126.775, 37.66], [126.77, 37.66], [126.77, 37.665], [126.775, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080674,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.665], [126.77, 37.66], [126.765, 37.66], [126.765, 37.665], [126.77, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080673,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 1,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.665], [126.765, 37.66], [126.76, 37.66], [126.76, 37.665], [126.765, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080672,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 9
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.665], [126.76, 37.66], [126.755, 37.66], [126.755, 37.665], [126.76, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080671,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 1,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.665], [126.755, 37.66], [126.75, 37.66], [126.75, 37.665], [126.755, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071080,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.665], [126.75, 37.66], [126.745, 37.66], [126.745, 37.665], [126.75, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071079,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.665], [126.745, 37.66], [126.74, 37.66], [126.74, 37.665], [126.745, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071078,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.665], [126.74, 37.66], [126.735, 37.66], [126.735, 37.665], [126.74, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071077,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.665], [126.735, 37.66], [126.73, 37.66], [126.73, 37.665], [126.735, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071076,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.665], [126.73, 37.66], [126.725, 37.66], [126.725, 37.665], [126.73, 37.665]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081069,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.67], [126.995, 37.665], [126.99, 37.665], [126.99, 37.67], [126.995, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081068,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.67], [126.99, 37.665], [126.985, 37.665], [126.985, 37.67], [126.99, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081067,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.67], [126.985, 37.665], [126.98, 37.665], [126.98, 37.67], [126.985, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081066,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.67], [126.98, 37.665], [126.975, 37.665], [126.975, 37.67], [126.98, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081065,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.67], [126.975, 37.665], [126.97, 37.665], [126.97, 37.67], [126.975, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081064,
      "ITEM1": 1,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.67], [126.97, 37.665], [126.965, 37.665], [126.965, 37.67], [126.97, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081063,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.67], [126.965, 37.665], [126.96, 37.665], [126.96, 37.67], [126.965, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081062,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.67], [126.96, 37.665], [126.955, 37.665], [126.955, 37.67], [126.96, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081061,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.67], [126.955, 37.665], [126.95, 37.665], [126.95, 37.67], [126.955, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080970,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.67], [126.95, 37.665], [126.945, 37.665], [126.945, 37.67], [126.95, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080969,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.67], [126.945, 37.665], [126.94, 37.665], [126.94, 37.67], [126.945, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080968,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.67], [126.94, 37.665], [126.935, 37.665], [126.935, 37.67], [126.94, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080967,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.67], [126.935, 37.665], [126.93, 37.665], [126.93, 37.67], [126.935, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080966,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 2,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.67], [126.93, 37.665], [126.925, 37.665], [126.925, 37.67], [126.93, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080965,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.67], [126.925, 37.665], [126.92, 37.665], [126.92, 37.67], [126.925, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080964,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.67], [126.92, 37.665], [126.915, 37.665], [126.915, 37.67], [126.92, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080963,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.67], [126.915, 37.665], [126.91, 37.665], [126.91, 37.67], [126.915, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080962,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.67], [126.91, 37.665], [126.905, 37.665], [126.905, 37.67], [126.91, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080961,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.67], [126.905, 37.665], [126.9, 37.665], [126.9, 37.67], [126.905, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080870,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.67], [126.9, 37.665], [126.895, 37.665], [126.895, 37.67], [126.9, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080869,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.67], [126.895, 37.665], [126.89, 37.665], [126.89, 37.67], [126.895, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080868,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.67], [126.89, 37.665], [126.885, 37.665], [126.885, 37.67], [126.89, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080867,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.67], [126.885, 37.665], [126.88, 37.665], [126.88, 37.67], [126.885, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080866,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.67], [126.88, 37.665], [126.875, 37.665], [126.875, 37.67], [126.88, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080865,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.67], [126.875, 37.665], [126.87, 37.665], [126.87, 37.67], [126.875, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080864,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.67], [126.87, 37.665], [126.865, 37.665], [126.865, 37.67], [126.87, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080863,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.67], [126.865, 37.665], [126.86, 37.665], [126.86, 37.67], [126.865, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080862,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.67], [126.86, 37.665], [126.855, 37.665], [126.855, 37.67], [126.86, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080861,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.67], [126.855, 37.665], [126.85, 37.665], [126.85, 37.67], [126.855, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080770,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.67], [126.85, 37.665], [126.845, 37.665], [126.845, 37.67], [126.85, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080769,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.67], [126.845, 37.665], [126.84, 37.665], [126.84, 37.67], [126.845, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080768,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.67], [126.84, 37.665], [126.835, 37.665], [126.835, 37.67], [126.84, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080767,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.67], [126.835, 37.665], [126.83, 37.665], [126.83, 37.67], [126.835, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080766,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.67], [126.83, 37.665], [126.825, 37.665], [126.825, 37.67], [126.83, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080765,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.67], [126.825, 37.665], [126.82, 37.665], [126.82, 37.67], [126.825, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080764,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.67], [126.82, 37.665], [126.815, 37.665], [126.815, 37.67], [126.82, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080763,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.67], [126.815, 37.665], [126.81, 37.665], [126.81, 37.67], [126.815, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080762,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 9,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.67], [126.81, 37.665], [126.805, 37.665], [126.805, 37.67], [126.81, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080761,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.67], [126.805, 37.665], [126.8, 37.665], [126.8, 37.67], [126.805, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080670,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.67], [126.8, 37.665], [126.795, 37.665], [126.795, 37.67], [126.8, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080669,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.67], [126.795, 37.665], [126.79, 37.665], [126.79, 37.67], [126.795, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080668,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.67], [126.79, 37.665], [126.785, 37.665], [126.785, 37.67], [126.79, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080667,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.67], [126.785, 37.665], [126.78, 37.665], [126.78, 37.67], [126.785, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080666,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.67], [126.78, 37.665], [126.775, 37.665], [126.775, 37.67], [126.78, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080665,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.67], [126.775, 37.665], [126.77, 37.665], [126.77, 37.67], [126.775, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080664,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.67], [126.77, 37.665], [126.765, 37.665], [126.765, 37.67], [126.77, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080663,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.67], [126.765, 37.665], [126.76, 37.665], [126.76, 37.67], [126.765, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080662,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 0,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.67], [126.76, 37.665], [126.755, 37.665], [126.755, 37.67], [126.76, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080661,
      "ITEM1": 4,
      "ITEM2": 9,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.67], [126.755, 37.665], [126.75, 37.665], [126.75, 37.67], [126.755, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071070,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.67], [126.75, 37.665], [126.745, 37.665], [126.745, 37.67], [126.75, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071069,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.67], [126.745, 37.665], [126.74, 37.665], [126.74, 37.67], [126.745, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071068,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.67], [126.74, 37.665], [126.735, 37.665], [126.735, 37.67], [126.74, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071067,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.67], [126.735, 37.665], [126.73, 37.665], [126.73, 37.67], [126.735, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071066,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.67], [126.73, 37.665], [126.725, 37.665], [126.725, 37.67], [126.73, 37.67]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081059,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.675], [126.995, 37.67], [126.99, 37.67], [126.99, 37.675], [126.995, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081058,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.675], [126.99, 37.67], [126.985, 37.67], [126.985, 37.675], [126.99, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081057,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 1,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.675], [126.985, 37.67], [126.98, 37.67], [126.98, 37.675], [126.985, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081056,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.675], [126.98, 37.67], [126.975, 37.67], [126.975, 37.675], [126.98, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081055,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.675], [126.975, 37.67], [126.97, 37.67], [126.97, 37.675], [126.975, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081054,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.675], [126.97, 37.67], [126.965, 37.67], [126.965, 37.675], [126.97, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081053,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.675], [126.965, 37.67], [126.96, 37.67], [126.96, 37.675], [126.965, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081052,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.675], [126.96, 37.67], [126.955, 37.67], [126.955, 37.675], [126.96, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081051,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.675], [126.955, 37.67], [126.95, 37.67], [126.95, 37.675], [126.955, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080960,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.675], [126.95, 37.67], [126.945, 37.67], [126.945, 37.675], [126.95, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080959,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.675], [126.945, 37.67], [126.94, 37.67], [126.94, 37.675], [126.945, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080958,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.94, 37.675], [126.94, 37.67], [126.935, 37.67], [126.935, 37.675], [126.94, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080957,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.675], [126.935, 37.67], [126.93, 37.67], [126.93, 37.675], [126.935, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080956,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.675], [126.93, 37.67], [126.925, 37.67], [126.925, 37.675], [126.93, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080955,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.675], [126.925, 37.67], [126.92, 37.67], [126.92, 37.675], [126.925, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080954,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.675], [126.92, 37.67], [126.915, 37.67], [126.915, 37.675], [126.92, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080953,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 1,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.675], [126.915, 37.67], [126.91, 37.67], [126.91, 37.675], [126.915, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080952,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.675], [126.91, 37.67], [126.905, 37.67], [126.905, 37.675], [126.91, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080951,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.675], [126.905, 37.67], [126.9, 37.67], [126.9, 37.675], [126.905, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080860,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.675], [126.9, 37.67], [126.895, 37.67], [126.895, 37.675], [126.9, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080859,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.675], [126.895, 37.67], [126.89, 37.67], [126.89, 37.675], [126.895, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080858,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.675], [126.89, 37.67], [126.885, 37.67], [126.885, 37.675], [126.89, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080857,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.675], [126.885, 37.67], [126.88, 37.67], [126.88, 37.675], [126.885, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080856,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.675], [126.88, 37.67], [126.875, 37.67], [126.875, 37.675], [126.88, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080855,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.675], [126.875, 37.67], [126.87, 37.67], [126.87, 37.675], [126.875, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080854,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.675], [126.87, 37.67], [126.865, 37.67], [126.865, 37.675], [126.87, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080853,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.675], [126.865, 37.67], [126.86, 37.67], [126.86, 37.675], [126.865, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080852,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.675], [126.86, 37.67], [126.855, 37.67], [126.855, 37.675], [126.86, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080851,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.675], [126.855, 37.67], [126.85, 37.67], [126.85, 37.675], [126.855, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080760,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.675], [126.85, 37.67], [126.845, 37.67], [126.845, 37.675], [126.85, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080759,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.675], [126.845, 37.67], [126.84, 37.67], [126.84, 37.675], [126.845, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080758,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.675], [126.84, 37.67], [126.835, 37.67], [126.835, 37.675], [126.84, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080757,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.675], [126.835, 37.67], [126.83, 37.67], [126.83, 37.675], [126.835, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080756,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.675], [126.83, 37.67], [126.825, 37.67], [126.825, 37.675], [126.83, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080755,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.675], [126.825, 37.67], [126.82, 37.67], [126.82, 37.675], [126.825, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080754,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.675], [126.82, 37.67], [126.815, 37.67], [126.815, 37.675], [126.82, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080753,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.675], [126.815, 37.67], [126.81, 37.67], [126.81, 37.675], [126.815, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080752,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.675], [126.81, 37.67], [126.805, 37.67], [126.805, 37.675], [126.81, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080751,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.675], [126.805, 37.67], [126.8, 37.67], [126.8, 37.675], [126.805, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080660,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.675], [126.8, 37.67], [126.795, 37.67], [126.795, 37.675], [126.8, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080659,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.675], [126.795, 37.67], [126.79, 37.67], [126.79, 37.675], [126.795, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080658,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.675], [126.79, 37.67], [126.785, 37.67], [126.785, 37.675], [126.79, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080657,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.675], [126.785, 37.67], [126.78, 37.67], [126.78, 37.675], [126.785, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080656,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.675], [126.78, 37.67], [126.775, 37.67], [126.775, 37.675], [126.78, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080655,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.675], [126.775, 37.67], [126.77, 37.67], [126.77, 37.675], [126.775, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080654,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.675], [126.77, 37.67], [126.765, 37.67], [126.765, 37.675], [126.77, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080653,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.675], [126.765, 37.67], [126.76, 37.67], [126.76, 37.675], [126.765, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080652,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.675], [126.76, 37.67], [126.755, 37.67], [126.755, 37.675], [126.76, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080651,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.675], [126.755, 37.67], [126.75, 37.67], [126.75, 37.675], [126.755, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071060,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.75, 37.675], [126.75, 37.67], [126.745, 37.67], [126.745, 37.675], [126.75, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071059,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.745, 37.675], [126.745, 37.67], [126.74, 37.67], [126.74, 37.675], [126.745, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071058,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.74, 37.675], [126.74, 37.67], [126.735, 37.67], [126.735, 37.675], [126.74, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071057,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.735, 37.675], [126.735, 37.67], [126.73, 37.67], [126.73, 37.675], [126.735, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376071056,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.73, 37.675], [126.73, 37.67], [126.725, 37.67], [126.725, 37.675], [126.73, 37.675]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081049,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.68], [126.995, 37.675], [126.99, 37.675], [126.99, 37.68], [126.995, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081048,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.68], [126.99, 37.675], [126.985, 37.675], [126.985, 37.68], [126.99, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081047,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.68], [126.985, 37.675], [126.98, 37.675], [126.98, 37.68], [126.985, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081046,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.68], [126.98, 37.675], [126.975, 37.675], [126.975, 37.68], [126.98, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081045,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.68], [126.975, 37.675], [126.97, 37.675], [126.97, 37.68], [126.975, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081044,
      "ITEM1": 2,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 2,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.68], [126.97, 37.675], [126.965, 37.675], [126.965, 37.68], [126.97, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081043,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.68], [126.965, 37.675], [126.96, 37.675], [126.96, 37.68], [126.965, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081042,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.68], [126.96, 37.675], [126.955, 37.675], [126.955, 37.68], [126.96, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081041,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.68], [126.955, 37.675], [126.95, 37.675], [126.95, 37.68], [126.955, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080950,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 1,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.68], [126.95, 37.675], [126.945, 37.675], [126.945, 37.68], [126.95, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080949,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 1,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.68], [126.945, 37.675], [126.94, 37.675], [126.94, 37.68], [126.945, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080943,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.68], [126.915, 37.675], [126.91, 37.675], [126.91, 37.68], [126.915, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080942,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.68], [126.91, 37.675], [126.905, 37.675], [126.905, 37.68], [126.91, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080941,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.68], [126.905, 37.675], [126.9, 37.675], [126.9, 37.68], [126.905, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080850,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.68], [126.9, 37.675], [126.895, 37.675], [126.895, 37.68], [126.9, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080849,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.68], [126.895, 37.675], [126.89, 37.675], [126.89, 37.68], [126.895, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080848,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.68], [126.89, 37.675], [126.885, 37.675], [126.885, 37.68], [126.89, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080847,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.68], [126.885, 37.675], [126.88, 37.675], [126.88, 37.68], [126.885, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080846,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.68], [126.88, 37.675], [126.875, 37.675], [126.875, 37.68], [126.88, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080845,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 1,
      "ITEM4": 7,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.68], [126.875, 37.675], [126.87, 37.675], [126.87, 37.68], [126.875, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080844,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.68], [126.87, 37.675], [126.865, 37.675], [126.865, 37.68], [126.87, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080843,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.68], [126.865, 37.675], [126.86, 37.675], [126.86, 37.68], [126.865, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080842,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.68], [126.86, 37.675], [126.855, 37.675], [126.855, 37.68], [126.86, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080841,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.68], [126.855, 37.675], [126.85, 37.675], [126.85, 37.68], [126.855, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080750,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.68], [126.85, 37.675], [126.845, 37.675], [126.845, 37.68], [126.85, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080749,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.68], [126.845, 37.675], [126.84, 37.675], [126.84, 37.68], [126.845, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080748,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.68], [126.84, 37.675], [126.835, 37.675], [126.835, 37.68], [126.84, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080747,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.68], [126.835, 37.675], [126.83, 37.675], [126.83, 37.68], [126.835, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080746,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.68], [126.83, 37.675], [126.825, 37.675], [126.825, 37.68], [126.83, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080745,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.68], [126.825, 37.675], [126.82, 37.675], [126.82, 37.68], [126.825, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080744,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.68], [126.82, 37.675], [126.815, 37.675], [126.815, 37.68], [126.82, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080743,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.68], [126.815, 37.675], [126.81, 37.675], [126.81, 37.68], [126.815, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080742,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.68], [126.81, 37.675], [126.805, 37.675], [126.805, 37.68], [126.81, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080741,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.68], [126.805, 37.675], [126.8, 37.675], [126.8, 37.68], [126.805, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080650,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.68], [126.8, 37.675], [126.795, 37.675], [126.795, 37.68], [126.8, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080649,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.68], [126.795, 37.675], [126.79, 37.675], [126.79, 37.68], [126.795, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080648,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.68], [126.79, 37.675], [126.785, 37.675], [126.785, 37.68], [126.79, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080647,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.68], [126.785, 37.675], [126.78, 37.675], [126.78, 37.68], [126.785, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080646,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.68], [126.78, 37.675], [126.775, 37.675], [126.775, 37.68], [126.78, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080645,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.68], [126.775, 37.675], [126.77, 37.675], [126.77, 37.68], [126.775, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080644,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.68], [126.77, 37.675], [126.765, 37.675], [126.765, 37.68], [126.77, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080643,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.68], [126.765, 37.675], [126.76, 37.675], [126.76, 37.68], [126.765, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080642,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.68], [126.76, 37.675], [126.755, 37.675], [126.755, 37.68], [126.76, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080641,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.68], [126.755, 37.675], [126.75, 37.675], [126.75, 37.68], [126.755, 37.68]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081039,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.995, 37.685], [126.995, 37.68], [126.99, 37.68], [126.99, 37.685], [126.995, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081038,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.99, 37.685], [126.99, 37.68], [126.985, 37.68], [126.985, 37.685], [126.99, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081037,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.685], [126.985, 37.68], [126.98, 37.68], [126.98, 37.685], [126.985, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081036,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.685], [126.98, 37.68], [126.975, 37.68], [126.975, 37.685], [126.98, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081035,
      "ITEM1": 2,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.685], [126.975, 37.68], [126.97, 37.68], [126.97, 37.685], [126.975, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081034,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.685], [126.97, 37.68], [126.965, 37.68], [126.965, 37.685], [126.97, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081033,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.685], [126.965, 37.68], [126.96, 37.68], [126.96, 37.685], [126.965, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081032,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.685], [126.96, 37.68], [126.955, 37.68], [126.955, 37.685], [126.96, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081031,
      "ITEM1": 2,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.685], [126.955, 37.68], [126.95, 37.68], [126.95, 37.685], [126.955, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080940,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.685], [126.95, 37.68], [126.945, 37.68], [126.945, 37.685], [126.95, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080939,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.945, 37.685], [126.945, 37.68], [126.94, 37.68], [126.94, 37.685], [126.945, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080933,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.685], [126.915, 37.68], [126.91, 37.68], [126.91, 37.685], [126.915, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080932,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.685], [126.91, 37.68], [126.905, 37.68], [126.905, 37.685], [126.91, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080931,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.685], [126.905, 37.68], [126.9, 37.68], [126.9, 37.685], [126.905, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080840,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 2,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.685], [126.9, 37.68], [126.895, 37.68], [126.895, 37.685], [126.9, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080839,
      "ITEM1": 5,
      "ITEM2": 1,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.685], [126.895, 37.68], [126.89, 37.68], [126.89, 37.685], [126.895, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080838,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.685], [126.89, 37.68], [126.885, 37.68], [126.885, 37.685], [126.89, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080837,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.685], [126.885, 37.68], [126.88, 37.68], [126.88, 37.685], [126.885, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080836,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.685], [126.88, 37.68], [126.875, 37.68], [126.875, 37.685], [126.88, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080835,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.685], [126.875, 37.68], [126.87, 37.68], [126.87, 37.685], [126.875, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080834,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.685], [126.87, 37.68], [126.865, 37.68], [126.865, 37.685], [126.87, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080833,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.685], [126.865, 37.68], [126.86, 37.68], [126.86, 37.685], [126.865, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080832,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.685], [126.86, 37.68], [126.855, 37.68], [126.855, 37.685], [126.86, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080831,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.685], [126.855, 37.68], [126.85, 37.68], [126.85, 37.685], [126.855, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080740,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.685], [126.85, 37.68], [126.845, 37.68], [126.845, 37.685], [126.85, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080739,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.685], [126.845, 37.68], [126.84, 37.68], [126.84, 37.685], [126.845, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080738,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.685], [126.84, 37.68], [126.835, 37.68], [126.835, 37.685], [126.84, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080737,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.685], [126.835, 37.68], [126.83, 37.68], [126.83, 37.685], [126.835, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080736,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.685], [126.83, 37.68], [126.825, 37.68], [126.825, 37.685], [126.83, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080735,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.685], [126.825, 37.68], [126.82, 37.68], [126.82, 37.685], [126.825, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080734,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.685], [126.82, 37.68], [126.815, 37.68], [126.815, 37.685], [126.82, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080733,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.685], [126.815, 37.68], [126.81, 37.68], [126.81, 37.685], [126.815, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080732,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.685], [126.81, 37.68], [126.805, 37.68], [126.805, 37.685], [126.81, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080731,
      "ITEM1": 8,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.685], [126.805, 37.68], [126.8, 37.68], [126.8, 37.685], [126.805, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080640,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.685], [126.8, 37.68], [126.795, 37.68], [126.795, 37.685], [126.8, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080639,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.685], [126.795, 37.68], [126.79, 37.68], [126.79, 37.685], [126.795, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080638,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.685], [126.79, 37.68], [126.785, 37.68], [126.785, 37.685], [126.79, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080637,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.685], [126.785, 37.68], [126.78, 37.68], [126.78, 37.685], [126.785, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080636,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.685], [126.78, 37.68], [126.775, 37.68], [126.775, 37.685], [126.78, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080635,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 1,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.685], [126.775, 37.68], [126.77, 37.68], [126.77, 37.685], [126.775, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080634,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.685], [126.77, 37.68], [126.765, 37.68], [126.765, 37.685], [126.77, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080633,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.685], [126.765, 37.68], [126.76, 37.68], [126.76, 37.685], [126.765, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080632,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 9,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.685], [126.76, 37.68], [126.755, 37.68], [126.755, 37.685], [126.76, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080631,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.685], [126.755, 37.68], [126.75, 37.68], [126.75, 37.685], [126.755, 37.685]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081027,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.985, 37.69], [126.985, 37.685], [126.98, 37.685], [126.98, 37.69], [126.985, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081026,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.98, 37.69], [126.98, 37.685], [126.975, 37.685], [126.975, 37.69], [126.98, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081025,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.975, 37.69], [126.975, 37.685], [126.97, 37.685], [126.97, 37.69], [126.975, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081024,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.69], [126.97, 37.685], [126.965, 37.685], [126.965, 37.69], [126.97, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081023,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 2,
      "ITEM6": 3,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.69], [126.965, 37.685], [126.96, 37.685], [126.96, 37.69], [126.965, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081022,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.69], [126.96, 37.685], [126.955, 37.685], [126.955, 37.69], [126.96, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081021,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.69], [126.955, 37.685], [126.95, 37.685], [126.95, 37.69], [126.955, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080930,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.95, 37.69], [126.95, 37.685], [126.945, 37.685], [126.945, 37.69], [126.95, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080924,
      "ITEM1": 9,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.69], [126.92, 37.685], [126.915, 37.685], [126.915, 37.69], [126.92, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080923,
      "ITEM1": 9,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.69], [126.915, 37.685], [126.91, 37.685], [126.91, 37.69], [126.915, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080922,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.69], [126.91, 37.685], [126.905, 37.685], [126.905, 37.69], [126.91, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080921,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.69], [126.905, 37.685], [126.9, 37.685], [126.9, 37.69], [126.905, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080830,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.69], [126.9, 37.685], [126.895, 37.685], [126.895, 37.69], [126.9, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080829,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.69], [126.895, 37.685], [126.89, 37.685], [126.89, 37.69], [126.895, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080828,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.69], [126.89, 37.685], [126.885, 37.685], [126.885, 37.69], [126.89, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080827,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.69], [126.885, 37.685], [126.88, 37.685], [126.88, 37.69], [126.885, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080826,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.69], [126.88, 37.685], [126.875, 37.685], [126.875, 37.69], [126.88, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080825,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.69], [126.875, 37.685], [126.87, 37.685], [126.87, 37.69], [126.875, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080824,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.69], [126.87, 37.685], [126.865, 37.685], [126.865, 37.69], [126.87, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080823,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.69], [126.865, 37.685], [126.86, 37.685], [126.86, 37.69], [126.865, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080822,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.69], [126.86, 37.685], [126.855, 37.685], [126.855, 37.69], [126.86, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080821,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.69], [126.855, 37.685], [126.85, 37.685], [126.85, 37.69], [126.855, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080730,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.69], [126.85, 37.685], [126.845, 37.685], [126.845, 37.69], [126.85, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080729,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.69], [126.845, 37.685], [126.84, 37.685], [126.84, 37.69], [126.845, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080728,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.69], [126.84, 37.685], [126.835, 37.685], [126.835, 37.69], [126.84, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080727,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.69], [126.835, 37.685], [126.83, 37.685], [126.83, 37.69], [126.835, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080726,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.69], [126.83, 37.685], [126.825, 37.685], [126.825, 37.69], [126.83, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080725,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 0,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.69], [126.825, 37.685], [126.82, 37.685], [126.82, 37.69], [126.825, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080724,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.69], [126.82, 37.685], [126.815, 37.685], [126.815, 37.69], [126.82, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080723,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.69], [126.815, 37.685], [126.81, 37.685], [126.81, 37.69], [126.815, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080722,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.69], [126.81, 37.685], [126.805, 37.685], [126.805, 37.69], [126.81, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080721,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.69], [126.805, 37.685], [126.8, 37.685], [126.8, 37.69], [126.805, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080630,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 10,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.69], [126.8, 37.685], [126.795, 37.685], [126.795, 37.69], [126.8, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080629,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.69], [126.795, 37.685], [126.79, 37.685], [126.79, 37.69], [126.795, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080628,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.69], [126.79, 37.685], [126.785, 37.685], [126.785, 37.69], [126.79, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080627,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 1,
      "ITEM5": 4,
      "ITEM6": 2,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.69], [126.785, 37.685], [126.78, 37.685], [126.78, 37.69], [126.785, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080626,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.69], [126.78, 37.685], [126.775, 37.685], [126.775, 37.69], [126.78, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080625,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.69], [126.775, 37.685], [126.77, 37.685], [126.77, 37.69], [126.775, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080624,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.69], [126.77, 37.685], [126.765, 37.685], [126.765, 37.69], [126.77, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080623,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 1,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.69], [126.765, 37.685], [126.76, 37.685], [126.76, 37.69], [126.765, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080622,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.69], [126.76, 37.685], [126.755, 37.685], [126.755, 37.69], [126.76, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080621,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.69], [126.755, 37.685], [126.75, 37.685], [126.75, 37.69], [126.755, 37.69]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081014,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.97, 37.695], [126.97, 37.69], [126.965, 37.69], [126.965, 37.695], [126.97, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081013,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 8,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.965, 37.695], [126.965, 37.69], [126.96, 37.69], [126.96, 37.695], [126.965, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081012,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.96, 37.695], [126.96, 37.69], [126.955, 37.69], [126.955, 37.695], [126.96, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376081011,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.955, 37.695], [126.955, 37.69], [126.95, 37.69], [126.95, 37.695], [126.955, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080914,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.695], [126.92, 37.69], [126.915, 37.69], [126.915, 37.695], [126.92, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080913,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.695], [126.915, 37.69], [126.91, 37.69], [126.91, 37.695], [126.915, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080912,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.695], [126.91, 37.69], [126.905, 37.69], [126.905, 37.695], [126.91, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080911,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.695], [126.905, 37.69], [126.9, 37.69], [126.9, 37.695], [126.905, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080820,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.695], [126.9, 37.69], [126.895, 37.69], [126.895, 37.695], [126.9, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080819,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.695], [126.895, 37.69], [126.89, 37.69], [126.89, 37.695], [126.895, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080818,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.695], [126.89, 37.69], [126.885, 37.69], [126.885, 37.695], [126.89, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080817,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.695], [126.885, 37.69], [126.88, 37.69], [126.88, 37.695], [126.885, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080816,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.695], [126.88, 37.69], [126.875, 37.69], [126.875, 37.695], [126.88, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080815,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.695], [126.875, 37.69], [126.87, 37.69], [126.87, 37.695], [126.875, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080814,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.695], [126.87, 37.69], [126.865, 37.69], [126.865, 37.695], [126.87, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080813,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 9,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.695], [126.865, 37.69], [126.86, 37.69], [126.86, 37.695], [126.865, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080812,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.695], [126.86, 37.69], [126.855, 37.69], [126.855, 37.695], [126.86, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080811,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.695], [126.855, 37.69], [126.85, 37.69], [126.85, 37.695], [126.855, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080720,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.695], [126.85, 37.69], [126.845, 37.69], [126.845, 37.695], [126.85, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080719,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.695], [126.845, 37.69], [126.84, 37.69], [126.84, 37.695], [126.845, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080718,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.695], [126.84, 37.69], [126.835, 37.69], [126.835, 37.695], [126.84, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080717,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.695], [126.835, 37.69], [126.83, 37.69], [126.83, 37.695], [126.835, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080716,
      "ITEM1": 2,
      "ITEM2": 7,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.695], [126.83, 37.69], [126.825, 37.69], [126.825, 37.695], [126.83, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080715,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.695], [126.825, 37.69], [126.82, 37.69], [126.82, 37.695], [126.825, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080714,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.695], [126.82, 37.69], [126.815, 37.69], [126.815, 37.695], [126.82, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080713,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 10,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.695], [126.815, 37.69], [126.81, 37.69], [126.81, 37.695], [126.815, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080712,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.695], [126.81, 37.69], [126.805, 37.69], [126.805, 37.695], [126.81, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080711,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.695], [126.805, 37.69], [126.8, 37.69], [126.8, 37.695], [126.805, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080620,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 2,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.695], [126.8, 37.69], [126.795, 37.69], [126.795, 37.695], [126.8, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080619,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.695], [126.795, 37.69], [126.79, 37.69], [126.79, 37.695], [126.795, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080618,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.695], [126.79, 37.69], [126.785, 37.69], [126.785, 37.695], [126.79, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080617,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.695], [126.785, 37.69], [126.78, 37.69], [126.78, 37.695], [126.785, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080616,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.695], [126.78, 37.69], [126.775, 37.69], [126.775, 37.695], [126.78, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080615,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.695], [126.775, 37.69], [126.77, 37.69], [126.77, 37.695], [126.775, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080614,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.695], [126.77, 37.69], [126.765, 37.69], [126.765, 37.695], [126.77, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080613,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.695], [126.765, 37.69], [126.76, 37.69], [126.76, 37.695], [126.765, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080612,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.695], [126.76, 37.69], [126.755, 37.69], [126.755, 37.695], [126.76, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080611,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.695], [126.755, 37.69], [126.75, 37.69], [126.75, 37.695], [126.755, 37.695]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080905,
      "ITEM1": 9,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.7], [126.925, 37.695], [126.92, 37.695], [126.92, 37.7], [126.925, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080904,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.7], [126.92, 37.695], [126.915, 37.695], [126.915, 37.7], [126.92, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080903,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.7], [126.915, 37.695], [126.91, 37.695], [126.91, 37.7], [126.915, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080902,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.7], [126.91, 37.695], [126.905, 37.695], [126.905, 37.7], [126.91, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080901,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.7], [126.905, 37.695], [126.9, 37.695], [126.9, 37.7], [126.905, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080810,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.7], [126.9, 37.695], [126.895, 37.695], [126.895, 37.7], [126.9, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080809,
      "ITEM1": 5,
      "ITEM2": 1,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.7], [126.895, 37.695], [126.89, 37.695], [126.89, 37.7], [126.895, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080808,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.7], [126.89, 37.695], [126.885, 37.695], [126.885, 37.7], [126.89, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080807,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.7], [126.885, 37.695], [126.88, 37.695], [126.88, 37.7], [126.885, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080806,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.7], [126.88, 37.695], [126.875, 37.695], [126.875, 37.7], [126.88, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080805,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.7], [126.875, 37.695], [126.87, 37.695], [126.87, 37.7], [126.875, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080804,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.7], [126.87, 37.695], [126.865, 37.695], [126.865, 37.7], [126.87, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080803,
      "ITEM1": 5,
      "ITEM2": 1,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.7], [126.865, 37.695], [126.86, 37.695], [126.86, 37.7], [126.865, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080802,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.7], [126.86, 37.695], [126.855, 37.695], [126.855, 37.7], [126.86, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080801,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.7], [126.855, 37.695], [126.85, 37.695], [126.85, 37.7], [126.855, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080710,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.7], [126.85, 37.695], [126.845, 37.695], [126.845, 37.7], [126.85, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080709,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.7], [126.845, 37.695], [126.84, 37.695], [126.84, 37.7], [126.845, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080708,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 8,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.7], [126.84, 37.695], [126.835, 37.695], [126.835, 37.7], [126.84, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080707,
      "ITEM1": 5,
      "ITEM2": 9,
      "ITEM3": 9,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 11
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.7], [126.835, 37.695], [126.83, 37.695], [126.83, 37.7], [126.835, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080706,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.7], [126.83, 37.695], [126.825, 37.695], [126.825, 37.7], [126.83, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080705,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.7], [126.825, 37.695], [126.82, 37.695], [126.82, 37.7], [126.825, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080704,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 1,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.7], [126.82, 37.695], [126.815, 37.695], [126.815, 37.7], [126.82, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080703,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.7], [126.815, 37.695], [126.81, 37.695], [126.81, 37.7], [126.815, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080702,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.7], [126.81, 37.695], [126.805, 37.695], [126.805, 37.7], [126.81, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080701,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.7], [126.805, 37.695], [126.8, 37.695], [126.8, 37.7], [126.805, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080610,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.7], [126.8, 37.695], [126.795, 37.695], [126.795, 37.7], [126.8, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080609,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.7], [126.795, 37.695], [126.79, 37.695], [126.79, 37.7], [126.795, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080608,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 9,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.7], [126.79, 37.695], [126.785, 37.695], [126.785, 37.7], [126.79, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080607,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 1,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.7], [126.785, 37.695], [126.78, 37.695], [126.78, 37.7], [126.785, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080606,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.7], [126.78, 37.695], [126.775, 37.695], [126.775, 37.7], [126.78, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080605,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.7], [126.775, 37.695], [126.77, 37.695], [126.77, 37.7], [126.775, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080604,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.7], [126.77, 37.695], [126.765, 37.695], [126.765, 37.7], [126.77, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080603,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.7], [126.765, 37.695], [126.76, 37.695], [126.76, 37.7], [126.765, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080602,
      "ITEM1": 3,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.7], [126.76, 37.695], [126.755, 37.695], [126.755, 37.7], [126.76, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080601,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.7], [126.755, 37.695], [126.75, 37.695], [126.75, 37.7], [126.755, 37.7]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080495,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.705], [126.925, 37.7], [126.92, 37.7], [126.92, 37.705], [126.925, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080494,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.705], [126.92, 37.7], [126.915, 37.7], [126.915, 37.705], [126.92, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080493,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 9,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.705], [126.915, 37.7], [126.91, 37.7], [126.91, 37.705], [126.915, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080492,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.705], [126.91, 37.7], [126.905, 37.7], [126.905, 37.705], [126.91, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080491,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.705], [126.905, 37.7], [126.9, 37.7], [126.9, 37.705], [126.905, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080300,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.705], [126.9, 37.7], [126.895, 37.7], [126.895, 37.705], [126.9, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080399,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.705], [126.895, 37.7], [126.89, 37.7], [126.89, 37.705], [126.895, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080398,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.705], [126.89, 37.7], [126.885, 37.7], [126.885, 37.705], [126.89, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080397,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 9,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.705], [126.885, 37.7], [126.88, 37.7], [126.88, 37.705], [126.885, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080396,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.705], [126.88, 37.7], [126.875, 37.7], [126.875, 37.705], [126.88, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080395,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.705], [126.875, 37.7], [126.87, 37.7], [126.87, 37.705], [126.875, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080394,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 1,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.705], [126.87, 37.7], [126.865, 37.7], [126.865, 37.705], [126.87, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080393,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.705], [126.865, 37.7], [126.86, 37.7], [126.86, 37.705], [126.865, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080392,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.705], [126.86, 37.7], [126.855, 37.7], [126.855, 37.705], [126.86, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080391,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.705], [126.855, 37.7], [126.85, 37.7], [126.85, 37.705], [126.855, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080200,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.705], [126.85, 37.7], [126.845, 37.7], [126.845, 37.705], [126.85, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080299,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.705], [126.845, 37.7], [126.84, 37.7], [126.84, 37.705], [126.845, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080298,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.705], [126.84, 37.7], [126.835, 37.7], [126.835, 37.705], [126.84, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080297,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.705], [126.835, 37.7], [126.83, 37.7], [126.83, 37.705], [126.835, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080296,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.705], [126.83, 37.7], [126.825, 37.7], [126.825, 37.705], [126.83, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080295,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.705], [126.825, 37.7], [126.82, 37.7], [126.82, 37.705], [126.825, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080294,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.705], [126.82, 37.7], [126.815, 37.7], [126.815, 37.705], [126.82, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080293,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.705], [126.815, 37.7], [126.81, 37.7], [126.81, 37.705], [126.815, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080292,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.705], [126.81, 37.7], [126.805, 37.7], [126.805, 37.705], [126.81, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080291,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.705], [126.805, 37.7], [126.8, 37.7], [126.8, 37.705], [126.805, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080100,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.705], [126.8, 37.7], [126.795, 37.7], [126.795, 37.705], [126.8, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080199,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 1,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.705], [126.795, 37.7], [126.79, 37.7], [126.79, 37.705], [126.795, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080198,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.705], [126.79, 37.7], [126.785, 37.7], [126.785, 37.705], [126.79, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080197,
      "ITEM1": 7,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.705], [126.785, 37.7], [126.78, 37.7], [126.78, 37.705], [126.785, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080196,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.705], [126.78, 37.7], [126.775, 37.7], [126.775, 37.705], [126.78, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080195,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.705], [126.775, 37.7], [126.77, 37.7], [126.77, 37.705], [126.775, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080194,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.705], [126.77, 37.7], [126.765, 37.7], [126.765, 37.705], [126.77, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080193,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.765, 37.705], [126.765, 37.7], [126.76, 37.7], [126.76, 37.705], [126.765, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080192,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.705], [126.76, 37.7], [126.755, 37.7], [126.755, 37.705], [126.76, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080191,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.705], [126.755, 37.7], [126.75, 37.7], [126.75, 37.705], [126.755, 37.705]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080486,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.71], [126.93, 37.705], [126.925, 37.705], [126.925, 37.71], [126.93, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080485,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.71], [126.925, 37.705], [126.92, 37.705], [126.92, 37.71], [126.925, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080484,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.71], [126.92, 37.705], [126.915, 37.705], [126.915, 37.71], [126.92, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080483,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.71], [126.915, 37.705], [126.91, 37.705], [126.91, 37.71], [126.915, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080482,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.71], [126.91, 37.705], [126.905, 37.705], [126.905, 37.71], [126.91, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080481,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.71], [126.905, 37.705], [126.9, 37.705], [126.9, 37.71], [126.905, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080390,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.71], [126.9, 37.705], [126.895, 37.705], [126.895, 37.71], [126.9, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080389,
      "ITEM1": 2,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.71], [126.895, 37.705], [126.89, 37.705], [126.89, 37.71], [126.895, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080388,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.71], [126.89, 37.705], [126.885, 37.705], [126.885, 37.71], [126.89, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080387,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.71], [126.885, 37.705], [126.88, 37.705], [126.88, 37.71], [126.885, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080386,
      "ITEM1": 8,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.71], [126.88, 37.705], [126.875, 37.705], [126.875, 37.71], [126.88, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080385,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.71], [126.875, 37.705], [126.87, 37.705], [126.87, 37.71], [126.875, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080384,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.71], [126.87, 37.705], [126.865, 37.705], [126.865, 37.71], [126.87, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080383,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.71], [126.865, 37.705], [126.86, 37.705], [126.86, 37.71], [126.865, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080382,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.71], [126.86, 37.705], [126.855, 37.705], [126.855, 37.71], [126.86, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080381,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.71], [126.855, 37.705], [126.85, 37.705], [126.85, 37.71], [126.855, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080290,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.71], [126.85, 37.705], [126.845, 37.705], [126.845, 37.71], [126.85, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080289,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.71], [126.845, 37.705], [126.84, 37.705], [126.84, 37.71], [126.845, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080288,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.71], [126.84, 37.705], [126.835, 37.705], [126.835, 37.71], [126.84, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080287,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.71], [126.835, 37.705], [126.83, 37.705], [126.83, 37.71], [126.835, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080286,
      "ITEM1": 2,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.71], [126.83, 37.705], [126.825, 37.705], [126.825, 37.71], [126.83, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080285,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.71], [126.825, 37.705], [126.82, 37.705], [126.82, 37.71], [126.825, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080284,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.71], [126.82, 37.705], [126.815, 37.705], [126.815, 37.71], [126.82, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080283,
      "ITEM1": 3,
      "ITEM2": 2,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.71], [126.815, 37.705], [126.81, 37.705], [126.81, 37.71], [126.815, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080282,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.71], [126.81, 37.705], [126.805, 37.705], [126.805, 37.71], [126.81, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080281,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 2,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.71], [126.805, 37.705], [126.8, 37.705], [126.8, 37.71], [126.805, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080190,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.71], [126.8, 37.705], [126.795, 37.705], [126.795, 37.71], [126.8, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080189,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 8,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.71], [126.795, 37.705], [126.79, 37.705], [126.79, 37.71], [126.795, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080188,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.71], [126.79, 37.705], [126.785, 37.705], [126.785, 37.71], [126.79, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080187,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 9,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.71], [126.785, 37.705], [126.78, 37.705], [126.78, 37.71], [126.785, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080186,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 9,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.71], [126.78, 37.705], [126.775, 37.705], [126.775, 37.71], [126.78, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080185,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.71], [126.775, 37.705], [126.77, 37.705], [126.77, 37.71], [126.775, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080184,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.77, 37.71], [126.77, 37.705], [126.765, 37.705], [126.765, 37.71], [126.77, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080182,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.76, 37.71], [126.76, 37.705], [126.755, 37.705], [126.755, 37.71], [126.76, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080181,
      "ITEM1": 4,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 2,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.755, 37.71], [126.755, 37.705], [126.75, 37.705], [126.75, 37.71], [126.755, 37.71]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080475,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 8,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.715], [126.925, 37.71], [126.92, 37.71], [126.92, 37.715], [126.925, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080474,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.715], [126.92, 37.71], [126.915, 37.71], [126.915, 37.715], [126.92, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080473,
      "ITEM1": 3,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 2,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.715], [126.915, 37.71], [126.91, 37.71], [126.91, 37.715], [126.915, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080472,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.715], [126.91, 37.71], [126.905, 37.71], [126.905, 37.715], [126.91, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080471,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.715], [126.905, 37.71], [126.9, 37.71], [126.9, 37.715], [126.905, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080380,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.715], [126.9, 37.71], [126.895, 37.71], [126.895, 37.715], [126.9, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080379,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.715], [126.895, 37.71], [126.89, 37.71], [126.89, 37.715], [126.895, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080378,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.715], [126.89, 37.71], [126.885, 37.71], [126.885, 37.715], [126.89, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080377,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.715], [126.885, 37.71], [126.88, 37.71], [126.88, 37.715], [126.885, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080376,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 2,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.715], [126.88, 37.71], [126.875, 37.71], [126.875, 37.715], [126.88, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080375,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.715], [126.875, 37.71], [126.87, 37.71], [126.87, 37.715], [126.875, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080374,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 1,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.715], [126.87, 37.71], [126.865, 37.71], [126.865, 37.715], [126.87, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080373,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.715], [126.865, 37.71], [126.86, 37.71], [126.86, 37.715], [126.865, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080372,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.715], [126.86, 37.71], [126.855, 37.71], [126.855, 37.715], [126.86, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080371,
      "ITEM1": 9,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.715], [126.855, 37.71], [126.85, 37.71], [126.85, 37.715], [126.855, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080280,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.715], [126.85, 37.71], [126.845, 37.71], [126.845, 37.715], [126.85, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080279,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.715], [126.845, 37.71], [126.84, 37.71], [126.84, 37.715], [126.845, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080278,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 1,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.715], [126.84, 37.71], [126.835, 37.71], [126.835, 37.715], [126.84, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080277,
      "ITEM1": 6,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.715], [126.835, 37.71], [126.83, 37.71], [126.83, 37.715], [126.835, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080276,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.715], [126.83, 37.71], [126.825, 37.71], [126.825, 37.715], [126.83, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080275,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.715], [126.825, 37.71], [126.82, 37.71], [126.82, 37.715], [126.825, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080274,
      "ITEM1": 1,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.715], [126.82, 37.71], [126.815, 37.71], [126.815, 37.715], [126.82, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080273,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.715], [126.815, 37.71], [126.81, 37.71], [126.81, 37.715], [126.815, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080272,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.715], [126.81, 37.71], [126.805, 37.71], [126.805, 37.715], [126.81, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080271,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.715], [126.805, 37.71], [126.8, 37.71], [126.8, 37.715], [126.805, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080180,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.715], [126.8, 37.71], [126.795, 37.71], [126.795, 37.715], [126.8, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080179,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 2,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.715], [126.795, 37.71], [126.79, 37.71], [126.79, 37.715], [126.795, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080178,
      "ITEM1": 1,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 2,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.715], [126.79, 37.71], [126.785, 37.71], [126.785, 37.715], [126.79, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080177,
      "ITEM1": 3,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.715], [126.785, 37.71], [126.78, 37.71], [126.78, 37.715], [126.785, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080176,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.715], [126.78, 37.71], [126.775, 37.71], [126.775, 37.715], [126.78, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080175,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.775, 37.715], [126.775, 37.71], [126.77, 37.71], [126.77, 37.715], [126.775, 37.715]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080465,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 8,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.72], [126.925, 37.715], [126.92, 37.715], [126.92, 37.72], [126.925, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080464,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.72], [126.92, 37.715], [126.915, 37.715], [126.915, 37.72], [126.92, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080463,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.72], [126.915, 37.715], [126.91, 37.715], [126.91, 37.72], [126.915, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080462,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.72], [126.91, 37.715], [126.905, 37.715], [126.905, 37.72], [126.91, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080461,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.72], [126.905, 37.715], [126.9, 37.715], [126.9, 37.72], [126.905, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080370,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.72], [126.9, 37.715], [126.895, 37.715], [126.895, 37.72], [126.9, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080369,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.72], [126.895, 37.715], [126.89, 37.715], [126.89, 37.72], [126.895, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080368,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.72], [126.89, 37.715], [126.885, 37.715], [126.885, 37.72], [126.89, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080367,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.72], [126.885, 37.715], [126.88, 37.715], [126.88, 37.72], [126.885, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080366,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.72], [126.88, 37.715], [126.875, 37.715], [126.875, 37.72], [126.88, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080365,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.72], [126.875, 37.715], [126.87, 37.715], [126.87, 37.72], [126.875, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080364,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.72], [126.87, 37.715], [126.865, 37.715], [126.865, 37.72], [126.87, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080363,
      "ITEM1": 8,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.72], [126.865, 37.715], [126.86, 37.715], [126.86, 37.72], [126.865, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080362,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.72], [126.86, 37.715], [126.855, 37.715], [126.855, 37.72], [126.86, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080361,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.72], [126.855, 37.715], [126.85, 37.715], [126.85, 37.72], [126.855, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080270,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.72], [126.85, 37.715], [126.845, 37.715], [126.845, 37.72], [126.85, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080269,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.72], [126.845, 37.715], [126.84, 37.715], [126.84, 37.72], [126.845, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080268,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.72], [126.84, 37.715], [126.835, 37.715], [126.835, 37.72], [126.84, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080267,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.72], [126.835, 37.715], [126.83, 37.715], [126.83, 37.72], [126.835, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080266,
      "ITEM1": 8,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.72], [126.83, 37.715], [126.825, 37.715], [126.825, 37.72], [126.83, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080265,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.72], [126.825, 37.715], [126.82, 37.715], [126.82, 37.72], [126.825, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080264,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.72], [126.82, 37.715], [126.815, 37.715], [126.815, 37.72], [126.82, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080263,
      "ITEM1": 3,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.72], [126.815, 37.715], [126.81, 37.715], [126.81, 37.72], [126.815, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080262,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.72], [126.81, 37.715], [126.805, 37.715], [126.805, 37.72], [126.81, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080261,
      "ITEM1": 5,
      "ITEM2": 1,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 9,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.72], [126.805, 37.715], [126.8, 37.715], [126.8, 37.72], [126.805, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080170,
      "ITEM1": 7,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.72], [126.8, 37.715], [126.795, 37.715], [126.795, 37.72], [126.8, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080169,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.72], [126.795, 37.715], [126.79, 37.715], [126.79, 37.72], [126.795, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080168,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.72], [126.79, 37.715], [126.785, 37.715], [126.785, 37.72], [126.79, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080167,
      "ITEM1": 1,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.72], [126.785, 37.715], [126.78, 37.715], [126.78, 37.72], [126.785, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080166,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.78, 37.72], [126.78, 37.715], [126.775, 37.715], [126.775, 37.72], [126.78, 37.72]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080455,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 1,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 1,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.725], [126.925, 37.72], [126.92, 37.72], [126.92, 37.725], [126.925, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080454,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 2,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.725], [126.92, 37.72], [126.915, 37.72], [126.915, 37.725], [126.92, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080453,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.725], [126.915, 37.72], [126.91, 37.72], [126.91, 37.725], [126.915, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080452,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.725], [126.91, 37.72], [126.905, 37.72], [126.905, 37.725], [126.91, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080451,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.725], [126.905, 37.72], [126.9, 37.72], [126.9, 37.725], [126.905, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080360,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.725], [126.9, 37.72], [126.895, 37.72], [126.895, 37.725], [126.9, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080359,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.725], [126.895, 37.72], [126.89, 37.72], [126.89, 37.725], [126.895, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080358,
      "ITEM1": 1,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 9,
      "ITEMT": 8
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.725], [126.89, 37.72], [126.885, 37.72], [126.885, 37.725], [126.89, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080357,
      "ITEM1": 7,
      "ITEM2": 4,
      "ITEM3": 2,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.725], [126.885, 37.72], [126.88, 37.72], [126.88, 37.725], [126.885, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080356,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 1,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.725], [126.88, 37.72], [126.875, 37.72], [126.875, 37.725], [126.88, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080355,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.725], [126.875, 37.72], [126.87, 37.72], [126.87, 37.725], [126.875, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080354,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.725], [126.87, 37.72], [126.865, 37.72], [126.865, 37.725], [126.87, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080353,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.725], [126.865, 37.72], [126.86, 37.72], [126.86, 37.725], [126.865, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080352,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.725], [126.86, 37.72], [126.855, 37.72], [126.855, 37.725], [126.86, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080351,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.725], [126.855, 37.72], [126.85, 37.72], [126.85, 37.725], [126.855, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080260,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.725], [126.85, 37.72], [126.845, 37.72], [126.845, 37.725], [126.85, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080259,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 7,
      "ITEM6": 2,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.725], [126.845, 37.72], [126.84, 37.72], [126.84, 37.725], [126.845, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080258,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.725], [126.84, 37.72], [126.835, 37.72], [126.835, 37.725], [126.84, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080257,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.725], [126.835, 37.72], [126.83, 37.72], [126.83, 37.725], [126.835, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080256,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 8,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.725], [126.83, 37.72], [126.825, 37.72], [126.825, 37.725], [126.83, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080255,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 1,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.725], [126.825, 37.72], [126.82, 37.72], [126.82, 37.725], [126.825, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080254,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.725], [126.82, 37.72], [126.815, 37.72], [126.815, 37.725], [126.82, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080253,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.725], [126.815, 37.72], [126.81, 37.72], [126.81, 37.725], [126.815, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080252,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.725], [126.81, 37.72], [126.805, 37.72], [126.805, 37.725], [126.81, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080251,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.725], [126.805, 37.72], [126.8, 37.72], [126.8, 37.725], [126.805, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080160,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.725], [126.8, 37.72], [126.795, 37.72], [126.795, 37.725], [126.8, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080159,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.725], [126.795, 37.72], [126.79, 37.72], [126.79, 37.725], [126.795, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080158,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.79, 37.725], [126.79, 37.72], [126.785, 37.72], [126.785, 37.725], [126.79, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080157,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.785, 37.725], [126.785, 37.72], [126.78, 37.72], [126.78, 37.725], [126.785, 37.725]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080445,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.73], [126.925, 37.725], [126.92, 37.725], [126.92, 37.73], [126.925, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080444,
      "ITEM1": 5,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.73], [126.92, 37.725], [126.915, 37.725], [126.915, 37.73], [126.92, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080443,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.73], [126.915, 37.725], [126.91, 37.725], [126.91, 37.73], [126.915, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080442,
      "ITEM1": 3,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 9,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.73], [126.91, 37.725], [126.905, 37.725], [126.905, 37.73], [126.91, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080441,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 1,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.73], [126.905, 37.725], [126.9, 37.725], [126.9, 37.73], [126.905, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080350,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.9, 37.73], [126.9, 37.725], [126.895, 37.725], [126.895, 37.73], [126.9, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080349,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.895, 37.73], [126.895, 37.725], [126.89, 37.725], [126.89, 37.73], [126.895, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080348,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.89, 37.73], [126.89, 37.725], [126.885, 37.725], [126.885, 37.73], [126.89, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080347,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 8,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.885, 37.73], [126.885, 37.725], [126.88, 37.725], [126.88, 37.73], [126.885, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080346,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.88, 37.73], [126.88, 37.725], [126.875, 37.725], [126.875, 37.73], [126.88, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080345,
      "ITEM1": 4,
      "ITEM2": 8,
      "ITEM3": 5,
      "ITEM4": 2,
      "ITEM5": 5,
      "ITEM6": 8,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.73], [126.875, 37.725], [126.87, 37.725], [126.87, 37.73], [126.875, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080344,
      "ITEM1": 5,
      "ITEM2": 3,
      "ITEM3": 2,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.73], [126.87, 37.725], [126.865, 37.725], [126.865, 37.73], [126.87, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080343,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.73], [126.865, 37.725], [126.86, 37.725], [126.86, 37.73], [126.865, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080342,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.73], [126.86, 37.725], [126.855, 37.725], [126.855, 37.73], [126.86, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080341,
      "ITEM1": 5,
      "ITEM2": 2,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.73], [126.855, 37.725], [126.85, 37.725], [126.85, 37.73], [126.855, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080250,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 4,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.73], [126.85, 37.725], [126.845, 37.725], [126.845, 37.73], [126.85, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080249,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.73], [126.845, 37.725], [126.84, 37.725], [126.84, 37.73], [126.845, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080248,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.84, 37.73], [126.84, 37.725], [126.835, 37.725], [126.835, 37.73], [126.84, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080247,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 6,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.835, 37.73], [126.835, 37.725], [126.83, 37.725], [126.83, 37.73], [126.835, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080246,
      "ITEM1": 4,
      "ITEM2": 2,
      "ITEM3": 4,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.83, 37.73], [126.83, 37.725], [126.825, 37.725], [126.825, 37.73], [126.83, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080245,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.825, 37.73], [126.825, 37.725], [126.82, 37.725], [126.82, 37.73], [126.825, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080244,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.82, 37.73], [126.82, 37.725], [126.815, 37.725], [126.815, 37.73], [126.82, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080243,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.815, 37.73], [126.815, 37.725], [126.81, 37.725], [126.81, 37.73], [126.815, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080242,
      "ITEM1": 8,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.81, 37.73], [126.81, 37.725], [126.805, 37.725], [126.805, 37.73], [126.81, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080241,
      "ITEM1": 6,
      "ITEM2": 6,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.73], [126.805, 37.725], [126.8, 37.725], [126.8, 37.73], [126.805, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080150,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.73], [126.8, 37.725], [126.795, 37.725], [126.795, 37.73], [126.8, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080149,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.73], [126.795, 37.725], [126.79, 37.725], [126.79, 37.73], [126.795, 37.73]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080436,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 8,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.735], [126.93, 37.73], [126.925, 37.73], [126.925, 37.735], [126.93, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080435,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.735], [126.925, 37.73], [126.92, 37.73], [126.92, 37.735], [126.925, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080434,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.735], [126.92, 37.73], [126.915, 37.73], [126.915, 37.735], [126.92, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080433,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.735], [126.915, 37.73], [126.91, 37.73], [126.91, 37.735], [126.915, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080432,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 7,
      "ITEM5": 7,
      "ITEM6": 3,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.735], [126.91, 37.73], [126.905, 37.73], [126.905, 37.735], [126.91, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080431,
      "ITEM1": 4,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 8,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.735], [126.905, 37.73], [126.9, 37.73], [126.9, 37.735], [126.905, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080335,
      "ITEM1": 2,
      "ITEM2": 4,
      "ITEM3": 7,
      "ITEM4": 3,
      "ITEM5": 6,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.875, 37.735], [126.875, 37.73], [126.87, 37.73], [126.87, 37.735], [126.875, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080334,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.87, 37.735], [126.87, 37.73], [126.865, 37.73], [126.865, 37.735], [126.87, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080333,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 3,
      "ITEM4": 8,
      "ITEM5": 7,
      "ITEM6": 2,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.865, 37.735], [126.865, 37.73], [126.86, 37.73], [126.86, 37.735], [126.865, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080332,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.735], [126.86, 37.73], [126.855, 37.73], [126.855, 37.735], [126.86, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080331,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.735], [126.855, 37.73], [126.85, 37.73], [126.85, 37.735], [126.855, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080240,
      "ITEM1": 5,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 7,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.85, 37.735], [126.85, 37.73], [126.845, 37.73], [126.845, 37.735], [126.85, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080239,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.845, 37.735], [126.845, 37.73], [126.84, 37.73], [126.84, 37.735], [126.845, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080231,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 5,
      "ITEM6": 3,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.735], [126.805, 37.73], [126.8, 37.73], [126.8, 37.735], [126.805, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080140,
      "ITEM1": 4,
      "ITEM2": 6,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.735], [126.8, 37.73], [126.795, 37.73], [126.795, 37.735], [126.8, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080139,
      "ITEM1": 3,
      "ITEM2": 6,
      "ITEM3": 5,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.735], [126.795, 37.73], [126.79, 37.73], [126.79, 37.735], [126.795, 37.735]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080427,
      "ITEM1": 5,
      "ITEM2": 5,
      "ITEM3": 8,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.74], [126.935, 37.735], [126.93, 37.735], [126.93, 37.74], [126.935, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080426,
      "ITEM1": 3,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 7,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.74], [126.93, 37.735], [126.925, 37.735], [126.925, 37.74], [126.93, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080425,
      "ITEM1": 7,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 4,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.74], [126.925, 37.735], [126.92, 37.735], [126.92, 37.74], [126.925, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080424,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 3,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.74], [126.92, 37.735], [126.915, 37.735], [126.915, 37.74], [126.92, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080423,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 3,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.74], [126.915, 37.735], [126.91, 37.735], [126.91, 37.74], [126.915, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080422,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 3,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.74], [126.91, 37.735], [126.905, 37.735], [126.905, 37.74], [126.91, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080421,
      "ITEM1": 6,
      "ITEM2": 7,
      "ITEM3": 5,
      "ITEM4": 3,
      "ITEM5": 2,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.74], [126.905, 37.735], [126.9, 37.735], [126.9, 37.74], [126.905, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080322,
      "ITEM1": 8,
      "ITEM2": 3,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.86, 37.74], [126.86, 37.735], [126.855, 37.735], [126.855, 37.74], [126.86, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080321,
      "ITEM1": 3,
      "ITEM2": 5,
      "ITEM3": 5,
      "ITEM4": 8,
      "ITEM5": 3,
      "ITEM6": 6,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.855, 37.74], [126.855, 37.735], [126.85, 37.735], [126.85, 37.74], [126.855, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080221,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 7,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 7,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.805, 37.74], [126.805, 37.735], [126.8, 37.735], [126.8, 37.74], [126.805, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080130,
      "ITEM1": 0,
      "ITEM2": 4,
      "ITEM3": 8,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 5,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.8, 37.74], [126.8, 37.735], [126.795, 37.735], [126.795, 37.74], [126.8, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080129,
      "ITEM1": 6,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 4,
      "ITEM5": 7,
      "ITEM6": 6,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.795, 37.74], [126.795, 37.735], [126.79, 37.735], [126.79, 37.74], [126.795, 37.74]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080417,
      "ITEM1": 4,
      "ITEM2": 7,
      "ITEM3": 4,
      "ITEM4": 6,
      "ITEM5": 7,
      "ITEM6": 5,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.745], [126.935, 37.74], [126.93, 37.74], [126.93, 37.745], [126.935, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080416,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 6,
      "ITEM5": 2,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.745], [126.93, 37.74], [126.925, 37.74], [126.925, 37.745], [126.93, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080415,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 8,
      "ITEM5": 6,
      "ITEM6": 4,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.745], [126.925, 37.74], [126.92, 37.74], [126.92, 37.745], [126.925, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080414,
      "ITEM1": 4,
      "ITEM2": 4,
      "ITEM3": 4,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 5,
      "ITEMT": 2
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.745], [126.92, 37.74], [126.915, 37.74], [126.915, 37.745], [126.92, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080413,
      "ITEM1": 6,
      "ITEM2": 2,
      "ITEM3": 5,
      "ITEM4": 6,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 0
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.745], [126.915, 37.74], [126.91, 37.74], [126.91, 37.745], [126.915, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080412,
      "ITEM1": 6,
      "ITEM2": 8,
      "ITEM3": 4,
      "ITEM4": 7,
      "ITEM5": 4,
      "ITEM6": 5,
      "ITEMT": 5
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.745], [126.91, 37.74], [126.905, 37.74], [126.905, 37.745], [126.91, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080411,
      "ITEM1": 7,
      "ITEM2": 6,
      "ITEM3": 9,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.905, 37.745], [126.905, 37.74], [126.9, 37.74], [126.9, 37.745], [126.905, 37.745]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080407,
      "ITEM1": 7,
      "ITEM2": 5,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 3
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.935, 37.75], [126.935, 37.745], [126.93, 37.745], [126.93, 37.75], [126.935, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080406,
      "ITEM1": 6,
      "ITEM2": 5,
      "ITEM3": 7,
      "ITEM4": 4,
      "ITEM5": 3,
      "ITEM6": 7,
      "ITEMT": 6
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.93, 37.75], [126.93, 37.745], [126.925, 37.745], [126.925, 37.75], [126.93, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080405,
      "ITEM1": 5,
      "ITEM2": 8,
      "ITEM3": 6,
      "ITEM4": 5,
      "ITEM5": 6,
      "ITEM6": 6,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.925, 37.75], [126.925, 37.745], [126.92, 37.745], [126.92, 37.75], [126.925, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080404,
      "ITEM1": 5,
      "ITEM2": 6,
      "ITEM3": 2,
      "ITEM4": 4,
      "ITEM5": 4,
      "ITEM6": 7,
      "ITEMT": 4
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.92, 37.75], [126.92, 37.745], [126.915, 37.745], [126.915, 37.75], [126.92, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080403,
      "ITEM1": 3,
      "ITEM2": 7,
      "ITEM3": 6,
      "ITEM4": 3,
      "ITEM5": 5,
      "ITEM6": 4,
      "ITEMT": 7
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.915, 37.75], [126.915, 37.745], [126.91, 37.745], [126.91, 37.75], [126.915, 37.75]]]]
    }
  }, {
    "type": "Feature",
    "properties": {
      "id": null,
      "accident": null,
      "MAPIDCD_NO": 376080402,
      "ITEM1": 6,
      "ITEM2": 1,
      "ITEM3": 8,
      "ITEM4": 5,
      "ITEM5": 3,
      "ITEM6": 4,
      "ITEMT": 1
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [[[[126.91, 37.75], [126.91, 37.745], [126.905, 37.745], [126.905, 37.75], [126.91, 37.75]]]]
    }
  }
  ]
}


// export const cityDistrictData = {
//   type: 'FeatureCollection',
//   name: 'goyang_index(1000)_4326',
//   crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
//   features: [
//     {
//       id: '376081852',
//       type: 'Feature',
//       properties: {
//         fid: 389790,
//         MAPIDCD_NO: '376081852',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000003342936, 37.575000018677954],
//             [126.859999998061, 37.570000041926576],
//             [126.85500004864699, 37.570000042870468],
//             [126.85499997617667, 37.575000019569629],
//             [126.86000003342936, 37.575000018677954]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081851',
//       type: 'Feature',
//       properties: {
//         fid: 389792,
//         MAPIDCD_NO: '376081851',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499997617667, 37.575000019569629],
//             [126.85500004864699, 37.570000042870468],
//             [126.84999998754591, 37.570000011675461],
//             [126.85000003371061, 37.574999989550442],
//             [126.85499997617667, 37.575000019569629]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081760',
//       type: 'Feature',
//       properties: {
//         fid: 389794,
//         MAPIDCD_NO: '376081760',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000003371061, 37.574999989550442],
//             [126.84999998754591, 37.570000011675461],
//             [126.84500003817098, 37.570000041503121],
//             [126.84499997651122, 37.575000017494851],
//             [126.85000003371061, 37.574999989550442]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081846',
//       type: 'Feature',
//       properties: {
//         fid: 390311,
//         MAPIDCD_NO: '376081846',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000005032711, 37.580000034646567],
//             [126.88000003525954, 37.574999968902269],
//             [126.87499997951575, 37.575000005824307],
//             [126.87499999844172, 37.579999980228621],
//             [126.88000005032711, 37.580000034646567]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081845',
//       type: 'Feature',
//       properties: {
//         fid: 390313,
//         MAPIDCD_NO: '376081845',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499999844172, 37.579999980228621],
//             [126.87499997951575, 37.575000005824307],
//             [126.87000003397674, 37.575000011793954],
//             [126.86999994502131, 37.579999986189158],
//             [126.87499999844172, 37.579999980228621]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081844',
//       type: 'Feature',
//       properties: {
//         fid: 390315,
//         MAPIDCD_NO: '376081844',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999994502131, 37.579999986189158],
//             [126.87000003397674, 37.575000011793954],
//             [126.86499997670546, 37.574999985630782],
//             [126.86500000635816, 37.579999961216316],
//             [126.86999994502131, 37.579999986189158]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081843',
//       type: 'Feature',
//       properties: {
//         fid: 390318,
//         MAPIDCD_NO: '376081843',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500000635816, 37.579999961216316],
//             [126.86499997670546, 37.574999985630782],
//             [126.86000003342936, 37.575000018677954],
//             [126.85999995521463, 37.579999992423396],
//             [126.86500000635816, 37.579999961216316]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081842',
//       type: 'Feature',
//       properties: {
//         fid: 390320,
//         MAPIDCD_NO: '376081842',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999995521463, 37.579999992423396],
//             [126.86000003342936, 37.575000018677954],
//             [126.85499997617667, 37.575000019569629],
//             [126.85500001656817, 37.579999994496156],
//             [126.85999995521463, 37.579999992423396]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081841',
//       type: 'Feature',
//       properties: {
//         fid: 390322,
//         MAPIDCD_NO: '376081841',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500001656817, 37.579999994496156],
//             [126.85499997617667, 37.575000019569629],
//             [126.85000003371061, 37.574999989550442],
//             [126.84999996623355, 37.579999962608525],
//             [126.85500001656817, 37.579999994496156]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081750',
//       type: 'Feature',
//       properties: {
//         fid: 390324,
//         MAPIDCD_NO: '376081750',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999996623355, 37.579999962608525],
//             [126.85000003371061, 37.574999989550442],
//             [126.84499997651122, 37.575000017494851],
//             [126.84500002763841, 37.57999999172479],
//             [126.84999996623355, 37.579999962608525]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081749',
//       type: 'Feature',
//       properties: {
//         fid: 390326,
//         MAPIDCD_NO: '376081749',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500002763841, 37.57999999172479],
//             [126.84499997651122, 37.575000017494851],
//             [126.84000003411852, 37.575000014537082],
//             [126.83999997737585, 37.579999986870163],
//             [126.84500002763841, 37.57999999172479]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081836',
//       type: 'Feature',
//       properties: {
//         fid: 390861,
//         MAPIDCD_NO: '376081836',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999995045054, 37.585000005456727],
//             [126.88000005032711, 37.580000034646567],
//             [126.87499999844172, 37.579999980228621],
//             [126.87500001639204, 37.585000042368989],
//             [126.87999995045054, 37.585000005456727]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081835',
//       type: 'Feature',
//       properties: {
//         fid: 390863,
//         MAPIDCD_NO: '376081835',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500001639204, 37.585000042368989],
//             [126.87499999844172, 37.579999980228621],
//             [126.86999994502131, 37.579999986189158],
//             [126.869999969063, 37.58499995699281],
//             [126.87500001639204, 37.585000042368989]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081834',
//       type: 'Feature',
//       properties: {
//         fid: 390865,
//         MAPIDCD_NO: '376081834',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.869999969063, 37.58499995699281],
//             [126.86999994502131, 37.579999986189158],
//             [126.86500000635816, 37.579999961216316],
//             [126.86500003497913, 37.585000020933776],
//             [126.869999969063, 37.58499995699281]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081833',
//       type: 'Feature',
//       properties: {
//         fid: 390868,
//         MAPIDCD_NO: '376081833',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500003497913, 37.585000020933776],
//             [126.86500000635816, 37.579999961216316],
//             [126.85999995521463, 37.579999992423396],
//             [126.85999998765728, 37.584999962575779],
//             [126.86500003497913, 37.585000020933776]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081832',
//       type: 'Feature',
//       properties: {
//         fid: 390870,
//         MAPIDCD_NO: '376081832',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999998765728, 37.584999962575779],
//             [126.85999995521463, 37.579999992423396],
//             [126.85500001656817, 37.579999994496156],
//             [126.85500005435422, 37.584999963413608],
//             [126.85999998765728, 37.584999962575779]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081831',
//       type: 'Feature',
//       properties: {
//         fid: 390872,
//         MAPIDCD_NO: '376081831',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500005435422, 37.584999963413608],
//             [126.85500001656817, 37.579999994496156],
//             [126.84999996623355, 37.579999962608525],
//             [126.85000000855557, 37.585000022213748],
//             [126.85500005435422, 37.584999963413608]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081740',
//       type: 'Feature',
//       properties: {
//         fid: 390874,
//         MAPIDCD_NO: '376081740',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000000855557, 37.585000022213748],
//             [126.84999996623355, 37.579999962608525],
//             [126.84500002763841, 37.57999999172479],
//             [126.84499996283, 37.584999959318239],
//             [126.85000000855557, 37.585000022213748]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081739',
//       type: 'Feature',
//       properties: {
//         fid: 390876,
//         MAPIDCD_NO: '376081739',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499996283, 37.584999959318239],
//             [126.84500002763841, 37.57999999172479],
//             [126.83999997737585, 37.579999986870163],
//             [126.84000003114134, 37.584999955631496],
//             [126.84499996283, 37.584999959318239]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081738',
//       type: 'Feature',
//       properties: {
//         fid: 390879,
//         MAPIDCD_NO: '376081738',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000003114134, 37.584999955631496],
//             [126.83999997737585, 37.579999986870163],
//             [126.83500003887653, 37.580000041225134],
//             [126.83499998697576, 37.585000009891175],
//             [126.84000003114134, 37.584999955631496]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081737',
//       type: 'Feature',
//       properties: {
//         fid: 390881,
//         MAPIDCD_NO: '376081737',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499998697576, 37.585000009891175],
//             [126.83500003887653, 37.580000041225134],
//             [126.82999998950623, 37.5799999750746],
//             [126.8300000553857, 37.585000033226059],
//             [126.83499998697576, 37.585000009891175]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081830',
//       type: 'Feature',
//       properties: {
//         fid: 391401,
//         MAPIDCD_NO: '376081830',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000001814613, 37.58999995940534],
//             [126.90000002839909, 37.584999991121514],
//             [126.89499997895838, 37.584999975185525],
//             [126.89499997554528, 37.590000032452],
//             [126.90000001814613, 37.58999995940534]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081829',
//       type: 'Feature',
//       properties: {
//         fid: 391404,
//         MAPIDCD_NO: '376081829',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499997554528, 37.590000032452],
//             [126.89499997895838, 37.584999975185525],
//             [126.89000004576991, 37.585000016624242],
//             [126.89000004613232, 37.589999982580245],
//             [126.89499997554528, 37.590000032452]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081828',
//       type: 'Feature',
//       properties: {
//         fid: 391407,
//         MAPIDCD_NO: '376081828',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000004613232, 37.589999982580245],
//             [126.89000004576991, 37.585000016624242],
//             [126.88499999624938, 37.585000025908634],
//             [126.88500000416509, 37.589999992501021],
//             [126.89000004613232, 37.589999982580245]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081827',
//       type: 'Feature',
//       properties: {
//         fid: 391409,
//         MAPIDCD_NO: '376081827',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500000416509, 37.589999992501021],
//             [126.88499999624938, 37.585000025908634],
//             [126.87999995045054, 37.585000005456727],
//             [126.87999996140326, 37.589999970849888],
//             [126.88500000416509, 37.589999992501021]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081826',
//       type: 'Feature',
//       properties: {
//         fid: 391411,
//         MAPIDCD_NO: '376081826',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999996140326, 37.589999970849888],
//             [126.87999995045054, 37.585000005456727],
//             [126.87500001639204, 37.585000042368989],
//             [126.87500003263877, 37.590000008367959],
//             [126.87999996140326, 37.589999970849888]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081825',
//       type: 'Feature',
//       properties: {
//         fid: 391413,
//         MAPIDCD_NO: '376081825',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500003263877, 37.590000008367959],
//             [126.87500001639204, 37.585000042368989],
//             [126.869999969063, 37.58499995699281],
//             [126.86999998985093, 37.590000011915485],
//             [126.87500003263877, 37.590000008367959]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081824',
//       type: 'Feature',
//       properties: {
//         fid: 391415,
//         MAPIDCD_NO: '376081824',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999998985093, 37.590000011915485],
//             [126.869999969063, 37.58499995699281],
//             [126.86500003497913, 37.585000020933776],
//             [126.86499994855511, 37.589999985704246],
//             [126.86999998985093, 37.590000011915485]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081823',
//       type: 'Feature',
//       properties: {
//         fid: 391418,
//         MAPIDCD_NO: '376081823',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499994855511, 37.589999985704246],
//             [126.86500003497913, 37.585000020933776],
//             [126.85999998765728, 37.584999962575779],
//             [126.86000002127476, 37.590000018675219],
//             [126.86499994855511, 37.589999985704246]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081822',
//       type: 'Feature',
//       properties: {
//         fid: 391420,
//         MAPIDCD_NO: '376081822',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000002127476, 37.590000018675219],
//             [126.85999998765728, 37.584999962575779],
//             [126.85500005435422, 37.584999963413608],
//             [126.8549999800081, 37.590000017659776],
//             [126.86000002127476, 37.590000018675219]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081821',
//       type: 'Feature',
//       properties: {
//         fid: 391422,
//         MAPIDCD_NO: '376081821',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8549999800081, 37.590000017659776],
//             [126.85500005435422, 37.584999963413608],
//             [126.85000000855557, 37.585000022213748],
//             [126.85000005353329, 37.589999987508179],
//             [126.8549999800081, 37.590000017659776]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081730',
//       type: 'Feature',
//       properties: {
//         fid: 391424,
//         MAPIDCD_NO: '376081730',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000005353329, 37.589999987508179],
//             [126.85000000855557, 37.585000022213748],
//             [126.84499996283, 37.584999959318239],
//             [126.84500001231513, 37.590000013489025],
//             [126.85000005353329, 37.589999987508179]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081729',
//       type: 'Feature',
//       properties: {
//         fid: 391426,
//         MAPIDCD_NO: '376081729',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500001231513, 37.590000013489025],
//             [126.84499996283, 37.584999959318239],
//             [126.84000003114134, 37.584999955631496],
//             [126.83999997264525, 37.59000000970903],
//             [126.84500001231513, 37.590000013489025]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081728',
//       type: 'Feature',
//       properties: {
//         fid: 391429,
//         MAPIDCD_NO: '376081728',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999997264525, 37.59000000970903],
//             [126.84000003114134, 37.584999955631496],
//             [126.83499998697576, 37.585000009891175],
//             [126.83500004556952, 37.589999973188107],
//             [126.83999997264525, 37.59000000970903]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081727',
//       type: 'Feature',
//       properties: {
//         fid: 391431,
//         MAPIDCD_NO: '376081727',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500004556952, 37.589999973188107],
//             [126.83499998697576, 37.585000009891175],
//             [126.8300000553857, 37.585000033226059],
//             [126.83000000600269, 37.5899999964013],
//             [126.83500004556952, 37.589999973188107]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081726',
//       type: 'Feature',
//       properties: {
//         fid: 391434,
//         MAPIDCD_NO: '376081726',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000000600269, 37.5899999964013],
//             [126.8300000553857, 37.585000033226059],
//             [126.82500000987592, 37.585000024354621],
//             [126.82499996578729, 37.589999986234332],
//             [126.83000000600269, 37.5899999964013]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081725',
//       type: 'Feature',
//       properties: {
//         fid: 391437,
//         MAPIDCD_NO: '376081725',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499996578729, 37.589999986234332],
//             [126.82500000987592, 37.585000024354621],
//             [126.81999996598354, 37.584999983927396],
//             [126.82000004188042, 37.5900000370993],
//             [126.82499996578729, 37.589999986234332]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081911',
//       type: 'Feature',
//       properties: {
//         fid: 391949,
//         MAPIDCD_NO: '376081911',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500004747253, 37.594999998502445],
//             [126.9049999497345, 37.590000034500932],
//             [126.90000001814613, 37.58999995940534],
//             [126.90000000942868, 37.595000011828475],
//             [126.90500004747253, 37.594999998502445]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081820',
//       type: 'Feature',
//       properties: {
//         fid: 391951,
//         MAPIDCD_NO: '376081820',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000000942868, 37.595000011828475],
//             [126.90000001814613, 37.58999995940534],
//             [126.89499997554528, 37.590000032452],
//             [126.89499997053852, 37.594999995377243],
//             [126.90000000942868, 37.595000011828475]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081819',
//       type: 'Feature',
//       properties: {
//         fid: 391954,
//         MAPIDCD_NO: '376081819',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499997053852, 37.594999995377243],
//             [126.89499997554528, 37.590000032452],
//             [126.89000004613232, 37.589999982580245],
//             [126.8900000456491, 37.595000036270235],
//             [126.89499997053852, 37.594999995377243]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081818',
//       type: 'Feature',
//       properties: {
//         fid: 391957,
//         MAPIDCD_NO: '376081818',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8900000456491, 37.595000036270235],
//             [126.89000004613232, 37.589999982580245],
//             [126.88500000416509, 37.589999992501021],
//             [126.88500000894145, 37.595000045013485],
//             [126.8900000456491, 37.595000036270235]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081817',
//       type: 'Feature',
//       properties: {
//         fid: 391959,
//         MAPIDCD_NO: '376081817',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500000894145, 37.595000045013485],
//             [126.88500000416509, 37.589999992501021],
//             [126.87999996140326, 37.589999970849888],
//             [126.87999997143859, 37.595000022175462],
//             [126.88500000894145, 37.595000045013485]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081816',
//       type: 'Feature',
//       properties: {
//         fid: 391961,
//         MAPIDCD_NO: '376081816',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999997143859, 37.595000022175462],
//             [126.87999996140326, 37.589999970849888],
//             [126.87500003263877, 37.590000008367959],
//             [126.87500004867879, 37.594999970170043],
//             [126.87999997143859, 37.595000022175462]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081815',
//       type: 'Feature',
//       properties: {
//         fid: 391963,
//         MAPIDCD_NO: '376081815',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500004867879, 37.594999970170043],
//             [126.87500003263877, 37.590000008367959],
//             [126.86999998985093, 37.590000011915485],
//             [126.87000001113924, 37.594999974314561],
//             [126.87500004867879, 37.594999970170043]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081814',
//       type: 'Feature',
//       properties: {
//         fid: 391965,
//         MAPIDCD_NO: '376081814',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000001113924, 37.594999974314561],
//             [126.86999998985093, 37.590000011915485],
//             [126.86499994855511, 37.589999985704246],
//             [126.86499997660587, 37.595000037030218],
//             [126.87000001113924, 37.594999974314561]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081813',
//       type: 'Feature',
//       properties: {
//         fid: 391968,
//         MAPIDCD_NO: '376081813',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499997660587, 37.595000037030218],
//             [126.86499994855511, 37.589999985704246],
//             [126.86000002127476, 37.590000018675219],
//             [126.86000005309575, 37.594999978634995],
//             [126.86499997660587, 37.595000037030218]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081812',
//       type: 'Feature',
//       properties: {
//         fid: 391970,
//         MAPIDCD_NO: '376081812',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000005309575, 37.594999978634995],
//             [126.86000002127476, 37.590000018675219],
//             [126.8549999800081, 37.590000017659776],
//             [126.8550000170751, 37.5949999781884],
//             [126.86000005309575, 37.594999978634995]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081811',
//       type: 'Feature',
//       properties: {
//         fid: 391972,
//         MAPIDCD_NO: '376081811',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8550000170751, 37.5949999781884],
//             [126.8549999800081, 37.590000017659776],
//             [126.85000005353329, 37.589999987508179],
//             [126.84999998179784, 37.595000038101851],
//             [126.8550000170751, 37.5949999781884]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081720',
//       type: 'Feature',
//       properties: {
//         fid: 391974,
//         MAPIDCD_NO: '376081720',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999998179784, 37.595000038101851],
//             [126.85000005353329, 37.589999987508179],
//             [126.84500001231513, 37.590000013489025],
//             [126.8449999466079, 37.594999974498528],
//             [126.84999998179784, 37.595000038101851]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081719',
//       type: 'Feature',
//       properties: {
//         fid: 391976,
//         MAPIDCD_NO: '376081719',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8449999466079, 37.594999974498528],
//             [126.84500001231513, 37.590000013489025],
//             [126.83999997264525, 37.59000000970903],
//             [126.84000002320293, 37.594999970072543],
//             [126.8449999466079, 37.594999974498528]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081718',
//       type: 'Feature',
//       properties: {
//         fid: 391979,
//         MAPIDCD_NO: '376081718',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000002320293, 37.594999970072543],
//             [126.83999997264525, 37.59000000970903],
//             [126.83500004556952, 37.589999973188107],
//             [126.83499998956974, 37.595000023586536],
//             [126.84000002320293, 37.594999970072543]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081717',
//       type: 'Feature',
//       properties: {
//         fid: 391981,
//         MAPIDCD_NO: '376081717',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499998956974, 37.595000023586536],
//             [126.83500004556952, 37.589999973188107],
//             [126.83000000600269, 37.5899999964013],
//             [126.82999995525476, 37.595000045514112],
//             [126.83499998956974, 37.595000023586536]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081716',
//       type: 'Feature',
//       properties: {
//         fid: 391984,
//         MAPIDCD_NO: '376081716',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999995525476, 37.595000045514112],
//             [126.83000000600269, 37.5899999964013],
//             [126.82499996578729, 37.589999986234332],
//             [126.8250000335448, 37.595000036502519],
//             [126.82999995525476, 37.595000045514112]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081715',
//       type: 'Feature',
//       properties: {
//         fid: 391987,
//         MAPIDCD_NO: '376081715',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8250000335448, 37.595000036502519],
//             [126.82499996578729, 37.589999986234332],
//             [126.82000004188042, 37.5900000370993],
//             [126.82000000018007, 37.594999995273433],
//             [126.8250000335448, 37.595000036502519]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081714',
//       type: 'Feature',
//       properties: {
//         fid: 391989,
//         MAPIDCD_NO: '376081714',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000000018007, 37.594999995273433],
//             [126.82000004188042, 37.5900000370993],
//             [126.81500000264325, 37.589999963793417],
//             [126.81499996762129, 37.595000012598291],
//             [126.82000000018007, 37.594999995273433]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081901',
//       type: 'Feature',
//       properties: {
//         fid: 392499,
//         MAPIDCD_NO: '376081901',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500003197681, 37.599999955929725],
//             [126.90500004747253, 37.594999998502445],
//             [126.90000000942868, 37.595000011828475],
//             [126.89999999914906, 37.599999969909675],
//             [126.90500003197681, 37.599999955929725]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081810',
//       type: 'Feature',
//       properties: {
//         fid: 392501,
//         MAPIDCD_NO: '376081810',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999999914906, 37.599999969909675],
//             [126.90000000942868, 37.595000011828475],
//             [126.89499997053852, 37.594999995377243],
//             [126.89499996475891, 37.600000042430487],
//             [126.89999999914906, 37.599999969909675]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081809',
//       type: 'Feature',
//       properties: {
//         fid: 392504,
//         MAPIDCD_NO: '376081809',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499996475891, 37.600000042430487],
//             [126.89499997053852, 37.594999995377243],
//             [126.8900000456491, 37.595000036270235],
//             [126.89000004582138, 37.599999993828568],
//             [126.89499996475891, 37.600000042430487]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081808',
//       type: 'Feature',
//       properties: {
//         fid: 392507,
//         MAPIDCD_NO: '376081808',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000004582138, 37.599999993828568],
//             [126.8900000456491, 37.595000036270235],
//             [126.88500000894145, 37.595000045013485],
//             [126.88500001432747, 37.600000003197593],
//             [126.89000004582138, 37.599999993828568]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081807',
//       type: 'Feature',
//       properties: {
//         fid: 392509,
//         MAPIDCD_NO: '376081807',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500001432747, 37.600000003197593],
//             [126.88500000894145, 37.595000045013485],
//             [126.87999997143859, 37.595000022175462],
//             [126.87999998203739, 37.599999980975937],
//             [126.88500001432747, 37.600000003197593]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081806',
//       type: 'Feature',
//       properties: {
//         fid: 392511,
//         MAPIDCD_NO: '376081806',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999998203739, 37.599999980975937],
//             [126.87999997143859, 37.595000022175462],
//             [126.87500004867879, 37.594999970170043],
//             [126.87499995047897, 37.6000000173049],
//             [126.87999998203739, 37.599999980975937]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081805',
//       type: 'Feature',
//       properties: {
//         fid: 392513,
//         MAPIDCD_NO: '376081805',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499995047897, 37.6000000173049],
//             [126.87500004867879, 37.594999970170043],
//             [126.87000001113924, 37.594999974314561],
//             [126.87000003143906, 37.600000020844234],
//             [126.87499995047897, 37.6000000173049]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081804',
//       type: 'Feature',
//       properties: {
//         fid: 392515,
//         MAPIDCD_NO: '376081804',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000003143906, 37.600000020844234],
//             [126.87000001113924, 37.594999974314561],
//             [126.86499997660587, 37.595000037030218],
//             [126.86500000061061, 37.599999994006154],
//             [126.87000003143906, 37.600000020844234]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081803',
//       type: 'Feature',
//       properties: {
//         fid: 392518,
//         MAPIDCD_NO: '376081803',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500000061061, 37.599999994006154],
//             [126.86499997660587, 37.595000037030218],
//             [126.86000005309575, 37.594999978634995],
//             [126.85999997053209, 37.600000025717272],
//             [126.86500000061061, 37.599999994006154]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081802',
//       type: 'Feature',
//       properties: {
//         fid: 392520,
//         MAPIDCD_NO: '376081802',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999997053209, 37.600000025717272],
//             [126.86000005309575, 37.594999978634995],
//             [126.8550000170751, 37.5949999781884],
//             [126.8550000530086, 37.60000002465172],
//             [126.85999997053209, 37.600000025717272]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081801',
//       type: 'Feature',
//       properties: {
//         fid: 392522,
//         MAPIDCD_NO: '376081801',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8550000530086, 37.60000002465172],
//             [126.8550000170751, 37.5949999781884],
//             [126.84999998179784, 37.595000038101851],
//             [126.85000002146737, 37.599999993180717],
//             [126.8550000530086, 37.60000002465172]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081710',
//       type: 'Feature',
//       properties: {
//         fid: 392524,
//         MAPIDCD_NO: '376081710',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000002146737, 37.599999993180717],
//             [126.84999998179784, 37.595000038101851],
//             [126.8449999466079, 37.594999974498528],
//             [126.84499999069446, 37.600000020257241],
//             [126.85000002146737, 37.599999993180717]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081709',
//       type: 'Feature',
//       properties: {
//         fid: 392526,
//         MAPIDCD_NO: '376081709',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499999069446, 37.600000020257241],
//             [126.8449999466079, 37.594999974498528],
//             [126.84000002320293, 37.594999970072543],
//             [126.8399999614841, 37.600000015751576],
//             [126.84499999069446, 37.600000020257241]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081708',
//       type: 'Feature',
//       properties: {
//         fid: 392529,
//         MAPIDCD_NO: '376081708',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8399999614841, 37.600000015751576],
//             [126.84000002320293, 37.594999970072543],
//             [126.83499998956974, 37.595000023586536],
//             [126.83500004488147, 37.599999978486409],
//             [126.8399999614841, 37.600000015751576]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081707',
//       type: 'Feature',
//       properties: {
//         fid: 392531,
//         MAPIDCD_NO: '376081707',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500004488147, 37.599999978486409],
//             [126.83499998956974, 37.595000023586536],
//             [126.82999995525476, 37.595000045514112],
//             [126.83000001577092, 37.600000000936369],
//             [126.83500004488147, 37.599999978486409]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081706',
//       type: 'Feature',
//       properties: {
//         fid: 392534,
//         MAPIDCD_NO: '376081706',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000001577092, 37.600000000936369],
//             [126.82999995525476, 37.595000045514112],
//             [126.8250000335448, 37.595000036502519],
//             [126.82499998827571, 37.599999990000363],
//             [126.83000001577092, 37.600000000936369]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081705',
//       type: 'Feature',
//       properties: {
//         fid: 392537,
//         MAPIDCD_NO: '376081705',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499998827571, 37.599999990000363],
//             [126.8250000335448, 37.595000036502519],
//             [126.82000000018007, 37.594999995273433],
//             [126.81999995929358, 37.600000039398992],
//             [126.82499998827571, 37.599999990000363]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081704',
//       type: 'Feature',
//       properties: {
//         fid: 392539,
//         MAPIDCD_NO: '376081704',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999995929358, 37.600000039398992],
//             [126.82000000018007, 37.594999995273433],
//             [126.81499996762129, 37.595000012598291],
//             [126.81500004378606, 37.599999965926351],
//             [126.81999995929358, 37.600000039398992]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081703',
//       type: 'Feature',
//       properties: {
//         fid: 392541,
//         MAPIDCD_NO: '376081703',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500004378606, 37.599999965926351],
//             [126.81499996762129, 37.595000012598291],
//             [126.81000004772031, 37.594999997194293],
//             [126.81000001723233, 37.600000042289395],
//             [126.81500004378606, 37.599999965926351]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081702',
//       type: 'Feature',
//       properties: {
//         fid: 392543,
//         MAPIDCD_NO: '376081702',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000001723233, 37.600000042289395],
//             [126.81000004772031, 37.594999997194293],
//             [126.80500001534654, 37.595000041488277],
//             [126.80499998864221, 37.599999995109584],
//             [126.81000001723233, 37.600000042289395]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081491',
//       type: 'Feature',
//       properties: {
//         fid: 393049,
//         MAPIDCD_NO: '376081491',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500001576598, 37.605000001088975],
//             [126.90500003197681, 37.599999955929725],
//             [126.89999999914906, 37.599999969909675],
//             [126.89999998811098, 37.605000015723334],
//             [126.90500001576598, 37.605000001088975]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081300',
//       type: 'Feature',
//       properties: {
//         fid: 393051,
//         MAPIDCD_NO: '376081300',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999998811098, 37.605000015723334],
//             [126.89999999914906, 37.599999969909675],
//             [126.89499996475891, 37.600000042430487],
//             [126.8949999596371, 37.604999996956508],
//             [126.89999998811098, 37.605000015723334]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081399',
//       type: 'Feature',
//       properties: {
//         fid: 393054,
//         MAPIDCD_NO: '376081399',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8949999596371, 37.604999996956508],
//             [126.89499996475891, 37.600000042430487],
//             [126.89000004582138, 37.599999993828568],
//             [126.89000004516311, 37.605000037317765],
//             [126.8949999596371, 37.604999996956508]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081398',
//       type: 'Feature',
//       properties: {
//         fid: 393057,
//         MAPIDCD_NO: '376081398',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000004516311, 37.605000037317765],
//             [126.89000004582138, 37.599999993828568],
//             [126.88500001432747, 37.600000003197593],
//             [126.88500001731597, 37.604999957171387],
//             [126.89000004516311, 37.605000037317765]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081397',
//       type: 'Feature',
//       properties: {
//         fid: 393059,
//         MAPIDCD_NO: '376081397',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500001731597, 37.604999957171387],
//             [126.88500001432747, 37.600000003197593],
//             [126.87999998203739, 37.599999980975937],
//             [126.87999999173411, 37.605000023905738],
//             [126.88500001731597, 37.604999957171387]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081396',
//       type: 'Feature',
//       properties: {
//         fid: 393061,
//         MAPIDCD_NO: '376081396',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999999173411, 37.605000023905738],
//             [126.87999998203739, 37.599999980975937],
//             [126.87499995047897, 37.6000000173049],
//             [126.87499996383282, 37.604999970700462],
//             [126.87999999173411, 37.605000023905738]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081395',
//       type: 'Feature',
//       properties: {
//         fid: 393063,
//         MAPIDCD_NO: '376081395',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499996383282, 37.604999970700462],
//             [126.87499995047897, 37.6000000173049],
//             [126.87000003143906, 37.600000020844234],
//             [126.87000005224013, 37.60499997485006],
//             [126.87499996383282, 37.604999970700462]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081394',
//       type: 'Feature',
//       properties: {
//         fid: 393065,
//         MAPIDCD_NO: '376081394',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000005224013, 37.60499997485006],
//             [126.87000003143906, 37.600000020844234],
//             [126.86500000061061, 37.599999994006154],
//             [126.86500002583394, 37.605000036927756],
//             [126.87000005224013, 37.60499997485006]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081393',
//       type: 'Feature',
//       properties: {
//         fid: 393068,
//         MAPIDCD_NO: '376081393',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500002583394, 37.605000036927756],
//             [126.86500000061061, 37.599999994006154],
//             [126.85999997053209, 37.600000025717272],
//             [126.85999999942821, 37.604999979076474],
//             [126.86500002583394, 37.605000036927756]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081392',
//       type: 'Feature',
//       properties: {
//         fid: 393070,
//         MAPIDCD_NO: '376081392',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999999942821, 37.604999979076474],
//             [126.85999997053209, 37.600000025717272],
//             [126.8550000530086, 37.60000002465172],
//             [126.85499997379722, 37.604999977961789],
//             [126.85999999942821, 37.604999979076474]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081391',
//       type: 'Feature',
//       properties: {
//         fid: 393072,
//         MAPIDCD_NO: '376081391',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499997379722, 37.604999977961789],
//             [126.8550000530086, 37.60000002465172],
//             [126.85000002146737, 37.599999993180717],
//             [126.84999994892372, 37.605000035385707],
//             [126.85499997379722, 37.604999977961789]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081200',
//       type: 'Feature',
//       properties: {
//         fid: 393074,
//         MAPIDCD_NO: '376081200',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999994892372, 37.605000035385707],
//             [126.85000002146737, 37.599999993180717],
//             [126.84499999069446, 37.600000020257241],
//             [126.84500003740696, 37.60499997170561],
//             [126.84999994892372, 37.605000035385707]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081299',
//       type: 'Feature',
//       properties: {
//         fid: 393076,
//         MAPIDCD_NO: '376081299',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500003740696, 37.60499997170561],
//             [126.84499999069446, 37.600000020257241],
//             [126.8399999614841, 37.600000015751576],
//             [126.84000001111578, 37.6049999659266],
//             [126.84500003740696, 37.60499997170561]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081298',
//       type: 'Feature',
//       properties: {
//         fid: 393079,
//         MAPIDCD_NO: '376081298',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000001111578, 37.6049999659266],
//             [126.8399999614841, 37.600000015751576],
//             [126.83500004488147, 37.599999978486409],
//             [126.83499998786616, 37.605000018697311],
//             [126.84000001111578, 37.6049999659266]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081297',
//       type: 'Feature',
//       properties: {
//         fid: 393081,
//         MAPIDCD_NO: '376081297',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499998786616, 37.605000018697311],
//             [126.83500004488147, 37.599999978486409],
//             [126.83000001577092, 37.600000000936369],
//             [126.82999996391696, 37.605000041665484],
//             [126.83499998786616, 37.605000018697311]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081296',
//       type: 'Feature',
//       properties: {
//         fid: 393084,
//         MAPIDCD_NO: '376081296',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999996391696, 37.605000041665484],
//             [126.83000001577092, 37.600000000936369],
//             [126.82499998827571, 37.599999990000363],
//             [126.82500005260235, 37.605000031873338],
//             [126.82999996391696, 37.605000041665484]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081295',
//       type: 'Feature',
//       properties: {
//         fid: 393087,
//         MAPIDCD_NO: '376081295',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500005260235, 37.605000031873338],
//             [126.82499998827571, 37.599999990000363],
//             [126.81999995929358, 37.600000039398992],
//             [126.82000002961641, 37.604999989844806],
//             [126.82500005260235, 37.605000031873338]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081294',
//       type: 'Feature',
//       properties: {
//         fid: 393089,
//         MAPIDCD_NO: '376081294',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000002961641, 37.604999989844806],
//             [126.81999995929358, 37.600000039398992],
//             [126.81500004378606, 37.599999965926351],
//             [126.81500000516901, 37.605000006338237],
//             [126.82000002961641, 37.604999989844806]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081293',
//       type: 'Feature',
//       properties: {
//         fid: 393091,
//         MAPIDCD_NO: '376081293',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500000516901, 37.605000006338237],
//             [126.81500004378606, 37.599999965926351],
//             [126.81000001723233, 37.600000042289395],
//             [126.80999998235654, 37.604999991237449],
//             [126.81500000516901, 37.605000006338237]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081292',
//       type: 'Feature',
//       properties: {
//         fid: 393093,
//         MAPIDCD_NO: '376081292',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999998235654, 37.604999991237449],
//             [126.81000001723233, 37.600000042289395],
//             [126.80499998864221, 37.599999995109584],
//             [126.80499996035647, 37.605000034670645],
//             [126.80999998235654, 37.604999991237449]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081291',
//       type: 'Feature',
//       properties: {
//         fid: 393095,
//         MAPIDCD_NO: '376081291',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499996035647, 37.605000034670645],
//             [126.80499998864221, 37.599999995109584],
//             [126.79999996085877, 37.600000008275146],
//             [126.80000005187574, 37.604999957039105],
//             [126.80499996035647, 37.605000034670645]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081481',
//       type: 'Feature',
//       properties: {
//         fid: 393604,
//         MAPIDCD_NO: '376081481',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499999730638, 37.610000042035594],
//             [126.90500001576598, 37.605000001088975],
//             [126.89999998811098, 37.605000015723334],
//             [126.8999999755255, 37.609999965392234],
//             [126.90499999730638, 37.610000042035594]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081390',
//       type: 'Feature',
//       properties: {
//         fid: 393606,
//         MAPIDCD_NO: '376081390',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8999999755255, 37.609999965392234],
//             [126.89999998811098, 37.605000015723334],
//             [126.8949999596371, 37.604999996956508],
//             [126.89499995146227, 37.610000037400994],
//             [126.8999999755255, 37.609999965392234]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081389',
//       type: 'Feature',
//       properties: {
//         fid: 393609,
//         MAPIDCD_NO: '376081389',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499995146227, 37.610000037400994],
//             [126.8949999596371, 37.604999996956508],
//             [126.89000004516311, 37.605000037317765],
//             [126.89000004286585, 37.609999988268576],
//             [126.89499995146227, 37.610000037400994]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081388',
//       type: 'Feature',
//       properties: {
//         fid: 393611,
//         MAPIDCD_NO: '376081388',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000004286585, 37.609999988268576],
//             [126.89000004516311, 37.605000037317765],
//             [126.88500001731597, 37.604999957171387],
//             [126.88500002169639, 37.60999999708811],
//             [126.89000004286585, 37.609999988268576]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081387',
//       type: 'Feature',
//       properties: {
//         fid: 393614,
//         MAPIDCD_NO: '376081387',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500002169639, 37.60999999708811],
//             [126.88500001731597, 37.604999957171387],
//             [126.87999999173411, 37.605000023905738],
//             [126.87999999972919, 37.609999974298269],
//             [126.88500002169639, 37.60999999708811]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081386',
//       type: 'Feature',
//       properties: {
//         fid: 393616,
//         MAPIDCD_NO: '376081386',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999999972919, 37.609999974298269],
//             [126.87999999173411, 37.605000023905738],
//             [126.87499996383282, 37.604999970700462],
//             [126.87499997849204, 37.61000001004016],
//             [126.87999999972919, 37.609999974298269]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081385',
//       type: 'Feature',
//       properties: {
//         fid: 393618,
//         MAPIDCD_NO: '376081385',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499997849204, 37.61000001004016],
//             [126.87499996383282, 37.604999970700462],
//             [126.87000005224013, 37.60499997485006],
//             [126.86999995647744, 37.610000014171632],
//             [126.87499997849204, 37.61000001004016]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081384',
//       type: 'Feature',
//       properties: {
//         fid: 393620,
//         MAPIDCD_NO: '376081384',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999995647744, 37.610000014171632],
//             [126.87000005224013, 37.60499997485006],
//             [126.86500002583394, 37.605000036927756],
//             [126.86500004927704, 37.60999998551133],
//             [126.86999995647744, 37.610000014171632]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081383',
//       type: 'Feature',
//       properties: {
//         fid: 393623,
//         MAPIDCD_NO: '376081383',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500004927704, 37.60999998551133],
//             [126.86500002583394, 37.605000036927756],
//             [126.85999999942821, 37.604999979076474],
//             [126.86000002724948, 37.610000016566786],
//             [126.86500004927704, 37.60999998551133]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081382',
//       type: 'Feature',
//       properties: {
//         fid: 393625,
//         MAPIDCD_NO: '376081382',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000002724948, 37.610000016566786],
//             [126.85999999942821, 37.604999979076474],
//             [126.85499997379722, 37.604999977961789],
//             [126.85500000674676, 37.610000016022568],
//             [126.86000002724948, 37.610000016566786]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081381',
//       type: 'Feature',
//       properties: {
//         fid: 393627,
//         MAPIDCD_NO: '376081381',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500000674676, 37.610000016022568],
//             [126.85499997379722, 37.604999977961789],
//             [126.84999994892372, 37.605000035385707],
//             [126.84999998778501, 37.609999983878375],
//             [126.85500000674676, 37.610000016022568]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081290',
//       type: 'Feature',
//       properties: {
//         fid: 393629,
//         MAPIDCD_NO: '376081290',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999998778501, 37.609999983878375],
//             [126.84999994892372, 37.605000035385707],
//             [126.84500003740696, 37.60499997170561],
//             [126.84499996733993, 37.610000008447869],
//             [126.84999998778501, 37.609999983878375]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081289',
//       type: 'Feature',
//       properties: {
//         fid: 393631,
//         MAPIDCD_NO: '376081289',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499996733993, 37.610000008447869],
//             [126.84500003740696, 37.60499997170561],
//             [126.84000001111578, 37.6049999659266],
//             [126.83999994617447, 37.610000003206387],
//             [126.84499996733993, 37.610000008447869]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081288',
//       type: 'Feature',
//       properties: {
//         fid: 393634,
//         MAPIDCD_NO: '376081288',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999994617447, 37.610000003206387],
//             [126.84000001111578, 37.6049999659266],
//             [126.83499998786616, 37.605000018697311],
//             [126.83500004214591, 37.609999967014744],
//             [126.83999994617447, 37.610000003206387]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081287',
//       type: 'Feature',
//       properties: {
//         fid: 393636,
//         MAPIDCD_NO: '376081287',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500004214591, 37.609999967014744],
//             [126.83499998786616, 37.605000018697311],
//             [126.82999996391696, 37.605000041665484],
//             [126.83000002109309, 37.609999986888539],
//             [126.83500004214591, 37.609999967014744]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081286',
//       type: 'Feature',
//       properties: {
//         fid: 393639,
//         MAPIDCD_NO: '376081286',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000002109309, 37.609999986888539],
//             [126.82999996391696, 37.605000041665484],
//             [126.82500005260235, 37.605000031873338],
//             [126.82500000388768, 37.609999976975573],
//             [126.83000002109309, 37.609999986888539]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081285',
//       type: 'Feature',
//       properties: {
//         fid: 393642,
//         MAPIDCD_NO: '376081285',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500000388768, 37.609999976975573],
//             [126.82500005260235, 37.605000031873338],
//             [126.82000002961641, 37.604999989844806],
//             [126.81999998520961, 37.610000025575786],
//             [126.82500000388768, 37.609999976975573]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081284',
//       type: 'Feature',
//       properties: {
//         fid: 393644,
//         MAPIDCD_NO: '376081284',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999998520961, 37.610000025575786],
//             [126.82000002961641, 37.604999989844806],
//             [126.81500000516901, 37.605000006338237],
//             [126.8149999659001, 37.610000040757235],
//             [126.81999998520961, 37.610000025575786]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081283',
//       type: 'Feature',
//       properties: {
//         fid: 393646,
//         MAPIDCD_NO: '376081283',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8149999659001, 37.610000040757235],
//             [126.81500000516901, 37.605000006338237],
//             [126.80999998235654, 37.604999991237449],
//             [126.80999994820823, 37.610000026137712],
//             [126.8149999659001, 37.610000040757235]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081282',
//       type: 'Feature',
//       properties: {
//         fid: 393648,
//         MAPIDCD_NO: '376081282',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999994820823, 37.610000026137712],
//             [126.80999998235654, 37.604999991237449],
//             [126.80499996035647, 37.605000034670645],
//             [126.80500004547633, 37.609999978779051],
//             [126.80999994820823, 37.610000026137712]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081281',
//       type: 'Feature',
//       properties: {
//         fid: 393650,
//         MAPIDCD_NO: '376081281',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500004547633, 37.609999978779051],
//             [126.80499996035647, 37.605000034670645],
//             [126.80000005187574, 37.604999957039105],
//             [126.80000002799048, 37.60999999107122],
//             [126.80500004547633, 37.609999978779051]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081190',
//       type: 'Feature',
//       properties: {
//         fid: 393653,
//         MAPIDCD_NO: '376081190',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000002799048, 37.60999999107122],
//             [126.80000005187574, 37.604999957039105],
//             [126.79500003009171, 37.605000027401566],
//             [126.79500001219211, 37.609999969956014],
//             [126.80000002799048, 37.60999999107122]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081189',
//       type: 'Feature',
//       properties: {
//         fid: 393656,
//         MAPIDCD_NO: '376081189',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500001219211, 37.609999969956014],
//             [126.79500003009171, 37.605000027401566],
//             [126.79000000859121, 37.604999974223816],
//             [126.78999999494081, 37.6100000091527],
//             [126.79500001219211, 37.609999969956014]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081471',
//       type: 'Feature',
//       properties: {
//         fid: 394159,
//         MAPIDCD_NO: '376081471',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9049999795822, 37.61499998865083],
//             [126.90499999730638, 37.610000042035594],
//             [126.8999999755255, 37.609999965392234],
//             [126.89999996219599, 37.615000000990435],
//             [126.9049999795822, 37.61499998865083]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081380',
//       type: 'Feature',
//       properties: {
//         fid: 394161,
//         MAPIDCD_NO: '376081380',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999996219599, 37.615000000990435],
//             [126.8999999755255, 37.609999965392234],
//             [126.89499995146227, 37.610000037400994],
//             [126.89499994396037, 37.614999983515411],
//             [126.89999996219599, 37.615000000990435]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081379',
//       type: 'Feature',
//       properties: {
//         fid: 394164,
//         MAPIDCD_NO: '376081379',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499994396037, 37.614999983515411],
//             [126.89499995146227, 37.610000037400994],
//             [126.89000004286585, 37.609999988268576],
//             [126.89000003975281, 37.615000023347108],
//             [126.89499994396037, 37.614999983515411]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081378',
//       type: 'Feature',
//       properties: {
//         fid: 394166,
//         MAPIDCD_NO: '376081378',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000003975281, 37.615000023347108],
//             [126.89000004286585, 37.609999988268576],
//             [126.88500002169639, 37.60999999708811],
//             [126.8850000236793, 37.615000032794015],
//             [126.89000003975281, 37.615000023347108]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081377',
//       type: 'Feature',
//       properties: {
//         fid: 394169,
//         MAPIDCD_NO: '376081377',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8850000236793, 37.615000032794015],
//             [126.88500002169639, 37.60999999708811],
//             [126.87999999972919, 37.609999974298269],
//             [126.88000000682219, 37.615000008819592],
//             [126.8850000236793, 37.615000032794015]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081376',
//       type: 'Feature',
//       properties: {
//         fid: 394171,
//         MAPIDCD_NO: '376081376',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000000682219, 37.615000008819592],
//             [126.87999999972919, 37.609999974298269],
//             [126.87499997849204, 37.61000001004016],
//             [126.87499999067934, 37.615000045170056],
//             [126.88000000682219, 37.615000008819592]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081375',
//       type: 'Feature',
//       properties: {
//         fid: 394173,
//         MAPIDCD_NO: '376081375',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499999067934, 37.615000045170056],
//             [126.87499997849204, 37.61000001004016],
//             [126.86999995647744, 37.610000014171632],
//             [126.86999997453376, 37.614999957968685],
//             [126.87499999067934, 37.615000045170056]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081374',
//       type: 'Feature',
//       properties: {
//         fid: 394175,
//         MAPIDCD_NO: '376081374',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999997453376, 37.614999957968685],
//             [126.86999995647744, 37.610000014171632],
//             [126.86500004927704, 37.60999998551133],
//             [126.86499995837137, 37.61500001941809],
//             [126.86999997453376, 37.614999957968685]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081373',
//       type: 'Feature',
//       properties: {
//         fid: 394178,
//         MAPIDCD_NO: '376081373',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499995837137, 37.61500001941809],
//             [126.86500004927704, 37.60999998551133],
//             [126.86000002724948, 37.610000016566786],
//             [126.86000005550989, 37.61499996153443],
//             [126.86499995837137, 37.61500001941809]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081372',
//       type: 'Feature',
//       properties: {
//         fid: 394180,
//         MAPIDCD_NO: '376081372',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000005550989, 37.61499996153443],
//             [126.86000002724948, 37.610000016566786],
//             [126.85500000674676, 37.610000016022568],
//             [126.85500004011969, 37.614999959758812],
//             [126.86000005550989, 37.61499996153443]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081371',
//       type: 'Feature',
//       properties: {
//         fid: 394182,
//         MAPIDCD_NO: '376081371',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500004011969, 37.614999959758812],
//             [126.85500000674676, 37.610000016022568],
//             [126.84999998778501, 37.609999983878375],
//             [126.85000002321935, 37.615000016490477],
//             [126.85500004011969, 37.614999959758812]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081280',
//       type: 'Feature',
//       properties: {
//         fid: 394184,
//         MAPIDCD_NO: '376081280',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000002321935, 37.615000016490477],
//             [126.84999998778501, 37.609999983878375],
//             [126.84499996733993, 37.610000008447869],
//             [126.84500001011372, 37.615000043427344],
//             [126.85000002321935, 37.615000016490477]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081279',
//       type: 'Feature',
//       properties: {
//         fid: 394186,
//         MAPIDCD_NO: '376081279',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500001011372, 37.615000043427344],
//             [126.84499996733993, 37.610000008447869],
//             [126.83999994617447, 37.610000003206387],
//             [126.83999999405243, 37.615000036926233],
//             [126.84500001011372, 37.615000043427344]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081278',
//       type: 'Feature',
//       properties: {
//         fid: 394189,
//         MAPIDCD_NO: '376081278',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999999405243, 37.615000036926233],
//             [126.83999994617447, 37.610000003206387],
//             [126.83500004214591, 37.609999967014744],
//             [126.83499997956801, 37.614999998814412],
//             [126.83999999405243, 37.615000036926233]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081277',
//       type: 'Feature',
//       properties: {
//         fid: 394191,
//         MAPIDCD_NO: '376081277',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499997956801, 37.614999998814412],
//             [126.83500004214591, 37.609999967014744],
//             [126.83000002109309, 37.609999986888539],
//             [126.82999996586787, 37.615000019220354],
//             [126.83499997956801, 37.614999998814412]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081276',
//       type: 'Feature',
//       properties: {
//         fid: 394194,
//         MAPIDCD_NO: '376081276',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999996586787, 37.615000019220354],
//             [126.83000002109309, 37.609999986888539],
//             [126.82500000388768, 37.609999976975573],
//             [126.82499995148253, 37.615000009804461],
//             [126.82999996586787, 37.615000019220354]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081275',
//       type: 'Feature',
//       properties: {
//         fid: 394197,
//         MAPIDCD_NO: '376081275',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499995148253, 37.615000009804461],
//             [126.82500000388768, 37.609999976975573],
//             [126.81999998520961, 37.610000025575786],
//             [126.82000005204385, 37.614999965824381],
//             [126.82499995148253, 37.615000009804461]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081274',
//       type: 'Feature',
//       properties: {
//         fid: 394199,
//         MAPIDCD_NO: '376081274',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000005204385, 37.614999965824381],
//             [126.81999998520961, 37.610000025575786],
//             [126.8149999659001, 37.610000040757235],
//             [126.81500004007397, 37.61499998331761],
//             [126.82000005204385, 37.614999965824381]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081273',
//       type: 'Feature',
//       properties: {
//         fid: 394201,
//         MAPIDCD_NO: '376081273',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500004007397, 37.61499998331761],
//             [126.8149999659001, 37.610000040757235],
//             [126.80999994820823, 37.610000026137712],
//             [126.81000002748796, 37.614999967382317],
//             [126.81500004007397, 37.61499998331761]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081272',
//       type: 'Feature',
//       properties: {
//         fid: 394203,
//         MAPIDCD_NO: '376081272',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000002748796, 37.614999967382317],
//             [126.80999994820823, 37.610000026137712],
//             [126.80500004547633, 37.609999978779051],
//             [126.80500001344674, 37.615000009948751],
//             [126.81000002748796, 37.614999967382317]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081271',
//       type: 'Feature',
//       properties: {
//         fid: 394205,
//         MAPIDCD_NO: '376081271',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500001344674, 37.615000009948751],
//             [126.80500004547633, 37.609999978779051],
//             [126.80000002799048, 37.60999999107122],
//             [126.80000000332528, 37.615000020914785],
//             [126.80500001344674, 37.615000009948751]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081180',
//       type: 'Feature',
//       properties: {
//         fid: 394208,
//         MAPIDCD_NO: '376081180',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000000332528, 37.615000020914785],
//             [126.80000002799048, 37.60999999107122],
//             [126.79500001219211, 37.609999969956014],
//             [126.79499999034168, 37.615000000239817],
//             [126.80000000332528, 37.615000020914785]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081179',
//       type: 'Feature',
//       properties: {
//         fid: 394211,
//         MAPIDCD_NO: '376081179',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499999034168, 37.615000000239817],
//             [126.79500001219211, 37.609999969956014],
//             [126.78999999494081, 37.6100000091527],
//             [126.78999998045313, 37.6150000380918],
//             [126.79499999034168, 37.615000000239817]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081178',
//       type: 'Feature',
//       properties: {
//         fid: 394213,
//         MAPIDCD_NO: '376081178',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999998045313, 37.6150000380918],
//             [126.78999999494081, 37.6100000091527],
//             [126.78499997939748, 37.610000014941079],
//             [126.78499996998875, 37.61500004431516],
//             [126.78999998045313, 37.6150000380918]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081462',
//       type: 'Feature',
//       properties: {
//         fid: 394712,
//         MAPIDCD_NO: '376081462',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999997491122, 37.6199999747815],
//             [126.90999999768559, 37.615000033033283],
//             [126.9049999795822, 37.61499998865083],
//             [126.90499996117181, 37.620000019391973],
//             [126.90999997491122, 37.6199999747815]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081461',
//       type: 'Feature',
//       properties: {
//         fid: 394714,
//         MAPIDCD_NO: '376081461',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499996117181, 37.620000019391973],
//             [126.9049999795822, 37.61499998865083],
//             [126.89999996219599, 37.615000000990435],
//             [126.89999994884678, 37.620000032387679],
//             [126.90499996117181, 37.620000019391973]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081370',
//       type: 'Feature',
//       properties: {
//         fid: 394716,
//         MAPIDCD_NO: '376081370',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999994884678, 37.620000032387679],
//             [126.89999996219599, 37.615000000990435],
//             [126.89499994396037, 37.614999983515411],
//             [126.89500004899617, 37.620000014337734],
//             [126.89999994884678, 37.620000032387679]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081369',
//       type: 'Feature',
//       properties: {
//         fid: 394719,
//         MAPIDCD_NO: '376081369',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89500004899617, 37.620000014337734],
//             [126.89499994396037, 37.614999983515411],
//             [126.89000003975281, 37.615000023347108],
//             [126.89000003728195, 37.619999964096152],
//             [126.89500004899617, 37.620000014337734]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081368',
//       type: 'Feature',
//       properties: {
//         fid: 394721,
//         MAPIDCD_NO: '376081368',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000003728195, 37.619999964096152],
//             [126.89000003975281, 37.615000023347108],
//             [126.8850000236793, 37.615000032794015],
//             [126.88500002402191, 37.619999972356659],
//             [126.89000003728195, 37.619999964096152]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081367',
//       type: 'Feature',
//       properties: {
//         fid: 394724,
//         MAPIDCD_NO: '376081367',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500002402191, 37.619999972356659],
//             [126.8850000236793, 37.615000032794015],
//             [126.88000000682219, 37.615000008819592],
//             [126.88000001374643, 37.620000039142283],
//             [126.88500002402191, 37.619999972356659]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081366',
//       type: 'Feature',
//       properties: {
//         fid: 394726,
//         MAPIDCD_NO: '376081366',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000001374643, 37.620000039142283],
//             [126.88000000682219, 37.615000008819592],
//             [126.87499999067934, 37.615000045170056],
//             [126.87500000116413, 37.6199999841579],
//             [126.88000001374643, 37.620000039142283]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081365',
//       type: 'Feature',
//       properties: {
//         fid: 394728,
//         MAPIDCD_NO: '376081365',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500000116413, 37.6199999841579],
//             [126.87499999067934, 37.615000045170056],
//             [126.86999997453376, 37.614999957968685],
//             [126.86999998932041, 37.61999998768583],
//             [126.87500000116413, 37.6199999841579]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081364',
//       type: 'Feature',
//       properties: {
//         fid: 394730,
//         MAPIDCD_NO: '376081364',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999998932041, 37.61999998768583],
//             [126.86999997453376, 37.614999957968685],
//             [126.86499995837137, 37.61500001941809],
//             [126.86499997898, 37.619999959596235],
//             [126.86999998932041, 37.61999998768583]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081363',
//       type: 'Feature',
//       properties: {
//         fid: 394733,
//         MAPIDCD_NO: '376081363',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499997898, 37.619999959596235],
//             [126.86499995837137, 37.61500001941809],
//             [126.86000005550989, 37.61499996153443],
//             [126.85999996712019, 37.619999990005809],
//             [126.86499997898, 37.619999959596235]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081362',
//       type: 'Feature',
//       properties: {
//         fid: 394735,
//         MAPIDCD_NO: '376081362',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999996712019, 37.619999990005809],
//             [126.86000005550989, 37.61499996153443],
//             [126.85500004011969, 37.614999959758812],
//             [126.85499995678387, 37.619999988796991],
//             [126.85999996712019, 37.619999990005809]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081361',
//       type: 'Feature',
//       properties: {
//         fid: 394737,
//         MAPIDCD_NO: '376081361',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499995678387, 37.619999988796991],
//             [126.85500004011969, 37.614999959758812],
//             [126.85000002321935, 37.615000016490477],
//             [126.84999994798724, 37.6199999559695],
//             [126.85499995678387, 37.619999988796991]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081270',
//       type: 'Feature',
//       properties: {
//         fid: 394739,
//         MAPIDCD_NO: '376081270',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999994798724, 37.6199999559695],
//             [126.85000002321935, 37.615000016490477],
//             [126.84500001011372, 37.615000043427344],
//             [126.84500005099854, 37.619999982268418],
//             [126.84999994798724, 37.6199999559695]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081269',
//       type: 'Feature',
//       properties: {
//         fid: 394741,
//         MAPIDCD_NO: '376081269',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500005099854, 37.619999982268418],
//             [126.84500001011372, 37.615000043427344],
//             [126.83999999405243, 37.615000036926233],
//             [126.84000004001034, 37.6199999745083],
//             [126.84500005099854, 37.619999982268418]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081268',
//       type: 'Feature',
//       properties: {
//         fid: 394744,
//         MAPIDCD_NO: '376081268',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000004001034, 37.6199999745083],
//             [126.83999999405243, 37.615000036926233],
//             [126.83499997956801, 37.614999998814412],
//             [126.83500003204571, 37.620000027072244],
//             [126.84000004001034, 37.6199999745083]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081267',
//       type: 'Feature',
//       properties: {
//         fid: 394746,
//         MAPIDCD_NO: '376081267',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500003204571, 37.620000027072244],
//             [126.83499997956801, 37.614999998814412],
//             [126.82999996586787, 37.615000019220354],
//             [126.83000002193776, 37.619999957861467],
//             [126.83500003204571, 37.620000027072244]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081266',
//       type: 'Feature',
//       properties: {
//         fid: 394749,
//         MAPIDCD_NO: '376081266',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000002193776, 37.619999957861467],
//             [126.82999996586787, 37.615000019220354],
//             [126.82499995148253, 37.615000009804461],
//             [126.82500001407456, 37.62000003729996],
//             [126.83000002193776, 37.619999957861467]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081265',
//       type: 'Feature',
//       properties: {
//         fid: 394751,
//         MAPIDCD_NO: '376081265',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500001407456, 37.62000003729996],
//             [126.82499995148253, 37.615000009804461],
//             [126.82000005204385, 37.614999965824381],
//             [126.82000000411674, 37.619999993160043],
//             [126.82500001407456, 37.62000003729996]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081264',
//       type: 'Feature',
//       properties: {
//         fid: 394754,
//         MAPIDCD_NO: '376081264',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000000411674, 37.619999993160043],
//             [126.82000005204385, 37.614999965824381],
//             [126.81500004007397, 37.61499998331761],
//             [126.81499999721039, 37.620000009342469],
//             [126.82000000411674, 37.619999993160043]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081263',
//       type: 'Feature',
//       properties: {
//         fid: 394756,
//         MAPIDCD_NO: '376081263',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499999721039, 37.620000009342469],
//             [126.81500004007397, 37.61499998331761],
//             [126.81000002748796, 37.614999967382317],
//             [126.80999998967057, 37.619999993889607],
//             [126.81499999721039, 37.620000009342469]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081262',
//       type: 'Feature',
//       properties: {
//         fid: 394758,
//         MAPIDCD_NO: '376081262',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999998967057, 37.619999993889607],
//             [126.81000002748796, 37.614999967382317],
//             [126.80500001344674, 37.615000009948751],
//             [126.80499998295734, 37.62000003513986],
//             [126.80999998967057, 37.619999993889607]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081261',
//       type: 'Feature',
//       properties: {
//         fid: 394760,
//         MAPIDCD_NO: '376081261',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499998295734, 37.62000003513986],
//             [126.80500001344674, 37.615000009948751],
//             [126.80000000332528, 37.615000020914785],
//             [126.79999997645926, 37.619999956428138],
//             [126.80499998295734, 37.62000003513986]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081170',
//       type: 'Feature',
//       properties: {
//         fid: 394763,
//         MAPIDCD_NO: '376081170',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999997645926, 37.619999956428138],
//             [126.80000000332528, 37.615000020914785],
//             [126.79499999034168, 37.615000000239817],
//             [126.79499996767399, 37.620000026335546],
//             [126.79999997645926, 37.619999956428138]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081169',
//       type: 'Feature',
//       properties: {
//         fid: 394766,
//         MAPIDCD_NO: '376081169',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499996767399, 37.620000026335546],
//             [126.79499999034168, 37.615000000239817],
//             [126.78999998045313, 37.6150000380918],
//             [126.78999996370226, 37.61999997270199],
//             [126.79499996767399, 37.620000026335546]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081168',
//       type: 'Feature',
//       properties: {
//         fid: 394768,
//         MAPIDCD_NO: '376081168',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999996370226, 37.61999997270199],
//             [126.78999998045313, 37.6150000380918],
//             [126.78499996998875, 37.61500004431516],
//             [126.78499995601942, 37.619999979347227],
//             [126.78999996370226, 37.61999997270199]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081167',
//       type: 'Feature',
//       properties: {
//         fid: 394770,
//         MAPIDCD_NO: '376081167',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78499995601942, 37.619999979347227],
//             [126.78499996998875, 37.61500004431516],
//             [126.77999995896467, 37.615000018909228],
//             [126.77999995145635, 37.620000042706465],
//             [126.78499995601942, 37.619999979347227]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081166',
//       type: 'Feature',
//       properties: {
//         fid: 394772,
//         MAPIDCD_NO: '376081166',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77999995145635, 37.620000042706465],
//             [126.77999995896467, 37.615000018909228],
//             [126.774999949663, 37.614999961887406],
//             [126.77499994719467, 37.619999986101483],
//             [126.77999995145635, 37.620000042706465]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081452',
//       type: 'Feature',
//       properties: {
//         fid: 395262,
//         MAPIDCD_NO: '376081452',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999994921339, 37.625000002446185],
//             [126.90999997491122, 37.6199999747815],
//             [126.90499996117181, 37.620000019391973],
//             [126.90500005453488, 37.624999958164111],
//             [126.90999994921339, 37.625000002446185]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081451',
//       type: 'Feature',
//       properties: {
//         fid: 395264,
//         MAPIDCD_NO: '376081451',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500005453488, 37.624999958164111],
//             [126.90499996117181, 37.620000019391973],
//             [126.89999994884678, 37.620000032387679],
//             [126.90000004725403, 37.624999970018671],
//             [126.90500005453488, 37.624999958164111]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081360',
//       type: 'Feature',
//       properties: {
//         fid: 395266,
//         MAPIDCD_NO: '376081360',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000004725403, 37.624999970018671],
//             [126.89999994884678, 37.620000032387679],
//             [126.89500004899617, 37.620000014337734],
//             [126.89500004065806, 37.625000042181206],
//             [126.90000004725403, 37.624999970018671]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081359',
//       type: 'Feature',
//       properties: {
//         fid: 395269,
//         MAPIDCD_NO: '376081359',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89500004065806, 37.625000042181206],
//             [126.89500004899617, 37.620000014337734],
//             [126.89000003728195, 37.619999964096152],
//             [126.8900000316998, 37.624999992565861],
//             [126.89500004065806, 37.625000042181206]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081358',
//       type: 'Feature',
//       properties: {
//         fid: 395271,
//         MAPIDCD_NO: '376081358',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8900000316998, 37.624999992565861],
//             [126.89000003728195, 37.619999964096152],
//             [126.88500002402191, 37.619999972356659],
//             [126.88500002574216, 37.624999999664055],
//             [126.8900000316998, 37.624999992565861]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081357',
//       type: 'Feature',
//       properties: {
//         fid: 395274,
//         MAPIDCD_NO: '376081357',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500002574216, 37.624999999664055],
//             [126.88500002402191, 37.619999972356659],
//             [126.88000001374643, 37.620000039142283],
//             [126.88000001896964, 37.624999976927455],
//             [126.88500002574216, 37.624999999664055]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081356',
//       type: 'Feature',
//       properties: {
//         fid: 395276,
//         MAPIDCD_NO: '376081356',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000001896964, 37.624999976927455],
//             [126.88000001374643, 37.620000039142283],
//             [126.87500000116413, 37.6199999841579],
//             [126.87500001293979, 37.625000010891668],
//             [126.88000001896964, 37.624999976927455]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081355',
//       type: 'Feature',
//       properties: {
//         fid: 395278,
//         MAPIDCD_NO: '376081355',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500001293979, 37.625000010891668],
//             [126.87500000116413, 37.6199999841579],
//             [126.86999998932041, 37.61999998768583],
//             [126.87000000611498, 37.625000015020028],
//             [126.87500001293979, 37.625000010891668]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081354',
//       type: 'Feature',
//       properties: {
//         fid: 395280,
//         MAPIDCD_NO: '376081354',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000000611498, 37.625000015020028],
//             [126.86999998932041, 37.61999998768583],
//             [126.86499997898, 37.619999959596235],
//             [126.86499999854172, 37.624999985706744],
//             [126.87000000611498, 37.625000015020028]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081353',
//       type: 'Feature',
//       properties: {
//         fid: 395283,
//         MAPIDCD_NO: '376081353',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499999854172, 37.624999985706744],
//             [126.86499997898, 37.619999959596235],
//             [126.85999996712019, 37.619999990005809],
//             [126.85999999396545, 37.625000016710196],
//             [126.86499999854172, 37.624999985706744]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081352',
//       type: 'Feature',
//       properties: {
//         fid: 395285,
//         MAPIDCD_NO: '376081352',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999999396545, 37.625000016710196],
//             [126.85999996712019, 37.619999990005809],
//             [126.85499995678387, 37.619999988796991],
//             [126.85499998866114, 37.6250000142711],
//             [126.85999999396545, 37.625000016710196]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081351',
//       type: 'Feature',
//       properties: {
//         fid: 395287,
//         MAPIDCD_NO: '376081351',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499998866114, 37.6250000142711],
//             [126.85499995678387, 37.619999988796991],
//             [126.84999994798724, 37.6199999559695],
//             [126.8499999826139, 37.624999981994108],
//             [126.85499998866114, 37.6250000142711]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081260',
//       type: 'Feature',
//       properties: {
//         fid: 395289,
//         MAPIDCD_NO: '376081260',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8499999826139, 37.624999981994108],
//             [126.84999994798724, 37.6199999559695],
//             [126.84500005099854, 37.619999982268418],
//             [126.84499997734669, 37.625000006414936],
//             [126.8499999826139, 37.624999981994108]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081259',
//       type: 'Feature',
//       properties: {
//         fid: 395291,
//         MAPIDCD_NO: '376081259',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499997734669, 37.625000006414936],
//             [126.84500005099854, 37.619999982268418],
//             [126.84000004001034, 37.6199999745083],
//             [126.83999997137244, 37.624999999194124],
//             [126.84499997734669, 37.625000006414936]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081258',
//       type: 'Feature',
//       properties: {
//         fid: 395293,
//         MAPIDCD_NO: '376081258',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999997137244, 37.624999999194124],
//             [126.84000004001034, 37.6199999745083],
//             [126.83500003204571, 37.620000027072244],
//             [126.83499996922402, 37.624999962159293],
//             [126.83999997137244, 37.624999999194124]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081257',
//       type: 'Feature',
//       properties: {
//         fid: 395296,
//         MAPIDCD_NO: '376081257',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499996922402, 37.624999962159293],
//             [126.83500003204571, 37.620000027072244],
//             [126.83000002193776, 37.619999957861467],
//             [126.82999996334148, 37.624999981795227],
//             [126.83499996922402, 37.624999962159293]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081256',
//       type: 'Feature',
//       properties: {
//         fid: 395299,
//         MAPIDCD_NO: '376081256',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999996334148, 37.624999981795227],
//             [126.83000002193776, 37.619999957861467],
//             [126.82500001407456, 37.62000003729996],
//             [126.82499995905468, 37.624999969800825],
//             [126.82999996334148, 37.624999981795227]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081255',
//       type: 'Feature',
//       properties: {
//         fid: 395301,
//         MAPIDCD_NO: '376081255',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499995905468, 37.624999969800825],
//             [126.82500001407456, 37.62000003729996],
//             [126.82000000411674, 37.619999993160043],
//             [126.81999995555874, 37.625000016304213],
//             [126.82499995905468, 37.624999969800825]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081254',
//       type: 'Feature',
//       properties: {
//         fid: 395304,
//         MAPIDCD_NO: '376081254',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999995555874, 37.625000016304213],
//             [126.82000000411674, 37.619999993160043],
//             [126.81499999721039, 37.620000009342469],
//             [126.81499995366217, 37.625000032979074],
//             [126.81999995555874, 37.625000016304213]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081253',
//       type: 'Feature',
//       properties: {
//         fid: 395306,
//         MAPIDCD_NO: '376081253',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499995366217, 37.625000032979074],
//             [126.81499999721039, 37.620000009342469],
//             [126.80999998967057, 37.619999993889607],
//             [126.80999995114784, 37.625000016206712],
//             [126.81499995366217, 37.625000032979074]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081252',
//       type: 'Feature',
//       properties: {
//         fid: 395308,
//         MAPIDCD_NO: '376081252',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999995114784, 37.625000016206712],
//             [126.80999998967057, 37.619999993889607],
//             [126.80499998295734, 37.62000003513986],
//             [126.80499994801544, 37.624999967789165],
//             [126.80999995114784, 37.625000016206712]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081251',
//       type: 'Feature',
//       properties: {
//         fid: 395310,
//         MAPIDCD_NO: '376081251',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499994801544, 37.624999967789165],
//             [126.80499998295734, 37.62000003513986],
//             [126.79999997645926, 37.619999956428138],
//             [126.79999994570221, 37.624999977867269],
//             [126.80499994801544, 37.624999967789165]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081160',
//       type: 'Feature',
//       properties: {
//         fid: 395313,
//         MAPIDCD_NO: '376081160',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999994570221, 37.624999977867269],
//             [126.79999997645926, 37.619999956428138],
//             [126.79499996767399, 37.620000026335546],
//             [126.79499994505747, 37.624999956312564],
//             [126.79999994570221, 37.624999977867269]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081159',
//       type: 'Feature',
//       properties: {
//         fid: 395316,
//         MAPIDCD_NO: '376081159',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499994505747, 37.624999956312564],
//             [126.79499996767399, 37.620000026335546],
//             [126.78999996370226, 37.61999997270199],
//             [126.7900000562891, 37.624999993920746],
//             [126.79499994505747, 37.624999956312564]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081158',
//       type: 'Feature',
//       properties: {
//         fid: 395318,
//         MAPIDCD_NO: '376081158',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7900000562891, 37.624999993920746],
//             [126.78999996370226, 37.61999997270199],
//             [126.78499995601942, 37.619999979347227],
//             [126.78500005589397, 37.624999999218069],
//             [126.7900000562891, 37.624999993920746]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081157',
//       type: 'Feature',
//       properties: {
//         fid: 395320,
//         MAPIDCD_NO: '376081157',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500005589397, 37.624999999218069],
//             [126.78499995601942, 37.619999979347227],
//             [126.77999995145635, 37.620000042706465],
//             [126.78000005493742, 37.62499997286745],
//             [126.78500005589397, 37.624999999218069]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081156',
//       type: 'Feature',
//       properties: {
//         fid: 395322,
//         MAPIDCD_NO: '376081156',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000005493742, 37.62499997286745],
//             [126.77999995145635, 37.620000042706465],
//             [126.77499994719467, 37.619999986101483],
//             [126.77500005482645, 37.625000005009738],
//             [126.78000005493742, 37.62499997286745]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081155',
//       type: 'Feature',
//       properties: {
//         fid: 395326,
//         MAPIDCD_NO: '376081155',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500005482645, 37.625000005009738],
//             [126.77499994719467, 37.619999986101483],
//             [126.77000005484821, 37.619999986882839],
//             [126.77000005644062, 37.6250000055169],
//             [126.77500005482645, 37.625000005009738]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081154',
//       type: 'Feature',
//       properties: {
//         fid: 395328,
//         MAPIDCD_NO: '376081154',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000005644062, 37.6250000055169],
//             [126.77000005484821, 37.619999986882839],
//             [126.76500005093647, 37.619999955341726],
//             [126.76499994421496, 37.624999973668686],
//             [126.77000005644062, 37.6250000055169]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081543',
//       type: 'Feature',
//       properties: {
//         fid: 395789,
//         MAPIDCD_NO: '376081543',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96499996704183, 37.629999966968093],
//             [126.96500004701892, 37.625000032573816],
//             [126.96000003823696, 37.624999975092216],
//             [126.95999996260964, 37.630000000386858],
//             [126.96499996704183, 37.629999966968093]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081542',
//       type: 'Feature',
//       properties: {
//         fid: 395791,
//         MAPIDCD_NO: '376081542',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95999996260964, 37.630000000386858],
//             [126.96000003823696, 37.624999975092216],
//             [126.95500002782691, 37.62499997611107],
//             [126.95499995946108, 37.630000002176395],
//             [126.95999996260964, 37.630000000386858]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081442',
//       type: 'Feature',
//       properties: {
//         fid: 395812,
//         MAPIDCD_NO: '376081442',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000003916275, 37.630000026486371],
//             [126.90999994921339, 37.625000002446185],
//             [126.90500005453488, 37.624999958164111],
//             [126.90500003616796, 37.629999980501736],
//             [126.91000003916275, 37.630000026486371]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081441',
//       type: 'Feature',
//       properties: {
//         fid: 395814,
//         MAPIDCD_NO: '376081441',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500003616796, 37.629999980501736],
//             [126.90500005453488, 37.624999958164111],
//             [126.90000004725403, 37.624999970018671],
//             [126.90000003160731, 37.629999993002023],
//             [126.90500003616796, 37.629999980501736]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081350',
//       type: 'Feature',
//       properties: {
//         fid: 395816,
//         MAPIDCD_NO: '376081350',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000003160731, 37.629999993002023],
//             [126.90000004725403, 37.624999970018671],
//             [126.89500004065806, 37.625000042181206],
//             [126.89500003072777, 37.629999975682651],
//             [126.90000003160731, 37.629999993002023]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081349',
//       type: 'Feature',
//       properties: {
//         fid: 395819,
//         MAPIDCD_NO: '376081349',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89500003072777, 37.629999975682651],
//             [126.89500004065806, 37.625000042181206],
//             [126.8900000316998, 37.624999992565861],
//             [126.89000002830495, 37.630000015044523],
//             [126.89500003072777, 37.629999975682651]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081348',
//       type: 'Feature',
//       properties: {
//         fid: 395821,
//         MAPIDCD_NO: '376081348',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000002830495, 37.630000015044523],
//             [126.8900000316998, 37.624999992565861],
//             [126.88500002574216, 37.624999999664055],
//             [126.88500002506508, 37.630000022760065],
//             [126.89000002830495, 37.630000015044523]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081347',
//       type: 'Feature',
//       properties: {
//         fid: 395824,
//         MAPIDCD_NO: '376081347',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500002506508, 37.630000022760065],
//             [126.88500002574216, 37.624999999664055],
//             [126.88000001896964, 37.624999976927455],
//             [126.88000002329086, 37.629999998840546],
//             [126.88500002506508, 37.630000022760065]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081346',
//       type: 'Feature',
//       properties: {
//         fid: 395826,
//         MAPIDCD_NO: '376081346',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000002329086, 37.629999998840546],
//             [126.88000001896964, 37.624999976927455],
//             [126.87500001293979, 37.625000010891668],
//             [126.87500002224363, 37.630000033415087],
//             [126.88000002329086, 37.629999998840546]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081345',
//       type: 'Feature',
//       properties: {
//         fid: 395828,
//         MAPIDCD_NO: '376081345',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500002224363, 37.630000033415087],
//             [126.87500001293979, 37.625000010891668],
//             [126.87000000611498, 37.625000015020028],
//             [126.8700000204157, 37.630000036341791],
//             [126.87500002224363, 37.630000033415087]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081344',
//       type: 'Feature',
//       properties: {
//         fid: 395830,
//         MAPIDCD_NO: '376081344',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8700000204157, 37.630000036341791],
//             [126.87000000611498, 37.625000015020028],
//             [126.86499999854172, 37.624999985706744],
//             [126.86500001782324, 37.6300000076201],
//             [126.8700000204157, 37.630000036341791]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081343',
//       type: 'Feature',
//       properties: {
//         fid: 395833,
//         MAPIDCD_NO: '376081343',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500001782324, 37.6300000076201],
//             [126.86499999854172, 37.624999985706744],
//             [126.85999999396545, 37.625000016710196],
//             [126.86000001824255, 37.630000037403136],
//             [126.86500001782324, 37.6300000076201]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081342',
//       type: 'Feature',
//       properties: {
//         fid: 395835,
//         MAPIDCD_NO: '376081342',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000001824255, 37.630000037403136],
//             [126.85999999396545, 37.625000016710196],
//             [126.85499998866114, 37.6250000142711],
//             [126.855000015651, 37.630000035524525],
//             [126.86000001824255, 37.630000037403136]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081341',
//       type: 'Feature',
//       properties: {
//         fid: 395837,
//         MAPIDCD_NO: '376081341',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.855000015651, 37.630000035524525],
//             [126.85499998866114, 37.6250000142711],
//             [126.8499999826139, 37.624999981994108],
//             [126.85000001686437, 37.630000002020843],
//             [126.855000015651, 37.630000035524525]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081250',
//       type: 'Feature',
//       properties: {
//         fid: 395839,
//         MAPIDCD_NO: '376081250',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000001686437, 37.630000002020843],
//             [126.8499999826139, 37.624999981994108],
//             [126.84499997734669, 37.625000006414936],
//             [126.84500001657469, 37.630000026995731],
//             [126.85000001686437, 37.630000002020843]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081249',
//       type: 'Feature',
//       properties: {
//         fid: 395841,
//         MAPIDCD_NO: '376081249',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500001657469, 37.630000026995731],
//             [126.84499997734669, 37.625000006414936],
//             [126.83999997137244, 37.624999999194124],
//             [126.84000001557709, 37.630000020319656],
//             [126.84500001657469, 37.630000026995731]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081248',
//       type: 'Feature',
//       properties: {
//         fid: 395843,
//         MAPIDCD_NO: '376081248',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000001557709, 37.630000020319656],
//             [126.83999997137244, 37.624999999194124],
//             [126.83499996922402, 37.624999962159293],
//             [126.83500001615417, 37.629999982004882],
//             [126.84000001557709, 37.630000020319656]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081247',
//       type: 'Feature',
//       properties: {
//         fid: 395846,
//         MAPIDCD_NO: '376081247',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500001615417, 37.629999982004882],
//             [126.83499996922402, 37.624999962159293],
//             [126.82999996334148, 37.624999981795227],
//             [126.83000001752919, 37.630000000376988],
//             [126.83500001615417, 37.629999982004882]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081246',
//       type: 'Feature',
//       properties: {
//         fid: 395849,
//         MAPIDCD_NO: '376081246',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000001752919, 37.630000000376988],
//             [126.82999996334148, 37.624999981795227],
//             [126.82499995905468, 37.624999969800825],
//             [126.82500001821656, 37.629999988899243],
//             [126.83000001752919, 37.630000000376988]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081245',
//       type: 'Feature',
//       properties: {
//         fid: 395851,
//         MAPIDCD_NO: '376081245',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500001821656, 37.629999988899243],
//             [126.82499995905468, 37.624999969800825],
//             [126.81999995555874, 37.625000016304213],
//             [126.82000001969379, 37.630000035909809],
//             [126.82500001821656, 37.629999988899243]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081244',
//       type: 'Feature',
//       properties: {
//         fid: 395854,
//         MAPIDCD_NO: '376081244',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000001969379, 37.630000035909809],
//             [126.81999995555874, 37.625000016304213],
//             [126.81499995366217, 37.625000032979074],
//             [126.81500002134695, 37.62999996113858],
//             [126.82000001969379, 37.630000035909809]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081243',
//       type: 'Feature',
//       properties: {
//         fid: 395856,
//         MAPIDCD_NO: '376081243',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500002134695, 37.62999996113858],
//             [126.81499995366217, 37.625000032979074],
//             [126.80999995114784, 37.625000016206712],
//             [126.81000002297691, 37.630000034982906],
//             [126.81500002134695, 37.62999996113858]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081242',
//       type: 'Feature',
//       properties: {
//         fid: 395858,
//         MAPIDCD_NO: '376081242',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000002297691, 37.630000034982906],
//             [126.80999995114784, 37.625000016206712],
//             [126.80499994801544, 37.624999967789165],
//             [126.80500002709835, 37.629999985255232],
//             [126.81000002297691, 37.630000034982906]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081241',
//       type: 'Feature',
//       properties: {
//         fid: 395860,
//         MAPIDCD_NO: '376081241',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500002709835, 37.629999985255232],
//             [126.80499994801544, 37.624999967789165],
//             [126.79999994570221, 37.624999977867269],
//             [126.80000002975486, 37.629999995803104],
//             [126.80500002709835, 37.629999985255232]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081150',
//       type: 'Feature',
//       properties: {
//         fid: 395863,
//         MAPIDCD_NO: '376081150',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000002975486, 37.629999995803104],
//             [126.79999994570221, 37.624999977867269],
//             [126.79499994505747, 37.624999956312564],
//             [126.79500003181258, 37.629999974695259],
//             [126.80000002975486, 37.629999995803104]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081149',
//       type: 'Feature',
//       properties: {
//         fid: 395866,
//         MAPIDCD_NO: '376081149',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500003181258, 37.629999974695259],
//             [126.79499994505747, 37.624999956312564],
//             [126.7900000562891, 37.624999993920746],
//             [126.79000003698023, 37.630000010283574],
//             [126.79500003181258, 37.629999974695259]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081148',
//       type: 'Feature',
//       properties: {
//         fid: 395868,
//         MAPIDCD_NO: '376081148',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000003698023, 37.630000010283574],
//             [126.7900000562891, 37.624999993920746],
//             [126.78500005589397, 37.624999999218069],
//             [126.78500004155229, 37.63000001601776],
//             [126.79000003698023, 37.630000010283574]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081147',
//       type: 'Feature',
//       properties: {
//         fid: 395870,
//         MAPIDCD_NO: '376081147',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500004155229, 37.63000001601776],
//             [126.78500005589397, 37.624999999218069],
//             [126.78000005493742, 37.62499997286745],
//             [126.78000004557929, 37.629999988292113],
//             [126.78500004155229, 37.63000001601776]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081146',
//       type: 'Feature',
//       properties: {
//         fid: 395872,
//         MAPIDCD_NO: '376081146',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000004557929, 37.629999988292113],
//             [126.78000005493742, 37.62499997286745],
//             [126.77500005482645, 37.625000005009738],
//             [126.77500005043368, 37.630000020852485],
//             [126.78000004557929, 37.629999988292113]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081145',
//       type: 'Feature',
//       properties: {
//         fid: 395876,
//         MAPIDCD_NO: '376081145',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500005043368, 37.630000020852485],
//             [126.77500005482645, 37.625000005009738],
//             [126.77000005644062, 37.6250000055169],
//             [126.77000005476346, 37.630000019951865],
//             [126.77500005043368, 37.630000020852485]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081144',
//       type: 'Feature',
//       properties: {
//         fid: 395878,
//         MAPIDCD_NO: '376081144',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000005476346, 37.630000019951865],
//             [126.77000005644062, 37.6250000055169],
//             [126.76499994421496, 37.624999973668686],
//             [126.76499994749378, 37.629999988502867],
//             [126.77000005476346, 37.630000019951865]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081143',
//       type: 'Feature',
//       properties: {
//         fid: 395880,
//         MAPIDCD_NO: '376081143',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76499994749378, 37.629999988502867],
//             [126.76499994421496, 37.624999973668686],
//             [126.75999994616805, 37.625000001012815],
//             [126.75999995440945, 37.630000016236878],
//             [126.76499994749378, 37.629999988502867]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081142',
//       type: 'Feature',
//       properties: {
//         fid: 395883,
//         MAPIDCD_NO: '376081142',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999995440945, 37.630000016236878],
//             [126.75999994616805, 37.625000001012815],
//             [126.75499994761631, 37.624999996706173],
//             [126.75499996083723, 37.630000010508269],
//             [126.75999995440945, 37.630000016236878]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081537',
//       type: 'Feature',
//       properties: {
//         fid: 396331,
//         MAPIDCD_NO: '376081537',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98500000067132, 37.634999990675432],
//             [126.98499998734899, 37.629999971236074],
//             [126.97999998082067, 37.630000039245218],
//             [126.98000000198651, 37.634999971178644],
//             [126.98500000067132, 37.634999990675432]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081536',
//       type: 'Feature',
//       properties: {
//         fid: 396333,
//         MAPIDCD_NO: '376081536',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98000000198651, 37.634999971178644],
//             [126.97999998082067, 37.630000039245218],
//             [126.97499997614497, 37.629999987298127],
//             [126.97500000165604, 37.635000008362965],
//             [126.98000000198651, 37.634999971178644]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081535',
//       type: 'Feature',
//       properties: {
//         fid: 396335,
//         MAPIDCD_NO: '376081535',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97500000165604, 37.635000008362965],
//             [126.97499997614497, 37.629999987298127],
//             [126.96999997208853, 37.629999993853772],
//             [126.97000000257235, 37.63500001390976],
//             [126.97500000165604, 37.635000008362965]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081534',
//       type: 'Feature',
//       properties: {
//         fid: 396337,
//         MAPIDCD_NO: '376081534',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97000000257235, 37.63500001390976],
//             [126.96999997208853, 37.629999993853772],
//             [126.96499996704183, 37.629999966968093],
//             [126.96500000248484, 37.634999987808492],
//             [126.97000000257235, 37.63500001390976]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081533',
//       type: 'Feature',
//       properties: {
//         fid: 396339,
//         MAPIDCD_NO: '376081533',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96500000248484, 37.634999987808492],
//             [126.96499996704183, 37.629999966968093],
//             [126.95999996260964, 37.630000000386858],
//             [126.96000000302419, 37.635000020199641],
//             [126.96500000248484, 37.634999987808492]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081432',
//       type: 'Feature',
//       properties: {
//         fid: 396362,
//         MAPIDCD_NO: '376081432',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000001202909, 37.634999955604748],
//             [126.91000003916275, 37.630000026486371],
//             [126.90500003616796, 37.629999980501736],
//             [126.90500001553851, 37.635000000428406],
//             [126.91000001202909, 37.634999955604748]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081431',
//       type: 'Feature',
//       properties: {
//         fid: 396364,
//         MAPIDCD_NO: '376081431',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500001553851, 37.635000000428406],
//             [126.90500003616796, 37.629999980501736],
//             [126.90000003160731, 37.629999993002023],
//             [126.90000001592694, 37.635000013586428],
//             [126.90500001553851, 37.635000000428406]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081340',
//       type: 'Feature',
//       properties: {
//         fid: 396366,
//         MAPIDCD_NO: '376081340',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000001592694, 37.635000013586428],
//             [126.90000003160731, 37.629999993002023],
//             [126.89500003072777, 37.629999975682651],
//             [126.89500001775851, 37.634999993298614],
//             [126.90000001592694, 37.635000013586428]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081339',
//       type: 'Feature',
//       properties: {
//         fid: 396369,
//         MAPIDCD_NO: '376081339',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89500001775851, 37.634999993298614],
//             [126.89500003072777, 37.629999975682651],
//             [126.89000002830495, 37.630000015044523],
//             [126.89000002028347, 37.635000033299413],
//             [126.89500001775851, 37.634999993298614]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081338',
//       type: 'Feature',
//       properties: {
//         fid: 396371,
//         MAPIDCD_NO: '376081338',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000002028347, 37.635000033299413],
//             [126.89000002830495, 37.630000015044523],
//             [126.88500002506508, 37.630000022760065],
//             [126.88500002425712, 37.6350000416563],
//             [126.89000002028347, 37.635000033299413]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081337',
//       type: 'Feature',
//       properties: {
//         fid: 396374,
//         MAPIDCD_NO: '376081337',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500002425712, 37.6350000416563],
//             [126.88500002506508, 37.630000022760065],
//             [126.88000002329086, 37.629999998840546],
//             [126.88000002744396, 37.635000016554507],
//             [126.88500002425712, 37.6350000416563]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081336',
//       type: 'Feature',
//       properties: {
//         fid: 396376,
//         MAPIDCD_NO: '376081336',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000002744396, 37.635000016554507],
//             [126.88000002329086, 37.629999998840546],
//             [126.87500002224363, 37.630000033415087],
//             [126.87500002983032, 37.634999961598673],
//             [126.88000002744396, 37.635000016554507]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081335',
//       type: 'Feature',
//       properties: {
//         fid: 396378,
//         MAPIDCD_NO: '376081335',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500002983032, 37.634999961598673],
//             [126.87500002224363, 37.630000033415087],
//             [126.8700000204157, 37.630000036341791],
//             [126.87000003296805, 37.63499996332434],
//             [126.87500002983032, 37.634999961598673]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081334',
//       type: 'Feature',
//       properties: {
//         fid: 396380,
//         MAPIDCD_NO: '376081334',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000003296805, 37.63499996332434],
//             [126.8700000204157, 37.630000036341791],
//             [126.86500001782324, 37.6300000076201],
//             [126.8650000368249, 37.635000025336119],
//             [126.87000003296805, 37.63499996332434]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081333',
//       type: 'Feature',
//       properties: {
//         fid: 396383,
//         MAPIDCD_NO: '376081333',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8650000368249, 37.635000025336119],
//             [126.86500001782324, 37.6300000076201],
//             [126.86000001824255, 37.630000037403136],
//             [126.86000004069344, 37.634999965560688],
//             [126.8650000368249, 37.635000025336119]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081332',
//       type: 'Feature',
//       properties: {
//         fid: 396385,
//         MAPIDCD_NO: '376081332',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000004069344, 37.634999965560688],
//             [126.86000001824255, 37.630000037403136],
//             [126.855000015651, 37.630000035524525],
//             [126.85500004533193, 37.634999962465329],
//             [126.86000004069344, 37.634999965560688]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081331',
//       type: 'Feature',
//       properties: {
//         fid: 396387,
//         MAPIDCD_NO: '376081331',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500004533193, 37.634999962465329],
//             [126.855000015651, 37.630000035524525],
//             [126.85000001686437, 37.630000002020843],
//             [126.85000004845651, 37.63500001783958],
//             [126.85500004533193, 37.634999962465329]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081240',
//       type: 'Feature',
//       properties: {
//         fid: 396389,
//         MAPIDCD_NO: '376081240',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000004845651, 37.63500001783958],
//             [126.85000001686437, 37.630000002020843],
//             [126.84500001657469, 37.630000026995731],
//             [126.8450000531071, 37.635000043369061],
//             [126.85000004845651, 37.63500001783958]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081239',
//       type: 'Feature',
//       properties: {
//         fid: 396391,
//         MAPIDCD_NO: '376081239',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8450000531071, 37.635000043369061],
//             [126.84500001657469, 37.630000026995731],
//             [126.84000001557709, 37.630000020319656],
//             [126.83999994598382, 37.635000036616951],
//             [126.8450000531071, 37.635000043369061]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081238',
//       type: 'Feature',
//       properties: {
//         fid: 396393,
//         MAPIDCD_NO: '376081238',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999994598382, 37.635000036616951],
//             [126.84000001557709, 37.630000020319656],
//             [126.83500001615417, 37.629999982004882],
//             [126.83499995151564, 37.634999997030796],
//             [126.83999994598382, 37.635000036616951]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081237',
//       type: 'Feature',
//       properties: {
//         fid: 396396,
//         MAPIDCD_NO: '376081237',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499995151564, 37.634999997030796],
//             [126.83500001615417, 37.629999982004882],
//             [126.83000001752919, 37.630000000376988],
//             [126.82999995556187, 37.635000015911849],
//             [126.83499995151564, 37.634999997030796]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081236',
//       type: 'Feature',
//       properties: {
//         fid: 396399,
//         MAPIDCD_NO: '376081236',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999995556187, 37.635000015911849],
//             [126.83000001752919, 37.630000000376988],
//             [126.82500001821656, 37.629999988899243],
//             [126.82499996345281, 37.635000004959444],
//             [126.82999995556187, 37.635000015911849]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081235',
//       type: 'Feature',
//       properties: {
//         fid: 396401,
//         MAPIDCD_NO: '376081235',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499996345281, 37.635000004959444],
//             [126.82500001821656, 37.629999988899243],
//             [126.82000001969379, 37.630000035909809],
//             [126.81999996843717, 37.634999960529214],
//             [126.82499996345281, 37.635000004959444]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081234',
//       type: 'Feature',
//       properties: {
//         fid: 396404,
//         MAPIDCD_NO: '376081234',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999996843717, 37.634999960529214],
//             [126.82000001969379, 37.630000035909809],
//             [126.81500002134695, 37.62999996113858],
//             [126.81499997648736, 37.634999974590507],
//             [126.81999996843717, 37.634999960529214]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081233',
//       type: 'Feature',
//       properties: {
//         fid: 396406,
//         MAPIDCD_NO: '376081233',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499997648736, 37.634999974590507],
//             [126.81500002134695, 37.62999996113858],
//             [126.81000002297691, 37.630000034982906],
//             [126.8099999816351, 37.634999956975236],
//             [126.81499997648736, 37.634999974590507]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081232',
//       type: 'Feature',
//       properties: {
//         fid: 396408,
//         MAPIDCD_NO: '376081232',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8099999816351, 37.634999956975236],
//             [126.81000002297691, 37.630000034982906],
//             [126.80500002709835, 37.629999985255232],
//             [126.80499998984001, 37.634999999653118],
//             [126.8099999816351, 37.634999956975236]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081231',
//       type: 'Feature',
//       properties: {
//         fid: 396410,
//         MAPIDCD_NO: '376081231',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499998984001, 37.634999999653118],
//             [126.80500002709835, 37.629999985255232],
//             [126.80000002975486, 37.629999995803104],
//             [126.79999999744591, 37.635000008863948],
//             [126.80499998984001, 37.634999999653118]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081140',
//       type: 'Feature',
//       properties: {
//         fid: 396413,
//         MAPIDCD_NO: '376081140',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999999744591, 37.635000008863948],
//             [126.80000002975486, 37.629999995803104],
//             [126.79500003181258, 37.629999974695259],
//             [126.79500000671888, 37.634999986423267],
//             [126.79999999744591, 37.635000008863948]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081139',
//       type: 'Feature',
//       properties: {
//         fid: 396416,
//         MAPIDCD_NO: '376081139',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500000671888, 37.634999986423267],
//             [126.79500003181258, 37.629999974695259],
//             [126.79000003698023, 37.630000010283574],
//             [126.79000001455086, 37.635000022444835],
//             [126.79500000671888, 37.634999986423267]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081138',
//       type: 'Feature',
//       properties: {
//         fid: 396418,
//         MAPIDCD_NO: '376081138',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000001455086, 37.635000022444835],
//             [126.79000003698023, 37.630000010283574],
//             [126.78500004155229, 37.63000001601776],
//             [126.78500002407012, 37.635000026813863],
//             [126.79000001455086, 37.635000022444835]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081137',
//       type: 'Feature',
//       properties: {
//         fid: 396420,
//         MAPIDCD_NO: '376081137',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500002407012, 37.635000026813863],
//             [126.78500004155229, 37.63000001601776],
//             [126.78000004557929, 37.629999988292113],
//             [126.78000003302617, 37.634999999516289],
//             [126.78500002407012, 37.635000026813863]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081136',
//       type: 'Feature',
//       properties: {
//         fid: 396422,
//         MAPIDCD_NO: '376081136',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000003302617, 37.634999999516289],
//             [126.78000004557929, 37.629999988292113],
//             [126.77500005043368, 37.630000020852485],
//             [126.77500004282605, 37.635000030692808],
//             [126.78000003302617, 37.634999999516289]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081135',
//       type: 'Feature',
//       properties: {
//         fid: 396426,
//         MAPIDCD_NO: '376081135',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500004282605, 37.635000030692808],
//             [126.77500005043368, 37.630000020852485],
//             [126.77000005476346, 37.630000019951865],
//             [126.77000005206536, 37.635000032004029],
//             [126.77500004282605, 37.635000030692808]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081134',
//       type: 'Feature',
//       properties: {
//         fid: 396428,
//         MAPIDCD_NO: '376081134',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000005206536, 37.635000032004029],
//             [126.77000005476346, 37.630000019951865],
//             [126.76499994749378, 37.629999988502867],
//             [126.76499994973207, 37.634999999152392],
//             [126.77000005206536, 37.635000032004029]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081133',
//       type: 'Feature',
//       properties: {
//         fid: 396430,
//         MAPIDCD_NO: '376081133',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76499994973207, 37.634999999152392],
//             [126.76499994749378, 37.629999988502867],
//             [126.75999995440945, 37.630000016236878],
//             [126.75999996159091, 37.635000025474447],
//             [126.76499994973207, 37.634999999152392]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081132',
//       type: 'Feature',
//       properties: {
//         fid: 396433,
//         MAPIDCD_NO: '376081132',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999996159091, 37.635000025474447],
//             [126.75999995440945, 37.630000016236878],
//             [126.75499996083723, 37.630000010508269],
//             [126.75499997294313, 37.63500002012708],
//             [126.75999996159091, 37.635000025474447]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081131',
//       type: 'Feature',
//       properties: {
//         fid: 396435,
//         MAPIDCD_NO: '376081131',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75499997294313, 37.63500002012708],
//             [126.75499996083723, 37.630000010508269],
//             [126.74999996904198, 37.629999973133387],
//             [126.74999998607146, 37.634999983124068],
//             [126.75499997294313, 37.63500002012708]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071540',
//       type: 'Feature',
//       properties: {
//         fid: 396438,
//         MAPIDCD_NO: '376071540',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74999998607146, 37.634999983124068],
//             [126.74999996904198, 37.629999973133387],
//             [126.74499997584346, 37.629999996026768],
//             [126.74499999781354, 37.635000004577392],
//             [126.74999998607146, 37.634999983124068]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081528',
//       type: 'Feature',
//       properties: {
//         fid: 396879,
//         MAPIDCD_NO: '376081528',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99000000958486, 37.639999996546671],
//             [126.99000000057012, 37.634999980338058],
//             [126.98500000067132, 37.634999990675432],
//             [126.9850000146109, 37.640000007706284],
//             [126.99000000958486, 37.639999996546671]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081527',
//       type: 'Feature',
//       properties: {
//         fid: 396881,
//         MAPIDCD_NO: '376081527',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9850000146109, 37.640000007706284],
//             [126.98500000067132, 37.634999990675432],
//             [126.98000000198651, 37.634999971178644],
//             [126.98000002086288, 37.639999987219831],
//             [126.9850000146109, 37.640000007706284]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081526',
//       type: 'Feature',
//       properties: {
//         fid: 396883,
//         MAPIDCD_NO: '376081526',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98000002086288, 37.639999987219831],
//             [126.98000000198651, 37.634999971178644],
//             [126.97500000165604, 37.635000008362965],
//             [126.97500002545577, 37.640000025207677],
//             [126.98000002086288, 37.639999987219831]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081525',
//       type: 'Feature',
//       properties: {
//         fid: 396885,
//         MAPIDCD_NO: '376081525',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97500002545577, 37.640000025207677],
//             [126.97500000165604, 37.635000008362965],
//             [126.97000000257235, 37.63500001390976],
//             [126.97000003129479, 37.640000031548659],
//             [126.97500002545577, 37.640000025207677]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081524',
//       type: 'Feature',
//       properties: {
//         fid: 396887,
//         MAPIDCD_NO: '376081524',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97000003129479, 37.640000031548659],
//             [126.97000000257235, 37.63500001390976],
//             [126.96500000248484, 37.634999987808492],
//             [126.96500003614209, 37.640000004429673],
//             [126.97000003129479, 37.640000031548659]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081523',
//       type: 'Feature',
//       properties: {
//         fid: 396889,
//         MAPIDCD_NO: '376081523',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96500003614209, 37.640000004429673],
//             [126.96500000248484, 37.634999987808492],
//             [126.96000000302419, 37.635000020199641],
//             [126.96000004160258, 37.640000037596259],
//             [126.96500003614209, 37.640000004429673]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081423',
//       type: 'Feature',
//       properties: {
//         fid: 396909,
//         MAPIDCD_NO: '376081423',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499998102422, 37.6399999854621],
//             [126.91500001147594, 37.634999969279868],
//             [126.91000001202909, 37.634999955604748],
//             [126.9099999865051, 37.639999970661449],
//             [126.91499998102422, 37.6399999854621]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081422',
//       type: 'Feature',
//       properties: {
//         fid: 396912,
//         MAPIDCD_NO: '376081422',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9099999865051, 37.639999970661449],
//             [126.91000001202909, 37.634999955604748],
//             [126.90500001553851, 37.635000000428406],
//             [126.904999992675, 37.640000014338739],
//             [126.9099999865051, 37.639999970661449]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081421',
//       type: 'Feature',
//       properties: {
//         fid: 396914,
//         MAPIDCD_NO: '376081421',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.904999992675, 37.640000014338739],
//             [126.90500001553851, 37.635000000428406],
//             [126.90000001592694, 37.635000013586428],
//             [126.90000000024227, 37.640000028166511],
//             [126.904999992675, 37.640000014338739]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081330',
//       type: 'Feature',
//       properties: {
//         fid: 396916,
//         MAPIDCD_NO: '376081330',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000000024227, 37.640000028166511],
//             [126.90000001592694, 37.635000013586428],
//             [126.89500001775851, 37.634999993298614],
//             [126.8950000069851, 37.6400000085277],
//             [126.90000000024227, 37.640000028166511]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081329',
//       type: 'Feature',
//       properties: {
//         fid: 396918,
//         MAPIDCD_NO: '376081329',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8950000069851, 37.6400000085277],
//             [126.89500001775851, 37.634999993298614],
//             [126.89000002028347, 37.635000033299413],
//             [126.89000001517211, 37.63999995723605],
//             [126.8950000069851, 37.6400000085277]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081328',
//       type: 'Feature',
//       properties: {
//         fid: 396921,
//         MAPIDCD_NO: '376081328',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000001517211, 37.63999995723605],
//             [126.89000002028347, 37.635000033299413],
//             [126.88500002425712, 37.6350000416563],
//             [126.88500002180959, 37.639999964408858],
//             [126.89000001517211, 37.63999995723605]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081327',
//       type: 'Feature',
//       properties: {
//         fid: 396924,
//         MAPIDCD_NO: '376081327',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500002180959, 37.639999964408858],
//             [126.88500002425712, 37.6350000416563],
//             [126.88000002744396, 37.635000016554507],
//             [126.88000002914738, 37.640000031859792],
//             [126.88500002180959, 37.639999964408858]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081326',
//       type: 'Feature',
//       properties: {
//         fid: 396926,
//         MAPIDCD_NO: '376081326',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000002914738, 37.640000031859792],
//             [126.88000002744396, 37.635000016554507],
//             [126.87500002983032, 37.634999961598673],
//             [126.87500003645675, 37.639999975712833],
//             [126.88000002914738, 37.640000031859792]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081325',
//       type: 'Feature',
//       properties: {
//         fid: 396928,
//         MAPIDCD_NO: '376081325',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500003645675, 37.639999975712833],
//             [126.87500002983032, 37.634999961598673],
//             [126.87000003296805, 37.63499996332434],
//             [126.87000004450168, 37.639999978040557],
//             [126.87500003645675, 37.639999975712833]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081324',
//       type: 'Feature',
//       properties: {
//         fid: 396930,
//         MAPIDCD_NO: '376081324',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000004450168, 37.639999978040557],
//             [126.87000003296805, 37.63499996332434],
//             [126.8650000368249, 37.635000025336119],
//             [126.86500005328009, 37.640000038842395],
//             [126.87000004450168, 37.639999978040557]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081323',
//       type: 'Feature',
//       properties: {
//         fid: 396933,
//         MAPIDCD_NO: '376081323',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500005328009, 37.640000038842395],
//             [126.8650000368249, 37.635000025336119],
//             [126.86000004069344, 37.634999965560688],
//             [126.85999994872991, 37.639999977232932],
//             [126.86500005328009, 37.640000038842395]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081322',
//       type: 'Feature',
//       properties: {
//         fid: 396935,
//         MAPIDCD_NO: '376081322',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999994872991, 37.639999977232932],
//             [126.86000004069344, 37.634999965560688],
//             [126.85500004533193, 37.634999962465329],
//             [126.85499995827334, 37.639999974706726],
//             [126.85999994872991, 37.639999977232932]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081321',
//       type: 'Feature',
//       properties: {
//         fid: 396937,
//         MAPIDCD_NO: '376081321',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499995827334, 37.639999974706726],
//             [126.85500004533193, 37.634999962465329],
//             [126.85000004845651, 37.63500001783958],
//             [126.84999996630171, 37.640000030640735],
//             [126.85499995827334, 37.639999974706726]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081230',
//       type: 'Feature',
//       properties: {
//         fid: 396939,
//         MAPIDCD_NO: '376081230',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999996630171, 37.640000030640735],
//             [126.85000004845651, 37.63500001783958],
//             [126.8450000531071, 37.635000043369061],
//             [126.84499997666246, 37.639999964789453],
//             [126.84999996630171, 37.640000030640735]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081229',
//       type: 'Feature',
//       properties: {
//         fid: 396941,
//         MAPIDCD_NO: '376081229',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499997666246, 37.639999964789453],
//             [126.8450000531071, 37.635000043369061],
//             [126.83999994598382, 37.635000036616951],
//             [126.83999998779522, 37.639999957409948],
//             [126.84499997666246, 37.639999964789453]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081228',
//       type: 'Feature',
//       properties: {
//         fid: 396943,
//         MAPIDCD_NO: '376081228',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999998779522, 37.639999957409948],
//             [126.83999994598382, 37.635000036616951],
//             [126.83499995151564, 37.634999997030796],
//             [126.83499999743113, 37.640000008488883],
//             [126.83999998779522, 37.639999957409948]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081227',
//       type: 'Feature',
//       properties: {
//         fid: 396946,
//         MAPIDCD_NO: '376081227',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499999743113, 37.640000008488883],
//             [126.83499995151564, 37.634999997030796],
//             [126.82999995556187, 37.635000015911849],
//             [126.8300000063777, 37.640000027897067],
//             [126.83499999743113, 37.640000008488883]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081226',
//       type: 'Feature',
//       properties: {
//         fid: 396949,
//         MAPIDCD_NO: '376081226',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8300000063777, 37.640000027897067],
//             [126.82999995556187, 37.635000015911849],
//             [126.82499996345281, 37.635000004959444],
//             [126.8250000191846, 37.640000015659865],
//             [126.8300000063777, 37.640000027897067]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081225',
//       type: 'Feature',
//       properties: {
//         fid: 396951,
//         MAPIDCD_NO: '376081225',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8250000191846, 37.640000015659865],
//             [126.82499996345281, 37.635000004959444],
//             [126.81999996843717, 37.634999960529214],
//             [126.82000002906764, 37.639999971738114],
//             [126.8250000191846, 37.640000015659865]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081224',
//       type: 'Feature',
//       properties: {
//         fid: 396954,
//         MAPIDCD_NO: '376081224',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000002906764, 37.639999971738114],
//             [126.81999996843717, 37.634999960529214],
//             [126.81499997648736, 37.634999974590507],
//             [126.8150000420323, 37.639999984495915],
//             [126.82000002906764, 37.639999971738114]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081223',
//       type: 'Feature',
//       properties: {
//         fid: 396956,
//         MAPIDCD_NO: '376081223',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8150000420323, 37.639999984495915],
//             [126.81499997648736, 37.634999974590507],
//             [126.8099999816351, 37.634999956975236],
//             [126.81000005207693, 37.639999967370372],
//             [126.8150000420323, 37.639999984495915]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081222',
//       type: 'Feature',
//       properties: {
//         fid: 396958,
//         MAPIDCD_NO: '376081222',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000005207693, 37.639999967370372],
//             [126.8099999816351, 37.634999956975236],
//             [126.80499998984001, 37.634999999653118],
//             [126.8049999518562, 37.640000008058308],
//             [126.81000005207693, 37.639999967370372]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081221',
//       type: 'Feature',
//       properties: {
//         fid: 396960,
//         MAPIDCD_NO: '376081221',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8049999518562, 37.640000008058308],
//             [126.80499998984001, 37.634999999653118],
//             [126.79999999744591, 37.635000008863948],
//             [126.79999996435745, 37.640000017735318],
//             [126.8049999518562, 37.640000008058308]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081130',
//       type: 'Feature',
//       properties: {
//         fid: 396963,
//         MAPIDCD_NO: '376081130',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999996435745, 37.640000017735318],
//             [126.79999999744591, 37.635000008863948],
//             [126.79500000671888, 37.634999986423267],
//             [126.79499997625818, 37.639999995737931],
//             [126.79999996435745, 37.640000017735318]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081129',
//       type: 'Feature',
//       properties: {
//         fid: 396966,
//         MAPIDCD_NO: '376081129',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499997625818, 37.639999995737931],
//             [126.79500000671888, 37.634999986423267],
//             [126.79000001455086, 37.635000022444835],
//             [126.78999999126745, 37.6400000304179],
//             [126.79499997625818, 37.639999995737931]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081128',
//       type: 'Feature',
//       properties: {
//         fid: 396968,
//         MAPIDCD_NO: '376081128',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999999126745, 37.6400000304179],
//             [126.79000001455086, 37.635000022444835],
//             [126.78500002407012, 37.635000026813863],
//             [126.78500000341258, 37.640000035211273],
//             [126.78999999126745, 37.6400000304179]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081127',
//       type: 'Feature',
//       properties: {
//         fid: 396970,
//         MAPIDCD_NO: '376081127',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500000341258, 37.640000035211273],
//             [126.78500002407012, 37.635000026813863],
//             [126.78000003302617, 37.634999999516289],
//             [126.78000001952704, 37.640000008356196],
//             [126.78500000341258, 37.640000035211273]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081126',
//       type: 'Feature',
//       properties: {
//         fid: 396972,
//         MAPIDCD_NO: '376081126',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000001952704, 37.640000008356196],
//             [126.78000003302617, 37.634999999516289],
//             [126.77500004282605, 37.635000030692808],
//             [126.77500003423516, 37.640000038149488],
//             [126.78000001952704, 37.640000008356196]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081125',
//       type: 'Feature',
//       properties: {
//         fid: 396976,
//         MAPIDCD_NO: '376081125',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500003423516, 37.640000038149488],
//             [126.77500004282605, 37.635000030692808],
//             [126.77000005206536, 37.635000032004029],
//             [126.77000004838189, 37.640000038068173],
//             [126.77500003423516, 37.640000038149488]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081124',
//       type: 'Feature',
//       properties: {
//         fid: 396978,
//         MAPIDCD_NO: '376081124',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000004838189, 37.640000038068173],
//             [126.77000005206536, 37.635000032004029],
//             [126.76499994973207, 37.634999999152392],
//             [126.76499995093005, 37.640000005617111],
//             [126.77000004838189, 37.640000038068173]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081123',
//       type: 'Feature',
//       properties: {
//         fid: 396980,
//         MAPIDCD_NO: '376081123',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76499995093005, 37.640000005617111],
//             [126.76499994973207, 37.634999999152392],
//             [126.75999996159091, 37.635000025474447],
//             [126.75999996769481, 37.640000030527872],
//             [126.76499995093005, 37.640000005617111]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081122',
//       type: 'Feature',
//       properties: {
//         fid: 396983,
//         MAPIDCD_NO: '376081122',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999996769481, 37.640000030527872],
//             [126.75999996159091, 37.635000025474447],
//             [126.75499997294313, 37.63500002012708],
//             [126.75499998393413, 37.640000025562408],
//             [126.75999996769481, 37.640000030527872]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081121',
//       type: 'Feature',
//       properties: {
//         fid: 396985,
//         MAPIDCD_NO: '376081121',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75499998393413, 37.640000025562408],
//             [126.75499997294313, 37.63500002012708],
//             [126.74999998607146, 37.634999983124068],
//             [126.74999999970014, 37.63999998711504],
//             [126.75499998393413, 37.640000025562408]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071530',
//       type: 'Feature',
//       properties: {
//         fid: 396988,
//         MAPIDCD_NO: '376071530',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74999999970014, 37.63999998711504],
//             [126.74999998607146, 37.634999983124068],
//             [126.74499999781354, 37.635000004577392],
//             [126.74500001859417, 37.640000008945933],
//             [126.74999999970014, 37.63999998711504]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071529',
//       type: 'Feature',
//       properties: {
//         fid: 396990,
//         MAPIDCD_NO: '376071529',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74500001859417, 37.640000008945933],
//             [126.74499999781354, 37.635000004577392],
//             [126.74000001135194, 37.634999994374056],
//             [126.74000003476856, 37.639999997279304],
//             [126.74500001859417, 37.640000008945933]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081518',
//       type: 'Feature',
//       properties: {
//         fid: 397429,
//         MAPIDCD_NO: '376081518',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99000001925468, 37.645000010346045],
//             [126.99000000958486, 37.639999996546671],
//             [126.9850000146109, 37.640000007706284],
//             [126.98500002918068, 37.645000020525877],
//             [126.99000001925468, 37.645000010346045]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081517',
//       type: 'Feature',
//       properties: {
//         fid: 397431,
//         MAPIDCD_NO: '376081517',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98500002918068, 37.645000020525877],
//             [126.9850000146109, 37.640000007706284],
//             [126.98000002086288, 37.639999987219831],
//             [126.98000004033214, 37.644999999050263],
//             [126.98500002918068, 37.645000020525877]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081516',
//       type: 'Feature',
//       properties: {
//         fid: 397433,
//         MAPIDCD_NO: '376081516',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98000004033214, 37.644999999050263],
//             [126.98000002086288, 37.639999987219831],
//             [126.97500002545577, 37.640000025207677],
//             [126.97500004981117, 37.645000037842181],
//             [126.98000004033214, 37.644999999050263]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081515',
//       type: 'Feature',
//       properties: {
//         fid: 397435,
//         MAPIDCD_NO: '376081515',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97500004981117, 37.645000037842181],
//             [126.97500002545577, 37.640000025207677],
//             [126.97000003129479, 37.640000031548659],
//             [126.96999994719884, 37.645000042666084],
//             [126.97500004981117, 37.645000037842181]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081514',
//       type: 'Feature',
//       properties: {
//         fid: 397437,
//         MAPIDCD_NO: '376081514',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96999994719884, 37.645000042666084],
//             [126.97000003129479, 37.640000031548659],
//             [126.96500003614209, 37.640000004429673],
//             [126.96499995693092, 37.64500001632765],
//             [126.96999994719884, 37.645000042666084]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081513',
//       type: 'Feature',
//       properties: {
//         fid: 397439,
//         MAPIDCD_NO: '376081513',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96499995693092, 37.64500001632765],
//             [126.96500003614209, 37.640000004429673],
//             [126.96000004160258, 37.640000037596259],
//             [126.95999996566097, 37.644999960124643],
//             [126.96499995693092, 37.64500001632765]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081512',
//       type: 'Feature',
//       properties: {
//         fid: 397441,
//         MAPIDCD_NO: '376081512',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95999996566097, 37.644999960124643],
//             [126.96000004160258, 37.640000037596259],
//             [126.9550000460787, 37.640000039104514],
//             [126.9549999773064, 37.644999960602576],
//             [126.95999996566097, 37.644999960124643]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081413',
//       type: 'Feature',
//       properties: {
//         fid: 397459,
//         MAPIDCD_NO: '376081413',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499994841296, 37.644999995626854],
//             [126.91499998102422, 37.6399999854621],
//             [126.9099999865051, 37.639999970661449],
//             [126.90999995877021, 37.644999981503737],
//             [126.91499994841296, 37.644999995626854]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081412',
//       type: 'Feature',
//       properties: {
//         fid: 397462,
//         MAPIDCD_NO: '376081412',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999995877021, 37.644999981503737],
//             [126.9099999865051, 37.639999970661449],
//             [126.904999992675, 37.640000014338739],
//             [126.90499996981556, 37.645000025849157],
//             [126.90999995877021, 37.644999981503737]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081411',
//       type: 'Feature',
//       properties: {
//         fid: 397464,
//         MAPIDCD_NO: '376081411',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499996981556, 37.645000025849157],
//             [126.904999992675, 37.640000014338739],
//             [126.90000000024227, 37.640000028166511],
//             [126.89999998227212, 37.645000038533155],
//             [126.90499996981556, 37.645000025849157]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081320',
//       type: 'Feature',
//       properties: {
//         fid: 397466,
//         MAPIDCD_NO: '376081320',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999998227212, 37.645000038533155],
//             [126.90000000024227, 37.640000028166511],
//             [126.8950000069851, 37.6400000085277],
//             [126.89499999388893, 37.645000019543829],
//             [126.89999998227212, 37.645000038533155]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081319',
//       type: 'Feature',
//       properties: {
//         fid: 397468,
//         MAPIDCD_NO: '376081319',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499999388893, 37.645000019543829],
//             [126.8950000069851, 37.6400000085277],
//             [126.89000001517211, 37.63999995723605],
//             [126.8900000069639, 37.644999967089717],
//             [126.89499999388893, 37.645000019543829]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081318',
//       type: 'Feature',
//       properties: {
//         fid: 397471,
//         MAPIDCD_NO: '376081318',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8900000069639, 37.644999967089717],
//             [126.89000001517211, 37.63999995723605],
//             [126.88500002180959, 37.639999964408858],
//             [126.88500001847373, 37.644999974893267],
//             [126.8900000069639, 37.644999967089717]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081317',
//       type: 'Feature',
//       properties: {
//         fid: 397474,
//         MAPIDCD_NO: '376081317',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500001847373, 37.644999974893267],
//             [126.88500002180959, 37.639999964408858],
//             [126.88000002914738, 37.640000031859792],
//             [126.88000003069797, 37.64500004116293],
//             [126.88500001847373, 37.644999974893267]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081316',
//       type: 'Feature',
//       properties: {
//         fid: 397476,
//         MAPIDCD_NO: '376081316',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000003069797, 37.64500004116293],
//             [126.88000002914738, 37.640000031859792],
//             [126.87500003645675, 37.639999975712833],
//             [126.87500004289323, 37.644999983825478],
//             [126.88000003069797, 37.64500004116293]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081315',
//       type: 'Feature',
//       properties: {
//         fid: 397478,
//         MAPIDCD_NO: '376081315',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500004289323, 37.644999983825478],
//             [126.87500003645675, 37.639999975712833],
//             [126.87000004450168, 37.639999978040557],
//             [126.87000005580809, 37.644999986755842],
//             [126.87500004289323, 37.644999983825478]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081314',
//       type: 'Feature',
//       properties: {
//         fid: 397480,
//         MAPIDCD_NO: '376081314',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000005580809, 37.644999986755842],
//             [126.87000004450168, 37.639999978040557],
//             [126.86500005328009, 37.640000038842395],
//             [126.86499995687547, 37.644999957411933],
//             [126.87000005580809, 37.644999986755842]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081313',
//       type: 'Feature',
//       properties: {
//         fid: 397483,
//         MAPIDCD_NO: '376081313',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499995687547, 37.644999957411933],
//             [126.86500005328009, 37.640000038842395],
//             [126.85999994872991, 37.639999977232932],
//             [126.85999996976615, 37.644999987125409],
//             [126.86499995687547, 37.644999957411933]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081312',
//       type: 'Feature',
//       properties: {
//         fid: 397485,
//         MAPIDCD_NO: '376081312',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999996976615, 37.644999987125409],
//             [126.85999994872991, 37.639999977232932],
//             [126.85499995827334, 37.639999974706726],
//             [126.85499998419256, 37.644999983371221],
//             [126.85999996976615, 37.644999987125409]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081311',
//       type: 'Feature',
//       properties: {
//         fid: 397487,
//         MAPIDCD_NO: '376081311',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499998419256, 37.644999983371221],
//             [126.85499995827334, 37.639999974706726],
//             [126.84999996630171, 37.640000030640735],
//             [126.84999999708737, 37.645000039870411],
//             [126.85499998419256, 37.644999983371221]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081220',
//       type: 'Feature',
//       properties: {
//         fid: 397489,
//         MAPIDCD_NO: '376081220',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999999708737, 37.645000039870411],
//             [126.84999996630171, 37.640000030640735],
//             [126.84499997666246, 37.639999964789453],
//             [126.84500001232979, 37.64499997277246],
//             [126.84999999708737, 37.645000039870411]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081219',
//       type: 'Feature',
//       properties: {
//         fid: 397491,
//         MAPIDCD_NO: '376081219',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500001232979, 37.64499997277246],
//             [126.84499997666246, 37.639999964789453],
//             [126.83999998779522, 37.639999957409948],
//             [126.84000002606055, 37.644999965926793],
//             [126.84500001232979, 37.64499997277246]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081218',
//       type: 'Feature',
//       properties: {
//         fid: 397493,
//         MAPIDCD_NO: '376081218',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000002606055, 37.644999965926793],
//             [126.83999998779522, 37.639999957409948],
//             [126.83499999743113, 37.640000008488883],
//             [126.83500004284336, 37.645000015752927],
//             [126.84000002606055, 37.644999965926793]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081217',
//       type: 'Feature',
//       properties: {
//         fid: 397496,
//         MAPIDCD_NO: '376081217',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500004284336, 37.645000015752927],
//             [126.83499999743113, 37.640000008488883],
//             [126.8300000063777, 37.640000027897067],
//             [126.83000005666919, 37.645000033886355],
//             [126.83500004284336, 37.645000015752927]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081216',
//       type: 'Feature',
//       properties: {
//         fid: 397499,
//         MAPIDCD_NO: '376081216',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000005666919, 37.645000033886355],
//             [126.8300000063777, 37.640000027897067],
//             [126.8250000191846, 37.640000015659865],
//             [126.82499995872516, 37.645000021506092],
//             [126.83000005666919, 37.645000033886355]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081215',
//       type: 'Feature',
//       properties: {
//         fid: 397501,
//         MAPIDCD_NO: '376081215',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499995872516, 37.645000021506092],
//             [126.8250000191846, 37.640000015659865],
//             [126.82000002906764, 37.639999971738114],
//             [126.81999997575311, 37.644999976299033],
//             [126.82499995872516, 37.645000021506092]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081214',
//       type: 'Feature',
//       properties: {
//         fid: 397504,
//         MAPIDCD_NO: '376081214',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999997575311, 37.644999976299033],
//             [126.82000002906764, 37.639999971738114],
//             [126.8150000420323, 37.639999984495915],
//             [126.81499999129515, 37.644999991341116],
//             [126.81999997575311, 37.644999976299033]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081213',
//       type: 'Feature',
//       properties: {
//         fid: 397506,
//         MAPIDCD_NO: '376081213',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499999129515, 37.644999991341116],
//             [126.8150000420323, 37.639999984495915],
//             [126.81000005207693, 37.639999967370372],
//             [126.81000000848295, 37.644999972911741],
//             [126.81499999129515, 37.644999991341116]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081212',
//       type: 'Feature',
//       properties: {
//         fid: 397508,
//         MAPIDCD_NO: '376081212',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000000848295, 37.644999972911741],
//             [126.81000005207693, 37.639999967370372],
//             [126.8049999518562, 37.640000008058308],
//             [126.80500002420952, 37.6450000129276],
//             [126.81000000848295, 37.644999972911741]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081211',
//       type: 'Feature',
//       properties: {
//         fid: 397510,
//         MAPIDCD_NO: '376081211',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500002420952, 37.6450000129276],
//             [126.8049999518562, 37.640000008058308],
//             [126.79999996435745, 37.640000017735318],
//             [126.80000004156874, 37.645000023076143],
//             [126.80500002420952, 37.6450000129276]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081120',
//       type: 'Feature',
//       properties: {
//         fid: 397513,
//         MAPIDCD_NO: '376081120',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000004156874, 37.645000023076143],
//             [126.79999996435745, 37.640000017735318],
//             [126.79499997625818, 37.639999995737931],
//             [126.79499994726454, 37.644999999074578],
//             [126.80000004156874, 37.645000023076143]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081119',
//       type: 'Feature',
//       properties: {
//         fid: 397516,
//         MAPIDCD_NO: '376081119',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499994726454, 37.644999999074578],
//             [126.79499997625818, 37.639999995737931],
//             [126.78999999126745, 37.6400000304179],
//             [126.78999996486327, 37.645000034188975],
//             [126.79499994726454, 37.644999999074578]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081118',
//       type: 'Feature',
//       properties: {
//         fid: 397518,
//         MAPIDCD_NO: '376081118',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999996486327, 37.645000034188975],
//             [126.78999999126745, 37.6400000304179],
//             [126.78500000341258, 37.640000035211273],
//             [126.78499998414783, 37.645000037632215],
//             [126.78999996486327, 37.645000034188975]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081117',
//       type: 'Feature',
//       properties: {
//         fid: 397520,
//         MAPIDCD_NO: '376081117',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78499998414783, 37.645000037632215],
//             [126.78500000341258, 37.640000035211273],
//             [126.78000001952704, 37.640000008356196],
//             [126.78000000285009, 37.645000011192693],
//             [126.78499998414783, 37.645000037632215]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081116',
//       type: 'Feature',
//       properties: {
//         fid: 397522,
//         MAPIDCD_NO: '376081116',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000000285009, 37.645000011192693],
//             [126.78000001952704, 37.640000008356196],
//             [126.77500003423516, 37.640000038149488],
//             [126.77500002241166, 37.645000041405872],
//             [126.78000000285009, 37.645000011192693]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081115',
//       type: 'Feature',
//       properties: {
//         fid: 397526,
//         MAPIDCD_NO: '376081115',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500002241166, 37.645000041405872],
//             [126.77500003423516, 37.640000038149488],
//             [126.77000004838189, 37.640000038068173],
//             [126.77000004142859, 37.64500003993259],
//             [126.77500002241166, 37.645000041405872]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081114',
//       type: 'Feature',
//       properties: {
//         fid: 397528,
//         MAPIDCD_NO: '376081114',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000004142859, 37.64500003993259],
//             [126.77000004838189, 37.640000038068173],
//             [126.76499995093005, 37.640000005617111],
//             [126.76499994883869, 37.64500000608016],
//             [126.77000004142859, 37.64500003993259]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081113',
//       type: 'Feature',
//       properties: {
//         fid: 397530,
//         MAPIDCD_NO: '376081113',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76499994883869, 37.64500000608016],
//             [126.76499995093005, 37.640000005617111],
//             [126.75999996769481, 37.640000030527872],
//             [126.75999997045436, 37.645000031382764],
//             [126.76499994883869, 37.64500000608016]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081112',
//       type: 'Feature',
//       properties: {
//         fid: 397533,
//         MAPIDCD_NO: '376081112',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999997045436, 37.645000031382764],
//             [126.75999996769481, 37.640000030527872],
//             [126.75499998393413, 37.640000025562408],
//             [126.75499999156156, 37.645000024997252],
//             [126.75999997045436, 37.645000031382764]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081111',
//       type: 'Feature',
//       properties: {
//         fid: 397535,
//         MAPIDCD_NO: '376081111',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75499999156156, 37.645000024997252],
//             [126.75499998393413, 37.640000025562408],
//             [126.74999999970014, 37.63999998711504],
//             [126.75000001217654, 37.644999986923061],
//             [126.75499999156156, 37.645000024997252]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071520',
//       type: 'Feature',
//       properties: {
//         fid: 397538,
//         MAPIDCD_NO: '376071520',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75000001217654, 37.644999986923061],
//             [126.74999999970014, 37.63999998711504],
//             [126.74500001859417, 37.640000008945933],
//             [126.74500003593694, 37.645000007315147],
//             [126.75000001217654, 37.644999986923061]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071519',
//       type: 'Feature',
//       properties: {
//         fid: 397540,
//         MAPIDCD_NO: '376071519',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74500003593694, 37.645000007315147],
//             [126.74500001859417, 37.640000008945933],
//             [126.74000003476856, 37.639999997279304],
//             [126.73999994586247, 37.644999997089869],
//             [126.74500003593694, 37.645000007315147]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071518',
//       type: 'Feature',
//       properties: {
//         fid: 397542,
//         MAPIDCD_NO: '376071518',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73999994586247, 37.644999997089869],
//             [126.74000003476856, 37.639999997279304],
//             [126.7350000540973, 37.640000044087294],
//             [126.734999967789, 37.645000042420975],
//             [126.73999994586247, 37.644999997089869]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081508',
//       type: 'Feature',
//       properties: {
//         fid: 397974,
//         MAPIDCD_NO: '376081508',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99000002960459, 37.650000018130818],
//             [126.99000001925468, 37.645000010346045],
//             [126.98500002918068, 37.645000020525877],
//             [126.98500004438097, 37.65000002913397],
//             [126.99000002960459, 37.650000018130818]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081507',
//       type: 'Feature',
//       properties: {
//         fid: 397976,
//         MAPIDCD_NO: '376081507',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98500004438097, 37.65000002913397],
//             [126.98500002918068, 37.645000020525877],
//             [126.98000004033214, 37.644999999050263],
//             [126.97999994475759, 37.65000000796271],
//             [126.98500004438097, 37.65000002913397]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081506',
//       type: 'Feature',
//       properties: {
//         fid: 397978,
//         MAPIDCD_NO: '376081506',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97999994475759, 37.65000000796271],
//             [126.98000004033214, 37.644999999050263],
//             [126.97500004981117, 37.645000037842181],
//             [126.97499995973261, 37.649999955621134],
//             [126.97999994475759, 37.65000000796271]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081505',
//       type: 'Feature',
//       properties: {
//         fid: 397980,
//         MAPIDCD_NO: '376081505',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97499995973261, 37.649999955621134],
//             [126.97500004981117, 37.645000037842181],
//             [126.96999994719884, 37.645000042666084],
//             [126.96999997532416, 37.6499999617447],
//             [126.97499995973261, 37.649999955621134]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081504',
//       type: 'Feature',
//       properties: {
//         fid: 397982,
//         MAPIDCD_NO: '376081504',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96999997532416, 37.6499999617447],
//             [126.96999994719884, 37.645000042666084],
//             [126.96499995693092, 37.64500001632765],
//             [126.964999989276, 37.650000024520025],
//             [126.96999997532416, 37.6499999617447]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081503',
//       type: 'Feature',
//       properties: {
//         fid: 397984,
//         MAPIDCD_NO: '376081503',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.964999989276, 37.650000024520025],
//             [126.96499995693092, 37.64500001632765],
//             [126.95999996566097, 37.644999960124643],
//             [126.96000000513284, 37.649999967301291],
//             [126.964999989276, 37.650000024520025]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081502',
//       type: 'Feature',
//       properties: {
//         fid: 397986,
//         MAPIDCD_NO: '376081502',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96000000513284, 37.649999967301291],
//             [126.95999996566097, 37.644999960124643],
//             [126.9549999773064, 37.644999960602576],
//             [126.95500001935723, 37.649999968535965],
//             [126.96000000513284, 37.649999967301291]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081406',
//       type: 'Feature',
//       properties: {
//         fid: 397998,
//         MAPIDCD_NO: '376081406',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92999998146524, 37.650000036271905],
//             [126.93000002852573, 37.645000028796247],
//             [126.92500003997509, 37.6450000195512],
//             [126.9249999977561, 37.650000027733064],
//             [126.92999998146524, 37.650000036271905]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081405',
//       type: 'Feature',
//       properties: {
//         fid: 398000,
//         MAPIDCD_NO: '376081405',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9249999977561, 37.650000027733064],
//             [126.92500003997509, 37.6450000195512],
//             [126.92000005052825, 37.6449999786353],
//             [126.92000001316386, 37.649999985711396],
//             [126.9249999977561, 37.650000027733064]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081404',
//       type: 'Feature',
//       properties: {
//         fid: 398002,
//         MAPIDCD_NO: '376081404',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000001316386, 37.649999985711396],
//             [126.92000005052825, 37.6449999786353],
//             [126.91499994841296, 37.644999995626854],
//             [126.91500002925102, 37.650000002149874],
//             [126.92000001316386, 37.649999985711396]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081403',
//       type: 'Feature',
//       properties: {
//         fid: 398004,
//         MAPIDCD_NO: '376081403',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500002925102, 37.650000002149874],
//             [126.91499994841296, 37.644999995626854],
//             [126.90999995877021, 37.644999981503737],
//             [126.9100000444473, 37.649999988709688],
//             [126.91500002925102, 37.650000002149874]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081402',
//       type: 'Feature',
//       properties: {
//         fid: 398007,
//         MAPIDCD_NO: '376081402',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9100000444473, 37.649999988709688],
//             [126.90999995877021, 37.644999981503737],
//             [126.90499996981556, 37.645000025849157],
//             [126.90499994698931, 37.650000031354324],
//             [126.9100000444473, 37.649999988709688]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081401',
//       type: 'Feature',
//       properties: {
//         fid: 398009,
//         MAPIDCD_NO: '376081401',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499994698931, 37.650000031354324],
//             [126.90499996981556, 37.645000025849157],
//             [126.89999998227212, 37.645000038533155],
//             [126.89999996428344, 37.650000044697713],
//             [126.90499994698931, 37.650000031354324]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081310',
//       type: 'Feature',
//       properties: {
//         fid: 398011,
//         MAPIDCD_NO: '376081310',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999996428344, 37.650000044697713],
//             [126.89999998227212, 37.645000038533155],
//             [126.89499999388893, 37.645000019543829],
//             [126.89499998075158, 37.650000024555816],
//             [126.89999996428344, 37.650000044697713]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081309',
//       type: 'Feature',
//       properties: {
//         fid: 398013,
//         MAPIDCD_NO: '376081309',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499998075158, 37.650000024555816],
//             [126.89499999388893, 37.645000019543829],
//             [126.8900000069639, 37.644999967089717],
//             [126.88999999866269, 37.6499999727425],
//             [126.89499998075158, 37.650000024555816]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081308',
//       type: 'Feature',
//       properties: {
//         fid: 398016,
//         MAPIDCD_NO: '376081308',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88999999866269, 37.6499999727425],
//             [126.8900000069639, 37.644999967089717],
//             [126.88500001847373, 37.644999974893267],
//             [126.88500001500756, 37.649999981177345],
//             [126.88999999866269, 37.6499999727425]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081307',
//       type: 'Feature',
//       properties: {
//         fid: 398018,
//         MAPIDCD_NO: '376081307',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500001500756, 37.649999981177345],
//             [126.88500001847373, 37.644999974893267],
//             [126.88000003069797, 37.64500004116293],
//             [126.88000003283024, 37.649999956137272],
//             [126.88500001500756, 37.649999981177345]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081306',
//       type: 'Feature',
//       properties: {
//         fid: 398021,
//         MAPIDCD_NO: '376081306',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000003283024, 37.649999956137272],
//             [126.88000003069797, 37.64500004116293],
//             [126.87500004289323, 37.644999983825478],
//             [126.87500004910972, 37.649999989541563],
//             [126.88000003283024, 37.649999956137272]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081305',
//       type: 'Feature',
//       properties: {
//         fid: 398023,
//         MAPIDCD_NO: '376081305',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500004910972, 37.649999989541563],
//             [126.87500004289323, 37.644999983825478],
//             [126.87000005580809, 37.644999986755842],
//             [126.86999995350205, 37.649999992469823],
//             [126.87500004910972, 37.649999989541563]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081304',
//       type: 'Feature',
//       properties: {
//         fid: 398025,
//         MAPIDCD_NO: '376081304',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999995350205, 37.649999992469823],
//             [126.87000005580809, 37.644999986755842],
//             [126.86499995687547, 37.644999957411933],
//             [126.86499997276417, 37.649999962522593],
//             [126.86999995350205, 37.649999992469823]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081303',
//       type: 'Feature',
//       properties: {
//         fid: 398028,
//         MAPIDCD_NO: '376081303',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499997276417, 37.649999962522593],
//             [126.86499995687547, 37.644999957411933],
//             [126.85999996976615, 37.644999987125409],
//             [126.85999999050124, 37.649999991018021],
//             [126.86499997276417, 37.649999962522593]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081302',
//       type: 'Feature',
//       properties: {
//         fid: 398030,
//         MAPIDCD_NO: '376081302',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999999050124, 37.649999991018021],
//             [126.85999996976615, 37.644999987125409],
//             [126.85499998419256, 37.644999983371221],
//             [126.85500000749076, 37.649999987826639],
//             [126.85999999050124, 37.649999991018021]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081301',
//       type: 'Feature',
//       properties: {
//         fid: 398032,
//         MAPIDCD_NO: '376081301',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500000749076, 37.649999987826639],
//             [126.85499998419256, 37.644999983371221],
//             [126.84999999708737, 37.645000039870411],
//             [126.85000002749754, 37.650000043101436],
//             [126.85500000749076, 37.649999987826639]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081210',
//       type: 'Feature',
//       properties: {
//         fid: 398034,
//         MAPIDCD_NO: '376081210',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000002749754, 37.650000043101436],
//             [126.84999999708737, 37.645000039870411],
//             [126.84500001232979, 37.64499997277246],
//             [126.84500004756858, 37.649999976560039],
//             [126.85000002749754, 37.650000043101436]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081209',
//       type: 'Feature',
//       properties: {
//         fid: 398036,
//         MAPIDCD_NO: '376081209',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500004756858, 37.649999976560039],
//             [126.84500001232979, 37.64499997277246],
//             [126.84000002606055, 37.644999965926793],
//             [126.8399999527884, 37.649999967824733],
//             [126.84500004756858, 37.649999976560039]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081208',
//       type: 'Feature',
//       properties: {
//         fid: 398038,
//         MAPIDCD_NO: '376081208',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8399999527884, 37.649999967824733],
//             [126.84000002606055, 37.644999965926793],
//             [126.83500004284336, 37.645000015752927],
//             [126.83499997213112, 37.650000018171042],
//             [126.8399999527884, 37.649999967824733]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081207',
//       type: 'Feature',
//       properties: {
//         fid: 398041,
//         MAPIDCD_NO: '376081207',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499997213112, 37.650000018171042],
//             [126.83500004284336, 37.645000015752927],
//             [126.83000005666919, 37.645000033886355],
//             [126.82999999304995, 37.650000036840808],
//             [126.83499997213112, 37.650000018171042]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081206',
//       type: 'Feature',
//       properties: {
//         fid: 398044,
//         MAPIDCD_NO: '376081206',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999999304995, 37.650000036840808],
//             [126.83000005666919, 37.645000033886355],
//             [126.82499995872516, 37.645000021506092],
//             [126.82500001329387, 37.6500000238208],
//             [126.82999999304995, 37.650000036840808]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081205',
//       type: 'Feature',
//       properties: {
//         fid: 398046,
//         MAPIDCD_NO: '376081205',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500001329387, 37.6500000238208],
//             [126.82499995872516, 37.645000021506092],
//             [126.81999997575311, 37.644999976299033],
//             [126.82000003287905, 37.649999979110412],
//             [126.82500001329387, 37.6500000238208]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081204',
//       type: 'Feature',
//       properties: {
//         fid: 398049,
//         MAPIDCD_NO: '376081204',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000003287905, 37.649999979110412],
//             [126.81999997575311, 37.644999976299033],
//             [126.81499999129515, 37.644999991341116],
//             [126.81500005326083, 37.649999992850191],
//             [126.82000003287905, 37.649999979110412]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081203',
//       type: 'Feature',
//       properties: {
//         fid: 398051,
//         MAPIDCD_NO: '376081203',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500005326083, 37.649999992850191],
//             [126.81499999129515, 37.644999991341116],
//             [126.81000000848295, 37.644999972911741],
//             [126.80999996191738, 37.649999974248715],
//             [126.81500005326083, 37.649999992850191]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081202',
//       type: 'Feature',
//       properties: {
//         fid: 398053,
//         MAPIDCD_NO: '376081202',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999996191738, 37.649999974248715],
//             [126.81000000848295, 37.644999972911741],
//             [126.80500002420952, 37.6450000129276],
//             [126.80499998473255, 37.650000014754617],
//             [126.80999996191738, 37.649999974248715]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081201',
//       type: 'Feature',
//       properties: {
//         fid: 398055,
//         MAPIDCD_NO: '376081201',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499998473255, 37.650000014754617],
//             [126.80500002420952, 37.6450000129276],
//             [126.80000004156874, 37.645000023076143],
//             [126.80000000692928, 37.650000023568076],
//             [126.80499998473255, 37.650000014754617]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081110',
//       type: 'Feature',
//       properties: {
//         fid: 398058,
//         MAPIDCD_NO: '376081110',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000000692928, 37.650000023568076],
//             [126.80000004156874, 37.645000023076143],
//             [126.79499994726454, 37.644999999074578],
//             [126.7950000285409, 37.649999998885917],
//             [126.80000000692928, 37.650000023568076]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081109',
//       type: 'Feature',
//       properties: {
//         fid: 398061,
//         MAPIDCD_NO: '376081109',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7950000285409, 37.649999998885917],
//             [126.79499994726454, 37.644999999074578],
//             [126.78999996486327, 37.645000034188975],
//             [126.79000005095841, 37.650000034453761],
//             [126.7950000285409, 37.649999998885917]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081108',
//       type: 'Feature',
//       properties: {
//         fid: 398063,
//         MAPIDCD_NO: '376081108',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000005095841, 37.650000034453761],
//             [126.78999996486327, 37.645000034188975],
//             [126.78499998414783, 37.645000037632215],
//             [126.7849999617078, 37.650000037654074],
//             [126.79000005095841, 37.650000034453761]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081107',
//       type: 'Feature',
//       properties: {
//         fid: 398065,
//         MAPIDCD_NO: '376081107',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7849999617078, 37.650000037654074],
//             [126.78499998414783, 37.645000037632215],
//             [126.78000000285009, 37.645000011192693],
//             [126.7799999852446, 37.65000000984196],
//             [126.7849999617078, 37.650000037654074]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081106',
//       type: 'Feature',
//       properties: {
//         fid: 398067,
//         MAPIDCD_NO: '376081106',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7799999852446, 37.65000000984196],
//             [126.78000000285009, 37.645000011192693],
//             [126.77500002241166, 37.645000041405872],
//             [126.77500000964001, 37.650000038673205],
//             [126.7799999852446, 37.65000000984196]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081105',
//       type: 'Feature',
//       properties: {
//         fid: 398071,
//         MAPIDCD_NO: '376081105',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500000964001, 37.650000038673205],
//             [126.77500002241166, 37.645000041405872],
//             [126.77000004142859, 37.64500003993259],
//             [126.77000003347226, 37.65000003761115],
//             [126.77500000964001, 37.650000038673205]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081104',
//       type: 'Feature',
//       properties: {
//         fid: 398073,
//         MAPIDCD_NO: '376081104',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000003347226, 37.65000003761115],
//             [126.77000004142859, 37.64500003993259],
//             [126.76499994883869, 37.64500000608016],
//             [126.76499994568928, 37.650000004160553],
//             [126.77000003347226, 37.65000003761115]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081103',
//       type: 'Feature',
//       properties: {
//         fid: 398075,
//         MAPIDCD_NO: '376081103',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76499994568928, 37.650000004160553],
//             [126.76499994883869, 37.64500000608016],
//             [126.75999997045436, 37.645000031382764],
//             [126.75999997213631, 37.650000028053114],
//             [126.76499994568928, 37.650000004160553]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081102',
//       type: 'Feature',
//       properties: {
//         fid: 398078,
//         MAPIDCD_NO: '376081102',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999997213631, 37.650000028053114],
//             [126.75999997045436, 37.645000031382764],
//             [126.75499999156156, 37.645000024997252],
//             [126.75499999805615, 37.65000002205079],
//             [126.75999997213631, 37.650000028053114]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081101',
//       type: 'Feature',
//       properties: {
//         fid: 398080,
//         MAPIDCD_NO: '376081101',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75499999805615, 37.65000002205079],
//             [126.75499999156156, 37.645000024997252],
//             [126.75000001217654, 37.644999986923061],
//             [126.75000002348273, 37.649999984350437],
//             [126.75499999805615, 37.65000002205079]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071510',
//       type: 'Feature',
//       properties: {
//         fid: 398083,
//         MAPIDCD_NO: '376071510',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75000002348273, 37.649999984350437],
//             [126.75000001217654, 37.644999986923061],
//             [126.74500003593694, 37.645000007315147],
//             [126.74500005207204, 37.650000003304449],
//             [126.75000002348273, 37.649999984350437]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071509',
//       type: 'Feature',
//       properties: {
//         fid: 398085,
//         MAPIDCD_NO: '376071509',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74500005207204, 37.650000003304449],
//             [126.74500003593694, 37.645000007315147],
//             [126.73999994586247, 37.644999997089869],
//             [126.73999996455109, 37.649999991617065],
//             [126.74500005207204, 37.650000003304449]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071508',
//       type: 'Feature',
//       properties: {
//         fid: 398087,
//         MAPIDCD_NO: '376071508',
//         MAPID_NM: '김포',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73999996455109, 37.649999991617065],
//             [126.73999994586247, 37.644999997089869],
//             [126.734999967789, 37.645000042420975],
//             [126.73499999355346, 37.65000003730848],
//             [126.73999996455109, 37.649999991617065]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071507',
//       type: 'Feature',
//       properties: {
//         fid: 398090,
//         MAPIDCD_NO: '376071507',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73499999355346, 37.65000003730848],
//             [126.734999967789, 37.645000042420975],
//             [126.7299999924426, 37.644999967751922],
//             [126.73000002301505, 37.6499999629759],
//             [126.73499999355346, 37.65000003730848]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071506',
//       type: 'Feature',
//       properties: {
//         fid: 398094,
//         MAPIDCD_NO: '376071506',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73000002301505, 37.6499999629759],
//             [126.7299999924426, 37.644999967751922],
//             [126.72500001706656, 37.645000041658818],
//             [126.72500005019754, 37.650000035392289],
//             [126.73000002301505, 37.6499999629759]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071505',
//       type: 'Feature',
//       properties: {
//         fid: 398096,
//         MAPIDCD_NO: '376071505',
//         MAPID_NM: '김포',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.72500005019754, 37.650000035392289],
//             [126.72500001706656, 37.645000041658818],
//             [126.72000003993449, 37.64499999373237],
//             [126.71999996678687, 37.649999987048993],
//             [126.72500005019754, 37.650000035392289]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081097',
//       type: 'Feature',
//       properties: {
//         fid: 398521,
//         MAPIDCD_NO: '376081097',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98499994457984, 37.65500003302558],
//             [126.98500004438097, 37.65000002913397],
//             [126.97999994475759, 37.65000000796271],
//             [126.97999996540601, 37.655000011371],
//             [126.98499994457984, 37.65500003302558]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081096',
//       type: 'Feature',
//       properties: {
//         fid: 398523,
//         MAPIDCD_NO: '376081096',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97999996540601, 37.655000011371],
//             [126.97999994475759, 37.65000000796271],
//             [126.97499995973261, 37.649999955621134],
//             [126.97499998292545, 37.654999959824558],
//             [126.97999996540601, 37.655000011371]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081095',
//       type: 'Feature',
//       properties: {
//         fid: 398525,
//         MAPIDCD_NO: '376081095',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97499998292545, 37.654999959824558],
//             [126.97499995973261, 37.649999955621134],
//             [126.96999997532416, 37.6499999617447],
//             [126.97000000334069, 37.654999964941219],
//             [126.97499998292545, 37.654999959824558]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081094',
//       type: 'Feature',
//       properties: {
//         fid: 398527,
//         MAPIDCD_NO: '376081094',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97000000334069, 37.654999964941219],
//             [126.96999997532416, 37.6499999617447],
//             [126.964999989276, 37.650000024520025],
//             [126.96500002210263, 37.655000028502862],
//             [126.97000000334069, 37.654999964941219]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081093',
//       type: 'Feature',
//       properties: {
//         fid: 398529,
//         MAPIDCD_NO: '376081093',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96500002210263, 37.655000028502862],
//             [126.964999989276, 37.650000024520025],
//             [126.96000000513284, 37.649999967301291],
//             [126.96000004276891, 37.654999972061191],
//             [126.96500002210263, 37.655000028502862]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081092',
//       type: 'Feature',
//       properties: {
//         fid: 398531,
//         MAPIDCD_NO: '376081092',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96000004276891, 37.654999972061191],
//             [126.96000000513284, 37.649999967301291],
//             [126.95500001935723, 37.649999968535965],
//             [126.95499994845052, 37.654999971737169],
//             [126.96000004276891, 37.654999972061191]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081091',
//       type: 'Feature',
//       properties: {
//         fid: 398533,
//         MAPIDCD_NO: '376081091',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95499994845052, 37.654999971737169],
//             [126.95500001935723, 37.649999968535965],
//             [126.95000003422761, 37.650000026431272],
//             [126.94999996812885, 37.655000030385935],
//             [126.95499994845052, 37.654999971737169]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080998',
//       type: 'Feature',
//       properties: {
//         fid: 398539,
//         MAPIDCD_NO: '376080998',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94000000754694, 37.654999962501734],
//             [126.9399999506485, 37.649999960135133],
//             [126.93499996651468, 37.6500000149445],
//             [126.93500002821881, 37.655000018041306],
//             [126.94000000754694, 37.654999962501734]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080997',
//       type: 'Feature',
//       properties: {
//         fid: 398541,
//         MAPIDCD_NO: '376080997',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93500002821881, 37.655000018041306],
//             [126.93499996651468, 37.6500000149445],
//             [126.92999998146524, 37.650000036271905],
//             [126.93000004797423, 37.65500004008959],
//             [126.93500002821881, 37.655000018041306]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080996',
//       type: 'Feature',
//       properties: {
//         fid: 398543,
//         MAPIDCD_NO: '376080996',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93000004797423, 37.65500004008959],
//             [126.92999998146524, 37.650000036271905],
//             [126.9249999977561, 37.650000027733064],
//             [126.92499995571914, 37.655000029907114],
//             [126.93000004797423, 37.65500004008959]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080995',
//       type: 'Feature',
//       properties: {
//         fid: 398545,
//         MAPIDCD_NO: '376080995',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499995571914, 37.655000029907114],
//             [126.9249999977561, 37.650000027733064],
//             [126.92000001316386, 37.649999985711396],
//             [126.91999997593037, 37.654999988582894],
//             [126.92499995571914, 37.655000029907114]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080994',
//       type: 'Feature',
//       properties: {
//         fid: 398547,
//         MAPIDCD_NO: '376080994',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999997593037, 37.654999988582894],
//             [126.92000001316386, 37.649999985711396],
//             [126.91500002925102, 37.650000002149874],
//             [126.91499999682013, 37.6550000057094],
//             [126.91999997593037, 37.654999988582894]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080993',
//       type: 'Feature',
//       properties: {
//         fid: 398549,
//         MAPIDCD_NO: '376080993',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499999682013, 37.6550000057094],
//             [126.91500002925102, 37.650000002149874],
//             [126.9100000444473, 37.649999988709688],
//             [126.91000001683236, 37.654999991145324],
//             [126.91499999682013, 37.6550000057094]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080992',
//       type: 'Feature',
//       properties: {
//         fid: 398552,
//         MAPIDCD_NO: '376080992',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000001683236, 37.654999991145324],
//             [126.9100000444473, 37.649999988709688],
//             [126.90499994698931, 37.650000031354324],
//             [126.9050000375312, 37.655000035031009],
//             [126.91000001683236, 37.654999991145324]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080991',
//       type: 'Feature',
//       properties: {
//         fid: 398554,
//         MAPIDCD_NO: '376080991',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9050000375312, 37.655000035031009],
//             [126.90499994698931, 37.650000031354324],
//             [126.89999996428344, 37.650000044697713],
//             [126.89999994473452, 37.65499995651907],
//             [126.9050000375312, 37.655000035031009]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080800',
//       type: 'Feature',
//       properties: {
//         fid: 398556,
//         MAPIDCD_NO: '376080800',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999994473452, 37.65499995651907],
//             [126.89999996428344, 37.650000044697713],
//             [126.89499998075158, 37.650000024555816],
//             [126.89499996754417, 37.655000027168732],
//             [126.89999994473452, 37.65499995651907]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080899',
//       type: 'Feature',
//       properties: {
//         fid: 398558,
//         MAPIDCD_NO: '376080899',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499996754417, 37.655000027168732],
//             [126.89499998075158, 37.650000024555816],
//             [126.88999999866269, 37.6499999727425],
//             [126.88999998798668, 37.654999975985007],
//             [126.89499996754417, 37.655000027168732]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080898',
//       type: 'Feature',
//       properties: {
//         fid: 398561,
//         MAPIDCD_NO: '376080898',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88999998798668, 37.654999975985007],
//             [126.88999999866269, 37.6499999727425],
//             [126.88500001500756, 37.649999981177345],
//             [126.88500000915894, 37.654999981446508],
//             [126.88999998798668, 37.654999975985007]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080897',
//       type: 'Feature',
//       properties: {
//         fid: 398563,
//         MAPIDCD_NO: '376080897',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500000915894, 37.654999981446508],
//             [126.88500001500756, 37.649999981177345],
//             [126.88000003283024, 37.649999956137272],
//             [126.88000003177881, 37.654999957028934],
//             [126.88500000915894, 37.654999981446508]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080896',
//       type: 'Feature',
//       properties: {
//         fid: 398566,
//         MAPIDCD_NO: '376080896',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000003177881, 37.654999957028934],
//             [126.88000003283024, 37.649999956137272],
//             [126.87500004910972, 37.649999989541563],
//             [126.87500005512163, 37.654999991058332],
//             [126.88000003177881, 37.654999957028934]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080895',
//       type: 'Feature',
//       properties: {
//         fid: 398568,
//         MAPIDCD_NO: '376080895',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500005512163, 37.654999991058332],
//             [126.87500004910972, 37.649999989541563],
//             [126.86999995350205, 37.649999992469823],
//             [126.86999996431689, 37.65499999278785],
//             [126.87500005512163, 37.654999991058332]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080894',
//       type: 'Feature',
//       properties: {
//         fid: 398570,
//         MAPIDCD_NO: '376080894',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999996431689, 37.65499999278785],
//             [126.86999995350205, 37.649999992469823],
//             [126.86499997276417, 37.649999962522593],
//             [126.86499998612179, 37.654999961620355],
//             [126.86999996431689, 37.65499999278785]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080893',
//       type: 'Feature',
//       properties: {
//         fid: 398573,
//         MAPIDCD_NO: '376080893',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499998612179, 37.654999961620355],
//             [126.86499997276417, 37.649999962522593],
//             [126.85999999050124, 37.649999991018021],
//             [126.86000000865263, 37.65499999070083],
//             [126.86499998612179, 37.654999961620355]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080892',
//       type: 'Feature',
//       properties: {
//         fid: 398575,
//         MAPIDCD_NO: '376080892',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000000865263, 37.65499999070083],
//             [126.85999999050124, 37.649999991018021],
//             [126.85500000749076, 37.649999987826639],
//             [126.85500003270224, 37.654999988097565],
//             [126.86000000865263, 37.65499999070083]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080891',
//       type: 'Feature',
//       properties: {
//         fid: 398577,
//         MAPIDCD_NO: '376080891',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500003270224, 37.654999988097565],
//             [126.85500000749076, 37.649999987826639],
//             [126.85000002749754, 37.650000043101436],
//             [126.85000005524954, 37.655000042123724],
//             [126.85500003270224, 37.654999988097565]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080700',
//       type: 'Feature',
//       properties: {
//         fid: 398579,
//         MAPIDCD_NO: '376080700',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000005524954, 37.655000042123724],
//             [126.85000002749754, 37.650000043101436],
//             [126.84500004756858, 37.649999976560039],
//             [126.84499996674974, 37.654999975509924],
//             [126.85000005524954, 37.655000042123724]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080799',
//       type: 'Feature',
//       properties: {
//         fid: 398581,
//         MAPIDCD_NO: '376080799',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499996674974, 37.654999975509924],
//             [126.84500004756858, 37.649999976560039],
//             [126.8399999527884, 37.649999967824733],
//             [126.83999999011442, 37.654999967951689],
//             [126.84499996674974, 37.654999975509924]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080798',
//       type: 'Feature',
//       properties: {
//         fid: 398583,
//         MAPIDCD_NO: '376080798',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999999011442, 37.654999967951689],
//             [126.8399999527884, 37.649999967824733],
//             [126.83499997213112, 37.650000018171042],
//             [126.83500001426262, 37.655000017033686],
//             [126.83999999011442, 37.654999967951689]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080797',
//       type: 'Feature',
//       properties: {
//         fid: 398586,
//         MAPIDCD_NO: '376080797',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500001426262, 37.655000017033686],
//             [126.83499997213112, 37.650000018171042],
//             [126.82999999304995, 37.650000036840808],
//             [126.8300000399701, 37.655000036232387],
//             [126.83500001426262, 37.655000017033686]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080796',
//       type: 'Feature',
//       properties: {
//         fid: 398589,
//         MAPIDCD_NO: '376080796',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8300000399701, 37.655000036232387],
//             [126.82999999304995, 37.650000036840808],
//             [126.82500001329387, 37.6500000238208],
//             [126.82499995165631, 37.655000021280756],
//             [126.8300000399701, 37.655000036232387]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080795',
//       type: 'Feature',
//       properties: {
//         fid: 398591,
//         MAPIDCD_NO: '376080795',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499995165631, 37.655000021280756],
//             [126.82500001329387, 37.6500000238208],
//             [126.82000003287905, 37.649999979110412],
//             [126.81999997602848, 37.654999977075875],
//             [126.82499995165631, 37.655000021280756]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080794',
//       type: 'Feature',
//       properties: {
//         fid: 398594,
//         MAPIDCD_NO: '376080794',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999997602848, 37.654999977075875],
//             [126.82000003287905, 37.649999979110412],
//             [126.81500005326083, 37.649999992850191],
//             [126.81500000121287, 37.654999989509157],
//             [126.81999997602848, 37.654999977075875]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080793',
//       type: 'Feature',
//       properties: {
//         fid: 398596,
//         MAPIDCD_NO: '376080793',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500000121287, 37.654999989509157],
//             [126.81500005326083, 37.649999992850191],
//             [126.80999996191738, 37.649999974248715],
//             [126.81000002800855, 37.654999972057439],
//             [126.81500000121287, 37.654999989509157]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080792',
//       type: 'Feature',
//       properties: {
//         fid: 398598,
//         MAPIDCD_NO: '376080792',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000002800855, 37.654999972057439],
//             [126.80999996191738, 37.649999974248715],
//             [126.80499998473255, 37.650000014754617],
//             [126.80500005562475, 37.655000011242961],
//             [126.81000002800855, 37.654999972057439]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080791',
//       type: 'Feature',
//       properties: {
//         fid: 398600,
//         MAPIDCD_NO: '376080791',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500005562475, 37.655000011242961],
//             [126.80499998473255, 37.650000014754617],
//             [126.80000000692928, 37.650000023568076],
//             [126.79999996926068, 37.655000018053975],
//             [126.80500005562475, 37.655000011242961]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080600',
//       type: 'Feature',
//       properties: {
//         fid: 398603,
//         MAPIDCD_NO: '376080600',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999996926068, 37.655000018053975],
//             [126.80000000692928, 37.650000023568076],
//             [126.7950000285409, 37.649999998885917],
//             [126.79499999563788, 37.654999995633062],
//             [126.79999996926068, 37.655000018053975]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080699',
//       type: 'Feature',
//       properties: {
//         fid: 398606,
//         MAPIDCD_NO: '376080699',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499999563788, 37.654999995633062],
//             [126.7950000285409, 37.649999998885917],
//             [126.79000005095841, 37.650000034453761],
//             [126.79000002285417, 37.655000029847656],
//             [126.79499999563788, 37.654999995633062]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080698',
//       type: 'Feature',
//       properties: {
//         fid: 398608,
//         MAPIDCD_NO: '376080698',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000002285417, 37.655000029847656],
//             [126.79000005095841, 37.650000034453761],
//             [126.7849999617078, 37.650000037654074],
//             [126.78500005175488, 37.655000032372421],
//             [126.79000002285417, 37.655000029847656]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080697',
//       type: 'Feature',
//       properties: {
//         fid: 398610,
//         MAPIDCD_NO: '376080697',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500005175488, 37.655000032372421],
//             [126.7849999617078, 37.650000037654074],
//             [126.7799999852446, 37.65000000984196],
//             [126.77999996671085, 37.655000004303851],
//             [126.78500005175488, 37.655000032372421]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080696',
//       type: 'Feature',
//       properties: {
//         fid: 398612,
//         MAPIDCD_NO: '376080696',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77999996671085, 37.655000004303851],
//             [126.7799999852446, 37.65000000984196],
//             [126.77500000964001, 37.650000038673205],
//             [126.77499999588518, 37.65500003355632],
//             [126.77999996671085, 37.655000004303851]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080695',
//       type: 'Feature',
//       properties: {
//         fid: 398616,
//         MAPIDCD_NO: '376080695',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77499999588518, 37.65500003355632],
//             [126.77500000964001, 37.650000038673205],
//             [126.77000003347226, 37.65000003761115],
//             [126.77000002451307, 37.655000031103654],
//             [126.77499999588518, 37.65500003355632]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080694',
//       type: 'Feature',
//       properties: {
//         fid: 398618,
//         MAPIDCD_NO: '376080694',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000002451307, 37.655000031103654],
//             [126.77000003347226, 37.65000003761115],
//             [126.76499994568928, 37.650000004160553],
//             [126.7650000548778, 37.654999996959361],
//             [126.77000002451307, 37.655000031103654]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080693',
//       type: 'Feature',
//       properties: {
//         fid: 398620,
//         MAPIDCD_NO: '376080693',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7650000548778, 37.654999996959361],
//             [126.76499994568928, 37.650000004160553],
//             [126.75999997213631, 37.650000028053114],
//             [126.75999997045578, 37.655000022327087],
//             [126.7650000548778, 37.654999996959361]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080692',
//       type: 'Feature',
//       properties: {
//         fid: 398623,
//         MAPIDCD_NO: '376080692',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999997045578, 37.655000022327087],
//             [126.75999997213631, 37.650000028053114],
//             [126.75499999805615, 37.65000002205079],
//             [126.75500000116884, 37.65500001490598],
//             [126.75999997045578, 37.655000022327087]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080691',
//       type: 'Feature',
//       properties: {
//         fid: 398625,
//         MAPIDCD_NO: '376080691',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75500000116884, 37.65500001490598],
//             [126.75499999805615, 37.65000002205079],
//             [126.75000002348273, 37.649999984350437],
//             [126.75000003365508, 37.654999975791945],
//             [126.75500000116884, 37.65500001490598]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071000',
//       type: 'Feature',
//       properties: {
//         fid: 398628,
//         MAPIDCD_NO: '376071000',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75000003365508, 37.654999975791945],
//             [126.75000002348273, 37.649999984350437],
//             [126.74500005207204, 37.650000003304449],
//             [126.74499995139102, 37.654999994371025],
//             [126.75000003365508, 37.654999975791945]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071099',
//       type: 'Feature',
//       properties: {
//         fid: 398630,
//         MAPIDCD_NO: '376071099',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74499995139102, 37.654999994371025],
//             [126.74500005207204, 37.650000003304449],
//             [126.73999996455109, 37.649999991617065],
//             [126.73999998428015, 37.654999981976893],
//             [126.74499995139102, 37.654999994371025]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071098',
//       type: 'Feature',
//       properties: {
//         fid: 398632,
//         MAPIDCD_NO: '376071098',
//         MAPID_NM: '김포',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73999998428015, 37.654999981976893],
//             [126.73999996455109, 37.649999991617065],
//             [126.73499999355346, 37.65000003730848],
//             [126.73500001578691, 37.655000028],
//             [126.73999998428015, 37.654999981976893]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071097',
//       type: 'Feature',
//       properties: {
//         fid: 398635,
//         MAPIDCD_NO: '376071097',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73500001578691, 37.655000028],
//             [126.73499999355346, 37.65000003730848],
//             [126.73000002301505, 37.6499999629759],
//             [126.73000004910713, 37.655000042328084],
//             [126.73500001578691, 37.655000028]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071096',
//       type: 'Feature',
//       properties: {
//         fid: 398639,
//         MAPIDCD_NO: '376071096',
//         MAPID_NM: '김포',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73000004910713, 37.655000042328084],
//             [126.73000002301505, 37.6499999629759],
//             [126.72500005019754, 37.650000035392289],
//             [126.72499997089778, 37.655000024216058],
//             [126.73000004910713, 37.655000042328084]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081088',
//       type: 'Feature',
//       properties: {
//         fid: 398989,
//         MAPIDCD_NO: '376081088',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99000004777462, 37.66000002104397],
//             [126.99000003835546, 37.655000021693574],
//             [126.98499994457984, 37.65500003302558],
//             [126.98499995876718, 37.660000033200212],
//             [126.99000004777462, 37.66000002104397]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081087',
//       type: 'Feature',
//       properties: {
//         fid: 398991,
//         MAPIDCD_NO: '376081087',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98499995876718, 37.660000033200212],
//             [126.98499994457984, 37.65500003302558],
//             [126.97999996540601, 37.655000011371],
//             [126.97999998210108, 37.660000012350608],
//             [126.98499995876718, 37.660000033200212]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081086',
//       type: 'Feature',
//       properties: {
//         fid: 398993,
//         MAPIDCD_NO: '376081086',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97999998210108, 37.660000012350608],
//             [126.97999996540601, 37.655000011371],
//             [126.97499998292545, 37.654999959824558],
//             [126.97500000668745, 37.659999958014588],
//             [126.97999998210108, 37.660000012350608]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081085',
//       type: 'Feature',
//       properties: {
//         fid: 398995,
//         MAPIDCD_NO: '376081085',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97500000668745, 37.659999958014588],
//             [126.97499998292545, 37.654999959824558],
//             [126.97000000334069, 37.654999964941219],
//             [126.97000003187642, 37.6599999639275],
//             [126.97500000668745, 37.659999958014588]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081084',
//       type: 'Feature',
//       properties: {
//         fid: 398997,
//         MAPIDCD_NO: '376081084',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97000003187642, 37.6599999639275],
//             [126.97000000334069, 37.654999964941219],
//             [126.96500002210263, 37.655000028502862],
//             [126.96500005541112, 37.660000028275995],
//             [126.97000003187642, 37.6599999639275]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081083',
//       type: 'Feature',
//       properties: {
//         fid: 398999,
//         MAPIDCD_NO: '376081083',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96500005541112, 37.660000028275995],
//             [126.96500002210263, 37.655000028502862],
//             [126.96000004276891, 37.654999972061191],
//             [126.95999996522309, 37.659999970279955],
//             [126.96500005541112, 37.660000028275995]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081082',
//       type: 'Feature',
//       properties: {
//         fid: 399001,
//         MAPIDCD_NO: '376081082',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95999996522309, 37.659999970279955],
//             [126.96000004276891, 37.654999972061191],
//             [126.95499994845052, 37.654999971737169],
//             [126.9549999913081, 37.65999997125347],
//             [126.95999996522309, 37.659999970279955]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081081',
//       type: 'Feature',
//       properties: {
//         fid: 399003,
//         MAPIDCD_NO: '376081081',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9549999913081, 37.65999997125347],
//             [126.95499994845052, 37.654999971737169],
//             [126.94999996812885, 37.655000030385935],
//             [126.95000001575694, 37.660000030661067],
//             [126.9549999913081, 37.65999997125347]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080990',
//       type: 'Feature',
//       properties: {
//         fid: 399005,
//         MAPIDCD_NO: '376080990',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95000001575694, 37.660000030661067],
//             [126.94999996812885, 37.655000030385935],
//             [126.94499998751174, 37.654999967217321],
//             [126.9450000399231, 37.659999966439393],
//             [126.95000001575694, 37.660000030661067]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080989',
//       type: 'Feature',
//       properties: {
//         fid: 399007,
//         MAPIDCD_NO: '376080989',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9450000399231, 37.659999966439393],
//             [126.94499998751174, 37.654999967217321],
//             [126.94000000754694, 37.654999962501734],
//             [126.93999995135562, 37.659999961925728],
//             [126.9450000399231, 37.659999966439393]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080988',
//       type: 'Feature',
//       properties: {
//         fid: 399009,
//         MAPIDCD_NO: '376080988',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93999995135562, 37.659999961925728],
//             [126.94000000754694, 37.654999962501734],
//             [126.93500002821881, 37.655000018041306],
//             [126.93499997454207, 37.660000016377815],
//             [126.93999995135562, 37.659999961925728]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080987',
//       type: 'Feature',
//       properties: {
//         fid: 399011,
//         MAPIDCD_NO: '376080987',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93499997454207, 37.660000016377815],
//             [126.93500002821881, 37.655000018041306],
//             [126.93000004797423, 37.65500004008959],
//             [126.9299999990789, 37.660000037340069],
//             [126.93499997454207, 37.660000016377815]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080986',
//       type: 'Feature',
//       properties: {
//         fid: 399013,
//         MAPIDCD_NO: '376080986',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9299999990789, 37.660000037340069],
//             [126.93000004797423, 37.65500004008959],
//             [126.92499995571914, 37.655000029907114],
//             [126.92500002495478, 37.660000028417429],
//             [126.9299999990789, 37.660000037340069]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080985',
//       type: 'Feature',
//       properties: {
//         fid: 399015,
//         MAPIDCD_NO: '376080985',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92500002495478, 37.660000028417429],
//             [126.92499995571914, 37.655000029907114],
//             [126.91999997593037, 37.654999988582894],
//             [126.92000004993206, 37.6599999877959],
//             [126.92500002495478, 37.660000028417429]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080984',
//       type: 'Feature',
//       properties: {
//         fid: 399017,
//         MAPIDCD_NO: '376080984',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000004993206, 37.6599999877959],
//             [126.91999997593037, 37.654999988582894],
//             [126.91499999682013, 37.6550000057094],
//             [126.91499996222984, 37.660000003250872],
//             [126.92000004993206, 37.6599999877959]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080983',
//       type: 'Feature',
//       properties: {
//         fid: 399019,
//         MAPIDCD_NO: '376080983',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499996222984, 37.660000003250872],
//             [126.91499999682013, 37.6550000057094],
//             [126.91000001683236, 37.654999991145324],
//             [126.90999998700661, 37.659999989365986],
//             [126.91499996222984, 37.660000003250872]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080982',
//       type: 'Feature',
//       properties: {
//         fid: 399022,
//         MAPIDCD_NO: '376080982',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999998700661, 37.659999989365986],
//             [126.91000001683236, 37.654999991145324],
//             [126.9050000375312, 37.655000035031009],
//             [126.90500001248354, 37.660000032118845],
//             [126.90999998700661, 37.659999989365986]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080981',
//       type: 'Feature',
//       properties: {
//         fid: 399024,
//         MAPIDCD_NO: '376080981',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500001248354, 37.660000032118845],
//             [126.9050000375312, 37.655000035031009],
//             [126.89999994473452, 37.65499995651907],
//             [126.90000003935533, 37.660000044984876],
//             [126.90500001248354, 37.660000032118845]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080890',
//       type: 'Feature',
//       properties: {
//         fid: 399026,
//         MAPIDCD_NO: '376080890',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000003935533, 37.660000044984876],
//             [126.89999994473452, 37.65499995651907],
//             [126.89499996754417, 37.655000027168732],
//             [126.89499995202878, 37.660000023765541],
//             [126.90000003935533, 37.660000044984876]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080889',
//       type: 'Feature',
//       properties: {
//         fid: 399028,
//         MAPIDCD_NO: '376080889',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499995202878, 37.660000023765541],
//             [126.89499996754417, 37.655000027168732],
//             [126.88999998798668, 37.654999975985007],
//             [126.88999997723266, 37.659999973223641],
//             [126.89499995202878, 37.660000023765541]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080888',
//       type: 'Feature',
//       properties: {
//         fid: 399031,
//         MAPIDCD_NO: '376080888',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88999997723266, 37.659999973223641],
//             [126.88999998798668, 37.654999975985007],
//             [126.88500000915894, 37.654999981446508],
//             [126.88500000543284, 37.659999979329378],
//             [126.88999997723266, 37.659999973223641]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080887',
//       type: 'Feature',
//       properties: {
//         fid: 399033,
//         MAPIDCD_NO: '376080887',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500000543284, 37.659999979329378],
//             [126.88500000915894, 37.654999981446508],
//             [126.88000003177881, 37.654999957028934],
//             [126.88000002981045, 37.660000043849429],
//             [126.88500000543284, 37.659999979329378]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080886',
//       type: 'Feature',
//       properties: {
//         fid: 399036,
//         MAPIDCD_NO: '376080886',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000002981045, 37.660000043849429],
//             [126.88000003177881, 37.654999957028934],
//             [126.87500005512163, 37.654999991058332],
//             [126.87499994529138, 37.6599999877629],
//             [126.88000002981045, 37.660000043849429]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080885',
//       type: 'Feature',
//       properties: {
//         fid: 399038,
//         MAPIDCD_NO: '376080885',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499994529138, 37.6599999877629],
//             [126.87500005512163, 37.654999991058332],
//             [126.86999996431689, 37.65499999278785],
//             [126.86999997262282, 37.659999988894825],
//             [126.87499994529138, 37.6599999877629]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080884',
//       type: 'Feature',
//       properties: {
//         fid: 399040,
//         MAPIDCD_NO: '376080884',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999997262282, 37.659999988894825],
//             [126.86999996431689, 37.65499999278785],
//             [126.86499998612179, 37.654999961620355],
//             [126.86499999918496, 37.659999958322416],
//             [126.86999997262282, 37.659999988894825]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080883',
//       type: 'Feature',
//       properties: {
//         fid: 399043,
//         MAPIDCD_NO: '376080883',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499999918496, 37.659999958322416],
//             [126.86499998612179, 37.654999961620355],
//             [126.86000000865263, 37.65499999070083],
//             [126.86000002648761, 37.659999986185966],
//             [126.86499999918496, 37.659999958322416]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080882',
//       type: 'Feature',
//       properties: {
//         fid: 399045,
//         MAPIDCD_NO: '376080882',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000002648761, 37.659999986185966],
//             [126.86000000865263, 37.65499999070083],
//             [126.85500003270224, 37.654999988097565],
//             [126.85500005530847, 37.659999982356467],
//             [126.86000002648761, 37.659999986185966]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080881',
//       type: 'Feature',
//       properties: {
//         fid: 399047,
//         MAPIDCD_NO: '376080881',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500005530847, 37.659999982356467],
//             [126.85500003270224, 37.654999988097565],
//             [126.85000005524954, 37.655000042123724],
//             [126.8499999692249, 37.660000038127393],
//             [126.85500005530847, 37.659999982356467]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080790',
//       type: 'Feature',
//       properties: {
//         fid: 399049,
//         MAPIDCD_NO: '376080790',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8499999692249, 37.660000038127393],
//             [126.85000005524954, 37.655000042123724],
//             [126.84499996674974, 37.654999975509924],
//             [126.84499999885723, 37.6599999708934],
//             [126.8499999692249, 37.660000038127393]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080789',
//       type: 'Feature',
//       properties: {
//         fid: 399051,
//         MAPIDCD_NO: '376080789',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499999885723, 37.6599999708934],
//             [126.84499996674974, 37.654999975509924],
//             [126.83999999011442, 37.654999967951689],
//             [126.84000002699088, 37.659999962080867],
//             [126.84499999885723, 37.6599999708934]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080788',
//       type: 'Feature',
//       properties: {
//         fid: 399053,
//         MAPIDCD_NO: '376080788',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000002699088, 37.659999962080867],
//             [126.83999999011442, 37.654999967951689],
//             [126.83500001426262, 37.655000017033686],
//             [126.83500005589124, 37.660000011701776],
//             [126.84000002699088, 37.659999962080867]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080787',
//       type: 'Feature',
//       properties: {
//         fid: 399056,
//         MAPIDCD_NO: '376080787',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500005589124, 37.660000011701776],
//             [126.83500001426262, 37.655000017033686],
//             [126.8300000399701, 37.655000036232387],
//             [126.82999997072948, 37.660000028970664],
//             [126.83500005589124, 37.660000011701776]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080786',
//       type: 'Feature',
//       properties: {
//         fid: 399059,
//         MAPIDCD_NO: '376080786',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999997072948, 37.660000028970664],
//             [126.8300000399701, 37.655000036232387],
//             [126.82499995165631, 37.655000021280756],
//             [126.82500000052809, 37.660000015183037],
//             [126.82999997072948, 37.660000028970664]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080785',
//       type: 'Feature',
//       properties: {
//         fid: 399061,
//         MAPIDCD_NO: '376080785',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500000052809, 37.660000015183037],
//             [126.82499995165631, 37.655000021280756],
//             [126.81999997602848, 37.654999977075875],
//             [126.82000003193369, 37.659999969699506],
//             [126.82500000052809, 37.660000015183037]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080784',
//       type: 'Feature',
//       properties: {
//         fid: 399064,
//         MAPIDCD_NO: '376080784',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000003193369, 37.659999969699506],
//             [126.81999997602848, 37.654999977075875],
//             [126.81500000121287, 37.654999989509157],
//             [126.81499994849776, 37.65999998197595],
//             [126.82000003193369, 37.659999969699506]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080783',
//       type: 'Feature',
//       properties: {
//         fid: 399066,
//         MAPIDCD_NO: '376080783',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499994849776, 37.65999998197595],
//             [126.81500000121287, 37.654999989509157],
//             [126.81000002800855, 37.654999972057439],
//             [126.80999998005815, 37.659999963209067],
//             [126.81499994849776, 37.65999998197595]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080782',
//       type: 'Feature',
//       properties: {
//         fid: 399068,
//         MAPIDCD_NO: '376080782',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999998005815, 37.659999963209067],
//             [126.81000002800855, 37.654999972057439],
//             [126.80500005562475, 37.655000011242961],
//             [126.80500001015422, 37.660000002859249],
//             [126.80999998005815, 37.659999963209067]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080781',
//       type: 'Feature',
//       properties: {
//         fid: 399070,
//         MAPIDCD_NO: '376080781',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500001015422, 37.660000002859249],
//             [126.80500005562475, 37.655000011242961],
//             [126.79999996926068, 37.655000018053975],
//             [126.80000004189755, 37.660000010811544],
//             [126.80500001015422, 37.660000002859249]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080690',
//       type: 'Feature',
//       properties: {
//         fid: 399073,
//         MAPIDCD_NO: '376080690',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000004189755, 37.660000010811544],
//             [126.79999996926068, 37.655000018053975],
//             [126.79499999563788, 37.654999995633062],
//             [126.79499995966827, 37.659999986374551],
//             [126.80000004189755, 37.660000010811544]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080689',
//       type: 'Feature',
//       properties: {
//         fid: 399076,
//         MAPIDCD_NO: '376080689',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499995966827, 37.659999986374551],
//             [126.79499999563788, 37.654999995633062],
//             [126.79000002285417, 37.655000029847656],
//             [126.78999999389635, 37.660000021052639],
//             [126.79499995966827, 37.659999986374551]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080688',
//       type: 'Feature',
//       properties: {
//         fid: 399078,
//         MAPIDCD_NO: '376080688',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999999389635, 37.660000021052639],
//             [126.79000002285417, 37.655000029847656],
//             [126.78500005175488, 37.655000032372421],
//             [126.78500002527325, 37.66000002400429],
//             [126.78999999389635, 37.660000021052639]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080687',
//       type: 'Feature',
//       properties: {
//         fid: 399080,
//         MAPIDCD_NO: '376080687',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500002527325, 37.66000002400429],
//             [126.78500005175488, 37.655000032372421],
//             [126.77999996671085, 37.655000004303851],
//             [126.77999994498154, 37.65999999456433],
//             [126.78500002527325, 37.66000002400429]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080686',
//       type: 'Feature',
//       properties: {
//         fid: 399082,
//         MAPIDCD_NO: '376080686',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77999994498154, 37.65999999456433],
//             [126.77999996671085, 37.655000004303851],
//             [126.77499999588518, 37.65500003355632],
//             [126.77499997891513, 37.660000022436051],
//             [126.77999994498154, 37.65999999456433]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080685',
//       type: 'Feature',
//       properties: {
//         fid: 399086,
//         MAPIDCD_NO: '376080685',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77499997891513, 37.660000022436051],
//             [126.77499999588518, 37.65500003355632],
//             [126.77000002451307, 37.655000031103654],
//             [126.77000001228386, 37.660000020395863],
//             [126.77499997891513, 37.660000022436051]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080684',
//       type: 'Feature',
//       properties: {
//         fid: 399088,
//         MAPIDCD_NO: '376080684',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000001228386, 37.660000020395863],
//             [126.77000002451307, 37.655000031103654],
//             [126.7650000548778, 37.654999996959361],
//             [126.76500004738854, 37.659999986654761],
//             [126.77000001228386, 37.660000020395863]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080683',
//       type: 'Feature',
//       properties: {
//         fid: 399090,
//         MAPIDCD_NO: '376080683',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76500004738854, 37.659999986654761],
//             [126.7650000548778, 37.654999996959361],
//             [126.75999997045578, 37.655000022327087],
//             [126.75999996998297, 37.660000010627861],
//             [126.76500004738854, 37.659999986654761]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080682',
//       type: 'Feature',
//       properties: {
//         fid: 399093,
//         MAPIDCD_NO: '376080682',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999996998297, 37.660000010627861],
//             [126.75999997045578, 37.655000022327087],
//             [126.75500000116884, 37.65500001490598],
//             [126.75500000545209, 37.660000001788752],
//             [126.75999996998297, 37.660000010627861]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080681',
//       type: 'Feature',
//       properties: {
//         fid: 399095,
//         MAPIDCD_NO: '376080681',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75500000545209, 37.660000001788752],
//             [126.75500000116884, 37.65500001490598],
//             [126.75000003365508, 37.654999975791945],
//             [126.75000004040827, 37.659999963035524],
//             [126.75500000545209, 37.660000001788752]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071090',
//       type: 'Feature',
//       properties: {
//         fid: 399098,
//         MAPIDCD_NO: '376071090',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75000004040827, 37.659999963035524],
//             [126.75000003365508, 37.654999975791945],
//             [126.74499995139102, 37.654999994371025],
//             [126.7449999628727, 37.659999981980221],
//             [126.75000004040827, 37.659999963035524]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071089',
//       type: 'Feature',
//       properties: {
//         fid: 399100,
//         MAPIDCD_NO: '376071089',
//         MAPID_NM: '김포',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7449999628727, 37.659999981980221],
//             [126.74499995139102, 37.654999994371025],
//             [126.73999998428015, 37.654999981976893],
//             [126.74000000051551, 37.659999968139978],
//             [126.7449999628727, 37.659999981980221]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071088',
//       type: 'Feature',
//       properties: {
//         fid: 399102,
//         MAPIDCD_NO: '376071088',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74000000051551, 37.659999968139978],
//             [126.73999998428015, 37.654999981976893],
//             [126.73500001578691, 37.655000028],
//             [126.73500003902376, 37.660000014524776],
//             [126.74000000051551, 37.659999968139978]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071087',
//       type: 'Feature',
//       properties: {
//         fid: 399105,
//         MAPIDCD_NO: '376071087',
//         MAPID_NM: '김포',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73500003902376, 37.660000014524776],
//             [126.73500001578691, 37.655000028],
//             [126.73000004910713, 37.655000042328084],
//             [126.72999996144299, 37.660000028435761],
//             [126.73500003902376, 37.660000014524776]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071086',
//       type: 'Feature',
//       properties: {
//         fid: 399109,
//         MAPIDCD_NO: '376071086',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.72999996144299, 37.660000028435761],
//             [126.73000004910713, 37.655000042328084],
//             [126.72499997089778, 37.655000024216058],
//             [126.72500000134413, 37.660000009589545],
//             [126.72999996144299, 37.660000028435761]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081079',
//       type: 'Feature',
//       properties: {
//         fid: 399457,
//         MAPIDCD_NO: '376081079',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99500002994097, 37.664999974618489],
//             [126.99500002460454, 37.6599999785122],
//             [126.99000004777462, 37.66000002104397],
//             [126.9899999444698, 37.665000017494172],
//             [126.99500002994097, 37.664999974618489]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081078',
//       type: 'Feature',
//       properties: {
//         fid: 399459,
//         MAPIDCD_NO: '376081078',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9899999444698, 37.665000017494172],
//             [126.99000004777462, 37.66000002104397],
//             [126.98499995876718, 37.660000033200212],
//             [126.98499997359838, 37.665000027360172],
//             [126.9899999444698, 37.665000017494172]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081077',
//       type: 'Feature',
//       properties: {
//         fid: 399461,
//         MAPIDCD_NO: '376081077',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98499997359838, 37.665000027360172],
//             [126.98499995876718, 37.660000033200212],
//             [126.97999998210108, 37.660000012350608],
//             [126.98000000167009, 37.665000007326093],
//             [126.98499997359838, 37.665000027360172]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081076',
//       type: 'Feature',
//       properties: {
//         fid: 399463,
//         MAPIDCD_NO: '376081076',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98000000167009, 37.665000007326093],
//             [126.97999998210108, 37.660000012350608],
//             [126.97500000668745, 37.659999958014588],
//             [126.97500003035877, 37.6650000439266],
//             [126.98000000167009, 37.665000007326093]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081075',
//       type: 'Feature',
//       properties: {
//         fid: 399465,
//         MAPIDCD_NO: '376081075',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97500003035877, 37.6650000439266],
//             [126.97500000668745, 37.659999958014588],
//             [126.97000003187642, 37.6599999639275],
//             [126.96999994527134, 37.6649999599863],
//             [126.97500003035877, 37.6650000439266]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081074',
//       type: 'Feature',
//       properties: {
//         fid: 399467,
//         MAPIDCD_NO: '376081074',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96999994527134, 37.6649999599863],
//             [126.97000003187642, 37.6599999639275],
//             [126.96500005541112, 37.660000028275995],
//             [126.9649999735545, 37.665000023314704],
//             [126.96999994527134, 37.6649999599863]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081073',
//       type: 'Feature',
//       properties: {
//         fid: 399469,
//         MAPIDCD_NO: '376081073',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9649999735545, 37.665000023314704],
//             [126.96500005541112, 37.660000028275995],
//             [126.95999996522309, 37.659999970279955],
//             [126.96000000374106, 37.664999966621295],
//             [126.9649999735545, 37.665000023314704]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081072',
//       type: 'Feature',
//       properties: {
//         fid: 399471,
//         MAPIDCD_NO: '376081072',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96000000374106, 37.664999966621295],
//             [126.95999996522309, 37.659999970279955],
//             [126.9549999913081, 37.65999997125347],
//             [126.95500003230575, 37.664999966550489],
//             [126.96000000374106, 37.664999966621295]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081071',
//       type: 'Feature',
//       properties: {
//         fid: 399473,
//         MAPIDCD_NO: '376081071',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95500003230575, 37.664999966550489],
//             [126.9549999913081, 37.65999997125347],
//             [126.95000001575694, 37.660000030661067],
//             [126.94999994812163, 37.665000024386089],
//             [126.95500003230575, 37.664999966550489]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080980',
//       type: 'Feature',
//       properties: {
//         fid: 399475,
//         MAPIDCD_NO: '376080980',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94999994812163, 37.665000024386089],
//             [126.95000001575694, 37.660000030661067],
//             [126.9450000399231, 37.659999966439393],
//             [126.9449999770071, 37.66499996271223],
//             [126.94999994812163, 37.665000024386089]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080979',
//       type: 'Feature',
//       properties: {
//         fid: 399477,
//         MAPIDCD_NO: '376080979',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9449999770071, 37.66499996271223],
//             [126.9450000399231, 37.659999966439393],
//             [126.93999995135562, 37.659999961925728],
//             [126.94000000655677, 37.664999957670041],
//             [126.9449999770071, 37.66499996271223]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080978',
//       type: 'Feature',
//       properties: {
//         fid: 399479,
//         MAPIDCD_NO: '376080978',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94000000655677, 37.664999957670041],
//             [126.93999995135562, 37.659999961925728],
//             [126.93499997454207, 37.660000016377815],
//             [126.93500003675533, 37.665000011061622],
//             [126.94000000655677, 37.664999957670041]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080977',
//       type: 'Feature',
//       properties: {
//         fid: 399481,
//         MAPIDCD_NO: '376080977',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93500003675533, 37.665000011061622],
//             [126.93499997454207, 37.660000016377815],
//             [126.9299999990789, 37.660000037340069],
//             [126.92999995264319, 37.665000032197959],
//             [126.93500003675533, 37.665000011061622]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080976',
//       type: 'Feature',
//       properties: {
//         fid: 399483,
//         MAPIDCD_NO: '376080976',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92999995264319, 37.665000032197959],
//             [126.9299999990789, 37.660000037340069],
//             [126.92500002495478, 37.660000028417429],
//             [126.92499998099501, 37.665000022169579],
//             [126.92999995264319, 37.665000032197959]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080975',
//       type: 'Feature',
//       properties: {
//         fid: 399485,
//         MAPIDCD_NO: '376080975',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499998099501, 37.665000022169579],
//             [126.92500002495478, 37.660000028417429],
//             [126.92000004993206, 37.6599999877959],
//             [126.92000001071519, 37.664999980443937],
//             [126.92499998099501, 37.665000022169579]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080974',
//       type: 'Feature',
//       properties: {
//         fid: 399487,
//         MAPIDCD_NO: '376080974',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000001071519, 37.664999980443937],
//             [126.92000004993206, 37.6599999877959],
//             [126.91499996222984, 37.660000003250872],
//             [126.91500004111245, 37.664999997150261],
//             [126.92000001071519, 37.664999980443937]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080973',
//       type: 'Feature',
//       properties: {
//         fid: 399489,
//         MAPIDCD_NO: '376080973',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500004111245, 37.664999997150261],
//             [126.91499996222984, 37.660000003250872],
//             [126.90999998700661, 37.659999989365986],
//             [126.90999995723756, 37.6649999833828],
//             [126.91500004111245, 37.664999997150261]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080972',
//       type: 'Feature',
//       properties: {
//         fid: 399492,
//         MAPIDCD_NO: '376080972',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999995723756, 37.6649999833828],
//             [126.90999998700661, 37.659999989365986],
//             [126.90500001248354, 37.660000032118845],
//             [126.9049999874409, 37.665000026806034],
//             [126.90999995723756, 37.6649999833828]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080971',
//       type: 'Feature',
//       properties: {
//         fid: 399494,
//         MAPIDCD_NO: '376080971',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9049999874409, 37.665000026806034],
//             [126.90500001248354, 37.660000032118845],
//             [126.90000003935533, 37.660000044984876],
//             [126.90000001905287, 37.665000038530522],
//             [126.9049999874409, 37.665000026806034]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080880',
//       type: 'Feature',
//       properties: {
//         fid: 399496,
//         MAPIDCD_NO: '376080880',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000001905287, 37.665000038530522],
//             [126.90000003935533, 37.660000044984876],
//             [126.89499995202878, 37.660000023765541],
//             [126.89500004982209, 37.6650000185444],
//             [126.90000001905287, 37.665000038530522]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080879',
//       type: 'Feature',
//       properties: {
//         fid: 399498,
//         MAPIDCD_NO: '376080879',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89500004982209, 37.6650000185444],
//             [126.89499995202878, 37.660000023765541],
//             [126.88999997723266, 37.659999973223641],
//             [126.88999996638619, 37.664999966260766],
//             [126.89500004982209, 37.6650000185444]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080878',
//       type: 'Feature',
//       properties: {
//         fid: 399501,
//         MAPIDCD_NO: '376080878',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88999996638619, 37.664999966260766],
//             [126.88999997723266, 37.659999973223641],
//             [126.88500000543284, 37.659999979329378],
//             [126.8849999970422, 37.664999972987722],
//             [126.88999996638619, 37.664999966260766]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080877',
//       type: 'Feature',
//       properties: {
//         fid: 399503,
//         MAPIDCD_NO: '376080877',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8849999970422, 37.664999972987722],
//             [126.88500000543284, 37.659999979329378],
//             [126.88000002981045, 37.660000043849429],
//             [126.88000002842453, 37.665000036340651],
//             [126.8849999970422, 37.664999972987722]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080876',
//       type: 'Feature',
//       properties: {
//         fid: 399506,
//         MAPIDCD_NO: '376080876',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000002842453, 37.665000036340651],
//             [126.88000002981045, 37.660000043849429],
//             [126.87499994529138, 37.6599999877629],
//             [126.87499994636723, 37.664999979053846],
//             [126.88000002842453, 37.665000036340651]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080875',
//       type: 'Feature',
//       properties: {
//         fid: 399508,
//         MAPIDCD_NO: '376080875',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499994636723, 37.664999979053846],
//             [126.87499994529138, 37.6599999877629],
//             [126.86999997262282, 37.659999988894825],
//             [126.86999997841956, 37.664999980790654],
//             [126.87499994636723, 37.664999979053846]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080874',
//       type: 'Feature',
//       properties: {
//         fid: 399510,
//         MAPIDCD_NO: '376080874',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999997841956, 37.664999980790654],
//             [126.86999997262282, 37.659999988894825],
//             [126.86499999918496, 37.659999958322416],
//             [126.8650000112014, 37.66500004095461],
//             [126.86999997841956, 37.664999980790654]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080873',
//       type: 'Feature',
//       properties: {
//         fid: 399513,
//         MAPIDCD_NO: '376080873',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8650000112014, 37.66500004095461],
//             [126.86499999918496, 37.659999958322416],
//             [126.86000002648761, 37.659999986185966],
//             [126.86000004399091, 37.664999979275834],
//             [126.8650000112014, 37.66500004095461]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080872',
//       type: 'Feature',
//       properties: {
//         fid: 399515,
//         MAPIDCD_NO: '376080872',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000004399091, 37.664999979275834],
//             [126.86000002648761, 37.659999986185966],
//             [126.85500005530847, 37.659999982356467],
//             [126.85499996188494, 37.664999975390877],
//             [126.86000004399091, 37.664999979275834]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080871',
//       type: 'Feature',
//       properties: {
//         fid: 399517,
//         MAPIDCD_NO: '376080871',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499996188494, 37.664999975390877],
//             [126.85500005530847, 37.659999982356467],
//             [126.8499999692249, 37.660000038127393],
//             [126.84999999618755, 37.665000028756552],
//             [126.85499996188494, 37.664999975390877]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080780',
//       type: 'Feature',
//       properties: {
//         fid: 399519,
//         MAPIDCD_NO: '376080780',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999999618755, 37.665000028756552],
//             [126.8499999692249, 37.660000038127393],
//             [126.84499999885723, 37.6599999708934],
//             [126.84500002826916, 37.664999962068272],
//             [126.84999999618755, 37.665000028756552]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080779',
//       type: 'Feature',
//       properties: {
//         fid: 399521,
//         MAPIDCD_NO: '376080779',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500002826916, 37.664999962068272],
//             [126.84499999885723, 37.6599999708934],
//             [126.84000002699088, 37.659999962080867],
//             [126.83999994921071, 37.665000043311],
//             [126.84500002826916, 37.664999962068272]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080778',
//       type: 'Feature',
//       properties: {
//         fid: 399523,
//         MAPIDCD_NO: '376080778',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999994921071, 37.665000043311],
//             [126.84000002699088, 37.659999962080867],
//             [126.83500005589124, 37.660000011701776],
//             [126.83499998137259, 37.665000001523104],
//             [126.83999994921071, 37.665000043311]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080777',
//       type: 'Feature',
//       properties: {
//         fid: 399526,
//         MAPIDCD_NO: '376080777',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499998137259, 37.665000001523104],
//             [126.83500005589124, 37.660000011701776],
//             [126.82999997072948, 37.660000028970664],
//             [126.83000001656185, 37.665000019974144],
//             [126.83499998137259, 37.665000001523104]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080776',
//       type: 'Feature',
//       properties: {
//         fid: 399529,
//         MAPIDCD_NO: '376080776',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000001656185, 37.665000019974144],
//             [126.82999997072948, 37.660000028970664],
//             [126.82500000052809, 37.660000015183037],
//             [126.82500005108999, 37.665000004904833],
//             [126.83000001656185, 37.665000019974144]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080775',
//       type: 'Feature',
//       properties: {
//         fid: 399531,
//         MAPIDCD_NO: '376080775',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500005108999, 37.665000004904833],
//             [126.82500000052809, 37.660000015183037],
//             [126.82000003193369, 37.659999969699506],
//             [126.81999997156368, 37.664999959266126],
//             [126.82500005108999, 37.665000004904833]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080774',
//       type: 'Feature',
//       properties: {
//         fid: 399534,
//         MAPIDCD_NO: '376080774',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999997156368, 37.664999959266126],
//             [126.82000003193369, 37.659999969699506],
//             [126.81499994849776, 37.65999998197595],
//             [126.81500000622488, 37.664999970895664],
//             [126.81999997156368, 37.664999959266126]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080773',
//       type: 'Feature',
//       properties: {
//         fid: 399536,
//         MAPIDCD_NO: '376080773',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500000622488, 37.664999970895664],
//             [126.81499994849776, 37.65999998197595],
//             [126.80999998005815, 37.659999963209067],
//             [126.8100000416616, 37.665000042749121],
//             [126.81500000622488, 37.664999970895664]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080772',
//       type: 'Feature',
//       properties: {
//         fid: 399538,
//         MAPIDCD_NO: '376080772',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8100000416616, 37.665000042749121],
//             [126.80999998005815, 37.659999963209067],
//             [126.80500001015422, 37.660000002859249],
//             [126.80499996392503, 37.664999992087],
//             [126.8100000416616, 37.665000042749121]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080771',
//       type: 'Feature',
//       properties: {
//         fid: 399540,
//         MAPIDCD_NO: '376080771',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499996392503, 37.664999992087],
//             [126.80500001015422, 37.660000002859249],
//             [126.80000004189755, 37.660000010811544],
//             [126.80000000039414, 37.66499999870598],
//             [126.80499996392503, 37.664999992087]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080680',
//       type: 'Feature',
//       properties: {
//         fid: 399543,
//         MAPIDCD_NO: '376080680',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000000039414, 37.66499999870598],
//             [126.80000004189755, 37.660000010811544],
//             [126.79499995966827, 37.659999986374551],
//             [126.79500003852606, 37.664999973617505],
//             [126.80000000039414, 37.66499999870598]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080679',
//       type: 'Feature',
//       properties: {
//         fid: 399546,
//         MAPIDCD_NO: '376080679',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500003852606, 37.664999973617505],
//             [126.79499995966827, 37.659999986374551],
//             [126.78999999389635, 37.660000021052639],
//             [126.78999996181761, 37.665000008054882],
//             [126.79500003852606, 37.664999973617505]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080678',
//       type: 'Feature',
//       properties: {
//         fid: 399548,
//         MAPIDCD_NO: '376080678',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999996181761, 37.665000008054882],
//             [126.78999999389635, 37.660000021052639],
//             [126.78500002527325, 37.66000002400429],
//             [126.78499999791796, 37.665000009645183],
//             [126.78999996181761, 37.665000008054882]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080677',
//       type: 'Feature',
//       properties: {
//         fid: 399550,
//         MAPIDCD_NO: '376080677',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78499999791796, 37.665000009645183],
//             [126.78500002527325, 37.66000002400429],
//             [126.77999994498154, 37.65999999456433],
//             [126.78000003571732, 37.66499997952657],
//             [126.78499999791796, 37.665000009645183]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080676',
//       type: 'Feature',
//       properties: {
//         fid: 399552,
//         MAPIDCD_NO: '376080676',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000003571732, 37.66499997952657],
//             [126.77999994498154, 37.65999999456433],
//             [126.77499997891513, 37.660000022436051],
//             [126.77499996096208, 37.665000008931244],
//             [126.78000003571732, 37.66499997952657]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080675',
//       type: 'Feature',
//       properties: {
//         fid: 399556,
//         MAPIDCD_NO: '376080675',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77499996096208, 37.665000008931244],
//             [126.77499997891513, 37.660000022436051],
//             [126.77000001228386, 37.660000020395863],
//             [126.76999999905185, 37.665000005501668],
//             [126.77499996096208, 37.665000008931244]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080674',
//       type: 'Feature',
//       properties: {
//         fid: 399558,
//         MAPIDCD_NO: '376080674',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76999999905185, 37.665000005501668],
//             [126.77000001228386, 37.660000020395863],
//             [126.76500004738854, 37.659999986654761],
//             [126.76500003887696, 37.664999970361862],
//             [126.76999999905185, 37.665000005501668]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080673',
//       type: 'Feature',
//       properties: {
//         fid: 399560,
//         MAPIDCD_NO: '376080673',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76500003887696, 37.664999970361862],
//             [126.76500004738854, 37.659999986654761],
//             [126.75999996998297, 37.660000010627861],
//             [126.75999996616557, 37.6649999947293],
//             [126.76500003887696, 37.664999970361862]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080672',
//       type: 'Feature',
//       properties: {
//         fid: 399563,
//         MAPIDCD_NO: '376080672',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999996616557, 37.6649999947293],
//             [126.75999996998297, 37.660000010627861],
//             [126.75500000545209, 37.660000001788752],
//             [126.75500000633544, 37.664999986275333],
//             [126.75999996616557, 37.6649999947293]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080671',
//       type: 'Feature',
//       properties: {
//         fid: 399565,
//         MAPIDCD_NO: '376080671',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75500000633544, 37.664999986275333],
//             [126.75500000545209, 37.660000001788752],
//             [126.75000004040827, 37.659999963035524],
//             [126.75000004510261, 37.665000036221755],
//             [126.75500000633544, 37.664999986275333]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071080',
//       type: 'Feature',
//       properties: {
//         fid: 399568,
//         MAPIDCD_NO: '376071080',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75000004510261, 37.665000036221755],
//             [126.75000004040827, 37.659999963035524],
//             [126.7449999628727, 37.659999981980221],
//             [126.74499997316521, 37.664999965406388],
//             [126.75000004510261, 37.665000036221755]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071079',
//       type: 'Feature',
//       properties: {
//         fid: 399570,
//         MAPIDCD_NO: '376071079',
//         MAPID_NM: '김포',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74499997316521, 37.664999965406388],
//             [126.7449999628727, 37.659999981980221],
//             [126.74000000051551, 37.659999968139978],
//             [126.74000001458697, 37.665000042049428],
//             [126.74499997316521, 37.664999965406388]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071078',
//       type: 'Feature',
//       properties: {
//         fid: 399572,
//         MAPIDCD_NO: '376071078',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74000001458697, 37.665000042049428],
//             [126.74000000051551, 37.659999968139978],
//             [126.73500003902376, 37.660000014524776],
//             [126.73500005646213, 37.6649999968385],
//             [126.74000001458697, 37.665000042049428]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071077',
//       type: 'Feature',
//       properties: {
//         fid: 399575,
//         MAPIDCD_NO: '376071077',
//         MAPID_NM: '김포',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73500005646213, 37.6649999968385],
//             [126.73500003902376, 37.660000014524776],
//             [126.72999996144299, 37.660000028435761],
//             [126.7299999858557, 37.665000009299966],
//             [126.73500005646213, 37.6649999968385]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071076',
//       type: 'Feature',
//       properties: {
//         fid: 399579,
//         MAPIDCD_NO: '376071076',
//         MAPID_NM: '김포',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7299999858557, 37.665000009299966],
//             [126.72999996144299, 37.660000028435761],
//             [126.72500000134413, 37.660000009589545],
//             [126.72500003045208, 37.664999990782796],
//             [126.7299999858557, 37.665000009299966]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081069',
//       type: 'Feature',
//       properties: {
//         fid: 399927,
//         MAPIDCD_NO: '376081069',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99500003371605, 37.669999966501784],
//             [126.99500002994097, 37.664999974618489],
//             [126.9899999444698, 37.665000017494172],
//             [126.98999995295139, 37.670000008409374],
//             [126.99500003371605, 37.669999966501784]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081068',
//       type: 'Feature',
//       properties: {
//         fid: 399929,
//         MAPIDCD_NO: '376081068',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98999995295139, 37.670000008409374],
//             [126.9899999444698, 37.665000017494172],
//             [126.98499997359838, 37.665000027360172],
//             [126.98499998678122, 37.670000019100641],
//             [126.98999995295139, 37.670000008409374]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081067',
//       type: 'Feature',
//       properties: {
//         fid: 399931,
//         MAPIDCD_NO: '376081067',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98499998678122, 37.670000019100641],
//             [126.98499997359838, 37.665000027360172],
//             [126.98000000167009, 37.665000007326093],
//             [126.98000001956588, 37.669999998079881],
//             [126.98499998678122, 37.670000019100641]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081066',
//       type: 'Feature',
//       properties: {
//         fid: 399933,
//         MAPIDCD_NO: '376081066',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98000001956588, 37.669999998079881],
//             [126.98000000167009, 37.665000007326093],
//             [126.97500003035877, 37.6650000439266],
//             [126.97500005295427, 37.67000003548695],
//             [126.98000001956588, 37.669999998079881]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081065',
//       type: 'Feature',
//       properties: {
//         fid: 399935,
//         MAPIDCD_NO: '376081065',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97500005295427, 37.67000003548695],
//             [126.97500003035877, 37.6650000439266],
//             [126.96999994527134, 37.6649999599863],
//             [126.96999997419786, 37.670000040681678],
//             [126.97500005295427, 37.67000003548695]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081064',
//       type: 'Feature',
//       properties: {
//         fid: 399937,
//         MAPIDCD_NO: '376081064',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96999997419786, 37.670000040681678],
//             [126.96999994527134, 37.6649999599863],
//             [126.9649999735545, 37.665000023314704],
//             [126.96500000782021, 37.6700000146678],
//             [126.96999997419786, 37.670000040681678]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081063',
//       type: 'Feature',
//       properties: {
//         fid: 399939,
//         MAPIDCD_NO: '376081063',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96500000782021, 37.6700000146678],
//             [126.9649999735545, 37.665000023314704],
//             [126.96000000374106, 37.664999966621295],
//             [126.96000004044973, 37.669999956940117],
//             [126.96500000782021, 37.6700000146678]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081062',
//       type: 'Feature',
//       properties: {
//         fid: 399941,
//         MAPIDCD_NO: '376081062',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96000004044973, 37.669999956940117],
//             [126.96000000374106, 37.664999966621295],
//             [126.95500003230575, 37.664999966550489],
//             [126.95499996032395, 37.669999957114612],
//             [126.96000004044973, 37.669999956940117]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081061',
//       type: 'Feature',
//       properties: {
//         fid: 399943,
//         MAPIDCD_NO: '376081061',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95499996032395, 37.669999957114612],
//             [126.95500003230575, 37.664999966550489],
//             [126.94999994812163, 37.665000024386089],
//             [126.94999999421545, 37.670000016233878],
//             [126.95499996032395, 37.669999957114612]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080970',
//       type: 'Feature',
//       properties: {
//         fid: 399945,
//         MAPIDCD_NO: '376080970',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94999999421545, 37.670000016233878],
//             [126.94999994812163, 37.665000024386089],
//             [126.9449999770071, 37.66499996271223],
//             [126.94500002715182, 37.670000041835259],
//             [126.94999999421545, 37.670000016233878]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080969',
//       type: 'Feature',
//       properties: {
//         fid: 399947,
//         MAPIDCD_NO: '376080969',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94500002715182, 37.670000041835259],
//             [126.9449999770071, 37.66499996271223],
//             [126.94000000655677, 37.664999957670041],
//             [126.93999994800312, 37.670000036995816],
//             [126.94500002715182, 37.670000041835259]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080968',
//       type: 'Feature',
//       properties: {
//         fid: 399949,
//         MAPIDCD_NO: '376080968',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93999994800312, 37.670000036995816],
//             [126.94000000655677, 37.664999957670041],
//             [126.93500003675533, 37.665000011061622],
//             [126.93499998130513, 37.670000000973786],
//             [126.93999994800312, 37.670000036995816]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080967',
//       type: 'Feature',
//       properties: {
//         fid: 399951,
//         MAPIDCD_NO: '376080967',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93499998130513, 37.670000000973786],
//             [126.93500003675533, 37.665000011061622],
//             [126.92999995264319, 37.665000032197959],
//             [126.93000001526524, 37.670000023375636],
//             [126.93499998130513, 37.670000000973786]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080966',
//       type: 'Feature',
//       properties: {
//         fid: 399953,
//         MAPIDCD_NO: '376080966',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93000001526524, 37.670000023375636],
//             [126.92999995264319, 37.665000032197959],
//             [126.92499998099501, 37.665000022169579],
//             [126.92500005059081, 37.670000012268751],
//             [126.93000001526524, 37.670000023375636]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080965',
//       type: 'Feature',
//       properties: {
//         fid: 399955,
//         MAPIDCD_NO: '376080965',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92500005059081, 37.670000012268751],
//             [126.92499998099501, 37.665000022169579],
//             [126.92000001071519, 37.664999980443937],
//             [126.91999997161585, 37.669999970689389],
//             [126.92500005059081, 37.670000012268751]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080964',
//       type: 'Feature',
//       properties: {
//         fid: 399957,
//         MAPIDCD_NO: '376080964',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999997161585, 37.669999970689389],
//             [126.92000001071519, 37.664999980443937],
//             [126.91500004111245, 37.664999997150261],
//             [126.91500000670384, 37.669999988085372],
//             [126.91999997161585, 37.669999970689389]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080963',
//       type: 'Feature',
//       properties: {
//         fid: 399959,
//         MAPIDCD_NO: '376080963',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500000670384, 37.669999988085372],
//             [126.91500004111245, 37.664999997150261],
//             [126.90999995723756, 37.6649999833828],
//             [126.91000004092615, 37.66999997196023],
//             [126.91500000670384, 37.669999988085372]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080962',
//       type: 'Feature',
//       properties: {
//         fid: 399962,
//         MAPIDCD_NO: '376080962',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000004092615, 37.66999997196023],
//             [126.90999995723756, 37.6649999833828],
//             [126.9049999874409, 37.665000026806034],
//             [126.90499996243233, 37.670000015487204],
//             [126.91000004092615, 37.66999997196023]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080961',
//       type: 'Feature',
//       properties: {
//         fid: 399964,
//         MAPIDCD_NO: '376080961',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499996243233, 37.670000015487204],
//             [126.9049999874409, 37.665000026806034],
//             [126.90000001905287, 37.665000038530522],
//             [126.89999999646507, 37.6700000278618],
//             [126.90499996243233, 37.670000015487204]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080870',
//       type: 'Feature',
//       properties: {
//         fid: 399966,
//         MAPIDCD_NO: '376080870',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999999646507, 37.6700000278618],
//             [126.90000001905287, 37.665000038530522],
//             [126.89500004982209, 37.6650000185444],
//             [126.89500003193648, 37.670000006725324],
//             [126.89999999646507, 37.6700000278618]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080869',
//       type: 'Feature',
//       properties: {
//         fid: 399968,
//         MAPIDCD_NO: '376080869',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89500003193648, 37.670000006725324],
//             [126.89500004982209, 37.6650000185444],
//             [126.88999996638619, 37.664999966260766],
//             [126.88999995470979, 37.670000045225187],
//             [126.89500003193648, 37.670000006725324]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080868',
//       type: 'Feature',
//       properties: {
//         fid: 399971,
//         MAPIDCD_NO: '376080868',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88999995470979, 37.670000045225187],
//             [126.88999996638619, 37.664999966260766],
//             [126.8849999970422, 37.664999972987722],
//             [126.88499999080435, 37.669999960654231],
//             [126.88999995470979, 37.670000045225187]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080867',
//       type: 'Feature',
//       properties: {
//         fid: 399973,
//         MAPIDCD_NO: '376080867',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499999080435, 37.669999960654231],
//             [126.8849999970422, 37.664999972987722],
//             [126.88000002842453, 37.665000036340651],
//             [126.8800000268719, 37.670000024631371],
//             [126.88499999080435, 37.669999960654231]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080866',
//       type: 'Feature',
//       properties: {
//         fid: 399976,
//         MAPIDCD_NO: '376080866',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8800000268719, 37.670000024631371],
//             [126.88000002842453, 37.665000036340651],
//             [126.87499994636723, 37.664999979053846],
//             [126.87499994949131, 37.669999967959463],
//             [126.8800000268719, 37.670000024631371]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080865',
//       type: 'Feature',
//       properties: {
//         fid: 399978,
//         MAPIDCD_NO: '376080865',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499994949131, 37.669999967959463],
//             [126.87499994636723, 37.664999979053846],
//             [126.86999997841956, 37.664999980790654],
//             [126.86999998624239, 37.669999968499305],
//             [126.87499994949131, 37.669999967959463]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080864',
//       type: 'Feature',
//       properties: {
//         fid: 399980,
//         MAPIDCD_NO: '376080864',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999998624239, 37.669999968499305],
//             [126.86999997841956, 37.664999980790654],
//             [126.8650000112014, 37.66500004095461],
//             [126.86500002143923, 37.670000029247248],
//             [126.86999998624239, 37.669999968499305]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080863',
//       type: 'Feature',
//       properties: {
//         fid: 399983,
//         MAPIDCD_NO: '376080863',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500002143923, 37.670000029247248],
//             [126.8650000112014, 37.66500004095461],
//             [126.86000004399091, 37.664999979275834],
//             [126.85999994554078, 37.669999965737496],
//             [126.86500002143923, 37.670000029247248]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080862',
//       type: 'Feature',
//       properties: {
//         fid: 399985,
//         MAPIDCD_NO: '376080862',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999994554078, 37.669999965737496],
//             [126.86000004399091, 37.664999979275834],
//             [126.85499996188494, 37.664999975390877],
//             [126.85499998377681, 37.669999961255037],
//             [126.85999994554078, 37.669999965737496]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080861',
//       type: 'Feature',
//       properties: {
//         fid: 399987,
//         MAPIDCD_NO: '376080861',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499998377681, 37.669999961255037],
//             [126.85499996188494, 37.664999975390877],
//             [126.84999999618755, 37.665000028756552],
//             [126.85000002049206, 37.6700000151764],
//             [126.85499998377681, 37.669999961255037]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080770',
//       type: 'Feature',
//       properties: {
//         fid: 399989,
//         MAPIDCD_NO: '376080770',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000002049206, 37.6700000151764],
//             [126.84999999618755, 37.665000028756552],
//             [126.84500002826916, 37.664999962068272],
//             [126.84499994534352, 37.67000003855803],
//             [126.85000002049206, 37.6700000151764]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080769',
//       type: 'Feature',
//       properties: {
//         fid: 399991,
//         MAPIDCD_NO: '376080769',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499994534352, 37.67000003855803],
//             [126.84500002826916, 37.664999962068272],
//             [126.83999994921071, 37.665000043311],
//             [126.83999998288137, 37.670000029036416],
//             [126.84499994534352, 37.67000003855803]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080768',
//       type: 'Feature',
//       properties: {
//         fid: 399993,
//         MAPIDCD_NO: '376080768',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999998288137, 37.670000029036416],
//             [126.83999994921071, 37.665000043311],
//             [126.83499998137259, 37.665000001523104],
//             [126.83500001972081, 37.669999987788643],
//             [126.83999998288137, 37.670000029036416]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080767',
//       type: 'Feature',
//       properties: {
//         fid: 399996,
//         MAPIDCD_NO: '376080767',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500001972081, 37.669999987788643],
//             [126.83499998137259, 37.665000001523104],
//             [126.83000001656185, 37.665000019974144],
//             [126.82999994621848, 37.670000004323732],
//             [126.83500001972081, 37.669999987788643]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080766',
//       type: 'Feature',
//       properties: {
//         fid: 399999,
//         MAPIDCD_NO: '376080766',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999994621848, 37.670000004323732],
//             [126.83000001656185, 37.665000019974144],
//             [126.82500005108999, 37.665000004904833],
//             [126.82499998542268, 37.669999989771057],
//             [126.82999994621848, 37.670000004323732]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080765',
//       type: 'Feature',
//       properties: {
//         fid: 400001,
//         MAPIDCD_NO: '376080765',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499998542268, 37.669999989771057],
//             [126.82500005108999, 37.665000004904833],
//             [126.81999997156368, 37.664999959266126],
//             [126.82000002541011, 37.670000033631517],
//             [126.82499998542268, 37.669999989771057]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080764',
//       type: 'Feature',
//       properties: {
//         fid: 400004,
//         MAPIDCD_NO: '376080764',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000002541011, 37.670000033631517],
//             [126.81999997156368, 37.664999959266126],
//             [126.81500000622488, 37.664999970895664],
//             [126.81499994989932, 37.669999956767029],
//             [126.82000002541011, 37.670000033631517]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080763',
//       type: 'Feature',
//       properties: {
//         fid: 400006,
//         MAPIDCD_NO: '376080763',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499994989932, 37.669999956767029],
//             [126.81500000622488, 37.664999970895664],
//             [126.8100000416616, 37.665000042749121],
//             [126.8099999900259, 37.670000027306443],
//             [126.81499994989932, 37.669999956767029]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080762',
//       type: 'Feature',
//       properties: {
//         fid: 400008,
//         MAPIDCD_NO: '376080762',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8099999900259, 37.670000027306443],
//             [126.8100000416616, 37.665000042749121],
//             [126.80499996392503, 37.664999992087],
//             [126.80500003035519, 37.669999975989086],
//             [126.8099999900259, 37.670000027306443]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080761',
//       type: 'Feature',
//       properties: {
//         fid: 400010,
//         MAPIDCD_NO: '376080761',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500003035519, 37.669999975989086],
//             [126.80499996392503, 37.664999992087],
//             [126.80000000039414, 37.66499999870598],
//             [126.79999995811187, 37.669999982409827],
//             [126.80500003035519, 37.669999975989086]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080670',
//       type: 'Feature',
//       properties: {
//         fid: 400013,
//         MAPIDCD_NO: '376080670',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999995811187, 37.669999982409827],
//             [126.80000000039414, 37.66499999870598],
//             [126.79500003852606, 37.664999973617505],
//             [126.79500000091463, 37.6699999577819],
//             [126.79999995811187, 37.669999982409827]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080669',
//       type: 'Feature',
//       properties: {
//         fid: 400016,
//         MAPIDCD_NO: '376080669',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500000091463, 37.6699999577819],
//             [126.79500003852606, 37.664999973617505],
//             [126.78999996181761, 37.665000008054882],
//             [126.79000004228637, 37.669999989747851],
//             [126.79500000091463, 37.6699999577819]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080668',
//       type: 'Feature',
//       properties: {
//         fid: 400018,
//         MAPIDCD_NO: '376080668',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000004228637, 37.669999989747851],
//             [126.78999996181761, 37.665000008054882],
//             [126.78499999791796, 37.665000009645183],
//             [126.78499996965458, 37.669999992899996],
//             [126.79000004228637, 37.669999989747851]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080667',
//       type: 'Feature',
//       properties: {
//         fid: 400020,
//         MAPIDCD_NO: '376080667',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78499996965458, 37.669999992899996],
//             [126.78499999791796, 37.665000009645183],
//             [126.78000003571732, 37.66499997952657],
//             [126.78000001213941, 37.669999961411371],
//             [126.78499996965458, 37.669999992899996]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080666',
//       type: 'Feature',
//       properties: {
//         fid: 400022,
//         MAPIDCD_NO: '376080666',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000001213941, 37.669999961411371],
//             [126.78000003571732, 37.66499997952657],
//             [126.77499996096208, 37.665000008931244],
//             [126.77500005544449, 37.669999990133547],
//             [126.78000001213941, 37.669999961411371]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080665',
//       type: 'Feature',
//       properties: {
//         fid: 400025,
//         MAPIDCD_NO: '376080665',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500005544449, 37.669999990133547],
//             [126.77499996096208, 37.665000008931244],
//             [126.76999999905185, 37.665000005501668],
//             [126.76999998481718, 37.669999986420827],
//             [126.77500005544449, 37.669999990133547]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080664',
//       type: 'Feature',
//       properties: {
//         fid: 400028,
//         MAPIDCD_NO: '376080664',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76999998481718, 37.669999986420827],
//             [126.76999999905185, 37.665000005501668],
//             [126.76500003887696, 37.664999970361862],
//             [126.76500002841877, 37.670000041812159],
//             [126.76999998481718, 37.669999986420827]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080663',
//       type: 'Feature',
//       properties: {
//         fid: 400030,
//         MAPIDCD_NO: '376080663',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76500002841877, 37.670000041812159],
//             [126.76500003887696, 37.664999970361862],
//             [126.75999996616557, 37.6649999947293],
//             [126.7599999590033, 37.669999974631267],
//             [126.76500002841877, 37.670000041812159]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080662',
//       type: 'Feature',
//       properties: {
//         fid: 400033,
//         MAPIDCD_NO: '376080662',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7599999590033, 37.669999974631267],
//             [126.75999996616557, 37.6649999947293],
//             [126.75500000633544, 37.664999986275333],
//             [126.75500000383661, 37.669999966563005],
//             [126.7599999590033, 37.669999974631267]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080661',
//       type: 'Feature',
//       properties: {
//         fid: 400035,
//         MAPIDCD_NO: '376080661',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75500000383661, 37.669999966563005],
//             [126.75500000633544, 37.664999986275333],
//             [126.75000004510261, 37.665000036221755],
//             [126.75000004955187, 37.670000015097642],
//             [126.75500000383661, 37.669999966563005]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071070',
//       type: 'Feature',
//       properties: {
//         fid: 400038,
//         MAPIDCD_NO: '376071070',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75000004955187, 37.670000015097642],
//             [126.75000004510261, 37.665000036221755],
//             [126.74499997316521, 37.664999965406388],
//             [126.74499998137374, 37.670000032973078],
//             [126.75000004955187, 37.670000015097642]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071069',
//       type: 'Feature',
//       properties: {
//         fid: 400040,
//         MAPIDCD_NO: '376071069',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74499998137374, 37.670000032973078],
//             [126.74499997316521, 37.664999965406388],
//             [126.74000001458697, 37.665000042049428],
//             [126.74000002836921, 37.670000019847564],
//             [126.74499998137374, 37.670000032973078]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071068',
//       type: 'Feature',
//       properties: {
//         fid: 400042,
//         MAPIDCD_NO: '376071068',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74000002836921, 37.670000019847564],
//             [126.74000001458697, 37.665000042049428],
//             [126.73500005646213, 37.6649999968385],
//             [126.73499996152184, 37.669999974249684],
//             [126.74000002836921, 37.670000019847564]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071067',
//       type: 'Feature',
//       properties: {
//         fid: 400045,
//         MAPIDCD_NO: '376071067',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73499996152184, 37.669999974249684],
//             [126.73500005646213, 37.6649999968385],
//             [126.7299999858557, 37.665000009299966],
//             [126.73000000896747, 37.66999998598304],
//             [126.73499996152184, 37.669999974249684]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071066',
//       type: 'Feature',
//       properties: {
//         fid: 400049,
//         MAPIDCD_NO: '376071066',
//         MAPID_NM: '김포',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73000000896747, 37.66999998598304],
//             [126.7299999858557, 37.665000009299966],
//             [126.72500003045208, 37.664999990782796],
//             [126.72500005595416, 37.669999967780711],
//             [126.73000000896747, 37.66999998598304]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081059',
//       type: 'Feature',
//       properties: {
//         fid: 400397,
//         MAPIDCD_NO: '376081059',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99500003759893, 37.6750000424995],
//             [126.99500003371605, 37.669999966501784],
//             [126.98999995295139, 37.670000008409374],
//             [126.98999996210215, 37.674999995111634],
//             [126.99500003759893, 37.6750000424995]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081058',
//       type: 'Feature',
//       properties: {
//         fid: 400399,
//         MAPIDCD_NO: '376081058',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98999996210215, 37.674999995111634],
//             [126.98999995295139, 37.670000008409374],
//             [126.98499998678122, 37.670000019100641],
//             [126.9850000005959, 37.675000006628672],
//             [126.98999996210215, 37.674999995111634]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081057',
//       type: 'Feature',
//       properties: {
//         fid: 400401,
//         MAPIDCD_NO: '376081057',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9850000005959, 37.675000006628672],
//             [126.98499998678122, 37.670000019100641],
//             [126.98000001956588, 37.669999998079881],
//             [126.98000003805626, 37.674999984621778],
//             [126.9850000005959, 37.675000006628672]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081056',
//       type: 'Feature',
//       properties: {
//         fid: 400403,
//         MAPIDCD_NO: '376081056',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98000003805626, 37.674999984621778],
//             [126.98000001956588, 37.669999998079881],
//             [126.97500005295427, 37.67000003548695],
//             [126.97499996271185, 37.675000022331169],
//             [126.98000003805626, 37.674999984621778]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081055',
//       type: 'Feature',
//       properties: {
//         fid: 400405,
//         MAPIDCD_NO: '376081055',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97499996271185, 37.675000022331169],
//             [126.97500005295427, 37.67000003548695],
//             [126.96999997419786, 37.670000040681678],
//             [126.96999999974977, 37.675000027015585],
//             [126.97499996271185, 37.675000022331169]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081054',
//       type: 'Feature',
//       properties: {
//         fid: 400407,
//         MAPIDCD_NO: '376081054',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96999999974977, 37.675000027015585],
//             [126.96999997419786, 37.670000040681678],
//             [126.96500000782021, 37.6700000146678],
//             [126.96500003803314, 37.6750000017901],
//             [126.96999999974977, 37.675000027015585]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081053',
//       type: 'Feature',
//       properties: {
//         fid: 400409,
//         MAPIDCD_NO: '376081053',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96500003803314, 37.6750000017901],
//             [126.96500000782021, 37.6700000146678],
//             [126.96000004044973, 37.669999956940117],
//             [126.95999996355567, 37.67500003266003],
//             [126.96500003803314, 37.6750000017901]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081052',
//       type: 'Feature',
//       properties: {
//         fid: 400411,
//         MAPIDCD_NO: '376081052',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95999996355567, 37.67500003266003],
//             [126.96000004044973, 37.669999956940117],
//             [126.95499996032395, 37.669999957114612],
//             [126.95500000147074, 37.675000034123329],
//             [126.95999996355567, 37.67500003266003]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081051',
//       type: 'Feature',
//       properties: {
//         fid: 400413,
//         MAPIDCD_NO: '376081051',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95500000147074, 37.675000034123329],
//             [126.95499996032395, 37.669999957114612],
//             [126.94999999421545, 37.670000016233878],
//             [126.95000004069351, 37.675000002070462],
//             [126.95500000147074, 37.675000034123329]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080960',
//       type: 'Feature',
//       properties: {
//         fid: 400415,
//         MAPIDCD_NO: '376080960',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95000004069351, 37.675000002070462],
//             [126.94999999421545, 37.670000016233878],
//             [126.94500002715182, 37.670000041835259],
//             [126.94499996487967, 37.675000029691915],
//             [126.95000004069351, 37.675000002070462]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080959',
//       type: 'Feature',
//       properties: {
//         fid: 400417,
//         MAPIDCD_NO: '376080959',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94499996487967, 37.675000029691915],
//             [126.94500002715182, 37.670000041835259],
//             [126.93999994800312, 37.670000036995816],
//             [126.94000000378873, 37.675000024325172],
//             [126.94499996487967, 37.675000029691915]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080958',
//       type: 'Feature',
//       properties: {
//         fid: 400419,
//         MAPIDCD_NO: '376080958',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94000000378873, 37.675000024325172],
//             [126.93999994800312, 37.670000036995816],
//             [126.93499998130513, 37.670000000973786],
//             [126.9350000417608, 37.674999987232979],
//             [126.94000000378873, 37.675000024325172]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080957',
//       type: 'Feature',
//       properties: {
//         fid: 400421,
//         MAPIDCD_NO: '376080957',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9350000417608, 37.674999987232979],
//             [126.93499998130513, 37.670000000973786],
//             [126.93000001526524, 37.670000023375636],
//             [126.92999996698217, 37.675000009809835],
//             [126.9350000417608, 37.674999987232979]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080956',
//       type: 'Feature',
//       properties: {
//         fid: 400423,
//         MAPIDCD_NO: '376080956',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92999996698217, 37.675000009809835],
//             [126.93000001526524, 37.670000023375636],
//             [126.92500005059081, 37.670000012268751],
//             [126.92500000469489, 37.674999999400853],
//             [126.92999996698217, 37.675000009809835]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080955',
//       type: 'Feature',
//       properties: {
//         fid: 400425,
//         MAPIDCD_NO: '376080955',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92500000469489, 37.674999999400853],
//             [126.92500005059081, 37.670000012268751],
//             [126.91999997161585, 37.669999970689389],
//             [126.92000004377478, 37.674999957276015],
//             [126.92500000469489, 37.674999999400853]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080954',
//       type: 'Feature',
//       properties: {
//         fid: 400427,
//         MAPIDCD_NO: '376080954',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000004377478, 37.674999957276015],
//             [126.91999997161585, 37.669999970689389],
//             [126.91500000670384, 37.669999988085372],
//             [126.91499997013611, 37.674999973001867],
//             [126.92000004377478, 37.674999957276015]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080953',
//       type: 'Feature',
//       properties: {
//         fid: 400429,
//         MAPIDCD_NO: '376080953',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499997013611, 37.674999973001867],
//             [126.91500000670384, 37.669999988085372],
//             [126.91000004092615, 37.66999997196023],
//             [126.91000000901114, 37.674999957557546],
//             [126.91499997013611, 37.674999973001867]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080952',
//       type: 'Feature',
//       properties: {
//         fid: 400432,
//         MAPIDCD_NO: '376080952',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000000901114, 37.674999957557546],
//             [126.91000004092615, 37.66999997196023],
//             [126.90499996243233, 37.670000015487204],
//             [126.90500004856979, 37.6750000005254],
//             [126.91000000901114, 37.674999957557546]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080951',
//       type: 'Feature',
//       properties: {
//         fid: 400434,
//         MAPIDCD_NO: '376080951',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500004856979, 37.6750000005254],
//             [126.90499996243233, 37.670000015487204],
//             [126.89999999646507, 37.6700000278618],
//             [126.89999997612756, 37.675000013001579],
//             [126.90500004856979, 37.6750000005254]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080860',
//       type: 'Feature',
//       properties: {
//         fid: 400436,
//         MAPIDCD_NO: '376080860',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999997612756, 37.675000013001579],
//             [126.89999999646507, 37.6700000278618],
//             [126.89500003193648, 37.670000006725324],
//             [126.8950000139814, 37.674999992506365],
//             [126.89999997612756, 37.675000013001579]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080859',
//       type: 'Feature',
//       properties: {
//         fid: 400438,
//         MAPIDCD_NO: '376080859',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8950000139814, 37.674999992506365],
//             [126.89500003193648, 37.670000006725324],
//             [126.88999995470979, 37.670000045225187],
//             [126.89000005481965, 37.675000028630919],
//             [126.8950000139814, 37.674999992506365]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080858',
//       type: 'Feature',
//       properties: {
//         fid: 400441,
//         MAPIDCD_NO: '376080858',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000005481965, 37.675000028630919],
//             [126.88999995470979, 37.670000045225187],
//             [126.88499999080435, 37.669999960654231],
//             [126.88499998141072, 37.675000036039023],
//             [126.89000005481965, 37.675000028630919]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080857',
//       type: 'Feature',
//       properties: {
//         fid: 400443,
//         MAPIDCD_NO: '376080857',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499998141072, 37.675000036039023],
//             [126.88499999080435, 37.669999960654231],
//             [126.8800000268719, 37.670000024631371],
//             [126.88000002288487, 37.675000008709524],
//             [126.88499998141072, 37.675000036039023]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080856',
//       type: 'Feature',
//       properties: {
//         fid: 400446,
//         MAPIDCD_NO: '376080856',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000002288487, 37.675000008709524],
//             [126.8800000268719, 37.670000024631371],
//             [126.87499994949131, 37.669999967959463],
//             [126.8749999493878, 37.675000042781662],
//             [126.88000002288487, 37.675000008709524]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080855',
//       type: 'Feature',
//       properties: {
//         fid: 400448,
//         MAPIDCD_NO: '376080855',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8749999493878, 37.675000042781662],
//             [126.87499994949131, 37.669999967959463],
//             [126.86999998624239, 37.669999968499305],
//             [126.86999999079426, 37.67500004212495],
//             [126.8749999493878, 37.675000042781662]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080854',
//       type: 'Feature',
//       properties: {
//         fid: 400450,
//         MAPIDCD_NO: '376080854',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999999079426, 37.67500004212495],
//             [126.86999998624239, 37.669999968499305],
//             [126.86500002143923, 37.670000029247248],
//             [126.86500003141377, 37.675000011538479],
//             [126.86999999079426, 37.67500004212495]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080853',
//       type: 'Feature',
//       properties: {
//         fid: 400452,
//         MAPIDCD_NO: '376080853',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500003141377, 37.675000011538479],
//             [126.86500002143923, 37.670000029247248],
//             [126.85999994554078, 37.669999965737496],
//             [126.85999995937831, 37.675000038744486],
//             [126.86500003141377, 37.675000011538479]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080852',
//       type: 'Feature',
//       properties: {
//         fid: 400455,
//         MAPIDCD_NO: '376080852',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999995937831, 37.675000038744486],
//             [126.85999994554078, 37.669999965737496],
//             [126.85499998377681, 37.669999961255037],
//             [126.85500000225208, 37.675000034840025],
//             [126.85999995937831, 37.675000038744486]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080851',
//       type: 'Feature',
//       properties: {
//         fid: 400457,
//         MAPIDCD_NO: '376080851',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500000225208, 37.675000034840025],
//             [126.85499998377681, 37.669999961255037],
//             [126.85000002049206, 37.6700000151764],
//             [126.85000004439029, 37.674999999201809],
//             [126.85500000225208, 37.675000034840025]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080760',
//       type: 'Feature',
//       properties: {
//         fid: 400459,
//         MAPIDCD_NO: '376080760',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000004439029, 37.674999999201809],
//             [126.85000002049206, 37.6700000151764],
//             [126.84499994534352, 37.67000003855803],
//             [126.84499997162408, 37.675000021327584],
//             [126.85000004439029, 37.674999999201809]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080759',
//       type: 'Feature',
//       properties: {
//         fid: 400461,
//         MAPIDCD_NO: '376080759',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499997162408, 37.675000021327584],
//             [126.84499994534352, 37.67000003855803],
//             [126.83999998288137, 37.670000029036416],
//             [126.84000001608692, 37.675000010566073],
//             [126.84499997162408, 37.675000021327584]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080758',
//       type: 'Feature',
//       properties: {
//         fid: 400463,
//         MAPIDCD_NO: '376080758',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000001608692, 37.675000010566073],
//             [126.83999998288137, 37.670000029036416],
//             [126.83500001972081, 37.669999987788643],
//             [126.83499994417429, 37.674999969219684],
//             [126.84000001608692, 37.675000010566073]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080757',
//       type: 'Feature',
//       properties: {
//         fid: 400466,
//         MAPIDCD_NO: '376080757',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499994417429, 37.674999969219684],
//             [126.83500001972081, 37.669999987788643],
//             [126.82999994621848, 37.670000004323732],
//             [126.82999998869623, 37.674999986925442],
//             [126.83499994417429, 37.674999969219684]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080756',
//       type: 'Feature',
//       properties: {
//         fid: 400469,
//         MAPIDCD_NO: '376080756',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999998869623, 37.674999986925442],
//             [126.82999994621848, 37.670000004323732],
//             [126.82499998542268, 37.669999989771057],
//             [126.82500003255534, 37.674999971092276],
//             [126.82999998869623, 37.674999986925442]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080755',
//       type: 'Feature',
//       properties: {
//         fid: 400471,
//         MAPIDCD_NO: '376080755',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500003255534, 37.674999971092276],
//             [126.82499998542268, 37.669999989771057],
//             [126.82000002541011, 37.670000033631517],
//             [126.81999996152061, 37.675000014798528],
//             [126.82500003255534, 37.674999971092276]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080754',
//       type: 'Feature',
//       properties: {
//         fid: 400474,
//         MAPIDCD_NO: '376080754',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999996152061, 37.675000014798528],
//             [126.82000002541011, 37.670000033631517],
//             [126.81499994989932, 37.669999956767029],
//             [126.81500000548684, 37.675000027429213],
//             [126.81999996152061, 37.675000014798528]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080753',
//       type: 'Feature',
//       properties: {
//         fid: 400476,
//         MAPIDCD_NO: '376080753',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500000548684, 37.675000027429213],
//             [126.81499994989932, 37.669999956767029],
//             [126.8099999900259, 37.670000027306443],
//             [126.81000005109468, 37.675000006532528],
//             [126.81500000548684, 37.675000027429213]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080752',
//       type: 'Feature',
//       properties: {
//         fid: 400478,
//         MAPIDCD_NO: '376080752',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000005109468, 37.675000006532528],
//             [126.8099999900259, 37.670000027306443],
//             [126.80500003035519, 37.669999975989086],
//             [126.80499998182677, 37.675000045159038],
//             [126.81000005109468, 37.675000006532528]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080751',
//       type: 'Feature',
//       properties: {
//         fid: 400480,
//         MAPIDCD_NO: '376080751',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499998182677, 37.675000045159038],
//             [126.80500003035519, 37.669999975989086],
//             [126.79999995811187, 37.669999982409827],
//             [126.80000002845939, 37.674999960793365],
//             [126.80499998182677, 37.675000045159038]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080660',
//       type: 'Feature',
//       properties: {
//         fid: 400483,
//         MAPIDCD_NO: '376080660',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000002845939, 37.674999960793365],
//             [126.79999995811187, 37.669999982409827],
//             [126.79500000091463, 37.6699999577819],
//             [126.79499995938366, 37.675000026067245],
//             [126.80000002845939, 37.674999960793365]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080659',
//       type: 'Feature',
//       properties: {
//         fid: 400486,
//         MAPIDCD_NO: '376080659',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499995938366, 37.675000026067245],
//             [126.79500000091463, 37.6699999577819],
//             [126.79000004228637, 37.669999989747851],
//             [126.79000000624066, 37.67499996835776],
//             [126.79499995938366, 37.675000026067245]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080658',
//       type: 'Feature',
//       properties: {
//         fid: 400488,
//         MAPIDCD_NO: '376080658',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000000624066, 37.67499996835776],
//             [126.79000004228637, 37.669999989747851],
//             [126.78499996965458, 37.669999992899996],
//             [126.7850000539092, 37.674999970850891],
//             [126.79000000624066, 37.67499996835776]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080657',
//       type: 'Feature',
//       properties: {
//         fid: 400490,
//         MAPIDCD_NO: '376080657',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7850000539092, 37.674999970850891],
//             [126.78499996965458, 37.669999992899996],
//             [126.78000001213941, 37.669999961411371],
//             [126.77999998676272, 37.675000029234859],
//             [126.7850000539092, 37.674999970850891]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080656',
//       type: 'Feature',
//       properties: {
//         fid: 400492,
//         MAPIDCD_NO: '376080656',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77999998676272, 37.675000029234859],
//             [126.78000001213941, 37.669999961411371],
//             [126.77500005544449, 37.669999990133547],
//             [126.77500003330074, 37.674999968240073],
//             [126.77999998676272, 37.675000029234859]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080655',
//       type: 'Feature',
//       properties: {
//         fid: 400495,
//         MAPIDCD_NO: '376080655',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500003330074, 37.674999968240073],
//             [126.77500005544449, 37.669999990133547],
//             [126.76999998481718, 37.669999986420827],
//             [126.76999996958006, 37.674999963153205],
//             [126.77500003330074, 37.674999968240073]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080654',
//       type: 'Feature',
//       properties: {
//         fid: 400498,
//         MAPIDCD_NO: '376080654',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76999996958006, 37.674999963153205],
//             [126.76999998481718, 37.669999986420827],
//             [126.76500002841877, 37.670000041812159],
//             [126.76500001555954, 37.675000017132959],
//             [126.76999996958006, 37.674999963153205]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080653',
//       type: 'Feature',
//       properties: {
//         fid: 400500,
//         MAPIDCD_NO: '376080653',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76500001555954, 37.675000017132959],
//             [126.76500002841877, 37.670000041812159],
//             [126.7599999590033, 37.669999974631267],
//             [126.75999995213633, 37.675000040488456],
//             [126.76500001555954, 37.675000017132959]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080652',
//       type: 'Feature',
//       properties: {
//         fid: 400503,
//         MAPIDCD_NO: '376080652',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999995213633, 37.675000040488456],
//             [126.7599999590033, 37.669999974631267],
//             [126.75500000383661, 37.669999966563005],
//             [126.75500000160787, 37.675000031004053],
//             [126.75999995213633, 37.675000040488456]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080651',
//       type: 'Feature',
//       properties: {
//         fid: 400505,
//         MAPIDCD_NO: '376080651',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75500000160787, 37.675000031004053],
//             [126.75500000383661, 37.669999966563005],
//             [126.75000004955187, 37.670000015097642],
//             [126.75000005058176, 37.674999989775017],
//             [126.75500000160787, 37.675000031004053]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071060',
//       type: 'Feature',
//       properties: {
//         fid: 400508,
//         MAPIDCD_NO: '376071060',
//         MAPID_NM: '김포',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75000005058176, 37.674999989775017],
//             [126.75000004955187, 37.670000015097642],
//             [126.74499998137374, 37.670000032973078],
//             [126.74499998702018, 37.675000008018046],
//             [126.75000005058176, 37.674999989775017]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071059',
//       type: 'Feature',
//       properties: {
//         fid: 400510,
//         MAPIDCD_NO: '376071059',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74499998702018, 37.675000008018046],
//             [126.74499998137374, 37.670000032973078],
//             [126.74000002836921, 37.670000019847564],
//             [126.74000003865749, 37.674999993448388],
//             [126.74499998702018, 37.675000008018046]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071058',
//       type: 'Feature',
//       properties: {
//         fid: 400512,
//         MAPIDCD_NO: '376071058',
//         MAPID_NM: '김포',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.74000003865749, 37.674999993448388],
//             [126.74000002836921, 37.670000019847564],
//             [126.73499996152184, 37.669999974249684],
//             [126.73499997551808, 37.675000036522881],
//             [126.74000003865749, 37.674999993448388]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071057',
//       type: 'Feature',
//       properties: {
//         fid: 400515,
//         MAPIDCD_NO: '376071057',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.73499997551808, 37.675000036522881],
//             [126.73499996152184, 37.669999974249684],
//             [126.73000000896747, 37.66999998598304],
//             [126.7300000285106, 37.674999958469996],
//             [126.73499997551808, 37.675000036522881]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376071056',
//       type: 'Feature',
//       properties: {
//         fid: 400519,
//         MAPIDCD_NO: '376071056',
//         MAPID_NM: '김포',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7300000285106, 37.674999958469996],
//             [126.73000000896747, 37.66999998598304],
//             [126.72500005595416, 37.669999967780711],
//             [126.7249999657907, 37.675000029978605],
//             [126.7300000285106, 37.674999958469996]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081049',
//       type: 'Feature',
//       properties: {
//         fid: 400867,
//         MAPIDCD_NO: '376081049',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99500004278731, 37.680000025955572],
//             [126.99500003759893, 37.6750000424995],
//             [126.98999996210215, 37.674999995111634],
//             [126.98999997192252, 37.679999977600758],
//             [126.99500004278731, 37.680000025955572]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081048',
//       type: 'Feature',
//       properties: {
//         fid: 400869,
//         MAPIDCD_NO: '376081048',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98999997192252, 37.679999977600758],
//             [126.98999996210215, 37.674999995111634],
//             [126.9850000005959, 37.675000006628672],
//             [126.98500001505536, 37.679999988141518],
//             [126.98999997192252, 37.679999977600758]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081047',
//       type: 'Feature',
//       properties: {
//         fid: 400871,
//         MAPIDCD_NO: '376081047',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98500001505536, 37.679999988141518],
//             [126.9850000005959, 37.675000006628672],
//             [126.98000003805626, 37.674999984621778],
//             [126.98000005714165, 37.67999996695162],
//             [126.98500001505536, 37.679999988141518]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081046',
//       type: 'Feature',
//       properties: {
//         fid: 400873,
//         MAPIDCD_NO: '376081046',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98000005714165, 37.67999996695162],
//             [126.98000003805626, 37.674999984621778],
//             [126.97499996271185, 37.675000022331169],
//             [126.97499998641477, 37.680000005468571],
//             [126.98000005714165, 37.67999996695162]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081045',
//       type: 'Feature',
//       properties: {
//         fid: 400875,
//         MAPIDCD_NO: '376081045',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97499998641477, 37.680000005468571],
//             [126.97499996271185, 37.675000022331169],
//             [126.96999999974977, 37.675000027015585],
//             [126.97000002807701, 37.6800000109513],
//             [126.97499998641477, 37.680000005468571]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081044',
//       type: 'Feature',
//       properties: {
//         fid: 400877,
//         MAPIDCD_NO: '376081044',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97000002807701, 37.6800000109513],
//             [126.96999999974977, 37.675000027015585],
//             [126.96500003803314, 37.6750000017901],
//             [126.96499995759425, 37.679999984197785],
//             [126.97000002807701, 37.6800000109513]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081043',
//       type: 'Feature',
//       properties: {
//         fid: 400879,
//         MAPIDCD_NO: '376081043',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96499995759425, 37.679999984197785],
//             [126.96500003803314, 37.6750000017901],
//             [126.95999996355567, 37.67500003266003],
//             [126.95999999886672, 37.680000016351329],
//             [126.96499995759425, 37.679999984197785]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081042',
//       type: 'Feature',
//       properties: {
//         fid: 400881,
//         MAPIDCD_NO: '376081042',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95999999886672, 37.680000016351329],
//             [126.95999996355567, 37.67500003266003],
//             [126.95500000147074, 37.675000034123329],
//             [126.95500004141709, 37.680000016782245],
//             [126.95999999886672, 37.680000016351329]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081041',
//       type: 'Feature',
//       properties: {
//         fid: 400883,
//         MAPIDCD_NO: '376081041',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95500004141709, 37.680000016782245],
//             [126.95500000147074, 37.675000034123329],
//             [126.95000004069351, 37.675000002070462],
//             [126.94999997185896, 37.67999998496142],
//             [126.95500004141709, 37.680000016782245]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080950',
//       type: 'Feature',
//       properties: {
//         fid: 400885,
//         MAPIDCD_NO: '376080950',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94999997185896, 37.67999998496142],
//             [126.95000004069351, 37.675000002070462],
//             [126.94499996487967, 37.675000029691915],
//             [126.9450000140739, 37.680000012060582],
//             [126.94999997185896, 37.67999998496142]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080949',
//       type: 'Feature',
//       properties: {
//         fid: 400887,
//         MAPIDCD_NO: '376080949',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9450000140739, 37.680000012060582],
//             [126.94499996487967, 37.675000029691915],
//             [126.94000000378873, 37.675000024325172],
//             [126.93999994420067, 37.680000006897586],
//             [126.9450000140739, 37.680000012060582]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080943',
//       type: 'Feature',
//       properties: {
//         fid: 400899,
//         MAPIDCD_NO: '376080943',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500004635701, 37.680000044405126],
//             [126.91499997013611, 37.674999973001867],
//             [126.91000000901114, 37.674999957557546],
//             [126.90999997870766, 37.680000029090948],
//             [126.91500004635701, 37.680000044405126]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080942',
//       type: 'Feature',
//       properties: {
//         fid: 400902,
//         MAPIDCD_NO: '376080942',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999997870766, 37.680000029090948],
//             [126.91000000901114, 37.674999957557546],
//             [126.90500004856979, 37.6750000005254],
//             [126.9050000213266, 37.679999982590331],
//             [126.90999997870766, 37.680000029090948]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080941',
//       type: 'Feature',
//       properties: {
//         fid: 400904,
//         MAPIDCD_NO: '376080941',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9050000213266, 37.679999982590331],
//             [126.90500004856979, 37.6750000005254],
//             [126.89999997612756, 37.675000013001579],
//             [126.89999995351954, 37.679999992124024],
//             [126.9050000213266, 37.679999982590331]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080850',
//       type: 'Feature',
//       properties: {
//         fid: 400906,
//         MAPIDCD_NO: '376080850',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999995351954, 37.679999992124024],
//             [126.89999997612756, 37.675000013001579],
//             [126.8950000139814, 37.674999992506365],
//             [126.8949999959864, 37.679999972282189],
//             [126.89999995351954, 37.679999992124024]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080849',
//       type: 'Feature',
//       properties: {
//         fid: 400908,
//         MAPIDCD_NO: '376080849',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8949999959864, 37.679999972282189],
//             [126.8950000139814, 37.674999992506365],
//             [126.89000005481965, 37.675000028630919],
//             [126.89000004143689, 37.680000009050765],
//             [126.8949999959864, 37.679999972282189]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080848',
//       type: 'Feature',
//       properties: {
//         fid: 400911,
//         MAPIDCD_NO: '376080848',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000004143689, 37.680000009050765],
//             [126.89000005481965, 37.675000028630919],
//             [126.88499998141072, 37.675000036039023],
//             [126.88499997264654, 37.680000015290929],
//             [126.89000004143689, 37.680000009050765]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080847',
//       type: 'Feature',
//       properties: {
//         fid: 400913,
//         MAPIDCD_NO: '376080847',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499997264654, 37.680000015290929],
//             [126.88499998141072, 37.675000036039023],
//             [126.88000002288487, 37.675000008709524],
//             [126.88000001871642, 37.679999990389405],
//             [126.88499997264654, 37.680000015290929]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080846',
//       type: 'Feature',
//       properties: {
//         fid: 400916,
//         MAPIDCD_NO: '376080846',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000001871642, 37.679999990389405],
//             [126.88000002288487, 37.675000008709524],
//             [126.8749999493878, 37.675000042781662],
//             [126.8749999498515, 37.680000021472331],
//             [126.88000001871642, 37.679999990389405]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080845',
//       type: 'Feature',
//       properties: {
//         fid: 400918,
//         MAPIDCD_NO: '376080845',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8749999498515, 37.680000021472331],
//             [126.8749999493878, 37.675000042781662],
//             [126.86999999079426, 37.67500004212495],
//             [126.86999999586695, 37.680000021422309],
//             [126.8749999498515, 37.680000021472331]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080844',
//       type: 'Feature',
//       properties: {
//         fid: 400920,
//         MAPIDCD_NO: '376080844',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999999586695, 37.680000021422309],
//             [126.86999999079426, 37.67500004212495],
//             [126.86500003141377, 37.675000011538479],
//             [126.86500004109458, 37.679999991433235],
//             [126.86999999586695, 37.680000021422309]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080843',
//       type: 'Feature',
//       properties: {
//         fid: 400922,
//         MAPIDCD_NO: '376080843',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500004109458, 37.679999991433235],
//             [126.86500003141377, 37.675000011538479],
//             [126.85999995937831, 37.675000038744486],
//             [126.85999997367416, 37.680000017424625],
//             [126.86500004109458, 37.679999991433235]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080842',
//       type: 'Feature',
//       properties: {
//         fid: 400925,
//         MAPIDCD_NO: '376080842',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999997367416, 37.680000017424625],
//             [126.85999995937831, 37.675000038744486],
//             [126.85500000225208, 37.675000034840025],
//             [126.85500001888646, 37.68000001408646],
//             [126.85999997367416, 37.680000017424625]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080841',
//       type: 'Feature',
//       properties: {
//         fid: 400927,
//         MAPIDCD_NO: '376080841',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500001888646, 37.68000001408646],
//             [126.85500000225208, 37.675000034840025],
//             [126.85000004439029, 37.674999999201809],
//             [126.84999995224563, 37.679999976589947],
//             [126.85500001888646, 37.68000001408646]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080750',
//       type: 'Feature',
//       properties: {
//         fid: 400929,
//         MAPIDCD_NO: '376080750',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999995224563, 37.679999976589947],
//             [126.85000004439029, 37.674999999201809],
//             [126.84499997162408, 37.675000021327584],
//             [126.84499999976065, 37.67999999811061],
//             [126.84999995224563, 37.679999976589947]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080749',
//       type: 'Feature',
//       properties: {
//         fid: 400931,
//         MAPIDCD_NO: '376080749',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499999976065, 37.67999999811061],
//             [126.84499997162408, 37.675000021327584],
//             [126.84000001608692, 37.675000010566073],
//             [126.84000004654359, 37.679999989689655],
//             [126.84499999976065, 37.67999999811061]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080748',
//       type: 'Feature',
//       properties: {
//         fid: 400933,
//         MAPIDCD_NO: '376080748',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000004654359, 37.679999989689655],
//             [126.84000001608692, 37.675000010566073],
//             [126.83499994417429, 37.674999969219684],
//             [126.83499998070582, 37.680000037222563],
//             [126.84000004654359, 37.679999989689655]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080747',
//       type: 'Feature',
//       properties: {
//         fid: 400936,
//         MAPIDCD_NO: '376080747',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499998070582, 37.680000037222563],
//             [126.83499994417429, 37.674999969219684],
//             [126.82999998869623, 37.674999986925442],
//             [126.83000002838273, 37.679999963517041],
//             [126.83499998070582, 37.680000037222563]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080746',
//       type: 'Feature',
//       properties: {
//         fid: 400938,
//         MAPIDCD_NO: '376080746',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000002838273, 37.679999963517041],
//             [126.82999998869623, 37.674999986925442],
//             [126.82500003255534, 37.674999971092276],
//             [126.82499996262685, 37.680000037685041],
//             [126.83000002838273, 37.679999963517041]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080745',
//       type: 'Feature',
//       properties: {
//         fid: 400941,
//         MAPIDCD_NO: '376080745',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499996262685, 37.680000037685041],
//             [126.82500003255534, 37.674999971092276],
//             [126.81999996152061, 37.675000014798528],
//             [126.8200000126856, 37.679999990636368],
//             [126.82499996262685, 37.680000037685041]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080744',
//       type: 'Feature',
//       properties: {
//         fid: 400943,
//         MAPIDCD_NO: '376080744',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8200000126856, 37.679999990636368],
//             [126.81999996152061, 37.675000014798528],
//             [126.81500000548684, 37.675000027429213],
//             [126.8149999478519, 37.6800000031124],
//             [126.8200000126856, 37.679999990636368]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080743',
//       type: 'Feature',
//       properties: {
//         fid: 400946,
//         MAPIDCD_NO: '376080743',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8149999478519, 37.6800000031124],
//             [126.81500000548684, 37.675000027429213],
//             [126.81000005109468, 37.675000006532528],
//             [126.80999999579065, 37.6799999826923],
//             [126.8149999478519, 37.6800000031124]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080742',
//       type: 'Feature',
//       properties: {
//         fid: 400948,
//         MAPIDCD_NO: '376080742',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999999579065, 37.6799999826923],
//             [126.81000005109468, 37.675000006532528],
//             [126.80499998182677, 37.675000045159038],
//             [126.805000046797, 37.680000020678236],
//             [126.80999999579065, 37.6799999826923]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080741',
//       type: 'Feature',
//       properties: {
//         fid: 400950,
//         MAPIDCD_NO: '376080741',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.805000046797, 37.680000020678236],
//             [126.80499998182677, 37.675000045159038],
//             [126.80000002845939, 37.674999960793365],
//             [126.79999998151271, 37.68000002622923],
//             [126.805000046797, 37.680000020678236]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080650',
//       type: 'Feature',
//       properties: {
//         fid: 400953,
//         MAPIDCD_NO: '376080650',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999998151271, 37.68000002622923],
//             [126.80000002845939, 37.674999960793365],
//             [126.79499995938366, 37.675000026067245],
//             [126.79500003355628, 37.680000000726494],
//             [126.79999998151271, 37.68000002622923]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080649',
//       type: 'Feature',
//       properties: {
//         fid: 400956,
//         MAPIDCD_NO: '376080649',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500003355628, 37.680000000726494],
//             [126.79499995938366, 37.675000026067245],
//             [126.79000000624066, 37.67499996835776],
//             [126.78999997075063, 37.680000032918642],
//             [126.79500003355628, 37.680000000726494]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080648',
//       type: 'Feature',
//       properties: {
//         fid: 400958,
//         MAPIDCD_NO: '376080648',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999997075063, 37.680000032918642],
//             [126.79000000624066, 37.67499996835776],
//             [126.7850000539092, 37.674999970850891],
//             [126.78500002075678, 37.680000034038571],
//             [126.78999997075063, 37.680000032918642]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080647',
//       type: 'Feature',
//       properties: {
//         fid: 400960,
//         MAPIDCD_NO: '376080647',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500002075678, 37.680000034038571],
//             [126.7850000539092, 37.674999970850891],
//             [126.77999998676272, 37.675000029234859],
//             [126.7799999590611, 37.680000002729294],
//             [126.78500002075678, 37.680000034038571]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080646',
//       type: 'Feature',
//       properties: {
//         fid: 400962,
//         MAPIDCD_NO: '376080646',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7799999590611, 37.680000002729294],
//             [126.77999998676272, 37.675000029234859],
//             [126.77500003330074, 37.674999968240073],
//             [126.77500001160004, 37.680000030497041],
//             [126.7799999590611, 37.680000002729294]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080645',
//       type: 'Feature',
//       properties: {
//         fid: 400965,
//         MAPIDCD_NO: '376080645',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500001160004, 37.680000030497041],
//             [126.77500003330074, 37.674999968240073],
//             [126.76999996958006, 37.674999963153205],
//             [126.76999995018952, 37.680000025811111],
//             [126.77500001160004, 37.680000030497041]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080644',
//       type: 'Feature',
//       properties: {
//         fid: 400968,
//         MAPIDCD_NO: '376080644',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76999995018952, 37.680000025811111],
//             [126.76999996958006, 37.674999963153205],
//             [126.76500001555954, 37.675000017132959],
//             [126.76500000391063, 37.67999999008412],
//             [126.76999995018952, 37.680000025811111]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080643',
//       type: 'Feature',
//       properties: {
//         fid: 400970,
//         MAPIDCD_NO: '376080643',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76500000391063, 37.67999999008412],
//             [126.76500001555954, 37.675000017132959],
//             [126.75999995213633, 37.675000040488456],
//             [126.76000005621768, 37.680000012730794],
//             [126.76500000391063, 37.67999999008412]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080642',
//       type: 'Feature',
//       properties: {
//         fid: 400973,
//         MAPIDCD_NO: '376080642',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76000005621768, 37.680000012730794],
//             [126.75999995213633, 37.675000040488456],
//             [126.75500000160787, 37.675000031004053],
//             [126.7549999968799, 37.680000002921894],
//             [126.76000005621768, 37.680000012730794]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080641',
//       type: 'Feature',
//       properties: {
//         fid: 400975,
//         MAPIDCD_NO: '376080641',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7549999968799, 37.680000002921894],
//             [126.75500000160787, 37.675000031004053],
//             [126.75000005058176, 37.674999989775017],
//             [126.7500000504598, 37.6799999602681],
//             [126.7549999968799, 37.680000002921894]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081039',
//       type: 'Feature',
//       properties: {
//         fid: 401332,
//         MAPIDCD_NO: '376081039',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.99500004641472, 37.68500000518813],
//             [126.99500004278731, 37.680000025955572],
//             [126.98999997192252, 37.679999977600758],
//             [126.98999998014465, 37.684999955866779],
//             [126.99500004641472, 37.68500000518813]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081038',
//       type: 'Feature',
//       properties: {
//         fid: 401334,
//         MAPIDCD_NO: '376081038',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98999998014465, 37.684999955866779],
//             [126.98999997192252, 37.679999977600758],
//             [126.98500001505536, 37.679999988141518],
//             [126.98500002786683, 37.684999967234269],
//             [126.98999998014465, 37.684999955866779]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081037',
//       type: 'Feature',
//       properties: {
//         fid: 401336,
//         MAPIDCD_NO: '376081037',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98500002786683, 37.684999967234269],
//             [126.98500001505536, 37.679999988141518],
//             [126.98000005714165, 37.67999996695162],
//             [126.97999996050183, 37.685000036491864],
//             [126.98500002786683, 37.684999967234269]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081036',
//       type: 'Feature',
//       properties: {
//         fid: 401338,
//         MAPIDCD_NO: '376081036',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97999996050183, 37.685000036491864],
//             [126.98000005714165, 37.67999996695162],
//             [126.97499998641477, 37.680000005468571],
//             [126.9750000084203, 37.684999982581573],
//             [126.97999996050183, 37.685000036491864]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081035',
//       type: 'Feature',
//       properties: {
//         fid: 401340,
//         MAPIDCD_NO: '376081035',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9750000084203, 37.684999982581573],
//             [126.97499998641477, 37.680000005468571],
//             [126.97000002807701, 37.6800000109513],
//             [126.97000005466965, 37.684999988863048],
//             [126.9750000084203, 37.684999982581573]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081034',
//       type: 'Feature',
//       properties: {
//         fid: 401342,
//         MAPIDCD_NO: '376081034',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97000005466965, 37.684999988863048],
//             [126.97000002807701, 37.6800000109513],
//             [126.96499995759425, 37.679999984197785],
//             [126.96499998876571, 37.684999962898921],
//             [126.97000005466965, 37.684999988863048]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081033',
//       type: 'Feature',
//       properties: {
//         fid: 401344,
//         MAPIDCD_NO: '376081033',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96499998876571, 37.684999962898921],
//             [126.96499995759425, 37.679999984197785],
//             [126.95999999886672, 37.680000016351329],
//             [126.96000003463676, 37.68499999402993],
//             [126.96499998876571, 37.684999962898921]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081032',
//       type: 'Feature',
//       properties: {
//         fid: 401346,
//         MAPIDCD_NO: '376081032',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96000003463676, 37.68499999402993],
//             [126.95999999886672, 37.680000016351329],
//             [126.95500004141709, 37.680000016782245],
//             [126.95499996836189, 37.6849999947075],
//             [126.96000003463676, 37.68499999402993]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081031',
//       type: 'Feature',
//       properties: {
//         fid: 401348,
//         MAPIDCD_NO: '376081031',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95499996836189, 37.6849999947075],
//             [126.95500004141709, 37.680000016782245],
//             [126.94999997185896, 37.67999998496142],
//             [126.9500000168038, 37.684999962369595],
//             [126.95499996836189, 37.6849999947075]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080940',
//       type: 'Feature',
//       properties: {
//         fid: 401350,
//         MAPIDCD_NO: '376080940',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9500000168038, 37.684999962369595],
//             [126.94999997185896, 37.67999998496142],
//             [126.9450000140739, 37.680000012060582],
//             [126.94499995019216, 37.684999989687107],
//             [126.9500000168038, 37.684999962369595]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080939',
//       type: 'Feature',
//       properties: {
//         fid: 401352,
//         MAPIDCD_NO: '376080939',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94499995019216, 37.684999989687107],
//             [126.9450000140739, 37.680000012060582],
//             [126.93999994420067, 37.680000006897586],
//             [126.93999999831748, 37.684999983997947],
//             [126.94499995019216, 37.684999989687107]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080933',
//       type: 'Feature',
//       properties: {
//         fid: 401364,
//         MAPIDCD_NO: '376080933',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500000997215, 37.685000022714078],
//             [126.91500004635701, 37.680000044405126],
//             [126.90999997870766, 37.680000029090948],
//             [126.90999994463938, 37.685000006267892],
//             [126.91500000997215, 37.685000022714078]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080932',
//       type: 'Feature',
//       properties: {
//         fid: 401367,
//         MAPIDCD_NO: '376080932',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999994463938, 37.685000006267892],
//             [126.90999997870766, 37.680000029090948],
//             [126.9050000213266, 37.679999982590331],
//             [126.9049999941181, 37.684999958648717],
//             [126.90999994463938, 37.685000006267892]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080931',
//       type: 'Feature',
//       properties: {
//         fid: 401369,
//         MAPIDCD_NO: '376080931',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9049999941181, 37.684999958648717],
//             [126.9050000213266, 37.679999982590331],
//             [126.89999995351954, 37.679999992124024],
//             [126.90000004202095, 37.684999969411066],
//             [126.9049999941181, 37.684999958648717]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080840',
//       type: 'Feature',
//       properties: {
//         fid: 401371,
//         MAPIDCD_NO: '376080840',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000004202095, 37.684999969411066],
//             [126.89999995351954, 37.679999992124024],
//             [126.8949999959864, 37.679999972282189],
//             [126.894999977205, 37.685000037984011],
//             [126.90000004202095, 37.684999969411066]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080839',
//       type: 'Feature',
//       properties: {
//         fid: 401373,
//         MAPIDCD_NO: '376080839',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.894999977205, 37.685000037984011],
//             [126.8949999959864, 37.679999972282189],
//             [126.89000004143689, 37.680000009050765],
//             [126.89000002796254, 37.684999985268391],
//             [126.894999977205, 37.685000037984011]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080838',
//       type: 'Feature',
//       properties: {
//         fid: 401376,
//         MAPIDCD_NO: '376080838',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000002796254, 37.684999985268391],
//             [126.89000004143689, 37.680000009050765],
//             [126.88499997264654, 37.680000015290929],
//             [126.88499996373862, 37.684999992143773],
//             [126.89000002796254, 37.684999985268391]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080837',
//       type: 'Feature',
//       properties: {
//         fid: 401378,
//         MAPIDCD_NO: '376080837',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499996373862, 37.684999992143773],
//             [126.88499997264654, 37.680000015290929],
//             [126.88000001871642, 37.679999990389405],
//             [126.88000001212858, 37.684999966053745],
//             [126.88499996373862, 37.684999992143773]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080836',
//       type: 'Feature',
//       properties: {
//         fid: 401380,
//         MAPIDCD_NO: '376080836',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000001212858, 37.684999966053745],
//             [126.88000001871642, 37.679999990389405],
//             [126.8749999498515, 37.680000021472331],
//             [126.87499994782841, 37.684999997753138],
//             [126.88000001212858, 37.684999966053745]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080835',
//       type: 'Feature',
//       properties: {
//         fid: 401383,
//         MAPIDCD_NO: '376080835',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499994782841, 37.684999997753138],
//             [126.8749999498515, 37.680000021472331],
//             [126.86999999586695, 37.680000021422309],
//             [126.86999999841547, 37.684999998310317],
//             [126.87499994782841, 37.684999997753138]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080834',
//       type: 'Feature',
//       properties: {
//         fid: 401385,
//         MAPIDCD_NO: '376080834',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999999841547, 37.684999998310317],
//             [126.86999999586695, 37.680000021422309],
//             [126.86500004109458, 37.679999991433235],
//             [126.86500004824448, 37.684999965314],
//             [126.86999999841547, 37.684999998310317]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080833',
//       type: 'Feature',
//       properties: {
//         fid: 401387,
//         MAPIDCD_NO: '376080833',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500004824448, 37.684999965314],
//             [126.86500004109458, 37.679999991433235],
//             [126.85999997367416, 37.680000017424625],
//             [126.85999998538644, 37.684999991893832],
//             [126.86500004824448, 37.684999965314]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080832',
//       type: 'Feature',
//       properties: {
//         fid: 401390,
//         MAPIDCD_NO: '376080832',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999998538644, 37.684999991893832],
//             [126.85999997367416, 37.680000017424625],
//             [126.85500001888646, 37.68000001408646],
//             [126.85500003745159, 37.684999987344732],
//             [126.85999998538644, 37.684999991893832]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080831',
//       type: 'Feature',
//       properties: {
//         fid: 401392,
//         MAPIDCD_NO: '376080831',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500003745159, 37.684999987344732],
//             [126.85500001888646, 37.68000001408646],
//             [126.84999995224563, 37.679999976589947],
//             [126.84999997458503, 37.685000040546178],
//             [126.85500003745159, 37.684999987344732]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080740',
//       type: 'Feature',
//       properties: {
//         fid: 401394,
//         MAPIDCD_NO: '376080740',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999997458503, 37.685000040546178],
//             [126.84999995224563, 37.679999976589947],
//             [126.84499999976065, 37.67999999811061],
//             [126.84500002518593, 37.684999972486843],
//             [126.84999997458503, 37.685000040546178]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080739',
//       type: 'Feature',
//       properties: {
//         fid: 401396,
//         MAPIDCD_NO: '376080739',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500002518593, 37.684999972486843],
//             [126.84499999976065, 37.67999999811061],
//             [126.84000004654359, 37.679999989689655],
//             [126.83999996314363, 37.684999962179859],
//             [126.84500002518593, 37.684999972486843]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080738',
//       type: 'Feature',
//       properties: {
//         fid: 401398,
//         MAPIDCD_NO: '376080738',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999996314363, 37.684999962179859],
//             [126.84000004654359, 37.679999989689655],
//             [126.83499998070582, 37.680000037222563],
//             [126.8350000152717, 37.685000010889219],
//             [126.83999996314363, 37.684999962179859]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080737',
//       type: 'Feature',
//       properties: {
//         fid: 401401,
//         MAPIDCD_NO: '376080737',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8350000152717, 37.685000010889219],
//             [126.83499998070582, 37.680000037222563],
//             [126.83000002838273, 37.679999963517041],
//             [126.82999995556345, 37.685000027212531],
//             [126.8350000152717, 37.685000010889219]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080736',
//       type: 'Feature',
//       properties: {
//         fid: 401403,
//         MAPIDCD_NO: '376080736',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999995556345, 37.685000027212531],
//             [126.83000002838273, 37.679999963517041],
//             [126.82499996262685, 37.680000037685041],
//             [126.82500000633017, 37.685000010604838],
//             [126.82999995556345, 37.685000027212531]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080735',
//       type: 'Feature',
//       properties: {
//         fid: 401406,
//         MAPIDCD_NO: '376080735',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500000633017, 37.685000010604838],
//             [126.82499996262685, 37.680000037685041],
//             [126.8200000126856, 37.679999990636368],
//             [126.81999994754497, 37.684999963416153],
//             [126.82500000633017, 37.685000010604838]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080734',
//       type: 'Feature',
//       properties: {
//         fid: 401408,
//         MAPIDCD_NO: '376080734',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999994754497, 37.684999963416153],
//             [126.8200000126856, 37.679999990636368],
//             [126.8149999478519, 37.6800000031124],
//             [126.81500000068962, 37.684999975248033],
//             [126.81999994754497, 37.684999963416153]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080733',
//       type: 'Feature',
//       properties: {
//         fid: 401411,
//         MAPIDCD_NO: '376080733',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500000068962, 37.684999975248033],
//             [126.8149999478519, 37.6800000031124],
//             [126.80999999579065, 37.6799999826923],
//             [126.81000005237145, 37.685000043647911],
//             [126.81500000068962, 37.684999975248033]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080732',
//       type: 'Feature',
//       properties: {
//         fid: 401413,
//         MAPIDCD_NO: '376080732',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000005237145, 37.685000043647911],
//             [126.80999999579065, 37.6799999826923],
//             [126.805000046797, 37.680000020678236],
//             [126.80499999309835, 37.684999991310683],
//             [126.81000005237145, 37.685000043647911]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080731',
//       type: 'Feature',
//       properties: {
//         fid: 401415,
//         MAPIDCD_NO: '376080731',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499999309835, 37.684999991310683],
//             [126.805000046797, 37.680000020678236],
//             [126.79999998151271, 37.68000002622923],
//             [126.8000000480579, 37.684999996217321],
//             [126.80499999309835, 37.684999991310683]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080640',
//       type: 'Feature',
//       properties: {
//         fid: 401418,
//         MAPIDCD_NO: '376080640',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8000000480579, 37.684999996217321],
//             [126.79999998151271, 37.68000002622923],
//             [126.79500003355628, 37.680000000726494],
//             [126.79499998898557, 37.684999970490274],
//             [126.8000000480579, 37.684999996217321]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080639',
//       type: 'Feature',
//       properties: {
//         fid: 401421,
//         MAPIDCD_NO: '376080639',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499998898557, 37.684999970490274],
//             [126.79500003355628, 37.680000000726494],
//             [126.78999997075063, 37.680000032918642],
//             [126.79000004413679, 37.6850000038181],
//             [126.79499998898557, 37.684999970490274]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080638',
//       type: 'Feature',
//       properties: {
//         fid: 401423,
//         MAPIDCD_NO: '376080638',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000004413679, 37.6850000038181],
//             [126.78999997075063, 37.680000032918642],
//             [126.78500002075678, 37.680000034038571],
//             [126.78499998757879, 37.6850000029101],
//             [126.79000004413679, 37.6850000038181]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080637',
//       type: 'Feature',
//       properties: {
//         fid: 401425,
//         MAPIDCD_NO: '376080637',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78499998757879, 37.6850000029101],
//             [126.78500002075678, 37.680000034038571],
//             [126.7799999590611, 37.680000002729294],
//             [126.780000043838, 37.684999972727574],
//             [126.78499998757879, 37.6850000029101]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080636',
//       type: 'Feature',
//       properties: {
//         fid: 401427,
//         MAPIDCD_NO: '376080636',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.780000043838, 37.684999972727574],
//             [126.7799999590611, 37.680000002729294],
//             [126.77500001160004, 37.680000030497041],
//             [126.77499998527526, 37.6849999984115],
//             [126.780000043838, 37.684999972727574]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080635',
//       type: 'Feature',
//       properties: {
//         fid: 401430,
//         MAPIDCD_NO: '376080635',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77499998527526, 37.6849999984115],
//             [126.77500001160004, 37.680000030497041],
//             [126.76999995018952, 37.680000025811111],
//             [126.77000004408565, 37.684999994857208],
//             [126.77499998527526, 37.6849999984115]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080634',
//       type: 'Feature',
//       properties: {
//         fid: 401433,
//         MAPIDCD_NO: '376080634',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000004408565, 37.684999994857208],
//             [126.76999995018952, 37.680000025811111],
//             [126.76500000391063, 37.67999999008412],
//             [126.76499998670386, 37.684999957017965],
//             [126.77000004408565, 37.684999994857208]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080633',
//       type: 'Feature',
//       properties: {
//         fid: 401435,
//         MAPIDCD_NO: '376080633',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76499998670386, 37.684999957017965],
//             [126.76500000391063, 37.67999999008412],
//             [126.76000005621768, 37.680000012730794],
//             [126.76000004583146, 37.68499998007583],
//             [126.76499998670386, 37.684999957017965]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080632',
//       type: 'Feature',
//       properties: {
//         fid: 401438,
//         MAPIDCD_NO: '376080632',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76000004583146, 37.68499998007583],
//             [126.76000005621768, 37.680000012730794],
//             [126.7549999968799, 37.680000002921894],
//             [126.75499998878756, 37.684999968837758],
//             [126.76000004583146, 37.68499998007583]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080631',
//       type: 'Feature',
//       properties: {
//         fid: 401440,
//         MAPIDCD_NO: '376080631',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75499998878756, 37.684999968837758],
//             [126.7549999968799, 37.680000002921894],
//             [126.7500000504598, 37.6799999602681],
//             [126.75000004827869, 37.685000016702823],
//             [126.75499998878756, 37.684999968837758]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081027',
//       type: 'Feature',
//       properties: {
//         fid: 401801,
//         MAPIDCD_NO: '376081027',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.98500004068781, 37.690000032244171],
//             [126.98500002786683, 37.684999967234269],
//             [126.97999996050183, 37.685000036491864],
//             [126.97999997850214, 37.690000010386889],
//             [126.98500004068781, 37.690000032244171]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081026',
//       type: 'Feature',
//       properties: {
//         fid: 401803,
//         MAPIDCD_NO: '376081026',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97999997850214, 37.690000010386889],
//             [126.97999996050183, 37.685000036491864],
//             [126.9750000084203, 37.684999982581573],
//             [126.97500003097125, 37.689999957285252],
//             [126.97999997850214, 37.690000010386889]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081025',
//       type: 'Feature',
//       properties: {
//         fid: 401805,
//         MAPIDCD_NO: '376081025',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.97500003097125, 37.689999957285252],
//             [126.9750000084203, 37.684999982581573],
//             [126.97000005466965, 37.684999988863048],
//             [126.96999996836513, 37.689999962053705],
//             [126.97500003097125, 37.689999957285252]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081024',
//       type: 'Feature',
//       properties: {
//         fid: 401807,
//         MAPIDCD_NO: '376081024',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96999996836513, 37.689999962053705],
//             [126.97000005466965, 37.684999988863048],
//             [126.96499998876571, 37.684999962898921],
//             [126.96500001978617, 37.690000025716458],
//             [126.96999996836513, 37.689999962053705]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081023',
//       type: 'Feature',
//       properties: {
//         fid: 401809,
//         MAPIDCD_NO: '376081023',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96500001978617, 37.690000025716458],
//             [126.96499998876571, 37.684999962898921],
//             [126.96000003463676, 37.68499999402993],
//             [126.95999995742211, 37.689999968781493],
//             [126.96500001978617, 37.690000025716458]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081022',
//       type: 'Feature',
//       properties: {
//         fid: 401811,
//         MAPIDCD_NO: '376081022',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95999995742211, 37.689999968781493],
//             [126.96000003463676, 37.68499999402993],
//             [126.95499996836189, 37.6849999947075],
//             [126.95500000911815, 37.68999996894707],
//             [126.95999995742211, 37.689999968781493]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081021',
//       type: 'Feature',
//       properties: {
//         fid: 401813,
//         MAPIDCD_NO: '376081021',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95500000911815, 37.68999996894707],
//             [126.95499996836189, 37.6849999947075],
//             [126.9500000168038, 37.684999962369595],
//             [126.94999994802365, 37.690000026971809],
//             [126.95500000911815, 37.68999996894707]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080930',
//       type: 'Feature',
//       properties: {
//         fid: 401815,
//         MAPIDCD_NO: '376080930',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.94999994802365, 37.690000026971809],
//             [126.9500000168038, 37.684999962369595],
//             [126.94499995019216, 37.684999989687107],
//             [126.94500000004706, 37.689999963638677],
//             [126.94999994802365, 37.690000026971809]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080924',
//       type: 'Feature',
//       properties: {
//         fid: 401827,
//         MAPIDCD_NO: '376080924',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000003361355, 37.68999997901345],
//             [126.91999996329578, 37.685000005055009],
//             [126.91500000997215, 37.685000022714078],
//             [126.91499997369671, 37.689999995015128],
//             [126.92000003361355, 37.68999997901345]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080923',
//       type: 'Feature',
//       properties: {
//         fid: 401829,
//         MAPIDCD_NO: '376080923',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499997369671, 37.689999995015128],
//             [126.91500000997215, 37.685000022714078],
//             [126.90999994463938, 37.685000006267892],
//             [126.91000002631397, 37.689999979818879],
//             [126.91499997369671, 37.689999995015128]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080922',
//       type: 'Feature',
//       properties: {
//         fid: 401832,
//         MAPIDCD_NO: '376080922',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000002631397, 37.689999979818879],
//             [126.90999994463938, 37.685000006267892],
//             [126.9049999941181, 37.684999958648717],
//             [126.90499996394185, 37.690000020620381],
//             [126.91000002631397, 37.689999979818879]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080921',
//       type: 'Feature',
//       properties: {
//         fid: 401834,
//         MAPIDCD_NO: '376080921',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499996394185, 37.690000020620381],
//             [126.9049999941181, 37.684999958648717],
//             [126.90000004202095, 37.684999969411066],
//             [126.90000001864608, 37.690000032057974],
//             [126.90499996394185, 37.690000020620381]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080830',
//       type: 'Feature',
//       properties: {
//         fid: 401836,
//         MAPIDCD_NO: '376080830',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000001864608, 37.690000032057974],
//             [126.90000004202095, 37.684999969411066],
//             [126.894999977205, 37.685000037984011],
//             [126.89499995681862, 37.690000011144875],
//             [126.90000001864608, 37.690000032057974]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080829',
//       type: 'Feature',
//       properties: {
//         fid: 401838,
//         MAPIDCD_NO: '376080829',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499995681862, 37.690000011144875],
//             [126.894999977205, 37.685000037984011],
//             [126.89000002796254, 37.684999985268391],
//             [126.89000001212854, 37.689999957271851],
//             [126.89499995681862, 37.690000011144875]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080828',
//       type: 'Feature',
//       properties: {
//         fid: 401840,
//         MAPIDCD_NO: '376080828',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000001212854, 37.689999957271851],
//             [126.89000002796254, 37.684999985268391],
//             [126.88499996373862, 37.684999992143773],
//             [126.88499995244864, 37.689999962980409],
//             [126.89000001212854, 37.689999957271851]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080827',
//       type: 'Feature',
//       properties: {
//         fid: 401843,
//         MAPIDCD_NO: '376080827',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499995244864, 37.689999962980409],
//             [126.88499996373862, 37.684999992143773],
//             [126.88000001212858, 37.684999966053745],
//             [126.88000000462419, 37.69000002764534],
//             [126.88499995244864, 37.689999962980409]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080826',
//       type: 'Feature',
//       properties: {
//         fid: 401845,
//         MAPIDCD_NO: '376080826',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000000462419, 37.69000002764534],
//             [126.88000001212858, 37.684999966053745],
//             [126.87499994782841, 37.684999997753138],
//             [126.87499994561694, 37.689999968030733],
//             [126.88000000462419, 37.69000002764534]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080825',
//       type: 'Feature',
//       properties: {
//         fid: 401848,
//         MAPIDCD_NO: '376080825',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499994561694, 37.689999968030733],
//             [126.87499994782841, 37.684999997753138],
//             [126.86999999841547, 37.684999998310317],
//             [126.87000000073842, 37.689999969195732],
//             [126.87499994561694, 37.689999968030733]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080824',
//       type: 'Feature',
//       properties: {
//         fid: 401850,
//         MAPIDCD_NO: '376080824',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000000073842, 37.689999969195732],
//             [126.86999999841547, 37.684999998310317],
//             [126.86500004824448, 37.684999965314],
//             [126.86500005660069, 37.69000002693835],
//             [126.87000000073842, 37.689999969195732]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080823',
//       type: 'Feature',
//       properties: {
//         fid: 401852,
//         MAPIDCD_NO: '376080823',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86500005660069, 37.69000002693835],
//             [126.86500004824448, 37.684999965314],
//             [126.85999998538644, 37.684999991893832],
//             [126.85999999676784, 37.689999963966834],
//             [126.86500005660069, 37.69000002693835]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080822',
//       type: 'Feature',
//       properties: {
//         fid: 401855,
//         MAPIDCD_NO: '376080822',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85999999676784, 37.689999963966834],
//             [126.85999998538644, 37.684999991893832],
//             [126.85500003745159, 37.684999987344732],
//             [126.85500005338034, 37.689999958194996],
//             [126.85999999676784, 37.689999963966834]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080821',
//       type: 'Feature',
//       properties: {
//         fid: 401857,
//         MAPIDCD_NO: '376080821',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500005338034, 37.689999958194996],
//             [126.85500003745159, 37.684999987344732],
//             [126.84999997458503, 37.685000040546178],
//             [126.84999999505276, 37.690000010164255],
//             [126.85500005338034, 37.689999958194996]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080730',
//       type: 'Feature',
//       properties: {
//         fid: 401859,
//         MAPIDCD_NO: '376080730',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999999505276, 37.690000010164255],
//             [126.84999997458503, 37.685000040546178],
//             [126.84500002518593, 37.684999972486843],
//             [126.84500004939109, 37.690000032794011],
//             [126.84999999505276, 37.690000010164255]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080729',
//       type: 'Feature',
//       properties: {
//         fid: 401861,
//         MAPIDCD_NO: '376080729',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500004939109, 37.690000032794011],
//             [126.84500002518593, 37.684999972486843],
//             [126.83999996314363, 37.684999962179859],
//             [126.83999999188035, 37.690000021236138],
//             [126.84500004939109, 37.690000032794011]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080728',
//       type: 'Feature',
//       properties: {
//         fid: 401863,
//         MAPIDCD_NO: '376080728',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999999188035, 37.690000021236138],
//             [126.83999996314363, 37.684999962179859],
//             [126.8350000152717, 37.685000010889219],
//             [126.83500004935165, 37.689999978557658],
//             [126.83999999188035, 37.690000021236138]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080727',
//       type: 'Feature',
//       properties: {
//         fid: 401866,
//         MAPIDCD_NO: '376080727',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500004935165, 37.689999978557658],
//             [126.8350000152717, 37.685000010889219],
//             [126.82999995556345, 37.685000027212531],
//             [126.82999999189526, 37.689999995401166],
//             [126.83500004935165, 37.689999978557658]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080726',
//       type: 'Feature',
//       properties: {
//         fid: 401868,
//         MAPIDCD_NO: '376080726',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999999189526, 37.689999995401166],
//             [126.82999995556345, 37.685000027212531],
//             [126.82500000633017, 37.685000010604838],
//             [126.82500004945689, 37.689999979330238],
//             [126.82999999189526, 37.689999995401166]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080725',
//       type: 'Feature',
//       properties: {
//         fid: 401871,
//         MAPIDCD_NO: '376080725',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500004945689, 37.689999979330238],
//             [126.82500000633017, 37.685000010604838],
//             [126.81999994754497, 37.684999963416153],
//             [126.81999999438352, 37.690000020980882],
//             [126.82500004945689, 37.689999979330238]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080724',
//       type: 'Feature',
//       properties: {
//         fid: 401873,
//         MAPIDCD_NO: '376080724',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999999438352, 37.690000020980882],
//             [126.81999994754497, 37.684999963416153],
//             [126.81500000068962, 37.684999975248033],
//             [126.81500005204711, 37.690000033317823],
//             [126.81999999438352, 37.690000020980882]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080723',
//       type: 'Feature',
//       properties: {
//         fid: 401876,
//         MAPIDCD_NO: '376080723',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500005204711, 37.690000033317823],
//             [126.81500000068962, 37.684999975248033],
//             [126.81000005237145, 37.685000043647911],
//             [126.8099999956672, 37.690000011422583],
//             [126.81500005204711, 37.690000033317823]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080722',
//       type: 'Feature',
//       properties: {
//         fid: 401878,
//         MAPIDCD_NO: '376080722',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8099999956672, 37.690000011422583],
//             [126.81000005237145, 37.685000043647911],
//             [126.80499999309835, 37.684999991310683],
//             [126.80500005434109, 37.689999958432949],
//             [126.8099999956672, 37.690000011422583]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080721',
//       type: 'Feature',
//       properties: {
//         fid: 401880,
//         MAPIDCD_NO: '376080721',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500005434109, 37.689999958432949],
//             [126.80499999309835, 37.684999991310683],
//             [126.8000000480579, 37.684999996217321],
//             [126.80000000040859, 37.689999963143535],
//             [126.80500005434109, 37.689999958432949]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080630',
//       type: 'Feature',
//       properties: {
//         fid: 401883,
//         MAPIDCD_NO: '376080630',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000000040859, 37.689999963143535],
//             [126.8000000480579, 37.684999996217321],
//             [126.79499998898557, 37.684999970490274],
//             [126.79499994501394, 37.690000026203876],
//             [126.80000000040859, 37.689999963143535]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080629',
//       type: 'Feature',
//       properties: {
//         fid: 401885,
//         MAPIDCD_NO: '376080629',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499994501394, 37.690000026203876],
//             [126.79499998898557, 37.684999970490274],
//             [126.79000004413679, 37.6850000038181],
//             [126.79000000555622, 37.689999968055943],
//             [126.79499994501394, 37.690000026203876]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080628',
//       type: 'Feature',
//       properties: {
//         fid: 401888,
//         MAPIDCD_NO: '376080628',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000000555622, 37.689999968055943],
//             [126.79000004413679, 37.6850000038181],
//             [126.78499998757879, 37.6850000029101],
//             [126.7849999512249, 37.689999969381041],
//             [126.79000000555622, 37.689999968055943]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080627',
//       type: 'Feature',
//       properties: {
//         fid: 401890,
//         MAPIDCD_NO: '376080627',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7849999512249, 37.689999969381041],
//             [126.78499998757879, 37.6850000029101],
//             [126.780000043838, 37.684999972727574],
//             [126.78000001341718, 37.690000027971458],
//             [126.7849999512249, 37.689999969381041]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080626',
//       type: 'Feature',
//       properties: {
//         fid: 401892,
//         MAPIDCD_NO: '376080626',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000001341718, 37.690000027971458],
//             [126.780000043838, 37.684999972727574],
//             [126.77499998527526, 37.6849999984115],
//             [126.774999960236, 37.689999963954392],
//             [126.78000001341718, 37.690000027971458]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080625',
//       type: 'Feature',
//       properties: {
//         fid: 401895,
//         MAPIDCD_NO: '376080625',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.774999960236, 37.689999963954392],
//             [126.77499998527526, 37.6849999984115],
//             [126.77000004408565, 37.684999994857208],
//             [126.77000002131283, 37.689999958999934],
//             [126.774999960236, 37.689999963954392]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080624',
//       type: 'Feature',
//       properties: {
//         fid: 401898,
//         MAPIDCD_NO: '376080624',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000002131283, 37.689999958999934],
//             [126.77000004408565, 37.684999994857208],
//             [126.76499998670386, 37.684999957017965],
//             [126.76499996981816, 37.690000011708108],
//             [126.77000002131283, 37.689999958999934]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080623',
//       type: 'Feature',
//       properties: {
//         fid: 401900,
//         MAPIDCD_NO: '376080623',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76499996981816, 37.690000011708108],
//             [126.76499998670386, 37.684999957017965],
//             [126.76000004583146, 37.68499998007583],
//             [126.76000003120453, 37.690000033346827],
//             [126.76499996981816, 37.690000011708108]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080622',
//       type: 'Feature',
//       properties: {
//         fid: 401903,
//         MAPIDCD_NO: '376080622',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76000003120453, 37.690000033346827],
//             [126.76000004583146, 37.68499998007583],
//             [126.75499998878756, 37.684999968837758],
//             [126.7549999809293, 37.690000022511242],
//             [126.76000003120453, 37.690000033346827]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080621',
//       type: 'Feature',
//       properties: {
//         fid: 401905,
//         MAPIDCD_NO: '376080621',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7549999809293, 37.690000022511242],
//             [126.75499998878756, 37.684999968837758],
//             [126.75000004827869, 37.685000016702823],
//             [126.75000004358523, 37.689999978812288],
//             [126.7549999809293, 37.690000022511242]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081014',
//       type: 'Feature',
//       properties: {
//         fid: 402272,
//         MAPIDCD_NO: '376081014',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96999999533783, 37.695000023474911],
//             [126.96999996836513, 37.689999962053705],
//             [126.96500001978617, 37.690000025716458],
//             [126.96500004965651, 37.694999995985391],
//             [126.96999999533783, 37.695000023474911]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081013',
//       type: 'Feature',
//       properties: {
//         fid: 402274,
//         MAPIDCD_NO: '376081013',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.96500004965651, 37.694999995985391],
//             [126.96500001978617, 37.690000025716458],
//             [126.95999995742211, 37.689999968781493],
//             [126.95999999342369, 37.695000028168991],
//             [126.96500004965651, 37.694999995985391]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081012',
//       type: 'Feature',
//       properties: {
//         fid: 402276,
//         MAPIDCD_NO: '376081012',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95999999342369, 37.695000028168991],
//             [126.95999995742211, 37.689999968781493],
//             [126.95500000911815, 37.68999996894707],
//             [126.95500004963718, 37.695000027303756],
//             [126.95999999342369, 37.695000028168991]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376081011',
//       type: 'Feature',
//       properties: {
//         fid: 402278,
//         MAPIDCD_NO: '376081011',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.95500004963718, 37.695000027303756],
//             [126.95500000911815, 37.68999996894707],
//             [126.94999994802365, 37.690000026971809],
//             [126.94999999143587, 37.694999995950738],
//             [126.95500004963718, 37.695000027303756]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080914',
//       type: 'Feature',
//       properties: {
//         fid: 402292,
//         MAPIDCD_NO: '376080914',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999998995232, 37.695000038337071],
//             [126.92000003361355, 37.68999997901345],
//             [126.91499997369671, 37.689999995015128],
//             [126.91500004867297, 37.694999963662084],
//             [126.91999998995232, 37.695000038337071]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080913',
//       type: 'Feature',
//       properties: {
//         fid: 402294,
//         MAPIDCD_NO: '376080913',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500004867297, 37.694999963662084],
//             [126.91499997369671, 37.689999995015128],
//             [126.91000002631397, 37.689999979818879],
//             [126.90999999166918, 37.695000036912482],
//             [126.91500004867297, 37.694999963662084]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080912',
//       type: 'Feature',
//       properties: {
//         fid: 402297,
//         MAPIDCD_NO: '376080912',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999999166918, 37.695000036912482],
//             [126.91000002631397, 37.689999979818879],
//             [126.90499996394185, 37.690000020620381],
//             [126.90500004793057, 37.694999988831015],
//             [126.90999999166918, 37.695000036912482]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080911',
//       type: 'Feature',
//       properties: {
//         fid: 402299,
//         MAPIDCD_NO: '376080911',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500004793057, 37.694999988831015],
//             [126.90499996394185, 37.690000020620381],
//             [126.90000001864608, 37.690000032057974],
//             [126.89999999371241, 37.695000000360636],
//             [126.90500004793057, 37.694999988831015]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080820',
//       type: 'Feature',
//       properties: {
//         fid: 402301,
//         MAPIDCD_NO: '376080820',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999999371241, 37.695000000360636],
//             [126.90000001864608, 37.690000032057974],
//             [126.89499995681862, 37.690000011144875],
//             [126.89500004981704, 37.694999978881931],
//             [126.89999999371241, 37.695000000360636]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080819',
//       type: 'Feature',
//       properties: {
//         fid: 402303,
//         MAPIDCD_NO: '376080819',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89500004981704, 37.694999978881931],
//             [126.89499995681862, 37.690000011144875],
//             [126.89000001212854, 37.689999957271851],
//             [126.88999999546491, 37.695000015201281],
//             [126.89500004981704, 37.694999978881931]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080818',
//       type: 'Feature',
//       properties: {
//         fid: 402305,
//         MAPIDCD_NO: '376080818',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88999999546491, 37.695000015201281],
//             [126.89000001212854, 37.689999957271851],
//             [126.88499995244864, 37.689999962980409],
//             [126.88500005370953, 37.695000020335087],
//             [126.88999999546491, 37.695000015201281]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080817',
//       type: 'Feature',
//       properties: {
//         fid: 402308,
//         MAPIDCD_NO: '376080817',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500005370953, 37.695000020335087],
//             [126.88499995244864, 37.689999962980409],
//             [126.88000000462419, 37.69000002764534],
//             [126.87999999771911, 37.69499999310441],
//             [126.88500005370953, 37.695000020335087]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080816',
//       type: 'Feature',
//       properties: {
//         fid: 402310,
//         MAPIDCD_NO: '376080816',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999999771911, 37.69499999310441],
//             [126.88000000462419, 37.69000002764534],
//             [126.87499994561694, 37.689999968030733],
//             [126.87500005586944, 37.695000024837015],
//             [126.87999999771911, 37.69499999310441]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080815',
//       type: 'Feature',
//       properties: {
//         fid: 402313,
//         MAPIDCD_NO: '376080815',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500005586944, 37.695000024837015],
//             [126.87499994561694, 37.689999968030733],
//             [126.87000000073842, 37.689999969195732],
//             [126.87000000205818, 37.695000026009176],
//             [126.87500005586944, 37.695000024837015]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080814',
//       type: 'Feature',
//       properties: {
//         fid: 402315,
//         MAPIDCD_NO: '376080814',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000000205818, 37.695000026009176],
//             [126.87000000073842, 37.689999969195732],
//             [126.86500005660069, 37.69000002693835],
//             [126.86499994748728, 37.694999993599609],
//             [126.87000000205818, 37.695000026009176]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080813',
//       type: 'Feature',
//       properties: {
//         fid: 402317,
//         MAPIDCD_NO: '376080813',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499994748728, 37.694999993599609],
//             [126.86500005660069, 37.69000002693835],
//             [126.85999999676784, 37.689999963966834],
//             [126.8600000070748, 37.695000020166326],
//             [126.86499994748728, 37.694999993599609]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080812',
//       type: 'Feature',
//       properties: {
//         fid: 402320,
//         MAPIDCD_NO: '376080812',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8600000070748, 37.695000020166326],
//             [126.85999999676784, 37.689999963966834],
//             [126.85500005338034, 37.689999958194996],
//             [126.85499995248411, 37.695000014342014],
//             [126.8600000070748, 37.695000020166326]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080811',
//       type: 'Feature',
//       properties: {
//         fid: 402322,
//         MAPIDCD_NO: '376080811',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499995248411, 37.695000014342014],
//             [126.85500005338034, 37.689999958194996],
//             [126.84999999505276, 37.690000010164255],
//             [126.85000001284649, 37.694999977374636],
//             [126.85499995248411, 37.695000014342014]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080720',
//       type: 'Feature',
//       properties: {
//         fid: 402324,
//         MAPIDCD_NO: '376080720',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000001284649, 37.694999977374636],
//             [126.84999999505276, 37.690000010164255],
//             [126.84500004939109, 37.690000032794011],
//             [126.84499996053866, 37.694999998146066],
//             [126.85000001284649, 37.694999977374636]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080719',
//       type: 'Feature',
//       properties: {
//         fid: 402326,
//         MAPIDCD_NO: '376080719',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499996053866, 37.694999998146066],
//             [126.84500004939109, 37.690000032794011],
//             [126.83999999188035, 37.690000021236138],
//             [126.84000002095125, 37.694999985968089],
//             [126.84499996053866, 37.694999998146066]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080718',
//       type: 'Feature',
//       properties: {
//         fid: 402328,
//         MAPIDCD_NO: '376080718',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000002095125, 37.694999985968089],
//             [126.83999999188035, 37.690000021236138],
//             [126.83500004935165, 37.689999978557658],
//             [126.834999968686, 37.6950000333207],
//             [126.84000002095125, 37.694999985968089]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080717',
//       type: 'Feature',
//       properties: {
//         fid: 402331,
//         MAPIDCD_NO: '376080717',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.834999968686, 37.6950000333207],
//             [126.83500004935165, 37.689999978557658],
//             [126.82999999189526, 37.689999995401166],
//             [126.83000002995611, 37.694999959407468],
//             [126.834999968686, 37.6950000333207]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080716',
//       type: 'Feature',
//       properties: {
//         fid: 402333,
//         MAPIDCD_NO: '376080716',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000002995611, 37.694999959407468],
//             [126.82999999189526, 37.689999995401166],
//             [126.82500004945689, 37.689999979330238],
//             [126.82499997776755, 37.695000033339156],
//             [126.83000002995611, 37.694999959407468]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080715',
//       type: 'Feature',
//       properties: {
//         fid: 402336,
//         MAPIDCD_NO: '376080715',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499997776755, 37.695000033339156],
//             [126.82500004945689, 37.689999979330238],
//             [126.81999999438352, 37.690000020980882],
//             [126.82000003914632, 37.69499998601362],
//             [126.82499997776755, 37.695000033339156]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080714',
//       type: 'Feature',
//       properties: {
//         fid: 402338,
//         MAPIDCD_NO: '376080714',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000003914632, 37.69499998601362],
//             [126.81999999438352, 37.690000020980882],
//             [126.81500005204711, 37.690000033317823],
//             [126.81499998789165, 37.694999996394849],
//             [126.82000003914632, 37.69499998601362]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080713',
//       type: 'Feature',
//       properties: {
//         fid: 402341,
//         MAPIDCD_NO: '376080713',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499998789165, 37.694999996394849],
//             [126.81500005204711, 37.690000033317823],
//             [126.8099999956672, 37.690000011422583],
//             [126.81000004942989, 37.694999973852326],
//             [126.81499998789165, 37.694999996394849]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080712',
//       type: 'Feature',
//       properties: {
//         fid: 402343,
//         MAPIDCD_NO: '376080712',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000004942989, 37.694999973852326],
//             [126.8099999956672, 37.690000011422583],
//             [126.80500005434109, 37.689999958432949],
//             [126.80500000059482, 37.69500001082168],
//             [126.81000004942989, 37.694999973852326]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080711',
//       type: 'Feature',
//       properties: {
//         fid: 402345,
//         MAPIDCD_NO: '376080711',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500000059482, 37.69500001082168],
//             [126.80500005434109, 37.689999958432949],
//             [126.80000000040859, 37.689999963143535],
//             [126.79999994886531, 37.695000015991688],
//             [126.80500000059482, 37.69500001082168]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080620',
//       type: 'Feature',
//       properties: {
//         fid: 402348,
//         MAPIDCD_NO: '376080620',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999994886531, 37.695000015991688],
//             [126.80000000040859, 37.689999963143535],
//             [126.79499994501394, 37.690000026203876],
//             [126.7950000122334, 37.694999988264151],
//             [126.79999994886531, 37.695000015991688]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080619',
//       type: 'Feature',
//       properties: {
//         fid: 402350,
//         MAPIDCD_NO: '376080619',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7950000122334, 37.694999988264151],
//             [126.79499994501394, 37.690000026203876],
//             [126.79000000555622, 37.689999968055943],
//             [126.78999996297779, 37.695000020019187],
//             [126.7950000122334, 37.694999988264151]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080618',
//       type: 'Feature',
//       properties: {
//         fid: 402353,
//         MAPIDCD_NO: '376080618',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999996297779, 37.695000020019187],
//             [126.79000000555622, 37.689999968055943],
//             [126.7849999512249, 37.689999969381041],
//             [126.78500002882247, 37.695000020688042],
//             [126.78999996297779, 37.695000020019187]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080617',
//       type: 'Feature',
//       properties: {
//         fid: 402355,
//         MAPIDCD_NO: '376080617',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500002882247, 37.695000020688042],
//             [126.7849999512249, 37.689999969381041],
//             [126.78000001341718, 37.690000027971458],
//             [126.77999998068923, 37.694999987083385],
//             [126.78500002882247, 37.695000020688042]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080616',
//       type: 'Feature',
//       properties: {
//         fid: 402357,
//         MAPIDCD_NO: '376080616',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77999998068923, 37.694999987083385],
//             [126.78000001341718, 37.690000027971458],
//             [126.774999960236, 37.689999963954392],
//             [126.77500004452492, 37.6950000143164],
//             [126.77999998068923, 37.694999987083385]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080615',
//       type: 'Feature',
//       properties: {
//         fid: 402359,
//         MAPIDCD_NO: '376080615',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500004452492, 37.6950000143164],
//             [126.774999960236, 37.689999963954392],
//             [126.77000002131283, 37.689999958999934],
//             [126.76999999667167, 37.695000007278928],
//             [126.77500004452492, 37.6950000143164]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080614',
//       type: 'Feature',
//       properties: {
//         fid: 402363,
//         MAPIDCD_NO: '376080614',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76999999667167, 37.695000007278928],
//             [126.77000002131283, 37.689999958999934],
//             [126.76499996981816, 37.690000011708108],
//             [126.76499995053187, 37.694999970268313],
//             [126.76999999667167, 37.695000007278928]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080613',
//       type: 'Feature',
//       properties: {
//         fid: 402365,
//         MAPIDCD_NO: '376080613',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76499995053187, 37.694999970268313],
//             [126.76499996981816, 37.690000011708108],
//             [126.76000003120453, 37.690000033346827],
//             [126.76000001641393, 37.694999990502829],
//             [126.76499995053187, 37.694999970268313]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080612',
//       type: 'Feature',
//       properties: {
//         fid: 402368,
//         MAPIDCD_NO: '376080612',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76000001641393, 37.694999990502829],
//             [126.76000003120453, 37.690000033346827],
//             [126.7549999809293, 37.690000022511242],
//             [126.75499997060817, 37.694999980056167],
//             [126.76000001641393, 37.694999990502829]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080611',
//       type: 'Feature',
//       properties: {
//         fid: 402370,
//         MAPIDCD_NO: '376080611',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75499997060817, 37.694999980056167],
//             [126.7549999809293, 37.690000022511242],
//             [126.75000004358523, 37.689999978812288],
//             [126.7500000391007, 37.695000026877267],
//             [126.75499997060817, 37.694999980056167]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080905',
//       type: 'Feature',
//       properties: {
//         fid: 402755,
//         MAPIDCD_NO: '376080905',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499999876476, 37.700000044295209],
//             [126.92500004674727, 37.694999989880088],
//             [126.91999998995232, 37.695000038337071],
//             [126.91999994714004, 37.700000001522696],
//             [126.92499999876476, 37.700000044295209]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080904',
//       type: 'Feature',
//       properties: {
//         fid: 402757,
//         MAPIDCD_NO: '376080904',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999994714004, 37.700000001522696],
//             [126.91999998995232, 37.695000038337071],
//             [126.91500004867297, 37.694999963662084],
//             [126.91500000961958, 37.7000000176696],
//             [126.91999994714004, 37.700000001522696]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080903',
//       type: 'Feature',
//       properties: {
//         fid: 402759,
//         MAPIDCD_NO: '376080903',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500000961958, 37.7000000176696],
//             [126.91500004867297, 37.694999963662084],
//             [126.90999999166918, 37.695000036912482],
//             [126.90999995778246, 37.700000001474578],
//             [126.91500000961958, 37.7000000176696]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080902',
//       type: 'Feature',
//       properties: {
//         fid: 402762,
//         MAPIDCD_NO: '376080902',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999995778246, 37.700000001474578],
//             [126.90999999166918, 37.695000036912482],
//             [126.90500004793057, 37.694999988831015],
//             [126.90500001778874, 37.700000044196173],
//             [126.90999995778246, 37.700000001474578]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080901',
//       type: 'Feature',
//       properties: {
//         fid: 402764,
//         MAPIDCD_NO: '376080901',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500001778874, 37.700000044196173],
//             [126.90500004793057, 37.694999988831015],
//             [126.89999999371241, 37.695000000360636],
//             [126.89999996649374, 37.699999964447819],
//             [126.90500001778874, 37.700000044196173]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080810',
//       type: 'Feature',
//       properties: {
//         fid: 402766,
//         MAPIDCD_NO: '376080810',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999996649374, 37.699999964447819],
//             [126.89999999371241, 37.695000000360636],
//             [126.89500004981704, 37.694999978881931],
//             [126.8950000286128, 37.7000000319623],
//             [126.89999996649374, 37.699999964447819]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080809',
//       type: 'Feature',
//       properties: {
//         fid: 402768,
//         MAPIDCD_NO: '376080809',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8950000286128, 37.7000000319623],
//             [126.89500004981704, 37.694999978881931],
//             [126.88999999546491, 37.695000015201281],
//             [126.8899999771799, 37.699999978787574],
//             [126.8950000286128, 37.7000000319623]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080808',
//       type: 'Feature',
//       properties: {
//         fid: 402770,
//         MAPIDCD_NO: '376080808',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8899999771799, 37.699999978787574],
//             [126.88999999546491, 37.695000015201281],
//             [126.88500005370953, 37.695000020335087],
//             [126.88500003988668, 37.699999984558247],
//             [126.8899999771799, 37.699999978787574]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080807',
//       type: 'Feature',
//       properties: {
//         fid: 402773,
//         MAPIDCD_NO: '376080807',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500003988668, 37.699999984558247],
//             [126.88500005370953, 37.695000020335087],
//             [126.87999999771911, 37.69499999310441],
//             [126.87999999063344, 37.699999956164461],
//             [126.88500003988668, 37.699999984558247]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080806',
//       type: 'Feature',
//       properties: {
//         fid: 402775,
//         MAPIDCD_NO: '376080806',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999999063344, 37.699999956164461],
//             [126.87999999771911, 37.69499999310441],
//             [126.87500005586944, 37.695000024837015],
//             [126.87500005324414, 37.69999998851538],
//             [126.87999999063344, 37.699999956164461]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080805',
//       type: 'Feature',
//       properties: {
//         fid: 402778,
//         MAPIDCD_NO: '376080805',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500005324414, 37.69999998851538],
//             [126.87500005586944, 37.695000024837015],
//             [126.87000000205818, 37.695000026009176],
//             [126.8700000016316, 37.6999999884818],
//             [126.87500005324414, 37.69999998851538]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080804',
//       type: 'Feature',
//       properties: {
//         fid: 402780,
//         MAPIDCD_NO: '376080804',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8700000016316, 37.6999999884818],
//             [126.87000000205818, 37.695000026009176],
//             [126.86499994748728, 37.694999993599609],
//             [126.86499995379587, 37.699999954881449],
//             [126.8700000016316, 37.6999999884818]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080803',
//       type: 'Feature',
//       properties: {
//         fid: 402782,
//         MAPIDCD_NO: '376080803',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499995379587, 37.699999954881449],
//             [126.86499994748728, 37.694999993599609],
//             [126.8600000070748, 37.695000020166326],
//             [126.86000001557285, 37.69999998202622],
//             [126.86499995379587, 37.699999954881449]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080802',
//       type: 'Feature',
//       properties: {
//         fid: 402785,
//         MAPIDCD_NO: '376080802',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000001557285, 37.69999998202622],
//             [126.8600000070748, 37.695000020166326],
//             [126.85499995248411, 37.695000014342014],
//             [126.8549999677159, 37.699999974992657],
//             [126.86000001557285, 37.69999998202622]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080801',
//       type: 'Feature',
//       properties: {
//         fid: 402787,
//         MAPIDCD_NO: '376080801',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8549999677159, 37.699999974992657],
//             [126.85499995248411, 37.695000014342014],
//             [126.85000001284649, 37.694999977374636],
//             [126.85000003174775, 37.700000028724823],
//             [126.8549999677159, 37.699999974992657]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080710',
//       type: 'Feature',
//       properties: {
//         fid: 402789,
//         MAPIDCD_NO: '376080710',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000003174775, 37.700000028724823],
//             [126.85000001284649, 37.694999977374636],
//             [126.84499996053866, 37.694999998146066],
//             [126.84499998242207, 37.699999958115626],
//             [126.85000003174775, 37.700000028724823]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080709',
//       type: 'Feature',
//       properties: {
//         fid: 402791,
//         MAPIDCD_NO: '376080709',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499998242207, 37.699999958115626],
//             [126.84499996053866, 37.694999998146066],
//             [126.84000002095125, 37.694999985968089],
//             [126.84000004647432, 37.7000000384208],
//             [126.84499998242207, 37.699999958115626]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080708',
//       type: 'Feature',
//       properties: {
//         fid: 402793,
//         MAPIDCD_NO: '376080708',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000004647432, 37.7000000384208],
//             [126.84000002095125, 37.694999985968089],
//             [126.834999968686, 37.6950000333207],
//             [126.8349999994703, 37.699999994386829],
//             [126.84000004647432, 37.7000000384208]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080707',
//       type: 'Feature',
//       properties: {
//         fid: 402796,
//         MAPIDCD_NO: '376080707',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8349999994703, 37.699999994386829],
//             [126.834999968686, 37.6950000333207],
//             [126.83000002995611, 37.694999959407468],
//             [126.82999995095187, 37.700000010490889],
//             [126.8349999994703, 37.699999994386829]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080706',
//       type: 'Feature',
//       properties: {
//         fid: 402798,
//         MAPIDCD_NO: '376080706',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999995095187, 37.700000010490889],
//             [126.83000002995611, 37.694999959407468],
//             [126.82499997776755, 37.695000033339156],
//             [126.82500001746539, 37.699999993662011],
//             [126.82999995095187, 37.700000010490889]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080705',
//       type: 'Feature',
//       properties: {
//         fid: 402801,
//         MAPIDCD_NO: '376080705',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500001746539, 37.699999993662011],
//             [126.82499997776755, 37.695000033339156],
//             [126.82000003914632, 37.69499998601362],
//             [126.81999996905842, 37.70000003452278],
//             [126.82500001746539, 37.699999993662011]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080704',
//       type: 'Feature',
//       properties: {
//         fid: 402803,
//         MAPIDCD_NO: '376080704',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999996905842, 37.70000003452278],
//             [126.82000003914632, 37.69499998601362],
//             [126.81499998789165, 37.694999996394849],
//             [126.81500003568709, 37.700000044261792],
//             [126.81999996905842, 37.70000003452278]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080703',
//       type: 'Feature',
//       properties: {
//         fid: 402806,
//         MAPIDCD_NO: '376080703',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500003568709, 37.700000044261792],
//             [126.81499998789165, 37.694999996394849],
//             [126.81000004942989, 37.694999973852326],
//             [126.80999999049081, 37.700000023368283],
//             [126.81500003568709, 37.700000044261792]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080702',
//       type: 'Feature',
//       properties: {
//         fid: 402808,
//         MAPIDCD_NO: '376080702',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999999049081, 37.700000023368283],
//             [126.81000004942989, 37.694999973852326],
//             [126.80500000059482, 37.69500001082168],
//             [126.80500005811018, 37.699999969546184],
//             [126.80999999049081, 37.700000023368283]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080701',
//       type: 'Feature',
//       properties: {
//         fid: 402810,
//         MAPIDCD_NO: '376080701',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500005811018, 37.699999969546184],
//             [126.80500000059482, 37.69500001082168],
//             [126.79999994886531, 37.695000015991688],
//             [126.80000001083758, 37.699999973391975],
//             [126.80500005811018, 37.699999969546184]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080610',
//       type: 'Feature',
//       properties: {
//         fid: 402813,
//         MAPIDCD_NO: '376080610',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000001083758, 37.699999973391975],
//             [126.79999994886531, 37.695000015991688],
//             [126.7950000122334, 37.694999988264151],
//             [126.79499996662096, 37.70000003739819],
//             [126.80000001083758, 37.699999973391975]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080609',
//       type: 'Feature',
//       properties: {
//         fid: 402815,
//         MAPIDCD_NO: '376080609',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499996662096, 37.70000003739819],
//             [126.7950000122334, 37.694999988264151],
//             [126.78999996297779, 37.695000020019187],
//             [126.7900000338351, 37.69999997834821],
//             [126.79499996662096, 37.70000003739819]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080608',
//       type: 'Feature',
//       properties: {
//         fid: 402818,
//         MAPIDCD_NO: '376080608',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7900000338351, 37.69999997834821],
//             [126.78999996297779, 37.695000020019187],
//             [126.78500002882247, 37.695000020688042],
//             [126.78499999071286, 37.699999976977146],
//             [126.7900000338351, 37.69999997834821]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080607',
//       type: 'Feature',
//       properties: {
//         fid: 402820,
//         MAPIDCD_NO: '376080607',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78499999071286, 37.699999976977146],
//             [126.78500002882247, 37.695000020688042],
//             [126.77999998068923, 37.694999987083385],
//             [126.77999994614453, 37.700000033935218],
//             [126.78499999071286, 37.699999976977146]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080606',
//       type: 'Feature',
//       properties: {
//         fid: 402822,
//         MAPIDCD_NO: '376080606',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77999994614453, 37.700000033935218],
//             [126.77999998068923, 37.694999987083385],
//             [126.77500004452492, 37.6950000143164],
//             [126.77500001531261, 37.699999969666194],
//             [126.77999994614453, 37.700000033935218]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080605',
//       type: 'Feature',
//       properties: {
//         fid: 402824,
//         MAPIDCD_NO: '376080605',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500001531261, 37.699999969666194],
//             [126.77500004452492, 37.6950000143164],
//             [126.76999999667167, 37.695000007278928],
//             [126.76999997189415, 37.699999963046324],
//             [126.77500001531261, 37.699999969666194]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080604',
//       type: 'Feature',
//       properties: {
//         fid: 402828,
//         MAPIDCD_NO: '376080604',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76999997189415, 37.699999963046324],
//             [126.76999999667167, 37.695000007278928],
//             [126.76499995053187, 37.694999970268313],
//             [126.76500004274496, 37.700000015474657],
//             [126.76999997189415, 37.699999963046324]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080603',
//       type: 'Feature',
//       properties: {
//         fid: 402830,
//         MAPIDCD_NO: '376080603',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76500004274496, 37.700000015474657],
//             [126.76499995053187, 37.694999970268313],
//             [126.76000001641393, 37.694999990502829],
//             [126.75999999963268, 37.700000035401018],
//             [126.76500004274496, 37.700000015474657]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080602',
//       type: 'Feature',
//       properties: {
//         fid: 402833,
//         MAPIDCD_NO: '376080602',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999999963268, 37.700000035401018],
//             [126.76000001641393, 37.694999990502829],
//             [126.75499997060817, 37.694999980056167],
//             [126.7549999582709, 37.700000023541328],
//             [126.75999999963268, 37.700000035401018]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080601',
//       type: 'Feature',
//       properties: {
//         fid: 402835,
//         MAPIDCD_NO: '376080601',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7549999582709, 37.700000023541328],
//             [126.75499997060817, 37.694999980056167],
//             [126.7500000391007, 37.695000026877267],
//             [126.75000002983555, 37.699999980602385],
//             [126.7549999582709, 37.700000023541328]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080495',
//       type: 'Feature',
//       properties: {
//         fid: 403220,
//         MAPIDCD_NO: '376080495',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499995163493, 37.705000006176732],
//             [126.92499999876476, 37.700000044295209],
//             [126.91999994714004, 37.700000001522696],
//             [126.92000001788688, 37.704999962862445],
//             [126.92499995163493, 37.705000006176732]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080494',
//       type: 'Feature',
//       properties: {
//         fid: 403222,
//         MAPIDCD_NO: '376080494',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000001788688, 37.704999962862445],
//             [126.91999994714004, 37.700000001522696],
//             [126.91500000961958, 37.7000000176696],
//             [126.91499996910142, 37.704999977330985],
//             [126.92000001788688, 37.704999962862445]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080493',
//       type: 'Feature',
//       properties: {
//         fid: 403224,
//         MAPIDCD_NO: '376080493',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499996910142, 37.704999977330985],
//             [126.91500000961958, 37.7000000176696],
//             [126.90999995778246, 37.700000001474578],
//             [126.91000003512519, 37.704999962387653],
//             [126.91499996910142, 37.704999977330985]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080492',
//       type: 'Feature',
//       properties: {
//         fid: 403226,
//         MAPIDCD_NO: '376080492',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000003512519, 37.704999962387653],
//             [126.90999995778246, 37.700000001474578],
//             [126.90500001778874, 37.700000044196173],
//             [126.90499998840278, 37.70500000342517],
//             [126.91000003512519, 37.704999962387653]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080491',
//       type: 'Feature',
//       properties: {
//         fid: 403229,
//         MAPIDCD_NO: '376080491',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499998840278, 37.70500000342517],
//             [126.90500001778874, 37.700000044196173],
//             [126.89999996649374, 37.699999964447819],
//             [126.90000005425499, 37.705000013245645],
//             [126.90499998840278, 37.70500000342517]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080300',
//       type: 'Feature',
//       properties: {
//         fid: 403231,
//         MAPIDCD_NO: '376080300',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000005425499, 37.705000013245645],
//             [126.89999996649374, 37.699999964447819],
//             [126.8950000286128, 37.7000000319623],
//             [126.8950000080729, 37.704999992513031],
//             [126.90000005425499, 37.705000013245645]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080399',
//       type: 'Feature',
//       properties: {
//         fid: 403233,
//         MAPIDCD_NO: '376080399',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8950000080729, 37.704999992513031],
//             [126.8950000286128, 37.7000000319623],
//             [126.8899999771799, 37.699999978787574],
//             [126.889999960334, 37.705000028311019],
//             [126.8950000080729, 37.704999992513031]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080398',
//       type: 'Feature',
//       properties: {
//         fid: 403235,
//         MAPIDCD_NO: '376080398',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.889999960334, 37.705000028311019],
//             [126.8899999771799, 37.699999978787574],
//             [126.88500003988668, 37.699999984558247],
//             [126.88500002747436, 37.705000032916622],
//             [126.889999960334, 37.705000028311019]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080397',
//       type: 'Feature',
//       properties: {
//         fid: 403238,
//         MAPIDCD_NO: '376080397',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500002747436, 37.705000032916622],
//             [126.88500003988668, 37.699999984558247],
//             [126.87999999063344, 37.699999956164461],
//             [126.87999998036284, 37.705000005139063],
//             [126.88500002747436, 37.705000032916622]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080396',
//       type: 'Feature',
//       properties: {
//         fid: 403240,
//         MAPIDCD_NO: '376080396',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999998036284, 37.705000005139063],
//             [126.87999999063344, 37.699999956164461],
//             [126.87500005324414, 37.69999998851538],
//             [126.87500004740573, 37.705000036306146],
//             [126.87999998036284, 37.705000005139063]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080395',
//       type: 'Feature',
//       properties: {
//         fid: 403243,
//         MAPIDCD_NO: '376080395',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500004740573, 37.705000036306146],
//             [126.87500005324414, 37.69999998851538],
//             [126.8700000016316, 37.6999999884818],
//             [126.87000000247052, 37.705000036894056],
//             [126.87500004740573, 37.705000036306146]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080394',
//       type: 'Feature',
//       properties: {
//         fid: 403245,
//         MAPIDCD_NO: '376080394',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000000247052, 37.705000036894056],
//             [126.8700000016316, 37.6999999884818],
//             [126.86499995379587, 37.699999954881449],
//             [126.8649999567741, 37.705000003881665],
//             [126.87000000247052, 37.705000036894056]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080393',
//       type: 'Feature',
//       properties: {
//         fid: 403247,
//         MAPIDCD_NO: '376080393',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8649999567741, 37.705000003881665],
//             [126.86499995379587, 37.699999954881449],
//             [126.86000001557285, 37.69999998202622],
//             [126.8600000252497, 37.705000029826962],
//             [126.8649999567741, 37.705000003881665]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080392',
//       type: 'Feature',
//       properties: {
//         fid: 403250,
//         MAPIDCD_NO: '376080392',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8600000252497, 37.705000029826962],
//             [126.86000001557285, 37.69999998202622],
//             [126.8549999677159, 37.699999974992657],
//             [126.85499997953031, 37.705000023362508],
//             [126.8600000252497, 37.705000029826962]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080391',
//       type: 'Feature',
//       properties: {
//         fid: 403252,
//         MAPIDCD_NO: '376080391',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85499997953031, 37.705000023362508],
//             [126.8549999677159, 37.699999974992657],
//             [126.85000003174775, 37.700000028724823],
//             [126.85000004877784, 37.704999985736535],
//             [126.85499997953031, 37.705000023362508]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080200',
//       type: 'Feature',
//       properties: {
//         fid: 403254,
//         MAPIDCD_NO: '376080200',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000004877784, 37.704999985736535],
//             [126.85000003174775, 37.700000028724823],
//             [126.84499998242207, 37.699999958115626],
//             [126.84500000306952, 37.705000005817887],
//             [126.85000004877784, 37.704999985736535]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080299',
//       type: 'Feature',
//       properties: {
//         fid: 403256,
//         MAPIDCD_NO: '376080299',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500000306952, 37.705000005817887],
//             [126.84499998242207, 37.699999958115626],
//             [126.84000004647432, 37.7000000384208],
//             [126.83999995890987, 37.704999994111439],
//             [126.84500000306952, 37.705000005817887]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080298',
//       type: 'Feature',
//       properties: {
//         fid: 403258,
//         MAPIDCD_NO: '376080298',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999995890987, 37.704999994111439],
//             [126.84000004647432, 37.7000000384208],
//             [126.8349999994703, 37.699999994386829],
//             [126.83500002894778, 37.705000041384132],
//             [126.83999995890987, 37.704999994111439]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080297',
//       type: 'Feature',
//       properties: {
//         fid: 403260,
//         MAPIDCD_NO: '376080297',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500002894778, 37.705000041384132],
//             [126.8349999994703, 37.699999994386829],
//             [126.82999995095187, 37.700000010490889],
//             [126.82999998565873, 37.7049999660931],
//             [126.83500002894778, 37.705000041384132]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080296',
//       type: 'Feature',
//       properties: {
//         fid: 403263,
//         MAPIDCD_NO: '376080296',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999998565873, 37.7049999660931],
//             [126.82999995095187, 37.700000010490889],
//             [126.82500001746539, 37.699999993662011],
//             [126.82500005576971, 37.705000039917152],
//             [126.82999998565873, 37.7049999660931]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080295',
//       type: 'Feature',
//       properties: {
//         fid: 403266,
//         MAPIDCD_NO: '376080295',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500005576971, 37.705000039917152],
//             [126.82500001746539, 37.699999993662011],
//             [126.81999996905842, 37.70000003452278],
//             [126.82000001260263, 37.704999989364325],
//             [126.82500005576971, 37.705000039917152]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080294',
//       type: 'Feature',
//       properties: {
//         fid: 403268,
//         MAPIDCD_NO: '376080294',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000001260263, 37.704999989364325],
//             [126.81999996905842, 37.70000003452278],
//             [126.81500003568709, 37.700000044261792],
//             [126.81499997020676, 37.70499999895133],
//             [126.82000001260263, 37.704999989364325]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080293',
//       type: 'Feature',
//       properties: {
//         fid: 403271,
//         MAPIDCD_NO: '376080293',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499997020676, 37.70499999895133],
//             [126.81500003568709, 37.700000044261792],
//             [126.80999999049081, 37.700000023368283],
//             [126.81000004060061, 37.704999977398671],
//             [126.81499997020676, 37.70499999895133]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080292',
//       type: 'Feature',
//       properties: {
//         fid: 403273,
//         MAPIDCD_NO: '376080292',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000004060061, 37.704999977398671],
//             [126.80999999049081, 37.700000023368283],
//             [126.80500005811018, 37.699999969546184],
//             [126.80499999835234, 37.7050000135231],
//             [126.81000004060061, 37.704999977398671]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080291',
//       type: 'Feature',
//       properties: {
//         fid: 403275,
//         MAPIDCD_NO: '376080291',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499999835234, 37.7050000135231],
//             [126.80500005811018, 37.699999969546184],
//             [126.80000001083758, 37.699999973391975],
//             [126.79999995774513, 37.705000017856335],
//             [126.80499999835234, 37.7050000135231]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080100',
//       type: 'Feature',
//       properties: {
//         fid: 403278,
//         MAPIDCD_NO: '376080100',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999995774513, 37.705000017856335],
//             [126.80000001083758, 37.699999973391975],
//             [126.79499996662096, 37.70000003739819],
//             [126.79500002998064, 37.70499998926023],
//             [126.79999995774513, 37.705000017856335]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080199',
//       type: 'Feature',
//       properties: {
//         fid: 403280,
//         MAPIDCD_NO: '376080199',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500002998064, 37.70499998926023],
//             [126.79499996662096, 37.70000003739819],
//             [126.7900000338351, 37.69999997834821],
//             [126.78999998957539, 37.705000020127777],
//             [126.79500002998064, 37.70499998926023]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080198',
//       type: 'Feature',
//       properties: {
//         fid: 403283,
//         MAPIDCD_NO: '376080198',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999998957539, 37.705000020127777],
//             [126.7900000338351, 37.69999997834821],
//             [126.78499999071286, 37.699999976977146],
//             [126.7849999485787, 37.705000019189072],
//             [126.78999998957539, 37.705000020127777]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080197',
//       type: 'Feature',
//       properties: {
//         fid: 403285,
//         MAPIDCD_NO: '376080197',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7849999485787, 37.705000019189072],
//             [126.78499999071286, 37.699999976977146],
//             [126.77999994614453, 37.700000033935218],
//             [126.78000002271212, 37.704999987150082],
//             [126.7849999485787, 37.705000019189072]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080196',
//       type: 'Feature',
//       properties: {
//         fid: 403287,
//         MAPIDCD_NO: '376080196',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000002271212, 37.704999987150082],
//             [126.77999994614453, 37.700000033935218],
//             [126.77500001531261, 37.699999969666194],
//             [126.77499998425773, 37.705000010953675],
//             [126.78000002271212, 37.704999987150082]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080195',
//       type: 'Feature',
//       properties: {
//         fid: 403289,
//         MAPIDCD_NO: '376080195',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77499998425773, 37.705000010953675],
//             [126.77500001531261, 37.699999969666194],
//             [126.76999997189415, 37.699999963046324],
//             [126.76999994523062, 37.705000004751945],
//             [126.77499998425773, 37.705000010953675]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080194',
//       type: 'Feature',
//       properties: {
//         fid: 403293,
//         MAPIDCD_NO: '376080194',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76999994523062, 37.705000004751945],
//             [126.76999997189415, 37.699999963046324],
//             [126.76500004274496, 37.700000015474657],
//             [126.76500001911855, 37.704999965646408],
//             [126.76999994523062, 37.705000004751945]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080193',
//       type: 'Feature',
//       properties: {
//         fid: 403295,
//         MAPIDCD_NO: '376080193',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.76500001911855, 37.704999965646408],
//             [126.76500004274496, 37.700000015474657],
//             [126.75999999963268, 37.700000035401018],
//             [126.7599999804018, 37.704999985972222],
//             [126.76500001911855, 37.704999965646408]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080192',
//       type: 'Feature',
//       properties: {
//         fid: 403298,
//         MAPIDCD_NO: '376080192',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7599999804018, 37.704999985972222],
//             [126.75999999963268, 37.700000035401018],
//             [126.7549999582709, 37.700000023541328],
//             [126.75500005687087, 37.704999975219515],
//             [126.7599999804018, 37.704999985972222]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080191',
//       type: 'Feature',
//       properties: {
//         fid: 403300,
//         MAPIDCD_NO: '376080191',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75500005687087, 37.704999975219515],
//             [126.7549999582709, 37.700000023541328],
//             [126.75000002983555, 37.699999980602385],
//             [126.75000002077933, 37.7050000202825],
//             [126.75500005687087, 37.704999975219515]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080486',
//       type: 'Feature',
//       properties: {
//         fid: 403683,
//         MAPIDCD_NO: '376080486',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92999994658642, 37.709999973415883],
//             [126.93000000020044, 37.705000016465206],
//             [126.92499995163493, 37.705000006176732],
//             [126.92500001588249, 37.709999960788075],
//             [126.92999994658642, 37.709999973415883]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080485',
//       type: 'Feature',
//       properties: {
//         fid: 403685,
//         MAPIDCD_NO: '376080485',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92500001588249, 37.709999960788075],
//             [126.92499995163493, 37.705000006176732],
//             [126.92000001788688, 37.704999962862445],
//             [126.91999997237758, 37.710000007752669],
//             [126.92500001588249, 37.709999960788075]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080484',
//       type: 'Feature',
//       properties: {
//         fid: 403687,
//         MAPIDCD_NO: '376080484',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999997237758, 37.710000007752669],
//             [126.92000001788688, 37.704999962862445],
//             [126.91499996910142, 37.704999977330985],
//             [126.91500004141813, 37.710000023477932],
//             [126.91999997237758, 37.710000007752669]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080483',
//       type: 'Feature',
//       properties: {
//         fid: 403689,
//         MAPIDCD_NO: '376080483',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500004141813, 37.710000023477932],
//             [126.91499996910142, 37.704999977330985],
//             [126.91000003512519, 37.704999962387653],
//             [126.9099999983942, 37.7100000068537],
//             [126.91500004141813, 37.710000023477932]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080482',
//       type: 'Feature',
//       properties: {
//         fid: 403691,
//         MAPIDCD_NO: '376080482',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9099999983942, 37.7100000068537],
//             [126.91000003512519, 37.704999962387653],
//             [126.90499998840278, 37.70500000342517],
//             [126.90499995676949, 37.709999958437827],
//             [126.9099999983942, 37.7100000068537]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080481',
//       type: 'Feature',
//       properties: {
//         fid: 403694,
//         MAPIDCD_NO: '376080481',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499995676949, 37.709999958437827],
//             [126.90499998840278, 37.70500000342517],
//             [126.90000005425499, 37.705000013245645],
//             [126.90000002701156, 37.70999996892435],
//             [126.90499995676949, 37.709999958437827]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080390',
//       type: 'Feature',
//       properties: {
//         fid: 403696,
//         MAPIDCD_NO: '376080390',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000002701156, 37.70999996892435],
//             [126.90000005425499, 37.705000013245645],
//             [126.8950000080729, 37.704999992513031],
//             [126.89499998449288, 37.710000037174204],
//             [126.90000002701156, 37.70999996892435]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080389',
//       type: 'Feature',
//       properties: {
//         fid: 403698,
//         MAPIDCD_NO: '376080389',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499998449288, 37.710000037174204],
//             [126.8950000080729, 37.704999992513031],
//             [126.889999960334, 37.705000028311019],
//             [126.89000005532897, 37.709999982275484],
//             [126.89499998449288, 37.710000037174204]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080388',
//       type: 'Feature',
//       properties: {
//         fid: 403700,
//         MAPIDCD_NO: '376080388',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000005532897, 37.709999982275484],
//             [126.889999960334, 37.705000028311019],
//             [126.88500002747436, 37.705000032916622],
//             [126.88500001341002, 37.709999986932218],
//             [126.89000005532897, 37.709999982275484]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080387',
//       type: 'Feature',
//       properties: {
//         fid: 403703,
//         MAPIDCD_NO: '376080387',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88500001341002, 37.709999986932218],
//             [126.88500002747436, 37.705000032916622],
//             [126.87999998036284, 37.705000005139063],
//             [126.87999997067755, 37.709999959783353],
//             [126.88500001341002, 37.709999986932218]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080386',
//       type: 'Feature',
//       properties: {
//         fid: 403705,
//         MAPIDCD_NO: '376080386',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999997067755, 37.709999959783353],
//             [126.87999998036284, 37.705000005139063],
//             [126.87500004740573, 37.705000036306146],
//             [126.87500004210617, 37.709999991569859],
//             [126.87999997067755, 37.709999959783353]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080385',
//       type: 'Feature',
//       properties: {
//         fid: 403708,
//         MAPIDCD_NO: '376080385',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500004210617, 37.709999991569859],
//             [126.87500004740573, 37.705000036306146],
//             [126.87000000247052, 37.705000036894056],
//             [126.87000000156361, 37.709999990965287],
//             [126.87500004210617, 37.709999991569859]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080384',
//       type: 'Feature',
//       properties: {
//         fid: 403710,
//         MAPIDCD_NO: '376080384',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87000000156361, 37.709999990965287],
//             [126.87000000247052, 37.705000036894056],
//             [126.8649999567741, 37.705000003881665],
//             [126.86499996025914, 37.709999956751133],
//             [126.87000000156361, 37.709999990965287]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080383',
//       type: 'Feature',
//       properties: {
//         fid: 403712,
//         MAPIDCD_NO: '376080383',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499996025914, 37.709999956751133],
//             [126.8649999567741, 37.705000003881665],
//             [126.8600000252497, 37.705000029826962],
//             [126.86000003084926, 37.709999983275587],
//             [126.86499996025914, 37.709999956751133]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080382',
//       type: 'Feature',
//       properties: {
//         fid: 403715,
//         MAPIDCD_NO: '376080382',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000003084926, 37.709999983275587],
//             [126.8600000252497, 37.705000029826962],
//             [126.85499997953031, 37.705000023362508],
//             [126.8549999917892, 37.70999997560299],
//             [126.86000003084926, 37.709999983275587]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080381',
//       type: 'Feature',
//       properties: {
//         fid: 403717,
//         MAPIDCD_NO: '376080381',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8549999917892, 37.70999997560299],
//             [126.85499997953031, 37.705000023362508],
//             [126.85000004877784, 37.704999985736535],
//             [126.84999995118528, 37.71000002805215],
//             [126.8549999917892, 37.70999997560299]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080290',
//       type: 'Feature',
//       properties: {
//         fid: 403719,
//         MAPIDCD_NO: '376080290',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999995118528, 37.71000002805215],
//             [126.85000004877784, 37.704999985736535],
//             [126.84500000306952, 37.705000005817887],
//             [126.84500002409926, 37.709999957392206],
//             [126.84999995118528, 37.71000002805215]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080289',
//       type: 'Feature',
//       properties: {
//         fid: 403721,
//         MAPIDCD_NO: '376080289',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84500002409926, 37.709999957392206],
//             [126.84500000306952, 37.705000005817887],
//             [126.83999995890987, 37.704999994111439],
//             [126.83999998351267, 37.710000036367383],
//             [126.84500002409926, 37.709999957392206]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080288',
//       type: 'Feature',
//       properties: {
//         fid: 403723,
//         MAPIDCD_NO: '376080288',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999998351267, 37.710000036367383],
//             [126.83999995890987, 37.704999994111439],
//             [126.83500002894778, 37.705000041384132],
//             [126.83499994301444, 37.709999993404821],
//             [126.83999998351267, 37.710000036367383]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080287',
//       type: 'Feature',
//       properties: {
//         fid: 403725,
//         MAPIDCD_NO: '376080287',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499994301444, 37.709999993404821],
//             [126.83500002894778, 37.705000041384132],
//             [126.82999998565873, 37.7049999660931],
//             [126.83000001901547, 37.710000007626782],
//             [126.83499994301444, 37.709999993404821]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080286',
//       type: 'Feature',
//       properties: {
//         fid: 403728,
//         MAPIDCD_NO: '376080286',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000001901547, 37.710000007626782],
//             [126.82999998565873, 37.7049999660931],
//             [126.82500005576971, 37.705000039917152],
//             [126.82499997861719, 37.709999989384769],
//             [126.83000001901547, 37.710000007626782]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080285',
//       type: 'Feature',
//       properties: {
//         fid: 403731,
//         MAPIDCD_NO: '376080285',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499997861719, 37.709999989384769],
//             [126.82500005576971, 37.705000039917152],
//             [126.82000001260263, 37.704999989364325],
//             [126.82000005469348, 37.710000031940993],
//             [126.82499997861719, 37.709999989384769]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080284',
//       type: 'Feature',
//       properties: {
//         fid: 403733,
//         MAPIDCD_NO: '376080284',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000005469348, 37.710000031940993],
//             [126.82000001260263, 37.704999989364325],
//             [126.81499997020676, 37.70499999895133],
//             [126.81500001667611, 37.710000040232941],
//             [126.82000005469348, 37.710000031940993]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080283',
//       type: 'Feature',
//       properties: {
//         fid: 403736,
//         MAPIDCD_NO: '376080283',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500001667611, 37.710000040232941],
//             [126.81499997020676, 37.70499999895133],
//             [126.81000004060061, 37.704999977398671],
//             [126.80999997799349, 37.710000018514613],
//             [126.81500001667611, 37.710000040232941]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080282',
//       type: 'Feature',
//       properties: {
//         fid: 403738,
//         MAPIDCD_NO: '376080282',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999997799349, 37.710000018514613],
//             [126.81000004060061, 37.704999977398671],
//             [126.80499999835234, 37.7050000135231],
//             [126.80500005440904, 37.709999963862458],
//             [126.80999997799349, 37.710000018514613]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080281',
//       type: 'Feature',
//       properties: {
//         fid: 403740,
//         MAPIDCD_NO: '376080281',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500005440904, 37.709999963862458],
//             [126.80499999835234, 37.7050000135231],
//             [126.79999995774513, 37.705000017856335],
//             [126.80000001591532, 37.7099999668593],
//             [126.80500005440904, 37.709999963862458]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080190',
//       type: 'Feature',
//       properties: {
//         fid: 403743,
//         MAPIDCD_NO: '376080190',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000001591532, 37.7099999668593],
//             [126.79999995774513, 37.705000017856335],
//             [126.79500002998064, 37.70499998926023],
//             [126.79499998047615, 37.710000029997879],
//             [126.80000001591532, 37.7099999668593]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080189',
//       type: 'Feature',
//       properties: {
//         fid: 403745,
//         MAPIDCD_NO: '376080189',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499998047615, 37.710000029997879],
//             [126.79500002998064, 37.70499998926023],
//             [126.78999998957539, 37.705000020127777],
//             [126.79000005648133, 37.709999970061929],
//             [126.79499998047615, 37.710000029997879]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080188',
//       type: 'Feature',
//       properties: {
//         fid: 403748,
//         MAPIDCD_NO: '376080188',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000005648133, 37.709999970061929],
//             [126.78999998957539, 37.705000020127777],
//             [126.7849999485787, 37.705000019189072],
//             [126.7850000221334, 37.709999967786025],
//             [126.79000005648133, 37.709999970061929]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080187',
//       type: 'Feature',
//       properties: {
//         fid: 403750,
//         MAPIDCD_NO: '376080187',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7850000221334, 37.709999967786025],
//             [126.7849999485787, 37.705000019189072],
//             [126.78000002271212, 37.704999987150082],
//             [126.77999998633733, 37.710000023820527],
//             [126.7850000221334, 37.709999967786025]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080186',
//       type: 'Feature',
//       properties: {
//         fid: 403752,
//         MAPIDCD_NO: '376080186',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77999998633733, 37.710000023820527],
//             [126.78000002271212, 37.704999987150082],
//             [126.77499998425773, 37.705000010953675],
//             [126.77499995082964, 37.70999995971458],
//             [126.77999998633733, 37.710000023820527]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080185',
//       type: 'Feature',
//       properties: {
//         fid: 403754,
//         MAPIDCD_NO: '376080185',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77499995082964, 37.70999995971458],
//             [126.77499998425773, 37.705000010953675],
//             [126.76999994523062, 37.705000004751945],
//             [126.77000002875792, 37.710000041155155],
//             [126.77499995082964, 37.70999995971458]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080184',
//       type: 'Feature',
//       properties: {
//         fid: 403758,
//         MAPIDCD_NO: '376080184',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77000002875792, 37.710000041155155],
//             [126.76999994523062, 37.705000004751945],
//             [126.76500001911855, 37.704999965646408],
//             [126.76499999581353, 37.710000003573384],
//             [126.77000002875792, 37.710000041155155]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080182',
//       type: 'Feature',
//       properties: {
//         fid: 403762,
//         MAPIDCD_NO: '376080182',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.75999996146678, 37.710000022496857],
//             [126.7599999804018, 37.704999985972222],
//             [126.75500005687087, 37.704999975219515],
//             [126.7550000400439, 37.710000010318247],
//             [126.75999996146678, 37.710000022496857]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080181',
//       type: 'Feature',
//       properties: {
//         fid: 403765,
//         MAPIDCD_NO: '376080181',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.7550000400439, 37.710000010318247],
//             [126.75500005687087, 37.704999975219515],
//             [126.75000002077933, 37.7050000202825],
//             [126.75000000921112, 37.709999965636918],
//             [126.7550000400439, 37.710000010318247]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080475',
//       type: 'Feature',
//       properties: {
//         fid: 404150,
//         MAPIDCD_NO: '376080475',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499996615052, 37.715000002570214],
//             [126.92500001588249, 37.709999960788075],
//             [126.91999997237758, 37.710000007752669],
//             [126.92000004114536, 37.714999960667868],
//             [126.92499996615052, 37.715000002570214]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080474',
//       type: 'Feature',
//       properties: {
//         fid: 404152,
//         MAPIDCD_NO: '376080474',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000004114536, 37.714999960667868],
//             [126.91999997237758, 37.710000007752669],
//             [126.91500004141813, 37.710000023477932],
//             [126.91500000109966, 37.714999974726972],
//             [126.92000004114536, 37.714999960667868]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080473',
//       type: 'Feature',
//       properties: {
//         fid: 404154,
//         MAPIDCD_NO: '376080473',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500000109966, 37.714999974726972],
//             [126.91500004141813, 37.710000023477932],
//             [126.9099999983942, 37.7100000068537],
//             [126.90999996242232, 37.714999958787928],
//             [126.91500000109966, 37.714999974726972]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080472',
//       type: 'Feature',
//       properties: {
//         fid: 404156,
//         MAPIDCD_NO: '376080472',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999996242232, 37.714999958787928],
//             [126.9099999983942, 37.7100000068537],
//             [126.90499995676949, 37.709999958437827],
//             [126.9050000378919, 37.714999999946464],
//             [126.90999996242232, 37.714999958787928]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080471',
//       type: 'Feature',
//       properties: {
//         fid: 404159,
//         MAPIDCD_NO: '376080471',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9050000378919, 37.714999999946464],
//             [126.90499995676949, 37.709999958437827],
//             [126.90000002701156, 37.70999996892435],
//             [126.89999999902557, 37.715000010526907],
//             [126.9050000378919, 37.714999999946464]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080380',
//       type: 'Feature',
//       properties: {
//         fid: 404161,
//         MAPIDCD_NO: '376080380',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999999902557, 37.715000010526907],
//             [126.90000002701156, 37.70999996892435],
//             [126.89499998449288, 37.710000037174204],
//             [126.89499995932331, 37.714999987491332],
//             [126.89999999902557, 37.715000010526907]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080379',
//       type: 'Feature',
//       properties: {
//         fid: 404163,
//         MAPIDCD_NO: '376080379',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499995932331, 37.714999987491332],
//             [126.89499998449288, 37.710000037174204],
//             [126.89000005532897, 37.709999982275484],
//             [126.89000003604059, 37.715000023380526],
//             [126.89499995932331, 37.714999987491332]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080378',
//       type: 'Feature',
//       properties: {
//         fid: 404165,
//         MAPIDCD_NO: '376080378',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000003604059, 37.715000023380526],
//             [126.89000005532897, 37.709999982275484],
//             [126.88500001341002, 37.709999986932218],
//             [126.88499999618895, 37.71500002866393],
//             [126.89000003604059, 37.715000023380526]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080377',
//       type: 'Feature',
//       properties: {
//         fid: 404168,
//         MAPIDCD_NO: '376080377',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499999618895, 37.71500002866393],
//             [126.88500001341002, 37.709999986932218],
//             [126.87999997067755, 37.709999959783353],
//             [126.87999996007612, 37.715000000353591],
//             [126.88499999618895, 37.71500002866393]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080376',
//       type: 'Feature',
//       properties: {
//         fid: 404170,
//         MAPIDCD_NO: '376080376',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999996007612, 37.715000000353591],
//             [126.87999997067755, 37.709999959783353],
//             [126.87500004210617, 37.709999991569859],
//             [126.87500003586227, 37.715000030957512],
//             [126.87999996007612, 37.715000000353591]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080375',
//       type: 'Feature',
//       properties: {
//         fid: 404173,
//         MAPIDCD_NO: '376080375',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500003586227, 37.715000030957512],
//             [126.87500004210617, 37.709999991569859],
//             [126.87000000156361, 37.709999990965287],
//             [126.86999999740003, 37.71500002914879],
//             [126.87500003586227, 37.715000030957512]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080374',
//       type: 'Feature',
//       properties: {
//         fid: 404175,
//         MAPIDCD_NO: '376080374',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999999740003, 37.71500002914879],
//             [126.87000000156361, 37.709999990965287],
//             [126.86499996025914, 37.709999956751133],
//             [126.86499996041344, 37.71499999733841],
//             [126.86999999740003, 37.71500002914879]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080373',
//       type: 'Feature',
//       properties: {
//         fid: 404177,
//         MAPIDCD_NO: '376080373',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499996041344, 37.71499999733841],
//             [126.86499996025914, 37.709999956751133],
//             [126.86000003084926, 37.709999983275587],
//             [126.8600000376279, 37.71500002266454],
//             [126.86499996041344, 37.71499999733841]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080372',
//       type: 'Feature',
//       properties: {
//         fid: 404180,
//         MAPIDCD_NO: '376080372',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8600000376279, 37.71500002266454],
//             [126.86000003084926, 37.709999983275587],
//             [126.8549999917892, 37.70999997560299],
//             [126.85500000063038, 37.715000015562183],
//             [126.8600000376279, 37.71500002266454]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080371',
//       type: 'Feature',
//       properties: {
//         fid: 404182,
//         MAPIDCD_NO: '376080371',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500000063038, 37.715000015562183],
//             [126.8549999917892, 37.70999997560299],
//             [126.84999995118528, 37.71000002805215],
//             [126.84999996516012, 37.71499997665439],
//             [126.85500000063038, 37.715000015562183]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080280',
//       type: 'Feature',
//       properties: {
//         fid: 404184,
//         MAPIDCD_NO: '376080280',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999996516012, 37.71499997665439],
//             [126.84999995118528, 37.71000002805215],
//             [126.84500002409926, 37.709999957392206],
//             [126.8450000416242, 37.714999996686061],
//             [126.84999996516012, 37.71499997665439]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080279',
//       type: 'Feature',
//       properties: {
//         fid: 404186,
//         MAPIDCD_NO: '376080279',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8450000416242, 37.714999996686061],
//             [126.84500002409926, 37.709999957392206],
//             [126.83999998351267, 37.710000036367383],
//             [126.8400000061819, 37.714999984285853],
//             [126.8450000416242, 37.714999996686061]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080278',
//       type: 'Feature',
//       properties: {
//         fid: 404188,
//         MAPIDCD_NO: '376080278',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8400000061819, 37.714999984285853],
//             [126.83999998351267, 37.710000036367383],
//             [126.83499994301444, 37.709999993404821],
//             [126.83499997149743, 37.715000030206276],
//             [126.8400000061819, 37.714999984285853]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080277',
//       type: 'Feature',
//       properties: {
//         fid: 404190,
//         MAPIDCD_NO: '376080277',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499997149743, 37.715000030206276],
//             [126.83499994301444, 37.709999993404821],
//             [126.83000001901547, 37.710000007626782],
//             [126.83000004956442, 37.715000044951438],
//             [126.83499997149743, 37.715000030206276]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080276',
//       type: 'Feature',
//       properties: {
//         fid: 404193,
//         MAPIDCD_NO: '376080276',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000004956442, 37.715000044951438],
//             [126.83000001901547, 37.710000007626782],
//             [126.82499997861719, 37.709999989384769],
//             [126.82500001576157, 37.715000027249118],
//             [126.83000004956442, 37.715000044951438]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080275',
//       type: 'Feature',
//       properties: {
//         fid: 404196,
//         MAPIDCD_NO: '376080275',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500001576157, 37.715000027249118],
//             [126.82499997861719, 37.709999989384769],
//             [126.82000005469348, 37.710000031940993],
//             [126.81999998128917, 37.714999977725782],
//             [126.82500001576157, 37.715000027249118]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080274',
//       type: 'Feature',
//       properties: {
//         fid: 404198,
//         MAPIDCD_NO: '376080274',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999998128917, 37.714999977725782],
//             [126.82000005469348, 37.710000031940993],
//             [126.81500001667611, 37.710000040232941],
//             [126.81499994760263, 37.714999986521043],
//             [126.81999998128917, 37.714999977725782]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080273',
//       type: 'Feature',
//       properties: {
//         fid: 404201,
//         MAPIDCD_NO: '376080273',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499994760263, 37.714999986521043],
//             [126.81500001667611, 37.710000040232941],
//             [126.80999997799349, 37.710000018514613],
//             [126.81000002671938, 37.71499996415821],
//             [126.81499994760263, 37.714999986521043]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080272',
//       type: 'Feature',
//       properties: {
//         fid: 404203,
//         MAPIDCD_NO: '376080272',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000002671938, 37.71499996415821],
//             [126.80999997799349, 37.710000018514613],
//             [126.80500005440904, 37.709999963862458],
//             [126.80499999317708, 37.714999999453582],
//             [126.81000002671938, 37.71499996415821]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080271',
//       type: 'Feature',
//       properties: {
//         fid: 404205,
//         MAPIDCD_NO: '376080271',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499999317708, 37.714999999453582],
//             [126.80500005440904, 37.709999963862458],
//             [126.80000001591532, 37.7099999668593],
//             [126.79999996127441, 37.715000002939163],
//             [126.80499999317708, 37.714999999453582]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080180',
//       type: 'Feature',
//       properties: {
//         fid: 404207,
//         MAPIDCD_NO: '376080180',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999996127441, 37.715000002939163],
//             [126.80000001591532, 37.7099999668593],
//             [126.79499998047615, 37.710000029997879],
//             [126.79500004222794, 37.714999973477006],
//             [126.79999996127441, 37.715000002939163]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080179',
//       type: 'Feature',
//       properties: {
//         fid: 404210,
//         MAPIDCD_NO: '376080179',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500004222794, 37.714999973477006],
//             [126.79499998047615, 37.710000029997879],
//             [126.79000005648133, 37.709999970061929],
//             [126.79000000825474, 37.715000003445951],
//             [126.79500004222794, 37.714999973477006]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080178',
//       type: 'Feature',
//       properties: {
//         fid: 404213,
//         MAPIDCD_NO: '376080178',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000000825474, 37.715000003445951],
//             [126.79000005648133, 37.709999970061929],
//             [126.7850000221334, 37.709999967786025],
//             [126.78499997822644, 37.715000001617405],
//             [126.79000000825474, 37.715000003445951]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080177',
//       type: 'Feature',
//       properties: {
//         fid: 404215,
//         MAPIDCD_NO: '376080177',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78499997822644, 37.715000001617405],
//             [126.7850000221334, 37.709999967786025],
//             [126.77999998633733, 37.710000023820527],
//             [126.77999994535195, 37.714999967949758],
//             [126.78499997822644, 37.715000001617405]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080176',
//       type: 'Feature',
//       properties: {
//         fid: 404217,
//         MAPIDCD_NO: '376080176',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77999994535195, 37.714999967949758],
//             [126.77999998633733, 37.710000023820527],
//             [126.77499995082964, 37.70999995971458],
//             [126.77500002904571, 37.71499999150528],
//             [126.77999994535195, 37.714999967949758]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080175',
//       type: 'Feature',
//       properties: {
//         fid: 404219,
//         MAPIDCD_NO: '376080175',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.77500002904571, 37.71499999150528],
//             [126.77499995082964, 37.70999995971458],
//             [126.77000002875792, 37.710000041155155],
//             [126.76999999871302, 37.714999984344068],
//             [126.77500002904571, 37.71499999150528]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080465',
//       type: 'Feature',
//       properties: {
//         fid: 404615,
//         MAPIDCD_NO: '376080465',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92500003005245, 37.720000040697983],
//             [126.92499996615052, 37.715000002570214],
//             [126.92000004114536, 37.714999960667868],
//             [126.91999999364155, 37.719999997132909],
//             [126.92500003005245, 37.720000040697983]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080464',
//       type: 'Feature',
//       properties: {
//         fid: 404617,
//         MAPIDCD_NO: '376080464',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999999364155, 37.719999997132909],
//             [126.92000004114536, 37.714999960667868],
//             [126.91500000109966, 37.714999974726972],
//             [126.91499996015487, 37.7200000137007],
//             [126.91999999364155, 37.719999997132909]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080463',
//       type: 'Feature',
//       properties: {
//         fid: 404619,
//         MAPIDCD_NO: '376080463',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499996015487, 37.7200000137007],
//             [126.91500000109966, 37.714999974726972],
//             [126.90999996242232, 37.714999958787928],
//             [126.91000003927171, 37.719999995410042],
//             [126.91499996015487, 37.7200000137007]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080462',
//       type: 'Feature',
//       properties: {
//         fid: 404621,
//         MAPIDCD_NO: '376080462',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000003927171, 37.719999995410042],
//             [126.90999996242232, 37.714999958787928],
//             [126.9050000378919, 37.714999999946464],
//             [126.90500000331944, 37.720000036665674],
//             [126.91000003927171, 37.719999995410042]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080461',
//       type: 'Feature',
//       properties: {
//         fid: 404624,
//         MAPIDCD_NO: '376080461',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500000331944, 37.720000036665674],
//             [126.9050000378919, 37.714999999946464],
//             [126.89999999902557, 37.715000010526907],
//             [126.89999996949614, 37.719999955982168],
//             [126.90500000331944, 37.720000036665674]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080370',
//       type: 'Feature',
//       properties: {
//         fid: 404626,
//         MAPIDCD_NO: '376080370',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89999996949614, 37.719999955982168],
//             [126.89999999902557, 37.715000010526907],
//             [126.89499995932331, 37.714999987491332],
//             [126.89500004909915, 37.720000024326509],
//             [126.89999996949614, 37.719999955982168]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080369',
//       type: 'Feature',
//       properties: {
//         fid: 404628,
//         MAPIDCD_NO: '376080369',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89500004909915, 37.720000024326509],
//             [126.89499995932331, 37.714999987491332],
//             [126.89000003604059, 37.715000023380526],
//             [126.89000001513165, 37.719999970142],
//             [126.89500004909915, 37.720000024326509]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080368',
//       type: 'Feature',
//       properties: {
//         fid: 404630,
//         MAPIDCD_NO: '376080368',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89000001513165, 37.719999970142],
//             [126.89000003604059, 37.715000023380526],
//             [126.88499999618895, 37.71500002866393],
//             [126.88499998186916, 37.719999974273769],
//             [126.89000001513165, 37.719999970142]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080367',
//       type: 'Feature',
//       properties: {
//         fid: 404633,
//         MAPIDCD_NO: '376080367',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499998186916, 37.719999974273769],
//             [126.88499999618895, 37.71500002866393],
//             [126.87999996007612, 37.715000000353591],
//             [126.87999994704038, 37.720000036709422],
//             [126.88499998186916, 37.719999974273769]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080366',
//       type: 'Feature',
//       properties: {
//         fid: 404635,
//         MAPIDCD_NO: '376080366',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87999994704038, 37.720000036709422],
//             [126.87999996007612, 37.715000000353591],
//             [126.87500003586227, 37.715000030957512],
//             [126.875000027904, 37.71999997600328],
//             [126.87999994704038, 37.720000036709422]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080365',
//       type: 'Feature',
//       properties: {
//         fid: 404638,
//         MAPIDCD_NO: '376080365',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.875000027904, 37.71999997600328],
//             [126.87500003586227, 37.715000030957512],
//             [126.86999999740003, 37.71500002914879],
//             [126.86999999374439, 37.719999974805773],
//             [126.875000027904, 37.71999997600328]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080364',
//       type: 'Feature',
//       properties: {
//         fid: 404640,
//         MAPIDCD_NO: '376080364',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999999374439, 37.719999974805773],
//             [126.86999999740003, 37.71500002914879],
//             [126.86499996041344, 37.71499999733841],
//             [126.86499996257494, 37.720000031934681],
//             [126.86999999374439, 37.719999974805773]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080363',
//       type: 'Feature',
//       properties: {
//         fid: 404642,
//         MAPIDCD_NO: '376080363',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499996257494, 37.720000031934681],
//             [126.86499996041344, 37.71499999733841],
//             [126.8600000376279, 37.71500002266454],
//             [126.86000004259829, 37.719999967713441],
//             [126.86499996257494, 37.720000031934681]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080362',
//       type: 'Feature',
//       properties: {
//         fid: 404645,
//         MAPIDCD_NO: '376080362',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000004259829, 37.719999967713441],
//             [126.8600000376279, 37.71500002266454],
//             [126.85500000063038, 37.715000015562183],
//             [126.85500000991661, 37.719999959391806],
//             [126.86000004259829, 37.719999967713441]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080361',
//       type: 'Feature',
//       properties: {
//         fid: 404647,
//         MAPIDCD_NO: '376080361',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500000991661, 37.719999959391806],
//             [126.85500000063038, 37.715000015562183],
//             [126.84999996516012, 37.71499997665439],
//             [126.84999997795832, 37.720000011185469],
//             [126.85500000991661, 37.719999959391806]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080270',
//       type: 'Feature',
//       properties: {
//         fid: 404649,
//         MAPIDCD_NO: '376080270',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999997795832, 37.720000011185469],
//             [126.84999996516012, 37.71499997665439],
//             [126.8450000416242, 37.714999996686061],
//             [126.84499994753044, 37.720000031163934],
//             [126.84999997795832, 37.720000011185469]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080269',
//       type: 'Feature',
//       properties: {
//         fid: 404651,
//         MAPIDCD_NO: '376080269',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499994753044, 37.720000031163934],
//             [126.8450000416242, 37.714999996686061],
//             [126.8400000061819, 37.714999984285853],
//             [126.84000002758768, 37.720000018134186],
//             [126.84499994753044, 37.720000031163934]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080268',
//       type: 'Feature',
//       properties: {
//         fid: 404653,
//         MAPIDCD_NO: '376080268',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000002758768, 37.720000018134186],
//             [126.8400000061819, 37.714999984285853],
//             [126.83499997149743, 37.715000030206276],
//             [126.83499999799969, 37.719999974473275],
//             [126.84000002758768, 37.720000018134186]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080267',
//       type: 'Feature',
//       properties: {
//         fid: 404655,
//         MAPIDCD_NO: '376080267',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83499999799969, 37.719999974473275],
//             [126.83499997149743, 37.715000030206276],
//             [126.83000004956442, 37.715000044951438],
//             [126.82999996692558, 37.719999987307851],
//             [126.83499999799969, 37.719999974473275]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080266',
//       type: 'Feature',
//       properties: {
//         fid: 404658,
//         MAPIDCD_NO: '376080266',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999996692558, 37.719999987307851],
//             [126.83000004956442, 37.715000044951438],
//             [126.82500001576157, 37.715000027249118],
//             [126.82500004860954, 37.719999970764739],
//             [126.82999996692558, 37.719999987307851]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080265',
//       type: 'Feature',
//       properties: {
//         fid: 404661,
//         MAPIDCD_NO: '376080265',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500004860954, 37.719999970764739],
//             [126.82500001576157, 37.715000027249118],
//             [126.81999998128917, 37.714999977725782],
//             [126.8200000198932, 37.720000010096918],
//             [126.82500004860954, 37.719999970764739]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080264',
//       type: 'Feature',
//       properties: {
//         fid: 404663,
//         MAPIDCD_NO: '376080264',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8200000198932, 37.720000010096918],
//             [126.81999998128917, 37.714999977725782],
//             [126.81499994760263, 37.714999986521043],
//             [126.81499999049399, 37.720000019400842],
//             [126.8200000198932, 37.720000010096918]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080263',
//       type: 'Feature',
//       properties: {
//         fid: 404666,
//         MAPIDCD_NO: '376080263',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81499999049399, 37.720000019400842],
//             [126.81499994760263, 37.714999986521043],
//             [126.81000002671938, 37.71499996415821],
//             [126.80999996046107, 37.719999995070836],
//             [126.81499999049399, 37.720000019400842]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080262',
//       type: 'Feature',
//       properties: {
//         fid: 404668,
//         MAPIDCD_NO: '376080262',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80999996046107, 37.719999995070836],
//             [126.81000002671938, 37.71499996415821],
//             [126.80499999317708, 37.714999999453582],
//             [126.80500004466461, 37.720000031520321],
//             [126.80999996046107, 37.719999995070836]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080261',
//       type: 'Feature',
//       properties: {
//         fid: 404670,
//         MAPIDCD_NO: '376080261',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500004466461, 37.720000031520321],
//             [126.80499999317708, 37.714999999453582],
//             [126.79999996127441, 37.715000002939163],
//             [126.80000001479444, 37.720000033670637],
//             [126.80500004466461, 37.720000031520321]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080170',
//       type: 'Feature',
//       properties: {
//         fid: 404672,
//         MAPIDCD_NO: '376080170',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000001479444, 37.720000033670637],
//             [126.79999996127441, 37.715000002939163],
//             [126.79500004222794, 37.714999973477006],
//             [126.79499998657941, 37.7200000040014],
//             [126.80000001479444, 37.720000033670637]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080169',
//       type: 'Feature',
//       properties: {
//         fid: 404675,
//         MAPIDCD_NO: '376080169',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499998657941, 37.7200000040014],
//             [126.79500004222794, 37.714999973477006],
//             [126.79000000825474, 37.715000003445951],
//             [126.78999995915822, 37.720000034441178],
//             [126.79499998657941, 37.7200000040014]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080168',
//       type: 'Feature',
//       properties: {
//         fid: 404678,
//         MAPIDCD_NO: '376080168',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78999995915822, 37.720000034441178],
//             [126.79000000825474, 37.715000003445951],
//             [126.78499997822644, 37.715000001617405],
//             [126.78500004461991, 37.720000031932479],
//             [126.78999995915822, 37.720000034441178]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080167',
//       type: 'Feature',
//       properties: {
//         fid: 404680,
//         MAPIDCD_NO: '376080167',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78500004461991, 37.720000031932479],
//             [126.78499997822644, 37.715000001617405],
//             [126.77999994535195, 37.714999967949758],
//             [126.78000001831308, 37.719999996919576],
//             [126.78500004461991, 37.720000031932479]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080166',
//       type: 'Feature',
//       properties: {
//         fid: 404682,
//         MAPIDCD_NO: '376080166',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78000001831308, 37.719999996919576],
//             [126.77999994535195, 37.714999967949758],
//             [126.77500002904571, 37.71499999150528],
//             [126.77499999281763, 37.720000022014155],
//             [126.78000001831308, 37.719999996919576]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080455',
//       type: 'Feature',
//       properties: {
//         fid: 405070,
//         MAPIDCD_NO: '376080455',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499997909782, 37.724999983924747],
//             [126.92500003005245, 37.720000040697983],
//             [126.91999999364155, 37.719999997132909],
//             [126.91999994852684, 37.725000031204324],
//             [126.92499997909782, 37.724999983924747]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080454',
//       type: 'Feature',
//       properties: {
//         fid: 405072,
//         MAPIDCD_NO: '376080454',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999994852684, 37.725000031204324],
//             [126.91999999364155, 37.719999997132909],
//             [126.91499996015487, 37.7200000137007],
//             [126.91500003124482, 37.724999955285817],
//             [126.91999994852684, 37.725000031204324]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080453',
//       type: 'Feature',
//       properties: {
//         fid: 405074,
//         MAPIDCD_NO: '376080453',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500003124482, 37.724999955285817],
//             [126.91499996015487, 37.7200000137007],
//             [126.91000003927171, 37.719999995410042],
//             [126.91000000044171, 37.725000029049376],
//             [126.91500003124482, 37.724999955285817]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080452',
//       type: 'Feature',
//       properties: {
//         fid: 405076,
//         MAPIDCD_NO: '376080452',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000000044171, 37.725000029049376],
//             [126.91000003927171, 37.719999995410042],
//             [126.90500000331944, 37.720000036665674],
//             [126.90499996947533, 37.724999980853482],
//             [126.91000000044171, 37.725000029049376]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080451',
//       type: 'Feature',
//       properties: {
//         fid: 405079,
//         MAPIDCD_NO: '376080451',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499996947533, 37.724999980853482],
//             [126.90500000331944, 37.720000036665674],
//             [126.89999996949614, 37.719999955982168],
//             [126.90000005267967, 37.724999989740873],
//             [126.90499996947533, 37.724999980853482]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080360',
//       type: 'Feature',
//       properties: {
//         fid: 405081,
//         MAPIDCD_NO: '376080360',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000005267967, 37.724999989740873],
//             [126.89999996949614, 37.719999955982168],
//             [126.89500004909915, 37.720000024326509],
//             [126.8950000238314, 37.724999966235195],
//             [126.90000005267967, 37.724999989740873]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080359',
//       type: 'Feature',
//       properties: {
//         fid: 405083,
//         MAPIDCD_NO: '376080359',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8950000238314, 37.724999966235195],
//             [126.89500004909915, 37.720000024326509],
//             [126.89000001513165, 37.719999970142],
//             [126.88999999339335, 37.725000002827827],
//             [126.8950000238314, 37.724999966235195]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080358',
//       type: 'Feature',
//       properties: {
//         fid: 405085,
//         MAPIDCD_NO: '376080358',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88999999339335, 37.725000002827827],
//             [126.89000001513165, 37.719999970142],
//             [126.88499998186916, 37.719999974273769],
//             [126.88499996440753, 37.725000005796666],
//             [126.88999999339335, 37.725000002827827]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080357',
//       type: 'Feature',
//       properties: {
//         fid: 405088,
//         MAPIDCD_NO: '376080357',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499996440753, 37.725000005796666],
//             [126.88499998186916, 37.719999974273769],
//             [126.87999994704038, 37.720000036709422],
//             [126.88000004807553, 37.72499997752886],
//             [126.88499996440753, 37.725000005796666]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080356',
//       type: 'Feature',
//       properties: {
//         fid: 405090,
//         MAPIDCD_NO: '376080356',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000004807553, 37.72499997752886],
//             [126.87999994704038, 37.720000036709422],
//             [126.875000027904, 37.71999997600328],
//             [126.87500001897104, 37.725000008777563],
//             [126.88000004807553, 37.72499997752886]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080355',
//       type: 'Feature',
//       properties: {
//         fid: 405092,
//         MAPIDCD_NO: '376080355',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500001897104, 37.725000008777563],
//             [126.875000027904, 37.71999997600328],
//             [126.86999999374439, 37.719999974805773],
//             [126.86999998908586, 37.725000006389173],
//             [126.87500001897104, 37.725000008777563]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080354',
//       type: 'Feature',
//       properties: {
//         fid: 405095,
//         MAPIDCD_NO: '376080354',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999998908586, 37.725000006389173],
//             [126.86999999374439, 37.719999974805773],
//             [126.86499996257494, 37.720000031934681],
//             [126.86499996067506, 37.724999973980516],
//             [126.86999998908586, 37.725000006389173]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080353',
//       type: 'Feature',
//       properties: {
//         fid: 405097,
//         MAPIDCD_NO: '376080353',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499996067506, 37.724999973980516],
//             [126.86499996257494, 37.720000031934681],
//             [126.86000004259829, 37.719999967713441],
//             [126.86000004647897, 37.724999998689832],
//             [126.86499996067506, 37.724999973980516]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080352',
//       type: 'Feature',
//       properties: {
//         fid: 405100,
//         MAPIDCD_NO: '376080352',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000004647897, 37.724999998689832],
//             [126.86000004259829, 37.719999967713441],
//             [126.85500000991661, 37.719999959391806],
//             [126.85500001805407, 37.724999990951964],
//             [126.86000004647897, 37.724999998689832]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080351',
//       type: 'Feature',
//       properties: {
//         fid: 405102,
//         MAPIDCD_NO: '376080351',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500001805407, 37.724999990951964],
//             [126.85500000991661, 37.719999959391806],
//             [126.84999997795832, 37.720000011185469],
//             [126.84999999036735, 37.725000041517511],
//             [126.85500001805407, 37.724999990951964]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080260',
//       type: 'Feature',
//       properties: {
//         fid: 405104,
//         MAPIDCD_NO: '376080260',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84999999036735, 37.725000041517511],
//             [126.84999997795832, 37.720000011185469],
//             [126.84499994753044, 37.720000031163934],
//             [126.84499996273492, 37.724999970118652],
//             [126.84999999036735, 37.725000041517511]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080259',
//       type: 'Feature',
//       properties: {
//         fid: 405106,
//         MAPIDCD_NO: '376080259',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499996273492, 37.724999970118652],
//             [126.84499994753044, 37.720000031163934],
//             [126.84000002758768, 37.720000018134186],
//             [126.84000004932943, 37.724999957657545],
//             [126.84499996273492, 37.724999970118652]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080258',
//       type: 'Feature',
//       properties: {
//         fid: 405108,
//         MAPIDCD_NO: '376080258',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84000004932943, 37.724999957657545],
//             [126.84000002758768, 37.720000018134186],
//             [126.83499999799969, 37.719999974473275],
//             [126.83500002321128, 37.7250000028679],
//             [126.84000004932943, 37.724999957657545]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080257',
//       type: 'Feature',
//       properties: {
//         fid: 405110,
//         MAPIDCD_NO: '376080257',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500002321128, 37.7250000028679],
//             [126.83499999799969, 37.719999974473275],
//             [126.82999996692558, 37.719999987307851],
//             [126.82999999638982, 37.72500001623969],
//             [126.83500002321128, 37.7250000028679]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080256',
//       type: 'Feature',
//       properties: {
//         fid: 405113,
//         MAPIDCD_NO: '376080256',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82999999638982, 37.72500001623969],
//             [126.82999996692558, 37.719999987307851],
//             [126.82500004860954, 37.719999970764739],
//             [126.82499996886469, 37.724999999574869],
//             [126.82999999638982, 37.72500001623969]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080255',
//       type: 'Feature',
//       properties: {
//         fid: 405116,
//         MAPIDCD_NO: '376080255',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82499996886469, 37.724999999574869],
//             [126.82500004860954, 37.719999970764739],
//             [126.8200000198932, 37.720000010096918],
//             [126.82000005561461, 37.725000038259736],
//             [126.82499996886469, 37.724999999574869]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080254',
//       type: 'Feature',
//       properties: {
//         fid: 405118,
//         MAPIDCD_NO: '376080254',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82000005561461, 37.725000038259736],
//             [126.8200000198932, 37.720000010096918],
//             [126.81499999049399, 37.720000019400842],
//             [126.81500003131205, 37.724999956143577],
//             [126.82000005561461, 37.725000038259736]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080253',
//       type: 'Feature',
//       properties: {
//         fid: 405121,
//         MAPIDCD_NO: '376080253',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81500003131205, 37.724999956143577],
//             [126.81499999049399, 37.720000019400842],
//             [126.80999996046107, 37.719999995070836],
//             [126.81000000695086, 37.725000024256083],
//             [126.81500003131205, 37.724999956143577]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080252',
//       type: 'Feature',
//       properties: {
//         fid: 405123,
//         MAPIDCD_NO: '376080252',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000000695086, 37.725000024256083],
//             [126.80999996046107, 37.719999995070836],
//             [126.80500004466461, 37.720000031520321],
//             [126.80499998053193, 37.724999968584839],
//             [126.81000000695086, 37.725000024256083]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080251',
//       type: 'Feature',
//       properties: {
//         fid: 405125,
//         MAPIDCD_NO: '376080251',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80499998053193, 37.724999968584839],
//             [126.80500004466461, 37.720000031520321],
//             [126.80000001479444, 37.720000033670637],
//             [126.79999995720141, 37.724999969422704],
//             [126.80499998053193, 37.724999968584839]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080160',
//       type: 'Feature',
//       properties: {
//         fid: 405127,
//         MAPIDCD_NO: '376080160',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999995720141, 37.724999969422704],
//             [126.80000001479444, 37.720000033670637],
//             [126.79499998657941, 37.7200000040014],
//             [126.79500004585235, 37.725000031025807],
//             [126.79999995720141, 37.724999969422704]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080159',
//       type: 'Feature',
//       properties: {
//         fid: 405130,
//         MAPIDCD_NO: '376080159',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500004585235, 37.725000031025807],
//             [126.79499998657941, 37.7200000040014],
//             [126.78999995915822, 37.720000034441178],
//             [126.79000002128491, 37.724999969985852],
//             [126.79500004585235, 37.725000031025807]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080158',
//       type: 'Feature',
//       properties: {
//         fid: 405133,
//         MAPIDCD_NO: '376080158',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79000002128491, 37.724999969985852],
//             [126.78999995915822, 37.720000034441178],
//             [126.78500004461991, 37.720000031932479],
//             [126.78499999980689, 37.724999967256238],
//             [126.79000002128491, 37.724999969985852]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080157',
//       type: 'Feature',
//       properties: {
//         fid: 405135,
//         MAPIDCD_NO: '376080157',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.78499999980689, 37.724999967256238],
//             [126.78500004461991, 37.720000031932479],
//             [126.78000001831308, 37.719999996919576],
//             [126.77999997687749, 37.725000022808921],
//             [126.78499999980689, 37.724999967256238]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080445',
//       type: 'Feature',
//       properties: {
//         fid: 405525,
//         MAPIDCD_NO: '376080445',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92500004109607, 37.730000013625656],
//             [126.92499997909782, 37.724999983924747],
//             [126.91999994852684, 37.725000031204324],
//             [126.92000001547191, 37.729999969684243],
//             [126.92500004109607, 37.730000013625656]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080444',
//       type: 'Feature',
//       properties: {
//         fid: 405527,
//         MAPIDCD_NO: '376080444',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000001547191, 37.729999969684243],
//             [126.91999994852684, 37.725000031204324],
//             [126.91500003124482, 37.724999955285817],
//             [126.91499998823166, 37.729999985834652],
//             [126.92000001547191, 37.729999969684243]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080443',
//       type: 'Feature',
//       properties: {
//         fid: 405529,
//         MAPIDCD_NO: '376080443',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499998823166, 37.729999985834652],
//             [126.91500003124482, 37.724999955285817],
//             [126.91000000044171, 37.725000029049376],
//             [126.90999996238612, 37.729999968353987],
//             [126.91499998823166, 37.729999985834652]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080442',
//       type: 'Feature',
//       properties: {
//         fid: 405531,
//         MAPIDCD_NO: '376080442',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999996238612, 37.729999968353987],
//             [126.91000000044171, 37.725000029049376],
//             [126.90499996947533, 37.724999980853482],
//             [126.90500004843878, 37.730000007931544],
//             [126.90999996238612, 37.729999968353987]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080441',
//       type: 'Feature',
//       properties: {
//         fid: 405534,
//         MAPIDCD_NO: '376080441',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500004843878, 37.730000007931544],
//             [126.90499996947533, 37.724999980853482],
//             [126.90000005267967, 37.724999989740873],
//             [126.90000002238543, 37.730000018716886],
//             [126.90500004843878, 37.730000007931544]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080350',
//       type: 'Feature',
//       properties: {
//         fid: 405536,
//         MAPIDCD_NO: '376080350',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90000002238543, 37.730000018716886],
//             [126.90000005267967, 37.724999989740873],
//             [126.8950000238314, 37.724999966235195],
//             [126.89499999776322, 37.72999999587006],
//             [126.90000002238543, 37.730000018716886]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080349',
//       type: 'Feature',
//       properties: {
//         fid: 405538,
//         MAPIDCD_NO: '376080349',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.89499999776322, 37.72999999587006],
//             [126.8950000238314, 37.724999966235195],
//             [126.88999999339335, 37.725000002827827],
//             [126.88999997156499, 37.730000031309608],
//             [126.89499999776322, 37.72999999587006]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080348',
//       type: 'Feature',
//       properties: {
//         fid: 405540,
//         MAPIDCD_NO: '376080348',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88999997156499, 37.730000031309608],
//             [126.88999999339335, 37.725000002827827],
//             [126.88499996440753, 37.725000005796666],
//             [126.88499994680359, 37.73000003491866],
//             [126.88999997156499, 37.730000031309608]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080347',
//       type: 'Feature',
//       properties: {
//         fid: 405543,
//         MAPIDCD_NO: '376080347',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88499994680359, 37.73000003491866],
//             [126.88499996440753, 37.725000005796666],
//             [126.88000004807553, 37.72499997752886],
//             [126.88000003471774, 37.73000000547929],
//             [126.88499994680359, 37.73000003491866]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080346',
//       type: 'Feature',
//       properties: {
//         fid: 405545,
//         MAPIDCD_NO: '376080346',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.88000003471774, 37.73000000547929],
//             [126.88000004807553, 37.72499997752886],
//             [126.87500001897104, 37.725000008777563],
//             [126.87500000985109, 37.730000035547008],
//             [126.88000003471774, 37.73000000547929]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080345',
//       type: 'Feature',
//       properties: {
//         fid: 405547,
//         MAPIDCD_NO: '376080345',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87500000985109, 37.730000035547008],
//             [126.87500001897104, 37.725000008777563],
//             [126.86999998908586, 37.725000006389173],
//             [126.8699999841878, 37.730000033770942],
//             [126.87500000985109, 37.730000035547008]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080344',
//       type: 'Feature',
//       properties: {
//         fid: 405550,
//         MAPIDCD_NO: '376080344',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8699999841878, 37.730000033770942],
//             [126.86999998908586, 37.725000006389173],
//             [126.86499996067506, 37.724999973980516],
//             [126.86499996001358, 37.730000000162775],
//             [126.8699999841878, 37.730000033770942]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080343',
//       type: 'Feature',
//       properties: {
//         fid: 405552,
//         MAPIDCD_NO: '376080343',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499996001358, 37.730000000162775],
//             [126.86499996067506, 37.724999973980516],
//             [126.86000004647897, 37.724999998689832],
//             [126.86000005006099, 37.730000023663244],
//             [126.86499996001358, 37.730000000162775]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080342',
//       type: 'Feature',
//       properties: {
//         fid: 405555,
//         MAPIDCD_NO: '376080342',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000005006099, 37.730000023663244],
//             [126.86000004647897, 37.724999998689832],
//             [126.85500001805407, 37.724999990951964],
//             [126.85500002585562, 37.730000016509791],
//             [126.86000005006099, 37.730000023663244]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080341',
//       type: 'Feature',
//       properties: {
//         fid: 405557,
//         MAPIDCD_NO: '376080341',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500002585562, 37.730000016509791],
//             [126.85500001805407, 37.724999990951964],
//             [126.84999999036735, 37.725000041517511],
//             [126.85000000090585, 37.729999977510516],
//             [126.85500002585562, 37.730000016509791]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080250',
//       type: 'Feature',
//       properties: {
//         fid: 405559,
//         MAPIDCD_NO: '376080250',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000000090585, 37.729999977510516],
//             [126.84999999036735, 37.725000041517511],
//             [126.84499996273492, 37.724999970118652],
//             [126.84499997897289, 37.729999996817362],
//             [126.85000000090585, 37.729999977510516]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080249',
//       type: 'Feature',
//       properties: {
//         fid: 405561,
//         MAPIDCD_NO: '376080249',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499997897289, 37.729999996817362],
//             [126.84499996273492, 37.724999970118652],
//             [126.84000004932943, 37.724999957657545],
//             [126.83999995406211, 37.729999982462196],
//             [126.84499997897289, 37.729999996817362]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080248',
//       type: 'Feature',
//       properties: {
//         fid: 405563,
//         MAPIDCD_NO: '376080248',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83999995406211, 37.729999982462196],
//             [126.84000004932943, 37.724999957657545],
//             [126.83500002321128, 37.7250000028679],
//             [126.83500004563638, 37.730000028854924],
//             [126.83999995406211, 37.729999982462196]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080247',
//       type: 'Feature',
//       properties: {
//         fid: 405565,
//         MAPIDCD_NO: '376080247',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83500004563638, 37.730000028854924],
//             [126.83500002321128, 37.7250000028679],
//             [126.82999999638982, 37.72500001623969],
//             [126.83000002303001, 37.730000042764459],
//             [126.83500004563638, 37.730000028854924]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080246',
//       type: 'Feature',
//       properties: {
//         fid: 405568,
//         MAPIDCD_NO: '376080246',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.83000002303001, 37.730000042764459],
//             [126.82999999638982, 37.72500001623969],
//             [126.82499996886469, 37.724999999574869],
//             [126.82500000202128, 37.730000023036077],
//             [126.83000002303001, 37.730000042764459]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080245',
//       type: 'Feature',
//       properties: {
//         fid: 405571,
//         MAPIDCD_NO: '376080245',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.82500000202128, 37.730000023036077],
//             [126.82499996886469, 37.724999999574869],
//             [126.82000005561461, 37.725000038259736],
//             [126.81999998032413, 37.729999973261386],
//             [126.82500000202128, 37.730000023036077]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080244',
//       type: 'Feature',
//       properties: {
//         fid: 405573,
//         MAPIDCD_NO: '376080244',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81999998032413, 37.729999973261386],
//             [126.82000005561461, 37.725000038259736],
//             [126.81500003131205, 37.724999956143577],
//             [126.8149999571573, 37.729999979961484],
//             [126.81999998032413, 37.729999973261386]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080243',
//       type: 'Feature',
//       properties: {
//         fid: 405576,
//         MAPIDCD_NO: '376080243',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8149999571573, 37.729999979961484],
//             [126.81500003131205, 37.724999956143577],
//             [126.81000000695086, 37.725000024256083],
//             [126.81000005133649, 37.729999957304763],
//             [126.8149999571573, 37.729999979961484]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080242',
//       type: 'Feature',
//       properties: {
//         fid: 405578,
//         MAPIDCD_NO: '376080242',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.81000005133649, 37.729999957304763],
//             [126.81000000695086, 37.725000024256083],
//             [126.80499998053193, 37.724999968584839],
//             [126.8050000283089, 37.7299999904489],
//             [126.81000005133649, 37.729999957304763]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080241',
//       type: 'Feature',
//       properties: {
//         fid: 405580,
//         MAPIDCD_NO: '376080241',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8050000283089, 37.7299999904489],
//             [126.80499998053193, 37.724999968584839],
//             [126.79999995720141, 37.724999969422704],
//             [126.80000000917157, 37.729999993571212],
//             [126.8050000283089, 37.7299999904489]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080150',
//       type: 'Feature',
//       properties: {
//         fid: 405582,
//         MAPIDCD_NO: '376080150',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80000000917157, 37.729999993571212],
//             [126.79999995720141, 37.724999969422704],
//             [126.79500004585235, 37.725000031025807],
//             [126.79499998943531, 37.729999963039496],
//             [126.80000000917157, 37.729999993571212]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080149',
//       type: 'Feature',
//       properties: {
//         fid: 405585,
//         MAPIDCD_NO: '376080149',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499998943531, 37.729999963039496],
//             [126.79500004585235, 37.725000031025807],
//             [126.79000002128491, 37.724999969985852],
//             [126.78999997049115, 37.7299999925981],
//             [126.79499998943531, 37.729999963039496]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080436',
//       type: 'Feature',
//       properties: {
//         fid: 405973,
//         MAPIDCD_NO: '376080436',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93000001327776, 37.734999959834717],
//             [126.92999995460257, 37.730000025190684],
//             [126.92500004109607, 37.730000013625656],
//             [126.92499998979545, 37.735000038564806],
//             [126.93000001327776, 37.734999959834717]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080435',
//       type: 'Feature',
//       properties: {
//         fid: 405975,
//         MAPIDCD_NO: '376080435',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499998979545, 37.735000038564806],
//             [126.92500004109607, 37.730000013625656],
//             [126.92000001547191, 37.729999969684243],
//             [126.91999996609431, 37.734999995318177],
//             [126.92499998979545, 37.735000038564806]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080434',
//       type: 'Feature',
//       properties: {
//         fid: 405977,
//         MAPIDCD_NO: '376080434',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999996609431, 37.734999995318177],
//             [126.92000001547191, 37.729999969684243],
//             [126.91499998823166, 37.729999985834652],
//             [126.91499994532967, 37.735000010373767],
//             [126.91999996609431, 37.734999995318177]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080433',
//       type: 'Feature',
//       properties: {
//         fid: 405979,
//         MAPIDCD_NO: '376080433',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499994532967, 37.735000010373767],
//             [126.91499998823166, 37.729999985834652],
//             [126.90999996238612, 37.729999968353987],
//             [126.91000003490531, 37.734999992334515],
//             [126.91499994532967, 37.735000010373767]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080432',
//       type: 'Feature',
//       properties: {
//         fid: 405981,
//         MAPIDCD_NO: '376080432',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000003490531, 37.734999992334515],
//             [126.90999996238612, 37.729999968353987],
//             [126.90500004843878, 37.730000007931544],
//             [126.90500001392559, 37.735000033836016],
//             [126.91000003490531, 37.734999992334515]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080431',
//       type: 'Feature',
//       properties: {
//         fid: 405984,
//         MAPIDCD_NO: '376080431',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500001392559, 37.735000033836016],
//             [126.90500004843878, 37.730000007931544],
//             [126.90000002238543, 37.730000018716886],
//             [126.89999999209061, 37.735000041684934],
//             [126.90500001392559, 37.735000033836016]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080335',
//       type: 'Feature',
//       properties: {
//         fid: 405997,
//         MAPIDCD_NO: '376080335',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.87499999901725, 37.7349999679743],
//             [126.87500000985109, 37.730000035547008],
//             [126.8699999841878, 37.730000033770942],
//             [126.86999997754464, 37.734999966811131],
//             [126.87499999901725, 37.7349999679743]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080334',
//       type: 'Feature',
//       properties: {
//         fid: 406000,
//         MAPIDCD_NO: '376080334',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86999997754464, 37.734999966811131],
//             [126.8699999841878, 37.730000033770942],
//             [126.86499996001358, 37.730000000162775],
//             [126.86499995680585, 37.735000022131551],
//             [126.86999997754464, 37.734999966811131]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080333',
//       type: 'Feature',
//       properties: {
//         fid: 406002,
//         MAPIDCD_NO: '376080333',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86499995680585, 37.735000022131551],
//             [126.86499996001358, 37.730000000162775],
//             [126.86000005006099, 37.730000023663244],
//             [126.86000005181973, 37.734999956098861],
//             [126.86499995680585, 37.735000022131551]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080332',
//       type: 'Feature',
//       properties: {
//         fid: 406005,
//         MAPIDCD_NO: '376080332',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000005181973, 37.734999956098861],
//             [126.86000005006099, 37.730000023663244],
//             [126.85500002585562, 37.730000016509791],
//             [126.85500003102058, 37.73500003965772],
//             [126.86000005181973, 37.734999956098861]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080331',
//       type: 'Feature',
//       properties: {
//         fid: 406007,
//         MAPIDCD_NO: '376080331',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500003102058, 37.73500003965772],
//             [126.85500002585562, 37.730000016509791],
//             [126.85000000090585, 37.729999977510516],
//             [126.85000001026772, 37.734999999431544],
//             [126.85500003102058, 37.73500003965772]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080240',
//       type: 'Feature',
//       properties: {
//         fid: 406009,
//         MAPIDCD_NO: '376080240',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85000001026772, 37.734999999431544],
//             [126.85000000090585, 37.729999977510516],
//             [126.84499997897289, 37.729999996817362],
//             [126.84499999253112, 37.735000017502124],
//             [126.85000001026772, 37.734999999431544]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080239',
//       type: 'Feature',
//       properties: {
//         fid: 406011,
//         MAPIDCD_NO: '376080239',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.84499999253112, 37.735000017502124],
//             [126.84499997897289, 37.729999996817362],
//             [126.83999995406211, 37.729999982462196],
//             [126.83999997406953, 37.735000003716685],
//             [126.84499999253112, 37.735000017502124]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080231',
//       type: 'Feature',
//       properties: {
//         fid: 406030,
//         MAPIDCD_NO: '376080231',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.8049999618608, 37.7350000092527],
//             [126.8050000283089, 37.7299999904489],
//             [126.80000000917157, 37.729999993571212],
//             [126.79999994691319, 37.735000011050268],
//             [126.8049999618608, 37.7350000092527]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080140',
//       type: 'Feature',
//       properties: {
//         fid: 406032,
//         MAPIDCD_NO: '376080140',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999994691319, 37.735000011050268],
//             [126.80000000917157, 37.729999993571212],
//             [126.79499998943531, 37.729999963039496],
//             [126.79500004483172, 37.734999981665787],
//             [126.79999994691319, 37.735000011050268]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080139',
//       type: 'Feature',
//       properties: {
//         fid: 406035,
//         MAPIDCD_NO: '376080139',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79500004483172, 37.734999981665787],
//             [126.79499998943531, 37.729999963039496],
//             [126.78999997049115, 37.7299999925981],
//             [126.79000002780589, 37.735000009872152],
//             [126.79500004483172, 37.734999981665787]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080427',
//       type: 'Feature',
//       properties: {
//         fid: 406421,
//         MAPIDCD_NO: '376080427',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93499997566822, 37.7399999622806],
//             [126.93500003443846, 37.7350000313168],
//             [126.93000001327776, 37.734999959834717],
//             [126.92999995570952, 37.739999981641269],
//             [126.93499997566822, 37.7399999622806]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080426',
//       type: 'Feature',
//       properties: {
//         fid: 406423,
//         MAPIDCD_NO: '376080426',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92999995570952, 37.739999981641269],
//             [126.93000001327776, 37.734999959834717],
//             [126.92499998979545, 37.735000038564806],
//             [126.92500005058759, 37.739999969709608],
//             [126.92999995570952, 37.739999981641269]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080425',
//       type: 'Feature',
//       properties: {
//         fid: 406425,
//         MAPIDCD_NO: '376080425',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92500005058759, 37.739999969709608],
//             [126.92499998979545, 37.735000038564806],
//             [126.91999996609431, 37.734999995318177],
//             [126.92000003262831, 37.740000015511228],
//             [126.92500005058759, 37.739999969709608]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080424',
//       type: 'Feature',
//       properties: {
//         fid: 406427,
//         MAPIDCD_NO: '376080424',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000003262831, 37.740000015511228],
//             [126.91999996609431, 37.734999995318177],
//             [126.91499994532967, 37.735000010373767],
//             [126.91500001376254, 37.740000029455004],
//             [126.92000003262831, 37.740000015511228]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080423',
//       type: 'Feature',
//       properties: {
//         fid: 406429,
//         MAPIDCD_NO: '376080423',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500001376254, 37.740000029455004],
//             [126.91499994532967, 37.735000010373767],
//             [126.91000003490531, 37.734999992334515],
//             [126.90999999626185, 37.740000013354255],
//             [126.91500001376254, 37.740000029455004]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080422',
//       type: 'Feature',
//       properties: {
//         fid: 406431,
//         MAPIDCD_NO: '376080422',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999999626185, 37.740000013354255],
//             [126.91000003490531, 37.734999992334515],
//             [126.90500001392559, 37.735000033836016],
//             [126.90499997790097, 37.739999963592162],
//             [126.90999999626185, 37.740000013354255]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080421',
//       type: 'Feature',
//       properties: {
//         fid: 406434,
//         MAPIDCD_NO: '376080421',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90499997790097, 37.739999963592162],
//             [126.90500001392559, 37.735000033836016],
//             [126.89999999209061, 37.735000041684934],
//             [126.89999996022418, 37.739999972110361],
//             [126.90499997790097, 37.739999963592162]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080322',
//       type: 'Feature',
//       properties: {
//         fid: 406455,
//         MAPIDCD_NO: '376080322',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.86000005248879, 37.739999974461156],
//             [126.86000005181973, 37.734999956098861],
//             [126.85500003102058, 37.73500003965772],
//             [126.85500003663192, 37.739999966675668],
//             [126.86000005248879, 37.739999974461156]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080321',
//       type: 'Feature',
//       properties: {
//         fid: 406457,
//         MAPIDCD_NO: '376080321',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.85500003663192, 37.739999966675668],
//             [126.85500003102058, 37.73500003965772],
//             [126.85000001026772, 37.734999999431544],
//             [126.85000001924091, 37.740000017153015],
//             [126.85500003663192, 37.739999966675668]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080221',
//       type: 'Feature',
//       properties: {
//         fid: 406480,
//         MAPIDCD_NO: '376080221',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.80500000816329, 37.740000024531675],
//             [126.8049999618608, 37.7350000092527],
//             [126.79999994691319, 37.735000011050268],
//             [126.79999999509811, 37.74000002499649],
//             [126.80500000816329, 37.740000024531675]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080130',
//       type: 'Feature',
//       properties: {
//         fid: 406482,
//         MAPIDCD_NO: '376080130',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79999999509811, 37.74000002499649],
//             [126.79999994691319, 37.735000011050268],
//             [126.79500004483172, 37.734999981665787],
//             [126.79499998366816, 37.739999995407153],
//             [126.79999999509811, 37.74000002499649]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080129',
//       type: 'Feature',
//       properties: {
//         fid: 406485,
//         MAPIDCD_NO: '376080129',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.79499998366816, 37.739999995407153],
//             [126.79500004483172, 37.734999981665787],
//             [126.79000002780589, 37.735000009872152],
//             [126.78999997306276, 37.740000022284384],
//             [126.79499998366816, 37.739999995407153]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080417',
//       type: 'Feature',
//       properties: {
//         fid: 406871,
//         MAPIDCD_NO: '376080417',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93500002770504, 37.744999977893869],
//             [126.93499997566822, 37.7399999622806],
//             [126.92999995570952, 37.739999981641269],
//             [126.9300000118657, 37.74499999798487],
//             [126.93500002770504, 37.744999977893869]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080416',
//       type: 'Feature',
//       properties: {
//         fid: 406873,
//         MAPIDCD_NO: '376080416',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9300000118657, 37.74499999798487],
//             [126.92999995570952, 37.739999981641269],
//             [126.92500005058759, 37.739999969709608],
//             [126.92499999736896, 37.744999986220634],
//             [126.9300000118657, 37.74499999798487]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080415',
//       type: 'Feature',
//       properties: {
//         fid: 406875,
//         MAPIDCD_NO: '376080415',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499999736896, 37.744999986220634],
//             [126.92500005058759, 37.739999969709608],
//             [126.92000003262831, 37.740000015511228],
//             [126.91999998352755, 37.7450000327292],
//             [126.92499999736896, 37.744999986220634]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080414',
//       type: 'Feature',
//       properties: {
//         fid: 406877,
//         MAPIDCD_NO: '376080414',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91999998352755, 37.7450000327292],
//             [126.92000003262831, 37.740000015511228],
//             [126.91500001376254, 37.740000029455004],
//             [126.91499996950235, 37.744999955439823],
//             [126.91999998352755, 37.7450000327292]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080413',
//       type: 'Feature',
//       properties: {
//         fid: 406879,
//         MAPIDCD_NO: '376080413',
//         MAPID_NM: '서울',
//         color: '#e63757'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91499996950235, 37.744999955439823],
//             [126.91500001376254, 37.740000029455004],
//             [126.90999999626185, 37.740000013354255],
//             [126.90999995542292, 37.745000028353111],
//             [126.91499996950235, 37.744999955439823]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080412',
//       type: 'Feature',
//       properties: {
//         fid: 406881,
//         MAPIDCD_NO: '376080412',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90999995542292, 37.745000028353111],
//             [126.90999999626185, 37.740000013354255],
//             [126.90499997790097, 37.739999963592162],
//             [126.90500005467825, 37.744999979843108],
//             [126.90999995542292, 37.745000028353111]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080411',
//       type: 'Feature',
//       properties: {
//         fid: 406884,
//         MAPIDCD_NO: '376080411',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.90500005467825, 37.744999979843108],
//             [126.90499997790097, 37.739999963592162],
//             [126.89999996022418, 37.739999972110361],
//             [126.9000000411307, 37.744999987233349],
//             [126.90500005467825, 37.744999979843108]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080407',
//       type: 'Feature',
//       properties: {
//         fid: 407316,
//         MAPIDCD_NO: '376080407',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.93499996648168, 37.749999990555686],
//             [126.93500002770504, 37.744999977893869],
//             [126.9300000118657, 37.74499999798487],
//             [126.9299999547243, 37.750000011372791],
//             [126.93499996648168, 37.749999990555686]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080406',
//       type: 'Feature',
//       properties: {
//         fid: 407318,
//         MAPIDCD_NO: '376080406',
//         MAPID_NM: '서울',
//         color: '#f5803e'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.9299999547243, 37.750000011372791],
//             [126.9300000118657, 37.74499999798487],
//             [126.92499999736896, 37.744999986220634],
//             [126.92499994432271, 37.74999999852281],
//             [126.9299999547243, 37.750000011372791]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080405',
//       type: 'Feature',
//       properties: {
//         fid: 407320,
//         MAPIDCD_NO: '376080405',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92499994432271, 37.74999999852281],
//             [126.92499999736896, 37.744999986220634],
//             [126.91999998352755, 37.7450000327292],
//             [126.92000004808446, 37.750000044494882],
//             [126.92499994432271, 37.74999999852281]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080404',
//       type: 'Feature',
//       properties: {
//         fid: 407322,
//         MAPIDCD_NO: '376080404',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.92000004808446, 37.750000044494882],
//             [126.91999998352755, 37.7450000327292],
//             [126.91499996950235, 37.744999955439823],
//             [126.91500003813897, 37.749999967908643],
//             [126.92000004808446, 37.750000044494882]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080403',
//       type: 'Feature',
//       properties: {
//         fid: 407324,
//         MAPIDCD_NO: '376080403',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91500003813897, 37.749999967908643],
//             [126.91499996950235, 37.744999955439823],
//             [126.90999995542292, 37.745000028353111],
//             [126.91000002588251, 37.750000039701689],
//             [126.91500003813897, 37.749999967908643]
//           ]
//         ]
//       }
//     },
//     {
//       id: '376080402',
//       type: 'Feature',
//       properties: {
//         fid: 407326,
//         MAPIDCD_NO: '376080402',
//         MAPID_NM: '서울',
//         color: '#00d27a'
//       },
//       geometry: {
//         type: 'Polygon',
//         coordinates: [
//           [
//             [126.91000002588251, 37.750000039701689],
//             [126.90999995542292, 37.745000028353111],
//             [126.90500005467825, 37.744999979843108],
//             [126.90500001571537, 37.749999991303135],
//             [126.91000002588251, 37.750000039701689]
//           ]
//         ]
//       }
//     }
//   ]
// };
