import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, getPastDates, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  return params
    .map(
      ({ value, borderColor, name, seriesName }, index) =>
        `${
          index === 0 ? `<span class='text-800 fs--1'>${name}</span><br/>` : ''
        }` +
        `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>
              <span class='text-600 fs--1'>${
                seriesName === 'subs'
                  ? '실시간 변화량'
                  : seriesName === 'replus'
                  ? '압밀변화량 '
                  : '지표변화량'
              }: ${value.toLocaleString()}mm</span>`
    )
    .join('<br/>');
};

const getOptions = (data, series) => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: data.legendData,
    show: true,
    type: 'scroll',
    orient: 'horizontal',
    // orient: 'vertical',
    right: 10,
    top: 0,
    bottom: 20,
    formatter: function (name) {
      if (name === 'subs') {
        return '실시간 변화량';
      } else if (name === 'replus') {
        return '압밀변화량';
      } else {
        return '지표변화량';
      }
    }
  },
  xAxis: {
    type: 'category',
    data: data.date,
    boundaryGap: true,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => dayjs(value).format('YY-MM-DD\r\n(HH:mm)')
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    // min: function (value) {
    //   return value.min;
    // },
    // max: function (value) {
    //   return value.max;
    // },
    splitLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  dataZoom: [
    {
      show: true,
      type: 'inside',
      filterMode: 'none',
      xAxisIndex: [0],
      startValue: -9999,
      endValue: 100
    }
    // {
    //   show: true,
    //   type: 'inside',
    //   filterMode: 'none',
    //   yAxisIndex: [0],
    //   startValue: -20,
    //   endValue: 20
    // }
  ],
  series: series,
  grid: {
    right: '6%',
    left: '7%',
    bottom: '15%',
    top: '15%',
    containLabel: false
  }
});

const SolidationsSubsidencesChart = forwardRef(({ data }, ref) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!!data.sensorData) {
      makeSeries();
    }
  }, [data]);

  const makeSeries = () => {
    let series = [];
    data.sensorData.forEach((data, index) => {
      series.push({
        name: data.name,
        type: 'line',
        data: data.data,
        lineStyle: { color: getColor(data.color) },
        itemStyle: {
          borderColor: getColor(data.color),
          borderWidth: 2,
          backgroundColor: 'transparent'
        },
        symbol: 'circle',
        symbolSize: 10,
        emphasis: {
          scale: true
        }
      });
    });
    setSeries(series);
  };
  return (
    <ReactEChartsCore
      ref={ref}
      echarts={echarts}
      option={getOptions(data, series)}
    />
  );
});

SolidationsSubsidencesChart.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.array,
    legendData: PropTypes.array,
    sensorData: PropTypes.array
  })
};

export default SolidationsSubsidencesChart;
