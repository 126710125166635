import React, { useEffect, useState, useContext } from 'react';
import {
  Form,
  Tabs,
  Tab,
  Nav,
  FormControl,
  Button,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapsContext } from 'context/Context';

import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import FacilityItems from './FacilityItems';
import classNames from 'classnames';

const RoadFacilityContent = () => {
  // console.log('FilterInfo : ', facilitiesInfo);
  const { selectedProject, setSelectedProject } = useContext(MapsContext);
  const [orginFacilities, setOriginFacilities] = useState([]);
  const [viewFacilities, setViewFacilities] = useState([]);
  const [selectedTabValue, setSelectedTabValue] = useState('ALL');
  const [searchText, setSearchText] = useState('');

  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      if (!!selectedProject.facilities) {
        setOriginFacilities(selectedProject.facilities);
        setViewFacilities(selectedProject.facilities);
        setTotalElements(selectedProject.facilities.length);
      } else {
        setOriginFacilities([]);
        setViewFacilities([]);
        setTotalElements(0);
      }
    }
  }, [selectedProject]);

  const onTabChange = value => {
    setSelectedTabValue(value);
    if (value === 'ALL') {
      setViewFacilities(orginFacilities);
      setTotalElements(orginFacilities.length);
    }
    if (value === 'NO_ISSUE') {
      setViewFacilities(
        orginFacilities.filter(
          f => f.eventStatus.WIP === 0 && f.eventStatus.PRE === 0
        )
      );

      setTotalElements(
        orginFacilities.filter(
          f => f.eventStatus.WIP === 0 && f.eventStatus.PRE === 0
        ).length
      );
    }
    if (value === 'ON_ISSUE') {
      setViewFacilities(
        orginFacilities.filter(
          f => f.eventStatus.WIP > 0 || f.eventStatus.PRE > 0
        )
      );
      setTotalElements(
        orginFacilities.filter(
          f => f.eventStatus.WIP > 0 || f.eventStatus.PRE > 0
        ).length
      );
    }
    if (value === 'FAVORITE') {
      setViewFacilities(orginFacilities.filter(f => f.favorite));
      setTotalElements(orginFacilities.filter(f => f.favorite).length);
    }
  };

  return (
    <>
      <InputGroup className="position-relative">
        <FormControl
          size="sm"
          id="search"
          type="search"
          className="shadow-none"
          placeholder="검색어를 입력하세요."
          // onChange={e => handleTicketsSearch(e.target.value)}
        />
        <Button
          size="sm"
          variant="outline-secondary"
          className="border-300 hover-border-secondary"
        >
          <FontAwesomeIcon icon="search" className="fs--1" />
        </Button>
      </InputGroup>
      <Flex
        alignItems="center"
        alignContent={'center'}
        direction="row"
        justifyContent="between"
        className={'px-3'}
      >
        <Flex
          alignItems="end"
          alignContent={'end'}
          direction="column"
          justifyContent="start"
          className={'fs--1'}
        >
          총 {totalElements}개소
        </Flex>
        <Flex
          alignItems={'end'}
          alignContent={'end'}
          direction="column"
          justifyContent="end"
          className={'fs--1'}
        >
          <Form.Check
            type="switch"
            id="checkedSwitch"
            label="즐겨찾기"
            // defaultChecked
          />
        </Flex>
      </Flex>
      <Tabs
        defaultActiveKey="ALL"
        id="fill-project-tab"
        className={classNames('nav')}
        onSelect={onTabChange}
        fill
      >
        <Tab eventKey="ALL" title="전체"></Tab>
        <Tab eventKey="NO_ISSUE" title="진행중"></Tab>
        <Tab eventKey="ON_ISSUE" title="점검요함"></Tab>
        {/*<Tab eventKey="BEFORE" title="예정"></Tab>*/}
        {/*<Tab eventKey="FINISH" title="완료"></Tab>*/}
      </Tabs>
      <SimpleBarReact
        style={{ height: '100%', minWidth: '65px' }}
        className={'mt-0'}
      >
        <Nav className="border-0" activeKey="ALL">
          {viewFacilities.map((facilitie, index) => (
            <FacilityItems key={index} facilitie={facilitie} />
          ))}
        </Nav>
      </SimpleBarReact>
    </>
  );
};

export default RoadFacilityContent;
