export const gaspipeData = {
  type: 'FeatureCollection',
  name: 'gas pipe',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      id: '1',
      type: 'Feature',
      properties: {
        id: 1,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780199, 37.647601],
            [126.780213, 37.647592],
            [126.791247, 37.639239]
          ]
        ]
      }
    },
    {
      id: '2',
      type: 'Feature',
      properties: {
        id: 2,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.791269, 37.639258],
            [126.792034, 37.639905],
            [126.792098, 37.63996]
          ]
        ]
      }
    },
    {
      id: '3',
      type: 'Feature',
      properties: {
        id: 3,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.791269, 37.639258],
            [126.790024, 37.638223]
          ]
        ]
      }
    },
    {
      id: '4',
      type: 'Feature',
      properties: {
        id: 4,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790025, 37.638223],
            [126.788954, 37.637404],
            [126.788768, 37.637282]
          ]
        ]
      }
    },
    {
      id: '5',
      type: 'Feature',
      properties: {
        id: 5,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788768, 37.637282],
            [126.787834, 37.636658]
          ]
        ]
      }
    },
    {
      id: '6',
      type: 'Feature',
      properties: {
        id: 6,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787834, 37.636658],
            [126.776949, 37.644859],
            [126.776936, 37.644869]
          ]
        ]
      }
    },
    {
      id: '7',
      type: 'Feature',
      properties: {
        id: 7,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780461, 37.642213],
            [126.780466, 37.642216],
            [126.783762, 37.644941]
          ]
        ]
      }
    },
    {
      id: '8',
      type: 'Feature',
      properties: {
        id: 8,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783762, 37.644941],
            [126.784519, 37.645592],
            [126.784576, 37.645637]
          ]
        ]
      }
    },
    {
      id: '9',
      type: 'Feature',
      properties: {
        id: 9,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783954, 37.639583],
            [126.783953, 37.639582],
            [126.783959, 37.639587],
            [126.787217, 37.642327]
          ]
        ]
      }
    },
    {
      id: '10',
      type: 'Feature',
      properties: {
        id: 10,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787217, 37.642327],
            [126.787995, 37.642984],
            [126.788051, 37.643025]
          ]
        ]
      }
    },
    {
      id: '11',
      type: 'Feature',
      properties: {
        id: 11,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789481, 37.641942],
            [126.789424, 37.641896],
            [126.788648, 37.641243]
          ]
        ]
      }
    },
    {
      id: '12',
      type: 'Feature',
      properties: {
        id: 12,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788648, 37.641243],
            [126.785349, 37.638539],
            [126.785343, 37.638535]
          ]
        ]
      }
    },
    {
      id: '13',
      type: 'Feature',
      properties: {
        id: 13,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786674, 37.637532],
            [126.78668, 37.637537],
            [126.789936, 37.640268]
          ]
        ]
      }
    },
    {
      id: '14',
      type: 'Feature',
      properties: {
        id: 14,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789936, 37.640268],
            [126.790726, 37.640915],
            [126.790781, 37.640958]
          ]
        ]
      }
    },
    {
      id: '15',
      type: 'Feature',
      properties: {
        id: 15,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788989, 37.637431],
            [126.787707, 37.638398]
          ]
        ]
      }
    },
    {
      id: '16',
      type: 'Feature',
      properties: {
        id: 16,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788469, 37.637823],
            [126.790847, 37.639769]
          ]
        ]
      }
    },
    {
      id: '17',
      type: 'Feature',
      properties: {
        id: 17,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790847, 37.639769],
            [126.791523, 37.640366],
            [126.791541, 37.640382]
          ]
        ]
      }
    },
    {
      id: '18',
      type: 'Feature',
      properties: {
        id: 18,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.777996, 37.64407],
            [126.78125, 37.646799],
            [126.781254, 37.646803]
          ]
        ]
      }
    },
    {
      id: '21',
      type: 'Feature',
      properties: {
        id: 21,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778886, 37.644817],
            [126.777874, 37.645589],
            [126.777833, 37.64562]
          ]
        ]
      }
    },
    {
      id: '22',
      type: 'Feature',
      properties: {
        id: 22,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.77938, 37.645231],
            [126.778344, 37.645986],
            [126.778303, 37.646014]
          ]
        ]
      }
    },
    {
      id: '23',
      type: 'Feature',
      properties: {
        id: 23,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780017, 37.645765],
            [126.778994, 37.646534],
            [126.778959, 37.646563]
          ]
        ]
      }
    },
    {
      id: '24',
      type: 'Feature',
      properties: {
        id: 24,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782393, 37.64387],
            [126.781805, 37.644313]
          ]
        ]
      }
    },
    {
      id: '25',
      type: 'Feature',
      properties: {
        id: 25,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784425, 37.644403],
            [126.784444, 37.644419],
            [126.785215, 37.645104],
            [126.785274, 37.645155]
          ]
        ]
      }
    },
    {
      id: '26',
      type: 'Feature',
      properties: {
        id: 26,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784745, 37.644686],
            [126.784765, 37.64467],
            [126.78675, 37.643193]
          ]
        ]
      }
    },
    {
      id: '27',
      type: 'Feature',
      properties: {
        id: 27,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786413, 37.642898],
            [126.786435, 37.642917],
            [126.78668, 37.643129],
            [126.786688, 37.643136]
          ]
        ]
      }
    },
    {
      id: '28',
      type: 'Feature',
      properties: {
        id: 28,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786688, 37.643136],
            [126.787195, 37.643596],
            [126.787251, 37.643638]
          ]
        ]
      }
    },
    {
      id: '29',
      type: 'Feature',
      properties: {
        id: 29,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785435, 37.643639],
            [126.784588, 37.642923]
          ]
        ]
      }
    },
    {
      id: '30',
      type: 'Feature',
      properties: {
        id: 30,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782393, 37.64387],
            [126.782856, 37.64351]
          ]
        ]
      }
    },
    {
      id: '31',
      type: 'Feature',
      properties: {
        id: 31,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785436, 37.640829],
            [126.784858, 37.64124]
          ]
        ]
      }
    },
    {
      id: '32',
      type: 'Feature',
      properties: {
        id: 32,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786785, 37.641964],
            [126.786339, 37.642271]
          ]
        ]
      }
    },
    {
      id: '33',
      type: 'Feature',
      properties: {
        id: 33,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784347, 37.639914],
            [126.783938, 37.640194]
          ]
        ]
      }
    },
    {
      id: '34',
      type: 'Feature',
      properties: {
        id: 34,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 3
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781727, 37.64326],
            [126.78256, 37.642646]
          ]
        ]
      }
    },
    {
      id: '35',
      type: 'Feature',
      properties: {
        id: 35,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790175, 37.638348],
            [126.788815, 37.639328]
          ]
        ]
      }
    },
    {
      id: '38',
      type: 'Feature',
      properties: {
        id: 38,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787768, 37.641873],
            [126.788578, 37.64255],
            [126.788624, 37.642591]
          ]
        ]
      }
    },
    {
      id: '39',
      type: 'Feature',
      properties: {
        id: 39,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78928, 37.640728],
            [126.790108, 37.641411],
            [126.790143, 37.641441]
          ]
        ]
      }
    },
    {
      id: '40',
      type: 'Feature',
      properties: {
        id: 40,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782292, 37.640834],
            [126.783388, 37.64173]
          ]
        ]
      }
    },
    {
      id: '19',
      type: 'Feature',
      properties: {
        id: 19,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781964, 37.646266],
            [126.781594, 37.645959],
            [126.781326, 37.645737],
            [126.78116, 37.645602],
            [126.781028, 37.645481],
            [126.780931, 37.645404],
            [126.780785, 37.645272],
            [126.78062, 37.645138],
            [126.780452, 37.645],
            [126.780308, 37.644883],
            [126.78013, 37.644742],
            [126.779864, 37.64452],
            [126.779298, 37.644041],
            [126.778956, 37.643759],
            [126.778689, 37.643548]
          ]
        ]
      }
    },
    {
      id: '20',
      type: 'Feature',
      properties: {
        id: 20,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: null
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78257, 37.645807],
            [126.782562, 37.645799],
            [126.782082, 37.645388],
            [126.781723, 37.645094],
            [126.781439, 37.64485],
            [126.781091, 37.644563],
            [126.780711, 37.644249],
            [126.780285, 37.64389],
            [126.779849, 37.643536],
            [126.779422, 37.643177],
            [126.779305, 37.643084]
          ]
        ]
      }
    }
  ]
};
