import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AppContext, {
  ChatContext,
  LoginUserContext,
  SocketContext
} from 'context/Context';
import Picker from '@emoji-mart/react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { Cookies } from 'react-cookie';
import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import Flex from '../../Flex';
import ComposeAttachment from '../../../app/email/compose/ComposeAttachment';
import { chainAxios } from '../../../../helpers/chainAxios';
import { toast } from 'react-toastify';
const cookies = new Cookies();

const formatDate = date => {
  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };

  const now = date
    .toLocaleString('en-US', options)
    .split(',')
    .map(item => item.trim());

  return {
    day: now[0],
    hour: now[3],
    date: now[1] + ', ' + now[2]
  };
};

const MessageTextArea = ({ thread }) => {
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [message, setMessage] = useState('');

  // 저장된 파일정보
  const [attachments, setAttachments] = useState([]);

  const {
    config: { isDark }
  } = useContext(AppContext);

  const { loginUser } = useContext(LoginUserContext);
  const { stompChatClient } = useContext(SocketContext);

  const addEmoji = e => {
    let emoji = e.native;
    setMessage(message + emoji);
    setPreviewEmoji(false);
  };

  const handleAddAttachment = files => {
    if (files.length === 0) return;
    if (files.length > 6 || files.length + attachments.length > 6) {
      toast.error(`한 번에 파일은 최대 6개까지만 선택이 가능합니다.`, {
        theme: 'colored'
      });
      return;
    }
    // console.log('handleAddAttachment : ', files);
    const fileArray = [];
    const orginFileArray = [];

    Array.from(files).forEach(file => {
      orginFileArray.push(file);
      const { name, size, type } = file;

      const newFile = {
        id: name + 1 + Date.now(),
        name,
        size,
        type,
        files: file
      };
      fileArray.push(newFile);
    });
    setAttachments([...attachments, ...fileArray]);
  };

  const handleDetachAttachment = id => {
    setAttachments(attachments.filter(attachment => attachment.id !== id));
  };

  const sendFileChat = (formData, message) => {
    // http://112.175.18.232:7000/api/chatroom/bc43c237-8224-4f94-ac36-30a56e2680d0/chat?receiverId=dkchoi%40chainreum.com&message=%EC%82%AC%EC%A7%84%20%EC%8A%A4%EC%9B%A8%EA%B1%B0%20%EC%A0%84%EC%86%A1
    let sendMessage = '사진을 전송하였습니다.';
    if (message !== '') {
      sendMessage = message;
    }
    formData.append('message', sendMessage);

    let url = 'api/chatroom/' + thread.id + '/chat';
    chainAxios
      .post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
        console.log('MessageTextArea sendFileChat() : ', response);
        setAttachments([]);
      })
      .catch(error => {
        console.log('MessageTextArea sendFileChat() : ', error);
      });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (attachments.length > 0) {
      // forData 생성
      const formData = new FormData();
      Array.from(attachments).forEach(attachment => {
        formData.append('file', attachment.files);
      });
      sendFileChat(formData, message.replace(/(?:\r\n|\r|\n)/g, '<br>'));
    } else {
      if (stompChatClient.connected) {
        stompChatClient.send(
          '/app/chat',
          {},
          JSON.stringify({
            sender: { key: loginUser.userId },
            message: message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            chatroom: { key: thread.id }
          })
        );
      } else {
        stompChatClient.connect({}, frame => {
          stompChatClient.send(
            '/app/chat',
            {},
            JSON.stringify({
              sender: { key: loginUser.userId },
              message: message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
              chatroom: { key: thread.id }
            })
          );
        });
      }
    }
    setMessage('');
  };

  // useEffect(() => {
  //   if (isOpenThreadInfo) {
  //     setPreviewEmoji(false);
  //   }
  // }, [isOpenThreadInfo]);

  return (
    <>
      {attachments.length > 0 && (
        <div className="bg-light px-x1 py-3">
          <Flex direction="row" inline>
            {attachments.map(attachment => (
              <ComposeAttachment
                {...attachment}
                key={attachment.id}
                handleDetachAttachment={handleDetachAttachment}
              />
            ))}
          </Flex>
        </div>
      )}
      <Form className="chat-editor-area" onSubmit={handleSubmit}>
        <TextareaAutosize
          minRows={3}
          maxRows={6}
          value={message}
          placeholder="Type your message"
          onChange={({ target }) => setMessage(target.value)}
          className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
        />

        <Form.Group controlId="chatFileUpload">
          <Form.Label className="chat-file-upload cursor-pointer">
            <FontAwesomeIcon icon="paperclip" />
          </Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            multiple
            className="d-none"
            onChange={({ target }) => handleAddAttachment(target.files)}
          />
        </Form.Group>

        <Button
          variant="link"
          className="emoji-icon "
          onClick={() => setPreviewEmoji(!previewEmoji)}
        >
          <FontAwesomeIcon
            icon={['far', 'laugh-beam']}
            onClick={() => setPreviewEmoji(!previewEmoji)}
          />
        </Button>

        {previewEmoji && (
          <div className="chat-emoji-picker" dir="ltr">
            <Picker
              set="google"
              onEmojiSelect={addEmoji}
              theme={isDark ? 'dark' : 'light'}
              previewPosition="none"
              skinTonePosition="none"
            />
          </div>
        )}

        <Button
          variant="send"
          size="sm"
          disabled={message.length === 0}
          className={classNames('shadow-none', {
            'text-primary': message.length > 0
          })}
          type="submit"
        >
          Send
        </Button>
      </Form>
    </>
  );
};

MessageTextArea.propTypes = {
  thread: PropTypes.object
};

export default MessageTextArea;
