import { chainAxios } from 'helpers/chainAxios';

export const getChatrooms = async status => {
  let url =
    '/api/chatrooms?pageNo=0&pageSize=99999&sort=updateDate$desc&filters=status$' +
    status;
  console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chatroomList.dataList;
  }
  return [];
};
