import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import SolidationsSubsidencesChart from '../../../maps/left-area/detail/chart/sensor/SolidationsSubsidencesChart';
import FalconCardHeader from '../../../common/FalconCardHeader';
import { useSolidationsSubsidences } from '../../../maps/left-area/detail/hook/useMapsDetail';
import { isEmpty } from '../../../../helpers/utils';
import { useSensorSolidationsSubsidences } from '../hook/useManageSensor';

const SensorSolidationsSubsidences = ({
  sensor,
  searchStartDate,
  searchEndDate
}) => {
  const {
    data: solidationsSubsidencesDatas,
    sensorId,
    setSensorId,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  } = useSensorSolidationsSubsidences();

  const chartRef = useRef(null);
  const [solidationsSubsidencesChartData, setSolidationsSubsidencesChartData] =
    useState({});

  useEffect(() => {
    if (
      !isEmpty(sensor) &&
      !isEmpty(searchStartDate) &&
      !isEmpty(searchEndDate)
    ) {
      setSensorId(sensor.id);
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    }
  }, [sensor, searchStartDate, searchEndDate]);

  useEffect(() => {
    // console.log(sensorDistanceDatas);
    if (!isEmpty(solidationsSubsidencesDatas)) {
      makeSolidationsSubsidendcesChartData(solidationsSubsidencesDatas);
    }
  }, [solidationsSubsidencesDatas]);

  const makeSolidationsSubsidendcesChartData = dataList => {
    let dates = [];
    let subs = [];
    let replus = [];
    let reminus = [];

    dataList.forEach(data => {
      dates.push(data.x);
      subs.push(data.y1);
      replus.push(data.y2);
      reminus.push(data.y3);
    });

    let legendDatas = [];
    let sensorDatas = [];

    legendDatas.push('subs');
    legendDatas.push('replus');
    legendDatas.push('reminus');

    sensorDatas.push({
      color: 'success',
      name: 'subs',
      data: subs
    });

    sensorDatas.push({
      color: 'danger',
      name: 'replus',
      data: replus
    });

    sensorDatas.push({
      color: 'primary',
      name: 'reminus',
      data: reminus
    });

    setSolidationsSubsidencesChartData({
      date: dates,
      legendData: legendDatas,
      sensorData: sensorDatas
    });
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="센서 기간별 압밀·지표 침하/변화량"
        light
        titleTag="h6"
      />
      <Card.Body className="pe-xxl-0">
        <SolidationsSubsidencesChart
          data={solidationsSubsidencesChartData}
          ref={chartRef}
          style={{ height: '100%' }}
        />
      </Card.Body>
    </Card>
  );
};

export default SensorSolidationsSubsidences;
