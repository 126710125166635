import React, { useRef, useEffect, useState } from 'react';
import { Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import {
  getChartSeriesColor,
  getColor,
  getPastDates,
  rgbaColor
} from 'helpers/utils';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';
import moment from 'moment/moment';
import { chainAxios } from 'helpers/chainAxios';
import { getDates } from 'helpers/utils';
import { useFluctuations } from '../../hook/useMapsDetail';
import FalconCardHeader from '../../../../../common/FalconCardHeader';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);
const tooltipFormatter = params => {
  // console.log(params);
  return params
    .map(
      ({ value, name, seriesName, color }, index) =>
        `${index === 0 ? `<span class='text-800'>${name}</span><br/>` : ''}` +
        `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${color}"></div>
              <span class='text-600 fs--1'>${
                seriesName === 'y1'
                  ? '거리만 변동'
                  : seriesName === 'y2'
                  ? 'X 또는 Y축 변동'
                  : seriesName === 'y3'
                  ? '거리와 X 또는 Y축 변동'
                  : '모두 변동'
              }: ${value.toLocaleString()}회</span>`
    )
    .join('<br/>');
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-1100') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  },
  legend: {
    data: data.keys,
    show: true,
    type: 'scroll',
    orient: 'horizontal',
    // orient: 'vertical',
    right: 10,
    top: 0,
    bottom: 20,
    formatter: function (name) {
      if (name === 'y1') {
        return '거리만 변동';
      } else if (name === 'y2') {
        return 'X 또는 Y축 변동';
      } else if (name === 'y3') {
        return '거리와 X 또는 Y축 변동';
      }
    }
  },
  xAxis: {
    type: 'category',
    data: !!data.date ? data.date : [],
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'solid'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      formatter: value => dayjs(value).format('YY-MM-DD'),
      margin: 15
    },
    splitLine: {
      show: false
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisTick: { show: false },
    axisLine: { show: false },
    min: 0
  },
  dataZoom: [
    {
      show: true,
      type: 'inside',
      filterMode: 'none',
      xAxisIndex: [0],
      startValue: -20,
      endValue: 20
    }
    // {
    //   show: true,
    //   type: 'inside',
    //   filterMode: 'none',
    //   yAxisIndex: [0],
    //   startValue: -20,
    //   endValue: 20
    // }
  ],
  series: data.series,
  grid: {
    right: '6%',
    left: '7%',
    bottom: '15%',
    top: '15%',
    containLabel: false
  }
});

const FluctuationsChart = ({ selectedSensor }) => {
  const {
    data: fluctuationsDatas,
    setFluctuationsSensorId,
    setFluctuationsSearchPeriod
  } = useFluctuations();

  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('1개월');
  const [fluctuationChartData, setFluctuationChartData] = useState({});

  useEffect(() => {
    if (!!selectedSensor) {
      setFluctuationsSensorId(selectedSensor.id);
    }
  }, [selectedSensor]);

  useEffect(() => {
    makeFluctuationChartDatas(fluctuationsDatas);
  }, [fluctuationsDatas]);

  const makeFluctuationChartDatas = datas => {
    // console.log(datas);
    let chartDates = [];
    let y1 = []; // 거리만 변동
    let y2 = []; // X 또는 Y축 변동
    let y3 = []; // 거리와 X 또는 Y축 변동

    let sortDatas = datas.sort((a, b) => {
      if (a.x < b.x) return -1;
      if (a.x > b.x) return 1;

      return 0;
    });

    sortDatas.forEach(data => {
      chartDates.push(data.x);
      y1.push(data.y1);
      y2.push(data.y2);
      y3.push(data.y3);
    });

    let values = [];
    values.push(y1);
    values.push(y2);
    values.push(y3);

    let seriesColors = getChartSeriesColor();
    let series = [];

    ['y1', 'y2', 'y3'].forEach((key, index) => {
      series.push({
        name: key,
        type: 'bar',
        stack: 'total',
        data: values[index],
        lineStyle: {
          color: getColor(seriesColors[index % seriesColors.length])
        },
        itemStyle: {
          color: getColor(seriesColors[index % seriesColors.length]),
          borderRadius: [3, 3, 0, 0]
        },
        // showSymbol: false,
        // symbol: 'circle',
        // smooth: false,
        emphasis: {
          focus: 'series'
        }
      });
    });
    // console.log('series : ', series);
    setFluctuationChartData({
      date: chartDates,
      keys: ['y1', 'y2', 'y3'],
      series: series
    });
  };

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }

    setFluctuationsSearchPeriod(value);
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title="진동수"
        light
        titleTag="h6"
        endEl={
          <Dropdown onSelect={onSelectSearchPeriod}>
            <Dropdown.Toggle variant="falcon-default">
              {!!selectedSearchPeriod ? selectedSearchPeriod : '조회 개월 수'}
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item key="1" eventKey="1W">
                1주
              </Dropdown.Item>
              <Dropdown.Item key="2" eventKey="1M">
                1개월
              </Dropdown.Item>
              <Dropdown.Item key="3" eventKey="3M">
                3개월
              </Dropdown.Item>
              <Dropdown.Item key="4" eventKey="6M">
                6개월
              </Dropdown.Item>
              <Dropdown.Item key="5" eventKey="1Y">
                1년
              </Dropdown.Item>
              <Dropdown.Item key="6" eventKey="ALL">
                설치일부터
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
      <Card.Body className="pe-xxl-0">
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(fluctuationChartData)}
          style={{ height: '18.75rem' }}
        />
      </Card.Body>
    </Card>
  );
};

export default FluctuationsChart;
