import HalfDoughnutChart from 'components/dashboards/lms/monthly-targets/HalfDoughnutChart';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  Form,
  Row,
  Table,
  ProgressBar,
  Dropdown,
  Button
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import classNames from 'classnames';

import { chainAxios } from 'helpers/chainAxios';
import { useProjects } from '../hook/useDashboard';
import SoftBadge from '../../common/SoftBadge';
import AdvanceTable from '../../common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from '../../common/advance-table/AdvanceTableWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import IconButton from '../../common/IconButton';
import FalconCardHeader from '../../common/FalconCardHeader';
import CountUp from 'react-countup';
import PredictiveAnalysisChart from '../predictive-analysis/PredictiveAnalysisChart';
import IconItem from '../../common/icon/IconItem';
import CardDropdown from '../../common/CardDropdown';

const EventChart = () => {
  const columns = [
    {
      accessor: 'title',
      Header: '프로젝트',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { title } = rowData.row.original;
        return (
          <div className={'fs--1 text-truncate text-center'}>
            <Button
              className={'fs--1'}
              variant="link"
              onClick={() => moveEventManage()}
            >
              {title}
            </Button>
          </div>
        );
      }
    },
    {
      accessor: 'eventTotal',
      Header: '총 이벤트 수',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { eventTotal } = rowData.row.original;
        return (
          <div
            className={'fs--1 text-truncate text-center'}
            // style={{ width: '100px' }}
          >
            <Flex
              direction={'row'}
              alignItems={'center'}
              alignContent={'center'}
              className={'text-center text-800'}
              justifyContent={'center'}
            >
              <h5 className="mb-1 font-sans-serif">
                <CountUp
                  start={0}
                  end={eventTotal}
                  duration={2.75}
                  className="text-700 fs--0"
                  separator=","
                />
              </h5>
              <div className={'fs--1 fw-semi-bold'}>건</div>
            </Flex>
          </div>
        );
      }
    },
    {
      accessor: 'event',
      Header: '심각도현황',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { level0, level1, level2, level3, level4 } = rowData.row.original;
        return (
          <Flex
            direction={'row'}
            alignItems={'center'}
            alignContent={'center'}
            justifyContent={'around'}
          >
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'danger'} className="fs--2 mb-1">
                심각
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={level0}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'warning'} className="fs--2 mb-1">
                경고
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={level1}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'info'} className="fs--2 mb-1">
                주의
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={level2}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'success'} className="fs--2 mb-1">
                관찰
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={level3}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'secondary'} className="fs--2 mb-1">
                알림
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={level4}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
          </Flex>
        );
      }
    },
    {
      accessor: 'process',
      Header: '처리현황',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { eventApproved, eventDone, eventPre, eventWip } =
          rowData.row.original;
        return (
          <Flex
            direction={'row'}
            alignItems={'center'}
            alignContent={'center'}
            justifyContent={'around'}
          >
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'danger'} className="fs--2 mb-1">
                대기
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={eventPre}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'warning'} className="fs--2 mb-1">
                진행
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={eventWip}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'info'} className="fs--2 mb-1">
                완료
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={eventDone}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <SoftBadge pill bg={'success'} className="fs--2 mb-1">
                승인
              </SoftBadge>
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                className={'text-center text-800'}
                justifyContent={'center'}
              >
                <h5 className={'fs--1'}>
                  <CountUp
                    start={0}
                    end={eventApproved}
                    duration={2.75}
                    className="text-700"
                    separator=","
                  />
                </h5>
                <div className={'fs--2 fw-semi-bold'}>건</div>
              </Flex>
            </Flex>
          </Flex>
        );
      }
    },
    {
      accessor: 'percentage',
      Header: '조치율(%)',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { percentage } = rowData.row.original;
        return (
          <Flex
            direction={'column'}
            alignItems="center"
            justifyContent={'center'}
          >
            <div className="fw-semi-bold fs--1">
              <CountUp
                start={0}
                end={percentage}
                duration={2.75}
                className="text-700"
                separator=","
              />
              %
            </div>
            <ProgressBar
              now={percentage}
              style={{ width: '50px', height: 6 }}
            />
          </Flex>
        );
      }
    }
  ];

  const navigate = useNavigate();

  const { data: evnets, isLoading, isFetching } = useProjects();
  const [eventRates, setEventRates] = useState([]);
  const [eventTotal, setEventTotal] = useState(0);
  const [eventAlert, setEventAlert] = useState(0);
  const [eventApproved, setEventApproved] = useState(0);
  const [eventPre, setEventPre] = useState(0);
  const [eventWip, setEventWip] = useState(0);
  const [eventDone, setEventDone] = useState(0);

  const [eventLevel0, setEventLevel0] = useState(0);
  const [eventLevel1, setEventLevel1] = useState(0);
  const [eventLevel2, setEventLevel2] = useState(0);
  const [eventLevel3, setEventLevel3] = useState(0);
  const [eventLevel4, setEventLevel4] = useState(0);

  useEffect(() => {
    makeEventRateData(evnets);
  }, []);

  useEffect(() => {
    if (!isFetching) {
      makeEventRateData(evnets);
    } else {
      makeEventRateData([]);
    }
  }, [isFetching]);

  // (TOTAL:전체 이벤트, ALERT:경고, PRE:처리대기, WIP:처리중, DONE:처리완료)
  const makeEventRateData = projects => {
    let eventRates = [];

    let total = 0;
    let alert = 0;
    let approved = 0;
    let pre = 0;
    let wip = 0;
    let done = 0;

    let level0 = 0;
    let level1 = 0;
    let level2 = 0;
    let level3 = 0;
    let level4 = 0;

    projects.forEach(project => {
      total += parseInt(project.eventStatus.TOTAL);
      alert += parseInt(project.eventStatus.ALERT);
      approved += parseInt(project.eventStatus.APPROVED);
      pre += parseInt(project.eventStatus.PRE);
      wip += parseInt(project.eventStatus.WIP);
      done += parseInt(project.eventStatus.DONE);

      level0 += parseInt(project.eventStatus.LEVEL0);
      level1 += parseInt(project.eventStatus.LEVEL1);
      level2 += parseInt(project.eventStatus.LEVEL2);
      level3 += parseInt(project.eventStatus.LEVEL3);
      level4 += parseInt(project.eventStatus.LEVEL4);

      // console.log('makeEventRateData : ', project);
      eventRates.push({
        id: project.id,
        title: project.name,
        eventTotal: project.eventStatus.TOTAL,
        eventApproved: project.eventStatus.APPROVED,
        eventAlert: project.eventStatus.ALERT,
        eventDone: project.eventStatus.DONE,
        eventPre: project.eventStatus.PRE,
        eventWip: project.eventStatus.WIP,
        level0: project.eventStatus.LEVEL0,
        level1: project.eventStatus.LEVEL1,
        level2: project.eventStatus.LEVEL2,
        level3: project.eventStatus.LEVEL3,
        level4: project.eventStatus.LEVEL4,
        subTitle:
          '총: ' +
          project.eventStatus.TOTAL +
          ' 경고: ' +
          project.eventStatus.ALERT +
          ' 완료: ' +
          project.eventStatus.DONE +
          ' 진행: ' +
          parseInt(project.eventStatus.WIP + project.eventStatus.PRE),
        percentage:
          project.eventStatus.TOTAL === 0
            ? '100'
            : parseInt(
                ((project.eventStatus.APPROVED + project.eventStatus.DONE) /
                  project.eventStatus.TOTAL) *
                  100
              ).toString()
      });
    });

    eventRates.sort((a, b) => {
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
    });

    eventRates.sort((a, b) => {
      if (a.percentage > b.percentage) return -1;
      if (a.percentage < b.percentage) return 1;
    });

    setEventTotal(total);
    setEventAlert(alert);
    setEventApproved(approved);
    setEventPre(pre);
    setEventWip(wip);
    setEventDone(done);

    setEventLevel0(level0);
    setEventLevel1(level1);
    setEventLevel2(level2);
    setEventLevel3(level3);
    setEventLevel4(level4);

    setEventRates(eventRates);
  };

  const moveEventManage = () => {
    navigate('/event/manage');
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="이벤트 현황"
        light
        titleTag="h6"
        endEl={
          <IconButton
            variant="link"
            className="mb-1"
            icon="plus"
            transform="shrink-3"
            size="sm"
            onClick={() => moveEventManage()}
          >
            더보기
          </IconButton>
        }
      />
      <Card.Body className={'p-0'} style={{ minHeight: '370px' }}>
        <Row className="g-0 py-3">
          <Col xs={12} md={6} className={'border-end px-3 py-3'}>
            <Flex
              direction={'row'}
              justifyContent={'around'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                className="mb-3 position-static"
              >
                <IconItem
                  tag="div"
                  icon={'exclamation-triangle'}
                  bg={`soft-primary`}
                  color={'primary'}
                  size="sm"
                  iconClass="fs--1"
                  className="me-2 shadow-none"
                />
                <h6 className="mb-0 flex-1">{'심각도현황'}</h6>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'danger'} className="fs--1 mb-1">
                  심각
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventLevel0}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'warning'} className="fs--1 mb-1">
                  경고
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventLevel1}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'info'} className="fs--1 mb-1">
                  주의
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventLevel2}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'success'} className="fs--1 mb-1">
                  관찰
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventLevel3}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'secondary'} className="fs--1 mb-1">
                  알림
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventLevel4}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
            </Flex>
          </Col>
          <Col xs={12} md={6} className={'px-3 py-3'}>
            <Flex
              direction={'row'}
              justifyContent={'around'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                className="mb-3 position-static"
              >
                <IconItem
                  tag="div"
                  icon={'check'}
                  bg={`soft-primary`}
                  color={'primary'}
                  size="sm"
                  iconClass="fs--2"
                  className="me-2 shadow-none"
                />
                <h6 className="mb-0 flex-1">{'처리현황'}</h6>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'danger'} className="fs--1 mb-1">
                  대기
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventPre}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'warning'} className="fs--1 mb-1">
                  진행
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventWip}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'info'} className="fs--1 mb-1">
                  완료
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventDone}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <SoftBadge pill bg={'success'} className="fs--1 mb-1">
                  승인
                </SoftBadge>
                <Flex direction={'row'} alignItems={'center'}>
                  <h5 className="mb-1 font-sans-serif">
                    <CountUp
                      start={0}
                      end={eventApproved}
                      duration={2.75}
                      className="text-700 fs--0"
                      separator=","
                    />
                  </h5>
                  <div className={'fs--1 fw-semi-bold'}>건</div>
                </Flex>
              </Flex>
            </Flex>
          </Col>
          <Col xs={12}>
            <AdvanceTableWrapper
              columns={columns}
              data={eventRates}
              sortable
              pagination
              perPage={3}
            >
              <AdvanceTable
                table
                headerClassName="text-nowrap align-middle text-center"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  hover: true,
                  className: 'fs--1 mb-0 overflow-hidden text-center'
                }}
              />
            </AdvanceTableWrapper>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EventChart;
