import React, { forwardRef, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import { getColor, rgbaColor } from 'helpers/utils';
import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';

const AverageDailyReminusChart = forwardRef(({ data }, ref) => {
  const chartRef = useRef(null);

  const [series, setSeries] = useState([]);
  const [legends, setLegends] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (data.seriesDatas) {
      makeSeries(data.seriesDatas);
    }
  }, [data]);

  const makeSeries = () => {
    let makeSeries = [];
    data.seriesDatas.forEach((item, index) => {
      makeSeries.push({
        name: item.name,
        type: 'line',
        connectNulls: true,
        data: item.data,
        lineStyle: { color: getColor(item.color) },
        itemStyle: {
          borderColor: getColor(item.color),
          borderWidth: 2,
          backgroundColor: 'transparent'
        },
        symbol: 'circle',
        symbolSize: 5,
        showSymbol: true,
        emphasis: {
          scale: true
        },
        smooth: false,
        animation: false,
        animationDuration: 1000, // 애니메이션의 지속 시간 설정
        animationDurationUpdate: 1000, // 업데이트 시 애니메이션의 지속 시간 설정
        animationEasing: 'linear', // 애니메이션의 진행 방식 설정
        animationEasingUpdate: 'linear' // 업데이트 시 애니메이션의 진행 방식 설정
      });
    });

    setSeries(makeSeries);
    setLegends(data.legendData);
    setDates(data.date);
  };

  const tooltipFormatter = params => {
    // console.log(params);
    return `<span class='text-800 fs--1'>${params.name}</span><br/>
  <div class="dot me-1 fs--2 d-inline-block" style="background-color: ${
    params.borderColor
  }"></div>
        <span class='text-600 fs--1'>${params.seriesName} : ${
      params.value === undefined ? '-' : params.value
    }cm</span>`;
  };

  const getOptions = (legends, dates, series) => ({
    color: getColor('gray-100'),
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-100'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0
    },
    legend: {
      data: legends,
      show: true,
      type: 'plain',
      orient: 'horizontal', // 가로 방향으로 배치
      left: 'center', // 전체 범례의 가로 방향 가운데 정렬
      top: 'top', // 범례를 상단에 배치
      align: 'auto' // 여러 줄일 때 아이템을 중심에 정렬
      // 또는 'left' 또는 'right'로 설정해 범례 아이템을 좌측이나 우측 정렬 가능
      // padding: [0, 20], // 패딩이 적용된 경우 확인
      // itemGap: 10 // 아이템 간 간격 확인
    },
    xAxis: {
      type: 'category',
      data: dates,
      boundaryGap: true,
      axisPointer: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      splitLine: {
        show: true, // 보조선 표시
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        },
        alignWithLabel: true // 보조선을 레이블과 맞춤
      },
      axisLine: {
        lineStyle: {
          color: rgbaColor('#000', 0.01),
          type: 'dashed'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        margin: 15,
        formatter: value => dayjs(value).format('YYYY-MM-DD'),
        interval: 'auto', // 자동으로 레이블 간격 조정
        rotate: 45, // 레이블을 45도 회전
        fontSize: 10 // 글꼴 크기를 줄임
      }
    },
    yAxis: {
      type: 'value',
      name: '침하량(cm)', // Y축에 추가적인 문구를 추가
      nameLocation: 'middle', // 문구 위치 (start, middle, end)
      nameTextStyle: {
        color: getColor('gray-600'),
        fontWeight: 'regular',
        fontSize: 12,
        padding: [0, 0, 15, 0] // 이름의 위치를 조정하기 위한 패딩 설정
      },
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 5 // Y축 값 레이블의 간격을 조정하여 겹침 방지
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series: series,
    grid: {
      right: '2%',
      left: '7%',
      bottom: '20%',
      top: '20%',
      containLabel: false
    }
  });

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getOptions(legends, dates, series)}
      notMerge={true}
    />
  );
});

export default AverageDailyReminusChart;
