import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Col,
  Dropdown,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Tooltip
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectDetailTopArea from './component/TopArea';
import ProjectBaseInfo from './component/BaseInfo';
import ProjectEventList from './component/EventList';
import ProjectFacilitiesList from './component/FacilitiesList';
import ProjectSensorList from './component/SensorList';
import ProjectPhotoArea from './component/PhotoArea';
import { chainAxios } from 'helpers/chainAxios';
import AppContext, { MapsContext } from 'context/Context';
import classNames from 'classnames';
import SimpleBarReact from 'simplebar-react';

const ProjectDetail = () => {
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const { setSelectedProject } = useContext(MapsContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  const navigate = useNavigate();
  const params = useParams();

  const [project, setProject] = useState({});

  useEffect(() => {
    getProject();
  }, [params]);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);
    setSelectedProject({});

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  const getProject = () => {
    let url = '/api/project/' + params.projectId;
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ProjectDetail getProject() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(response.data.data.project);
          setProject(response.data.data.project);
        }
      })
      .catch(error => {
        console.log('ProjectDetail getProject() : ', error);
      });
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <ProjectDetailTopArea />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <ProjectBaseInfo project={project} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <ProjectEventList project={project} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xl={8} style={{ minHeight: '100%' }}>
          <Card className={classNames('overflow-hidden')}>
            <Tab.Container id="audience-tab" defaultActiveKey="facility">
              <SimpleBarReact>
                <Card.Header className="p-0 bg-light">
                  <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
                    <Nav.Item>
                      <Nav.Link className="mb-0 py-3 px-4" eventKey="facility">
                        <h6 className={'mb-0'}>프로젝트 도로·시설물 목록</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="mb-0 py-3 px-4" eventKey="sensor">
                        <h6 className={'mb-0'}>프로젝트 센서 목록</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBarReact>
              <Card.Body className={'pt-0'}>
                <Tab.Content>
                  <Tab.Pane unmountOnExit eventKey="facility">
                    <ProjectFacilitiesList project={project} />
                  </Tab.Pane>
                  <Tab.Pane unmountOnExit eventKey="sensor">
                    <ProjectSensorList project={project} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
        {/*<Col md={12} xl={4} style={{ minHeight: '100%' }}>*/}
        {/*  */}
        {/*  <ProjectFacilitiesList project={project} />*/}
        {/*</Col>*/}
        {/*<Col md={12} xl={4} style={{ minHeight: '100%' }}>*/}
        {/*  <ProjectSensorList project={project} />*/}
        {/*</Col>*/}
        <Col md={12} xl={4}>
          <ProjectPhotoArea project={project} />
        </Col>
      </Row>
    </>
  );
};

export default ProjectDetail;
