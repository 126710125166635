import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getPosition, tooltipFormatter } from 'helpers/echart-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import React from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = () => ({
  textStyle: {
    fontFamily: 'Pretendard',
    fontSize: 12
  },
  color: [
    rgbaColor(getColor('primary'), 0.7),
    rgbaColor(getColor('info'), 0.6),
    rgbaColor(getColor('secondary'), 0.2),
    rgbaColor(getColor('warning'), 0.6)
  ],
  legend: {
    data: ['정상', '주의', '관심', '관리', '점검'],
    left: 'center',
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    textStyle: { color: getColor('gray-700') },
    itemGap: 10,
    bottom: 'bottom'
  },
  yAxis: {
    type: 'category',
    data: ['공사현장', '시설물', '도로'],
    axisLine: {
      show: false
    },
    boundaryGap: false,
    splitLine: {
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: getColor('gray-600')
    }
  },
  xAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    }
  },
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    axisPointer: {
      type: 'none'
    },
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    position(pos, params, dom, rect, size) {
      return getPosition(pos, params, dom, rect, size);
    },
    formatter: tooltipFormatter
  },

  series: [
    {
      name: '정상',
      type: 'bar',
      stack: 'total',
      data: [1405, 1300, 1620, 1430, 1500, 1520],
      barWidth: '40%'
    },
    {
      name: '주의',
      type: 'bar',
      stack: 'total',
      data: [320, 302, 301, 334, 340, 390],
      barWidth: '40%'
    },
    {
      name: '관심',
      type: 'bar',
      stack: 'total',
      data: [220, 182, 351, 234, 290, 300],
      barWidth: '40%'
    },
    {
      name: '관리',
      type: 'bar',
      stack: 'total',
      data: [120, 182, 191, 134, 190, 170],
      barWidth: '40%'
    },
    {
      name: '점검',
      type: 'bar',
      stack: 'total',
      data: [100, 25, 123, 176, 129, 170],
      barWidth: '40%'
    }
  ],
  grid: {
    right: '8%',
    left: '5%',
    bottom: '40%',
    top: '7%',
    containLabel: true
  }
});

const PredictionSafetyChart = () => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions()}
      style={{ height: 'calc(100vh - 90vh)' }}
    />
  );
};

export default PredictionSafetyChart;
