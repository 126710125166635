import Map, {
  NavigationControl,
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
  Source,
  Layer,
  Marker,
  Popup,
  FlyToInterpolator
} from 'react-map-gl';

import { WebMercatorViewport } from 'viewport-mercator-project';

import mapMarker from 'assets/img/icons/map-marker.png';

import facilityMarker4 from 'assets/img/icons/facility-marker4.svg';
import facilityMarker3 from 'assets/img/icons/facility-marker3.svg';
import facilityMarker2 from 'assets/img/icons/facility-marker2.svg';
import facilityMarker1 from 'assets/img/icons/facility-marker1.svg';
import facilityMarker0 from 'assets/img/icons/facility-marker0.svg';

import boreHoleMarker from 'assets/img/icons/borehole-marker.png';

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import Flex from 'components/common/Flex';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { chainAxios } from 'helpers/chainAxios';
import { getColor, getSearchPeriodAmount, isEmpty } from 'helpers/utils';
import SoftBadge from 'components/common/SoftBadge';
import { fromLonLat } from 'ol/proj';
import {
  useMarkerBoreHoles,
  useMarkerFacilities,
  useMarkerProjects,
  useMarkerSensors
} from './hook/useCenterArea';
import moment from 'moment/moment';
import { MapsContext } from 'context/Context';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import ItemDetail from '../left-area/detail';
import MapboxItemDetail from '../left-area/detail/MapboxDetail';
import SensorDetail from '../left-area/sensor-detail';
import { debounce } from 'lodash';

import {
  cityData,
  districtData,
  subDistrictData,
  cityDistrictData
} from 'data/maps/goyang';
import PredictDetail from './predict-detail';

const MapboxIndex = () => {
  // const { data: projects } = useMarkerProjects();
  const { data: facilities } = useMarkerFacilities();
  const { data: sensors } = useMarkerSensors();
  const {
    data: boreHoles,
    setMinLat,
    setMaxLat,
    setMinLng,
    setMaxLng
  } = useMarkerBoreHoles();

  const mapContainerRef = useRef(null);

  const { selectedProject, setSelectedProject } = useContext(MapsContext);

  const [selectedMapStyle, setSelectedMapStyle] = useState('Streets');
  const [mapStyle, setMapStyle] = useState(
    'mapbox://styles/mapbox/streets-v12'
  );
  const [mapStyleName, setMapStyleName] = useState('streets-v12');

  const skyLayerStyle = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': 15
    }
  };
  const buildingLayerStyle = {
    id: '3d-buildings',
    source: 'composite',
    'source-layer': 'building',
    filter: ['==', 'extrude', 'true'],
    type: 'fill-extrusion',
    minzoom: 15,
    paint: {
      'fill-extrusion-color': '#aaa', // 건물 색상
      'fill-extrusion-height': ['get', 'height'], // 건물 높이
      'fill-extrusion-base': ['get', 'min_height'], // 건물의 최소 높이
      'fill-extrusion-opacity': 0.6 // 건물 투명도
    }
  };

  const [facilityPolygons, setFacilityPolygons] = useState({});
  const [isVisibleBuildings, setIsVisibleBuildings] = useState(true);
  const [isVisibleFacilityMarker, setIsVisibleFacilityMarker] = useState(false);
  const [isVisibleFacilityBoundary, setIsVisibleFacilityBoundary] =
    useState(false);
  const [isVisibleSenserMarker, setIsVisibleSensorMarker] = useState(false);
  const [isVisibleBoreHoleMarker, setIsVisibleBoreHoleMarker] = useState(false);

  const [detailShow, setDetailShow] = useState(false);
  const [sensorDetailShow, setSensorDetailShow] = useState(false);
  const [predictDetailShow, setPredictDetailShow] = useState(false);
  const [predictDetailFeature, setPredictDetailFeature] = useState({});

  const [hoveredFacilityMarker, setHoveredFacilityMarker] = useState(null);
  const [hoveredSensorMarker, setHoveredSensorMarker] = useState(null);
  const [hoveredBoreHoleMarker, setHoveredBoreHoleMarker] = useState(null);

  const [selectedFacilitiesId, setSelectedFacilitiesId] = useState(null);
  const [selectedSensorId, setSelectedSensorId] = useState(null);

  const [isVisibleCity, setIsVisibleCity] = useState(true);
  const [isVisibleDistrict, setIsVisibleDistrict] = useState(true);
  const [isVisibleSubDistrict, setIsVisibleSubDistrict] = useState(true);
  const [isVisiblePopup, setIsVisiblePopup] = useState(true);
  const [isVisibleHazardMap, setIsVisibleHazardMap] = useState(true);

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);

  const [popupInfo, setPopupInfo] = useState(null);
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null);

  useEffect(() => {
    if (!isEmpty(facilities)) {
      makeFacilityPolygons(facilities);

      if (mapContainerRef.current) {
        const bounds = mapContainerRef.current.getMap().getBounds();
        // setMinLat(bounds.getSouth());
        // setMaxLat(bounds.getNorth());
        // setMinLng(bounds.getWest());
        // setMaxLng(bounds.getEast());

        makeBoreHole(
          bounds.getSouth(),
          bounds.getNorth(),
          bounds.getWest(),
          bounds.getEast()
        );
      }
    }
  }, [facilities]);

  useEffect(() => {
    if (!isEmpty(selectedProject)) {
      mapContainerRef.current?.flyTo({
        center: [
          selectedProject.location.longitude,
          selectedProject.location.latitude
        ],
        zoom: 18,
        duration: 3000,
        pitch: 45,
        bearing: 0
      });

      setIsVisibleCity(false);
      setIsVisibleDistrict(false);
      setIsVisibleSubDistrict(false);

      setIsVisibleFacilityMarker(true);
      setIsVisibleFacilityBoundary(true);
      setIsVisibleSensorMarker(true);
      setIsVisibleBoreHoleMarker(true);
    }
  }, [selectedProject]);

  const makeBoreHole = useCallback(
    debounce(() => {
      // 현재 화면에 표시되는 지도의 최대, 최소 위도 경도 계산
      if (mapContainerRef.current) {
        const bounds = mapContainerRef.current.getMap().getBounds();
        // console.log('Min Longitude:', bounds.getWest());
        // console.log('Max Longitude:', bounds.getEast());
        // console.log('Min Latitude:', bounds.getSouth());
        // console.log('Max Latitude:', bounds.getNorth());

        setMinLng(bounds.getWest());
        setMaxLng(bounds.getEast());
        setMinLat(bounds.getSouth());
        setMaxLat(bounds.getNorth());
      }
    }, 500),
    []
  );

  const makeFacilityPolygons = facilities => {
    let polygons = [];

    facilities.forEach((facility, index) => {
      if (facility.boundary === null) {
        return;
      }
      // console.log('MakeFacilitiesBoundary : ', facilitie.boundary);
      let lineCoordinates = [];

      facility.boundary.forEach(b => {
        lineCoordinates.push([b.longitude, b.latitude]);
      });

      polygons.push({
        id: facility.id,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [lineCoordinates]
        }
      });
    });

    // console.log({ type: 'FeatureCollection', features: polygons });
    setFacilityPolygons({ type: 'FeatureCollection', features: polygons });
  };

  // 지도 스타일 변경 함수
  const handleFacilityMarkerHover = facilityId => {
    const facilityMarker = facilities.find(m => m.id === facilityId);
    setHoveredFacilityMarker(facilityMarker);
  };

  const handleFacilityMarkerLeave = () => {
    setHoveredFacilityMarker(null);
  };

  const handleSensorMarkerHover = sensorId => {
    const sensorMarker = sensors.find(m => m.id === sensorId);
    setHoveredSensorMarker(sensorMarker);
  };

  const handleSensorMarkerLeave = () => {
    setHoveredSensorMarker(null);
  };

  const handleBoreHoleMarkerHover = boreHoleId => {
    const boreHoleMarker = boreHoles.find(m => m.id === boreHoleId);
    setHoveredBoreHoleMarker(boreHoleMarker);
  };

  const handleBoreHoleMarkerLeave = () => {
    setHoveredBoreHoleMarker(null);
  };

  const handleZoom = useCallback(
    debounce(e => {
      setIsVisibleFacilityMarker(e.viewState.zoom >= 12.5);

      if (e.viewState.zoom >= 16.5) {
        setIsVisibleCity(false);
        setIsVisibleDistrict(false);
        setIsVisibleSubDistrict(false);

        setIsVisibleFacilityBoundary(e.viewState.zoom >= 18);
        setIsVisibleSensorMarker(e.viewState.zoom >= 18);
        setIsVisibleBoreHoleMarker(e.viewState.zoom >= 18);
      } else {
        setIsVisibleCity(e.viewState.zoom <= 14);
        setIsVisibleDistrict(e.viewState.zoom <= 14);
        setIsVisibleSubDistrict(e.viewState.zoom <= 14);

        setIsVisibleFacilityBoundary(false);
        setIsVisibleSensorMarker(false);
        setIsVisibleBoreHoleMarker(false);
      }
    }, 300),
    []
  );
  const onSelectMapStyle = value => {
    if (value === 'streets-v12') {
      setSelectedMapStyle('Streets');
    }
    if (value === 'outdoors-v12') {
      setSelectedMapStyle('Outdoors');
    }
    if (value === 'light-v11') {
      setSelectedMapStyle('Light');
    }
    if (value === 'dark-v11') {
      setSelectedMapStyle('Dark');
    }
    if (value === 'satellite-streets-v12') {
      setSelectedMapStyle('Satellite Streets');
    }
    if (value === 'navigation-day-v1') {
      setSelectedMapStyle('Navigation Day');
    }
    if (value === 'navigation-night-v1') {
      setSelectedMapStyle('Navigation Night');
    }
    if (value === 'standard') {
      setSelectedMapStyle('Standard');
    }

    setMapStyleName(value);
    setMapStyle(`mapbox://styles/mapbox/${value}`);
  };

  const onRender = e => {
    // https://github.com/mapbox/mapbox-gl-language/?tab=readme-ov-file#setlanguage
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));
  };

  const handleMove = evt => {
    setViewport({
      latitude: evt.viewState.latitude,
      longitude: evt.viewState.longitude,
      zoom: evt.viewState.zoom,
      pitch: evt.viewState.pitch,
      bearing: evt.viewState.bearing
    });

    makeBoreHole();
  };

  const onClickFacilityMarker = facilityId => {
    setSelectedFacilitiesId(facilityId);
    setDetailShow(true);
  };

  const onClickSenserMarker = sensorId => {
    setSelectedSensorId(sensorId);
    setSensorDetailShow(true);
  };

  const detailModalShowEvent = () => {
    setDetailShow(!detailShow);
    // setMiniMapSelectedSensor('');
  };

  const sensorDetailModalShowEvent = () => {
    setSensorDetailShow(!sensorDetailShow);
    // setMiniMapSelectedSensor('');
  };

  const predictDetailModalShowEvent = () => {
    setPredictDetailShow(!predictDetailShow);
  };

  const ShowBuildings = () => {
    setIsVisibleBuildings(!isVisibleBuildings);
  };

  const ShowFacilitiesMarker = () => {
    setIsVisibleFacilityMarker(!isVisibleFacilityMarker);
  };

  const ShowSensorMarker = () => {
    setIsVisibleSensorMarker(!isVisibleSenserMarker);
  };

  const ShowFacilitiesBoundary = () => {
    setIsVisibleFacilityBoundary(!isVisibleFacilityBoundary);
  };

  const ShowBoreHoles = () => {
    setIsVisibleBoreHoleMarker(!isVisibleBoreHoleMarker);
  };

  const ShowCityDistricts = () => {
    setIsVisibleCity(!isVisibleCity);
    setIsVisibleDistrict(!isVisibleDistrict);
    setIsVisibleSubDistrict(!isVisibleSubDistrict);
  };

  const ShowHazardMap = () => {
    setIsVisibleHazardMap(!isVisibleHazardMap);
  };

  const clearSelectedLayer = () => {
    const map = mapContainerRef.current.getMap();

    setPopupInfo(null);

    if (isSourceExist('cities')) {
      cityData.features.map(d => {
        map.setFeatureState(
          { source: 'cities', id: d.properties.SIDO_CD },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('districts')) {
      districtData.features.map(d => {
        map.setFeatureState(
          { source: 'districts', id: d.properties.SIGUNGU_CD },
          { hover: false, selected: false }
        );
      });
    }

    if (isSourceExist('sub-districts')) {
      subDistrictData.features.map(d => {
        map.setFeatureState(
          { source: 'sub-districts', id: d.properties.ADM_CD },
          { hover: false, selected: false }
        );
      });
    }
  };

  // 새로운 함수: 특정 소스가 존재하는지 확인
  const isSourceExist = sourceId => {
    const map = mapContainerRef.current.getMap();
    return map.getSource(sourceId) !== undefined;
  };

  const handleMapClick = event => {
    const features = event.features;
    // const map = mapContainerRef.current.getMap();

    if (features && features.length > 0) {
      const feature = features[0];

      clearSelectedLayer();

      if (feature.layer.id === 'city-layer') {
        setSelectedSubDistrict(null);
        setSelectedCity(feature);
        const cityDistricts = districtData.features.filter(
          d =>
            d.properties.SIGUNGU_CD.substring(0, 2) ===
            feature.properties.SIDO_CD
        );
        setDistricts(cityDistricts);
        setSelectedDistrict(null);
        setSubDistricts([]);

        const { lng, lat } = event.lngLat; // 클릭한 위치의 좌표

        // setViewport({
        //   ...viewport,
        //   latitude: lat,
        //   longitude: lng,
        //   zoom: 10, // 확대할 줌 레벨
        //   duration: 10000, // 애니메이션 지속 시간 (밀리초)
        //   pitch: 30, // 카메라 각도 (기울기)
        //   bearing: 0 // 카메라 방향
        // });

        mapContainerRef.current.flyTo({
          center: [lng, lat],
          zoom: 12,
          duration: 1500,
          pitch: 30,
          bearing: 0
        });
      }
      if (feature.layer.id === 'district-layer') {
        setSelectedSubDistrict(null);
        setSelectedDistrict(feature);

        const districtSubDistricts = subDistrictData.features.filter(
          d =>
            d.properties.ADM_CD.substring(0, 5) ===
            feature.properties.SIGUNGU_CD
        );
        setSubDistricts(districtSubDistricts);

        const { lng, lat } = event.lngLat; // 클릭한 위치의 좌표

        mapContainerRef.current.flyTo({
          center: [lng, lat],
          zoom: 12.5,
          duration: 1500,
          pitch: 45,
          bearing: 0
        });
      }

      if (feature.layer.id === 'sub-district-layer') {
        setSelectedSubDistrict(feature);

        const { lng, lat } = event.lngLat; // 클릭한 위치의 좌표

        mapContainerRef.current.flyTo({
          center: [lng, lat],
          zoom: 14.5,
          duration: 1500,
          pitch: 45,
          bearing: 0
        });
      }
    } else {
      if (!isVisibleHazardMap || detailShow || sensorDetailShow) {
        return;
      }
      const features = mapContainerRef.current.queryRenderedFeatures(
        event.point,
        {
          layers: ['city-district-layer']
        }
      );

      if (features.length) {
        const feature = features[0];
        // console.log(feature);

        setPredictDetailFeature(feature);
        setPredictDetailShow(true);
      }
    }
  };

  const handleLayerHover = useCallback(
    debounce(event => {
      const map = mapContainerRef.current.getMap();
      const overLayers = map.queryRenderedFeatures(event.point);

      if (overLayers.length > 0) {
        clearSelectedLayer();

        const firstLayer = overLayers[0];

        if (firstLayer.layer.id === 'city-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['city-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // setPopupInfo({
            //   longitude: event.lngLat.lng,
            //   latitude: event.lngLat.lat,
            //   name: hoveredFeature.properties.SIDO_NM
            // });

            // Set feature state to change color
            map.setFeatureState(
              {
                source: 'cities',
                id: hoveredFeature.properties.SIDO_CD
              },
              { hover: true }
            );
            setHoveredFeatureId(hoveredFeature.properties.SIDO_CD);
          }
        }

        if (firstLayer.layer.id === 'district-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['district-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // setPopupInfo({
            //   longitude: event.lngLat.lng,
            //   latitude: event.lngLat.lat,
            //   name: hoveredFeature.properties.SIGUNGU_NM
            // });

            // Set feature state to change color
            map.setFeatureState(
              { source: 'districts', id: hoveredFeature.properties.SIGUNGU_CD },
              { hover: true }
            );
            setHoveredFeatureId(hoveredFeature.properties.SIGUNGU_CD);
          }
        }

        if (firstLayer.layer.id === 'sub-district-layer') {
          const features = map.queryRenderedFeatures(event.point, {
            layers: ['sub-district-layer']
          });

          if (features.length > 0) {
            const hoveredFeature = features[0];
            // setPopupInfo({
            //   longitude: event.lngLat.lng,
            //   latitude: event.lngLat.lat,
            //   name: hoveredFeature.properties.ADM_NM
            // });

            // Set feature state to change color
            map.setFeatureState(
              {
                source: 'sub-districts',
                id: hoveredFeature.properties.ADM_CD
              },
              { hover: true }
            );
            setHoveredFeatureId(hoveredFeature.properties.ADM_CD);
          }
        }
      }
    }, 0),
    []
  );

  const handleLayerLeave = event => {
    clearSelectedLayer();

    // const map = mapContainerRef.current.getMap();
    //
    // if (hoveredFeatureId !== null) {
    //   // Reset feature state when mouse leaves
    //   map.setFeatureState(
    //     { source: 'cities', id: hoveredFeatureId },
    //     { hover: false }
    //   );
    // }
    setHoveredFeatureId(null);
    // setPopupInfo(null);
  };

  const [viewport, setViewport] = useState({
    width: '100vw',
    height: '100vh',
    latitude: 37.66461,
    longitude: 126.83741,
    zoom: 11,
    pitch: 10, // 카메라 각도 (기울기)
    bearing: 0 // 카메라 방향
  });

  return (
    <>
      <Map
        onRender={onRender}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        style={{
          borderRadius: '5px',
          position: 'relative',
          width: '100%',
          height: '94vh',
          minHeight: '650px'
        }}
        mapStyle={mapStyle}
        ref={mapContainerRef}
        // terrain={{ source: 'mapbox-dem', exaggeration: 1 }}
        onZoom={e => {
          handleZoom(e);
        }}
        onClick={handleMapClick}
        interactiveLayerIds={[
          'city-layer',
          'district-layer',
          'sub-district-layer'
        ]} // 클릭 가능한 레이어 ID 목록 추가
        // initialViewState={viewport}
        {...viewport}
        onViewportChange={nextViewport => setViewport(nextViewport)}
        onMove={handleMove}
        onMouseMove={handleLayerHover}
        onMouseLeave={handleLayerLeave}
        dragPan={true}
        dragRotate={true}
        scrollZoom={true}
        touchZoom={true}
        doubleClickZoom={true}
        touchRotate={true}
      >
        <GeolocateControl position="top-right" />
        <FullscreenControl position="top-right" />
        <NavigationControl position="top-right" />
        <ScaleControl />
        {/*<AttributionControl />*/}
        <div
          style={{
            position: 'relative',
            top: '16px',
            left: '16px',
            right: '16px',
            zIndex: '1'
          }}
        >
          <Flex direction={'row'} justifyContent={'start'} className={'pb-2'}>
            <Dropdown onSelect={onSelectMapStyle} className={'me-3'}>
              <Dropdown.Toggle variant="falcon-default" size="sm">
                {!!selectedMapStyle ? selectedMapStyle : 'Map Style'}
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item key="1" eventKey="streets-v12">
                  Streets
                </Dropdown.Item>
                <Dropdown.Item key="2" eventKey="outdoors-v12">
                  Outdoors
                </Dropdown.Item>
                <Dropdown.Item key="3" eventKey="light-v11">
                  Light
                </Dropdown.Item>
                <Dropdown.Item key="4" eventKey="dark-v11">
                  Dark
                </Dropdown.Item>
                <Dropdown.Item key="5" eventKey="satellite-streets-v12">
                  Satellite Streets
                </Dropdown.Item>
                <Dropdown.Item key="6" eventKey="navigation-day-v1">
                  Navigation Day
                </Dropdown.Item>
                <Dropdown.Item key="6" eventKey="navigation-night-v1">
                  Navigation Night
                </Dropdown.Item>
                {/*<Dropdown.Divider />*/}
                {/*<Dropdown.Item key="7" eventKey="standard">*/}
                {/*  Standard*/}
                {/*</Dropdown.Item>*/}
              </Dropdown.Menu>
            </Dropdown>
          </Flex>
          <Flex direction={'row'} justifyContent={'start'} className={'pb-1'}>
            <Flex direction={'column'} className={'px-1'}>
              <Button onClick={() => ShowBuildings()}>3D건물 ON/OFF</Button>
            </Flex>
            <Flex direction={'column'} className={'px-1'}>
              <Button onClick={() => ShowFacilitiesMarker()}>
                시설물 ON/OFF
              </Button>
            </Flex>
            <Flex direction={'column'} className={'px-1'}>
              <Button onClick={() => ShowSensorMarker()}>센서 ON/OFF</Button>
            </Flex>
            <Flex direction={'column'} className={'px-1'}>
              <Button onClick={() => ShowFacilitiesBoundary()}>
                영역 ON/OFF
              </Button>
            </Flex>
            <Flex direction={'column'} className={'px-1'}>
              <Button onClick={() => ShowBoreHoles()}>시추공 ON/OFF</Button>
            </Flex>
          </Flex>
          <Flex direction={'row'} justifyContent={'start'} className={'pb-1'}>
            <Flex direction={'column'} className={'px-1'}>
              <Button onClick={() => ShowCityDistricts()}>
                지도영역 ON/OFF
              </Button>
            </Flex>
            <Flex direction={'column'} className={'px-1'}>
              <Button onClick={() => ShowHazardMap()}>예측지도 ON/OFF</Button>
            </Flex>
          </Flex>
        </div>
        {isVisiblePopup && popupInfo && (
          <Popup
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            closeButton={false}
            closeOnClick={false}
            anchor="top"
          >
            <div>{popupInfo.name}</div>
          </Popup>
        )}
        {isVisibleHazardMap && (
          <Source
            id="cities-district"
            type="geojson"
            // data={cityData}
            data={{
              type: 'FeatureCollection',
              features: cityDistrictData.features
            }}
          >
            <Layer
              id="city-district-layer"
              beforeId={'building'}
              type="fill"
              paint={{
                'fill-color': [
                  'step',
                  ['get', 'ITEMT'],
                  getColor('success'), // 기본값 (0 ~ 6 녹색)
                  7,
                  '#FFFF00', // ITEMT가 7일 때 노란색
                  8,
                  getColor('warning'), // ITEMT가 8일 때 주황색
                  9,
                  getColor('danger') // ITEMT가 9 이상일 때 빨간색
                ],
                'fill-opacity': 0.9
              }}
            />
            <Layer
              id="city-district-line-layer"
              beforeId={'building'}
              type="line"
              paint={{
                'line-color': '#ffffff',
                'line-width': 2
              }}
            />
            {/*<Layer*/}
            {/*  id="city-district-label-layer"*/}
            {/*  type="symbol"*/}
            {/*  layout={{*/}
            {/*    'text-field': ['get', 'MAPIDCD_NO'],*/}
            {/*    'text-offset': [0, 1.5],*/}
            {/*    'text-anchor': 'top'*/}
            {/*  }}*/}
            {/*  paint={{*/}
            {/*    'text-color': '#007cbf'*/}
            {/*  }}*/}
            {/*/>*/}
          </Source>
        )}

        {isVisibleCity && (
          <Source
            id="cities"
            type="geojson"
            // data={cityData}
            data={{ type: 'FeatureCollection', features: cityData.features }}
          >
            <Layer
              id="city-layer"
              type="fill"
              paint={{
                'fill-color': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  '#888', // Hover color
                  '#888' // Default color
                ],
                'fill-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  0.6, // Hover opacity
                  0.2 // Default opacity
                ]
              }}
            />
            <Layer
              id="city-layer-line"
              type="line"
              paint={{
                'line-color': '#ffffff',
                'line-width': 3
              }}
            />
            <Layer
              id="city-layer-labels"
              type="symbol"
              layout={{
                'symbol-placement': 'point', // Place symbols at point locations
                'text-allow-overlap': false, // Prevent text overlap
                'text-field': ['get', 'SIDO_NM'], // Assuming 'name' property contains the city name
                'text-size': 18, // Font size
                'text-anchor': 'center', // Anchor the text in the center
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'] // Font stack
              }}
              paint={{
                'text-color': '#ffffff' // Text color
                // 'text-opacity': [
                //   'case',
                //   ['boolean', ['feature-state', 'hover'], false],
                //   1, // Show text when hover
                //   0 // Hide text when not hover
                // ]
              }}
              minzoom={10} // Set the minimum zoom level at which the labels appear
              maxzoom={12} // Set the maximum zoom level at which the labels appear
            />
          </Source>
        )}

        {isVisibleDistrict && districts.length > 0 && (
          <Source
            id="districts"
            type="geojson"
            data={{ type: 'FeatureCollection', features: districts }}
          >
            <Layer
              id="district-layer"
              type="fill"
              paint={{
                'fill-color': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  '#888', // Hover color
                  '#888' // Default color
                ],
                'fill-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  0.6, // Hover opacity
                  0.2 // Default opacity
                ]
              }}
              //onClick={handleDistrictClick}
            />
            <Layer
              id="district-layer-line"
              type="line"
              paint={{
                'line-color': '#ffffff',
                'line-width': 3
              }}
              //onClick={handleDistrictClick}
            />
            <Layer
              id="district-layer-labels"
              type="symbol"
              layout={{
                'symbol-placement': 'point', // Place symbols at point locations
                'text-allow-overlap': false, // Prevent text overlap
                'text-field': ['get', 'SIGUNGU_NM'], // Assuming 'name' property contains the city name
                'text-size': 18, // Font size
                'text-anchor': 'center', // Anchor the text in the center
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'] // Font stack
              }}
              paint={{
                'text-color': '#ffffff', // Text color
                'text-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  1, // Show text when hover
                  0 // Hide text when not hover
                ]
              }}
              minzoom={10} // Set the minimum zoom level at which the labels appear
              maxzoom={15} // Set the maximum zoom level at which the labels appear
            />
          </Source>
        )}

        {!isEmpty(selectedSubDistrict) && (
          <Source
            id="selected-sub-districts"
            type="geojson"
            data={selectedSubDistrict}
          >
            <Layer
              id="selected-sub-district-layer-line"
              type="line"
              paint={{
                'line-color': getColor('primary'),
                'line-width': 5
              }}
              //onClick={handleDistrictClick}
            />
            <Layer
              id="selected-sub-district-layer-labels"
              type="symbol"
              layout={{
                'symbol-placement': 'point', // Place symbols at point locations
                'text-allow-overlap': false, // Prevent text overlap
                'text-field': ['get', 'ADM_NM'], // Assuming 'name' property contains the city name
                'text-size': 18, // Font size
                'text-anchor': 'center', // Anchor the text in the center
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'] // Font stack
              }}
              paint={{
                'text-color': '#ffffff' // Text color
              }}
            />
          </Source>
        )}

        {isVisibleSubDistrict && subDistricts.length > 0 && (
          <Source
            id="sub-districts"
            type="geojson"
            data={{ type: 'FeatureCollection', features: subDistricts }}
          >
            <Layer
              id="sub-district-layer"
              type="fill"
              paint={{
                'fill-color': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  '#888', // Hover color
                  '#888' // Default color
                ],
                'fill-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  0.6, // Hover opacity
                  0.2 // Default opacity
                ]
              }}
            />
            <Layer
              id="sub-district-layer-line"
              type="line"
              paint={{
                'line-color': '#ffffff',
                'line-width': 3
              }}
              //onClick={handleDistrictClick}
            />
            <Layer
              id="sub-district-layer-labels"
              type="symbol"
              layout={{
                'symbol-placement': 'point', // Place symbols at point locations
                'text-allow-overlap': false, // Prevent text overlap
                'text-field': ['get', 'ADM_NM'], // Assuming 'name' property contains the city name
                'text-size': 18, // Font size
                'text-anchor': 'center', // Anchor the text in the center
                'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'] // Font stack
              }}
              paint={{
                'text-color': '#ffffff' // Text color
              }}
            />
          </Source>
        )}

        {isVisibleBoreHoleMarker &&
          !isEmpty(boreHoles) &&
          boreHoles.map((boreHole, index) => (
            <Marker
              key={boreHole.id}
              longitude={boreHole.longitude}
              latitude={boreHole.latitude}
              anchor="center"
            >
              <div
                onMouseEnter={() => handleBoreHoleMarkerHover(boreHole.id)}
                onMouseLeave={handleBoreHoleMarkerLeave}
                // onClick={() => onClickBoreHoleMarker(boreHole.id)}
              >
                <img
                  src={boreHoleMarker}
                  style={{
                    width: '45px',
                    height: '45px'
                  }}
                  alt={boreHole.boreHoleNo}
                />
              </div>
            </Marker>
          ))}
        {hoveredBoreHoleMarker && (
          <Popup
            longitude={hoveredBoreHoleMarker.longitude}
            latitude={hoveredBoreHoleMarker.latitude}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredBoreHoleMarker.boreholeNo}
                </h6>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                {hoveredBoreHoleMarker.exploration.value}
              </h6>
            </Flex>
          </Popup>
        )}
        {isVisibleSenserMarker &&
          !isEmpty(sensors) &&
          sensors.map((sensor, index) => (
            <Marker
              key={sensor.id}
              longitude={sensor.location.longitude}
              latitude={sensor.location.latitude}
              anchor="center"
            >
              <div
                onMouseEnter={() => handleSensorMarkerHover(sensor.id)}
                onMouseLeave={handleSensorMarkerLeave}
                onClick={() => onClickSenserMarker(sensor.id)}
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  backgroundColor:
                    sensor.status === 'ON'
                      ? getColor('primary')
                      : sensor.status === 'OFF'
                      ? getColor('warning')
                      : getColor('danger'),
                  opacity: 0.5,
                  cursor: 'pointer'
                }}
              />
            </Marker>
          ))}
        {isVisibleFacilityMarker &&
          !isEmpty(facilities) &&
          facilities.map((facility, index) => (
            <>
              <Marker
                key={facility.id}
                longitude={facility.location.longitude}
                latitude={facility.location.latitude}
                anchor="center"
              >
                <div
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => handleFacilityMarkerHover(facility.id)}
                  onMouseLeave={handleFacilityMarkerLeave}
                  onClick={() => onClickFacilityMarker(facility.id)}
                >
                  <img
                    src={
                      facility.status === '4'
                        ? facilityMarker4
                        : facility.status === '3'
                        ? facilityMarker3
                        : facility.status === '2'
                        ? facilityMarker2
                        : facility.status === '1'
                        ? facilityMarker1
                        : facilityMarker0
                    }
                    style={{
                      width: '45px',
                      height: '45px'
                    }}
                    alt={facility.name}
                  />
                </div>
              </Marker>
            </>
          ))}
        {hoveredFacilityMarker && (
          <Popup
            longitude={hoveredFacilityMarker.location.longitude}
            latitude={hoveredFacilityMarker.location.latitude}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'column'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              <Flex
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <h6 className={'text-700 mt-1'}>
                  {hoveredFacilityMarker.name}
                </h6>
                <SoftBadge
                  pill
                  bg={
                    hoveredFacilityMarker.status === '4'
                      ? 'primary'
                      : hoveredFacilityMarker.status === '3'
                      ? 'info'
                      : hoveredFacilityMarker.status === '2'
                      ? 'secondary'
                      : hoveredFacilityMarker.status === '1'
                      ? 'warning'
                      : hoveredFacilityMarker.status === '0'
                      ? 'danger'
                      : 'dark'
                  }
                  className="fs--2 text-800 ms-1"
                >
                  {hoveredFacilityMarker.status === '4'
                    ? 'A등급'
                    : hoveredFacilityMarker.status === '3'
                    ? 'B등급'
                    : hoveredFacilityMarker.status === '2'
                    ? 'C등급'
                    : hoveredFacilityMarker.status === '1'
                    ? 'D등급'
                    : hoveredFacilityMarker.status === '0'
                    ? 'E등급'
                    : '등급확인필요'}
                </SoftBadge>
              </Flex>
              <h6 className={'fs--2 text-700'}>
                {hoveredFacilityMarker.address}
              </h6>
            </Flex>
            {/*{!isEmpty(hoveredFacilityMarker.images) &&*/}
            {/*  hoveredFacilityMarker.images.length > 0 && (*/}
            {/*    <Flex*/}
            {/*      direction={'column'}*/}
            {/*      alignItems={'center'}*/}
            {/*      alignContent={'center'}*/}
            {/*      className={'p-2 pt-0'}*/}
            {/*    >*/}
            {/*      <Flex*/}
            {/*        direction={'column'}*/}
            {/*        justifyContent={'center'}*/}
            {/*        alignItems={'center'}*/}
            {/*        alignContent={'center'}*/}
            {/*      >*/}
            {/*        <img*/}
            {/*          style={{ width: '160px', height: '200px' }}*/}
            {/*          src={*/}
            {/*            process.env.REACT_APP_API_SERVER +*/}
            {/*            'api/file/' +*/}
            {/*            hoveredFacilityMarker.images[0]*/}
            {/*          }*/}
            {/*        />*/}
            {/*      </Flex>*/}
            {/*    </Flex>*/}
            {/*  )}*/}
          </Popup>
        )}
        {isVisibleSenserMarker && hoveredSensorMarker && (
          <Popup
            longitude={hoveredSensorMarker.location.longitude}
            latitude={hoveredSensorMarker.location.latitude}
            anchor="left"
            closeButton={false}
            dynamicPosition={true}
            // onClose={() => setShowPopup(false)}
          >
            <Flex
              direction={'row'}
              alignItems={'start'}
              alignContent={'center'}
              className={'p-2'}
            >
              {!isEmpty(hoveredSensorMarker.images) &&
                hoveredSensorMarker.images.length > 0 && (
                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    alignContent={'center'}
                    className={'pe-3'}
                  >
                    <img
                      style={{ width: '120px', height: '160px' }}
                      src={
                        process.env.REACT_APP_API_SERVER +
                        'api/file/' +
                        hoveredSensorMarker.images[0]
                      }
                    />
                  </Flex>
                )}
              <Flex
                direction={'column'}
                alignItems={'start'}
                alignContent={'center'}
              >
                <Flex
                  direction={'row'}
                  alignItems={'start'}
                  alignContent={'center'}
                >
                  <div
                    className={'fs--2 text-900 mb-1'}
                    style={{ width: '60px' }}
                  >
                    센서명:
                  </div>
                  <div className={'fs--2 text-700'} style={{ width: '100px' }}>
                    {hoveredSensorMarker.sensorHole.value}
                  </div>
                </Flex>
                <Flex
                  direction={'row'}
                  alignItems={'start'}
                  alignContent={'center'}
                >
                  <div
                    className={'fs--2 text-900 mb-1'}
                    style={{ width: '60px' }}
                  >
                    상태:
                  </div>
                  <SoftBadge
                    pill
                    bg={
                      hoveredSensorMarker.status === 'ON'
                        ? 'primary'
                        : hoveredSensorMarker.status === 'OFF'
                        ? 'warning'
                        : hoveredSensorMarker.status === 'ERR'
                        ? 'danger'
                        : 'dark'
                    }
                    className="fs--2 text-800"
                  >
                    {hoveredSensorMarker.status === 'ON'
                      ? 'ON'
                      : hoveredSensorMarker.status === 'OFF'
                      ? 'OFF'
                      : hoveredSensorMarker.status === 'ERR'
                      ? 'ERR'
                      : '등급확인필요'}
                  </SoftBadge>
                </Flex>
                <Flex
                  direction={'row'}
                  alignItems={'start'}
                  alignContent={'center'}
                >
                  <div
                    className={'fs--2 text-900 mb-1'}
                    style={{ width: '60px' }}
                  >
                    배터리:
                  </div>
                  <div className={'fs--2 text-700'} style={{ width: '100px' }}>
                    {!isEmpty(hoveredSensorMarker.battery)
                      ? hoveredSensorMarker.battery
                      : '-'}
                  </div>
                </Flex>
                <Flex
                  direction={'row'}
                  alignItems={'start'}
                  alignContent={'center'}
                >
                  <div
                    className={'fs--2 text-900 mb-1'}
                    style={{ width: '120px' }}
                  >
                    마지막 통신시간:
                  </div>
                </Flex>
                <Flex
                  direction={'row'}
                  alignItems={'center'}
                  alignContent={'end'}
                >
                  <div
                    className={'fs--2 text-700 text-end'}
                    style={{ width: '150px' }}
                  >
                    {hoveredSensorMarker.properties?.SSRAWDATE}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </Popup>
        )}
        {isVisibleFacilityBoundary && !isEmpty(facilityPolygons) && (
          <Source id="polygons" type="geojson" data={facilityPolygons}>
            <Layer
              id="polygonLayer"
              type="fill"
              paint={{
                'fill-color': getColor('secondary'),
                'fill-opacity': 0.3
              }}
            />
            <Layer
              type="line"
              paint={{
                'line-color': getColor('danger'), // 테두리 선 색상
                'line-width': 2 // 테두리 선 두께
              }}
            />
          </Source>
        )}
        {isVisibleBuildings && mapStyleName !== 'standard' && (
          <>
            <Layer {...buildingLayerStyle} />
            {/*<Source*/}
            {/*  id="mapbox-dem"*/}
            {/*  type="raster-dem"*/}
            {/*  url="mapbox://mapbox.mapbox-terrain-dem-v1"*/}
            {/*  tileSize={512}*/}
            {/*  maxzoom={18}*/}
            {/*/>*/}
            <Layer {...skyLayerStyle} />
          </>
        )}
      </Map>
      {predictDetailShow && (
        <PredictDetail
          feature={predictDetailFeature}
          detailModalShow={predictDetailShow}
          detailModalShowEvent={predictDetailModalShowEvent}
        />
      )}
      {sensorDetailShow && (
        <SensorDetail
          sensosrId={selectedSensorId}
          detailModalShow={sensorDetailShow}
          detailModalShowEvent={sensorDetailModalShowEvent}
        />
      )}
      {detailShow && (
        <MapboxItemDetail
          facilityId={selectedFacilitiesId}
          detailModalShow={detailShow}
          detailModalShowEvent={detailModalShowEvent}
        />
      )}
    </>
  );
};

export default MapboxIndex;
