import {
  ButtonGroup,
  Card,
  Col,
  DropdownButton,
  Dropdown,
  Form,
  Row
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import {
  getColor,
  getSearchPeriodAmount,
  toStringByFormatting
} from 'helpers/utils';
import React, { useRef, useState } from 'react';
import EventStaticstics from './chart/EventStaticstics';
import SensorProcessStaticstics from './chart/SensorProcessStaticstics';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IconButton from '../../common/IconButton';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

const StatisticsService = () => {
  const printAreaRef = useRef();

  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('1주일');
  const [searchStartDate, setSearchStartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const [isDisable, setIsDisable] = useState(true);

  const [startDate, setStartDate] = useState(
    Date.parse(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  );
  const [endDate, setEndDate] = useState(
    Date.parse(moment().format('YYYY-MM-DD'))
  );

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }
    if (value === 'USER') {
      setSelectedSearchPriod('사용자지정');
      setIsDisable(false);
    }

    if (value !== 'USER') {
      let amount = getSearchPeriodAmount(value);
      let pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
      let pEndDate = moment().format('YYYY-MM-DD');

      setSearchStartDate(pStartDate);
      setSearchEndDate(pEndDate);

      setStartDate(Date.parse(pStartDate));
      setEndDate(Date.parse(pEndDate));

      setIsDisable(true);
    }
  };

  const SearchCustom = () => {
    if (startDate > endDate) {
      toast.error(`조회 시작일이 조회 종료일보다 큽니다.`, {
        theme: 'colored'
      });
    } else {
      setSearchStartDate(toStringByFormatting(new Date(startDate)));
      setSearchEndDate(toStringByFormatting(new Date(endDate)));
    }
  };

  const pageStyle = `
    @media print {
      .col-lg-4 {
        width: 40% !important;
      }
      .col-lg-8 {
        width: 50% !important;
      }
    }
  `;

  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
    pageStyle: pageStyle
  });

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} xl={12} xxl={12}>
          <Card className="h-md-100">
            <Card.Header>
              <h5>서비스 통계</h5>
            </Card.Header>
            <Card.Body>
              <Row
                as={Flex}
                alignItems="center"
                justifyContent="end"
                className={'px-3 pt-3'}
                style={{ backgroundColor: getColor('gray-100') }}
              >
                <Row as={Flex} alignItems="center" justifyContent="end">
                  <Col
                    xs={12}
                    md={10}
                    xl={6}
                    xxl={4}
                    className={'pb-3'}
                    as={Flex}
                    alignItems="center"
                    alignContent={'center'}
                    justifyContent="end"
                  >
                    <Dropdown
                      onSelect={onSelectSearchPeriod}
                      className={'me-3'}
                    >
                      <Dropdown.Toggle variant="falcon-default" size="sm">
                        {!!selectedSearchPeriod
                          ? selectedSearchPeriod
                          : '조회 개월 수'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="py-2">
                        <Dropdown.Item key="1" eventKey="1W">
                          1주일
                        </Dropdown.Item>
                        <Dropdown.Item key="2" eventKey="1M">
                          1개월
                        </Dropdown.Item>
                        <Dropdown.Item key="3" eventKey="3M">
                          3개월
                        </Dropdown.Item>
                        <Dropdown.Item key="4" eventKey="6M">
                          6개월
                        </Dropdown.Item>
                        <Dropdown.Item key="5" eventKey="1Y">
                          1년
                        </Dropdown.Item>
                        <Dropdown.Item key="6" eventKey="ALL">
                          설치일부터
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item key="7" eventKey="USER">
                          사용자지정
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <DatePicker
                      showIcon
                      selected={startDate}
                      disabled={isDisable}
                      onChange={date => setStartDate(date)}
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                    />
                    <h6 className={'mx-2'}>~</h6>
                    <DatePicker
                      showIcon
                      selected={endDate}
                      disabled={isDisable}
                      onChange={date => setEndDate(date)}
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                    />
                    <IconButton
                      variant="falcon-default"
                      // size="sm"
                      icon="search"
                      className="me-1 mb-2 mb-sm-0 ms-3"
                      iconClassName="me-1"
                      disabled={isDisable}
                      onClick={() => SearchCustom()}
                    />
                  </Col>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3" ref={printAreaRef}>
        <Col md={6} xs={12}>
          <EventStaticstics
            searchStartDate={searchStartDate}
            searchEndDate={searchEndDate}
          />
        </Col>
        <Col md={6} xs={12}>
          <SensorProcessStaticstics
            searchStartDate={searchStartDate}
            searchEndDate={searchEndDate}
          />
        </Col>
      </Row>
      <button onClick={handlePrint}>Print this out!</button>
    </>
  );
};

export default StatisticsService;
