export const commupipeData = {
  type: 'FeatureCollection',
  name: 'commu pipe',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      id: '1',
      type: 'Feature',
      properties: {
        id: 1,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780225, 37.647623],
            [126.780239, 37.647614],
            [126.791249, 37.639278]
          ]
        ]
      }
    },
    {
      id: '2',
      type: 'Feature',
      properties: {
        id: 2,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.791261, 37.639288],
            [126.792024, 37.639942],
            [126.79207, 37.639981]
          ]
        ]
      }
    },
    {
      id: '3',
      type: 'Feature',
      properties: {
        id: 3,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.791261, 37.639288],
            [126.790016, 37.638253]
          ]
        ]
      }
    },
    {
      id: '4',
      type: 'Feature',
      properties: {
        id: 4,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790016, 37.638253],
            [126.789064, 37.637522],
            [126.788757, 37.637307]
          ]
        ]
      }
    },
    {
      id: '5',
      type: 'Feature',
      properties: {
        id: 5,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788757, 37.637307],
            [126.78783, 37.636695]
          ]
        ]
      }
    },
    {
      id: '6',
      type: 'Feature',
      properties: {
        id: 6,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78783, 37.636695],
            [126.776968, 37.644875],
            [126.776955, 37.644885]
          ]
        ]
      }
    },
    {
      id: '7',
      type: 'Feature',
      properties: {
        id: 7,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780446, 37.642256],
            [126.78045, 37.642259],
            [126.783746, 37.644984]
          ]
        ]
      }
    },
    {
      id: '8',
      type: 'Feature',
      properties: {
        id: 8,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783746, 37.644984],
            [126.78451, 37.645628],
            [126.784547, 37.645657]
          ]
        ]
      }
    },
    {
      id: '9',
      type: 'Feature',
      properties: {
        id: 9,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783924, 37.639636],
            [126.783925, 37.639637],
            [126.783929, 37.63964],
            [126.787187, 37.642381]
          ]
        ]
      }
    },
    {
      id: '10',
      type: 'Feature',
      properties: {
        id: 10,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787187, 37.642381],
            [126.787965, 37.643037],
            [126.788, 37.643064]
          ]
        ]
      }
    },
    {
      id: '11',
      type: 'Feature',
      properties: {
        id: 11,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789447, 37.641968],
            [126.789412, 37.641936],
            [126.788636, 37.641283]
          ]
        ]
      }
    },
    {
      id: '12',
      type: 'Feature',
      properties: {
        id: 12,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788636, 37.641283],
            [126.785337, 37.638579],
            [126.785332, 37.638576]
          ]
        ]
      }
    },
    {
      id: '13',
      type: 'Feature',
      properties: {
        id: 13,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786672, 37.637567],
            [126.786676, 37.63757],
            [126.789932, 37.640302]
          ]
        ]
      }
    },
    {
      id: '14',
      type: 'Feature',
      properties: {
        id: 14,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789932, 37.640302],
            [126.790722, 37.640948],
            [126.790757, 37.640976]
          ]
        ]
      }
    },
    {
      id: '15',
      type: 'Feature',
      properties: {
        id: 15,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788912, 37.637416],
            [126.787635, 37.638374]
          ]
        ]
      }
    },
    {
      id: '16',
      type: 'Feature',
      properties: {
        id: 16,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788404, 37.637797],
            [126.790721, 37.639693]
          ]
        ]
      }
    },
    {
      id: '17',
      type: 'Feature',
      properties: {
        id: 17,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790721, 37.639693],
            [126.791479, 37.640363],
            [126.791519, 37.640398]
          ]
        ]
      }
    },
    {
      id: '18',
      type: 'Feature',
      properties: {
        id: 18,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778003, 37.644096],
            [126.781264, 37.646831],
            [126.781269, 37.646835]
          ]
        ]
      }
    },
    {
      id: '21',
      type: 'Feature',
      properties: {
        id: 21,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.77889, 37.64484],
            [126.777867, 37.645619],
            [126.777847, 37.645633]
          ]
        ]
      }
    },
    {
      id: '22',
      type: 'Feature',
      properties: {
        id: 22,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779378, 37.645249],
            [126.778344, 37.646002],
            [126.778313, 37.646022]
          ]
        ]
      }
    },
    {
      id: '23',
      type: 'Feature',
      properties: {
        id: 23,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780018, 37.645786],
            [126.779015, 37.646546],
            [126.778976, 37.646577]
          ]
        ]
      }
    },
    {
      id: '24',
      type: 'Feature',
      properties: {
        id: 24,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782353, 37.64393],
            [126.781766, 37.644373]
          ]
        ]
      }
    },
    {
      id: '25',
      type: 'Feature',
      properties: {
        id: 25,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784428, 37.644443],
            [126.784429, 37.644444],
            [126.7852, 37.645129],
            [126.78525, 37.645172]
          ]
        ]
      }
    },
    {
      id: '26',
      type: 'Feature',
      properties: {
        id: 26,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784737, 37.644717],
            [126.784748, 37.644708],
            [126.786748, 37.643222]
          ]
        ]
      }
    },
    {
      id: '27',
      type: 'Feature',
      properties: {
        id: 27,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786421, 37.642934],
            [126.786426, 37.642939],
            [126.786689, 37.643169],
            [126.786725, 37.643201]
          ]
        ]
      }
    },
    {
      id: '28',
      type: 'Feature',
      properties: {
        id: 28,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786725, 37.643201],
            [126.787191, 37.643625],
            [126.78723, 37.643655]
          ]
        ]
      }
    },
    {
      id: '29',
      type: 'Feature',
      properties: {
        id: 29,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785447, 37.643671],
            [126.78457, 37.642931]
          ]
        ]
      }
    },
    {
      id: '30',
      type: 'Feature',
      properties: {
        id: 30,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782353, 37.64393],
            [126.782869, 37.643529]
          ]
        ]
      }
    },
    {
      id: '31',
      type: 'Feature',
      properties: {
        id: 31,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785406, 37.640882],
            [126.784828, 37.641294]
          ]
        ]
      }
    },
    {
      id: '32',
      type: 'Feature',
      properties: {
        id: 32,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786755, 37.642017],
            [126.786309, 37.642324]
          ]
        ]
      }
    },
    {
      id: '33',
      type: 'Feature',
      properties: {
        id: 33,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784317, 37.639967],
            [126.783908, 37.640248]
          ]
        ]
      }
    },
    {
      id: '34',
      type: 'Feature',
      properties: {
        id: 34,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 3
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781712, 37.643303],
            [126.782521, 37.642706]
          ]
        ]
      }
    },
    {
      id: '35',
      type: 'Feature',
      properties: {
        id: 35,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789998, 37.638239],
            [126.78863, 37.639209]
          ]
        ]
      }
    },
    {
      id: '38',
      type: 'Feature',
      properties: {
        id: 38,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78777, 37.641912],
            [126.788571, 37.642573],
            [126.788608, 37.642604]
          ]
        ]
      }
    },
    {
      id: '39',
      type: 'Feature',
      properties: {
        id: 39,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789291, 37.640761],
            [126.790094, 37.641441],
            [126.790117, 37.641461]
          ]
        ]
      }
    },
    {
      id: '40',
      type: 'Feature',
      properties: {
        id: 40,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782296, 37.640863],
            [126.783391, 37.641757]
          ]
        ]
      }
    },
    {
      id: '19',
      type: 'Feature',
      properties: {
        id: 19,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781976, 37.646299],
            [126.781599, 37.645986],
            [126.78133, 37.645764],
            [126.781156, 37.645616],
            [126.781016, 37.645502],
            [126.780917, 37.645418],
            [126.78077, 37.645293],
            [126.780612, 37.645162],
            [126.780447, 37.645021],
            [126.780313, 37.644911],
            [126.780137, 37.644764],
            [126.779871, 37.644543],
            [126.779306, 37.644064],
            [126.77896, 37.643786],
            [126.778694, 37.643576]
          ]
        ]
      }
    },
    {
      id: '20',
      type: 'Feature',
      properties: {
        id: 20,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: null
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782582, 37.64584],
            [126.782579, 37.645837],
            [126.782084, 37.645416],
            [126.781714, 37.645111],
            [126.781442, 37.64488],
            [126.781092, 37.644591],
            [126.780712, 37.644274],
            [126.780285, 37.643916],
            [126.779858, 37.643563],
            [126.779427, 37.643205],
            [126.779306, 37.643115]
          ]
        ]
      }
    }
  ]
};
