import { useState } from 'react';
import { useQuery } from 'react-query';
import { getChatrooms } from '../api';

export function useChatrooms() {
  const [selectedStatus, setSelectedStatus] = useState('0');

  const { data = [] } = useQuery(
    ['getChatrooms', selectedStatus],
    () => getChatrooms(selectedStatus),
    {
      // select: selectFilterTabValue,
      staleTime: 5000,
      cacheTime: 300000,
      refetchOnMount: true,
      refetchOnReconnect: true
    }
  );

  return {
    data,
    selectedStatus,
    setSelectedStatus
  };
}
