import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CountUp from 'react-countup';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import BaseInfo from './components/BaseInfo';
import MapInfo from './components/MapInfo';
import RiskChart from './components/RiskChart';
import DetailInfo from './components/DetailInfo';

const PredictDetail = ({ feature, detailModalShow, detailModalShowEvent }) => {
  const [detailInfo, setDetailInfo] = useState({
    id: '-',
    name: '-',
    ins_date: '-',
    ins_depth: '-',
    diameter: '-',
    length: '-',
    damNumber: '-'
  });
  useEffect(() => {
    if (detailModalShow) {
    }
  }, [detailModalShow]);

  const setDetailData = value => {
    setDetailInfo(value);
  };

  return (
    <Modal
      // dialogClassName="modal-75w"
      size="xl"
      show={detailModalShow}
      onHide={detailModalShowEvent}
      backdrop="static"
      keyboard={false}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton>
        <Flex direction="column">
          <Modal.Title id="example-custom-modal-styling-title">
            [{feature.properties.MAPIDCD_NO}] 구역 상세정보
          </Modal.Title>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-0 pb-2">
          <BaseInfo properties={feature.properties} />
        </Row>
        <Row className="g-0">
          <Col xs={12} lg={6} className={'pe-2'}>
            <MapInfo feature={feature} setDetailInfo={setDetailData} />
          </Col>
          <Col xs={12} lg={6} className={'ps-2'}>
            <Row className="g-0 pb-2">
              <RiskChart properties={feature.properties} />
            </Row>
            <Row className="g-0">
              <DetailInfo detailData={detailInfo} />
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn_close"
          variant="secondary"
          onClick={detailModalShowEvent}
        >
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PredictDetail;
