import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import SoftBadge from 'components/common/SoftBadge';

const BaseInfo = ({ properties }) => {
  const makeRatingName = () => {
    let ratingName = 'A';

    if (properties.ITEMT === 6) {
      ratingName = 'B';
    }

    if (properties.ITEMT === 7) {
      ratingName = 'C';
    }

    if (properties.ITEMT === 8) {
      ratingName = 'D';
    }

    if (properties.ITEMT >= 9) {
      ratingName = 'E';
    }

    return ratingName;
  };

  const makeBadge = () => {
    let badgeColor = 'dark';
    let badgeName = '등급확인필요';

    if (properties.ITEMT < 7) {
      badgeName = '안전';
      badgeColor = 'success';
    }

    if (properties.ITEMT === 7) {
      badgeName = '주의';

      return (
        <div
          className={'badge rounded-pill ms-2 fs--0 text-800'}
          style={{ backgroundColor: '#FFFF00' }}
        >
          {badgeName}
        </div>
      );
    }

    if (properties.ITEMT === 8) {
      badgeName = '경고';
      badgeColor = 'warning';
    }

    if (properties.ITEMT >= 9) {
      badgeName = '심각';
      badgeColor = 'danger';
    }

    return (
      <SoftBadge pill bg={badgeColor} className="ms-2 fs--0 text-800">
        {badgeName}
      </SoftBadge>
    );
  };

  return (
    <Card className="h-100">
      <FalconCardHeader title="구역정보" light titleTag="h6" />
      <Card.Body className={'p-0'}>
        <Row className="g-0 p-3 pt-0">
          <Col xs={3} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>구역번호</div>
            <div className={'fs--0 text-700'}>{properties.MAPIDCD_NO}</div>
          </Col>
          <Col xs={3} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>위험도 점수</div>
            <div className={'fs--0 text-700'}>{properties.ITEMT}</div>
          </Col>
          <Col xs={3} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>위험도 등급</div>
            <div className={'fs--0 text-700'}>{makeRatingName()}</div>
          </Col>
          <Col xs={3} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>위험도 평가</div>
            <div className={'fs--0 text-700'}>{makeBadge()}</div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BaseInfo;
