import React, { useContext, useEffect, useRef, useState } from 'react';
import { Offcanvas, Container, Dropdown, CloseButton } from 'react-bootstrap';
import EventList from './EventList';
import 'components/maps/Custom.css';
import FalconCardHeader from '../../common/FalconCardHeader';

const BottomArea = props => {
  // const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <Offcanvas
      show={props.ButtomAreaShow}
      onHide={props.ButtomAreaShowEvent}
      scroll={true}
      backdrop={false}
      keyboard={false}
      placement="bottom"
      className={'mb-1 mx-offcanvas rounded-2'}
    >
      <Offcanvas.Header className={'pb-0'} closeButton>
        <Offcanvas.Title>알림내역</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className={'pb-0'} style={{ overflow: 'heidden' }}>
        <EventList />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default BottomArea;
