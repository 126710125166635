export const escepipeData = {
  type: 'FeatureCollection',
  name: 'elec pipe',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      id: '1',
      type: 'Feature',
      properties: {
        id: 1,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780251, 37.647644],
            [126.780264, 37.647634],
            [126.791258, 37.639312]
          ]
        ]
      }
    },
    {
      id: '2',
      type: 'Feature',
      properties: {
        id: 2,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.791268, 37.63932],
            [126.792029, 37.639987],
            [126.792045, 37.64]
          ]
        ]
      }
    },
    {
      id: '3',
      type: 'Feature',
      properties: {
        id: 3,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.791268, 37.63932],
            [126.790019, 37.638291]
          ]
        ]
      }
    },
    {
      id: '4',
      type: 'Feature',
      properties: {
        id: 4,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790019, 37.638291],
            [126.788773, 37.637333]
          ]
        ]
      }
    },
    {
      id: '5',
      type: 'Feature',
      properties: {
        id: 5,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788773, 37.637333],
            [126.787834, 37.636728]
          ]
        ]
      }
    },
    {
      id: '6',
      type: 'Feature',
      properties: {
        id: 6,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787834, 37.636728],
            [126.776991, 37.644895],
            [126.776978, 37.644905]
          ]
        ]
      }
    },
    {
      id: '7',
      type: 'Feature',
      properties: {
        id: 7,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780438, 37.642298],
            [126.780442, 37.642301],
            [126.783738, 37.645026]
          ]
        ]
      }
    },
    {
      id: '8',
      type: 'Feature',
      properties: {
        id: 8,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783738, 37.645026],
            [126.784487, 37.645668],
            [126.78451, 37.645684]
          ]
        ]
      }
    },
    {
      id: '9',
      type: 'Feature',
      properties: {
        id: 9,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783894, 37.639695],
            [126.783894, 37.639695],
            [126.783899, 37.639699],
            [126.787158, 37.642439]
          ]
        ]
      }
    },
    {
      id: '10',
      type: 'Feature',
      properties: {
        id: 10,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787158, 37.642439],
            [126.787936, 37.643096],
            [126.787946, 37.643105]
          ]
        ]
      }
    },
    {
      id: '11',
      type: 'Feature',
      properties: {
        id: 11,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789433, 37.641979],
            [126.789422, 37.64197],
            [126.788632, 37.641323]
          ]
        ]
      }
    },
    {
      id: '12',
      type: 'Feature',
      properties: {
        id: 12,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788632, 37.641323],
            [126.785333, 37.638619],
            [126.785328, 37.638615]
          ]
        ]
      }
    },
    {
      id: '13',
      type: 'Feature',
      properties: {
        id: 13,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786676, 37.637599],
            [126.786681, 37.637604],
            [126.789937, 37.640335]
          ]
        ]
      }
    },
    {
      id: '14',
      type: 'Feature',
      properties: {
        id: 14,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789937, 37.640335],
            [126.790726, 37.640982],
            [126.790738, 37.64099]
          ]
        ]
      }
    },
    {
      id: '15',
      type: 'Feature',
      properties: {
        id: 15,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788875, 37.637411],
            [126.787587, 37.638363]
          ]
        ]
      }
    },
    {
      id: '16',
      type: 'Feature',
      properties: {
        id: 16,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788364, 37.637789],
            [126.790729, 37.639724]
          ]
        ]
      }
    },
    {
      id: '17',
      type: 'Feature',
      properties: {
        id: 17,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790729, 37.639724],
            [126.791487, 37.640394],
            [126.791505, 37.640409]
          ]
        ]
      }
    },
    {
      id: '18',
      type: 'Feature',
      properties: {
        id: 18,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778017, 37.644122],
            [126.781275, 37.646854],
            [126.781284, 37.646862]
          ]
        ]
      }
    },
    {
      id: '21',
      type: 'Feature',
      properties: {
        id: 21,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778899, 37.644862],
            [126.777864, 37.645647]
          ]
        ]
      }
    },
    {
      id: '22',
      type: 'Feature',
      properties: {
        id: 22,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779382, 37.645267],
            [126.778349, 37.646014],
            [126.778327, 37.646034]
          ]
        ]
      }
    },
    {
      id: '23',
      type: 'Feature',
      properties: {
        id: 23,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780024, 37.645806],
            [126.779023, 37.646573],
            [126.778996, 37.646594]
          ]
        ]
      }
    },
    {
      id: '24',
      type: 'Feature',
      properties: {
        id: 24,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782377, 37.64395],
            [126.781789, 37.644392]
          ]
        ]
      }
    },
    {
      id: '25',
      type: 'Feature',
      properties: {
        id: 25,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784436, 37.644476],
            [126.784446, 37.644483],
            [126.785224, 37.645172],
            [126.785235, 37.645182]
          ]
        ]
      }
    },
    {
      id: '26',
      type: 'Feature',
      properties: {
        id: 26,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784737, 37.644741],
            [126.784742, 37.644737],
            [126.786747, 37.643246]
          ]
        ]
      }
    },
    {
      id: '27',
      type: 'Feature',
      properties: {
        id: 27,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786431, 37.642966],
            [126.786685, 37.643189],
            [126.7867, 37.643202]
          ]
        ]
      }
    },
    {
      id: '28',
      type: 'Feature',
      properties: {
        id: 28,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786699, 37.643202],
            [126.787198, 37.643661],
            [126.787209, 37.64367]
          ]
        ]
      }
    },
    {
      id: '29',
      type: 'Feature',
      properties: {
        id: 29,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785453, 37.643706],
            [126.784546, 37.642941]
          ]
        ]
      }
    },
    {
      id: '30',
      type: 'Feature',
      properties: {
        id: 30,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782377, 37.64395],
            [126.782892, 37.643548]
          ]
        ]
      }
    },
    {
      id: '31',
      type: 'Feature',
      properties: {
        id: 31,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785376, 37.640941],
            [126.784798, 37.641352]
          ]
        ]
      }
    },
    {
      id: '32',
      type: 'Feature',
      properties: {
        id: 32,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786726, 37.642076],
            [126.78628, 37.642383]
          ]
        ]
      }
    },
    {
      id: '33',
      type: 'Feature',
      properties: {
        id: 33,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784288, 37.640026],
            [126.783878, 37.640306]
          ]
        ]
      }
    },
    {
      id: '34',
      type: 'Feature',
      properties: {
        id: 34,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 3
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781704, 37.643345],
            [126.782528, 37.642738]
          ]
        ]
      }
    },
    {
      id: '35',
      type: 'Feature',
      properties: {
        id: 35,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790028, 37.638299],
            [126.788657, 37.639261]
          ]
        ]
      }
    },
    {
      id: '38',
      type: 'Feature',
      properties: {
        id: 38,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787782, 37.641943],
            [126.788574, 37.642607],
            [126.788588, 37.642619]
          ]
        ]
      }
    },
    {
      id: '39',
      type: 'Feature',
      properties: {
        id: 39,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789302, 37.640793],
            [126.790083, 37.641462],
            [126.790099, 37.641474]
          ]
        ]
      }
    },
    {
      id: '40',
      type: 'Feature',
      properties: {
        id: 40,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782306, 37.640892],
            [126.783402, 37.641787]
          ]
        ]
      }
    },
    {
      id: '19',
      type: 'Feature',
      properties: {
        id: 19,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781995, 37.646324],
            [126.781614, 37.646011],
            [126.781345, 37.645787],
            [126.781171, 37.645641],
            [126.781027, 37.645523],
            [126.780928, 37.645446],
            [126.780786, 37.645321],
            [126.780623, 37.64519],
            [126.780459, 37.64505],
            [126.780325, 37.644939],
            [126.780148, 37.644792],
            [126.779882, 37.644571],
            [126.779318, 37.644092],
            [126.778972, 37.643814],
            [126.778709, 37.643601]
          ]
        ]
      }
    },
    {
      id: '20',
      type: 'Feature',
      properties: {
        id: 20,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: null
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782599, 37.645867],
            [126.7821, 37.645442],
            [126.78173, 37.645137],
            [126.781449, 37.644911],
            [126.781101, 37.644624],
            [126.780728, 37.6443],
            [126.780295, 37.643951],
            [126.779862, 37.643592],
            [126.779432, 37.643238],
            [126.779309, 37.643149]
          ]
        ]
      }
    }
  ]
};
