import { Button, Card, Col, Modal, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { chainAxios } from 'helpers/chainAxios';
import { isEmpty } from 'helpers/utils';
import MapboxMinimapSensor from './MapboxMinimapSensor';
import { default as SensorInfo } from '../../../management-target/sensor/SensorDetail';
import PhotoArea from './component/PhotoArea';
import SoftBadge from 'components/common/SoftBadge';
import ContentArea from './component/ContentArea';

const SensorDetail = ({ sensosrId, detailModalShow, detailModalShowEvent }) => {
  const [sensorInfo, setSensorInfo] = useState({});

  useEffect(() => {
    if (detailModalShow) {
      getSensorInfo();
    }
  }, [detailModalShow]);
  const getSensorInfo = () => {
    let url = '/api/sensor/' + sensosrId;
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ItemDetail facilityInfo() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'SensorDetail getSensorInfo() : ',
          //   response.data.data.sensor
          // );
          setSensorInfo(response.data.data.sensor);
        }
      })
      .catch(error => {
        console.log('getSensorInfo list() : ', error);
      });
  };
  const makeTitle = () => {
    let sensorHoleValue = '-';
    if (!isEmpty(sensorInfo) && !isEmpty(sensorInfo.sensorHole)) {
      sensorHoleValue = sensorInfo.sensorHole.value;
    }

    return sensorHoleValue;
  };

  const makeStatusBadge = () => {
    let badgeColor = 'dark';
    let badgeName = '등급확인필요';

    if (sensorInfo.status === 'ON') {
      badgeName = 'ON';
      badgeColor = 'primary';
    }
    if (sensorInfo.status === 'OFF') {
      badgeName = 'OFF';
      badgeColor = 'warning';
    }
    if (sensorInfo.status === 'ERR') {
      badgeName = 'ERR';
      badgeColor = 'danger';
    }

    return (
      <SoftBadge pill bg={badgeColor} className="ms-2 fs--0 text-800">
        {badgeName}
      </SoftBadge>
    );
  };

  return (
    <Modal
      // dialogClassName="modal-75w"
      size="xl"
      show={detailModalShow}
      onHide={detailModalShowEvent}
      backdrop="static"
      keyboard={false}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton>
        <Flex direction="column">
          <Modal.Title id="example-custom-modal-styling-title">
            {makeTitle()}
            {makeStatusBadge()}
          </Modal.Title>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-3 mb-3">
          <Col xs={12} lg={4}>
            <Card className={classNames('overflow-hidden')}>
              <Tab.Container id="audience-tab" defaultActiveKey="map">
                {/*<SimpleBarReact>*/}
                <Card.Header className="p-0 bg-light">
                  <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
                    <Nav.Item>
                      <Nav.Link className="mb-0 py-3 px-4" eventKey="map">
                        <h6 className={'mb-0'}>미니맵</h6>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="mb-0 py-3 px-4" eventKey="photo">
                        <h6 className={'mb-0'}>현장사진</h6>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                {/*</SimpleBarReact>*/}
                <Card.Body>
                  <Tab.Content>
                    <Tab.Pane unmountOnExit eventKey="map">
                      <Col
                        xs={12}
                        style={{
                          maxHeight: '250px',
                          justifyContent: 'center'
                        }}
                      >
                        <MapboxMinimapSensor sensorInfo={sensorInfo} />
                      </Col>
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="photo">
                      <Col
                        xs={12}
                        style={{
                          minHeight: '250px',
                          maxHeight: '250px',
                          justifyContent: 'center'
                        }}
                      >
                        <PhotoArea sensorInfo={sensorInfo} />
                      </Col>
                    </Tab.Pane>
                  </Tab.Content>
                </Card.Body>
              </Tab.Container>
            </Card>
          </Col>
          <Col xs={12} lg={8} className={'px-4'}>
            <SensorInfo sensor={sensorInfo} />
          </Col>
        </Row>
        <Row className={'g-3'}>
          <Col xs={12}>
            <ContentArea sensorInfo={sensorInfo} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn_close"
          variant="secondary"
          onClick={detailModalShowEvent}
        >
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SensorDetail;
