import { useState } from 'react';
import { useQuery } from 'react-query';
import {
  getSensorAngle,
  getSensorDirectionAngels,
  getSensorDistance,
  getSensorFluctuations,
  getSensorRawData,
  getSensorSendData,
  getSensorSlopeAngels,
  getSensorSolidationsSubsidendces
} from '../api';
import {
  getFluctuations,
  getSlopeAngels,
  getSolidationsSubsidendces
} from '../../../maps/left-area/detail/api';

export function useSensorDistance() {
  const [sensorId, setSensorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    ['getSensorDistance', sensorId, startDate, endDate],
    () => getSensorDistance(sensorId, startDate, endDate),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    sensorId,
    setSensorId,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}

export function useSensorAngle() {
  const [sensorId, setSensorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    ['getSensorAngle', sensorId, startDate, endDate],
    () => getSensorAngle(sensorId, startDate, endDate),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    sensorId,
    setSensorId,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}

export function useSensorSlopeAngles() {
  const [sensorId, setSensorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data = [] } = useQuery(
    ['getSensorSlopeAngels', sensorId, startDate, endDate],
    () => getSensorSlopeAngels(sensorId, startDate, endDate),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    sensorId,
    setSensorId,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}
export function useSensorDirectionAngle() {
  const [sensorId, setSensorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    ['getSensorDirectionAngels', sensorId, startDate, endDate],
    () => getSensorDirectionAngels(sensorId, startDate, endDate),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    sensorId,
    setSensorId,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}

export function useSensorFluctuations() {
  const [sensorId, setSensorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data = [] } = useQuery(
    ['getSensorFluctuations', sensorId, startDate, endDate],
    () => getSensorFluctuations(sensorId, startDate, endDate),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    sensorId,
    setSensorId,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}

export function useSensorSolidationsSubsidences() {
  const [sensorId, setSensorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data = [] } = useQuery(
    ['getSensorSolidationsSubsidendces', sensorId, startDate, endDate],
    () => getSensorSolidationsSubsidendces(sensorId, startDate, endDate),
    {
      // select: selectFilterTabValue,
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    sensorId,
    setSensorId,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}

export function useSensorSendData() {
  const [sensorId, setSensorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    ['getSensorSendData', sensorId, startDate, endDate],
    () => getSensorSendData(sensorId, startDate, endDate),
    {
      staleTime: 1000,
      cacheTime: 30000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: false
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    sensorId,
    setSensorId,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}

export function useSensorRawData() {
  const [sensorId, setSensorId] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    ['getSensorRawData', sensorId, pageNo, pageSize, startDate, endDate],
    () => getSensorRawData(sensorId, pageNo, pageSize, startDate, endDate),
    {
      staleTime: 5000,
      cacheTime: 300000,
      refetchOnMount: true,
      refetchOnReconnect: true
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    sensorId,
    setSensorId,
    pageNo,
    setPageNo,
    pageSize,
    setPageSize,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}
