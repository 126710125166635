import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { SocketContext } from 'context/Context';
import { Cookies } from 'react-cookie';
import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const cookies = new Cookies();

const SocketProvider = ({ children }) => {
  //   const [emailState, emailDispatch] = useReducer(emailReducer, {
  //     emails: rawEmails,
  //     allEmails: rawEmails,
  //     filters: ['all', 'unread', 'star', 'attachments', 'archive', 'snooze'],
  //     currentFilter: 'all'
  //   });

  // const stompClient = Stomp.over(
  //   new SockJS(
  //     'http://112.175.18.232:7000/ws?X-AUTH-TOKEN=' +
  //       cookies.get('Authorization')
  //   )
  // );

  const stompEventClient = Stomp.over(function () {
    return new SockJS(
      'https://gms.chainreum.com/ws?X-AUTH-TOKEN=' +
        cookies.get('Authorization')
    );
  });

  const stompChatClient = Stomp.over(function () {
    return new SockJS(
      'https://gms.chainreum.com/ws?X-AUTH-TOKEN=' +
        cookies.get('Authorization')
    );
  });

  //   const [socketState, socketDispatch] = useReducer(socketReducer, {
  //     socketClient: stompClient
  //   });

  return (
    <SocketContext.Provider value={{ stompEventClient, stompChatClient }}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = { children: PropTypes.node };

export default SocketProvider;
