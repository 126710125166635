import { Col, Row } from 'react-bootstrap';
import Flex from '../../../../common/Flex';
import React from 'react';
import { getColor } from '../../../../../helpers/utils';

const RoadInfo = ({ facility }) => {
  return (
    <Row
      xs={12}
      className={'py-3 border rounded h-100'}
      style={{ backgroundColor: getColor('white') }}
    >
      <Col xs={6} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>도로명코드</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.RDCODE}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>도로고유키</div>
            <div className={'fs--1 text-700'}>
              {!!facility && !!facility.properties && facility.properties.RDKEY}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>광역도로구분</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.RDWDRCODE}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>종속여부</div>
            <div className={'fs--1 text-700'}>
              {!!facility && !!facility.properties && facility.properties.RDDPN}
            </div>
          </Col>
        </Flex>
      </Col>
      <Col xs={6} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>도로폭</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.RDWIDTH}{' '}
              m2
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>도로길이</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.RDLENGTH}{' '}
              m2
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>도로구간 기점주소</div>
            <div className={'fs--1 text-700'}>
              {!!facility && !!facility.properties && facility.properties.RDRBP}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>도로구간 종점주소</div>
            <div className={'fs--1 text-700'}>
              {!!facility && !!facility.properties && facility.properties.RDREP}
            </div>
          </Col>
        </Flex>
      </Col>
    </Row>
  );
};

export default RoadInfo;
