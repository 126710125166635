import React, { useRef, useEffect, useState, Suspense, lazy } from 'react';
import { Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { getChartSeriesColor } from 'helpers/utils';
import FalconCardHeader from '../../../../../common/FalconCardHeader';
import { useRealTimeDistance } from '../../hook/useMapsDetail';
import RealTimeDistanceChart from './RealTimeDistanceChart';

// const RealTimeDistanceChart = lazy(() => import('./RealTimeDistanceChart'));

const RealTimeDistance = ({ facilityId }) => {
  const {
    data: realTimeDistanceDatas,
    isLoading,
    isFetching,
    setRealTimeDistanceFacilityId,
    setRealTimeDistanceSearchPeriod
  } = useRealTimeDistance();

  const chartRef = useRef(null);
  const [realTimeDistanceChartData, setRealTimeDistanceChartData] = useState(
    {}
  );
  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('1주일');

  const [chartLoading, setChartLoading] = useState(false);

  useEffect(() => {
    if (!!facilityId) {
      setRealTimeDistanceFacilityId(facilityId);
    }
  }, [facilityId]);

  // useEffect(() => {
  //   if (
  //     !!realTimeDistanceDatas &&
  //     JSON.stringify(realTimeDistanceDatas) !== '{}'
  //   ) {
  //     makeRealTimeDistanceChartData(realTimeDistanceDatas);
  //   }
  // }, [realTimeDistanceDatas]);

  useEffect(() => {
    if (
      !isFetching &&
      !!realTimeDistanceDatas &&
      JSON.stringify(realTimeDistanceDatas) !== '{}'
    ) {
      makeRealTimeDistanceChartData(realTimeDistanceDatas);
    }
  }, [isFetching]);

  const makeRealTimeDistanceChartData = chartDatas => {
    console.time();
    setChartLoading(true);

    let orginDates = [];
    let orginLegend = [];

    // console.log('makeRealTimeDistanceChartData : ', chartDatas);
    // console.log(Object.keys(chartDatas.legend));
    const seriesColors = getChartSeriesColor();
    // ms-0 form-check-input-primary opacity-75
    Object.keys(chartDatas.legend).forEach((key, index) => {
      if (key !== 'X') {
        let legendValue = chartDatas.legend[key].split(' : ');
        let color = 'blue';
        if (index !== 0) {
          color = seriesColors[(index - 1) % seriesColors.length];
        }
        orginLegend.push({
          key: key,
          value: legendValue[0],
          color: color
        });
      }

      if (key === 'X') {
        chartDatas.chartData.forEach(data => {
          orginDates.push(data[key]);
        });
      }
    });

    let setDates = new Set(orginDates);
    let seriesDatas = [];
    let legendDatas = [];

    let sortOrginLegend = orginLegend.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;

      return 0;
    });

    // setFacilitySensors(sortOrginLegend);

    sortOrginLegend.forEach(legend => {
      // console.log('legend : ', legend);
      legendDatas.push(legend.value);
      let values = [];
      setDates.forEach(date => {
        // console.log('date : ', date);
        let filterData = chartDatas.chartData.filter(
          chart => chart['X'] === date && !!chart[legend.key]
        );
        if (filterData.length > 0) {
          values.push(filterData[0][legend.key]);
        } else {
          values.push(null);
        }
      });
      // console.log('values : ', values);
      seriesDatas.push({
        key: legend.key,
        name: legend.value,
        color: legend.color,
        data: values
      });
    });

    setRealTimeDistanceChartData({
      date: Array.from(setDates),
      legendData: legendDatas,
      seriesDatas: seriesDatas
    });

    // console.log('makeRealTimeDistanceChartData : ', {
    //   date: Array.from(setDates),
    //   legendData: legendDatas,
    //   seriesDatas: seriesDatas
    // });

    setChartLoading(false);
    console.timeEnd();
  };

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }

    setRealTimeDistanceSearchPeriod(value);
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="실시간 거리 측정 데이터"
        light
        titleTag="h6"
        endEl={
          <Dropdown onSelect={onSelectSearchPeriod}>
            <Dropdown.Toggle variant="falcon-default" size="sm">
              {!!selectedSearchPeriod ? selectedSearchPeriod : '조회 개월 수'}
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item key="1" eventKey="1W">
                1주
              </Dropdown.Item>
              <Dropdown.Item key="2" eventKey="1M">
                1개월
              </Dropdown.Item>
              <Dropdown.Item key="3" eventKey="3M">
                3개월
              </Dropdown.Item>
              <Dropdown.Item key="4" eventKey="6M">
                6개월
              </Dropdown.Item>
              <Dropdown.Item key="5" eventKey="1Y">
                1년
              </Dropdown.Item>
              <Dropdown.Item key="6" eventKey="ALL">
                설치일부터
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
      <Card.Body>
        {/*<Suspense fallback={<h1>Loading profile...</h1>}>*/}
        <RealTimeDistanceChart
          data={realTimeDistanceChartData}
          ref={chartRef}
        />
        {/*</Suspense>*/}
      </Card.Body>
    </Card>
  );
};

export default RealTimeDistance;
