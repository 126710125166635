import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, isEmpty } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';
import { chainAxios } from 'helpers/chainAxios';
import FalconCardHeader from '../../../../common/FalconCardHeader';

const SensorChart = ({ facilityId }) => {
  // const marketShare = [
  //   { id: 1, value: 125, name: 'ON', color: 'primary' },
  //   { id: 2, value: 5, name: 'ERR', color: 'danger' }
  // ];
  const [marketShare, setMarketShare] = useState([]);

  useEffect(() => {
    if (!isEmpty(facilityId)) {
      sensorInfo();
    }
  }, [facilityId]);
  const sensorInfo = () => {
    let url = '/api/facility/' + facilityId + '/sensors';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('SensorChart sensorInfo() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'SensorChart sensorInfo() : ',
          //   response.data.data.sensorList.dataList
          // );
          // let makeShare = new Array();
          // makeShare.push({
          //   id: 1,
          //   name: 'ON',
          //   value: response.data.data.sensorStatus.ON,
          //   color: 'primary'
          // });
          // makeShare.push({
          //   id: 2,
          //   name: 'ERR',
          //   value: response.data.data.sensorStatus.ERR,
          //   color: 'danger'
          // });
          // setMarketShare(makeShare);
          makeSensorChartData(response.data.data.sensorList.dataList);
        }
      })
      .catch(error => {
        console.log('SensorChart sensorInfo() : ', error);
      });
  };

  const makeSensorChartData = sensors => {
    let makeShare = new Array();

    let onCount = 0;
    let offCount = 0;
    let errCount = 0;

    sensors.forEach(sensor => {
      if (sensor.status == 'ON') {
        onCount += 1;
      }
      if (sensor.status == 'OFF') {
        offCount += 1;
      }
      if (sensor.status == 'ERR') {
        errCount += 1;
      }
    });

    makeShare.push({
      id: 1,
      name: 'ON',
      value: onCount,
      color: 'primary'
    });
    makeShare.push({
      id: 2,
      name: 'OFF',
      value: offCount,
      color: 'warning'
    });
    makeShare.push({
      id: 3,
      name: 'ERR',
      value: errCount,
      color: 'danger'
    });

    setMarketShare(makeShare);
  };

  const radius = ['100%', '65%'];

  const getOptions = (data, radius) => ({
    color: [getColor('primary'), getColor('warning'), getColor('danger')],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => {
        // console.log(params);
        return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
          params.data.color
        )}"></div><span class='text-600 fs--1'><strong>${
          params.data.name
        }:</strong> ${params.value}개소</span>`;
      }
    },
    series: [
      {
        name: '센서 현황\r\n' + total + '개소',
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: '0.8333333333rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const total = marketShare.reduce((acc, val) => val.value + acc, 0);

  const SensorItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </p>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <div>{value} 개소</div>
      </Flex>
    );
  };

  return (
    <Card className="h-md-100">
      <FalconCardHeader title="센서 현황" light titleTag="h6" />
      <Card.Body className={'pt-0'}>
        <Row className="justify-content-between g-0">
          <Col xs={5} sm={6} xxl className="pe-2">
            {/*<h6 className="mt-1">Sensors</h6>*/}
            {marketShare.map((item, index) => (
              <SensorItem item={item} index={index} key={index} total={total} />
            ))}
          </Col>
          <Col xs="auto">
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(marketShare, radius)}
                style={{ width: '6.625rem', height: '6.625rem' }}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SensorChart;
