import { chainAxios } from 'helpers/chainAxios';

export const getProjectStatus = async () => {
  let url = 'api/projects/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.projectStatus;
  }
  return [];
};

export const getFacilityStatus = async () => {
  let url = 'api/facility/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.facilityList;
  }
  return [];
};

export const getGatewayStatus = async () => {
  let url = 'api/gateway/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.gatewayStatus;
  }
  return [];
};

export const getSensorStatus = async () => {
  let url = 'api/sensor/status';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.sensorStatus;
  }
  return [];
};

export const getProjectsNoSearch = async () => {
  let url = 'api/projects?pageNo=0&pageSize=5&sort=createDate$desc';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.projectList.dataList;
  }
  return [];
};

export const getDataTraffic = async (startDate, endDate) => {
  let url =
    '/api/sensor/chart/traffic?startDate=' + startDate + '&endDate=' + endDate;
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart;
  }
  return [];
};

export const getSingleMultiAnalysis = async () => {
  let url = 'api/sensor/chart/sawStatus';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.status;
  }
  return [];
};

export const getSingleMultiAnalysis2 = async () => {
  let url = 'api/sensor/chart/sawStatus2';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.status;
  }
  return [];
};
