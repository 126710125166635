export const heatpipeData = {
  type: 'FeatureCollection',
  name: 'heat pipe',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      id: '1',
      type: 'Feature',
      properties: {
        id: 1,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780277, 37.647666],
            [126.79127, 37.639343]
          ]
        ]
      }
    },
    {
      id: '2',
      type: 'Feature',
      properties: {
        id: 2,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.79127, 37.639343],
            [126.792032, 37.64001]
          ]
        ]
      }
    },
    {
      id: '3',
      type: 'Feature',
      properties: {
        id: 3,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.79127, 37.639343],
            [126.790021, 37.638314]
          ]
        ]
      }
    },
    {
      id: '4',
      type: 'Feature',
      properties: {
        id: 4,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790021, 37.638314],
            [126.788775, 37.637356]
          ]
        ]
      }
    },
    {
      id: '5',
      type: 'Feature',
      properties: {
        id: 5,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788775, 37.637356],
            [126.787836, 37.636751]
          ]
        ]
      }
    },
    {
      id: '6',
      type: 'Feature',
      properties: {
        id: 6,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787836, 37.636751],
            [126.776994, 37.644918]
          ]
        ]
      }
    },
    {
      id: '7',
      type: 'Feature',
      properties: {
        id: 7,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780423, 37.642335],
            [126.783719, 37.64506]
          ]
        ]
      }
    },
    {
      id: '8',
      type: 'Feature',
      properties: {
        id: 8,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783719, 37.64506],
            [126.784477, 37.64571]
          ]
        ]
      }
    },
    {
      id: '9',
      type: 'Feature',
      properties: {
        id: 9,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783864, 37.639743],
            [126.787123, 37.642483]
          ]
        ]
      }
    },
    {
      id: '10',
      type: 'Feature',
      properties: {
        id: 10,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787123, 37.642483],
            [126.787901, 37.64314]
          ]
        ]
      }
    },
    {
      id: '11',
      type: 'Feature',
      properties: {
        id: 11,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789415, 37.641993],
            [126.788624, 37.641346]
          ]
        ]
      }
    },
    {
      id: '12',
      type: 'Feature',
      properties: {
        id: 12,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788624, 37.641346],
            [126.785325, 37.638642]
          ]
        ]
      }
    },
    {
      id: '13',
      type: 'Feature',
      properties: {
        id: 13,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786679, 37.637623],
            [126.789935, 37.640354]
          ]
        ]
      }
    },
    {
      id: '14',
      type: 'Feature',
      properties: {
        id: 14,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789935, 37.640354],
            [126.790724, 37.641001]
          ]
        ]
      }
    },
    {
      id: '15',
      type: 'Feature',
      properties: {
        id: 15,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78884, 37.637406],
            [126.787545, 37.63835]
          ]
        ]
      }
    },
    {
      id: '16',
      type: 'Feature',
      properties: {
        id: 16,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788326, 37.63778],
            [126.790733, 37.63975]
          ]
        ]
      }
    },
    {
      id: '17',
      type: 'Feature',
      properties: {
        id: 17,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790733, 37.63975],
            [126.79149, 37.64042]
          ]
        ]
      }
    },
    {
      id: '18',
      type: 'Feature',
      properties: {
        id: 18,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778026, 37.644141],
            [126.781299, 37.646892]
          ]
        ]
      }
    },
    {
      id: '19',
      type: 'Feature',
      properties: {
        id: 19,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778718, 37.643619],
            [126.782011, 37.646352]
          ]
        ]
      }
    },
    {
      id: '20',
      type: 'Feature',
      properties: {
        id: 20,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779315, 37.643169],
            [126.78262, 37.645892]
          ]
        ]
      }
    },
    {
      id: '21',
      type: 'Feature',
      properties: {
        id: 21,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778907, 37.644882],
            [126.777876, 37.645656]
          ]
        ]
      }
    },
    {
      id: '22',
      type: 'Feature',
      properties: {
        id: 22,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779382, 37.645281],
            [126.778337, 37.646042]
          ]
        ]
      }
    },
    {
      id: '23',
      type: 'Feature',
      properties: {
        id: 23,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780029, 37.645824],
            [126.779013, 37.646608]
          ]
        ]
      }
    },
    {
      id: '24',
      type: 'Feature',
      properties: {
        id: 24,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782388, 37.643959],
            [126.7818, 37.644401]
          ]
        ]
      }
    },
    {
      id: '25',
      type: 'Feature',
      properties: {
        id: 25,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784447, 37.644508],
            [126.785218, 37.645193]
          ]
        ]
      }
    },
    {
      id: '26',
      type: 'Feature',
      properties: {
        id: 26,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784743, 37.644771],
            [126.786749, 37.643269]
          ]
        ]
      }
    },
    {
      id: '27',
      type: 'Feature',
      properties: {
        id: 27,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786442, 37.642999],
            [126.786693, 37.643218]
          ]
        ]
      }
    },
    {
      id: '28',
      type: 'Feature',
      properties: {
        id: 28,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786693, 37.643218],
            [126.7872, 37.643677]
          ]
        ]
      }
    },
    {
      id: '29',
      type: 'Feature',
      properties: {
        id: 29,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78546, 37.643742],
            [126.784539, 37.642965]
          ]
        ]
      }
    },
    {
      id: '30',
      type: 'Feature',
      properties: {
        id: 30,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782388, 37.643959],
            [126.782903, 37.643557]
          ]
        ]
      }
    },
    {
      id: '31',
      type: 'Feature',
      properties: {
        id: 31,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785341, 37.640985],
            [126.784764, 37.641396]
          ]
        ]
      }
    },
    {
      id: '32',
      type: 'Feature',
      properties: {
        id: 32,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78669, 37.64212],
            [126.786244, 37.642426]
          ]
        ]
      }
    },
    {
      id: '33',
      type: 'Feature',
      properties: {
        id: 33,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784253, 37.640069],
            [126.783843, 37.64035]
          ]
        ]
      }
    },
    {
      id: '34',
      type: 'Feature',
      properties: {
        id: 34,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 3
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781686, 37.643378],
            [126.782518, 37.642765]
          ]
        ]
      }
    },
    {
      id: '35',
      type: 'Feature',
      properties: {
        id: 35,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790046, 37.638335],
            [126.788685, 37.639306]
          ]
        ]
      }
    },
    {
      id: '38',
      type: 'Feature',
      properties: {
        id: 38,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787791, 37.641977],
            [126.788571, 37.642632]
          ]
        ]
      }
    },
    {
      id: '39',
      type: 'Feature',
      properties: {
        id: 39,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789311, 37.640827],
            [126.790083, 37.641487]
          ]
        ]
      }
    },
    {
      id: '40',
      type: 'Feature',
      properties: {
        id: 40,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782302, 37.640919],
            [126.783398, 37.641814]
          ]
        ]
      }
    }
  ]
};
