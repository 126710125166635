import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import {
  getColor,
  getSearchPeriodAmount,
  isEmpty,
  toStringByFormatting
} from 'helpers/utils';
import DatePicker from 'react-datepicker';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import ResponsivePagination from 'react-responsive-pagination';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { toast } from 'react-toastify';
import {
  useGeoLogicalCrossSectionImages,
  useSubsidenceMapImages
} from '../hook/useMapsDetail';
import Lightbox from 'react-18-image-lightbox';

const CrossSectionContent = ({ facilityInfo }) => {
  const searchPeriods = [
    {
      code: '1W',
      name: '1주일'
    },
    {
      code: '1M',
      name: '1개월'
    },
    {
      code: '3M',
      name: '3개월'
    },
    {
      code: '6M',
      name: '6개월'
    },
    {
      code: '1Y',
      name: '1년'
    },
    {
      code: 'DIVIDER',
      name: '구분선'
    },
    {
      code: 'USER',
      name: '사용자지정'
    }
  ];
  const columns = [
    {
      accessor: 'targetTime',
      Header: '날짜',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { targetTime } = rowData.row.original;

        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {targetTime}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'file',
      Header: '상세보기',
      headerProps: { className: 'text-900', style: { width: '50px' } },
      Cell: rowData => {
        const { file } = rowData.row.original;
        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '50px' }}
          >
            <IconButton
              variant="falcon-primary"
              className="mb-1"
              icon="search"
              transform="shrink-3"
              size="sm"
              onClick={() => onClickViewImage(file)}
            ></IconButton>
          </div>
        );
      }
    }
  ];

  const {
    data: crossSectionImages,
    setFacilityId,
    setSectionId,
    setParamStartDate,
    setParamEndDate
  } = useGeoLogicalCrossSectionImages();

  const [displayCrossSectionImages, setDisplayCrossSectionImages] = useState(
    []
  );

  const [imageClips, setImageClips] = useState([]);
  const [imageDateClips, setImageDateClips] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [selectedSearchPeriod, setSelectedSearchPriod] = useState({
    code: '1W',
    name: '1주일'
  });
  const [searchStartDate, setSearchStartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const [startDate, setStartDate] = useState(
    Date.parse(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  );
  const [endDate, setEndDate] = useState(
    Date.parse(moment().format('YYYY-MM-DD'))
  );

  const [isDisable, setIsDisable] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [viewImage, setViewImage] = useState(null);

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 10;

  useEffect(() => {
    setFacilityId(facilityInfo.id);
    if (!isEmpty(facilityInfo.sections)) {
      setSectionId(facilityInfo.sections[0].id);
    } else {
      setSectionId('EMPTY');

      toast.error('시설물에 Section정보가 없습니다.', {
        theme: 'colored'
      });
    }
  }, [facilityInfo]);

  useEffect(() => {
    if (!isEmpty(selectedSearchPeriod)) {
      onSelectSearchPeriod(selectedSearchPeriod);
    }
  }, [selectedSearchPeriod]);

  useEffect(() => {
    if (crossSectionImages.length > 0) {
      let totalElements = crossSectionImages.length;
      setTotalPages(Math.ceil(totalElements / _pageSize));
      setTotalElements(totalElements);
      makePagingData(crossSectionImages);

      setImageClips([
        process.env.REACT_APP_API_SERVER +
          'api/file/' +
          crossSectionImages[crossSectionImages.length - 1].file.key
      ]);
      setImageDateClips([
        crossSectionImages[crossSectionImages.length - 1].targetTime
      ]);
    } else {
      setTotalPages(1);
      setTotalElements(0);
      setDisplayCrossSectionImages([]);
    }
  }, [crossSectionImages]);

  useEffect(() => {
    if (!isEmpty(crossSectionImages)) {
      makePagingData(crossSectionImages);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!isEmpty(imageClips)) {
      const interval = setInterval(() => {
        setCurrentImageIndex(prevIndex => {
          const nextIndex = prevIndex + 1;
          if (nextIndex === imageClips.length) {
            clearInterval(interval); // 모든 이미지가 보여지면 interval 종료
            return prevIndex; // 현재 인덱스를 유지하여 이미지가 고정되게 함
          }
          return nextIndex;
        });
      }, 700);

      return () => clearInterval(interval);
    }
  }, [imageClips]);

  const makePagingData = mapImages => {
    if (mapImages === undefined) {
      return;
    }

    let startIndex = (currentPage - 1) * _pageSize;
    let endIndex = currentPage * _pageSize;

    if (endIndex >= mapImages.length) {
      endIndex = mapImages.length;
    }

    let displayValues = [];

    for (startIndex; startIndex < endIndex; startIndex += 1) {
      displayValues.push(mapImages[startIndex]);
    }

    setDisplayCrossSectionImages(displayValues);
  };

  const onSelectSearchPeriod = value => {
    if (value.code === 'USER') {
      setIsDisable(false);
    } else {
      let amount = getSearchPeriodAmount(value.code);
      let pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
      let pEndDate = moment().format('YYYY-MM-DD');

      setSearchStartDate(pStartDate);
      setSearchEndDate(pEndDate);

      setParamStartDate(pStartDate);
      setParamEndDate(pEndDate);

      setStartDate(Date.parse(pStartDate));
      setEndDate(Date.parse(pEndDate));

      setIsDisable(true);
    }
  };

  const SearchCustom = () => {
    if (startDate > endDate) {
      toast.error(`조회 시작일이 조회 종료일보다 큽니다.`, {
        theme: 'colored'
      });
    } else {
      setSearchStartDate(toStringByFormatting(new Date(startDate)));
      setSearchEndDate(toStringByFormatting(new Date(endDate)));

      // setParamStartDate(toStringByFormatting(new Date(startDate)));
      // setParamEndDate(toStringByFormatting(new Date(endDate)));
    }
  };

  const onClickViewImage = file => {
    setViewImage(process.env.REACT_APP_API_SERVER + 'api/file/' + file.key);
    setIsOpen(true);
  };

  const onClickShowPeriodSimulation = () => {
    setCurrentImageIndex(0);
    setImageClips([]);
    setImageDateClips([]);

    if (crossSectionImages.length === 0) {
      toast.error('조회 된 지질단면도가 없습니다.', {
        theme: 'colored'
      });
      return;
    }

    const makeImages = Array.from({ length: crossSectionImages.length });
    const makeImageDate = Array.from({ length: crossSectionImages.length });

    crossSectionImages.sort((a, b) => {
      if (a.targetTime > b.targetTime) return 1;
      if (a.targetTime < b.targetTime) return -1;
    });

    crossSectionImages.forEach((crossSectionImage, index) => {
      // console.log(crossSectionImage);
      makeImages[index] =
        process.env.REACT_APP_API_SERVER +
        'api/file/' +
        crossSectionImage.file.key;
      makeImageDate[index] = crossSectionImage.targetTime;
    });

    setImageClips(makeImages);
    setImageDateClips(makeImageDate);
  };

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={viewImage}
          onCloseRequest={() => setIsOpen(false)}
          reactModalStyle={{ overlay: { zIndex: 999999 } }}
          onImageLoad={() => {
            window.dispatchEvent(new Event('resize'));
          }}
        />
      )}
      <Row
        as={Flex}
        alignItems="center"
        justifyContent="end"
        className={'px-3 pt-3'}
        style={{ backgroundColor: getColor('gray-100') }}
      >
        <Row as={Flex} alignItems="center" justifyContent="end">
          <Col
            xs={12}
            xxl={6}
            className={'pb-3'}
            as={Flex}
            alignItems="center"
            alignContent={'center'}
            justifyContent="end"
          >
            <Dropdown className={'me-3'}>
              <Dropdown.Toggle variant="falcon-default" size="sm">
                {!isEmpty(selectedSearchPeriod)
                  ? selectedSearchPeriod.name
                  : '조회 개월 수'}
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                {searchPeriods.map((period, index) =>
                  period.code === 'DIVIDER' ? (
                    <Dropdown.Divider key={index} />
                  ) : (
                    <Dropdown.Item
                      key={index}
                      onClick={() => setSelectedSearchPriod(period)}
                    >
                      {period.name}
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
            <DatePicker
              showIcon
              selected={startDate}
              disabled={isDisable}
              onChange={date => setStartDate(date)}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
            <h6 className={'mx-2'}>~</h6>
            <DatePicker
              showIcon
              selected={endDate}
              disabled={isDisable}
              onChange={date => setEndDate(date)}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
            <IconButton
              variant="falcon-default"
              // size="sm"
              icon="search"
              className="me-1 mb-2 mb-sm-0 ms-3"
              iconClassName="me-1"
              disabled={isDisable}
              onClick={() => SearchCustom()}
            />
          </Col>
        </Row>
      </Row>
      <Row className="g-3">
        <Col xs={12} md={3}>
          <Card className="h-100">
            <Card.Body className={'pb-3'}>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="start"
                className={'px-3'}
              >
                <Flex
                  alignItems="end"
                  alignContent={'end'}
                  direction="column"
                  justifyContent="start"
                  className={'fs--1'}
                >
                  총 {totalElements}건
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={displayCrossSectionImages}
                sortable
                pagination
                perPage={_pageSize}
              >
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle text-center"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    hover: true,
                    className: 'fs--1 mb-0 overflow-hidden text-center'
                  }}
                />
              </AdvanceTableWrapper>
              {displayCrossSectionImages.length === 0 && (
                <div
                  className={'pt-5'}
                  style={{
                    height: (_pageSize * 57.5).toString() + 'px'
                  }}
                >
                  <h6 className={'text-center'}>데이터가 없습니다.</h6>
                </div>
              )}
              {displayCrossSectionImages.length !== 0 &&
                displayCrossSectionImages.length !== _pageSize && (
                  <div
                    style={{
                      height:
                        (
                          (_pageSize - displayCrossSectionImages.length) *
                          57.5
                        ).toString() + 'px'
                    }}
                  ></div>
                )}
              <div className="mt-3">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                  maxWidth={350}
                  extraClassName={'justify-content-center fs--2'}
                  previousLabel="‹"
                  nextLabel="›"
                  // narrowBehaviour={combine(dropNav, dropEllipsis)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={9}>
          <Card className="h-100">
            <FalconCardHeader
              title="지질단면도 시뮬레이션"
              light
              titleTag="h6"
            />
            <Card.Body className={'p-0'}>
              <Row className="g-0 p-3 pt-4">
                {!isEmpty(imageClips) && imageClips.length > 0 && (
                  <img
                    src={imageClips[currentImageIndex]}
                    alt=""
                    style={{
                      maxHeight: (_pageSize * 57.5).toString() + 'px',
                      objectFit: 'contain'
                    }}
                  />
                )}
                <div
                  style={{
                    position: 'absolute',
                    top: '64px',
                    right: '16px',
                    zIndex: '1'
                  }}
                >
                  <Flex
                    direction={'row'}
                    justifyContent={'end'}
                    className={'pb-1'}
                  >
                    <Button
                      size={'sm'}
                      // disabled={!isEmpty(imageClipLayers)}
                      onClick={() => onClickShowPeriodSimulation()}
                    >
                      시뮬레이션
                    </Button>
                  </Flex>
                  <Flex
                    direction={'row'}
                    justifyContent={'center'}
                    className={'pb-1'}
                  >
                    <h5>
                      <strong>{imageDateClips[currentImageIndex]}</strong>
                    </h5>
                  </Flex>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CrossSectionContent;
