import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import {
  getColor,
  getSearchPeriodAmount,
  isEmpty,
  toStringByFormatting
} from 'helpers/utils';
import DatePicker from 'react-datepicker';
import IconButton from 'components/common/IconButton';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import ResponsivePagination from 'react-responsive-pagination';
import Lightbox from 'react-18-image-lightbox';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Map, {
  NavigationControl,
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
  Source,
  Layer,
  Marker,
  Popup
} from 'react-map-gl';
import AppContext from 'context/Context';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { chainAxios } from 'helpers/chainAxios';
import { useSubsidenceMapImages } from '../hook/useMapsDetail';

const buildingLayerStyle = {
  id: '3d-buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 15,
  paint: {
    'fill-extrusion-color': '#aaa', // 건물 색상
    'fill-extrusion-height': ['get', 'height'], // 건물 높이
    'fill-extrusion-base': ['get', 'min_height'], // 건물의 최소 높이
    'fill-extrusion-opacity': 0.6 // 건물 투명도
  }
};

const SubsidenceMapContent = ({ facilityInfo }) => {
  const columns = [
    {
      accessor: 'targetTime',
      Header: '날짜',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { targetTime } = rowData.row.original;

        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {targetTime}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'file',
      Header: '상세보기',
      headerProps: { className: 'text-900', style: { width: '50px' } },
      Cell: rowData => {
        const { file } = rowData.row.original;
        if (file != null) {
          return (
            <div
              className={'fs--1 text-truncate text-center'}
              style={{ width: '50px' }}
            >
              <IconButton
                variant="falcon-primary"
                className="mb-1"
                icon="search"
                transform="shrink-3"
                size="sm"
                onClick={() => onClickViewImage(file)}
              ></IconButton>
            </div>
          );
        }
      }
    }
  ];

  const {
    config: { isDark }
  } = useContext(AppContext);

  const {
    data: subsidenceMapImages,
    facilityId,
    setFacilityId,
    setParamStartDate,
    setParamEndDate
  } = useSubsidenceMapImages();
  const [displaySubsidenceMapImages, setDisplaySubsidenceMapImages] = useState(
    []
  );

  const mapContainerRef = useRef(null);

  const [mapInstance, setMapInstance] = useState(null);

  const [mapStyle, setMapStyle] = useState(
    'mapbox://styles/mapbox/streets-v12'
  );

  const [selectedDisplayType, setSelectedDisplayType] =
    useState('실시간변화량');
  const [selectedDisplayTypeValue, setSelectedDisplayTypeValue] =
    useState('REALTIME');

  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('1주일');
  const [searchStartDate, setSearchStartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const [isDisable, setIsDisable] = useState(true);

  const [startDate, setStartDate] = useState(
    Date.parse(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  );
  const [endDate, setEndDate] = useState(
    Date.parse(moment().format('YYYY-MM-DD'))
  );

  const [facilitySensors, setFacilitySensors] = useState({});
  const [facilityPolygons, setFacilityPolygons] = useState({});

  const [subsidenceMaps, setSubsidenceMaps] = useState([]);

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 10;

  const [isOpen, setIsOpen] = useState(false);
  const [viewImage, setViewImage] = useState(null);
  const [imageClips, setImageClips] = useState([]);
  const [imageClipLayers, setImageClipLayers] = useState([]);
  const [imageDateClips, setImageDateClips] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSimulation, setIsSimulation] = useState(false);

  useEffect(() => {
    setFacilityId(facilityInfo.id);
    getFacilitySensors();
  }, []);

  useEffect(() => {
    if (!isEmpty(facilityInfo)) {
      makeFacilityPolygons();

      mapContainerRef.current?.flyTo({
        center: [
          facilityInfo.location.longitude,
          facilityInfo.location.latitude
        ],
        duration: 2000
      });
    }
  }, [facilityInfo]);

  useEffect(() => {
    if (subsidenceMapImages.length > 0) {
      subsidenceMapImages.sort((a, b) => {
        if (a.targetTime > b.targetTime) return -1;
        if (a.targetTime < b.targetTime) return 1;
      });

      let totalElements = subsidenceMapImages.length;
      setTotalPages(Math.ceil(totalElements / _pageSize));
      setTotalElements(totalElements);
      makePagingData(subsidenceMapImages);
    } else {
      setTotalPages(1);
      setTotalElements(0);
      setDisplaySubsidenceMapImages([]);
    }
  }, [subsidenceMapImages]);

  useEffect(() => {
    if (!isEmpty(subsidenceMapImages)) {
      makePagingData(subsidenceMapImages);
    }
  }, [currentPage]);

  useEffect(() => {
    if (isDark) {
      setMapStyle(`mapbox://styles/mapbox/dark-v11`);
    } else {
      setMapStyle(`mapbox://styles/mapbox/streets-v12`);
    }
  }, [isDark]);
  // 이미지 변경을 위한 타이머 설정
  useEffect(() => {
    console.log('imageClips : ', imageClips.length);
    if (imageClips.length > 0) {
      // ShowAnimateSubsidence();
      MakeImageClipsLayers();
    } // 3초마다 이미지 변경
  }, [imageClips]);

  useEffect(() => {
    console.log('imageClipLayers : ', imageClipLayers.length);
    if (imageClipLayers.length > 0) {
      ShowAnimateSubsidence();
    }
  }, [imageClipLayers]);

  const MakeImageClipsLayers = () => {
    let imageLayers = [];

    imageClips.forEach((imageClip, index) => {
      let imageLayer = {
        id: `image-layer-${index}`,
        type: 'raster', // Layer 유형 (raster, fill, line, symbol 등)
        source: {
          type: 'image', // Layer의 데이터 소스 유형
          url: imageClip.image, // 이미지 URL
          coordinates: imageClip.bbox
        },
        layout: { visibility: 'none' },
        paint: { 'raster-opacity': 0.5 }
      };

      // mapContainerRef.current.getMap().addLayer(imageLayer);
      mapInstance.addLayer(imageLayer);
      imageLayers.push(imageLayer);
    });

    if (mapInstance.getLayer('3d-buildings')) {
      mapInstance.removeLayer('3d-buildings'); // ID가 'my-layer'인 레이어 제거
    }
    if (mapInstance.getSource('3d-buildings')) {
      mapInstance.removeSource('3d-buildings'); // ID가 'my-source'인 소스 제거
    }

    mapInstance.addLayer(buildingLayerStyle);
    setImageClipLayers(imageLayers);
    // mapContainerRef.current.getMap().addLayer({
    //   id: 'image-layer',
    //   type: 'raster', // Layer 유형 (raster, fill, line, symbol 등)
    //   source: {
    //     type: 'image', // Layer의 데이터 소스 유형
    //     url: imageClips[0], // 이미지 URL
    //     coordinates: bbox
    //   },
    //   layout: { visibility: 'none' },
    //   paint: { 'raster-opacity': 0.5 }
    // });
  };

  const makePagingData = mapImages => {
    if (mapImages === undefined) {
      return;
    }

    let startIndex = (currentPage - 1) * _pageSize;
    let endIndex = currentPage * _pageSize;

    if (endIndex >= mapImages.length) {
      endIndex = mapImages.length;
    }

    let displayValues = [];

    for (startIndex; startIndex < endIndex; startIndex += 1) {
      displayValues.push(mapImages[startIndex]);
    }

    setDisplaySubsidenceMapImages(displayValues);
  };

  let animationIndex = 0;

  const ShowAnimateSubsidence = () => {
    console.log('ShowAnimateLayers : ', animationIndex);
    setCurrentImageIndex(animationIndex);

    imageClipLayers.forEach((element, index, array) => {
      if (index === animationIndex) {
        let layerId = 'image-layer-' + index;
        mapInstance.setLayoutProperty(layerId, 'visibility', 'visible');
        // map.setPaintProperty(layerId, 'raster-opacity', 0.5);
      }
    });

    animationIndex++;

    let animateTimeid = setTimeout(ShowAnimateSubsidence, 500);
    if (animationIndex >= imageClips.length) {
      animationIndex = 0;
      setIsSimulation(false);
      // imageClipLayers.forEach((element, index, array) => {
      //   let layerId = 'image-layer-' + index;
      //   if (mapInstance.getLayer(layerId)) {
      //     mapInstance.removeLayer(layerId); // ID가 'my-layer'인 레이어 제거
      //   }
      //   if (mapInstance.getSource(layerId)) {
      //     mapInstance.removeSource(layerId); // ID가 'my-source'인 소스 제거
      //   }
      // });
      //
      // setImageClipLayers([]);
      clearTimeout(animateTimeid);
    }
  };

  const onClickViewImage = file => {
    setViewImage(process.env.REACT_APP_API_SERVER + 'api/file/' + file.key);
    setIsOpen(true);
  };

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }
    if (value === 'USER') {
      setSelectedSearchPriod('사용자지정');
      setIsDisable(false);
    }

    if (value !== 'USER') {
      let amount = getSearchPeriodAmount(value);
      let pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
      let pEndDate = moment().format('YYYY-MM-DD');

      setSearchStartDate(pStartDate);
      setSearchEndDate(pEndDate);

      setParamStartDate(pStartDate);
      setParamEndDate(pEndDate);

      setStartDate(Date.parse(pStartDate));
      setEndDate(Date.parse(pEndDate));

      setIsDisable(true);
    }
  };

  const onSelectDisplayType = value => {
    if (value === 'REALTIME') {
      setSelectedDisplayType('실시간변화량');
    }
    if (value === 'CONSOLIDATION') {
      setSelectedDisplayType('압밀변화량');
    }
    if (value === 'INDEX') {
      setSelectedDisplayType('지표변화량');
    }

    setSelectedDisplayTypeValue(value);
  };

  const onRender = useCallback(e => {
    // https://github.com/mapbox/mapbox-gl-language/?tab=readme-ov-file#setlanguage
    e.target.addControl(new MapboxLanguage({ defaultLanguage: 'ko' }));
  }, []);

  const SearchCustom = () => {
    if (startDate > endDate) {
      toast.error(`조회 시작일이 조회 종료일보다 큽니다.`, {
        theme: 'colored'
      });
    } else {
      setSearchStartDate(toStringByFormatting(new Date(startDate)));
      setSearchEndDate(toStringByFormatting(new Date(endDate)));

      setParamStartDate(toStringByFormatting(new Date(startDate)));
      setParamEndDate(toStringByFormatting(new Date(endDate)));
    }
  };

  const getFacilitySensors = () => {
    // http://112.175.18.232:7000/api/facility/19274ac9-6dcd-4d2e-8454-dd33731d5803/sensors?pageNo=0&pageSize=20&sort=createDate%24desc
    let url =
      '/api/facility/' +
      facilityInfo.id +
      '/sensors?pageNo=0&pageSize=9999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getSensors : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getSensors : ', response.data.data.sensorList.dataList);
          setFacilitySensors(response.data.data.sensorList.dataList);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const makeFacilityPolygons = () => {
    let polygons = [];

    if (facilityInfo.boundary === null) {
      return;
    }
    // console.log('MakeFacilitiesBoundary : ', facilitie.boundary);
    let lineCoordinates = [];

    facilityInfo.boundary.forEach(b => {
      lineCoordinates.push([b.longitude, b.latitude]);
    });

    polygons.push({
      id: facilityInfo.id,
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [lineCoordinates]
      }
    });

    // console.log({ type: 'FeatureCollection', features: polygons });
    setFacilityPolygons({ type: 'FeatureCollection', features: polygons });
  };

  const ShowPeriodSimulation = () => {
    imageClipLayers.forEach((element, index, array) => {
      let layerId = 'image-layer-' + index;
      if (mapInstance.getLayer(layerId)) {
        mapInstance.removeLayer(layerId); // ID가 'my-layer'인 레이어 제거
      }
      if (mapInstance.getSource(layerId)) {
        mapInstance.removeSource(layerId); // ID가 'my-source'인 소스 제거
      }
    });

    setImageClipLayers([]);

    setImageClips([]);
    setImageDateClips([]);

    setCurrentImageIndex(0);

    if (subsidenceMapImages.length === 0) {
      toast.error('조회 된 침하지도가 없습니다.', {
        theme: 'colored'
      });
      return;
    }

    if (selectedDisplayTypeValue === 'REALTIME') {
      MakePeriodImages(subsidenceMapImages.length, [0, 0, 500, 500]);
    }
    if (selectedDisplayTypeValue === 'CONSOLIDATION') {
      MakePeriodImages(subsidenceMapImages.length, [500, 0, 1000, 500]);
    }
    if (selectedDisplayTypeValue === 'INDEX') {
      MakePeriodImages(subsidenceMapImages.length, [1000, 0, 1500, 500]);
    }
  };

  const MakePeriodImages = (period, size) => {
    // [126.7895595285683, 37.638370118511574, 126.79048559483618, 37.63898375651078]
    // const bbox = [
    //   [126.7895595285683, 37.638370118511574],
    //   [126.79048559483618, 37.638370118511574],
    //   [126.79048559483618, 37.63898375651078],
    //   [126.7895595285683, 37.63898375651078]
    // ];

    setIsSimulation(true);

    const makeImages = Array.from({ length: period });
    const makeImagesDate = Array.from({ length: period });

    subsidenceMapImages.sort((a, b) => {
      if (a.targetTime > b.targetTime) return 1;
      if (a.targetTime < b.targetTime) return -1;
    });

    subsidenceMapImages.forEach((img, index) => {
      const bbox = [
        [img.bbox[0], img.bbox[1]],
        [img.bbox[2], img.bbox[1]],
        [img.bbox[2], img.bbox[3]],
        [img.bbox[0], img.bbox[3]]
      ];

      let clippingExtent = size;
      // 클리핑 영역을 지정하여 Canvas를 생성합니다.
      let canvas = document.createElement('canvas');
      canvas.width = 500;
      canvas.height = 500;
      let ctx = canvas.getContext('2d');
      let fileExtent = img.file.value.split('.');
      let image = new Image();
      // console.log(fileExtent[1]);
      // console.log('data:image/' + fileExtent[1] + ';base64,' + img.content);
      image.src = 'data:image/' + fileExtent[1] + ';base64,' + img.content;
      // 이미지가 로드된 후 실행될 함수를 정의합니다.
      image.onload = async function () {
        // // 이미지를 좌우로 대칭시킵니다.
        ctx.scale(1, -1);
        // // 이미지를 90도 회전시킵니다.
        // ctx.translate(image.width / 2, image.height / 2); // 회전 중심을 이미지의 중심으로 이동합니다.
        // ctx.rotate(Math.PI / 2); // 90도를 라디안으로 변환하여 이미지를 회전시킵니다.

        await ctx.drawImage(
          image,
          clippingExtent[0],
          clippingExtent[1],
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          -canvas.height
        ); // 이미지의 너비만큼 이동하여 좌우로 대칭된 이미지를 그립니다.

        // 클리핑된 이미지를 데이터 URI로 변환합니다.
        let clippedImageURI = canvas.toDataURL();
        // console.log('clippedImageURI : ', clippedImageURI);
        makeImages[index] = { image: clippedImageURI, bbox: bbox };
        makeImagesDate[index] = img.targetTime;

        if (index === period - 1) {
          setImageClips(makeImages);
          setImageDateClips(makeImagesDate);
        }
      };
    });
  };

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={viewImage}
          onCloseRequest={() => setIsOpen(false)}
          reactModalStyle={{ overlay: { zIndex: 999999 } }}
          onImageLoad={() => {
            window.dispatchEvent(new Event('resize'));
          }}
        />
      )}
      <Row
        as={Flex}
        alignItems="center"
        justifyContent="end"
        className={'px-3 pt-3'}
        style={{ backgroundColor: getColor('gray-100') }}
      >
        <Row as={Flex} alignItems="center" justifyContent="end">
          <Col
            xs={12}
            xxl={6}
            className={'pb-3'}
            as={Flex}
            alignItems="center"
            alignContent={'center'}
            justifyContent="end"
          >
            <Dropdown onSelect={onSelectSearchPeriod} className={'me-3'}>
              <Dropdown.Toggle variant="falcon-default" size="sm">
                {!isEmpty(selectedSearchPeriod)
                  ? selectedSearchPeriod
                  : '조회 개월 수'}
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item key="1" eventKey="1W">
                  1주일
                </Dropdown.Item>
                <Dropdown.Item key="2" eventKey="1M">
                  1개월
                </Dropdown.Item>
                <Dropdown.Item key="3" eventKey="3M">
                  3개월
                </Dropdown.Item>
                <Dropdown.Item key="4" eventKey="6M">
                  6개월
                </Dropdown.Item>
                <Dropdown.Item key="5" eventKey="1Y">
                  1년
                </Dropdown.Item>
                <Dropdown.Item key="6" eventKey="ALL">
                  설치일부터
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item key="7" eventKey="USER">
                  사용자지정
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <DatePicker
              showIcon
              selected={startDate}
              disabled={isDisable}
              onChange={date => setStartDate(date)}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
            <h6 className={'mx-2'}>~</h6>
            <DatePicker
              showIcon
              selected={endDate}
              disabled={isDisable}
              onChange={date => setEndDate(date)}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
            <IconButton
              variant="falcon-default"
              // size="sm"
              icon="search"
              className="me-1 mb-2 mb-sm-0 ms-3"
              iconClassName="me-1"
              disabled={isDisable}
              onClick={() => SearchCustom()}
            />
          </Col>
        </Row>
      </Row>
      <Row className="g-3">
        <Col xs={12} md={3}>
          <Card className="h-100">
            <Card.Body className={'pb-3'}>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="start"
                className={'px-3'}
              >
                <Flex
                  alignItems="end"
                  alignContent={'end'}
                  direction="column"
                  justifyContent="start"
                  className={'fs--1'}
                >
                  총 {totalElements}건
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={displaySubsidenceMapImages}
                sortable
                pagination
                perPage={_pageSize}
              >
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle text-center"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    hover: true,
                    className: 'fs--1 mb-0 overflow-hidden text-center'
                  }}
                />
              </AdvanceTableWrapper>
              {displaySubsidenceMapImages.length === 0 && (
                <div
                  className={'pt-5'}
                  style={{
                    height:
                      ((_pageSize - subsidenceMaps.length) * 57.5).toString() +
                      'px'
                  }}
                >
                  <h6 className={'text-center'}>데이터가 없습니다.</h6>
                </div>
              )}
              {displaySubsidenceMapImages.length !== 0 &&
                displaySubsidenceMapImages.length !== _pageSize && (
                  <div
                    style={{
                      height:
                        (
                          (_pageSize - displaySubsidenceMapImages.length) *
                          57.5
                        ).toString() + 'px'
                    }}
                  ></div>
                )}
              <div className="mt-3">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                  maxWidth={350}
                  extraClassName={'justify-content-center fs--2'}
                  previousLabel="‹"
                  nextLabel="›"
                  // narrowBehaviour={combine(dropNav, dropEllipsis)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={9}>
          <Card className="h-100">
            <FalconCardHeader title="침하지도 시뮬레이션" light titleTag="h6" />
            <Card.Body className={'p-0'}>
              <Row className="g-0 p-3 pt-0">
                <Map
                  onRender={onRender}
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                  initialViewState={{
                    longitude: 126.7901852,
                    latitude: 37.6385557,
                    pitch: 45, // 카메라 각도 (기울기)
                    bearing: 0, // 카메라 방향
                    zoom: 19
                  }}
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '776px'
                  }}
                  mapStyle={mapStyle}
                  ref={mapContainerRef}
                  minZoom={18}
                  // terrain={{ source: 'mapbox-dem', exaggeration: 0.1 }}
                  onLoad={() =>
                    setMapInstance(mapContainerRef.current.getMap())
                  }
                >
                  {/*<GeolocateControl position="top-left" />*/}
                  {/*<FullscreenControl position="top-left" />*/}
                  <NavigationControl position="top-left" />
                  <ScaleControl />
                  <Layer {...buildingLayerStyle} />

                  {!isEmpty(facilitySensors) &&
                    facilitySensors.map((sensor, index) => (
                      <Marker
                        key={sensor.id}
                        longitude={sensor.location.longitude}
                        latitude={sensor.location.latitude}
                        anchor="center"
                      >
                        <div
                          // onMouseEnter={() => handleSensorMarkerHover(sensor.id)}
                          // onMouseLeave={handleSensorMarkerLeave}
                          style={{
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%',
                            backgroundColor:
                              sensor.status === 'ON'
                                ? getColor('primary')
                                : sensor.status === 'OFF'
                                ? getColor('warning')
                                : getColor('danger'),
                            opacity: 0.5,
                            cursor: 'default'
                          }}
                        />
                        <h6>{sensor.sensorHole.value}</h6>
                      </Marker>
                    ))}
                  <Source id="polygons" type="geojson" data={facilityPolygons}>
                    <Layer
                      id="polygonLayer"
                      type="fill"
                      paint={{
                        'fill-color': getColor('secondary'),
                        'fill-opacity': 0.3
                      }}
                    />
                    <Layer
                      type="line"
                      paint={{
                        'line-color': getColor('danger'), // 테두리 선 색상
                        'line-width': 2 // 테두리 선 두께
                      }}
                    />
                  </Source>
                  <div
                    style={{
                      position: 'relative',
                      top: '16px',
                      right: '16px',
                      zIndex: '1'
                    }}
                  >
                    <Flex
                      direction={'row'}
                      justifyContent={'end'}
                      className={'pb-1'}
                    >
                      <Dropdown
                        onSelect={onSelectDisplayType}
                        className={'me-3'}
                      >
                        <Dropdown.Toggle
                          variant="falcon-default"
                          size="sm"
                          disabled={isSimulation}
                        >
                          {!!selectedDisplayType
                            ? selectedDisplayType
                            : '표시형식'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-2">
                          <Dropdown.Item key="1" eventKey="REALTIME">
                            실시간변화량
                          </Dropdown.Item>
                          <Dropdown.Item key="2" eventKey="CONSOLIDATION">
                            압밀변화량
                          </Dropdown.Item>
                          <Dropdown.Item key="3" eventKey="INDEX">
                            지표변화량
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Button
                        size={'sm'}
                        disabled={isSimulation}
                        onClick={() => ShowPeriodSimulation()}
                      >
                        시뮬레이션
                      </Button>
                    </Flex>
                    <Flex
                      direction={'row'}
                      justifyContent={'center'}
                      className={'pb-1'}
                    >
                      <h5>
                        <strong>{imageDateClips[currentImageIndex]}</strong>
                      </h5>
                    </Flex>
                  </div>
                </Map>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SubsidenceMapContent;
