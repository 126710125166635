import React, { useState } from 'react';
import { SensorContext } from 'context/Context';
import PropTypes from 'prop-types';

const SensorProvider = ({ children }) => {
  // dkchoi
  const [isOpenSensorCurrentSituation, setIsOpenSensorCurrentSituation] =
    useState(false);
  const [currentSensor, setCurrentSensor] = useState({});

  const value = {
    isOpenSensorCurrentSituation,
    setIsOpenSensorCurrentSituation,
    currentSensor,
    setCurrentSensor
  };

  return (
    <SensorContext.Provider value={value}>{children}</SensorContext.Provider>
  );
};

SensorProvider.propTypes = { children: PropTypes.node.isRequired };

export default SensorProvider;
