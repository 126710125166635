import { Button, Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import React from 'react';
import ModelView from '../index';

const ModelViewModal = ({
  modelViewArgs,
  modelViewModelShow,
  modelViewModelShowEvent
}) => {
  return (
    <Modal
      // dialogClassName="modal-90w"
      size="xl"
      show={modelViewModelShow}
      onHide={modelViewModelShowEvent}
      backdrop="static"
      keyboard={false}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {'3D 모델링 상세보기'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModelView modelViewArgs={modelViewArgs} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn_close"
          variant="secondary"
          onClick={modelViewModelShowEvent}
        >
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModelViewModal;
