import Flex from 'components/common/Flex';
import { ChatContext } from 'context/Context';
import React, { useContext, useState, useEffect } from 'react';
import { Card, Tab } from 'react-bootstrap';
import ChatContent from './content/ChatContent';
import ChatSidebar from './sidebar/ChatSidebar';

import { chainAxios } from 'helpers/chainAxios';

import { SocketContext } from 'context/Context';

const ChatTab = () => {
  const { setIsOpenThreadInfo, setScrollToBottom } = useContext(ChatContext);

  const [hideSidebar, setHideSidebar] = useState(false);
  const [threads, setThreads] = useState([]);
  const [filterStatus, setFilterStatus] = useState('0');
  const [receivedMessage, setReceivedMessage] = useState({});

  const handleSelect = e => {
    // console.log('ChatTab handleSelect e : ', e);
    setHideSidebar(false);
    setIsOpenThreadInfo(false);
    // const thread = threads.find(thread => thread.id === e);
    // setCurrentThread(thread);
    // threadsDispatch({
    //   type: 'EDIT',
    //   id: thread.id,
    //   payload: { ...thread, read: true }
    // });
    setScrollToBottom(true);
  };

  useEffect(() => {
    chatroomsList();
  }, []);

  useEffect(() => {
    chatroomsList();
  }, [filterStatus]);

  useEffect(() => {
    // console.log(stompClient);
  }, [threads]);

  const handleTabChange = value => {
    setFilterStatus(value);
  };

  const chatroomsList = () => {
    let url =
      '/api/chatrooms?pageNo=0&pageSize=20&sort=createDate$desc&filters=status$' +
      filterStatus;
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ChatSidebar chatroomsList() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'chatroomsList() threads : ',
          //   response.data.data.chatroomList.dataList
          // );
          setThreads(response.data.data.chatroomList.dataList);
        }

        if (response.data.resultCode === 404) {
          setThreads([]);
        }
      })
      .catch(error => {
        console.log('ChatSidebar chatroomsList() : ', error);
      });
  };

  const updateLastMessage = msg => {
    let receivedMsg = JSON.parse(msg.body);
    console.log('updateLastMessage() receivedMsg : ', receivedMsg);
    console.log('updateLastMessage() threads : ', threads);
    console.log('updateLastMessage() filterStatus : ', filterStatus);

    if (!!receivedMsg.chatroom) {
      chatroomsList();
      setReceivedMessage(receivedMsg);
    }

    // threads.forEach(thread => {
    //   if (receivedMsg.chatroom.key === thread.id) {
    //     // thread.lastChat.id = receivedMsg.id;
    //     // thread.lastChat.createDate = receivedMsg.createDate;
    //     // thread.lastChat.message = receivedMsg.message;

    //     // thread.lastChat.sender.key = receivedMsg.sender.key;
    //     // thread.lastChat.sender.value = receivedMsg.sender.value;

    //     // console.log('updateLastMessage thread : ', thread);
    //     chatroomsList();
    //   }
    // });
  };

  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="0"
      onSelect={handleSelect}
    >
      <Card className="card-chat overflow-hidden">
        <Card.Body as={Flex} className="p-0 h-100">
          <ChatSidebar
            hideSidebar={hideSidebar}
            handleTabChange={handleTabChange}
            threads={threads}
          />
          <ChatContent
            setHideSidebar={setHideSidebar}
            filterStatus={filterStatus}
            receivedMessage={receivedMessage}
          />
        </Card.Body>
      </Card>
    </Tab.Container>
  );
};

const Chat = () => {
  return <ChatTab />;
};

export default Chat;
