import { useSensorDistance } from '../hook/useManageSensor';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { getChartSeriesColor, isEmpty } from 'helpers/utils';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import RealTimeDistanceChart from '../../../maps/left-area/detail/chart/realtime/RealTimeDistanceChart';
import FalconCardHeader from '../../../common/FalconCardHeader';

const SensorDistance = ({ sensor, searchStartDate, searchEndDate }) => {
  const {
    data: sensorDistanceDatas,
    setSensorId,
    setStartDate,
    setEndDate
  } = useSensorDistance();

  const chartRef = useRef(null);
  const [sensorDistanceChartData, setSensorDistanceChartData] = useState({});

  useEffect(() => {
    if (
      !isEmpty(sensor) &&
      !isEmpty(searchStartDate) &&
      !isEmpty(searchEndDate)
    ) {
      setSensorId(sensor.id);
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    }
  }, [sensor, searchStartDate, searchEndDate]);

  useEffect(() => {
    // console.log(sensorDistanceDatas);
    if (!isEmpty(sensorDistanceDatas)) {
      makeSensorDistanceChartData(sensorDistanceDatas);
    }
  }, [sensorDistanceDatas]);

  const makeSensorDistanceChartData = chartDatas => {
    let orginDates = [];
    let orginValues = [];
    let orginLegend = [];

    // console.log('makeSensorDistanceChartData : ', chartDatas);
    // console.log(Object.keys(chartDatas.legend));
    const seriesColors = getChartSeriesColor();
    chartDatas.forEach(data => {
      orginDates.push(data['x']);
      orginValues.push(data['y1']);
    });

    orginLegend.push({
      key: sensor.sensorHole.value,
      name: sensor.sensorHole.value,
      color: seriesColors[0],
      data: orginValues
    });

    setSensorDistanceChartData({
      date: orginDates,
      legendData: [sensor.sensorHole.value],
      seriesDatas: orginLegend
    });
  };

  return (
    <Card className="h-100">
      <FalconCardHeader title="센서 기간별 측정거리" light titleTag="h6" />
      <Card.Body className="pe-xxl-0">
        {/*<Suspense fallback={<h1>Loading profile...</h1>}>*/}
        <RealTimeDistanceChart
          data={sensorDistanceChartData}
          chartLoading={false}
          ref={chartRef}
        />
        {/*</Suspense>*/}
      </Card.Body>
    </Card>
  );
};

export default SensorDistance;
