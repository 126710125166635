import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ModelView from '../model-view';
import ModelViewTop from '../model-view/component/Top';
const ModelingContent = ({ facilityId }) => {
  return (
    <>
      <Row className="g-3">
        <Col md={12}>
          <Card className="h-100">
            <Card.Body className={'pb-3'} style={{ height: '45rem' }}>
              <ModelViewTop />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ModelingContent;
