import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Container, Button } from 'react-bootstrap';

import LeftArea from './left-area';
import RightArea from './right-area';
import BottomArea from './bottom-area';

import AppContext, { CourseContext } from 'context/Context';

import CenterArea from './center-area';
import LeftToggle from './center-area/toggle/LeftToggle';
import BottomToggle from './center-area/toggle/BottomToggle';
import RightToggle from './center-area/toggle/RightToggle';
import { MapsContext } from 'context/Context';
import MapboxIndex from './center-area/MapboxIndex';

const MapboxMaps = () => {
  const [leftShow, setLeftShow] = useState(true);
  const [rightShow, setRightShow] = useState(true);
  const [buttomShow, setButtomShow] = useState(true);

  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  const { setSelectedProject } = useContext(MapsContext);
  const { setMiniMapSelectedSensor } = useContext(MapsContext);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', true);
    setSelectedProject({});
    setMiniMapSelectedSensor('');

    setLeftShow(true);
    setRightShow(true);

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);
  const LeftAreaShowEvent = () => {
    setLeftShow(!leftShow);
  };

  const RightAreaShowEvent = () => {
    setRightShow(!rightShow);
  };

  const ButtomAreaShowEvent = () => {
    setButtomShow(!buttomShow);
  };

  return (
    <Row className="g-3" style={{ height: '100%' }}>
      {leftShow && (
        <Col xxl={2} md={3} className={'p-0'}>
          <LeftArea LeftAreaShowEvent={LeftAreaShowEvent} />
        </Col>
      )}
      {!leftShow && <LeftToggle LeftAreaShowEvent={LeftAreaShowEvent} />}
      <Col
        id={'map-container'}
        xxl={leftShow && rightShow ? 8 : leftShow || rightShow ? 10 : 12}
        md={leftShow && rightShow ? 6 : leftShow || rightShow ? 9 : 12}
      >
        <MapboxIndex />
        {!buttomShow && (
          <BottomToggle ButtomAreaShowEvent={ButtomAreaShowEvent} />
        )}
        <BottomArea
          ButtomAreaShow={buttomShow}
          ButtomAreaShowEvent={ButtomAreaShowEvent}
        />
      </Col>
      {!rightShow && <RightToggle RightAreaShowEvent={RightAreaShowEvent} />}
      {rightShow && (
        <Col xxl={2} md={3} className={'p-0'}>
          <RightArea RightAreaShowEvent={RightAreaShowEvent} />
        </Col>
      )}
    </Row>
  );
};

export default MapboxMaps;
