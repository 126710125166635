import { Breadcrumb, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import React from 'react';
import IconButton from 'components/common/IconButton';
import { useNavigate } from 'react-router-dom';

const ProjectDetailTopArea = () => {
  const navigate = useNavigate();
  return (
    <Card>
      <Card.Body as={Flex} justifyContent="start" alignItems="center">
        <div className={'pe-2'}>
          <ItemButton
            tootltip="Back to inbox"
            icon="arrow-left"
            onClick={() => {
              navigate('/management-target/projects');
            }}
          />
        </div>
        <Breadcrumb>
          <Breadcrumb.Item
            href="/management-target/projects"
            className={'fs--1'}
          >
            프로젝트
          </Breadcrumb.Item>
          <Breadcrumb.Item className={'fs--1'} active>
            상세보기
          </Breadcrumb.Item>
        </Breadcrumb>
      </Card.Body>
    </Card>
  );
};

const ItemButton = ({ tootltip, icon, className, onClick }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
          {tootltip}
        </Tooltip>
      }
    >
      <div className="d-inline-block">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon={icon}
          className={className}
          onClick={onClick}
        />
      </div>
    </OverlayTrigger>
  );
};

export default ProjectDetailTopArea;
