import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import React from 'react';
import { getColor } from '../../../../../helpers/utils';

const FieldInfo = ({ facility }) => {
  return (
    <Row
      xs={12}
      className={'py-3 border rounded h-100'}
      style={{ backgroundColor: getColor('white') }}
    >
      <Col xs={6} md={3} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>도로명코드</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FCRDCODE}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>건물본번</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FCMNNM}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>건물부번</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FCSLNO}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>작업단계</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDSTATE}
            </div>
          </Col>
        </Flex>
      </Col>
      <Col xs={6} md={3} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>건축면적</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDAREA}{' '}
              m2
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>연면적</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDTOTAREA}{' '}
              m2
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>주용도</div>
            <div className={'fs--1 text-700'}>
              {!!facility && !!facility.properties && facility.properties.FDUSE}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>층수</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDFLOORS}
            </div>
          </Col>
        </Flex>
      </Col>
      <Col xs={6} md={3} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>건축주</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDOWNER}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>건축주 연락처</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDOWNERNUMBER}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>설계자</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDARCHITECT}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>설계자 연락처</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDARCHITECTNUMBER}
            </div>
          </Col>
        </Flex>
      </Col>
      <Col xs={6} md={3} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>감리자</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDSUPERVISOR}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>감리자 연락처</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDOWNERNUMBER}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>시공자</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDCONSTRUCTOR}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>시공자 연락처</div>
            <div className={'fs--1 text-700'}>
              {!!facility &&
                !!facility.properties &&
                facility.properties.FDCONSTRUCTORNUMBER}
            </div>
          </Col>
        </Flex>
      </Col>
    </Row>
  );
};

export default FieldInfo;
