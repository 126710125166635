import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import moment from 'moment/moment';
import { chainAxios } from 'helpers/chainAxios';
import { getDates } from 'helpers/utils';
import RealDataSendChart from './RealDataSendChart';
import FalconCardHeader from '../../../../../common/FalconCardHeader';
import { useRealDataSend } from '../../hook/useMapsDetail';

const RealDataSend = ({ facilityId }) => {
  const {
    data: realDataSendDatas,
    isLoading,
    isFetching,
    setRealDataSendFacilityId,
    setRealDataSendSearchPeriod
  } = useRealDataSend();

  const chartRef = useRef(null);

  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('1주일');
  const [realDataSendChartData, setRealDataSendChartData] = useState([]);

  useEffect(() => {
    if (!!facilityId) {
      setRealDataSendFacilityId(facilityId);
    }
  }, [facilityId]);

  useEffect(() => {
    if (
      !isFetching &&
      !!realDataSendDatas &&
      JSON.stringify(realDataSendDatas) !== '[]'
    ) {
      // console.table(realDataSendDatas);
      makeDataTrafficChartData(realDataSendDatas);
    }
  }, [isFetching]);

  const makeDataTrafficChartData = trafficDatas => {
    // console.log('RealDataSend makeDataTrafficChartData() : ', trafficDatas);
    let chartDates = [];
    let chartAllSendCount = [];
    let chartErrorSendCount = [];

    let sortTrafficDatas = trafficDatas.chartData.sort((a, b) => {
      if (a.x < b.x) return -1;
      if (a.x > b.x) return 1;

      return 0;
    });

    sortTrafficDatas.forEach(data => {
      chartDates.push(data.x);
      chartAllSendCount.push(data.y1);
      chartErrorSendCount.push(data.y2);
    });

    setRealDataSendChartData({
      date: chartDates,
      allSendCount: chartAllSendCount,
      errorSendCount: chartErrorSendCount
    });
  };

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }

    setRealDataSendSearchPeriod(value);
  };

  return (
    <>
      {/*<Card className="h-100">*/}
      <FalconCardHeader
        // title="실시간 데이터 전송"
        light
        titleTag="h6"
        endEl={
          <Dropdown onSelect={onSelectSearchPeriod}>
            <Dropdown.Toggle variant="falcon-default" size="sm">
              {!!selectedSearchPeriod ? selectedSearchPeriod : '조회 개월 수'}
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item key="1" eventKey="1W">
                1주
              </Dropdown.Item>
              <Dropdown.Item key="2" eventKey="1M">
                1개월
              </Dropdown.Item>
              <Dropdown.Item key="3" eventKey="3M">
                3개월
              </Dropdown.Item>
              <Dropdown.Item key="4" eventKey="6M">
                6개월
              </Dropdown.Item>
              <Dropdown.Item key="5" eventKey="1Y">
                1년
              </Dropdown.Item>
              <Dropdown.Item key="6" eventKey="ALL">
                설치일부터
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
      {/*<Card.Body>*/}
      <RealDataSendChart data={realDataSendChartData} ref={chartRef} />
      {/*</Card.Body>*/}
      {/*</Card>*/}
    </>
  );
};

export default RealDataSend;
