import { chainAxios } from 'helpers/chainAxios';

export const getMarkerFacilities = async () => {
  let url = '/api/facilities?pageNo=0&pageSize=99999&sort=createDate%24desc';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.facilityList.dataList;
  }
  return [];
};

export const getMarkerSensors = async () => {
  let url = '/api/sensors?pageNo=0&pageSize=99999&sort=createDate%24desc';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.sensorList.dataList;
  }
  return [];
};

export const getMarkerProjects = async () => {
  let url = '/api/projects?pageNo=0&pageSize=5&sort=createDate$desc';
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.projectList.dataList;
  }
  return [];
};

export const getMarkerBoreHoles = async (minLat, maxLat, minLng, maxLng) => {
  // http://112.175.18.232:7000/api/borehole?startLon=126.78582562739018&startLat=37.63709119898766&endLon=126.7944866992371&endLat=37.644842990135615
  let url =
    '/api/borehole?startLon=' +
    minLng +
    '&startLat=' +
    minLat +
    '&endLon=' +
    maxLng +
    '&endLat=' +
    maxLat;
  // console.log(url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.boreholeList);
    return response.data.data.boreholeList;
  }
  return [];
};
