import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useStatEvent, useStatSensorProcess } from '../../hook/useStatistics';
import SensorProcessSummaryChart from './SensorProcessSummaryChart';
import SensorProcessDetailChart from './SensorProcessDetailChart';
import { isEmpty } from 'helpers/utils';

const SensorProcessStaticstics = ({ searchStartDate, searchEndDate }) => {
  const {
    data: sensorProcessStaticsData,
    isLoading,
    isFetching,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  } = useStatSensorProcess();

  useEffect(() => {
    if (!isEmpty(searchStartDate) && !isEmpty(searchEndDate)) {
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    }
  }, [searchStartDate, searchEndDate]);

  return (
    <Card className="h-100">
      <FalconCardHeader title="센서 점검/조치 통계" light titleTag="h6" />
      <Card.Body>
        <Row className="g-3 h-100">
          <Col lg={4} xs={12}>
            <SensorProcessSummaryChart
              title={'센서 점검/조치 현황'}
              chartData={sensorProcessStaticsData}
            />
          </Col>
          <Col lg={8} xs={12}>
            <SensorProcessDetailChart
              title={'기간별 센서 점검/조치 차트'}
              chartData={sensorProcessStaticsData}
            />
          </Col>
        </Row>
        {/*<RealDataSendChart data={realDataSendChartData} ref={chartRef} />*/}
      </Card.Body>
    </Card>
  );
};

export default SensorProcessStaticstics;
