import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CartNotification from 'components/navbar/top/CartNotification';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import AppContext, {
  ActionContext,
  ChatContext,
  LoginUserContext,
  SensorContext
} from 'context/Context';
import React, { useContext, useState, useEffect } from 'react';
import { Nav, OverlayTrigger, Tooltip, Offcanvas } from 'react-bootstrap';
import NineDotMenu from './NineDotMenu';
import Chatting from 'components/common/chat';
import classNames from 'classnames';
import ChatDetail from 'components/common/chat/content';
import {
  faToggleOn,
  faToggleOff,
  faWindowMinimize,
  faWindowMaximize
} from '@fortawesome/free-solid-svg-icons';
import ActionDetail from '../../event/process/detail';
import SensorCurrentSituation from '../../management-target/sensor/situation';

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL, isFluid, navbarPosition },
    setConfig
  } = useContext(AppContext);

  const { isOpenChatRoom, isAllReadChat, setIsAllReadChat } =
    useContext(ChatContext);

  const { isOpenActionDetail } = useContext(ActionContext);

  const { isOpenSensorCurrentSituation } = useContext(SensorContext);

  const { loginUser } = useContext(LoginUserContext);

  useEffect(() => {
    // console.log('isOpenChatRoom : ', isOpenChatRoom);
    if (!isOpenChatRoom) {
      // console.log('닫음');
    }
  }, [isOpenChatRoom]);

  const [chattingShow, setChattingShow] = useState(false);

  const ChattingAreaShowEvent = () => {
    setChattingShow(!chattingShow);
    setIsAllReadChat(true);
  };

  return (
    <>
      <Nav
        navbar
        className="navbar-nav-icons ms-auto flex-row align-items-center"
        as="ul"
      >
        <Nav.Item as={'li'}>
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={() => setConfig('isFluid', !isFluid)}
          >
            <OverlayTrigger
              key="left"
              placement={isRTL ? 'bottom' : 'left'}
              overlay={
                <Tooltip style={{ position: 'fixed' }} id="ThemeColor">
                  {isFluid ? 'Switch to solid theme' : 'Switch to fluid theme'}
                </Tooltip>
              }
            >
              <div className="theme-control-toggle-label">
                <FontAwesomeIcon
                  icon={isFluid ? faWindowMinimize : faWindowMaximize}
                  className="fs-0"
                />
              </div>
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as={'li'}>
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={() => setConfig('isDark', !isDark)}
          >
            <OverlayTrigger
              key="left"
              placement={isRTL ? 'bottom' : 'left'}
              overlay={
                <Tooltip style={{ position: 'fixed' }} id="ThemeColor">
                  {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
                </Tooltip>
              }
            >
              <div className="theme-control-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="fs-0"
                />
              </div>
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as={'li'} className="dropdown">
          <Nav.Link
            className={classNames('px-0 nav-link', {
              'notification-indicator notification-indicator-primary':
                !isAllReadChat
            })}
            onClick={ChattingAreaShowEvent}
          >
            <FontAwesomeIcon
              icon="comments"
              transform="shrink-6"
              className="fs-4"
            />
          </Nav.Link>
          <Chatting
            chattingShow={chattingShow}
            ChattingAreaShowEvent={ChattingAreaShowEvent}
          />
          {!!isOpenChatRoom && isOpenChatRoom && (
            <ChatDetail ChatDetailShow={isOpenChatRoom} />
          )}
          {!!isOpenActionDetail && isOpenActionDetail && (
            <ActionDetail isActionDetailShow={isOpenActionDetail} />
          )}
          {!!isOpenSensorCurrentSituation && isOpenSensorCurrentSituation && (
            <SensorCurrentSituation
              isSensorCurrentSituationShow={isOpenSensorCurrentSituation}
            />
          )}
        </Nav.Item>
        {/* <CartNotification /> */}
        <NotificationDropdown />
        <div className={'fs--1  '}>
          {loginUser.company} {loginUser.name}님
        </div>
        {/* <NineDotMenu /> */}
        <ProfileDropdown />
      </Nav>
    </>
  );
};

export default TopNavRightSideNavItem;
