import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import RealtimeAngleChart from '../../../maps/left-area/detail/chart/realtime/RealtimeAngleChart';
import React, { useEffect, useRef, useState } from 'react';
import FalconCardHeader from '../../../common/FalconCardHeader';
import { useSensorAngle } from '../hook/useManageSensor';
import { getChartSeriesColor, isEmpty } from '../../../../helpers/utils';

const SensorAngle = ({ sensor, searchStartDate, searchEndDate }) => {
  const {
    data: sensorAngelDatas,
    isLoading,
    isFetching,
    setSensorId,
    setStartDate,
    setEndDate
  } = useSensorAngle();

  const chartRef = useRef(null);
  const [sensorAngleChartData, setSensorAngleChartData] = useState([]);

  useEffect(() => {
    if (
      !isEmpty(sensor) &&
      !isEmpty(searchStartDate) &&
      !isEmpty(searchEndDate)
    ) {
      setSensorId(sensor.id);
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    }
  }, [sensor, searchStartDate, searchEndDate]);

  useEffect(() => {
    // console.log(sensorDistanceDatas);
    if (!isEmpty(sensorAngelDatas)) {
      makeSensorAngleChartData(sensorAngelDatas);
    }
  }, [sensorAngelDatas]);

  const makeSensorAngleChartData = chartDatas => {
    // console.log('makeSensorAngleChartData : ', chartDatas);
    let orginDate = [];
    let orginData = [];
    let orginLegend = [];
    const seriesColors = getChartSeriesColor();

    chartDatas.forEach(data => {
      orginDate.push(data['x']);
      orginData.push({ date: data['x'], value: [data['y1'], data['y2']] });
    });
    orginLegend.push(sensor.sensorHole.value);

    setSensorAngleChartData({
      date: orginDate,
      legendData: orginLegend,
      seriesDatas: [
        {
          color: seriesColors[0],
          name: sensor.sensorHole.value,
          data: orginData
        }
      ]
    });
  };

  return (
    <Card className="h-100">
      <FalconCardHeader title="센서 기간별 측정각도" light titleTag="h6" />
      <Card.Body>
        <RealtimeAngleChart data={sensorAngleChartData} ref={chartRef} />
      </Card.Body>
    </Card>
  );
};

export default SensorAngle;
