import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from 'components/common/Flex';
import FileItem from 'components/common/chat/action/FileItem';
import ComposeAttachment from 'components/app/email/compose/ComposeAttachment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ActionContext } from 'context/Context';
import SoftBadge from '../../../common/SoftBadge';
import { isEmpty } from '../../../../helpers/utils';
import FalconLightBox from '../../../common/FalconLightBox';
import { chainAxios } from '../../../../helpers/chainAxios';

const ActionDetail = ({ isActionDetailShow }) => {
  const inputForm = useRef();

  const { isOpenActionDetail, setIsOpenActionDetail, currentEvent } =
    useContext(ActionContext);

  const [attachmentFiles, setAttachmentFiles] = useState([]);

  const [checkMainCategoryName, setCheckMainCategoryName] = useState('-');
  const [checkSubCategoryName, setCheckSubCategoryName] = useState('-');
  const [actionMainCategoryName, setActionMainCategoryName] = useState('-');
  const [actionSubCategoryName, setActionSubCategoryName] = useState('-');

  useEffect(() => {
    console.log('ActionDetail current event : ', currentEvent);
    if (!isEmpty(currentEvent)) {
      makePathFiles(currentEvent);

      if (currentEvent.actions.length > 0) {
        makeCheckCodeName(currentEvent.actions[0].eventInspectCode);
        makeActionCodeName(currentEvent.actions[0].eventActionCode);
      }
    }
  }, []);

  const onHideEvent = () => {
    setIsOpenActionDetail(!isActionDetailShow);
  };
  const makeEventLevel = eventLevel => {
    let badgeColor;
    let badgeName;
    if (eventLevel === '0') {
      badgeName = '심각';
      badgeColor = 'danger';
    } else if (eventLevel === '1') {
      badgeName = '경고';
      badgeColor = 'warning';
    } else if (eventLevel === '2') {
      badgeName = '주의';
      badgeColor = 'info';
    } else if (eventLevel === '3') {
      badgeName = '관찰';
      badgeColor = 'success';
    } else if (eventLevel === '4') {
      badgeName = '알림';
      badgeColor = 'secondary';
    } else {
      badgeName = '-';
      badgeColor = 'dark';
    }

    return (
      <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
        {badgeName}
      </SoftBadge>
    );
  };
  const makePathFiles = event => {
    if (isEmpty(event.actions[0].files)) {
      return;
    }
    let files = [];
    event.actions[0].files.forEach((file, index) => {
      let url = process.env.REACT_APP_API_SERVER + 'api/file/' + file;
      files.push({ key: file, url: url });
    });

    setAttachmentFiles(files);
  };
  const makeEventContent = content => {
    return content.replaceAll('\r\n', '<br/>');
  };
  const makeActionDate = actions => {
    return actions[0].actionDate;
  };
  const makeActorName = actions => {
    return actions[0].actor.value + '(' + actions[0].actor.key + ')';
  };
  const makeActionContent = actions => {
    return actions[0].actionContent;
  };
  const makeTargetName = () => {
    let targetName = '-';

    if (!isEmpty(currentEvent.facility)) {
      targetName = '시설물 (' + currentEvent.facility.value + ')';
    }

    if (!isEmpty(currentEvent.gateway)) {
      targetName = '게이트웨이 (' + currentEvent.gateway.value + ')';
    }

    if (!isEmpty(currentEvent.sensor)) {
      targetName = '센서 (' + currentEvent.sensor.value + ')';
    }

    return targetName;
  };
  const makeEventType = () => {
    // console.log(currentChatRoom.event.eventCode);
    let eventCode = currentEvent.eventCode;
    let eventTypeName = '-';

    if (!isEmpty(eventCode) && eventCode.length > 5) {
      let eventType = eventCode.substring(0, 5);

      if (
        eventType === 'e0000' ||
        eventType === 'e0100' ||
        eventType === 'e0302' ||
        eventType === 'e0405'
      ) {
        eventTypeName = '인증문제';
      }
      if (eventType === 'e0300' || eventType === 'e0400') {
        eventTypeName = '신호불량';
      }
      if (
        eventType === 'e0301' ||
        eventType === 'e0401' ||
        eventType === 'e0402' ||
        eventType === 'e0403'
      ) {
        eventTypeName = '상태불량';
      }
      if (
        eventType === 'e0200' ||
        eventType === 'e0500' ||
        eventType === 'e0501' ||
        eventType === 'e0502' ||
        eventType === 'e0503'
      ) {
        eventTypeName = '위험예측';
      }
    }

    return eventTypeName;
  };
  const makeCheckCodeName = inspectCode => {
    console.log(inspectCode);
    if (isEmpty(inspectCode)) {
      return;
    }

    getCheckMainCategoryName(inspectCode);
    getCheckSubCategoryName(inspectCode);
  };

  const makeActionCodeName = actionCode => {
    console.log(actionCode);
    if (isEmpty(actionCode)) {
      return;
    }

    getActionMainCategoryName(actionCode);
    getActionSubCategoryName(actionCode);
  };
  const getCheckMainCategoryName = eventCode => {
    let depth1Code = eventCode.substring(0, 3);
    let url = '/api/codes/depth2?depth1=' + depth1Code + '&sort=code%24asc';
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          let filter = response.data.data.codeList.filter(
            a => a.code === eventCode.substring(0, 5)
          );
          if (filter.length > 0) {
            setCheckMainCategoryName(filter[0].name);
          }
        }
      })
      .catch(error => {
        console.log('getCheckMainCategoryName ERROR : ', error);
      });
  };
  const getActionMainCategoryName = eventCode => {
    let depth1Code = eventCode.substring(0, 3);
    let url = '/api/codes/depth2?depth1=' + depth1Code + '&sort=code%24asc';
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          let filter = response.data.data.codeList.filter(
            a => a.code === eventCode.substring(0, 5)
          );
          if (filter.length > 0) {
            setActionMainCategoryName(filter[0].name);
          }
        }
      })
      .catch(error => {
        console.log('getActionMainCategoryName ERROR : ', error);
      });
  };
  const getCheckSubCategoryName = eventCode => {
    let depth2Code = eventCode.substring(0, 5);
    // http://112.175.18.232:7000/api/codes/depth3?depth2=c0400&sort=code%24asc
    let url = '/api/codes/depth3?depth2=' + depth2Code + '&sort=code%24asc';
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          let filter = response.data.data.codeList.filter(
            a => a.code === eventCode
          );
          if (filter.length > 0) {
            setCheckSubCategoryName(filter[0].name);
          }
          // setCheckSubCategory(response.data.data.codeList);
        }
      })
      .catch(error => {
        console.log('getCheckSubCategoryName ERROR : ', error);
      });
  };
  const getActionSubCategoryName = eventCode => {
    let depth2Code = eventCode.substring(0, 5);
    // http://112.175.18.232:7000/api/codes/depth3?depth2=c0400&sort=code%24asc
    let url = '/api/codes/depth3?depth2=' + depth2Code + '&sort=code%24asc';
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          let filter = response.data.data.codeList.filter(
            a => a.code === eventCode
          );
          if (filter.length > 0) {
            setActionSubCategoryName(filter[0].name);
          }
        }
      })
      .catch(error => {
        console.log('getActionSubCategoryName ERROR : ', error);
      });
  };

  return (
    <Modal
      // dialogClassName="modal-70w"
      size="lg"
      show={isActionDetailShow}
      onHide={onHideEvent}
      backdrop="static"
      keyboard={false}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h4>조치/점검내용</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={inputForm} className={'px-1'}>
          <Form.Group
            as={Row}
            className="mb-2 border-bottom"
            controlId="formBaseInfo"
          >
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              프로젝트
            </Form.Label>
            <Col md={'10'} xs={'8'} className={'py-1'}>
              <div className={'fs--1 fw-normal text-800'}>
                {currentEvent.project.value}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              발생일시
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1'}>
              <div className={'fs--1 fw-normal text-800'}>
                {currentEvent.createDate}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              발생위치
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1'}>
              <div className={'fs--1 fw-normal text-800'}>
                {makeTargetName()}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              이벤트 분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1'}>
              <div className={'fs--1 fw-normal text-800'}>
                {makeEventType()}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              심각도
            </Form.Label>
            <Col md={'4'} xs={'8'}>
              {makeEventLevel(currentEvent.eventLevel)}
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              이벤트 내용
            </Form.Label>
            <Col md={'10'} xs={'8'} className={'py-1 mb-2'}>
              <div className={'fs--1 fw-normal text-800'}>
                {makeEventContent(currentEvent.content)}
              </div>
            </Col>
            {/*<Form.Label*/}
            {/*  column*/}
            {/*  md={'2'}*/}
            {/*  xs={'4'}*/}
            {/*  className={'fs--1 fw-semi-bold text-700'}*/}
            {/*>*/}
            {/*  참가자*/}
            {/*</Form.Label>*/}
            {/*<Col md={'10'} xs={'8'}>*/}
            {/*  <Form.Control*/}
            {/*    plaintext*/}
            {/*    readOnly*/}
            {/*    defaultValue={makeChatRoomMembers(currentEvent.members)}*/}
            {/*    className={'fs--1 fw-normal text-800'}*/}
            {/*  />*/}
            {/*</Col>*/}
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formEventActions">
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              점검일자
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1 mb-2'}>
              <div className={'fs--1 fw-normal text-800'}>
                {makeActionDate(currentEvent.actions)}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              점검자
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1 mb-2'}>
              <div className={'fs--1 fw-normal text-800'}>
                {makeActorName(currentEvent.actions)}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              점검 대분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1 mb-2'}>
              <div className={'fs--1 fw-normal text-800'}>
                {checkMainCategoryName}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              점검 소분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1 mb-2'}>
              <div className={'fs--1 fw-normal text-800'}>
                {checkSubCategoryName}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              조치 대분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1 mb-2'}>
              <div className={'fs--1 fw-normal text-800'}>
                {actionMainCategoryName}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700 mb-2'}
            >
              조치 소분류
            </Form.Label>
            <Col md={'4'} xs={'8'} className={'py-1 mb-2'}>
              <div className={'fs--1 fw-normal text-800'}>
                {actionSubCategoryName}
              </div>
            </Col>
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              조치내용
            </Form.Label>
            <Col xs={'12'}>
              <Form.Control
                as="textarea"
                readOnly
                rows="5"
                defaultValue={makeActionContent(currentEvent.actions)}
                style={{ resize: 'none' }}
                className={'fs--1 fw-normal text-800'}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formEventActionImage"
          >
            <Form.Label
              column
              md={'2'}
              xs={'4'}
              className={'fs--1 fw-semi-bold text-700'}
            >
              첨부 이미지
            </Form.Label>
            <Col xs={'12'}>
              <SimpleBarReact
                style={{
                  minHeight: '85px',
                  maxHeight: '180px'
                  // minWidth: '250px'
                }}
                className={'border rounded px-3 py-4'}
              >
                <Flex direction={'row'} wrap={'wrap'}>
                  {attachmentFiles.length === 0 && (
                    <Flex
                      direction={'column'}
                      className={'text-500 text-center'}
                    >
                      <div className={'fs--1 fw-normal text-500'}>
                        첨부 된 이미지 없습니다.
                      </div>
                    </Flex>
                  )}
                  {attachmentFiles.map((file, index) => (
                    <FalconLightBox
                      key={index}
                      image={file.url}
                      className={'pb-1'}
                    >
                      <Image rounded src={file.url} width={110} alt="" />
                    </FalconLightBox>
                  ))}
                </Flex>
              </SimpleBarReact>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          direction={'row'}
          justifyContent={'end'}
          style={{ width: '100%' }}
        >
          <Flex direction={'row'}>
            <Button
              className="btn_close"
              variant="secondary"
              onClick={onHideEvent}
            >
              닫기
            </Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

export default ActionDetail;
