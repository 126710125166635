import { useSlopeAngles } from '../../../maps/left-area/detail/hook/useMapsDetail';
import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import FalconCardHeader from '../../../common/FalconCardHeader';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useSensorDirectionAngle } from '../hook/useManageSensor';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { getColor, isEmpty, rgbaColor } from '../../../../helpers/utils';
import dayjs from 'dayjs';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  // console.log(params);
  return params
    .map(
      ({ value, borderColor, seriesName, name }, index) =>
        `<span class='text-800 fs--1'>${name}</span><br/><div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>
        <span class='text-600 fs--1'>${seriesName}: ${
          value === undefined ? '-' : value
        }°</span>`
    )
    .join('<br/>');
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: ['value'],
    show: false
  },
  xAxis: {
    type: 'category',
    data: data.date,
    boundaryGap: true,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => dayjs(value).format('YY-MM-DD\r\n(HH:mm)')
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    // min: function (value) {
    //   return value.min - 0.5;
    // },
    // max: function (value) {
    //   return value.max + 0.5;
    // },
    splitLine: {
      show: true,
      interval: 0.1,
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  dataZoom: [
    {
      show: true,
      type: 'inside',
      filterMode: 'none',
      xAxisIndex: [0],
      startValue: -9999,
      endValue: 100
    }
    // {
    //   show: true,
    //   type: 'inside',
    //   filterMode: 'none',
    //   yAxisIndex: [0],
    //   startValue: -20,
    //   endValue: 20
    // }
  ],
  series: [
    {
      name: '방향각',
      type: 'line',
      data: data.value,
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    right: '48px',
    left: '48px',
    bottom: '15%',
    top: '5%',
    containLabel: false
  }
});

const SensorDirectionAngels = ({ sensor, searchStartDate, searchEndDate }) => {
  const {
    data: directionAnglesDatas,
    isLoading,
    isFetching,
    setSensorId,
    setStartDate,
    setEndDate
  } = useSensorDirectionAngle();

  const [directionAnglesChartData, setDirectionAnglesChartData] = useState({});
  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('1개월');

  // useEffect(() => {
  //   setSlopeAnglesSearchPeriod('1W');
  // }, []);

  useEffect(() => {
    if (
      !isEmpty(sensor) &&
      !isEmpty(searchStartDate) &&
      !isEmpty(searchEndDate)
    ) {
      setSensorId(sensor.id);
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    }
  }, [sensor, searchStartDate, searchEndDate]);

  useEffect(() => {
    // console.log(sensorDistanceDatas);
    if (!isEmpty(directionAnglesDatas)) {
      makeDirectionAnglesChartData(directionAnglesDatas);
    }
  }, [directionAnglesDatas]);

  const makeDirectionAnglesChartData = dataList => {
    let dates = [];
    let values = [];

    dataList.forEach(data => {
      // console.log('makeSlopeAnglesChartData item : ', data);
      dates.push(data.x);
      values.push(data.y1);
    });

    setDirectionAnglesChartData({
      date: dates,
      value: values
    });
  };

  return (
    <Card>
      <FalconCardHeader title="센서 기간별 방향각" light titleTag="h6" />
      <Card.Body className="pe-xxl-0">
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(directionAnglesChartData)}
        />
      </Card.Body>
    </Card>
  );
};

export default SensorDirectionAngels;
