import Flex from 'components/common/Flex';
import { getColor, isEmpty } from 'helpers/utils';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import ResponsivePagination from 'react-responsive-pagination';
import React, { useEffect, useState } from 'react';
import { useSensorRawData } from '../hook/useManageSensor';
import moment from 'moment';

const SensorRawData = ({ sensor, searchStartDate, searchEndDate }) => {
  const columns = [
    {
      accessor: 'sensingTime',
      Header: '측정일',
      headerProps: { className: 'text-900', style: { width: '100px' } },
      cellProps: { style: { width: '100px' } },
      Cell: rowData => {
        const { sensingTime } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <div className={'fs--1'}>
              {moment(sensingTime).format('YYYY-MM-DD')}
            </div>
            <div className={'fs--1'}>
              {moment(sensingTime).format('HH:mm:ss')}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'sensorId',
      Header: '센서Id',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { sensorId } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {sensorId}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'ssstatus',
      Header: '상태',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { ssstatus } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {ssstatus === '1' ? 'OK' : 'NG'}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'tilt',
      Header: 'tilt',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { tilt } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {tilt}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'angleX',
      Header: 'angleX',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { angleX } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {angleX}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'angleY',
      Header: 'angleY',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { angleY } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {angleY}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'azimuth',
      Header: 'azimuth',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { azimuth } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {azimuth}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'corAngleX',
      Header: 'corAngleX',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { corAngleX } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {corAngleX}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'corAngleY',
      Header: 'corAngleY',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { corAngleY } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {corAngleY}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'corDist',
      Header: 'corDist',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { corDist } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {corDist}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'distance',
      Header: 'distance',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { distance } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {distance}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'subs',
      Header: 'subs',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { subs } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {subs}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'subsPlus',
      Header: 'subsPlus',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { subsPlus } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {subsPlus}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'subsMinus',
      Header: 'subsMinus',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { subsMinus } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {subsMinus}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'changed',
      Header: 'changed',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { changed } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {changed}
            </div>
          </Flex>
        );
      }
    }
  ];

  const {
    data: res,
    isLoading,
    isFetching,
    sensorId,
    setSensorId,
    pageNo,
    setPageNo,
    pageSize,
    setPageSize,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  } = useSensorRawData();

  // const [rawDataList, setRawDataList] = useState([]);

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 5;

  const [rawDatas, setRawDatas] = useState([]);

  useEffect(() => {
    if (
      !isEmpty(sensor) &&
      !isEmpty(searchStartDate) &&
      !isEmpty(searchEndDate)
    ) {
      setSensorId(sensor.id);
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
      setPageNo(0);
      setPageSize(_pageSize);
    }
  }, [sensor, searchStartDate, searchEndDate]);

  useEffect(() => {
    setPageNo(currentPage - 1);
  }, [currentPage]);

  useEffect(() => {
    if (!isEmpty(res)) {
      let totalElements = res.totalElements;
      setTotalPages(Math.ceil(totalElements / _pageSize));
      setTotalElements(totalElements);

      setRawDatas(res.dataList);
    }
  }, [res]);

  return (
    <>
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="between"
        className={'px-3 pt-3'}
      >
        <Flex
          alignItems="end"
          alignContent={'end'}
          direction="column"
          justifyContent="start"
          className={'fs--1'}
        >
          총 {totalElements}건
        </Flex>
      </Flex>
      <AdvanceTableWrapper
        columns={columns}
        data={rawDatas}
        sortable
        pagination
        perPage={_pageSize}
      >
        <AdvanceTable
          table
          headerClassName="text-nowrap align-middle text-center"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            hover: true,
            className: 'fs--1 mb-0 overflow-hidden text-center'
          }}
        />
        <div className="mt-3">
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={setCurrentPage}
            maxWidth={350}
            extraClassName={'justify-content-center fs--2'}
            previousLabel="‹"
            nextLabel="›"
            // narrowBehaviour={combine(dropNav, dropEllipsis)}
          />
        </div>
      </AdvanceTableWrapper>
    </>
  );
};

export default SensorRawData;
