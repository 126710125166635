import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import { ChatContext } from 'context/Context';

import { chainAxios } from 'helpers/chainAxios';

const ChatContentHeader = ({ thread, setHideSidebar }) => {
  // const { currentThread } = useContext(ChatContext);
  // const { getUser, isOpenThreadInfo, setIsOpenThreadInfo } =
  //   useContext(ChatContext);
  // const user = getUser(thread);

  const [threadStatus, setThreadStatus] = useState(thread.status);

  const makeContentHeader = thread => {
    let header = '-';

    if (thread.status === '0') {
      header = '[점검요함] ';
    }

    if (thread.status === '1') {
      header = '[조치완료] ';
    }

    if (thread.status === '2') {
      header = '[재점검필] ';
    }

    if (thread.status === '3') {
      header = '[승인] ';
    }

    header += !!thread.project ? thread.project.value : '-';

    return header;
  };

  const makeChatRoomMembers = thread => {
    let members = '';
    thread.members.forEach(member => {
      members += member.memberName + ' / ';
    });

    return members.substring(0, members.length - 3);
  };

  const onClickStatusChange = statusValue => {
    let url = '/api/chatroom/' + thread.id + '?status=' + statusValue;
    console.log('onClickStatusChange url : ', url);
    chainAxios
      .put(url)
      .then(response => {
        console.log('ChatContentHeader onClickStatusChange() : ', response);
        if (response.data.resultCode === 200) {
          toast.success(`Change Status!!!`, {
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        console.log('ChatContentHeader onClickStatusChange() : ', error);
      });
  };

  return (
    <div className="chat-content-header">
      <Row className="flex-between-center">
        <Col xs={6} md={8} as={Flex} alignItems="center">
          <div
            className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer"
            onClick={() => setHideSidebar(true)}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </div>
          <div className="min-w-0">
            <h5 className="mb-0 text-truncate fs-0">
              {!!thread && makeContentHeader(thread)}
            </h5>
            <div className="fs--1">{!!thread && thread.title}</div>
            <div className="fs--1">
              {!!thread.members && makeChatRoomMembers(thread)}
            </div>
            {/* <div className="fs--2 text-400">
              {user.status === 'status-online'
                ? 'Active on  chat'
                : 'Active 7h ago'}
            </div> */}
          </div>
        </Col>
        <Col xs="auto">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>점검요함</Tooltip>}
          >
            <Button
              variant="falcon-primary"
              className="me-2"
              size="sm"
              disabled={threadStatus === '0'}
              onClick={() => onClickStatusChange('0')}
            >
              {/* <FontAwesomeIcon icon="phone" /> */}
              점검요함
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>조치완료</Tooltip>}
          >
            <Button
              variant="falcon-primary"
              className="me-2"
              size="sm"
              disabled={threadStatus === '1'}
              onClick={() => onClickStatusChange('1')}
            >
              {/* <FontAwesomeIcon icon="video" /> */}
              조치완료
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>재점검필</Tooltip>}
          >
            <Button
              variant="falcon-primary"
              className="me-2"
              size="sm"
              disabled={threadStatus === '2'}
              onClick={() => onClickStatusChange('2')}
            >
              {/* <FontAwesomeIcon icon="info" /> */}
              재점검필
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip style={{ position: 'fixed' }}>승인</Tooltip>}
          >
            <Button
              variant="falcon-primary"
              size="sm"
              disabled={threadStatus === '3'}
              onClick={() => onClickStatusChange('3')}
            >
              {/* <FontAwesomeIcon icon="info" /> */}
              승인
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </div>
  );
};

// ChatContentHeader.propTypes = {
//   setHideSidebar: PropTypes.func.isRequired
// };

export default ChatContentHeader;
