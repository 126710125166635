import { getSearchPeriodAmount, isEmpty } from 'helpers/utils';
import moment from 'moment/moment';
import { chainAxios } from 'helpers/chainAxios';

export const getSensorDistance = async (sensorId, startDate, endDate) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let pStartDate = startDate;
  let pEndDate = endDate;
  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/sensor/1/chart?pageNo=0&pageSize=99999&sort=sensingTime%24desc&startDate=2024-01-01&endDate=2024-02-28&items=distance
  let url =
    '/api/sensor/' +
    sensorId +
    '/chart?pageNo=0&pageSize=99999&sort=sensingTime$asc&startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate +
    '&items=distance';
  // console.log('getSensorDistance : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response);
    return response.data.data.chart.dataList;
  }
  return {};
};

export const getSensorAngle = async (sensorId, startDate, endDate) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let pStartDate = startDate;
  let pEndDate = endDate;
  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/sensor/1/chart?pageNo=0&pageSize=99999&sort=sensingTime%24desc&startDate=2024-01-01&endDate=2024-02-28&items=angleX&items=angleY
  let url =
    '/api/sensor/' +
    sensorId +
    '/chart?pageNo=0&pageSize=99999&sort=sensingTime$asc&startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate +
    '&items=angleX,angleY';
  // console.log('getRealTimeAngle : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response);
    return response.data.data.chart.dataList;
  }
  return {};
};

export const getSensorSlopeAngels = async (sensorId, startDate, endDate) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let pStartDate = startDate;
  let pEndDate = endDate;
  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/sensor/1/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate%24asc&startDate=2023-12-20&endDate=2024-01-20&items=saw_tilt
  let url =
    '/api/sensor/' +
    sensorId +
    '/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate$asc&startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate +
    '&items=saw_tilt';
  // console.log('getSlopeAngels : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart.dataList;
  }
  return [];
};

export const getSensorDirectionAngels = async (
  sensorId,
  startDate,
  endDate
) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let pStartDate = startDate;
  let pEndDate = endDate;
  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/sensor/2/analyzedchart?pageNo=0&pageSize=20&sort=sawDate%24desc&startDate=2023-12-01&endDate=2024-01-31&items=saw_azimuth
  let url =
    '/api/sensor/' +
    sensorId +
    '/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate$asc&startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate +
    '&items=saw_azimuth';
  // console.log('getSlopeAngels : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart.dataList;
  }
  return [];
};

export const getSensorFluctuations = async (sensorId, startDate, endDate) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let pStartDate = startDate;
  let pEndDate = endDate;
  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/sensor/2/fluctchart?startDate=2023-12-01&endDate=2023-12-31
  let url =
    '/api/sensor/' +
    sensorId +
    '/fluctchart?startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate;
  // console.log('getFluctuations : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart.chartData;
  }
  return [];
};

export const getSensorSolidationsSubsidendces = async (
  sensorId,
  startDate,
  endDate
) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let pStartDate = startDate;
  let pEndDate = endDate;
  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/sensor/1/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate%24asc&startDate=2023-12-20&endDate=2024-01-20&items=saw_subs%2Csaw_replus%2Csaw_reminus
  let url =
    '/api/sensor/' +
    sensorId +
    '/analyzedchart?pageNo=0&pageSize=9999&sort=sawDate$asc&startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate +
    '&items=saw_subs,saw_replus,saw_reminus';
  // console.log('getSolidationsSubsidendces : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart.dataList;
  }
  return [];
};

export const getSensorSendData = async (sensorId, startDate, endDate) => {
  if (sensorId === '') {
    sensorId = '*';
  }
  let pStartDate = startDate;
  let pEndDate = endDate;
  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // http://112.175.18.232:7000/api/sensor/1/chart/traffic?startDate=2023-10-01&endDate=2023-10-31
  let url =
    '/api/sensor/' +
    sensorId +
    '/chart/traffic?startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate;
  // console.log('getSlopeAngels : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    // console.table(response.data.data.projectList.dataList);
    return response.data.data.chart;
  }
  return [];
};

export const getSensorRawData = async (
  sensorId,
  pageNo,
  pageSize,
  startDate,
  endDate
) => {
  if (sensorId === '') {
    sensorId = '*';
  }

  let pStartDate = startDate;
  let pEndDate = endDate;
  if (isEmpty(pStartDate) || isEmpty(pEndDate)) {
    let amount = getSearchPeriodAmount('1W');
    pStartDate = moment().subtract(amount, 'days').format('YYYY-MM-DD');
    pEndDate = moment().format('YYYY-MM-DD');
  }

  // hhttp://112.175.18.232:7000/api/sensor/1/rawData?pageNo=0&pageSize=20&sort=sensingTime%24desc&startDate=2024-03-01&endDate=2024-03-31
  let url =
    '/api/sensor/' +
    sensorId +
    '/rawData?pageNo=' +
    pageNo +
    '&pageSize=' +
    pageSize +
    '&sort=sensingTime$desc&startDate=' +
    pStartDate +
    '&endDate=' +
    pEndDate;
  // console.log('getSensorRawData : ', url);
  const response = await chainAxios.get(url);
  if (response.data.resultCode === 200) {
    return response.data.data.sensorDataList;
  }
  return [];
};
