import React, { useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import FalconLightBox from '../../FalconLightBox';
import Flex from 'components/common/Flex';

const FileItem = ({ file, checkItemHandler }) => {
  const [checked, setChecked] = useState(false); // 체크 여부 판단
  const checkHandled = selectedFile => {
    checkItemHandler(selectedFile, !checked);
    setChecked(!checked);
  };

  return (
    <Flex key={file.key} direction={'column'}>
      <Form.Check
        type="checkbox"
        id={file.key}
        aria-label="check"
        className={'pt-3 pe-3'}
        checked={checked}
        onChange={e => checkHandled(file)}
      />
      <FalconLightBox image={file.url} className={'pb-1'}>
        <Image rounded src={file.url} width={110} alt="" />
      </FalconLightBox>
    </Flex>
  );
};

export default FileItem;
