import { Card, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import React from 'react';

const SensorImages = ({ sensor }) => {
  return (
    <Card className="h-md-100">
      <Card.Header>
        <h6>센서 현장 사진</h6>
      </Card.Header>
      <Card.Body className={'pt-0'}>
        <Col xs={12} style={{ minHeight: '420px', justifyContent: 'center' }}>
          {!!sensor && !!sensor.images && sensor.images.length > 0 ? (
            <Carousel>
              {sensor.images.map((img, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100 h-100"
                      style={{ objectFit: 'cover', maxHeight: '420px' }}
                      src={process.env.REACT_APP_API_SERVER + 'api/file/' + img}
                      alt=""
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          ) : (
            <h5 className={'text-500'}>등록 된 사진이 없습니다.</h5>
          )}
        </Col>
      </Card.Body>
    </Card>
  );
};

export default SensorImages;
