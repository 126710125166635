import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import { Nav } from 'react-bootstrap';
import LastMessage from './LastMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatSidebarDropdownAction from './ChatSidebarDropdownAction';
import { ChatContext } from 'context/Context';

import avatarSrc from 'assets/img/illustrations/geostr.png';
import dayjs from 'dayjs';

const ChatThread = ({ thread, index }) => {
  // const { getUser, messages } = useContext(ChatContext);
  // const user = getUser(thread);
  // const message = messages.find(({ id }) => id === thread.messagesId);
  // const lastMessage = message?.content[message.content.length - 1];

  const { setCurrentChatRoom } = useContext(ChatContext);

  const sendSelectedThreadInfo = () => {
    // console.log('ChatThread selectedThread : ', thread);
    setCurrentChatRoom(thread);

    // threadsDispatch({
    //   type: 'EDIT',
    //   id: thread.id,
    //   payload: { ...thread, read: true }
    // });
  };

  const makeLastMessageDate = thread => {
    let date = dayjs(thread.lastChat.createDate, 'YYYY-MM-DD HH:mm:ss');

    let today = dayjs();
    let expired_at = dayjs(date.format('YYYY-MM-DD'));

    let result = expired_at.diff(today, 'day', true);
    // console.log('makeLastMessageDate : ', Math.floor(result));
    if (Math.floor(result) === -1) {
      return date.format('HH:mm:ss');
    } else {
      return date.format('YYYY-MM-DD');
    }
  };

  const makeChatRoomStatusName = thread => {
    if (thread.status === '0') {
      return '점검요함';
    }
    if (thread.status === '1') {
      return '조치완료';
    }
    if (thread.status === '2') {
      return '재점검필';
    }
    if (thread.status === '3') {
      return '승인';
    }
  };

  return (
    <Nav.Link
      eventKey={thread.id}
      className={classNames(`chat-contact hover-actions-trigger p-3`, {
        'unread-message': false,
        'read-message': true
      })}
      onClick={sendSelectedThreadInfo}
    >
      {/* <div className="d-md-none d-lg-block">
        <ChatSidebarDropdownAction />
      </div> */}
      <Flex>
        <Avatar className={'status-online'} src={avatarSrc} size="xl" />
        <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
          <Flex justifyContent="between">
            <h6 className="mb-0 chat-contact-title">
              {!!thread.project && thread.project.value}
            </h6>
            <span className="message-time fs--2">
              {' '}
              {!!thread.lastChat ? makeLastMessageDate(thread) : '-'}{' '}
            </span>
          </Flex>
          <div className="min-w-0">
            <div className="chat-contact-content pe-3">
              <div className="chat-contact-content fs--2">{thread.title}</div>
              <div className="chat-contact-content fs--2">
                {!!thread.lastChat
                  ? thread.lastChat.message.length > 20
                    ? thread.lastChat.message.substring(0, 20) + '...'
                    : thread.lastChat.message
                  : '-'}
              </div>
              {/* <LastMessage lastMessage={lastMessage} thread={thread} /> */}
              <div className="position-absolute bottom-0 end-0 px-0">
                {!!thread.status ? makeChatRoomStatusName(thread) : '-'}
                {/* {!!lastMessage?.status && (
                  <FontAwesomeIcon
                    icon={classNames({
                      check:
                        lastMessage.status === 'seen' ||
                        lastMessage.status === 'sent',
                      'check-double': lastMessage.status === 'delivered'
                    })}
                    transform="shrink-5 down-4"
                    className={classNames({
                      'text-success': lastMessage.status === 'seen',
                      'text-400':
                        lastMessage.status === 'delivered' ||
                        lastMessage.status === 'sent'
                    })}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
        {/* <Avatar className={user.status} src={user.avatarSrc} size="xl" />
        <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
          <Flex justifyContent="between">
            <h6 className="mb-0 chat-contact-title">{user.name}</h6>
            <span className="message-time fs--2">
              {' '}
              {!!lastMessage && lastMessage.time.day}{' '}
            </span>
          </Flex>
          <div className="min-w-0">
            <div className="chat-contact-content pe-3">
              <LastMessage lastMessage={lastMessage} thread={thread} />
              <div className="position-absolute bottom-0 end-0 hover-hide">
                {!!lastMessage?.status && (
                  <FontAwesomeIcon
                    icon={classNames({
                      check:
                        lastMessage.status === 'seen' ||
                        lastMessage.status === 'sent',
                      'check-double': lastMessage.status === 'delivered'
                    })}
                    transform="shrink-5 down-4"
                    className={classNames({
                      'text-success': lastMessage.status === 'seen',
                      'text-400':
                        lastMessage.status === 'delivered' ||
                        lastMessage.status === 'sent'
                    })}
                  />
                )}
              </div>
            </div>
          </div>
        </div> */}
      </Flex>
    </Nav.Link>
  );
};

// ChatThread.propTypes = {
//   thread: PropTypes.object.isRequired,
//   index: PropTypes.number.isRequired
// };

export default ChatThread;
