export const wewerpipeData = {
  type: 'FeatureCollection',
  name: 'sewer pipe',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      id: '1',
      type: 'Feature',
      properties: {
        id: 1,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780185, 37.64759],
            [126.780199, 37.64758],
            [126.791261, 37.639209]
          ]
        ]
      }
    },
    {
      id: '2',
      type: 'Feature',
      properties: {
        id: 2,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.791262, 37.639209],
            [126.792032, 37.639859],
            [126.792127, 37.639938]
          ]
        ]
      }
    },
    {
      id: '3',
      type: 'Feature',
      properties: {
        id: 3,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.791262, 37.639209],
            [126.790029, 37.638189]
          ]
        ]
      }
    },
    {
      id: '4',
      type: 'Feature',
      properties: {
        id: 4,
        ins_date: '2024-06-04',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790029, 37.638189],
            [126.788978, 37.637383],
            [126.788764, 37.637241]
          ]
        ]
      }
    },
    {
      id: '5',
      type: 'Feature',
      properties: {
        id: 5,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788764, 37.637241],
            [126.787847, 37.636627]
          ]
        ]
      }
    },
    {
      id: '6',
      type: 'Feature',
      properties: {
        id: 6,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 1700.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787847, 37.636627],
            [126.776935, 37.644848],
            [126.776922, 37.644858]
          ]
        ]
      }
    },
    {
      id: '7',
      type: 'Feature',
      properties: {
        id: 7,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780474, 37.642182],
            [126.780478, 37.642185],
            [126.783774, 37.64491]
          ]
        ]
      }
    },
    {
      id: '8',
      type: 'Feature',
      properties: {
        id: 8,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783774, 37.64491],
            [126.784531, 37.645561],
            [126.784603, 37.645618]
          ]
        ]
      }
    },
    {
      id: '9',
      type: 'Feature',
      properties: {
        id: 9,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783974, 37.639544],
            [126.783975, 37.639545],
            [126.78398, 37.639549],
            [126.787238, 37.642289]
          ]
        ]
      }
    },
    {
      id: '10',
      type: 'Feature',
      properties: {
        id: 10,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787238, 37.642289],
            [126.788017, 37.642946],
            [126.788087, 37.642998]
          ]
        ]
      }
    },
    {
      id: '11',
      type: 'Feature',
      properties: {
        id: 11,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789521, 37.641912],
            [126.78945, 37.641854],
            [126.788674, 37.641202]
          ]
        ]
      }
    },
    {
      id: '12',
      type: 'Feature',
      properties: {
        id: 12,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788674, 37.641202],
            [126.785375, 37.638498],
            [126.785369, 37.638494]
          ]
        ]
      }
    },
    {
      id: '13',
      type: 'Feature',
      properties: {
        id: 13,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786684, 37.637503],
            [126.78669, 37.637508],
            [126.789946, 37.640239]
          ]
        ]
      }
    },
    {
      id: '14',
      type: 'Feature',
      properties: {
        id: 14,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789946, 37.640239],
            [126.790735, 37.640886],
            [126.790804, 37.64094]
          ]
        ]
      }
    },
    {
      id: '15',
      type: 'Feature',
      properties: {
        id: 15,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788993, 37.637396],
            [126.787705, 37.638359]
          ]
        ]
      }
    },
    {
      id: '16',
      type: 'Feature',
      properties: {
        id: 16,
        ins_date: '2024-07-01',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.788463, 37.637792],
            [126.79087, 37.639762]
          ]
        ]
      }
    },
    {
      id: '17',
      type: 'Feature',
      properties: {
        id: 17,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.79087, 37.639762],
            [126.791546, 37.640359],
            [126.791557, 37.640369]
          ]
        ]
      }
    },
    {
      id: '18',
      type: 'Feature',
      properties: {
        id: 18,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.777995, 37.64405],
            [126.781249, 37.646779],
            [126.781253, 37.646782]
          ]
        ]
      }
    },
    {
      id: '21',
      type: 'Feature',
      properties: {
        id: 21,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778885, 37.644796],
            [126.777873, 37.645568],
            [126.77782, 37.64561]
          ]
        ]
      }
    },
    {
      id: '22',
      type: 'Feature',
      properties: {
        id: 22,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779379, 37.64521],
            [126.778343, 37.645965],
            [126.778289, 37.646002]
          ]
        ]
      }
    },
    {
      id: '23',
      type: 'Feature',
      properties: {
        id: 23,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780015, 37.645744],
            [126.778993, 37.646513],
            [126.778944, 37.64655]
          ]
        ]
      }
    },
    {
      id: '24',
      type: 'Feature',
      properties: {
        id: 24,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782379, 37.643859],
            [126.781792, 37.644301]
          ]
        ]
      }
    },
    {
      id: '25',
      type: 'Feature',
      properties: {
        id: 25,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784427, 37.644381],
            [126.784446, 37.644397],
            [126.785217, 37.645082],
            [126.785289, 37.645143]
          ]
        ]
      }
    },
    {
      id: '26',
      type: 'Feature',
      properties: {
        id: 26,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784743, 37.644661],
            [126.784751, 37.644656],
            [126.786756, 37.643167]
          ]
        ]
      }
    },
    {
      id: '27',
      type: 'Feature',
      properties: {
        id: 27,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78642, 37.642873],
            [126.786442, 37.642891],
            [126.786686, 37.643103],
            [126.786694, 37.643111]
          ]
        ]
      }
    },
    {
      id: '28',
      type: 'Feature',
      properties: {
        id: 28,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786694, 37.643111],
            [126.787202, 37.64357],
            [126.787272, 37.643622]
          ]
        ]
      }
    },
    {
      id: '29',
      type: 'Feature',
      properties: {
        id: 29,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785422, 37.643627],
            [126.784574, 37.642911]
          ]
        ]
      }
    },
    {
      id: '30',
      type: 'Feature',
      properties: {
        id: 30,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782379, 37.643859],
            [126.782842, 37.643498]
          ]
        ]
      }
    },
    {
      id: '31',
      type: 'Feature',
      properties: {
        id: 31,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.785457, 37.640791],
            [126.784879, 37.641202]
          ]
        ]
      }
    },
    {
      id: '32',
      type: 'Feature',
      properties: {
        id: 32,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.786806, 37.641926],
            [126.786361, 37.642233]
          ]
        ]
      }
    },
    {
      id: '33',
      type: 'Feature',
      properties: {
        id: 33,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.784369, 37.639876],
            [126.783959, 37.640157]
          ]
        ]
      }
    },
    {
      id: '34',
      type: 'Feature',
      properties: {
        id: 34,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 3
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78174, 37.643228],
            [126.782573, 37.642615]
          ]
        ]
      }
    },
    {
      id: '35',
      type: 'Feature',
      properties: {
        id: 35,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.790167, 37.638303],
            [126.788807, 37.639284]
          ]
        ]
      }
    },
    {
      id: '38',
      type: 'Feature',
      properties: {
        id: 38,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.787777, 37.641846],
            [126.788587, 37.642523],
            [126.788646, 37.642576]
          ]
        ]
      }
    },
    {
      id: '39',
      type: 'Feature',
      properties: {
        id: 39,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.789288, 37.640702],
            [126.790115, 37.641385],
            [126.790164, 37.641425]
          ]
        ]
      }
    },
    {
      id: '40',
      type: 'Feature',
      properties: {
        id: 40,
        ins_date: '2024-05-09',
        ins_depth: 4.0,
        diameter: 350,
        length: 150.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782294, 37.640811],
            [126.782707, 37.641135],
            [126.783402, 37.641712]
          ]
        ]
      }
    },
    {
      id: '19',
      type: 'Feature',
      properties: {
        id: 19,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78196, 37.646248],
            [126.781584, 37.645934],
            [126.781323, 37.645717],
            [126.781181, 37.64559],
            [126.781038, 37.645472],
            [126.780932, 37.645384],
            [126.780785, 37.645256],
            [126.780623, 37.645124],
            [126.780455, 37.644987],
            [126.78032, 37.64488],
            [126.780112, 37.644714],
            [126.779926, 37.64456],
            [126.779299, 37.644021],
            [126.778963, 37.643732],
            [126.778696, 37.643521]
          ]
        ]
      }
    },
    {
      id: '20',
      type: 'Feature',
      properties: {
        id: 20,
        ins_date: '2024-07-10',
        ins_depth: 4.0,
        diameter: 350,
        length: 530.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782567, 37.645788],
            [126.782551, 37.645775],
            [126.78208, 37.645373],
            [126.7817, 37.645051],
            [126.781404, 37.644796],
            [126.781058, 37.644513],
            [126.780712, 37.644227],
            [126.780244, 37.643838],
            [126.779952, 37.643599],
            [126.779689, 37.643384],
            [126.779423, 37.643155],
            [126.779306, 37.643061]
          ]
        ]
      }
    },
    {
      id: '41',
      type: 'Feature',
      properties: {
        id: 41,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778847, 37.645598],
            [126.778444, 37.64526]
          ]
        ]
      }
    },
    {
      id: '42',
      type: 'Feature',
      properties: {
        id: 42,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778656, 37.645438],
            [126.778759, 37.645359]
          ]
        ]
      }
    },
    {
      id: '43',
      type: 'Feature',
      properties: {
        id: 43,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 3
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778547, 37.645346],
            [126.778451, 37.645416]
          ]
        ]
      }
    },
    {
      id: '44',
      type: 'Feature',
      properties: {
        id: 44,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779182, 37.645353],
            [126.778787, 37.645018]
          ]
        ]
      }
    },
    {
      id: '45',
      type: 'Feature',
      properties: {
        id: 45,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.77898, 37.645182],
            [126.778903, 37.645236]
          ]
        ]
      }
    },
    {
      id: '46',
      type: 'Feature',
      properties: {
        id: 46,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778878, 37.645096],
            [126.778957, 37.645039]
          ]
        ]
      }
    },
    {
      id: '41',
      type: 'Feature',
      properties: {
        id: 41,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778367, 37.645192],
            [126.777963, 37.644853]
          ]
        ]
      }
    },
    {
      id: '42',
      type: 'Feature',
      properties: {
        id: 42,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778175, 37.645031],
            [126.778278, 37.644952]
          ]
        ]
      }
    },
    {
      id: '43',
      type: 'Feature',
      properties: {
        id: 43,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778066, 37.644939],
            [126.77797, 37.645009]
          ]
        ]
      }
    },
    {
      id: '44',
      type: 'Feature',
      properties: {
        id: 44,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778688, 37.644947],
            [126.778268, 37.644584]
          ]
        ]
      }
    },
    {
      id: '45',
      type: 'Feature',
      properties: {
        id: 45,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778502, 37.644787],
            [126.778435, 37.644833]
          ]
        ]
      }
    },
    {
      id: '46',
      type: 'Feature',
      properties: {
        id: 46,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778401, 37.6447],
            [126.778482, 37.64464]
          ]
        ]
      }
    },
    {
      id: '41',
      type: 'Feature',
      properties: {
        id: 41,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779482, 37.646146],
            [126.779079, 37.645808]
          ]
        ]
      }
    },
    {
      id: '42',
      type: 'Feature',
      properties: {
        id: 42,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779291, 37.645986],
            [126.779394, 37.645907]
          ]
        ]
      }
    },
    {
      id: '43',
      type: 'Feature',
      properties: {
        id: 43,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779182, 37.645894],
            [126.779086, 37.645964]
          ]
        ]
      }
    },
    {
      id: '44',
      type: 'Feature',
      properties: {
        id: 44,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779817, 37.645894],
            [126.779422, 37.645559]
          ]
        ]
      }
    },
    {
      id: '45',
      type: 'Feature',
      properties: {
        id: 45,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779589, 37.6457],
            [126.779512, 37.645754]
          ]
        ]
      }
    },
    {
      id: '46',
      type: 'Feature',
      properties: {
        id: 46,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 15.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779487, 37.645614],
            [126.779566, 37.645557]
          ]
        ]
      }
    },
    {
      id: '47',
      type: 'Feature',
      properties: {
        id: 47,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 100.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780499, 37.646149],
            [126.779878, 37.646631]
          ]
        ]
      }
    },
    {
      id: '48',
      type: 'Feature',
      properties: {
        id: 48,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 100.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780298, 37.64681],
            [126.779687, 37.646292]
          ]
        ]
      }
    },
    {
      id: '49',
      type: 'Feature',
      properties: {
        id: 49,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 100.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78004, 37.646062],
            [126.780639, 37.646568]
          ]
        ]
      }
    },
    {
      id: '50',
      type: 'Feature',
      properties: {
        id: 50,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.778342, 37.643788],
            [126.778712, 37.644092]
          ]
        ]
      }
    },
    {
      id: '51',
      type: 'Feature',
      properties: {
        id: 51,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779243, 37.64459],
            [126.779622, 37.644299]
          ]
        ]
      }
    },
    {
      id: '52',
      type: 'Feature',
      properties: {
        id: 52,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.779901, 37.64514],
            [126.780281, 37.644849]
          ]
        ]
      }
    },
    {
      id: '53',
      type: 'Feature',
      properties: {
        id: 53,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.7802, 37.645379],
            [126.780579, 37.645088]
          ]
        ]
      }
    },
    {
      id: '54',
      type: 'Feature',
      properties: {
        id: 54,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780534, 37.645659],
            [126.780913, 37.645368]
          ]
        ]
      }
    },
    {
      id: '55',
      type: 'Feature',
      properties: {
        id: 55,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 4
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781129, 37.646162],
            [126.781509, 37.645871]
          ]
        ]
      }
    },
    {
      id: '56',
      type: 'Feature',
      properties: {
        id: 56,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 50.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78083, 37.645907],
            [126.781209, 37.645616]
          ]
        ]
      }
    },
    {
      id: '57',
      type: 'Feature',
      properties: {
        id: 57,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 250.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782294, 37.645995],
            [126.780788, 37.644769]
          ]
        ]
      }
    },
    {
      id: '58',
      type: 'Feature',
      properties: {
        id: 58,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 25.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781831, 37.645621],
            [126.781668, 37.645743]
          ]
        ]
      }
    },
    {
      id: '59',
      type: 'Feature',
      properties: {
        id: 59,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 25.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781694, 37.645256],
            [126.781534, 37.645376]
          ]
        ]
      }
    },
    {
      id: '60',
      type: 'Feature',
      properties: {
        id: 60,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 25.0,
        dam_number: 4
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781235, 37.645133],
            [126.781075, 37.645253]
          ]
        ]
      }
    },
    {
      id: '61',
      type: 'Feature',
      properties: {
        id: 61,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 25.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.78105, 37.644732],
            [126.780889, 37.644852]
          ]
        ]
      }
    },
    {
      id: '62',
      type: 'Feature',
      properties: {
        id: 62,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 120.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780098, 37.643719],
            [126.779766, 37.643953],
            [126.779321, 37.643589]
          ]
        ]
      }
    },
    {
      id: '63',
      type: 'Feature',
      properties: {
        id: 63,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 105.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.780393, 37.643273],
            [126.779742, 37.642733]
          ]
        ]
      }
    },
    {
      id: '64',
      type: 'Feature',
      properties: {
        id: 64,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 60.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.7804, 37.643968],
            [126.780816, 37.643671]
          ]
        ]
      }
    },
    {
      id: '65',
      type: 'Feature',
      properties: {
        id: 65,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 60.0,
        dam_number: 2
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.783007, 37.644276],
            [126.782209, 37.644862]
          ]
        ]
      }
    },
    {
      id: '66',
      type: 'Feature',
      properties: {
        id: 66,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 60.0,
        dam_number: 1
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.782597, 37.645177],
            [126.782209, 37.644862]
          ]
        ]
      }
    },
    {
      id: '67',
      type: 'Feature',
      properties: {
        id: 67,
        ins_date: '2024-07-12',
        ins_depth: 4.0,
        diameter: 350,
        length: 60.0,
        dam_number: 0
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [126.781832, 37.644545],
            [126.782209, 37.644862]
          ]
        ]
      }
    }
  ]
};
