import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutContent from 'components/authentication/LogoutContent';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.sessionStorage.clear();
    cookies.set('Authorization', '', 0);

    navigate('/login');
  }, []);

  return <></>;

  // return <LogoutContent layout={'simple'} />;
};

export default Logout;
