import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, isEmpty } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';
import { chainAxios } from 'helpers/chainAxios';

const ProjectManageChart = ({}) => {
  // const projectShare = [
  //   { id: 1, value: 1, name: '진행중', color: 'primary' },
  //   { id: 2, value: 1, name: '점검요함', color: 'warning' },
  //   { id: 3, value: 1, name: '완료', color: 'success' },
  //   { id: 4, value: 1, name: '예정', color: 'secondary' }
  // ];

  const [projectShare, setProjectShare] = useState([]);

  useEffect(() => {
    projectyInfo();
  }, []);

  const projectyInfo = () => {
    let url = '/api/projects/status';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('FacilityChart facilityInfo() : ', response);
        if (response.data.resultCode === 200) {
          let makeShare = new Array();
          makeShare.push({
            id: 1,
            name: '진행중',
            value:
              response.data.data.projectStatus.NO_ISSUE == undefined
                ? 0
                : response.data.data.projectStatus.NO_ISSUE,
            color: 'primary'
          });

          makeShare.push({
            id: 2,
            name: '점검요함',
            value:
              response.data.data.projectStatus.ON_ISSUE == undefined
                ? 0
                : response.data.data.projectStatus.ON_ISSUE,
            color: 'danger'
          });

          makeShare.push({
            id: 3,
            name: '예정',
            value:
              response.data.data.projectStatus.BEFORE == undefined
                ? 0
                : response.data.data.projectStatus.BEFORE,
            color: 'warning'
          });

          makeShare.push({
            id: 4,
            name: '완료',
            value:
              response.data.data.projectStatus.FINISH == undefined
                ? 0
                : response.data.data.projectStatus.FINISH,
            color: 'success'
          });

          setProjectShare(makeShare);
        }
      })
      .catch(error => {
        console.log('FacilityChart facilityInfo() : ', error);
      });
  };

  const radius = ['100%', '65%'];

  const getOptions = (data, radius) => ({
    color: [
      getColor('primary'),
      getColor('danger'),
      getColor('warning'),
      getColor('success')
    ],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => {
        // console.log(params);
        return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
          params.data.color
        )}"></div><span class="fs--1"><strong>${params.data.name}:</strong> ${
          params.value
        }개소</span>`;
      }
    },
    series: [
      {
        name: '운영 현황\r\n' + total + '개소',
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: '0.8333333333rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const total = projectShare.reduce((acc, val) => val.value + acc, 0);

  const SensorItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`px-4 fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </p>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <div>{value} 개소</div>
      </Flex>
    );
  };

  return (
    // <Card className="h-md-100">
    //   <Card.Body>
    //     <Row className="g-0 h-100">
    <>
      <Flex
        direction="column"
        justifyContent="between"
        className="rounded-1 h-100"
        alignItems="center"
      >
        <h6 className={'fs--1 text-900'}>{'전체 프로젝트'}</h6>
      </Flex>
      <Flex
        direction="column"
        justifyContent="center"
        className="rounded-1 h-100 mb-1"
        alignItems="center"
      >
        <BasicECharts
          echarts={echarts}
          options={getOptions(projectShare, radius)}
          style={{ width: '10rem', height: '10rem' }}
          // style={{ width: '10rem', height: '15.8rem' }}
        />
      </Flex>
      <Col className="px-2 text-center">
        {projectShare.map((item, index) => (
          <SensorItem item={item} index={index} key={index} total={total} />
        ))}
      </Col>
    </>
    //     </Row>
    //   </Card.Body>
    // </Card>
  );
};

export default ProjectManageChart;
