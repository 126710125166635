import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { Feature, Map, View } from 'ol';
import { defaults } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import { Group, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import { Fill, Icon, Style, Text } from 'ol/style';
import mapMarker from '../../../../../assets/img/icons/map-marker.png';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';

const ProjectBaseInfo = ({ project }) => {
  const miniMapRef = useRef();
  const miniMapTooltipRef = useRef();
  const [miniMapInstance, setMiniMapInstance] = useState(null);

  useEffect(() => {
    MakeVworldMiniMap();
  }, []);

  useEffect(() => {
    if (project !== undefined) {
      if (miniMapInstance !== null) {
        miniMapInstance
          .getView()
          .setCenter(
            fromLonLat([project.location.longitude, project.location.latitude])
          );
        MakeVworldProjectMarker(project);
      }
    }
  }, [project]);

  const MakeMiniMapTooltip = feature => {
    // console.log('MakeMiniMapTooltip : ', feature);
    let type = feature.get('type');
    let name = feature.get('name');
    let address = feature.get('address');
    let id = feature.get('id');

    let htmlCode =
      "<Flex direction={'row'} justifyContent={'end'}><Flex direction={'column'} className={'px-1'}><h6>" +
      id +
      '</h6><h6>' +
      type +
      '</h6><h6>' +
      name +
      '</h6><h6>' +
      address +
      '</h6></Flex></Flex>';

    miniMapTooltipRef.current.innerHTML = htmlCode;
  };
  const MakeVworldMiniMap = () => {
    const miniMap = new Map({
      controls: defaults({ zoom: false, rotate: false }),
      // layers: [vworldLayers],
      target: 'miniMap',
      view: new View({
        center: fromLonLat([126.7901852, 37.6385557]),
        // center: fromLonLat([
        //   facilityInfo.location.longitude,
        //   facilityInfo.location.latitude
        // ]),
        maxZoom: 19,
        minZoom: 19,
        zoom: 19
      })
    });

    const baseLayerGroup = new Group({
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://api.vworld.kr/req/wmts/1.0.0/BB2A44B3-37F9-31C5-8C81-8DBFF739A3E4/Base/{z}/{y}/{x}.png'
          }),
          title: 'base-vworld-base',
          visible: true,
          // properties: { name: 'base-vworld-base' },
          minZoom: 6,
          maxZoom: 19,
          zIndex: 2
          // preload: Infinity
        })
      ]
    });
    miniMap.addLayer(baseLayerGroup);

    setMiniMapInstance(miniMap);
  };

  const MakeVworldProjectMarker = project => {
    miniMapInstance.getLayers().forEach(layer => {
      if (layer.get('name') && layer.get('name') === 'projectMarker') {
        miniMapInstance.removeLayer(layer);
      }
    });

    let marker = new Feature({
      geometry: new Point(
        fromLonLat([project?.location.longitude, project?.location.latitude])
      ),
      name: project?.name,
      type: project?.type,
      address: project?.address,
      id: project?.id
    });

    let myStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: mapMarker,
        scale: 0.6
      }),
      text: new Text({
        text: marker.get('name'),
        font: 'bold 12px Pretendard',
        offsetX: 0,
        offsetY: 10,
        fill: new Fill({
          color: 'black'
        })
      })
    });
    marker.setStyle(myStyle);
    // create a vector layer and add the marker feature to it
    let markerLayer = new VectorLayer({
      source: new VectorSource({
        features: [marker]
      }),
      zIndex: 99,
      visible: true,
      name: 'projectMarker'
    });

    // add myLayer
    miniMapInstance.addLayer(markerLayer);
  };
  return (
    <Card>
      <Card.Header>
        <h6>프로젝트 관리</h6>
      </Card.Header>
      <Card.Body style={{ backgroundColor: getColor('gray-100') }}>
        <Row xs={12}>
          <Col md={3} xl={3}>
            <div
              ref={miniMapRef}
              id="miniMap"
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                minHeight: '260px'
              }}
              className={'border rounded'}
            >
              <div
                ref={miniMapTooltipRef}
                id="info"
                style={{
                  position: 'absolute',
                  display: 'inline-block',
                  height: 'auto',
                  width: 'auto',
                  zIndex: '100',
                  backgroundColor: '#333',
                  color: '#fff',
                  textAlign: 'center',
                  borderRadius: '4px',
                  padding: '5px',
                  left: '50%',
                  transform: 'translateX(3%)',
                  visibility: 'hidden',
                  pointerEvents: 'none'
                }}
              ></div>
            </div>
          </Col>
          <Col md={9} xl={9} className={'px-4'}>
            <Row
              xs={12}
              className={'py-3 border rounded h-100'}
              style={{ backgroundColor: getColor('white') }}
            >
              <Col xs={6} as={Flex}>
                <Flex direction={'column'} justifyContent={'between'}>
                  <Col xs={12}>
                    <div className={'fs--1 text-900 mb-1'}>프로젝트</div>
                    <div className={'fs--1 text-700'}>{project.name}</div>
                  </Col>
                  <Col xs={12}>
                    <div className={'fs--1 text-900 mb-1'}>주소</div>
                    <div className={'fs--1 text-700'}>
                      {project.properties?.PJADDRESS}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className={'fs--1 text-900 mb-1'}>발주사</div>
                    <div className={'fs--1 text-700'}>
                      {project.properties?.PJORDER}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className={'fs--1 text-900 mb-1'}>관리부서</div>
                    <div className={'fs--1 text-700'}>
                      {project.properties?.PJMANAGER}
                    </div>
                  </Col>
                </Flex>
              </Col>
              <Col xs={6} as={Flex}>
                <Flex direction={'column'} justifyContent={'between'}>
                  <Col xs={12}>
                    <div className={'fs--1 text-900 mb-1'}>관리업체</div>
                    <div className={'fs--1 text-700'}>
                      {project.properties?.PJCONSTRUCTOR}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className={'fs--1 text-900 mb-1'}>등록일</div>
                    <div className={'fs--1 text-700'}>{project.createDate}</div>
                  </Col>
                  <Col xs={12}>
                    <div className={'fs--1 text-900 mb-1'}>관리 시작일</div>
                    <div className={'fs--1 text-700'}>{project.initDate}</div>
                  </Col>
                  <Col xs={12}>
                    <div className={'fs--1 text-900 mb-1'}>관리 종료일</div>
                    <div className={'fs--1 text-700'}>{project.endDate}</div>
                  </Col>
                </Flex>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
      {/*<Card.Footer*/}
      {/*  as={Flex}*/}
      {/*  justifyContent="end"*/}
      {/*  style={{ backgroundColor: getColor('gray-100') }}*/}
      {/*>*/}
      {/*  /!*<IconButton variant="falcon-default" transform="shrink-3" size="sm">*!/*/}
      {/*  /!*  수정*!/*/}
      {/*  /!*</IconButton>*!/*/}
      {/*  <IconButton variant="falcon-default" transform="shrink-3" size="sm">*/}
      {/*    목록*/}
      {/*  </IconButton>*/}
      {/*</Card.Footer>*/}
    </Card>
  );
};

export default ProjectBaseInfo;
