import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Row,
  Col,
  Dropdown,
  Card,
  Button,
  Form,
  Badge,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

import AppContext, {
  ActionContext,
  ChatContext,
  CourseContext,
  MapsContext,
  SocketContext
} from 'context/Context';
import { chainAxios } from 'helpers/chainAxios';
import iconAngleRight from 'assets/img/icons/icon-angle-right.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import editAlt from '../../../assets/img/icons/edit-alt.svg';
import moment from 'moment/moment';
import { getColor, isEmpty } from 'helpers/utils';
import IconButton from 'components/common/IconButton';
import FalconCardFooterLink from '../../common/FalconCardFooterLink';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import {
  combine,
  dropEllipsis,
  dropNav
} from 'react-responsive-pagination/narrowBehaviour';

const EventManage = () => {
  const columns = [
    {
      accessor: 'favorite',
      Header: '즐겨찾기',
      headerProps: {
        className: 'text-900 --falcon-gray-100',
        style: { width: '95px' }
      },
      cellProps: {
        style: { width: '95px' }
      },
      Cell: rowData => {
        const { favorite } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <FontAwesomeIcon
              onClick={() => {}}
              icon={favorite ? 'star' : ['far', 'star']}
              // transform="down-2"
              className={classNames(
                { 'text-primary': favorite, 'text-300': !favorite },
                'cursor-pointer'
              )}
            />
          </Flex>
        );
      }
    },
    {
      accessor: 'createDate',
      Header: '발생일시',
      // headerProps: { className: 'text-900' },
      // Cell: rowData => {
      //   const { createDate } = rowData.row.original;
      //   return (
      //     <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
      //       <div className={'fs--1'} style={{ width: '100px' }}>
      //         {moment(createDate).format('YYYY-MM-DD')}
      //       </div>
      //       <div className={'fs--1'} style={{ width: '100px' }}>
      //         {moment(createDate).format('HH:mm:ss')}
      //       </div>
      //     </Flex>
      //   );
      // }
      headerProps: { className: 'text-900', style: { width: '140px' } },
      cellProps: {
        style: { width: '140px' }
      },
      Cell: rowData => {
        const { createDate } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <div className={'fs--1'} style={{ width: '140px' }}>
              {moment(createDate).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'project.value',
      Header: '프로젝트명',
      headerProps: { className: 'text-900', style: { width: '150px' } },
      cellProps: {
        style: { width: '150px' }
      },
      Cell: rowData => {
        const { project } = rowData.row.original;
        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '150px' }}
          >
            {project.value}
          </div>
        );
      }
    },
    {
      accessor: 'eventTarget',
      Header: '이벤트대상',
      headerProps: { className: 'text-900', style: { width: '100px' } },
      cellProps: {
        style: { width: '100px' }
      },
      Cell: rowData => {
        const { eventCode } = rowData.row.original;
        let eventTypeName = '-';

        if (!isEmpty(eventCode) && eventCode.length > 3) {
          let eventType = eventCode.substring(0, 3);

          if (eventType === 'e00') {
            eventTypeName = '사용자';
          }
          if (eventType === 'e01') {
            eventTypeName = '프로젝트';
          }
          if (eventType === 'e02') {
            eventTypeName = '사이트';
          }
          if (eventType === 'e03') {
            eventTypeName = '게이트웨이';
          }
          if (eventType === 'e04') {
            eventTypeName = '센서';
          }
          if (eventType === 'e05') {
            eventTypeName = '센서분석';
          }
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '80px' }}
          >
            {eventTypeName}
          </div>
        );
      }
    },
    {
      accessor: 'eventType',
      Header: '이벤트분류',
      headerProps: { className: 'text-900', style: { width: '100px' } },
      cellProps: {
        style: { width: '100px' }
      },
      Cell: rowData => {
        const { eventCode } = rowData.row.original;
        let eventTypeName = '-';
        if (!isEmpty(eventCode) && eventCode.length > 5) {
          let eventType = eventCode.substring(0, 5);

          if (
            eventType === 'e0000' ||
            eventType === 'e0100' ||
            eventType === 'e0302' ||
            eventType === 'e0405'
          ) {
            eventTypeName = '인증문제';
          }
          if (eventType === 'e0300' || eventType === 'e0400') {
            eventTypeName = '신호불량';
          }
          if (
            eventType === 'e0301' ||
            eventType === 'e0401' ||
            eventType === 'e0402' ||
            eventType === 'e0403'
          ) {
            eventTypeName = '상태불량';
          }
          if (
            eventType === 'e0200' ||
            eventType === 'e0500' ||
            eventType === 'e0501' ||
            eventType === 'e0502' ||
            eventType === 'e0503'
          ) {
            eventTypeName = '위험예측';
          }
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '80px' }}
          >
            {eventTypeName}
          </div>
        );
      }
    },
    {
      accessor: 'eventLevel',
      Header: '심각도',
      headerProps: { className: 'text-900', style: { width: '80px' } },
      cellProps: {
        style: { width: '80px' }
      },
      Cell: rowData => {
        const { eventLevel } = rowData.row.original;
        let badgeColor;
        let badgeName;
        if (eventLevel === '0') {
          badgeName = '심각';
          badgeColor = 'danger';
        } else if (eventLevel === '1') {
          badgeName = '경고';
          badgeColor = 'warning';
        } else if (eventLevel === '2') {
          badgeName = '주의';
          badgeColor = 'info';
        } else if (eventLevel === '3') {
          badgeName = '관찰';
          badgeColor = 'success';
        } else if (eventLevel === '4') {
          badgeName = '알림';
          badgeColor = 'secondary';
        } else {
          badgeName = '-';
          badgeColor = 'dark';
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '80px' }}
          >
            <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
              {badgeName}
            </SoftBadge>
          </div>
        );
      }
    },
    {
      accessor: 'eventStatus',
      Header: '처리상태',
      headerProps: { className: 'text-900', style: { width: '80px' } },
      cellProps: {
        style: { width: '80px' }
      },
      Cell: rowData => {
        const { eventStatus } = rowData.row.original;

        let badgeName = '-';
        let badgeColor = 'dark';

        if (eventStatus === '0') {
          badgeName = '대기';
          badgeColor = 'danger';
        }

        if (eventStatus === '1') {
          badgeName = '완료';
          badgeColor = 'primary';
        }

        if (eventStatus === '2') {
          badgeName = '진행';
          badgeColor = 'warning';
        }

        if (eventStatus === '3') {
          badgeName = '승인';
          badgeColor = 'success';
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '80px' }}
          >
            <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
              {badgeName}
            </SoftBadge>
          </div>
        );
      }
    },
    {
      accessor: 'targetName',
      Header: '발생위치',
      headerProps: { className: 'text-900', style: { width: '150px' } },
      cellProps: {
        style: { width: '150px' }
      },
      Cell: rowData => {
        const { site, facility, gateway, sensor } = rowData.row.original;
        // console.log(rowData.row.original);
        let targetName = '-';

        if (!isEmpty(site)) {
          targetName = '사이트 (' + site.value + ')';
        }
        if (!isEmpty(facility)) {
          targetName = '시설물 (' + facility.value + ')';
        }
        if (!isEmpty(gateway)) {
          targetName = '게이트웨이 (' + gateway.value + ')';
        }
        if (!isEmpty(sensor)) {
          targetName = '센서 (' + sensor.value + ')';
        }

        return (
          <div
            className={'fs--1 text-truncate text-center'}
            style={{ width: '150px' }}
            data-toggle="tooltip"
            data-placement="top"
            title={targetName}
          >
            {targetName}
          </div>
        );
      }
    },
    {
      accessor: 'content',
      Header: '알림내용',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { content } = rowData.row.original;
        // let displayContent = content;
        // if (content.length > 50) {
        //   displayContent = content.substring(0, 50) + '...';
        // }
        return (
          <div
            className={'fs--1 col-12 text-truncate text-start'}
            style={{ width: 'auto' }}
          >
            {content}
          </div>
        );
      }
    },
    {
      accessor: 'actions',
      Header: '조치내역',
      headerProps: { className: 'text-900', style: { width: '50px' } },
      cellProps: {
        style: { width: '50px' }
      },
      Cell: rowData => {
        const { actions } = rowData.row.original;
        if (actions != null) {
          return (
            <div
              className={'fs--1 text-truncate text-center'}
              style={{ width: '50px' }}
            >
              <IconButton
                variant="falcon-primary"
                className="mb-1"
                icon="link"
                transform="shrink-3"
                size="sm"
                onClick={() => onClickMoveProcess(rowData.row.original)}
              ></IconButton>
            </div>
          );
        }
      }
    },
    {
      accessor: 'chatroomId',
      Header: '채팅내역',
      headerProps: { className: 'text-900', style: { width: '50px' } },
      cellProps: {
        style: { width: '50px' }
      },
      Cell: rowData => {
        const { chatroomId } = rowData.row.original;
        if (!!chatroomId) {
          return (
            <div
              className={'fs--1 text-truncate text-center'}
              style={{ width: '50px' }}
            >
              <IconButton
                variant="falcon-primary"
                className="mb-1"
                icon="comments"
                transform="shrink-3"
                size="sm"
                onClick={() => onClickMoveChatRoom(chatroomId)}
              ></IconButton>
            </div>
          );
        }
      }
    }
  ];
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const { setSelectedProject } = useContext(MapsContext);

  const { setIsOpenChatRoom, getChatRoom } = useContext(ChatContext);
  const { setIsOpenActionDetail, setCurrentEvent } = useContext(ActionContext);

  const navigate = useNavigate();
  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);
  const [searchProjects, setSearchProjects] = useState([]);
  const [searchFacilites, setSearchFacilites] = useState([]);
  const [events, setEvents] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 10;

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);
    setSelectedProject({});

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  useEffect(() => {
    getProjects();
    getFacilites();
    getEvents();
  }, []);

  useEffect(() => {
    getEvents();
  }, [currentPage]);

  const onClickMoveProcess = event => {
    // navigate('/event/process');
    setCurrentEvent(event);
    setIsOpenActionDetail(true);
  };

  const onClickMoveChatRoom = chatroomId => {
    // console.log('onClickMoveChatRoom : ', chatroomId);
    getChatRoom(chatroomId);
    // setIsOpenChatRoom(true);
  };

  const getProjects = () => {
    let url = '/api/projects?pageNo=0&pageSize=99999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log("EventManage getProjects : ", response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'EventManage getProjects : ',
          //   response.data.data.projectList.dataList
          // );
          setSearchProjects(response.data.data.projectList.dataList);
        }
      })
      .catch(error => {
        console.log('EventManage getProjects : ', error);
      });
  };

  const getFacilites = () => {
    let url = '/api/facilities?pageNo=0&pageSize=99999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log("EventManage getProjects : ", response);
        if (response.data.resultCode === 200) {
          // console.log(
          //   'EventManage getFacilites : ',
          //   response.data.data.facilityList.dataList
          // );
          setSearchFacilites(response.data.data.facilityList.dataList);
        }
      })
      .catch(error => {
        console.log('EventManage getFacilites : ', error);
      });
  };

  const getEvents = () => {
    // http://112.175.18.232:7000/api/events?pageNo=0&pageSize=20&sort=createDate%24desc
    let url =
      '/api/events?pageNo=' +
      (currentPage - 1) +
      '&pageSize=' +
      _pageSize +
      '&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('EventManage getEvents : ', response);
        if (response.data.resultCode === 200) {
          // console.log('EventManage getEvents : ', response.data.data.eventList);
          let totalElements = response.data.data.eventList.totalElements;
          setTotalPages(Math.ceil(totalElements / _pageSize));
          setTotalElements(totalElements);
          setEvents(response.data.data.eventList.dataList);
        }
      })
      .catch(error => {
        console.log('EventManage getEvents : ', error);
      });
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12} xl={12} xxl={12}>
          <Card className="h-md-100">
            <Card.Header>
              <h5>이벤트 관리</h5>
            </Card.Header>
            <Card.Body>
              <Row
                as={Flex}
                alignItems="center"
                justifyContent="between"
                className={'px-3 pt-3'}
                style={{ backgroundColor: getColor('gray-100') }}
              >
                <Row as={Flex} alignItems="center" justifyContent="start">
                  <Col xs={12} md={2} className={'pb-3'}>
                    {/*<Form.Select size="sm" className="me-md-2">*/}
                    {/*  <option value={'project_all'}>프로젝트 전체</option>*/}
                    {/*  {searchProjects.map((project, index) => (*/}
                    {/*    <option value={project.id}>{project.name}</option>*/}
                    {/*  ))}*/}
                    {/*</Form.Select>*/}
                  </Col>
                  <Col xs={12} md={2} className={'pb-3'}>
                    {/*<Form.Select size="sm" className="me-md-2">*/}
                    {/*  <option value={'facility_all'}>시설물 전체</option>*/}
                    {/*  {searchFacilites.map((facility, index) => (*/}
                    {/*    <option value={facility.id}>{facility.name}</option>*/}
                    {/*  ))}*/}
                    {/*</Form.Select>*/}
                  </Col>
                  <Col xs={12} md={2} className={'pb-3'}>
                    {/*<Form.Select size="sm" className="me-md-2">*/}
                    {/*  <option value={'level_all'}>심각도 전체</option>*/}
                    {/*  <option value={'0'}>심각</option>*/}
                    {/*  <option value={'1'}>경고</option>*/}
                    {/*  <option value={'2'}>주의</option>*/}
                    {/*  <option value={'3'}>관찰</option>*/}
                    {/*  <option value={'4'}>알림</option>*/}
                    {/*</Form.Select>*/}
                  </Col>
                  <Col xs={12} md={2} className={'pb-3'}>
                    {/*<Form.Select size="sm">*/}
                    {/*  <option value={'level_all'}>상태 전체</option>*/}
                    {/*  <option value={'0'}>점검요함</option>*/}
                    {/*  <option value={'1'}>조치완료</option>*/}
                    {/*  <option value={'2'}>재점검필</option>*/}
                    {/*  <option value={'3'}>승인</option>*/}
                    {/*</Form.Select>*/}
                  </Col>
                  <Col xs={12} md={4} className={'pb-3'}>
                    <Row
                      as={Flex}
                      alignItems="center"
                      justifyContent="end"
                      className={'pe-0'}
                    >
                      <Col
                        as={Flex}
                        xs={12}
                        md={8}
                        alignItems="center"
                        direction="row"
                        justifyContent="end"
                      >
                        <AdvanceTableSearchBox
                          table
                          globalFilter={''}
                          setGlobalFilter={() => {}}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className={'px-3 pt-3'}
              >
                <Flex
                  alignItems="end"
                  alignContent={'end'}
                  direction="column"
                  justifyContent="start"
                  className={'fs--1'}
                >
                  총 {totalElements}건
                </Flex>
                <Flex
                  alignItems={'end'}
                  alignContent={'end'}
                  direction="column"
                  justifyContent="end"
                  className={'fs--1'}
                >
                  <Form.Check
                    type="switch"
                    id="checkedSwitch"
                    label="즐겨찾기"
                    // defaultChecked
                  />
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={events}
                sortable
                pagination
                perPage={_pageSize}
              >
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle text-center"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    hover: true,
                    className: 'fs--1 mb-0 overflow-hidden text-center'
                  }}
                />
              </AdvanceTableWrapper>
              {events.length !== _pageSize && (
                <div
                  style={{
                    height:
                      ((_pageSize - events.length) * 57.5).toString() + 'px'
                  }}
                ></div>
              )}
              <div className="mt-3">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                  maxWidth={350}
                  extraClassName={'justify-content-center fs--2'}
                  previousLabel="‹"
                  nextLabel="›"
                  // narrowBehaviour={combine(dropNav, dropEllipsis)}
                />
              </div>
            </Card.Body>
            <Card.Footer style={{ backgroundColor: getColor('gray-100') }}>
              <IconButton
                variant="falcon-default"
                icon="external-link-alt"
                transform="shrink-3"
                size="sm"
              >
                엑셀 내보내기
              </IconButton>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EventManage;
