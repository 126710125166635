import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Col,
  Dropdown,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Tooltip
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SensorDetailTopArea from './component/TopArea';
import { chainAxios } from 'helpers/chainAxios';
import AppContext, { MapsContext } from 'context/Context';
import SensorBaseInfo from './component/BaseInfo';
import SensorEventList from './component/EventList';
import RoadFacilitiesDetailSensorList from '../../road-facilities/detail/component/SensorList';
import RoadFacilitiesDetailPhotoArea from '../../road-facilities/detail/component/PhotoArea';
import SensorFacilities from '../SensorFacilities';
import SensorImages from '../SensorImages';
import SensorEvents from '../SensorEvents';

const SensorDetail = () => {
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);
  const { setSelectedProject } = useContext(MapsContext);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);
    setSelectedProject({});

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  const navigate = useNavigate();
  const params = useParams();

  const [sensor, setSensor] = useState({});

  useEffect(() => {
    getSensor();
  }, [params]);

  const getSensor = () => {
    let url = '/api/sensor/' + params.sensorId;
    chainAxios
      .get(url)
      .then(response => {
        // console.log('ProjectDetail getProject() : ', response);
        if (response.data.resultCode === 200) {
          // console.log(response.data.data.project);
          setSensor(response.data.data.sensor);
        }
      })
      .catch(error => {
        console.log('SensorDetail getSensor() : ', error);
      });
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <SensorDetailTopArea />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <SensorBaseInfo sensor={sensor} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          {/*<SensorEventList sensor={sensor} />*/}
          <SensorEvents sensor={sensor} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={12} xl={8} style={{ minHeight: '100%' }}>
          <SensorFacilities sensor={sensor} />
        </Col>
        <Col md={12} xl={4}>
          <SensorImages sensor={sensor} />
        </Col>
      </Row>
    </>
  );
};

export default SensorDetail;
