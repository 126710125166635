import React, { useRef, useEffect, useState } from 'react';
import { Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, getPastDates, rgbaColor } from 'helpers/utils';
import moment from 'moment/moment';
import { getDates } from 'helpers/utils';
import { chainAxios } from 'helpers/chainAxios';
import { useSlopeAngles } from '../../hook/useMapsDetail';
import FalconCardHeader from '../../../../../common/FalconCardHeader';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  // console.log(params);
  return params
    .map(
      ({ value, borderColor, seriesName, name }, index) =>
        `<span class='text-800'>${name}</span><br/><div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>
        <span class='text-600 fs--1'>${seriesName}: ${
          value === undefined ? '-' : value
        }°</span>`
    )
    .join('<br/>');
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: ['value'],
    show: false
  },
  xAxis: {
    type: 'category',
    data: data.date,
    boundaryGap: true,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => dayjs(value).format('YY-MM-DD\r\n(HH:mm)')
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    // min: function (value) {
    //   return value.min - 0.5;
    // },
    // max: function (value) {
    //   return value.max + 0.5;
    // },
    splitLine: {
      show: true,
      interval: 0.1,
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  dataZoom: [
    {
      show: true,
      type: 'inside',
      filterMode: 'none',
      xAxisIndex: [0],
      startValue: -9999,
      endValue: 100
    }
    // {
    //   show: true,
    //   type: 'inside',
    //   filterMode: 'none',
    //   yAxisIndex: [0],
    //   startValue: -20,
    //   endValue: 20
    // }
  ],
  series: [
    {
      name: '경사각',
      type: 'line',
      data: data.value,
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      markArea: {
        data: [
          [
            {
              name: '정상',
              label: {
                position: 'right' // 영역 상단에 레이블 표시
              },
              yAxis: '1.5',
              itemStyle: {
                color: rgbaColor(getColor('success'), 0.1)
              }
            },
            {
              yAxis: '-1.5'
            }
          ],
          [
            {
              name: '주의',
              label: {
                position: 'right' // 영역 상단에 레이블 표시
              },
              yAxis: '1.5',
              itemStyle: {
                color: rgbaColor(getColor('warning'), 0.1)
              }
            },
            {
              yAxis: '2.12'
            }
          ],
          [
            {
              name: '주의',
              label: {
                position: 'right' // 영역 상단에 레이블 표시
              },
              yAxis: '-1.5',
              itemStyle: {
                color: rgbaColor(getColor('warning'), 0.1)
              }
            },
            {
              yAxis: '-2.12'
            }
          ],
          [
            {
              name: '경고',
              label: {
                position: 'right' // 영역 상단에 레이블 표시
              },
              yAxis: '2.12',
              itemStyle: {
                color: rgbaColor(getColor('danger'), 0.1)
              }
            },
            {
              yAxis: '999'
            }
          ],
          [
            {
              name: '경고',
              label: {
                position: 'right' // 영역 상단에 레이블 표시
              },
              yAxis: '-2.12',
              itemStyle: {
                color: rgbaColor(getColor('danger'), 0.1)
              }
            },
            {
              yAxis: '-999'
            }
          ]
        ]
      },
      // markLine: {
      //   data: [
      //     {
      //       symbol: 'none',
      //       name: '주의',
      //       yAxis: 1.5,
      //       label: {
      //         normal: {
      //           show: false,
      //           position: 'insideEndTop'
      //         }
      //       },
      //       lineStyle: {
      //         normal: {
      //           color: 'orange',
      //           width: 2
      //         }
      //       }
      //     },
      //     {
      //       symbol: 'none',
      //       name: '주의',
      //       yAxis: -1.5,
      //       label: {
      //         normal: {
      //           show: false,
      //           position: 'insideEndTop'
      //         }
      //       },
      //       lineStyle: {
      //         normal: {
      //           color: 'orange',
      //           width: 2
      //         }
      //       }
      //     },
      //     {
      //       symbol: 'none',
      //       name: '경고',
      //       yAxis: 2.12,
      //       label: {
      //         normal: {
      //           show: false,
      //           position: 'insideEndTop'
      //         }
      //       },
      //       lineStyle: {
      //         normal: {
      //           color: 'red',
      //           width: 2
      //         }
      //       }
      //     },
      //     {
      //       symbol: 'none',
      //       name: '경고',
      //       yAxis: -2.12,
      //       label: {
      //         normal: {
      //           show: false,
      //           position: 'insideEndTop'
      //         }
      //       },
      //       lineStyle: {
      //         normal: {
      //           color: 'red',
      //           width: 2
      //         }
      //       }
      //     }
      //   ]
      // },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      }
      // areaStyle: {
      //   color: {
      //     type: 'linear',
      //     x: 0,
      //     y: 0,
      //     x2: 0,
      //     y2: 1,
      //     colorStops: [
      //       {
      //         offset: 0,
      //         color: rgbaColor(getColor('primary'), 0.2)
      //       },
      //       {
      //         offset: 1,
      //         color: rgbaColor(getColor('primary'), 0)
      //       }
      //     ]
      //   }
      // }
    }
  ],
  grid: {
    right: '48px',
    left: '48px',
    bottom: '15%',
    top: '5%',
    containLabel: false
  }
});

const SlopeAnglesChart = ({ selectedSensor }) => {
  const {
    data: slopeAnglesDatas,
    setSlopeAnglesSensorId,
    setSlopeAnglesSearchPeriod
  } = useSlopeAngles();

  const [selectedSearchPeriod, setSelectedSearchPriod] = useState('1개월');
  const [slopAnglesChartData, setSlopAnglesChartData] = useState({});

  useEffect(() => {
    if (!!selectedSensor) {
      setSlopeAnglesSensorId(selectedSensor.id);
    }
  }, [selectedSensor]);

  useEffect(() => {
    makeSlopeAnglesChartData(slopeAnglesDatas);
  }, [slopeAnglesDatas]);

  const makeSlopeAnglesChartData = dataList => {
    let dates = [];
    let values = [];

    dataList.forEach(data => {
      // console.log('makeSlopeAnglesChartData item : ', data);
      dates.push(data.x);
      values.push(data.y1);
    });

    // console.log('makeSlopeAnglesChartData : ', {
    //   date: dates,
    //   value: values
    // });

    setSlopAnglesChartData({
      date: dates,
      value: values
    });
  };

  const onSelectSearchPeriod = value => {
    if (value === '1W') {
      setSelectedSearchPriod('1주일');
    }
    if (value === '1M') {
      setSelectedSearchPriod('1개월');
    }
    if (value === '3M') {
      setSelectedSearchPriod('3개월');
    }
    if (value === '6M') {
      setSelectedSearchPriod('6개월');
    }
    if (value === '1Y') {
      setSelectedSearchPriod('1년');
    }
    if (value === 'ALL') {
      setSelectedSearchPriod('설치일부터');
    }

    setSlopeAnglesSearchPeriod(value);
  };

  return (
    <Card>
      <FalconCardHeader
        title="(수평)경사각"
        light
        titleTag="h6"
        endEl={
          <Dropdown onSelect={onSelectSearchPeriod}>
            <Dropdown.Toggle variant="falcon-default">
              {!!selectedSearchPeriod ? selectedSearchPeriod : '조회 개월 수'}
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item key="1" eventKey="1W">
                1주
              </Dropdown.Item>
              <Dropdown.Item key="2" eventKey="1M">
                1개월
              </Dropdown.Item>
              <Dropdown.Item key="3" eventKey="3M">
                3개월
              </Dropdown.Item>
              <Dropdown.Item key="4" eventKey="6M">
                6개월
              </Dropdown.Item>
              <Dropdown.Item key="5" eventKey="1Y">
                1년
              </Dropdown.Item>
              <Dropdown.Item key="6" eventKey="ALL">
                설치일부터
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
      <Card.Body className="pe-xxl-0">
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(slopAnglesChartData)}
        />
      </Card.Body>
    </Card>
  );
};

export default SlopeAnglesChart;
