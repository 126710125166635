import { isEmpty } from 'helpers/utils';
import Carousel from 'react-bootstrap/Carousel';
import React from 'react';

const PhotoArea = ({ sensorInfo }) => {
  return (
    <>
      {!isEmpty(sensorInfo) &&
      !isEmpty(sensorInfo.images) &&
      sensorInfo.images.length > 0 ? (
        <Carousel>
          {sensorInfo.images.map((img, index) => {
            return (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100 h-100"
                  style={{
                    objectFit: 'cover',
                    maxHeight: '250px'
                  }}
                  src={process.env.REACT_APP_API_SERVER + 'api/file/' + img}
                  alt=""
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : (
        <h5 className={'text-500'}>등록 된 사진이 없습니다.</h5>
      )}
    </>
  );
};

export default PhotoArea;
