import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, isEmpty } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';
import { chainAxios } from 'helpers/chainAxios';
import { MapsContext } from 'context/Context';
import FalconCardHeader from '../../../common/FalconCardHeader';

const SensorManageChart = () => {
  const { selectedProject, setSelectedProject } = useContext(MapsContext);

  const [replusAnalysis, setReplusAnalysis] = useState([]); // 압밀침하분석
  const [reminusAnalysis, setReminusAnalysis] = useState([]); // 지표침하분석

  const [replusRoadAnalysis, setReplusRoadAnalysis] = useState([]);
  const [reminusRoadAnalysis, setReminusRoadAnalysis] = useState([]);
  const [replusFacilityAnalysis, setReplusFacilityAnalysis] = useState([]);
  const [reminusFacilityAnalysis, setReminusFacilityAnalysis] = useState([]);
  const [replusFieldAnalysis, setReplusFieldAnalysis] = useState([]);
  const [reminusFieldAnalysis, setReminusFieldAnalysis] = useState([]);

  useEffect(() => {
    // getProjects();
  }, []);

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      // makeRoadFacilitiesManageChartData([selectedProject]);
      getSensorAnalysisDatas(selectedProject.id);
    }
    if (!!selectedProject && JSON.stringify(selectedProject) === '{}') {
      // getProjects();
      getSensorAnalysisDatas('ALL');
    }
  }, [selectedProject]);

  const getSensorAnalysisDatas = projectId => {
    let url = '';
    if (projectId === 'ALL') {
      url = '/api/sensor/chart/sawStatus2';
    } else {
      // http://112.175.18.232:7000/api/sensor/chart/sawStatus?scope=project&scopeId=3e278d82-0fd8-43a8-a661-9dc3d738660f
      url = '/api/sensor/chart/sawStatus2?scope=project&scopeId=' + projectId;
    }
    // console.log('getSensorAnalysisDatas url : ', url);
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          // console.table(
          //   'SensorManageChart getSensorAnalysisDatas() : ',
          //   response.data.data.status
          // );
          makeSensorAnalysisDatas(response.data.data.status);
        }
      })
      .catch(error => {
        console.log('SensorManageChart getSensorAnalysisDatas() : ', error);
      });
  };

  const makeSensorAnalysisDatas = datas => {
    // console.table('도로 : ', datas.RD);
    let replusRoadDatas = [];
    let reminusRoadDatas = [];
    let replusFacilityDatas = [];
    let reminusFacilityDatas = [];
    let replusFieldDatas = [];
    let reminusFieldDatas = [];

    replusRoadDatas.push({
      id: 1,
      value:
        datas.RD.replus150 !== '' ? datas.RD.replus150.split(',').length : 0,
      items:
        datas.RD.replus150 !== '' ? datas.RD.replus150.split(',').sort() : '',
      name: '150mm이내',
      color: 'primary'
    });
    replusRoadDatas.push({
      id: 2,
      value:
        datas.RD.replus250 !== '' ? datas.RD.replus250.split(',').length : 0,
      items:
        datas.RD.replus250 !== '' ? datas.RD.replus250.split(',').sort() : '',
      name: '250mm이내',
      color: 'warning'
    });
    replusRoadDatas.push({
      id: 3,
      value:
        datas.RD.replus300 !== '' ? datas.RD.replus300.split(',').length : 0,
      items:
        datas.RD.replus300 !== '' ? datas.RD.replus300.split(',').sort() : '',
      name: '250mm이상',
      color: 'danger'
    });

    reminusRoadDatas.push({
      id: 1,
      value:
        datas.RD.reminus150 !== '' ? datas.RD.reminus150.split(',').length : 0,
      items:
        datas.RD.reminus150 !== '' ? datas.RD.reminus150.split(',').sort() : '',
      name: '150mm이내',
      color: 'primary'
    });
    reminusRoadDatas.push({
      id: 2,
      value:
        datas.RD.reminus250 !== '' ? datas.RD.reminus250.split(',').length : 0,
      items:
        datas.RD.reminus250 !== '' ? datas.RD.reminus250.split(',').sort() : '',
      name: '250mm이내',
      color: 'warning'
    });
    reminusRoadDatas.push({
      id: 3,
      value:
        datas.RD.reminus300 !== '' ? datas.RD.reminus300.split(',').length : 0,
      items:
        datas.RD.reminus300 !== '' ? datas.RD.reminus300.split(',').sort() : '',
      name: '250mm이상',
      color: 'danger'
    });

    // console.table('시설물 : ', datas.FC);
    replusFacilityDatas.push({
      id: 1,
      value: datas.FC.replus15 !== '' ? datas.FC.replus15.split(',').length : 0,
      items:
        datas.FC.replus15 !== '' ? datas.FC.replus15.split(',').sort() : '',
      name: '15mm이내',
      color: 'primary'
    });
    replusFacilityDatas.push({
      id: 2,
      value: datas.FC.replus25 !== '' ? datas.FC.replus25.split(',').length : 0,
      items:
        datas.FC.replus25 !== '' ? datas.FC.replus25.split(',').sort() : '',
      name: '25mm이내',
      color: 'warning'
    });
    replusFacilityDatas.push({
      id: 3,
      value: datas.FC.replus30 !== '' ? datas.FC.replus30.split(',').length : 0,
      items:
        datas.FC.replus30 !== '' ? datas.FC.replus30.split(',').sort() : '',
      name: '25mm이상',
      color: 'danger'
    });

    reminusFacilityDatas.push({
      id: 1,
      value:
        datas.FC.reminus15 !== '' ? datas.FC.reminus15.split(',').length : 0,
      items:
        datas.FC.reminus15 !== '' ? datas.FC.reminus15.split(',').sort() : '',
      name: '15mm이내',
      color: 'primary'
    });
    reminusFacilityDatas.push({
      id: 2,
      value:
        datas.FC.reminus25 !== '' ? datas.FC.reminus25.split(',').length : 0,
      items:
        datas.FC.reminus25 !== '' ? datas.FC.reminus25.split(',').sort() : '',
      name: '25mm이내',
      color: 'warning'
    });
    reminusFacilityDatas.push({
      id: 3,
      value:
        datas.FC.reminus30 !== '' ? datas.FC.reminus30.split(',').length : 0,
      items:
        datas.FC.reminus30 !== '' ? datas.FC.reminus30.split(',').sort() : '',
      name: '25mm이상',
      color: 'danger'
    });

    // console.table('공사현장 : ', datas.FD);
    replusFieldDatas.push({
      id: 1,
      value: datas.FD.replus15 !== '' ? datas.FD.replus15.split(',').length : 0,
      items:
        datas.FD.replus15 !== '' ? datas.FD.replus15.split(',').sort() : '',
      name: '15mm이내',
      color: 'primary'
    });
    replusFieldDatas.push({
      id: 2,
      value: datas.FDreplus25 !== '' ? datas.FD.replus25.split(',').length : 0,
      items:
        datas.FD.replus25 !== '' ? datas.FD.replus25.split(',').sort() : '',
      name: '25mm이내',
      color: 'warning'
    });
    replusFieldDatas.push({
      id: 3,
      value: datas.FD.replus30 !== '' ? datas.FD.replus30.split(',').length : 0,
      items:
        datas.FD.replus30 !== '' ? datas.FD.replus30.split(',').sort() : '',
      name: '25mm이상',
      color: 'danger'
    });

    reminusFieldDatas.push({
      id: 1,
      value:
        datas.FD.reminus15 !== '' ? datas.FD.reminus15.split(',').length : 0,
      items:
        datas.FD.reminus15 !== '' ? datas.FD.reminus15.split(',').sort() : '',
      name: '15mm이내',
      color: 'primary'
    });
    reminusFieldDatas.push({
      id: 2,
      value:
        datas.FD.reminus25 !== '' ? datas.FD.reminus25.split(',').length : 0,
      items:
        datas.FD.reminus25 !== '' ? datas.FD.reminus25.split(',').sort() : '',
      name: '25mm이내',
      color: 'warning'
    });
    reminusFieldDatas.push({
      id: 3,
      value:
        datas.FD.reminus30 !== '' ? datas.FD.reminus30.split(',').length : 0,
      items:
        datas.FD.reminus30 !== '' ? datas.FD.reminus30.split(',').sort() : '',
      name: '25mm이상',
      color: 'danger'
    });

    setReplusRoadAnalysis(replusRoadDatas);
    setReminusRoadAnalysis(reminusRoadDatas);
    setReplusFacilityAnalysis(replusFacilityDatas);
    setReminusFacilityAnalysis(reminusFacilityDatas);
    setReplusFieldAnalysis(replusFieldDatas);
    setReminusFieldAnalysis(reminusFieldDatas);
  };

  const radius = ['100%', '65%'];

  const getOptions = (data, text, radius) => ({
    color: [getColor('primary'), getColor('warning'), getColor('danger')],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => {
        // console.log(params);
        return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
          params.data.color
        )}"></div><span class='text-600 fs--1'><strong>${
          params.data.name
        }:</strong> ${params.value}개소</span>
        ${params.data.items !== '' ? `<br/>` : ''}
        ${
          params.data.items !== ''
            ? `<span class='text-600 fs--2'>${params.data.items}</span>`
            : ''
        }`;

        // ${
        //   params.data.items !== ''
        //     ? params.data.items.map((item, index) => {
        //         return `<span class='text-600 fs--1'>${item}</span><br/>`;
        //       })
        //     : ''
        // }`;
      }
    },
    series: [
      {
        name: text,
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: '0.8333333333rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const replusRoadTotalText =
    '압밀침하분석\r\n' +
    replusRoadAnalysis.reduce((acc, val) => val.value + acc, 0) +
    '개소';
  const reminusRoadTotalText =
    '지표침하분석\r\n' +
    reminusRoadAnalysis.reduce((acc, val) => val.value + acc, 0) +
    '개소';
  const replusFacilityTotalText =
    '압밀침하분석\r\n' +
    replusFacilityAnalysis.reduce((acc, val) => val.value + acc, 0) +
    '개소';
  const reminusFacilityTotalText =
    '지표침하분석\r\n' +
    reminusFacilityAnalysis.reduce((acc, val) => val.value + acc, 0) +
    '개소';
  const replusFieldTotalText =
    '압밀침하분석\r\n' +
    replusFieldAnalysis.reduce((acc, val) => val.value + acc, 0) +
    '개소';
  const reminusFieldTotalText =
    '지표침하분석\r\n' +
    reminusFieldAnalysis.reduce((acc, val) => val.value + acc, 0) +
    '개소';

  const SensorItem = ({ item, index }) => {
    const { name, color, value } = item;
    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`px-2 fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </p>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <div>{value} 개소</div>
      </Flex>
    );
  };

  return (
    // <Card className="h-md-100">
    //   <Card.Body>
    //     <Row className="g-0 h-100">
    <>
      <Flex
        direction="row"
        justifyContent="start"
        className="rounded-1 h-100"
        alignItems="center"
      >
        <h6 className="mt-1">센서 관리 현황</h6>
      </Flex>
      <Flex
        direction="column"
        justifyContent="between"
        className="rounded-1 h-100"
        alignItems="center"
      >
        <h6 className={'fs--1 text-900'}>
          {!isEmpty(selectedProject) ? selectedProject.name : '전체 프로젝트'}
        </h6>
      </Flex>
      <Flex
        direction="row"
        justifyContent="start"
        className="rounded-1 h-100 "
        alignItems="center"
      >
        <h6 className="mt-1">도로</h6>
      </Flex>
      <Row className="justify-content-between g-0 pb-2 px-2">
        <Col xs={6} xxl className="pe-3">
          {replusRoadAnalysis.map((item, index) => (
            <SensorItem item={item} index={index} key={index} />
          ))}
        </Col>
        <Col xs="auto">
          <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions(
                replusRoadAnalysis,
                replusRoadTotalText,
                radius
              )}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-between g-0 px-2">
        <Col xs={6} xxl className="pe-3">
          {reminusRoadAnalysis.map((item, index) => (
            <SensorItem item={item} index={index} key={index} />
          ))}
        </Col>
        <Col xs="auto">
          <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions(
                reminusRoadAnalysis,
                reminusRoadTotalText,
                radius
              )}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Col>
      </Row>
      <Flex
        direction="row"
        justifyContent="start"
        className="rounded-1 h-100 "
        alignItems="center"
      >
        <h6 className="mt-1">시설물</h6>
      </Flex>
      <Row className="justify-content-between g-0 pb-2 px-2">
        <Col xs={6} xxl className="pe-3">
          {replusFacilityAnalysis.map((item, index) => (
            <SensorItem item={item} index={index} key={index} />
          ))}
        </Col>
        <Col xs="auto">
          <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions(
                replusFacilityAnalysis,
                replusFacilityTotalText,
                radius
              )}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-between g-0 px-2">
        <Col xs={6} xxl className="pe-3">
          {reminusFacilityAnalysis.map((item, index) => (
            <SensorItem item={item} index={index} key={index} />
          ))}
        </Col>
        <Col xs="auto">
          <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions(
                reminusFacilityAnalysis,
                reminusFacilityTotalText,
                radius
              )}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Col>
      </Row>
      <Flex
        direction="row"
        justifyContent="start"
        className="rounded-1 h-100 "
        alignItems="center"
      >
        <h6 className="mt-1">공사현장</h6>
      </Flex>
      <Row className="justify-content-between g-0 pb-2 px-2">
        <Col xs={6} xxl className="pe-3">
          {replusFieldAnalysis.map((item, index) => (
            <SensorItem item={item} index={index} key={index} />
          ))}
        </Col>
        <Col xs="auto">
          <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions(
                replusFieldAnalysis,
                replusFieldTotalText,
                radius
              )}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-between g-0 px-2">
        <Col xs={6} xxl className="pe-3">
          {reminusFieldAnalysis.map((item, index) => (
            <SensorItem item={item} index={index} key={index} />
          ))}
        </Col>
        <Col xs="auto">
          <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions(
                reminusFieldAnalysis,
                reminusFieldTotalText,
                radius
              )}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Col>
      </Row>
    </>
    //     </Row>
    //   </Card.Body>
    // </Card>
  );
};

export default SensorManageChart;
