import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getChartSeriesColor, getColor, isEmpty } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';

const SensorProcessSummaryChart = ({ title, chartData }) => {
  const [summaryShare, setSummaryShare] = useState([]);

  useEffect(() => {
    if (!isEmpty(chartData)) {
      makeSummaryChartData(chartData);
    }
  }, [chartData]);

  const makeSummaryChartData = data => {
    // console.log('SensorProcessSummaryChart : ', data);
    const seriesColors = [
      'primary',
      'danger',
      'warning',
      'success',
      'secondary',
      'info'
    ];

    let makeShare = [];
    let sortKey = [];
    let iNum = 0;

    Object.keys(data.summary).forEach((key, index) => {
      if (key.toUpperCase() !== 'X' && key.toUpperCase() !== 'CATEGORY') {
        sortKey.push(key);
      }
    });

    if (sortKey.length === 0) {
      Object.keys(data.chart.legend).forEach((key, index) => {
        if (key.toUpperCase() !== 'X' && key.toUpperCase() !== 'CATEGORY') {
          sortKey.push(key);
        }
      });

      sortKey.sort().forEach((key, index) => {
        makeShare.push({
          id: key,
          name: data.chart.legend[key],
          value: 0,
          color: seriesColors[iNum]
        });
        iNum++;
      });
    } else {
      sortKey.sort().forEach((summaryKey, index) => {
        Object.keys(data.chart.legend).forEach((legendKey, index) => {
          if (summaryKey.toUpperCase() === legendKey.toUpperCase()) {
            // console.log(data.chart.legend[legendKey]);
            makeShare.push({
              id: legendKey,
              name: data.chart.legend[legendKey],
              value: data.summary[summaryKey],
              color: seriesColors[iNum]
            });
          }
        });
        iNum++;
      });
    }

    // console.log(makeShare);
    setSummaryShare(makeShare);
  };

  const radius = ['100%', '65%'];

  const getOptions = (data, radius) => ({
    color: [
      getColor('primary'),
      getColor('danger'),
      getColor('warning'),
      getColor('success'),
      getColor('secondary'),
      getColor('info')
    ],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => {
        // console.log(params);
        return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
          params.data.color
        )}"></div><span class='text-600 fs--1'><strong>${
          params.data.name
        }:</strong> ${params.value}건</span>`;
      }
    },
    series: [
      {
        name: total,
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}건',
          fontSize: '1rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const total = summaryShare.reduce((acc, val) => val.value + acc, 0);

  const SummaryItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`px-4 fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </p>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <div>{value} 건</div>
      </Flex>
    );
  };

  return (
    // <Card className="h-md-100">
    //   <Card.Body>
    //     <Row className="g-0 h-100">
    <>
      <h6 className="mt-1 text-center pb-3">{title}</h6>
      <Flex
        direction="column"
        justifyContent="center"
        className="rounded-1 mb-1"
        alignItems="center"
      >
        <BasicECharts
          echarts={echarts}
          options={getOptions(summaryShare, radius)}
          style={{ width: '10rem', height: '10rem' }}
          // style={{ width: '10rem', height: '15.8rem' }}
        />
      </Flex>
      <Col className="px-2 text-center">
        {summaryShare.map((item, index) => (
          <SummaryItem item={item} index={index} key={index} total={total} />
        ))}
      </Col>
    </>
    //     </Row>
    //   </Card.Body>
    // </Card>
  );
};

export default SensorProcessSummaryChart;
