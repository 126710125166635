import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, getDates, isEmpty, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import FalconCardHeader from '../../../common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import { useSensorSendData } from '../hook/useManageSensor';
import moment from 'moment/moment';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  return params
    .map(
      ({ value, borderColor, name }, index) =>
        `${
          index === 0 ? `<span class='text-800 fs--1'>${name}</span><br/>` : ''
        }` +
        `<div class="dot me-1 fs--1 d-inline-block" style="background-color: ${borderColor}"></div>
              <span class='text-600 fs--1'>${
                index === 0 ? '총 전송수' : '에러전송수'
              }: ${value}건</span>`
    )
    .join('<br/>');
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: ['allSendCount', 'errorSendCount'],
    show: true,
    type: 'scroll',
    orient: 'horizontal',
    // orient: 'vertical',

    right: 10,
    top: 0,
    bottom: 20,
    formatter: function (name) {
      if (name === 'allSendCount') {
        return '총 전송수';
      } else {
        return '에러전송수';
      }
    }
  },
  xAxis: {
    type: 'category',
    data: data.date,
    boundaryGap: true,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => dayjs(value).format('YY-MM-DD')
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  dataZoom: [
    {
      show: true,
      type: 'inside',
      filterMode: 'none',
      xAxisIndex: [0],
      startValue: -20,
      endValue: 20
    }
    // {
    //   show: true,
    //   type: 'inside',
    //   filterMode: 'none',
    //   yAxisIndex: [0],
    //   startValue: -20,
    //   endValue: 20
    // }
  ],
  series: [
    {
      name: 'allSendCount',
      type: 'line',
      // smooth: true,
      data: data.allSendCount,
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      },
      animationDelay: function (idx) {
        // console.log(idx);
        // console.log(data.allSendCount);
        return idx * 1000;
      },
      animationEasingUpdate: 'linear',
      areaStyle: {
        color: {
          type: 'cubicIn',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    },
    {
      name: 'errorSendCount',
      type: 'line',
      // smooth: true,
      data: data.errorSendCount,
      lineStyle: { color: rgbaColor(getColor('danger'), 0.3) },
      itemStyle: {
        borderColor: rgbaColor(getColor('danger'), 0.6),
        borderWidth: 2
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      },
      animationDelay: function (idx) {
        return idx * 100;
      },
      animationEasingUpdate: 'linear'
    }
  ],
  grid: {
    right: '6%',
    left: '7%',
    bottom: '15%',
    top: '15%',
    containLabel: false
  }
});

const SensorDataSend = ({ sensor, searchStartDate, searchEndDate }) => {
  const {
    data: sendDatas,
    isLoading,
    isFetching,
    setSensorId,
    setStartDate,
    setEndDate
  } = useSensorSendData();

  const chartRef = useRef(null);

  const [dataSendChartData, setDataSendChartData] = useState([]);

  useEffect(() => {
    if (
      !isEmpty(sensor) &&
      !isEmpty(searchStartDate) &&
      !isEmpty(searchEndDate)
    ) {
      setSensorId(sensor.id);
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    }
  }, [sensor, searchStartDate, searchEndDate]);

  useEffect(() => {
    if (!isEmpty(sendDatas)) {
      makeSendChartData(sendDatas);
    }
  }, [sendDatas]);

  const makeSendChartData = datas => {
    let startDate = moment(searchStartDate, 'YYYY-MM-DD');
    let endDate = moment(searchEndDate, 'YYYY-MM-DD');

    let chartDates = [];
    let chartAllSendCount = [];
    let chartErrorSendCount = [];

    let dates = getDates(startDate, endDate);

    dates.forEach(date => {
      let convertDate = moment(date).format('YYYY-MM-DD');
      chartDates.push(convertDate);
      let trafficData = datas.filter(data => data['x'] === convertDate);

      if (!!trafficData && trafficData.length > 0) {
        chartAllSendCount.push(trafficData[0].y1);
        chartErrorSendCount.push(trafficData[0].y2);
      } else {
        chartAllSendCount.push('0');
        chartErrorSendCount.push('0');
      }
    });

    setDataSendChartData({
      date: chartDates,
      allSendCount: chartAllSendCount,
      errorSendCount: chartErrorSendCount
    });
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="센서 기간별 데이터 전송횟수"
        light
        titleTag="h6"
      />
      <Card.Body className="pe-xxl-0">
        <ReactEChartsCore
          ref={chartRef}
          echarts={echarts}
          option={getOptions(dataSendChartData)}
          notMerge={true}
        />
      </Card.Body>
    </Card>
  );
};

// DataTrafficChart.propTypes = {
//   data: PropTypes.shape({
//     date: PropTypes.array,
//     allSendCount: PropTypes.array,
//     errorSendCount: PropTypes.array
//   })
// };

export default SensorDataSend;
