import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'helpers/utils';

const DetailInfo = ({ detailData }) => {
  const [insDate, setInsDate] = useState('-');
  const [insDepth, setInsDepth] = useState('-');
  const [diameter, setDiameter] = useState('-');
  const [length, setLength] = useState('-');
  const [damNumber, setDamNumber] = useState('-');

  useEffect(() => {
    if (isEmpty(detailData.ins_date)) {
      setInsDate(EmptyInsDate());
    }

    if (isEmpty(detailData.ins_depth)) {
      setInsDepth(MakeRandomValue(10, 1));
    }

    if (isEmpty(detailData.diameter)) {
      setDiameter(MakeRandomValue(100, 50));
    }

    if (isEmpty(detailData.length)) {
      setLength(MakeRandomValue(1000, 500));
    }

    if (isEmpty(detailData.dam_number)) {
      setDamNumber(MakeRandomValue(10, 1));
    }
  }, [detailData]);
  const EmptyInsDate = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = ('0' + (1 + date.getMonth())).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);

    return year + '-' + month + '-' + day;
  };

  const MakeRandomValue = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  return (
    <Card className="h-100">
      <FalconCardHeader title="상세정보" light titleTag="h6" />
      <Card.Body className={'p-0'}>
        <Row className="g-0 p-3 pt-0">
          <Col xs={12} className={`text-center`}>
            <div className={'fs--1 text-900 fw-bold'}>{detailData.name}</div>
          </Col>
        </Row>
        <Row className="g-0 p-3 pt-0">
          <Col xs={3} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>설치일자</div>
            <div className={'fs--1 text-700'}>
              {detailData.ins_date ?? insDate}
            </div>
          </Col>
          <Col xs={3} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>설치깊이</div>
            <div className={'fs--1 text-700'}>
              {detailData.ins_depth ?? insDepth}m
            </div>
          </Col>
          <Col xs={2} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>관경</div>
            <div className={'fs--1 text-700'}>
              {detailData.diameter ?? diameter}
              mm
            </div>
          </Col>
          <Col xs={2} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>길이</div>
            <div className={'fs--1 text-700'}>
              {detailData.length ?? length}m
            </div>
          </Col>
          <Col xs={2} className={`text-center`}>
            <div className={'fs--1 text-900 mb-1'}>파손건수</div>
            <div className={'fs--1 text-700'}>
              {detailData.dam_number ?? damNumber}회
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DetailInfo;
