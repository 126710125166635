import React, { useState, useEffect, useContext, useRef } from 'react';
import Flex from 'components/common/Flex';

import { Button, Modal, Row, Col, Tabs, Tab, Card, Nav } from 'react-bootstrap';
import { chainAxios } from 'helpers/chainAxios';
import AverageReminusChart from './chart/AverageDailyMeanReminusChart';
import AverageSubsChart from './chart/AverageDailyMeanSubsChart';
import AverageDailyMeanReminusChart from './chart/AverageDailyMeanReminusChart';
import AverageDailyMeanSubsChart from './chart/AverageDailyMeanSubsChart';
import AverageDailyReminusChart from './chart/AverageDailyReminusChart';
import { getChartSeriesColor } from 'helpers/utils';
import AverageDailySubsChart from './chart/AverageDailySubsCharts';
import { useReactToPrint } from 'react-to-print';
import Logo from 'components/common/Logo';
import logo from 'assets/img/illustrations/geostr.png';

const MonitoringReport = ({
  params,
  detailModalShow,
  detailModalShowEvent
}) => {
  const printAreaRef = useRef();

  const dailyMeanReminusChartRef = useRef(null);
  const [dailyMeanReminusChartDatas, setDailyMeanReminusChartDatas] = useState(
    {}
  );

  const dailyMeanSubsChartRef = useRef(null);
  const [dailyMeanSubsChartDatas, setDailyMeanSubsChartDatas] = useState({});

  const dailyReminusChartRef = useRef(null);
  const [dailyReminusChartDatas, setDailyReminusChartDatas] = useState({});

  const dailySubsChartRef = useRef(null);
  const [dailySubsChartDatas, setDailySubsChartDatas] = useState({});

  useEffect(() => {
    getDailyMeanReportDatas('saw_reminus');
    getDailyMeanReportDatas('saw_subs');

    getDailyReportDatas('saw_reminus');
    getDailyReportDatas('saw_subs');
  }, [params]);

  const pageStyle = `
    @media print {
      .non-printable-section {
        display: none;
      }
    }
    /* 페이지 여백 설정 */
    @page {
      margin: 1in; /* 모든 방향에 1인치 여백 설정 */
    }
    
    /* page-break 클래스가 있는 요소에서 페이지를 나눕니다 */
    .page-break {
      page-break-before: always;
      page-break-inside: avoid;
    }
    
    /* 인쇄 영역 내부 여백 설정 */
    .printable-area {
      padding: 0in; /* 인쇄 영역 내부 여백 */
    }
  `;

  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
    pageStyle: pageStyle,
    documentTitle:
      '[' + params.month + '] 지반 모니터링 보고서 (' + params.scopeName + ')' // 파일명을 지정합니다.
  });

  const getDailyReportDatas = item => {
    // https://gms.chainreum.com/api/sensor/chart/daily?scope=project&scopeId=1&startDate=2024-07-01&endDate=2024-07-31&item=saw_reminus
    let url =
      '/api/sensor/chart/daily?scope=' +
      params.scope +
      '&scopeId=' +
      params.scopeId +
      '&startDate=' +
      params.start +
      '&endDate=' +
      params.end +
      '&item=' +
      item;

    chainAxios
      .get(url)
      .then(response => {
        // console.log('facilityInfo : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getDailyReportDatas : ', response.data);
          if (item === 'saw_reminus') {
            makeDailyReminusChartDatas(response.data.data.chart);
          }

          if (item === 'saw_subs') {
            makeDailySubsChartDatas(response.data.data.chart);
          }
        }
      })
      .catch(error => {
        console.log('getDailyReportDatas ERROR : ', error);
      });
  };

  const makeDailySubsChartDatas = subsDatas => {
    let orginDates = [];
    let orginLegend = [];

    const seriesColors = getChartSeriesColor();

    Object.keys(subsDatas.legend).forEach((key, index) => {
      if (key !== 'x') {
        let legendValue = subsDatas.legend[key];
        let color = 'blue';
        if (index !== 0) {
          color = seriesColors[(index - 1) % seriesColors.length];
        }
        orginLegend.push({
          key: key,
          value: legendValue.length === 1 ? '0' + legendValue : legendValue,
          color: color
        });
      }

      if (key === 'x') {
        subsDatas.chartData.forEach(data => {
          orginDates.push(data[key]);
        });
      }
    });

    let setDates = new Set(orginDates);
    let seriesDatas = [];
    let legendDatas = [];

    let sortOrginLegend = orginLegend.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;

      return 0;
    });

    sortOrginLegend.forEach(legend => {
      legendDatas.push(legend.value);
      let values = [];
      setDates.forEach(date => {
        let filterData = subsDatas.chartData.filter(
          chart => chart['x'] === date && !!chart[legend.key]
        );
        if (filterData.length > 0) {
          values.push(filterData[0][legend.key]);
        } else {
          values.push(null);
        }
      });

      seriesDatas.push({
        key: legend.key,
        name: legend.value,
        color: legend.color,
        data: values
      });
    });

    // console.log('makeDailySubsChartDatas : ', {
    //   date: Array.from(setDates),
    //   legendData: legendDatas,
    //   seriesDatas: seriesDatas
    // });

    setDailySubsChartDatas({
      date: Array.from(setDates),
      legendData: legendDatas,
      seriesDatas: seriesDatas
    });
  };

  const makeDailyReminusChartDatas = reminusDatas => {
    let orginDates = [];
    let orginLegend = [];

    const seriesColors = getChartSeriesColor();
    Object.keys(reminusDatas.legend).forEach((key, index) => {
      if (key !== 'x') {
        let legendValue = reminusDatas.legend[key];
        let color = 'blue';
        if (index !== 0) {
          color = seriesColors[(index - 1) % seriesColors.length];
        }
        orginLegend.push({
          key: key,
          value: legendValue.length === 1 ? '0' + legendValue : legendValue,
          color: color
        });
      }

      if (key === 'x') {
        reminusDatas.chartData.forEach(data => {
          orginDates.push(data[key]);
        });
      }
    });

    let setDates = new Set(orginDates);
    let seriesDatas = [];
    let legendDatas = [];

    let sortOrginLegend = orginLegend.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;

      return 0;
    });

    sortOrginLegend.forEach(legend => {
      legendDatas.push(legend.value);
      let values = [];
      setDates.forEach(date => {
        let filterData = reminusDatas.chartData.filter(
          chart => chart['x'] === date && !!chart[legend.key]
        );
        if (filterData.length > 0) {
          values.push(filterData[0][legend.key]);
        } else {
          values.push(null);
        }
      });

      seriesDatas.push({
        key: legend.key,
        name: legend.value,
        color: legend.color,
        data: values
      });
    });

    // console.log('makeDailyReminusChartDatas : ', {
    //   date: Array.from(setDates),
    //   legendData: legendDatas,
    //   seriesDatas: seriesDatas
    // });

    setDailyReminusChartDatas({
      date: Array.from(setDates),
      legendData: legendDatas,
      seriesDatas: seriesDatas
    });
  };

  const getDailyMeanReportDatas = item => {
    // https://gms.chainreum.com/api/sensor/chart/dailyMean?scope=project&scopeId=1&startDate=2024-07-01&endDate=2024-07-31&items=saw_reminus
    let url =
      '/api/sensor/chart/dailyMean?scope=' +
      params.scope +
      '&scopeId=' +
      params.scopeId +
      '&startDate=' +
      params.start +
      '&endDate=' +
      params.end +
      '&items=' +
      item;
    chainAxios
      .get(url)
      .then(response => {
        // console.log('facilityInfo : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getDailyMeanReportDatas : ', response.data);
          if (item === 'saw_reminus') {
            makeDailyMeanReminusChartDatas(response.data.data.chart);
          }

          if (item === 'saw_subs') {
            makeDailyMeanSubsChartDatas(response.data.data.chart);
          }
        }
      })
      .catch(error => {
        console.log('getDailyMeanReportDatas ERROR : ', error);
      });
  };

  const makeDailyMeanSubsChartDatas = subsDatas => {
    let orginDates = [];
    let orginValues = [];

    let sortSubsDatas = subsDatas.chartData.sort((a, b) => {
      if (a.x < b.x) return -1;
      if (a.x > b.x) return 1;

      return 0;
    });

    sortSubsDatas.forEach(data => {
      orginDates.push(data.x);
      orginValues.push(data.y1);
    });

    // console.log('makeSubsChartDatas : ', {
    //   dates: orginDates,
    //   avgSubs: orginValues
    // });

    setDailyMeanSubsChartDatas({
      dates: orginDates,
      avgSubs: orginValues
    });
  };

  const makeDailyMeanReminusChartDatas = reminusDatas => {
    let orginDates = [];
    let orginValues = [];

    let sortReminusDatas = reminusDatas.chartData.sort((a, b) => {
      if (a.x < b.x) return -1;
      if (a.x > b.x) return 1;

      return 0;
    });

    sortReminusDatas.forEach(data => {
      orginDates.push(data.x);
      orginValues.push(data.y1);
    });

    // console.log('makeReminusChartDatas : ', {
    //   dates: orginDates,
    //   avgReminus: orginValues
    // });

    setDailyMeanReminusChartDatas({
      dates: orginDates,
      avgReminus: orginValues
    });
  };

  return (
    <>
      {/* 모달 바디에 대한 스타일링을 위한 CSS */}
      <style>
        {`
          .modal-body-custom {
            max-height: 70vh; /* 뷰포트 높이의 80%로 최대 높이 설정 */
            overflow-y: auto; /* 내용이 넘치면 스크롤 표시 */
          }
          .separator {
            width: 100%;
            margin: 30px auto;
            border-top: 10px solid red;
          }
          .bottom-separator {
            width: 100%;
            margin: 30px auto;
            border-top: 10px solid black;
          }
        `}
      </style>
      <Modal
        size="xl"
        show={detailModalShow}
        onHide={detailModalShowEvent}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            [{params.month}] 지반 모니터링 보고서 ({params.scopeName})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <Row className={'g-3'} ref={printAreaRef}>
            <div class="text-left" className={'mb-8'}>
              <h3>고양시</h3>
            </div>
            <div className="align-items-center">
              <Col>
                <div class="separator"></div>
                <h1 className={'mt-6 text-center'}>지반 모니터링 보고서</h1>
                <h2 className={'mt-4 text-center'}>{params.scopeName}</h2>
                <h4 className={'mt-4 mb-6 text-center'}>
                  {params.scopeAddress}
                </h4>
                <div class="bottom-separator"></div>
              </Col>
              <Col>
                <Row style={{ marginTop: '46rem', marginBottom: '3rem' }}>
                  <Col xs={5} className={'text-end'}>
                    <img src={logo} alt="Logo" width="60px" height="60px" />
                  </Col>
                  <Col xs={7} className={'text-start'}>
                    <h4>지오스트(주)</h4>
                    <h4>Geo Structure Co., Ltd</h4>
                  </Col>
                </Row>
              </Col>
            </div>
            <hr class={'non-printable-section'} />
            <h3 class={'page-break'}>3. 모니터링 통계</h3>
            <dl>
              <dt>
                <h4>3.1 게이트웨이별 침하량 • 침하각 통계</h4>
              </dt>
              <dd class={'ms-3'}>
                <h5 style={{ lineHeight: '1.6' }}>
                  평균 침하량이 감소하는 경우, 외부 하중으로 상부 지표면에
                  압밀이 진행 되거나 상부 지표면 침하가 진행되고 있다고 추정할
                  수 있다. 평균 침하량 이 증가하는 경우, 하부 지반의 토사가 유실
                  중이거나 또는 지하수위 변화에 따른 지반 침하가 진행되고 있다고
                  추정할 수 있다.
                  <br />
                  만약 토사 유실에 수직한 방향 이외의 방향성이 존재할 경우,
                  침하각이 유의미하게 증가할 수 있으므로 이는 침하각 그래프를
                  살펴보아야 한다.
                </h5>
              </dd>
            </dl>
            <br />
            <dl>
              <dt class={'ms-3'}>
                <h4>1) 게이트웨이 ID "0003760842"(V2.2)</h4>
              </dt>
              <dd class={'ms-4 pb-2'}>
                <h5>
                  데이터 분석 시작일로부터 종료일까지의 전체 평균
                  침하량/침하각의 변화는 아래 그래프와 같다.
                </h5>
              </dd>
              <dd>
                <AverageDailyMeanReminusChart
                  data={dailyMeanReminusChartDatas}
                  ref={dailyMeanReminusChartRef}
                />
              </dd>
              <dd>
                <AverageDailyMeanSubsChart
                  data={dailyMeanSubsChartDatas}
                  ref={dailyMeanSubsChartRef}
                />
              </dd>
            </dl>
            <hr class={'non-printable-section'} />
            <dl class={'page-break'}>
              <dt>
                <h4>3.2 침하 위험 요소 분석</h4>
              </dt>
              <dd class={'ms-3'}>
                <h5 style={{ lineHeight: '1.6' }}>
                  지반 측정장치 개별의 침하량과 침하각의 변화를 확인하여
                  국부적인 지 반 변위를 확인할 수 있다. 여기서는 지반 측정장치별
                  지반 데이터를 바탕 으로 침하 위험 요소를 두 범주(침하량,
                  침하각)으로 나누어 분석한다.
                </h5>
                <ul>
                  <li>
                    <h5>
                      침하량 : 최종 침하량, 침하량 기울기, 침하량 표준편차
                    </h5>
                  </li>
                  <li>
                    <h5>침하각 : 최종 침하각, 최종 방향각, 침하각 표준편차</h5>
                  </li>
                </ul>
                <h5>
                  각 지반 측정장치별 위험 요소 분석 결과는 결론 5.1에서 표로
                  정리한다.
                </h5>
              </dd>
            </dl>
            <br />
            <dl>
              <dt class={'ms-3'}>
                <h4>1) 게이트웨이 ID "0003760842"(V2.2)</h4>
              </dt>
              <dd class={'ms-4'}>
                <h5>
                  데이터 분석 시작일로부터 종료일까지의 전체 평균
                  침하량/침하각의 변화는 아래 그래프와 같다.
                </h5>
              </dd>
              <dd class={'ms-4 pb-2'}>
                <AverageDailyReminusChart
                  data={dailyReminusChartDatas}
                  ref={dailyReminusChartRef}
                />
              </dd>
              <dd class={'ms-4'}>
                <AverageDailySubsChart
                  data={dailySubsChartDatas}
                  ref={dailySubsChartRef}
                />
              </dd>
            </dl>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handlePrint}>
            프린트
          </Button>
          <Button
            className="btn_close"
            variant="secondary"
            onClick={detailModalShowEvent}
          >
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MonitoringReport;
