import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import { Nav } from 'react-bootstrap';
import LastMessage from './LastMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChatContext } from 'context/Context';

import avatarSrc from 'assets/img/illustrations/geostr.png';
import dayjs from 'dayjs';
import ChatMemberCount from '../common/ChatMemberCount';
import { isEmpty } from 'helpers/utils';
import SoftBadge from '../../SoftBadge';

const ChatThread = ({ chatRoom, index }) => {
  // console.log('ChatThread : ', chatRoom);
  const { setCurrentChatRoom, setIsOpenChatRoom, getChatRoom } =
    useContext(ChatContext);

  const SelectedThreadInfo = () => {
    // console.log('SelectedThreadInfo : ', chatRoom);
    setCurrentChatRoom({ ...chatRoom });
    setIsOpenChatRoom(true);
  };

  const makeLastMessageDate = chatRoom => {
    let date = dayjs(chatRoom.lastChat.createDate, 'YYYY-MM-DD HH:mm:ss');

    let today = dayjs();
    let expired_at = dayjs(date.format('YYYY-MM-DD'));

    let result = expired_at.diff(today, 'day', true);
    // console.log('makeLastMessageDate : ', Math.floor(result));
    if (Math.floor(result) === -1) {
      return date.format('HH:mm:ss');
    } else {
      return date.format('YYYY-MM-DD');
    }
  };

  const makeChatRoomStatusName = chatRoom => {
    if (chatRoom.status === '0') {
      return '점검요함';
    }
    if (chatRoom.status === '1') {
      return '조치완료';
    }
    if (chatRoom.status === '2') {
      return '재점검필';
    }
    if (chatRoom.status === '3') {
      return '승인';
    }
  };

  const makeEventType = event => {
    let badgeColor = 'dark';
    let badgeName = '-';

    if (!isEmpty(event.eventCode) && event.eventCode.length > 5) {
      let eventType = event.eventCode.substring(0, 5);

      if (
        eventType === 'e0000' ||
        eventType === 'e0100' ||
        eventType === 'e0302' ||
        eventType === 'e0405'
      ) {
        badgeColor = 'secondary';
        badgeName = '인증문제';
      }
      if (eventType === 'e0300' || eventType === 'e0400') {
        badgeColor = 'info';
        badgeName = '신호불량';
      }
      if (
        eventType === 'e0301' ||
        eventType === 'e0401' ||
        eventType === 'e0402' ||
        eventType === 'e0403'
      ) {
        badgeColor = 'warning';
        badgeName = '상태불량';
      }
      if (
        eventType === 'e0200' ||
        eventType === 'e0500' ||
        eventType === 'e0501' ||
        eventType === 'e0502' ||
        eventType === 'e0503'
      ) {
        badgeColor = 'danger';
        badgeName = '위험예측';
      }
    }

    return (
      <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
        {badgeName}
      </SoftBadge>
    );
  };

  const makeEventLevel = () => {
    let badgeColor = 'dark';
    let badgeName = '-';

    let eventLevel = chatRoom.event.eventLevel;

    if (eventLevel === '0') {
      badgeName = '심각';
      badgeColor = 'danger';
    }
    if (eventLevel === '1') {
      badgeName = '경고';
      badgeColor = 'warning';
    }
    if (eventLevel === '2') {
      badgeName = '주의';
      badgeColor = 'info';
    }
    if (eventLevel === '3') {
      badgeName = '관찰';
      badgeColor = 'success';
    }
    if (eventLevel === '4') {
      badgeName = '알림';
      badgeColor = 'secondary';
    }

    return (
      <SoftBadge pill bg={badgeColor} className="me-2 fs--2">
        {badgeName}
      </SoftBadge>
    );
  };

  const makeChatMemberData = members => {
    let chatMemberNames = [];
    members.forEach(member => {
      chatMemberNames.push(member.memberName);
    });
    return chatMemberNames;
  };

  const makeLastChatData = lastChat => {
    let msg = lastChat.message;
    let retValue = '-';
    if (msg.indexOf('<br>') != -1) {
      let msgBr = msg.split('<br>');
      retValue = msgBr[msgBr.length - 1];
    } else {
      retValue = msg;
    }

    if (retValue.length > 20) {
      retValue = retValue.substring(0, 20) + '...';
    }

    return retValue;
  };

  return (
    <Nav.Link
      eventKey={chatRoom.id}
      style={{ height: '4.5rem' }}
      className={classNames(`chat-contact hover-actions-trigger p-3`, {
        'unread-message': false,
        'read-message': true
      })}
      onClick={SelectedThreadInfo}
    >
      {/* <div className="d-md-none d-lg-block">
        <ChatSidebarDropdownAction />
      </div> */}
      <Flex>
        {/*<Avatar className={'status-online'} src={avatarSrc} size="xl" />*/}
        <Avatar
          size="2xl"
          isExact={true}
          name={
            !!chatRoom.project ? chatRoom.project.value.substring(0, 1) : '-'
          }
          className="me-1"
        />
        <div className="flex-1 chat-contact-body ms-2 d-sm-block">
          <Flex justifyContent="between">
            <Flex direction={'row'}>
              <h6 className="mb-0 chat-contact-title">
                {!!chatRoom.project && chatRoom.project.value}
              </h6>
              {/*&nbsp;*/}
              {/*<ChatMemberCount*/}
              {/*  members={makeChatMemberData(chatRoom.members)}*/}
              {/*  onClickEvent={() => {}}*/}
              {/*/>*/}
            </Flex>
            <span className="message-time fs--2">
              {!!chatRoom.lastChat ? makeLastMessageDate(chatRoom) : '-'}{' '}
            </span>
          </Flex>
          <div className="min-w-0">
            <div className="chat-contact-content pe-3">
              <div className="chat-contact-content fs--2">
                {!isEmpty(chatRoom.event) && makeEventType(chatRoom.event)}
                {!isEmpty(chatRoom.event) && makeEventLevel()}
                {chatRoom.title}
              </div>
              <div className="chat-contact-content fs--2">
                {!!chatRoom.lastChat
                  ? makeLastChatData(chatRoom.lastChat)
                  : '-'}
              </div>
              {/* <LastMessage lastMessage={lastMessage} thread={thread} /> */}
              {/*<div className="position-absolute bottom-0 end-0 px-0 fs--2">*/}
              {/*  {!!lastMessage?.status && (*/}
              {/*    <FontAwesomeIcon*/}
              {/*      icon={classNames({*/}
              {/*        check:*/}
              {/*          lastMessage.status === 'seen' ||*/}
              {/*          lastMessage.status === 'sent',*/}
              {/*        'check-double': lastMessage.status === 'delivered'*/}
              {/*      })}*/}
              {/*      transform="shrink-5 down-4"*/}
              {/*      className={classNames({*/}
              {/*        'text-success': lastMessage.status === 'seen',*/}
              {/*        'text-400':*/}
              {/*          lastMessage.status === 'delivered' ||*/}
              {/*          lastMessage.status === 'sent'*/}
              {/*      })}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {/* <Avatar className={user.status} src={user.avatarSrc} size="xl" />
        <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
          <Flex justifyContent="between">
            <h6 className="mb-0 chat-contact-title">{user.name}</h6>
            <span className="message-time fs--2">
              {' '}
              {!!lastMessage && lastMessage.time.day}{' '}
            </span>
          </Flex>
          <div className="min-w-0">
            <div className="chat-contact-content pe-3">
              <LastMessage lastMessage={lastMessage} thread={thread} />
              <div className="position-absolute bottom-0 end-0 hover-hide">
                {!!lastMessage?.status && (
                  <FontAwesomeIcon
                    icon={classNames({
                      check:
                        lastMessage.status === 'seen' ||
                        lastMessage.status === 'sent',
                      'check-double': lastMessage.status === 'delivered'
                    })}
                    transform="shrink-5 down-4"
                    className={classNames({
                      'text-success': lastMessage.status === 'seen',
                      'text-400':
                        lastMessage.status === 'delivered' ||
                        lastMessage.status === 'sent'
                    })}
                  />
                )}
              </div>
            </div>
          </div>
        </div> */}
      </Flex>
    </Nav.Link>
  );
};

// ChatThread.propTypes = {
//   thread: PropTypes.object.isRequired,
//   index: PropTypes.number.isRequired
// };

export default ChatThread;
