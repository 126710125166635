import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, getPastDates, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  return params
    .map(
      ({ value, borderColor, name }, index) =>
        `${
          index === 0
            ? `<span class='text-800 fs--2'><strong>${name}</strong></span><br/>`
            : ''
        }` +
        `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>
              <span class='text-600 fs--1'>${
                index === 0 ? '총 전송수' : '에러전송수'
              }: ${value}건</span>`
    )
    .join('<br/>');
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: ['allSendCount', 'errorSendCount'],
    show: true,
    type: 'scroll',
    orient: 'horizontal',
    // orient: 'vertical',

    right: 10,
    top: 0,
    bottom: 20,
    formatter: function (name) {
      if (name === 'allSendCount') {
        return '총 전송수';
      } else {
        return '에러전송수';
      }
    }
  },
  xAxis: {
    type: 'category',
    data: data.date,
    boundaryGap: true,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => dayjs(value).format('YY-MM-DD')
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  // dataZoom: [
  //   {
  //     show: true,
  //     type: 'inside',
  //     filterMode: 'none',
  //     xAxisIndex: [0],
  //     startValue: -20,
  //     endValue: 20
  //   }
  //   // {
  //   //   show: true,
  //   //   type: 'inside',
  //   //   filterMode: 'none',
  //   //   yAxisIndex: [0],
  //   //   startValue: -20,
  //   //   endValue: 20
  //   // }
  // ],
  series: [
    {
      name: 'allSendCount',
      type: 'line',
      // smooth: true,
      data: data.allSendCount,
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      },
      smooth: false,
      animation: true,
      animationDuration: 1000, // 애니메이션의 지속 시간 설정
      animationDurationUpdate: 1000, // 업데이트 시 애니메이션의 지속 시간 설정
      animationEasing: 'linear', // 애니메이션의 진행 방식 설정
      animationEasingUpdate: 'linear' // 업데이트 시 애니메이션의 진행 방식 설정
    },
    {
      name: 'errorSendCount',
      type: 'line',
      // smooth: true,
      data: data.errorSendCount,
      lineStyle: { color: rgbaColor(getColor('danger'), 0.3) },
      itemStyle: {
        borderColor: rgbaColor(getColor('danger'), 0.6),
        borderWidth: 2
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      },
      smooth: false,
      animation: true,
      animationDuration: 1000, // 애니메이션의 지속 시간 설정
      animationDurationUpdate: 1000, // 업데이트 시 애니메이션의 지속 시간 설정
      animationEasing: 'linear', // 애니메이션의 진행 방식 설정
      animationEasingUpdate: 'linear' // 업데이트 시 애니메이션의 진행 방식 설정
    }
  ],
  grid: {
    right: '6%',
    left: '7%',
    bottom: '15%',
    top: '15%',
    containLabel: false
  }
});

const DataTrafficChart = forwardRef(({ data }, ref) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      // 1. 기존 차트 인스턴스를 초기화(삭제)
      echarts.dispose(chartRef.current.getEchartsInstance());
    }
    chartRef.current.resize();
    // 2. 새로운 차트를 생성
    chartRef.current.getEchartsInstance().setOption(getOptions(data));
  }, [data]);

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getOptions(data)}
      // notMerge={true}
    />
  );
});

// DataTrafficChart.propTypes = {
//   data: PropTypes.shape({
//     date: PropTypes.array,
//     allSendCount: PropTypes.array,
//     errorSendCount: PropTypes.array
//   })
// };

export default DataTrafficChart;
