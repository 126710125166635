import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, isEmpty } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';
import { chainAxios } from 'helpers/chainAxios';
import FalconCardHeader from '../../../../common/FalconCardHeader';

const IndicatorsVerticalChart = ({ facilityId, facilityType }) => {
  const [reminusAnalysis, setReminusAnalysis] = useState([]);

  useEffect(() => {
    if (!isEmpty(facilityId) && !isEmpty(facilityType)) {
      getIndicatorsAnalysisDatas();
    }
  }, [facilityId]);

  const getIndicatorsAnalysisDatas = () => {
    let url =
      '/api/sensor/chart/sawStatus2?scope=facility&scopeId=' + facilityId;
    // console.log('getIndicatorsAnalysisDatas url : ', url);
    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          // console.log(
          //   'SensorManageChart getSensorAnalysisDatas() : ',
          //   response.data.data.status
          // );
          makeIndicatorsAnalysisDatas(response.data.data.status[facilityType]);
        }
      })
      .catch(error => {
        console.log('IndicatorsChart getIndicatorsAnalysisDatas() : ', error);
      });
  };

  const makeIndicatorsAnalysisDatas = datas => {
    let reminusDatas = [];
    if (facilityType === 'RD') {
      reminusDatas.push({
        id: 1,
        value: datas.reminus150 !== '' ? datas.reminus150.split(',').length : 0,
        items:
          datas.reminus150 !== '' ? datas.reminus150.split(',').sort() : '',
        name: '150mm이내',
        color: 'primary'
      });
      reminusDatas.push({
        id: 2,
        value: datas.reminus250 !== '' ? datas.reminus250.split(',').length : 0,
        items:
          datas.reminus250 !== '' ? datas.reminus250.split(',').sort() : '',
        name: '250mm이내',
        color: 'warning'
      });
      reminusDatas.push({
        id: 3,
        value: datas.reminus300 !== '' ? datas.reminus300.split(',').length : 0,
        items:
          datas.reminus300 !== '' ? datas.reminus300.split(',').sort() : '',
        name: '250mm이상',
        color: 'danger'
      });
    } else {
      reminusDatas.push({
        id: 1,
        value: datas.reminus15 !== '' ? datas.reminus15.split(',').length : 0,
        items: datas.reminus15 !== '' ? datas.reminus15.split(',').sort() : '',
        name: '15mm이내',
        color: 'primary'
      });
      reminusDatas.push({
        id: 2,
        value: datas.reminus25 !== '' ? datas.reminus25.split(',').length : 0,
        items: datas.reminus25 !== '' ? datas.reminus25.split(',').sort() : '',
        name: '25mm이내',
        color: 'warning'
      });
      reminusDatas.push({
        id: 3,
        value: datas.reminus30 !== '' ? datas.reminus30.split(',').length : 0,
        items: datas.reminus30 !== '' ? datas.reminus30.split(',').sort() : '',
        name: '25mm이상',
        color: 'danger'
      });
    }

    setReminusAnalysis(reminusDatas);
  };

  const radius = ['100%', '65%'];
  const getOptions = (data, radius) => ({
    color: [getColor('primary'), getColor('warning'), getColor('danger')],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => {
        // console.log(params);
        return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
          params.data.color
        )}"></div><span class='text-600 fs--1'><strong>${
          params.data.name
        }:</strong> ${params.value}개소</span>
        ${params.data.items !== '' ? `<br/>` : ''}
        ${
          params.data.items !== ''
            ? `<span class='text-600 fs--2'>${params.data.items}</span>`
            : ''
        }`;
      }
    },
    series: [
      {
        name: '지표침하\r\n' + total + '개소',
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: '0.8333333333rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const total = reminusAnalysis.reduce((acc, val) => val.value + acc, 0);

  const SensorItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        justifyContent="between"
        className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      >
        <p className="mb-1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </p>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <div>{value} 개소</div>
      </Flex>
    );
  };

  return (
    <Card className="h-md-100">
      <FalconCardHeader title="지표침하 현황" light titleTag="h6" />
      <Card.Body style={{ minHeight: '220px', maxHeight: '220px' }}>
        <Row className="justify-content-center g-0">
          {/*<h6 className="mt-1">프로젝트 관리 현황</h6>*/}
          <Col xs="12">
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(reminusAnalysis, radius)}
                style={{ width: '100%', height: '6.625rem' }}
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center g-0">
          <Col xs="12">
            {reminusAnalysis.map((item, index) => (
              <SensorItem item={item} index={index} key={index} total={total} />
            ))}
          </Col>
        </Row>
      </Card.Body>
      {/*<Card.Body>*/}
      {/*  <Row className="justify-content-between g-0">*/}
      {/*    <Col xs={5} sm={6} xxl className="pe-2">*/}
      {/*      <h6 className="mt-1">지표침하 현황</h6>*/}
      {/*      {reminusAnalysis.map((item, index) => (*/}
      {/*        <SensorItem item={item} index={index} key={index} total={total} />*/}
      {/*      ))}*/}
      {/*    </Col>*/}
      {/*    <Col xs="auto">*/}
      {/*      <div className="ps-0">*/}
      {/*        <BasicECharts*/}
      {/*          echarts={echarts}*/}
      {/*          options={getOptions(reminusAnalysis, radius)}*/}
      {/*          style={{ width: '6.625rem', height: '6.625rem' }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Card.Body>*/}
    </Card>
  );
};

export default IndicatorsVerticalChart;
