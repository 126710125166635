import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo
} from 'react';
import SimpleBar from 'simplebar-react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Text } from '@react-three/drei';
import mapboxgl from 'mapbox-gl';
import * as THREE from 'three';
import { DoubleSide } from 'three';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const ModelView = ({ modelViewArgs }) => {
  const mapContainerRef = useRef(null);
  const meshRef = useRef();
  const [texture, setTexture] = useState(null);

  const centerCoord = [126.79003848, 37.63872167]; // 지도 중심 좌표
  const mapScale = 420000; // 위도/경도를 3D 좌표로 변환하기 위한 스케일 팩터
  const kernelSize = 5; // 5x5 커널 크기

  const groundFloor = 17;
  const underFloor = 3;
  const floorHeight = 30;

  const [facilityPositions, setFacilityPositions] = useState([
    [126.78970786033346, 37.638737971441216],
    [126.79004340494657, 37.63848435278889],
    [126.79029398668746, 37.63869549502789],
    [126.79012053713933, 37.63883036530686],
    [126.79004927795665, 37.63877703731778],
    [126.7899470875895, 37.63885330873255],
    [126.78983471733983, 37.63876587564067],
    [126.78978812479801, 37.63879191955134],
    [126.78970786033346, 37.638737971441216]
  ]);
  const [sensorPositions, setSensorPositions] = useState([
    [126.79040361, 37.63865172, -0.8, 'S01', 'OFF'],
    [126.79036845, 37.63862228, 5.8, 'S02', 'ON'],
    [126.79033666, 37.63859396, -6.6, 'S03', 'ON'],
    [126.79029884, 37.63856149, -5.2, 'S04', 'OFF'],
    [126.79022605, 37.63849938, -8.1, 'S05', 'ON'],
    [126.79019159, 37.63847038, -0.9, 'S06', 'ON'],
    [126.79015353, 37.63843864, -24.86, 'S07', 'ON'],
    [126.79007725, 37.63841385, -7.09, 'S09', 'ON'],
    [126.79001294, 37.63843674, -0.96, 'S10', 'ON'],
    [126.78998532, 37.63845678, 1.17, 'S11', 'ON'],
    [126.78994933, 37.63848289, -6.92, 'S12', 'ON'],
    [126.78992673, 37.63849928, 10.0, 'S13', 'ON'],
    [126.78986981, 37.63854057, -5.54, 'S14', 'ON'],
    [126.78975527, 37.63862665, -9.3, 'S15', 'ON'],
    [126.78971519, 37.63865589, -15.71, 'S16', 'ON'],
    [126.78967324, 37.63868616, -1.28, 'S17', 'ON'],
    [126.78967833, 37.63872735, -30.5, 'S18', 'ON'],
    [126.78968307, 37.63875929, -0.58, 'S19', 'ON'],
    [126.78968921, 37.63878663, 0.59, 'S20', 'ON'],
    [126.78972565, 37.63879503, -2.9, 'S21', 'OFF'],
    [126.78972964, 37.63882029, -21.92, 'S22', 'ON'],
    [126.78976647, 37.63882845, -5.0, 'S23', 'OFF'],
    [126.78976917, 37.63885674, 4.02, 'S24', 'ON'],
    [126.78980637, 37.63886254, -3.19, 'S25', 'ON'],
    [126.78981039, 37.63888908, -0.33, 'S26', 'ON'],
    [126.78985519, 37.63890276, 0.32, 'S27', 'OFF']
  ]);
  const [boreholePositions, setBoreholePositions] = useState([
    [126.78972183000454, 37.63877723005332, 10.9 - 10.9, 'BH-1', 'BOREHOLE'],
    [126.79036967000347, 37.63865570005227, 10.6 - 10.7, 'BH-3A', 'BOREHOLE'],
    [126.79000868999603, 37.63846834004981, 10.6 - 10.6, 'BH-2', 'BOREHOLE']
  ]);
  const [boreholePositions1, setBoreholePositions1] = useState([
    [126.78972183000454, 37.63877723005332, 8.9 - 10.9],
    [126.79036967000347, 37.63865570005227, 4.2 - 10.7],
    [126.79000868999603, 37.63846834004981, 3.1 - 10.6]
  ]);
  const [boreholePositions2, setBoreholePositions2] = useState([
    [126.78972183000454, 37.63877723005332, -3.6 - 10.9],
    [126.79036967000347, 37.63865570005227, -4.3 - 10.7],
    [126.79000868999603, 37.63846834004981, -4.0 - 10.6]
  ]);
  const [boreholePositions3, setBoreholePositions3] = useState([
    [126.78972183000454, 37.63877723005332, -18.1 - 10.9],
    [126.79036967000347, 37.63865570005227, -17.3 - 10.7],
    [126.79000868999603, 37.63846834004981, -16.8 - 10.6]
  ]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: centerCoord,
      zoom: 17.8,
      interactive: true
    });

    map.on('load', () => {
      const canvas = map.getCanvas();
      const texture = new THREE.CanvasTexture(canvas);
      texture.minFilter = THREE.LinearFilter;
      setTexture(texture);

      console.log('canvas width : ', canvas.width);
      console.log('canvas height : ', canvas.height);
    });

    return () => map.remove();
  }, [modelViewArgs]);

  const convertCoordToPosition = useCallback(
    (coord, heightScale) => {
      const [lng, lat, height, name, status] = coord;
      const [centerLng, centerLat] = centerCoord;

      const xMapScaleTempValue = 30000;
      const yMapScaleTempValue = 100000;
      const x = (lng - centerLng) * (mapScale - xMapScaleTempValue);
      const y = (lat - centerLat) * (mapScale + yMapScaleTempValue);
      const z = height * heightScale; // 높낮이 값을 스케일 팩터와 함께 z축으로 사용

      return [x, y, z, name, status];
    },
    [centerCoord, mapScale]
  );

  const applyHeightToVertices = useCallback(
    (geometry, positions, heightScale, distanceThreshold) => {
      const vertices = geometry.attributes.position.array;
      const halfKernelSize = Math.floor(kernelSize / 2);

      for (let i = 0; i < vertices.length; i += 3) {
        const x = vertices[i];
        const y = vertices[i + 1];

        let totalHeight = 0;
        let totalWeight = 0;

        for (let dx = -halfKernelSize; dx <= halfKernelSize; dx++) {
          for (let dy = -halfKernelSize; dy <= halfKernelSize; dy++) {
            positions.forEach(pos => {
              const [sx, sy, sz] = convertCoordToPosition(pos, heightScale);
              const adjustedSx = sx + dx;
              const adjustedSy = sy + dy;
              const distance = Math.sqrt(
                (x - adjustedSx) ** 2 + (y - adjustedSy) ** 2
              );

              if (distance < distanceThreshold) {
                const distanceRatio =
                  (distanceThreshold - distance) / distanceThreshold;
                const weight =
                  distanceRatio *
                  Math.exp(
                    -(dx * dx + dy * dy) / (2 * halfKernelSize * halfKernelSize)
                  );
                totalHeight += sz * weight;
                totalWeight += weight;
              }
            });
          }
        }

        if (totalWeight > 0) {
          vertices[i + 2] = totalHeight / totalWeight;
        } else {
          vertices[i + 2] = 0;
        }
      }

      geometry.attributes.position.needsUpdate = true;
    },
    [kernelSize, convertCoordToPosition]
  );

  const getSensorColor = useCallback(status => {
    let sensorColor = 0xff0000;
    if (status === 'ON') {
      sensorColor = 0x2c7be5;
    }
    if (status === 'OFF') {
      sensorColor = 0xf5803e;
    }
    if (status === 'ERR') {
      sensorColor = 0xe63757;
    }

    return sensorColor;
  }, []);

  const polygonShape = useMemo(() => {
    return new THREE.Shape(
      facilityPositions.map(pos => {
        const [x, y] = convertCoordToPosition(
          pos,
          modelViewArgs.facilityHeightScale
        );
        return new THREE.Vector2(x, y);
      })
    );
  }, [
    facilityPositions,
    convertCoordToPosition,
    modelViewArgs.facilityHeightScale
  ]);

  const extrudeSettings = useMemo(
    () => ({
      depth: groundFloor * floorHeight * modelViewArgs.facilityHeightScale,
      bevelEnabled: true
    }),
    [groundFloor, floorHeight, modelViewArgs.facilityHeightScale]
  );

  const underExtrudeSettings = useMemo(
    () => ({
      depth: -(underFloor * floorHeight) * modelViewArgs.facilityHeightScale,
      bevelEnabled: true
    }),
    [underFloor, floorHeight, modelViewArgs.facilityHeightScale]
  );

  const MapTexture = () => {
    const geometryRef = useRef();

    useEffect(() => {
      if (geometryRef.current) {
        applyHeightToVertices(
          geometryRef.current,
          sensorPositions,
          modelViewArgs.markerHeightScale,
          modelViewArgs.markerDistanceThreshold
        );
      }
    }, [sensorPositions, applyHeightToVertices, modelViewArgs]);

    return (
      <mesh ref={meshRef} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry ref={geometryRef} args={[500, 375, 250, 168]} />
        <meshBasicMaterial
          map={texture}
          side={DoubleSide}
          wireframe={modelViewArgs.isMapWireframe}
        />
        {sensorPositions.map((position, index) => {
          const [x, y, z, name, status] = convertCoordToPosition(
            position,
            modelViewArgs.markerHeightScale
          );
          return (
            <mesh key={index} position={[x, y, z]}>
              <sphereGeometry args={[5 * modelViewArgs.circleScale, 32, 32]} />
              <meshBasicMaterial color={getSensorColor(status)} />
              <Text
                position={[0, 0, 7.5]}
                fontSize={5}
                color={0x000000}
                anchorX="center"
                anchorY="middle"
                rotation={[Math.PI / 2, Math.PI / 2, 0]}
              >
                {name}({z.toFixed(2)})
              </Text>
            </mesh>
          );
        })}
        {/* Extruded Polygon Geometry */}
        <mesh rotation={[0, 0, 0]}>
          <extrudeGeometry args={[polygonShape, extrudeSettings]} />
          <meshBasicMaterial
            color={'#aaa'}
            side={DoubleSide}
            wireframe={false}
            transparent={true}
            opacity={0.5}
          />
        </mesh>

        {/* Extruded Polygon Geometry */}
        <mesh rotation={[0, 0, 0]}>
          <extrudeGeometry args={[polygonShape, underExtrudeSettings]} />
          <meshBasicMaterial
            color={0x00ff00}
            side={DoubleSide}
            wireframe={false}
            transparent={true}
            opacity={0.5}
          />
        </mesh>
      </mesh>
    );
  };

  const UnderGroundLayer = useCallback(
    ({ depth, color, datas }) => {
      const geometryRef = useRef();

      useEffect(() => {
        if (geometryRef.current) {
          applyHeightToVertices(
            geometryRef.current,
            datas,
            modelViewArgs.underGroundHeightScale,
            modelViewArgs.underGroundDistanceThreshold
          );
        }
      }, [datas, applyHeightToVertices, modelViewArgs]);

      return (
        <mesh position={[0, -depth, 0]} rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry ref={geometryRef} args={[500, 375, 250, 168]} />
          <meshBasicMaterial
            color={color}
            side={DoubleSide}
            wireframe={modelViewArgs.isUnderGroundWireframe}
          />
          {datas.map((position, index) => {
            const [x, y, z] = convertCoordToPosition(
              position,
              modelViewArgs.underGroundHeightScale
            );
            return (
              <mesh key={index} position={[x, y, z]}>
                <sphereGeometry
                  args={[5 * modelViewArgs.circleScale, 32, 32]}
                />
                <meshBasicMaterial color={0x000000} />
              </mesh>
            );
          })}
        </mesh>
      );
    },
    [applyHeightToVertices, convertCoordToPosition, modelViewArgs]
  );

  return (
    <>
      <div
        ref={mapContainerRef}
        style={{ width: '100%', height: '100vh', display: 'none' }}
      />
      <Canvas
        style={{ width: '100%', height: '63.3vh' }}
        camera={{ position: [0, 350, 350], fov: 75, near: 0.1, far: 5000 }}
      >
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <axesHelper args={[10]} />
        <OrbitControls enableZoom={true} enablePan={true} />
        {texture && <MapTexture />}
        <UnderGroundLayer
          depth={0.5}
          color={'#A47A68'}
          datas={boreholePositions1}
        />
        <UnderGroundLayer
          depth={0.5}
          color={'#B2B5B3'}
          datas={boreholePositions2}
        />
        <UnderGroundLayer
          depth={0.5}
          color={'#927D75'}
          datas={boreholePositions3}
        />
      </Canvas>
    </>
  );
};

export default ModelView;
