import { Card, Col, Row } from 'react-bootstrap';
import React from 'react';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { faChartArea } from '@fortawesome/free-solid-svg-icons';

const SensorDetail = ({ sensor }) => {
  return (
    <Row
      xs={12}
      className={'py-3 border rounded h-100'}
      style={{ backgroundColor: getColor('white') }}
    >
      <Col xs={6} md={3} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>센서번호</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.sensorHole?.value}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>센서종류</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSTYPE}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>게이트웨이번호</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.HUBID}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>접속주기</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSPERIOD + '분'}
            </div>
          </Col>
        </Flex>
      </Col>
      <Col xs={6} md={3} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>측정주기</div>
            <div className={'fs--1 text-700'}>
              {!!sensor &&
                parseInt(sensor.properties?.SSPERIOD) *
                  parseInt(sensor.properties?.SSPERIODN) +
                  '분'}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>측정횟수</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSCOUNT}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>NG횟수</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSNGCOUNT}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>마지막 통신시간</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSRAWDATE}
            </div>
          </Col>
        </Flex>
      </Col>
      <Col xs={6} md={3} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>외부강관 길이</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSLENGTH + 'mm'}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>외부강관 직경</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSRADIUS * 2 + 'mm'}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>외부강관 무게</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSWEIGHT + 'kg'}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>하중</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSLOAD + 'kg'}
            </div>
          </Col>
        </Flex>
      </Col>
      <Col xs={6} md={3} as={Flex}>
        <Flex direction={'column'} justifyContent={'between'}>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>누름쇠 길이</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSPLENGTH + 'mm'}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>누름쇠 직경</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSPRADIUS * 2 + 'mm'}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>누름쇠 무게</div>
            <div className={'fs--1 text-700'}>
              {!!sensor && sensor.properties?.SSPWEIGHT + 'kg'}
            </div>
          </Col>
          <Col xs={12}>
            <div className={'fs--1 text-900 mb-1'}>-</div>
            <div className={'fs--1 text-700'}>
              -{/*<IconButton*/}
              {/*  variant="falcon-primary"*/}
              {/*  className="mb-1"*/}
              {/*  icon={faChartArea}*/}
              {/*  transform="shrink-3"*/}
              {/*  size="sm"*/}
              {/*  // onClick={() => onClickMoveChatRoom(chatroomId)}*/}
              {/*></IconButton>*/}
            </div>
          </Col>
        </Flex>
      </Col>
    </Row>
  );
};

export default SensorDetail;
