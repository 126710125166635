import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { chainAxios } from 'helpers/chainAxios';
import Carousel from 'react-bootstrap/Carousel';

const ProjectSummary = ({ selectedProject }) => {
  // console.log(selectedProject);
  // const [projectImages, setProjectImages] = useState('');
  // console.log('ProjectSummary : ', selectedProject);
  // useEffect(() => {
  //   if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
  //     // makeImages(selectedProject);
  //     // console.log(selectedProject);
  //     if (!!selectedProject.images && selectedProject.images.length > 0) {
  //       setProjectImages(
  //         process.env.REACT_APP_API_SERVER +
  //           'api/file/' +
  //           selectedProject.images[0]
  //       );
  //     }
  //   }
  // }, [selectedProject]);

  return (
    // <Card className="h-md-100">
    //   <Card.Body>
    <>
      <Flex
        direction="column"
        justifyContent="between"
        className="rounded-1 h-100"
        alignItems="center"
      >
        <h6 className={'fs--1 text-900'}>{selectedProject.name}</h6>
      </Flex>
      <Flex
        direction="row"
        justifyContent="start"
        alignContent={'center'}
        alignItems={'center'}
      >
        <Col xs="4">
          <h6 className={'fs--1 text-900'}>주소</h6>
        </Col>
        <Col xs="8">
          <h6 className={'fs--1 text-700 fw-normal'}>
            {!!selectedProject.properties
              ? selectedProject.properties.PJADDRESS
              : '-'}
          </h6>
        </Col>
      </Flex>
      <Flex
        direction="row"
        justifyContent="strech"
        alignContent={'center'}
        alignItems={'center'}
      >
        <Col xs="4">
          <h6 className={'fs--1 text-900'}>발주사</h6>
        </Col>
        <Col xs="8">
          <h6 className={'fs--1 text-700 fw-normal'}>
            {!!selectedProject.properties
              ? selectedProject.properties.PJORDER
              : '-'}
          </h6>
        </Col>
      </Flex>
      <Flex
        direction="row"
        justifyContent="strech"
        alignContent={'center'}
        alignItems={'center'}
      >
        <Col xs="4">
          <h6 className={'fs--1 text-900'}>관리업체</h6>
        </Col>
        <Col xs="8">
          <h6 className={'fs--1 text-700 fw-normal'}>
            {!!selectedProject.properties
              ? selectedProject.properties.PJCONSTRUCTOR
              : '-'}
          </h6>
        </Col>
      </Flex>
      <Flex
        direction="row"
        justifyContent="strech"
        alignContent={'center'}
        alignItems={'center'}
      >
        <Col xs="4">
          <h6 className={'fs--1 text-900'}>관리부서</h6>
        </Col>
        <Col xs="8">
          <h6 className={'fs--1 text-700 fw-normal'}>
            {!!selectedProject.properties
              ? selectedProject.properties.PJMANAGER
              : '-'}
          </h6>
        </Col>
      </Flex>
      <Flex
        direction="row"
        justifyContent="strech"
        alignContent={'center'}
        alignItems={'center'}
      >
        <Col xs="4">
          <h6 className={'fs--1 text-900'}>관리 시작일</h6>
        </Col>
        <Col xs="8">
          <h6 className={'fs--1 text-700 fw-normal'}>
            {!!selectedProject.initDate ? selectedProject.initDate : '-'}
          </h6>
        </Col>
      </Flex>
      <Flex
        direction="row"
        justifyContent="strech"
        alignContent={'center'}
        alignItems={'center'}
      >
        <Col xs="4">
          <h6 className={'fs--1 text-900'}>관리 종료일</h6>
        </Col>
        <Col xs="8">
          <h6 className={'fs--1 text-700 fw-normal'}>
            {!!selectedProject.endDate ? selectedProject.endDate : '-'}
          </h6>
        </Col>
      </Flex>
      <Flex direction="row" justifyContent="center">
        <Carousel>
          {!!selectedProject &&
          !!selectedProject.images &&
          selectedProject.images.length > 0 ? (
            selectedProject.images.map((img, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className={'w-100'}
                    style={{ objectFit: 'cover', maxHeight: '160px' }}
                    src={process.env.REACT_APP_API_SERVER + 'api/file/' + img}
                    alt=""
                    // height="100%"
                  />
                </Carousel.Item>
              );
            })
          ) : (
            <Carousel.Item>
              <Carousel.Caption>
                <h3>이미지가 없습니다</h3>
              </Carousel.Caption>
            </Carousel.Item>
          )}
        </Carousel>
      </Flex>
    </>
    //   </Card.Body>
    // </Card>
  );
};

export default ProjectSummary;
