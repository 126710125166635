import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Dropdown, Form, Row } from 'react-bootstrap';

import 'ol/ol.css';
import { Map, Overlay, View } from 'ol';
import { defaults, FullScreen } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source.js';
import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature.js';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
  Icon,
  Circle
} from 'ol/style';
import { XYZ } from 'ol/source';
import { boundingExtent } from 'ol/extent.js';
import mapMarker from 'assets/img/icons/map-marker.png';
import { getColor } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';

import { chainAxios } from 'helpers/chainAxios';
import FalconCardHeader from '../../common/FalconCardHeader';
import { MapsContext } from '../../../context/Context';

const ClusterMap = () => {
  const { getProject } = useContext(MapsContext);

  const clusterMapDiv = useRef();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState('');

  useEffect(() => {
    getProjectList();
    return () => {};
  }, []);

  const getProjectList = () => {
    let url = '/api/projects?pageNo=0&pageSize=99999&sort=createDate%24desc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('getProjectList : ', response);
        if (response.data.resultCode === 200) {
          // console.log('list : ', response.data.data.facilityList.dataList);
          MakeClusterMap(response.data.data.projectList.dataList);
          setProjects(response.data.data.projectList.dataList);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const MakeClusterMap = data => {
    // console.log('MakeClusterMap : ', data);
    const raster = new TileLayer({
      source: new XYZ({
        url: `http://api.vworld.kr/req/wmts/1.0.0/60C87CFF-D191-37F4-A177-CA536871409A/Satellite/{z}/{y}/{x}.jpeg`,
        crossOrigin: 'Anonymous'
      })
    });

    const features = new Array(data.length);

    for (let i = 0; i < data.length; i++) {
      if (data[i].location === null || data[i].location === undefined) {
        console.log('MakeClusterMap : location null or undefined');
        return;
      }
      let marker = new Feature({
        geometry: new Point(
          fromLonLat([data[i].location.longitude, data[i].location.latitude])
        ),
        name: data[i].name,
        id: data[i].id
      });

      let myStyle = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: mapMarker,
          scale: 0.6
        }),
        text: new Text({
          text: marker.get('name'),
          font: 'bold 12px Pretendard',
          offsetX: 0,
          offsetY: 10,
          fill: new Fill({
            color: '#fff'
          })
        })
      });
      marker.setStyle(myStyle);

      features[i] = marker;
    }

    // let markerLayer = new VectorLayer({
    //   source: new VectorSource({
    //     features: features
    //   }),
    //   zIndex: 1,
    //   visible: true
    // });

    const clusterSource = new Cluster({
      distance: parseInt(100, 10),
      minDistance: parseInt(100, 10),
      source: new VectorSource({
        features: features
      }),
      zIndex: 2,
      visible: true
    });

    const styleCache = {};

    const clusters = new VectorLayer({
      source: clusterSource,
      style: function (feature) {
        const size = feature.get('features').length;
        let style = styleCache[size];
        // console.log('MakeClusterMap (styleCache) : ', styleCache);
        if (!style && size > 1) {
          style = new Style({
            image: new CircleStyle({
              radius: 18,
              stroke: new Stroke({
                color: '#fff'
              }),
              fill: new Fill({
                color: getColor('primary')
              })
            }),
            text: new Text({
              font: 'bold 12px Pretendard',
              offsetX: 0,
              offsetY: 0,
              text: size.toString(),
              fill: new Fill({
                color: '#fff'
              })
            })
          });
          styleCache[size] = style;
        }
        if (!style && size === 1) {
          style = feature.get('features')[0].getStyle();
        }
        return style;
      }
    });

    // create Map instance
    const map = new Map({
      controls: defaults({ zoom: false, rotate: false }).extend([
        // new FullScreen()
      ]),
      layers: [raster, clusters],
      target: 'clusterMap',
      view: new View({
        center: fromLonLat([126.8897781, 37.5388127]),
        maxZoom: 19,
        zoom: 10
      })
    });

    map.on('click', e => {
      clusters.getFeatures(e.pixel).then(clickedFeatures => {
        console.log('clickedFeatures : ', clickedFeatures);
        if (clickedFeatures.length) {
          // Get clustered Coordinates
          const features = clickedFeatures[0].get('features');
          if (features.length > 1) {
            const extent = boundingExtent(
              features.map(r => r.getGeometry().getCoordinates())
            );
            map
              .getView()
              .fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
          }

          if (features.length === 1) {
            console.log('clusterMap : ', features[0].get('id'));
            getProject(features[0].get('id'));

            navigate('/maps');
          }
        }
      });
    });
    const info = document.getElementById('info');

    map.on('pointermove', function (evt) {
      if (evt.dragging) {
        info.style.visibility = 'hidden';
        currentFeature = undefined;
        return;
      }
      const pixel = map.getEventPixel(evt.originalEvent);
      displayFeatureInfo(pixel, evt.originalEvent.target);
    });

    let currentFeature;
    const displayFeatureInfo = (pixel, target) => {
      const feature = target.closest('.ol-control')
        ? undefined
        : map.forEachFeatureAtPixel(pixel, function (feature) {
            return feature;
          });
      if (feature) {
        info.style.left = pixel[0] + 'px';
        info.style.top = pixel[1] + 'px';
        // console.log('zzzzz : ', feature);
        if (feature !== currentFeature && feature.get('features').length == 1) {
          info.style.visibility = 'visible';
          // console.log('tootip feautre : ', feature);
          info.innerText = feature.get('features')[0].get('name');
        }
      } else {
        info.style.visibility = 'hidden';
      }
      currentFeature = feature;
    };

    map.getTargetElement().addEventListener('pointerleave', function () {
      currentFeature = undefined;
      info.style.visibility = 'hidden';
    });

    setMapInstance(map);
  };

  const onSelectProject = value => {
    if (value === 'project_all') {
      mapInstance.getView().setCenter(fromLonLat([126.8897781, 37.5388127]));
      mapInstance.getView().setZoom(10);
      return;
    }

    let project = JSON.parse(value);
    if (mapInstance !== null) {
      setSelectedProjectName(project.name);
      mapInstance
        .getView()
        .setCenter(
          fromLonLat([project.location.longitude, project.location.latitude])
        );

      mapInstance.getView().setZoom(19);
    }
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="맵"
        light
        titleTag="h6"
        endEl={
          <Form.Select
            size="sm"
            className="me-3"
            onChange={e => {
              onSelectProject(e.target.value);
            }}
          >
            <option value={'project_all'}>프로젝트를 선택하세요.</option>
            {projects.map((project, index) => (
              <option key={index} value={JSON.stringify(project)}>
                {project.name}
              </option>
            ))}
          </Form.Select>
          // <Dropdown onSelect={onSelectProject}>
          //   <Dropdown.Toggle variant="falcon-default">
          //     {!!selectedProjectName
          //       ? selectedProjectName
          //       : '프로젝트를 선택하세요.'}
          //   </Dropdown.Toggle>
          //   <Dropdown.Menu className="py-2">
          //     {projects.map((project, index) => (
          //       <Dropdown.Item key={index} eventKey={JSON.stringify(project)}>
          //         {project.name}
          //       </Dropdown.Item>
          //     ))}
          //   </Dropdown.Menu>
          // </Dropdown>
        }
      />
      <Card.Body className="pt-0">
        <div
          ref={clusterMapDiv}
          id="clusterMap"
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            minHeight: '380px'
          }}
        >
          <div
            id="info"
            style={{
              position: 'absolute',
              display: 'inline-block',
              height: 'auto',
              width: 'auto',
              zIndex: '100',
              backgroundColor: '#333',
              color: '#fff',
              textAlign: 'center',
              borderRadius: '4px',
              padding: '5px',
              left: '50%',
              transform: 'translateX(3%)',
              visibility: 'hidden',
              pointerEvents: 'none'
            }}
          ></div>
        </div>
        <Row className="justify-content-between g-0">
          <Col xs="auto"></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ClusterMap;
