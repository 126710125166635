import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getColor, isEmpty } from 'helpers/utils';
import * as echarts from 'echarts/core';

import Flex from 'components/common/Flex';
import BasicECharts from 'components/common/BasicEChart';
import { chainAxios } from 'helpers/chainAxios';
import CountUp from 'react-countup';

const PredictiveAnalysisChart = ({ groundAnalysisDatas }) => {
  const [replusAnalysis, setReplusAnalysis] = useState([]);
  const [reminusAnalysis, setReminusAnalysis] = useState([]);

  useEffect(() => {
    if (!!groundAnalysisDatas && JSON.stringify(groundAnalysisDatas) !== '{}') {
      // makePredictiveAnalysisDatas(groundAnalysisDatas);
      makePredictiveAnalysisDatas2(groundAnalysisDatas);
    } else {
      setReplusAnalysis([]);
      setReminusAnalysis([]);
    }
  }, [groundAnalysisDatas]);

  const makePredictiveAnalysisDatas2 = datas => {
    let replusDatas = [];
    let reminusDatas = [];

    let replus15 = 0;
    let replus25 = 0;
    let replus30 = 0;

    let reminus15 = 0;
    let reminus25 = 0;
    let reminus30 = 0;

    Object.keys(datas).forEach((key, index) => {
      if (key !== 'COUNT') {
        if (!isEmpty(datas[key]['replus15'])) {
          replus15 += datas[key]['replus15'].split(',').length;
        }
        if (!isEmpty(datas[key]['replus25'])) {
          replus25 += datas[key]['replus25'].split(',').length;
        }
        if (!isEmpty(datas[key]['replus30'])) {
          replus30 += datas[key]['replus30'].split(',').length;
        }

        if (!isEmpty(datas[key]['reminus15'])) {
          reminus15 += datas[key]['reminus15'].split(',').length;
        }
        if (!isEmpty(datas[key]['reminus25'])) {
          reminus25 += datas[key]['reminus25'].split(',').length;
        }
        if (!isEmpty(datas[key]['reminus30'])) {
          reminus30 += datas[key]['reminus30'].split(',').length;
        }
      }
    });

    replusDatas.push({
      id: 1,
      value: replus15,
      name: '15mm이내',
      color: 'primary'
    });
    replusDatas.push({
      id: 2,
      value: replus25,
      name: '25mm이내',
      color: 'warning'
    });
    replusDatas.push({
      id: 3,
      value: replus30,
      name: '25mm이상',
      color: 'danger'
    });

    reminusDatas.push({
      id: 1,
      value: reminus15,
      name: '15mm이내',
      color: 'primary'
    });
    reminusDatas.push({
      id: 2,
      value: reminus25,
      name: '25mm이내',
      color: 'warning'
    });
    reminusDatas.push({
      id: 3,
      value: reminus30,
      name: '25mm이상',
      color: 'danger'
    });

    setReplusAnalysis(replusDatas);
    setReminusAnalysis(reminusDatas);
  };

  const makePredictiveAnalysisDatas = datas => {
    let replusDatas = [];
    let reminusDatas = [];

    replusDatas.push({
      id: 1,
      value: datas.replus15,
      name: '15mm이내',
      color: 'primary'
    });
    replusDatas.push({
      id: 2,
      value: datas.replus25,
      name: '25mm이내',
      color: 'warning'
    });
    replusDatas.push({
      id: 3,
      value: datas.replus30,
      name: '25mm이상',
      color: 'danger'
    });

    reminusDatas.push({
      id: 1,
      value: datas.reminus15,
      name: '15mm이내',
      color: 'primary'
    });
    reminusDatas.push({
      id: 2,
      value: datas.reminus25,
      name: '25mm이내',
      color: 'warning'
    });
    reminusDatas.push({
      id: 3,
      value: datas.reminus30,
      name: '25mm이상',
      color: 'danger'
    });

    setReplusAnalysis(replusDatas);
    setReminusAnalysis(reminusDatas);
  };

  const roadShare = [
    { id: 1, value: 0, name: 'A등급', color: 'primary' },
    { id: 2, value: 0, name: 'B등급', color: 'warning' },
    { id: 3, value: 0, name: 'C등급', color: 'danger' }
  ];

  const radius = ['100%', '65%'];

  const getOptions = (data, radius, centerTitle, centerTotal) => ({
    color: [getColor('primary'), getColor('warning'), getColor('danger')],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params => {
        // console.log(params);
        return `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${getColor(
          params.data.color
        )}"></div><span class="fs--1"><strong>${params.data.name}:</strong> ${
          params.value
        }개소</span>`;
      }
    },
    series: [
      {
        // name: centerTitle + '\r\n' + centerTotal + '개소',
        name: centerTotal,
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}개소',
          fontSize: '1rem',
          fontFamily: 'Pretendard',
          color: getColor('dark')
        },
        data
      }
    ]
  });

  const replusTotal = replusAnalysis.reduce((acc, val) => val.value + acc, 0);
  const reminusTotal = reminusAnalysis.reduce((acc, val) => val.value + acc, 0);
  const total3 = roadShare.reduce((acc, val) => val.value + acc, 0);

  const SensorItem = ({ item, index, total }) => {
    const { name, color, value } = item;

    // const percentage = ((value * 100) / total).toFixed(0);
    return (
      <Flex
        alignItems="center"
        alignContent={'center'}
        justifyContent="between"
        className={`fw-semi-bold fs--1 ${index == 0 && 'mt-3'}`}
      >
        <div className="mb-1 fs--1">
          <FontAwesomeIcon
            icon="circle"
            className={`me-2 text-${
              color.split('-')[1] || color || 'text-info'
            }`}
          />
          {name}
        </div>
        {/* <div className="d-xxl-none">{percentage}%</div> */}
        <Flex direction={'row'} alignItems={'center'}>
          <h5>
            <CountUp
              start={0}
              end={value}
              duration={2.75}
              className="text-700 fs--0"
              separator=","
            />
          </h5>
          <div className={'fs--1 fw-semi-bold'}>개소</div>
        </Flex>
      </Flex>
    );
  };

  return (
    <Row className="h-100">
      <Col
        xl={6}
        className={'px-6'}
        style={{ minHeight: '230px', maxHeight: '230px' }}
      >
        <Row className="justify-content-center g-0">
          <h6 className="mt-1">(싱글)압밀침하분석</h6>
          <Col xs="12">
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(
                  replusAnalysis,
                  radius,
                  '압밀침하분석',
                  replusTotal
                )}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center g-0">
          <Col xs="12">
            {replusAnalysis.map((item, index) => (
              <SensorItem
                item={item}
                index={index}
                key={index}
                total={replusTotal}
              />
            ))}
          </Col>
        </Row>
      </Col>
      <Col xl={6} className={'px-6'}>
        <Row className="justify-content-center g-0">
          <h6 className="mt-1">(싱글)지표침하분석</h6>
          <Col xs="12">
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(
                  reminusAnalysis,
                  radius,
                  '지표침하분석',
                  reminusTotal
                )}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center g-0">
          <Col xs="12">
            {reminusAnalysis.map((item, index) => (
              <SensorItem
                item={item}
                index={index}
                key={index}
                total={reminusTotal}
              />
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PredictiveAnalysisChart;
