import React, { useState, useContext, useEffect } from 'react';
import Flex from 'components/common/Flex';

import { Badge, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import ItemDetail from '../detail';
import classNames from 'classnames';
import { MapsContext } from 'context/Context';
import { chainAxios } from 'helpers/chainAxios';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import SoftBadge from 'components/common/SoftBadge';

const ProjectItems = ({ project, searchText }) => {
  // BEFORE : 예정,
  // FINISH : 완료
  // ON_ISSUE : 점검요함
  // NO_ISSUE : 진행중
  const queryClient = useQueryClient();

  const { setSelectedProject } = useContext(MapsContext);
  const [marked, setMarked] = useState(project.favorite);

  const onClickProjectItem = project => {
    setSelectedProject(project);
  };

  const changeFavoriteProject = useMutation(
    project => {
      let value = 'N';
      if (!marked) {
        value = 'Y';
      }
      let url = '/api/project/' + project.id + '?favorite=' + value;
      // console.log(url);
      chainAxios.put(url);
    },
    {
      onSuccess: () => {
        setMarked(!marked);
        queryClient.invalidateQueries(['getProjects', searchText]);
      }
    }
  );

  const makeSubTitle = () => {
    // RD : 도로 / FC : 시설물 / FD : 공사현장
    let subTitle = '도로: 0 시설물: 0 공사현장: 0';
    if (!!project.facilities) {
      let road = 0;
      let facility = 0;
      let field = 0;
      project.facilities.forEach(f => {
        if (f.type === 'RD') {
          road += 1;
        }
        if (f.type === 'FC') {
          facility += 1;
        }
        if (f.type === 'FD') {
          field += 1;
        }
      });
      subTitle =
        '도로: ' +
        road.toString() +
        ' 시설물: ' +
        facility.toString() +
        ' 공사현장: ' +
        field.toString();
    }
    return subTitle;
  };

  const makeStatusBadge = () => {
    let badgeColor = 'dark';
    let badgeName = '등급확인필요';
    if (project.status === 'BEFORE') {
      badgeName = '예정';
      badgeColor = 'secondary';
    }
    if (project.status === 'FINISH') {
      badgeName = '완료';
      badgeColor = 'success';
    }
    if (project.status === 'ON_ISSUE') {
      badgeName = '점검요함';
      badgeColor = 'danger';
    }
    if (project.status === 'NO_ISSUE') {
      badgeName = '진행중';
      badgeColor = 'primary';
    }
    return <SoftBadge bg={badgeColor}>{badgeName}</SoftBadge>;
  };
  return (
    <Nav.Link
      eventKey={project.id}
      className={classNames(`chat-contact hover-actions-trigger px-3`, {
        'unread-message': false,
        'read-message': false
      })}
      style={{ height: 'auto', border: 0 }}
      // onClick={() => onClickProjectItem(project)}
    >
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="between"
        // className="py-3"
      >
        <Flex direction="row" alignItems="center">
          <div className="pe-3">
            <FontAwesomeIcon
              onClick={async () => {
                await changeFavoriteProject.mutate(project);
              }}
              icon={marked ? 'star' : ['far', 'star']}
              // transform="down-2"
              className={classNames(
                'ms-1',
                { 'text-warning': marked, 'text-300': !marked },
                'cursor-pointer'
              )}
            />
          </div>
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="around"
            style={{ cursor: 'pointer' }}
            onClick={() => onClickProjectItem(project)}
          >
            <div className="fs--0 text-700 fw-semi-bold">{project.name}</div>
            <div className="fs--1 text-900">{makeSubTitle()}</div>
          </Flex>
        </Flex>
        {makeStatusBadge()}
      </Flex>
    </Nav.Link>
  );
};

export default ProjectItems;
