import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { LoginUserContext } from 'context/Context';
import { Image, Col, Row } from 'react-bootstrap';
import FalconLightBoxGallery from '../../FalconLightBoxGallery';
import FalconLightBox from 'components/common/FalconLightBox';
import { chainAxios } from '../../../../helpers/chainAxios';

const Message = ({
  message,
  senderUserId,
  senderName,
  status,
  time,
  attachments,
  isGroup
}) => {
  const { loginUser } = useContext(LoginUserContext);
  const isLeft = senderUserId !== loginUser.userId;

  const [chatFiles, setChatFiles] = useState([]);

  useEffect(() => {
    if (attachments !== null) {
      // console.log('Message attachments : ', attachments);
      getAttachmentFiles(attachments);
    }
  }, [attachments]);

  const getAttachmentFiles = attachments => {
    let files = [];
    attachments.forEach((attachement, index) => {
      let url = process.env.REACT_APP_API_SERVER + 'api/file/' + attachement;
      files.push(url);
    });

    setChatFiles(files);
  };

  return (
    <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
      {/*{isLeft && <Avatar size="l" className="me-2" src={avatarSrc} />}*/}
      {isLeft && (
        <Avatar
          size="xl"
          isExact={true}
          name={senderName.substring(0, 1)}
          className="me-2"
        />
      )}
      <div
        className={classNames('flex-1', {
          'd-flex justify-content-end': !isLeft
        })}
      >
        <div
          className={classNames('w-xxl-75', {
            'w-100': !isLeft
          })}
        >
          {isLeft && (
            <span className="text-600 fs--2">
              {senderName}({senderUserId})
            </span>
          )}
          <Flex
            direction={'column'}
            alignItems={!isLeft ? 'end' : 'start'}
            className={classNames('hover-actions-trigger')}
          >
            {/* {!isLeft && <ChatMessageOptions />} */}
            {chatFiles && chatFiles.length > 1 ? (
              <div className="chat-message chat-gallery">
                <FalconLightBoxGallery images={chatFiles}>
                  {setImgIndex => (
                    <Row
                      className="mx-n1"
                      as={Flex}
                      justifyContent={!isLeft ? 'end' : 'start'}
                    >
                      {chatFiles.map((img, index) => {
                        return (
                          <Col
                            xs={6}
                            md={4}
                            className="px-1"
                            style={{ minWidth: 50 }}
                            key={index}
                          >
                            <Image
                              fluid
                              rounded
                              className="mb-2 cursor-pointer"
                              src={img}
                              alt=""
                              onClick={() => setImgIndex(index)}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </FalconLightBoxGallery>
                <Flex
                  direction={'column'}
                  alignItems={!isLeft ? 'end' : 'start'}
                  className={classNames('hover-actions-trigger')}
                >
                  <div
                    className={classNames('p-2 rounded-2 chat-message', {
                      'bg-200': isLeft,
                      'bg-primary text-white light': !isLeft
                    })}
                  >
                    {message && (
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: message ? message : ''
                        }}
                      />
                    )}
                  </div>
                </Flex>
              </div>
            ) : (
              <>
                {chatFiles && chatFiles.length === 1 && (
                  <Flex direction={'column'} className={'pb-2'}>
                    <FalconLightBox image={chatFiles} className={'pb-1'}>
                      <Image fluid rounded src={chatFiles} width={150} alt="" />
                    </FalconLightBox>
                  </Flex>
                )}
                <Flex direction={'column'}>
                  <div
                    className={classNames('p-2 rounded-2 chat-message', {
                      'bg-200': isLeft,
                      'bg-primary text-white light': !isLeft
                    })}
                    style={{ maxWidth: 'none' }}
                  >
                    {message && (
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: message ? message : ''
                        }}
                      />
                    )}
                  </div>
                </Flex>
              </>
            )}
            {/* {isLeft && <ChatMessageOptions />} */}
          </Flex>
          <div
            className={classNames('text-400 fs--2', {
              'text-end': !isLeft
            })}
          >
            {isLeft && isGroup && (
              <span className="font-weight-semi-bold me-2">{name}</span>
            )}
            {time}
            {/*{!isLeft && !!message && !!status && (*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={classNames({*/}
            {/*      check: status === 'SEEN' || status === 'SENT',*/}
            {/*      'check-double': status === 'DELIVERED'*/}
            {/*    })}*/}
            {/*    className={classNames('ms-2', {*/}
            {/*      'text-success': status === 'SEEN'*/}
            {/*    })}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </div>
      </div>
    </Flex>
  );
};

Message.defaultProps = { status: '' };

export default Message;
