import React, { useState, useContext } from 'react';
import Flex from 'components/common/Flex';

import { Badge, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { MapsContext } from 'context/Context';
import ItemDetail from '../detail';
import { chainAxios } from 'helpers/chainAxios';
import SoftBadge from 'components/common/SoftBadge';

const FacilityItems = ({ facilitie }) => {
  // console.log(facilitie);
  const [detailShow, setDetailShow] = useState(false);
  const [marked, setMarked] = useState(facilitie.favorite);
  const { miniMapSelectedSensor, setMiniMapSelectedSensor } =
    useContext(MapsContext);

  const detailModalShowEvent = () => {
    setDetailShow(!detailShow);
    setMiniMapSelectedSensor('');
  };

  const onClickMarked = facility => {
    // http://112.175.18.232:7000/api/facility/1?favorite=Y
    let value = 'N';
    if (!marked) {
      value = 'Y';
    }
    let url = '/api/facility/' + facility.id + '?favorite=' + value;
    // console.log('onClickMarked url : ', url);
    chainAxios
      .put(url)
      .then(response => {
        // console.log('onClickMarked : ', response);
        if (response.data.resultCode === 0) {
          setMarked(!marked);
        }
      })
      .catch(error => {
        console.log('onClickMarked : ', error);
      });
  };

  const makeSubTitle = () => {
    let subTitle = '-';
    if (!!facilitie.address) {
      subTitle = facilitie.address;
    }
    return subTitle;
  };

  const makeStatusBadge = () => {
    let badgeColor = 'dark';
    let badgeName = '등급확인필요';
    if (facilitie.status === '4') {
      badgeName = 'A등급';
      badgeColor = 'primary';
    }
    if (facilitie.status === '3') {
      badgeName = 'B등급';
      badgeColor = 'info';
    }
    if (facilitie.status === '2') {
      badgeName = 'C등급';
      badgeColor = 'secondary';
    }
    if (facilitie.status === '1') {
      badgeName = 'D등급';
      badgeColor = 'warning';
    }
    if (facilitie.status === '0') {
      badgeName = 'E등급';
      badgeColor = 'danger';
    }

    return (
      <SoftBadge bg={badgeColor} className="mb-1">
        {badgeName}
      </SoftBadge>
    );
  };

  const makeEventStatusBadge = () => {
    let badgeColor = 'dark';
    let badgeName = '등급확인필요';

    if (!!facilitie.eventStatus) {
      badgeColor = 'primary';
      badgeName = '진행중';

      if (facilitie.eventStatus.WIP > 0 || facilitie.eventStatus.PRE > 0) {
        badgeColor = 'warning';
        badgeName = '점검요함';
      }
    }
    return <SoftBadge bg={badgeColor}>{badgeName}</SoftBadge>;
  };

  return (
    <Nav.Link
      eventKey={facilitie.id}
      className={classNames(`chat-contact hover-actions-trigger px-3`, {
        'unread-message': false,
        'read-message': false
      })}
      style={{ height: 'auto', border: 0 }}
      //   onClick={() => detailModalShowEvent}
    >
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="between"
        // className="py-3"
      >
        <Flex direction="row" alignItems="center">
          <div className="pe-3">
            <FontAwesomeIcon
              onClick={() => onClickMarked(facilitie)}
              icon={marked ? 'star' : ['far', 'star']}
              // transform="down-2"
              className={classNames(
                'ms-1',
                { 'text-warning': marked, 'text-300': !marked },
                'cursor-pointer'
              )}
            />
          </div>
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="around"
            style={{ cursor: 'pointer' }}
            onClick={detailModalShowEvent}
          >
            <div className="fs--0 text-700 fw-semi-bold">{facilitie.name}</div>
            <div className="fs--1 text-900">{makeSubTitle()}</div>
          </Flex>
        </Flex>
        <Flex
          alignItems="end"
          direction="column"
          justifyContent="between"
          // className="py-3"
        >
          {makeStatusBadge()}
          {makeEventStatusBadge()}
        </Flex>
      </Flex>
      {detailShow && (
        <ItemDetail
          facilityId={facilitie.id}
          detailModalShow={detailShow}
          detailModalShowEvent={detailModalShowEvent}
        />
      )}
    </Nav.Link>
  );
};
export default FacilityItems;
