import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { GraphicComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import {
  getChartSeriesColor,
  getColor,
  getPastDates,
  isEmpty,
  rgbaColor
} from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Flex from 'components/common/Flex';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent,
  GraphicComponent,
  CanvasRenderer
]);
const EventDetailChart = ({ title, chartData }) => {
  const tooltipFormatter = params => {
    // console.log(params);
    return `<span class='text-800'>${params.name}</span><br/>
  <div class="dot me-1 fs--2 d-inline-block" style="background-color: ${
    params.borderColor
  }"></div>
        <span class='text-600 fs--1'>${params.seriesName} : ${
      params.value === undefined ? '-' : params.value
    }건</span>`;
  };
  const getOptions = (legends, dates, series) => ({
    color: getColor('gray-100'),
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-100'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0
    },
    legend: {
      data: legends,
      show: true,
      type: 'scroll',
      orient: 'horizontal',
      // orient: 'vertical',
      right: 10,
      top: 0,
      bottom: 20
      // selector: [
      //   {
      //     type: 'all',
      //     title: '전체선택'
      //   },
      //   {
      //     type: 'inverse',
      //     title: '반전'
      //   }
      // ]
    },
    xAxis: {
      type: 'category',
      data: dates,
      boundaryGap: true,
      axisPointer: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: rgbaColor('#000', 0.01),
          type: 'dashed'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        margin: 15,
        formatter: value => dayjs(value).format('YY-MM-DD')
      }
    },
    yAxis: {
      type: 'value',
      // interval: 1, // 간격 설정
      min: 0,
      // max: 100,
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    dataZoom: [
      {
        show: true,
        type: 'inside',
        filterMode: 'none',
        xAxisIndex: [0],
        startValue: -9999,
        endValue: 100
      }
      // {
      //   show: true,
      //   type: 'inside',
      //   filterMode: 'none',
      //   yAxisIndex: [0],
      //   startValue: -20,
      //   endValue: 20
      // }
    ],
    series: series,
    grid: {
      right: '6%',
      left: '7%',
      bottom: '15%',
      top: '15%',
      containLabel: false
    }
  });

  const chartRef = useRef(null);

  const [series, setSeries] = useState([]);
  const [legends, setLegends] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (!isEmpty(chartData)) {
      makeDetailChartData(chartData);
    }
  }, [chartData]);

  const makeDetailChartData = data => {
    // console.log(data);
    const seriesColors = [
      'primary',
      'danger',
      'warning',
      'success',
      'secondary',
      'info'
    ];

    let sortKey = [];
    let makeSeries = [];
    let originDates = [];
    let originLegends = [];
    let iNum = 0;

    Object.keys(data.chart.legend).forEach((key, index) => {
      sortKey.push(key);
    });

    sortKey.sort().forEach((key, index) => {
      if (key.toUpperCase() !== 'X') {
        let values = [];
        originLegends.push(data.chart.legend[key]);
        data.chart.chartData.forEach((item, index) => {
          originDates.push(item.x);
          values.push(item[key]);
        });

        makeSeries.push({
          name: data.chart.legend[key],
          type: 'line',
          connectNulls: true,
          data: values,
          lineStyle: { color: getColor(seriesColors[iNum]) },
          itemStyle: {
            borderColor: getColor(seriesColors[iNum]),
            borderWidth: 2,
            backgroundColor: 'transparent'
          },
          symbol: 'circle',
          symbolSize: 10,
          showSymbol: true,
          emphasis: {
            scale: true
          }
          // smooth: true
        });

        iNum++;
      }
    });
    // console.log(Array.from(new Set(originDates)));
    // console.log(originLegends);
    // console.log(makeSeries);
    setSeries(makeSeries);
    setLegends(originLegends);
    setDates(Array.from(new Set(originDates)));
  };

  return (
    <>
      <h6 className="mt-1 text-center">{title}</h6>
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        option={getOptions(legends, dates, series)}
      />
    </>
  );
};

export default EventDetailChart;
