import React, { useRef, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import DataTrafficChart from './DataTrafficChart';
import moment from 'moment/moment';
import { chainAxios } from 'helpers/chainAxios';
import { getDates } from 'helpers/utils';
import AppContext from 'context/Context';
import { useDataTraffic } from '../hook/useDashboard';
import IconButton from '../../common/IconButton';
import FalconCardHeader from '../../common/FalconCardHeader';
import echarts from 'echarts';

const DataTraffic = () => {
  const { data: traffics, isLoading, isFetching } = useDataTraffic();
  const chartRef = useRef(null);

  const [dataTrafficChartData, setDataTrafficChartData] = useState([]);

  useEffect(() => {
    // dataTrafficInfo();
    makeDataTrafficChartData(traffics);
  }, []);

  useEffect(() => {
    if (!isFetching) {
      makeDataTrafficChartData(traffics);
    }
  }, [isFetching]);

  const makeDataTrafficChartData = trafficDatas => {
    // console.log('DataTrafficChart makeDataTrafficChartData() : ', trafficDatas);
    let startDate = moment().subtract(30, 'days');
    let endDate = moment();

    let chartDates = [];
    let chartAllSendCount = [];
    let chartErrorSendCount = [];

    let dates = getDates(startDate, endDate);

    dates.forEach(date => {
      let convertDate = moment(date).format('YYYY-MM-DD');
      chartDates.push(convertDate);
      let trafficData = trafficDatas.filter(data => data['x'] === convertDate);

      if (!!trafficData && trafficData.length > 0) {
        chartAllSendCount.push(trafficData[0].y1);
        chartErrorSendCount.push(trafficData[0].y2);
      } else {
        chartAllSendCount.push('0');
        chartErrorSendCount.push('0');
      }
    });

    let chartDatas = [];

    chartDatas.push({
      date: chartDates,
      allSendCount: chartAllSendCount,
      errorSendCount: chartErrorSendCount
    });

    setDataTrafficChartData(chartDatas[0]);

    // console.log(chartDatas[0]);
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="지반 데이터 전송 현황 (총전송수 / 에러전송수)"
        light
        titleTag="h6"
      />
      <Card.Body className="pe-xxl-0">
        <DataTrafficChart
          data={dataTrafficChartData}
          ref={chartRef}
          // style={{ width: '100%' }}
        />
      </Card.Body>
    </Card>
  );
};

export default DataTraffic;
