import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Dropdown, Card, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';

import AppContext, { CourseContext, SocketContext } from 'context/Context';
import StatisticsStats from '../stats';
import {
  formatDateToYYYYMMDD,
  getColor,
  getSearchPeriodAmount,
  isEmpty,
  toStringByFormatting
} from 'helpers/utils';
import IconButton from '../../common/IconButton';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { chainAxios } from 'helpers/chainAxios';
import AdvanceTableWrapper from '../../common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../common/advance-table/AdvanceTable';
import ResponsivePagination from 'react-responsive-pagination';
import MonitoringReport from './view/monitoring';

const StatisticsReport = () => {
  const columns = [
    {
      accessor: 'month',
      Header: '날짜',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { month } = rowData.row.original;
        return (
          <Button
            className={'fs--1 text-truncate text-center'}
            variant="link"
            onClick={() => {
              onClickViewReport(rowData.row.original);
            }}
          >
            {month}
          </Button>
        );
      }
    }
  ];

  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  useEffect(() => {
    getProjects();
    getFacilities();

    setConfig('isNavbarVerticalCollapsed', false);
    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  const printAreaRef = useRef();

  const [detailShow, setDetailShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);

  const [scope, setScope] = useState('');
  const [projects, setProjects] = useState([]);
  const [facilities, setFacilities] = useState([]);

  const [reports, setReports] = useState([]);
  const [displayReports, setDisplayReports] = useState([]);

  const [totalElements, setTotalElements] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 10;

  useEffect(() => {
    if (!isEmpty(reports)) {
      makePagingData(reports);
    }
  }, [currentPage]);

  const onSelectProject = value => {
    setScope('project');
    setSelectedProject(projects.filter(project => project.id === value)[0]);
    setSelectedFacility('');
    setDisplayReports([]);
  };

  const onSelectFacility = value => {
    setScope('facility');
    setSelectedProject('');
    setSelectedFacility(
      facilities.filter(facility => facility.id === value)[0]
    );
    setDisplayReports([]);
  };

  const pageStyle = `
    @media print {
      .col-lg-4 {
        width: 40% !important;
      }
      .col-lg-8 {
        width: 50% !important;
      }
    }
  `;

  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
    pageStyle: pageStyle
  });

  const getProjects = () => {
    let url = '/api/projects?pageNo=0&pageSize=99999&sort=name%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('Project getProjectInfo() : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getProjects : ', response.data);
          setProjects(response.data.data.projectList.dataList);
        }
      })
      .catch(error => {
        console.log('getProjects ERROR : ', error);
      });
  };

  const getFacilities = () => {
    let url = '/api/facilities?pageNo=0&pageSize=99999&sort=name%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('facilityInfo : ', response);
        if (response.data.resultCode === 200) {
          // console.log('getFacilities : ', response.data);
          setFacilities(response.data.data.facilityList.dataList);
        }
      })
      .catch(error => {
        console.log('getFacilities ERROR : ', error);
      });
  };

  const onClickReportMonth = () => {
    if (selectedProject) {
      makeReportList(selectedProject);
    }

    if (selectedFacility) {
      if (selectedFacility.project) {
        let project = projects.filter(
          project => project.id === selectedFacility.project.key
        );
        if (project) {
          makeReportList(project[0]);
        } else {
          console.error('일치하는 프로젝트가 없습니다.');
        }
      } else {
        console.error('맵핑된 프로젝트가 없습니다.');
      }
    }
  };

  const makeReportList = project => {
    const result = getMonthsArrayWithStartAndEndDates(
      new Date(project.initDate),
      new Date(project.endDate)
    );

    setReports(result);
    setTotalElements(result.length);
    setTotalPages(Math.ceil(result.length / _pageSize));
    setCurrentPage(1);

    makePagingData(result);
  };

  const makePagingData = datas => {
    if (datas === undefined) {
      return;
    }

    let startIndex = (currentPage - 1) * _pageSize;
    let endIndex = currentPage * _pageSize;

    if (endIndex >= datas.length) {
      endIndex = datas.length;
    }

    let displayValues = [];

    for (startIndex; startIndex < endIndex; startIndex += 1) {
      displayValues.push(datas[startIndex]);
    }

    setDisplayReports(displayValues);
  };

  const getMonthsArrayWithStartAndEndDates = (startDate, endDate) => {
    let result = [];
    let currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );

    // 오늘 날짜 설정
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 오늘 날짜의 시간 부분을 00:00:00으로 설정

    // endDate가 오늘 이후인 경우, 오늘까지만 확인하도록 설정
    const effectiveEndDate = endDate > today ? today : endDate;

    while (currentDate <= effectiveEndDate) {
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1; // 월은 0부터 시작하므로 +1
      let formattedMonth = `${year}-${month.toString().padStart(2, '0')}`; // yyyy-MM 형식

      // 현재 달은 제외
      if (year === today.getFullYear() && month === today.getMonth() + 1) {
        break; // 현재 달에 도달하면 while 루프 종료
      }

      // 해당 월의 첫 번째 날
      let startOfMonth = new Date(year, month - 1, 1);

      // 해당 월의 마지막 날 (기본적으로 설정)
      let endOfMonth = new Date(year, month, 0);

      // 종료일이 오늘 날짜를 초과할 경우, 종료일을 오늘로 설정
      if (endOfMonth > effectiveEndDate) {
        endOfMonth = effectiveEndDate;
      }

      // 결과 배열에 추가
      result.push({
        month: formattedMonth,
        start: formatDateToYYYYMMDD(startOfMonth),
        end: formatDateToYYYYMMDD(endOfMonth),
        sort: startOfMonth
      });

      // 다음 달로 이동
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // 최신 순으로 정렬
    result.sort((a, b) => b.sort - a.sort);

    return result;
  };

  const onClickViewReport = rowData => {
    let address = '-';

    if (
      selectedProject &&
      selectedProject.properties &&
      selectedProject.properties.PJADDRESS
    ) {
      address = selectedProject.properties.PJADDRESS;
    }

    if (selectedFacility) {
      address = selectedFacility.address;
    }

    setSelectedRowData({
      ...rowData,
      scope: scope,
      scopeId: scope === 'project' ? selectedProject.id : selectedFacility.id,
      scopeName:
        scope === 'project' ? selectedProject.name : selectedFacility.name,
      scopeAddress: address
    });
    setDetailShow(true);
  };

  const detailModalShowEvent = () => {
    setDetailShow(!detailShow);
    setSelectedRowData(null);
  };

  return (
    <>
      {detailShow && (
        <MonitoringReport
          params={selectedRowData}
          detailModalShow={detailShow}
          detailModalShowEvent={detailModalShowEvent}
        />
      )}
      <Row className="g-3 mb-3">
        <Col md={12} xl={12} xxl={12}>
          <Card className="h-md-100">
            <Card.Header>
              <h5>보고서 샘플</h5>
            </Card.Header>
            <Card.Body>
              <Row
                as={Flex}
                alignItems="center"
                justifyContent="start"
                className={'px-3 pt-3'}
                style={{ backgroundColor: getColor('gray-100') }}
              >
                <Row as={Flex} alignItems="center" justifyContent="end">
                  <Col
                    xs={12}
                    md={10}
                    xl={6}
                    xxl={4}
                    className={'pb-3'}
                    as={Flex}
                    alignItems="center"
                    alignContent={'center'}
                    justifyContent="end"
                  >
                    <Dropdown onSelect={onSelectProject} className={'me-3'}>
                      <Dropdown.Toggle variant="falcon-default" size="sm">
                        {!!selectedProject
                          ? selectedProject.name
                          : '프로젝트를 선택하세요'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="py-2">
                        {projects.map(project => (
                          <Dropdown.Item key={project.id} eventKey={project.id}>
                            {project.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown onSelect={onSelectFacility} className={'me-3'}>
                      <Dropdown.Toggle variant="falcon-default" size="sm">
                        {!!selectedFacility
                          ? selectedFacility.name
                          : '시설물을 선택하세요'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="py-2">
                        {facilities.map(facility => (
                          <Dropdown.Item
                            key={facility.id}
                            eventKey={facility.id}
                          >
                            {facility.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="search"
                      className="me-1 mb-sm-0 ms-3"
                      iconClassName="me-1"
                      onClick={() => onClickReportMonth()}
                    />
                  </Col>
                </Row>
              </Row>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className={'px-3 pt-3'}
              >
                <Flex
                  alignItems="end"
                  alignContent={'end'}
                  direction="column"
                  justifyContent="start"
                  className={'fs--1'}
                >
                  총 {totalElements}건
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={displayReports}
                sortable
                pagination
                perPage={_pageSize}
              >
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle text-center"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    hover: true,
                    className: 'fs--1 mb-0 overflow-hidden text-center'
                  }}
                />
              </AdvanceTableWrapper>
              {displayReports.length !== _pageSize && (
                <div
                  style={{
                    height:
                      ((_pageSize - displayReports.length) * 57.5).toString() +
                      'px'
                  }}
                ></div>
              )}
              <div className="mt-3">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                  maxWidth={350}
                  extraClassName={'justify-content-center fs--2'}
                  previousLabel="‹"
                  nextLabel="›"
                  // narrowBehaviour={combine(dropNav, dropEllipsis)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3" ref={printAreaRef}>
        {/*<Col md={6} xs={12}>*/}
        {/*  <EventStaticstics*/}
        {/*    searchStartDate={searchStartDate}*/}
        {/*    searchEndDate={searchEndDate}*/}
        {/*  />*/}
        {/*</Col>*/}
        {/*<Col md={6} xs={12}>*/}
        {/*  <SensorProcessStaticstics*/}
        {/*    searchStartDate={searchStartDate}*/}
        {/*    searchEndDate={searchEndDate}*/}
        {/*  />*/}
        {/*</Col>*/}
      </Row>
      <button onClick={handlePrint}>Print this out!</button>
    </>
  );
};

export default StatisticsReport;
