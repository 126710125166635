import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, getPastDates, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  return params
    .map(
      ({ value, borderColor, name }, index) =>
        `${index === 0 ? `<span class='text-800'>${name}</span><br/>` : ''}` +
        `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>
              <span class='text-600 fs--1'>${'일 평균 침하각'}: ${value.toLocaleString()}°</span>`
    )
    .join('<br/>');
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: ['avgSubs'],
    show: false,
    type: 'scroll',
    orient: 'horizontal',
    // orient: 'vertical',
    right: 10,
    top: 0,
    bottom: 20,
    formatter: function (name) {
      return '침하각';
    }
  },
  xAxis: {
    type: 'category',
    data: data.dates,
    boundaryGap: true,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: {
      show: true, // 보조선 표시
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      },
      alignWithLabel: true // 보조선을 레이블과 맞춤
    },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => dayjs(value).format('YYYY-MM-DD'),
      interval: 'auto', // 자동으로 레이블 간격 조정
      rotate: 45, // 레이블을 45도 회전
      fontSize: 10 // 글꼴 크기를 줄임
    }
  },
  yAxis: {
    type: 'value',
    name: '평균 침하각(°)', // Y축에 추가적인 문구를 추가
    nameLocation: 'middle', // 문구 위치 (start, middle, end)
    nameTextStyle: {
      color: getColor('gray-600'),
      fontWeight: 'regular',
      fontSize: 12,
      padding: [0, 0, 10, 0] // 이름의 위치를 조정하기 위한 패딩 설정
    },
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 5 // Y축 값 레이블의 간격을 조정하여 겹침 방지
    },
    axisTick: { show: false },
    axisLine: { show: false }
    // interval: 1 // Y축 간격 설정 (20단위로 레이블 표시)
  },
  series: [
    {
      name: 'avgSubs',
      type: 'line',
      data: data.avgSubs,
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      symbol: 'circle',
      symbolSize: 10,
      emphasis: {
        scale: true
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      },
      smooth: false,
      animation: false,
      animationDuration: 1000, // 애니메이션의 지속 시간 설정
      animationDurationUpdate: 1000, // 업데이트 시 애니메이션의 지속 시간 설정
      animationEasing: 'linear', // 애니메이션의 진행 방식 설정
      animationEasingUpdate: 'linear' // 업데이트 시 애니메이션의 진행 방식 설정
    }
  ],
  grid: {
    right: '2%',
    left: '7%',
    bottom: '20%',
    top: '5%',
    containLabel: false
  }
});

const AverageDailyMeanSubsChart = forwardRef(({ data }, ref) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      // 1. 기존 차트 인스턴스를 초기화(삭제)
      echarts.dispose(chartRef.current.getEchartsInstance());
    }
    // 2. 새로운 차트를 생성
    chartRef.current.getEchartsInstance().setOption(getOptions(data));
  }, [data]);

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getOptions(data)}
      className={'ps-4'}
    />
  );
});

export default AverageDailyMeanSubsChart;
