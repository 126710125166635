import { useState } from 'react';
import { useQuery } from 'react-query';
import { getStatEvent, getStatSensorProcess } from '../api';

export function useStatEvent() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    ['getStatEvent', startDate, endDate],
    () => getStatEvent(startDate, endDate),
    {
      staleTime: 5000,
      cacheTime: 300000,
      refetchOnMount: true,
      refetchOnReconnect: true
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}

export function useStatSensorProcess() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const {
    isLoading,
    isFetching,
    data = {}
  } = useQuery(
    ['getStatSensorProcess', startDate, endDate],
    () => getStatSensorProcess(startDate, endDate),
    {
      staleTime: 5000,
      cacheTime: 300000,
      refetchOnMount: true,
      refetchOnReconnect: true
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
}
