import { Button, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import React, { useEffect, useState } from 'react';
import { chainAxios } from 'helpers/chainAxios';
import { fromLonLat } from 'ol/proj';
import ResponsivePagination from 'react-responsive-pagination';
import SoftBadge from '../../../../common/SoftBadge';
import { useNavigate } from 'react-router-dom';

const ProjectSensorList = ({ project }) => {
  const sensorColumns = [
    // {
    //   accessor: 'no',
    //   Header: 'No',
    //   headerProps: { className: 'text-900' },
    //   Cell: rowData => {
    //     const { index } = rowData.row;
    //     return (
    //       <Flex alignItems="center">
    //         <div className="flex-1 fs--1 text-truncate text-center text-800">
    //           {totalElements - index}
    //         </div>
    //       </Flex>
    //     );
    //   }
    // },
    {
      accessor: 'id',
      Header: '센서Id',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <div className={'fs--1 text-truncate text-center text-800'}>{id}</div>
        );
      }
    },
    {
      accessor: 'sensorHole.value',
      Header: '센서',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { sensorHole, id } = rowData.row.original;
        return (
          <Button
            className={'fs--1'}
            variant="link"
            onClick={() => {
              goToSensorDetail(id);
            }}
          >
            {!!sensorHole ? sensorHole.value : '-'}
          </Button>
        );
      }
    },
    {
      accessor: 'status',
      Header: '상태',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        let badgeColor = 'dark';
        if (status === 'OFF') {
          badgeColor = 'warning';
        }
        if (status === 'ERR') {
          badgeColor = 'danger';
        }
        if (status === 'ON') {
          badgeColor = 'primary';
        }
        return (
          <SoftBadge bg={badgeColor} className="fs--2">
            {status}
          </SoftBadge>
        );
      }
    }
  ];

  const navigate = useNavigate();

  const [projectSensors, setProjectSensors] = useState([]);
  const [diplaySensors, setDisaplaySensors] = useState([]);

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 5;

  useEffect(() => {
    if (
      project !== undefined &&
      project != null &&
      JSON.stringify(project) != '{}'
    ) {
      getProjectSensors();
    }
  }, [project]);

  useEffect(() => {
    if (projectSensors != undefined) {
      makePagingData(projectSensors);
    }
  }, [currentPage]);

  const getProjectSensors = () => {
    // http://112.175.18.232:7000/api/project/1/sensors?sort=id%24asc
    let url =
      '/api/project/' +
      project?.id +
      '/sensors?pageNo=' +
      (currentPage - 1) +
      '&pageSize=' +
      _pageSize +
      '&sort=id%24asc';

    console.log(url);
    chainAxios
      .get(url)
      .then(response => {
        console.log('Project getProjectSensors() : ', response);
        if (response.data.resultCode === 200) {
          let sensorList = response.data.data.sensorList;

          let totalElements = response.data.data.sensorList.length;
          setTotalPages(Math.ceil(totalElements / _pageSize));
          setTotalElements(totalElements);

          sensorList.sort((a, b) => {
            if (a.sensorHole.value > b.sensorHole.value) return 1;
            if (a.sensorHole.value < b.sensorHole.value) return -1;
          });
          setProjectSensors(sensorList);

          makePagingData(sensorList);
        } else {
          setTotalPages(1);
          setTotalElements(0);
          setProjectSensors([]);
          setDisaplaySensors([]);
        }
      })
      .catch(error => {
        console.log('Project getProjectSensors() : ', error);
      });
  };
  const makePagingData = sensors => {
    if (sensors === undefined) {
      return;
    }

    // console.log(sensors);
    // console.log(currentPage);
    let startIndex = (currentPage - 1) * _pageSize;
    let endIndex = currentPage * _pageSize;

    if (endIndex >= sensors.length) {
      endIndex = sensors.length;
    }

    let displayValues = [];

    for (startIndex; startIndex < endIndex; startIndex += 1) {
      // console.log(startIndex);
      // console.log(sensors[startIndex]);
      displayValues.push(sensors[startIndex]);
    }

    setDisaplaySensors(displayValues);
  };

  const goToSensorDetail = sensorId => {
    navigate('/management-target/sensor/' + sensorId);
  };

  return (
    // <Card style={{ minHeight: 'inherit' }}>
    //   <Card.Header>
    //     <h6>프로젝트 도로·시설물 센서 목록</h6>
    //   </Card.Header>
    //   <Card.Body>
    <>
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="end"
        className={'px-3 py-3'}
        style={{ backgroundColor: getColor('gray-100') }}
      >
        <Flex alignItems="center" direction="row" justifyContent="end">
          <Flex alignItems="center" direction="row" justifyContent="start">
            <AdvanceTableSearchBox
              table
              globalFilter={''}
              setGlobalFilter={() => {}}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="between"
        className={'px-3 pt-3'}
      >
        <Flex
          alignItems="end"
          alignContent={'end'}
          direction="column"
          justifyContent="start"
          className={'fs--1'}
        >
          총 {totalElements}건
        </Flex>
      </Flex>
      <AdvanceTableWrapper
        columns={sensorColumns}
        data={diplaySensors}
        sortable
        pagination
        perPage={5}
      >
        <AdvanceTable
          table
          headerClassName="text-nowrap align-middle text-center"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            hover: true,
            className: 'fs--1 mb-0 overflow-hidden text-center'
          }}
        />
        <div className="mt-3">
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={setCurrentPage}
            maxWidth={350}
            extraClassName={'justify-content-center fs--2'}
            previousLabel="‹"
            nextLabel="›"
            // narrowBehaviour={combine(dropNav, dropEllipsis)}
          />
        </div>
      </AdvanceTableWrapper>
    </>
    //   </Card.Body>
    // </Card>
  );
};

export default ProjectSensorList;
