import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext, { MapsContext } from 'context/Context';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Flex from 'components/common/Flex';
import { chainAxios } from '../../../helpers/chainAxios';
import ItemDetail from '../../maps/left-area/detail';
import AddFacility from './add';
import AdvanceTableSearchBox from '../../common/advance-table/AdvanceTableSearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Carousel from 'react-bootstrap/Carousel';
import { Feature, Map, View } from 'ol';
import { defaults } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import { Group, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import { Fill, Icon, Style, Text } from 'ol/style';
import mapMarker from '../../../assets/img/icons/map-marker.png';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import RoadFacilitiesMap from './RoadFacilitiesMap';
import { getColor } from '../../../helpers/utils';
import ResponsivePagination from 'react-responsive-pagination';
import IconButton from '../../common/IconButton';
import SoftBadge from '../../common/SoftBadge';
import { useNavigate } from 'react-router-dom';

const RoadFacilities = () => {
  const columns = [
    {
      accessor: 'favorite',
      Header: '즐겨찾기',
      headerProps: {
        className: 'text-900 --falcon-gray-100',
        style: { width: '95px' }
      },
      cellProps: {
        style: { width: '95px' }
      },
      Cell: rowData => {
        const { favorite } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <FontAwesomeIcon
              onClick={() => {}}
              icon={favorite ? 'star' : ['far', 'star']}
              // transform="down-2"
              className={classNames(
                { 'text-primary': favorite, 'text-300': !favorite },
                'cursor-pointer'
              )}
            />
          </Flex>
        );
      }
    },
    {
      accessor: 'projectName',
      Header: '프로젝트명',
      headerProps: { className: 'text-900', style: { width: '250px' } },
      cellProps: {
        style: { width: '250px' }
      },
      Cell: rowData => {
        // console.log(rowData.row.original);
        const { value } = rowData.row.original.project;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {value}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'name',
      Header: '도로·시설물',
      headerProps: { className: 'text-900', style: { width: '250px' } },
      cellProps: {
        style: { width: '250px' }
      },
      Cell: rowData => {
        const { name, id } = rowData.row.original;
        return (
          <Button
            variant="link"
            className={'fs--1'}
            onClick={() => {
              // console.log(rowData.row.original);
              // setFacilitiesDetail(rowData.row.original);
              goToRoadFacilityDetail(id);
            }}
          >
            {name}
          </Button>
        );
      }
    },
    {
      accessor: '7',
      Header: '침하센서',
      headerProps: { className: 'text-900', style: { width: '120px' } },
      cellProps: {
        style: { width: '120px' }
      }
    },
    {
      accessor: 'type',
      Header: '종류',
      headerProps: { className: 'text-900', style: { width: '120px' } },
      cellProps: {
        style: { width: '120px' }
      },
      Cell: rowData => {
        const { id, type } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {type === 'RD' ? '도로' : type === 'FC' ? '시설물' : '공사현장'}(
              {id})
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'address',
      Header: '주소',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { address } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-start flex-1 text-800'}>
              {address}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'status',
      Header: '예측 안전등급',
      headerProps: { className: 'text-900', style: { width: '120px' } },
      cellProps: {
        style: { width: '120px' }
      },
      Cell: rowData => {
        const { status } = rowData.row.original;

        let badgeColor;
        let badgeName;
        if (status === '0') {
          badgeName = 'E등급';
          badgeColor = 'danger';
        } else if (status === '1') {
          badgeName = 'D등급';
          badgeColor = 'warning';
        } else if (status === '2') {
          badgeName = 'C등급';
          badgeColor = 'secondary';
        } else if (status === '3') {
          badgeName = 'B등급';
          badgeColor = 'info';
        } else if (status === '4') {
          badgeName = 'A등급';
          badgeColor = 'primary';
        } else {
          badgeName = '등급확인필요';
          badgeColor = 'dark';
        }

        return (
          <SoftBadge pill bg={badgeColor} className="me-2 fs--2 text-800">
            {badgeName}
          </SoftBadge>
        );
      }
    },
    {
      accessor: '5',
      Header: '담당부서',
      headerProps: { className: 'text-900', style: { width: '150px' } },
      cellProps: {
        style: { width: '150px' }
      }
    },
    {
      accessor: '6',
      Header: '관리업체',
      headerProps: { className: 'text-900', style: { width: '150px' } },
      cellProps: {
        style: { width: '150px' }
      }
    },
    {
      accessor: '8',
      Header: '관리시작일',
      headerProps: { className: 'text-900', style: { width: '150px' } },
      cellProps: {
        style: { width: '150px' }
      }
    },
    {
      accessor: '9',
      Header: '관리종료일',
      headerProps: { className: 'text-900', style: { width: '150px' } },
      cellProps: {
        style: { width: '150px' }
      }
    }
  ];

  const navigate = useNavigate();

  const [facilitiesInfo, setFacilitiesInfo] = useState([]);
  const [facilityStatus, setFacilityStatus] = useState({});

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 10;

  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const { setSelectedProject } = useContext(MapsContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);
    setSelectedProject({});

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  useEffect(() => {
    // getProjectStatus();
    getFacilities();
  }, []);

  useEffect(() => {
    getFacilities();
  }, [currentPage]);

  const getFacilities = () => {
    let url =
      '/api/facilities?pageNo=' +
      (currentPage - 1) +
      '&pageSize=' +
      _pageSize +
      '&sort=name%24asc';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('facilityInfo : ', response);
        if (response.data.resultCode === 200) {
          let totalElements = response.data.data.facilityList.totalElements;
          setTotalPages(Math.ceil(totalElements / _pageSize));
          setTotalElements(totalElements);

          setFacilitiesInfo(response.data.data.facilityList.dataList);
        }
      })
      .catch(error => {
        console.log('facilityInfo ERROR : ', error);
      });
  };

  const goToRoadFacilityDetail = facilityId => {
    navigate('/management-target/road-facilities/' + facilityId);
  };

  const getFacilityStatus = () => {
    // http://112.175.18.232:7000/api/facility/status
    let url = '/api/facility/status';
    chainAxios
      .get(url)
      .then(response => {
        // console.log('RoadFacilities getFacilityStatus() : ', response);
        if (response.data.resultCode === 200) {
          setFacilityStatus(response.data.data.facilityList);
        }
      })
      .catch(error => {
        console.log('RoadFacilities getFacilityStatus() : ', error);
      });
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="h-100">
            <Card.Header>
              <h5>도로·시설물</h5>
            </Card.Header>
            <Card.Body>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className={'px-3 py-3'}
                style={{ backgroundColor: getColor('gray-100') }}
              >
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="start"
                >
                  {/*<Form.Select size="sm" className="me-3">*/}
                  {/*  <option value={'status_all'}>상태 전체</option>*/}
                  {/*  /!*{projectInfo.map((project, index) => (*!/*/}
                  {/*  /!*  <option value={project.id}>{project.name}</option>*!/*/}
                  {/*  /!*))}*!/*/}
                  {/*  <option value={'BEFORE'}>예정</option>*/}
                  {/*  <option value={'FINISH'}>완료</option>*/}
                  {/*  <option value={'ON_ISSUE'}>점검요함</option>*/}
                  {/*  <option value={'NO_ISSUE'}>진행중</option>*/}
                  {/*</Form.Select>*/}
                </Flex>
                <Flex alignItems="center" direction="row" justifyContent="end">
                  <Flex
                    alignItems="center"
                    direction="row"
                    justifyContent="start"
                  >
                    <AdvanceTableSearchBox
                      table
                      globalFilter={''}
                      setGlobalFilter={() => {}}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className={'px-3 pt-3'}
              >
                <Flex
                  alignItems="end"
                  alignContent={'end'}
                  direction="column"
                  justifyContent="start"
                  className={'fs--1'}
                >
                  총 {totalElements}건
                </Flex>
                <Flex
                  alignItems={'end'}
                  alignContent={'end'}
                  direction="column"
                  justifyContent="end"
                  className={'fs--1'}
                >
                  <Form.Check
                    type="switch"
                    id="checkedSwitch"
                    label="즐겨찾기"
                    // defaultChecked
                  />
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={facilitiesInfo}
                sortable
                pagination
                perPage={_pageSize}
              >
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle text-center"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    hover: true,
                    className: 'fs--1 mb-0 overflow-hidden text-center'
                  }}
                />
              </AdvanceTableWrapper>
              {facilitiesInfo.length !== _pageSize && (
                <div
                  style={{
                    height:
                      ((_pageSize - facilitiesInfo.length) * 57.5).toString() +
                      'px'
                  }}
                ></div>
              )}
              <div className="mt-3">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                  maxWidth={350}
                  extraClassName={'justify-content-center fs--2'}
                  previousLabel="‹"
                  nextLabel="›"
                  // narrowBehaviour={combine(dropNav, dropEllipsis)}
                />
              </div>
            </Card.Body>
            <Card.Footer style={{ backgroundColor: getColor('gray-100') }}>
              <IconButton
                variant="falcon-default"
                icon="external-link-alt"
                transform="shrink-3"
                size="sm"
              >
                엑셀 내보내기
              </IconButton>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RoadFacilities;
