import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useStatEvent } from '../../hook/useStatistics';
import EventSummaryChart from './EventSummaryChart';
import EventDetailChart from './EventDetailChart';
import { isEmpty } from 'helpers/utils';

const EventStaticstics = ({ searchStartDate, searchEndDate }) => {
  const {
    data: eventStaticsData,
    isLoading,
    isFetching,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  } = useStatEvent();

  useEffect(() => {
    if (!isEmpty(searchStartDate) && !isEmpty(searchEndDate)) {
      setStartDate(searchStartDate);
      setEndDate(searchEndDate);
    }
  }, [searchStartDate, searchEndDate]);

  return (
    <Card className="h-100">
      <FalconCardHeader title="이벤트 통계" light titleTag="h6" />
      <Card.Body>
        <Row className="g-3 h-100">
          <Col lg={4} xs={12}>
            <EventSummaryChart
              title={'이벤트 관리 현황'}
              chartData={eventStaticsData}
            />
          </Col>
          <Col lg={8} xs={12}>
            <EventDetailChart
              title={'기간별 이벤트 발생 차트'}
              chartData={eventStaticsData}
            />
          </Col>
        </Row>
        {/*<RealDataSendChart data={realDataSendChartData} ref={chartRef} />*/}
      </Card.Body>
    </Card>
  );
};

export default EventStaticstics;
