import { Card, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { getChartSeriesColor, getColor, isEmpty } from 'helpers/utils';
import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);
const tooltipFormatter = params => {
  // console.log(params);
  return params
    .map(
      ({ value, name, seriesName, color }, index) =>
        `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${color}"></div>` +
        `${index === 0 ? `<span class='text-800'>${name}</span> : ` : ''}` +
        `<span class='text-600 fs--1'>${value.toLocaleString()}</span>`
    )
    .join('<br/>');
};

const getOptions = data => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-1100') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  },
  xAxis: {
    type: 'category',
    data: data.keys,
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'solid'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      formatter: function (name) {
        return name;
      },
      margin: 15
    },
    splitLine: {
      show: false
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisTick: { show: false },
    axisLine: { show: false },
    min: 0
  },
  series: [
    {
      data: data.values,
      type: 'bar',
      itemStyle: {
        color: function (params) {
          let seriesColors = getChartSeriesColor();
          return seriesColors[params.dataIndex];
        }
      },
      label: {
        show: true,
        position: 'top', // or 'inside' or other positions based on your preference
        formatter: value => {
          if (value.data < 6) {
            return 'A';
          }
          if (value.data === 6) {
            return 'B';
          }
          if (value.data === 7) {
            return 'C';
          }
          if (value.data === 8) {
            return 'D';
          }
          if (value.data >= 9) {
            return 'E';
          }
        } // '{b}' for category name, '{c}' for value
      }
    }
  ],
  grid: {
    right: '6%',
    left: '7%',
    bottom: '15%',
    top: '15%',
    containLabel: false
  }
});

const RiskChart = ({ properties }) => {
  const [riskChartData, setRiskChartData] = useState({});

  useEffect(() => {
    if (!isEmpty(properties)) {
      MakeRiskChartData();
    }
  }, [properties]);

  const MakeRiskChartData = () => {
    let values = [];
    values.push(properties.ITEM1);
    values.push(properties.ITEM2);
    values.push(properties.ITEM3);
    values.push(properties.ITEM4);
    values.push(properties.ITEM5);
    values.push(properties.ITEM6);

    // console.log('series : ', series);
    setRiskChartData({
      values: values,
      keys: ['상수관', '하수관', '열배관', '가스관', '전기관', '통신관']
    });
  };

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="지하매설도 개별 위험도 그래프"
        light
        titleTag="h6"
      />
      <Card.Body className={'p-0'}>
        <Row className="g-0 p-3 pt-0">
          <ReactEChartsCore
            echarts={echarts}
            option={getOptions(riskChartData)}
            style={{ height: '18.75rem' }}
          />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RiskChart;
