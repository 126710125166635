import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Container,
  Form,
  InputGroup,
  Tabs,
  Tab,
  Badge,
  Dropdown,
  DropdownButton,
  Offcanvas,
  Button
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CloseButton from 'react-bootstrap/CloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faBackspace,
  faBackward,
  faStepBackward,
  faThumbtack,
  faClipboardCheck,
  faFastBackward,
  faReply
} from '@fortawesome/free-solid-svg-icons';
import FilterList from './FilterList';
import FilterInfo from './FilterInfo';
import { chainAxios } from 'helpers/chainAxios';
import ProjectManageChart from './chart/ProjectManageChart';
import ProjectList from './ProjectList';
import ProjectSummary from './ProjectSummary';
import ProjectContent from './tab/ProjectContent';
import RoadFacilityContent from './tab/RoadFacilityContent';
import { MapsContext } from 'context/Context';
import { disable } from 'ol/rotationconstraint';
import IconButton from '../../common/IconButton';
import FalconCardHeader from '../../common/FalconCardHeader';

const LeftArea = props => {
  const [facilitiesInfo, setFacilitiesInfo] = useState({});

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const LeftAreaShowEvent = () => {
    props.LeftAreaShowEvent();
  };

  const { selectedProject, setSelectedProject } = useContext(MapsContext);
  const [showProjectSummary, setShowProjectSummary] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('P');
  const [tabDisabled, setTabDisabled] = useState(true);
  // const [selectedProejct, setSelectedProject] = useState({});

  useEffect(() => {}, []);

  useEffect(() => {
    if (!!selectedProject && JSON.stringify(selectedProject) !== '{}') {
      setShowProjectSummary(true);
      setActiveTabKey('F');
      setTabDisabled(false);
    }

    if (!!selectedProject && JSON.stringify(selectedProject) === '{}') {
      setActiveTabKey('P');
      setTabDisabled(true);
    }
  }, [selectedProject]);

  const backShowProjectManageChart = () => {
    setShowProjectSummary(false);
    setSelectedProject({});
  };
  const onTabChange = value => {
    setActiveTabKey(value);
  };

  return (
    <Card style={{ height: '100%', position: 'relative' }}>
      <FalconCardHeader
        title={showProjectSummary ? '프로젝트 개요' : '프로젝트 관리현황'}
        light
        titleTag="h6"
        endEl={
          <Flex justifyContent={showProjectSummary ? 'between' : 'end'}>
            {showProjectSummary ? (
              <IconButton
                variant="falcon-light"
                icon={faReply}
                transform="shrink-3"
                size="sm"
                onClick={() => {
                  backShowProjectManageChart();
                }}
              ></IconButton>
            ) : null}
            {/*<CloseButton onClick={LeftAreaShowEvent} />*/}
          </Flex>
        }
      />
      <Card.Body className={'pt-0'}>
        <Row className="g-3 align-items-center mt-1 mb-3">
          {showProjectSummary ? (
            <ProjectSummary selectedProject={selectedProject} />
          ) : (
            <ProjectManageChart />
          )}
        </Row>
        <Row className="g-3 align-items-center mt-1 mb-3">
          {showProjectSummary ? <RoadFacilityContent /> : <ProjectContent />}
          {/*<Tabs*/}
          {/*  defaultActiveKey={'P'}*/}
          {/*  id="fill-type-tab"*/}
          {/*  activeKey={activeTabKey}*/}
          {/*  onSelect={onTabChange}*/}
          {/*  // className="mb-3"*/}
          {/*  fill*/}
          {/*>*/}
          {/*  <Tab eventKey="P" title="프로젝트">*/}
          {/*    <ProjectContent />*/}
          {/*  </Tab>*/}
          {/*  <Tab eventKey="F" title="도로·시설물" disabled={tabDisabled}>*/}
          {/*    <RoadFacilityContent />*/}
          {/*  </Tab>*/}
          {/*</Tabs>*/}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LeftArea;
