import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useContext
} from 'react';
import { Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import { MapsContext } from 'context/Context';

const ModelViewTop = ({ onButtonClick }) => {
  const {
    isShowModelView,
    setIsShowModelView,
    modelViewArgs,
    setModelViewArgs
  } = useContext(MapsContext);

  const [isMapWireframe, setIsMapWireframe] = useState(true);
  const [isUnderGroundWireframe, setIsUnderGroundWireframe] = useState(true);

  const [circleScale, setCircleScale] = useState(0.5);
  const [facilityHeightScale, setFacilityHeightScale] = useState(0.5);
  const [markerHeightScale, setMarkerHeightScale] = useState(0.1);
  const [underGroundHeightScale, setUnderGroundHeightScale] = useState(5);
  const [markerDistanceThreshold, setMarkerDistanceThreshold] = useState(10);
  const [underGroundDistanceThreshold, setUnderGroundDistanceThreshold] =
    useState(250);

  const handleMapWireframeChange = useCallback(event => {
    setIsMapWireframe(event.target.checked);
  }, []);

  const handleUnderGroundWireframeChange = useCallback(event => {
    setIsUnderGroundWireframe(event.target.checked);
  }, []);

  const handleCircleScaleInputChange = useCallback(event => {
    setCircleScale(event.target.value);
  }, []);

  const handleFacilityHeightInputChange = useCallback(event => {
    setFacilityHeightScale(event.target.value);
  }, []);

  const handleMarkerHeightInputChange = useCallback(event => {
    setMarkerHeightScale(event.target.value);
  }, []);

  const handleUnderGroundHeightInputChange = useCallback(event => {
    setUnderGroundHeightScale(event.target.value);
  }, []);

  const handleMarkerDistanceThresholdInputChange = useCallback(event => {
    setMarkerDistanceThreshold(event.target.value);
  }, []);

  const handleUnderGroundDistanceThresholdInputChange = useCallback(event => {
    setUnderGroundDistanceThreshold(event.target.value);
  }, []);

  const handleMakeModelViewArgs = () => {
    setModelViewArgs({
      isMapWireframe: isMapWireframe,
      isUnderGroundWireframe: isUnderGroundWireframe,
      circleScale: circleScale,
      facilityHeightScale: facilityHeightScale,
      markerHeightScale: markerHeightScale,
      underGroundHeightScale: underGroundHeightScale,
      markerDistanceThreshold: markerDistanceThreshold,
      underGroundDistanceThreshold: underGroundDistanceThreshold
    });
    setIsShowModelView(true);
  };

  const containerStyle = useMemo(
    () => ({
      backgroundColor: getColor('gray-100')
    }),
    []
  );

  return (
    <>
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="between"
        className={'px-3 py-3'}
        style={containerStyle}
      >
        <Flex alignItems="center" direction="row" justifyContent="start">
          <Form.Group className={'pe-3'}>
            <Form.Label>지도 와이어프레임</Form.Label>
            <Form.Check
              key={isMapWireframe}
              type="switch"
              label="표시"
              checked={isMapWireframe}
              onChange={handleMapWireframeChange}
            />
          </Form.Group>
          <Form.Group className={'pe-3'}>
            <Form.Label>지하지질 와이어프레임</Form.Label>
            <Form.Check
              key={isUnderGroundWireframe}
              type="switch"
              label="표시"
              checked={isUnderGroundWireframe}
              onChange={handleUnderGroundWireframeChange}
            />
          </Form.Group>
          <Form.Group className={'pe-2'}>
            <Form.Label>마커 크기비율</Form.Label>
            <Form.Control
              type="number"
              value={circleScale}
              min="0.1"
              max="5"
              step="0.1"
              onChange={handleCircleScaleInputChange}
            />
          </Form.Group>
          <Form.Group className={'pe-2'}>
            <Form.Label>건물높이 높낮이비율</Form.Label>
            <Form.Control
              type="number"
              value={facilityHeightScale}
              min="0.1"
              max="10"
              step="0.1"
              onChange={handleFacilityHeightInputChange}
            />
          </Form.Group>
          <Form.Group className={'pe-2'}>
            <Form.Label>센서표고 높낮이비율</Form.Label>
            <Form.Control
              type="number"
              value={markerHeightScale}
              min="0.1"
              max="10"
              step="0.1"
              onChange={handleMarkerHeightInputChange}
            />
          </Form.Group>
          <Form.Group className={'pe-2'}>
            <Form.Label>지하지질 높낮이비율</Form.Label>
            <Form.Control
              type="number"
              value={underGroundHeightScale}
              min="0.1"
              max="10"
              step="0.1"
              onChange={handleUnderGroundHeightInputChange}
            />
          </Form.Group>
          <Form.Group className={'pe-2'}>
            <Form.Label>센서 거리임계값</Form.Label>
            <Form.Control
              type="number"
              value={markerDistanceThreshold}
              min="1"
              max="100"
              step="1"
              onChange={handleMarkerDistanceThresholdInputChange}
            />
          </Form.Group>
          <Form.Group className={'pe-2'}>
            <Form.Label>지하지질 거리임계값</Form.Label>
            <Form.Control
              type="number"
              value={underGroundDistanceThreshold}
              min="10"
              max="500"
              step="10"
              onChange={handleUnderGroundDistanceThresholdInputChange}
            />
          </Form.Group>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        direction="row"
        justifyContent="end"
        className={'px-3 py-3'}
        style={containerStyle}
      >
        <Flex alignItems="center" direction="row" justifyContent="end">
          <Button
            variant="falcon-primary"
            size="lg"
            onClick={handleMakeModelViewArgs}
          >
            3D모델 생성하기
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ModelViewTop;
