import React, { Suspense, useEffect, useState } from 'react';

import { Row, Col, Dropdown, Tab, Card, Nav } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import FacilityPredictedSafetyChart from '../chart/realtime/FacilityPredictedSafetyChart';
import RealTimeDistance from '../chart/realtime/RealTimeDistance';
import RealDataSend from '../chart/realtime/RealDataSend';
import RealtimeAngle from '../chart/realtime/RealtimeAngle';
import classNames from 'classnames';
import SimpleBarReact from 'simplebar-react';
import ProjectFacilitiesList from '../../../../management-target/project/detail/component/FacilitiesList';
import ProjectSensorList from '../../../../management-target/project/detail/component/SensorList';

const RealTimeContent = ({ facilityId }) => {
  return (
    <>
      {/*<SimpleBar style={{ width: '100%', height: '100%', maxHeight: 950 }}>*/}
      <Row className="g-3">
        <Col md={12}>
          <RealTimeDistance facilityId={facilityId} className="h-100" />
        </Col>
      </Row>
      <Row className="g-3">
        <Col lg={8} className="pt-3">
          <RealtimeAngle facilityId={facilityId} className="w-100 h-100" />
        </Col>
        <Col lg={4} className="pt-3">
          <Card className={classNames('overflow-hidden pb-4')}>
            <Tab.Container id="audience-tab" defaultActiveKey="data">
              {/*<SimpleBarReact>*/}
              <Card.Header className="p-0 bg-light">
                <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="data">
                      <h6 className={'mb-0'}>실시간 데이터 전송</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="mb-0 py-3 px-4" eventKey="safety">
                      <h6 className={'mb-0'}>시설물 예측 안전 등급</h6>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              {/*</SimpleBarReact>*/}
              <Card.Body className={'p-0'}>
                <Tab.Content>
                  <Tab.Pane unmountOnExit eventKey="data">
                    <RealDataSend facilityId={facilityId} className="h-100" />
                  </Tab.Pane>
                  <Tab.Pane unmountOnExit eventKey="safety">
                    <FacilityPredictedSafetyChart
                      facilityId={facilityId}
                      className="h-100"
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
      {/*</SimpleBar>*/}
    </>
  );
};

export default RealTimeContent;
