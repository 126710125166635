import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const RealtimeAngleChart = forwardRef(({ data }, ref) => {
  const chartRef = useRef(null);

  const [series, setSeries] = useState([]);
  const [legends, setLegends] = useState([]);
  const [visibleSeries, setVisibleSeries] = useState({});

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.getEchartsInstance();
      // legend.selected에서 현재 시리즈 상태를 뽑아내기
      const currentOptions = chartInstance.getOption();
      if (currentOptions && currentOptions.legend && currentOptions.legend[0]) {
        const currentVisibleSeries = currentOptions.legend[0].selected;
        setVisibleSeries(currentVisibleSeries); // 상태 업데이트
      }

      // 기존 차트 인스턴스를 초기화(삭제)
      echarts.dispose(chartRef.current.getEchartsInstance());
    }
    // 시리즈와 레전드 데이터를 만들고, visibleSeries 업데이트
    if (data.seriesDatas) {
      makeSeries(data.seriesDatas);
    }
    // 차트를 다시 그리기
    const chartInstance = chartRef.current.getEchartsInstance();
    chartInstance.setOption(getOptions(legends, series));
    // 차트가 다시 그려진 후 이벤트 리스너 다시 등록
    onChartReady(chartInstance);
  }, [data]);

  const makeSeries = () => {
    let makeSeries = new Array();

    data.seriesDatas.forEach((item, index) => {
      // console.log('RealtimeAngleChart item : ', item);
      makeSeries.push({
        name: item.name,
        type: 'line',
        connectNulls: true,
        data: item.data,
        lineStyle: { color: getColor(item.color) },
        itemStyle: {
          borderColor: getColor(item.color),
          borderWidth: 2,
          backgroundColor: 'transparent'
        },
        symbol: 'circle',
        symbolSize: 10,
        showSymbol: true,
        emphasis: {
          scale: true
        },
        smooth: false,
        animation: true,
        animationDuration: 1000, // 애니메이션의 지속 시간 설정
        animationDurationUpdate: 1000, // 업데이트 시 애니메이션의 지속 시간 설정
        animationEasing: 'linear', // 애니메이션의 진행 방식 설정
        animationEasingUpdate: 'linear' // 업데이트 시 애니메이션의 진행 방식 설정
      });
    });

    setSeries(makeSeries);
    setLegends(data.legendData);
  };

  const onChartReady = chart => {
    // "전체 선택" 버튼이 눌렸을 때
    chart.on('legendselectall', params => {
      // const { selected, name, type } = params;
      // console.log('Legend changed 전체:', selected, name, type);
      setVisibleSeries(params.selected);
    });

    // "반전" 버튼이 눌렸을 때
    chart.on('legendinverseselect', params => {
      // const { selected, name, type } = params;
      // console.log('Legend changed 반전:', selected, name, type);
      setVisibleSeries(params.selected);
    });

    chart.on('legendselectchanged', params => {
      setVisibleSeries(params.selected);
    });
  };

  const tooltipFormatter = params => {
    // console.log(params);
    return `<span class='text-600 fs--1'>${params.data.date}</span><br/>
  <div class="dot me-1 fs--2 d-inline-block" style="background-color: ${
    params.borderColor
  }"></div>
        <span class='text-600 fs--1'>${
          params.seriesName
        }</span> : [<span class='text-600 fs--1'>${
      params.value === undefined
        ? ' - '
        : ' X : ' + params.value[0] + '˚ / Y : ' + params.value[1] + '˚ '
    }</span>]`;
  };

  const getOptions = (legends, series) => ({
    color: getColor('gray-100'),
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-100'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0
    },
    legend: {
      data: legends,
      show: true,
      type: 'scroll',
      orient: 'horizontal',
      // orient: 'vertical',
      right: 10,
      top: 0,
      bottom: 20,
      selector: [
        {
          type: 'all',
          title: '전체선택'
        },
        {
          type: 'inverse',
          title: '반전'
        }
      ],
      // 현재 보이는 시리즈 상태를 반영
      selected: visibleSeries
    },
    xAxis: {
      type: 'value',
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15
      },
      axisTick: { show: false },
      // axisLine: { show: false },
      minorTick: {
        show: true
      },
      minorSplitLine: {
        show: true
      }
    },
    yAxis: {
      type: 'value',
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15
      },
      axisTick: { show: false },
      // axisLine: { show: false },
      minorTick: {
        show: true
      },
      minorSplitLine: {
        show: true
      }
    },
    dataZoom: [
      {
        show: true,
        type: 'inside',
        filterMode: 'none',
        xAxisIndex: [0],
        startValue: -20,
        endValue: 20
      },
      {
        show: true,
        type: 'inside',
        filterMode: 'none',
        yAxisIndex: [0],
        startValue: -20,
        endValue: 20
      }
    ],
    series: series,
    grid: {
      right: '6%',
      left: '7%',
      bottom: '10%',
      top: '10%',
      containLabel: false
    }
  });

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getOptions(legends, series)}
      className="h-100"
      notMerge={true}
      onChartReady={onChartReady}
    />
  );
});

// DataTrafficChart.propTypes = {
//   data: PropTypes.shape({
//     date: PropTypes.array,
//     allSendCount: PropTypes.array,
//     errorSendCount: PropTypes.array
//   })
// };

export default RealtimeAngleChart;
