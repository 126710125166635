import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import moment from 'moment/moment';
import IconButton from 'components/common/IconButton';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext, { MapsContext, SensorContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { chainAxios } from 'helpers/chainAxios';
import { getColor } from 'helpers/utils';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import ResponsivePagination from 'react-responsive-pagination';
import SensorDetail from '../index';

const MapboxSensorContent = ({ facilityId }) => {
  const columns = [
    {
      accessor: 'favorite',
      Header: '즐겨찾기',
      headerProps: { className: 'text-900 --falcon-gray-100' },
      Cell: rowData => {
        const { favorite } = rowData.row.original;
        return (
          <Flex direction={'column'} className={'w-100'} alignItems={'center'}>
            <FontAwesomeIcon
              onClick={() => {}}
              icon={favorite ? 'star' : ['far', 'star']}
              // transform="down-2"
              className={classNames(
                { 'text-primary': favorite, 'text-300': !favorite },
                'cursor-pointer'
              )}
            />
          </Flex>
        );
      }
    },
    // {
    //   accessor: 'project.value',
    //   Header: '프로젝트명',
    //   headerProps: { className: 'text-900' },
    //   Cell: rowData => {
    //     const { project } = rowData.row.original;
    //     return (
    //       <Flex alignItems="center">
    //         <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
    //           {project.value}
    //         </div>
    //       </Flex>
    //     );
    //   }
    // },
    {
      accessor: 'properties.HUBID',
      Header: '허브번호',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { properties } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {properties.HUBID}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'sensorHole',
      Header: '센서명',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { sensorHole, id } = rowData.row.original;
        return (
          <Button
            className={'fs--1'}
            variant="link"
            onClick={() => {
              goToSensorDetail(id);
            }}
          >
            {!!sensorHole ? sensorHole.value : '-'}
          </Button>
        );
      }
    },
    {
      accessor: 'properties.SSTYPE',
      Header: '측정타입',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { properties } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {properties.SSTYPE}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'status',
      Header: '상태',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        let badgeColor = 'dark';
        if (status === 'OFF') {
          badgeColor = 'warning';
        }
        if (status === 'ERR') {
          badgeColor = 'danger';
        }
        if (status === 'ON') {
          badgeColor = 'primary';
        }
        return (
          <SoftBadge bg={badgeColor} className="fs--2">
            {status}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'manageDepart',
      Header: '관리부서',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { manageDepart } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {manageDepart}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'properties.SSVERSION',
      Header: '펌웨어 버전',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { properties } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {properties.SSVERSION}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'battery',
      Header: '배터리',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { battery } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {battery}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'createDate',
      Header: '설치일',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { createDate } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {moment(createDate).format('YYYY-MM-DD')}
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'endDate',
      Header: '종료일',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { endDate } = rowData.row.original;
        let convertEndDate = '';
        if (endDate !== undefined) {
          convertEndDate = moment(endDate).format('YYYY-MM-DD');
        }

        return (
          <Flex alignItems="center">
            <div className={'fs--1 text-truncate text-center flex-1 text-800'}>
              {convertEndDate}
            </div>
          </Flex>
        );
      }
    }
  ];

  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const { setSelectedProject } = useContext(MapsContext);

  const [selectedSensorId, setSelectedSensorId] = useState(null);
  const [detailShow, setDetailShow] = useState(false);

  const { setIsOpenSensorCurrentSituation, setCurrentSensor } =
    useContext(SensorContext);

  const coursesNavbarVerticalCollapsed = useRef(isNavbarVerticalCollapsed);

  const navigate = useNavigate();

  const [sensorList, setSensorList] = useState([]);

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const _pageSize = 10;

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', false);
    // setSelectedProject({});

    return () => {
      setConfig(
        'isNavbarVerticalCollapsed',
        coursesNavbarVerticalCollapsed.current
      );
    };
  }, []);

  useEffect(() => {
    // getProjectStatus();
    getSensorList();
  }, []);

  useEffect(() => {
    getSensorList();
  }, [currentPage]);

  const getSensorList = () => {
    let url =
      '/api/facility/' +
      facilityId +
      '/sensors?pageNo=' +
      (currentPage - 1) +
      '&pageSize=' +
      _pageSize +
      '&sort=createDate$desc';

    chainAxios
      .get(url)
      .then(response => {
        if (response.data.resultCode === 200) {
          let sensorList = response.data.data.sensorList.dataList;

          let totalElements = response.data.data.sensorList.totalElements;
          setTotalPages(Math.ceil(totalElements / _pageSize));
          setTotalElements(totalElements);

          sensorList.sort((a, b) => {
            if (a.sensorHole === null || b.sensorHole === null) return -99;
            if (a.sensorHole.value > b.sensorHole.value) return 1;
            if (a.sensorHole.value < b.sensorHole.value) return -1;
          });
          setSensorList(sensorList);
        }
      })
      .catch(error => {
        console.log('Sensor getSensorList() : ', error);
      });
  };

  const goToSensorDetail = sensorId => {
    setSelectedSensorId(sensorId);
    setDetailShow(true);
  };

  const detailModalShowEvent = () => {
    setDetailShow(!detailShow);
    // setMiniMapSelectedSensor('');
  };

  return (
    <>
      {detailShow && (
        <SensorDetail
          sensosrId={selectedSensorId}
          detailModalShow={detailShow}
          detailModalShowEvent={detailModalShowEvent}
        />
      )}
      <Row className="g-3">
        <Col md={12}>
          <Card className="h-100">
            <Card.Body className={'pb-3'}>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className={'px-3 py-3'}
                style={{ backgroundColor: getColor('gray-100') }}
              >
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="start"
                >
                  {/*<Form.Select size="sm" className="me-3">*/}
                  {/*  <option value={'status_all'}>상태 전체</option>*/}
                  {/*  /!*{projectInfo.map((project, index) => (*!/*/}
                  {/*  /!*  <option value={project.id}>{project.name}</option>*!/*/}
                  {/*  /!*))}*!/*/}
                  {/*  <option value={'BEFORE'}>예정</option>*/}
                  {/*  <option value={'FINISH'}>완료</option>*/}
                  {/*  <option value={'ON_ISSUE'}>점검요함</option>*/}
                  {/*  <option value={'NO_ISSUE'}>진행중</option>*/}
                  {/*</Form.Select>*/}
                </Flex>
                <Flex alignItems="center" direction="row" justifyContent="end">
                  <Flex
                    alignItems="center"
                    direction="row"
                    justifyContent="start"
                  >
                    <AdvanceTableSearchBox
                      table
                      globalFilter={''}
                      setGlobalFilter={() => {}}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent="between"
                className={'px-3'}
              >
                <Flex
                  alignItems="end"
                  alignContent={'end'}
                  direction="column"
                  justifyContent="start"
                  className={'fs--1'}
                >
                  총 {totalElements}건
                </Flex>
                <Flex
                  alignItems={'end'}
                  alignContent={'end'}
                  direction="column"
                  justifyContent="end"
                  className={'fs--1'}
                >
                  <Form.Check
                    type="switch"
                    id="checkedSwitch"
                    label="즐겨찾기"
                    // defaultChecked
                  />
                </Flex>
              </Flex>
              <AdvanceTableWrapper
                columns={columns}
                data={sensorList}
                sortable
                pagination
                perPage={_pageSize}
              >
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle text-center"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    hover: true,
                    className: 'fs--1 mb-0 overflow-hidden text-center'
                  }}
                />
              </AdvanceTableWrapper>
              {sensorList.length !== _pageSize && (
                <div
                  style={{
                    height:
                      ((_pageSize - sensorList.length) * 56.5).toString() + 'px'
                  }}
                ></div>
              )}
              <div className="mt-3">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                  maxWidth={350}
                  extraClassName={'justify-content-center fs--2'}
                  previousLabel="‹"
                  nextLabel="›"
                  // narrowBehaviour={combine(dropNav, dropEllipsis)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MapboxSensorContent;
